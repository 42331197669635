import React, { Fragment, useState } from "react"
import PropTypes from "prop-types"
import { Spinner } from "@tmu/components/common"

import {
  Money,
  LazyImage,
  Button,
  Checkbox,
  UseBefore,
} from "@tmu/components/common"
import { FormattedMessage, navigate } from "gatsby-plugin-intl"

import {
  StyledWrapper,
  StyledSuccessDetailTitle,
  StyledSuccessPageImage,
  StyledSuccessButtons,
  StyledSuccessDonationInfo,
  StyledInfoSection,
  StyledMerchantInfoPart,
} from "./index.styles"

const MerchantCampaignDonationSuccessContent = ({
  campaign,
  donation,
  formatMessage,
  CLOUDFLARE_IMAGE_URL,
  merchant,
  user,
  isTablet,
  noDonationUsedPacs,
  noDonationNewPrice,
}) => {
  const imageSize = {
    width: isTablet ? 414 : 240,
  }
  const [isChecked, setIsChecked] = useState(false)
  const usedPacs =
    noDonationUsedPacs ??
    donation?.merchantPacReconciliation?.pacAmountAfterNormalizedDonation
  const newPrice =
    noDonationNewPrice ??
    donation?.merchantPacReconciliation?.purchaseAmountAfterDiscount
  if (!donation && !campaign) return <Spinner />
  

  return (
    <StyledWrapper>
      {campaign?.partner?.name && donation?.amount ? (
        <StyledSuccessDetailTitle data-testid="donation-success-details">
          <FormattedMessage
            id="campaign::donationSuccess::processedText1"
            defaultMessage="You have donated <pacs>{amount}</pacs> to <partner>{partnerName}</partner>"
            values={{
              pacs: (...chunks) => (
                <span key="processedText">
                  {chunks.map((chunk, i) => (
                    <Fragment key={i}>{chunk}</Fragment>
                  ))}
                </span>
              ),
              partner: (...chunks) => (
                <span key="partner">
                  {chunks.map((chunk, i) => (
                    <Fragment key={i}>{chunk}</Fragment>
                  ))}
                </span>
              ),
              partnerName: campaign?.partner?.name,
              amount: (
                <Money
                  value={
                    donation?.merchantPacReconciliation
                      ?.normalizedDonationAmount ||
                    donation?.amount ||
                    ""
                  }
                />
              ),
            }}
          />
        </StyledSuccessDetailTitle>
      ) : null}
      {usedPacs && Number(usedPacs) > 0 ? (
        <StyledSuccessDetailTitle data-testid="donation-success-details">
          <FormattedMessage
            id="campaign::donationSuccess::usedPacs"
            defaultMessage="You used <used>{earnedPACs}</used> PACs from your wallet."
            values={{
              earnedPACs: (
                <Money value={Number(usedPacs) || 0} currency={false} />
              ),
              used: (...chunks) => (
                <span key="used">
                  {chunks.map((chunk, i) => (
                    <Fragment key={i}>
                      <img
                        className="pacs-image"
                        src={
                          CLOUDFLARE_IMAGE_URL + "/static/assets/images/pac.svg"
                        }
                        alt={`pacs`}
                        width={16}
                        height={16}
                      />
                      {chunk}
                    </Fragment>
                  ))}
                </span>
              ),
            }}
          />
        </StyledSuccessDetailTitle>
      ) : null}
      <StyledSuccessPageImage>
        <LazyImage
          altName="donation-success"
          src={
            CLOUDFLARE_IMAGE_URL +
            (usedPacs
              ? "/static/assets/images/anim_order_slow.gif"
              : "/static/assets/images/tmu_celebration.gif")
          }
          width={isTablet ? "450" : "300"}
          fit="cover"
        />
      </StyledSuccessPageImage>
      <StyledInfoSection>
        <FormattedMessage
          id="campaign::donationSuccess::email"
          defaultMessage="We have sent a confirmation letter of your donation to <strong>{email}</strong>."
          values={{
            email: user?.email || donation?.intendedEmail,
            strong: (...chunks) => (
              <strong key="emailStrong" className="emailStrong">
                {chunks}
              </strong>
            ),
          }}
          tagName="p"
        />
        {donation?.merchantPacReconciliation?.pacMultiplierSurplus &&
        Number(donation?.merchantPacReconciliation?.pacMultiplierSurplus) >
          0 ? (
          <StyledSuccessDonationInfo>
            <FormattedMessage
              id="campaign::donationSuccess::earnedPacs"
              defaultMessage="You have received <earned>{earnedPACs}</earned> pacs for your future purchases."
              values={{
                earnedPACs: (
                  <Money
                    value={Number(
                      donation?.merchantPacReconciliation?.pacMultiplierSurplus
                    )}
                    currency={false}
                  />
                ),
                earned: (...chunks) => (
                  <span key="earned">
                    {chunks.map((chunk, i) => (
                      <Fragment key={i}>
                        <img
                          className="pacs-pacs-image"
                          src={
                            CLOUDFLARE_IMAGE_URL +
                            "/static/assets/images/pac.svg"
                          }
                          alt={`pacs`}
                          width={16}
                          height={16}
                        />
                        {chunk}
                      </Fragment>
                    ))}
                  </span>
                ),
              }}
              tagName="p"
            />
          </StyledSuccessDonationInfo>
        ) : null}

        {campaign?.isVoucher && campaign?.deadline ? (
          <>
            <hr />
            <UseBefore
              isVoucher={campaign?.isVoucher}
              endDate={campaign?.endDate}
              isCampaign={true}
            />
          </>
        ) : null}

        <hr />

        <StyledMerchantInfoPart>
          <LazyImage
            src={merchant?.logo}
            {...imageSize}
            altName={`image`}
            fit="cover"
          />
          <div>
            {newPrice ? (
              <FormattedMessage
                id="campaign::donationSuccess::newPurchasePrice"
                defaultMessage="Your new purchase price in {merchant} is <strong>{price}</strong>."
                values={{
                  merchant: merchant?.name,
                  price: <Money value={newPrice} />,
                  strong: (...chunks) => (
                    <strong key="emailStrong" className="price">
                      {chunks}
                    </strong>
                  ),
                }}
                tagName="p"
              />
            ) : null}
            {merchant?.name && (
              <FormattedMessage
                id="campaign::donationSuccess::show"
                defaultMessage="Show this screen to a cashier of {merchant}."
                values={{
                  merchant: merchant?.name,
                }}
                tagName="p"
              />
            )}
          </div>
        </StyledMerchantInfoPart>

        <hr />
        <Checkbox
          className="check"
          label={formatMessage({
            id: "campaign::donationSuccess::showed",
            defaultMessage: "I showed my donation to the cashier",
          })}
          isChecked={isChecked}
          onChange={() => setIsChecked(!isChecked)}
        />

        <div className={!isTablet ? "sticky-bottom-bar" : ""}>
          <StyledSuccessButtons>
            <Button
              label="leave"
              variant="outlined"
              color="blue"
              onClick={() => {
                navigate("/")
              }}>
              <FormattedMessage
                id="campaign::donationSuccess::leave"
                defaultMessage="Leave"
              />
            </Button>
          </StyledSuccessButtons>
        </div>
      </StyledInfoSection>
    </StyledWrapper>
  )
}

MerchantCampaignDonationSuccessContent.propTypes = {
  campaign: PropTypes.object,
  donation: PropTypes.object,
  redirectTo: PropTypes.string,
  donationType: PropTypes.string,
  isTablet: PropTypes.bool,
}

export default MerchantCampaignDonationSuccessContent
