import { SUPPORTED_LANGUAGES } from "@tmu/apollo/constants"

const useSupportedLanguages = () => {
  const getLanguageLabelForLanguageValue = (languageValue) => {
    const selectedLanguage = SUPPORTED_LANGUAGES.find((lang) => {
      return lang?.value === languageValue
    })

    return selectedLanguage ? selectedLanguage.label : null
  }

  const getLanguageValueForLanguageLabel = (languageLabel) => {
    const selectedLanguage = SUPPORTED_LANGUAGES.find((lang) => {
      return lang?.label === languageLabel
    })

    return selectedLanguage ? selectedLanguage.value : null
  }

  const getMatchingLanguageForLanguageValue = (languageValue) => {
    const matchingLanguage = SUPPORTED_LANGUAGES.find((lang) => {
      return lang?.value === languageValue
    })

    return matchingLanguage || ""
  }

  const getMatchingLanguageForLanguageLabel = (languageLabel) => {
    const matchingLanguage = SUPPORTED_LANGUAGES.find((lang) => {
      return lang?.label === languageLabel
    })

    return matchingLanguage || ""
  }

  return {
    getLanguageLabelForLanguageValue,
    getLanguageValueForLanguageLabel,
    getMatchingLanguageForLanguageValue,
    getMatchingLanguageForLanguageLabel,
  }
}

export default useSupportedLanguages
