import React, { useState } from "react"
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons/faChevronRight"
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons/faChevronLeft"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, FreeMode, Pagination, Mousewheel, Keyboard } from "swiper"
import {
  StyledSwiperWrapper,
  StyledChevronWrapper,
  StyledSwiperWrapperDots,
} from "./index.styles"

// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"

const SwiperComponent = ({
  children = [],
  withDots,
  isStoreCampaign = false,
  isSmall,
  mainPartners = false,
  otherPartners = false,
}) => {
  const [swiper, setSwiper] = React.useState()
  const prevRef = React.useRef()
  const nextRef = React.useRef()
  React.useEffect(() => {
    if (swiper) {
      swiper.params.navigation.prevEl = prevRef.current
      swiper.params.navigation.nextEl = nextRef.current
      if (swiper.navigation) {
        swiper.navigation.init()
        swiper.navigation.update()
      }
    }
  }, [swiper])
  return withDots ? (
    <StyledSwiperWrapperDots>
      <Swiper
        cssMode={true}
        navigation={true}
        pagination={true}
        mousewheel={true}
        keyboard={true}
        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
        className="mySwiper">
        {children.map((child, index) => {
          return <SwiperSlide key={index}>{child}</SwiperSlide>
        })}
      </Swiper>
    </StyledSwiperWrapperDots>
  ) : (
    <StyledSwiperWrapper>
      <Swiper
        modules={[FreeMode, Navigation]}
        spaceBetween={16}
        freeMode={true}
        navigation={{
          prevEl: prevRef?.current,
          nextEl: nextRef?.current,
        }}
        slidesPerView={"auto"}
        onSwiper={setSwiper}>
        {children.map((child, index) => {
          return <SwiperSlide key={index}>{child}</SwiperSlide>
        })}
      </Swiper>
      <StyledChevronWrapper
        isStoreCampaign={isStoreCampaign}
        isSmall={isSmall}
        mainPartners={mainPartners}
        otherPartners={otherPartners}
        className="chevron chevron-left"
        left
        ref={prevRef}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </StyledChevronWrapper>

      <StyledChevronWrapper
        isStoreCampaign={isStoreCampaign}
        isSmall={isSmall}
        mainPartners={mainPartners}
        otherPartners={otherPartners}
        className="chevron chevron-right"
        ref={nextRef}>
        <FontAwesomeIcon icon={faChevronRight} />
      </StyledChevronWrapper>
    </StyledSwiperWrapper>
  )
}

export default SwiperComponent
