import React, { useState } from "react"
import PropTypes from "prop-types"
import { Editor } from "react-draft-wysiwyg"
import { EditorState, ContentState, convertToRaw, Modifier } from "draft-js"
import { Field } from "formik"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import { useIntl } from "gatsby-plugin-intl"

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { StyledTextEditor } from "./index.styles"

const clearInlineStyles = (editorState) => {
  const styles = ["BOLD", "ITALIC", "UNDERLINE", "STRIKETHROUGH", "CODE"]

  const contentWithoutStyles = styles.reduce(
    (newContentState, style) =>
      Modifier.removeInlineStyle(
        newContentState,
        editorState.getSelection(),
        style
      ),
    editorState.getCurrentContent()
  )

  return EditorState.push(
    editorState,
    contentWithoutStyles,
    "change-inline-style"
  )
}

const TextEditor = ({
  value,
  disabled,
  onChange,
  testId = "editor",
  className,
  maxLength,
  ...props
}) => {
  const { locale } = useIntl()

  const [editorState, setEditorState] = useState(() => {
    const contentBlock = htmlToDraft(value ?? "")
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      )

      return clearInlineStyles(EditorState.createWithContent(contentState))
    }
    return EditorState.createEmpty()
  })

  const textLength = editorState.getCurrentContent().getPlainText().length

  const handleEditorStateChange = (editorState, field) => {
    setEditorState(editorState)
    const value = draftToHtml(convertToRaw(editorState?.getCurrentContent()))
    field.onChange(value)
    onChange(value)
  }

  // The inline options have been set according to the document in the link below.
  // https://jpuri.github.io/react-draft-wysiwyg/#/docs
  const toolbarOption = {
    options: ["inline"],
    inline: {
      options: ["bold", "italic", "underline"],
    },
  }

  return (
    <StyledTextEditor className={className}>
      <Field>
        {({
          field, // { name, value, onChange, onBlur }
          form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
          meta,
        }) => (
          <Editor
            webDriverTestID={testId}
            editorClassName="editor-class"
            editorState={editorState}
            onEditorStateChange={(evt) => {
              handleEditorStateChange(evt, field)
            }}
            handleBeforeInput={(val) => {
              if (val && textLength >= maxLength) {
                return "handled"
              }
              return "not-handled"
            }}
            handlePastedText={(val) => {
              return val.length + textLength >= maxLength
            }}
            toolbar={toolbarOption}
            stripPastedStyles={true}
            localization={{
              locale,
            }}
            {...props}
          />
        )}
      </Field>
      {maxLength && (
        <p className="text-editor-counter">
          {locale === "en" ? (
            `${textLength} characters used out of ${maxLength}`
          ) : locale === "es" ? (
            `${textLength} caracteres usados de ${maxLength}`
          ) : locale === "it" ? (
            `${textLength} caratteri utilizzati su ${maxLength}`
          ) : (
            <FormattedMessage
              id="form::textarea-textCounter"
              defaultMessage="{used} characters used out of {total}"
              values={{
                used: textLength || 0,
                total: maxLength,
              }}
            />
          )}
        </p>
      )}
    </StyledTextEditor>
  )
}

TextEditor.propTypes = {
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  testId: PropTypes.string,
  className: PropTypes.string,
  maxLength: PropTypes.number,
}

TextEditor.defaultProps = {
  value: "",
  disabled: false,
  onChange: () => {},
}

export default TextEditor
