import { useEffect, useState } from "react"
import { useLazyQuery } from "@apollo/client"
import { useApolloApiClients } from "@tmu/apollo/client"
import { PARTNER_DETAIL_QUERY } from "@tmu/apollo/storefront/queries/partner"
import { CAMPAIGN_LISTING_QUERY } from "@tmu/apollo/storefront/queries/campaign"
import { useIntl } from "gatsby-plugin-intl"

const useCharities = () => {
  const { storefrontClient } = useApolloApiClients()
  const { locale } = useIntl()
  const [charity, setCharity] = useState()
  const [callCharities, { loading, data, fetchMore, called, refetch }] =
    useLazyQuery(CAMPAIGN_LISTING_QUERY, {
      client: storefrontClient,
    })

  const getCharities = (variables) => {
    const vars = {
      fundraiserType: ["charities"],
      isPublic: true,
      first: 15,
      ...variables,
    }
    if (!called) {
      return callCharities({ variables: vars })
    } else {
      return refetch(vars)
    }
  }

  const [
    callCharity,
    {
      loading: charityLoading,
      data: charityData,
      called: charityCalled,
      refetch: charityRefetch,
    },
  ] = useLazyQuery(PARTNER_DETAIL_QUERY({ locale }), {
    client: storefrontClient,
  })
  const getCharity = (slug) => {
    const vars = { slug, isPublic: true }
    if (!charityCalled) {
      callCharity({ variables: vars })
    } else {
      charityRefetch(vars)
    }
  }

  useEffect(() => {
    if (charityData?.partner) setCharity(charityData?.partner)
  }, [charityData])

  const charities = data?.allCampaigns || {}

  return {
    charities,
    getCharities,
    fetchMore,
    getCharity,
    charity,
    charityLoading,
    charityCalled,
    loading,
  }
}

export default useCharities
