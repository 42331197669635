import styled from "@emotion/styled"

export const StyledOurPricing = styled.div`
  padding-bottom: 6.25rem;
`
export const StyledTitle = styled.h3`
  text-align: center;
  margin: 0 auto;
  font-weight: ${(p) => p.theme.fontWeights.regular};
  font-size: 3.125rem;
  line-height: 3.75rem;
  color: ${(p) => p.theme.colors.racingGreen};
  padding-bottom: 3.125rem;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1.875rem;
    line-height: 1.875rem;
  }
`
export const StyledCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.9375rem;
  justify-content: center;
  @media screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: flex;
    flex-direction: column;
  }
`
