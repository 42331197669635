import { useLazyQuery, useMutation } from "@apollo/client"
import { useApolloApiClients } from "@tmu/apollo/client"

import {
  CREATE_MERCHANT_PAC_RECONCILIATION_MUTATION,
  UPDATE_MERCHANT_PAC_RECONCILIATION_MUTATION,
} from "@tmu/apollo/storefront/mutations/user"
import { MERCHANT_PAC_RECONCILIATION_QUERY } from "@tmu/apollo/storefront/queries/user"

const usePacDiscountCalculation = (options) => {
  const { storefrontClient } = useApolloApiClients()
  const [createPacRecon, { loading, data, called, errors: createErrors }] =
    useMutation(CREATE_MERCHANT_PAC_RECONCILIATION_MUTATION, {
      ...options,
      client: options?.client ?? storefrontClient,
    })

  const [
    updatePacRecon,
    { loading: updateLoading, data: updateData, called: updateCalled },
  ] = useMutation(UPDATE_MERCHANT_PAC_RECONCILIATION_MUTATION, {
    ...options,
    client: options?.client ?? storefrontClient,
  })

  const [
    getPacReconDetail,
    { loading: detailLoading, data: detailData, called: detailCalled },
  ] = useLazyQuery(MERCHANT_PAC_RECONCILIATION_QUERY, {
    ...options,
    client: options?.client ?? storefrontClient,
  })

  return {
    createPacRecon,
    loading,
    detailLoading,
    called,
    updatePacRecon,
    updateLoading,
    updateCalled,
    createdReconData:
      data?.createMerchantPacReconciliation?.merchantPacReconciliation,
    createdReconError: [
      ...(createErrors || []),
      ...(data?.createMerchantPacReconciliation?.errors || []),
    ],
    getPacReconDetail,
    pacReconDetail: detailData?.merchantPacReconciliation,
    detailCalled,
  }
}

export default usePacDiscountCalculation
