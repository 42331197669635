import styled from "@emotion/styled"
import { Button } from "@tmu/components/common"
import { css } from "@emotion/react"

export const StyledDescription = styled.div`
  text-align: center;
  p {
    margin-bottom: 0.75rem;
  }
  color: ${(p) => p.theme.colors.pitchBlack};
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-family: ${(p) => p.theme.fontFamilies.headlineBig};
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: left;
    color: ${(p) => p.theme.colors.white};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2rem;
    p {
      font-size: 1.5rem;
      line-height: 2.25rem;
      text-align: left;
    }
  }
`

export const StyledTitle = styled.span`
  text-align: center;
  width: 100%;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-family: ${(p) => p.theme.fontFamilies.headlineBig};
  color: ${(p) => p.theme.colors.pitchBlack};
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1.5rem;
    line-height: 2rem;
    text-align: left;
    color: ${(p) => p.theme.colors.white};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 3rem;
    line-height: 4.125rem;
    text-align: left;
  }
`

export const StyledPage = styled.div`
  max-width: 21rem;
  text-align: center;
  margin: auto;
`

export const StyledBottom = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2rem;
`

export const StyledContinueButton = styled(Button)`
  background-color: ${(p) => p.theme.colors.pacificBlue};

  ${(p) =>
    p?.disabled &&
    css`
      background-color: ${p.theme.colors.gray};
    `}
`
export const StyledSkipButton = styled.div`
  cursor: pointer;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    text-align: right;
  }
`

export const StyledCategoryContainer = styled.div`
  > p {
    //uppercase header
    margin: 2rem 0 0.5rem;
    font-size: 0.75rem;
    line-height: 1.25rem;
    letter-spacing: 0.03em;
    text-align: left;
  }
  hr {
    margin: 0.5rem 0;
  }
  .filter-checkbox {
    //checkbox filters
    text-align: left;
    font-size: 1rem;
    line-height: 1.375rem;
    color: ${(p) => p.theme.colors.black};
    padding: 0;
    width: 100%;
    margin: 0.8125rem 0;
    > span {
      display: block;
      position: relative;
      float: right;
    }

    ${(p) =>
      p.isOfferList &&
      css`
        input:checked ~ .checkmark {
          background-color: ${p.theme.colors.carrot};
          border: 0.0625rem solid ${p.theme.colors.carrot};
        }

        input:checked ~ .checkmark:after {
          background-color: ${p.theme.colors.carrot};
        }
      `}
  }

  .category-filters button {
    font-family: ${(p) => p.theme.fontFamilies.regular};
    padding: 0.25rem 0;
    margin: 0;
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    font-size: 1rem;
    line-height: 1.375rem;
    min-height: 1.9375rem;
    color: ${(p) => p.theme.colors.black};
    width: 100%;
    display: flex;
    justify-content: space-between;
    i {
      margin-left: 0;
      margin-right: 0.6rem;
      width: 1rem;
      color: ${(p) => p.theme.colors.carrot};
    }
    svg {
      padding-top: 0.125rem;
      font-size: 1.2rem;
      height: 1.2rem;
      color: ${(p) => p.theme.colors.carrot};
    }
  }
  ${(p) =>
    p.isOfferList &&
    css`
      .category-filters button svg {
        color: ${p.theme.colors.carrot};
      }
    `}
`

export const StyledTopicContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  flex-wrap: wrap;
  grid-row-gap: 1.25rem;
  grid-column-gap: minmax(0.1rem, 1rem);
  justify-content: space-between;
  justify-items: center;
  width: 100%;
`

export const StyledTopicSelectItem = styled.div`
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.1),
    0 0.375rem 0.3125rem 0 rgba(0, 0, 0, 0.1);
  min-height: 6.25rem;
  cursor: pointer;
  max-width: 6.5rem;
  min-width: 6.5rem;
  &.selected {
    background-color: ${(p) => p.theme.colors.pacificBlue};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    max-width: 8.75rem;
    min-width: 8.75rem;
    min-height: 7.75rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    max-width: 16.625rem;
    min-width: 16.625rem;
    min-height: 13.125rem;
  }
`

export const StyledTopicSelectItemImage = styled.div``

export const StyledTopicSelectItemText = styled.div`
  height: 2.125rem;
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  max-width: 6.25rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &.selected {
    color: ${(p) => p.theme.colors.white};
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    max-width: 8.75rem;
    height: 2.6rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    max-width: 16.625rem;
    height: 4.5rem;
    font-size: 1.125rem;
    line-height: 1.5rem;
  }

  & > div {
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    display: -webkit-box;
  }
`

export const StyledWelcomePageContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  height: 100%;
`

export const StyledWelcomeLeft = styled.div`
  background-color: ${(p) => p.theme.colors.softGreen};
  height: 100%;

  &.step-1 {
    background-color: ${(p) => p.theme.colors.carrot};
  }
`

export const StyledWelcomeLeftContent = styled.div`
  margin-left: 1.5rem;
  magin-right: 1.5rem;
  text-align: left;
  float: left;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    magin-right: 7.25rem;
  }
`
export const StyledWelcomeRightContent = styled.div`
  padding: 2.25rem 1rem 0 1rem;
  text-align: center;
  margin: auto;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    max-width: 29.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding: 2.25rem 6.25rem 0 2rem;
    max-width: 60rem;
  }
  .skip-button {
    text-align: right;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.5rem;
      line-height: 2.25rem;
    }
  }

  .continue-button {
    text-align: center;
    button {
      display: unset;
      min-width: 12.5rem;
      margin-bottom: 1.125rem;
      @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
        min-width: 20.375rem;
        font-size: 1rem;
        margin-bottom: 3.125rem;
      }
    }
  }
`
export const StyledWelcomeRight = styled.div``

export const StyledLogo = styled.div`
  margin-top: 2rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin-top: 2.5rem;
  }
  div > button {
    margin: 0 !important;
    padding: 0 !important;
  }

  div > button > a > svg {
    margin: 0 !important;
    padding: 0 !important;
  }

  div {
    justify-content: left !important;
  }
`
