import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import FacebookLogin from "react-facebook-login"

const clientId = process.env.FACEBOOK_APP_ID

const useFacebookAuth = ({ signInWithFacebook }) => {
  return (
    <FacebookLogin
      appId={clientId}
      fields="name,email,picture"
      callback={signInWithFacebook}
      disableMobileRedirect={true}
      cssclassName="btn-facebook"
      icon={<i className="fab fa-facebook"></i>}
      textButton={
        <FormattedMessage
          id="signIn::continueWithFacebook"
          defaultMessage="Continue with Facebook"
          tagName="span"
        />
      }
    />
  )
}

export default useFacebookAuth
