import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { injectIntl, navigate } from "gatsby-plugin-intl"

import { Spinner, ErrorBoundary } from "@tmu/components/common"
import { useAuth } from "@tmu/hooks"
import {
  getCredentials,
  isBrowser,
  navigateIfNotAuthenticated,
} from "@tmu/utils/auth"

const PrivateRoute = ({ component: Component, intl, ...rest }) => {
  const { isLoading, isAuthenticated } = useAuth()
  const { expired } = getCredentials()

  const redirectUrl = isBrowser
    ? window?.location?.pathname + window?.location?.search
    : "/"

  useEffect(() => {
    if (isLoading) {
      return
    }
    if (isBrowser && expired) {
      if (rest?.path?.startsWith("campaign/")) {
        navigateIfNotAuthenticated(redirectUrl)
      } else {
        navigate(`/signin?next=${redirectUrl}`)
      }
    }
  }, [isLoading, isAuthenticated])

  return isLoading || !isAuthenticated || expired ? (
    <Spinner />
  ) : (
    <ErrorBoundary>
      <Component {...rest} />
    </ErrorBoundary>
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
}

export default injectIntl(PrivateRoute)
