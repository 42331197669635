import React, { Fragment, useRef } from "react"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { Form, useFormikContext } from "formik"
import { Button, CommonToggle, LazyImage } from "@tmu/components/common"
import { FavoriteNGOSection } from "@tmu/components/dashboard/dashboardCommon"
import { useAuth } from "@tmu/hooks"
import { getValueForLocale } from "@tmu/utils/string"
import theme from "@tmu/global/theme"

import {
  StyledProfileFavoriteCharityContainer,
  StyledProfileFavoriteCharityImage,
  StyledProfileFavoriteCharityImageContainer,
  StyledProfileFavoriteCharitySupportToggle,
  StyledProfileFlagRowContent,
  StyledProfileFlexContainer,
  StyledProfileFormSectionHelpText,
  StyledProfileSectionBoldTitle,
  StyledProfileSectionTitle,
  StyledProfileRowContent,
  StyledProfileSection,
  StyledProfileSpacer,
} from "./index.styles"

const FavoriteCharityProfileForm = ({
  partnerLogo,
  tempFavoriteCharityId,
  tempFavoriteCharity,
  isEdit,
  onFavChange,
  onToggleChange,
  onCancel,
  onSave,
  hideTitle,
}) => {
  const { locale, defaultLocale } = useIntl()
  const { user } = useAuth()
  const favNgoRef = useRef()

  const { values } = useFormikContext()

  return (
    <Form className="favorite-charity-profile-form">
      <fieldset>
        <StyledProfileRowContent hideTitle={hideTitle}>
          <StyledProfileFlagRowContent></StyledProfileFlagRowContent>
          {isEdit ? (
            <StyledProfileFavoriteCharityContainer>
              {!hideTitle ? (
                <>
                  <StyledProfileSectionTitle>
                    <FormattedMessage
                      id="dashboard::accountForm::charity"
                      defaultMessage="Charity"
                    />
                  </StyledProfileSectionTitle>
                  <StyledProfileSpacer bottom={0.75} />
                </>
              ) : null}
              <FavoriteNGOSection
                ref={favNgoRef}
                me={user}
                hideLabel={true}
                favoriteNGO={tempFavoriteCharityId}
                onFavChange={onFavChange}
                forceToDesktopView={true}
              />
              <StyledProfileSpacer top={1.5} />
              {onToggleChange && (
                <StyledProfileFavoriteCharitySupportToggle>
                  <CommonToggle
                    defaultChecked={
                      values?.allowUsersToSelectADifferentCampaign
                    }
                    handleChange={onToggleChange}
                  />
                  {values?.allowUsersToSelectADifferentCampaign ? (
                    <FormattedMessage
                      id="dashboard::accountForm::charitySupport"
                      defaultMessage="Buyer can change charity to support"
                      tagName="div"
                    />
                  ) : (
                    <FormattedMessage
                      id="dashboard::accountForm::charityNotSupport"
                      defaultMessage="Buyer <strong>can not</strong> change charity to support"
                      values={{
                        strong: (...chunks) => (
                          <span>
                            {chunks.map((chunk, i) => (
                              <Fragment key={i}>{chunk}</Fragment>
                            ))}
                          </span>
                        ),
                      }}
                    />
                  )}
                </StyledProfileFavoriteCharitySupportToggle>
              )}
            </StyledProfileFavoriteCharityContainer>
          ) : (
            <StyledProfileFavoriteCharityImageContainer>
              <StyledProfileFavoriteCharityImage>
                <LazyImage
                  src={partnerLogo}
                  altName={`partner-image`}
                  width={96}
                  height={54}
                  fit="cover"
                />
              </StyledProfileFavoriteCharityImage>
              <StyledProfileFormSectionHelpText>
                {getValueForLocale(
                  tempFavoriteCharity ?? values?.favoriteCharity,
                  "name",
                  locale,
                  defaultLocale
                )}
              </StyledProfileFormSectionHelpText>
              <StyledProfileSection>
                {values?.allowUsersToSelectADifferentCampaign ? (
                  <FormattedMessage
                    id="dashboard::accountForm::charitySupport"
                    defaultMessage="Buyer can change charity to support"
                    tagName="div"
                  />
                ) : (
                  <StyledProfileSectionBoldTitle>
                    <FormattedMessage
                      id="dashboard::accountForm::charityNotSupport"
                      defaultMessage="Buyer <strong>can not</strong> change charity to support"
                      values={{
                        strong: (...chunks) => (
                          <span>
                            {chunks.map((chunk, i) => (
                              <Fragment key={i}>{chunk}</Fragment>
                            ))}
                          </span>
                        ),
                      }}
                    />
                  </StyledProfileSectionBoldTitle>
                )}
              </StyledProfileSection>
            </StyledProfileFavoriteCharityImageContainer>
          )}
        </StyledProfileRowContent>
      </fieldset>
      {isEdit && onCancel && onSave && (
        <>
          <StyledProfileSpacer bottom={1.5} />
          <StyledProfileRowContent>
            <StyledProfileFlagRowContent></StyledProfileFlagRowContent>
            <StyledProfileFlexContainer>
              <Button
                className="black-button"
                color="transparent"
                label="cancel"
                onClick={onCancel}>
                <FormattedMessage
                  id="dashboard::forms::cancel"
                  defaultMessage="Cancel"
                />
              </Button>
              <Button
                data-testid="btn-save"
                className="blue-button button-margin"
                color={theme.colors.pacificBlue}
                type="button"
                onClick={onSave}>
                <FormattedMessage
                  id="dashboard::accountForm::save"
                  defaultMessage="Save"
                />
              </Button>
            </StyledProfileFlexContainer>
          </StyledProfileRowContent>
        </>
      )}
    </Form>
  )
}

export default FavoriteCharityProfileForm
