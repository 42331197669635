import styled from "@emotion/styled"

export const StyledWrapper = styled.div`
  margin: 5rem auto;

  p {
    text-align: center;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    span {
      font-weight: ${(p) => p.theme.fontWeights.bold};
    }

    a {
      color: ${(p) => p.theme.colors.blue};
    }
  }

  h5 {
    text-align: center;
  }

  .blue {
    color: ${(p) => p.theme.colors.blue};
  }

  .carrot {
    color: ${(p) => p.theme.colors.carrot};
  }

  strong {
    font-weight: unset;
  }
  .pacs-image {
    margin-right: 0.2rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    p {
      padding: 0.5rem 1.5rem;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin: 7rem auto;
    p {
      font-size: 1.5rem;
      line-height: 2.25rem;
    }
    button {
      min-height: 3.375rem;
      font-size: 1rem;
    }
  }
`

export const StyledSuccessDetailTitle = styled.div`
  font-weight: ${(p) => p.theme.fontWeights.bold};
  font-family: ${(p) => p.theme.fontFamilies.headline};
  color: ${(p) => p.theme.colors.pitchBlack};

  font-size: 1.5rem;
  line-height: 2.125rem;
  padding: 1rem 0 0;

  text-align: center;
  margin: auto;

  span {
    font-weight: ${(p) => p.theme.fontWeights.bold};
    font-family: ${(p) => p.theme.fontFamilies.headline};
  }

  .pacs-image {
    height: 1.2rem;
    width: 1.3rem;
    margin-right: 0.2rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 2rem;
    line-height: 2.75rem;
    .pacs-image {
      height: 1.5rem;
      width: 1.5rem;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 3rem;
    line-height: 4.0625rem;
  }
`

export const StyledSuccessButtons = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  padding: 0;
  gap: 1rem;

  button {
    width: 100%;
    margin: 0;
    max-width: 13.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    gap: 1.875rem;

    flex-direction: row;

    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    button {
      width: 13.5rem;
      max-width: initial;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    button {
      width: 22.5rem;
    }
  }
`

export const StyledSuccessPageImage = styled.div`
  text-align: center;
`
export const StyledSuccessOptionName = styled.p`
  font-weight: ${(p) => p.theme.fontWeights.bold};
  font-family: ${(p) => p.theme.fontFamilies.headline};
`
export const StyledSuccessDonationInfo = styled.p`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.3125rem;

  span {
    font-weight: ${(p) => p.theme.fontWeights.regular} !important;
  }
  img {
    position: relative;
    top: 0.1rem;
    margin: 0 0.2rem;
  }
`

export const StyledSuccessDonationInfoRegister = styled.div`
  text-align: center;
  margin: auto;

  a {
    color: ${(p) => p.theme.colors.blue};
  }

  span {
    font-weight: ${(p) => p.theme.fontWeights.regular} !important;
    a {
      color: ${(p) => p.theme.colors.carrot};
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    span,
    a {
      font-size: 1.5rem;
    }
  }
`
export const StyledSuccessDetailEventSubtitle = styled.div`
  font-weight: ${(p) => p.theme.fontWeights.bold};
  font-family: ${(p) => p.theme.fontFamilies.headline};
  color: ${(p) => p.theme.colors.pitchBlack};
  font-size: 1.125rem;
  line-height: 1.5rem;
  text-align: center;
  padding: 1rem;
  margin: auto;

  span,
  a {
    font-weight: ${(p) => p.theme.fontWeights.bold};
    font-family: ${(p) => p.theme.fontFamilies.headline};
  }

  .name {
    display: block;
    color: ${(p) => p.theme.colors.blue};
    cursor: pointer;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1.5rem;
    line-height: 2.125rem;
    .name {
      display: inline-block;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
    line-height: 2.75rem;
  }
`

export const StyledInfoSection = styled.div`
  margin: auto;
  padding: 1rem;
  max-width: 33.5rem;

  hr {
    height: 0.0625rem;
    background: ${(p) => p.theme.colors.carrot};
    margin: 1rem 0;
  }

  p,
  label {
    color: ${(p) => p.theme.colors.pitchBlack};
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-style: normal;
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    font-size: 0.75rem;
    line-height: 1.25rem;
  }

  label {
    margin: auto;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    width: 33.5rem;
    p,
    label {
      font-size: 1rem;
    }
    hr {
      margin: 1.5rem 0;
    }
    label {
      margin: 0 auto 2.5rem;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    width: 50rem;
    max-width: 50rem;

    p,
    label {
      font-size: 1.5rem;
      line-height: 1.25;
    }
    hr {
      margin: 2rem 0;
    }
    label {
      margin: 0 auto 3rem;
      .checkmark {
        top: 0.1875rem;
        height: 1.5rem;
        width: 1.5rem;
        &:after {
          left: 0.425rem;
          top: 0.14rem;
          width: 0.3rem;
          height: 0.625rem;
        }
      }
    }
  }
`

export const StyledMerchantInfoPart = styled.div`
  display: grid;
  grid-template-columns: 6.75rem auto;
  align-items: center;

  img {
    width: auto;
    height: auto;
    max-width: 6.75rem;
    max-height: 4rem;
  }
  p {
    text-align: left;
  }

  .price {
    color: ${(p) => p.theme.colors.carrot};
    font-weight: ${(p) => p.theme.fontWeights.bold};
    font-family: ${(p) => p.theme.fontFamilies.headline};
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    grid-template-columns: 10rem auto;
    img {
      max-width: 10rem;
      max-height: 5.875rem;
    }
  }
`
