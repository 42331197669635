import styled from "@emotion/styled"
import { CheckboxContainer } from "@tmu/components/common"
import { css } from "@emotion/react"

const GridTemplate = `
  display: grid;
  grid-template-columns: repeat(4, minmax(1.25rem, 1fr)) repeat(1, 4rem);
  grid-template-rows: 1fr;
  gap: 1rem;
  padding-left: 0;
  padding-right: 0;
  align-items: center;
`

export const StyledUserTable = styled.div``

export const StyledUserTableHeader = styled.div`
  ${GridTemplate}
  color: ${(p) => p.theme.colors.tableHeaderDefault};
  border-bottom: 0.0625rem solid ${(p) => p.theme.colors.tableItemBorder};
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 0.8rem;

  .edit-buttons {
    height: 100%;
    justify-self: end;
  }

  /* @media only screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
    grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr;
  }

  @media only screen and (max-width: ${(p) => p.theme.breakpoints.phone}) {
    display: none;
  } */
`

export const StyledUserTableRow = styled.div`
  ${GridTemplate}
  border-bottom: 0.0625rem solid ${(p) => p.theme.colors.tableItemBorder};
  min-height: 3.125rem;
  box-sizing: border-box;
  width: 100%;

  *:not(button) {
    color: ${(p) => p.theme.colors.secondaryBrandTextColor};
  }
  div,
  strong {
    font-size: 0.8rem;
  }
  strong {
    font-weight: ${(p) => p.theme.fontWeights.bold};
    color: ${(p) => p.theme.colors.gondola};
  }
  .light {
    opacity: 0.6;
    overflow: hidden;
  }

  .status {
    &.active {
      color: ${(p) => p.theme.colors.lightBlue};
    }

    &.disabled {
      color: ${(p) => p.theme.colors.lightRed};
    }
  }
  .secondary {
    color: ${(p) => p.theme.colors.primaryBrandTextColor};
  }

  .edit-buttons {
    height: 100%;
    justify-self: end;
    display: flex;
    align-items: center;
  }

  /* @media only screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    gap: 0.25rem;
    grid-template-areas:
      "fullName status"
      "email email"
      "role role"
      "date date";
    padding: 1.5rem 0;
    .hide-mobile {
      display: none;
    }
    .fullName {
      grid-area: fullName;
    }

    .status {
      grid-area: status;
      justify-self: end;
    }

    .email {
      grid-area: email;
    }

    .role {
      grid-area: role;
    }

    .date {
      grid-area: date;
    }
  } */
`

export const StyledCheckbox = styled(CheckboxContainer)`
  min-height: initial;
  label {
    margin: 0;
    color: ${(p) => p.theme.colors.lightRed};

    ${(p) =>
      p.checked &&
      css`
        color: ${p.theme.colors.lightBlue};
      `}
  }
`

export const selectStyles = {
  control: {
    borderWidth: "0.0625rem",
    borderRadius: "0.25rem",
    borderColor: "rgba(13,8,9,0.15)",
    //borderStyle: "unset",
    minWidth: "auto",
  },
  singleValue: {
    padding: 1,
  },
  option: {
    padding: "0.5rem 0.75rem",
  },
}

export const StyledUserTableMobileHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  color: ${(p) => p.theme.colors.tableHeaderDefault};
  border-bottom: 0.0625rem solid ${(p) => p.theme.colors.tableItemBorder};
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 0.8rem;
`

export const StyledUserTableMobileRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border-bottom: 0.0625rem solid ${(p) => p.theme.colors.tableItemBorder};
  min-height: 3.125rem;
  box-sizing: border-box;
  width: 100%;

  div,
  button,
  strong {
    font-size: 0.8rem;
  }

  strong {
    font-weight: ${(p) => p.theme.fontWeights.bold};
    color: ${(p) => p.theme.colors.gondola};
  }

  button {
    padding-right: unset;
    text-align: right;
  }

  p {
    color: ${(p) => p.theme.colors.blue};
  }
`

export const StyledRoleList = styled.div`
  overflow-y: auto;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-align: left;
`

export const StyledRoleItem = styled.div`
  font-size: 1.25rem;
  line-height: 1.5rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
`

export const StyledRoleNameContainer = styled.div`
  display: flex;
  gap: 0.75rem;

  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  font-style: ${(p) => p.theme.fontStyles.normal};
  color: ${(p) => p.theme.colors.pitchBlack};

  font-size: 1rem;
  line-height: 1.5rem;
`

export const StyledCheckIcon = styled.div`
  svg {
    color: ${(p) => p.theme.colors.pacificBlue};
    font-size: 1.5rem;
    height: 1.5rem;
  }
  height: 1.5rem;
`
export const StyledEmailWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const StyledUserContent = styled.div`
  &.fullName,
  &.date,
  &.email {
    text-align: left;
  }

  &.fullName {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-style: ${(p) => p.theme.fontStyles.normal};
    color: ${(p) => p.theme.colors.pitchBlack};
    font-size: 1.5rem;
    line-height: 2.125rem;

    margin-top: 0.8125rem;
  }

  &.date,
  &.email {
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    font-style: ${(p) => p.theme.fontStyles.normal};
    color: ${(p) => p.theme.colors.pitchBlack};
    font-size: 0.75rem;
    line-height: 1.25rem;

    margin: 0.5rem 0;
  }
`

export const StyledDeleteUserContainer = styled.div`
  button {
    width: 100%;
  }
  .blue-button {
    color: ${(p) => p.theme.colors.white};
    background-color: ${(p) => p.theme.colors.pacificBlue};
    :disabled {
      background-color: ${(p) => p.theme.colors.gray};
    }
    margin-bottom: 1rem;
  }

  .button-margin {
    margin-left: 0;
    margin-right: 0;
    width: 88vw;
    position: absolute;
    bottom: 1rem;
    white-space: normal;
  }
`
