import React from "react"
import { Button, LazyImage } from "@tmu/components/common"
import Slider from "react-slick"
import { FormattedMessage } from "gatsby-plugin-intl"
import { useIntl, navigate } from "gatsby-plugin-intl"
import { useMediaQuery } from "beautiful-react-hooks"
import { useAuth } from "@tmu/hooks"
import {
  StyledHero,
  StyledSlickDots,
  StyledHeroText,
  StyledHeroTitle,
  StyledPartial,
  StyledOverlay,
  StyledTopShadow,
  StyledHeroPage,
  StyledLogo,
  StyledTitleWrapper,
  StyledDiscountText,
} from "./index.styles"
const placeholder = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAB4BAMAAADLSivhAAAAG1BMVEXMzMyWlpacnJy+vr6jo6OxsbGqqqrFxcW3t7fLh8KjAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAnUlEQVRYhe3RvQrCMBiF4VOl1rGCFxDN0FWK4Frp4tjBC9CheyyVrlK8cH+mBopmFHmf7R0OHyESAAAAAOCXTNQuXbyuxvOz6V71tch3djS/iM/KlJbaKi4aN8ggkTaJydRI9uBl2FjNzTzfqt54GTaeW71PRZWXYePeqdRdOhovw8Zl5/LOKsnqYQaOV4v09bGz08UNEgAAAAD+1QORnRwe8Vw6/QAAAABJRU5ErkJggg==`
const { CLOUDFLARE_IMAGE_URL } = process.env
import { isBrowser } from "@tmu/utils/auth"

const SlidePage = ({
  logo,
  image,
  title,
  maxPacDiscountInOffers,
  detail,
  buttonLink,
  isButtonLinkExternal,
  buttonText,
  addOtpToButtonLink,
  contactRef = null,
  buttonEnabled = true,
}) => {
  const { pathname, search } = isBrowser ? window?.location : ""

  const { isAuthenticated } = useAuth()

  const { locale } = useIntl()

  const fluidImage =
    CLOUDFLARE_IMAGE_URL + "/static/assets/images/users/donors-square.jpg"

  const isLarge =
    typeof window !== "undefined" && useMediaQuery("(min-width: 63.75rem)")
  const isMedium =
    typeof window !== "undefined" && useMediaQuery("(max-width: 42.5rem)")

  const imgSize = {
    width: isLarge ? 1240 : isMedium ? 900 : 640,
    height: isLarge ? 900 : isMedium ? 900 : 640,
  }

  const handleHeroDetailClick = () => {
    if (!isAuthenticated && addOtpToButtonLink) {
      navigate(`/signin?&lang=${locale}&next=${pathname}${search}`)
    } else {
      if (contactRef && contactRef?.current) {
        contactRef?.current?.scrollIntoView({
          behavior: "smooth",
        })
      } else {
        if (isButtonLinkExternal) {
          isBrowser && window.open(buttonLink, "_blank")
        } else {
          const localePath = `/${locale}/`
          const [url] =
            buttonLink?.indexOf(localePath) === -1
              ? buttonLink?.split(localePath)
              : [buttonLink]

          navigate(url)
        }
      }
    }
  }

  const setImageStatus = () => {}

  const defaultComponent = (
    <StyledHeroPage>
      <div className="bg-image">
        <LazyImage
          altName={`hero-${image || fluidImage}`}
          src={image || fluidImage}
          {...imgSize}
          fit="cover"
          setImageStatus={setImageStatus}
        />
        <>
          <StyledOverlay />
          <StyledTopShadow />
        </>
      </div>

      <StyledPartial>
        <StyledTitleWrapper>
          {logo && (
            <StyledLogo
              data-testid="hero-detail-logo"
              src={logo || placeholder}
            />
          )}
          {title && (
            <StyledHeroTitle data-testid="hero-detail-title">
              {title}
            </StyledHeroTitle>
          )}
        </StyledTitleWrapper>
        {detail && (
          <StyledHeroText data-testid="hero-detail-description">
            {detail}
          </StyledHeroText>
        )}
        {Number(maxPacDiscountInOffers) ? (
          <StyledDiscountText data-testid="hero-detail-discount-text">
            <FormattedMessage
              id="hero::spendPACsUpTo"
              defaultMessage="Spend PACs up to {maxPacDiscountInOffers}%"
              values={{
                maxPacDiscountInOffers,
              }}
            />
          </StyledDiscountText>
        ) : null}
        {buttonEnabled && buttonText && buttonLink && (
          <Button
            color="red"
            label="Hero detail"
            data-testid="btn-hero-detail"
            className="hero--button"
            onClick={() => handleHeroDetailClick()}>
            {buttonText}
          </Button>
        )}
      </StyledPartial>
    </StyledHeroPage>
  )

  return defaultComponent
}

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  lazyLoad: true,
}

const Hero = ({
  contactRef,
  page,
  children = null,
  items = [],
  handleNavigate = () => {},
}) => {
  return (
    <StyledHero>
      {items?.length ? (
        <StyledSlickDots>
          <Slider {...settings}>
            {items.map((item) => (
              <SlidePage
                key={item.id}
                {...item}
                page={page}
                onNavigate={handleNavigate}
                contactRef={contactRef}
                buttonEnabled={item?.buttonEnabled}
              />
            ))}
          </Slider>
        </StyledSlickDots>
      ) : (
        children
      )}
    </StyledHero>
  )
}

export default Hero
