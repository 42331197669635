import React, { useCallback, useContext } from "react"
import { navigate, useIntl } from "gatsby-plugin-intl"
import { usePreviousValue } from "beautiful-react-hooks"
import { usePACBalance } from "@tmu/hooks"
import { Logo, Button, Money } from "@tmu/components/common"
import { HomePageContext } from "@tmu/context/homePageContext"
import theme from "@tmu/global/theme"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"

const HeaderPacBalance = ({ customClass }) => {
  const { locale } = useIntl()
  usePACBalance()
  const contextData = useContext(HomePageContext)

  const pacBalance = contextData.globalPacBalance
  const prevPACBalance = usePreviousValue(pacBalance)
  const { isWide } = getAllScreenTypes()
  const wideStyle = isWide ? "wide" : ""

  const handlePacWallet = useCallback(() => {
    navigate("/dashboard/donors/wallet")
  }, [])

  return (
    <li
      data-testid="user-pac-balance"
      className={
        prevPACBalance && pacBalance && prevPACBalance !== pacBalance
          ? "navbar__pac pulsate-fwd"
          : "navbar__pac"
      }>
      <Button
        className={`${wideStyle} ${customClass}`}
        variant="text"
        label="Pac Wallet"
        onClick={handlePacWallet}>
        <a href={`/${locale}/dashboard/donors/wallet`}>
          <Logo
            className="pac-icon"
            width={isWide ? 25 : 19}
            height={isWide ? 32 : 24}
            fill={theme?.colors?.carrot}
          />
          {pacBalance ? (
            <span>
              {!isNaN(pacBalance) && (
                <Money value={pacBalance} currency={false} />
              )}
            </span>
          ) : null}
        </a>
      </Button>
    </li>
  )
}

export default HeaderPacBalance
