import styled from "@emotion/styled"

export const StyledCookieMsgWrapper = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 10000;
  display: flex;
  background: ${(p) => p.theme.colors.white};
  padding: 1.25rem;
  border: 0.0625rem solid rgba(0, 0, 0, 0.15);
  color: ${(p) => p.theme.colors.secondaryBrandTextColor};
  justify-content: space-betwen;
  align-items: center;

  p {
    flex: 2 1 100%;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
    flex-direction: column;
    font-size: 0.9375rem;

    button {
      font-size: 0.9375rem;
      width: 100%;
    }

    p {
      margin-bottom: 1.25rem;
    }
  }

  button {
    min-width: 14.5rem;
    margin-left: 1.25rem;
  }
`
