import styled from "@emotion/styled"

export const StyledExpiryDate = styled.p`
  text-transform: uppercase;
  font-family: ${(p) => p.theme.fontFamilies.headlineBig};
  font-weight: ${(p) => p.theme.fontWeights.liteBlack};
  font-size: 0.75rem;
  line-height: 1.25rem;

  .blue-text {
    color: ${(p) => p.theme.colors.pacificBlue};
  }
`
export const StyledUseBefore = styled.span`
  color: ${(p) => p.theme.colors.pitchBlack};
  font-family: ${(p) => p.theme.fontFamilies.headlineBig};
  font-weight: ${(p) => p.theme.fontWeights.liteBlack};
  font-size: 0.75rem;
  line-height: 1.25rem;
`
export const StyledEndDate = styled.span`
  color: ${(p) => p.theme.colors.carrot};
  font-family: ${(p) => p.theme.fontFamilies.headlineBig};
  font-weight: ${(p) => p.theme.fontWeights.liteBlack};
  font-size: 0.75rem;
  line-height: 1.25rem;
`
