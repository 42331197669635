import React from "react"
import {
  StyledImageItem,
  StyledImageList,
  StyledImageOverlay,
  StyledNumber,
} from "./index.styles"
import { LazyImage } from "@tmu/components/common"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"

const CharityImageSelector = ({
  partnerImages,
  onSelect,
  selectedImages = [],
}) => {
  const { isWide } = getAllScreenTypes()

  const addSelected = (id) => {
    let selectedData = []
    if (!selectedImages || selectedImages?.indexOf(id) === -1) {
      if (selectedImages?.length > 4) {
        return
      }
      const temp = selectedImages ?? []
      selectedData = [...temp, id]
      onSelect(selectedData)
    }
  }

  const removeSelected = (id) => {
    const selectedData = selectedImages?.filter((item) => item !== id)
    onSelect(selectedData)
  }
  return (
    <StyledImageList>
      {partnerImages?.map((item, index) => {
        const selectedIndex = selectedImages?.indexOf(item?.node?.id)
        return (
          <StyledImageItem
            key={index}
            onClick={() => {
              const itemId = item?.node?.id
              if (!selectedImages || selectedImages?.indexOf(itemId) === -1) {
                addSelected(itemId)
              } else {
                removeSelected(itemId)
              }
            }}>
            <LazyImage
              src={item?.node?.image}
              width={isWide ? 334 : 220}
              height={isWide ? 188 : 120}
              altName={`card-image`}
              fit="cover"
            />
            {selectedIndex != null && selectedIndex !== -1 && (
              <StyledImageOverlay>
                <StyledNumber>{selectedIndex + 1}</StyledNumber>
              </StyledImageOverlay>
            )}
          </StyledImageItem>
        )
      })}
    </StyledImageList>
  )
}

export default CharityImageSelector
