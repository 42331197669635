import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import { StyledCampaignUpdatesTableHeader } from "./index.styles"

const CampaignUpdatesTableHeader = () => {
  return (
    <StyledCampaignUpdatesTableHeader>
      <div>
        <FormattedMessage
          id="dashboard::ngo::tableHeader::updatesTitle"
          defaultMessage="Title"
        />
      </div>
      <div>
        <FormattedMessage
          id="dashboard::ngo::tableHeader::updatesContent"
          defaultMessage="Content"
        />
      </div>
      <div className="centered">
        <FormattedMessage
          id="dashboard::ngo::tableHeader::updatesImage"
          defaultMessage="Image"
        />
      </div>
      <div className="centered">
        <FormattedMessage
          id="dashboard::ngo::tableHeader::date"
          defaultMessage="Date"
        />
      </div>
    </StyledCampaignUpdatesTableHeader>
  )
}

export default CampaignUpdatesTableHeader
