import { useExpanded, useSortBy, useTable } from "react-table"
import { StyledHeaderIconContainer, StyledTable } from "./index.styles"
import {
  faArrowDownShortWide,
  faArrowUpWideShort,
  faBars,
} from "@fortawesome/free-solid-svg-icons"
import { Pagination } from "@tmu/components/common"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"

const Table = ({
  data,
  columns,
  hiddenColumns,
  headerLineSeperator,
  onRowClick,
  options,
  expandClassName,
  expandingClassName,
  initialSort,
  handleSort,
  paginationOptions,
  hasPagination,
}) => {
  const expand = options?.expand === true && useExpanded
  const sort = options?.sort === true && useSortBy
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: { hiddenColumns, sortBy: initialSort },
      manualSortBy: true,
    },
    sort,
    expand
  )

  const [lastSort, setLastSort] = useState(initialSort)

  useEffect(() => {
    if (sortBy === lastSort) {
      return
    }

    const column = sortBy?.length
      ? columns?.filter((item) => item?.accessor === sortBy[0]?.id)?.at(0)
      : lastSort?.[0]?.id

    if (column?.disableSort) {
      return
    }

    if (typeof handleSort === "function") {
      handleSort(sortBy)
    }

    setLastSort(sortBy)
  }, [sortBy])

  const isFunction = typeof onRowClick === "function"

  const handleRowClick = (row, index) => {
    if (isFunction) {
      onRowClick(row, index)
    }
  }

  const rowClass = isFunction ? "clickable" : ""

  const sortColumnParser = (column) => {
    const sortIcon = column.isSorted
      ? column.isSortedDesc
        ? faArrowDownShortWide
        : faArrowUpWideShort
      : faBars

    const isActive = sortIcon !== faBars && "active-sort"

    return (
      <StyledHeaderIconContainer
        StyledHeaderIconContainer
        className={`header-icon ${isActive}`}>
        <FontAwesomeIcon icon={sortIcon} />
        {column.render("Header")}
      </StyledHeaderIconContainer>
    )
  }

  return (
    <>
      <StyledTable>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(
                      sort
                        ? {
                            ...column?.getSortByToggleProps(),
                            className: column?.headerClassName,
                            style: column?.headerStyle,
                          }
                        : {
                            className: column?.headerClassName,
                            style: column?.headerStyle,
                          }
                    )}>
                    {column?.canSort === true && column?.disableSort !== true
                      ? sortColumnParser(column)
                      : column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row)
              const expandedClass = row?.isExpanded ? expandClassName : ""
              const expandingClass = row?.depth > 0 ? expandingClassName : ""
              return (
                <tr
                  {...row.getRowProps()}
                  className={`${rowClass} ${expandedClass} ${expandingClass}`}
                  onClick={() => handleRowClick(row, i)}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </StyledTable>
      {hasPagination && (
        <Pagination
          {...paginationOptions}
          pageCount={paginationOptions?.pageCount}
          handlePageClick={paginationOptions?.handlePageClick}
          handlePageSizeChange={paginationOptions?.handlePageSizeChange}
          initialPage={paginationOptions?.initialPage}
          initialPageSize={paginationOptions?.initialPageSize}
          pageSizesLabel={paginationOptions?.pageSizesLabel}
        />
      )}
    </>
  )
}

export default Table
