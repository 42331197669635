import React, { useEffect, useRef, useState } from "react"
import {
  CustomModal,
  NewCard,
  Spinner,
  FilterBar,
  Button,
} from "@tmu/components/common"
import { useIntl } from "gatsby-plugin-intl"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import theme from "@tmu/global/theme"
import { useAllCampaignCategories, useCharities } from "@tmu/hooks"
import { getValueForLocale } from "@tmu/utils/string"
import { LIST_TYPES } from "@tmu/apollo/constants"
import {
  StyledCategoryContainer,
  StyledCategoryItem,
  StyledCharityList,
  StyledContent,
  StyledContentContainer,
  StyledFilterContainer,
  StyledHeaderContainer,
  StyledMobileBackground,
  StyledMobileHeaderWrapper,
} from "./index.styles"
import { CampaignListAction } from "../../campaigns/CampaignListing/index.styles"
import { isBrowser } from "@tmu/utils/auth"

const sortingTypes = [
  {
    id: "name,-created",
    label: {
      id: "sorting::alphabeticallyLow",
      defaultMessage: "Alphabetically A-Z",
    },
  },
  {
    id: "-name,-created",
    label: {
      id: "sorting::alphabeticallyHigh",
      defaultMessage: "Alphabetically Z-A",
    },
  },
  {
    id: "fundedAmount,-created",
    label: {
      id: "sorting::donationsLow",
      defaultMessage: "Total raised: low > high",
    },
  },
  {
    id: "-fundedAmount,-created",
    label: {
      id: "sorting::donationsHigh",
      defaultMessage: "Total raised: high > low",
    },
  },
  {
    id: "donation_count,-created",
    label: {
      id: "sorting::donationCountLow",
      defaultMessage: "Donations: low > high",
    },
  },
  {
    id: "-donation_count,-created",
    label: {
      id: "sorting::donationCountHigh",
      defaultMessage: "Donations: high > low",
    },
  },
  {
    id: "-starts_at,-created",
    label: {
      id: "sorting::dateNew",
      defaultMessage: "Date: recent first",
    },
  },
  {
    id: "starts_at,-created",
    label: {
      id: "sorting::dateOld",
      defaultMessage: "Date: old first",
    },
  },
]

const CharitySelector = ({
  isOpen = false,
  onSelect,
  onClose,
  type,
  scrollTopAfterClose,
  ...rest
}) => {
  const { formatMessage, locale, defaultLocale } = useIntl()
  const [modalStatus, setModalStatus] = useState(isOpen)
  const { charities, getCharities, fetchMore, loading } = useCharities()
  const [loadMoreAfter, setLoadMoreAfter] = useState(false)
  const [selectedSortBy, setSelectedSortBy] = useState("-fundedAmount,-created")
  const [selectedCountry, setSelectedCountry] = useState()
  const [searchText, setSearchText] = useState(null)
  const [selectedCategories, setSelectedCategories] = useState([])
  const { allCampaignCategories: categories } = useAllCampaignCategories()
  let searchTimeoutId = null
  let resizeTimeout = null
  const loadRef = useRef()
  const contentRef = useRef()
  const backgroundRef = useRef()
  const mobileHeaderRef = useRef()
  const eligibleCriteria =
    type === "event"
      ? {
          isEligibleForSupporterEvent: true,
        }
      : {
          isEligibleForSupporterCollection: true,
        }
  const { isTablet, isWide } = getAllScreenTypes()
  const filterContainerRef = useRef()

  const [isCharityCheck, setIsCharityCheck] = useState(true)
  const [isCharityCampaignCheck, setIsCharityCampaignCheck] = useState(true)
  const [isSupporterCampaignCheck, setIsSupporterCampaignCheck] = useState(
    rest?.filters?.campaignCheckbox?.supporterCampaign === true
  )

  useEffect(() => {
    window.document.body.style["overflow-y"] = "hidden"
  }, [])

  useEffect(() => {
    setModalStatus(isOpen)
  }, [isOpen])

  const style = isWide
    ? {
        width: "90%",
        height: "90%",
        background: theme.colors.whiteSmoke,
        overflowX: "hidden",
        paddingRight: "4rem",
        paddingLeft: "4rem",
        paddingTop: "1.5rem",
      }
    : isTablet
    ? {
        width: "90%",
        height: "90%",
        background: theme.colors.whiteSmoke,
        overflowX: "hidden",
        paddingRight: "2rem",
        paddingLeft: "2rem",
      }
    : {
        background: theme.colors.whiteSmoke,
        padding: "0",
        overflowX: "hidden",
      }

  const headerText = isTablet ? (
    <div ref={mobileHeaderRef}>
      {formatMessage({
        id: "charitySelector::header",
        defaultMessage: "Choose a charity to support with your campaign",
      })}
    </div>
  ) : (
    <StyledMobileHeaderWrapper ref={mobileHeaderRef}>
      <h2>
        {formatMessage({
          id: "charitySelector::headerMobile",
          defaultMessage: "Choose a charity to support",
        })}
      </h2>
    </StyledMobileHeaderWrapper>
  )

  useEffect(() => {
    let fundraiserType = []

    if (isCharityCheck) {
      fundraiserType.push("charities")
    }

    if (isCharityCampaignCheck) {
      fundraiserType.push("campaigns")
    }

    if (isSupporterCampaignCheck) {
      fundraiserType.push("supporters")
    }

    if (!fundraiserType?.length) {
      fundraiserType = ["charities", "campaigns"]

      if (rest?.filters?.campaignCheckbox?.supporterCampaign === true) {
        fundraiserType.push("supporters")
      }
    }

    getCharities({
      orderBy: selectedSortBy,
      country: selectedCountry,
      search: searchText,
      categories: selectedCategories,
      fundraiserType: fundraiserType,
      ...eligibleCriteria,
    })
  }, [
    selectedSortBy,
    selectedCountry,
    searchText,
    selectedCategories,
    isSupporterCampaignCheck,
    isCharityCampaignCheck,
    isCharityCheck,
  ])

  const closeModal = () => {
    setModalStatus(false)
  }

  useEffect(() => {
    if (!loadRef?.current) {
      return
    }
    let options = {
      threshold: 1,
    }
    const observer = new IntersectionObserver(handleObserver, options)

    if (loadRef?.current) {
      observer?.observe(loadRef?.current)
    }
  }, [loadRef?.current, isTablet, charities?.pageInfo?.hasNextPage])

  // Handle intersection with load more
  const handleObserver = (entities) => {
    const target = entities[0]
    if (target.isIntersecting) {
      setLoadMoreAfter(true)
    }
  }

  useEffect(() => {
    if (loadMoreAfter && charities?.pageInfo?.hasNextPage) {
      handleLoadMore()
      setLoadMoreAfter(false)
    }
  }, [loadMoreAfter, charities?.pageInfo?.hasNextPage])

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        orderBy: selectedSortBy,
        country: selectedCountry,
        search: searchText,
        categories: selectedCategories,
        after: charities?.pageInfo?.endCursor,
        ...eligibleCriteria,
        isPublic: true,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult?.allCampaigns?.edges
        const pageInfo = fetchMoreResult?.allCampaigns?.pageInfo

        return newEdges?.length
          ? {
              // Put the new allCampaigns at the end of the list and update `pageInfo`
              // so we have the new `endCursor` and `hasNextPage` values
              allCampaigns: {
                __typename: prev?.allCampaigns?.__typename,
                edges: [...prev?.allCampaigns?.edges, ...newEdges],
                pageInfo,
              },
            }
          : prev
      },
    })
  }

  const setSorting = (orderBy) => {
    setSelectedSortBy(orderBy?.value)
  }

  const selectCountry = (countryShortCode) => {
    const countryCode = countryShortCode?.id

    setSelectedCountry(countryCode)
  }
  const handleSearch = (e) => {
    const searchValue = e?.target?.value
    clearTimeout(searchTimeoutId)
    searchTimeoutId = setTimeout(() => {
      setSearchText(searchValue)
    }, 1000)
  }

  const selectCategory = (id) => {
    if (selectedCategories.indexOf(id) === -1) {
      if (selectedCategories.length > 2) {
        return
      }
      setSelectedCategories([...selectedCategories, id])
    } else {
      const temp = selectedCategories.filter((item) => item !== id)
      setSelectedCategories(temp)
    }
  }
  if (isBrowser) {
    window.addEventListener("resize", () => {
      setBackgroundHeight()
      clearTimeout(resizeTimeout)
      resizeTimeout = setTimeout(() => {
        setBackgroundHeight()
      }, 500)
    })
  }
  useEffect(() => {
    setBackgroundHeight()
    clearTimeout(resizeTimeout)
    resizeTimeout = setTimeout(() => {
      setBackgroundHeight()
    }, 500)
  }, [
    filterContainerRef.current,
    backgroundRef.current,
    mobileHeaderRef.current,
    charities,
  ])

  const setBackgroundHeight = () => {
    const headerHeight = mobileHeaderRef?.current?.clientHeight || 0
    const filterHeight = filterContainerRef.current?.clientHeight || 0
    if (backgroundRef?.current) {
      const windowWidth = isBrowser ? window.innerWidth : 0
      const heightAddition =
        windowWidth >= 1920 ? 100 : windowWidth >= 768 ? 75 : 70

      backgroundRef.current.style.height =
        filterHeight + headerHeight + heightAddition + "px"
    }

    if (contentRef?.current) {
      const windowHeight = isBrowser ? window.innerHeight : 0
      contentRef.current.style.maxHeight =
        windowHeight - headerHeight - filterHeight - 220 + "px"
    }
  }
  const callAfterClose = () => {
    if (isBrowser) {
      window.document.body.style["overflow-y"] = "unset"
    }
  }
  const modalContent = (
    <StyledContentContainer>
      <StyledMobileBackground ref={backgroundRef} data-testid="background" />
      <StyledHeaderContainer ref={filterContainerRef}>
        <StyledFilterContainer>
          <FilterBar
            handleSort={setSorting}
            handleSelectCountry={selectCountry}
            selectedCountry={selectedCountry}
            isCountryFilterDropdown={isTablet}
            defaultSortValue={selectedSortBy}
            handleSearch={handleSearch}
            sortOptions={sortingTypes}
            categories={categories}
            selected={selectedCategories}
            onSelect={(id) => {
              selectCategory(id)
            }}
            filterType="charity"
            searchFieldName="allCampaigns"
            isCharityCheck={isCharityCheck}
            isCharityCampaignCheck={isCharityCampaignCheck}
            isSupporterCampaignCheck={isSupporterCampaignCheck}
            setIsCharityCheck={setIsCharityCheck}
            setIsCharityCampaignCheck={setIsCharityCampaignCheck}
            setIsSupporterCampaignCheck={setIsSupporterCampaignCheck}
            filters={{
              sort: true,
              search: true,
              country: true,
              hideFundraiser: true,
              campaignTypes: true,
              campaignCheckbox: {
                supporterCampaign:
                  rest?.filters?.campaignCheckbox?.supporterCampaign === true,
              },
              style:
                isTablet && !isWide ? { country: { height: "2.5rem" } } : null,
            }}
          />
        </StyledFilterContainer>
        {isTablet && (
          <StyledCategoryContainer>
            {categories?.map((cat, index) => {
              return (
                <StyledCategoryItem key={index}>
                  <Button
                    className={
                      selectedCategories.indexOf(cat?.id) !== -1 ? "active" : ""
                    }
                    color="transparent"
                    type="button"
                    onClick={() => {
                      selectCategory(cat?.id)
                    }}>
                    {getValueForLocale(cat, "name", locale, defaultLocale)}
                  </Button>
                </StyledCategoryItem>
              )
            })}
          </StyledCategoryContainer>
        )}
      </StyledHeaderContainer>
      <StyledContent ref={contentRef}>
        {loading ? (
          <Spinner condensed />
        ) : (
          <StyledCharityList>
            {charities?.edges?.map(({ node: charity }) => {
              return (
                <NewCard
                  variant={"charityWithCTA"}
                  key={charity?.id}
                  id={charity?.id}
                  imageSrc={charity?.image}
                  title={getValueForLocale(
                    charity,
                    "name",
                    locale,
                    defaultLocale
                  )}
                  type={charity?.campaignType}
                  totalDonors={charity?.donationCount}
                  totalSupport={charity?.fundedAmount}
                  size={LIST_TYPES.LIST}
                  goalAmount={charity?.goalAmount}
                  fundedAmount={charity?.fundedAmount}
                  partnerLogo={getValueForLocale(
                    charity?.partner,
                    "logo",
                    locale,
                    defaultLocale
                  )}
                  partnerName={
                    charity?.partner?.displayName || charity?.partner?.name
                  }
                  taxId={charity?.partner?.taxId}
                  chooseAction={() => {
                    if (typeof onSelect === "function") {
                      onSelect(charity)
                    }
                    closeModal()
                    if (typeof onClose === "function") {
                      onClose()
                    }
                    callAfterClose()
                    if (scrollTopAfterClose) {
                      setTimeout(() => {
                        window?.scrollTo(0, 0)
                      }, 100)
                    }
                  }}
                />
              )
            })}
          </StyledCharityList>
        )}
        <CampaignListAction ref={loadRef}>
          {charities?.pageInfo?.hasNextPage && <Spinner condensed />}
        </CampaignListAction>
      </StyledContent>
    </StyledContentContainer>
  )

  const headerStyle = isTablet
    ? {
        marginTop: "0.5rem",
      }
    : {
        marginTop: "0.5rem",
        paddingRight: "3rem",
        paddingLeft: "1rem",
      }

  return (
    <>
      <CustomModal
        isModalOpen={modalStatus}
        style={style}
        headerStyle={headerStyle}
        header={headerText}
        children={modalContent}
        isCloseIcon={true}
        scrollTopAfterClose={scrollTopAfterClose}
        cancelButtonAction={() => {
          closeModal()
          if (typeof onClose === "function") {
            onClose()
          }
        }}
        isMobile={!isTablet}
      />
    </>
  )
}

export default CharitySelector
