import React, { useEffect, useCallback, useState } from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import { StyledForm } from "@tmu/global/page-addons/dashboard.styles"
import {
  StyledFlexContainer,
  StyledReceiptFormContainer,
  StyledSection,
  StyledSectionTitle,
  StyledSimpleTextContent,
} from "./index.styles"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import {
  Button,
  Dropdown,
  FieldError,
  TextInput,
  PhoneInput,
  BaseError,
} from "@tmu/components/common"
import {
  address,
  firstName,
  displayName,
  country,
  postalCode,
  taxIdRequired,
  phone,
} from "@tmu/utils/validation"
import { useCountries, useAddressValidation } from "@tmu/hooks"
import { Spacer } from "@tmu/global/page-addons/detail-page.styles"
import theme from "@tmu/global/theme"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { useAuth } from "@tmu/hooks"

const ReceiptDetailsForm = ({ data, saveReceiptData }) => {
  const { user } = useAuth()

  const { countryOptions, getCountries } = useCountries()
  const [addressLoading, setAddressLoading] = useState(false)
  const { checkAddress, addressError, setAddressError } = useAddressValidation()
  const { formatMessage } = useIntl()

  const validationSchema = Yup.object().shape({
    firstName: firstName({ formatMessage }),
    lastName: displayName({ formatMessage }),
    addressLine1: address({ formatMessage }),
    country: country({ formatMessage }),
    postCode: postalCode({ formatMessage }),
    taxId: taxIdRequired({ formatMessage }),
    phoneNumber: phone({ formatMessage }),
  })

  const initialValues = {
    ...data,
  }

  useEffect(() => {
    getCountries()
  }, [])

  const { isTablet, isWide } = getAllScreenTypes()

  const handleSubmitData = async (values) => {
    setAddressError()
    setAddressLoading(true)

    const correctedAddressData = await checkAddress(
      values.addressLine1,
      values.postCode,
      values.country
    )
    if (correctedAddressData?.result) {
      setAddressError()
      saveReceiptData({
        ...values,
        city: correctedAddressData.city,
        region: correctedAddressData.region,
        postCode: correctedAddressData.postalCode,
      })
    }
    setAddressLoading(false)
  }

  const selectStyles = !isTablet
    ? {
        control: {
          height: "3rem",
          paddingTop: "0",
        },
        valueContainer: {
          height: "3rem",
          paddingTop: "0",
        },
        inputContainer: {
          height: "3rem",
          paddingTop: "0",
        },
        input: {
          padding: "0",
          margin: "0",
        },
        option: {
          paddingTop: "0",
          paddingBottom: "0",
        },
      }
    : isWide
    ? {
        control: {
          height: "3rem",
          paddingTop: "0",
        },
      }
    : {}

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={validationSchema}
      initialValues={initialValues}
      validateOnMount
      onSubmit={handleSubmitData}>
      {({
        values,
        handleBlur,
        setFieldValue,
        handleChange,
        errors,
        setErrors,
        touched,
        setTouched,
      }) => {
        const currentCountry = useCallback(
          countryOptions?.find((item) => item?.value === values?.country)
        )
        return (
          <StyledForm onSubmit={handleSubmitData}>
            <StyledReceiptFormContainer>
              {addressLoading ? (
                <BaseError
                  message={formatMessage({
                    id: "forms::address::validating",
                    defaultMessage:
                      "Please wait while your address is being validated",
                  })}
                />
              ) : null}
              {addressError ? <BaseError message={addressError} /> : null}
              {user?.accountType === "BUSINESS" ? (
                <>
                  <StyledSection>
                    <StyledSectionTitle>
                      <FormattedMessage
                        id="dashboard::profile::organizationName"
                        defaultMessage="Organization Name"
                      />
                      *
                    </StyledSectionTitle>
                    <Spacer bottom={0.5} />
                    <TextInput
                      newDesign
                      data-testid="firstName"
                      id="firstName"
                      name="firstName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.firstName}
                    />
                    <FieldError name="firstName" />
                  </StyledSection>
                </>
              ) : (
                <>
                  <StyledSection>
                    <StyledSectionTitle>
                      <FormattedMessage
                        id="dashboard::profile::firstName"
                        defaultMessage="First Name"
                      />
                      *
                    </StyledSectionTitle>
                    <Spacer bottom={0.5} />
                    <TextInput
                      newDesign
                      data-testid="firstName"
                      id="firstName"
                      name="firstName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.firstName}
                    />
                    <FieldError name="firstName" />
                  </StyledSection>
                  <Spacer bottom={1} />
                  <StyledSection>
                    <StyledSectionTitle>
                      <FormattedMessage
                        id="dashboard::profile::lastName"
                        defaultMessage="Last Name"
                      />
                      *
                    </StyledSectionTitle>
                    <Spacer bottom={0.5} />
                    <TextInput
                      newDesign
                      data-testid="lastName"
                      id="lastName"
                      name="lastName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.lastName}
                    />
                    <FieldError name="lastName" />
                  </StyledSection>
                </>
              )}
              <Spacer bottom={1} />
              <StyledSection>
                <StyledSectionTitle>
                  <FormattedMessage
                    id="dashboard::profile::address"
                    defaultMessage="address"
                  />
                  *
                </StyledSectionTitle>
                <Spacer bottom={0.5} />
                <TextInput
                  newDesign
                  data-testid="addressLine1"
                  id="addressLine1"
                  name="addressLine1"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.addressLine1}
                />
                <FieldError name="addressLine1" />
              </StyledSection>
              <Spacer bottom={1} />
              <StyledSection>
                <StyledSectionTitle>
                  <FormattedMessage
                    id="dashboard::profile::postCode"
                    defaultMessage="Postal Code"
                  />
                  *
                </StyledSectionTitle>
                <Spacer bottom={0.5} />

                <TextInput
                  newDesign
                  data-testid="postCode"
                  id="postCode"
                  name="postCode"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.postCode}
                />
                <FieldError name="postCode" />
              </StyledSection>
              <Spacer bottom={1} />
              <StyledSection>
                <StyledSectionTitle>
                  <FormattedMessage
                    id="dashboard::profile::country"
                    defaultMessage="country"
                  />
                  *
                </StyledSectionTitle>
                <Spacer bottom={0.5} />
                <Dropdown
                  newDesign
                  data-testid="country"
                  id="country"
                  name="country"
                  onBlur={handleBlur}
                  value={currentCountry}
                  options={countryOptions}
                  defaultSelectStyles={selectStyles}
                  onChange={(selected) => {
                    setFieldValue("country", selected?.value, true)
                    setTouched({ ...touched, country: true }, true)
                  }}
                />
                <FieldError name="country" />
              </StyledSection>

              <Spacer bottom={1} />
              <StyledSection>
                <StyledSectionTitle>
                  <FormattedMessage
                    id="dashboard::profile::taxId"
                    defaultMessage="tax id/vat"
                  />
                  *
                </StyledSectionTitle>
                <Spacer bottom={0.5} />
                <TextInput
                  newDesign
                  data-testid="taxId"
                  id="taxId"
                  name="taxId"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.taxId}
                />
                <FieldError name="taxId" />
              </StyledSection>
              <Spacer bottom={1} />
              <StyledSection>
                <PhoneInput
                  title={
                    <FormattedMessage
                      id="dashboard::profile::phone"
                      defaultMessage="phone number"
                    />
                  }
                  name="phoneNumber"
                  isRequired={true}
                  value={values?.phoneNumber || ""}
                  errors={errors}
                  touched={touched}
                  onChange={(phone) =>
                    setFieldValue("phoneNumber", phone, true)
                  }
                  onBlur={() => setTouched({ ...touched, phoneNumber: true })}
                />
              </StyledSection>
              <Spacer bottom={1.2} />
              <StyledSection>
                <StyledSectionTitle>
                  <FormattedMessage
                    id="dashboard::profile::email"
                    defaultMessage="email"
                  />
                </StyledSectionTitle>
                <Spacer bottom={0.5} />
                <StyledSimpleTextContent>{data?.email}</StyledSimpleTextContent>
              </StyledSection>

              <Spacer bottom={2.5} />
            </StyledReceiptFormContainer>
            <StyledSection>
              <StyledFlexContainer>
                <Button
                  data-testid="btn-save"
                  className="blue-button button-margin"
                  color={theme.colors.pacificBlue}
                  type="button"
                  disabled={Object.keys(errors)?.length > 0}
                  onClick={() => {
                    handleSubmitData(values)
                  }}>
                  <FormattedMessage
                    id="dashboard::profile::save"
                    defaultMessage="Save"
                  />
                </Button>
              </StyledFlexContainer>
            </StyledSection>
            <Spacer bottom={0.5} />
          </StyledForm>
        )
      }}
    </Formik>
  )
}

export default ReceiptDetailsForm
