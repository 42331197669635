import React, { useRef, useImperativeHandle, forwardRef } from "react"
import PropTypes from "prop-types"
import {
  StyledFilterBar,
  StyledRightGroup,
  StyledLeftGroup,
  StyledSelectorsWrapper,
  StyledCategoryFilterWrapper,
  StoreFilterWrapper,
  StyledCaption,
  StyledCampaignTypes,
  StyledHorizontalContainer,
  StyledWrapper,
  StyledBlocker,
} from "./index.styles"
import {
  SearchBoxFilter,
  SortSelector,
  ListTypeSwitcher,
  CountrySelectorFilter,
  ListingFilters,
  StoreFilter,
  OnlineOfflineMerchantFilter,
  CategorySubcategorySelect,
  Checkbox,
} from "@tmu/components/common"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { Spacer } from "@tmu/src/global/page-addons/detail-page.styles"

const FilterBar = forwardRef(
  (
    {
      handleSearch,
      handleSort,
      handleListType,
      handleSelectCountry,
      selectedCountry,
      selectedCity,
      handleStoreFilterChange,
      sortOptions,
      defaultSortValue,
      categories,
      selected,
      onSelect,
      onSingleCategorySelect,
      handleShowAll,
      setEventsFilter,
      isEventsFilter,
      searchFieldName,
      isOfferList,
      storeList,
      filters,
      selectedCategories,
      setSelectedCategories,
      isOnlyPACFilter,
      setOnlyPACFilter,
      isSupporterFilter,
      setIsSupporterFilter,
      isOnlyCorporateFilter,
      setIsOnlyCorporateFilter,
      isCharitiesFilter,
      setCharitiesFilter,
      isCampaignsFilter,
      setCampaignsFilter,
      handleTypeChange,
      isStoresFilter,
      setStoresFilter,
      isOffersFilter,
      isComingSoonFilter,
      setOffersFilter,
      storeFilterDefaultValue,
      setComingSoonFilter,
      isCountryFilterDropdown = false,
      onMerchantTypeChange,
      isOnlineMerchant,
      withTitles,
      onSliderChange,
      sliderDefaultValue,
      merchantType,
      handlePriceSliderChange,
      minMaxPrice,
      filterType,
      isDonationPage = false,
      isCharityCheck = true,
      isCharityCampaignCheck = true,
      isSupporterCampaignCheck = true,
      queryFilters = {},
      disabled,
      ...rest
    },
    ref
  ) => {
    const { isTablet, isDesktop, isWide } = getAllScreenTypes()
    const isMobile = !isWide && !isDesktop && !isTablet

    useImperativeHandle(ref, () => ({
      handleSearchFethMore: (value) => handleSearchFethMore(value),
    }))

    const { formatMessage } = useIntl()
    const searchBoxRef = useRef()

    const handleSortAction = (values) => {
      if (handleSort) {
        handleSort(values)
      }
    }

    const handleListTypeAction = (listType) => {
      if (handleListType) {
        handleListType(listType)
      }
    }

    const handleSelectCountryAction = (country) => {
      if (handleSelectCountry) {
        handleSelectCountry(country)
      }
    }

    const handleSearchFethMore = () => {
      searchBoxRef?.current?.handleFetchMore()
    }

    const sortingTypes = sortOptions || [
      {
        id: "-starts_at,-created",
        label: {
          id: "sorting::recent",
          defaultMessage: "Recent",
        },
      },
      {
        id: "name,-created",
        label: {
          id: "sorting::alphabetically",
          defaultMessage: "Alphabetically",
        },
      },
      {
        id: "-fundedAmount,-created",
        label: {
          id: "sorting::fundedAmount",
          defaultMessage: "Donated Amount",
        },
      },
    ]

    const sortOptionsData = sortingTypes?.map(({ id, label }) => ({
      value: id,
      label: formatMessage(label),
    }))

    return (
      <StyledWrapper>
        {disabled && <StyledBlocker />}
        <StyledFilterBar isOfferList={isOfferList} filterType={filterType}>
          <StyledLeftGroup isOfferList={isOfferList} filterType={filterType}>
            <StyledSelectorsWrapper>
              {filters?.country && (
                <CountrySelectorFilter
                  isOfferList={isOfferList}
                  handleSelect={handleSelectCountryAction}
                  activeCountry={selectedCountry}
                  activeCity={selectedCity}
                  isDropdown={isCountryFilterDropdown}
                  searchable={isOfferList}
                  customStyle={filters?.style?.country}
                />
              )}
              {filters?.category && (
                <StyledCategoryFilterWrapper>
                  {withTitles && (
                    <StyledCaption>
                      <FormattedMessage
                        id="listing::filters::pickCategory"
                        defaultMessage="Pick a Category"
                      />
                    </StyledCaption>
                  )}
                  <CategorySubcategorySelect
                    handleShowAll={handleShowAll}
                    categories={categories}
                    selected={selected}
                    onSelect={onSingleCategorySelect}
                    onSingleCategorySelect={onSingleCategorySelect}
                    isDropdown={true}
                  />
                </StyledCategoryFilterWrapper>
              )}

              <ListingFilters
                className="mobile-filter"
                handleSelect={handleSelectCountryAction}
                categories={categories}
                selected={selected}
                onSelect={onSelect}
                onSingleCategorySelect={onSingleCategorySelect}
                handleShowAll={handleShowAll}
                defaultSortValue={defaultSortValue}
                sortOptions={sortOptionsData}
                handleSortAction={handleSortAction}
                setEventsFilter={setEventsFilter}
                isEventsFilter={isEventsFilter}
                isOfferList={isOfferList}
                selectedCategories={selectedCategories}
                setSelectedCategories={setSelectedCategories}
                isOnlyPACFilter={isOnlyPACFilter}
                setOnlyPACFilter={setOnlyPACFilter}
                isSupporterFilter={isSupporterFilter}
                setIsSupporterFilter={setIsSupporterFilter}
                isOnlyCorporateFilter={isOnlyCorporateFilter}
                setIsOnlyCorporateFilter={setIsOnlyCorporateFilter}
                isCharitiesFilter={isCharitiesFilter}
                setCharitiesFilter={setCharitiesFilter}
                isCampaignsFilter={isCampaignsFilter}
                setCampaignsFilter={setCampaignsFilter}
                handleTypeChange={handleTypeChange}
                isStoresFilter={isStoresFilter}
                setStoresFilter={setStoresFilter}
                isOffersFilter={isOffersFilter}
                setOffersFilter={setOffersFilter}
                isComingSoonFilter={isComingSoonFilter}
                setComingSoonFilter={setComingSoonFilter}
                storeFilterDefaultValue={storeFilterDefaultValue}
                handleStoreFilterChange={handleStoreFilterChange}
                filters={filters}
                isOnlineMerchant={isOnlineMerchant}
                onSliderChange={onSliderChange}
                sliderDefaultValue={sliderDefaultValue}
                merchantType={merchantType}
                handlePriceSliderChange={handlePriceSliderChange}
                minMaxPrice={minMaxPrice}
                isDonationPage={isDonationPage}
                isCharityCheck={isCharityCheck}
                isCharityCampaignCheck={isCharityCampaignCheck}
                isSupporterCampaignCheck={isSupporterCampaignCheck}
                queryFilters={queryFilters}
                {...rest}
              />
            </StyledSelectorsWrapper>

            {isOfferList && !isTablet ? (
              <StoreFilterWrapper>
                <StoreFilter
                  isMobile="true"
                  defaultValue={storeFilterDefaultValue}
                  handleChange={handleStoreFilterChange}
                  merchantType={merchantType}
                  filters={queryFilters}
                />
              </StoreFilterWrapper>
            ) : null}
          </StyledLeftGroup>

          <StyledRightGroup
            isOfferList={isOfferList}
            filters={filters}
            filterType={filterType}
            withCampaignTypes={filters?.campaignTypes}>
            {filters?.onlineOflineMerchant && (
              <div>
                {withTitles && (
                  <StyledCaption>
                    <FormattedMessage
                      id="listing::filters::offerTypeTitle"
                      defaultMessage="Type of Offer"
                    />
                  </StyledCaption>
                )}
                <OnlineOfflineMerchantFilter
                  onChange={onMerchantTypeChange}
                  isOnlineMerchant={isOnlineMerchant}
                />
              </div>
            )}
            {filters?.store && !isMobile && (
              <div>
                {withTitles && (
                  <StyledCaption>
                    <FormattedMessage
                      id="listing::filters::storeFilterTitle"
                      defaultMessage="Store"
                    />
                  </StyledCaption>
                )}
                <StoreFilter
                  handleChange={handleStoreFilterChange}
                  options={storeList}
                  defaultValue={storeFilterDefaultValue}
                  merchantType={merchantType}
                  hideShowAll={true}
                  filters={queryFilters}
                />
              </div>
            )}
            {filters?.search && (
              <SearchBoxFilter
                ref={searchBoxRef}
                className="search-box"
                fieldName={searchFieldName}
                handleSearch={handleSearch}
              />
            )}

            {isDesktop === true && filters?.listType && (
              <ListTypeSwitcher handleListType={handleListTypeAction} />
            )}

            {!isMobile && filters?.sort && !filters?.campaignTypes && (
              <SortSelector
                defaultValue={defaultSortValue}
                options={sortOptionsData}
                handleSort={handleSortAction}
              />
            )}
          </StyledRightGroup>
        </StyledFilterBar>
        {!isMobile && filters?.campaignTypes && (
          <>
            <Spacer top={2} />
            <StyledHorizontalContainer>
              <StyledCampaignTypes>
                {filters?.campaignCheckbox?.charity !== false && (
                  <Checkbox
                    className="filter-checkbox"
                    label={
                      <FormattedMessage
                        id="offerListing::campaigns::charities"
                        defaultMessage="Charities"
                      />
                    }
                    isChecked={isCharityCheck}
                    onChange={() => {
                      rest?.setIsCharityCheck(!isCharityCheck)
                    }}
                  />
                )}
                {filters?.campaignCheckbox?.charityCampaign !== false && (
                  <Checkbox
                    className="filter-checkbox"
                    label={
                      <FormattedMessage
                        id="offerListing::campaigns::charityCampaigns"
                        defaultMessage="Charity Campaigns"
                      />
                    }
                    isChecked={isCharityCampaignCheck}
                    onChange={() => {
                      rest?.setIsCharityCampaignCheck(!isCharityCampaignCheck)
                    }}
                  />
                )}
                {filters?.campaignCheckbox?.supporterCampaign !== false && (
                  <Checkbox
                    className="filter-checkbox"
                    label={
                      <FormattedMessage
                        id="offerListing::campaigns::supporterCampaigns"
                        defaultMessage="Supporter Campaigns"
                      />
                    }
                    isChecked={isSupporterCampaignCheck}
                    onChange={() => {
                      rest?.setIsSupporterCampaignCheck(
                        !isSupporterCampaignCheck
                      )
                    }}
                  />
                )}
              </StyledCampaignTypes>

              {filters?.sort && (
                <SortSelector
                  defaultValue={defaultSortValue}
                  options={sortOptionsData}
                  handleSort={handleSortAction}
                />
              )}
            </StyledHorizontalContainer>
          </>
        )}
      </StyledWrapper>
    )
  }
)

export default FilterBar

FilterBar.propTypes = {
  handleSearch: PropTypes.func,
  handleSort: PropTypes.func,
  handleListType: PropTypes.func,
  handleSelectCountry: PropTypes.func,
  handleStoreFilterChange: PropTypes.func,
  sortOptions: PropTypes.array,
  searchFieldName: PropTypes.string,
  isOfferList: PropTypes.boolean,
  storeList: PropTypes.array,
  filters: PropTypes.object,
  storeFilterDefaultValue: PropTypes.array,
}
