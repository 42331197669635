import React from "react"
import theme from "@tmu/global/theme"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { graphql, useStaticQuery } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import { Dropdown } from "@tmu/components/common"
import { useAuth } from "@tmu/hooks"

const { CLOUDFLARE_IMAGE_URL } = process.env

const HeaderLanguage = ({ whiteStyle }) => {
  const { locale } = useIntl()
  const { updateCommunicationLanguage } = useAuth()
  const { isWide } = getAllScreenTypes()

  const langugeImageSize = isWide ? "24" : "20"

  const {
    site: {
      siteMetadata: { supportedLanguages },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            supportedLanguages {
              id
              label
            }
          }
        }
      }
    `
  )

  const handleChangeLanguage = (e) => {
    updateCommunicationLanguage({
      variables: {
        language: e,
      },
    })
  }

  const languageSection = supportedLanguages.map((key) => {
    let icon
    switch (key.id) {
      case "it":
        icon = (
          <img
            src={CLOUDFLARE_IMAGE_URL + "/static/assets/images/svg/italy.svg"}
            alt="Italy Flag"
            width={langugeImageSize}
            height={langugeImageSize}
          />
        )

        break
      case "es":
        icon = (
          <img
            src={CLOUDFLARE_IMAGE_URL + "/static/assets/images/svg/spain.svg"}
            alt="Spain Flag"
            width={langugeImageSize}
            height={langugeImageSize}
          />
        )
        break
      default:
        icon = (
          <img
            src={
              CLOUDFLARE_IMAGE_URL +
              "/static/assets/images/svg/united-kingdom.svg"
            }
            alt="England Flag"
            width={langugeImageSize}
            height={langugeImageSize}
          />
        )
    }
    return {
      id: `subtitle-language-${key.id}`,
      language: key.id,
      label: (
        <div className={`languages-header`}>
          {icon} {`${key.id.charAt(0).toUpperCase()}${key.id.slice(1)}`}
        </div>
      ),
    }
  })

  let defaultLanguage = languageSection.filter(
    (language) => language.language === locale
  )[0].language

  return (
    <Dropdown
      data-testid="header-change-language"
      variant="pill"
      isSearchable={false}
      placeholder={defaultLanguage}
      classNamePrefix="react-select"
      defaultSelectStyles={{
        container: {},
        option: {
          color: "black !important",
        },
        menuList: {
          paddingTop: "0",
          paddingBottom: "0",
          classNamePrefix: "language-menu-list",
        },
        control: {
          background: "none",
          border: "none",
          boxShadow: "none",
          outline: "none",
          minWidth: "6.25rem",
          fontSize: isWide ? "1.5rem" : "1rem",
        },
        indicatorsContainer: {
          position: "absolute",
          paddingLeft: "3.4375rem",
        },
        valueContainer: {
          marginTop: isWide ? "" : "0.2rem",
          color: whiteStyle?.length ? "white" : "black",
        },
        singleValue: {
          color: "",
        },
        dropdownIndicator: {
          marginLeft: isWide ? "1rem" : "",
          marginTop: isWide ? "0.2rem" : "",
          "&:hover": {
            color: theme?.colors?.pitchBlack,
          },
          "&": {
            color: whiteStyle?.length > 0 ? theme?.colors?.white : "",
          },
        },
        placeholder: {
          color:
            whiteStyle?.length > 0
              ? theme?.colors?.white
              : theme?.colors?.pitchBlack,
        },
      }}
      options={languageSection.map(({ label, language }) => ({
        value: language,
        label,
      }))}
      defaultValue={defaultLanguage}
      onChange={(evt) => handleChangeLanguage(evt)}
    />
  )
}

export default HeaderLanguage
