import React, { useState } from "react"
import { FastField, useFormikContext } from "formik"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import {
  TextInput,
  Dropdown,
  FieldError,
  CurrencyInput,
  DatePicker,
  TextEditor,
  Tabs,
  TabList,
  Panel,
} from "@tmu/components/common"
import { VideoSource } from "@tmu/components/dashboard/dashboardCommon"
import { useAllCampaignCategories, useTypeConstants } from "@tmu/hooks"
import {
  StyledFormSectionTitle,
  StyledFormSectionHelpText,
} from "@tmu/global/page-addons/dashboard.styles"
import {
  StyledCampaignTypeSection,
  StyledFieldHelpText,
  StyledGoalFields,
} from "../index.styles"
import { Tab } from "@tmu/components/dashboard/dashboardCommon"
import { REVIEW_STATUS, FIELD_LENGTHS } from "@tmu/apollo/constants"
import { formatDate } from "@tmu/utils/date"

function Basics({
  activeLangs,
  setActiveLangs,
  campaignType,
  setCampaignType,
  status,
}) {
  const { formatMessage } = useIntl()
  const { campaignTypes } = useTypeConstants({})
  const [tabIndex, setTabIndex] = useState(0)

  const campaignTypeOptions = [
    {
      value: campaignTypes[1]?.id,
      label: formatMessage({
        id: "dashboard::campaignForm::goalBased",
        defaultMessage: "Goal Based",
      }),
    },
    {
      value: campaignTypes[2]?.id,
      label: formatMessage({
        id: "dashboard::campaignForm::openCampaign",
        defaultMessage: "Open Campaign",
      }),
    },
    {
      value: campaignTypes[3]?.id,
      label: formatMessage({
        id: "dashboard::campaignForm::timeLimited",
        defaultMessage: "Time Limited",
      }),
    },
  ]

  const {
    values,
    setFieldValue,
    setValues,
    setTouched,
    touched,
    handleBlur,
    setFieldTouched,
  } = useFormikContext()

  const { allCampaignCategories } = useAllCampaignCategories()
  const handleChange = (key, value) => {
    if (typeof key === "string") {
      return setFieldValue(key, value, true)
    }
    return setValues(
      {
        ...values,
        ...key,
      },
      true
    )
  }

  const CampaignNameText = (
    <StyledFieldHelpText>
      <FormattedMessage
        id="dashboard::campaignForm::campaignFormDescription"
        defaultMessage="Make a good first impression: introduce your project objectives and entice people to learn more. This basic information will represent your project on your project page, on your project card, and in searches."
      />
    </StyledFieldHelpText>
  )
  const CampaignDescText = (
    <StyledFieldHelpText>
      <FormattedMessage
        id="dashboard::campaignForm::shortDescFieldDesc"
        defaultMessage="Describe your campaign in a few lines:"
      />
    </StyledFieldHelpText>
  )
  const CampaignStoryText = (
    <>
      <StyledFormSectionTitle>
        <FormattedMessage
          id="dashboard::campaignForm::descriptionTitle"
          defaultMessage="Your Story"
        />
        *
      </StyledFormSectionTitle>
      <StyledFormSectionHelpText>
        <FormattedMessage
          id="dashboard::campaignForm::descriptionDesc"
          defaultMessage="Tell potential supporters more about your campaign. Provide details that will motivate people to contribute. A good pitch is compelling, informative, and easy to digest."
        />
      </StyledFormSectionHelpText>
    </>
  )
  const CampaignName = formatMessage({
    id: "dashboard::campaignForm::nameFieldTitle",
    defaultMessage: "Campaign Name",
  })
  const CampaignShortDescription = formatMessage({
    id: "dashboard::campaignForm::descFieldTitle",
    defaultMessage: "Short Description",
  })
  const CampaignStory = formatMessage({
    id: "dashboard::campaignForm::descFieldTitle",
    defaultMessage: "Campaign Description",
  })
  return (
    <>
      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList>
          <Tab
            isActive={tabIndex === 0}
            activeLangs={activeLangs}
            setActiveLangs={setActiveLangs}>
            <FormattedMessage
              id="dashboard::campaignForm::English"
              defaultMessage="English"
            />
          </Tab>
          <Tab
            isActive={tabIndex === 1}
            activeLangs={activeLangs}
            setActiveLangs={setActiveLangs}>
            <FormattedMessage
              id="dashboard::campaignForm::Italian"
              defaultMessage="Italian"
            />
          </Tab>
          <Tab
            isActive={tabIndex === 2}
            activeLangs={activeLangs}
            setActiveLangs={setActiveLangs}>
            <FormattedMessage
              id="dashboard::campaignForm::Spanish"
              defaultMessage="Spanish"
            />
          </Tab>
        </TabList>
        <Panel>
          {CampaignNameText}
          <FastField
            data-testid="input-nameEn"
            id="nameTabEn"
            name="nameTabEn"
            value={values.nameTabEn}
            component={TextInput}
            label={`${CampaignName}*`}
            className="float-container"
            placeholder=""
            onBlur={handleBlur}
            maxLength={FIELD_LENGTHS.name}
            onChange={(evt) => handleChange("nameTabEn", evt.target.value)}
          />
          <FieldError
            name="nameTabEn"
            autoFocus={true}
            scrollOpts={{ block: "center", behavior: "smooth" }}
          />
          {CampaignDescText}
          <br />
          <FastField
            data-testid="input-shortDescriptionEn"
            id="shortDescriptionTabEn"
            name="shortDescriptionTabEn"
            label={`${CampaignShortDescription}*`}
            type="textarea"
            component={TextInput}
            className="float-container info-area full-width"
            value={values.shortDescriptionTabEn}
            maxLength={FIELD_LENGTHS.shortDescription}
            onBlur={handleBlur}
            onChange={(evt) =>
              handleChange("shortDescriptionTabEn", evt.target.value)
            }
            placeholder=""
          />
          <FieldError
            name="shortDescriptionTabEn"
            autoFocus={true}
            scrollOpts={{ block: "center", behavior: "smooth" }}
          />
          <hr />
          {CampaignStoryText}
          <TextEditor
            data-testid="input-descriptionEn"
            name="descriptionTabEn"
            label={`${CampaignStory}*`}
            className="float-container info-area full-width"
            value={values.descriptionTabEn}
            onBlur={() => setTouched({ ...touched, descriptionTabEn: true })}
            onChange={(value) => handleChange("descriptionTabEn", value)}
            placeholder=""
          />
          <FieldError
            name="descriptionTabEn"
            autoFocus={true}
            scrollOpts={{ block: "end", behavior: "smooth" }}
          />
        </Panel>
        <Panel>
          {CampaignNameText}
          <FastField
            data-testid="input-nameIt"
            id="nameTabIt"
            name="nameTabIt"
            value={values.nameTabIt}
            component={TextInput}
            label={`${CampaignName}*`}
            className="float-container"
            placeholder=""
            onBlur={handleBlur}
            onChange={(evt) => handleChange("nameTabIt", evt.target.value)}
          />
          <FieldError
            name="nameTabIt"
            autoFocus={true}
            scrollOpts={{ block: "center", behavior: "smooth" }}
          />
          {CampaignDescText}
          <br />
          <FastField
            data-testid="input-shortDescriptionIt"
            id="shortDescriptionTabIt"
            name="shortDescriptionTabIt"
            label={`${CampaignShortDescription}*`}
            type="textarea"
            component={TextInput}
            className="float-container info-area full-width"
            value={values.shortDescriptionTabIt}
            onBlur={handleBlur}
            onChange={(evt) =>
              handleChange("shortDescriptionTabIt", evt.target.value)
            }
            placeholder=""
          />
          <FieldError
            name="shortDescriptionTabIt"
            autoFocus={true}
            scrollOpts={{ block: "center", behavior: "smooth" }}
          />
          <hr />
          {CampaignStoryText}
          <TextEditor
            data-testid="input-descriptionIt"
            name="descriptionTabIt"
            label={`${CampaignStory}*`}
            className="float-container info-area full-width"
            value={values.descriptionTabIt}
            onBlur={handleBlur}
            onChange={(value) => handleChange("descriptionTabIt", value)}
            placeholder=""
          />
          <FieldError
            name="descriptionTabIt"
            autoFocus={true}
            scrollOpts={{ block: "end", behavior: "smooth" }}
          />
        </Panel>
        <Panel>
          {CampaignNameText}
          <FastField
            data-testid="input-nameEs"
            id="nameTabEs"
            name="nameTabEs"
            value={values.nameTabEs}
            component={TextInput}
            label={`${CampaignName}*`}
            className="float-container"
            placeholder=""
            onBlur={handleBlur}
            onChange={(evt) => handleChange("nameTabEs", evt.target.value)}
          />
          <FieldError
            name="nameTabEs"
            autoFocus={true}
            scrollOpts={{ block: "center", behavior: "smooth" }}
          />
          {CampaignDescText}
          <br />
          <FastField
            data-testid="input-shortDescriptionEs"
            id="shortDescriptionTabEs"
            name="shortDescriptionTabEs"
            label={`${CampaignShortDescription}*`}
            type="textarea"
            component={TextInput}
            className="float-container info-area full-width"
            value={values.shortDescriptionTabEs}
            onBlur={handleBlur}
            onChange={(evt) =>
              handleChange("shortDescriptionTabEs", evt.target.value)
            }
            placeholder=""
          />
          <FieldError
            name="shortDescriptionTabEs"
            autoFocus={true}
            scrollOpts={{ block: "center", behavior: "smooth" }}
          />
          <hr />
          {CampaignStoryText}
          <TextEditor
            data-testid="input-descriptionEs"
            name="descriptionTabEs"
            label={`${CampaignStory}*`}
            className="float-container info-area full-width"
            value={values.descriptionTabEs}
            onBlur={handleBlur}
            onChange={(value) => handleChange("descriptionTabEs", value)}
            placeholder=""
          />
          <FieldError
            name="descriptionTabEs"
            autoFocus={true}
            scrollOpts={{ block: "end", behavior: "smooth" }}
          />
        </Panel>
      </Tabs>

      <hr />
      <StyledCampaignTypeSection
        isDisabled={status && status !== REVIEW_STATUS.IN_PROGRESS}>
        <StyledGoalFields>
          <Dropdown
            data-testid="campaign-type-select"
            id="campaignType"
            name="campaignType"
            isClearable={false}
            isSearchable={false}
            components={{
              ClearIndicator: null,
            }}
            className="select-field custom-select"
            options={campaignTypeOptions}
            value={
              campaignTypeOptions.filter(
                (type) => type.value === campaignType
              ) || campaignTypeOptions[0]
            }
            onChange={(type) => {
              setFieldValue("campaignType", type?.value, true)
              setTouched({ ...touched, campaignType: true })
              setCampaignType(type?.value)
            }}
            label={`${formatMessage({
              id: "dashboard::campaignForm::campaignType",
              defaultMessage: "Campaign Type",
            })}*`}
          />
          <FieldError name="campaignType" autoFocus={true} />
          <div>
            {campaignType === campaignTypes[1]?.id && (
              <div>
                <CurrencyInput
                  id="goalAmount"
                  name="goalAmount"
                  amount={Number(values.goalAmount) || ""}
                  label={formatMessage({
                    id: "dashboard::campaignForm::goalAmountFieldTitle",
                    defaultMessage: "Goal Amount",
                  })}
                  className="float-container"
                  onValueChange={({ value }) =>
                    setFieldValue("goalAmount", Number(value))
                  }
                  onFocus={() => setFieldTouched("goalAmount", true)}
                  onBlur={handleBlur}
                />
                <FieldError name="goalAmount" autoFocus={true} />
              </div>
            )}
          </div>
        </StyledGoalFields>
        <DatePicker
          showRange={campaignType === campaignTypes[3]?.id}
          onDateChange={(date) => {
            setFieldValue("startsAt", formatDate(date[0], "yyyy-MM-dd"), true)
            setFieldValue("deadline", formatDate(date[1], "yyyy-MM-dd"), true)
          }}
          placeholderText={formatMessage({
            id: "dashboard::campaignForm::startEndDate",
            defaultMessage: "Start - End Date",
          })}
        />
        <FieldError name="startsAt" autoFocus={true} />
        <FieldError name="deadline" autoFocus={true} />
        <hr />
        <StyledFormSectionHelpText>
          <FormattedMessage
            id="dashboard::campaignForm::categoryDesc"
            defaultMessage="To help supporters find your project, select the categories that best represent it."
          />
        </StyledFormSectionHelpText>
        <div data-testid="input-categories">
          <Dropdown
            isMulti={true}
            placeholder={formatMessage({
              id: "dashboard::campaignForm::category",
              defaultMessage: "Category",
            })}
            onBlur={handleBlur}
            options={allCampaignCategories?.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            defaultValue={values.categories}
            onChange={(categories) => {
              handleChange(
                "categories",
                categories && categories.map(({ value }) => value)
              )
            }}
          />
          <FieldError name="categories" autoFocus={true} />
        </div>
      </StyledCampaignTypeSection>

      <hr />
      <StyledFormSectionTitle>
        <FormattedMessage
          id="dashboard::campaignForm::videoTitle"
          defaultMessage="Campaign Video"
        />
      </StyledFormSectionTitle>
      <StyledFormSectionHelpText>
        <FormattedMessage
          id="dashboard::campaignForm::videoDesc"
          defaultMessage="Add a video to appear on the top of your campaign page. Keep your video 2-3 minutes."
        />
      </StyledFormSectionHelpText>
      <VideoSource
        videoUrl={values.video}
        onChange={(value) => handleChange("video", value)}
      />
      <FieldError name="video" autoFocus={true} />
    </>
  )
}

export default Basics
