export function clean(obj) {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName]
    }
  }
}

export function isErrorObject(error) {
  if (error && error.stack && error.message) {
    return true
  }

  return false
}
