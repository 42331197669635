import React from "react"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { Form, useFormikContext } from "formik"
import { Button, FieldError, TextEditor } from "@tmu/components/common"
import theme from "@tmu/global/theme"

import {
  StyledProfileFlagRowContent,
  StyledProfileFlexContainer,
  StyledProfileRowContent,
  StyledProfileSection,
  StyledProfileSpacer,
  StyledVisibleContainer,
} from "./index.styles"

const { CLOUDFLARE_IMAGE_URL } = process.env

const TellAboutProfileForm = ({ isEdit, onCancel, onSave }) => {
  const {
    errors,
    initialValues,
    values,
    touched,
    setTouched,
    setFieldValue,
    handleChange,
    handleBlur,
  } = useFormikContext()

  const { locale } = useIntl()

  const getLanguageImage = (suffix) => {
    return (
      <img
        src={CLOUDFLARE_IMAGE_URL + "/static/assets/images/svg/" + suffix}
        alt={`mai-privacy-image`}
        width={20}
        height={13}
      />
    )
  }

  return (
    <Form className="tell-about-profile-form">
      <fieldset>
        <StyledProfileRowContent>
          <StyledVisibleContainer className="inner align-top">
            <StyledProfileFlagRowContent>
              {getLanguageImage("united-kingdom.svg")}
            </StyledProfileFlagRowContent>
            <StyledProfileSection
              className={`${
                !isEdit
                  ? `tell-about-short-description`
                  : isEdit && locale === "en" && Object.keys(errors)?.length
                  ? `tell-about-short-description-show-error`
                  : `tell-about-short-description-hide-error`
              }`}>
              {isEdit ? (
                <>
                  <TextEditor
                    data-testid="shortDescriptionEn"
                    id="shortDescriptionEn"
                    name="shortDescriptionEn"
                    value={values?.shortDescriptionEn}
                    onBlur={() =>
                      setTouched({ ...touched, shortDescriptionEn: true })
                    }
                    onChange={(value) =>
                      setFieldValue("shortDescriptionEn", value, true)
                    }
                    className={
                      !!errors["shortDescriptionEn"] &&
                      !!touched["shortDescriptionEn"]
                        ? "text-editor-error"
                        : "unset"
                    }
                  />
                  <FieldError
                    name={"shortDescriptionEn"}
                    autoFocus={true}
                    scrollOpts={{ block: "end", behavior: "smooth" }}
                    error={errors}
                    touched={touched}
                  />
                </>
              ) : initialValues?.shortDescriptionEn.length ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: initialValues?.shortDescriptionEn ?? "",
                  }}
                />
              ) : (
                <FormattedMessage
                  id="dashboard::forms::storeInformationEnglish"
                  defaultMessage="You have not filled the store information in english"
                />
              )}
            </StyledProfileSection>
          </StyledVisibleContainer>
          <StyledVisibleContainer className="inner align-top">
            <StyledProfileFlagRowContent>
              {getLanguageImage("italy.svg")}
            </StyledProfileFlagRowContent>
            <StyledProfileSection
              className={`${
                !isEdit
                  ? `tell-about-short-description`
                  : isEdit && locale === "it" && Object.keys(errors)?.length
                  ? `tell-about-short-description-show-error`
                  : `tell-about-short-description-hide-error`
              }`}>
              {isEdit ? (
                <>
                  <TextEditor
                    data-testid="shortDescriptionIt"
                    id="shortDescriptionIt"
                    name="shortDescriptionIt"
                    value={values?.shortDescriptionIt}
                    onBlur={() =>
                      setTouched({ ...touched, shortDescriptionIt: true })
                    }
                    onChange={(value) =>
                      setFieldValue("shortDescriptionIt", value, true)
                    }
                    className={
                      !!errors["shortDescriptionIt"] &&
                      !!touched["shortDescriptionIt"]
                        ? "text-editor-error"
                        : "unset"
                    }
                  />
                  <FieldError
                    name={"shortDescriptionIt"}
                    autoFocus={true}
                    scrollOpts={{ block: "end", behavior: "smooth" }}
                    error={errors}
                    touched={touched}
                  />
                </>
              ) : initialValues?.shortDescriptionIt.length ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: initialValues?.shortDescriptionIt ?? "",
                  }}
                />
              ) : (
                <FormattedMessage
                  id="dashboard::forms::storeInformationItalian"
                  defaultMessage="You have not filled the store information in italian"
                />
              )}
            </StyledProfileSection>
          </StyledVisibleContainer>
          <StyledVisibleContainer className="inner align-top">
            <StyledProfileFlagRowContent>
              {getLanguageImage("spain.svg")}
            </StyledProfileFlagRowContent>

            <StyledProfileSection
              className={`${
                !isEdit
                  ? `tell-about-short-description`
                  : isEdit && locale === "es" && Object.keys(errors)?.length
                  ? `tell-about-short-description-show-error`
                  : `tell-about-short-description-hide-error`
              }`}>
              {isEdit ? (
                <>
                  <TextEditor
                    data-testid="shortDescriptionEs"
                    id="shortDescriptionEs"
                    name="shortDescriptionEs"
                    value={values?.shortDescriptionEs}
                    onBlur={() =>
                      setTouched({ ...touched, shortDescriptionEs: true })
                    }
                    onChange={(value) =>
                      setFieldValue("shortDescriptionEs", value, true)
                    }
                    className={
                      !!errors["shortDescriptionEs"] &&
                      !!touched["shortDescriptionEs"]
                        ? "text-editor-error"
                        : "unset"
                    }
                  />
                  <FieldError
                    name={"shortDescriptionEs"}
                    autoFocus={true}
                    scrollOpts={{ block: "end", behavior: "smooth" }}
                    error={errors}
                    touched={touched}
                  />
                </>
              ) : initialValues?.shortDescriptionEs.length ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: initialValues?.shortDescriptionEs ?? "",
                  }}
                />
              ) : (
                <FormattedMessage
                  id="dashboard::forms::storeInformationSpanish"
                  defaultMessage="You have not filled the store information in spanish"
                />
              )}
            </StyledProfileSection>
          </StyledVisibleContainer>
        </StyledProfileRowContent>
      </fieldset>
      {isEdit && (
        <>
          <StyledProfileSpacer bottom={1.5} />
          <StyledProfileRowContent>
            <StyledProfileFlagRowContent></StyledProfileFlagRowContent>
            <StyledProfileFlexContainer>
              <Button
                className="black-button"
                color="transparent"
                label="cancel"
                onClick={onCancel}>
                <FormattedMessage
                  id="dashboard::forms::cancel"
                  defaultMessage="Cancel"
                />
              </Button>
              <Button
                data-testid="btn-save"
                className="blue-button button-margin"
                color={theme.colors.pacificBlue}
                type="button"
                disabled={Object.keys(errors)?.length > 0}
                onClick={onSave}>
                <FormattedMessage
                  id="dashboard::accountForm::save"
                  defaultMessage="Save"
                />
              </Button>
            </StyledProfileFlexContainer>
          </StyledProfileRowContent>
        </>
      )}
    </Form>
  )
}

export default TellAboutProfileForm
