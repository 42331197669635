import React from "react"
import { useAuth } from "@tmu/hooks"
import { StyledFooterButton } from "./index.styles"
import { useIntl } from "gatsby-plugin-intl"
import { isBrowser } from "@tmu/utils/auth"

const FooterSectionsDesktop = ({
  id,
  title,
  subTitles,
  className,
  dataTestId,
  customContent,
}) => {
  const { updateCommunicationLanguage } = useAuth()
  const { locale } = useIntl()

  const handleFooterLinkClick = (id, key) => {
    if (id === "language-section") {
      updateCommunicationLanguage({
        variables: {
          language: key.language,
        },
      })
      return
    }

    const path = ` ${window.location.origin}/${locale || "en"}`
    if (
      isBrowser &&
      (key?.link?.includes("terms") || key?.link?.includes("policy"))
    )
      window.open(key?.directLink || `${path}${key?.link}`, "_blank")
  }
  return (
    <div data-testid={dataTestId || ""} className={className || ""}>
      <h4>{title}</h4>

      {customContent ||
        subTitles.map((key) => (
          <StyledFooterButton
            variant="text"
            align="left"
            key={key.id}
            onClick={() => handleFooterLinkClick(id, key)}>
            <a
              href={key?.directLink || `/${locale}${key.link}`}
              target={key?.directLink ? "_blank" : "_self"}>
              {key.subTitle}
            </a>
          </StyledFooterButton>
        ))}
    </div>
  )
}

export default FooterSectionsDesktop
