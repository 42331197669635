import React from "react"
import PropTypes from "prop-types"
import { StyledUserTable } from "./index.styles"
import UserTableHeader from "./UserTableHeader"
import UserTableRow from "./UserTableRow"

const UserTable = ({ users, dashboardType }) => {
  return (
    <>
      <UserTableHeader />
      <StyledUserTable>
        {users.map((user, index) => (
          <UserTableRow
            key={user?.id}
            index={index}
            dashboardType={dashboardType}
            {...user}
          />
        ))}
      </StyledUserTable>
    </>
  )
}

UserTable.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      created: PropTypes.string,
      apiRole: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
      user: PropTypes.shape({
        id: PropTypes.string,
        email: PropTypes.string,
      }),
      userRoles: PropTypes.arrayOf(PropTypes.string),
      isActive: PropTypes.bool,
    })
  ).isRequired,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
}

export default UserTable
