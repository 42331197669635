import React, { memo } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"

const StyledTruncatedText = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: ${(p) => p.numLines || 2};
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: inherit;
`

const TruncatedText = ({ children, ...rest }) => {
  return children ? (
    <StyledTruncatedText {...rest}>{children}</StyledTruncatedText>
  ) : null
}

TruncatedText.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
  numLines: PropTypes.number,
}

TruncatedText.defaultProps = {
  children: "",
  className: "",
  numLines: 2,
}

export default memo(TruncatedText)
