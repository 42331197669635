import React from "react"
import { navigate, useIntl } from "gatsby-plugin-intl"
import { useMutation } from "@apollo/client"
import { Form, useFormikContext } from "formik"
import { FieldError } from "@tmu/components/common"
import { ImageSelector } from "@tmu/components/dashboard/dashboardCommon"
import { useAuth, useDefaultMerchant, useToast } from "@tmu/hooks"
import { useApolloApiClients } from "@tmu/apollo/client"
import { SUBMIT_FOR_REVIEW_MERCHANT_STORE_MUTATION } from "@tmu/apollo/dashboard/mutations/merchant"
import { API_PERMISSIONS } from "@tmu/apollo/constants"

import {
  StyledProfileFlagRowContent,
  StyledProfileRowContent,
  StyledProfileSection,
} from "./index.styles"

const PhotoProfileForm = () => {
  const { apiPermissions } = useAuth()
  const { formatMessage } = useIntl()
  const { success, error } = useToast()
  const { merchantClient } = useApolloApiClients()

  const { initialValues, values, setFieldValue } = useFormikContext()

  const { refetch } = useDefaultMerchant({ isFullData: true })

  const [submitForReview] = useMutation(
    SUBMIT_FOR_REVIEW_MERCHANT_STORE_MUTATION,
    {
      client: merchantClient,
      onCompleted() {
        refetch()
      },
    }
  )

  const onChange = (image) => {
    const errorHandler = (err) => {
      error(
        err?.message ??
          formatMessage({
            id: "dashboard::campaignForm::errorMessage",
            defaultMessage: "An error occurred",
          })
      )
    }

    const mutationSuccessMessage = formatMessage({
      id: "dashboard::merchantStore::submittedMessage",
      defaultMessage: "Your updates are submitted for review!",
    })

    submitForReview({
      variables: {
        input: {
          image,
        },
      },
      client: merchantClient,
    })
      .then(({ data }) => {
        if (data?.merchantStoreSubmitForReview?.store) {
          success(mutationSuccessMessage)
          navigate("/dashboard/merchants/account/")
        } else {
          throw new Error(
            data?.merchantStoreSubmitForReview?.errors?.[0]?.messages?.[0]
          )
        }
      })

      .catch(errorHandler)
  }

  return (
    <Form className="photo-profile-form">
      <fieldset>
        <StyledProfileRowContent>
          <StyledProfileFlagRowContent></StyledProfileFlagRowContent>
          <StyledProfileSection className="image-upload">
            <ImageSelector
              dashed
              alignOverlay={true}
              noAspect
              defaultValue={values?.image}
              onChange={(image) => {
                setFieldValue("image", image)
                onChange(image)
              }}
              onCropChange={(croppedImage) =>
                setFieldValue("image", croppedImage)
              }
              onReset={() => setFieldValue("image", initialValues?.image)}
              showUploadDescription={
                apiPermissions?.includes(
                  API_PERMISSIONS.MERCHANT_UPDATE_ACCOUNT_INFORMATION
                )
                  ? true
                  : false
              }
              showUploadLabel={
                apiPermissions?.includes(
                  API_PERMISSIONS.MERCHANT_UPDATE_ACCOUNT_INFORMATION
                )
                  ? true
                  : false
              }
            />
            <div data-testid="error-image">
              <FieldError name="image" autoFocus={true} />
            </div>
          </StyledProfileSection>
        </StyledProfileRowContent>
      </fieldset>
    </Form>
  )
}

export default PhotoProfileForm
