import React from "react"
import { Line } from "rc-progress"
import theme from "@tmu/global/theme"
import { useMediaQuery } from "beautiful-react-hooks"
import { Tooltip } from "react-tooltip"

import { FormattedMessage, navigate, useIntl } from "gatsby-plugin-intl"
import { LazyImage, Money, TruncatedText } from "@tmu/components/common"
import { CardStats } from "../CardStats"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import {
  StyledCardTitle,
  StyledCard,
  StyledCardInfo,
  StyledCardSubtitle,
  StyledImageBox,
  StyledPartnerLogo,
  StyledPartnerTitle,
  StyledPartnerTitleText,
  StyledPACMultiplier,
  StyledDoublePacsTooltip,
} from "./index.styles"
const { CLOUDFLARE_IMAGE_URL } = process.env

const CheckoutSummaryCard = ({
  imageSrc,
  title,
  subtitle,
  description,
  totalDonors,
  totalSupport,
  discount,
  inSupportOfTitle,
  inSupportOfLogo,
  fiscalCode,
  size,
  cardLink,
  goalAmount,
  fundedAmount,
  type,
  partnerName,
  partnerLogo,
  hasLogo,
  progress = 0,
  showPartnerInfo,
  pacMultiplier,
  id,
  variant,
  isCorporate,
  ...rest
}) => {
  const { isTablet, isDesktop, isWide } = getAllScreenTypes()
  const imageSize = {
    width: isWide ? 414 : 240,
    height: isWide ? 233 : 147,
  }
  const pacMultiplierNumber = pacMultiplier?.split(".")[0]
  const { formatMessage } = useIntl()

  const doublePacsTipText = formatMessage(
    {
      id: "doublePacs::listing::cardTooltip",
      defaultMessage:
        "You will get {pacMultiplierNumber} PACs for every EUR donated",
    },
    {
      pacMultiplierNumber: pacMultiplierNumber,
    }
  )

  const defaultSubtitle =
    type === "EV" ? (
      <FormattedMessage
        id="dashboard::campaignType::event"
        defaultMessage="Event"
      />
    ) : isCorporate ? (
      <FormattedMessage
        id="campaign::donationForm::corporateFundraiser"
        defaultMessage="Corporate campaign"
      />
    ) : (
      <FormattedMessage
        id="dashboard::campaignType::charityCampaign"
        defaultMessage="Charity Campaign"
      />
    )
  const PacMultiplier = () => {
    return Number(pacMultiplier) > 1 ? (
      <StyledPACMultiplier>
        <img
          className="pacs-pacs-image"
          src={CLOUDFLARE_IMAGE_URL + "/static/assets/images/white_pac.svg"}
          alt={`pacs-pacs-banner`}
          width={28}
          height={23}
        />
        {` X `}
        <Money value={pacMultiplier} currency={false} />
      </StyledPACMultiplier>
    ) : null
  }
  return (
    <StyledCard
      data-testid={`checkoutSummaryCard${id}`}
      variant={variant}
      size={size}
      {...rest}
      onClick={() => navigate(cardLink)}>
      <StyledImageBox hasLogo={hasLogo} size={size}>
        <LazyImage
          src={imageSrc}
          {...imageSize}
          altName={`card-image`}
          fit="cover"
        />
        {pacMultiplier && (
          <StyledDoublePacsTooltip
            data-tooltip-id={`checkoutSummaryCard${id}`}
            data-tooltip-content={doublePacsTipText}>
            <PacMultiplier />
            <Tooltip
              arrowColor="transparent"
              effect="solid"
              place="bottom"
              offset={{
                bottom: isWide ? 50 : 30,
                right: isWide ? 59 : 10,
              }}
              type="info"
              textColor="black"
              backgroundColor="white"
              id={`checkoutSummaryCard${id}`}
              className="checkoutSummaryCard"
              delayShow={500}
            />
          </StyledDoublePacsTooltip>
        )}
        {type === "GB" ? (
          <Line
            percent={Number(progress)}
            strokeWidth="3"
            strokeColor={theme.colors.softGreen}
            trailColor={theme.colors.white}
            trailWidth="3"
            data-testid="progress-bar"
          />
        ) : null}
      </StyledImageBox>
      <StyledCardInfo size={size}>
        <StyledCardTitle>
          <TruncatedText numLines={2}>{title}</TruncatedText>
        </StyledCardTitle>
        <StyledCardSubtitle className="caption">
          {subtitle || defaultSubtitle}{" "}
          <FormattedMessage
            id="campaign::donationForm::inSupportOf"
            defaultMessage="in support of"
          />
          <StyledPartnerTitle size={size}>
            <StyledPartnerLogo
              data-testid="campaign-detail__foundation-logo"
              src={partnerLogo}
            />
            <StyledPartnerTitleText>
              <TruncatedText numLines={isTablet ? 2 : 3}>
                {partnerName}
              </TruncatedText>
            </StyledPartnerTitleText>
          </StyledPartnerTitle>
        </StyledCardSubtitle>
      </StyledCardInfo>
      <CardStats
        totalDonors={totalDonors}
        totalSupport={totalSupport}
        size={size}
      />
    </StyledCard>
  )
}

export default CheckoutSummaryCard
