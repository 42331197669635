import styled from "@emotion/styled"
import { Button } from "@tmu/components/common"
import { css } from "@emotion/react"
import { StyledComingSoonToggle as StyledComingSoonToggleOffer } from "@tmu/components/offers/OfferListing/index.styles"

export const StyledListingFilterWrapper = styled.div``
export const StyledDropDownWrapper = styled.div``
export const StyledOption = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  gap: 0.7rem;
  align-items: center;
`
export const StyledOptionLabel = styled.div`
  font-size: 0.75rem;
  line-height: 1.15rem;
`
export const StyledOptionIcon = styled.div`
  color: ${(p) => p.theme.colors.carrot};
  width: 1rem;
`

export const StyledFilterModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  p {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.bold};
  }
`

export const StyledListingFilterText = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${(p) => p.theme.colors[p.isOfferList ? "carrot" : "blue"]};
  font-family: ${(p) => p.theme.fontFamilies.headline};
  cursor: pointer;
  width: fit-content;
`
export const StyledListingFilters = styled.div`
  .price-slider .left-label {
    padding-left: 0;
  }

  .price-slider .right-label {
    margin-right: 2rem;
  }
  > p {
    //uppercase header
    margin: 2rem 0 0.5rem;
    font-size: 0.75rem;
    line-height: 1.25rem;
    letter-spacing: 0.03em;
    text-align: left;
  }

  hr {
    margin: 0.5rem 0;
  }

  .filter-checkbox {
    //checkbox filters
    text-align: left;
    font-size: 1rem !important;
    line-height: 1.375rem;
    color: ${(p) => p.theme.colors.black};
    padding: 0;
    width: 100%;
    margin: 0.8125rem 0;
    > span {
      display: block;
      position: relative;
      float: right;
    }

    ${(p) =>
      p.isOfferList &&
      css`
        input:checked ~ .checkmark {
          background-color: ${p.theme.colors.carrot};
          border: 0.0625rem solid ${p.theme.colors.carrot};
        }

        input:checked ~ .checkmark:after {
          background-color: ${p.theme.colors.carrot};
        }
      `}
  }

  .category-filters button {
    font-family: ${(p) => p.theme.fontFamilies.regular};
    padding: 0.25rem 0;
    margin: 0;
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    font-size: 1rem;
    line-height: 1.375rem;
    min-height: 1.9375rem;
    color: ${(p) => p.theme.colors.black};
    width: 100%;
    display: flex;
    justify-content: space-between;

    i {
      margin-left: 0;
      margin-right: 0.6rem;
      width: 1rem;
    }

    svg {
      padding-top: 0.125rem;
      font-size: 1.2rem;
      height: 1.2rem;
      color: ${(p) => p.theme.colors.blue};
    }
  }

  ${(p) =>
    p.isOfferList &&
    css`
      .category-filters button svg {
        color: ${p.theme.colors.carrot};
      }
    `}

  .type-caption {
    margin-top: 0;
  }
`

export const ShowAllButton = styled(Button)`
  font-size: 1rem;
  line-height: 1.25rem;
  margin-bottom: 1.5rem;
`
export const StyledComingSoonToggle = styled(StyledComingSoonToggleOffer)`
  font-size: 1rem;
  line-height: 1.375rem;
  padding-top: 0.8125rem !important;
  margin: 0.8125rem 0;
`
