import { gql } from "@apollo/client"
import { capitalize } from "@tmu/utils/string"

export const CORPORATEPAGE_QUERY_STR = ({ locale }) => {
  const localeCapital = capitalize(locale)

  return `
  query CorporatePage($first: Int) {
    corporateCampaigns: allMerchants(first: $first, bundleCode: ["MERCHANT_BRAND"], orderBy: "-created", isDisplayed: true) {
        edges {
          node {
            id
            name
            logo
            slug
            isDisplayed
            category {
              id
              name
              name${localeCapital}
            }
            offers {
              edges {
                node {
                  id
                  name
                  offerType
                  categories {
                    edges {
                      node {
                        id
                        name
                        name${localeCapital}
                      }
                    }
                  }
                }
              }
            }
            fundedAmountForDisplay
            donationCountForDisplay
            campaignCountForDisplay
            totalFundedAmount
            totalDonationCount
            totalCampaignCount
            totalPacDiscountAmount
          }
        }
    }
  }
`
}

export const CORPORATEPAGE_QUERY = ({ locale }) => gql`
  ${CORPORATEPAGE_QUERY_STR({ locale })}
`

export const CORPORATE_COMPANIES_LISTING_QUERY = gql`
  query CorporateCompaniesListing(
    $first: Int
    $search: String
    $orderBy: String
    $isDisplayed: Boolean
  ) {
    allMerchants(
      first: $first
      search: $search
      bundleCode: ["MERCHANT_BRAND"]
      orderBy: $orderBy
      isDisplayed: $isDisplayed
    ) {
      edges {
        node {
          id
          slug
          name
          logo
          isDisplayed
          fundedAmountForDisplay
          donationCountForDisplay
          campaignCountForDisplay
          totalFundedAmount
          totalFundedAmountForDisplay
          totalSocialImpact
          totalDonationCount
          totalDonationCountForDisplay
          totalSocialImpactForDisplay
          totalCampaignCount
          totalPacDiscountAmount
          defaultOffer {
            offerType
          }
        }
      }
    }
  }
`
