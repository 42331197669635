import styled from "@emotion/styled"
import {
  StyledFormSection,
  StyledFormSectionHelpText,
} from "@tmu/global/page-addons/dashboard.styles"

import { Spacer } from "@tmu/global/page-addons/detail-page.styles"

export const StyledProfileFavoriteCharityContainer = styled.div`
  .react-select__control {
    max-width: unset;
  }
`

export const StyledProfileFavoriteCharityImage = styled.div`
  margin-bottom: 0.75rem;
  width: fit-content;
  height: fit-content;
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin-bottom: 1rem;
  }
  min-width: 6rem;
  min-height: 3.375rem;
`

export const StyledProfileFavoriteCharityImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  align-items: left;
`

export const StyledProfileFavoriteCharitySupportToggle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  font-size: 1rem;
  line-height: 1.375rem;
`

export const StyledProfileFlagRowContent = styled.div`
  display: flex;
  justify-content: right;
  padding: 0 0.625rem;
`

export const StyledProfileFlexContainer = styled.div`
  display: flex;
  column-gap: 1.5rem;

  button {
    padding: 0 !important;
    margin: 0 !important;
  }

  .black-button {
    flex: 1;
    color: ${(p) => p.theme.colors.pitchBlack};
  }

  .black-button:hover {
    color: ${(p) => p.theme.colors.blue};
  }

  .blue-button {
    flex: 1;
    color: ${(p) => p.theme.colors.white};
    background-color: ${(p) => p.theme.colors.pacificBlue};
    :disabled {
      background-color: ${(p) => p.theme.colors.disabled};
    }
  }
`

export const StyledProfileFlexRow = styled.div`
  display: block;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    display: flex;
    justify-content: space-between;
  }
`

export const StyledProfileFormSection = styled((props) => (
  <StyledFormSection {...props} />
))`
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    .image-selector {
      flex: unset;
      img {
        width: auto;
        height: auto;
        max-height: 7.5rem !important;
      }
    }
  }
`

export const StyledProfileFormSectionHelpText = styled((props) => (
  <StyledFormSectionHelpText {...props} />
))``

export const StyledProfileRowContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(p) => (p.hideTitle ? "0rem" : " 1rem")};
`

export const StyledProfileSection = styled.div`
  width: 100%;
  &.tell-about-short-description {
    p {
      margin-bottom: unset;
    }
  }

  &.tell-about-short-description-show-error {
    margin-bottom: 0.75rem;
  }

  &.tell-about-short-description-hide-error {
    margin-bottom: unset;
  }

  .react-select__control {
    margin-top: 0;
  }
`

export const StyledProfileSectionTitle = styled.div`
  text-transform: uppercase;
  font-family: ${(p) => p.theme.fontFamilies.headlineBig};
  font-weight: ${(p) => p.theme.fontWeights.liteBlack};
  color: ${(p) => p.theme.colors.disabled};
  font-size: 0.625rem;
  line-height: 1rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.25rem;
    line-height: 2rem;
  }
`

export const StyledProfileSectionBoldTitle = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  margin: auto;

  span {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.pitchBlack};
  }
`

export const StyledProfileSpacer = styled((props) => <Spacer {...props} />)``

export const StyledPrivacyTooltip = styled.div`
  .react-tooltip__show,
  .react-tooltip__hide,
  .react-tooltip__closing {
    max-width: 22.5rem !important;
    margin-top: -0.2rem;
    margin-left: 1rem;
    padding: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.15) 0 0.2375rem 1.5625rem,
      rgba(0, 0, 0, 0.05) 0 0.3125rem 0.625rem;
  }

  .react-tooltip__show {
    opacity: 1;
  }
`
export const StyledVisibleContainer = styled.div`
  display: flex;
  justify-content: start;
  margin-left: 0;
  align-items: center;

  &.inner {
    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      margin-left: -2.3rem;
    }
    margin-left: 0;
  }

  &.align-top {
    align-items: baseline;
  }
`
