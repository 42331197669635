import React from "react"
import { StyledInfoBox } from "./index.styles"
import { FormattedMessage } from "gatsby-plugin-intl"
import { REVIEW_STATUS } from "@tmu/apollo/constants"

const InfoBox = ({ children, status, ...props }) => {
  switch (status) {
    case REVIEW_STATUS.WAITING_FOR_APPROVAL:
      return (
        <StyledInfoBox {...props} info={true}>
          <FormattedMessage
            id="reviewStatus::type::WAITING_FOR_APPROVAL"
            defaultMessage="Waiting for Approval"
          />
        </StyledInfoBox>
      )
    case REVIEW_STATUS.PENDING:
      return (
        <StyledInfoBox {...props} info={true}>
          <FormattedMessage
            id="reviewStatus::type::PENDING"
            defaultMessage="Pending"
          />
        </StyledInfoBox>
      )
    case REVIEW_STATUS.APPROVED:
      return (
        <StyledInfoBox {...props} success={true}>
          <FormattedMessage
            id="reviewStatus::type::APPROVED"
            defaultMessage="Approved"
          />
        </StyledInfoBox>
      )
    case REVIEW_STATUS.IN_PROGRESS:
      return (
        <StyledInfoBox {...props} warning={true}>
          <FormattedMessage
            id="reviewStatus::type::IN_PROGRESS"
            defaultMessage="In Progress"
          />
        </StyledInfoBox>
      )
    case REVIEW_STATUS.REJECTED:
      return (
        <StyledInfoBox {...props} error={true}>
          <FormattedMessage
            id="reviewStatus::type::REJECTED"
            defaultMessage="Rejected"
          />
        </StyledInfoBox>
      )
    case REVIEW_STATUS.ENDED:
      return (
        <StyledInfoBox {...props} error={true}>
          <FormattedMessage
            id="reviewStatus::type::ENDED"
            defaultMessage="Ended"
          />
        </StyledInfoBox>
      )
    default:
      return null
  }
}
export default InfoBox
