import styled from "@emotion/styled"
export const StyledTable = styled.div`
  table {
    border-collapse: collapse;
    thead > tr:first-child {
      border-bottom: 1px solid ${(p) => p.theme.colors.darkGrey};
      height: 2rem;
    }
    th,
    .header-text {
      text-transform: uppercase;
      font-size: 0.625rem;
      line-height: 1rem;
      font-weight: ${(p) => p.theme.fontWeights.liteBlack};
      color: ${(p) => p.theme.colors.disabled};
      font-family: ${(p) => p.theme.fontFamilies.headlineBig};
      text-align: left;
      padding: 0 0.5rem 0 0;

      @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }

    td,
    tr {
      padding: 0.5rem 1rem 0.5rem 0.5rem;
      font-size: 0.75rem;
      line-height: 1.25rem;
      font-weight: ${(p) => p.theme.fontWeights.liteMedium};
      font-family: ${(p) => p.theme.fontFamilies.regular};

      @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
        font-size: 1rem;
        line-height: 1.75rem;
      }
    }

    tr > td:first-child {
      padding-left: 0.2rem;
    }
  }

  .header-icon {
    display: flex;
    align-items: center;
    svg {
      height: 1rem;
      width: 1rem;
      padding-right: 0.2rem;

      @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
        height: 1.25rem;
        width: 1.25rem;
      }
    }
  }

  .clickable {
    cursor: pointer;
  }
  .active-sort {
    svg {
      color: ${(p) => p.theme.colors.blue} !important;
      height: 1.1rem;
      width: 1.1rem;
    }
  }

  .align-header-right {
    &,
    div {
      justify-content: end !important;
    }
  }

  .align-header-center {
    &,
    div {
      justify-content: center !important;
    }
  }
`
export const StyledLine = styled.hr`
  width: 100%;
  margin: 0;
`

export const StyledHeaderIconContainer = styled.div`
  display: flex;
`
