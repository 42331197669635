import styled from "@emotion/styled"
import { css } from "@emotion/react"

export const StyledSortSelector = styled.div`
  min-width: 12.5rem;
  align-self: baseline;

  &.corporate-companies {
    width: 100%;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      width: 12.5rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      width: 20rem;
    }
  }
`
export const StyledListItem = styled.div`
  text-align: left;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0.75rem;
  margin-top: 0.75rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  color: ${(p) => p.theme.colors.pitchBlack};
  font-family: ${(p) => p.theme.fontFamilies.regular};
  div > svg {
    color: ${(p) => p.theme.colors.pacificBlue};
  }
  span {
    margin-left: 0.5rem;
    color: ${(p) => p.theme.colors.pitchBlack};
  }

  ${(p) =>
    p.isOfferList &&
    css`
      div svg {
        color: ${p.theme.colors.carrot};
      }
    `}

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-top: 0;
  }
`

export const StyledList = styled.div`
  display: block;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: grid;
    grid-template-columns: 4fr 4fr 4fr;
  }
`
