import styled from "@emotion/styled"
import { css } from "@emotion/react"

const GridTemplate = `
  display: grid;
  grid-template-columns: minmax(1rem, 7rem) minmax(3rem, 16rem) minmax(2rem, 6rem) minmax(1.5rem, 3rem) minmax(3rem, 6rem) minmax(2.5rem,4.5rem) minmax(0, 1.5rem);
  grid-gap: 0.3125rem;
  & > div{
    display: flex;
    align-items: center;
  }
  align-items: center;
`

export const StyledCartTable = styled.div``

export const StyledCartTableHeader = styled.div`
  ${GridTemplate}
  color: ${(p) => p.theme.colors.tableHeaderDefault};
  padding: 0.7rem 0;
  font-size: 0.8rem;
  ${(p) =>
    p.isSalesStatusVisible &&
    css`
      grid-template-columns: 4rem 4rem 1fr 1fr 4rem 3rem 3rem 3rem 5rem;
      gap: 0.5rem;
      font-size: 0.7rem;
    `}
  border-bottom: 0.0625rem solid
    ${(p) => p.theme.colors.tableItemBorder};

  .centered {
    height: 100%;
    justify-self: center;
  }
`

export const StyledCartTableRow = styled.div`
  ${GridTemplate}
  width: 100%;
  overflow: visible;
  color: ${(p) => p.theme.colors.secondaryBrandTextColor};
  border-bottom: 0.0625rem solid ${(p) => p.theme.colors.tableItemBorder};
  font-size: 0.8rem;
  ${(p) =>
    p.isSalesStatusVisible &&
    css`
      gap: 0.5rem;
      grid-template-columns: 4rem 4rem 1fr 1fr 4rem 3rem 3rem 5rem;
      font-size: 0.7rem;
    `}
  width: 100%;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  height: 100%;
  box-sizing: border-box;

  *:not(button) {
    color: ${(p) => p.theme.colors.gondola};
  }

  strong {
    font-weight: ${(p) => p.theme.fontWeights.bold};
    color: ${(p) => p.theme.colors.gondola};
  }

  .desc * {
    color: ${(p) => p.theme.colors.secondaryBrandTextColor};
  }

  .centered {
    height: 100%;
    justify-self: center;
  }
  select {
    border-radius: 0.1875rem;
    color: ${(p) => p.theme.colors.secondaryBrandTextColor};
  }

  .lightButton {
    opacity: 0.4;
    cursor: not-allowed;
  }

  .media-box {
    padding-bottom: 0;
    height: auto;
    img {
      width: 100%;
    }
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.phone}) {
    strong {
      font-size: 0.6rem;
    }

    .hide-mobile {
      display: none;
    }

    .delete-button {
      font-size: 0.875rem;
      padding: 0;
      svg {
        margin: 0;
      }
    }
  }
`
