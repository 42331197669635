import styled from "@emotion/styled"
import { css } from "@emotion/react"

export const StyledSwiperWrapper = styled.div`
  position: relative;
  .swiper {
    margin-right: -1rem;
    margin-left: -0.5rem;
    padding: 0.5rem 0.5rem 1.3rem 0.5rem;
  }
  .swiper-button-disabled {
    display: none;
  }
  .swiper-slide {
    width: auto !important;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    .swiper {
      margin-right: auto;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
  }
`

export const StyledSwiperWrapperDots = styled.div`
  position: relative;

  .swiper {
    width: 100%;
    height: 100%;
    overflow: visible;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .swiper-button-next,
  .swiper-button-prev {
    color: ${(p) => p.theme.colors.pacificBlue};
    background: ${(p) => p.theme.colors.white};
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem;
    padding: 1rem;
    cursor: pointer;
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.15);
    &::after {
      font-size: 1.2rem;
      font-weight: bold;
    }
  }
  .swiper-pagination-horizontal {
    bottom: -1.5rem;
  }

  .swiper-pagination-bullet-active {
    background: ${(p) => p.theme.colors.blue};
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    .swiper-button-next,
    .swiper-button-prev {
      display: none;
    }
    .swiper-pagination-horizontal {
      bottom: -2rem;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    .swiper-button-next,
    .swiper-button-prev {
      display: flex;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    .swiper-button-next,
    .swiper-button-prev {
      height: 4rem;
      width: 4rem;
      &::after {
        font-size: 2rem;
      }
    }
    .swiper-pagination-horizontal {
      bottom: -3.25rem;
    }
    .swiper-pagination-bullet {
      width: 1rem;
      height: 1rem;
    }
  }
`

export const StyledChevronWrapper = styled.div`
  position: absolute;
  display: none;
  align-items: center;
  justify-content: center;
  height: 18.375rem;
  top: 0;
  width: 5rem;
  z-index: 2;
  ${(p) =>
    p.left
      ? css`
          left: -1rem;
          background: linear-gradient(
            90deg,
            #ffffff 42.13%,
            rgba(255, 255, 255, 0) 100%
          );
        `
      : css`
          right: -1.5rem;
          background: linear-gradient(
            270deg,
            #ffffff 42.13%,
            rgba(255, 255, 255, 0) 100%
          );
        `}
  svg {
    color: ${(p) => p.theme.colors.pacificBlue};
    background: ${(p) => p.theme.colors.white};
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem;
    padding: 0.5rem;
    cursor: pointer;
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.15);
  }

  ${(p) =>
    (p.isStoreCampaign || p.isSmall) &&
    css`
      svg {
        height: 1.5rem;
        width: 1.5rem;
      }
    `}

  ${(p) =>
    p.isStoreCampaign &&
    p.left &&
    css`
      background: linear-gradient(
        90deg,
        #f4f4f2 42.13%,
        rgba(244, 244, 242, 0) 100%
      );
    `}

  ${(p) =>
    p.isStoreCampaign &&
    !p.left &&
    css`
      background: linear-gradient(
        270deg,
        #f4f4f2 42.13%,
        rgba(244, 244, 242, 0) 100%
      );
    `}

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: ${(p) => (p.hide ? "none" : "flex")};
    height: ${(p) =>
      p.mainPartners
        ? "6.875rem !important"
        : p.otherPartners
        ? "4.25rem !important"
        : p.isSmall
        ? "10rem !important"
        : "23.75rem !important"};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    height: ${(p) =>
      p.mainPartners
        ? "8.75rem !important"
        : p.otherPartners
        ? "5rem !important"
        : p.isSmall
        ? "10rem !important"
        : "23.75rem !important"};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    height: ${(p) =>
      p.mainPartners
        ? "16rem! important"
        : p.otherPartners
        ? "8.75rem !important"
        : p.isSmall
        ? "16rem !important"
        : "33.5625rem !important"};
    width: 6.5rem;
    svg {
      height: 2.5rem !important;
      width: 2.5rem !important;
    }
  }
`
