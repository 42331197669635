import { PAYMENT_TYPES } from "@tmu/src/apollo/constants"
import {
  StyledPaymentMethod,
  StyledPaymentMethodText,
  StyledPaymentMethodsWrapper,
} from "./index.styles"
import { useState } from "react"
import { useCreditCards } from "@tmu/src/hooks"
import { LazyImage } from "@tmu/components/common"
import { FormattedMessage } from "gatsby-plugin-intl"

const { CLOUDFLARE_IMAGE_URL } = process.env

const PaymentMethods = ({
  onSelect,
  defaultValue,
  callClientSecret = false,
  hideBankTransfer,
  amount,
  ...rest
}) => {
  const [selected, setSelected] = useState(defaultValue ?? PAYMENT_TYPES.CARD)
  const { callCreateClientSecret } = useCreditCards({ callImmediate: false })
  const onClick = (val) => {
    setSelected(val)

    if (typeof onSelect === "function") {
      onSelect(val)
    }

    if (callClientSecret) {
      callCreateClientSecret({
        variables: { stripePaymentMethodType: val },
      })
    }
  }

  return (
    <StyledPaymentMethodsWrapper className={rest?.disabled ? "disabled" : ""}>
      <StyledPaymentMethod
        className={`${selected === PAYMENT_TYPES.CARD ? "selected" : ""}`}
        onClick={() => onClick(PAYMENT_TYPES.CARD)}
        key={PAYMENT_TYPES.CARD}>
        <LazyImage
          altName="card-logo"
          src={
            CLOUDFLARE_IMAGE_URL +
            "/static/assets/images/payment/card.inline.svg"
          }
          width="auto"
          height={45}
          fit="cover"
        />
      </StyledPaymentMethod>
      {amount >= 15 && (
        <StyledPaymentMethod
          className={selected === PAYMENT_TYPES.PAYPAL ? "selected" : ""}
          onClick={() => onClick(PAYMENT_TYPES.PAYPAL)}
          key={PAYMENT_TYPES.PAYPAL}>
          <LazyImage
            altName="card-logo"
            src={
              CLOUDFLARE_IMAGE_URL +
              "/static/assets/images/payment/paypal1920.png"
            }
            width="auto"
            height={75}
            fit="contain"
          />
        </StyledPaymentMethod>
      )}

      {amount && amount >= 100 && !hideBankTransfer && (
        <StyledPaymentMethod
          className={
            selected === PAYMENT_TYPES.CUSTOMER_BALANCE ? "selected" : ""
          }
          onClick={() => onClick(PAYMENT_TYPES.CUSTOMER_BALANCE)}
          key={PAYMENT_TYPES.CUSTOMER_BALANCE}>
          <StyledPaymentMethodText>
            <FormattedMessage
              id="checkout::paymentMethods::bankTransfer"
              defaultMessage="Bank Transfer"
            />
          </StyledPaymentMethodText>
        </StyledPaymentMethod>
      )}
    </StyledPaymentMethodsWrapper>
  )
}

export default PaymentMethods
