import styled from "@emotion/styled"
export const StyledFeatureCampaigns = styled.div``

export const StyledTable = styled.div`
  td,
  tr {
    padding-left: 1rem !important;
    @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      padding-left: unset !important;
    }
  }

  td,
  tr,
  span,
  div,
  p {
    font-weight: ${(p) => p.theme.fontWeights.semiBolder} !important;
    font-family: ${(p) => p.theme.fontFamilies.headline} !important;
    @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-weight: ${(p) => p.theme.fontWeights.liteMedium} !important;
      font-family: ${(p) => p.theme.fontFamilies.regular} !important;
    }
  }

  tr > td {
    height:4rem;
  }

  tr th * {
    font-weight: ${(p) => p.theme.fontWeights.semiBolder} !important;
    font-family: ${(p) => p.theme.fontFamilies.headlineBig} !important;
  }

  thead > tr > th:first-child {
    padding-left: 1rem !important;

    div {
      padding-left: 0;
    }

    @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      padding-left: 0 !important;
    }
  }

  .blue-text {
    color: ${(p) => p.theme.colors.blue};
  }

  .align-right {
    text-align: right !important;
    justify-content: end !important;
  }

  .nowrap{
    white-space:nowrap;
  }

  .align-center {
    text-align: center !important;
    justify-content: center !important;
  }

  .mobile-date {
    color: ${(p) => p.theme.colors.disabled};
    font-size: 0.625rem;
    font-weight: ${(p) => p.theme.fontWeights.liteMedium} !important;
    font-family: ${(p) => p.theme.fontFamilies.regular} !important;
  }

  .tooltip {
    background-color: ${(p) => p.theme.colors.white};
    color: ${(p) => p.theme.colors.pitchBlack} !important;
    box-shadow: 0 0 0.3125rem rgba(122, 119, 118, 0.5); !important;
  }
  
  .react-select__input-container {
    height: 0;
  }
  .react-select__input {
    height: 0;
  }

  .react-select__indicator {
    padding: 0;
  }

  .symbol {
    font-size: 1rem;
  }
`
export const StyledReceipt = styled.div`
  text-align: center;
  svg {
    height: 1.25rem;
    color: ${(p) => p.theme.colors.blue};
    @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      height: 1.75rem;
    }
    cursor: pointer;
  }
`

export const StyledServiceNameWrapper = styled.div`
  word-break: break-all;
`

export const StyledPageWrapper = styled.div`
  margin-left: -1rem;

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-left: unset;
  }

  .price-cell {
    text-wrap: nowrap;
  }
`

export const StyledCampaignLink = styled.span`
  cursor: pointer;
`

export const StyledTotalInfo = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${(p) => p.theme.colors.whiteSmoke};
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
`
export const StyledDotMenuButton = styled.div`
  cursor: pointer;
  svg {
    height: 1.6rem;
    @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      height: 2rem;
    }
  }
`
export const StyledEditMenu = styled.div`
  z-index: 999;
`
export const StyledEditMenuItem = styled.div`
  cursor: pointer;
  width: 100%;
  height: 100%;
`
export const StyledDropDown = styled.div``

export const StyledTooltip = styled.div`
  position: relative;
  display: inline-block;

  .tooltiptext {
    z-index: 999;
    width: max-content;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(p) => p.theme.colors.white};
    color: ${(p) => p.theme.colors.pitchBlack};
    text-align: center;
    border-radius: 0.25rem;
    line-height: 1rem;
    padding: 0.5rem;
    font-size: 0.625rem;
    position: absolute;
    top: 125%;
    right: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s;
    box-shadow: 0 0.25rem 0.75rem 0
      ${(p) => p.theme.colors.lightShadowConcreteGrey};
  }

  &:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    .tooltiptext {
      height: 2.75rem;
      padding: 0.75rem;
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
`

export const StyledLeftStockItem = styled.div`
  width: max-content;
  & > .red-text {
    color: ${(p) => p.theme.colors.red};
  }
`
