import styled from "@emotion/styled"
import { Button } from "@tmu/components/common"

export const StyledWrapper = styled.div`
  box-sizing: border-box;
  max-width: ${(p) => p.theme.breakpoints.wide};
  margin: 0 auto 2rem auto;
  padding: 0.875rem 1rem;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${(p) => p.theme.colors.primaryBrandTextColor};
  margin: 0 0 1.875rem 0;
  line-height: 2;
  a {
    color: ${(p) => p.theme.colors.primaryBrandTextColor};
    margin-right: 1rem;
  }
`

export const SliceHeader = styled.h3`
  flex: 2 1 auto;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    text-align: center;
  }
`

export const StyledViewAllButton = styled(Button)`
  margin: 0 auto 2.8125rem;
`
