import { FormattedMessage } from "gatsby-plugin-intl"

const { default: CustomModal } = require("../CustomModal")

const ConfirmModal = ({ ...rest }) => {
  return (
    <CustomModal
      style={{
        width: "30rem",
        height: "10rem",
      }}
      modalActionsStyle={{
        display: "flex",
      }}
      header={
        <FormattedMessage
          id="confirm::modal::sure"
          defaultMessage="Are you sure?"
        />
      }
      cancelButtonText={
        <FormattedMessage
          id="dashboard::campaigns::modalNo"
          defaultMessage="No"
        />
      }
      confirmButtonText={
        <FormattedMessage
          id="dashboard::campaigns::modalYes"
          defaultMessage="Yes"
        />
      }
      {...rest}
    />
  )
}

export default ConfirmModal
