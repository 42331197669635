import styled from "@emotion/styled"

export const StyledSearchContainer = styled.div`
  width: 100%;
  grid-area: search;

  position: relative;

  svg {
    position: absolute;
    top: 0.75rem;
    left: 0.5rem;
    z-index: 10;
    color: ${(p) => p.theme.colors.disabled};
    height: 0.8rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      height: 1.25rem;
      top: 1rem;
      left: 1rem;
    }
  }

  .search-input {
    grid-area: text !important;
    height: 2.5rem !important;
    padding-left: 1.75rem !important;
    border-color: ${(p) => p.theme.colors.disabled} !important;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      height: 3.375rem !important;
      padding-left: 4rem !important;
    }
  }

  .ellipsis-text > label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 60%;
    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      max-width: 75%;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      max-width: unset;
    }
  }

  .active > label {
    width: auto !important;
    max-width: unset !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 90% !important;
  }

  label {
    width: max-content !important;
    color: ${(p) => p.theme.colors.disabled} !important;
    font-size: 0.75rem !important;
    padding-top: 0.2rem !important;
    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.5rem !important;
      padding-top: 0 !important;
    }
  }

  input[value]:not([value=""]) + label {
    margin-top: 0.3rem !important;
    font-size: 0.85rem !important;
    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      margin-top: -0.2rem !important;
      font-size: 1rem !important;
    }
  }

  .active {
    input + label {
      margin-top: 0.3rem !important;
      font-size: 0.85rem !important;
      @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
        margin-top: -0.2rem !important;
        font-size: 1rem !important;
      }
    }
  }
`
export const StyledForm = styled.form`
  display: contents;
`
export const StyledInfoIcon = styled.div`
  color: ${(p) => p.theme.colors.blue};
  height: 1.25rem;
`
export const StyledIconWrapper = styled.div`
  .tooltip {
    background-color: ${(p) => p.theme.colors.white};
    color: ${(p) => p.theme.colors.pitchBlack};
    overflow-wordwrap: break-word;
    width: 20rem;
    padding: 1rem;
    font-size: 0.75rem;
    line-height: 1.25rem;
  }

  svg {
    height: 1.25rem;
    cursor: pointer;
    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      height: 1.75rem;
    }
  }
  align-self: top;
  margin-top: 1rem;
  margin-left: 1rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    align-self: center;
    margin-top: unset;
  }
`
export const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .main-title {
    margin-bottom: 0;
  }
`

export const StyledInvalidCodeError = styled.div`
  color: ${(p) => p.theme.colors.red};
  width: 100%;
  text-align: center;
  min-height: 4rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    text-align: left;
  }
`

export const StyledVoucherCodeButton = styled.div`
  text-align: right;
  color: ${(p) => p.theme.colors.carrot};
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-size: 1rem;
  cursor: pointer;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.25rem;
  }
`

export const StyledStickyButton = styled.div`
  button,
  button:hover,
  button:focus {
    background-color: ${(p) => p.theme.colors.carrot};
    border: none;
    color: ${(p) => p.theme.colors.white};
  }
`
export const StyledGenerateButton = styled.div`
  width: 100%;
  grid-area: submit;
  font-size: 1rem;
  button {
    height: 2.5rem;
    margin: 0;
    width: 100%;
    font-size: 1rem;
    line-height: 1rem;

    .spinner-condensed div {
      box-shadow: 0 0.125rem 0 0 ${(p) => p.theme.colors.white};
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 0.75rem;
      line-height: 0.7rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.25rem;
    }
  }
`

export const StyledVoucherCodeVerifyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    justify-content: start;
  }
  gap: 0.2rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    gap: 0.5rem;
  }
  input,
  input:focus {
    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      width: 4.5rem;
      height: 4.5rem;
    }
    width: 3.4rem;
    height: 3.4rem;
    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      width: 4rem;
      height: 4rem;
    }
    text-align: center;
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-size: 2rem;
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    line-height: 2.5rem;
    color: ${(p) => p.theme.colors.pitchBlack};
    border-radius: 0.25rem;
    border-color: ${(p) => p.theme.colors.concreteGrey};
    border-style: solid;
    outline: none;
    text-transform: uppercase;
  }

  &.error input {
    border-color: ${(p) => p.theme.colors.red};
    color: ${(p) => p.theme.colors.red};
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
`
export const StyledVerifyVoucherTitle = styled.div`
  white-space: pre;
  text-wrap: wrap;
  font-size: 2rem;
  line-height: 2.5rem;
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  margin-top: 50%;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    text-align: left;
    margin-top: unset;
    width: 20rem;
  }
`

export const StyledVerifyDetailTitle = styled.div`
  font-size: 2rem;
  line-height: 2.5rem;
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  text-align: center;
  span {
    font-family: ${(p) => p.theme.fontFamilies.headline};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-top: unset;
  }
`

export const StyledVerifyDate = styled.div`
  font-size: 2rem;
  line-height: 2.5rem;
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.carrot};
  text-align: center;
`
export const StyledContainer = styled.div`
  margin-top: -1.5rem;
`
export const StyledOfferName = styled.div`
  font-size: 1.5rem;
  line-height: 2rem;
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  text-align: center;
`

export const StyledOfferProductName = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  color: ${(p) => p.theme.colors.pitchBlack};
`

export const StyledTimeOfAction = styled.div`
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-family: ${(p) => p.theme.fontFamilies.headlineBig};
  font-weight: ${(p) => p.theme.fontWeights.liteBlack};
  color: ${(p) => p.theme.colors.concreteGrey};
  text-align: center;
  text-transform: uppercase;
`

export const StyledDetailContainer = styled.div`
  width: unset;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    width: 30rem;
  }
  text-align: center;

  .active-voucher {
    text-align: center !important;
    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      text-align: left !important;
    }
  }

  .blue-text {
    color: ${(p) => p.theme.colors.pacificBlue};
  }

  .sticky {
    position: absolute;
    width: 90vw;
    bottom: 1rem;
    left: 4vw;
  }

  .pac-icon {
    display: inline-block;
    vertical-align: bottom;
    margin-right: 0.5rem;
    margin-bottom: 0.1rem;
  }
`

export const StyledListContainer = styled.div`
  margin-top: calc(50vh - 9rem);

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-top: 4rem;
  }
`

export const StyledListBackground = styled.div`
  background-color: ${(p) => p.theme.colors.whiteSmoke};
  margin-left: -1rem;
  margin-right: -1rem;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 103vw;
  height: 50vh;
  z-index: -1;
`

export const StyledItemDescription = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  color: ${(p) => p.theme.colors.pitchBlack};
`
export const StyledItemValue = styled.div`
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.pitchBlack};
`

export const StyledListItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const StyledVoucherDetailButton = styled.div`
  button {
    width: 100%;
  }
`

export const StyledLine = styled.hr`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: auto;
  color: ${(p) => p.theme.colors.darkGrey};
`
