import { useLazyQuery } from "@apollo/client"

import { useApolloApiClients } from "@tmu/apollo/client"
import {
  CAMPAIGN_DETAIL_QUERY,
  CAMPAIGN_LISTING_QUERY,
} from "@tmu/apollo/storefront/queries/campaign"
import { useIntl } from "gatsby-plugin-intl"

const useCampaigns = (options) => {
  const { storefrontClient } = useApolloApiClients()
  const { locale } = useIntl()

  const [callAllCampaigns, { loading, data: allCampaignsData }] = useLazyQuery(
    CAMPAIGN_LISTING_QUERY,
    {
      client: storefrontClient,
      variables: { first: 1, isPublic: true },
    }
  )
  const [
    callCampaignDetail,
    { loading: campaignDetailLoading, data: campaignDetail },
  ] = useLazyQuery(CAMPAIGN_DETAIL_QUERY({ locale }), {
    client: storefrontClient,
  })
  const getAllCampaigns = () => {
    callAllCampaigns()
  }

  const campaign = campaignDetail?.campaign

  const allCampaigns = allCampaignsData?.allCampaigns?.edges

  return {
    getAllCampaigns,
    allCampaigns,
    callCampaignDetail,
    campaign,
  }
}

export default useCampaigns
