import React from "react"
import { FormattedMessage, navigate } from "gatsby-plugin-intl"
import PropTypes from "prop-types"
import { useLocation } from "@reach/router"
import { useAuth } from "@tmu/hooks"
import {
  StyledHowtoGetStarted,
  StyledHowtoGetStartedContent,
  StyledHowtoGetStartedCard,
  StyledJoinUsButton,
  StyledStores,
  StyledStoreButton,
  StyledRegisterDigitalBankingButton,
  StyledNameTitleText,
} from "@tmu/global/page-addons/how-it-works.styles"
import { isBrowser } from "@tmu/utils/auth"
import queryString from "query-string"

const { CLOUDFLARE_IMAGE_URL } = process.env

const HowtoGetStarted = ({
  title,
  howtoGetStarted,
  contactRef,
  type,
  children,
  isDigitalBanking = false,
  appleStoreUrl = "",
  playStoreUrl = "",
  digitalBankingUrl = "https://business.trustmeup.com/onboarding-user/",
}) => {
  const { isAuthenticated } = useAuth()
  const { search } = useLocation()
  const referralCode = queryString.parse(search)?.referral

  const handleRegister = () => {
    navigate(
      `/signup?accountType=PERSONAL${
        referralCode && referralCode !== "undefined"
          ? `&referral=${referralCode}`
          : ""
      }`
    )
  }

  const handleRegisterDigitalBanking = (url) => {
    isBrowser && url && window.open(url)
  }

  return (
    <>
      <StyledHowtoGetStarted>
        {title}
        <StyledHowtoGetStartedContent>
          {howtoGetStarted.map(({ id, logo, name, shortDescription }) => (
            <StyledHowtoGetStartedCard key={id}>
              <i className={logo}></i>
              <div className="cardDesc">
                <StyledNameTitleText>{name}</StyledNameTitleText>
                <p>{shortDescription}</p>
              </div>
            </StyledHowtoGetStartedCard>
          ))}
          {children}
        </StyledHowtoGetStartedContent>
        {contactRef && (
          <StyledJoinUsButton
            color="red"
            onClick={() =>
              contactRef?.current.scrollIntoView({
                behavior: "smooth",
              })
            }>
            <FormattedMessage
              id="howItWorks::merchants::joinUs"
              defaultMessage="Join Us"
            />
          </StyledJoinUsButton>
        )}
        {type === "donor" && !isAuthenticated && (
          <StyledJoinUsButton color="red" onClick={handleRegister}>
            <FormattedMessage
              id="howItWorks::merchants::joinUs"
              defaultMessage="Join Us"
            />
          </StyledJoinUsButton>
        )}

        {isDigitalBanking && (
          <>
            <StyledRegisterDigitalBankingButton
              color="red"
              onClick={() => handleRegisterDigitalBanking(digitalBankingUrl)}>
              <FormattedMessage
                id="digital::banking::register"
                defaultMessage="Register Now"
              />
            </StyledRegisterDigitalBankingButton>
            <StyledStores>
              <StyledStoreButton
                color="white"
                onClick={() => handleRegisterDigitalBanking(appleStoreUrl)}>
                <img
                  src={
                    CLOUDFLARE_IMAGE_URL +
                    "/static/assets/images/svg/appleStore.svg"
                  }
                  alt="appleStore"
                />
              </StyledStoreButton>
              <StyledStoreButton
                color="white"
                onClick={() => handleRegisterDigitalBanking(playStoreUrl)}>
                <img
                  src={
                    CLOUDFLARE_IMAGE_URL +
                    "/static/assets/images/svg/playStore.svg"
                  }
                  alt="playStore"
                />
              </StyledStoreButton>
            </StyledStores>
          </>
        )}
      </StyledHowtoGetStarted>
    </>
  )
}

HowtoGetStarted.propTypes = {
  howtoGetStarted: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      shortDescription: PropTypes.string.isRequired,
      logo: PropTypes.string.isRequired,
    })
  ),
}
export default HowtoGetStarted
