import { gql } from "@apollo/client"
import { partnerParts, partnerUserParts } from "../fragments/partner"

export const UPDATE_PARTNER_MUTATION = gql`
  mutation UpdatePartner($input: PartnerMutationInput!) {
    updatePartner(input: $input) {
      partner {
        ...partnerParts
      }
      errors {
        field
        messages
      }
    }
  }
  ${partnerParts}
`
export const CREATE_PARTNER_USER_MUTATION = gql`
  mutation CreatePartnerUser($input: CreatePartnerUserMutationInput!) {
    createPartnerUser(input: $input) {
      partnerUser {
        ...partnerUserParts
      }
      errors {
        field
        messages
      }
    }
  }
  ${partnerUserParts}
`

export const CREATE_PARTNER_USER_REFERRAL_MUTATION = gql`
  mutation CreatePartnerUserReferral(
    $input: CreatePartnerUserReferralMutationInput!
  ) {
    createPartnerUserReferral(input: $input) {
      referral {
        id
        code
        email
      }
      errors {
        field
        messages
      }
    }
  }
`

export const UPDATE_PARTNER_USER_MUTATION = gql`
  mutation UpdatePartnerUser($input: UpdatePartnerUserMutationInput!) {
    updatePartnerUser(input: $input) {
      partnerUser {
        ...partnerUserParts
      }
      errors {
        field
        messages
      }
    }
  }
  ${partnerUserParts}
`

export const DELETE_PARTNER_USER_MUTATION = gql`
  mutation DeletePartnerUserMutation($input: DeletePartnerUserMutationInput!) {
    deletePartnerUser(input: $input) {
      success
      errors {
        field
        messages
      }
    }
  }
`
export const PARTNER_SUBMIT_FOR_REVIEW_MUTATION = gql`
  mutation partnerSubmitForReview(
    $input: PartnerSubmitForReviewMutationInput!
  ) {
    partnerSubmitForReview(input: $input) {
      partner {
        ...partnerParts
      }
      errors {
        field
        messages
      }
    }
  }
  ${partnerParts}
`

export const GENERATE_PARTNER_REPORT_MUTATION = gql`
  mutation GeneratePartnerReport($input: GeneratePartnerReportMutationInput!) {
    generatePartnerReport(input: $input) {
      partnerReport {
        id
        report
        requestedUser {
          user {
            fullName
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }
`

export const CREATE_PARTNER_IMAGE_MUTATION = gql`
  mutation CreatePartnerImage($input: CreatePartnerImageMutationInput!) {
    createPartnerImage(input: $input) {
      partnerImage {
        id
        image
        order
        partner {
          id
        }
      }
      errors {
        field
        messages
      }
    }
  }
`

export const DELETE_PARTNER_IMAGE_MUTATION = gql`
  mutation DeletePartnerImage($input: DeletePartnerImageMutationInput!) {
    deletePartnerImage(input: $input) {
      success
      errors {
        field
        messages
      }
    }
  }
`

export const CREATE_PARTNER_HERO_MUTATION = gql`
  mutation CreateHeroItem($input: HeroItemMutationInput!) {
    heroItem(input: $input) {
      heroItem {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`

export const DELETE_PARTNER_HERO_MUTATION = gql`
  mutation DeleteHeroItem($input: HeroItemDeleteMutationInput!) {
    deleteHeroItem(input: $input) {
      success
    }
  }
`

export const UPDATE_PARTNER_HERO_MUTATION = gql`
  mutation UpdateHeroItem($input: HeroItemUpdateMutationInput!) {
    updateHeroItem(input: $input) {
      heroItem {
        isActive
        title
        detail
        image
        page
        buttonText
        buttonLink
        ordering
        id
      }
      errors {
        field
        messages
      }
    }
  }
`

export const HERO_SUBMIT_FOR_REVIEW_MUTATION = gql`
  mutation partnerHeroSubmitForReview(
    $input: HeroSubmitForReviewMutationInput!
  ) {
    partnerHeroSubmitForReview(input: $input) {
      heroItem {
        isActive
        title
        titleEn
        titleIt
        titleEs
        detail
        detailEn
        detailIt
        detailEs
        image
        page
        buttonText
        buttonTextEn
        buttonTextIt
        buttonTextEs
        buttonLink
        buttonLinkEn
        buttonLinkIt
        buttonLinkEs
        ordering
        id
      }
      errors {
        field
        messages
      }
    }
  }
`

export const UPDATE_PARTNER_PAYMENT_DESTINATION_MUTATION = gql`
  mutation ($input: UpdatePartnerPaymentDestinationMutationInput!) {
    updatePaymentDestination(input: $input) {
      paymentDestination {
        accountName
        bankName
        iban
        country
        sortCode
        accountNumber
        bic
      }
      errors {
        field
        messages
      }
    }
  }
`

export const CREATE_PARTNER_PAYMENT_DESTINATION_MUTATION = gql`
  mutation ($input: CreatePartnerPaymentDestinationMutationInput!) {
    createPaymentDestination(input: $input) {
      paymentDestination {
        accountName
        bankName
        iban
        country
        sortCode
        accountNumber
        bic
      }
      errors {
        field
        messages
      }
    }
  }
`

export const UPDATE_SUBSCRIPTION_PRODUCT = gql`
  mutation ($input: UpdateSubscriptionProductMutationInput!) {
    updateSubscriptionProduct(input: $input) {
      subscriptionProduct {
        id
        name
        description
        stripeStatementDescriptor
        subscriptionMinimumAmount
        subscriptionAmountOne
        descriptionOne
        descriptionOneIt
        subscriptionAmountTwo
        descriptionTwo
        subscriptionAmountThree
        descriptionThree
        campaign {
          id
          name
        }
      }
      errors {
        field
        messages
      }
    }
  }
`

export const PARTIAL_UPDATE_PARTNER_MUTATION = gql`
  mutation PartialUpdatePartner($input: PartialUpdatePartnerMutationInput!) {
    partialUpdatePartner(input: $input) {
      partner {
        ...partnerParts
      }
      errors {
        field
        messages
      }
    }
  }
  ${partnerParts}
`
