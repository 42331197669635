import React from "react"
import PropTypes from "prop-types"
import { StyledCondensedSpinner, StyledSpinner } from "./index.styles"

const Spinner = ({ condensed, ...rest }) => {
  return condensed ? (
    <StyledCondensedSpinner {...rest}>
      <div className="spinner-condensed-container">
        <div className="spinner-condensed">
          <div />
        </div>
      </div>
    </StyledCondensedSpinner>
  ) : (
    <StyledSpinner />
  )
}

Spinner.propTypes = {
  condensed: PropTypes.bool,
}

export default Spinner
