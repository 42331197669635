import styled from "@emotion/styled"

export const StyledTable = styled.div`
  td,
  tr {
    padding-left: 1rem !important;
    @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      padding-left: unset !important;
    }
  }

  td,
  tr,
  span,
  div,
  p {
    font-weight: ${(p) => p.theme.fontWeights.semiBolder} !important;
    font-family: ${(p) => p.theme.fontFamilies.headline} !important;
    @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-weight: ${(p) => p.theme.fontWeights.liteMedium} !important;
      font-family: ${(p) => p.theme.fontFamilies.regular} !important;
    }
  }

  tr > td {
    height: 4rem;
  }

  tr th * {
    font-weight: ${(p) => p.theme.fontWeights.semiBolder} !important;
    font-family: ${(p) => p.theme.fontFamilies.headlineBig} !important;
  }

  tbody > tr {
    border-bottom: 1px solid ${(p) => p.theme.colors.darkGrey};
  }

  thead > tr > th:first-child {
    padding-left: 1rem !important;

    div {
      padding-left: 0;
    }

    @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      padding-left: 0 !important;
    }
  }

  .blue-text {
    color: ${(p) => p.theme.colors.blue};
  }

  .align-right {
    text-align: right !important;
    justify-content: end !important;
  }

  .nowrap {
    white-space: nowrap;
  }

  .align-center {
    text-align: center !important;
    justify-content: center !important;
  }

  .mobile-date {
    color: ${(p) => p.theme.colors.disabled};
    font-size: 0.625rem;
    font-weight: ${(p) => p.theme.fontWeights.liteMedium} !important;
    font-family: ${(p) => p.theme.fontFamilies.regular} !important;
  }

  .react-select__input-container {
    height: 0;
  }
  .react-select__input {
    height: 0;
  }

  .react-select__indicator {
    padding: 0;
  }

  .symbol {
    font-size: 1rem;
  }
  .merchant-name {
    color: ${(p) => p.theme.colors.pitchBlack} !important;
    font-family: ${(p) => p.theme.fontFamilies.headlineBig} !important;
    font-size: 0.75rem !important;
    font-weight: ${(p) => p.theme.fontWeights.liteBlack} !important;
    line-height: 1.25rem !important;
    white-space: nowrap;

    @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.5rem !important;
      line-height: 2.125rem !important;
    }
  }

  .ellipsis-text {
    text-overflow: ellipsis;
    max-width: 5rem;
    overflow: hidden;
    @media only screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      max-width: 15rem;
    }
  }

  .bold-text {
    font-family: ${(p) => p.theme.fontFamilies.headlineBig} !important;
    font-weight: ${(p) => p.theme.fontWeights.liteBlack} !important;
  }

  .merchant-type {
    color: ${(p) => p.theme.colors.concreteGrey} !important;
    font-family: ${(p) => p.theme.fontFamilies.headlineBig} !important;
    font-size: 0.625rem !important;
    font-weight: ${(p) => p.theme.fontWeights.liteBlack} !important;
    line-height: 1rem !important;

    @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1rem !important;
      line-height: 2rem !important;
    }
  }

  .info-icon {
    color: ${(p) => p.theme.colors.pacificBlue} !important;
    margin-left: 0.5rem;
  }

  .line-height {
    min-height: 4rem;
    line-height: 4rem !important;
    @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      min-height: 7.5rem;
      line-height: 7.5rem !important;
    }
  }
`

export const StyledServiceNameWrapper = styled.div`
  display: flex;
  gap: 0.75rem;
  align-items: center;
  min-height: 4rem;
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    min-height: 7.5rem;
    gap: 1.25rem;
  }
`
export const StyledLogoWrapper = styled.div`
  img {
    border-radius: 50%;
  }
`
export const StyledTextWrapper = styled.div``
export const StyledNameWrapper = styled.div`
  color: ${(p) => p.theme.colors.pitchBlack} !important;
  font-family: ${(p) => p.theme.fontFamilies.headline} !important;
  font-size: 0.75rem !important;
  font-weight: ${(p) => p.theme.fontWeights.semiBolder} !important;
  line-height: 1.25rem !important;
`
export const StyledTypeWrapper = styled.div`
  color: ${(p) => p.theme.colors.concreteGrey} !important;
  font-family: ${(p) => p.theme.fontFamilies.headlineBig} !important;
  font-size: 0.625rem !important;
  font-weight: ${(p) => p.theme.fontWeights.liteBlack} !important;
  line-height: 1rem !important;
`

export const StyledPageWrapper = styled.div`
  margin-left: -1rem;

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-left: unset;
  }

  .price-cell {
    text-wrap: nowrap;
  }
  .tooltip {
    max-width: 10rem;
    padding: 0.75rem;
  }
`

export const StyledPageTotal = styled.div`
  color: ${(p) => p.theme.colors.pitchBlack};
  text-align: right;
  font-family: ${(p) => p.theme.fontFamilies.headlineBig};
  font-size: 1rem;
  font-style: normal;
  line-height: 1.5rem;
  text-transform: uppercase;
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
`

export const StyledPageTotalNumber = styled.span`
  color: ${(p) => p.theme.colors.pacificBlue};
  text-align: right;
  font-family: ${(p) => p.theme.fontFamilies.headlineBig};
  font-size: 1rem;
  font-style: normal;
  line-height: 1.5rem;
  text-transform: uppercase;
  margin-left: 0.5rem;

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
`
