import React, { useEffect, useCallback } from "react"
import { useQuery } from "@apollo/client"
import SEO from "@tmu/components/seo"
import { FormattedMessage, useIntl, navigate } from "gatsby-plugin-intl"

import { faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons/faMapMarkerAlt"
import { faStore } from "@fortawesome/pro-light-svg-icons/faStore"
import { faExternalLink } from "@fortawesome/pro-light-svg-icons/faExternalLink"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useLocalStorage, useMediaQuery } from "beautiful-react-hooks"
import {
  Spinner,
  Logo,
  LazyImage,
  Money,
  ShareButtons,
  TruncatedText,
  Tab,
  Tabs,
  TabList,
  Panel,
} from "@tmu/components/common"
import { useShoppingCart, useLogoImage } from "@tmu/hooks"
import { useAuth } from "@tmu/hooks"
import ContentNotFound from "@tmu/components/ContentNotFound"
import {
  StyledOfferHeader,
  StyledOfferTitle,
  OfferImage,
  StyledOfferTabs,
  StyledPacText,
  StyledOfferCaption,
  StyledOfferLabel,
  OfferStats,
  StyledOfferMain,
  StyledOfferTop,
  StyledAddCartButton,
  StyledSocialShare,
  StyledRibbon,
  StyledImageBox,
} from "./index.styles"
import { MERCHANT_OFFER_DETAIL_QUERY } from "@tmu/apollo/storefront/queries/merchant"
import { getValueForLocale } from "@tmu/utils/string"
import theme from "@tmu/global/theme"
import { useOfferLink } from "@tmu/src/hooks"
const OfferDetail = ({ slug, location }) => {
  const { pathname } = location
  const { formatMessage, locale, defaultLocale } = useIntl()
  const { isAuthenticated, isLoading } = useAuth()
  const { addToCart, mutating } = useShoppingCart()
  const { getOfferLink } = useOfferLink()
  const isMedium =
    typeof window !== "undefined" && useMediaQuery("(max-width: 48rem)")
  let timeoutId = null
  const { loading, data } = useQuery(MERCHANT_OFFER_DETAIL_QUERY, {
    variables: { slug },
    skip: slug ? slug.length === 0 : true,
    onCompleted: () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
        timeoutId = null
      }

      timeoutId = setTimeout(function () {
        if (typeof window !== "undefined") {
          window.prerenderReady = true
        }
      }, 500)
    },
  })

  const merchantOffer = data?.merchantOffer
  const logoImage = useLogoImage()
  const [cachedCartItem, setCachedCartItem] = useLocalStorage(
    `cached-cart-item`,
    null
  )

  const handleAddToCart = (offer) => {
    if (!isAuthenticated) {
      setCachedCartItem(offer.slug)
      navigate(`/signin?&lang=${locale}&next=${pathname}`)
    } else {
      setCachedCartItem(null)
      addToCart({
        offer,
        quantity: 1,
        successMessage: formatMessage({
          id: "offer::detail::buttons::addedToCart",
          defaultMessage: "Added to Cart",
        }),
        errorMessage: formatMessage({
          id: "offer::detail::buttons::customError",
          defaultMessage: "An unexpected error occured.",
        }),
      })
    }
  }

  const handleExternalOffer = () => {
    if (!isAuthenticated) {
      const redirectUrl = `/${locale}/offers/detail/${slug}`
      navigate(`/signin?&lang=${locale}&next=${redirectUrl}`)
    } else {
      getOfferLink({
        variables: { offerId: merchantOffer?.id },
        onCompleted: (offerData) => {
          window.open(offerData.offerLinkWithToken.offerLink, "_blank")
        },
      })
    }
  }

  useEffect(() => {
    if (!isAuthenticated || isLoading || loading) {
      return
    }
    if ((data, cachedCartItem && cachedCartItem.length > 0)) {
      addToCart({
        offer: data?.merchantOffer,
        quantity: 1,
        successMessage: formatMessage({
          id: "offer::detail::buttons::addedToCart",
          defaultMessage: "Added to Cart",
        }),
        errorMessage: formatMessage({
          id: "offer::detail::buttons::customError",
          defaultMessage: "An unexpected error occured.",
        }),
      })
      setCachedCartItem(null)
    }
  }, [
    isAuthenticated,
    isLoading,
    loading,
    data,
    cachedCartItem,
    addToCart,
    setCachedCartItem,
  ])

  const store = merchantOffer?.store
  const category = store?.category

  const productImage = data?.merchantOffer?.image
    ? `${data.merchantOffer?.image}?width=600&height=317&quality=90`
    : logoImage

  const OfferImageSection = useCallback(
    () => (
      <OfferImage>
        <StyledImageBox>
          <LazyImage
            src={merchantOffer?.image}
            altName={`offer-detail-image-${merchantOffer?.name}`}
            width={760}
            height={427}
            fit="cover"
          />
          {merchantOffer?.isAvailable === false && (
            <StyledRibbon>
              <FormattedMessage
                id="offer::detail::notAvailable"
                defaultMessage="NOT AVAILABLE"
              />
            </StyledRibbon>
          )}
        </StyledImageBox>
        {category && (
          <StyledOfferCaption>
            <StyledOfferLabel to={`/offers?categories=${category.id}`}>
              <i className={category?.icon}></i>
              {getValueForLocale(category, "name", locale, defaultLocale)}
            </StyledOfferLabel>
            <StyledOfferLabel to={`/offers?country=${store.country}`}>
              <FontAwesomeIcon icon={faMapMarkerAlt} />
              {merchantOffer?.country}
            </StyledOfferLabel>

            {merchantOffer?.store?.name && (
              <StyledOfferLabel
                to={`/offers/store/${merchantOffer?.store?.slug}`}>
                <FontAwesomeIcon icon={faStore} />
                {merchantOffer?.store?.name}
              </StyledOfferLabel>
            )}
          </StyledOfferCaption>
        )}
      </OfferImage>
    ),
    [category, defaultLocale, locale, merchantOffer, store]
  )

  const OfferTabSection = useCallback(
    () => (
      <StyledOfferTabs data-testid="offer-detail__overview">
        <Tabs>
          <div className="tab" data-testid="offer-detail__overview__tab">
            <TabList>
              <Tab>
                <FormattedMessage
                  id="offer::detail::tabs::offer"
                  defaultMessage="Offer"
                />
              </Tab>
              {merchantOffer?.disclaimer ? (
                <Tab>
                  <FormattedMessage
                    id="offer::detail::tabs::termsConditions"
                    defaultMessage="Terms & Conditions"
                  />
                </Tab>
              ) : null}
            </TabList>
          </div>
          <Panel>
            <FormattedMessage
              id="offer::detail::description"
              defaultMessage="Offer Description"
              tagName="h6"
            />
            <div
              dangerouslySetInnerHTML={{
                __html: getValueForLocale(
                  merchantOffer,
                  "description",
                  locale,
                  defaultLocale
                ),
              }}
            />
          </Panel>
          {merchantOffer?.disclaimer ? (
            <Panel>
              <div
                dangerouslySetInnerHTML={{
                  __html: getValueForLocale(
                    merchantOffer,
                    "disclaimer",
                    locale,
                    defaultLocale
                  ),
                }}
              />
            </Panel>
          ) : null}
        </Tabs>
      </StyledOfferTabs>
    ),
    [defaultLocale, locale, merchantOffer]
  )

  const OfferHeaderSection = useCallback(
    () => (
      <StyledOfferHeader>
        <StyledOfferTitle>{merchantOffer?.name}</StyledOfferTitle>
      </StyledOfferHeader>
    ),
    [merchantOffer]
  )
  const OfferStatsSection = useCallback(
    () => (
      <OfferStats>
        <p data-testid="offer-detail__short-description">
          <TruncatedText numLines={5}>
            {getValueForLocale(
              merchantOffer,
              "shortDescription",
              locale,
              defaultLocale
            )}
          </TruncatedText>
        </p>
        <StyledPacText>
          <Logo className="pac-image" fill={theme?.colors?.blue} />
          <FormattedMessage
            id="offer::detail::externalbuyPAC"
            defaultMessage="You can use <amount>{value}</amount> of <pacs>PACs</pacs> on this offers."
            tagName="p"
            values={{
              pacs: (...chunks) => <span key="pacs"> {chunks}</span>,
              value: (
                <Money
                  key="pacDiscount"
                  value={merchantOffer?.maxPacDiscount}
                  style="percent"
                  currency={false}
                />
              ),
              amount: (...chunks) => (
                <span key="amount" className="pac-amount">
                  {chunks}
                </span>
              ),
            }}>
            {(...chunks) =>
              chunks.map((chunk, i) => <span key={i}>{chunk}</span>)
            }
          </FormattedMessage>
        </StyledPacText>
        {merchantOffer?.isAvailable && (
          <StyledAddCartButton
            data-testid="btn-add-to-cart-external"
            color="red"
            className="offer--support-button"
            onClick={() => handleExternalOffer()}>
            <FontAwesomeIcon icon={faExternalLink} />
            <FormattedMessage
              id="offer::detail::buttons::externalCheckout"
              defaultMessage="Visit Page"
            />
          </StyledAddCartButton>
        )}

        <StyledSocialShare>
          <FormattedMessage
            id="offer::detail::shareoffer"
            defaultMessage="Share this offer"
            tagName="p"
          />
          <ShareButtons
            url={location.href}
            tags={["TMU", "TrustMeUp", slug]}
            slug={slug}
            name={merchantOffer?.store?.name}
          />
        </StyledSocialShare>
      </OfferStats>
    ),
    [
      defaultLocale,
      handleAddToCart,
      handleExternalOffer,
      locale,
      location.href,
      merchantOffer,
      mutating,
      slug,
    ]
  )

  return (
    <>
      <SEO
        lang={locale}
        title={merchantOffer?.name}
        description={merchantOffer?.shortDescription}
        image={productImage}
        pathname={pathname}
      />
      {loading ? (
        <Spinner />
      ) : merchantOffer ? (
        <>
          <StyledOfferTop />
          <StyledOfferMain>
            {isMedium ? (
              <>
                <OfferHeaderSection />
                <OfferImageSection />
                <OfferStatsSection />
                <OfferTabSection />
              </>
            ) : (
              <>
                <div>
                  <OfferImageSection />
                  <OfferTabSection />
                </div>
                <div>
                  <OfferHeaderSection />
                  <OfferStatsSection />
                </div>
              </>
            )}
          </StyledOfferMain>
        </>
      ) : (
        <ContentNotFound slug={slug} />
      )}
    </>
  )
}

export default OfferDetail
