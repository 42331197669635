import React, { useState, useEffect } from "react"
import { FormattedMessage, useIntl, Link } from "gatsby-plugin-intl"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import queryString from "query-string"
import { email, passwordRequired } from "@tmu/utils/validation"
import { useAuth, useToast } from "@tmu/hooks"
import { useMutation } from "@apollo/client"
import { RESEND_VERIFICATION_EMAIL } from "@tmu/apollo/storefront/mutations/user"
import { useApolloApiClients } from "@tmu/apollo/client"
import {
  TextInput,
  Button,
  BaseError,
  SocialLoginComponent,
} from "@tmu/components/common"
import SEO from "@tmu/components/seo"
import {
  StyledSignInWrapper,
  SignInTitle,
  StyledLinkSection,
  StyledSignInButtonContainer,
} from "./index.styles"
import theme from "@tmu/global/theme"
import { get } from "@tmu/utils/storage"
import { isBrowser } from "@tmu/utils/auth"

const SignInForm = ({ location }) => {
  const { signIn, loading } = useAuth()
  const [loginErrors, setLoginErrors] = useState(null)
  const savedEmail = isBrowser ? get("email") : ""
  const remember = isBrowser ? get("remember") : false
  const { formatMessage, locale } = useIntl()
  const [isUserVerified, setIsUserVerified] = useState(true)
  const { storefrontClient } = useApolloApiClients()
  const { success } = useToast()
  const [showPassword, setShowPassword] = useState(false)

  const initialValues = {
    email: savedEmail ?? "",
    password: "",
    remember,
  }

  const [callReSendVerificationEmail, { data, errors }] = useMutation(
    RESEND_VERIFICATION_EMAIL,
    {
      client: storefrontClient,
    }
  )

  const validationSchema = Yup.object().shape({
    email: email({ formatMessage }),
    password: passwordRequired({ formatMessage }),
  })

  const params = queryString.parse(location.search, { arrayFormat: "comma" })
  const next = params?.next?.replace(/\/(en|it|es)/g, "") ?? "/"
  const nextUrl = queryString.stringifyUrl({
    url: next,
    query: params,
  })
  const handleResendVerificationEmail = (email) => {
    callReSendVerificationEmail({
      variables: {
        input: { email: email, captcha: "captcha" },
        isPublic: true,
      },
    }).then((data) => {
      if (data?.data?.resendActivationEmail?.success === true) {
        const successMessage = formatMessage({
          id: "passwordRecovery::sentEmailSuccessfully",
          defaultMessage: "Email is sent successfully",
        })

        success(successMessage)
      } else if (data?.data?.resendActivationEmail?.errors?.length) {
        setLoginErrors(data?.data?.resendActivationEmail?.errors)
      }
    })
  }

  const handleSubmit = (values, form) => {
    form.setSubmitting(true)
    setLoginErrors([])
    signIn({
      ...values,
      next: nextUrl,
    })
      .then((res) => {
        let incomingErrors = []
        if (res?.nonFieldErrors) {
          res.nonFieldErrors.map((item) => incomingErrors.push(item.message))

          const hasVerifiedError = res.nonFieldErrors.some(
            (t) => t.code === "not_verified"
          )

          setIsUserVerified(!hasVerifiedError)

          setLoginErrors(incomingErrors)
        }
      })
      .catch((err) => {
        setLoginErrors([err.message])
      })
      .finally(() => {
        form.setSubmitting(false)
      })
  }

  useEffect(() => {
    setTimeout(() => {
      try {
        document.getElementById("email")?.click()
        document.getElementById("password")?.click()
      } catch (error) {}
    }, 1000)
  }, [])

  return (
    <StyledSignInWrapper>
      <SEO
        lang={locale}
        title={formatMessage({
          id: "nav::signin",
          defaultMessage: "SignIn",
        })}
      />

      <div>
        <div data-testid="text-welcome">
          <SignInTitle>
            <FormattedMessage id="signIn::title" defaultMessage="Sign in" />
          </SignInTitle>
        </div>
        <p>
          <FormattedMessage
            id="signIn::dontHaveAccount"
            defaultMessage="Don't have an account?"
          />{" "}
          <Link to={`/signup`} data-testid="text-signup" aria-label="Sign Up">
            <FormattedMessage id="signIn::signUp" defaultMessage="Sign Up" />
          </Link>
        </p>
        <SocialLoginComponent />
        <hr />
        <Formik
          enableReinitialize={true}
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={handleSubmit}>
          {({
            values,
            handleChange,
            handleBlur,
            isSubmitting,
            touched,
            errors,
          }) => {
            return (
              <Form className="user-form">
                <TextInput
                  newDesign
                  data-testid="input-email"
                  id="email"
                  name="email"
                  type="email"
                  onChange={(e) => {
                    const lowerCaseEmail = e.target.value.toLowerCase()
                    handleChange({
                      target: {
                        name: e.target.name,
                        value: lowerCaseEmail,
                      },
                    })
                  }}
                  onBlur={handleBlur}
                  value={values?.email}
                  label={formatMessage({
                    id: "signUp::email",
                    defaultMessage: "Email",
                  })}
                  placeholder={formatMessage({
                    id: "signUp::emailPlaceholder",
                    defaultMessage: "Type your email",
                  })}
                  error={errors.email}
                  touched={touched.email}
                />
                <TextInput
                  newDesign
                  data-testid="input-password"
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.password}
                  label={formatMessage({
                    id: "signUp::password",
                    defaultMessage: "Password",
                  })}
                  placeholder={formatMessage({
                    id: "signUp::password",
                    defaultMessage: "Password",
                  })}
                  error={errors.password}
                  touched={touched.password}
                  inputIcon={
                    <FontAwesomeIcon
                      icon={showPassword ? faEyeSlash : faEye}
                      className="input-icon"
                      color={theme.colors.blue}
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  }
                  iconRight={0.9375}
                />
                {loginErrors?.length
                  ? loginErrors.map((_, i) => (
                      <BaseError key={i} message={loginErrors} />
                    ))
                  : null}
                <StyledSignInButtonContainer>
                  <Button
                    label="signIn"
                    data-testid="btn-submit"
                    color="blue"
                    type="submit"
                    disabled={isSubmitting || loading}>
                    <FormattedMessage
                      id="signIn::signIn"
                      defaultMessage="Sign In"
                    />
                  </Button>

                  {isUserVerified === false && (
                    <Button
                      label="resendVerification"
                      data-testid="btn-resend-verification"
                      color="green"
                      type="button"
                      onClick={() =>
                        handleResendVerificationEmail(values?.email)
                      }>
                      <FormattedMessage
                        id="signIn::resendVerification"
                        defaultMessage="Resend Verification Email"
                      />
                    </Button>
                  )}
                </StyledSignInButtonContainer>
                <StyledLinkSection>
                  <p>
                    <Link
                      to={`/password-recovery`}
                      data-testid="text-recover"
                      aria-label="Recover Here"
                      className="forgot-password">
                      <FormattedMessage
                        id="signIn::forgotPassword"
                        defaultMessage="Forgot password?"
                      />
                    </Link>
                  </p>
                </StyledLinkSection>
              </Form>
            )
          }}
        </Formik>
      </div>
    </StyledSignInWrapper>
  )
}

export default SignInForm
