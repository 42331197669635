import { REQUIRED_FIELD_SYMBOL } from "@tmu/apollo/constants"
import { Dropdown, FieldError, TextInput } from "@tmu/components/common"
import { Spacer } from "@tmu/global/page-addons/detail-page.styles"
import { useCountries } from "@tmu/hooks"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { useFormikContext } from "formik"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { useEffect } from "react"
import { StyledFormRow, StyledSingleDonation } from "./index.styles"
import IndividualCompanyForm from "./IndividualCompanyForm"

const SingleDonation = ({ isCompany, isPhoneRequired, isPECRequired }) => {
  const { formatMessage } = useIntl()
  const { countries, countryOptions, getCountries } = useCountries()

  useEffect(() => {
    !countryOptions?.length && getCountries()
  }, [])

  const {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    setTouched,
  } = useFormikContext()

  const { isTablet, isDesktop, isWide } = getAllScreenTypes()

  return (
    <StyledSingleDonation>
      <TextInput style={{ display: "none" }} newDesign />
      <IndividualCompanyForm
        isCompany={isCompany}
        isPhoneRequired={isPhoneRequired}
      />
      <Spacer bottom={isWide ? 2.5 : isTablet || isDesktop ? 2 : 1.5} />

      {values?.isNeedInvoice && (
        <>
          <StyledFormRow>
            <p className="caption">
              <FormattedMessage
                id="dashboard::forms::country"
                defaultMessage="Country"
              />
              {REQUIRED_FIELD_SYMBOL}
            </p>
            <Dropdown
              newDesign
              data-testid="country"
              id="country"
              name="country"
              onBlur={handleBlur}
              value={countries?.find(
                (item) => (item?.id || item?.shortCode) === values?.country
              )}
              options={countryOptions}
              placeholder={formatMessage({
                id: "services::purchaseForm::yourCountry",
                defaultMessage: "Your country",
              })}
              defaultSelectStyles={{
                control: {
                  height: isWide ? "3.375rem" : "3rem",
                  paddingTop: "0",
                  paddingRight: "0",
                },
                valueContainer: {
                  height: isWide ? "3.375rem" : "3rem",
                  paddingTop: "0",
                },
              }}
              onChange={(selected) => {
                setFieldValue("country", selected?.value, true)
                setTouched({ ...touched, country: true }, true)
              }}
            />
            <FieldError name="country" />
          </StyledFormRow>
          <StyledFormRow>
            <p className="caption">
              <FormattedMessage
                id="dashboard::forms::address"
                defaultMessage="Address"
              />
              {REQUIRED_FIELD_SYMBOL}
            </p>
            <TextInput
              newDesign
              data-testid="addressLine1"
              id="addressLine1"
              name="addressLine1"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.addressLine1}
              placeholder={formatMessage({
                id: "services::purchaseForm::yourAddress",
                defaultMessage: "Your address",
              })}
            />
            <FieldError name="addressLine1" />
          </StyledFormRow>
          <StyledFormRow>
            <p className="caption">
              <FormattedMessage
                id="services::purchaseForm::postcode"
                defaultMessage="Postcode"
              />
              {REQUIRED_FIELD_SYMBOL}
            </p>
            <TextInput
              newDesign
              data-testid="postCode"
              id="postCode"
              name="postCode"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.postCode}
              placeholder={formatMessage({
                id: "services::purchaseForm::yourPostalCode",
                defaultMessage: "Your postcode",
              })}
            />
            <FieldError name="postCode" />
          </StyledFormRow>
        </>
      )}
      {isCompany && isPECRequired && values?.isNeedInvoice && (
        <>
          <StyledFormRow>
            <p className="caption">
              <FormattedMessage
                id="services::purchaseForm::certifiedBusinessEmail"
                defaultMessage="Certified business email (PEC) only for companies"
              />
            </p>
            <TextInput
              newDesign
              data-testid="businessEmailAddress"
              id="certifiedBusinessEmail"
              name="certifiedBusinessEmail"
              placeholder={formatMessage({
                id: "services::purchaseForm::yourCertifiedBusinessEmail",
                defaultMessage: "Your certified business email address (PEC)",
              })}
              onBlur={handleBlur}
              onChange={handleChange}
              defaultValue={values.certifiedBusinessEmail}
              value={values.certifiedBusinessEmail}
            />
            <FieldError name="certifiedBusinessEmail" />
          </StyledFormRow>
          <StyledFormRow>
            <p className="caption">
              <FormattedMessage
                id="services::purchaseForm::confirmCertifiedBusinessEmail"
                defaultMessage="Confirm certified business email (PEC)"
              />
            </p>
            <TextInput
              newDesign
              data-testid="businessEmailAddressConfirm"
              id="certifiedBusinessEmailConfirm"
              name="certifiedBusinessEmailConfirm"
              placeholder={formatMessage({
                id: "services::purchaseForm::yourCertifiedBusinessEmail",
                defaultMessage: "Your certified business email address (PEC)",
              })}
              onBlur={handleBlur}
              onChange={handleChange}
              onPaste={(e) => {
                e.preventDefault()
                return false
              }}
              defaultValue={values.certifiedBusinessEmailConfirm}
              value={values.certifiedBusinessEmailConfirm}
            />
            <FieldError name="certifiedBusinessEmailConfirm" />
          </StyledFormRow>
        </>
      )}
    </StyledSingleDonation>
  )
}

export default SingleDonation
