import React, { useRef, useEffect } from "react"
import { useLocation } from "@reach/router"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { ShareButtons } from "@tmu/components/common"
import { useSiteMetadata } from "@tmu/hooks"
import { StyledShareSocial, StyledSliceShareCampaign } from "./index.styles"
import { isInViewport } from "@tmu/utils/viewport"

const SliceShareCampaign = ({ slug }) => {
  const { locale } = useIntl()
  const { siteUrl } = useSiteMetadata()
  const ref = useRef()
  const { hash } = useLocation()

  const hashCallback = () => {
    const hashText = "#share-campaign"
    const isHashInUrl = hash === hashText || window?.location?.hash === hashText
    if (isInViewport(ref) && !isHashInUrl)
      history.pushState(null, null, hashText)
  }

  useEffect(() => {
    document.addEventListener("scroll", hashCallback)
    return () => document.removeEventListener("scroll", hashCallback)
  }, [])

  return (
    <StyledSliceShareCampaign id="share-campaign" ref={ref}>
      <div>
        <FormattedMessage
          id="campaigns::share::title"
          defaultMessage="Share this campaign"
          tagName="h3"
        />
        <FormattedMessage
          id="campaigns::share::description"
          defaultMessage="Like, tweet or post a story to let more people know and support this campaign to help them reach their goals faster."
          tagName="p"
        />
        <StyledShareSocial>
          <ShareButtons
            url={`${siteUrl}/${locale}/campaigns/${slug}/`}
            tags={["TMU", "TrustMeUp", slug]}
          />
        </StyledShareSocial>
      </div>
    </StyledSliceShareCampaign>
  )
}

export default SliceShareCampaign
