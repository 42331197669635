import styled from "@emotion/styled"
import { Button } from "@tmu/components/common"
import { css } from "@emotion/react"

export const StyledCampaignsWrapper = styled.div`
  margin: 3rem auto 0;
  padding: 0;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin: 5rem auto 0;
  }
  > div > div {
    margin: auto;
    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      max-width: ${(p) => p.theme.breakpoints.tablet};
      padding: 0 1.5rem;
    }
    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      max-width: ${(p) => p.theme.breakpoints.desktop};
      padding: 0 2rem;
    }
    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      max-width: ${(p) => p.theme.breakpoints.wide};
      padding: 0 6rem;
    }
  }
`

export const StyledCampaignsBody = styled.div`
  background: ${(p) => p.theme.colors.whiteSmoke};
  padding-top: ${(p) => (p.hasCorporateCampaigns ? "unset" : "0.75rem")};
  padding-bottom: 2rem;
  > div {
    display: block;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding-top: 1rem;

    > div {
      display: grid;
      grid-template-columns: 10.4375rem auto;
      grid-gap: 1rem;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    > div {
      grid-template-columns: 12rem auto;
      grid-gap: 1.5rem;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding-top: 2rem;
    > div {
      grid-template-columns: 24.375rem auto;
      grid-gap: 3rem;
    }
  }
`
export const StyledHeading = styled.div`
  background: ${(p) => p.theme.colors.white};
  padding: 1rem 0;
  svg {
    max-width: 1.625rem;
  }

  h3 {
    font-size: 1.5rem;
    margin: 0;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 1.5rem 0;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    padding: 2rem 0;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding: 2.5rem 0;
    h3 {
      font-size: 2rem;
      line-height: 2.625rem;
    }
  }
`
export const StyledHeadingTop = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 1rem;

  margin-bottom: 0.9375rem;
  padding: 0 1rem;
  svg {
    margin-right: 0.625rem;
    color: ${(p) => p.theme.colors.carrot};
    font-size: 0.75rem;
    height: 0.75rem;
  }
  a {
    margin: 0.3125rem 0 0;
    align-self: center;
  }
  h5 {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.blue};
    font-size: 0.75rem;
    cursor: pointer;
    justify-self: end;
    align-self: center;
    text-align: right;
    margin: 0;
  }
  > div {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 0;
    //grid-template-columns: 167px auto;
    grid-gap: 1.5rem;
    margin-bottom: 1.125rem;

    > div {
      justify-content: space-between;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    //grid-template-columns: 192px auto;
    margin-bottom: 1.5rem;

    > div {
      svg {
        font-size: 1rem;
        height: 1rem;
        line-height: 1.5rem;
      }
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    //grid-template-columns: 390px auto;
    grid-gap: 3rem;
    margin-bottom: 3rem;

    svg,
    h5 {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
  }
`

export const StyledListingFilters = styled.div`
  display: none;
  hr {
    margin: 0.8125rem 0;
  }
  > p {
    //uppercase header
    margin: 0 0 1rem;
    text-transform: uppercase;
    font-weight: ${(p) => p.theme.fontWeights.liteBlack};
    font-size: 0.75rem;
    line-height: 1.25rem;
    letter-spacing: 0.03em;
    color: ${(p) => p.theme.colors.disabled};
    font-family: ${(p) => p.theme.fontFamilies.headlineBig};
  }

  > div {
    //checkbox filters
    border: 0.0625rem solid ${(p) => p.theme.colors.darkGrey};
    border-radius: 0.25rem;
    padding: 1rem 0.75rem 0.875rem;
    margin-bottom: 1.5rem;
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: ${(p) => p.theme.colors.black};

    .filter-checkbox {
      padding: 0;
      width: 100%;
      font-size: 1rem;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 2.25rem;
      > span {
        top: 0;
        display: block;
        position: relative;
        float: right;
        min-width: 1.25rem;
      }
    }
    .last {
      min-height: 1.25rem;
      padding-bottom: 0.5rem;
    }
  }

  .category-filters button {
    font-family: ${(p) => p.theme.fontFamilies.regular};
    padding-left: 0;
    margin: 0;
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    font-size: 0.75rem;
    line-height: 1.125rem;
    height: 2.5rem;
    white-space: pre-wrap;
    text-align: left;
    color: ${(p) => p.theme.colors.disabled};
    i {
      margin-left: 0;
      margin-right: 0.6rem;
      width: 1rem;
    }
    svg {
      margin-left: 0.25rem;
      padding-top: 0.125rem;
      font-size: 1.2rem;
      height: 1.2rem;
    }
  }
  .charities span {
    margin-left: 0.125rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: block;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    > p {
      font-size: 1.25rem;
      line-height: 2rem;
    }
    > div {
      //checkbox filters
      font-size: 1.5rem;
      line-height: 2.25rem;
      padding: 1.5rem;

      .filter-checkbox {
        min-height: 2.25rem;
        margin: 1.5rem 0;
        > span {
          min-width: 2rem;
        }
      }
      hr {
        margin: 1.5rem 0;
      }

      .checkmark {
        height: 2rem;
        width: 2rem;
        :after {
          left: 0.55rem;
          top: 0.04rem;
          width: 0.6rem;
          height: 1.125rem;
        }
      }
    }
    .category-filters button {
      font-family: ${(p) => p.theme.fontFamilies.regular};
      font-size: 1.5rem;
      line-height: 2.25rem;
      height: 4.25rem;
      i {
        margin-left: 0;
        margin-right: 1rem;
        width: 2rem;
      }
      svg {
        padding-top: 0.375rem;
        font-size: 2rem;
        height: 2rem;
      }
    }
  }
`

export const StyledContent = styled.div`
  > div {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    > div {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    > div {
      display: flex;
      flex-wrap: wrap;
      gap: 1.25rem 0.75rem;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    > div {
      display: flex;
      flex-wrap: wrap;
      gap: 1.5rem;
    }
  }
`

export const StyledCampaignTitle = styled.div``
export const StyledCampaignNumber = styled.div``

export const StyledCampaignList = styled.div``

export const CampaignListAction = styled.div`
  width: 100%;
  margin: 2rem 0;
  > div {
    padding: 0;
  }
`

export const NoResults = styled.div`
  display: grid;
  grid-gap: 1rem;

  padding: 0 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: unset;
  }

  h6 {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }

  p {
    font-size: 1rem;
    line-height: 1.375rem;
  }
`

export const CampaignListButton = styled(Button)``

export const ShowAllButton = styled(Button)`
  margin-bottom: 1rem;
  ${(p) =>
    p.active &&
    css`
      color: ${p.theme.colors.pitchBlack} !important;
    `}
`

export const StyledCategoryItem = styled.div``

export const StyledLink = styled.a``

export const StyledEmptyPlaceholder = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 4rem;
    height: 4rem;
    margin-bottom: 1rem;
    opacity: 0.25;
  }
`

export const StyledFilterBar = styled.div``

export const StyledOnlyPacsToggle = styled.div`
  padding-top: 0 !important;
  margin-top: -0.5rem !important;
  border: none !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.75rem;
  line-height: 1.25rem;
  background: none !important;
  &:hover {
    background: none !important;
  }
  &.first,
  &.second {
    padding-bottom: 0;
  }
`
