import styled from "@emotion/styled"
export const StyledSliderWrapper = styled.div`
  .tooltip {
  }

  .left-label {
    padding-left: 1rem;
  }

  .rc-slider-tooltip-inner {
    background-color: ${(p) => p.theme.colors.white};
    color: ${(p) => p.theme.colors.pitchBlack};
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-size: 0.75rem;
    line-height: 1.25rem;
    width: 3rem;
    height: 1.75rem;
    box-shadow: 0px 2px 16px ${(p) => p.theme.colors.disabledOpacity};
    border-radius: 0.25rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      width: 4rem;
      height: 2.5rem;
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }

  .rc-slider-track {
    background-color: ${(p) => p.theme.colors.carrot};
  }
  .rc-slider-tooltip-arrow {
    border-top-color: ${(p) => p.theme.colors.white} !important;
  }
  .right-label {
    padding-right: 1rem;
  }
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding-left: 0;
    padding-right: 0;
  }
`
export const StyledMarkLabel = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${(p) => p.theme.colors.pitchBlack};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  font-family: ${(p) => p.theme.fontFamilies.regular};
  margin-top: 0.25rem;
  white-space: nowrap;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2.125rem;
  }
`
export const StyledMarkValue = styled.span`
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: ${(p) => p.theme.colors.pitchBlack};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  font-family: ${(p) => p.theme.fontFamilies.regular};
  text-align: center;
  width: 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1rem;
    line-height: 1.5rem;
  }
`
