import React, { useState, useRef, useEffect } from "react"
import { FormattedMessage, navigate, useIntl } from "gatsby-plugin-intl"
import { faShoppingCart } from "@fortawesome/pro-regular-svg-icons/faShoppingCart"
import { faShoppingCart as fadShoppingCart } from "@fortawesome/pro-duotone-svg-icons/faShoppingCart"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useLocation } from "@reach/router"
import queryString from "query-string"
import { useLazyQuery } from "@apollo/client"

import {
  Button,
  Money,
  TermsAndConditions,
  Spinner,
  CustomModal,
} from "@tmu/components/common"
import SEO from "@tmu/components/seo"
import { useShoppingCart, useAuth } from "@tmu/hooks"
import { ALL_ORDERS } from "@tmu/hooks/useAllOrders"
import { loadStripeInstance } from "@tmu/utils/stripe"

import CartTable from "./CartTable.js"
import CartSummary from "./CartSummary.js"
import {
  StyledWrapper,
  StyledCartHeader,
  StyledCartSummarySection,
  StyledCartFaq,
  StyledCartContent,
  StyledAmount,
  StyledSubTotalAmount,
  StyledCartActions,
  StyledCartProceed,
  StyledHelpText,
  StyledEmptyTablePlaceholder,
  StyledModalActions,
} from "./index.styles"
import { useApolloApiClients } from "@tmu/apollo/client"
import { modalStyles } from "@tmu/global/GlobalStyle"

const ShoppingCart = () => {
  const location = useLocation()
  const { formatMessage, locale } = useIntl()
  const { isLoading, isAuthenticated } = useAuth()
  const [agreeTerms, setAgreeTerms] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false)
  const [removalItem, setRemovalItem] = useState(null)
  const selectedCartItemIdRef = useRef(null)
  const paramOptions = { arrayFormat: "comma" }
  const params = queryString.parse(location.search, paramOptions)
  const { storefrontClient } = useApolloApiClients()
  loadStripeInstance(null, locale)
  const [callAllOrders, { data, loading: loadingAllOrders, called }] =
    useLazyQuery(ALL_ORDERS, {
      variables: {
        status_In: ["IN_PROGRESS"],
        first: 1,
        internal: true,
      },
      client: storefrontClient,
    })

  useEffect(() => {
    if (isLoading || !isAuthenticated) {
      return
    }

    if (!called) {
      callAllOrders()
    }
  }, [isLoading, isAuthenticated])

  const { removeFromCart, setQuantity, mutating } = useShoppingCart()

  const handleProceedToCheckout = () => {
    navigate(`/offers/checkout`)
  }

  const handleContinueShopping = () => {
    navigate(`/offers?offer=true&store=true`)
  }

  const handleRemove = ({ productId, cartItemId }) => {
    setRemovalItem({ productId, cartItemId })
    setModalOpen(true)
    selectedCartItemIdRef.current = cartItemId
  }

  const handleModalConfirm = () => {
    removeFromCart(removalItem)
    setRemovalItem(null)
    setModalOpen(false)
  }

  const handleModalClose = () => {
    selectedCartItemIdRef.current = null
    setRemovalItem(null)
    setModalOpen(false)
  }

  const order = data?.allOrders?.edges?.[0]?.node
  const total = order?.total ?? 0
  const subtotal = order?.subtotal ?? 0
  const pacDiscountAmount = order?.discountAmount ?? 0
  const cartItems = !order?.store?.id ? order?.cartItems?.edges : []
  const cartItemCount = cartItems?.length || 0
  const isCartEmpty = cartItemCount === 0

  return (
    <>
      <SEO
        lang={locale}
        title={formatMessage({
          id: "cart::header::shoppingCart",
          defaultMessage: "Shopping Cart",
        })}
      />
      <StyledCartHeader>
        <h1>
          <FontAwesomeIcon icon={faShoppingCart} />
          <FormattedMessage
            id="cart::header::shoppingCart"
            defaultMessage="Shopping Cart"
          />
        </h1>
      </StyledCartHeader>
      <StyledWrapper empty={isCartEmpty}>
        {loadingAllOrders ? (
          <Spinner condensed />
        ) : (
          <StyledCartContent>
            {isCartEmpty ? (
              <StyledEmptyTablePlaceholder>
                <FontAwesomeIcon icon={fadShoppingCart} />
                {params?.addressUpdate ? (
                  <FormattedMessage
                    id="cartTable::addressUpdated"
                    defaultMessage="Your cart has been reset due to address update. Continue shopping to see available offers for your country."
                    tagName="p"
                  />
                ) : (
                  <FormattedMessage
                    id="cartTable::emptyPlaceholder"
                    defaultMessage="Your cart is empty"
                  />
                )}
                <Button
                  variant="outlined"
                  label="Continue Shopping"
                  color="green"
                  onClick={handleContinueShopping}>
                  <FormattedMessage
                    id="cart::detail::buttons::continueShopping"
                    defaultMessage="Continue Shopping"
                  />
                </Button>
              </StyledEmptyTablePlaceholder>
            ) : (
              <>
                <CartTable
                  cartItems={cartItems}
                  removeFromCart={handleRemove}
                  setQuantity={setQuantity}
                  disabled={mutating}
                  isEditable={true}
                  selectedCartItemId={selectedCartItemIdRef.current}
                />
                <StyledSubTotalAmount>
                  <span>
                    <FormattedMessage
                      id="cart::detail::subTotal"
                      defaultMessage="Subtotal ({totalItems})"
                      values={{
                        totalItems: (
                          <FormattedMessage
                            id="cart::detail::itemTotalNumber"
                            values={{ itemTotalNumber: cartItemCount }}
                            defaultMessage="{itemTotalNumber, plural, =0 {No items} =1 {1 item} other {# items}}"
                          />
                        ),
                      }}
                    />
                  </span>
                  <StyledAmount strong>
                    <Money value={subtotal} tagName="p" />
                  </StyledAmount>
                </StyledSubTotalAmount>
                <StyledCartActions>
                  <StyledHelpText>
                    <FormattedMessage
                      id="cart::summary::cartDefinition"
                      defaultMessage="The Cart is a temporary place to store a list of your items and reflects each item's most recent price."
                      tagName="p"
                    />
                  </StyledHelpText>
                  <Button
                    label="Continue Shopping"
                    color="black"
                    onClick={handleContinueShopping}>
                    <FormattedMessage
                      id="cart::detail::buttons::continueShopping"
                      defaultMessage="Continue Shopping"
                    />
                  </Button>
                </StyledCartActions>
              </>
            )}
          </StyledCartContent>
        )}
        {!isCartEmpty && !loadingAllOrders && (
          <StyledCartSummarySection>
            <CartSummary
              subtotal={subtotal}
              total={total}
              pacDiscountAmount={pacDiscountAmount}
            />
            <StyledCartProceed>
              <TermsAndConditions
                name="agreeTerms"
                checked={agreeTerms}
                onChange={() => setAgreeTerms(!agreeTerms)}
              />
              <Button
                label="Coming Soon"
                color="red"
                className={`proceed-button ${
                  process.env.IS_DISABLE_PAYMENT === "true" ? "disabled" : ""
                }`}
                disabled={!agreeTerms || mutating}
                data-testid="button-proceed"
                onClick={handleProceedToCheckout}>
                {process.env.IS_DISABLE_PAYMENT === "true" ? (
                  <FormattedMessage
                    id="button::text::comingSoon"
                    defaultMessage="Coming Soon"
                  />
                ) : (
                  <FormattedMessage
                    id="cart::detail::buttons::proceedToCheckout"
                    defaultMessage="Proceed to checkout"
                  />
                )}
              </Button>
            </StyledCartProceed>

            <StyledCartFaq>
              <FormattedMessage
                id="offer::purchaseForm::spendYourPACs"
                defaultMessage="Spend your PACs at our gift card store"
                tagName="li"
              />
              <FormattedMessage
                id="offer::purchaseForm::chooseFrom"
                defaultMessage="Choose from a big variety of brands"
                tagName="li"
              />
              <FormattedMessage
                id="offer::purchaseForm::enjoyWide"
                defaultMessage="Enjoy a wide offer offer"
                tagName="li"
              />
            </StyledCartFaq>
          </StyledCartSummarySection>
        )}
      </StyledWrapper>
      <CustomModal
        isOpen={isModalOpen}
        style={modalStyles}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        onRequestClose={handleModalClose}>
        <FormattedMessage
          id="cart::modal::confirmDelete"
          defaultMessage="Are you sure to remove this offer?"
          tagName="p"
        />
        <StyledModalActions>
          <Button label="Yes" color="red" onClick={handleModalConfirm}>
            <FormattedMessage id="cart::modal::yes" defaultMessage="Yes" />
          </Button>
          <Button label="No" variant="outlined" onClick={handleModalClose}>
            <FormattedMessage id="cart::modal::no" defaultMessage="No" />
          </Button>
        </StyledModalActions>
      </CustomModal>
    </>
  )
}

export default ShoppingCart
