import React, { useState } from "react"
import {
  StyledFilter,
  StyledFilterItem,
  StyledPageWrapper,
} from "./index.styles"
import { FormattedMessage } from "gatsby-plugin-intl"

export const MERCHANT_TYPE = {
  ONLINE: 0,
  OFFLINE: 1,
}

const OnlineOfflineMerchantFilter = ({ onChange, isOnlineMerchant }) => {
  const [active, setActive] = useState(
    isOnlineMerchant !== false ? MERCHANT_TYPE.ONLINE : MERCHANT_TYPE.OFFLINE
  )

  const handleChange = (activeIndex) => {
    setActive(activeIndex)
    if (typeof onChange == "function") {
      onChange(activeIndex)
    }
  }

  return (
    <StyledPageWrapper>
      <StyledFilter>
        <StyledFilterItem
          className={`left ${active === MERCHANT_TYPE.ONLINE && "active"}`}
          onClick={() => handleChange(MERCHANT_TYPE.ONLINE)}>
          <div>
            <FormattedMessage
              id="shop::filters::onlineStores"
              defaultMessage="On-line stores"
            />
          </div>
        </StyledFilterItem>
        <StyledFilterItem
          className={`right ${active === MERCHANT_TYPE.OFFLINE && "active"}`}
          onClick={() => handleChange(MERCHANT_TYPE.OFFLINE)}>
          <div>
            <FormattedMessage
              id="shop::filters::offlineStores"
              defaultMessage="Off-line stores"
            />
          </div>
        </StyledFilterItem>
      </StyledFilter>
    </StyledPageWrapper>
  )
}

export default OnlineOfflineMerchantFilter
