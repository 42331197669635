const theme = {
  breakpoints: {
    phone: "22.5rem",
    tablet: "48rem",
    desktop: "64rem",
    wide: "120rem",
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fontFamilies: {
    headlineBig: "Open Sans ExtraBold, sans-serif",
    headline: "Open Sans Bold, sans-serif",
    regular: "Open Sans, sans-serif",
  },
  fontSizes: [12, 14, 16, 20, 24, 36, 48, 80, 96],
  fontStyles: {
    normal: "normal",
  },
  fontWeights: {
    light: 300,
    regular: "normal",
    liteMedium: 400,
    medium: 500,
    semiBold: 600,
    semiBolder: 700,
    liteBlack: 800,
    black: 900,
    bold: "bold",
  },
  lineHeights: {
    solid: 1,
    title: 1.25,
    copy: 1.5,
  },
  colors: {
    transparent: "transparent",
    red: "rgba(233, 69, 69, 1)",
    carrot: "rgba(226, 95, 74, 1)",
    blue: "rgba(10, 157, 189, 1)",
    green: "rgba(0, 160, 192, 1)",
    yellow: "rgba(255, 143, 0, 1)",
    gemYellow: "rgba(245, 153, 14, 1)",
    facebook: "rgba(66, 103, 178, 1)",
    celticGreen: "rgba(26, 55, 64, 1)",
    racingGreen: "rgba(8, 13, 12, 1)",
    doveGray: "rgba(114, 110, 108, 1)",
    darkGrey: "rgba(217, 217, 217, 1)",
    black: "rgba(7, 13, 11, 1)",
    pitchBlack: "rgba(0, 0, 8, 1)",
    white: "rgba(255, 255, 255, 1)",
    gondola: "rgba(13, 8, 9, 1)",
    gray: "rgba(226, 226, 226, 1)",
    cottonSeed: "rgba(189, 186, 184, 1)",
    gallery: "rgba(239, 239, 239, 1)",
    dimGray: "rgba(99, 106, 117, 1)",
    concreteGrey: "rgba(122, 119, 118, 1)",
    pacificBlue: "rgba(11, 157, 188, 1)",
    softGreen: "rgba(29, 158, 188, 1)",
    grayApprox: "rgba(133, 129, 129, 1)",
    shadowConcreteGrey: "rgba(122, 119, 118, 0.7)",
    lightShadowConcreteGrey: "rgba(122, 119, 118, 0.25)",
    pampasBackgroundColor: "rgba(250, 249, 247, 1)",
    grayedBackgroundColor: "rgba(246, 246, 246, 1)",
    tertiaryBrandColor: "rgba(83, 167, 185, 0.1)",
    primaryTextColor: "rgba(0, 0, 0, 1)",
    primaryTextColorLight: "rgba(0, 0, 0, 0.15)",
    lightGrey: "rgba(196, 196, 196, 1)",
    lightGreyHover: "rgba(187, 184, 184, 1)",
    primarySubmenuColor: "rgba(82, 79, 80, 1)",
    primarySubmenuBackgroundColor: "rgba(238, 246, 248, 1)",
    primaryBrandTextColor: "rgba(17, 17, 17, 1)",
    secondaryBrandTextColor: "rgba(104, 101, 100, 1)",
    formFieldsBorderColor: "rgba(13, 8, 9, 0.15)",
    tabFieldsColor: "rgba(36, 9, 9, 0.3)",
    tableHeaderDefault: "rgba(153, 153, 153, 1)",
    tableItemBorder: "rgba(238, 238, 238, 1)",
    tableItemStatusSuccess: "rgba(0, 132, 137, 1)",
    accordionSeparatorBottomBorder: "rgba(151, 151, 151, 0.3)",
    passwordRecoveryDescriptionFontColor: "rgba(93, 93, 93, 1)",
    passwordRecoveryDescriptionAnchorColor: "rgba(9, 157, 189, 1)",
    resetPasswordIconBackgroundColor: "rgba(241, 241, 241, 1)",
    resetPasswordIconColor: "rgba(152, 152, 152, 1)",
    shareButtonColorZeroOpacity: "rgba(29, 158, 188,0)",
    shareButtonColorOneOpacity: "rgba(29, 158, 188,1)",
    stickyBarShadowColor: "rgba(0, 0, 0, 0.3)",
    whiteSmoke: "rgba(244, 244, 242, 1)",
    lightRed: "rgba(255, 105, 107, 1)",
    lightBlue: "rgba(90, 180, 200, 1)",
    lightRosa: "rgba(235, 69, 67, 1)",
    gainsBoro: "rgba(221, 221, 221, 1)",
    darkGray: "rgba(170, 170, 170, 1)",
    canary: "rgba(255, 244, 132, 1)",
    goldenPoppy: "rgba(220, 198, 0, 1)",
    mintGreen: "rgba(185, 255, 171, 1)",
    infoBoxGreen: "rgba(17, 100, 0, 1)",
    beautyBush: "rgba(235, 177, 177, 1)",
    mexicanRed: "rgba(151, 57, 57, 1)",
    lightDarkGray: "rgba(161, 161, 161, 1)",
    lightBlack: "rgba(43, 50, 48, 1)",
    lightGreen: "rgba(0, 175, 202, 1)",
    lightOutline: "rgba(178, 204, 236, 1)",
    charade: "rgba(60, 64, 67, 1)",
    mortar: "rgba(81, 81, 81, 1)",
    tangaroa: "rgba(23, 29, 49, 1)",
    shadyLady: "rgba(151, 151, 151, 0.31)",
    gainsboro: "rgba(219, 218, 218, 1)",
    nepal: "rgba(133, 162, 182, 1)",
    irisBlue: "rgba(12, 158, 188, 1)",
    brown: "rgba(158, 33, 70, 1)",
    disabled: "rgba(122, 119, 118, 1)",
    disabledOpacity: "rgba(122, 119, 118, 0.4)",
    navyBlueFacebook: "rgba(0, 97, 243, 1)",
    navyBlueLinkedin: "rgba(0, 104, 225, 1)",
    dodgerBlueTwitter: "rgba(25, 145, 255, 1)",
  },
  letterSpacings: {
    normal: "normal",
    tracked: "0.1em",
    tight: "-0.05em",
    mega: "0.25em",
  },
  fonts: {
    serif: "athelas, georgia, times, serif",
    sansSerif:
      '-apple-system, BlinkMacSystemFont, "avenir next", avenir, "helvetica neue", helvetica, ubuntu, roboto, noto, "segoe ui", arial, sans-serif',
  },
  borders: [
    0,
    "1px solid",
    "2px solid",
    "4px solid",
    "8px solid",
    "16px solid",
    "32px solid",
  ],
  radii: [0, 2, 4, 16, 9999, "100%"],
  width: [16, 32, 64, 128, 256],
  heights: [16, 32, 64, 128, 256],
}

export default theme
