import styled from "@emotion/styled"
import { css } from "styled-components"

export const StyledDescription = styled.div``

export const StyledTitle = styled.span``

export const StyledPage = styled.div`
  max-width: 21rem;
  text-align: center;
  margin: auto;
`

export const StyledPageContainer = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  width: 80vw;
  max-height: 99vh;
  overflow-y: hidden;
`

export const StyledLeftSide = styled.div`
  background-color: ${(p) => p.theme.colors.whiteSmoke};
  max-height: 99vh;
  overflow: auto;
`

export const StyledLeftContent = styled.div`
  margin: 2rem 0.5rem 2rem 2rem;
`
export const StyledRightContent = styled.div``

export const StyledRightSide = styled.div`
  margin: 2rem;
  max-height: 99vh;
  overflow: auto;

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin: 4rem;
  }
`

export const StyledCategoryName = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-size: 1rem;
  font-style: normal;
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  line-height: 1.5rem;
  text-align: left;

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2.125rem;
  }
`

export const StyledCategoryNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  min-height: 3rem;

  &.highlighted {
    &,
    i {
      color: ${(p) => p.theme.colors.white};
    }
    background-color: ${(p) => p.theme.colors.carrot};
  }

  &.selected {
    div {
      font-family: ${(p) => p.theme.fontFamilies.headline};
      font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    }
  }

  &.sub-selected {
    &,
    div {
      font-family: ${(p) => p.theme.fontFamilies.headline};
      font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    }
  }
`

export const StyledSubcategoryNameContainer = styled.div`
  cursor: pointer;
`

export const StyledCategoryNameIcon = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
`

export const StyledCategoryNameArrow = styled.div``

export const StyledCloseIcon = styled.div`
  position: absolute;
  right: 2rem;
  cursor: pointer;
  svg {
    width: 2rem;
    height: 2rem;
  }

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    right: 4rem;
    svg {
      width: 4rem;
      height: 4rem;
    }
  }
`

export const StyledIcon = styled.div`
  color: ${(p) => p.theme.colors.pitchBlack};
  &,
  svg {
    width: 1.5rem;
  }
  min-width: 1.5rem;
`

export const StyledSubCategoryName = styled.div`
  text-align: left;
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-size: 1rem;
  font-style: normal;
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  line-height: 1.5rem;

  &.selected {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  }

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2.125rem;
  }
`

export const StyledCategoryTitle = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-size: 2rem;
  font-style: normal;
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  line-height: 2.75rem;
  text-align: left;
  max-width: 70%;

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 4rem;
    line-height: 5rem;
  }
`

export const StyledFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 2.5rem;
  background: ${(p) => p.theme.colors.carrot};
  color: ${(p) => p.theme.colors.white};
  border-radius: 0.25rem;
  cursor: pointer;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    height: 3.375rem;
  }
`

export const StyledFilterItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.75rem;
  color: ${(p) => p.theme.colors.white};
  padding: 0.25rem;
`

export const StyledLabel = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-size: 0.75rem;
  line-height: 1.125rem;

  ${(p) =>
    p.long &&
    css`
      letter-spacing: -0.0313rem !important;
    `}

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
`

export const StyledCategoryTitleMobile = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-size: 1.5rem;
  font-style: normal;
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  line-height: 2rem;
  text-align: left;
  margin-left: 2rem;
  min-height: 1.5rem;
`

export const StyledPageContainerMobile = styled.div`
  margin-top: -1.75rem;
  line-height: 2.25rem;
`

export const StyledSelectedCategoryMobile = styled.div`
  cursor: pointer;
  div > input {
    height: 2.5rem;
  }
  div > input:disabled {
    background: ${(p) => p.theme.colors.white};
  }
`
