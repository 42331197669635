import styled from "@emotion/styled"
import { Button } from "@tmu/components/common"

export const StyledOpenAccountCard = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 4.375rem;
  padding-left: 1.875rem;
  padding-bottom: 3.125rem;
  border-radius: 0.625rem;
  background-color: ${(p) => p.theme.colors.pampasBackgroundColor};

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding-top: 1.875rem;
    padding-left: 0.9375rem;
    padding-bottom: 1.5625rem;
  }
`
export const StyledTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
`
export const StyledTitle = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.regular};
  font-size: 4.375rem;
  line-height: 5.25rem;
  color: ${(p) => p.theme.colors.racingGreen};
  padding-bottom: 0.875rem;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 2.1875rem;
  }
`
export const StyledSubTitle = styled.div`
  font-size: 0.625rem;
  line-height: 0.75rem;
  padding-top: 1rem;
  padding-left: 0.4375rem;
`
export const StyledDescription = styled.span`
  font-size: 0.875rem;
  line-height: 1.5625rem;
  color: ${(p) => p.theme.colors.doveGray};
  max-width: 25rem;
  padding-bottom: 1.5625rem;
  padding-right: 1.875rem;
`
export const StyledButton = styled(Button)`
  max-width: 9.375rem;
`
