import styled from "@emotion/styled"

export const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: baseline;
  padding-right: 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 0;
  }


  .wallet-box-title {
    text-align: center;
    margin-bottom: 0.3125rem;
    font-size: 0.75rem;
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.primaryBrandTextColor};
  }
`

export const Wallet = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  svg {
    font-size: 2rem;
    height: 2rem;
    color: ${(p) => p.theme.colors.blue};
  }
`

export const Label = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  color: ${(p) => p.theme.colors.carrot};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  padding-left: 0.625rem;

  .pac-icon {
    color: ${(p) => p.theme.colors.carrot};
  }

  .amount {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-style: ${(p) => p.theme.fontStyles.normal};
    color: ${(p) => p.theme.colors.pitchBlack};
    font-size: 1.5rem;
    line-height: 2.125rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 2rem;
      line-height: 2.75rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 3rem;
      line-height: normal;
    }
  }

  .title {
    font-size: 0.875rem;
    color: ${(p) => p.theme.colors.blue};
  }
`
