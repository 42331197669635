import React from "react"
import PropTypes from "prop-types"
import PhoneInput from "react-phone-input-2"
import { FieldError } from "@tmu/components/common"
import { StyledPhoneInput } from "./index.styles"
import "react-phone-input-2/lib/high-res.css"

const Phone = ({
  title,
  name,
  isRequired,
  country,
  value,
  errors,
  touched,
  onChange,
  onBlur,
}) => {
  return (
    <StyledPhoneInput error={isRequired && errors?.[name] && touched?.[name]}>
      {title && <div className="caption">{title}</div>}
      <PhoneInput
        id={name}
        name={name}
        country={country}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      {isRequired && <FieldError name={name} />}
    </StyledPhoneInput>
  )
}

Phone.propTypes = {
  title: PropTypes.node,
  name: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  country: PropTypes.string,
  value: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
}

Phone.defaultProps = {
  isRequired: true,
  country: "it",
  onBlur: () => {},
}

export default Phone
