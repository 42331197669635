import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Button } from "@tmu/components/common"
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons/faFacebookSquare"
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram"
import { faLinkedin } from "@fortawesome/free-brands-svg-icons/faLinkedin"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { StyledSocialLinks } from "./index.styles"
//import { isBrowser } from "@tmu/utils/auth"

export default function SocialLinks({ color, size }) {
  const {
    site: {
      siteMetadata: { social },
    },
  } = useStaticQuery(graphql`
    query SocialData {
      site {
        siteMetadata {
          social {
            facebook
            instagram
            linkedin
          }
        }
      }
    }
  `)

  const handleSocialLink = (platform) => {
    //isBrowser && window.open(social[platform], "_blank")
  }

  return (
    <StyledSocialLinks color={color} size={size}>
      <Button
        label="facebook"
        variant="text"
        onClick={() => handleSocialLink("facebook")}
        className="facebook-footer">
        <a href={social["facebook"]} target="_blank">
          <FontAwesomeIcon icon={faFacebookSquare} />
        </a>
      </Button>
      <Button
        label="linkedIn"
        variant="text"
        className="linkedin-footer"
        onClick={() => handleSocialLink("linkedin")}>
        <a href={social["linkedin"]} target="_blank">
          <FontAwesomeIcon icon={faLinkedin} />
        </a>
      </Button>
      <Button
        label="instagram"
        variant="text"
        onClick={() => handleSocialLink("instagram")}
        className="instagram-footer">
        <a href={social["instagram"]} target="_blank">
          <FontAwesomeIcon icon={faInstagram} />
        </a>
      </Button>
    </StyledSocialLinks>
  )
}
