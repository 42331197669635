import React, { useState, useEffect } from "react"
import { navigate, FormattedMessage } from "gatsby-plugin-intl"
import { Button } from "@tmu/components/common"
import { StyledCookieMsgWrapper } from "./index.styles"
import { isBrowser } from "@tmu/utils/auth"

const CookieMessage = () => {
  const [close, setClose] = useState(true) // To disable initial render

  useEffect(() => {
    if (!isBrowser) {
      return
    }
    setClose(document.cookie.includes("cookies-accepted"))
  }, [])

  const acceptCookie = () => {
    let expires = new Date(new Date().setFullYear(new Date().getFullYear() + 3))
    document.cookie = `cookies-accepted=true;expires=${expires}`
    setClose(isBrowser && document.cookie.includes("cookies-accepted"))
  }

  return (
    !close && (
      <StyledCookieMsgWrapper>
        <FormattedMessage
          id="common::cookie::message"
          defaultMessage="This website uses cookies to improve your experience. To accept the use of cookies and continue using the site as normal, please click accept below. If you would like to find out more about the cookies used on the website please see our Cookie Policy."
          tagName="p"
        />
        <Button
          label="accept"
          color="black"
          onClick={() => {
            acceptCookie()
          }}
          data-testid="btn-accept-cookies">
          <FormattedMessage
            id="common::cookie::accept"
            defaultMessage="Accept"
          />
        </Button>
        <Button
          label="moreInfo"
          color="black"
          onClick={() => {
            navigate("/legal/cookie-policy/15")
          }}>
          <FormattedMessage
            id="common::cookie::moreInfo"
            defaultMessage="More Information"
          />
        </Button>
      </StyledCookieMsgWrapper>
    )
  )
}
export default CookieMessage
