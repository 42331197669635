import React from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import { StyledForm } from "@tmu/global/page-addons/dashboard.styles"
import {
  StyledFlexContainer,
  StyledReceiptFormContainer,
  StyledSection,
  StyledSectionTitle,
} from "./index.styles"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { Button, FieldError, TextInput } from "@tmu/components/common"
import { firstName, displayName } from "@tmu/utils/validation"
import { Spacer } from "@tmu/global/page-addons/detail-page.styles"
import theme from "@tmu/global/theme"

const NameChangeForm = ({ data, onCancel, saveReceiptData, isMobile }) => {
  const { formatMessage } = useIntl()
  const validationSchema = Yup.object().shape({
    firstName: firstName({ formatMessage }),
    lastName: displayName({ formatMessage }),
  })
  const initialValues = {
    ...data,
  }

  const handleSubmitData = async (values) => {
    saveReceiptData(values)
  }

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={validationSchema}
      initialValues={initialValues}
      validateOnMount
      onSubmit={handleSubmitData}>
      {({ values, handleBlur, handleChange, errors }) => {
        return (
          <StyledForm onSubmit={handleSubmitData}>
            <StyledReceiptFormContainer>
              <StyledSection>
                <StyledSectionTitle>
                  <FormattedMessage
                    id="dashboard::profile::firstName"
                    defaultMessage="First Name"
                  />
                  *
                </StyledSectionTitle>
                <Spacer bottom={0.5} />
                <TextInput
                  newDesign
                  data-testid="firstName"
                  id="firstName"
                  name="firstName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstName}
                />
                <FieldError name="firstName" />
              </StyledSection>
              <Spacer bottom={1} />
              <StyledSection>
                <StyledSectionTitle>
                  <FormattedMessage
                    id="dashboard::profile::lastName"
                    defaultMessage="Last Name"
                  />
                  *
                </StyledSectionTitle>
                <Spacer bottom={0.5} />
                <TextInput
                  newDesign
                  data-testid="lastName"
                  id="lastName"
                  name="lastName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lastName}
                />
                <FieldError name="lastName" />
              </StyledSection>
              <Spacer bottom={1} />
            </StyledReceiptFormContainer>
            <StyledSection>
              <StyledFlexContainer className="sticky-bottom-bar">
                <Button
                  data-testid="btn-save"
                  className="blue-button"
                  color={theme.colors.pacificBlue}
                  type="button"
                  disabled={Object.keys(errors)?.length > 0}
                  onClick={() => {
                    handleSubmitData(values)
                  }}>
                  <FormattedMessage
                    id="dashboard::profile::save"
                    defaultMessage="Save Changes"
                  />
                </Button>

                <Button
                  data-testid="btn-cancel"
                  type="button"
                  color="transparent"
                  onClick={() => {
                    onCancel()
                  }}>
                  <FormattedMessage
                    id="dashboard::profile::save2"
                    defaultMessage="Cancel"
                  />
                </Button>
              </StyledFlexContainer>
            </StyledSection>
            <Spacer bottom={0.5} />
          </StyledForm>
        )
      }}
    </Formik>
  )
}

export default NameChangeForm
