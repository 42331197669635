import React from "react"
import { FormattedNumber, IntlProvider } from "gatsby-plugin-intl"
import { useCurrency } from "@tmu/hooks"

const Money = ({ value, style = "currency", ...rest }) => {
  const { currency } = useCurrency()

  let minimumFractionDigits = rest?.minimumFractionDigits || 0
  const maximumFractionDigits = rest?.maximumFractionDigits || 2

  if (typeof value?.toString()?.split === "function") {
    const decimalsArr = value?.toString().split(".")
    const decimals = decimalsArr?.length > 1 ? decimalsArr[1] : 0

    if (Number(decimals) > 0) {
      minimumFractionDigits = 2
    }
  }

  rest["style"] = style
  if (rest?.currency === false) {
    delete rest.currency

    if (style === "currency") {
      delete rest.style
    }
  } else {
    rest["currency"] = currency
  }

  delete rest.minimumFractionDigits
  delete rest.maximumFractionDigits

  return (
    <IntlProvider locale="en">
      <FormattedNumber
        minimumFractionDigits={minimumFractionDigits}
        maximumFractionDigits={maximumFractionDigits}
        signDisplay="never"
        {...rest}
        value={style === "percent" ? value / 100 : value}
      />
    </IntlProvider>
  )
}

export default Money
