import styled from "@emotion/styled"
import { Button } from "@tmu/components/common"

export const StyledOfferPage = styled.div`
  padding-bottom: 3rem;
  .float-container {
    min-height: 3rem;
  }

  .float-container > label {
    color: ${(p) => p.theme.colors.concreteGrey};
  }

  .text-area {
    height: 15rem !important;
    padding-left: 1rem !important;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      height: 10.75rem !important;
    }
  }

  hr {
    margin: 3.125rem 0;
  }
  .image-selector {
    > div {
      p {
        color: ${(p) => p.theme.colors.white};
        margin: 1.125rem auto 2.125rem;
      }
      svg {
        opacity: 1;
      }
    }
  }
  .editor-class {
    height: 16rem;
  }

  .input-feedback {
    margin: 1rem 0 0;
  }
  input + .input-feedback {
    margin-top: 0.5rem;
  }
  .image-upload {
    border-radius: 4px;
    border: 1px solid ${(p) => p.theme.colors.darkGrey};
    display: inline-block;
    padding: 0.9375rem;
    width: 100%;
    min-height: 7rem;
    .image-selector {
      width: 100%;
      height: 100%;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      min-width: 12.5rem;
    }
  }

  .react-select__control {
    min-height: 3rem;
    border-color: rgba(13, 8, 9, 0.15);
  }

  .form-input {
    height: 3rem !important;
    padding-left: 1rem !important;
    input {
      height: 90%;
    }
  }

  .border,
  .border > textarea {
    border-color: rgba(13, 8, 9, 0.15);
  }

  .campaign-form > fieldset {
    align-items: start;
    margin-right: 0 !important;
  }

  .save-button {
    width: 100%;
    background-color: ${(p) => p.theme.colors.carrot};
    :hover {
      background-color: ${(p) => p.theme.colors.carrot};
    }
  }

  .save-container {
    margin: 0;
    padding: 0;
  }

  .pac-discount {
    width: 6rem;
    input {
      padding-right: 1rem;
    }
  }
  .input-percent {
    position: absolute;
    top: 0.75rem;
    left: 4.5rem;
    font-size: 1rem;
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    font-family: ${(p) => p.theme.fontFamilies.regular};
  }

  .input-container {
    position: relative;
  }

  .submit-for-review-button {
    background-color: ${(p) => p.theme.colors.carrot};
    :hover {
      background-color: ${(p) => p.theme.colors.carrot};
    }
  }

  .leave-button {
    background-color: ${(p) => p.theme.colors.white};
    color: ${(p) => p.theme.colors.pitchBlack};
  }

  .update-buttons {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    gap: 1rem;
    flex-direction: column-reverse;
    background-color: transparent;

    button {
      width: 100%;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      flex-direction: row;
      button {
        width: unset;
      }
    }
  }

  .bold-title {
    font-family: ${(p) => p.theme.fontFamilies.headline} !important;
    font-size: 1.25rem !important;
    font-weight: ${(p) => p.theme.fontWeights.semiBolder} !imoprtant;
    line-height: 1.75rem !important;
    color: ${(p) => p.theme.colors.pitchBlack} !important;
    text-transform: capitalize !important;
  }

  .mobile-text {
    font-size: 0.75rem;
  }

  .react-datepicker-wrapper {
    width: 100% !important;
    svg {
      top: 0.75rem;
    }
    input {
      border-color: rgba(13, 8, 9, 0.15);
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      width: 15rem !important;
    }
  }

  .upload-icon {
    margin-top: 1.25rem;
  }

  .second-grid {
    margin-right: 0;
    margin-left: 0;
    color: ${(p) => p.theme.colors.pitchBlack};
    :hover {
      color: ${(p) => p.theme.colors.carrot};
      border-color: ${(p) => p.theme.colors.carrot};
    }
  }
  .sticky-bottom-bar {
    bottom: 1rem;
    width: calc(100vw - 2rem);
    left: 1rem;
  }

  label {
    &:hover input ~ .checkmark {
      border: 0.0625rem solid ${(p) => p.theme.colors.carrot};
    }

    input:checked ~ .checkmark {
      background-color: ${(p) => p.theme.colors.carrot};
      border: 0.0625rem solid ${(p) => p.theme.colors.carrot};
    }
    .checkmark:after {
      background: ${(p) => p.theme.colors.carrot};
    }
  }
`

export const StyledPriceSection = styled.div`
  display: grid;
  grid-gap: 5rem;
  .offer-currency .float-container {
    height: 3.25rem;
    width: 10.625rem;

    & > div {
      width: 3.25rem;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    & > input {
      font-size: 1.5rem;
      font-weight: ${(p) => p.theme.fontWeights.bold};
      letter-spacing: 0;
      line-height: 1.5rem;
      padding: 0 !important;
      margin: 0.125rem 0 0;
      height: 80%;
      margin-left: 2rem;
      width: 70%;
    }
  }
  .pay-amount__currency {
    font-weight: ${(p) => p.theme.fontWeights.bold};
  }

  .external-offer-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8.125rem;
    align-items: center;

    .is-offer {
      padding-top: 4.6875rem;
    }
  }
`
export const StyledFieldHelpText = styled.p`
  margin-top: 1rem;
  margin-bottom: 1.24rem;
  font-size: 0.95rem;
  color: ${(p) => p.theme.colors.disabled};
`
export const StyledDescriptionText = styled.p`
  margin-top: 1.25rem;
  color: ${(p) => p.theme.colors.shuttleflGray};
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1.5rem;
  font-weight: ${(p) => p.theme.fontWeights.regular};
`

export const StyledImagesContainerList = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledImagesContainerListRow = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 0.5rem;
  position: relative;

  button.delete {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    left: 28.75rem;
    top: 0.625rem;
  }

  &:hover,
  &:focus,
  &:active,
  &.active {
    button.delete {
      visibility: visible;
      opacity: 1;
    }
  }
`

export const StyledAddImageButton = styled(Button)`
  svg {
    margin-left: 0;
    margin-right: 0.75rem;
  }
`
export const StyledOfferWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const StyledOfferIsUnlisted = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 3.125rem;
  padding-top: 2.5rem;
  span {
    padding-left: 1.25rem;
  }
`

export const StyledTitle = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.bold};
  text-align: center;
  color: ${(p) => p.theme.colors.black};
  font-size: 1.5rem;
  line-height: 2rem;
  text-transform: capitalize;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 2rem;
    line-height: 2.625rem;
  }
`

export const StyledTitleContainer = styled.div`
  display: grid;
  grid-template-areas: "back title status";
  grid-template-columns: 1fr 2fr 1fr;
  align-items: center;

  .title {
    grid-area: title;
    jusrify-self: center;
  }

  .back > svg {
    color: ${(p) => p.theme.colors.carrot} !important;
  }

  .status {
    grid-area: status;
    justify-self: end;
    span {
      margin: 0;
      font-size: 0.75rem;
      @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
        font-size: 1rem;
      }
    }
  }
`

export const StyledLabel = styled.div`
  width: auto;
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.bold};
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: ${(p) => p.theme.colors.black};
  margin: 0;
  padding: 0;

  &.center {
    text-align: center;
  }

  &.top-align {
    align-self: flex-start;
    margin-top: 0.6875rem;
  }

  &.subtext {
    margin-top: 0.5rem;
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.regular};
    font-size: 0.75rem;
    line-height: 1rem;
    color: ${(p) => p.theme.colors.black};
  }

  .info {
    margin-top: 0;
    font-size: 0.5rem;

    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.regular};
    line-height: 1.25rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    .info {
      margin-top: 0.75rem;
      font-size: 0.75rem;
    }
  }
  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    :not(.subheader):not(.subtext) {
      font-weight: ${(p) => p.theme.fontWeights.black};
      font-size: 0.625rem;
      line-height: 1rem;
      letter-spacing: 0.04rem;
      text-transform: uppercase;
      color: ${(p) => p.theme.colors.disabled};
      margin-top: 1rem;
      margin-bottom: 0.5rem;
    }
  }
`

export const StyledModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  h4 {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.pitchBlack};
    text-align: left;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 1.5rem;
      line-height: 2rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 2rem;
      line-height: 2.625rem;
    }
  }

  button {
    margin: 0;
    padding: 0;

    svg {
      height: 1.25rem;

      @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
        height: 2rem;
      }
    }
  }
`

export const StyledDatePickerWrapper = styled.div`
  .date-picker-input > input {
    max-width: unset;
    width: unset;
  }
`

export const StyledModalContainer = styled.div`
  line-height: 2;

  p {
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    color: ${(p) => p.theme.colors.pitchBlack};

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 1rem;
      line-height: 1.375rem;

      margin: 1rem 0 1.5rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.5rem;
      line-height: 2.25rem;

      margin: 1.5rem 0 3rem;
    }
  }

  .modal-leave {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.white};

    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;

    margin: 0 auto;
    width: 50%;
    height: 2.5rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 0.75rem;
      line-height: 1rem;
      height: 2.5rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1rem;
      line-height: 1.375rem;
      height: 3.375rem;
    }
  }
`
