import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { getQueryParams, getValueForLocale } from "@tmu/utils/string"
import { Button, NewCard, Spinner } from "@tmu/components/common"
import { useOffers } from "@tmu/hooks"
import { LIST_TYPES } from "@tmu/apollo/constants"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import SEO from "@tmu/components/seo"
import {
  StyledWrapper,
  StyledServicesBody,
  StyledContent,
  StyledHeader,
  StyledTitle,
  StyledListing,
  NoResults,
} from "./index.styles"

const ServiceListing = ({
  title,
  storeId,
  handleViewAll,
  customNotFoundMessage,
  hideDefaultOffer = false,
  servicesToExclude = [],
  limit = 4,
}) => {
  const { locale, defaultLocale, formatMessage } = useIntl()
  const params = getQueryParams()
  const [listType, setListType] = useState(params?.view || LIST_TYPES.TILE)

  const { callOffers, data, loading } = useOffers()

  const variables = {
    first: 6,
    store: [storeId],
    orderBy: "-modified",
  }

  useEffect(() => {
    callOffers({ variables })
  }, [])

  const { isTablet, isDesktop } = getAllScreenTypes()

  const listData = hideDefaultOffer
    ? data?.edges
        ?.filter((item) => !item?.node?.isDefaultOffer)
        ?.filter((item) => !servicesToExclude.includes(item?.node?.id))
    : data?.edges?.filter((item) => !servicesToExclude.includes(item?.node?.id))

  return (
    <>
      <SEO
        lang={locale}
        title={formatMessage({
          id: "dashboard::nav::services",
          defaultMessage: "Services",
        })}
      />
      <StyledWrapper id="services">
        <StyledServicesBody>
          {loading ? (
            <Spinner />
          ) : (
            <div>
              {listData?.length > 0 && (
                <StyledHeader>
                  <StyledTitle>
                    {title || (
                      <FormattedMessage
                        id="serviceListing::title"
                        defaultMessage="Other services of the store"
                      />
                    )}
                  </StyledTitle>
                  <Button
                    variant="link"
                    text={
                      !isTablet ? (
                        <FormattedMessage id="ui::all" defaultMessage="All" />
                      ) : (
                        <FormattedMessage
                          id="ui::viewAll"
                          defaultMessage="View all"
                        />
                      )
                    }
                    size="big"
                    onClick={handleViewAll}
                  />
                </StyledHeader>
              )}
              <StyledContent>
                <StyledListing>
                  {listData?.length > 0
                    ? listData?.slice(-1 * limit)?.map(({ node: offer }) => {
                        return (
                          <NewCard
                            variant="service"
                            id={offer?.id}
                            key={offer?.id}
                            imageSrc={offer?.image}
                            title={getValueForLocale(
                              offer,
                              "name",
                              locale,
                              defaultLocale
                            )}
                            description={getValueForLocale(
                              offer,
                              "description",
                              locale,
                              defaultLocale
                            )}
                            maxPacDiscount={offer?.maxPacDiscount}
                            size={!isDesktop ? LIST_TYPES.LIST : listType}
                            storeLogo={getValueForLocale(
                              offer?.store,
                              "logo",
                              locale,
                              defaultLocale
                            )}
                            storeName={
                              offer?.store?.displayName || offer?.store?.name
                            }
                            minPrice={offer?.minPrice}
                            maxPrice={offer?.maxPrice}
                            cardLink={`/services/${offer?.slug}`}
                          />
                        )
                      })
                    : !loading && (
                        <NoResults>
                          {customNotFoundMessage || (
                            <FormattedMessage
                              id="campaignListing::filters::noResult"
                              defaultMessage="We couldn’t find any results ("
                              tagName="h6"
                            />
                          )}
                        </NoResults>
                      )}
                </StyledListing>
              </StyledContent>
            </div>
          )}
        </StyledServicesBody>
      </StyledWrapper>
    </>
  )
}

ServiceListing.propTypes = {
  title: PropTypes.node.isRequired,
  storeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  handleViewAll: PropTypes.func.isRequired,
  customNotFoundMessage: PropTypes.node.isRequired,
}

export default ServiceListing
