import styled from "@emotion/styled"

export const StyledTitle = styled.div`
  h1 {
    text-align: center;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: ${(p) => p.theme.fontWeights.bold};
    margin-bottom: 1.5rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      line-height: 2.6rem;
      font-size: 2rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 3rem;
      line-height: 4.1rem;
    }
  }
`

export const StyledDescription = styled.div`
  text-align: center;
  color: ${(p) => p.theme.passwordRecoveryDescriptionFontColor};
  margin: 0 auto 0.5rem auto;
  font-size: 1rem;
  line-height: 1.375rem;
  max-width: 31rem;

  a {
    color: ${(p) => p.theme.passwordRecoveryDescriptionAnchorColor};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1rem;
    line-height: 1.375rem;
    max-width: 21rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 1rem;
    line-height: 1.375rem;
    max-width: 28rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2.25rem;
    max-width: 36rem;
  }
`

export const StyledFormContainer = styled.div`
  padding-top: 1rem;
  margin: 0 auto;
  max-width: 35rem;

  .feedback {
    margin-left: -1rem !important;
  }

  .input-feedback {
    margin-left: -1rem !important;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    max-width: 22rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    max-width: 28rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    max-width: 36rem;
  }
`

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.5rem;

  > button {
    width: 100%;
    height: 2.5rem;
    border-radius: 0.25rem;

    @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      width: 15rem;
    }
    @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      width: 18.5rem;
      height: 3.375rem;
    }
  }

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    position: relative;
    padding: 0;
  }
`

export const StyledBackLink = styled.div`
  margin-top: 1.5rem;
  color: ${(p) => p.theme.colors.blue};
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: ${(p) => p.theme.fontWeights.regular};

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2.2rem;
  }
`
