import styled from "@emotion/styled"

export const StyledTitle = styled.div`
  p {
    font-family: ${(p) => p.theme.fontFamilies.headlineBig};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.liteBlack};
    color: ${(p) => p.theme.colors.disabled};
    margin-bottom: unset;
    text-align: left;
    font-size: 0.625rem;
    line-height: 1rem;
    letter-spacing: 0.03em;
    text-transform: uppercase;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 0.75rem;
      line-height: 1.25rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.25rem;
      line-height: 2rem;
      letter-spacing: 0.02em;
    }
  }

  .user-name {
    padding-left: 0.3125rem !important;
  }
`
