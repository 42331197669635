import { useQuery, gql } from "@apollo/client"
import { useApolloApiClients } from "@tmu/apollo/client"

const useAllPartners = (options) => {
  const { storefrontClient } = useApolloApiClients()
  const { loading, data } = useQuery(
    gql`
      query Partners {
        allPartners {
          edges {
            node {
              id
              slug
              name
              logo
              shortDescription
              isFeatured
            }
          }
        }
      }
    `,
    { ...options, client: storefrontClient }
  )
  return loading ? [] : data?.allPartners?.edges.map(({ node }) => node) ?? []
}

export default useAllPartners
