import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import { Button, CustomModal } from "@tmu/components/common"
import AddDeveloperForm from "./AddDeveloperForm"
import { modalStyles } from "@tmu/global/GlobalStyle"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import theme from "@tmu/global/theme"
import {
  StyledModalText,
  StyledModalContent,
  StyledModalButton,
} from "./index.styles"

export const DevelopersModal = ({
  isModalOpen,
  id,
  name,
  isRevoke,
  revokeUser,
  handleModalClose,
  callAllApiUsers,
  checkUserExist,
}) => {
  const { isTablet } = getAllScreenTypes()

  const modalHeader = isRevoke ? (
    <FormattedMessage
      id="dashboard::developers::revokingCredentials"
      defaultMessage="Revoking credentials"
      tagName={!isTablet ? "p" : null}
    />
  ) : id === undefined && name === undefined ? (
    <FormattedMessage
      id="dashboard::users::authorizeNewUser"
      defaultMessage="New credentials"
      tagName={!isTablet ? "p" : null}
    />
  ) : (
    <FormattedMessage
      id="dashboard::users::editName"
      defaultMessage="Edit name"
      tagName={!isTablet ? "p" : null}
    />
  )

  return (
    <CustomModal
      isModalOpen={isModalOpen}
      style={{
        ...modalStyles,
        content: {
          ...modalStyles.content,
          border: "unset",
        },
      }}
      headerTextStyle={
        isTablet && {
          fontFamily: theme.fontFamilies.headline,
          fontWeight: theme.fontWeights.semiBolder,
          fontStyle: theme.fontStyles.normal,
          color: theme?.colors.pitchBlack,
          fontSize: "1.5rem",
          lineHeight: "normal",
        }
      }
      header={isTablet ? modalHeader : null}
      descriptionStyle={{ marginBottom: "unset" }}
      text={
        !isTablet &&
        !isRevoke && <StyledModalText>{modalHeader}</StyledModalText>
      }
      isCloseIcon={true}
      isCloseXButton={isTablet ? true : false}
      isCloseBackButton={!isTablet ? true : false}
      cancelButtonAction={() => handleModalClose()}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      isMobile={!isTablet}
      isFull={!isTablet}>
      <StyledModalContent
        className={!isTablet & isRevoke ? "mobile" : "not-mobile"}>
        {!isTablet && isRevoke && modalHeader}
        {isRevoke ? (
          <FormattedMessage
            id="dashboard::developers::revokingText"
            defaultMessage="Revoke {name}'s credentials?"
            values={{ name }}
            tagName="span"
          />
        ) : (
          <AddDeveloperForm
            developerId={id}
            developerName={name}
            onCancel={() => handleModalClose()}
            onSuccess={() => {
              handleModalClose()
              callAllApiUsers()
            }}
            checkUserExist={checkUserExist}
          />
        )}
      </StyledModalContent>
      {isRevoke && (
        <StyledModalButton
          className={!isTablet && isRevoke ? "mobile" : "not-mobile"}>
          <Button
            color="blue"
            type="button"
            label="revokeUser"
            onClick={revokeUser}
            data-testid="btn-revoke-user">
            <FormattedMessage
              id="dashboard::developers::revokeCredentials"
              defaultMessage="Revoke credentials"
            />
          </Button>
        </StyledModalButton>
      )}
    </CustomModal>
  )
}
