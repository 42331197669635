import styled from "@emotion/styled"

export const StyledContentContainer = styled.div``

export const StyledCharityList = styled.div`
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    display: flex;
    flex-wrap: wrap;
    gap: 1.25rem 0.75rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
  }
`
export const StyledFilterContainer = styled.div`
  display: flex;
  .search-icon {
    top: -0.2rem !important;
    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      top: -1.2rem !important;
    }
  }
  & > div {
    max-width: unset;
    width: 100%;
    gap: 1rem;
    row-gap: 1.5rem;
    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      row-gap: 0;
    }
  }

  & > div > div {
    gap: 1rem;
  }
`
export const StyledMobileBackground = styled.div`
  position: absolute;
  background-color: ${(p) => p.theme.colors.white};
  width: 100vw;
  height: 15rem;
  top: 0;
  left: 0;
  z-index: -1;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    height: 16.5rem;
  }
`

export const StyledCategoryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.5rem;
  gap: 0.5rem;
  row-gap: 1rem;
`

export const StyledCategoryItem = styled.div`
  button {
    border: 0;
    border-width: 0;
    margin: 0;
    font-size: 1rem;
    line-height: 1.375rem;
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    font-family: ${(p) => p.theme.fontFamilies.regular};
    text-transform: initial;
    background-color: ${(p) => p.theme.colors.whiteSmoke};
    height: 2.125rem;
    &:hover,
    &:focus {
      border: none;
      color: inherit;
      background-color: ${(p) => p.theme.colors.whiteSmoke};
    }

    &.active {
      background-color: ${(p) => p.theme.colors.pacificBlue};
      color: ${(p) => p.theme.colors.white};
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.5rem;
      line-height: 2.25rem;
      height: 3rem;
    }
  }
`

export const StyledMobileHeaderWrapper = styled.div`
  width: 100%;
`

export const StyledHeaderContainer = styled.div``

export const StyledContent = styled.div`
  margin-top: 2rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin: 2.5rem 0 0;
    max-height: calc(90vh - 25rem);
    overflow-y: scroll;
  }
`
