import React, { useEffect, useState } from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import { useLazyQuery, gql } from "@apollo/client"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus"
import { useApolloApiClients } from "@tmu/apollo/client"
import { useAuth, useDefaultMerchant } from "@tmu/hooks"
import { Button, ClickToCopy, Spinner } from "@tmu/components/common"
import { DevelopersModal } from "./DevelopersModal"
import DevelopersTableHeader from "./DevelopersTableHeader"
import DevelopersTableRow from "./DevelopersTableRow"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import {
  StyledPage,
  StyledPageTitle,
} from "@tmu/global/page-addons/dashboard.styles"
import {
  StyledPageTitleContainer,
  StyledPageDeveloperContainer,
  StyledDevelopersTable,
  StyledDevelopersTableContainer,
} from "./index.styles"

const API_USER_QUERY = gql`
  query allApiUsers($first: Int) {
    allApiUsers(first: $first) {
      edges {
        node {
          name
          clientId
          id
          otp
        }
      }
    }
  }
`

export default function Developers() {
  const { isLoading, user } = useAuth()
  const { merchantClient } = useApolloApiClients()
  const { isTablet, isDesktop, isWide } = getAllScreenTypes()
  const [userData, setUserData] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [callAllApiUsers, { data, loading }] = useLazyQuery(API_USER_QUERY, {
    variables: {
      first: 10,
    },
    client: merchantClient,
    skip: !user?.isMerchant,
    fetchPolicy: "cache-and-network",
  })

  const { defaultMerchant, loading: loadingMerchant } = useDefaultMerchant()

  useEffect(() => {
    if (loadingMerchant || isLoading || !defaultMerchant) {
      return
    }
  }, [loadingMerchant, defaultMerchant])

  useEffect(() => {
    callAllApiUsers()
  }, [])

  useEffect(() => {
    setUserData(data?.allApiUsers?.edges || [])
  }, [data])

  const checkUserExist = (name) => {
    return userData?.some(
      (item) => item?.node?.name?.toLowerCase() === name?.toLowerCase()
    )
  }

  const getFirstOTPInfo = () => userData[0]?.node?.otp || ""

  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <StyledPage>
        <StyledPageTitleContainer>
          <StyledPageTitle
            className="developers"
            style={{ marginBottom: "unset" }}>
            <FormattedMessage
              id="dashboard::nav::developers"
              defaultMessage="Plugin management"
              tagName="h1"
            />
          </StyledPageTitle>
          <StyledPageDeveloperContainer>
            {(isWide || isDesktop) && (
              <>
                <FormattedMessage
                  id="dashboard::developers::tableHeader::userId"
                  defaultMessage="User ID"
                  tagName="p"
                />
                <ClickToCopy
                  text={getFirstOTPInfo()}>{`${getFirstOTPInfo()?.substring(
                  0,
                  16
                )}...`}</ClickToCopy>
              </>
            )}
            <Button
              variant="text"
              data-testid="btn-new-credentials"
              onClick={() => setIsModalOpen(true)}>
              <FontAwesomeIcon icon={faPlus} />
              {isTablet && (
                <FormattedMessage
                  id="dashboard::users::authorizeNewUser"
                  defaultMessage="New credentials"
                />
              )}
            </Button>
          </StyledPageDeveloperContainer>
        </StyledPageTitleContainer>
        {!isWide && !isDesktop && (
          <StyledPageDeveloperContainer className="bottom-user-id">
            <FormattedMessage
              id="dashboard::developers::tableHeader::userId"
              defaultMessage="User ID"
              tagName="p"
            />
            <ClickToCopy
              text={getFirstOTPInfo()}>{`${getFirstOTPInfo()?.substring(
              0,
              16
            )}...`}</ClickToCopy>
          </StyledPageDeveloperContainer>
        )}
        <StyledDevelopersTableContainer>
          {loading ? (
            <Spinner />
          ) : (
            userData && (
              <>
                <DevelopersTableHeader />
                <StyledDevelopersTable>
                  {userData?.map((item, index) => {
                    return (
                      <DevelopersTableRow
                        key={item?.node?.id}
                        id={item?.node?.id}
                        name={item?.node?.name}
                        clientId={item?.node?.clientId}
                        callAllApiUsers={callAllApiUsers}
                        checkUserExist={checkUserExist}
                      />
                    )
                  })}
                </StyledDevelopersTable>
              </>
            )
          )}
        </StyledDevelopersTableContainer>
      </StyledPage>
      {isModalOpen && (
        <DevelopersModal
          {...{
            isModalOpen,
            handleModalClose,
            callAllApiUsers,
            checkUserExist,
          }}
        />
      )}
    </>
  )
}
