import styled from "@emotion/styled"

export const StyledPageWrapper = styled.div`
  margin-top: -1.25rem;
  height: 100%;
  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .align-left {
    text-align: left;
  }

  .align-right {
    text-align: right;
  }
`

export const StyledHeader = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-family: ${(p) => p.theme.fontFamilies.headlineBig};
  text-transform: uppercase;
`
export const StyledDonationAmount = styled.div`
  &,
  span {
    font-size: 3rem;
    line-height: 4rem;
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-family: ${(p) => p.theme.fontFamilies.headline};
    color: ${(p) => p.theme.colors.carrot};
  }
`
export const StyledDonor = styled.div`
  font-size: 1.15rem;
  line-height: 1.5rem;
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-family: ${(p) => p.theme.fontFamilies.headline};
  color: ${(p) => p.theme.colors.pitchBlack};
`
export const StyledDonationId = styled.div`
  display: flex;
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  font-family: ${(p) => p.theme.fontFamilies.regular};
  color: ${(p) => p.theme.colors.carrot};
  justify-content: center;
  align-items: center;
  svg {
    margin-left: 0.5rem;
    height: 1.25rem;
  }
`
export const StyledBottomContainer = styled.div`
  padding-top: 0.5rem;
  background-color: ${(p) => p.theme.colors.whiteSmoke};
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  height: 100%;
  overflow: hidden;
`
export const StyledLabel = styled.div``
export const StyledValue = styled.div`
  &,
  div,
  span {
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-family: ${(p) => p.theme.fontFamilies.headline};
  }

  justify-self: end;
  display: flex;
  gap: 2rem;
`

export const StyledValueItem = styled.span`
  font-size: 1.125rem;
  line-height: 1.5rem;
`

export const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem 0 1.5rem;
`

export const StyledGridRow = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  grid-gap: 1rem;
  align-items: center;
`

export const StyledLine = styled.hr`
  margin: 0.5rem 1.5rem 0.5rem 1.5rem;
  width: calc(100vw - 3rem);
`
export const StyledPacsContainer = styled.div`
  display: flex;
  grid-gap: 0.5rem;
  align-items: center;
`
