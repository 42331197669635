import styled from "@emotion/styled"
import { Button } from "@tmu/components/common"

export const StyledRewardForGood = styled.div`
  margin: 2rem auto 2.5rem;
  display: flex;
  flex-direction: column-reverse;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    flex-direction: row;
    margin: 3rem auto;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin: 3.375rem auto;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin: 5rem auto;
  }
`

export const StyledVideoContainerBox = styled.div`
  margin-left: -1rem;

  > iframe {
    width: calc(100% + 1rem) !important;
    height: 56vw;
    border: none;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-left: 0;
    width: 22rem;
    min-width: 22rem;
    > iframe {
      width: 100% !important;
      height: 12.375rem;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    width: 29.5rem;
    min-width: 29.5rem;
    > iframe {
      height: 16.625rem;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    width: 53.25rem;
    min-width: 53.25rem;
    > iframe {
      height: 30.0625rem;
    }
  }
`

export const StyledSuccessDetails = styled.div`
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 0 1rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    padding: 0 1.5rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding: 0 1.5rem;
  }
`

export const StyledSuccessDetailsTitle = styled.p`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-size: 1.5rem;
  line-height: 2rem;
  color: ${(p) => p.theme.colors.carrot};
  margin-bottom: 2rem;
  text-align: center;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    text-align: left;
    margin-bottom: 1rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin-bottom: 1.5rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 3rem;
    line-height: 4.0625rem;
    margin-bottom: 3rem;
  }
`

export const StyledDiscoverPACsButton = styled(Button)`
  margin: 0.5rem 0 2rem;
  p {
    span,
    svg {
      font-family: ${(p) => p.theme.fontFamilies.headline};
      font-style: ${(p) => p.theme.fontStyles.normal};
      font-weight: ${(p) => p.theme.fontWeights.semiBolder};
      color: ${(p) => p.theme.colors.carrot};
    }
    svg {
      display: inline-block;
    }
    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      span,
      svg {
        font-size: 1.5rem;
        height: 1.5rem;
        line-height: 1.75rem;
      }
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin: 0;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin-top: 0.75rem;
  }
`

export const StyledSuccessDetailsText = styled.p`
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  font-size: 0.75rem;
  line-height: 1.125rem;
  display: grid;
  grid-template-columns: 1.75rem auto;
  grid-gap: 1rem;
  align-items: center;
  color: ${(p) => p.theme.colors.black};
  margin-bottom: 1rem;

  svg {
    color: ${(p) => p.theme.colors.carrot};
    font-size: 1.5rem;
    height: 1.5rem;
    align-self: start;
    margin-top: 0.3rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 1rem;
    line-height: 1.375rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2.25rem;
    margin-bottom: 2rem;
  }
`
