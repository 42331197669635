import React, { useCallback, useEffect, useState } from "react"
import { useDropzone } from "react-dropzone"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { useFormikContext } from "formik"
import { Button, Spinner } from "@tmu/components/common"
import { v4 as uuidv4 } from "uuid"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus"
import { faTrash } from "@fortawesome/pro-solid-svg-icons/faTrash"
import { faRepeat } from "@fortawesome/pro-solid-svg-icons/faRepeat"
import {
  StyledImagesContainerList,
  StyledDropzoneContent,
  StyledImageControls,
  StyledImageThumb,
  StyledSpinner,
} from "./index.styles"
import { readFile } from "@tmu/utils/image"
import ImageCropper from "./ImageCropper"
import { useToast } from "@tmu/hooks"
import { Tooltip } from "react-tooltip"

const Dropzone = ({ onDrop, accept, isEmpty, children }) => {
  // Initializing useDropzone hooks with options
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    maxSize: 3145728,
  })
  return (
    <div {...getRootProps()} onClick={(e) => e.preventDefault()}>
      {
        <input
          id="dropzoneInput"
          className="dropzone-input"
          {...getInputProps()}
        />
      }
      <StyledDropzoneContent active={isDragActive}>
        {!isEmpty ? (
          children
        ) : isDragActive ? (
          <FormattedMessage
            id="dashboard::forms::releaseFiles"
            defaultMessage="Release to drop the files here"
            tagName="p"
          />
        ) : (
          <>
            <div
              className="open-dialog"
              onClick={() => {
                document.getElementById("dropzoneInput").click()
              }}>
              <FontAwesomeIcon icon={faPlus} size="3x" />
            </div>
            <FormattedMessage
              id="dashboard::forms::dragFiles"
              defaultMessage="Drag 'n' drop some files here, or click to select files"
              tagName="p"
            />
          </>
        )}
      </StyledDropzoneContent>
    </div>
  )
}

const Image = ({ image, loading, id, onRemove, onEdit }) => {
  const [imgSrc, setImgSrc] = useState(image)
  const isUserUpload = id.indexOf("user-upload") > -1
  const { formatMessage } = useIntl()

  useEffect(() => {
    if (!isUserUpload) {
      return
    }
    readFile(image).then((src) => {
      setImgSrc(src)
    })
    if (imgSrc === image) onEdit(id)
  }, [image, isUserUpload, imgSrc])

  return (
    <>
      <StyledImageThumb>
        <img width={196} height={118} alt={`img-${id}`} src={imgSrc} />
        <StyledImageControls>
          {loading ? (
            <StyledSpinner>
              <Spinner condensed />
            </StyledSpinner>
          ) : (
            <>
              {isUserUpload && (
                <Button
                  variant="text"
                  label="crop"
                  data-tooltip-content={formatMessage({
                    id: "dashboard::forms::replace",
                    defaultMessage: "Replace with another one",
                  })}
                  data-tooltip-id="repeat"
                  onClick={(e) => {
                    e.preventDefault()
                    onEdit(id)
                  }}>
                  <Tooltip
                    place="left"
                    type="info"
                    textColor="black"
                    backgroundColor="white"
                    id="repeat"
                    className="tooltip"
                    delayShow={200}
                  />
                  <FontAwesomeIcon icon={faRepeat} />
                </Button>
              )}
              <Button
                variant="text"
                label="trash"
                data-tooltip-content={formatMessage({
                  id: "dashboard::forms::delete",
                  defaultMessage: "Delete",
                })}
                data-tooltip-id="remove"
                onClick={(e) => {
                  e.preventDefault()
                  onRemove(id)
                }}>
                <Tooltip
                  place="left"
                  type="info"
                  id="remove"
                  textColor="black"
                  backgroundColor="white"
                  className="tooltip"
                  delayShow={200}
                />

                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </>
          )}
        </StyledImageControls>
      </StyledImageThumb>
    </>
  )
}

const ImageList = ({ images, processing, onRemove, onEdit }) => {
  // render each image by calling Image component
  const renderImage = ({ image, id, order }) => (
    <Image
      image={image}
      key={`${id}-image`}
      id={id}
      loading={processing.indexOf(id) > -1}
      onEdit={onEdit}
      onRemove={onRemove}
    />
  )

  // Return the list of files
  return (
    <StyledImagesContainerList>
      {images?.length && images.map(renderImage)}
      <div
        className="open-dialog"
        onClick={() => {
          document.getElementById("dropzoneInput").click()
        }}>
        <FontAwesomeIcon icon={faPlus} size="3x" />
      </div>
    </StyledImagesContainerList>
  )
}

const ImageGallery = ({ onDeleteImage, processingImages }) => {
  const [croppingImage, setCroppingImage] = useState(null)
  const { error: errorToast } = useToast()
  const { formatMessage } = useIntl()

  const { values, setFieldValue } = useFormikContext()
  const handleDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            errorToast(
              formatMessage({
                id: "dashboard::forms::imageTooLarge",
                defaultMessage: "The file size must be smaller than 3MB",
              })
            )
          }

          if (err.code === "file-invalid-type") {
            errorToast(
              formatMessage({
                id: "dashboard::forms::invalidFileType",
                defaultMessage: "Invalid file type",
              })
            )
          }
        })
      })
      Promise.all(
        acceptedFiles.map((file, index) => {
          const id = `user-upload-${uuidv4()}`
          return {
            id,
            image: file,
            order: index,
          }
        })
      ).then((results) =>
        setFieldValue("images", [...values.images, ...results])
      )
    },
    [values.images]
  )

  const handleRemoveImage = useCallback(
    (id) => {
      id.indexOf("user-upload") === -1
        ? onDeleteImage({
            variables: { input: { id } },
          })
            .then(({ data, errors }) => {
              if (errors?.length) {
                throw new Error(errors[0].message)
              } else if (data) {
                setFieldValue("images", [
                  ...values.images.filter((img) => img.id !== id),
                ])
              }
            })
            .catch((err) => {
              errorToast(err?.[0]?.message || err?.message || err)
            })
        : setFieldValue("images", [
            ...values.images.filter((img) => img.id !== id),
          ])
    },
    [values.images]
  )

  return (
    <>
      <Dropzone
        onDrop={handleDrop}
        accept={"image/*"}
        isEmpty={values.images?.length === 0}>
        <ImageList
          images={values.images}
          onRemove={handleRemoveImage}
          onEdit={(id) =>
            setCroppingImage(values.images.find((image) => image.id === id))
          }
          processing={processingImages}
        />
      </Dropzone>
      <ImageCropper
        isOpen={!!croppingImage}
        image={croppingImage?.image}
        id={croppingImage?.id}
        onConfirm={({ result, id }) => {
          const newImages = values.images.map((image) =>
            image.id === id ? { ...image, image: result } : image
          )
          setFieldValue("images", newImages)
          setCroppingImage(null)
        }}
        onCancel={() => setCroppingImage(null)}
      />
    </>
  )
}

export default ImageGallery
