import styled from "@emotion/styled"

export const StyledSliceShareCampaign = styled.div`
  background-color: rgb(250, 249, 246);
  margin: 3.125rem auto;
  padding: 2rem 1rem;
  box-sizing: border-box;
  color: ${(p) => p.theme.colors.primaryBrandTextColor};

  & > div {
    max-width: ${(p) => p.theme.breakpoints.wide};
    padding: 0 1rem;
    margin: auto;
    display: grid;
    align-items: center;
    grid-template-columns: 1.4fr 2.6fr 1fr;
    grid-gap: 0.625rem;

    button {
      margin-right: 0.9375rem;
    }

    svg {
      font-size: 2.3125rem;
      height: 2.3125rem;
    }
    svg:last-of-type {
      margin-right: 0;
    }
  }

  @media only screen and (max-width: 425px) {
    div {
      display: block !important;
      padding: 0;
      p {
        margin: 1rem 0;
      }
    }
  }
`
export const StyledShareSocial = styled.div`
  align-self: center;
  justify-self: end;
`
