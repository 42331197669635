import React, { useRef, forwardRef } from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import { FormattedMessage } from "gatsby-plugin-intl"
import { Tooltip } from "react-tooltip"

import {
  TextInputWrapper,
  InputFeedback,
  InputLabel,
  StyledNumberFormat,
  StyledInputWrapper,
  StyledInput,
  StyledError,
  StyledTextAreaWrapper,
} from "./index.styles"

const InputError = ({ error, touched }) =>
  error && touched ? (
    <InputFeedback className="input-feedback">{error}</InputFeedback>
  ) : null

const Label = ({ error, className, children, ...props }) => {
  return (
    <InputLabel
      className={`label ${className || ""}`}
      {...props}
      onClick={(e) => e.target.parentNode.classList.add("active")}>
      {children}
    </InputLabel>
  )
}

const TextInput = forwardRef(
  (
    {
      type,
      id,
      name,
      newDesign,
      label,
      error,
      touched,
      value,
      onChange,
      onFocus = () => {},
      onValueChange,
      className,
      inputClassName,
      children,
      onKeyDown,
      min,
      onBlur,
      max,
      maxLength,
      minLength,
      step,
      textAreaMaxLength,
      autoComplete = "off",
      langOverride,
      inputDataTip,
      inputDataFor,
      inputIcon,
      iconLeft,
      iconRight,
      ...props
    },
    ref
  ) => {
    const textAreaRef = useRef()

    const classes = classnames(
      {
        "error active": !!error,
      },
      className
    )
    const inputWapperClasses = classnames(
      "input-wrapper",
      {
        error: !!error,
      },
      className
    )

    const allowedKeys = [
      "backspace",
      "delete",
      "arrowleft",
      "arrowright",
      "tab",
      ".",
      ",",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "0",
    ]

    const handleKeyDown = (evt) => {
      if (
        !allowedKeys.includes(evt?.key.toLowerCase()) &&
        evt.target.value.length > maxLength
      )
        evt.preventDefault()
    }

    const handleFocus = ({ target }) => {
      while (
        target &&
        target.parentNode &&
        target.parentNode.classList &&
        !target.parentNode.classList.contains("float-container")
      ) {
        target = target.parentNode
      }
      target &&
        target.parentNode &&
        target.parentNode.classList &&
        target.parentNode.classList.add("active")
    }

    const handleBlur = (e) => {
      const { target } = e
      target &&
        target.parentNode &&
        target.parentNode.classList &&
        target.parentNode.classList.remove("active")

      if (typeof onBlur === "function") onBlur(e)
    }

    let inputProps = { ...props }
    delete inputProps.component
    return newDesign ? (
      <StyledInputWrapper
        className={inputWapperClasses}
        maxLength={maxLength}
        iconLeft={iconLeft}
        iconRight={iconRight}>
        {inputDataFor ? (
          <Tooltip
            place="top"
            type="info"
            effect="solid"
            arrowColor="transparent"
            textColor="black"
            backgroundColor="white"
            id={inputDataFor}
            className="tooltip"
            delayShow={200}
          />
        ) : null}
        {label ? (
          <Label className="caption" htmlFor={label}>
            {label}
          </Label>
        ) : null}
        {type === "textarea" ? (
          <>
            <StyledTextAreaWrapper
              {...inputProps}
              data-testid={`textarea-${id}`}
              id={id}
              ref={textAreaRef}
              name={name}
              className={inputClassName}
              type={type}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              onFocus={handleFocus}
              maxLength={maxLength}
              error={!!error && touched}
              autoComplete={autoComplete}
            />

            {maxLength && (
              <p className="textarea-counter">
                {langOverride === "en" ? (
                  `${textAreaRef?.current?.value?.length} characters used out of ${maxLength}`
                ) : langOverride === "es" ? (
                  `${textAreaRef?.current?.value?.length} caracteres usados de ${maxLength}`
                ) : langOverride === "it" ? (
                  `${textAreaRef?.current?.value?.length} caratteri utilizzati su ${maxLength}`
                ) : (
                  <FormattedMessage
                    id="form::textarea-textCounter"
                    defaultMessage="{used} characters used out of {total}"
                    values={{
                      used: textAreaRef?.current?.value?.length || 0,
                      total: maxLength,
                    }}
                  />
                )}
              </p>
            )}
          </>
        ) : type === "currency" ? (
          <StyledNumberFormat
            {...inputProps}
            data-testid={`input-${id}`}
            data-tooltip-content={inputDataTip}
            data-tooltip-id={inputDataFor}
            id={id}
            name={name}
            thousandSeparator={","}
            decimalSeparator={"."}
            decimalScale={2}
            allowNegative={false}
            onChange={onChange}
            onBlur={onBlur}
            onKeyDown={handleKeyDown}
            onValueChange={onValueChange}
            maxLength={maxLength}
            minLength={minLength}
            value={value}
            data-error={!!error && touched}
          />
        ) : (
          <>
            <StyledInput
              {...inputProps}
              data-tooltip-content={inputDataTip}
              data-tooltip-id={inputDataFor}
              ref={ref}
              data-testid={`input-${id}`}
              name={name}
              className={`common-input ${inputClassName}`}
              type={type}
              value={value}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              onKeyDown={type === "number" ? handleKeyDown : () => {}}
              min={min}
              max={max}
              maxLength={maxLength}
              minLength={minLength}
              step={step}
              error={!!error && touched}
              autoComplete={autoComplete}
            />
            {inputIcon}
          </>
        )}
        {error && touched ? (
          <StyledError className="input-feedback-absolute">{error}</StyledError>
        ) : null}
      </StyledInputWrapper>
    ) : (
      <TextInputWrapper className={classes}>
        {children}
        {type === "textarea" ? (
          <>
            <textarea
              {...inputProps}
              data-testid={`textarea-${id}`}
              id={id}
              ref={textAreaRef}
              name={name}
              className={inputClassName}
              type={type}
              value={value}
              onChange={onChange}
              onFocus={handleFocus}
              maxLength={textAreaMaxLength}
            />
            {textAreaMaxLength && (
              <p className="textarea-counter">
                {`${
                  textAreaRef?.current?.value?.length || 0
                } / ${textAreaMaxLength}`}
              </p>
            )}
          </>
        ) : type === "currency" ? (
          <StyledNumberFormat
            {...inputProps}
            data-testid={`input-${id}`}
            id={id}
            name={name}
            thousandSeparator={","}
            decimalSeparator={"."}
            decimalScale={2}
            allowNegative={false}
            onChange={onChange}
            onKeyDown={handleKeyDown}
            onValueChange={onValueChange}
            maxLength={maxLength}
            minLength={minLength}
            value={value}
          />
        ) : (
          <input
            {...inputProps}
            data-testid={`input-${id}`}
            id={id}
            name={name}
            className={inputClassName}
            type={type}
            value={value}
            onChange={onChange}
            onFocus={onFocus}
            onClick={handleFocus}
            onBlur={handleBlur}
            onKeyDown={type === "number" ? handleKeyDown : () => {}}
            min={min}
            max={max}
            maxLength={maxLength}
            minLength={minLength}
            step={step}
          />
        )}

        {label ? (
          <Label htmlFor={id} error={error}>
            {label}
          </Label>
        ) : null}

        <InputError error={error} touched={touched} />
      </TextInputWrapper>
    )
  }
)

TextInput.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
  ]),
  onChange: PropTypes.func,
  onValueChange: PropTypes.func,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
}

export default TextInput
