import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"

import {
  StyledBlogJoiningContainer,
  StyledBlogTitle,
  StyledBlogContent,
  StyledBlogButton,
  StyledJoiningTitle,
  StyledJoiningContent,
  StyledJoiningButton,
} from "./index.styles"

const BlogCareerSection = () => {
  return (
    <StyledBlogJoiningContainer className="blog-joining-wrapper">
      <StyledBlogTitle>
        <FormattedMessage
          id="aboutUs::blog::title"
          defaultMessage="TrustMeUp Blog"
        />
      </StyledBlogTitle>
      <StyledBlogContent>
        <FormattedMessage
          id="aboutUs::blog::content"
          defaultMessage="Discover the articles of the activity on the platform, charities and online stores present."
        />
      </StyledBlogContent>
      <StyledBlogButton
        variant="link"
        text={
          <FormattedMessage id="aboutUs::blog::link" defaultMessage="Blog" />
        }
        onClick={() => {
          window.location.href = "https://blog.trustmeup.com/"
        }}
      />
      <StyledJoiningTitle>
        <FormattedMessage
          id="aboutUs::joining::title"
          defaultMessage="Do you want to work with TrustMeUp?"
        />
      </StyledJoiningTitle>
      <StyledJoiningContent>
        <FormattedMessage
          id="aboutUs::joining::content"
          defaultMessage="Discover the open positions and become a member of the team."
        />
      </StyledJoiningContent>
      <StyledJoiningButton
        variant="link"
        text={
          <FormattedMessage
            id="aboutUs::joining::link"
            defaultMessage="Careers"
          />
        }
        onClick={() => {
          window.location.href = "https://jobs.trustmeup.com/jobs/Careers"
        }}
      />
    </StyledBlogJoiningContainer>
  )
}

export default BlogCareerSection
