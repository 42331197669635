import React, { Fragment } from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import { Money } from "@tmu/components/common"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { CURRENCY_DIGIT_COUNT } from "@tmu/apollo/constants"
import { StyledStats } from "./index.styles"

export const CardStats = ({ className, totalDonors, totalSupport, size }) => {
  const digitCount = totalSupport?.replace(".", "")?.replace(",", "")?.length

  const { isTablet } = getAllScreenTypes()

  return (
    <StyledStats size={size} className={className} data-testid={`cardStats`}>
      <p className="caption">
        <FormattedMessage
          id="progress::supporterCount"
          defaultMessage="{current, plural, =1 {<one>#</one> Donation} other {<num>#</num> Donations}}"
          values={{
            one: () => <span className="amount">1</span>,
            num: (...chunks) => (
              <span className="amount">
                {chunks.map((chunk, i) => (
                  <Fragment key={i}>{chunk !== "NaN" ? chunk : 0}</Fragment>
                ))}
              </span>
            ),
            current: Number(totalDonors),
          }}
        />
      </p>
      <p className="caption">
        <FormattedMessage
          id="progress::detail::totalSupport"
          defaultMessage="<funded>{amount}</funded> Raised"
          values={{
            funded: (...chunks) => (
              <span
                className={`amount ${
                  !isTablet & (digitCount >= CURRENCY_DIGIT_COUNT)
                    ? "right-eight-digit"
                    : "right"
                }`}>
                {chunks.map((chunk, i) => (
                  <Fragment key={i}>{chunk}</Fragment>
                ))}
              </span>
            ),
            amount: (
              <Money value={Number(totalSupport)} maximumFractionDigits={0} />
            ),
          }}
        />
      </p>
    </StyledStats>
  )
}
