import styled from "@emotion/styled"
import { css } from "@emotion/react"

export const StyledButton = styled.button`
  font-family: ${(p) => p.theme.fontFamilies?.headline};
  font-style: ${(p) => p.theme.fontStyles?.normal};
  font-weight: ${(p) => p.theme.fontWeights?.semiBolder};
  display: flex;
  align-items: center;
  text-align: ${(p) => p.align};
  justify-content: ${(p) =>
    p.align === "right"
      ? "flex-end"
      : p.align === "left"
      ? "flex-start"
      : "center"};
  text-transform: uppercase;
  transition: all 0.3s ease;
  letter-spacing: 0.04em;
  line-height: 0.87875rem;
  box-sizing: border-box;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  border-radius: ${(p) => (p.rounded ? "1.25rem" : "0.25rem")};
  margin: 0.2rem;
  color: ${({ disabled, buttonColor, theme: { colors = {} } }) =>
    buttonColor === "transparent"
      ? disabled
        ? colors.disabled
        : colors["black"]
      : colors["white"]};

  border: ${({ disabled, buttonColor, theme: { colors = {} } }) =>
    buttonColor === "transparent"
      ? `0.0625rem solid ${disabled ? colors.disabled : colors["black"]}`
      : "none"};
  background-color: ${({
    disabled,
    buttonColor = "black",
    theme: { colors = {} },
  }) =>
    disabled
      ? buttonColor === "transparent"
        ? "transparent"
        : colors.disabled
      : colors[buttonColor]};

  &:hover,
  &:focus {
    background-color: ${({ buttonColor, disabled, theme: { colors = {} } }) =>
      disabled ? colors.disabled : colors[buttonColor]};
    border: ${({ disabled, buttonColor, theme: { colors = {} } }) =>
      buttonColor === "transparent"
        ? `0.0625rem solid ${colors["blue"]}`
        : "none"};

    color: ${({ buttonColor, theme: { colors } }) =>
      buttonColor === "transparent" && colors["blue"]};
  }

  ${(p) => {
    switch (p.size) {
      case "small":
        return css`
          min-height: auto;
          padding: 0;
          font-size: 0.75rem;
          line-height: 0.87875rem;
        `
      case "big":
        return css`
          min-height: 2.625rem;
          padding: 0.71875rem 7.90625rem;
          font-size: ${p.variant === "link" ? "1.25rem" : "1rem"};
          line-height: ${p.variant === "link" ? "1.5rem" : "1.171875rem"};
          @media screen and (min-width: ${p.theme.breakpoints.wide}) {
            min-height: 3.375rem;
            font-size: 1rem;
          }
        `
      case "huge":
        return css`
          min-height: 3.75rem;
          padding: 1.28125rem 10.40625rem;
          font-size: 1rem;
          line-height: 1.171875rem;
        `
      default:
        // medium
        return css`
          min-height: 2.5rem;
          padding: 0.25rem 2rem;
          font-size: ${p.variant === "link" || p.variant === "icon"
            ? "1rem"
            : "0.75rem"};
          line-height: ${p.variant === "link" ? "1.5rem" : "0.9125rem"};
          @media screen and (min-width: ${p.theme?.breakpoints?.wide}) {
            min-height: 3.375rem;
            font-size: 1rem;
          }
        `
    }
  }}

  ${(p) =>
    p.variant === "pill" &&
    css`
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      font-size: 0.9375rem;
      font-weight: ${p.theme.fontWeights.bold};
      transition: all 0.3s ease 0s;
      min-height: auto;
      line-height: normal;
      margin: 0.2rem;
      padding: 0.45rem 0.5rem;
      box-sizing: border-box;
      cursor: pointer;
      color: ${p.theme.colors.disabled};
      background-color: ${p.theme.colors.transparent};
      border-radius: 1.25rem;
      text-transform: none;
      letter-spacing: normal;
      &:focus {
        outline: none;
      }
      &.active {
        background-color: ${p.theme.colors.transparent};
        color: ${p.theme.colors.pitchBlack}!important;
      }

      &:not(.active) {
        color: ${p.theme.colors.disabled};
        background-color: ${p.theme.colors.transparent};
      }
    `}

    ${(p) =>
    p.variant === "text" &&
    css`
      font-weight: ${p.theme.fontWeights.regular};
      min-height: auto;
      border: none;
      color: ${p.theme.colors.black};
      text-transform: none;
      letter-spacing: normal;
      &,
      &:hover,
      &:focus {
        background: transparent;
        border: none;
      }
    `}
    
    ${(p) =>
    p.variant === "icon"
      ? css`
          background-color: transparent;
          &:hover,
          &:focus {
            font-size: 120%;
            background-color: transparent;
          }
        `
      : css`
          svg,
          i {
            margin-right: 0.625rem;
          }

          @media screen and (max-width: ${p.theme?.breakpoints?.desktop}) {
            svg,
            i {
              margin-right: 0.625rem;
            }
          }

          @media screen and (max-width: ${p.theme?.breakpoints?.phone}) {
            svg,
            i {
              margin-right: 0.625rem;
            }
          }
        `}

  ${(p) =>
    p.disabled &&
    css`
      pointer-events: none;
    `}
`

export const StyledLinkButton = styled.button`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
  min-height: auto;
  min-width: fit-content;
  p {
    font-style: ${(p) => p.theme.fontStyles?.normal};
    font-family: ${(p) => p.theme.fontFamilies?.headline};
    font-weight: ${(p) => p.theme.fontWeights?.semiBolder};
    display: flex;
    align-items: center;
    color: ${(p) => p.theme.colors?.blue};
    svg {
      margin-left: 1rem;
      font-weight: ${(p) => p.theme.fontWeights?.bold};
      display: none;
      @media screen and (min-width: ${(p) => p.theme.breakpoints?.tablet}) {
        display: inline-block;
      }
    }
    ${(p) => {
      switch (p.size) {
        case "big":
          return css`
            font-size: 1.25rem;
            line-height: 1.75rem;
            svg {
              font-size: 1rem;
              height: 1rem;
            }
            span {
              font-family: ${p.theme.fontFamilies.headline};
            }
          `
        default:
          // medium
          return css`
            font-size: 1rem;
            line-height: 1.5rem;
            svg {
              font-size: 0.75rem;
              height: 0.75rem;
            }
          `
      }
    }}

    @media screen and (min-width: ${(p) => p.theme.breakpoints?.wide}) {
      font-size: 2rem;
      svg {
        position: relative;
        top: 0.125rem;
        font-size: 2rem;
        height: 2rem;
      }
      &.link-button-text span {
        font-size: 2rem;
      }
    }
  }
  svg {
    max-height: 1.5rem;
  }
`
