import { FormattedMessage } from "gatsby-plugin-intl"
import React from "react"
import { StyledCartTableHeader } from "./table.styles"

const CartTableHeader = ({ isSalesStatusVisible, index = "", page = "" }) => {
  return (
    <StyledCartTableHeader
      isSalesStatusVisible={isSalesStatusVisible}
      data-testid={"cart-table-header" + index}>
      {/* {isSalesStatusVisible && (
        <div>
          <FormattedMessage
            id="cart::tableHeader::voucher"
            defaultMessage="Voucher"
          />
        </div>
      )} */}
      <div>
        <FormattedMessage
          id="cart::tableHeader::offers"
          defaultMessage="Offers"
        />
      </div>
      <div />
      {/* {isSalesStatusVisible && (
        <div>
          <FormattedMessage
            id="cart::tableHeader::fulfillmentId"
            defaultMessage="Fulfillment ID"
          />
        </div>
      )} */}
      {!isSalesStatusVisible && (
        <div className="centered">
          <FormattedMessage
            id="cart::tableHeader::pacsUsed"
            defaultMessage="PACs Used"
          />
        </div>
      )}
      {/* <div className="centered">
        <FormattedMessage id="cart::tableHeader::tax" defaultMessage="Tax" />
      </div> */}
      <div className="centered">
        <FormattedMessage
          id="cart::tableHeader::quantity"
          defaultMessage="Quantity"
        />
      </div>
      <div className="centered">
        <FormattedMessage
          id="cart::tableHeader::price"
          defaultMessage="Price"
        />
      </div>
      {page === "dashboard-order" && (
        <div className="centered">
          <FormattedMessage
            id="dashboard::orders::invoice"
            defaultMessage="Invoice"
          />
        </div>
      )}
      {page === "dashboard-order" && (
        <div className="centered">
          <FormattedMessage
            id="dashboard::orders::requestRefund"
            defaultMessage="Request Refund"
          />
        </div>
      )}
      <div />
    </StyledCartTableHeader>
  )
}

export default CartTableHeader
