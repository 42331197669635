import React from "react"
import { Router } from "@reach/router"
import {
  Donations,
  DonationSearch,
  Orders,
  Wallet,
  DownloadCenter,
  ReferralCodeListings,
  ReferralCodeForm,
} from "@tmu/components/dashboard/dashboardPages"

const Donors = () => (
  <Router>
    <Wallet path="wallet/*" dashboardType="donors" />
    <Orders path="orders/*" dashboardType="donors" />
    <Donations path="donations/*" dashboardType="donors" />
    <DonationSearch path="donation-search/*" dashboardType="donors" />
    <DownloadCenter path="download-center/*" dashboardType="donors" />
    <ReferralCodeListings path="referral-codes/*" dashboardType="donors" />
    <ReferralCodeForm path="referral-codes/new" dashboardType="donors" />
    <ReferralCodeForm path="referral-codes/edit/:id" dashboardType="donors" />
  </Router>
)

export default Donors
