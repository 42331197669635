import React from "react"
import { FormattedMessage, Link, navigate } from "gatsby-plugin-intl"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { Button, Logo, Money } from "@tmu/components/common"
import theme from "@tmu/global/theme"
import {
  StyledUSPacsWrapper,
  StyledUSPacsTitle,
  StyledUSPacsContent,
} from "./index.styles"

const USPacs = ({ me }) => {
  const { isTablet, isDesktop, isWide } = getAllScreenTypes()

  return (
    <StyledUSPacsWrapper>
      <div>
        <StyledUSPacsTitle>
          <FormattedMessage
            id={`home::userSpace::${
              me?.isNewlyRegisteredAndGotSignupGift
                ? "registration"
                : !me?.isNewlyRegisteredAndGotSignupGift && me?.pacBalance > 0
                ? "earned"
                : "zero"
            }`}
            defaultMessage={
              me?.isNewlyRegisteredAndGotSignupGift
                ? "You received {pac} {amount} PACs for registration"
                : !me?.isNewlyRegisteredAndGotSignupGift && me?.pacBalance > 0
                ? "You have earned <pacamount>{pac} {amount} PACs</pacamount>"
                : "You have {pac} {amount} PACs"
            }
            values={{
              pac: (
                <Logo
                  className="pac-icon"
                  width={isWide ? 39 : isTablet || isDesktop ? 28 : 23}
                  height={isWide ? 33 : isTablet || isDesktop ? 23 : 19}
                  fill={theme?.colors?.white}
                />
              ),
              amount: <Money value={me?.pacBalance ?? 0} />,
              pacamount: (...chunks) => (
                <div key="pac-amount" className="pac-amount">
                  {chunks}
                </div>
              ),
            }}
          />
        </StyledUSPacsTitle>
        <StyledUSPacsContent>
          <Button
            variant="link"
            text={
              <FormattedMessage
                id={`home::userSpace::${
                  me?.isNewlyRegisteredAndGotSignupGift ||
                  (!me?.isNewlyRegisteredAndGotSignupGift && me?.pacBalance > 0)
                    ? "stores"
                    : "receive"
                }`}
                defaultMessage={
                  me?.isNewlyRegisteredAndGotSignupGift ||
                  (!me?.isNewlyRegisteredAndGotSignupGift && me?.pacBalance > 0)
                    ? "Choose how you will spend them in PAC Stores gallery"
                    : "Donate to receive PACs"
                }
              />
            }
            onClick={() => {
              navigate(
                `${
                  me?.isNewlyRegisteredAndGotSignupGift ||
                  (!me?.isNewlyRegisteredAndGotSignupGift && me?.pacBalance > 0)
                    ? "/offers/?offerrue&store=true"
                    : "/campaigns?campaign=true&charity=true&event=true"
                }`
              )
            }}
          />
          <span>
            <Link to={`/pacs`} aria-label="pacs" className="pacs-link">
              <FormattedMessage
                id="pacs::hero::title"
                defaultMessage="What are PACs?"
              />
            </Link>
          </span>
        </StyledUSPacsContent>
      </div>
    </StyledUSPacsWrapper>
  )
}

export default USPacs
