import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons/faCircleInfo"
import { LazyImage, Money, Spinner, Table } from "@tmu/components/common"
import { EmptyTable } from "@tmu/components/dashboard/dashboardCommon"
import { useDefaultMerchant } from "@tmu/src/hooks"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Spacer } from "@tmu/src/global/page-addons/detail-page.styles"
import { Tooltip } from "react-tooltip"
import {
  StyledLogoWrapper,
  StyledNameWrapper,
  StyledPageTotal,
  StyledPageTotalNumber,
  StyledPageWrapper,
  StyledServiceNameWrapper,
  StyledTable,
  StyledTextWrapper,
  StyledTypeWrapper,
} from "./index.styles"

const CorporateBrandsTable = () => {
  const { defaultMerchant, loading } = useDefaultMerchant()
  const { isTablet, isWide } = getAllScreenTypes()
  const { formatMessage } = useIntl()

  const sortChildStores = (childStores) => {
    const sortedChildStores = [...childStores]

    sortedChildStores.sort((a, b) => {
      const codeOrder = {
        MERCHANT_OFFLINE: 1,
        MERCHANT_INTERNAL: 2,
        MERCHANT_ONLINE: 3,
      }

      return codeOrder[a.pricingBundle.code] - codeOrder[b.pricingBundle.code]
    })

    return sortedChildStores
  }

  const allMerchants = sortChildStores(defaultMerchant?.childStores ?? [])

  const imgSize = {
    width: isWide ? 64 : 32,
    height: isWide ? 64 : 32,
  }

  const parentBrand = {
    id: defaultMerchant?.id,
    name: defaultMerchant?.name,
    activeCampaigns: defaultMerchant?.campaignCountForDisplay,
    totalDonations: defaultMerchant?.donationCountForDisplay,
    pacWalletDiscounts: defaultMerchant?.pacDiscountAmountForDisplay,
    totalRaised: defaultMerchant?.fundedAmountForDisplay,
    slug: defaultMerchant?.slug,
    merchant: defaultMerchant,
  }

  const totalText = "TOTAL"

  const totalLine = {
    id: totalText,
    name: formatMessage({
      id: "portfolio:brands::totalText",
      defaultMessage: "TOTAL",
    }),
    activeCampaigns: defaultMerchant?.totalCampaignCount,
    totalDonations: defaultMerchant?.totalDonationCount,
    pacWalletDiscounts: Number(defaultMerchant?.totalPacDiscountAmount),
    totalRaised: Number(defaultMerchant?.totalFundedAmount),
  }

  const checkTotal = (row) => {
    return row?.original?.id === totalText
  }

  const tableData = !allMerchants
    ? []
    : allMerchants?.map((node) => {
        return {
          id: node?.id,
          name: node?.name,
          activeCampaigns: node?.campaignCountForDisplay,
          totalDonations: node?.donationCountForDisplay,
          pacWalletDiscounts: node?.pacDiscountAmountForDisplay,
          totalRaised: node?.fundedAmountForDisplay,
          slug: node?.slug,
          merchant: node,
        }
      })

  tableData.unshift(parentBrand)

  tableData.push(totalLine)

  const pacWalletTooltipText = formatMessage({
    id: "portfolio:brands::pacWalletTooltipText",
    defaultMessage: "Fundraised amounts from your campaigns and purchases",
  })

  const totalRaisedTooltipText = formatMessage({
    id: "portfolio:brands::totalRaisedTooltipText",
    defaultMessage: "Total raised amounts from your campaigns and purchases",
  })

  const infoIcon = isTablet && (
    <FontAwesomeIcon className="icon info-icon" icon={faCircleInfo} />
  )

  const notApplicableText = "n/a"

  const columns = [
    {
      Header: (props) => (
        <div className="header-text">
          <FormattedMessage
            id="dashboard::tableHeader::merchant"
            defaultMessage="Merchant"
          />
        </div>
      ),
      Cell: (props) => {
        const { value, row } = props
        const merchant = row?.original?.merchant
        const offerType = merchant?.pricingBundle?.code?.replace(
          "MERCHANT_",
          ""
        )

        if (row?.original?.id === totalText) {
          return (
            <StyledNameWrapper
              title={value}
              className="merchant-name line-height">
              {value}
            </StyledNameWrapper>
          )
        }

        return (
          <StyledServiceNameWrapper>
            {isTablet && (
              <StyledLogoWrapper>
                <LazyImage
                  altName={`merhant-${value}`}
                  src={merchant?.logo}
                  {...imgSize}
                  fit="contain"
                />
              </StyledLogoWrapper>
            )}
            <StyledTextWrapper>
              <StyledNameWrapper
                title={value}
                className="merchant-name ellipsis-text">
                {value}
              </StyledNameWrapper>
              <StyledTypeWrapper className="merchant-type ellipsis-text">
                {offerType && (
                  <FormattedMessage
                    id={"storeFilter::type::" + offerType}
                    defaultMessage={offerType}
                  />
                )}
              </StyledTypeWrapper>
            </StyledTextWrapper>
          </StyledServiceNameWrapper>
        )
      },
      accessor: "name",
      disableSort: true,
    },
    {
      Header: (props) => (
        <div className="header-text header-icon align-right">
          <FormattedMessage
            id="dashboard::tableHeader::activeCampaigns"
            defaultMessage="Active Campaigns"
          />
        </div>
      ),
      Cell: ({ value, row }) => (
        <div
          className={`align-right ${checkTotal(row) ? "bold-text" : ""} ${
            row?.original?.id === totalText ? "merchant-name" : ""
          }`}>
          {isNaN(value) ? notApplicableText : value}
        </div>
      ),

      accessor: "activeCampaigns",
      disableSort: true,
    },
    {
      Header: (props) => (
        <div className="header-text header-icon align-right">
          <FormattedMessage
            id="dashboard::tableHeader::totalDonations"
            defaultMessage="Total Donations"
          />
        </div>
      ),
      Cell: ({ value, row }) => (
        <div
          className={`align-right ${checkTotal(row) ? "bold-text" : ""} ${
            row?.original?.id === totalText ? "merchant-name" : ""
          }`}>
          {isNaN(value) ? notApplicableText : value}
        </div>
      ),
      accessor: "totalDonations",
      disableSort: true,
    },
    {
      Header: (props) => (
        <div className="header-text header-icon align-right">
          <FormattedMessage
            id="dashboard::tableHeader::pacWalletDiscounts"
            defaultMessage="PAC Wallet Discounts"
          />
          <div
            data-tooltip-id="customTooltip"
            data-tooltip-content={pacWalletTooltipText}
            data-tooltip-place="bottom">
            {infoIcon}
          </div>
        </div>
      ),
      Cell: ({ value, row }) => (
        <div
          className={`align-right ${checkTotal(row) ? "bold-text" : ""} ${
            row?.original?.id === totalText ? "merchant-name" : ""
          }`}>
          {isNaN(value) ? notApplicableText : <Money value={value} />}
        </div>
      ),
      accessor: "pacWalletDiscounts",
      disableSort: true,
    },
    {
      Header: (props) => (
        <div className="header-text header-icon align-right">
          <FormattedMessage
            id="dashboard::tableHeader::totalRaised"
            defaultMessage="Total Raised"
          />
          <div
            data-tooltip-id="customTooltip"
            data-tooltip-content={totalRaisedTooltipText}
            data-tooltip-place="bottom">
            {infoIcon}
          </div>
        </div>
      ),
      Cell: ({ value, row }) => (
        <div
          className={`align-right ${checkTotal(row) ? "bold-text" : ""} ${
            row?.original?.id === totalText ? "merchant-name" : ""
          }`}>
          {isNaN(value) ? notApplicableText : <Money value={value} />}
        </div>
      ),
      accessor: "totalRaised",
      disableSort: true,
    },
  ]

  const hiddenColumns = isTablet ? [] : ["activeCampaigns"]

  return loading ? (
    <Spinner condensed />
  ) : tableData.length > 0 ? (
    <StyledPageWrapper>
      <StyledTable>
        <Table
          columns={columns}
          data={tableData}
          hiddenColumns={hiddenColumns}
          headerLineSeperator={true}
        />
      </StyledTable>
      <Spacer top={1} />
      <StyledPageTotal>
        <FormattedMessage
          id="dashboard::tableHeader::totalSocialImoact"
          defaultMessage="Total Social Impact"
        />
        <StyledPageTotalNumber>
          <Money
            value={totalLine?.totalRaised + totalLine?.pacWalletDiscounts}
          />
        </StyledPageTotalNumber>
      </StyledPageTotal>
      <Tooltip id="customTooltip" className="tooltip" />
    </StyledPageWrapper>
  ) : (
    <EmptyTable
      emptyMessage={
        <FormattedMessage
          id="dashboard::partner::services::empty"
          defaultMessage="It seems no service yet"
        />
      }
    />
  )
}

export default CorporateBrandsTable
