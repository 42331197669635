export const orderItemParts = `
  fragment orderItemParts on OrderNode {
    id
    status
    created
    total
    frozenTotal
    subtotal
    pacAmount
    discountAmount
    orderType
    redirectTo
    refundLink
    internalOrderValues
    user {
      email
    }
    intendedUser {
      email
    }
    store {
      id
      displayName
      name
    }
    campaign {
      id
      name
      nameEn
      nameIt
      nameEs
      pacMultiplier
    }
  }
`

export const cartItemParts = `
  fragment cartItemParts on CartItemNode {
    id
    status
    name
    created
    quantity
    frozenPrice
    frozenPacAmount
    frozenTmuFee
    frozenMaxPacAmount
    frozenSubtotal
    frozenTotal
    productId
    productName
    productPrice
    cartItemId
    taxAmount
    tickets {
      edges {
        node {
          id
          voucherCode
        }
      }
    }
    productVariation {
      name
      nameEn
      nameIt
      nameEs
      id
      price
      description
      descriptionEn
      descriptionIt
      descriptionEs
      pacDiscount
      maxTickets
      isUnlimitedTickets
      isSoldOut
      stockLeft
    }
    offer {
      id
      slug
      name
      image
      isVoucher
      endDate
      store {
        id
        slug
        name
        logo
      }
    }
  }
`
