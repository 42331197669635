import styled from "@emotion/styled"
import { Button } from "@tmu/components/common"

export const StyledHowItWorks = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 7.5rem;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 0.9375rem;
  }
`
export const StyledHowItWorksTitle = styled.h3`
  text-align: center;
  font-weight: ${(p) => p.theme.fontWeights.regular};
  font-size: 3.125rem;
  line-height: 3.75rem;
  color: ${(p) => p.theme.colors.black};
  padding-bottom: 1rem;
  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1.875rem;
    line-height: 1.875rem;
    padding-bottom: 0.625rem;
  }
`
export const StyledHowItWorksDescription = styled.span`
  max-width: 47.5rem;
  font-size: 1.25rem;
  line-height: 2.0625rem;
  text-align: center;
  margin: 0 auto;
  color: ${(p) => p.theme.colors.doveGray};
  padding-bottom: 3.75rem;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 0.9375rem;
    max-width: 18.75rem;
    padding-bottom: 1.875rem;
  }
`
export const StyledHowItWorksCards = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1.875rem;
  padding-bottom: 7.5rem;
  @media screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
    flex-direction: column;
    padding-bottom: 3.75rem;
  }
`

export const StyledHowItWorksDownloadTitle = styled.h3`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.regular};
  text-align: center;
  margin: 0 auto;
  font-size: 1.875rem;
  line-height: 2.25rem;
  color: ${(p) => p.theme.colors.pacificBlue};
  max-width: 55rem;
  padding-bottom: 2.1875rem;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 0.9375rem;
    max-width: 18.75rem;
    padding-bottom: 0.9375rem;
  }
`

export const StyledRegisterButton = styled(Button)`
  border: 0.0625rem solid ${(p) => p.theme.colors.gallery};
  border-radius: 0.25rem;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 1.5625rem;
`

export const StyledStores = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 11.25rem;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding-bottom: 3.125rem;
  }
`
export const StyledStoreButton = styled(Button)`
  border: 0.0625rem solid ${(p) => p.theme.colors.gallery};
  border-radius: 0.25rem;
`

export const StyledImg = styled.img`
  margin: 0 auto;
  width: 50rem;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
    width: 37.5rem;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    width: 28.125rem;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    width: 21.875rem;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    width: 10.625rem;
  }
`
