import styled from "@emotion/styled"

export const StyledWrapper = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    max-width: 30rem;
    margin-left: 2.25rem;
  }
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const StyledPageTitle = styled.div`
  text-transform: capitalize;
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-size: 1.5rem;
  font-weight: ${(p) => p.theme.fontFamilies.semiBolder};
  line-height: 2.5rem;
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 2rem;
  }
`
export const StyledAmountInfo = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-size: 2rem;
  font-weight: ${(p) => p.theme.fontFamilies.semiBolder};
  line-height: 2.5rem;
`
export const StyledDescription = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-size: 1rem;
  font-weight: ${(p) => p.theme.fontFamilies.liteMedium};
  line-height: 1.5rem;
`

export const StyledDetailContainer = styled.div`
  border-radius: 0.25rem;
  background: ${(p) => p.theme.colors.whiteSmoke};
  margin-left: -2rem;
  margin-right: -2rem;
  padding: 1.5rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin: 0;
    background: ${(p) => p.theme.colors.white};
    box-shadow: 0px 4px 12px 0px rgba(122, 119, 118, 0.4);
  }
`

export const StyledInfoContainer = styled.div`
  display: flex;
  gap: 1rem;
`

export const StyledCopyIcon = styled.div`
  color: ${(p) => p.theme.colors.pacificBlue};
`

export const StyledInfo = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-size: 1rem;
  font-weight: ${(p) => p.theme.fontFamilies.liteMedium};
  line-height: 1.5rem;
`

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    margin: 0;

    width: unset;
    @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      width: 40%;
    }
  }
  gap: 1rem;
  flex-direction: column;
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    flex-direction: row;
  }
`
export const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: start;

  gap: 1rem;
  .back {
    color: ${(p) => p.theme.colors.pacificBlue};
    cursor: pointer;
  }
  flex-direction: column;
  align-items: start;
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    flex-direction: row;
    align-items: center;
    margin-left: -2rem;
  }
`
