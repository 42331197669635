import {
  injectIntl,
  useIntl,
  FormattedMessage,
  navigate,
} from "gatsby-plugin-intl"
import React, {
  Fragment,
  useCallback,
  useEffect,
  useState,
  useContext,
} from "react"
import {
  StyledWrapper,
  StyledBigText,
  StyledDescription,
} from "./index.style.js"
import { Button } from "@tmu/components/common"

const MerchantSelfRegistrationSection = ({
  isMerchantInternal,
  isMerchantOffline,
  isMerchantOnline,
}) => {
  const handleRedirect = () => {
    navigate(
      `/merchant-registration/?type=${
        isMerchantInternal
          ? "internal"
          : isMerchantOnline
          ? "online"
          : "offline"
      }`
    )
  }
  return (
    <StyledWrapper>
      <StyledBigText>
        <FormattedMessage
          id="merchant::selfRegistration::bigText"
          defaultMessage="Display your professional services for <colored>€ 397 / year </colored>"
          values={{
            colored: (...chunks) => (
              <span className="colored">
                {chunks.map((chunk, i) => (
                  <Fragment key={i}>{chunk}</Fragment>
                ))}
              </span>
            ),
          }}
        />
      </StyledBigText>
      <StyledDescription>
        <FormattedMessage
          id="merchant::selfRegistration::description1"
          defaultMessage="We recommend to <bold>register with your company email</bold> first to become a merchant"
          values={{
            bold: (...chunks) => (
              <span className="bold">
                {chunks.map((chunk, i) => (
                  <Fragment key={i}>{chunk}</Fragment>
                ))}
              </span>
            ),
          }}
        />
        <br />
        <br />
        <FormattedMessage
          id="merchant::selfRegistration::description2"
          defaultMessage="Only merchants located in the EU and using the Euro as currency are eligible to join"
        />
      </StyledDescription>

      <Button
        label="joinMerchant"
        type="button"
        color="carrot"
        onClick={handleRedirect}>
        {isMerchantInternal ? (
          <FormattedMessage
            id="merchant::selfRegistration::buttonTextInternal"
            defaultMessage="join as an internal merchant"
          />
        ) : isMerchantOffline ? (
          <FormattedMessage
            id="merchant::selfRegistration::buttonTextOffline"
            defaultMessage="join as an offline merchant"
          />
        ) : (
          <FormattedMessage
            id="merchant::selfRegistration::buttonTextOnline"
            defaultMessage="join as an online merchant"
          />
        )}
      </Button>
    </StyledWrapper>
  )
}

export default MerchantSelfRegistrationSection
