import styled from "@emotion/styled"
import { css } from "@emotion/react"

export const StyledMainContainer = styled.div``

export const StyledCampaignChooseCauses = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 0.375rem;
  row-gap: 0.5rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    column-gap: unset;
    gap: 0.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    gap: 1rem;
  }

  .campaign-choose-cause-card {
    border: 0.125rem solid ${(p) => p.theme.colors.darkGrey};
    border-radius: 0.25rem;

    padding: 1rem 1rem;
    margin: unset;

    text-align: center;

    div {
      font-family: ${(p) => p.theme.fontFamilies.headline};
      font-style: ${(p) => p.theme.fontStyles.normal};
      font-weight: ${(p) => p.theme.fontWeights.semiBolder};

      padding: unset;

      font-size: 0.75rem;
      line-height: 1.125rem;
      letter-spacing: 0.01em;

      @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      }

      @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      }

      @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
        font-size: 1.5rem;
        line-height: 2rem;

        letter-spacing: unset;
      }
    }

    i {
      color: ${(p) => p.theme.colors.blue};

      font-size: 1.375rem;

      @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      }

      @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      }

      @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
        font-size: 2.75rem;
      }
    }

    &.selected {
      border: 0.125rem solid ${(p) => p.theme.colors.blue};
    }

    &.error {
      border: 0.0625rem solid ${(p) => p.theme.colors.red};
    }

    &:hover {
      border: 0.0625rem solid ${(p) => p.theme.colors.blue};
    }
  }
`
/* Cause Type Card Styles */
export const StyledCardWrapper = styled.div`
  padding: 1.6rem 1rem;
  border: 0.0625rem solid ${(p) => p.theme.colors.disabled};
  border-radius: 0.25rem;
  width: 100%;
  cursor: pointer;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    min-width: 5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    padding: 1.25rem 0;
    margin-bottom: 1rem;
    width: 100%;
  }

  svg {
    color: ${(p) => p.theme.colors.softGreen};
  }

  &:hover {
    border: 0.0625rem solid ${(p) => p.theme.colors.softGreen};
  }
  ${(p) =>
    p.disabled &&
    css`
      background: ${p.theme.colors.whiteSmoke};
      pointer-events: none;
    `}
`

export const StyledCampaignType = styled.div`
  padding: 1.25rem 4.0625rem;
  color: ${(p) => p.theme.colors.pitchBlack};
  font-size: 1.125rem;
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  line-height: 1.5rem;
  font-family: ${(p) => p.theme.fontFamilies.headline};
`
