import { useEffect } from "react"
import { useLazyQuery, gql } from "@apollo/client"
import { useApolloApiClients } from "@tmu/apollo/client"

const useDonationStatuses = ({ ...rest }) => {
  const { storefrontClient } = useApolloApiClients()

  const [callTypeConstants, { data, loading, called }] = useLazyQuery(
    gql`
      query TypeConstants {
        donationStatuses: __type(name: "DonationsDonationStatusChoices") {
          enumValues {
            name
            description
          }
        }
      }
    `,
    {
      client: storefrontClient,
      variables: { isPublic: true },
      ...rest,
    }
  )

  useEffect(() => {
    if (rest === null || rest?.callImmediately !== false) {
      if (!called) {
        callTypeConstants()
      }
    }
  }, [])

  const donationStatuses = data?.donationStatuses
    ? data.donationStatuses.enumValues.map(({ name, description }) => ({
        id: name,
        description,
      }))
    : []

  return {
    donationStatuses,
    callTypeConstants,
  }
}

export default useDonationStatuses
