import styled from "@emotion/styled"

export const StyledVideoWrapper = styled.div`
  position: relative
  min-height: 22.5rem;
  min-width: 43.0625rem;
  padding: 1.125rem 1.5rem;
  width: 43.125rem;
  background: ${(p) => p.theme.colors.primaryBrandTextColor};
  color: ${(p) => p.theme.colors.white};

  .success-video {
    diplay: flex;
  }
`

export const StyledPlayer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const StyledVideoWrapperDashed = styled.div`
  position: relative;
  padding: 4.6875rem 4.0625rem;
  box-sizing: border-box;
  border: 0.125rem dashed ${(p) => p.theme.colors.white};
  background-color: transparent;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    font-size: 4.5rem;
    height: 4.5rem;
    margin-bottom: 1.125rem;
    opacity: 0.4;
  }

  span {
    font-size: 1rem;
    margin-bottom: 1.5625rem;
  }

  .enter-video-appear {
    input {
      height: 3.25rem;
      width: 32rem;
      border: 0.0625rem solid rgba(13, 8, 9, 0.15);
      background-color: ${(p) => p.theme.colors.white};
      padding-left: 1.875rem;
      outline: none;
      font-size: 1.0625rem;
    }
  }
`
