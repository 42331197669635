import React, { useEffect, useState } from "react"
import { Button, LazyImage, Spinner, TextInput } from "@tmu/components/common"
import { useIntl, FormattedMessage, navigate } from "gatsby-plugin-intl"
import {
  StyledButtonContainer,
  StyledContainer,
  StyledDescription,
  StyledInputContainer,
  StyledLeftContent,
  StyledLogo,
  StyledRightContent,
  StyledTitle,
} from "./index.styles"
import { Spacer } from "@tmu/global/page-addons/detail-page.styles"
import { Form, Formik } from "formik"
import { StyledSectionTitle } from "../../dashboard/dashboardPages/Profile/index.styles"
import { useCampaigns, usePacDiscountCalculation, useToast } from "@tmu/hooks"
import { getValueForLocale } from "@tmu/utils/string"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { paymentAmount } from "@tmu/utils/validation"
import * as Yup from "yup"

const CalculateDiscount = ({ slug, ...rest }) => {
  const [firstLoad, setFirstLoad] = useState(false)
  const { locale, defaultLocale, formatMessage } = useIntl()
  const { callCampaignDetail, campaign } = useCampaigns()
  const { createPacRecon, createdReconData } = usePacDiscountCalculation()
  const { error: errorToast } = useToast()

  const { isTablet, isWide } = getAllScreenTypes()

  useEffect(() => {
    if (slug?.length > 0) {
      callCampaignDetail({ variables: { slug } })
    }
  }, [])

  useEffect(() => {
    if (
      createdReconData?.id?.length > 0 &&
      createdReconData?.campaign?.slug?.length > 0
    ) {
      navigate(
        `/campaigns/${createdReconData?.campaign?.slug}/donate/?reconId=${createdReconData?.id}`
      )
    }
  }, createdReconData)

  const validationSchema = Yup.object().shape({
    purchaseAmount: paymentAmount({ formatMessage, minValue: 1 }),
  })

  const logo = getValueForLocale(campaign?.store, "logo", locale, defaultLocale)

  const defaultOffer = campaign?.store?.offers?.edges
    ?.filter((item) => item?.node?.isDefaultOffer)
    ?.at(0)?.node

  const handleSubmit = (values, { setFieldError }) => {
    createPacRecon({
      variables: {
        input: {
          campaign: campaign?.id,
          offer: defaultOffer?.id,
          totalAmount: values.purchaseAmount.toString(),
        },
      },
    })
      .then(({ data }) => {
        setFieldError(
          "purchaseAmount",
          data?.createMerchantPacReconciliation?.errors?.[0]?.messages?.[0]
        )
      })
      .catch((err) => {
        errorToast(
          formatMessage({
            id: "calculate::discount::errorMessage",
            defaultMessage: "An error occurred",
          })
        )
      })
  }

  const initialValues = { purchaseAmount: "" }

  const title = (
    <StyledTitle>
      <FormattedMessage
        id="calculate::discount::title"
        defaultMessage="Calculate your discount"
      />
    </StyledTitle>
  )

  const logoContent = (
    <StyledLogo>
      <LazyImage
        width={isWide ? "414" : isTablet ? "240" : "328"}
        height={isWide ? "242" : !isTablet ? "140" : "192"}
        src={logo}
        altName={`card-image-${logo}`}
        fit="contain"
      />
    </StyledLogo>
  )

  const description = (
    <StyledDescription>
      <FormattedMessage
        id="calculate::discount::description"
        defaultMessage="Enter your purchase amount to calculate your donation amount"
      />
    </StyledDescription>
  )

  const formData = (
    <StyledInputContainer>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}>
        {({
          values,
          handleChange,
          handleBlur,
          handleReset,
          touched,
          errors,
          setFieldTouched,
          setFieldValue,
          isValid,
        }) => (
          <Form>
            <StyledSectionTitle>
              <FormattedMessage
                id="calculate::discount::purchaseAmountLabel"
                defaultMessage="Purchase Amount"
              />
            </StyledSectionTitle>
            <Spacer top={0.5} />
            <TextInput
              newDesign
              className="currency-icon"
              id="purchaseAmount"
              name="purchaseAmount"
              value={values.purchaseAmount}
              placeholder=""
              error={errors.purchaseAmount}
              touched={touched.purchaseAmount}
              onBlur={() => setFieldTouched("purchaseAmount")}
              onValueChange={({ value }) => {
                setFieldValue(
                  "purchaseAmount",
                  value ? Number(value) : "",
                  true
                )
              }}
              type="currency"
              maxLength={8}
            />
            <Spacer top={3} />
            <StyledButtonContainer
              className={!isTablet ? "sticky-bottom-bar" : ""}>
              <Button
                data-testid="btn-save"
                color="carrot"
                type="submit"
                disabled={false}>
                <FormattedMessage
                  id="calculate::discount::button"
                  defaultMessage="Calculate Discount"
                />
              </Button>
            </StyledButtonContainer>
          </Form>
        )}
      </Formik>
    </StyledInputContainer>
  )

  useEffect(() => {
    setFirstLoad(true)
  }, [])

  if (!firstLoad) {
    return (
      <span>
        <Spinner />
      </span>
    )
  }

  return (
    <StyledContainer>
      {isTablet ? (
        <>
          <StyledLeftContent>
            <Spacer top={5} />
            {logoContent}
          </StyledLeftContent>

          <StyledRightContent>
            {title}
            <Spacer top={2} />
            {description}
            <Spacer top={2} />
            {formData}
            <Spacer top={1.5} />
          </StyledRightContent>
        </>
      ) : (
        <>
          {title}
          {logoContent}
          {description}
          {formData}
        </>
      )}
    </StyledContainer>
  )
}

export default CalculateDiscount
