import { useQuery, gql } from "@apollo/client"
import { useApolloApiClients } from "@tmu/apollo/client"
import { useIntl } from "gatsby-plugin-intl"
import { getValueForLocale } from "@tmu/utils/string"
import { MAX_PER_PAGE } from "@tmu/apollo/constants"

const useAllPartnerCategories = (options) => {
  const { storefrontClient } = useApolloApiClients()
  const { locale, defaultLocale } = useIntl()
  const { loading, data } = useQuery(
    gql`
      query PartnerCategories($first: Int) {
        allPartnerCategories(first: $first) {
          edges {
            node {
              id
              slug
              nameEn
              nameIt
              nameEs
              icon
            }
          }
        }
      }
    `,
    {
      ...options,
      client: storefrontClient,
      variables: { first: MAX_PER_PAGE, isPublic: true },
    }
  )
  return loading
    ? []
    : data?.allPartnerCategories?.edges?.map(({ node }) => ({
        ...node,
        name: getValueForLocale(node, "name", locale, defaultLocale),
      })) ?? []
}

export default useAllPartnerCategories
