import { dateLocales } from "@tmu/src/apollo/constants"
import { format } from "date-fns"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import {
  StyledEndDate,
  StyledExpiryDate,
  StyledUseBefore,
} from "./index.styles"

const UseBefore = ({ isVoucher, endDate, isCampaign }) => {
  const { locale } = useIntl()

  const content =
    isVoucher && endDate ? (
      <StyledExpiryDate>
        <StyledUseBefore>
          {isCampaign ? (
            <FormattedMessage
              id="purchase::success::useBefore2"
              defaultMessage="Use Before"
            />
          ) : (
            <FormattedMessage
              id="purchase::success::useBefore"
              defaultMessage="Use Before"
            />
          )}
        </StyledUseBefore>{" "}
        <StyledEndDate className={isCampaign ? "blue-text" : ""}>
          {endDate
            ? format(new Date(endDate), "MMMM do yyyy", {
                locale: dateLocales[locale],
              })
            : ""}
        </StyledEndDate>
      </StyledExpiryDate>
    ) : (
      <></>
    )

  return content
}

export default UseBefore
