import styled from "@emotion/styled"
import { Button, Accordion } from "@tmu/components/common"

export const StyledFooterWrapper = styled.div`
  max-width: 85rem;
  margin: auto;
  padding: 2.5rem 1rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem 1rem;
  grid-template-areas:
    "logo logo"
    "join-tmu-section about-section"
    "explore-section  contact-section"
    "copyright copyright";
  justify-items: start;

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    gap: 1rem;
    grid-template-columns: 12.25rem repeat(4, 1fr);
    grid-template-areas: "logo join-tmu-section explore-section about-section contact-section";
    max-width: ${(p) => p.theme.breakpoints.tablet};
    padding: 2.5rem 1.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    padding: 3rem 2rem;
    max-width: ${(p) => p.theme.breakpoints.desktop};
    grid-template-columns: 20.1875rem repeat(4, 1fr);
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding: 7.5rem 6rem;
    grid-template-columns: 36.25rem repeat(4, 1fr);

    max-width: ${(p) => p.theme.breakpoints.wide};
  }

  font-family: ${(p) => p.theme.fontFamilies.regular};
  color: ${(p) => p.theme.colors.pitchBlack};

  &.border-top {
    border-top: 0.0625rem solid rgba(0, 0, 0, 0.15);
    .footer {
      min-height: auto;
    }
  }

  h4 {
    margin-bottom: 1rem;
    text-transform: uppercase;
    font-weight: ${(p) => p.theme.fontFamilies.black};
    font-size: 0.75rem;
    line-height: 1.25rem;
    letter-spacing: 0.03em;
    color: ${(p) => p.theme.colors.disabled};
    font-family: ${(p) => p.theme.fontFamilies.headlineBig};
    text-align: left;
  }
  p,
  button {
    line-height: 2;
    margin: 0;
    color: ${(p) => p.theme.colors.secondaryBrandTextColor};
    display: block;

    &:hover {
      color: ${(p) => p.theme.colors.lightBlack};
    }
  }

  button {
    align-items: center;
    text-align: left;
    transition: all 0.3s ease 0s;
    box-sizing: border-box;
    cursor: pointer;
    border: none;
    padding: 0;
    font-size: 1rem;
    font-weight: ${(p) => p.theme.fontWeights.regular};
    color: rgba(104, 101, 100, 1);
    width: 100%;
    margin: 0;
    background: transparent;
    text-transform: none;
    letter-spacing: normal;
    white-space: pre-wrap;
    line-height: 1;
    display: flex;
    align-items: start;
    min-height: initial;
    margin-bottom: 1rem;
  }

  .border {
    grid-area: border;
    border: 0.0625rem solid rgba(0, 0, 0, 0.15);
    margin: 0;
  }

  .explore-section {
    grid-area: explore-section;
  }

  .about-section {
    grid-area: about-section;
    padding-top: 0;

    @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    }
  }

  .contact-section {
    grid-area: contact-section;
  }

  .join-tmu-section {
    grid-area: join-tmu-section;
    padding-top: 0;

    @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    }
  }

  .language-section {
    grid-area: language-section;
  }

  .language-section-mod {
    grid-area: lang-section;
    justify-self: center;
  }

  .terms-section {
    grid-area: terms-section;
    justify-self: center;
  }

  .policy-section {
    grid-area: policy-section;
    justify-self: center;
  }

  .copyright-section {
    grid-area: copyright;
  }

  .logo {
    margin-left: 0;
    width: 9.375rem;
    @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      height: 1.8125rem;
    }
    @media only screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      height: 2.5rem;
      width: 13rem;
    }
    @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      height: 3.125rem;
      width: 16rem;
    }
  }
`

export const StyledFooterWrapperMobile = styled.div`
  &.border-top {
    border-top: 0.0625rem solid rgba(0, 0, 0, 0.15);
    .footer {
      min-height: auto;
    }
  }
  width: 100%;

  button {
    width: 100%;
    margin: 0;
  }
  padding-bottom: ${(p) => (p.hasExtraMargin ? "8rem" : 0)};
`

export const StyledFooterLogo = styled.div`
  grid-area: logo;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  > button {
    margin-bottom: 0;
    justify-content: start;
    a {
      margin: 0;
    }
  }
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
  }
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
  }
`

export const StyledSocialLinks = styled.div`
  grid-area: social;
  display: flex;
  justify-content: start;
  align-items: flex-start;
  align-content: flex-start;
  button {
    justify-content: start;
    a {
      margin: 0 1rem 0 0;
    }
  }

  svg {
    color: ${(p) => p.color || p.theme.colors.primaryBrandTextColor};
    font-size: ${(p) => p.size || "1.25rem"};
    height: ${(p) => p.size || "1.25rem"};
    margin: 0;
  }

  @media only screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin: 0 auto;
    display: flex;

    .facebook-footer {
      max-width: 2.5rem;
    }
    .instagram-footer {
      max-width: 2.5rem;
    }

    .linkedin-footer {
      max-width: 2.5rem;
    }

    button {
      max-width: 4.6875rem;
      padding: 1.3125rem;
      padding-left: 0;
    }
  }
`

export const StyledFooterButtonList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: flex-start;
`

export const StyledFooterButtonListItem = styled.li`
  margin-right: 1.875rem;
  min-width: fit-content;

  @media only screen and (max-width: 62.5rem) {
    padding: 0;
    border-bottom: 0.0625rem solid ${(p) => p.theme.colors.tableItemBorder};
    width: 100%;
  }
`

export const StyledFooterButton = styled(Button)`
  color: ${(p) => p.theme.colors.pitchBlack};
  padding: 0;
  margin-top: 0.25rem !important;
  @media screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
    padding: 0 1.5rem;
  }

  a {
    font-size: 0.75rem;
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    line-height: 1.125rem;
    margin: 0;
    color: ${(p) => p.theme.colors.pitchBlack};
    &:hover {
      color: ${(p) => p.theme.colors.blue};
    }
    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1rem;
    }
  }
`

export const StyledAccordion = styled(Accordion)`
  ${(p) => p.theme.colors.tableItemBorder};
  font-size: 1.125rem;
`

export const StyledAccordionSubElement = styled.a`
  width: 100%;
  font-size: 1rem;
  border-bottom: 0.0625rem solid ${(p) => p.theme.colors.tableItemBorder};
  padding: 1rem 2.8rem;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
    padding: 0.05rem 0.5rem;
  }
`

export const StyledFooterCopyRight = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.625rem;
  align-self: start;
  padding: 0;
  span {
    width: 100%;
    margin-top: 0.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 0.75rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1rem;
    margin-bottom: -0.5rem;
  }
`

export const StyledLogoText = styled.svg`
  .cls-1 {
    fill: none;
  }
  .cls-10,
  .cls-11,
  .cls-2,
  .cls-9 {
    fill: ${(p) => p.theme.colors.tangaroa};
  }
  .cls-3 {
    fill: ${(p) => p.theme.colors.blue};
  }
  .cls-4 {
    clip-path: url(#clip-path);
  }
  .cls-5 {
    clip-path: url(#clip-path-2);
  }
  .cls-6 {
    clip-path: url(#clip-path-3);
  }
  .cls-7 {
    clip-path: url(#clip-path-4);
  }
  .cls-10,
  .cls-11,
  .cls-8,
  .cls-9 {
    isolation: isolate;
  }
  .cls-10,
  .cls-11,
  .cls-9 {
    font-size: 4.2713rem;
  }
  .cls-9 {
    letter-spacing: 0.05em;
  }
  .cls-10 {
    letter-spacing: 0.06em;
  }
  .cls-11 {
    letter-spacing: -0.01em;
  }
`
export const StyledLanguageBar = styled.div`
  display: flex;
  gap: 1.5rem;
  margin-top: 3.5rem;
  div {
    cursor: pointer;
  }

  .glow {
    img {
      border: 0.125rem solid ${(p) => p.theme.colors.pacificBlue};
      border-radius: 6.25rem;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-top: 0.7rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin-top: 3.5rem;
  }
`
export const StyledLanguageList = styled.div`
  overflow-y: auto;
  // max-height: calc(1vh * 65);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-align: left;
`

export const StyledLanguageItem = styled.div`
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin-top: 1rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
`
export const StyledFlagNameContainer = styled.div`
  display: flex;
  gap: 0.75rem;
`
export const StyledCheckIcon = styled.div`
  svg {
    color: ${(p) => p.theme.colors.pacificBlue};
    font-size: 1.5rem;
    height: 1.5rem;
  }
  height: 1.5rem;
`
export const StyledLine = styled.hr`
  margin: 1rem 0;
  width: auto;
  color: ${(p) => p.theme.colors.darkGrey};

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin: 1.5rem 0;
  }
`

export const StyledLanguageHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-size: 1.25rem;
  cursor: pointer;
`
export const StyledLanguageHeader = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-size: 1.5rem;
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
`
