import React, { useState, useEffect, forwardRef } from "react"
import Autosuggest from "react-autosuggest"
import { navigate } from "gatsby-plugin-intl"
import { faSearch } from "@fortawesome/pro-solid-svg-icons/faSearch"
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useIntl } from "gatsby-plugin-intl"
import { useLazyQuery } from "@apollo/client"
import { SEARCH_QUERY } from "@tmu/apollo/storefront/queries/search"
import Partners from "./Sections/Partners"
import Campaigns from "./Sections/Campaigns"
import Offers from "./Sections/Offers"
import Stores from "./Sections/Stores"
import { theme, StyledTypeahead, StyledTypeaheadInput } from "./index.styles"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { getValueForLocale } from "@tmu/utils/string"

const Typeahead = forwardRef(
  ({ isUnlisted = false, isAuthenticated, onHideClick = () => {} }, ref) => {
    const { locale, defaultLocale, formatMessage } = useIntl()
    const [isUnlistedSearch, setIsUnlistedSearch] = useState(isUnlisted)
    const [suggestions, setSuggestions] = useState([])
    const [value, setValue] = useState("")
    const [key, setKey] = useState("")
    const [slug, setSlug] = useState("")
    const [suggestionsVisible, setIsSuggestionsVisible] = useState(false)
    const [highlightedSuggestion, setHighlightedSuggestion] = useState({})
    const first = 2
    let searchTimeoutId = null
    const { isTablet, isWide } = getAllScreenTypes()

    const imageWidth = isWide ? 240 : 88
    const imageHeight = isWide ? 134 : 49

    const smallImageWidth = isWide ? 100 : 40
    const smallImageHeight = isWide ? 55 : 22

    if (!isWide) {
      theme.suggestionsContainer.maxHeight = "38rem"
      theme.suggestionsContainer.top = "-0.9375rem"
    } else {
      theme.suggestionsContainer.top = "0"
    }

    if (!isTablet) {
      theme.suggestionsContainer.maxHeight = window?.innerHeight - 48 + "px"
      theme.suggestionsContainer.top = "0.9375rem"
    }

    useEffect(() => {
      if (!isUnlistedSearch) setValue("")
    }, [isUnlistedSearch])

    useEffect(() => {
      if (isUnlistedSearch) document.body.style.position = "fixed"
      return () => {
        document.body.style.position = "relative"
      }
    }, [isUnlistedSearch])

    const [searchData, { loading, error, data }] = useLazyQuery(SEARCH_QUERY, {
      variables: {
        query: value,
        first,
        isPublic: true,
        badge: ["NO_BADGE"],
        offerOrigin: ["offers"],
      },
      skip: !value || value.length === 0,
    })

    useEffect(() => {
      clearTimeout(searchTimeoutId)
      searchTimeoutId = setTimeout(() => {
        if (value?.length > 0) {
          searchData()
        }
      }, 1000)
    }, [value])

    const renderInputComponent = (inputProps) => (
      <StyledTypeaheadInput authenticated={isAuthenticated}>
        <input
          id="typeheadInput"
          placeholder={formatMessage({
            id: "search::placeholder",
            defaultMessage: "Enter non-profit, campaign, offer, fiscale",
          })}
          {...inputProps}
        />
        <div className="search-icon">
          <FontAwesomeIcon icon={faSearch} />
        </div>
        <button
          aria-label="Search"
          data-testid="btn-close-typehead"
          className="times-icon"
          onClick={() => {
            setIsUnlistedSearch(false)
            onHideClick()
          }}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </StyledTypeaheadInput>
    )

    const renderSuggestion = (suggestion) => {
      switch (suggestion?.key) {
        case "offer":
          return (
            <Offers
              name={suggestion?.name}
              slug={suggestion?.slug}
              image={suggestion?.image?.url || suggestion?.image}
              imgWidth={imageWidth}
              imgHeight={imageHeight}
              discount={suggestion?.discount}
              store={suggestion?.store}
              isWide={isWide}
              smallImageHeight={smallImageHeight}
              smallImageWidth={smallImageWidth}
              onClick={() => onSuggestionsCloseAndClear()}
              {...suggestion}
            />
          )
        case "organization":
          return (
            <Partners
              name={suggestion?.name}
              slug={suggestion?.slug}
              logo={suggestion?.logo}
              taxId={suggestion?.taxId}
              imgWidth={imageWidth}
              imgHeight={imageHeight}
              onClick={() => onSuggestionsCloseAndClear()}
            />
          )
        case "campaign":
          return (
            <Campaigns
              name={suggestion?.name}
              goalAmount={suggestion?.goalAmount ?? 0}
              fund={Number(suggestion?.fund) || 0}
              img={suggestion?.image?.url || suggestion?.image}
              slug={suggestion?.slug}
              imgWidth={imageWidth}
              imgHeight={imageHeight}
              partner={suggestion?.partner}
              smallImageHeight={smallImageHeight}
              smallImageWidth={smallImageWidth}
              onClick={() => onSuggestionsCloseAndClear()}
            />
          )
        case "merchant":
          return (
            <Stores
              id={suggestion?.id}
              name={suggestion?.name}
              slug={suggestion?.slug}
              logo={suggestion?.logo}
              imgWidth={imageWidth}
              imgHeight={imageHeight}
              onClick={() => onSuggestionsCloseAndClear()}
            />
          )
        default:
          return (
            <span onClick={() => onSuggestionsCloseAndClear()}>
              {suggestion?.name}
            </span>
          )
      }
    }
    const renderSectionTitle = (section) => {
      return (
        section?.title && <div className="suggest-title">{section?.title}</div>
      )
    }

    const getSectionSuggestions = (section) => section?.features || ""
    const getSuggestionValue = (suggestion) => {
      setKey(suggestion?.key)
      setSlug(suggestion?.slug)
      return suggestion?.name || ""
    }

    const productsStr = formatMessage({
      id: "search::offers",
      defaultMessage: "Offers",
    })
    const organizationsStr = formatMessage({
      id: "search::organizations",
      defaultMessage: "Organizations",
    })
    const campaignsStr = formatMessage({
      id: "search::campaigns",
      defaultMessage: "Campaigns",
    })

    const pacStoresStr = formatMessage({
      id: "search::pacStores",
      defaultMessage: "PAC Stores",
    })

    useEffect(() => {
      setIsUnlistedSearch(isUnlisted)
    }, [isUnlisted])

    useEffect(() => {
      if (!loading && !error && data && value?.length > 0) {
        const suggestionData = [
          {
            title: data?.allPartners?.edges.length > 0 && organizationsStr,
            features: data.allPartners?.edges
              .map(({ node }) => node)
              .map((partner) => ({
                key: "organization",
                name: partner?.name,
                slug: partner?.slug,
                logo: partner?.logo,
                taxId: partner?.taxId,
              })),
          },
          {
            title: data?.allCampaigns?.edges.length > 0 && campaignsStr,
            features: data.allCampaigns?.edges
              .map(({ node }) => node)
              .map((campaign) => ({
                key: "campaign",
                name: getValueForLocale(
                  campaign,
                  "name",
                  locale,
                  defaultLocale
                ),
                goalAmount: campaign.goalAmount,
                fund: campaign.fundedAmount,
                image: campaign?.image?.url || campaign?.image,
                slug: campaign.slug,
                partner: campaign?.partner,
              })),
          },
          {
            title: data?.offers?.edges.length > 0 && pacStoresStr,
            features: data?.offers?.edges
              .map(({ node }) => node)
              .map(({ store }) => ({
                key: "merchant",
                id: store?.id,
                name: store?.name,
                slug: store?.slug,
                logo: store?.logo,
              })),
          },
          {
            title: data?.allOffers?.edges.length > 0 && productsStr,
            features: data.allOffers?.edges
              .map(({ node }) => node)
              .map((offer) => ({
                key: "offer",
                name: offer.name,
                slug: offer.slug,
                image: offer?.image?.url || offer?.image,
                discount: offer?.maxPacDiscount,
                store: offer?.store,
                originalData: offer,
              })),
          },
        ]

        const filteredSuggestionData = suggestionData?.filter(
          (item) => item?.title
        )
        setSuggestions(filteredSuggestionData)
      }
    }, [
      loading,
      error,
      data,
      organizationsStr,
      campaignsStr,
      productsStr,
      pacStoresStr,
    ])

    const onChange = (event, param) => {
      const { newValue, method } = param
      if (method.toLowerCase() === "down" || method.toLowerCase() === "up") {
        return
      }
      setValue(newValue)
      if (!newValue || newValue?.length === 0) {
        setValue("")
        setSuggestions([])
      }
    }
    const onKeyDown = (event) => {
      if (event.key === "Enter") {
        onSuggestionsCloseAndClear()
        switch (key) {
          case "offer":
            return navigate(
              `/offers/?offer=true&stores=${highlightedSuggestion?.store?.id}`
            )

          case "organization":
            return navigate(`/charities/${slug}`)

          case "campaign":
            return navigate(`/campaigns/${slug}`)

          case "merchant":
            return navigate(`/offers/store/${slug.toLowerCase()}?offer=true`)
        }
      }
    }

    const onSuggestionsFetchRequested = ({ value }) => {}

    const onSuggestionsCloseAndClear = () => {
      setValue("")
      setSuggestions([])
      setIsUnlistedSearch(false)
      onHideClick()
    }

    useEffect(() => {
      setIsSuggestionsVisible(suggestions?.length > 0)
    }, [suggestions])

    const inputProps = {
      value,
      onChange,
      onKeyDown,
    }

    function renderSuggestionsContainer({ containerProps, children }) {
      return suggestionsVisible && <div {...containerProps}>{children}</div>
    }

    const onSuggestionHighlighted = ({ suggestion }) => {
      setHighlightedSuggestion(suggestion)
    }

    if (isTablet) {
      theme.suggestionsContainer["max-height"] = "80vh"
    }

    return (
      <StyledTypeahead
        className={`${!isUnlistedSearch ? "archived" : ""}`}
        data-testid="typeahead-wrapper"
        ref={ref}>
        <Autosuggest
          multiSection
          theme={theme}
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          renderSuggestion={renderSuggestion}
          renderSectionTitle={renderSectionTitle}
          getSectionSuggestions={getSectionSuggestions}
          getSuggestionValue={getSuggestionValue}
          inputProps={inputProps}
          renderInputComponent={renderInputComponent}
          focusInputOnSuggestionClick={isTablet}
          renderSuggestionsContainer={renderSuggestionsContainer}
          alwaysRenderSuggestions={true}
          onSuggestionHighlighted={onSuggestionHighlighted}
        />
      </StyledTypeahead>
    )
  }
)

Typeahead.defaultProps = {
  isUnlisted: false,
}

export default Typeahead
