import styled from "@emotion/styled"
import {
  StyledCard as CommonStyledCard,
  StyledImageBox as CommonStyledImageBox,
  StyledCardInfo as CommonStyledCardInfo,
  StyledCardTitle as CommonStyledCardTitle,
  StyledCardSubtitle as CommonStyledCardSubtitle,
  StyledPACMultiplier as CommonStyledPACMultiplier,
} from "../index.styles"

export const StyledCard = styled(CommonStyledCard)``

export const StyledImageBox = styled(CommonStyledImageBox)`
  .media-box img {
    width: auto;
    max-width: 100%;
    max-height: 100%;
  }
`

export const StyledDoublePacsTooltip = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 0;

  .charityCard {
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.15);
    opacity: 1 !important;
  }
  .charityCardListing {
    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      width: 13.75rem;
    }
    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      width: unset;
    }
  }
`

export const StyledCardInfo = styled(CommonStyledCardInfo)``

export const StyledCardTitle = styled(CommonStyledCardTitle)``

export const StyledCardSubtitle = styled(CommonStyledCardSubtitle)`
  .charity-subtitle {
    color: ${(p) => p.theme.colors.blue};
  }
`

export const StyledPACMultiplier = styled(CommonStyledPACMultiplier)``
