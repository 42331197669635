import React from "react"
import { Router } from "@reach/router"
import { injectIntl } from "gatsby-plugin-intl"
import CorporateCompaniesListing from "@tmu/components/corporate/CorporateCompaniesListing"

const Corporate = () => (
  <Router basepath="/:locale/corporate">
    <CorporateCompaniesListing path="/" />
  </Router>
)

export default injectIntl(Corporate)
