import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { useMutation } from "@apollo/client"
import { navigate } from "gatsby-plugin-intl"
import { useApolloApiClients } from "@tmu/apollo/client"
import {
  StyledPage,
  StyledPageContent,
  StyledPageTitle,
  StyledProfileTitle,
} from "@tmu/global/page-addons/dashboard.styles"
import {
  StyledChooseNewCardText,
  StyledCreditCardContainer,
  StyledEditLink,
  StyledHr,
  StyledMobileBackground,
  StyledMobileDeleteConfirmButtonsContainer,
  StyledMobileDeleteConfirmContainer,
  StyledMobileDeleteConfirmDescription,
  StyledMobileDeleteConfirmTitle,
  StyledMobileHr,
  StyledMobileMenu,
  StyledMobileMenuItem,
  StyledPageGrid,
  StyledProfileImage,
  StyledRestPassword,
  StyledRowContent,
  StyledRowTitle,
  StyledSection,
  StyledSectionTitle,
  StyledSimpleTextContent,
  StyledStatItem,
  StyledStatItemNumber,
  StyledStats,
  StyledUsedCardTitle,
  StyledUserDetail,
  StyledUserName,
} from "./index.styles"
import { Accordion } from "@tmu/components/common"
import { useAuth, useCampaigns, useCreditCards, useToast } from "@tmu/hooks"
import theme from "@tmu/global/theme"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { Spacer } from "@tmu/global/page-addons/detail-page.styles"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import { ImageSelector } from "@tmu/components/dashboard/dashboardCommon"
import ReceiptDetailsForm from "./receiptDetailsForm"
import NameChangeForm from "./nameChangeForm"
import {
  DELETE_ACCOUNT_MUTATION,
  SEND_PASSWORD_RESET_EMAIL,
  UPDATE_ME_MUTATION,
  CANCEL_SUBSCRIPTION_MUTATION,
} from "@tmu/apollo/storefront/mutations/user"
import {
  Button,
  CreditCard,
  CreditCardSelector,
  CreditCardSelectorWithStripe,
  CustomModal,
  Spinner,
  Money,
} from "@tmu/components/common"
import { HomePageContext } from "@tmu/context/homePageContext"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripeInstance } from "@tmu/utils/stripe"
import { getValueForLocale } from "@tmu/utils/string"
import { getFormatPhoneNumber } from "@tmu/utils/formatPhoneNumber"
import { DEFAULT_CURRENCY_SYMBOL } from "@tmu/apollo/constants"
import queryString from "query-string"
import { isBrowser } from "@tmu/src/utils/auth"

const Profile = () => {
  const searchStr = isBrowser ? window?.location?.search : ""

  const params = queryString.parse(searchStr, { arrayFormat: "comma" })
  const { success, error } = useToast()
  const stripeParams = params?.setup_intent_client_secret ? { ...params } : null

  const { user, callUserProfile, signOut } = useAuth()
  const { formatMessage, locale, defaultLocale } = useIntl()
  const [isReceiptEdit, setIsReceiptEdit] = useState(false)
  const [profileImage, setProfileImage] = useState()
  const { storefrontClient } = useApolloApiClients()
  const imageSelectorRef = useRef()
  const imageSelectorContainerRef = useRef()
  const userDetailRef = useRef()
  const backgroundRef = useRef()
  const [isImageEdit, setIsImageEdit] = useState(false)
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState({})
  const [editModalStatus, setEditModalStatus] = useState(false)
  const [selectedCard, setSelectedCard] = useState(null)
  const [cardSelectModalStatus, setCardSelectModalStatus] =
    useState(stripeParams)
  const { headerConfigData, setHeaderConfigData } = useContext(HomePageContext)
  const [recurringCard, setRecurringCard] = useState(null)
  const { getAllCampaigns, allCampaigns } = useCampaigns()
  const [recurringSubscription, setRecurringSubscription] = useState(null)
  const [isRecurringDonationModalOpen, setIsRecurringDonationModalOpen] =
    useState(false)
  const [subscription, setSubscription] = useState({})
  let resizeTimeout = null
  const {
    allCards: savedCards,
    callAllSavedAccounts: getSavedCards,
    loading: savedCardsLoading,
    subscriptionData: allSubscriptionsData,
    callSubscription,
    subscriptionsLoading,
  } = useCreditCards({
    callImmediate: false,
  })

  useEffect(() => {
    if (!user?.id) {
      return
    }
    getSavedCards()
  }, [])

  const { isTablet, isWide } = getAllScreenTypes()
  if (!isTablet) {
    window.addEventListener("resize", () => {
      setBackgroundHeight()
      clearTimeout(resizeTimeout)
      resizeTimeout = setTimeout(() => {
        setBackgroundHeight()
      }, 500)
    })
  }

  useEffect(() => {
    setBackgroundHeight()
    clearTimeout(resizeTimeout)
    resizeTimeout = setTimeout(() => {
      setBackgroundHeight()
    }, 500)
  }, [
    imageSelectorContainerRef.current,
    backgroundRef.current,
    userDetailRef.current,
    user,
  ])

  const setBackgroundHeight = () => {
    if (backgroundRef?.current) {
      const heightAddition = 100

      backgroundRef.current.style.height =
        imageSelectorContainerRef.current?.clientHeight +
        userDetailRef.current?.clientHeight +
        heightAddition +
        "px"
    }
  }

  const [updateProfile] = useMutation(UPDATE_ME_MUTATION, {
    client: storefrontClient,
  })

  const [callSendEmail, { data, errors }] = useMutation(
    SEND_PASSWORD_RESET_EMAIL,
    {
      client: storefrontClient,
    }
  )

  const [cancelSubscription] = useMutation(CANCEL_SUBSCRIPTION_MUTATION, {
    client: storefrontClient,
  })

  useEffect(() => {
    getAllCampaigns()
    callSubscription()
  }, [])

  const [deleteAccountMutation] = useMutation(DELETE_ACCOUNT_MUTATION, {
    client: storefrontClient,
  })

  const stripePublicKey = allCampaigns?.at(0)?.node?.stripePublicKey

  const stripeInstance = loadStripeInstance(stripePublicKey, locale)

  useEffect(() => {
    setHeaderConfigData({ ...headerConfigData, transparent: !isTablet })
  }, [isTablet])

  useEffect(() => {
    if (data?.sendPasswordResetEmail?.success) {
      const sentEmailSuccessfulText = formatMessage({
        id: "userProfileForm::changePasswordEmailSuccesfully",
        defaultMessage:
          "An email has been sent to your registered email account to change password",
      })

      success(sentEmailSuccessfulText)
    }

    if (errors) {
      error(errors.message)
    }
  }, [data, errors])

  const imageSize = isWide ? 162 : 106

  const sectionData = [
    {
      title:
        user?.accountType === "BUSINESS"
          ? formatMessage({
              id: "dashboard::profile::organizationName",
              defaultMessage: "Organization Name",
            })
          : formatMessage({
              id: "dashboard::profile::name",
              defaultMessage: "name",
            }),
      text: (
        <span>
          <p>{[user?.fullName].filter((item) => item).join(", ")}</p>
        </span>
      ),
    },
    {
      title: formatMessage({
        id: "dashboard::profile::address",
        defaultMessage: "address",
      }),
      text: (
        <span>
          <p>{user?.addressLine1}</p>
        </span>
      ),
    },
    {
      title: formatMessage({
        id: "dashboard::profile::taxId",
        defaultMessage: "tax id/vat",
      }),
      text: user?.taxId,
    },
    {
      title: formatMessage({
        id: "dashboard::profile::phone",
        defaultMessage: "phone number",
      }),
      text: getFormatPhoneNumber(user?.phoneNumber),
    },
    {
      title: formatMessage({
        id: "dashboard::profile::email",
        defaultMessage: "email",
      }),
      text: user?.email,
    },
  ]

  const statsData = [
    /*
    {
      amount: "1",
      label: formatMessage({
        id: "dashboard::profile::createdCampaigns",
        defaultMessage: "campaigns",
      }),
    },
    {
      amount: "20",
      label: formatMessage({
        id: "dashboard::profile::fundraised",
        defaultMessage: "fundraised",
      }),
    },
    {
      amount: "120",
      label: formatMessage({
        id: "dashboard::profile::donated",
        defaultMessage: "donated",
      }),
    },
    */
  ]

  const receiptDetailsContent = (
    <>
      {sectionData?.map((item, index) => {
        return (
          <StyledSection key={index}>
            <StyledSectionTitle>{item?.title}</StyledSectionTitle>
            <Spacer bottom={0.5} />
            <StyledSimpleTextContent>{item?.text}</StyledSimpleTextContent>
            <Spacer bottom={1} />
          </StyledSection>
        )
      })}
    </>
  )

  const getIntervalDescription = useCallback((interval) => {
    let intervalDescription = ""

    if (interval === "MONTH") {
      intervalDescription = formatMessage({
        id: "ui::monthly",
        defaultMessage: "Monthly",
      })
    } else if (interval === "YEAR") {
      intervalDescription = formatMessage({
        id: "ui::annually",
        defaultMessage: "Annually",
      })
    }

    return intervalDescription
  }, [])

  const handleOpenRecurringDonationModal = (subscription) => {
    setSubscription({
      allSubscriptions: {
        edges: [subscription],
      },
    })

    setIsRecurringDonationModalOpen(true)
  }

  const handleCloseRecurringDonationModal = () => {
    setIsRecurringDonationModalOpen(false)
  }

  const handleCancelSubscription = () => {
    const promiseArr = []

    subscription?.allSubscriptions?.edges?.forEach((item) => {
      const promise = cancelSubscription({
        variables: {
          input: {
            subscription: item?.node?.id,
          },
        },
      })

      promiseArr.push(promise)
    })

    Promise.all(promiseArr)
      .then((result) => {
        const err1 = result?.flatMap(
          (item) => item?.data?.updateSubscription?.errors
        )
        const err2 = result?.flatMap(
          (item) => item?.data?.cancelSubscription?.errors
        )
        const err3 = result?.flatMap((item) => item?.errors)

        const errorList = [...err1, ...err2, ...err3].filter((err) => err)

        if (errorList?.length === 0) {
          handleCloseRecurringDonationModal()
          window?.location?.reload()
        } else {
          error(
            // TODO : localisation will be added
            // errorList[0]?.messages?.[0] ||
            //    errorList[0]?.message ||
            formatMessage({
              id: "dashboard::userForm::errorMessage",
              defaultMessage: "An error occurred",
            })
          )
        }
      })
      .catch((err) => {
        error(
          formatMessage({
            id: "dashboard::userForm::errorMessage",
            defaultMessage: "An error occurred",
          })
        )
      })
      .finally(() => handleCloseRecurringDonationModal())
  }

  function recurringSingleItem(
    data,
    hasCancellationLink,
    subscription,
    isSingle
  ) {
    return (
      <StyledSection
        label={
          subscription?.node?.campaign?.isDefaultCampaign
            ? getValueForLocale(
                subscription?.node?.campaign,
                "name",
                locale,
                defaultLocale
              )
            : subscription?.node?.campaign?.partner?.name
        }>
        {isSingle ? (
          <>
            <StyledSectionTitle>
              {formatMessage({
                id: "dashboard::settings::charity",
                defaultMessage: "charity",
              })}
            </StyledSectionTitle>
            <StyledSimpleTextContent>
              {subscription?.node?.campaign?.isDefaultCampaign
                ? getValueForLocale(
                    subscription?.node?.campaign,
                    "name",
                    locale,
                    defaultLocale
                  )
                : subscription?.node?.campaign?.partner?.name}
            </StyledSimpleTextContent>
          </>
        ) : null}
        <Spacer bottom={isWide ? 2.5 : 1} />
        <StyledSectionTitle>
          {formatMessage({
            id: "donationForm::amount",
            defaultMessage: "amount",
          })}
        </StyledSectionTitle>
        <StyledSimpleTextContent>
          <Money value={subscription?.node?.plan?.amount || ""} />
        </StyledSimpleTextContent>
        <Spacer bottom={isWide ? 2.5 : 1} />
        <StyledSectionTitle>
          {formatMessage({
            id: "dashboard::profile::recurring",
            defaultMessage: "recurring",
          })}
        </StyledSectionTitle>
        <StyledSimpleTextContent>
          {getIntervalDescription(subscription?.node?.plan?.interval)}
        </StyledSimpleTextContent>
        {hasCancellationLink && (
          <>
            <Spacer bottom={isWide ? 2.5 : 1} />
            <Button
              variant="link"
              className="recurring-donation-link"
              text={
                <FormattedMessage
                  id="dashboard::profile::recurringDonationLink2"
                  defaultMessage="Cancel this recurring donation"
                />
              }
              onClick={() => handleOpenRecurringDonationModal(subscription)}
            />
          </>
        )}
        <Spacer bottom={isWide ? 2.5 : 1} />
      </StyledSection>
    )
  }

  const recurringDonationContent = (
    data,
    hasCancellationLink = false,
    isSingle
  ) => {
    return !data?.allSubscriptions?.edges?.length ? (
      <p>
        {formatMessage({
          id: "dashboard::profile::noRecurring",
          defaultMessage: "You don't have any recurring donations.",
        })}
      </p>
    ) : isSingle ? (
      recurringSingleItem(
        data,
        hasCancellationLink,
        data?.allSubscriptions?.edges?.[0],
        isSingle
      )
    ) : (
      <>
        {data?.allSubscriptions?.edges?.map((subscription, index) => {
          return (
            <Accordion key={index}>
              {recurringSingleItem(data, hasCancellationLink, subscription)}
            </Accordion>
          )
        })}
      </>
    )
  }

  const saveProfileImage = () => {
    imageSelectorRef.current.closeEdit()
    setIsImageEdit(false)
    saveProfileData({ profileImage })
  }

  const saveReceiptData = (data) => {
    saveProfileData({
      firstName: data?.firstName,
      lastName: data?.lastName,
      phoneNumber: data?.phoneNumber,
      country: data?.country,
      city: data?.city,
      region: data?.region,
      postCode: data?.postCode,
      taxId: data?.taxId,
      addressLine1: data?.addressLine1,
    })
  }

  const saveNameData = (data) => {
    saveProfileData({
      firstName: data?.firstName,
      lastName: data?.lastName,
    })
  }

  const saveProfileData = (input, isDefaultCardUpdate) => {
    updateProfile({
      variables: {
        input,
      },
    })
      .then(({ data }) => {
        if (data.updateMe.errors.length) {
          data.updateMe.errors.map(({ messages }) =>
            error(
              formatMessage({
                id: "dashboard::userForm::errorMessage",
                defaultMessage: messages[0] || "An error occurred",
              })
            )
          )
          return
        }

        const successMessage = isDefaultCardUpdate
          ? formatMessage({
              id: "dashboard::userForm::updateDefaultCard",
              defaultMessage: "Default card is changed",
            })
          : formatMessage({
              id: "dashboard::userForm::updateMessage",
              defaultMessage: "Your profile is updated!",
            })

        success(successMessage)
        callUserProfile()
      })
      .catch((err) => {
        error(
          formatMessage({
            id: "dashboard::userForm::errorMessage",
            defaultMessage: err?.message || "An error occurred",
          })
        )
      })
  }

  const handleConfirmDelete = () => {
    setDeleteModalOpen(false)
    deleteAccountMutation().then((res) => {
      if (res?.data?.forgetMe?.success) {
        success(
          formatMessage({
            id: "dashboard::accountForm::accountDeleted",
            defaultMessage: "Account removal request received.",
          })
        )
        navigate("/")
        signOut()
      } else {
        error(
          formatMessage({
            id: "dashboard::accountForm::errorMessage",
            defaultMessage: "An error occurred",
          })
        )
      }
    })
  }

  const handleDeleteMyAccount = () => {
    setDeleteModalOpen(true)
  }

  const handleCancelDelete = () => {
    setDeleteModalOpen(false)
  }

  const closeEditModal = () => {
    setEditModalStatus(false)
  }

  const openEditModal = (content) => {
    setIsReceiptEdit(false)
    setModalContent(content)
    setEditModalStatus(true)
  }

  const receiptDetailsText = formatMessage({
    id: "dashboard::profile::recieptDetails",
    defaultMessage: "Receipt Details",
  })

  const paymentsText = formatMessage({
    id: "dashboard::profile::payments",
    defaultMessage: "Payments Methods",
  })

  const recurringDonationText = formatMessage({
    id: "dashboard::profile::recurringDonation",
    defaultMessage: "Recurring donation",
  })

  const accountsText = formatMessage({
    id: "dashboard::profile::accounts",
    defaultMessage: "Account",
  })

  const editText = formatMessage({
    id: "dashboard::profile::edit",
    defaultMessage: "Edit",
  })

  const changeNameText = formatMessage({
    id: "dashboard::profile::changeName",
    defaultMessage: "Change Name",
  })
  const resetPasswordText = formatMessage({
    id: "dashboard::profile::resetPassword",
    defaultMessage: "Reset Password",
  })

  const deleteAccountText = formatMessage({
    id: "dashboard::profile::deleteAccount",
    defaultMessage: "Delete account",
  })

  const goBackText = formatMessage({
    id: "dashboard::common::goBack",
    defaultMessage: "Go back",
  })

  const deleteMyAccountText = formatMessage({
    id: "dashboard::accountForm::deleteMyAccount",
    defaultMessage: "Delete My Account",
  })

  const deleteAccountTitle = formatMessage({
    id: "dashboard::accountForm::deleteAccountTitle",
    defaultMessage: "Delete your account?",
  })

  const deleteAccountDescription = formatMessage({
    id: "dashboard::accountForm::deleteAccountDescription",
    defaultMessage:
      "Your account as well as all data about your donations, orders, payment information, created campaigns will be lost.",
  })

  const receiptModalContent = {
    header: receiptDetailsText,
    headerStyle: { paddingTop: "unset" },
    children: (
      <StyledRowContent>
        <StyledEditLink
          className="mobile"
          onClick={() => openEditModal(receiptFormModalContent)}>
          {editText}
        </StyledEditLink>
        {receiptDetailsContent}
      </StyledRowContent>
    ),
    style: { padding: 16 },
  }

  const receiptFormModalContent = {
    header: receiptDetailsText,
    children: (
      <StyledRowContent className={!isTablet && "mobile-scroll"}>
        <ReceiptDetailsForm
          data={user}
          saveReceiptData={saveReceiptData}
          isMobile={true}
          onCancel={() => {
            setIsReceiptEdit(false)
          }}
        />
      </StyledRowContent>
    ),
    onClose: () => openEditModal(receiptModalContent),
  }

  const changeNameFormModalContent = {
    header: changeNameText,
    children: (
      <StyledRowContent className={!isTablet && "mobile-scroll"}>
        <NameChangeForm
          data={user}
          saveReceiptData={saveNameData}
          isMobile={true}
          onCancel={() => {
            openEditModal(accountsModalContent)
          }}
        />
      </StyledRowContent>
    ),
    onClose: () => openEditModal(accountsModalContent),
  }

  const openSelectCardModal = () => {
    setCardSelectModalStatus(true)
  }

  const closeSelectCardModal = () => {
    setCardSelectModalStatus(false)
  }

  const creditCardSelector = savedCards?.length ? (
    <CreditCardSelector
      withDeleteButton={true}
      withSetDefault={true}
      cards={savedCards}
      onChange={(card) => {
        setSelectedCard(card)
      }}
      onDeleteError={(card, subscriptionArr) => {
        openSelectCardModal()
        setRecurringCard(card)
        setRecurringSubscription(subscriptionArr)
      }}
      onClick={(card) => {
        if (card?.cardId !== selectedCard?.cardId) {
          saveProfileData({ stripeDefaultPaymentMethodId: card?.cardId }, true)
        }
      }}
    />
  ) : (
    !savedCardsLoading && (
      <FormattedMessage
        id="dashboard::accountForm::noPaymentsText"
        defaultMessage="You don't have saved cards yet."
        tagName="p"
      />
    )
  )

  const paymentsModalContent = {
    header: paymentsText,
    headerStyle: { paddingTop: "unset" },
    children: <StyledRowContent>{creditCardSelector}</StyledRowContent>,
    style: { padding: 16 },
  }

  const accountsModalContent = {
    header: accountsText,
    headerStyle: { paddingTop: "unset" },
    children: (
      <StyledMobileMenu>
        <StyledMobileHr className="first-mobile-hr" />
        <StyledMobileMenuItem
          onClick={() => openEditModal(changeNameFormModalContent)}>
          {changeNameText}
        </StyledMobileMenuItem>
        <StyledMobileHr />
        <StyledMobileMenuItem
          onClick={() => {
            callSendEmail({ variables: { email: user?.email } })
            closeEditModal()
          }}>
          {resetPasswordText}
        </StyledMobileMenuItem>
        <StyledMobileHr />
        <StyledMobileMenuItem
          onClick={() => openEditModal(deleteAccountModalContent)}>
          {deleteAccountText}
        </StyledMobileMenuItem>
        <StyledMobileHr />
      </StyledMobileMenu>
    ),
    style: { padding: 16 },
  }

  const recurringModalContent = {
    header: recurringDonationText,
    headerStyle: { paddingTop: "unset" },
    style: { padding: 16, textAlign: "unset" },
    children: recurringDonationContent(allSubscriptionsData, true),
  }

  const mobileMenu = (
    <StyledMobileMenu>
      <StyledMobileMenuItem onClick={() => openEditModal(receiptModalContent)}>
        {receiptDetailsText}
      </StyledMobileMenuItem>
      <StyledMobileHr />
      <StyledMobileMenuItem onClick={() => openEditModal(accountsModalContent)}>
        {accountsText}
      </StyledMobileMenuItem>
      <StyledMobileHr />
      <StyledMobileMenuItem onClick={() => openEditModal(paymentsModalContent)}>
        {paymentsText}
      </StyledMobileMenuItem>
      <StyledMobileHr />
      <StyledMobileMenuItem
        onClick={() => openEditModal(recurringModalContent)}>
        {recurringDonationText}
      </StyledMobileMenuItem>
    </StyledMobileMenu>
  )

  const deleteAccountContent = (
    <StyledMobileDeleteConfirmContainer>
      <StyledMobileDeleteConfirmTitle>
        {deleteAccountTitle}
      </StyledMobileDeleteConfirmTitle>
      <Spacer top={1} />
      <StyledMobileDeleteConfirmDescription>
        {deleteAccountDescription}
      </StyledMobileDeleteConfirmDescription>
      <StyledMobileDeleteConfirmButtonsContainer>
        <Button
          className="blue-button"
          color={theme.colors.pacificBlue}
          label={deleteMyAccountText}
          onClick={() => {
            handleConfirmDelete()
            closeEditModal()
          }}
          data-testid="btn-confirm">
          {deleteMyAccountText}
        </Button>
        <Spacer top={1} />
        <Button
          color="transparent"
          variant="outlined"
          label={goBackText}
          onClick={() => {
            handleCancelDelete()
            closeEditModal()
          }}
          data-testid="btn-cancel">
          {goBackText}
        </Button>
      </StyledMobileDeleteConfirmButtonsContainer>
    </StyledMobileDeleteConfirmContainer>
  )

  const deleteAccountModalContent = {
    children: deleteAccountContent,
    isCloseXButton: true,
    isCloseIcon: false,
    onClose: () => openEditModal(accountsModalContent),
  }

  const cardSelectContent = (
    <>
      {recurringCard && (
        <StyledCreditCardContainer>
          <CreditCard
            cardType={recurringCard?.brand}
            lastFour={recurringCard?.last4}
            email={recurringCard?.paypalEmail}
          />
        </StyledCreditCardContainer>
      )}
      {savedCards?.length && (
        <>
          <StyledChooseNewCardText>
            <FormattedMessage
              id="dashboard::profile::choseAnotherCardForRecurring"
              defaultMessage="Choose another card to use for a recurring donation"
            />
          </StyledChooseNewCardText>
          <Spacer bottom={1} />

          {((stripePublicKey &&
            stripeInstance &&
            recurringCard &&
            recurringSubscription) ||
            stripeParams) && (
            <Elements stripe={stripeInstance} options={{ locale }}>
              <CreditCardSelectorWithStripe
                cardToExclude={recurringCard}
                cards={savedCards}
                withNewCard={true}
                cardToDeleteParam={recurringCard}
                subscriptionsParam={recurringSubscription}
                onDeleteComplete={() => {
                  closeSelectCardModal()
                  getSavedCards()
                  callSubscription()
                }}
                stripeParams={stripeParams}
              />
            </Elements>
          )}
        </>
      )}
    </>
  )

  return (
    <StyledPage>
      <StyledPageTitle>
        <StyledProfileTitle></StyledProfileTitle>
      </StyledPageTitle>
      {savedCardsLoading || subscriptionsLoading ? (
        <Spinner condensed />
      ) : (
        <StyledPageContent>
          {!isTablet && (
            <StyledMobileBackground
              ref={backgroundRef}
              data-testid="background"
              className={user?.profileImage?.length ? "" : "without-image"}
            />
          )}
          <StyledPageGrid>
            <StyledProfileImage
              horizontal
              className="logo-wrapper"
              ref={imageSelectorContainerRef}>
              <ImageSelector
                ref={imageSelectorRef}
                square
                defaultValue={user?.profileImage}
                imgWidth={imageSize}
                imgHeight={imageSize}
                onChange={(image) => {
                  setIsImageEdit(true)
                  setProfileImage(image)
                }}
                onCropChange={(croppedImage) => {
                  setProfileImage(croppedImage)
                }}
                onReset={() => {
                  setProfileImage(user?.profileImage)
                  setIsImageEdit(false)
                }}
              />
            </StyledProfileImage>
            <StyledUserDetail ref={userDetailRef}>
              <StyledUserName>{user?.fullName}</StyledUserName>
              <StyledStats>
                {statsData?.map((item, index) => {
                  return (
                    <StyledStatItem key={index}>
                      <StyledStatItemNumber>
                        {item?.amount > 0 ? DEFAULT_CURRENCY_SYMBOL : ""}
                        {item?.amount}
                      </StyledStatItemNumber>
                      <StyledSectionTitle>{item?.label}</StyledSectionTitle>
                    </StyledStatItem>
                  )
                })}
              </StyledStats>
            </StyledUserDetail>
          </StyledPageGrid>
          {isImageEdit && (
            <StyledEditLink
              onClick={() => {
                saveProfileImage()
              }}>
              <FormattedMessage
                id="dashboard::profile::save"
                defaultMessage="Save"
              />
            </StyledEditLink>
          )}
          {isTablet ? (
            <>
              <StyledHr />
              <StyledPageGrid>
                <StyledRowTitle>
                  {receiptDetailsText}
                  <Spacer bottom={0.5} />
                  {!isReceiptEdit && (
                    <StyledEditLink onClick={() => setIsReceiptEdit(true)}>
                      {editText}
                    </StyledEditLink>
                  )}
                </StyledRowTitle>
                <StyledRowContent>
                  {isReceiptEdit ? (
                    <ReceiptDetailsForm
                      data={user}
                      saveReceiptData={saveReceiptData}
                      onCancel={() => {
                        setIsReceiptEdit(false)
                      }}
                    />
                  ) : (
                    receiptDetailsContent
                  )}
                </StyledRowContent>
              </StyledPageGrid>
              <Spacer bottom={1} />
              <StyledPageGrid>
                <StyledRowTitle>
                  {paymentsText}
                  <Spacer bottom={0.5} />
                </StyledRowTitle>
                <StyledRowContent>{creditCardSelector}</StyledRowContent>
              </StyledPageGrid>
              <StyledPageGrid>
                <StyledRowTitle>
                  {recurringDonationText}
                  <Spacer bottom={0.5} />
                </StyledRowTitle>
                <StyledRowContent>
                  {recurringDonationContent(allSubscriptionsData, true)}
                </StyledRowContent>
              </StyledPageGrid>
              <Spacer bottom={isWide ? 3 : 2} />
              <StyledPageGrid>
                <StyledRowTitle>
                  {accountsText}
                  <Spacer bottom={0.5} />
                  <StyledEditLink onClick={handleDeleteMyAccount} color="red">
                    {deleteAccountText}
                  </StyledEditLink>
                </StyledRowTitle>
                <StyledRowContent>
                  <StyledRestPassword
                    onClick={() =>
                      callSendEmail({ variables: { email: user?.email } })
                    }>
                    {resetPasswordText}
                  </StyledRestPassword>
                </StyledRowContent>
              </StyledPageGrid>
            </>
          ) : (
            <>
              <Spacer top={2} />
              {mobileMenu}
            </>
          )}
        </StyledPageContent>
      )}

      {isDeleteModalOpen ? (
        <CustomModal
          isModalOpen={isDeleteModalOpen}
          style={isTablet && { textAlign: "unset" }}
          children={
            <>
              <StyledSimpleTextContent className="delete-account-text">
                {deleteAccountDescription}
              </StyledSimpleTextContent>
              <Spacer bottom={isWide ? 3.5 : 1.5} />
            </>
          }
          header={
            <StyledUsedCardTitle>{deleteAccountTitle}</StyledUsedCardTitle>
          }
          isCloseIcon={true}
          cancelButtonText={goBackText}
          cancelButtonAction={() => {
            handleCancelDelete()
            closeEditModal()
          }}
          confirmButtonText={deleteMyAccountText}
          confirmButtonAction={() => {
            handleConfirmDelete()
            closeEditModal()
          }}
          modalActionsStyle={{ display: "flex" }}
          isMobile={!isTablet}
          isFull={false}
        />
      ) : null}

      {editModalStatus && !isTablet ? (
        <CustomModal
          isModalOpen={editModalStatus && !isTablet}
          style={modalContent.style}
          header={modalContent?.header}
          headerStyle={modalContent?.headerStyle}
          text={modalContent?.text}
          children={modalContent?.children}
          isCloseIcon={modalContent?.isCloseIcon || true}
          isCloseXButton={modalContent?.isCloseXButton}
          cancelButtonAction={() => {
            closeEditModal()
            if (typeof modalContent?.onClose === "function") {
              modalContent.onClose()
            }
          }}
          isMobile={true}
          isFull={true}
        />
      ) : null}

      {savedCards?.length && cardSelectModalStatus ? (
        <CustomModal
          isModalOpen={cardSelectModalStatus}
          children={cardSelectContent}
          style={{
            paddingLeft: "2rem",
            paddingRight: "2rem",
            paddingBottom: "0",
          }}
          header={
            <StyledUsedCardTitle>
              <FormattedMessage
                id="dashboard::profile::usedCardTitle"
                defaultMessage="This card is used for a recurring donation"
              />
            </StyledUsedCardTitle>
          }
          isCloseIcon={true}
          cancelButtonAction={() => {
            closeSelectCardModal()
          }}
          isMobile={!isTablet}
        />
      ) : null}

      {isRecurringDonationModalOpen ? (
        <CustomModal
          isModalOpen={isRecurringDonationModalOpen}
          style={isTablet && { textAlign: "unset" }}
          text={
            !isTablet && (
              <StyledUsedCardTitle className="recurring-donation-mobile-text">
                <FormattedMessage
                  id="dashboard::profile::recurringDonationCancel"
                  defaultMessage="Cancel recurring donation"
                />
              </StyledUsedCardTitle>
            )
          }
          children={recurringDonationContent(subscription, false, true)}
          header={
            isTablet && (
              <StyledUsedCardTitle>
                <FormattedMessage
                  id="dashboard::profile::recurringDonationCancel"
                  defaultMessage="Cancel recurring donation"
                />
              </StyledUsedCardTitle>
            )
          }
          isCloseIcon={true}
          cancelButtonAction={() => {
            handleCloseRecurringDonationModal()
          }}
          confirmButtonText={formatMessage({
            id: "dashboard::profile::recurringDonationCancel",
            defaultMessage: "Cancel recurring donation",
          })}
          confirmButtonAction={() => {
            handleCancelSubscription()
          }}
          isMobile={!isTablet}
          isStickyModalActions={!isTablet}
        />
      ) : null}
    </StyledPage>
  )
}

export default Profile
