import styled from "@emotion/styled"
import { Button } from "@tmu/components/common"

export const StyledSupportButton = styled(Button)`
  text-align: center;
  height: 3.5rem;
  width: 100%;
  margin: 1rem 0 1rem;
  font-size: 0.875rem;
  position: relative;
  cursor: pointer;

  svg {
    font-size: 2.3125rem;
    height: 2.3125rem;
  }
  @media screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
    height: 3rem;
    svg {
      font-size: 1.5rem;
      height: 1.5rem;
    }
  }
  @media screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 0.75rem;
    svg {
      display: none;
    }
  }
  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 0.9375rem;
    height: 3rem;
    svg {
      display: block;
      font-size: 1.5rem;
      height: 1.5rem;
    }
  }
  @media screen and (max-width: ${(p) => p.theme.breakpoints.phone}) {
    font-size: 0.825rem;
    svg {
      display: none;
    }
  }
`

export const StyledSocialShare = styled.div`
  border-top: 0.0625rem solid ${(p) => p.theme.colors.primaryTextColorLight};
  border-bottom: 0.0625rem solid ${(p) => p.theme.colors.primaryTextColorLight};
  height: 4rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    color: ${(p) => p.theme.colors.disabled};
    font-size: 0.7rem;
    flex: 2;
  }
  svg {
    font-size: 1.4rem;
    height: 1.4rem;
    margin-left: 1.25rem;
    cursor: pointer;
  }
  @media screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
    height: 2rem;
    svg {
      font-size: 1.3rem;
      height: 1.3rem;
    }
  }
  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    position: relative;
    height: 4rem;
  }
`

export const StyledGoallessCampaignStats = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: -2rem;
  p {
    color: ${(p) => p.theme.colors.disabled};
    span {
      color: ${(p) => p.theme.colors.gondola};
    }
  }

  .funded {
    font-weight: ${(p) => p.theme.fontWeights.bold};
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: ${(p) => p.theme.colors.blue};
  }
`

export const StyledContainer = styled.div`
  margin-bottom: 2rem;
  .support {
    font-weight: ${(p) => p.theme.fontWeights.bold};
    text-align: right;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
`
