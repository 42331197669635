import React, { useEffect, useRef, useState } from "react"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import { faDownload } from "@fortawesome/pro-solid-svg-icons/faDownload"
import { faPrint } from "@fortawesome/pro-solid-svg-icons/faPrint"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "@tmu/components/common"
import theme from "@tmu/global/theme"
import { StyledModalContent, StyledActionButtons } from "./index.styles"

const ShowQRCode = ({
  svgSource,
  width = 124,
  height = 124,
  fill = theme?.colors?.primaryTextColor,
  ...props
}) => {
  const { formatMessage } = useIntl()
  const [qrCodePath, setQRCodePath] = useState("")
  const [viewBox, setViewBox] = useState("0 0 41 41")
  const svgRef = useRef()

  const extractQRCodePath = () => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(svgSource, "image/svg+xml")
    const qrPathElement = doc.querySelector("#qr-path")

    if (qrPathElement) {
      const path = qrPathElement.getAttribute("d")
      setQRCodePath(path)
    }

    const svgElement = doc.querySelector("svg")
    if (svgElement) {
      const viewBoxAttr = svgElement.getAttribute("viewBox")
      if (viewBoxAttr) {
        setViewBox(viewBoxAttr)
      }
    }
  }

  useEffect(() => {
    svgSource && extractQRCodePath()
  }, [svgSource])

  const handlePrintQRCode = () => {
    const svgString = new XMLSerializer().serializeToString(svgRef.current)
    const printWindow = window.open("", "Print Window", "height=600,width=800")
    printWindow.document.write(
      "<html><head><title>QR Code</title></head><body>"
    )
    printWindow.document.write(svgString)
    printWindow.document.write("</body></html>")
    printWindow.document.close()
    printWindow.print()
  }

  const handleDownloadQRCode = () => {
    const svgElement = svgRef.current
    const svgString = new XMLSerializer().serializeToString(svgElement)
    const blob = new Blob([svgString], { type: "image/svg+xml" })
    const url = URL.createObjectURL(blob)
    const a = document.createElement("a")
    a.href = url
    a.download = "qrCode.svg"
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    URL.revokeObjectURL(url)
  }

  return (
    <StyledModalContent>
      {svgSource && qrCodePath ? (
        <>
          <svg
            ref={svgRef}
            width={width}
            height={height}
            color={fill}
            viewBox={viewBox}
            className="logo"
            {...props}>
            <path
              d={qrCodePath}
              fill="currentColor"
              fillRule="nonzero"
              fillOpacity={1}
              stroke="none"
            />
          </svg>
          <br />
          <StyledActionButtons>
            <Button
              data-testid="btn-qr-print"
              type="button"
              color="transparent"
              onClick={handlePrintQRCode}>
              <FontAwesomeIcon icon={faPrint} />
              <FormattedMessage id="qr::modal::print" defaultMessage="Print" />
            </Button>
            <Button
              data-testid="btn-qr-download"
              type="button"
              color="blue"
              onClick={handleDownloadQRCode}>
              <FontAwesomeIcon icon={faDownload} />
              <FormattedMessage
                id="qr::modal::download"
                defaultMessage="Download"
              />
            </Button>
          </StyledActionButtons>
        </>
      ) : (
        formatMessage({
          id: "qr::modal::notAvailable",
          defaultMessage: "Not available",
        })
      )}
    </StyledModalContent>
  )
}

export default ShowQRCode
