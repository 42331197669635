import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useLazyQuery } from "@apollo/client"
import queryString from "query-string"
import { Spinner } from "@tmu/components/common"
import { useIntl, navigate } from "gatsby-plugin-intl"
import { get, set } from "@tmu/utils/storage"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { useAuth, usePACBalance } from "@tmu/hooks"
import CampaignDonationSuccessContent from "./CampaignDonationSuccessContent"
import MerchantCampaignDonationSuccessContent from "./MerchantCampaignDonationSuccessContent"
import { OFFER_TYPE } from "@tmu/apollo/constants"
import { DONATION_SUCCESS_QUERY } from "@tmu/apollo/storefront/queries/campaign"
import { useFooterType, useToast, useCampaigns } from "@tmu/hooks"
import { FOOTER_TYPE, RECURRING_DONATION_TYPE } from "@tmu/apollo/constants"
import { isBrowser } from "@tmu/utils/auth"

const CampaignDonationSuccess = ({ slug, location }) => {
  const recurringData = location?.state?.data
  if (recurringData) set("recurringData", recurringData)
  const [donation, setDonation] = useState(recurringData?.donation)
  const [campaign, setCampaign] = useState(recurringData?.campaign)
  const [data, setData] = useState()
  const { isLoading, isAuthenticated, user } = useAuth()
  const { CLOUDFLARE_IMAGE_URL } = process.env
  useFooterType({ footerType: FOOTER_TYPE.HIDE })
  const { locale, defaultLocale, formatMessage } = useIntl()
  const params = queryString.parse(location.search, { arrayFormat: "comma" })
  const { refetchPacBalance } = usePACBalance()
  const toast = useToast()
  const { callCampaignDetail, campaign: campaignData } = useCampaigns()

  const redirectTo = params?.redirectTo
  const donationType = params?.donationType
  const isDWOR = params?.dwor
  const isNoDonation = params?.noDonation
  const isOfflineDonation = params?.iom

  const noDonationUsedPacs = params?.used
  const noDonationNewPrice = params?.newPrice

  const shouldGetDonationDataWithApi = !recurringData

  const { isTablet } = getAllScreenTypes()

  const onDonationSuccessQueryCompleted = (data) => {
    if (
      data?.donation?.id &&
      typeof refetchPacBalance === "function" &&
      isAuthenticated &&
      user?.id
    ) {
      setTimeout(() => refetchPacBalance(), 5000)
    }
  }
  const [
    callSingleDonationSuccessMutation,
    {
      loading,
      error,
      data: singleDonationData,
      called: singleDonationCalled,
      refetch: singleDonationRefetch,
    },
  ] = useLazyQuery(DONATION_SUCCESS_QUERY, {
    variables: {
      slug,
      donationId: params?.donation,
    },
    skip: isLoading || !isAuthenticated,
    onCompleted: (data) => onDonationSuccessQueryCompleted(data),
  })

  useEffect(() => {
    if (isNoDonation) {
      callCampaignDetail({ variables: { slug } })
    }
  }, [isNoDonation])

  useEffect(() => {
    if (shouldGetDonationDataWithApi) {
      setData(singleDonationData)
    } else {
      if (typeof refetchPacBalance === "function") {
        setTimeout(() => refetchPacBalance(), 5000)
      }
    }
  }, [singleDonationData, recurringData])

  useEffect(() => {
    if (shouldGetDonationDataWithApi) {
      setData(singleDonationData)
    }
    setDonation(singleDonationData?.donation)
    setCampaign(singleDonationData?.campaign)
  }, [singleDonationData])

  useEffect(() => {
    if (isAuthenticated && params?.donation && shouldGetDonationDataWithApi) {
      if (singleDonationCalled) {
        singleDonationRefetch()
      } else {
        callSingleDonationSuccessMutation()
      }
    }
  }, [params?.donation, shouldGetDonationDataWithApi, isAuthenticated])

  const donateByShoppingOnComplete = (redirectTo) => {
    if (isBrowser) window.location.href = redirectTo
  }

  useEffect(() => {
    if (campaignFromApi) setCampaign(campaignFromApi)
  }, [, campaignFromApi])
  useEffect(() => {
    if (donationFromApi) setDonation(donationFromApi)
  }, [, donationFromApi])

  if (loading) return <Spinner />

  if (error) {
    toast?.error(
      formatMessage({
        id: "dashboard::campaignForm::errorMessage",
        defaultMessage: "An error occurred",
      })
    )
    navigate("/campaigns?campaign=true&charity=true&event=true")
  }

  const { campaign: campaignFromApi, donation: donationFromApi } = { ...data }

  const isOfflineMerchant =
    campaign?.store?.defaultOffer?.offerType === OFFER_TYPE.OFFLINE

  const isCorporateMerchant =
    campaign?.store?.defaultOffer?.offerType === OFFER_TYPE.BRAND

  const el =
    typeof window !== "undefined" && document.getElementsByTagName("body")[0]
  if (el) el.style.paddingBottom = !isTablet ? "3.125rem" : "1rem"

  return (
    <>
      {isOfflineMerchant || isOfflineDonation || isNoDonation ? (
        <MerchantCampaignDonationSuccessContent
          campaign={campaign || campaignData}
          donation={donation}
          locale={locale}
          defaultLocale={defaultLocale}
          donationType={donationType}
          formatMessage={formatMessage}
          CLOUDFLARE_IMAGE_URL={CLOUDFLARE_IMAGE_URL}
          user={user}
          isTablet={isTablet}
          merchant={campaign?.store || campaignData?.store}
          noDonationNewPrice={noDonationNewPrice}
          noDonationUsedPacs={noDonationUsedPacs}
          isAuthenticated={isAuthenticated}
        />
      ) : (
        <CampaignDonationSuccessContent
          isAuthenticated={isAuthenticated}
          isDWOR={isDWOR}
          campaign={campaign}
          donation={donation}
          locale={locale}
          defaultLocale={defaultLocale}
          donationType={donationType}
          formatMessage={formatMessage}
          CLOUDFLARE_IMAGE_URL={CLOUDFLARE_IMAGE_URL}
          redirectTo={redirectTo}
          donateByShoppingOnComplete={donateByShoppingOnComplete}
          user={user}
          isTablet={isTablet}
          isCorporateMerchant={isCorporateMerchant}
        />
      )}
    </>
  )
}

CampaignDonationSuccess.propTypes = {
  slug: PropTypes.string.isRequired,
}

CampaignDonationSuccess.defaultProps = {
  slug: "",
}

export default CampaignDonationSuccess
