import React from "react"

import {
  StyledPriceCard,
  StyledRightSlices,
  StyledSeperator,
  StyledLeftSlices,
  StyledSlice,
  StyledTitleWrapper,
  StyledSliceTitle,
  StyledSliceSubTitle,
  StyledSlicePrice,
  StyledSliceIcon,
  StyledBottomLine,
  StyledComingSoonTitle,
  StyledComingSoon,
} from "./index.styles"

const PriceCard = ({
  rightSlices,
  leftSlices,
  comingSoonTitle,
  comingSoon,
}) => {
  return (
    <StyledPriceCard>
      {rightSlices && (
        <StyledRightSlices>
          {rightSlices.map((rightSlice, i) => (
            <div key={i}>
              <StyledSlice>
                <StyledTitleWrapper>
                  {rightSlice.title && (
                    <StyledSliceTitle>{rightSlice.title}</StyledSliceTitle>
                  )}
                  {rightSlice.subTitle && (
                    <StyledSliceSubTitle>
                      {rightSlice.subTitle}
                    </StyledSliceSubTitle>
                  )}
                </StyledTitleWrapper>
                {rightSlice.price && (
                  <StyledSlicePrice>{rightSlice.price}</StyledSlicePrice>
                )}
                {rightSlice.icon && <StyledSliceIcon src={rightSlice.icon} />}
              </StyledSlice>
              {i !== 6 && <StyledBottomLine />}
            </div>
          ))}
        </StyledRightSlices>
      )}
      <StyledSeperator />
      {leftSlices && (
        <StyledLeftSlices>
          {leftSlices.map((leftSlice, i) => (
            <div key={i}>
              <StyledSlice>
                <StyledTitleWrapper>
                  {leftSlice.title && (
                    <StyledSliceTitle>{leftSlice.title}</StyledSliceTitle>
                  )}
                  {leftSlice.subTitle && (
                    <StyledSliceSubTitle>
                      {leftSlice.subTitle}
                    </StyledSliceSubTitle>
                  )}
                </StyledTitleWrapper>
                {leftSlice.price && (
                  <StyledSlicePrice>{leftSlice.price}</StyledSlicePrice>
                )}
                {leftSlice.icon && <StyledSliceIcon src={leftSlice.icon} />}
              </StyledSlice>
              {i !== 5 && <StyledBottomLine />}
            </div>
          ))}
          {comingSoonTitle && (
            <StyledComingSoonTitle>{comingSoonTitle}</StyledComingSoonTitle>
          )}
          {comingSoon &&
            comingSoon.map((e, i) => (
              <StyledComingSoon key={i}>{e}</StyledComingSoon>
            ))}
        </StyledLeftSlices>
      )}
    </StyledPriceCard>
  )
}

export default PriceCard
