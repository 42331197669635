import styled from "@emotion/styled"
import { css } from "@emotion/react"

export const StyledOverviewCard = styled.div`
  display: grid;
  grid-template-columns: 0.8fr 1.4fr 0.8fr;
  grid-template-rows: 0.6fr 1.8fr 0.6fr;
  gap: 0.0625rem 0.0625rem;
  grid-template-areas:
    "title title info"
    "content content content"
    "meta . action";
  border: 0.0625rem solid ${(p) => p.theme.colors.gainsboro};
  padding: 1.875rem 1.875rem 0.9375rem 1.875rem;
  border-radius: 0.8rem;
  align-items: center;
  min-height: 12.875rem;
  -webkit-min-logical-height: auto;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  place-self: center;
  justify-self: center;

  ${(p) =>
    p.hasImage &&
    css`
      grid-template-columns: 0.8fr 1.4fr 0.8fr;
      grid-template-rows: 0.6fr 1.8fr 0.6fr;
      grid-template-areas:
        "title title image"
        "content content image"
        "meta . action";
    `};

  @media only screen and (max-width: 25rem) {
    font-size: 1rem;
    grid-template-columns: 1.6fr 1.4fr;
    grid-template-rows: 1.2fr 1.8fr;
    grid-template-areas:
      "title title"
      "info info"
      "content content"
      "meta action";

    ${(p) =>
      p.hasImage &&
      css`
        grid-template-areas:
          "title image"
          "info info"
          "content content"
          "meta action";
      `};
  }
`

export const StyledOverviewCardTitle = styled.div`
  grid-area: title;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.3rem;
  font-weight: ${(p) => p.theme.fontWeights.bold};

  svg,
  i {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }

  svg.icon,
  i.icon {
    margin-left: 0;
    font-size: 2.25rem;
  }

  @media only screen and (max-width: 25rem) {
    font-size: 1rem;
  }
`

export const StyledOverviewCardImage = styled.div`
  grid-area: image;
  width: 6.25rem;
  height: 6.25rem;
  justify-self: center;
  align-self: start;
  img {
    width: auto;
    height: auto;
    max-width: 6.25rem;
    max-height: 6.25rem;
    border-radius: ${(p) => (p.avatar ? "50%" : 0)};
  }
`

export const StyledOverviewCardInfo = styled.div`
  grid-area: info;
  color: ${(p) => p.theme.colors.blue};
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-self: center;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin-right: -0.625rem;
  }

  strong {
    font-size: 1.5rem;
    font-weight: ${(p) => p.theme.fontWeights.bold};
    margin-right: 0.3125rem;
  }
`

export const StyledOverviewCardContent = styled.div`
  grid-area: content;
  align-self: start;
  margin-top: 1.375rem;

  h5 {
    font-size: 1.2rem;
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-weight: ${(p) => p.theme.fontWeights.regular};
    color: ${(p) => p.theme.colors.gondola};
    opacity: 0.6;
    margin-bottom: 0.375rem;
  }

  h6 {
    opacity: 0.6;
    color: ${(p) => p.theme.colors.gondola};
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-weight: ${(p) => p.theme.fontWeights.regular};
    font-size: 0.75rem;
  }

  p {
    color: ${(p) => p.theme.colors.primaryBrandTextColor};
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  address {
    color: ${(p) => p.theme.colors.tableHeaderDefault};
    font-size: 0.8rem;
    line-height: 1.6;
  }

  ul {
    display: block;
    color: ${(p) => p.theme.colors.primaryBrandTextColor};
    font-size: 0.8rem;
    padding-bottom: 1.125rem;
    padding-inline-start: 0;
    li {
      display: grid;
      grid-template-columns: 2.5fr 1.5fr 5.6rem;
      grid-template-rows: auto;
      grid-template-areas: "name date amount";
      gap: 0.3rem;
      justify-content: space-between;
      padding-top: 0.5rem;
      .name {
        grid-area: name;
      }
      .date {
        grid-area: date;
        justify-self: end;
      }
      .amount {
        grid-area: amount;
        justify-self: end;
      }
    }
  }
`

export const StyledOverviewCardMeta = styled.div`
  grid-area: meta;
`

export const StyledOverviewCardAction = styled.div`
  grid-area: action;
  justify-self: end;
`
