import React, { useContext, useEffect, useRef, useState } from "react"
import { useMutation } from "@apollo/client"

import theme from "@tmu/global/theme"
import { useAuth, useDefaultMerchant, useDefaultPartner } from "@tmu/hooks"
import { LanguageBar } from "@tmu/components/common"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons/faArrowLeft"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown"
import { UPDATE_ME_MUTATION } from "@tmu/apollo/storefront/mutations/user"
import { ME_QUERY } from "@tmu/apollo/storefront/queries/user"
import { faUserAlt } from "@fortawesome/pro-duotone-svg-icons/faUserAlt"

import {
  StyledGetStartedButtonContainerMobile,
  StyledGetStartedButtonMobile,
  StyledLanguageMenuItem,
  StyledLine,
  StyledMobileLanguageBar,
  StyledMobileMenuItem,
  StyledMobileMenuItemList,
  StyledMobileMenuWrapper,
  StyledMobileNavBarLogo,
} from "../index.styles"
import {
  StyledAvatar,
  StyledLeftGetStartedButton,
  StyledLeftMenuItem,
  StyledLeftMenuItemList,
  StyledLeftMenuTextWithIconWrapper,
  StyledLeftMenuUserInfo,
  StyledMerchantType,
  StyledMerchantsTypeWrapper,
  StyledLeftSigninButton,
  StyledMenuOverlay,
  StyledMerchant,
  StyledMerchantIcon,
  StyledMerchantOptions,
  StyledMerchantStatus,
  StyledMerchantTitle,
  StyledMerchantsWrapper,
  StyledMobileAuthSection,
  StyledMobileMenu,
  StyledMobileNavButton,
  StyledSelectedMerchant,
  StyledSelectedMerchantIcon,
  StyledSelectedMerchantTitle,
  StyledTooltip,
  StyledCloseIcon,
} from "@tmu/components/common/Header/index.styles"

import NavbarLogo from "./NavbarLogo"
import { navigate, FormattedMessage } from "gatsby-plugin-intl"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import {
  StyledLanguageHeader,
  StyledLanguageHeaderContainer,
} from "../../Footer/index.styles"

const MobileMenu = ({
  menuItems,
  merchantMenuItems,
  partnerMenuItems,
  closeModal,
  handleSignup,
  defaultPartner,
  activeMerchant,
  setActiveMerchant,
}) => {
  const {
    user,
    isAuthenticated,
    signOut,
    calledPartnerApiPermissions,
    callPartnerApiPermissions,
    calledMerchantApiPermissions,
    callMerchantApiPermissions,
    apiPermissions,
  } = useAuth()

  const {
    defaultMerchant,
    isMerchantOffline,
    isMerchantInternal,
    isMerchantOnline,
    isMerchantCorporate,
    loading: isMerchantLoading,
    refetch: merchantRefetch,
  } = useDefaultMerchant()
  const [languageStatus, setLanguageStatus] = useState(false)
  const { isTablet } = getAllScreenTypes()
  const [isClickedOnSwitchAccount, setIsClickedOnSwitchAccount] =
    useState(false)
  let menuItemsToList = isAuthenticated
    ? menuItems
    : menuItems?.filter((item) => item?.public)

  if (!isTablet) {
    menuItemsToList = menuItemsToList?.filter((item) => item?.mobile === true)
  }
  const merchantUsers = user?.merchantUsers?.edges
  const activeStore = activeMerchant?.node?.store

  const [updateProfile] = useMutation(UPDATE_ME_MUTATION, {
    refetchQueries: [
      {
        query: ME_QUERY,
      },
    ],
    onCompleted: () => {
      merchantRefetch()
      navigate("/dashboard/merchants/account")
    },
  })

  useEffect(() => {
    if (user?.isPartner && !calledPartnerApiPermissions) {
      callPartnerApiPermissions()
      return
    }

    const activeMerchantTemp =
      merchantUsers?.length === 1
        ? merchantUsers[0]
        : merchantUsers?.find(
            (merchant) =>
              merchant?.node?.store?.id === user?.activeStore?.id ||
              merchant?.node?.store?.id === defaultMerchant?.id
          )

    setActiveMerchant(activeMerchantTemp)

    if (
      user?.isMerchant &&
      activeMerchantTemp?.node?.id &&
      !calledMerchantApiPermissions
    ) {
      callMerchantApiPermissions({
        variables: { id: activeMerchantTemp?.node?.id },
      })
    }
  }, [user?.activeStore?.id, defaultMerchant?.id, defaultPartner?.id])

  const languageList = <LanguageBar isList={true} disabled height={24} />
  const isMultipleStore = merchantUsers?.length > 1

  const languageSelector = (
    <div
      onClick={() => {
        setLanguageStatus(false)
      }}>
      <StyledLanguageHeaderContainer>
        <div>
          <FontAwesomeIcon icon={faArrowLeft} />
        </div>
        <StyledLanguageHeader>
          <FormattedMessage id="nav::language" defaultMessage="Language" />
        </StyledLanguageHeader>
      </StyledLanguageHeaderContainer>
      {languageList}
    </div>
  )

  return (
    <StyledMobileMenuWrapper>
      <StyledMobileNavBarLogo
        onClick={() => {
          closeModal()
        }}>
        <NavbarLogo flat={false} width={150} height={50} />
      </StyledMobileNavBarLogo>
      {isAuthenticated && user?.id && (
        <StyledLeftMenuItem>
          <StyledAvatar>
            {user.profileImage?.length ? (
              <img
                src={`${user.profileImage}?width=${24}&height=${24}`}
                alt="avatar"
              />
            ) : (
              <FontAwesomeIcon icon={faUserAlt} />
            )}
          </StyledAvatar>
          <StyledLeftMenuUserInfo mobile>
            {user?.fullName}
          </StyledLeftMenuUserInfo>
        </StyledLeftMenuItem>
      )}
      <StyledMobileMenuItemList>
        {isAuthenticated
          ? languageStatus
            ? languageSelector
            : menuItemsToList?.map((item, index) => {
                return (
                  <>
                    <StyledMobileMenuItem
                      key={item?.label}
                      onClick={() => {
                        if (typeof item?.handler === "function") {
                          item?.handler()
                        }

                        if (item?.link?.length > 0) {
                          navigate(item?.link)
                        }
                        closeModal()
                      }}>
                      {item.rawText === "language" ? (
                        <StyledLanguageMenuItem
                          onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            setLanguageStatus(true)
                          }}>
                          <div>{item?.label}</div>
                          <div className="language">
                            <LanguageBar disabled height={16} />
                          </div>
                        </StyledLanguageMenuItem>
                      ) : (
                        item?.label
                      )}
                    </StyledMobileMenuItem>
                    <StyledLine />
                  </>
                )
              })
          : languageList}
      </StyledMobileMenuItemList>
      <StyledLeftMenuTextWithIconWrapper
        onClick={
          isMultipleStore
            ? () => setIsClickedOnSwitchAccount(!isClickedOnSwitchAccount)
            : () => {}
        }
        isMultipleMerchant={isMultipleStore}>
        <StyledLeftMenuUserInfo mobile>
          {activeMerchant?.node?.store?.name}
        </StyledLeftMenuUserInfo>

        {isMultipleStore && <FontAwesomeIcon icon={faChevronDown} />}
      </StyledLeftMenuTextWithIconWrapper>
      <StyledMerchantType>
        {activeStore?.defaultOffer?.offerType}
      </StyledMerchantType>
      {isClickedOnSwitchAccount && (
        <>
          <StyledMerchantsWrapper>
            <StyledSelectedMerchant>
              <StyledSelectedMerchantIcon>
                {activeStore?.logo ? (
                  <img
                    src={activeStore?.logo}
                    height={"100%"}
                    width={"100%"}
                    alt="Merchant Logo"
                  />
                ) : (
                  activeStore?.name?.[0] || item?.title?.[0]
                )}
              </StyledSelectedMerchantIcon>
              <div>
                <StyledSelectedMerchantTitle>
                  {activeStore?.name || item?.title}
                </StyledSelectedMerchantTitle>
                <StyledMerchantStatus>
                  {activeStore?.defaultOffer?.offerType}
                </StyledMerchantStatus>
              </div>
            </StyledSelectedMerchant>

            <StyledMerchantOptions>
              {merchantUsers
                ?.filter(
                  (merchant) =>
                    ![activeStore?.id, defaultMerchant?.id].includes(
                      merchant?.node?.store?.id
                    )
                )
                ?.map((merchant) => (
                  <StyledMerchant
                    onClick={async () => {
                      await updateProfile({
                        variables: {
                          input: {
                            activeStore: merchant?.node?.store?.id,
                          },
                        },
                      }).then((r) => {
                        setActiveMerchant(merchant)
                      })
                      setIsClickedOnSwitchAccount(false)
                    }}>
                    <StyledMerchantIcon>
                      {merchant?.node?.store?.logo ? (
                        <img
                          src={merchant?.node?.store?.logo}
                          height={"100%"}
                          width={"100%"}
                          alt="Merchant Logo"
                        />
                      ) : (
                        merchant?.node?.store?.name?.[0]
                      )}
                    </StyledMerchantIcon>
                    <div>
                      <StyledMerchantTitle>
                        {merchant?.node?.store?.name}
                      </StyledMerchantTitle>
                      <StyledMerchantStatus>
                        {merchant?.node?.store.defaultOffer?.offerType}
                      </StyledMerchantStatus>
                    </div>
                  </StyledMerchant>
                ))}
            </StyledMerchantOptions>
          </StyledMerchantsWrapper>
        </>
      )}
      <StyledMobileMenuItemList>
        {isAuthenticated
          ? merchantMenuItems
              ?.filter((item) => item)
              .map((item, index) => {
                return (
                  <>
                    <StyledMobileMenuItem
                      key={item?.label}
                      onClick={() => {
                        if (typeof item?.handler === "function") {
                          item?.handler()
                        }

                        if (item?.link?.length > 0) {
                          navigate(item?.link)
                        }
                        closeModal()
                      }}>
                      {item?.label}
                    </StyledMobileMenuItem>
                    <StyledLine />
                  </>
                )
              })
          : null}
      </StyledMobileMenuItemList>
      {isAuthenticated && user?.isPartner && (
        <StyledLeftMenuUserInfo mobile>
          {defaultPartner?.displayName || defaultPartner?.name}{" "}
        </StyledLeftMenuUserInfo>
      )}
      <StyledMobileMenuItemList>
        {isAuthenticated
          ? partnerMenuItems?.map((item, index) => {
              return (
                <>
                  <StyledMobileMenuItem
                    key={item?.label}
                    onClick={() => {
                      if (typeof item?.handler === "function") {
                        item?.handler()
                      }

                      if (item?.link?.length > 0) {
                        navigate(item?.link)
                      }
                      closeModal()
                    }}>
                    {item?.label}
                  </StyledMobileMenuItem>
                  <StyledLine />
                </>
              )
            })
          : null}
      </StyledMobileMenuItemList>
      {!isAuthenticated && (
        <StyledMobileLanguageBar onClick={closeModal}>
          <StyledGetStartedButtonContainerMobile>
            <StyledGetStartedButtonMobile
              to="/signup"
              color={theme.colors.pacificBlue}
              onClick={handleSignup}
              data-testid="btn-get-started">
              <FormattedMessage
                id="nav::getStartedMobile"
                defaultMessage="Sign in / Sign up"
              />
            </StyledGetStartedButtonMobile>
          </StyledGetStartedButtonContainerMobile>
        </StyledMobileLanguageBar>
      )}
    </StyledMobileMenuWrapper>
  )
}

export default MobileMenu
