import styled from "@emotion/styled"
import { css } from "@emotion/react"

export const StyledAddressFieldSet = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    "header"
    "address1"
    "country"
    "postal"
    "tax";
  grid-template-rows: auto;
  gap: 2rem;
  align-items: center;

  .city-select .react-select__option {
    color: ${(p) => p.theme.colors.primaryTextColor};
    background-color: ${(p) => p.theme.colors.white};
    &:hover {
      background-color: rgba(10, 157, 189, 0.5);
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
      "header header header"
      "address1 address1 address1"
      "country postal tax";
  }
`

export const StyledHeader = styled.div`
  grid-area: header;

  h3 {
    font-family: ${(p) => p.theme.fontFamilies.regular};
    margin-bottom: 1.25rem;
  }
`

export const StyledField = styled.div`
  .input-feedback {
    margin: 0.2rem;
  }
  ${(p) =>
    p.gridArea &&
    css`
      grid-area: ${p.gridArea};
    `}
`
