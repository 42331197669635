import React, { useEffect } from "react"
import { ServicesTable } from "@tmu/components/dashboard/dashboardCommon"
import {
  StyledPage,
  StyledPageContent,
  StyledPageTitle,
} from "@tmu/global/page-addons/dashboard.styles"
import {
  StyledForm,
  StyledSearchContainer,
} from "../../DonationSearch/index.styles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/pro-light-svg-icons/faSearch"
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus"
import { FormattedMessage, useIntl, navigate } from "gatsby-plugin-intl"
import { useState } from "react"
import { Button, TextInput } from "@tmu/components/common"
import { StyledCreate } from "./index.styles"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { useAuth } from "@tmu/hooks"
import { API_PERMISSIONS } from "@tmu/apollo/constants"

export default function ServiceListing() {
  const [serviceName, setServiceName] = useState("")
  const [searchText, setSearchText] = useState()
  const { formatMessage } = useIntl()
  const {
    apiPermissions,
    callMerchantApiPermissions,
    calledMerchantApiPermissions,
  } = useAuth()
  const { isTablet } = getAllScreenTypes()
  let searchTimeout = null

  const handleCreateService = () => {
    navigate("/dashboard/merchants/services/type")
  }

  // useEffect(() => {
  //   if (!apiPermissions?.length || !calledMerchantApiPermissions) {
  //     callMerchantApiPermissions()
  //   }
  // }, [apiPermissions, calledMerchantApiPermissions])

  const createText = (
    <FormattedMessage
      id="dashboard::services::createService"
      defaultMessage="Create a Service"
    />
  )

  return (
    <StyledPage>
      <StyledPageTitle>
        <div>
          <FormattedMessage
            id="dashboard::nav::services"
            defaultMessage="Services"
            tagName="h1"
          />
        </div>
        {apiPermissions?.includes(API_PERMISSIONS.MERCHANT_CREATE_PRODUCT) &&
          isTablet && (
            <StyledCreate>
              <div>
                <FontAwesomeIcon icon={faPlus} />
              </div>
              <div onClick={handleCreateService}>{createText}</div>
            </StyledCreate>
          )}
      </StyledPageTitle>

      <StyledPageContent data-testid="services-page-content">
        {isTablet && (
          <StyledSearchContainer>
            <StyledForm>
              <FontAwesomeIcon icon={faSearch} />
              <TextInput
                data-testid="services-search-box"
                id="serviceName"
                name="serviceName"
                value={serviceName}
                type="text"
                label={formatMessage({
                  id: "dashboard::servicesSearch::inputPlaceholder",
                  defaultMessage: "Service name",
                })}
                className="float-container full-width search-input"
                placeholder=""
                onChange={(event) => {
                  event.preventDefault()
                  event.stopPropagation()
                  setServiceName(event?.target?.value)
                  clearTimeout(searchTimeout)
                  searchTimeout = setTimeout(() => {
                    setSearchText(event?.target?.value)
                  }, 1000)
                }}
              />
            </StyledForm>
          </StyledSearchContainer>
        )}
        <ServicesTable
          searchText={searchText}
          apiPermissions={apiPermissions}
        />
        <br />
      </StyledPageContent>

      {apiPermissions?.includes(API_PERMISSIONS.MERCHANT_CREATE_PRODUCT) &&
        !isTablet && (
          <div className="sticky-bottom-bar">
            <Button
              data-testid="btn-create-service"
              color="transparent"
              type="button"
              onClick={handleCreateService}>
              <FontAwesomeIcon icon={faPlus} />
              {createText}
            </Button>
          </div>
        )}
    </StyledPage>
  )
}
