import React from "react"
import PropTypes from "prop-types"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import {
  StyledAssetTable,
  StyledAssetNameInfo,
  StyledAssetName,
  StyledAssetInfo,
  StyledAssetDescription,
  StyledAssetHr,
} from "./index.styles"

const DownloadCenterTable = ({ allAssets = [] }) => {
  const { isTablet, isDesktop, isWide } = getAllScreenTypes()

  const handleDownload = (assetUrl, filename) => {
    let element = document.createElement("a")
    element.setAttribute("href", assetUrl)
    element.setAttribute("download", filename)
    element.style.display = "none"
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
  }

  return (
    <>
      <StyledAssetHr bottom={1} />
      <StyledAssetTable>
        {allAssets?.map((asset) => (
          <div key={asset?.id}>
            <StyledAssetNameInfo>
              <StyledAssetName>
                <a
                  href={asset?.assetUrl}
                  onClick={() => handleDownload(asset?.assetUrl)}>
                  {asset?.name}
                </a>
              </StyledAssetName>
              <StyledAssetInfo>
                {asset?.mimetype}|{asset?.size}|{asset?.language}
              </StyledAssetInfo>
            </StyledAssetNameInfo>
            <StyledAssetDescription>
              {asset?.description}
            </StyledAssetDescription>
            <StyledAssetHr
              top={isWide ? 1.5 : isDesktop || isTablet ? 1 : 0.75}
              bottom={isWide ? 1.5 : isDesktop || isTablet ? 1 : 0.75}
            />
          </div>
        ))}
      </StyledAssetTable>
    </>
  )
}

DownloadCenterTable.propTypes = {
  allAssets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      assetUrl: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      mimetype: PropTypes.string.isRequired,
      size: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
      language: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default DownloadCenterTable
