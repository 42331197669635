import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { Button } from "@tmu/components/common"

export const StyledFieldHelpText = styled.p`
  margin-top: 1rem;
  margin-bottom: 1.24rem;
  font-size: 0.95rem;
  color: ${(p) => p.theme.colors.disabled};
`
export const StyledDropzoneContent = styled.div`
  border: 0.125rem dashed ${(p) => p.theme.colors.blue};
  padding: 1.5rem;
  text-align: center;
  border-radius: 0.3125rem;
  min-height: 15rem;
  display: flex;
  align-items: center;
  justify-content: start;
  .open-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.125rem solid ${(p) => p.theme.colors.tertiaryBrandColor};
    /* Dimensions should stay in pixels */
    height: 200px;
    width: 300px;
    margin: 0.25rem 1.25rem;
    align-self: flex-start;
    cursor: pointer;
    > svg {
      path {
        fill: ${(p) => p.theme.colors.tertiaryBrandColor};
      }
    }
  }
  ${(p) =>
    p.active &&
    css`
      border: 0.125rem dashed ${p.theme.colors.blue};
    `}
`

export const StyledImagesContainerListRow = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 0.5rem;
  position: relative;

  button.delete {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    left: 28.75rem;
    top: 0.625rem;
  }

  &:hover,
  &:focus,
  &:active,
  &.active {
    button.delete {
      visibility: visible;
      opacity: 1;
    }
  }
`

export const StyledAddImageButton = styled(Button)`
  svg {
    margin-left: 0;
    margin-right: 0.75rem;
  }
`

export const StyledGoalFields = styled.div`
  max-width: 18.75rem;
  .select-field,
  .float-container {
    margin-bottom: 2rem !important;
  }
  .SingleDatePicker,
  .SingleDatePickerInput {
    width: 100%;
  }
`

export const StyledSpinner = styled.div`
  .spinner-condensed-container {
    width: 1.5625rem !important;
    height: 1.5625rem !important;

    .spinner-condensed {
      transform: translateZ(0) scale(0.25) !important;
    }
  }
`
export const StyledCampaignTypeSection = styled.div`
  ${(p) =>
    p.isDisabled &&
    css`
      pointer-events: none;
    `}
`
