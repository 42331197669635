import React, { useEffect } from "react"

import { Spinner } from "@tmu/components/common"
import { navigate } from "gatsby-plugin-intl"
import queryString from "query-string"

const DonationSearch = () => {
  useEffect(() => {
    const params = queryString.parse(location?.search, { arrayFormat: "comma" })
    const link = `/dashboard/donors/donation-search?code=${params?.code}`
    navigate(link)
  }, [])

  return <Spinner />
}

export default DonationSearch
