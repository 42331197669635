import styled from "@emotion/styled"

export const StyledPaymentMethodsWrapper = styled.div`
  display: flex;
  gap: 1rem;

  .disabled {
    color: ${(p) => p.theme.colors.lightGrey} !important;
  }
`

export const StyledPaymentMethod = styled.div`
  cursor: pointer;
  width: 10rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 4px;
  border: 0px solid #c4c4c4;

  background-color: ${(p) => p.theme.colors.whiteSmoke};
  border-radius: 0.25rem;

  &.selected {
    border-color: ${(p) => p.theme.colors.blue};
    border-width: 0.063rem;
    border-radius: 0.25rem;
    border-style: solid;
  }
`

export const StyledPaymentMethodText = styled.div`
  font-size: 0.75rem;
  line-height: 1rem;
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  border-color: ${(p) => p.theme.colors.pitchBlack};
  text-transform: capitalize;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1rem;
    line-height: 1.75rem;
  }
`
