import React, { useEffect } from "react"
import { useLazyQuery } from "@apollo/client"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { useApolloApiClients } from "@tmu/apollo/client"
import { QUERY_STATIC_PAGES } from "@tmu/apollo/storefront/queries/static"
import { useAuth } from "@tmu/hooks"
import { Spinner } from "@tmu/components/common"
import ContentNotFound from "@tmu/components/ContentNotFound"
import {
  StyledLegalContentDetails,
  StyledLegalContentShortcuts,
  StyledLegalContent,
} from "./index.styles"
import { getContent } from "@tmu/components/legal/getContent"

const LegalContent = ({ slug, pageId }) => {
  const { strapiClient } = useApolloApiClients()
  const { isLoading } = useAuth()
  const { isTablet } = getAllScreenTypes()

  const { locale } = useIntl()

  const [callAllPages, { loading, data, called }] = useLazyQuery(
    QUERY_STATIC_PAGES,
    {
      client: strapiClient,
      variables: { pageId, locale },
    }
  )

  useEffect(() => {
    if (isLoading) {
      return
    }

    if (!called) {
      callAllPages()
    }
  }, [isLoading])

  const defaultContent = getContent({ locale })

  const defaultPageData = defaultContent?.pages?.data?.filter(
    (item) => Number(item.attributes.pageId) === Number(pageId)
  )?.[0]

  const pageData = data ? data?.pages?.data?.[0] : defaultPageData
  let htmlString = pageData?.attributes?.Content
  const EditedContent = htmlString?.replaceAll("<h4", (_, i) => {
    return `<h4 id="${i}" `
  })
  let temp = document.createElement("div")
  temp.innerHTML = EditedContent

  let part = [].slice.call(temp?.getElementsByTagName("h4"))
  let hash = typeof window !== "undefined" && window?.location?.hash

  if (loading) {
    return <Spinner condensed />
  }

  if (!pageData) {
    return <ContentNotFound slug={slug} />
  }
  const shortcuts = (
    <StyledLegalContentShortcuts>
      <p className="caption">
        <FormattedMessage
          id="legal::menu::shortcuts"
          defaultMessage="Shortcuts"
        />
      </p>
      {part?.length
        ? part.map((item) => (
            <a
              className={hash === "#" + item.id ? "active" : ""}
              href={`#${item.id}`}
              key={item.id}>
              {item.innerText.substring(item.innerText.indexOf(" ") + 1)}
            </a>
          ))
        : null}
    </StyledLegalContentShortcuts>
  )
  return !pageData ? (
    <Spinner condensed />
  ) : (
    <StyledLegalContent>
      {!isTablet ? shortcuts : null}
      <StyledLegalContentDetails>
        <h1>{pageData?.Title}</h1>
        <div
          dangerouslySetInnerHTML={{
            __html: EditedContent,
          }}
        />
      </StyledLegalContentDetails>
      {isTablet ? shortcuts : null}
    </StyledLegalContent>
  )
}

export default LegalContent
