import React, { useEffect } from "react"
import { Router } from "@reach/router"
import { navigate } from "gatsby-plugin-intl"
import {
  MerchantProfileForm,
  // TODO: Notifications will be added after designs and BE API is ready
  // Notifications,
  Sales,
  Stores,
  Users,
  Wallet,
  Developers,
  MerchantCampaigns,
  Services,
  DownloadCenter,
  CorporateBrands,
  ReferralCodeListings,
  ReferralCodeForm,
} from "@tmu/components/dashboard/dashboardPages"
import { useAuth, useDefaultMerchant } from "@tmu/hooks"
import { API_PERMISSIONS } from "@tmu/apollo/constants"
import { Spinner } from "@tmu/components/common"
const Merchants = () => {
  const { isLoading, user, apiPermissions } = useAuth()

  const { isMerchantInternal, isMerchantOnline, loading, defaultMerchant } =
    useDefaultMerchant()

  const merchantUsers = user?.merchantUsers?.edges

  const activeMerchantTemp =
    merchantUsers?.length === 1
      ? merchantUsers?.[0]
      : merchantUsers?.find(
          (merchant) =>
            merchant?.node?.store?.id === user?.activeStore?.id ||
            merchant?.node?.store?.id === defaultMerchant?.id
        )

  const permission = (type) =>
    apiPermissions?.includes(type) ||
    activeMerchantTemp?.node?.finalPermissions?.includes(type)

  useEffect(() => {
    if (isLoading) {
      return
    }
    if (!isLoading && user?.id && !user.isMerchant) {
      navigate("/dashboard/profile")
    }
  }, [isLoading, user])

  if (isLoading || loading) {
    return <Spinner />
  }

  return (
    <Router>
      {permission(API_PERMISSIONS.MERCHANT_VIEW_ACCOUNT_INFORMATION) && (
        <MerchantProfileForm path="account/*" />
      )}
      {permission(API_PERMISSIONS.MERCHANT_VIEW_WALLET) && (
        <Wallet path="wallet/*" dashboardType="merchants" />
      )}
      {isMerchantOnline &&
        permission(API_PERMISSIONS.MERCHANT_VIEW_PRODUCT_LISTING) && (
          <Stores path="stores/*" />
        )}
      {permission(API_PERMISSIONS.MERCHANT_VIEW_ORDER) && (
        <Sales path="sales/*" />
      )}
      {permission(API_PERMISSIONS.MERCHANT_MANAGE_USER) && (
        <Users path="users/*" dashboardType="merchants" />
      )}
      {permission(API_PERMISSIONS.MERCHANT_VIEW_PLUGIN_MANAGEMENT) && (
        <Developers path="developers/*" />
      )}
      {permission(API_PERMISSIONS.MERCHANT_VIEW_CAMPAIGN_LISTING) && (
        <MerchantCampaigns path="campaigns/*" dashboardType="merchants" />
      )}
      {/* permission(API_PERMISSIONS.MERCHANT_VIEW_NOTIFICATION_CENTER) && (
         <Notifications path="notifications/*" /> 
         ) */}
      {isMerchantInternal &&
        permission(API_PERMISSIONS.MERCHANT_VIEW_PRODUCT_LISTING) && (
          <Services path="services/*" />
        )}
      <DownloadCenter path="download-center/*" dashboardType="merchants" />
      {defaultMerchant?.pricingBundle?.code === "MERCHANT_BRAND" &&
        defaultMerchant?.isDisplayed === true && (
          <CorporateBrands path="corporate-brands/*" />
        )}
      {!isMerchantOnline &&
        permission(API_PERMISSIONS.MERCHANT_VIEW_REFERRAL_LINK) && (
          <ReferralCodeListings
            path="referral-codes/*"
            dashboardType="merchants"
          />
        )}
      {!isMerchantOnline &&
        permission(API_PERMISSIONS.MERCHANT_CREATE_REFERRAL_LINK) && (
          <ReferralCodeForm
            path="referral-codes/new"
            dashboardType="merchants"
          />
        )}
      {!isMerchantOnline &&
        permission(API_PERMISSIONS.MERCHANT_UPDATE_REFERRAL_LINK) && (
          <ReferralCodeForm
            path="referral-codes/edit/:id"
            dashboardType="merchants"
          />
        )}
    </Router>
  )
}

export default Merchants
