import styled from "@emotion/styled"

export const StyledSliceSupportWin = styled.div`
  > div {
    text-align: center;
    padding: 4.875rem 0.875rem;
    margin-bottom: 3.125rem;
  }
  h3 {
    line-height: 2.1875rem;
    margin-bottom: 1.75rem;
  }

  p {
    font-size: 1.25rem;
    font-weight: ${(p) => p.theme.fontWeights.light};
    line-height: 2rem;
    max-width: ${(p) => p.theme.breakpoints.tablet};
    margin: auto;
    margin-bottom: 1.75rem;

    span {
      font-weight: ${(p) => p.theme.fontWeights.bold};
    }
  }
  button {
    min-width: 6.875rem;
    display: inline-block;
    &:last-of-type {
      margin-left: 1.25rem;
    }
  }
  @media only screen and (max-width: 800px) {
    text-align: center;
    margin-left: 0;
  }
`
