import React, { Fragment, useMemo } from "react"
import StoreDetailInfo from "./StoreDetailInfo/StoreDetailInfo"
import { useQuery } from "@apollo/client"
import { MERCHANT_DETAIL_QUERY } from "@tmu/apollo/storefront/queries/merchant"
import { Money, Spinner } from "@tmu/components/common"
import {
  StyledNotFoundMessageWrapper,
  StyledStoreDetailWrapper,
  StyledVisitStore,
} from "./index.styles"
import OfferListing from "@tmu/components/offers/OfferListing/customOfferListing"
import ServiceListing from "@tmu/components/services/ServiceListing"
import CorporateCampaignListing from "@tmu/components/corporate/CorporateCampaignListing"
import { useIntl, navigate, FormattedMessage, Link } from "gatsby-plugin-intl"
import { useAuth } from "@tmu/hooks"
import { OFFER_TYPE } from "@tmu/apollo/constants"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import SEO from "@tmu/components/seo"
import { useOfferLink } from "@tmu/src/hooks"

const StoreDetail = ({ slug, location }) => {
  const { locale, formatMessage } = useIntl()
  const { isAuthenticated } = useAuth()
  const { isTablet } = getAllScreenTypes()
  const { getOfferLink } = useOfferLink()

  const { data } = useQuery(MERCHANT_DETAIL_QUERY({ locale }), {
    variables: {
      slug,
    },
  })

  const merchant = useMemo(() => {
    return data?.merchant
  }, [data])

  const defaultOffer = merchant?.defaultOffer

  if (!merchant) return <Spinner condensed />

  const customNotFoundMessageTitle = formatMessage({
    id: "storeDetail::noOfferMessage::title",
    defaultMessage: "There are no PAC Offers at the moment.",
  })

  const customNotFoundMessageDescription = (
    <FormattedMessage
      id="storeDetail::noOfferMessage::description"
      defaultMessage="To use general PAC store discount of up to {discount}% <red>visit the store </red>"
      values={{
        discount: (
          <Money value={merchant?.maxPacDiscountInOffers} currency={false} />
        ),
        red: (...chunks) => (
          <StyledVisitStore onClick={handleExternalOffer}>
            {chunks.map((chunk, i) => (
              <Fragment key={i}>{chunk}</Fragment>
            ))}
          </StyledVisitStore>
        ),
      }}
    />
  )

  const customNotFoundMessageForOffer = (
    <StyledNotFoundMessageWrapper>
      <h6>{customNotFoundMessageTitle}</h6>
      {Number(merchant?.maxPacDiscountInOffers) > 0 && (
        <p>{customNotFoundMessageDescription}</p>
      )}
    </StyledNotFoundMessageWrapper>
  )

  const customNotFoundMessageForService = (
    <StyledNotFoundMessageWrapper className="custom-not-found-service">
      <div>
        <FormattedMessage
          id="storeDetail::noServiceMessage::title"
          defaultMessage="There are currently no services available from this merchant."
          tagName="span"
        />
      </div>
      <div className="explore-services">
        <Link
          to={`/offers/?offer=true&store=true`}
          data-testid="text-pac-store"
          aria-label="Pac Store">
          <FormattedMessage
            id="storeDetail::noServiceMessage::description"
            defaultMessage="Explore services offered by other merchants."
            tagName="span"
          />
        </Link>
      </div>
    </StyledNotFoundMessageWrapper>
  )

  const handleExternalOffer = () => {
    if (!isAuthenticated) {
      const redirectUrl = `/${locale}/offers/store/${slug}?offer=true`
      navigate(`/signin?&lang=${locale}&next=${redirectUrl}`)
    } else {
      getOfferLink({
        variables: { offerId: defaultOffer?.id },
        onCompleted: (offerData) => {
          if (offerData?.offerLinkWithToken?.offerLink) {
            window.open(offerData.offerLinkWithToken.offerLink, "_blank")
          }
        },
      })
    }
  }
  const isMerchantOffline = defaultOffer?.offerType === OFFER_TYPE.OFFLINE

  const isOfferInternal = defaultOffer?.offerType === OFFER_TYPE.INTERNAL

  const isCorporate = defaultOffer?.offerType === OFFER_TYPE.BRAND

  const handleViewAll = () => {
    navigate(
      `/offers/?merchantType=0&offer=true&store=true&stores=${merchant?.id}`
    )
  }

  const store = data?.merchant
  const descriptionToShare =
    defaultOffer?.shortDescription || defaultOffer?.description

  return (
    <>
      <SEO
        lang={locale}
        title={store?.name}
        description={descriptionToShare}
        image={store?.logo}
        pathname={location?.pathname}
      />
      <StyledStoreDetailWrapper>
        <StoreDetailInfo slug={slug} location={location} merchant={merchant} />
      </StyledStoreDetailWrapper>
      {!isMerchantOffline && !isOfferInternal && !isCorporate ? (
        <OfferListing
          isOffers={true}
          storeId={merchant?.id}
          customNotFoundMessage={customNotFoundMessageForOffer}
        />
      ) : null}
      {isOfferInternal ? (
        <div style={{ margin: !isTablet ? "0 1rem" : "" }}>
          <ServiceListing
            title={
              <FormattedMessage
                id="serviceListing::merchantName::title"
                defaultMessage="Services"
                tagName="h4"
              />
            }
            storeId={merchant?.id}
            handleViewAll={handleViewAll}
            hideDefaultOffer={true}
            customNotFoundMessage={customNotFoundMessageForService}
          />
        </div>
      ) : null}
      {isCorporate ? (
        <div style={{ margin: !isTablet ? "0 1rem" : "" }}>
          <CorporateCampaignListing
            title={
              <FormattedMessage
                id="corporateCampaignListing::merchantName::title"
                defaultMessage="{merchant} campaigns"
                values={{
                  merchant: merchant?.name,
                }}
              />
            }
            storeId={merchant?.id}
            customNotFoundMessage=" "
          />
        </div>
      ) : null}
    </>
  )
}

export default StoreDetail
