import React, { useEffect, useContext } from "react"
import { useQuery } from "@apollo/client"
import { useAuth, useToast, useLogoImage } from "@tmu/hooks"
import { injectIntl, navigate } from "gatsby-plugin-intl"
import SEO from "@tmu/components/seo"
import { HOMEPAGE_QUERY } from "@tmu/apollo/storefront/queries/home"
import { Spinner, ErrorBoundary } from "@tmu/components/common"
import queryString from "query-string"
import {
  StyledSliceWrapper,
  StyledSliceCorporateCampaignWrapper,
} from "@tmu/global/page-addons/homepage.styles"
import { HomePageContext } from "@tmu/context/homePageContext"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import {
  SliceCampaigns,
  SliceHowItWorks,
  SlicePartners,
  SliceRewardForGood,
  SliceHeroHome,
  SliceCounter,
  SliceCharityFriendlyBrand,
  SliceMainPartners,
  SliceOtherPartners,
} from "@tmu/components/home"

const defaultQueryVariable = 8

const IndexPage = ({ intl, location }) => {
  const { isLoading, verifyAccount } = useAuth()
  const { setHeaderConfigData } = useContext(HomePageContext)
  const { locale, formatMessage } = intl
  const { success: successToast, error: errorToast } = useToast()
  const { pathname, search } = location
  const params = queryString.parse(search)
  const { isTablet } = getAllScreenTypes()

  const rules = {
    emailVerification:
      params?.code === "success" &&
      params?.success === "true" &&
      params?.supportForgotPassword === "true" &&
      params?.supportSignUp === "true",
    redirectAfterPasswordChange:
      params?.success === "true" && params?.changePasswordSuccess === "true",
  }

  const logoImage = useLogoImage()

  const { loading, data } = useQuery(HOMEPAGE_QUERY({ locale }), {
    variables: {
      first: defaultQueryVariable,
      isPublic: true,
    },
  })

  useEffect(() => {
    setHeaderConfigData({ transparent: true, whiteText: true })
  }, [])

  useEffect(() => {
    if (isLoading) {
      return
    }

    if (params?.error)
      errorToast(
        formatMessage({
          id: "signup::auth::errorOccured",
          defaultMessage: "An error occured. Please contact TMU support!",
        })
      )
  }, [isLoading])

  useEffect(() => {
    if (isLoading) {
      return
    }

    if (params?.activateToken) {
      verifyAccount({
        token: params?.activateToken,
      }).then(({ data: { verifyAccount: result } }) => {
        if (result?.success) {
          successToast(
            formatMessage({
              id: "signup::auth::emailVerified",
              defaultMessage:
                "Your email is verified. You can continue using the application",
            })
          )

          setTimeout(() => {
            navigate(
              result?.redirectUrl?.replace("https://trustmeup.com", "") ||
                "/signin?next=/user-space"
            )
          }, 2000)
        }

        if (result?.errors) {
          result?.errors[0]?.message
            ? errorToast(result?.errors[0]?.message)
            : Object.entries(result?.errors).map((err) =>
                errorToast(err[1][0].message)
              )
        }
      })
    }
    if (rules.emailVerification && !pathname.startsWith(`/${locale}/`))
      successToast(
        formatMessage({
          id: "signup::auth::emailVerified",
          defaultMessage:
            "Your email is verified. You can continue using the application",
        })
      )
    if (rules.redirectAfterPasswordChange)
      successToast(
        formatMessage({
          id: "signup::auth::passwordReset",
          defaultMessage:
            "You can now login to the application with the new password",
        })
      )
  }, [])

  return (
    <>
      <SEO
        lang={locale}
        image={logoImage}
        description={formatMessage({
          id: "seo::indexPage::description",
          defaultMessage:
            "Donations and Online Shopping! TrustMeUp is the only international web platform that turns every Purchase into a Donation and Rewards every donation 100% into Discounts for Shopping.",
        })}
      />
      <div
        id="g_id_onload"
        data-client_id={process.env.GOOGLE_CLIENT_ID}
        data-login_uri="/"></div>
      <ErrorBoundary>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <SliceHeroHome />
            <StyledSliceWrapper>
              <SlicePartners charities={data?.allPartners?.edges ?? []} />
              <SliceRewardForGood />
              <SliceCampaigns
                campaigns={data?.featuredCampaigns?.edges ?? []}
              />
              <SliceCharityFriendlyBrand />
              {data?.corporateCampaigns?.edges?.length ? (
                <StyledSliceCorporateCampaignWrapper>
                  <SliceCampaigns
                    hideDetails={true}
                    className="corporate-campaigns"
                    title={formatMessage({
                      id: "homepage::campaigns::corporateCampaigns",
                      defaultMessage: "Social Impact brands that are with us",
                    })}
                    buttonText={
                      isTablet &&
                      formatMessage({
                        id: "homepage::campaigns::corporateCampaignsButtonText",
                        defaultMessage: "Social Impact Brands & Campaigns",
                      })
                    }
                    campaigns={data?.corporateCampaigns?.edges ?? []}
                    isCorporateStore
                  />
                </StyledSliceCorporateCampaignWrapper>
              ) : null}
              <SliceHowItWorks />
              <SliceCounter />
              <SliceMainPartners />
              <SliceOtherPartners />
            </StyledSliceWrapper>
          </>
        )}
      </ErrorBoundary>
    </>
  )
}

export default injectIntl(IndexPage)
