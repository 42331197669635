import React from "react"
import PropTypes from "prop-types"
import { StyledCheckboxContainer } from "./index.styles"

const CheckboxContainer = ({ children, ...rest }) => {
  return <StyledCheckboxContainer {...rest}>{children}</StyledCheckboxContainer>
}

CheckboxContainer.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
}

CheckboxContainer.defaultProps = {
  children: "",
  className: "",
}

export default CheckboxContainer
