import React from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby-plugin-intl"
import { LazyImage, Money } from "@tmu/components/common"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { StyledCorporateCompaniesTableRow } from "./index.styles"

const CorporateCompaniesTableRow = ({
  style,
  index,
  id,
  slug,
  name,
  logo,
  donationCountForDisplay,
  totalSocialImpactForDisplay,
  totalDonationCount,
}) => {
  const { isTablet, isWide } = getAllScreenTypes()

  const onClick = () => {
    navigate(`/offers/store/${slug}`)
  }

  const logoSize = {
    width: isWide ? 240 : 160,
    height: isWide ? 135 : 90,
  }

  return (
    <StyledCorporateCompaniesTableRow
      key={id}
      style={style}
      data-testid={"corporate-companies-table-row" + index}
      onClick={onClick}>
      <div className="row row-center row-primary">{index}</div>
      {isTablet && (
        <div>
          <LazyImage
            src={logo}
            altName={`corporate-companies-logo`}
            {...logoSize}
            fit="cover"
          />
        </div>
      )}
      <div className="row row-left row-blue">{name}</div>
      <div className="row row-right row-primary">
        {totalDonationCount ?? donationCountForDisplay}
      </div>
      <div className="row row-right row-primary">
        <Money value={Number(totalSocialImpactForDisplay)} />
      </div>
    </StyledCorporateCompaniesTableRow>
  )
}

CorporateCompaniesTableRow.propTypes = {
  id: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  campaignCountForDisplay: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  donationCountForDisplay: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  totalFundedAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  totalPacDiscountAmount: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  totalDonationCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
}

export default CorporateCompaniesTableRow
