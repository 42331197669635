import styled from "@emotion/styled"

export const StyledSpinner = styled.div`
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
  grid-area: spinner;

  color: ${(p) => p.theme.colors.mortar};
  font-size: 0.625rem;
  margin: 40vh auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;

  &:before,
  &:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: load7 1.8s infinite ease-in-out;

    content: "";
    position: absolute;
    top: 0;
  }

  &:before {
    left: -3.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  &:after {
    left: 3.5em;
  }
  @-webkit-keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
  @keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
`

export const StyledCondensedSpinner = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: 8rem;
  padding-bottom: 8rem;
  box-sizing: border-box;

  .spinner-condensed-container {
    width: 3.125rem;
    height: 3.125rem;
    display: inline-block;
    overflow: hidden;
    background: none;
    margin: 0 auto;
  }

  .spinner-condensed {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(0.5);
    backface-visibility: hidden;
    transform-origin: 0 0;

    div {
      position: absolute;
      animation: spinner-condensed 1s linear infinite;
      width: 5rem;
      height: 5rem;
      top: 0.625rem;
      left: 0.625rem;
      border-radius: 50%;
      box-shadow: 0 0.125rem 0 0 ${(p) => p.theme.colors.nepal};
      transform-origin: 2.5rem 2.5625rem;
      box-sizing: content-box;
    }
  }

  @keyframes spinner-condensed {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
