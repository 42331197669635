import styled from "@emotion/styled"

export const StyledLabel = styled.label`
  display: block;
  position: relative;
  padding-left: 2.1875rem;
  // margin-bottom: 0.75rem;
  cursor: pointer;
  user-select: none;
  width: fit-content;

  &.disabled {
    color: ${(p) => p.theme.colors.disabled};
  }

  input:disabled {
    color: ${(p) => p.theme.colors.disabled};
    background-color: ${(p) => p.theme.colors.disabled};
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    height: 1.25rem;
    width: 1.25rem;
    opacity: 0.5;
    border-radius: 0.25rem;
    background-color: ${(p) => p.theme.colors.white};
    border: 0.0625rem solid ${(p) => p.theme.colors.disabled};
  }

  &:hover input ~ .checkmark {
    border: 0.0625rem solid ${(p) => p.theme.colors.blue};
  }

  input:checked ~ .checkmark {
    background-color: ${(p) => p.theme.colors.blue};
    border: 0.0625rem solid ${(p) => p.theme.colors.blue};
    opacity: 1;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 0.325rem;
    top: 0.04rem;
    width: 0.3rem;
    height: 0.625rem;
    border: solid ${(p) => p.theme.colors.white};
    border-width: 0 0.1875rem 0.1875rem 0;
    transform: rotate(40deg);
    opacity: 1;
    background: ${(p) => p.theme.colors.blue};
  }

  &.profile-form {
    margin-bottom: unset;
  }
`

export const StyledInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ span {
    background-color: transparent;
    .fa-check {
      display: block;
    }
  }
`

export const StyledCheckmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 1.5625rem;
  width: 1.5625rem;
  border-radius: 0.1875rem;
  border: 0.0625rem solid ${(p) => p.theme.colors.formFieldsBorderColor};
  background-color: transparent;
`
