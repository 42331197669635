import styled from "@emotion/styled"

export const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;

  p {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-style: ${(p) => p.theme.fontStyles.normal};
    text-align: center;
    font-size: 1.5rem;
    line-height: 2.125rem;
  }

  span {
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    font-style: ${(p) => p.theme.fontStyles.normal};
    text-align: center;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-top: 1rem;
  }

  &.mobile {
    height: 80%;

    .full-width {
      div {
        width: 20rem;
        margin-bottom: unset;
      }
    }
  }

  &.not-mobile {
    span {
      margin-bottom: 2rem;
    }

    .full-width {
      div {
        width: 86vw;
        margin-bottom: unset;
      }
    }
  }
`

export const StyledModalButton = styled.div`
  button {
    width: 100%;
  }

  &.mobile {
    position: absolute;
    margin-left: 0;
    margin-right: 0;
    bottom: 1rem;
    width: 86vw;
    white-space: normal;
  }
`
