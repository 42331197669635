import { useContext, useEffect } from "react"
import { useLazyQuery, gql } from "@apollo/client"
import { useApolloApiClients } from "@tmu/apollo/client"
import { useAuth } from "@tmu/hooks"
import { HomePageContext } from "../context/homePageContext"

const usePACBalance = () => {
  const { isAuthenticated, isLoading, user } = useAuth()
  const { storefrontClient } = useApolloApiClients()
  const contextData = useContext(HomePageContext)

  const [callPacBalance, { data, called, refetch }] = useLazyQuery(
    gql`
      query PacBalance {
        me {
          id
          pacBalance
        }
      }
    `,
    {
      client: storefrontClient,
    }
  )

  const pacBalance = data?.me?.pacBalance

  useEffect(() => {
    if (pacBalance) contextData.setGlobalPacBalance(pacBalance)
  }, [data, isLoading, isAuthenticated, pacBalance])

  useEffect(() => {
    if (!called && user?.id) {
      callPacBalance()
    }
  }, [user, isLoading, isAuthenticated])

  return {
    pacBalance,
    callPacBalance,
    refetchPacBalance: refetch,
    called,
  }
}

export default usePACBalance
