import styled from "@emotion/styled"
import {
  StyledCampaignsWrapper,
  StyledCampaignsBody,
  StyledContent as StyledCampaignContent,
  NoResults as CampaignsNoResults,
} from "@tmu/components/campaigns/CampaignListing/index.styles"

export const StyledWrapper = styled((props) => (
  <StyledCampaignsWrapper {...props} />
))`
  margin: unset;
  background: ${(p) => p.theme.colors.white};

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    background: none;
  }
`

export const StyledServicesBody = styled((props) => (
  <StyledCampaignsBody {...props} />
))`
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 2rem 0 3.375rem !important;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding: 3rem 0 5rem !important;
  }

  > div {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      grid-template-columns: 14.25rem auto;
      grid-gap: 1.5rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      grid-template-columns: 24.375rem auto;
      grid-gap: 3rem;
    }
  }

  margin: 0 -1rem;
  padding-bottom: unset;
  background: ${(p) => p.theme.colors.white};

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin: unset;
    background: ${(p) => p.theme.colors.whiteSmoke};
  }
`

export const StyledContent = styled((props) => (
  <StyledCampaignContent {...props} />
))`
  width: 100%;
  background: ${(p) => p.theme.colors.whiteSmoke};
  padding-top: 0.75rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    background: none;
    padding-top: unset;
  }
`

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: unset !important;
  margin-right: 1rem;
  padding-bottom: 0.75rem;
  background: ${(p) => p.theme.colors.white};

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    background: none;
    padding-bottom: unset;
  }

  button {
    p {
      span {
        font-family: ${(p) => p.theme.fontFamilies.headline};
        font-style: ${(p) => p.theme.fontStyles.normal};
        font-weight: ${(p) => p.theme.fontWeights.semiBolder};
        color: ${(p) => p.theme.colors.carrot};

        font-size: 1.125rem;
        line-height: 1.75rem;
      }

      svg {
        display: none;
      }
    }
  }
`

export const StyledTitle = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.pitchBlack};

  font-size: 1.125rem;
  line-height: 1.75rem;
  margin-left: 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1.5rem;
    line-height: 2.125rem;
    margin-left: unset;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 3rem;
    line-height: normal;
  }
`

export const StyledListing = styled.div`
  max-width: ${(p) => p.theme.breakpoints.wide};
  justify-content: center;
  text-align: center;
`

export const StyledActions = styled.div`
  text-align: center;
  display: block;
  align-self: center;
  justify-self: center;

  div {
    margin: 0;
  }
`

export const NoResults = styled((props) => <CampaignsNoResults {...props} />)`
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin: 0;
  }
  margin: 0 1rem;
`
