import React, { Fragment } from "react"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { useLocation } from "@reach/router"
import { useAuth } from "@tmu/hooks"
import { StyledMissingAddressWrapper, StyledLink } from "./index.styles"

const MissingAddress = () => {
  const { locale } = useIntl()
  const { pathname } = useLocation()
  const returnTo = pathname?.replace(`/${locale}`, "")
  const { needsToUpdateAccount, needsToUpdateTaxId, isAuthenticated } =
    useAuth()

  const isAccountPath = pathname === `/${locale}/dashboard/donors/account`

  function renderMessageChunks(chunks) {
    return (
      <p className="text-message">
        {chunks.map((chunk, i) => (
          <Fragment key={i}>{chunk}</Fragment>
        ))}
      </p>
    )
  }

  const accountUpdate = needsToUpdateTaxId || needsToUpdateAccount

  return accountUpdate && isAuthenticated ? (
    <StyledMissingAddressWrapper>
      {isAccountPath ? (
        <FormattedMessage
          id="checkout::missingAccountInfoSimple::account"
          defaultMessage="Please update your account information.">
          {(...args) => renderMessageChunks(args)}
        </FormattedMessage>
      ) : (
        <FormattedMessage
          id="checkout::missingAccountInfo::account2"
          defaultMessage="Please update your <account>account information</account>."
          values={{
            account: (...chunks) => (
              <StyledLink
                key="update-profile"
                to={`/dashboard/donors/account?next=${returnTo}`}>
                {chunks.map((chunk, i) => (
                  <Fragment key={i}>{chunk}</Fragment>
                ))}
              </StyledLink>
            ),
          }}>
          {(...args) => renderMessageChunks(args)}
        </FormattedMessage>
      )}
    </StyledMissingAddressWrapper>
  ) : null
}
export default MissingAddress
