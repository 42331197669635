import React from "react"
import PropTypes from "prop-types"
import { LazyImage } from "@tmu/components/common"
import { ProgressBar } from "@tmu/components/common"
import {
  StyledCampaigns,
  StyledPartnerContainer,
  StyledCampaignDetail,
  StyledImageContainer,
} from "../index.styles"

const Campaigns = ({
  name,
  goalAmount,
  fund,
  img,
  slug,
  onClick,
  imgWidth,
  imgHeight,
  partner,
  ...rest
}) => {
  return (
    <StyledCampaigns
      to={`/campaigns/${slug}`}
      data-testid="typeahead-campaign"
      className="common-typeahead"
      onClick={onClick}>
      <StyledImageContainer>
        <LazyImage
          src={img}
          altName={`campaign-image-${name}`}
          width={imgWidth || 320}
          height={imgHeight || 240}
          fit="cover"
        />
      </StyledImageContainer>
      <StyledCampaignDetail className="typeahead-item">
        <div>{name}</div>
        <StyledPartnerContainer className="typeahead-item">
          <StyledImageContainer>
            <LazyImage
              src={partner?.logo}
              altName={`charity-image-${partner?.name}`}
              width={rest?.smallImageWidth || 40}
              height={rest?.smallImageHeight || 22}
              fit="cover"
            />
          </StyledImageContainer>
          <div className="typeahead-item-small">{partner?.name}</div>
        </StyledPartnerContainer>
      </StyledCampaignDetail>
    </StyledCampaigns>
  )
}

Campaigns.propTypes = {
  name: PropTypes.string.isRequired,
  fund: PropTypes.number.isRequired,
  goalAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

Campaigns.defaultProps = {
  name: "",
  fund: 0,
  goalAmount: 0,
}

export default Campaigns
