import styled from "@emotion/styled"

export const StyledSliceWhoWeServe = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4.375rem 0.875rem 1.375rem;
  margin-bottom: 5rem;
  margin: auto;
`

export const StyledWwsHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 1.875rem;
  max-width: 76rem;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    align-items: center;
  }
`

export const StyledWwsTitle = styled.h3`
  text-align: center;
  @media only screen and (max-width: 1080px) {
    text-align: center;
    margin-left: 0;
  }
`

export const StyledWwsTabContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  @media only screen and (max-width: 450px) {
    height: auto;
    display: flex;
    flex-direction: column;
  }
`

export const StyledWwsText = styled.p`
  padding-top: 1.5625rem;
  max-width: 43.125rem;
  margin: 0 auto;
  color: ${(p) => p.theme.colors.disabled};
  font-size: 1.125rem;
  line-height: 1.8125rem;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    text-align: center;
  }
`
export const StyledTab = styled.div`
  .react-tabs__tab-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .react-tabs__tab {
    background: none;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${(p) => p.theme.colors.shuttleflGray};
    color: rgb(99, 106, 117);
    font-weight: ${(p) => p.theme.fontWeights.bold};
    font-size: 1.125rem;
    margin: 0 2.8125rem 0 0;
    padding: 0 1.125rem 0.625rem;
    gap: 0.625rem;
    opacity: 1;

    i {
      font-size: 1.875rem;
      padding-top: 0.625rem;
    }

    &:hover {
      color: ${(p) => p.theme.colors.pacificBlue};
      cursor: pointer;
    }
  }

  .react-tabs__tab--selected {
    color: ${(p) => p.theme.colors.pacificBlue};
    border-bottom: 0.1875rem solid ${(p) => p.theme.colors.pacificBlue};
  }
`

export const StyledWwsTabContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  padding: 0 0.875rem;
  margin-bottom: 3.125rem;

  .wws-tab-content__icon {
    height: 3rem;
    width: 3.75rem;
    color: ${(p) => p.theme.colors.gondola};
    font-size: 3rem;
    line-height: 3rem;
    margin-bottom: 1.5625rem;
  }
  .wws-tab-content__title {
    color: ${(p) => p.theme.colors.gondola};
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-weight: ${(p) => p.theme.fontWeights.bold};
    font-size: 1.5rem;
    line-height: 1.875rem;
    margin-bottom: 1.5625rem;
    text-align: center;
  }
  .wws-tab-content__text {
    display: -webkit-box;
    -webkit-line-clamp: 12;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    min-height: 5.5rem;
    max-width: ${(p) => p.theme.breakpoints.phone};
    padding: 0.3125rem;
    color: ${(p) => p.theme.colors.gondola};
    font-size: 1.125rem;
    line-height: 1.75rem;
    margin-bottom: 1.5625rem;
    span {
      font-weight: ${(p) => p.theme.fontWeights.bold};
    }
  }
  .wws-tab-content__button {
    margin-bottom: 1rem;
  }

  @media only screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
    flex-wrap: wrap;
  }
`

export const StyledWwsTabContentLeft = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  padding: 0.875rem;
  @media only screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
    align-items: center;
    .wws-tab-content__text {
      text-align: center;
    }
  }
`

export const StyledWwsTabContentRight = styled.div`
  display: grid;
  grid-template-areas: "big big small" "text medium medium";
  grid-gap: 1.75rem;
  grid-template-columns: minmax(0, 1fr) minmax(0, 0.2fr) minmax(0, 0.6fr);
  max-width: ${(p) => p.theme.breakpoints.tablet};

  .wws-tab-content__image--big {
    grid-area: big;
    align-self: end;
    width: 100%;
    img {
      border-radius: 0.3125rem;
    }
  }
  .wws-tab-content__image--small {
    grid-area: small;
    align-self: end;
    justify-self: right;
    width: 100%;
    margin-bottom: 2rem;
    img {
      border-radius: 0.3125rem;
    }
    margin-right: -0.3rem;
  }
  .wws-tab-content__image--medium {
    grid-area: medium;
    justify-self: right;
    width: 100%;
    margin-top: 2.5rem;

    img {
      border-radius: 0.3125rem;
    }
  }
  .wws-tab-content__image--text {
    grid-area: text;
    align-self: center;
    opacity: 0.5;
    color: ${(p) => p.theme.colors.gondola};
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
  }

  @media only screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: block;
    font-size: 0.875rem;
    width: 100%;
    padding: 1rem;
    .wws-tab-content__image--small,
    .wws-tab-content__image--medium {
      display: none;
    }
    .wws-tab-content__image--big {
      width: 100%;
      text-align: center;
      img {
        width: 100%;
        max-width: 22rem;
        height: auto;
      }
    }
    .wws-tab-content__image--text {
      width: 100%;
      padding: 1rem;
      max-width: unset;
    }
  }
`

export const StyledWwsContent = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    flex-direction: column;
  }
`

export const StyledWwsContentText = styled.p`
  max-width: 36.25rem;
  font-size: 1.375rem;
  color: ${(p) => p.theme.colors.shuttleflGray};
  line-height: 1.5;
  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1rem;
    text-align: center;
  }
`
export const StyledWwsContentSubtext = styled.p`
  font-size: 1.125rem;
  line-height: 1.5;
  color: ${(p) => p.theme.colors.grayApprox};
  max-width: 31.1875rem;
  margin-top: 1.25rem;
  margin-bottom: 1.875rem;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 0.875rem;
    text-align: center;
  }
`

export const StyledWwsContentLeft = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  padding-top: 2.5rem;
  @media only screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
    align-items: center;
    .wws-tab-content__text {
      text-align: center;
    }
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding-top: 0;
  }
`

export const StyledWwsImagesRight = styled.div`
  display: grid;
  grid-template-columns: 0.6fr 1.4fr;
  grid-template-rows: 1fr 1fr;
  gap: 1.25rem;
  grid-template-areas:
    "small big"
    ". medium";
  grid-area: images;
  padding-top: 2.5rem;

  img {
    border-radius: 0.3125rem;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: flex;
    flex-direction: column;
  }
`
export const StyledWwsImageBig = styled.div`
  grid-area: big;
`
export const StyledWwsImageMedium = styled.div`
  grid-area: medium;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    text-align: center;
  }
`
export const StyledWwsImageSmall = styled.div`
  grid-area: small;
  text-align: right;
  margin-top: 4.375rem;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-top: auto;
    text-align: center;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-top: 2rem;
  }
`
