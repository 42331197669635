import React, { useState } from "react"
import { gql, useMutation } from "@apollo/client"
import { useApolloApiClients } from "@tmu/apollo/client"
import { Button, FieldError, TextInput } from "@tmu/components/common"
import { StyledModalActions } from "@tmu/global/page-addons/dashboard.styles"
import { developersName } from "@tmu/utils/validation"
import { Field, Formik } from "formik"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import pass from "secure-random-password"
import { useToast } from "@tmu/hooks"
import * as Yup from "yup"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { StyledForm } from "./index.styles"

const AddDeveloperForm = ({
  developerId,
  developerName,
  onCancel,
  onSuccess,
  checkUserExist,
}) => {
  const { isTablet } = getAllScreenTypes()

  const CREATE_USER_MUTATION = gql`
    mutation ($input: CreateMerchantAPIUserMutationInput!) {
      createMerchantApiUser(input: $input) {
        errors {
          field
          messages
        }
      }
    }
  `

  const EDIT_NAME_MUTATION = gql`
    mutation ($input: UpdateMerchantAPIUserMutationInput!) {
      updateMerchantApiUser(input: $input) {
        apiUser {
          id
          name
          clientId
        }
        errors {
          field
          messages
        }
      }
    }
  `

  const { merchantClient } = useApolloApiClients()

  const [createUserMutation] = useMutation(CREATE_USER_MUTATION, {
    client: merchantClient,
  })

  const [editNameMutation] = useMutation(EDIT_NAME_MUTATION, {
    client: merchantClient,
  })

  const [userExistError, setUserExistError] = useState(false)
  const { error } = useToast()
  const initialValues = {
    name: developerName,
  }
  const { formatMessage } = useIntl()
  const validationSchema = Yup.object().shape({
    name: developersName({ formatMessage }),
  })

  const handleSubmit = async (values) => {
    if (developerId === undefined) {
      const passData = pass.randomPassword({
        length: 16,
        characters: [pass.lower, pass.upper, pass.digits],
      })

      createUser(values?.name, passData)
    } else {
      onCancel()
      editName(developerId, values?.name)
    }
  }

  const createUser = async (name, password) => {
    await createUserMutation({
      variables: {
        input: {
          name,
          password,
        },
      },
    })
      .then(() => {
        if (onSuccess) {
          onSuccess()
        }
      })
      .catch((err) => error(err.description))
  }

  const editName = async (id, nameStr) => {
    await editNameMutation({
      variables: {
        input: {
          name: nameStr,
          id,
        },
      },
    })
      .then(() => callAllApiUsers())
      .catch((err) => error(err.description))
  }

  const handleChangeData = (e) => {
    if (checkUserExist) {
      const result = checkUserExist(e?.target?.value)
      setUserExistError(result)
    }
  }

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}>
      {({ values, handleChange, handleBlur, errors, isSubmitting }) => {
        return (
          <>
            <StyledForm>
              <FormattedMessage
                id="dashboard::developers::addNewDeveloper"
                defaultMessage="Name new credentials"
                tagName="h6"
              />
              <Field
                data-testid="input-name"
                id="name"
                name="name"
                component={TextInput}
                className="float-container full-width"
                value={values?.name || ""}
                onChange={(e) => {
                  handleChange(e)
                  handleChangeData(e)
                }}
                onBlur={handleBlur}
              />
              <FieldError name="name" />
              {userExistError && (
                <span className="input-feedback" data-testid="input-feedback">
                  <FormattedMessage
                    id="dashboard::users::userExist"
                    defaultMessage="User already exists"
                  />
                </span>
              )}
              <StyledModalActions>
                <Button
                  data-testid={
                    developerId === undefined ? "btn-add" : "btn-update"
                  }
                  type="submit"
                  label={developerId === undefined ? "Add" : "Update"}
                  className={
                    isTablet
                      ? "not-mobile-btn-developer"
                      : "mobile-btn-developer"
                  }
                  disabled={isSubmitting || errors.name || userExistError}
                  color="blue">
                  {developerId === undefined ? (
                    <FormattedMessage
                      id="dashboard::developers::addCredentials"
                      defaultMessage="Add credentials"
                    />
                  ) : (
                    <FormattedMessage
                      id="dashboard::accountForm::save"
                      defaultMessage="Save"
                    />
                  )}
                </Button>
              </StyledModalActions>
            </StyledForm>
          </>
        )
      }}
    </Formik>
  )
}

export default AddDeveloperForm
