import { useLazyQuery, useQuery } from "@apollo/client"
import React, { useEffect, useState } from "react"
import { FormattedMessage, useIntl, navigate } from "gatsby-plugin-intl"
import { faPlus } from "@fortawesome/pro-solid-svg-icons/faPlus"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  CAMPAIGNS_TABS_MENU,
  CARDS_PER_PAGE,
  LIST_TYPES,
  OFFER_TYPE,
} from "@tmu/apollo/constants"
import {
  CAMPAIGN_LISTING_QUERY,
  CAMPAIGN_TOTAL_COUNT_QUERY,
} from "@tmu/apollo/storefront/queries/campaign"
import {
  Button,
  NewCard,
  Spinner,
  Tab,
  TabList,
  Tabs,
  VoucherChecker,
} from "@tmu/components/common"
import { SliceCampaigns } from "@tmu/components/home"
import { useAuth } from "@tmu/hooks"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { getDateWithZeroHours, getValueForLocale } from "@tmu/utils/string"
import { addDays } from "date-fns"
import {
  StyledContent,
  StyledMainContainer,
  StyledMobileContainer,
  StyledNonMobileContainer,
  StyledSupporterCampaignsCard,
  StyledSupporterCampaignsList,
  StyledTitle,
} from "./index.styles"

const SupporterCampaigns = () => {
  const { locale, defaultLocale, formatMessage } = useIntl()
  const { isLoading, user } = useAuth()
  const [tabIndex, setTabIndex] = useState(0)
  const { isTablet } = getAllScreenTypes()

  const tabMenu = CAMPAIGNS_TABS_MENU(formatMessage)

  const { data: totalCountData } = useQuery(CAMPAIGN_TOTAL_COUNT_QUERY, {
    variables: {
      user: user.id,
      orderBy: "-created",
    },
    fetchPolicy: "network-only",
  })

  const hasAllCampaigns = totalCountData?.allCampaigns?.totalCount > 0

  const [callCampaigns, { loading, data }] = useLazyQuery(
    CAMPAIGN_LISTING_QUERY,
    {
      fetchPolicy: "network-only",
    }
  )

  const campaigns = data?.allCampaigns?.edges

  const hasCampaigns = campaigns?.length > 0

  useEffect(() => {
    if (!user?.id || user?.id.length === 0 || isLoading) {
      return
    }
    let vars = {}

    if (tabIndex === 0) {
      vars["startsAt_Lte"] = getDateWithZeroHours(addDays(new Date(), 1))
      vars["visibilityStatus"] = ["1", "2", "3"]
      vars["status_In"] = ["APPROVED"]
    } else if (tabIndex === 1) {
      vars["startsAt_Gt"] = getDateWithZeroHours(addDays(new Date(), 1))
      vars["status_In"] = ["APPROVED"]
    } else if (tabIndex === 2) {
      vars["status_In"] = ["ENDED"]
    } else {
      vars = {}
    }

    callCampaigns({
      variables: {
        first: CARDS_PER_PAGE,
        user: user.id,
        orderBy: "-created",
        ...vars,
      },
    })
  }, [tabIndex])

  const tabs = tabMenu

  const handleCreateCampaign = () => {
    navigate("/dashboard/campaign?supporter=true")
  }

  return (
    <StyledMainContainer>
      <div className="top-bar">
        <FormattedMessage
          id={isTablet ? "dashboard::nav::campaignsLink" : " "}
          defaultMessage={isTablet ? "Campaigns" : " "}
          tagName={isTablet ? "h1" : "h4"}
        />
        <VoucherChecker isCampaign={true} />
        {isTablet && (
          <Button
            data-testid="btn-create-campaign"
            variant="text"
            onClick={handleCreateCampaign}
            type="button">
            <FontAwesomeIcon icon={faPlus} />
            <FormattedMessage
              id="supporter::campaigns::button"
              defaultMessage="Create a campaign"
            />
          </Button>
        )}
      </div>

      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList>
          {tabs.map(({ key, label, dataTestId }) => (
            <Tab key={key} data-testid={"test-id-" + dataTestId}>
              {label}
            </Tab>
          ))}
        </TabList>
      </Tabs>

      {!isTablet && (
        <StyledMobileContainer>
          {!data ? (
            <Spinner condensed />
          ) : (
            <>
              {!hasCampaigns && !hasAllCampaigns ? (
                <>
                  <StyledTitle>
                    <FormattedMessage
                      id="supporter::campaigns::title1"
                      defaultMessage="Create your first campaign"
                    />
                  </StyledTitle>
                  <StyledContent>
                    <FormattedMessage
                      id="supporter::campaigns::content1"
                      defaultMessage="Click to choose a charity you want to support"
                    />
                  </StyledContent>
                </>
              ) : tabIndex === 0 && !hasCampaigns ? (
                <>
                  <StyledTitle>
                    <FormattedMessage
                      id="supporter::campaigns::title2"
                      defaultMessage="You don't have active campaigns"
                    />
                  </StyledTitle>
                  <StyledContent>
                    <FormattedMessage
                      id="supporter::campaigns::content1"
                      defaultMessage="Click to choose a charity you want to support"
                    />
                  </StyledContent>
                </>
              ) : tabIndex === 1 && !hasCampaigns ? (
                <StyledContent className="mobile-message-content">
                  <FormattedMessage
                    id="supporter::campaigns::content3"
                    defaultMessage="You don't have upcoming campaigns"
                  />
                </StyledContent>
              ) : tabIndex === 2 && !hasCampaigns ? (
                <StyledContent className="mobile-message-content">
                  <FormattedMessage
                    id="supporter::campaigns::content2"
                    defaultMessage="You don't have finished campaigns."
                  />
                </StyledContent>
              ) : loading ? (
                <Spinner condensed />
              ) : (
                <StyledSupporterCampaignsList>
                  {campaigns?.map(({ node: campaign }) => {
                    return (
                      <NewCard
                        variant="campaign"
                        key={campaign?.id}
                        imageSrc={
                          campaign?.image?.url ||
                          campaign?.images?.edges?.[0]?.node?.image
                        }
                        title={getValueForLocale(
                          campaign,
                          "name",
                          locale,
                          defaultLocale
                        )}
                        type={campaign?.campaignType}
                        totalDonors={campaign?.donationCount}
                        totalSupport={campaign?.fundedAmount}
                        size={LIST_TYPES.LIST}
                        cardLink={`/campaigns/${campaign?.slug}`}
                        goalAmount={campaign?.goalAmount}
                        progress={campaign?.progress}
                        fundedAmount={campaign?.fundedAmount}
                        partnerLogo={getValueForLocale(
                          campaign?.partner,
                          "logo",
                          locale,
                          defaultLocale
                        )}
                        partnerName={
                          campaign?.partner?.displayName ||
                          campaign?.partner?.name
                        }
                        taxId={campaign?.partner?.taxId}
                        pacMultiplier={campaign?.pacMultiplier}
                        isCorporate={
                          campaign?.store?.defaultOffer?.offerType ===
                          OFFER_TYPE.BRAND
                        }
                      />
                    )
                  })}
                </StyledSupporterCampaignsList>
              )}
              <div className="sticky-bottom-bar">
                <Button
                  data-testid="btn-create-campaign"
                  color="transparent"
                  type="button"
                  onClick={handleCreateCampaign}>
                  <FontAwesomeIcon icon={faPlus} />
                  <FormattedMessage
                    id="supporter::campaigns::button"
                    defaultMessage="Create a campaign"
                  />
                </Button>
              </div>
            </>
          )}
        </StyledMobileContainer>
      )}

      {isTablet && (
        <StyledNonMobileContainer>
          {!data ? (
            <Spinner condensed />
          ) : !hasCampaigns && !hasAllCampaigns ? (
            <StyledSupporterCampaignsCard onClick={handleCreateCampaign}>
              <FontAwesomeIcon icon={faPlus} />
              <StyledTitle>
                <FormattedMessage
                  id="supporter::campaigns::title1"
                  defaultMessage="Create your first campaign"
                />
              </StyledTitle>
              <StyledContent>
                <FormattedMessage
                  id="supporter::campaigns::content1"
                  defaultMessage="Click to choose a charity you want to support"
                />
              </StyledContent>
            </StyledSupporterCampaignsCard>
          ) : tabIndex === 0 && !hasCampaigns ? (
            <StyledSupporterCampaignsCard onClick={handleCreateCampaign}>
              <FontAwesomeIcon icon={faPlus} />
              <StyledTitle>
                <FormattedMessage
                  id="supporter::campaigns::title2"
                  defaultMessage="You don't have active campaigns"
                />
              </StyledTitle>
              <StyledContent>
                <FormattedMessage
                  id="supporter::campaigns::content1"
                  defaultMessage="Click to choose a charity you want to support"
                />
              </StyledContent>
            </StyledSupporterCampaignsCard>
          ) : tabIndex === 1 && !hasCampaigns ? (
            <StyledContent className="non-mobile-message-content">
              <FormattedMessage
                id="supporter::campaigns::content3"
                defaultMessage="You don't have upcoming campaigns"
              />
            </StyledContent>
          ) : tabIndex === 2 && !hasCampaigns ? (
            <StyledContent className="non-mobile-message-content">
              <FormattedMessage
                id="supporter::campaigns::content2"
                defaultMessage="You don't have finished campaigns."
              />
            </StyledContent>
          ) : loading ? (
            <Spinner condensed />
          ) : (
            <SliceCampaigns headline=" " campaigns={campaigns} />
          )}
        </StyledNonMobileContainer>
      )}
    </StyledMainContainer>
  )
}

export default SupporterCampaigns
