import styled from "@emotion/styled"
import { Tab } from "@tmu/components/common"

export const StyledTab = styled(Tab)`
  display: inline-flex;
  align-items: center;
  margin-right: 1rem;
  margin-bottom: 2rem;
  > div {
    min-height: 1.25rem;
  }
  .errorButton {
    color: ${(p) => p.theme.colors.lightRed};
  }
  &.react-tabs__tab {
    opacity: 1;
  }

  button {
    color: ${(p) => p.theme.colors.primaryBrandTextColor};
    opacity: 0.4;
    outline: none;
    cursor: pointer;
    padding: 0.875rem 1rem;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    font-size: 1.125rem;
    background: none;
    border: none;
    border-bottom: 0.125rem solid ${(p) => p.theme.colors.white};
    font-weight: ${(p) => p.theme.fontWeights.bold};
    svg {
      margin-right: 1.25rem;
    }
    &.active {
      border-bottom: 0.125rem solid
        ${(p) => p.theme.colors.primaryBrandTextColor};
      opacity: 1;
    }
  }
  button:last-of-type {
    margin: 0;
  }
  @media only screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin-right: 0;
  }
`
