import styled from "@emotion/styled"

export const StyledHero = styled.div`
  min-height: 45vh;

  .slick-arrow {
    display: none !important;
  }
`

export const StyledSlickSlider = styled.div`
  margin-bottom: 4.6875rem;
  overflow: hidden;
`

export const StyledHeroPage = styled.div`
  display: flex !important;
  color: ${(p) => p.theme.colors.white};
  position: relative;
  padding: 0;

  .bg-image {
    position: absolute;
    width: 100%;
    min-height: 100%;

    img {
      width: 100% !important;
      border-radius: 0 !important;
    }
    @media screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
      .media-box {
        position: static;
      }
    }
  }
`
export const StyledTopShadow = styled.div`
  opacity: 0.5;
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 16rem;
  z-index: 1000;
`

export const StyledOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 150%;
  z-index: 900;
`
export const StyledPartial = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: start;
  justify-content: center;
  max-width: ${(p) => p.theme.breakpoints.wide};
  width: 100%;
  min-height: 45vh;
  z-index: 1200;
  position: relative;
  margin: 8.4375rem auto 2.375rem auto;
  box-sizing: content-box;
  padding: 0 0.875rem;

  .hero--button {
    font-size: 1rem;
    font-weight: ${(p) => p.theme.fontWeights.bold};
    border: none;
    margin: 1.875rem 0 0;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 12%;
    margin-bottom: 5%;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.phone}) {
    margin-top: 40%;
  }
`

export const StyledHeroTitle = styled.h2`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-size: 3.75rem;
  color: ${(p) => p.theme.colors.white};
  margin: 0;
  max-width: 60vw;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;

  p {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-size: 3.75rem;
    color: ${(p) => p.theme.colors.white};
    margin: 0;
    max-width: 60vw;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  b {
    font-family: ${(p) => p.theme.fontWeights.medium};
  }

  b,
  svg {
    color: rgba(255, 255, 255, 0);
    stroke: white;
    -webkit-text-stroke-width: 0.0625rem;
    -webkit-text-stroke-color: white;
  }

  @media only screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 2rem;
    max-width: 100%;

    p {
      max-width: 100%;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
`

export const StyledHeroText = styled.div`
  font-size: 1.2875rem;
  line-height: 2rem;
  max-width: ${(p) => p.theme.breakpoints.tablet};

  * {
    color: ${(p) => p.theme.colors.white};
    font-weight: ${(p) => p.theme.fontWeights.normal};
    line-height: 2rem;
  }

  strong {
    font-weight: ${(p) => p.theme.fontWeights.bold};
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1rem;
  }
`

export const StyledSlickDots = styled.div`
  .slick-slider {
    margin-bottom: 2.6875rem;
    overflow: hidden;
  }
  .slick-dots {
    bottom: 2.5rem !important;
    li {
      border: none;
      border-radius: 0.1563rem;
      cursor: pointer;
      height: 0.3125rem !important;
      width: 2.5rem !important;
      border-radius: 0.25rem;
      margin: 0 0.125rem !important;
      background-color: ${(p) => p.theme.colors.white};
      opacity: 0.3;
      button {
        height: 0.3125rem !important;
        width: 2.5rem !important;
        border-radius: 0.25rem;
        padding-bottom: 0.625rem;

        :after {
          content: "";
          position: absolute;
          top: -1.25rem;
          bottom: -1.25rem;
          left: -0.125rem;
          right: -0.125rem;
        }
        &::before {
          display: none;
        }
      }
    }
    .slick-active {
      opacity: 1;
    }

    @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
      display: none !important;
    }
  }
`

export const StyledDiscountText = styled.div`
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.bold};
  font-size: 1.125rem;
  line-height: 1.3125rem;
  color: ${(p) => p.theme.colors.white};
  margin-top: 0.8125rem;
`

export const StyledTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.875rem;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.phone}) {
    h2 {
      font-size: 1.25rem;
    }
  }
`

export const StyledLogo = styled.img`
  object-fit: contain;
  border-radius: 0.25rem;
  height: 5.625rem;
  margin-right: 1.25rem;
  @media screen and (max-width: ${(p) => p.theme.breakpoints.phone}) {
    height: 2.375rem;
    margin-right: 0.625rem;
  }
`

export const StyledWebsiteUrlText = styled.span`
  font-size: 0.8rem;
  line-height: 2rem;
  max-width: ${(p) => p.theme.breakpoints.tablet};
  color: ${(p) => p.theme.colors.white};
  font-weight: ${(p) => p.theme.fontWeights.normal};

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 0.6rem;
    line-height: 1;
    margin: 0;
  }
`
