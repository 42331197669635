import styled from "@emotion/styled"
export const StyledPageWrapper = styled.div`
  width: 100%;
`
export const StyledFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 2.5rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    height: 3.375rem;
  }
  .right {
    border-radius: 0 0.25rem 0.25rem 0;
    border: 1px solid ${(p) => p.theme.colors.disabled};
    border-left-width: 0;
  }
  .left {
    border-radius: 0.25rem 0 0 0.25rem;
    border: 1px solid ${(p) => p.theme.colors.disabled};
    border-right-width: 0;
  }
  .active {
    &,
    * {
      background: ${(p) => p.theme.colors.carrot};
      color: ${(p) => p.theme.colors.white};
      border: 1px solid transparent;
    }
  }
`
export const StyledFilterItem = styled.div`
  &,
  * {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: ${(p) => p.theme.colors.carrot};

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.125rem;
      line-height: 1.75rem;
    }
  }
  height: 100%;
  align-self: center;
  width: 50%;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  display: flex;
  div {
    align-self: center;
    justify-items: center;
    width: 100%;
  }
`
