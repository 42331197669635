import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage, useIntl, navigate } from "gatsby-plugin-intl"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { Logo, Money } from "@tmu/components/common"
import { formatDate } from "@tmu/utils/date"
import theme from "@tmu/global/theme"
import { StyledPACWalletTableRow, StyledPACAmount } from "./index.styles"

const PACWalletTableRow = ({
  style,
  description,
  created,
  spendingAmount,
  pacAmount,
  isIncomingTransaction,
  index,
  donation,
}) => {
  const { locale } = useIntl()

  const [, idStr] =
    donation?.id && donation?.id !== "-"
      ? window?.atob(donation?.id).split(":")
      : [, "-"]

  const donationLink = () => {
    navigate("/" + locale + "/dashboard/donors/donation-search?code=" + idStr)
  }

  const { isTablet, isWide } = getAllScreenTypes()

  return (
    <StyledPACWalletTableRow
      style={style}
      data-testid={"pac-wallet-table-row" + index}>
      <div>
        <a href={donationLink} aria-label={"Donation " + donation?.title}>
          {description}
        </a>
        {!isTablet && (
          <div>{formatDate(created, "MMM dd, yyyy, HH:mm:ss")}</div>
        )}
      </div>
      <div className="spending-amount">
        <strong>
          {spendingAmount ? <Money value={spendingAmount} /> : "-"}
        </strong>
      </div>
      {isTablet && <div>{formatDate(created, "MMM dd, yyyy, HH:mm:ss")}</div>}
      <StyledPACAmount>
        <div>
          {Number(pacAmount) > 0 ? (isIncomingTransaction ? "+" : "-") : null}
          <FormattedMessage
            id="dashboard::common::pacsEarned"
            defaultMessage="{amount}"
            values={{
              amount: <Money value={pacAmount} currency={false} />,
            }}
          />
        </div>
        <div>
          <Logo
            width={isWide ? 20 : 16}
            height={isWide ? 17 : 13}
            fill={
              isIncomingTransaction ? theme.colors.blue : theme.colors.carrot
            }
          />
        </div>
      </StyledPACAmount>
    </StyledPACWalletTableRow>
  )
}

PACWalletTableRow.propTypes = {
  description: PropTypes.any.isRequired,
  created: PropTypes.string.isRequired,
  spendingAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pacAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  isIncomingTransaction: PropTypes.bool.isRequired,
}

export default PACWalletTableRow
