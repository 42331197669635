import styled from "@emotion/styled"

export const StyledCorporateCompaniesWrapper = styled.div`
  margin: 3rem auto 0;
  padding: 0;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin: 5rem auto 0;
  }

  > div > div {
    margin: auto;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      max-width: ${(p) => p.theme.breakpoints.tablet};
      padding: 0 1.5rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      max-width: ${(p) => p.theme.breakpoints.desktop};
      padding: 0 2rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      max-width: ${(p) => p.theme.breakpoints.wide};
      padding: 0 6rem;
    }
  }
`

export const StyledCorporateCompaniesBody = styled.div`
  background: ${(p) => p.theme.colors.whiteSmoke};
  padding-top: ${(p) => (p.hasCorporateCampaigns ? "unset" : "0.75rem")};
  padding-bottom: 2rem;

  > div {
    display: block;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding-top: 1rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding-top: 2rem;
  }
`

export const StyledHeading = styled.div`
  background: ${(p) => p.theme.colors.white};
  padding: 1rem 0;

  svg {
    max-width: 1.625rem;
  }

  h3 {
    margin: 0;
    width: 8.625rem;
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-size: 1.125rem;
    line-height: 1.75rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      width: unset;
      font-size: 1.5rem;
      line-height: 2.125rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 2rem;
      line-height: 2.75rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 1.5rem 0;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    padding: 2rem 0;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding: 2.5rem 0;
    h3 {
      font-size: 2rem;
      line-height: 2.625rem;
    }
  }
`
export const StyledHeadingTop = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 1rem;

  margin-bottom: 0.9375rem;
  padding: 0 1rem;

  svg {
    margin-right: 0.625rem;
    color: ${(p) => p.theme.colors.carrot};
    font-size: 0.75rem;
    height: 0.75rem;
  }

  a {
    margin: 0.3125rem 0 0;
    align-self: flex-start;
  }

  h5 {
    justify-self: end;
    align-self: center;
    margin: 0;
    cursor: pointer;

    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.blue};
    text-align: right;
    text-transform: uppercase;

    font-size: 0.75rem;
    line-height: 1rem;
    width: 8.625rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      width: unset;
      line-height: normal;
      letter-spacing: 0.03rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
  }

  > div {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 0;
    margin-bottom: 1.125rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin-bottom: 1.5rem;

    > div {
      svg {
        font-size: 1rem;
        height: 1rem;
        line-height: 1.5rem;
      }
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    grid-gap: 3rem;
    margin-bottom: 3rem;

    svg,
    h5 {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
  }
`

export const StyledFilterBar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
  row-gap: 0.75rem;

  padding: 0 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    flex-direction: row;
    column-gap: 2rem;

    padding: unset;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    column-gap: 4rem;
  }
`

export const StyledContent = styled.div`
  > div {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    > div {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    > div {
      display: flex;
      flex-wrap: wrap;
      gap: 1.25rem 0.75rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    > div {
      display: flex;
      flex-wrap: wrap;
      gap: 1.5rem;
    }
  }
`

export const StyledCorporateCompaniesList = styled.div`
  padding: 0 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: unset;
  }
`

export const NoResults = styled.div`
  display: grid;
  grid-gap: 1rem;

  padding: 0 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: unset;
  }

  h6 {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }

  p {
    font-size: 1rem;
    line-height: 1.375rem;
  }
`
