import styled from "@emotion/styled"

export const StyledLabel = styled.label`
  display: block;
  position: relative;
  padding-left: 1.875rem;
  margin-bottom: 1.25rem;
  cursor: pointer;
  user-select: none;
  width: fit-content;

  &:hover input ~ span {
    background-color: transparent;
  }

  .radio-select-item {
  }
  .radio-select-label {
  }

  .radio-select-description {
  }

  .radio-select-input {
  }
`

export const StyledInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ span {
    background-color: transparent;
  }

  &:checked ~ span:after {
    display: block;
  }
`

export const StyledCheckmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 1.25rem;
  width: 1.25rem;
  background-color: transparent;
  border-radius: 50%;
  border: 0.0625rem solid ${(p) => p.theme.colors.blue};

  &:after {
    content: "";
    position: absolute;
    display: none;
  }

  &:after {
    top: 0.1875rem;
    left: 0.1875rem;
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    background: ${(p) => p.theme.colors.blue};
  }
`

export const StyledSoldOutLabel = styled.div`
  font-weight: ${(p) => p.theme.fontWeights.bold};
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-size: 0.75rem;
  line-height: 1.25rem;
  letter-spacing: 0.0225rem;
  color: ${(p) => p.theme.colors.pitchBlack};
  text-transform: uppercase;
  text-align: right;
  max-width: 90%;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
  }
`
