import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { StyledCardWrapper, StyledCampaignType } from "./index.styles"

const CauseTypeCard = ({ icon, type, size = "3x", ...rest }) =>
  icon || type ? (
    <StyledCardWrapper {...rest}>
      {icon?.iconName !== undefined ? (
        <FontAwesomeIcon icon={icon} size={size} />
      ) : (
        <i className={icon} />
      )}
      {type && <StyledCampaignType>{type}</StyledCampaignType>}
    </StyledCardWrapper>
  ) : (
    <></>
  )

CauseTypeCard.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  type: PropTypes.string.isRequired,
  size: PropTypes.string,
}

export default CauseTypeCard
