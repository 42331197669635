import React from "react"
import PropTypes from "prop-types"
import { EmptyTable } from "@tmu/components/dashboard/dashboardCommon"
import { FormattedMessage } from "gatsby-plugin-intl"
import { StyledCampaignFAQTable } from "./index.styles"
import CampaignFAQTableHeader from "./CampaignFAQTableHeader"
import CampaignFAQTableRow from "./CampaignFAQTableRow"

const CampaignFAQTable = ({ campaignFAQ, onEdit, onDelete }) => {
  return (
    <>
      {campaignFAQ?.length > 0 ? (
        <>
          <CampaignFAQTableHeader />
          <StyledCampaignFAQTable
            height={430}
            itemCount={campaignFAQ?.length ?? 0}
            itemSize={90}
            width="100%"
            itemData={campaignFAQ}>
            {({ index, style, data }) => {
              const faq = data[index]
              return (
                <CampaignFAQTableRow
                  key={faq?.id}
                  {...faq}
                  index={index}
                  style={style}
                  onEdit={() => onEdit(faq)}
                  onDelete={() => onDelete(faq)}
                />
              )
            }}
          </StyledCampaignFAQTable>
        </>
      ) : (
        <EmptyTable
          emptyMessage={
            <FormattedMessage
              id="dashboard::campaignFAQ::empty"
              defaultMessage="It seems you haven't created any campaign FAQs."
            />
          }
        />
      )}
    </>
  )
}

CampaignFAQTable.propTypes = {
  campaignFAQ: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string,
      answer: PropTypes.string,
      id: PropTypes.string,
      created: PropTypes.string,
      modified: PropTypes.string,
    })
  ).isRequired,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
}

export default CampaignFAQTable
