import PropTypes from "prop-types"
import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import CampaignTableHeader from "./CampaignTableHeader"
import CampaignTableRow from "./CampaignTableRow"
import theme from "@tmu/global/theme"
import { StyledCampaignTable } from "./index.styles"
import { getValueForLocale } from "@tmu/utils/string"

const CampaignTable = ({
  defaultCampaign,
  campaigns,
  onStopCampaign,
  handlePrivacyClick,
}) => {
  const { locale, defaultLocale } = useIntl()
  const { isDesktop, isWide } = getAllScreenTypes()

  const imageSize = {
    imageWidth: isWide ? 107 : isDesktop ? 45 : "",
    imageHeight: isWide ? 68 : isDesktop ? 28.7 : "",
  }

  return (
    <>
      <CampaignTableHeader />
      <StyledCampaignTable>
        {defaultCampaign?.map((campaign, index) => (
          <CampaignTableRow
            key={campaign.id}
            style={{ background: theme.colors.whiteSmoke }}
            index={index}
            {...campaign}
            {...imageSize}
            name={getValueForLocale(campaign, "name", locale, defaultLocale)}
            handlePrivacyClick={handlePrivacyClick}
          />
        ))}
      </StyledCampaignTable>
      <StyledCampaignTable>
        {campaigns?.map((campaign, index) => (
          <CampaignTableRow
            key={campaign.id}
            index={index}
            {...campaign}
            {...imageSize}
            onStopCampaign={onStopCampaign}
            name={getValueForLocale(campaign, "name", locale, defaultLocale)}
            handlePrivacyClick={handlePrivacyClick}
          />
        ))}
      </StyledCampaignTable>
    </>
  )
}

CampaignTable.propTypes = {
  campaigns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      image: PropTypes.object,
      name: PropTypes.string,
      campaignType: PropTypes.string,
      isUnlisted: PropTypes.bool,
      startsAt: PropTypes.string,
      deadline: PropTypes.string,
      fundedAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      goalAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      donationCount: PropTypes.number,
      status: PropTypes.string,
    })
  ).isRequired,
  onStopCampaign: PropTypes.func,
}

export default CampaignTable
