import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import {
  FundraiseHero,
  FundraiseInfo,
  FundraiseJoinAsCorporate,
} from "@tmu/components/fundraise"
import { BlogCareerSection } from "@tmu/components/common"
import SEO from "@tmu/components/seo"
import { StyledPageContainer } from "@tmu/global/page-addons/fundraise-page.styles"

const FundraisePage = ({ intl }) => {
  const { locale, formatMessage } = intl

  return (
    <>
      <SEO
        lang={locale}
        title={formatMessage({
          id: "fundraise::common::pageTitle",
          defaultMessage: "Become a Fundraiser",
        })}
        description={formatMessage({
          id: "seo::fundraise::description",
          defaultMessage:
            "Support your favorite charities by creating your campaign.",
        })}
      />
      <StyledPageContainer>
        <FundraiseHero />
        <FundraiseInfo />
        <FundraiseJoinAsCorporate />
        <BlogCareerSection />
      </StyledPageContainer>
    </>
  )
}

export default injectIntl(FundraisePage)
