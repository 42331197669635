import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import theme from "@tmu/global/theme"
import { useIntl } from "gatsby-plugin-intl"

import { Button } from "@tmu/components/common"
import { navigate } from "gatsby-plugin-intl"
import React from "react"
import {
  StyledActionCard,
  StyledActionCardTitle,
  StyledActionCardText,
} from "./index.styles"

const ActionCard = ({
  icon,
  title,
  description,
  buttonText,
  link,
  type,
  ...rest
}) => {
  const { locale } = useIntl()

  const handlePartnerClick = () => {
    navigate(link)
  }
  return (
    <StyledActionCard
      type={type}
      {...rest}
      onClick={handlePartnerClick}
      data-testid={`actionCard`}>
      <StyledActionCardTitle locale={locale}>
        <FontAwesomeIcon
          icon={icon}
          color={theme.colors[type === "offer" ? "carrot" : "blue"]}
        />
        {title}
      </StyledActionCardTitle>
      <StyledActionCardText type={type}>{description}</StyledActionCardText>
      <Button variant="link" text={buttonText} onClick={handlePartnerClick} />
    </StyledActionCard>
  )
}

export default ActionCard
