import React from "react"
import { useQuery } from "@apollo/client"
import { injectIntl } from "gatsby-plugin-intl"
import SEO from "@tmu/components/seo"
import { useApolloApiClients } from "@tmu/apollo/client"
import { ErrorBoundary, Spinner } from "@tmu/components/common"
import ContentNotFound from "@tmu/components/ContentNotFound"
import { StyledStaticPage } from "@tmu/global/page-addons/flat-page.styles"
import { QUERY_STATIC_PAGES } from "@tmu/apollo/storefront/queries/static"
import { isBrowser } from "@tmu/utils/auth"

const StaticPage = ({ slug, intl, pageId }) => {
  const { locale } = intl
  const { strapiClient } = useApolloApiClients()
  const { loading, data } = useQuery(QUERY_STATIC_PAGES, {
    variables: {
      pageId,
      locale,
    },
    client: strapiClient,
  })

  const pageData = data?.pages?.data?.[0]

  if (isBrowser && pageData?.slug) {
    window?.history?.pushState(
      "",
      "",
      "/" + locale + "/terms/" + pageData?.slug + "/" + pageData?.pageId
    )
  }

  if (loading) {
    return <Spinner />
  }

  if (!pageData) {
    return <ContentNotFound slug={slug} />
  }

  return (
    <>
      <SEO lang={locale} title={pageData?.Title} />
      <ErrorBoundary>
        <StyledStaticPage data-testid="content">
          <h1>{pageData?.Title}</h1>
          <div
            dangerouslySetInnerHTML={{
              __html: pageData?.Content,
            }}
          />
        </StyledStaticPage>
      </ErrorBoundary>
    </>
  )
}

export default injectIntl(StaticPage)
