import styled from "@emotion/styled"
import { Form } from "formik"

import {
  StyledPageContent,
  StyledPageActions,
} from "@tmu/global/page-addons/dashboard.styles"
import { StyledInfoBox as StyledInfoBoxBase } from "@tmu/components/common/InfoBox/index.styles"

const GridTemplate = `
  display: grid;
  grid-gap: 0.5rem;
  align-items: start;
`

export const StyledDevelopersTableContainer = styled.div``

export const StyledDevelopersTable = styled.div``

export const StyledDevelopersPageContent = styled(StyledPageContent)`
  padding: 2rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`

export const StyledDevelopersPageActions = styled(StyledPageActions)`
  border-bottom: none;
  padding: 2rem 0;
  flex-direction: column;
  align-items: flex-start;

  a {
    color: ${(p) => p.theme.colors.blue};
    font-weight: ${(p) => p.theme.fontWeights.bold};
  }
`

export const StyledInfoBox = styled(StyledInfoBoxBase)`
  margin: 2rem 0 0 0;
`

export const StyledDevelopersTableHeader = styled.div`
  ${GridTemplate}

  grid-template-columns: 2.25fr minmax(2rem, 2fr) minmax(0.5rem, 0.6fr);

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    grid-template-columns: 2.75fr minmax(3rem, 1.5fr) minmax(2rem, 1fr) minmax(
        0.5rem,
        0.5fr
      );
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    grid-template-columns: 3.75fr minmax(3rem, 1.25fr) minmax(2rem, 1fr) minmax(
        0.5rem,
        0.4fr
      );
  }

  color: ${(p) => p.theme.colors.tableHeaderDefault};
  border-bottom: 0.0625rem solid ${(p) => p.theme.colors.tableItemBorder};
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 0.8rem;
  justify-self: start;
`
export const StyledDevelopersTableRow = styled.div`
  ${GridTemplate}

  grid-template-columns: 2.25fr minmax(2rem, 2fr) minmax(0.5rem, 0.6fr);

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    grid-template-columns: 2.75fr minmax(3rem, 1.5fr) minmax(2rem, 1fr) minmax(
        0.5rem,
        0.5fr
      );

    border-bottom: 0.0625rem solid ${(p) => p.theme.colors.tableItemBorder};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    grid-template-columns: 3.75fr minmax(3rem, 1.25fr) minmax(2rem, 1fr) minmax(
        0.5rem,
        0.4fr
      );
  }

  overflow: hidden;
  color: ${(p) => p.theme.colors.secondaryBrandTextColor};
  width: 100%;
  height: 100%;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  box-sizing: border-box;

  div,
  strong {
    font-size: 0.8rem;
  }

  strong {
    font-weight: ${(p) => p.theme.fontWeights.bold};
    color: ${(p) => p.theme.colors.gondola};
  }

  .desc * {
    color: ${(p) => p.theme.colors.secondaryBrandTextColor};
  }
`

export const StyledDevelopersTableRowText = styled.div`
  height: 100%;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0.3rem 0 0;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: flex;
    justify-items: center;
    align-items: center;
    padding-bottom: 0.3rem;
  }

  &.name {
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    font-style: ${(p) => p.theme.fontStyles.normal};
    color: ${(p) => p.theme.colors.blue};
    cursor: pointer;

    font-size: 0.75rem;
    line-height: 1.125rem;
    letter-spacing: 0.0075rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 0.75rem;
      line-height: 1.25rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      font-size: 1rem;
      line-height: 1.5rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.5rem;
      line-height: 2.25rem;
    }
  }

  &.mobile-client-id,
  &.client-id {
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    font-style: ${(p) => p.theme.fontStyles.normal};
    color: ${(p) => p.theme.colors.pitchBlack};

    font-size: 0.625rem;
    line-height: 1rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 0.75rem;
      line-height: 1.25rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      font-size: 0.85rem;
      line-height: 1.5rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.5rem;
      line-height: 2.25rem;
    }
  }

  &.mobile-client-id {
    color: ${(p) => p.theme.colors.disabled};
  }

  &.password {
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    font-style: ${(p) => p.theme.fontStyles.normal};
    color: ${(p) => p.theme.colors.pitchBlack};

    font-size: 0.75rem;
    line-height: 1.25rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      font-size: 1rem;
      line-height: 1.5rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.5rem;
      line-height: 2.25rem;
    }
  }

  &.regenerate {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-style: ${(p) => p.theme.fontStyles.normal};
    color: ${(p) => p.theme.colors.blue};
    text-transform: uppercase;
    cursor: pointer;

    font-size: 0.75rem;
    line-height: 1.125rem;
    letter-spacing: 0.0075rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 0.75rem;
      line-height: 1.125rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.25rem;
      line-height: 2rem;
      letter-spacing: 0.025rem;
    }
  }

  &.revoke {
    button {
      margin: unset;
      padding: unset;
      svg {
        color: ${(p) => p.theme.colors.blue};
        font-size: 1rem;
        height: 1rem;

        margin-left: 1.5rem;

        @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
          margin-left: 1rem;
        }

        @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
          margin-left: 1.75rem;
        }

        @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
          padding: 0.25rem 2rem;
        }
      }
    }
  }
`

export const StyledTooltipButtonContainer = styled.div`
  display: flex;
`

export const StyledNameWrapper = styled.div`
  display: flex;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: space-between;

  div {
    margin: 0.3rem;
    height: fit-content;
    padding: 0.5rem;
  }

  svg {
    font-size: 0.7rem;
    height: 0.7rem;
  }

  [contenteditable="true"]:active,
  [contenteditable="true"]:focus {
    border-radius: 0.2rem;
    outline: grey solid 0.1rem;
  }

  button {
    display: block;
    padding: unset;
  }
`

export const StyledForm = styled(Form)`
  h6 {
    font-family: ${(p) => p.theme.fontFamilies.headlineBig};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.liteBlack};
    color: ${(p) => p.theme.colors.disabled};
    font-size: 0.75rem;
    line-height: 1.25rem;
    letter-spacing: 0.0225rem;
    text-transform: uppercase;
    text-align: left;
    margin: 0.5rem 0;
  }

  .float-container {
    padding-left: 0.5rem !important;
  }

  .full-width {
    width: 20rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      width: 44rem;
    }
  }

  button {
    width: 100%;
  }

  .input-feedback {
    bottom: 0;
    margin-top: -1.5rem;
  }
`

export const StyledPageTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const StyledPageDeveloperContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  p,
  span {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-style: ${(p) => p.theme.fontStyles.normal};

    font-size: 1rem;
    line-height: 1.5rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 2rem;
      line-height: 2.75rem;
    }
  }

  p {
    color: ${(p) => p.theme.colors.pitchBlack};
  }

  span {
    color: ${(p) => p.theme.colors.blue};
    margin: 0 0.5rem;
  }

  button {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.blue};
    font-size: 1rem;
    line-height: 1.5rem;
    padding: unset;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 2rem;
      line-height: 2.75rem;
    }

    svg {
      font-size: 1.75rem;
      height: 1.75rem;

      @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
        font-size: 1rem;
        height: 1rem;
      }

      @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
        font-size: 1.75rem;
        height: 1.75rem;
      }
    }
  }

  &.bottom-user-id {
    align-items: left;
    justify-content: left;
    margin-top: 1.25rem;
  }
`

export const StyledModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: -1.25rem;

  h4 {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.pitchBlack};
    text-align: left;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 1.5rem;
      line-height: 2rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 2rem;
      line-height: 2.625rem;
    }
  }

  button {
    margin: 0;
    padding: 0;

    svg {
      height: 1.25rem;

      @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
        height: 2rem;
      }
    }
  }
`

export const StyledModalText = styled.div`
  p {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-style: ${(p) => p.theme.fontStyles.normal};
    color: ${(p) => p.theme.colors.pitchBlack};
    font-size: 1.5rem;
    line-height: 2.125rem;
    margin: 0.8125rem 0 1.25rem;
  }
`

export const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;

  p {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-style: ${(p) => p.theme.fontStyles.normal};
    text-align: center;
    font-size: 1.5rem;
    line-height: 2.125rem;
  }

  span {
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    font-style: ${(p) => p.theme.fontStyles.normal};
    text-align: center;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-top: 1rem;
  }

  &.mobile {
    height: 80%;

    .full-width {
      div {
        width: 20rem;
        margin-bottom: unset;
      }
    }
  }

  &.not-mobile {
    span {
      margin-bottom: 2rem;
    }

    .full-width {
      div {
        width: 86vw;
        margin-bottom: unset;
      }
    }
  }
`

export const StyledModalButton = styled.div`
  button {
    width: 100%;
  }

  &.mobile {
    position: absolute;
    margin-left: 0;
    margin-right: 0;
    bottom: 1rem;
    width: 86vw;
    white-space: normal;
  }
`
