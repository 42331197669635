import { gql } from "@apollo/client"

export const QUERY_STATIC_PAGE_LIST = gql`
  query StaticPages($locale: I18NLocaleCode) {
    pages(locale: $locale, sort: ["Order:asc"], pagination: { limit: 100 }) {
      data {
        attributes {
          slug
          Title
          Location
          directLink
          pageId
        }
      }
    }
  }
`

export const QUERY_STATIC_PAGES = gql`
  query StaticPages($locale: I18NLocaleCode, $pageId: String) {
    pages(locale: $locale, filters: { pageId: { eq: $pageId } }) {
      data {
        attributes {
          slug
          Title
          Content
          directLink
          pageId
          Location
        }
      }
    }
  }
`
