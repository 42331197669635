/* eslint-disable jsx-a11y/no-onchange */
import React, { useEffect, useState } from "react"
import { useIntl, FormattedMessage, Link } from "gatsby-plugin-intl"
import { SearchBoxFilter, SortSelector, Spinner } from "@tmu/components/common"
import { getQueryParams } from "@tmu/utils/string"
import { CARDS_PER_PAGE } from "@tmu/apollo/constants"
import CorporateCompaniesTable from "../CorporateCompaniesTable"
import queryString from "query-string"
import SEO from "@tmu/components/seo"

import {
  StyledCorporateCompaniesWrapper,
  StyledHeading,
  StyledHeadingTop,
  StyledFilterBar,
  StyledCorporateCompaniesBody,
  StyledContent,
  StyledCorporateCompaniesList,
  NoResults,
} from "./index.styles"
import { useMerchants } from "@tmu/src/hooks"
import { useApolloApiClients } from "@tmu/src/apollo/client"

const sortingTypes = [
  {
    id: "-totalDonationCountForDisplay,-created",
    label: {
      id: "sorting::donationCountHigh",
      defaultMessage: "Donations: high > low",
    },
  },
  {
    id: "totalDonationCountForDisplay,-created",
    label: {
      id: "sorting::donationCountLow",
      defaultMessage: "Donations: low > high",
    },
  },
  {
    id: "-totalSocialImpactForDisplay,-created",
    label: {
      id: "sorting::fundedAmountHigh",
      defaultMessage: "Total Social Impact: high > low",
    },
  },
  {
    id: "totalSocialImpactForDisplay,-created",
    label: {
      id: "sorting::fundedAmountLow",
      defaultMessage: "Total Social Impact: low > high",
    },
  },
  {
    id: "name,-created",
    label: {
      id: "sorting::alphabeticallyLow",
      defaultMessage: "Alphabetically A-Z",
    },
  },
  {
    id: "-name,-created",
    label: {
      id: "sorting::alphabeticallyHigh",
      defaultMessage: "Alphabetically Z-A",
    },
  },
]

const CorporateCompaniesListing = () => {
  const { locale, formatMessage } = useIntl()
  const params = getQueryParams()
  const [searchText, setSearchText] = useState(null)
  const [selectedSortBy, setSelectedSortBy] = useState(
    "-totalDonationCountForDisplay,-created"
  )
  const { storefrontClient } = useApolloApiClients()
  const { callCorporateCompanies, corportateQueryData } = useMerchants()
  const { loading } = corportateQueryData
  let merchants = corportateQueryData?.allMerchants || []

  const allMerchants = selectedSortBy?.includes("totalFundedAmountForDisplay")
    ? merchants?.slice().sort((a, b) => {
        const v1 = parseFloat(a?.node?.totalSocialImpact)
        const v2 = parseFloat(b?.node?.totalSocialImpact)

        if (selectedSortBy.startsWith("-")) return v2 - v1
        else return v1 - v2
      })
    : merchants

  useEffect(() => {
    const variables = {
      first: CARDS_PER_PAGE,
      search: searchText,
      orderBy: selectedSortBy,
      isPublic: true,
      isDisplayed: true,
    }

    callCorporateCompanies({ variables, client: storefrontClient })
  }, [searchText, selectedSortBy])

  const sortOptionsData = sortingTypes?.map(({ id, label }) => ({
    value: id,
    label: formatMessage(label),
  }))

  const setSorting = (orderBy) => {
    navigateWithParams({
      ...params,
      orderBy: orderBy?.value,
    })
    setSelectedSortBy(orderBy?.value)
  }

  const navigateWithParams = (newParams) => {
    const updatedParams = queryString.stringify(
      { ...newParams },
      { arrayFormat: "comma" }
    )
    changeUrlState("?" + updatedParams)
  }

  const changeUrlState = (url) => {
    window?.history?.pushState({}, null, url)
  }

  const handleSearch = (e) => {
    setSearchText(e.target.value)
  }

  return (
    <>
      <SEO
        lang={locale}
        title={formatMessage({
          id: "seo::companiesListing::title",
          defaultMessage: "Become a Social Impact Brand",
        })}
        description={formatMessage({
          id: "seo::companiesListing::description",
          defaultMessage:
            "Create campaigns supporting charities of your choice, increase awareness of your customers, employees, suppliers and build a strong social impact brand",
        })}
      />
      <StyledCorporateCompaniesWrapper id="corporate-companies">
        <StyledHeading>
          <div>
            <StyledHeadingTop>
              <FormattedMessage
                id="companiesListing::companiesTitle"
                defaultMessage="Social Impact Companies"
                tagName="h3"
              />
              <Link to={`/join/corporate/`} aria-label="non-profit">
                <FormattedMessage
                  id="companiesListing::joinAsSocialImpact"
                  defaultMessage="join as social impact company"
                  tagName="h5"
                />
              </Link>
            </StyledHeadingTop>
            <StyledFilterBar>
              <SearchBoxFilter
                placeHolder={formatMessage({
                  id: "companiesListing::searchBox::placeHolder",
                  defaultMessage: "Company name",
                })}
                handleSearch={handleSearch}
              />
              <SortSelector
                defaultValue={selectedSortBy}
                options={sortOptionsData}
                handleSort={setSorting}
                className="corporate-companies"
              />
            </StyledFilterBar>
          </div>
        </StyledHeading>
        <StyledCorporateCompaniesBody>
          <div>
            <StyledContent>
              {loading ? (
                <Spinner />
              ) : allMerchants?.length > 0 ? (
                <StyledCorporateCompaniesList>
                  <CorporateCompaniesTable corporateCampaigns={allMerchants} />
                </StyledCorporateCompaniesList>
              ) : (
                !loading && (
                  <NoResults>
                    <FormattedMessage
                      id="campaignListing::filters::noResult"
                      defaultMessage="We couldn’t find any results ("
                      tagName="h6"
                    />
                    <FormattedMessage
                      id="campaignListing::filters::tryFilters"
                      defaultMessage="Try to change filters"
                      tagName="p"
                    />
                  </NoResults>
                )
              )}
            </StyledContent>
          </div>
        </StyledCorporateCompaniesBody>
      </StyledCorporateCompaniesWrapper>
    </>
  )
}

export default CorporateCompaniesListing
