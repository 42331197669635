import { campaignDetailParts } from "@tmu/apollo/storefront/fragments/campaign"
import { capitalize } from "@tmu/utils/string"

export const partnerListingParts = `
  fragment partnerListingParts on PartnerNode {
    id
    slug
    name
    displayName
    isFeatured
    logoEn
    logoIt
    logoEs
    logo
    shortDescriptionEn
    shortDescriptionIt
    shortDescriptionEs
    whySupportEn
    whySupportIt
    whySupportEs
    country
    city
    addressLine1
    addressLine2
    website
    youtube
    instagram
    facebook
    twitter
    linkedin
    taxId
    donationCount
    fundedAmount
    isEligibleForDonationPreference
    weight
    images {
      edges {
        node {
          id
          order
          image
        }
      }
    }
    __typename
  }
`

export const partnerListingPartsWithLocale = (locale)=> { 
  
  const localeSuffix = capitalize(locale)

  return `fragment partnerListingParts on PartnerNode {
    id
    slug
    name
    displayName
    isFeatured
    logo${localeSuffix}
    logo
    taxId
    __typename
  }
`}

export const partnerDetailParts = `
  fragment partnerDetailParts on PartnerNode {
    id
    slug
    name
    displayName
    isFeatured
    logoEn
    logoIt
    logoEs
    descriptionEn
    descriptionIt
    descriptionEs
    shortDescriptionEn
    shortDescriptionIt
    shortDescriptionEs
    whySupportEn
    whySupportIt
    whySupportEs
    country
    city
    addressLine1
    addressLine2
    website
    youtube
    instagram
    facebook
    twitter
    linkedin
    taxId
    isEligibleForDonationPreference
    isEligibleForSupporterCollection
    isEligibleForSupporterEvent
    weight
    images {
      edges {
        node {
          id
          image
        }
      }
    }
    defaultCampaign {
     ...campaignDetailParts
    }
    donationCount
    fundedAmount
    __typename
  }
  ${campaignDetailParts}
`

export const partnerListingPartsForHomePage = `
  fragment partnerListingPartsForHomePage on PartnerNode {
    id
    slug
    name
    displayName
    isFeatured
    logo
    logoEn
    logoIt
    logoEs
    shortDescriptionEn
    shortDescriptionIt
    shortDescriptionEs
    donationCount
    fundedAmount
    weight
    taxId
    campaigns {
      totalCount
    }
    __typename
  }
`
