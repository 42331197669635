import React from "react"
import { FormattedMessage, navigate } from "gatsby-plugin-intl"
import { faDove } from "@fortawesome/pro-solid-svg-icons/faDove"
import { faGem } from "@fortawesome/pro-regular-svg-icons/faGem"
import { faPencil } from "@fortawesome/pro-solid-svg-icons/faPencil"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "@tmu/components/common"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import {
  StyledMainContainer,
  StyledLeftContainer,
  StyledLeftIcon,
  StyledLeftTitle,
  StyledLeftContent,
  StyledCenterContainer,
  StyledCenterIcon,
  StyledCenterTitle,
  StyledCenterContent,
  StyledRightContainer,
  StyledRightIcon,
  StyledRightTitle,
  StyledRightContent,
} from "./index.styles"

const FundraiseInfo = () => {
  const { isTablet } = getAllScreenTypes()

  const handleNavigate = () => {
    navigate(`/dashboard/campaign?supporter=true`)
  }

  return (
    <StyledMainContainer>
      <StyledLeftContainer>
        <StyledLeftIcon>
          <FontAwesomeIcon icon={faPencil} />
        </StyledLeftIcon>
        <StyledLeftTitle>
          <FormattedMessage
            id="fundraise::info::title1"
            defaultMessage="Create a campaign or an Event"
          />
        </StyledLeftTitle>
        <StyledLeftContent>
          <FormattedMessage
            id="fundraise::info::content1"
            defaultMessage="Choose whether to create a generic fundraiser in support of your charity or create an event for your birthday, wedding, new birth and other special occasions for you."
          />
        </StyledLeftContent>
      </StyledLeftContainer>
      <StyledCenterContainer>
        <StyledCenterIcon>
          <FontAwesomeIcon icon={faGem} />
        </StyledCenterIcon>
        <StyledCenterTitle>
          <FormattedMessage
            id="fundraise::info::title2"
            defaultMessage="Choose a charity"
          />
        </StyledCenterTitle>
        <StyledCenterContent>
          <FormattedMessage
            id="fundraise::info::content2"
            defaultMessage="Select the Charity to which allocate the funds of your campaign."
          />
        </StyledCenterContent>
      </StyledCenterContainer>
      <StyledRightContainer>
        <StyledRightIcon>
          <FontAwesomeIcon icon={faDove} />
        </StyledRightIcon>
        <StyledRightTitle>
          <FormattedMessage
            id="fundraise::info::title3"
            defaultMessage="Share a link with your contacts"
          />
        </StyledRightTitle>
        <StyledRightContent>
          <FormattedMessage
            id="fundraise::info::content3"
            defaultMessage="Share the link of your campaign or event on social networks and ask your friends to donate. Each donation will always be rewarded 100% in discounts for purchases."
          />
        </StyledRightContent>
      </StyledRightContainer>
      <div
        className={
          isTablet
            ? "support-charity-center-button"
            : "support-charity-bottom-button"
        }>
        <Button color="blue" onClick={handleNavigate}>
          <FormattedMessage
            id="fundraise::info::button"
            defaultMessage="Start Now"
          />
        </Button>
      </div>
    </StyledMainContainer>
  )
}

export default FundraiseInfo
