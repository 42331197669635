import { useStripe } from "@stripe/react-stripe-js"
import { PAYMENT_TYPES } from "../apollo/constants"

const usePayment = (options) => {
  const stripe = useStripe()
  const confirmPaymentMethod = (paymentType) => {
    const isPayPal = paymentType === PAYMENT_TYPES.PAYPAL
    const confirm = isPayPal
      ? stripe.confirmPayPalPayment
      : stripe.confirmCardPayment

    return confirm
  }

  const confirmSetupMethod = (paymentType) => {
    const isPayPal = paymentType === PAYMENT_TYPES.PAYPAL
    const confirm = isPayPal
      ? stripe.confirmPayPalSetup
      : stripe.confirmCardSetup

    return confirm
  }

  return {
    confirmPaymentMethod,
    confirmSetupMethod,
  }
}

export default usePayment
