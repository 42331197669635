import React, { useEffect } from "react"
import queryString from "query-string"
import { injectIntl, navigate } from "gatsby-plugin-intl"
import { StyledPageContainer } from "@tmu/global/page-addons/password-recovery.styles"
import { CategorySelect } from "@tmu/components/common"
import { useAuth } from "@tmu/hooks"
import { get, set } from "@tmu/utils/storage"

const Welcome = ({ location }) => {
  const paramOptions = { arrayFormat: "comma" }
  const params = queryString.parse(location.search, paramOptions)
  const next = params?.next?.replace(/\/(en|it|es)/g, "") ?? "/"
  const nextUrl = queryString.stringifyUrl({
    url: next,
    query: params,
  })
  navigate(nextUrl)

  const { isAuthenticated, user, isLoading } = useAuth()
  useEffect(() => {
    if (!isAuthenticated && !user?.id && !isLoading) {
      navigate("/signin?next=" + nextUrl)
      return
    }

    if (user?.id) {
      const WELCOME_KEY = "welcome_key_" + user?.id
      if (get(WELCOME_KEY) === null) {
        set(WELCOME_KEY, true)
      } else {
        navigate(nextUrl)
      }
    }
  }, [isAuthenticated, user])

  return (
    <>
      {true ? (
        <></>
      ) : isAuthenticated ? (
        <StyledPageContainer>
          <CategorySelect />
        </StyledPageContainer>
      ) : (
        <></>
      )}
    </>
  )
}

export default injectIntl(Welcome)
