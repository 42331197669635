import React, { useState, useEffect, forwardRef } from "react"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import {
  CustomModal,
  Dropdown,
  LazyImage,
  TextInput,
} from "@tmu/components/common"
import {
  StyledCharityList,
  StyledCharityListItem,
  StyledFavoriteNGOSection,
  StyledImageContainer,
  StyledInfoContainer,
  StyledLine,
  StyledMobileWrapper,
  StyledPartnerName,
  StyledSearchBoxFilter,
  StyledTaxId,
  StyledTitle,
} from "./index.styles"
import { getValueForLocale } from "@tmu/utils/string"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/pro-solid-svg-icons/faSearch"
import { useCharities } from "@tmu/hooks"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"

const FavoriteNGOSection = forwardRef(
  (
    {
      hideLabel,
      me,
      onFavChange,
      favoriteNGO,
      setFavoriteNGO,
      forceToDesktopView,
      onClose,
    },
    ref
  ) => {
    const { charities, getCharities } = useCharities()
    const [donationEligiblePartnerOptions, setDonationEligiblePartnerOptions] =
      useState([])
    let searchTimeoutId = null
    let callTimeout = null
    const [searchText, setSearchText] = useState(null)
    const { locale, defaultLocale, formatMessage } = useIntl()
    const [modalStatus, setModalStatus] = useState(true)
    const [preferredCharity, setPreferredCharity] = useState(null)
    const { isTablet, isWide } = getAllScreenTypes()
    useEffect(() => {
      if (typeof setFavoriteNGO === "function") {
        setFavoriteNGO(me?.userPreference?.campaign?.id)
      }
    }, [me?.userPreference, me?.userPreference?.campaign?.id])

    useEffect(() => {
      if (!isTablet) {
        getCharities({ first: 10 })
      }
    }, [])
    useEffect(() => {
      setDonationEligiblePartnerOptions(
        charities?.edges?.map(({ node }) => ({
          label: node?.name + " - " + node?.partner?.taxId,
          value: node?.id,
          ...node,
        })) ?? []
      )
    }, [charities])

    useEffect(() => {
      if (!searchText) {
        return
      }
      getCharities({
        search: searchText,
        first: 100,
      })
    }, [searchText])

    useEffect(() => {
      setPreferredCharity(
        favoriteNGO
          ? donationEligiblePartnerOptions.find(
              (item) => item.value === favoriteNGO
            )
          : null
      )
    }, [
      favoriteNGO,
      donationEligiblePartnerOptions,
      donationEligiblePartnerOptions?.legth,
    ])

    const handleSearch = (e) => {
      const searchValue = e?.target?.value
      clearTimeout(searchTimeoutId)
      searchTimeoutId = setTimeout(() => {
        setSearchText(searchValue)
      }, 1000)
    }

    const closeModal = () => {
      setModalStatus(false)

      if (typeof onClose === "function") {
        onClose()
      }
    }

    const selectFavorite = (charityId, charity) => {
      if (typeof setFavoriteNGO === "function") {
        setFavoriteNGO(charityId)
      }
      if (typeof onFavChange === "function") {
        onFavChange(charityId, charity)
      }
    }
    const charityList = (
      <StyledMobileWrapper>
        <StyledSearchBoxFilter>
          <TextInput
            newDesign
            data-testid="input-search"
            id="search"
            name="search"
            onChange={handleSearch}
            placeholder={formatMessage({
              id: "searchbox::placeholder",
              defaultMessage: "Charity, Campaign Name, Fiscal Code",
            })}
          />
          <div className="search-icon">
            <FontAwesomeIcon icon={faSearch} />
          </div>
          <StyledLine />
        </StyledSearchBoxFilter>
        <StyledTitle>Non profits</StyledTitle>
        <StyledCharityList>
          {charities?.edges?.map(({ node: charity }, index) => {
            const partner = charity?.partner
            return (
              <StyledCharityListItem
                key={index}
                onClick={() => {
                  selectFavorite(charity?.id, charity)
                  closeModal()
                }}>
                <StyledImageContainer>
                  <LazyImage
                    src={partner?.logo}
                    altName={`partner-image-${partner?.name}`}
                    width={88}
                    height={48}
                    fit="cover"
                  />
                </StyledImageContainer>
                <StyledInfoContainer>
                  <StyledPartnerName>
                    {getValueForLocale(partner, "name", locale, defaultLocale)}
                  </StyledPartnerName>
                  <StyledTaxId>
                    {formatMessage({
                      id: "typehead::partners::fiscalCode",
                      defaultMessage: "Fiscal Code",
                    })}
                    &nbsp;
                    {partner?.taxId}
                  </StyledTaxId>
                </StyledInfoContainer>
              </StyledCharityListItem>
            )
          })}
        </StyledCharityList>
      </StyledMobileWrapper>
    )

    const promiseOptions = (inputValue) => {
      return new Promise((resolve) => {
        clearTimeout(callTimeout)
        callTimeout = setTimeout(() => {
          getCharities({
            search: inputValue,
            first: 10,
          }).then((result) => {
            resolve(handleResult(result?.data?.allCampaigns))
          })
        }, 1000)
      })
    }

    const handleResult = (result) => {
      const filteredResult = result?.edges?.map(({ node }) => ({
        value: node?.id,
        label: node?.partner?.name,
        charity: node,
      }))

      return filteredResult
    }

    return (
      <StyledFavoriteNGOSection>
        {isTablet || forceToDesktopView ? (
          <>
            {hideLabel !== true && (
              <FormattedMessage
                id="dashboard::chosenFavoriteCharity"
                defaultMessage="Chosen Favorite Charity"
                tagName="p"
              />
            )}
            <Dropdown
              data-testid={`select-favorite-charity`}
              type="asyncSelect"
              id="favoriteCharity"
              name="favoriteCharity"
              className="select-field custom-select"
              cacheOptions
              value={preferredCharity}
              defaultOptions={true}
              loadOptions={promiseOptions}
              placeholder={
                <FormattedMessage
                  id="forms::general::select"
                  defaultMessage="Select"
                />
              }
              onChange={(favoriteCharity) => {
                selectFavorite(favoriteCharity?.value, favoriteCharity)
              }}
              isClearable
              defaultSelectStyles={{
                control: {
                  paddingTop: isWide ? "0.5rem" : "0.3rem",
                  paddingRight: "0",
                  width: "100%",
                  maxWidth: isWide ? "47.75rem" : "29.5rem",
                },
              }}
            />
          </>
        ) : (
          <CustomModal
            isModalOpen={modalStatus}
            children={charityList}
            isMobile={true}
            isFull={true}
            style={{
              overflowY: "hidden",
            }}
            isCloseIcon={true}
            isCloseXButton={true}
            cancelButtonAction={closeModal}
            closeIconStyle={{ height: "1.5rem", zIndex: 999 }}
          />
        )}
      </StyledFavoriteNGOSection>
    )
  }
)

export default FavoriteNGOSection
