import React from "react"
import PropTypes from "prop-types"
import { Global, css } from "@emotion/react"

const { CLOUDFLARE_IMAGE_URL } = process.env

const fontsBaseUrl = CLOUDFLARE_IMAGE_URL + "/static/fonts"

const FontStyles = () => (
  <Global
    styles={css`
      @font-face {
        font-family: "Open Sans";
        src: url("${fontsBaseUrl}/OpenSans-Regular.eot");
        src: url("${fontsBaseUrl}/OpenSans-Regular.eot?#iefix")
            format("embedded-opentype"),
          url("${fontsBaseUrl}/OpenSans-Regular.woff2") format("woff2"),
          url("${fontsBaseUrl}/OpenSans-Regular.woff") format("woff"),
          url("${fontsBaseUrl}/OpenSans-Regular.ttf") format("truetype"),
          url("${fontsBaseUrl}/OpenSans-Regular.svg#OpenSans-Regular")
            format("svg");
        font-weight: normal;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: "Open Sans Bold";
        src: url("${fontsBaseUrl}/OpenSans-Bold.eot");
        src: url("${fontsBaseUrl}/OpenSans-Bold.eot?#iefix")
            format("embedded-opentype"),
          url("${fontsBaseUrl}/OpenSans-Bold.woff2") format("woff2"),
          url("${fontsBaseUrl}/OpenSans-Bold.woff") format("woff"),
          url("${fontsBaseUrl}/OpenSans-Bold.ttf") format("truetype"),
          url("${fontsBaseUrl}/OpenSans-Bold.svg#OpenSans-Bold") format("svg");
        font-weight: normal;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: "Open Sans ExtraBold";
        src: url("${fontsBaseUrl}/OpenSans-ExtraBold.eot");
        src: url("${fontsBaseUrl}/OpenSans-ExtraBold.eot?#iefix")
            format("embedded-opentype"),
          url("${fontsBaseUrl}/OpenSans-ExtraBold.woff2") format("woff2"),
          url("${fontsBaseUrl}/OpenSans-ExtraBold.woff") format("woff"),
          url("${fontsBaseUrl}/OpenSans-ExtraBold.ttf") format("truetype"),
          url("${fontsBaseUrl}/OpenSans-ExtraBold.svg#OpenSans-ExtraBold")
            format("svg");
        font-weight: normal;
        font-style: normal;
        font-display: swap;
      }
    `}
  />
)

export default FontStyles
