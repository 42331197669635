import styled from "@emotion/styled"
export const StyledListTypeSwitcher = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 1rem;
  align-self: center;
  justify-self: center;
  color: ${(p) => p.theme.colors.pacificBlue};

  .active {
    color: ${(p) => p.theme.colors.black};
  }

  svg {
    font-size: 1.65rem;
    height: 1.65rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    svg {
      font-size: 3.65rem;
      height: 3.65rem;
    }
  }
`
export const StyledListButton = styled.div`
  cursor: pointer;
  font-size: 1.25rem;
`
