import React from "react"
import PropTypes from "prop-types"
import CampaignCard from "./Variants/CampaignCard"
import WideCampaignCard from "./Variants/WideCampaignCard"
import CharityCard from "./Variants/CharityCard"
import CharityCardWithCTA from "./Variants/CharityCardWithCTA"
import OfferCard from "./Variants/OfferCard"
import OfflineStoreCard from "./Variants/OfflineStoreCard"
import StoreCard from "./Variants/StoreCard"
import CheckoutSummaryCard from "./Variants/CheckoutSummaryCard"

const Card = ({ variant, ...rest }) => {
  switch (variant) {
    case "campaign":
      return <CampaignCard {...rest} />
    case "wide-campaign":
      return <WideCampaignCard {...rest} />
    case "charity":
      return <CharityCard {...rest} />
    case "charityWithCTA":
      return <CharityCardWithCTA {...rest} />
    case "store":
      return <StoreCard {...rest} />
    case "offer":
      return <OfferCard {...rest} />
    case "service":
      return <OfferCard isServiceCard {...rest} />
    case "offlineStore":
      return <OfflineStoreCard {...rest} />
    case "summary":
      return <CheckoutSummaryCard variant={variant} {...rest} />
    default:
      return null
  }
}

Card.propTypes = {
  variant: PropTypes.oneOf([
    "campaign",
    "store",
    "partner",
    "offer",
    "brand",
    "merchant",
    "service",
    "offlineStore",
    "summary",
  ]).isRequired,
  imageSrc: PropTypes.string,
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  subTitle: PropTypes.string,
  description: PropTypes.string,
  totalDonors: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalSupport: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  discount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  goalAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fundedAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inSupportOfTitle: PropTypes.string,
  inSupportOfLogo: PropTypes.string,
  fiscalCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.oneOf(["default", "tile", "list"]).isRequired,
  cardLink: PropTypes.string,
}

Card.defaultProps = {
  size: "default",
}

export default Card
