import styled from "@emotion/styled"
import { css } from "@emotion/react"
import ReactCrop from "react-image-crop"

import "react-image-crop/dist/ReactCrop.css"

export const StyledImageSelector = styled.div`
  flex: 2 1 0;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  color: ${(p) => p.theme.colors.disabled};
  padding: 0;
  margin-right: 1rem;

  input[type="file"] {
    opacity: 0;
    visibility: hidden;
    height: 0;
  }

  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 18rem;
  }

  ${(p) =>
    p.dashed &&
    css`
      display: block;
      border: 0.125rem dashed ${p.theme.colors.blue};
      margin: 0;
      div {
        display: flex;
        background: none;
        box-sizing: border-box;
      }
      button {
        margin: auto;
      }
    `}

  .upload-icon {
    svg {
      width: 2.25rem;
      height: 2.25rem;
      color: ${(p) => p.theme.colors.lightGrey};
    }
  }
`

export const StyledDisabledPreviewImage = styled.div`
  overflow: hidden;

  img {
    object-fit: cover;
  }

  ${(p) =>
    p.square &&
    css`
      &,
      img,
      div {
        border-radius: 50%;
      }
    `}
`

export const StyledReactCrop = styled(ReactCrop)`
  color: ${(p) => p.theme.colors.disabled};
  padding: 0;

  &.ReactCrop {
    position: relative;
    display: inline-block;
    cursor: crosshair;
    overflow: hidden;

    ${(p) =>
      p.circularCrop
        ? css`
            width: 11.25rem;
            height: 11.25rem;
          `
        : css`
            width: auto;
            height: auto;
          `}
    background-color: transparent;
  }
  &.ReactCrop:focus {
    outline: none;
  }
  &.ReactCrop--disabled {
    cursor: inherit;
  }
  &.ReactCrop__image {
    display: block;
    max-width: 100%;
    max-height: -webkit-stretch;
    max-height: -moz-available;
    max-height: stretch;
  }
  &.ReactCrop--crop-invisible &.ReactCrop__image {
    opacity: 0.5;
  }
  &.ReactCrop--crop-archived &.ReactCrop__image {
    opacity: 0.5;
  }
  &.ReactCrop__crop-selection {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate3d(0, 0, 0);
    box-sizing: border-box;
    cursor: move;
    box-shadow: 0 0 0 9999em rgba(0, 0, 0, 0.5);
    border: 0.0625rem solid;
    border-image-source: url("data:image/gif;base64,R0lGODlhCgAKAJECAAAAAP///////wAAACH/C05FVFNDQVBFMi4wAwEAAAAh/wtYTVAgRGF0YVhNUDw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OEI5RDc5MTFDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OEI5RDc5MTBDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Af/+/fz7+vn49/b19PPy8fDv7u3s6+rp6Ofm5eTj4uHg397d3Nva2djX1tXU09LR0M/OzczLysnIx8bFxMPCwcC/vr28u7q5uLe2tbSzsrGwr66trKuqqainpqWko6KhoJ+enZybmpmYl5aVlJOSkZCPjo2Mi4qJiIeGhYSDgoGAf359fHt6eXh3dnV0c3JxcG9ubWxramloZ2ZlZGNiYWBfXl1cW1pZWFdWVVRTUlFQT05NTEtKSUhHRkVEQ0JBQD8+PTw7Ojk4NzY1NDMyMTAvLi0sKyopKCcmJSQjIiEgHx4dHBsaGRgXFhUUExIREA8ODQwLCgkIBwYFBAMCAQAAIfkEBQoAAgAsAAAAAAoACgAAAhWEERkn7W3ei7KlagMWF/dKgYeyGAUAIfkEBQoAAgAsAAAAAAoACgAAAg+UYwLJ7RnQm7QmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYgLJHdiinNSAVfOEKoUCACH5BAUKAAIALAAAAAAKAAoAAAIRVISAdusPo3RAzYtjaMIaUQAAIfkEBQoAAgAsAAAAAAoACgAAAg+MDiem7Q8bSLFaG5il6xQAIfkEBQoAAgAsAAAAAAoACgAAAg+UYRLJ7QnQm7SmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYBLJDdiinNSEVfOEKoECACH5BAUKAAIALAAAAAAKAAoAAAIRFISBdusPo3RBzYsjaMIaUQAAOw==");
    border-image-slice: 1;
    border-image-repeat: repeat;
  }
  &.ReactCrop--disabled &.ReactCrop__crop-selection {
    cursor: inherit;
  }
  &.ReactCrop__drag-handle {
    position: absolute;
    width: 0.5625rem;
    height: 0.5625rem;
    background-color: rgba(0, 0, 0, 0.2);
    border: 0.0625rem solid rgba(255, 255, 255, 0.7);
    box-sizing: border-box;
    outline: 0.0625rem solid transparent;
  }
  &.ReactCrop .ord-nw {
    top: 0;
    left: 0;
    margin-top: -0.3125rem;
    margin-left: -0.3125rem;
    cursor: nw-resize;
  }
  &.ReactCrop .ord-n {
    top: 0;
    left: 50%;
    margin-top: -0.3125rem;
    margin-left: -0.3125rem;
    cursor: n-resize;
  }
  &.ReactCrop .ord-ne {
    top: 0;
    right: 0;
    margin-top: -0.3125rem;
    margin-right: -0.3125rem;
    cursor: ne-resize;
  }
  &.ReactCrop .ord-e {
    top: 50%;
    right: 0;
    margin-top: -0.3125rem;
    margin-right: -0.3125rem;
    cursor: e-resize;
  }
  &.ReactCrop .ord-se {
    bottom: 0;
    right: 0;
    margin-bottom: -0.3125rem;
    margin-right: -0.3125rem;
    cursor: se-resize;
  }
  &.ReactCrop .ord-s {
    bottom: 0;
    left: 50%;
    margin-bottom: -0.3125rem;
    margin-left: -0.3125rem;
    cursor: s-resize;
  }
  &.ReactCrop .ord-sw {
    bottom: 0;
    left: 0;
    margin-bottom: -0.3125rem;
    margin-left: -0.3125rem;
    cursor: sw-resize;
  }
  &.ReactCrop .ord-w {
    top: 50%;
    left: 0;
    margin-top: -0.3125rem;
    margin-left: -0.3125rem;
    cursor: w-resize;
  }
  &.ReactCrop__disabled &.ReactCrop__drag-handle {
    cursor: inherit;
  }
  &.ReactCrop__drag-bar {
    position: absolute;
  }
  &.ReactCrop__drag-bar.ord-n {
    top: 0;
    left: 0;
    width: 100%;
    height: 0.375rem;
    margin-top: -0.1875rem;
  }
  &.ReactCrop__drag-bar.ord-e {
    right: 0;
    top: 0;
    width: 0.375rem;
    height: 100%;
    margin-right: -0.1875rem;
  }
  &.ReactCrop__drag-bar.ord-s {
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.375rem;
    margin-bottom: -0.1875rem;
  }
  &.ReactCrop__drag-bar.ord-w {
    top: 0;
    left: 0;
    width: 0.375rem;
    height: 100%;
    margin-left: -0.1875rem;
  }
  &.ReactCrop--new-crop &.ReactCrop__drag-bar,
  &.ReactCrop--new-crop &.ReactCrop__drag-handle,
  &.ReactCrop--fixed-aspect &.ReactCrop__drag-bar {
    display: none;
  }
  &.ReactCrop--fixed-aspect &.ReactCrop__drag-handle.ord-n,
  &.ReactCrop--fixed-aspect &.ReactCrop__drag-handle.ord-e,
  &.ReactCrop--fixed-aspect &.ReactCrop__drag-handle.ord-s,
  &.ReactCrop--fixed-aspect &.ReactCrop__drag-handle.ord-w {
    display: none;
  }
  @media (max-width: 768px), (pointer: coarse) {
    &.ReactCrop__drag-handle {
      width: 1.0625rem;
      height: 1.0625rem;
    }
    &.ReactCrop .ord-nw {
      margin-top: -0.5625rem;
      margin-left: -0.5625rem;
    }
    &.ReactCrop .ord-n {
      margin-top: -0.5625rem;
      margin-left: -0.5625rem;
    }
    &.ReactCrop .ord-ne {
      margin-top: -0.5625rem;
      margin-right: -0.5625rem;
    }
    &.ReactCrop .ord-e {
      margin-top: -0.5625rem;
      margin-right: -0.5625rem;
    }
    &.ReactCrop .ord-se {
      margin-bottom: -0.5625rem;
      margin-right: -0.5625rem;
    }
    &.ReactCrop .ord-s {
      margin-bottom: -0.5625rem;
      margin-left: -0.5625rem;
    }
    &.ReactCrop .ord-sw {
      margin-bottom: -0.5625rem;
      margin-left: -0.5625rem;
    }
    &.ReactCrop .ord-w {
      margin-top: -0.5625rem;
      margin-left: -0.5625rem;
    }
    &.ReactCrop__drag-bar.ord-n {
      height: 0.875rem;
      margin-top: -0.4375rem;
    }
    &.ReactCrop__drag-bar.ord-e {
      width: 0.875rem;
      margin-right: -0.4375rem;
    }
    &.ReactCrop__drag-bar.ord-s {
      height: 0.875rem;
      margin-bottom: -0.4375rem;
    }
    &.ReactCrop__drag-bar.ord-w {
      width: 0.875rem;
      margin-left: -0.4375rem;
    }
  }
`

export const StyledImageControls = styled.div`
  button > svg {
    color: black;
  }
`

export const StyledUploadIcon = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  background: rgb(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex !important;
  align-items: center;
  justify-content: center;

  svg {
    opacity: 1;
    height: 1.75rem;
  }
`

const getDropzoneBackgroundColor = (props) => {
  if (!props.disabled && props.isDragAccept) {
    return "rgba(0, 230, 118, 1)"
  }
  if (!props.disabled && props.isDragReject) {
    return "rgba(255, 23, 68, 1)"
  }
  if (!props.disabled && props.isDragActive) {
    return "rgba(33, 150, 243, 1)"
  }
  return props.theme.colors.white
}

export const StyledDropzone = styled.div`
  outline: none;
  transition: all 0.24s ease-in-out;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-sizing: content-box;
  width: 100%;
  opacity: 1;
  line-height: 2.6;
  font-size: 0.95rem;
  height: 100%;
  background-color: ${(p) => getDropzoneBackgroundColor(p)};

  svg,
  i {
    font-size: 4rem;
    opacity: 0.3;
    margin-bottom: 0.5rem;
  }

  *:focus {
    outline: none;
  }

  p {
    color: ${(p) => p.theme.colors.disabled};
    line-height: 1rem;
    margin: 0.5rem auto;
  }
`
