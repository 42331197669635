import styled from "@emotion/styled"
import { Button } from "@tmu/components/common"

export const StyledPacsDiscountContainer = styled.div`
  background-color: ${(p) => p.theme.colors.whiteSmoke};
  padding: 0.3125rem 1rem 3.5rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    background-color: ${(p) => p.theme.colors.carrot};
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    column-gap: 0.5rem;
    padding: 0.3125rem 1.7rem 3rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    column-gap: 1.5rem;
    padding: 0.3125rem 6rem 5rem;
  }
`

export const StyledImageBox = styled.div`
  img {
    margin: 1rem 0 0;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    img {
      margin-top: 0;
      border-radius: 0.25rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    img {
      border-radius: 0.5rem;
    }
  }
`

export const StyledPacsDiscountDescriptionContainer = styled.div`
  p:first-child {
    padding-top: 1.1875rem;
    padding-bottom: 0.625rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    grid-column: 3/5;
    width: 60%;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
  }
`

export const StyledPacsDiscountTitle = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: ${(p) => p.theme.colors.pitchBlack};

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    color: ${(p) => p.theme.colors.white};
    font-size: 1.5rem;
    line-height: 2rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
    line-height: 2.625rem;
  }
`

export const StyledPacsDiscountText1 = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  font-size: 1rem;
  line-height: 1.5rem;

  p,
  span {
    color: ${(p) => p.theme.colors.pitchBlack};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1rem;
    line-height: 1.375rem;

    p,
    span {
      color: ${(p) => p.theme.colors.white};
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
`

export const StyledPacsDiscountText2 = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  font-size: 1rem;
  line-height: 1.5rem;

  p,
  span {
    color: ${(p) => p.theme.colors.pitchBlack};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    color: ${(p) => p.theme.colors.white};
    font-size: 1rem;
    line-height: 1.375rem;

    p,
    span {
      color: ${(p) => p.theme.colors.white};
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
`

export const StyledPacsDiscountViewPacAllOffersButton = styled.span`
  a {
    display: none;
    font-size: 0.75rem;
    line-height: 1rem;
    color: ${(p) => p.theme.colors.carrot};
    font-family: ${(p) => p.theme.fontFamilies.headline};

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      display: block;
      font-size: 1rem;
      line-height: 1.5rem;
      color: ${(p) => p.theme.colors.white};
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      display: block;
      font-size: 1.25rem;
      line-height: 1.75rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      display: block;
      margin-top: 0.5rem;
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
  }
`
