import SliceCampaigns from "./SliceCampaigns"
import SliceHowItWorks from "./SliceHowItWorks"
import SliceFeaturedOffers from "./SliceFeaturedOffers"
import SliceWhoWeServe from "./SliceWhoWeServe"
import SlicePartners from "./SlicePartners"
import SliceMainPartners from "./SliceMainPartners"
import SliceOtherPartners from "./SliceOtherPartners"
import SliceOffers from "./SliceOffers"
import SliceSupportAndWin from "./SliceSupportAndWin"
import SliceShareCampaign from "./SliceShareCampaign"
import SliceRewardForGood from "./SliceRewardForGood"
import SliceHeroHome from "./SliceHeroHome"
import SliceCounter from "./SliceCounter"
import SliceCharityFriendlyBrand from "./SliceCharityFriendlyBrand"

export {
  SliceCampaigns,
  SliceHowItWorks,
  SliceFeaturedOffers,
  SliceWhoWeServe,
  SlicePartners,
  SliceMainPartners,
  SliceOtherPartners,
  SliceOffers,
  SliceSupportAndWin,
  SliceShareCampaign,
  SliceRewardForGood,
  SliceHeroHome,
  SliceCounter,
  SliceCharityFriendlyBrand
}
