import React from "react"
import PropTypes from "prop-types"
import { LazyImage } from "@tmu/components/common"
import {
  StyledPartners,
  StyledTaxId,
  StyledImageContainer,
} from "../index.styles"
import { useIntl } from "gatsby-plugin-intl"

const Partners = ({
  name,
  slug,
  logo,
  taxId = "",
  onClick,
  imgWidth,
  imgHeight,
}) => {
  const { formatMessage } = useIntl()

  return (
    <StyledPartners
      to={`/charities/${slug}`}
      data-testid="typeahead-partner"
      className="common-typeahead"
      onClick={onClick}>
      <StyledImageContainer>
        <LazyImage
          src={logo}
          altName={`partner-image-${name}`}
          width={imgWidth || 320}
          height={imgHeight || 320}
          fit="cover"
        />
      </StyledImageContainer>
      <div className="typeahead-item">
        <div>{name}</div>
        <StyledTaxId>
          {formatMessage({
            id: "typehead::partners::fiscalCode",
            defaultMessage: "Fiscal Code",
          })}
          &nbsp;
          {taxId}
        </StyledTaxId>
      </div>
    </StyledPartners>
  )
}

Partners.propTypes = {
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
}

Partners.defaultProps = {
  name: "",
  slug: "/",
}

export default Partners
