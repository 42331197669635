import React from "react"
import PropTypes from "prop-types"
import { LazyImage, Logo, Money } from "@tmu/components/common"
import {
  StyledOffers,
  StyledImageContainer,
  StyledOfferContainer,
  StyledPacContainer,
} from "../index.styles"

import theme from "@tmu/global/theme"
import { OFFER_TYPE } from "@tmu/apollo/constants"
const Offers = ({
  name,
  image,
  slug,
  onClick,
  imgWidth,
  imgHeight,
  discount,
  store,
  ...rest
}) => {
  const link =
    rest?.originalData?.offerType === OFFER_TYPE.INTERNAL
      ? `/services/${slug}`
      : `/offers/?offer=true&stores=${store?.id}`
  return (
    <StyledOffers
      to={link}
      data-testid="typeahead-offers"
      className="common-typeahead"
      onClick={onClick}>
      <StyledImageContainer>
        <LazyImage
          src={image}
          altName={`offers-image-${name}`}
          width={imgWidth || 320}
          height={imgHeight || 240}
        />
      </StyledImageContainer>

      <div>
        <StyledOfferContainer className="typeahead-item">
          <StyledPacContainer>
            <Logo
              width={rest?.isWide ? 25 : 15}
              height={rest?.isWide ? 20 : 13}
              fill={theme?.colors?.carrot}
            />
            <span className="no-ellipsis">
              <Money style="percent" value={discount} currency={false} />
            </span>
          </StyledPacContainer>
          <div>{name}</div>
          <StyledImageContainer>
            <LazyImage
              src={store?.logo}
              altName={`store-image-${store?.name}`}
              width={rest?.smallImageWidth || 40}
              height={rest?.smallImageHeight || 22}
              fit="cover"
            />
          </StyledImageContainer>
          <div className="typeahead-item-small">{store?.name}</div>
        </StyledOfferContainer>
      </div>
    </StyledOffers>
  )
}

Offers.propTypes = {
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
}

Offers.defaultProps = {
  name: "",
  slug: "/",
}

export default Offers
