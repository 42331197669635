import { useEffect } from "react"
import { isBrowser } from "@tmu/utils/auth"

const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref || !ref.current || ref.current.contains(event.target)) {
        return
      }

      handler(event)
    }

    if (isBrowser) {
      document.addEventListener("mousedown", listener)
      document.addEventListener("touchstart", listener)
    }

    return () => {
      if (isBrowser) {
        document.removeEventListener("mousedown", listener)
        document.removeEventListener("touchstart", listener)
      }
    }
  }, [ref, handler])
}

export default useOnClickOutside
