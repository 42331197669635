import styled from "@emotion/styled"

export const StyledSlice = styled.div`
  margin: 0 auto;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    .chevron {
      height: 23.5rem;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    .chevron {
      height: 33.5rem;
    }
  }
`
export const StyledPartnerSlice = styled.div`
  margin: 0 auto;
`

export const StyledSliceHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  h3 {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.black};
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin-bottom: 0;
    overflow-wrap: anywhere;
    white-space: break-spaces;
  }

  .link-button-text span {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-size: 1rem;
    line-height: 1.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    h3 {
      font-size: 1.5rem;
      line-height: 2rem;
    }
    .link-button-text span {
      font-family: ${(p) => p.theme.fontFamilies.headline};
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    h3 {
      font-size: 2rem;
      line-height: 2.625rem;
    }
  }
`
