import { gql } from "@apollo/client"

export const HERO_ITEM_QUERY = gql`
  query HeroItem($page: String!, $first: Int, $authenticated: Boolean!) {
    allHeroItems(page: $page, first: $first, authenticated: $authenticated) {
      edges {
        node {
          id
          title
          detail
          image
          isActive
          ordering
          buttonLink
          buttonText
          isButtonLinkExternal
        }
      }
    }
  }
`
