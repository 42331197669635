import React, { Fragment, useState, useContext } from "react"
import { FormattedMessage, useIntl, navigate } from "gatsby-plugin-intl"
import {
  Button,
  TextInput,
  FormErrors,
  TermsAndConditions,
} from "@tmu/components/common"
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons/faExclamationTriangle"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useAuth } from "@tmu/hooks"
import { CardElement } from "@stripe/react-stripe-js"
import {
  FormWrapper,
  StyledForm,
  StyledLink,
  StyledWarning,
} from "./index.styles"
import { Formik } from "formik"
import * as Yup from "yup"
import { cardholderName, agreeTerms } from "@tmu/utils/validation"
import { CheckoutContext } from "@tmu/components/offers/OfferPurchaseForm"
import { PAYMENT_TYPES } from "@tmu/src/apollo/constants"

const CheckoutForm = () => {
  const {
    isProcessing,
    onSubmitPayment,
    productData,
    productError,
    hasLastCheckError,
    paymentType,
  } = useContext(CheckoutContext)
  const { formatMessage } = useIntl()
  const { isLoading } = useAuth()
  const [cardError, setCardError] = useState()
  const validationSchema = Yup.object().shape({
    cardholderName: cardholderName({ formatMessage }),
    agreeTerms: agreeTerms({ formatMessage }),
  })
  const cardErrorList = {
    cardIncomplete: formatMessage({
      id: "forms::checkoutForm::cardIncomplete",
      defaultMessage: `Card information is incomplete!`,
    }),
  }
  const isPayPal = paymentType === PAYMENT_TYPES.PAYPAL

  const handleCardChange = (card, formErrors) => {
    const { error, complete } = card
    const stripeError = error?.code
      ? formatMessage({
          id: `forms::stripe::${error?.code}`,
          defaultMessage: `${error?.message}`,
        })
      : null
    const cardError =
      stripeError || (!complete ? cardErrorList.cardIncomplete : "") || ""
    if (cardError) {
      formErrors.card = cardError
    } else {
      delete formErrors.card
    }
    setCardError(cardError)
  }

  const handleBack = () => {
    navigate("/offers/cart")
  }

  return (
    <FormWrapper>
      <Formik
        initialValues={{
          cardholderName: "",
          agreeTerms: false,
        }}
        validationSchema={validationSchema}
        onSubmit={(values, form) => onSubmitPayment(values, form)}>
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          setTouched,
          isSubmitting,
          handleSubmit,
        }) => (
          <StyledForm onSubmit={handleSubmit} noValidate>
            {!isPayPal && (
              <>
                <TextInput
                  id="cardholderName"
                  name="cardholderName"
                  data-testid="cardholderName"
                  value={values.cardholderName}
                  type="text"
                  label={formatMessage({
                    id: "campaign::donationForm::cardholderName",
                    defaultMessage: "Cardholder Name",
                  })}
                  className="float-container full-width"
                  placeholder=""
                  error={touched.cardholderName && errors.cardholderName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isSubmitting || isProcessing || isLoading}
                />
                <div className="full-width credit_card">
                  <CardElement
                    onChange={(card) => handleCardChange(card, errors)}
                    options={{
                      disabled: isSubmitting || isProcessing || isLoading,
                    }}
                  />
                  <p className="input-feedback">{cardError}</p>
                </div>
              </>
            )}
            <TermsAndConditions
              className="full-width"
              name="agreeTerms"
              errors={errors}
              touched={touched}
              checked={values.agreeTerms}
              onChange={handleChange}
              onClick={() => setTouched({ ...touched, agreeTerms: true })}
              disabled={isSubmitting || isProcessing || isLoading}
            />
            <StyledWarning className="full-width">
              <FontAwesomeIcon icon={faExclamationTriangle} />
              <FormattedMessage
                id="offer::purchaseForm::deliveryTime"
                defaultMessage="It may take up to 72 hours for your order to be processed and delivered."
                tagName="span"
              />
            </StyledWarning>
            {hasLastCheckError && (
              <FormattedMessage
                id="offer::checkout::lastCheckError"
                defaultMessage="Please note that the prices of a offer or offers changed since you placed it in your Shopping Cart. <cart>Click here</cart> to see the updated prices and summary."
                values={{
                  cart: (...chunks) => (
                    <StyledLink key="cart" to="/offers/cart">
                      {chunks.map((chunk, i) => (
                        <Fragment key={i}>{chunk}</Fragment>
                      ))}
                    </StyledLink>
                  ),
                }}>
                {(...chunks) => (
                  <p className="input-feedback full-width">
                    {chunks.map((chunk, i) => (
                      <Fragment key={i}>{chunk}</Fragment>
                    ))}
                  </p>
                )}
              </FormattedMessage>
            )}
            {productData && (
              <FormErrors error={productError} errors={productData.errors} />
            )}
            <div className="full-width">
              <Button
                color="red"
                type="submit"
                label="Submit"
                disabled={
                  !values.agreeTerms ||
                  isSubmitting ||
                  isProcessing ||
                  isLoading ||
                  cardError ||
                  process.env.IS_DISABLE_PAYMENT === "true"
                }>
                {process.env.IS_DISABLE_PAYMENT === "true" ? (
                  <FormattedMessage
                    id="button::text::comingSoon"
                    defaultMessage="Coming Soon"
                  />
                ) : (
                  <FormattedMessage
                    id="ui::continue"
                    defaultMessage="Continue"
                  />
                )}
              </Button>
              <Button label="Back" onClick={handleBack}>
                <FormattedMessage id="ui::back" defaultMessage="Back" />
              </Button>
            </div>
          </StyledForm>
        )}
      </Formik>
    </FormWrapper>
  )
}

export default CheckoutForm
