import React, { useEffect } from "react"
import { injectIntl, navigate } from "gatsby-plugin-intl"
import { useDonations, useToast, useAuth } from "@tmu/hooks"
import { isBrowser } from "@tmu/utils/auth"

const DonateByShopping = () => {
  const { error } = useToast()
  const redirectUrl = isBrowser ? window?.location?.pathname : "/"
  const { isAuthenticated } = useAuth()
  let dst = ""
  if (typeof window !== "undefined") {
    dst = new URL(window?.location?.href).searchParams.get("dst")
  }

  const redirectUrlStr = redirectUrl + "?dst=" + dst

  useEffect(() => {
    if (isBrowser && !isAuthenticated) {
      navigate(`/signin?next=${redirectUrlStr}`)
    }
  }, [isAuthenticated])

  const { getShopByDonationData, data, error: donationError } = useDonations()

  const slug = data?.donateByShopping?.campaign?.slug

  useEffect(() => {
    if (dst?.length) {
      getShopByDonationData(dst)
    }
  }, [])

  useEffect(() => {
    if (slug && typeof window !== "undefined") {
      navigate("/campaigns/" + slug + "/donate?dst=" + dst)
    }
  }, [slug])

  useEffect(() => {
    if (donationError?.message?.indexOf("permission") > -1 && isBrowser) {
      navigate(`/signin?next=${redirectUrlStr}`)
      return
    }
    if (donationError) {
      error(donationError?.message)
      navigate(`/`)
    }
  }, [donationError])
  return <div></div>
}

export default injectIntl(DonateByShopping)
