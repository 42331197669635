import styled from "@emotion/styled"
export const StyledQRButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  font: inherit;
  color: inherit;
  cursor: pointer;
`

export const StyledModalContent = styled.div`
  img {
    width: 12.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
  }
`
export const StyledName = styled.p`
  border-radius: 0.5rem;
  margin: 0 auto 1.5rem;
  background-color: ${(p) =>
    p.isStore ? p.theme.colors.carrot : p.theme.colors.blue};
  color: white;
  padding: 0.5rem 0.2rem;
  font-size: 0.75rem;
  font-weight: bold;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
  }
`
export const StyledActionButtons = styled.div`
  display: flex;
  gap: 1.5rem;
  button {
    width: 100%;
    padding: 0;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    button {
      width: 10.5rem;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    button {
      width: 11.75rem;
    }
  }
`
