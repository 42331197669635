import React, { useRef, useEffect } from "react"
import { ErrorMessage, useFormikContext } from "formik"
import { isBrowser } from "@tmu/utils/auth"

const FormErrors = ({ error, errors = {} }) => (
  <>
    {error &&
      error.graphQLErrors &&
      error.graphQLErrors.map(({ message }, key) => (
        <div className="error" key={key}>
          <div className="input-feedback" data-testid="feedback-error-message">
            {message}
          </div>
        </div>
      ))}

    {errors &&
      Object.entries(errors).map(([key, [{ message }]]) => (
        <div className="error" key={key}>
          <div className="input-feedback" data-testid="feedback-error-message">
            {message}
          </div>
        </div>
      ))}
  </>
)

const FieldError = ({
  name,
  autoFocus = false,
  scrollOpts = { block: "center", behavior: "smooth" },
  ...rest
}) => {
  const { errors, touched } = useFormikContext()
  const fieldRef = useRef()

  const errorArr = Object.keys(errors)

  const tabEn = errorArr?.filter((n) => n.includes("TabEn"))
  const tabIt = errorArr?.filter((n) => n.includes("TabIt"))
  const tabEs = errorArr?.filter((n) => n.includes("TabEs"))

  if (isBrowser) {
    if (tabEn?.length > 0) {
      document?.getElementById("english")?.classList?.add("errorButton")
    }

    if (tabEs?.length > 0) {
      document?.getElementById("spanish")?.classList?.add("errorButton")
    }

    if (tabIt?.length > 0) {
      document?.getElementById("italian")?.classList?.add("errorButton")
    }
  }

  useEffect(() => {
    if (
      errors?.[name] &&
      name.indexOf("En") === -1 &&
      name.indexOf("It") === -1 &&
      name.indexOf("Es") === -1 &&
      fieldRef.current &&
      autoFocus
    ) {
      fieldRef.current.scrollIntoView(scrollOpts)
    }
  }, [errors, name, autoFocus])

  return (
    <div ref={fieldRef} className="field-error">
      {errors && touched && errors?.[name] && touched?.[name] && (
        <div className="input-feedback" data-testid="input-feedback">
          <ErrorMessage {...rest} name={name} id={name} />
        </div>
      )}
    </div>
  )
}

const BaseError = ({ message }) => (
  <div className="input-feedback base" data-testid="input-feedback">
    {message}
  </div>
)

export { FieldError, FormErrors, BaseError }
