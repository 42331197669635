import { gql, useQuery } from "@apollo/client"
import { useApolloApiClients } from "@tmu/apollo/client"
import { useIntl } from "gatsby-plugin-intl"
import { getValueForLocale } from "@tmu/utils/string"
import { MAX_PER_PAGE } from "@tmu/apollo/constants"
import { useEffect, useState } from "react"

const useAllOfferCategories = (options) => {
  const { storefrontClient } = useApolloApiClients()
  const { locale, defaultLocale } = useIntl()
  const [mainCategories, setMainCategories] = useState([])
  const [allOfferCategories, setAllOfferCategories] = useState([])
  const [organizedCategories, setOrganizedCategories] = useState([])
  const variables = { first: MAX_PER_PAGE * 2, isPublic: true }
  const { loading, data, fetchMore, called } = useQuery(
    gql`
      query OfferCategories($first: Int, $after: String) {
        allOfferCategories(first: $first, after: $after) {
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          edges {
            node {
              id
              slug
              nameEn
              nameIt
              nameEs
              image
              icon
              parent {
                id
                name
              }
              hasChildren
            }
          }
        }
      }
    `,
    {
      ...options,
      client: storefrontClient,
      variables,
    }
  )

  const checkChildren = (category, allOfferCats) => {
    const children = allOfferCats?.filter(
      (item) => item?.parent?.id === category?.id
    )

    if (children?.length > 0) {
      category["children"] = children
    }

    return category
  }

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        ...variables,
        after: data?.allOfferCategories?.pageInfo?.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult?.allOfferCategories?.edges
        const pageInfo = fetchMoreResult?.allOfferCategories?.pageInfo

        return newEdges?.length
          ? {
              // Put the new allCampaigns at the end of the list and update `pageInfo`
              // so we have the new `endCursor` and `hasNextPage` values
              allOfferCategories: {
                __typename: prev.allOfferCategories.__typename,
                edges: [...prev.allOfferCategories?.edges, ...newEdges],
                pageInfo,
              },
            }
          : prev
      },
    })
  }

  useEffect(() => {
    if (!data) {
      return
    }

    if (data?.allOfferCategories?.pageInfo?.hasNextPage) {
      handleLoadMore()
      return
    }

    const tempMap = new Map()

    data?.allOfferCategories?.edges?.forEach((item) => {
      tempMap?.set(item?.node?.id, item?.node)
    })

    const tempList = Array.from(tempMap?.values())

    const allOfferCats = loading
      ? []
      : tempList?.map((item) => ({
          ...item,
          name: getValueForLocale(item, "name", locale, defaultLocale),
        })) ?? []

    setAllOfferCategories(allOfferCats)

    const mainCats = allOfferCats?.filter((item) => !item?.parent?.id)

    setMainCategories(mainCats)

    setOrganizedCategories(
      mainCats?.map((item) => checkChildren(item, allOfferCats))
    )
  }, [data, loading])

  return {
    allOfferCategories,
    loading,
    mainCategories,
    organizedCategories,
  }
}

export default useAllOfferCategories
