import styled from "@emotion/styled"

export const StyledSocialSection = styled.div`
  width: 100%;
  max-width: 20.5rem; // width for google button to fit mobile
  margin: 0 auto;

  & > p {
    text-align: center;
  }

  .btn-facebook {
    width: 100%;
    background-color: ${(p) => p.theme.colors.white};
    border: 0.0625rem solid ${(p) => p.theme.colors.formFieldsBorderColor};
    border-radius: 0.25rem;
    cursor: pointer;
    color: ${(p) => p.theme.colors.primaryBrandTextColor};
    font-size: 1.125rem;
    font-weight: ${(p) => p.theme.fontWeights.bold};
    text-align: center;
    position: relative;
    margin: 0 0 1rem;

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    height: 2.5rem; // same with height of google button
    line-height: 3rem;
    padding: 0 1.5rem;

    span {
      white-space: nowrap;
    }

    &:hover {
      border: 0.0625rem solid ${({ theme: { colors } }) => colors.facebook};
      background-color: ${({ disabled, theme: { colors } }) =>
        disabled ? colors["disabled"] : colors.facebook};
      color: ${({ disabled, theme: { colors } }) =>
        disabled ? colors["disabled"] : colors.white};
      i {
        display: block;
        color: white;
      }
    }

    i {
      color: ${(p) => p.theme.colors.facebook};
      font-size: 1.25rem;
      margin-right: 0.5rem;
    }

    color: ${(p) =>
      p.theme.colors.charade}; // same color with color of google button
    cursor: pointer;
    font-family: "Google Sans", arial, sans-serif;
    font-size: 0.875rem;
    height: 2.5rem;
    letter-spacing: 0.0156rem;
  }

  @media screen and (min-width: 432px) {
    max-width: 25rem;
  }
`

export const GoogleSocialButton = styled.div`
  padding-bottom: 0.5rem;
`
