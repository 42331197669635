import styled from "@emotion/styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export const StyledBanner = styled.h1`
  height: 9.375rem;
  line-height: 9.375rem;
  background-color: ${(p) => p.theme.colors.tertiaryBrandColor};
  color: ${(p) => p.theme.colors.primaryBrandTextColor};
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-size: 2.25rem;
  font-weight: ${(p) => p.theme.fontWeights.bold};
  text-align: center;
  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1.3rem;
  }
`

export const StyledSuccessIcon = styled(FontAwesomeIcon)`
  color: ${(p) => p.theme.colors.blue};
  font-size: 2.4rem;
  font-weight: ${(p) => p.theme.fontWeights.light};
  letter-spacing: 0;
  line-height: 6.8125rem;
  justify-self: center;
  align-self: center;
  margin-right: 0.75rem;

  @media only screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1.6rem;
  }
`

export const StyledWrapper = styled.div`
  max-width: ${(p) => p.theme.breakpoints.wide};
  margin: 5rem auto;
  min-height: 80vh;
  height: 100%;
`

export const StyledSuccessContainer = styled.div`
  max-width: ${(p) => p.theme.breakpoints.desktop};
  margin: 3.125rem auto 6.875rem;
  border-radius: 0.4375rem;
  background-color: ${(p) => p.theme.colors.pampasBackgroundColor};
  padding: 1.1875rem;
`

export const StyledSuccessDocument = styled.div`
  box-sizing: border-box;
  border: 0.0625rem solid rgba(151, 151, 151, 0.5);
  padding: 1.5625rem 1.25rem 2rem;
  border-radius: 0.4375rem;
  display: grid;
  grid-template-columns: 0.25fr 0.5fr 0.25fr;
  grid-template-areas: ". text .";

  @media only screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    grid-template-columns: 0.1fr 0.7fr 0.2fr;
  }

  @media only screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    grid-template-columns: 1fr;
    grid-template-areas: "." "text";
    grid-gap: 1.875rem;
  }
`

export const StyledSuccessDetails = styled.div`
  grid-area: text;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    color: rgba(99, 106, 117, 1);
    font-size: 1.0625rem;
    letter-spacing: 0;
    line-height: 1.8125rem;
    text-align: center;
    a,
    span {
      color: rgba(10, 157, 189, 1);
      font-weight: ${(p) => p.theme.fontWeights.bold};
    }
  }

  h2 {
    display: flex;
    justify-content: center;
  }
`

export const StyledSuccessOfferTitle = styled.h2`
  border-top: 0.0625rem solid rgba(151, 151, 151, 1);
  border-bottom: 0.0625rem solid rgba(151, 151, 151, 1);
  color: rgba(17, 17, 17, 1);
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-size: 1.5rem;
  font-weight: ${(p) => p.theme.fontWeights.semiBold};
  letter-spacing: 0;
  line-height: 1.8125rem;
  text-align: center;
  padding: 1.25rem 0;
  margin: 1.875rem 0 2.5rem;
  display: flex;
  align-items: center;
`
