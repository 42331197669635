import React from "react"

import {
  StyledBenefitCard,
  StyledIcon,
  StyledDescription,
  StyledTitle,
  StyledFontIcon,
} from "./index.styles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const BenefitCard = ({
  icon,
  title,
  desc,
  fontIcon,
  carrot,
  blue,
  ...rest
}) => {
  return (
    <StyledBenefitCard {...rest}>
      {fontIcon ? (
        <StyledFontIcon carrot={carrot} blue={blue}>
          <FontAwesomeIcon icon={fontIcon} />
        </StyledFontIcon>
      ) : (
        icon && <StyledIcon src={icon} alt="benefit icon" />
      )}
      {title && <StyledTitle>{title}</StyledTitle>}
      {desc && <StyledDescription>{desc}</StyledDescription>}
    </StyledBenefitCard>
  )
}

export default BenefitCard
