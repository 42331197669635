import React, { useEffect } from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import { graphql, useStaticQuery } from "gatsby"
import FooterDesktop from "./FooterDesktop"
import { useAuth } from "@tmu/hooks"

const Footer = () => {
  const { isLoading } = useAuth()
  useEffect(() => {
    if (isLoading) {
      return
    }
  }, [isLoading])

  const { site } = useStaticQuery(
    graphql`
      query Footer {
        site {
          siteMetadata {
            supportedLanguages {
              id
              label
            }
          }
        }
      }
    `
  )

  const supportEmail = "support@trustmeup.com"

  const extendedFooterSections = {
    exploreSection: [
      <FormattedMessage id="nav::explore" defaultMessage="Explore" />,
      [
        {
          id: "subtitle-support-non-profit",
          link: "/campaigns?campaign=true&charity=true&event=true",
          label: "Explore - Support non-profits",
          subTitle: (
            <FormattedMessage
              id="sliceHeroHome::donateTitle"
              defaultMessage="Donate"
            />
          ),
        },
        {
          id: "subtitle-explore-pac-offers",
          link: "/offers?offer=true&store=true",
          label: "Explore - Pac Offers",
          subTitle: (
            <FormattedMessage
              id="sliceHeroHome::shopTitle"
              defaultMessage="Shop"
            />
          ),
        },
        {
          id: "subtitle-explore-fundraise",
          link: "/fundraise",
          label: "Explore - Fundraise",
          subTitle: (
            <FormattedMessage
              id="fundraise::common::pageTitle"
              defaultMessage="Fundraise"
            />
          ),
        },
        {
          id: "subtitle-explore-extension",
          link: "/extension",
          label: "Explore - Extension",
          subTitle: (
            <FormattedMessage
              id="extension::footer::title"
              defaultMessage="TMU Extension"
            />
          ),
        },
        // {
        //   id: "subtitle-explore-digital-banking",
        //   link: "/digital-banking/personal/",
        //   label: "Explore - Digital Banking",
        //   subTitle: (
        //     <FormattedMessage
        //       id="footer::digitalBanking"
        //       defaultMessage="Digital Banking"
        //     />
        //   ),
        // },
        {
          id: "legal::pageTitle",
          link: "/legal",
          label: "Terms and Policies",
          subTitle: (
            <FormattedMessage
              id="legal::pageTitle"
              defaultMessage="Terms and Policies"
            />
          ),
        },
        {
          id: "subtitle-explore-help-center",
          directLink: "https://intercom.help/trustmeup-help/en/",
          link: "",
          label: "Explore - Help Center",
          subTitle: (
            <FormattedMessage
              id="footer::helpCenter"
              defaultMessage="Help Center"
            />
          ),
        },
      ],
    ],
    aboutSection: [
      <FormattedMessage id="footer::about" defaultMessage="About" />,
      [
        {
          id: "subtitle-about-us",
          link: "/about-us",
          label: "About - About Us",
          subTitle: (
            <FormattedMessage id="footer::aboutUs" defaultMessage="About Us" />
          ),
        },
        {
          id: "subtitle-pacs",
          link: `/pacs/`,
          label: "What are PACs",
          subTitle: (
            <FormattedMessage
              id="footer::pacs"
              defaultMessage="What are PACs"
            />
          ),
        },
        {
          id: "subtitle-blog",
          directLink: "https://blog.trustmeup.com/",
          label: "About - Blog",
          subTitle: (
            <FormattedMessage id="footer::blog" defaultMessage="Blog" />
          ),
        },
        {
          id: "subtitle-careers",
          directLink: "https://jobs.trustmeup.com/",
          label: "About - Careers",
          subTitle: (
            <FormattedMessage id="footer::careers" defaultMessage="Careers" />
          ),
        },
      ],
    ],
    joinTMUSection: [
      <FormattedMessage id="footer::joinTMU" defaultMessage="Join TrustMeUp" />,

      [
        {
          id: "subtitle-join-as-donor",
          link: "/join/donors",
          label: "Join TMU  - Join as a Donor",
          subTitle: (
            <FormattedMessage
              id="footer::joinAsDonor"
              defaultMessage="Join as Donor"
            />
          ),
        },
        {
          id: "subtitle-how-it-works-non-profit",
          link: "/join/ngo",
          label: "Join TMU  - Join as a Non-Profit",
          subTitle: (
            <FormattedMessage
              id="footer::joinAsNonProfit"
              defaultMessage="Join as a Non-Profit"
            />
          ),
        },
        {
          id: "subtitle-join-as-merchant",
          link: "/join/merchants",
          label: "Join TMU  - Join as a Merchant",
          subTitle: (
            <FormattedMessage
              id="footer::joinAsMerchant"
              defaultMessage="Join as Merchant"
            />
          ),
        },
        {
          id: "subtitle-join-as-corporate",
          link: "/join/corporate",
          label: "Join TMU - Corporate",
          subTitle: (
            <FormattedMessage
              id="footer::joinAsCorporate"
              defaultMessage="Corporate"
            />
          ),
        },
      ],
    ],
  }

  const footerDownSections = {
    termsListItems: [],
    policyListItems: [],
    languages: [
      <FormattedMessage
        id="footer::languages::languages"
        defaultMessage="Languages"
      />,
      site.siteMetadata.supportedLanguages.map((key) => {
        return {
          id: `subtitle-language-${key.id}`,
          subTitle: (
            <FormattedMessage
              id={`footer::languages::${key.label.toLowerCase()}`}
              defaultMessage={key.label}
            />
          ),
          language: key.id,
          label: key.label,
          directLink: key?.directLink,
        }
      }),
    ],
  }

  return (
    <>
      <FooterDesktop
        exploreSection={extendedFooterSections.exploreSection[1]}
        aboutSection={extendedFooterSections.aboutSection[1]}
        joinTMUSection={extendedFooterSections.joinTMUSection[1]}
        languages={footerDownSections.languages[1]}
        supportEmail={supportEmail}
      />
    </>
  )
}

export default Footer
