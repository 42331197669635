import styled from "@emotion/styled"
import { TextInput } from "@tmu/components/common"

export const StyledPayAmount = styled(TextInput)`
  &.float-container {
    height: 3.75rem;
    margin-bottom: 0;
    label {
      transform: translate(4.5rem, -3.5rem) scale(0.8) !important;
      font-size: 1rem;
      width: auto;
      padding: 0 0.5rem;
    }
    input {
      padding: 0.4375rem 0 0 4.5rem !important;
      height: 3rem;
      margin-right: 0.125rem;

      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      /* Firefox */
      -moz-appearance: textfield;
    }
    @media only screen and (max-width: 62.5rem) {
      label {
        transform: translate(0.5rem, -3.5rem) scale(0.8) !important;
      }
      input {
        padding-left: 1rem !important;
      }
      .input-feedback {
        padding-left: 0;
      }
    }
  }

  .pay-amount__currency {
    background-color: ${(p) => p.theme.colors.blue};
    font-family: ${(p) => p.theme.fontFamilies.headline};
    box-sizing: border-box;
    position: absolute;
    top: -0.0625rem;
    left: -0.0625rem;
    height: 3.75rem;
    line-height: 3.75rem;
    width: 5.75rem;
    border: 0.0625rem solid ${(p) => p.theme.colors.blue};
    border-radius: 0.3125rem 0 0 0.3125rem;
    color: ${(p) => p.theme.colors.white};
    font-size: 2rem;
    text-align: center;
    z-index: 1;
  }
  .pay-amount__earned-pacs {
    position: absolute;
    right: 0.125rem;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    color: ${(p) => p.theme.colors.disabled};
    font-size: 0.9375rem;
    align-items: center;
    justify-content: center;
    max-width: ${(p) => p.theme.breakpoints.phone};
    z-index: 1;

    & > p {
      width: 12.5rem;
      margin-bottom: 0;
    }
    div {
      height: 2.5rem;
      padding: 0 0.3125rem;
      background-color: ${(p) => p.theme.colors.blue};
      border-radius: 0.1875rem;
      color: ${(p) => p.theme.colors.white};
      margin: 0 0.7375rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.25rem;
      font-weight: ${(p) => p.theme.fontWeights.bold};
      line-height: 1.5rem;
      svg {
        height: 1.25rem;
        width: 1.5625rem;
        margin-right: 0.625rem;
        color: ${(p) => p.theme.colors.white};
      }
    }
  }
`
export const CurrencyInputWrapper = styled.div`
  &.full-width {
    grid-column: 1 / 3;
  }
  &.min-max-input {
    display: flex;
    .min-input {
      border-radius: 0.3125rem 0 0 0.3125rem;
    }
    .max-input {
      border-radius: 0 0.3125rem 0.3125rem 0;
      input {
        padding-left: 0.3125rem !important;
      }
      label {
        transform: translate(0.5rem, 1.3rem) scale(1);
      }
      &.active label {
        transform: translate(0, -0.5rem) scale(0.75);
      }
    }
  }
  @media only screen and (max-width: 62.5rem) {
    .float-container {
      padding-left: 0.5rem;
      label {
        font-size: 0.8rem;
      }
    }
    .pay-amount input {
      padding-left: 1.1rem !important;
    }
    .pay-amount.float-container.active label {
      -webkit-transform: translate(5.5rem, -0.5rem) scale(0.75);
      transform: translate(1.1rem, -0.5rem) scale(0.75);
    }
    .pay-amount.float-container label {
      -webkit-transform: translate(6rem, 1.3rem) scale(1);
      transform: translate(2rem, 1.2rem) scale(1);
    }
    .pay-amount__currency {
      width: 2rem !important;
    }
    .pay-amount__earned-pacs {
      max-width: ${(p) => p.theme.breakpoints.phone}!important;
      p {
        display: none;
      }
    }
  }
`
