import styled from "@emotion/styled"

export const StyledPacContainer = styled.div`
  display: flex;
  grid-gap: 0.5rem;
  align-items: center;
  justify-content: end;
  min-width: 5rem;
`
export const StyledDateContainer = styled.div`
  min-width: 5rem;
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    min-width: unset;
  }
`

export const StyledPageWrapper = styled.div`
  .expanded-row {
    background-color: ${(p) => p.theme.colors.whiteSmoke};
  }

  .expanding-row {
    background-color: ${(p) => p.theme.colors.whiteSmoke};
    td {
      padding-top: 2rem;
    }
  }

  .clickable {
    cursor: pointer;
  }
`
export const StyledTable = styled.div`
  margin-left: -1rem;
  margin-right: unset;
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-right: 0rem;
  }

  table {
    margin-left: unset;
    @media only screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      margin-left: -1rem;
    }
  }

  td:first-child,
  tr {
    padding-left: 1.25rem !important;
    @media only screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      padding-left: 2.25rem !important;
    }
  }

  thead > tr > th:first-child {
    padding-left: 1rem !important;
    @media only screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      padding-left: 2rem !important;
    }
  }

  td {
    min-width: 5rem;
  }

  tr > td:last-child {
    width: 0.5rem;
    min-width: unset;
  }

  td,
  tr,
  span,
  div,
  p {
    font-weight: ${(p) => p.theme.fontWeights.semiBolder} !important;
    font-family: ${(p) => p.theme.fontFamilies.headline} !important;
    @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-weight: ${(p) => p.theme.fontWeights.liteMedium} !important;
      font-family: ${(p) => p.theme.fontFamilies.regular} !important;
    }
  }

  tr th * {
    font-weight: ${(p) => p.theme.fontWeights.semiBolder} !important;
    font-family: ${(p) => p.theme.fontFamilies.headlineBig} !important;
  }

  .active {
    svg {
      color: ${(p) => p.theme.colors.blue};
    }
  }
  .blue-text {
    color: ${(p) => p.theme.colors.blue};
  }

  .align-right {
    text-align: right !important;
    justify-content: end !important;
  }

  .align-center {
    text-align: center !important;
  }

  .mobile-date {
    color: ${(p) => p.theme.colors.disabled};
    font-size: 0.625rem;
    font-weight: ${(p) => p.theme.fontWeights.liteMedium} !important;
    font-family: ${(p) => p.theme.fontFamilies.regular} !important;
  }

  .no-wrap {
    text-wrap: nowrap;
  }

  .lite-font {
    font-weight: ${(p) => p.theme.fontWeights.liteMedium} !important;
    font-family: ${(p) => p.theme.fontFamilies.regular} !important;
  }
`
