import queryString from "query-string"
import React, { Fragment, useRef, useState, useEffect } from "react"

import {
  StyledSuccessDetailTitle,
  StyledSuccessPageImage,
} from "./index.styles"

import { FormattedMessage, useIntl, Link } from "gatsby-plugin-intl"

import { Button, LazyImage } from "@tmu/components/common"

const { CLOUDFLARE_IMAGE_URL } = process.env
import { navigate } from "gatsby-plugin-intl"

const Onboarded = ({ offer }) => {
  const paramOptions = { arrayFormat: "comma" }
  const params = queryString.parse(location.search, paramOptions)
  const merchantId = params?.merchant
  const isMerchantOnline = params?.type === "online"
  const isMerchantOffline = params?.type === "offline"
  const isMerchantInternal = params?.type === "internal"
  const isMerchantCorporate = false

  const handleEmbedded = () => {
    navigate("/dashboard/profile")
  }

  return (
    <>
      <StyledSuccessDetailTitle data-testid="donation-success-details">
        <FormattedMessage
          id="merchant::selfRegistrationForm::onboarded"
          defaultMessage="You have been onboarded!"
        />
        !
      </StyledSuccessDetailTitle>
      <StyledSuccessPageImage>
        <LazyImage
          altName="donation-success"
          src={
            CLOUDFLARE_IMAGE_URL + "/static/assets/images/tmu_celebration.gif"
          }
          width="400"
          fit="cover"
        />
      </StyledSuccessPageImage>
      <div style={{ textAlign: "center" }}>
        {isMerchantInternal ? (
          <>
            <p>
              <FormattedMessage
                id="merchant::selfRegistrationForm::review"
                defaultMessage="Stripe is reviewing your documentation."
                values={{
                  strong: (...chunks) => (
                    <span>
                      {chunks.map((chunk, i) => (
                        <Link
                          key={i}
                          to={`/dashboard/profile/`}
                          className="finance"
                          aria-label="finance">
                          {chunk}
                        </Link>
                      ))}
                    </span>
                  ),
                }}
              />
            </p>
            <FormattedMessage
              id="merchant::selfRegistrationForm::notify"
              defaultMessage="We'll notify you by email once you're ready to create your first service."
              tagName="p"
            />
          </>
        ) : isMerchantOnline ? (
          <>
            <p>
              <FormattedMessage
                id="merchant::selfRegistrationForm::review2"
                defaultMessage="You are successfully onboarded but you can't start selling before installing your plugin. Please contact <strong>support@trustmeup.com</strong> to install plugin."
                values={{
                  strong: (...chunks) => (
                    <span>
                      {chunks.map((chunk, i) => (
                        <Link
                          key={i}
                          to={`mailto:support@trustmeup.com`}
                          className="finance"
                          aria-label="finance">
                          {chunk}
                        </Link>
                      ))}
                    </span>
                  ),
                }}
              />
            </p>
          </>
        ) : isMerchantOffline ? (
          <>
            <p>
              <FormattedMessage
                id="merchant::selfRegistrationForm::review3"
                defaultMessage="You are successfully onboarded. Please contact <strong>support@trustmeup.com</strong> to start creating discount campaigns."
                values={{
                  strong: (...chunks) => (
                    <span>
                      {chunks.map((chunk, i) => (
                        <Link
                          key={i}
                          to={`mailto:support@trustmeup.com`}
                          className="finance"
                          aria-label="finance">
                          {chunk}
                        </Link>
                      ))}
                    </span>
                  ),
                }}
              />
            </p>
          </>
        ) : null}
      </div>
      <br />
      <div className="button-wrapper">
        <Button
          type="button"
          color="carrot"
          onClick={handleEmbedded}
          className="full-width">
          <FormattedMessage
            id="merchant::selfRegistrationForm::seeFinance"
            defaultMessage="see your dashboard"
          />
        </Button>
      </div>
    </>
  )
}

export default Onboarded
