import React, { Fragment, useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Dropdown } from "@tmu/components/common"
import { StyledSortSelector, StyledListItem, StyledList } from "./index.styles"
import { useIntl } from "gatsby-plugin-intl"
import theme from "@tmu/global/theme"
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"

const SortSelector = ({
  handleSort,
  options,
  defaultValue,
  isMobile,
  isOfferList,
  className,
}) => {
  const { formatMessage } = useIntl()
  const [selectedItem, setSelectedItem] = useState(null)
  const { isWide } = getAllScreenTypes()
  useEffect(() => {
    setSelectedItem(defaultValue)
  }, [])

  const handleChange = (values) => {
    setSelectedItem(values?.value)
    if (handleSort) {
      handleSort(values)
    }
  }

  const mobileVersion = (
    <StyledList>
      {options?.map((item, index) => {
        return (
          <Fragment key={index}>
            {index === 0 && <hr />}
            <StyledListItem
              onClick={() => handleChange(item)}
              isOfferList={isOfferList}>
              <div>{item?.label}</div>
              {selectedItem == item?.value && (
                <div>
                  <FontAwesomeIcon icon={faCheck} />
                </div>
              )}
            </StyledListItem>
            <hr />
          </Fragment>
        )
      })}
    </StyledList>
  )

  const dropdownHeight = isWide ? "3.375rem" : "2.5rem"

  const selectStyles = {
    control: {
      height: dropdownHeight,
      paddingTop: "0",
    },
    valueContainer: {
      height: dropdownHeight,
      paddingTop: "0",
    },
    inputContainer: {
      height: dropdownHeight,
      paddingTop: "0",
    },
    input: {
      padding: "0",
      margin: "0",
    },
  }

  return (
    <StyledSortSelector className={className}>
      {isMobile ? (
        mobileVersion
      ) : (
        <Dropdown
          name="sort"
          variant="condensed"
          placeholder={formatMessage({
            id: "sortSelect::sort",
            defaultMessage: "Sorting Options",
          })}
          defaultValue={defaultValue}
          options={options}
          onChange={(values) => handleChange(values)}
          defaultSelectStyles={{
            ...selectStyles,
            control: {
              borderRadius: "0.25rem",
              borderColor: theme.colors.disabled,
              height: isWide ? "3.5rem" : "2.5rem",
              fontSize: isWide ? "1rem" : "0.75rem",
              textAlign: "left",
            },
          }}
        />
      )}
    </StyledSortSelector>
  )
}

export default SortSelector

SortSelector.propTypes = {
  handleSort: PropTypes.func,
  options: PropTypes.array,
  defaultValue: PropTypes.string,
  isMobile: PropTypes.bool,
  isOfferList: PropTypes.bool,
  className: PropTypes.string,
}
