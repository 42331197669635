import styled from "@emotion/styled"

export const StyledUSPacsWrapper = styled.div`
  background: ${(p) => p.theme.colors.carrot};
  color: ${(p) => p.theme.colors.white};
  padding: 2.5rem 1rem;
  margin-top: 3.5rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 0;
    max-width: ${(p) => p.theme.breakpoints.tablet};
    margin: auto;
    > div {
      padding: 2rem 1.5rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    max-width: ${(p) => p.theme.breakpoints.desktop};
    > div {
      padding: 2rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    max-width: ${(p) => p.theme.breakpoints.wide};
    > div {
      padding: 4rem 6rem;
    }
  }
`

export const StyledUSPacsTitle = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};

  font-size: 1.5rem;
  line-height: 2rem;

  margin-bottom: 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 2rem;
    line-height: 2.625rem;

    margin-bottom: 1.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin-bottom: 1rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 3rem;
    line-height: 4.0625rem;

    margin-bottom: 2rem;
  }

  .pac-icon {
    margin-left: 0.25rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      margin-left: 0.375rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      margin-left: 0.5rem;
    }
  }

  .pac-amount {
    display: inline-block;
  }
`

export const StyledUSPacsContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: flex;
    flex-direction: unset;
    justify-content: space-between;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
  }

  .link-button-text {
    svg {
      color: ${(p) => p.theme.colors.white};

      display: flex;

      @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
        display: unset;
      }
    }

    span {
      font-family: ${(p) => p.theme.fontFamilies.regular};
      font-style: ${(p) => p.theme.fontStyles.normal};
      font-weight: ${(p) => p.theme.fontWeights.liteMedium};
      color: ${(p) => p.theme.colors.white};

      font-size: 1rem;
      line-height: 1.25rem;

      text-align: left;

      @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
        text-align: right;
      }

      @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
        font-size: 1.5rem;
        line-height: 2.1875rem;
      }

      @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      }
    }
  }

  .pacs-link {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};

    font-size: 1rem;
    line-height: 2.1875rem;

    text-align: left;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      text-align: right;
      line-height: 1.5rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      line-height: 2.1875rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 2rem;
      line-height: 2.625rem;
    }
  }
`
