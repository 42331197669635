import React, { Fragment } from "react"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { StyledLabel, StyledLink } from "./index.styles"

const TermsAndConditionsLabel = ({
  termsLink = "/legal/donor-terms/17",
  policyLink = "/legal/privacy-policy/29",
  ...rest
}) => {
  const { locale } = useIntl()

  return (
    <StyledLabel {...rest}>
      <FormattedMessage
        id="ui::agreeToTerms"
        defaultMessage="I agree to the <terms>Terms of Use</terms> and understand the <privacy>Privacy Policy</privacy>."
        values={{
          terms: (...chunks) => (
            <StyledLink
              data-testid="btn-terms-of-use"
              key="terms"
              name="termsLink"
              variant="text">
              {chunks.map((chunk, i) => (
                <a href={`/${locale}${termsLink}`} target="_blank" key={i}>
                  {chunk}
                </a>
              ))}
            </StyledLink>
          ),
          privacy: (...chunks) => (
            <StyledLink
              data-testid="btn-privacy"
              key="privacy"
              name="policyLink"
              variant="text">
              {chunks.map((chunk, i) => (
                <a href={`/${locale}${policyLink}`} target="_blank" key={i}>
                  {chunk}
                </a>
              ))}
            </StyledLink>
          ),
        }}>
        {(...chunks) => (
          <p className="text-message">
            {chunks.map((chunk, i) => (
              <Fragment key={i}>{chunk}</Fragment>
            ))}
          </p>
        )}
      </FormattedMessage>
    </StyledLabel>
  )
}

export default TermsAndConditionsLabel
