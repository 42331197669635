import React, { useState, useRef, useEffect, Fragment } from "react"
import { FormattedMessage, navigate, useIntl, Link } from "gatsby-plugin-intl"
import {
  Button,
  TextInput,
  CheckboxContainer,
  FormErrors,
  TermsAndConditions,
  Spinner,
  CreditCardSelector,
  RadioSelect,
  BaseError,
  PaymentMethods,
  SocialLoginComponent,
  Money,
  OnSubmitValidationError,
} from "@tmu/components/common"
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons/faCircleInfo"
import { useAuth, useToast, useAddressValidation } from "@tmu/hooks"
import { useMutation } from "@apollo/client"
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js"
import { UPDATE_ME_MUTATION } from "@tmu/apollo/storefront/mutations/user"
import { setCredentials } from "@tmu/utils/auth"
import {
  FormWrapper,
  StyledPageWrapper,
  StyledFormRow,
  StyledErrorMessage,
  StyledAgreeTerms,
  StyledButtonContainer,
  StyledUseAnotherCardButton,
  StyledFlexFormRow,
  StyledSingleDonationContainer,
  StyledQuestionIcon,
  StyledSectionTitle,
  StyledSectionBox,
  StyledBlueText,
  StyledFlexBox,
  StyledTitle,
  StyledWarningTitle,
  StyledLoginActions,
} from "./index.styles"
import { Tooltip } from "react-tooltip"
import { START_ORDER_PAYMENT_CHECKOUT_MUTATION } from "@tmu/apollo/storefront/mutations/cart"
import { Formik, Form } from "formik"
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons/faInfoCircle"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as Yup from "yup"
import {
  cardholderName,
  agreeTerms,
  firstName,
  displayName,
  email as emailValidation,
  fiscalCode,
  vat,
  phone,
  password,
  campanyName,
} from "@tmu/utils/validation"
import { cardElementStyles } from "@tmu/global/GlobalStyle"
import { useCreditCards, useUserExists } from "@tmu/hooks"
import {
  ACCOUNT_TYPE,
  PAYMENT_TYPES,
  REQUIRED_FIELD_SYMBOL,
} from "@tmu/apollo/constants"
import { isBrowser, isTokenExpired } from "@tmu/utils/auth"
import { get } from "@tmu/utils/storage"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import SingleDonation from "../DonationForm/SingleDonation"
import {
  StyledCardErrorWrapper,
  StyledEmailLabel,
} from "../DonationForm/index.styles"
import { useApolloApiClients } from "@tmu/apollo/client"
import { usePayment } from "@tmu/src/hooks"
import { Spacer } from "@tmu/src/global/page-addons/detail-page.styles"
import queryString from "query-string"

const ServicePurchaseForm = ({
  onComplete,
  charityId,
  variant,
  donationAmount,
  displayCardSection,
  order,
  handleDiscardOrder,
  createInitialOrder,
  isInvoiceMandatory,
  slug,
  discountAmount,
  internalOrderValues,
  isQuantityAvailable,
}) => {
  const {
    user,
    callUserProfile,
    signIn,
    loading,
    setIsAuthenticated,
    updateCommunicationLanguageWithBrowser,
  } = useAuth()
  const stripe = useStripe()
  const [alreadyHaveAccount, setAlreadyHaveAccount] = useState(false)
  const [addressLoading, setAddressLoading] = useState(false)
  const { checkAddress, setAddressError, addressError } = useAddressValidation()
  const [isCompany, setIsCompany] = useState(user?.accountType === "BUSINESS")
  const [isNeedInvoice, setIsNeedInvoice] = useState(!!isInvoiceMandatory)
  const [showLogin, setShowLogin] = useState(false)
  const { checkUserExist, doesUserExist } = useUserExists()
  const { error: errorToaster } = useToast()

  useEffect(() => {
    if (doesUserExist) {
      setAlreadyHaveAccount(true)
      setShowLogin(true)
    }
  }, [doesUserExist])

  const processPaypal = async () => {
    const searchStr = isBrowser ? window?.location?.search : ""
    const params = queryString.parse(searchStr, { arrayFormat: "comma" })

    if (params?.payment_intent) {
      if (params?.redirect_status === "succeeded") {
        const successPageURL = location.href.replace(
          "/checkout",
          "/checkout/success"
        )
        window.location.href = successPageURL
        return <Spinner />
      } else if (params?.redirect_status === "failed") {
        const paymentIntentClientSecret = params?.payment_intent_client_secret
        const rollbackUrl = new URL(location.href)
        rollbackUrl.searchParams.delete("redirect_status")
        rollbackUrl.searchParams.delete("payment_intent")
        rollbackUrl.searchParams.delete("payment_intent_client_secret")
        window?.history?.pushState({}, null, rollbackUrl.toString())
        const result = await stripe.retrievePaymentIntent(
          paymentIntentClientSecret
        )
        const errorData =
          result?.error ?? result?.paymentIntent?.last_payment_error?.message

        if (errorData) {
          errorToaster(errorData)
        }
      }
    }
  }
  if (stripe) {
    processPaypal()
  }
  const isAuthenticated = !isTokenExpired(get("token"))
  const [wantsRegister, setWantsRegister] = useState(!isAuthenticated)

  const { formatMessage, locale } = useIntl()

  const { email, country } = { ...user }
  const [isCardEmpty, setIsCardEmpty] = useState(true)

  const {
    callCreateClientSecret,
    callAllSavedAccounts,
    allSavedAccountsData,
    allCards,
  } = useCreditCards({
    callImmediate: false,
  })
  const elements = useElements({ locale })
  const creditCardRef = useRef()
  const creditCardRefError = useRef()
  const { confirmPaymentMethod } = usePayment()
  const [selectedPaymentType, setSelectedPaymentType] = useState(
    PAYMENT_TYPES.CARD
  )
  const [loginLoading, setLoginLoading] = useState(false)

  const isPayPal = selectedPaymentType === PAYMENT_TYPES.PAYPAL
  const isBankTransfer = selectedPaymentType === PAYMENT_TYPES.CUSTOMER_BALANCE
  const [processing, setProcessing] = useState(false)
  const [cardError, setCardError] = useState()
  const [selectedCard, setSelectedCard] = useState()
  const [useSavedCard, setUseSavedCard] = useState(isAuthenticated)
  const [submittedOnce, setSubmittedOnce] = useState(false)
  const { isTablet, isDesktop, isWide } = getAllScreenTypes()
  const { storefrontClient } = useApolloApiClients()
  const hasSavedCards = allCards?.length > 0
  const { CLOUDFLARE_IMAGE_URL } = process.env

  const [updateProfile] = useMutation(UPDATE_ME_MUTATION, {
    client: storefrontClient,
  })

  const [stripeErrorData, setStripeErrorData] = useState(null)
  const defaultErrorMessage = formatMessage({
    id: "forms::error::stripeClientSecret",
    defaultMessage: "There was a problem during the transaction",
  })
  let validationObject = {
    cardholderName:
      !isPayPal &&
      !isBankTransfer &&
      !useSavedCard &&
      cardholderName({ formatMessage }),
    agreeTerms: agreeTerms({ formatMessage }),
  }

  const createOrderData = (card) => {
    if (!card?.cardId) {
      return
    }

    const cardType =
      card?.brand?.toLowerCase() === PAYMENT_TYPES.PAYPAL
        ? PAYMENT_TYPES.PAYPAL
        : PAYMENT_TYPES.CARD

    createOrder(cardType)
  }

  const createOrder = (cardType) => {
    createInitialOrder({ stripePaymentMethodType: cardType }).then((r) => {
      callCreateClientSecret({
        variables: { stripePaymentMethodType: cardType },
      })
    })
  }
  const emailInformationText = formatMessage({
    id: "donation::emailInformationText",
    defaultMessage: "We will send the donation receipt to this address",
  })
  const registerOptions = [
    {
      id: "ok",
      label: formatMessage({
        id: "service::checkoutForm::registerOption",
        defaultMessage: "Register",
      }),
    },
    {
      id: "not",
      label: formatMessage({
        id: "service::checkoutForm::notRegisterOption",
        defaultMessage: "Do not register",
      }),
    },
  ]

  const needInvoiceTypes = [
    {
      id: "no",
      label: formatMessage({
        id: "dashboard::campaigns::modalNo",
        defaultMessage: "No",
      }),
    },
    {
      id: "yes",
      label: formatMessage({
        id: "dashboard::campaigns::modalYes",
        defaultMessage: "Yes",
      }),
    },
  ]

  validationObject = {
    ...validationObject,
    firstName: isCompany
      ? campanyName({ formatMessage })
      : firstName({ formatMessage }),
    lastName: isCompany ? null : displayName({ formatMessage }),
    password:
      wantsRegister &&
      password({
        formatMessage,
      }),
    confirmPassword:
      wantsRegister &&
      Yup.string()
        .required(
          formatMessage({
            id: "forms::passwordRequiredError",
            defaultMessage: "A password is required.",
          })
        )
        .test(
          "password-match",
          formatMessage({
            id: "signup::password::notMatch",
            defaultMessage: "Both passwords need to be the same",
          }),
          function (value) {
            return value === this.resolve(Yup.ref("password"))
          }
        ),
    intendedEmail: emailValidation({ formatMessage }),
    intendedConfirmEmail: Yup.string()
      .when("intendedEmail", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: () =>
          Yup.string().oneOf(
            [Yup.ref("intendedEmail")],
            formatMessage({
              id: "signup::email::notMatch",
              defaultMessage: "Both emails need to be the same",
            })
          ),
        otherwise: () => {},
      })
      .email(
        formatMessage({
          id: "forms::emailInvalidError",
          defaultMessage: "Enter a correct email",
        })
      )
      .required(
        formatMessage({
          id: "forms::emailRequiredError",
          defaultMessage: "Enter your email",
        })
      ),
    taxId: !isCompany ? fiscalCode({ formatMessage }) : vat({ formatMessage }),
    phoneNumber: phone({ formatMessage }),
    country:
      isAuthenticated &&
      isNeedInvoice &&
      Yup.string().required(
        formatMessage({
          id: "forms::yourCountryError",
          defaultMessage: "Enter your country",
        })
      ),
    addressLine1:
      isAuthenticated &&
      isNeedInvoice &&
      Yup.string().required(
        formatMessage({
          id: "forms::yourAddressError",
          defaultMessage: "Enter your address",
        })
      ),
    postCode:
      isAuthenticated &&
      isNeedInvoice &&
      Yup.string().required(
        formatMessage({
          id: "forms::yourPostcodeError",
          defaultMessage: "Enter your postcode",
        })
      ),
    certifiedBusinessEmail:
      isCompany &&
      isNeedInvoice &&
      !useSavedCard &&
      emailValidation({ formatMessage }),
    certifiedBusinessEmailConfirm:
      isCompany &&
      isNeedInvoice &&
      !useSavedCard &&
      Yup.string()
        .when("certifiedBusinessEmail", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: () =>
            Yup.string().oneOf(
              [Yup.ref("certifiedBusinessEmail")],
              formatMessage({
                id: "signup::email::notMatch",
                defaultMessage: "Both emails need to be the same",
              })
            ),
          otherwise: () => {},
        })
        .email(
          formatMessage({
            id: "forms::emailInvalidError",
            defaultMessage: "Enter a correct email",
          })
        )
        .required(
          formatMessage({
            id: "forms::emailRequiredError",
            defaultMessage: "Enter your email",
          })
        ),
  }

  const initialValues = {
    id: order?.id,
    cardholderName: "",
    agreeTerms: false,
    saveCard: false,
    comment: "",
    password: "",
    confirmPassword: "",
    firstName: user?.firstName ?? "",
    lastName: user?.lastName ?? "",
    country: user?.country ?? "",
    addressLine1: user?.addressLine1 ?? "",
    postCode: user?.postCode ?? "",
    certifiedBusinessEmail: user?.certifiedBusinessEmail ?? "",
    certifiedBusinessEmailConfirm: user?.certifiedBusinessEmail ?? "",
    intendedEmail: user?.email ?? "",
    intendedConfirmEmail: user?.email ?? "",
    taxId: user?.taxId ?? "",
    phoneNumber: user?.phoneNumber ?? "",
    isNeedInvoice: !!isInvoiceMandatory,
  }

  if (useSavedCard && hasSavedCards) {
    delete validationObject.cardholderName
  }

  useEffect(() => {
    if (
      !hasSavedCards &&
      !allSavedAccountsData?.called &&
      (user?.id || isAuthenticated)
    ) {
      callAllSavedAccounts()
    }

    if (!useSavedCard) {
      createOrder(selectedPaymentType)
      return
    }

    if (
      !hasSavedCards &&
      allSavedAccountsData?.called &&
      !allSavedAccountsData?.loading
    ) {
      createOrder(selectedPaymentType)
      return
    }
  }, [
    useSavedCard,
    allSavedAccountsData?.called,
    allSavedAccountsData?.loading,
    hasSavedCards,
    user?.id,
    isAuthenticated,
  ])

  const validationSchema = Yup.object().shape(validationObject)

  const [
    startNewOrderPayment,
    { error: startOrderError, data: startOrderData },
  ] = useMutation(START_ORDER_PAYMENT_CHECKOUT_MUTATION)

  const handleCardChange = (card, formErrors) => {
    setIsCardEmpty(card?.empty)
    const { error } = card
    setStripeErrorData(null)
    const stripeError = error?.code
      ? formatMessage({
          id: `forms::stripe::${error?.code}`,
          defaultMessage: `${error?.message}`,
        })
      : null
    const cardError = stripeError || ""

    if (cardError) {
      formErrors.card = cardError
    } else {
      delete formErrors.card
    }
    setCardError(cardError)
  }

  const handleCardFocus = () => {
    if (creditCardRef?.current) {
      creditCardRef?.current?.classList?.add("active")
    }
  }

  const handleLogin = ({ email, password }) => {
    setLoginLoading(true)
    signIn({
      email,
      password,
      silent: true,
    })
      .then((res) => {
        let incomingErrors = []
        if (res?.nonFieldErrors) {
          res.nonFieldErrors.map((item) => incomingErrors.push(item.message))
        }

        if (incomingErrors.length === 0) {
          setShowLogin(false)
          // wantsRegister(true)
        } else {
          // errorToaster(incomingErrors?.[0])
          errorToaster(
            formatMessage({
              id: "forms::error::invalidCredentials",
              defaultMessage: "Invalid credentials",
            })
          )
        }
      })
      .catch((err) => {
        //   setLoginErrors([err.message])
      })
      .finally(() => {
        setLoginLoading(false)
      })
  }
  const handleSubmit = async ({
    cardholderName,
    saveCard,
    comment,
    firstName,
    lastName,
    intendedEmail,
    phoneNumber,
    taxId,
    certifiedBusinessEmail,
    addressLine1,
    postCode,
    country,
    isNeedInvoice,
    password,
  }) => {
    if (!isQuantityAvailable) {
      errorToaster(
        formatMessage({
          defaultMessage: "There is no enough available quantity",
          id: "amount::provision::quantityWarning",
        })
      )
      return
    }
    setSubmittedOnce(true)

    if (!stripe || !elements) {
      return
    }
    let correctedAddressLine1
    let correctedCity
    let correctedCountry
    let correctedPostCode
    let paymentType = selectedPaymentType

    if (selectedCard?.cardId) {
      paymentType =
        selectedCard?.brand === PAYMENT_TYPES.PAYPAL
          ? PAYMENT_TYPES.PAYPAL
          : PAYMENT_TYPES.CARD
    }

    if (isBankTransfer) {
      paymentType = PAYMENT_TYPES.CUSTOMER_BALANCE
    }

    if (isNeedInvoice) {
      try {
        setAddressError()
        setAddressLoading(true)
        const correctedAddressData = await checkAddress(
          addressLine1,
          postCode,
          country
        )
        if (correctedAddressData?.result) {
          setAddressError()
          correctedAddressLine1 = correctedAddressData?.addressLine1
          correctedCity = correctedAddressData?.city
          correctedCountry = correctedAddressData?.country || ountry
          correctedPostCode = correctedAddressData?.postalCode
        } else throw new Error()
      } catch {
        setAddressError(
          formatMessage({
            id: "forms::address::notValid",
            defaultMessage:
              "Address or post code is not valid. Please try again.",
          })
        )
        return
      } finally {
        setAddressLoading(false)
      }
    }
    const orderId = order?.id
    setProcessing(true)
    setStripeErrorData(null)
    if (creditCardRef?.current) {
      creditCardRef?.current?.classList?.add("active")
    }

    let startOrderInput = {
      campaign: charityId,
      comment,
      id: orderId,
      stripeSavePaymentMethodForFuture: saveCard,
      stripePaymentMethodId: selectedCard?.cardId,
      stripePaymentMethodType: paymentType,
      taxId,
      certifiedBusinessEmail,
      firstName,
      lastName: isCompany ? "" : lastName,
      accountType: isCompany ? ACCOUNT_TYPE.BUSINESS : ACCOUNT_TYPE.PERSONAL,
      intendedEmail: intendedEmail?.toLowerCase(),
      phoneNumber,
      addressLine1: correctedAddressLine1,
      city: correctedCity,
      country: correctedCountry,
      postCode: correctedPostCode,
      isNeedInvoice,
    }

    if (wantsRegister && password) {
      startOrderInput.password = password
    }

    try {
      const { data } = await startNewOrderPayment({
        variables: {
          input: startOrderInput,
        },
      })

      const { order, errors } = { ...data?.startOrderPayment }
      if (errors?.length) throw new Error(errors?.[0].messages[0])
      if (wantsRegister) {
        setCredentials({
          token: order?.token,
          refreshToken: order?.refreshToken,
        })
        setIsAuthenticated(true)
        await updateCommunicationLanguageWithBrowser()
        callUserProfile(true)
      }
      if (isBankTransfer && order?.id) {
        navigate(`/services/${slug}/bank-transfer-details?id=${order?.id}`)
        return
      }

      const { stripePaymentMethodId, id, stripeClientSecret, status } = {
        ...order,
      }
      if (isAuthenticated)
        updateProfile({
          variables: {
            input: {
              firstName: startOrderInput?.firstName,
              lastName: startOrderInput?.lastName,
              phoneNumber: startOrderInput?.phoneNumber,
              country: startOrderInput?.country,
              city: startOrderInput?.city,
              region: startOrderInput?.region,
              postCode: startOrderInput?.postCode,
              taxId: startOrderInput?.taxId,
              addressLine1: startOrderInput?.addressLine1,
              accountType: startOrderInput?.accountType,
            },
          },
        })
          .then(({ data }) => {
            callUserProfile()
          })
          .catch((err) => {})

      if (status === "COMPLETED") {
        onComplete({ id, isDWOR: wantsRegister && !isAuthenticated })
        setProcessing(false)
        return
      }

      if (!stripeClientSecret || !id) throw new Error(defaultErrorMessage)

      const card = elements.getElement("card")

      const paymentMethod = !card
        ? selectedCard?.cardId
        : country
        ? {
            card,
            billing_details: {
              address: {
                country,
              },
              email,
              name: cardholderName,
            },
          }
        : {
            card,
            billing_details: {
              email,
              name: cardholderName,
            },
          }

      const confirmPayment = confirmPaymentMethod(paymentType)

      const returnUrl = new URL(location?.href)
      returnUrl.searchParams.append("id", order?.id)

      const { paymentIntent, error } = await confirmPayment(
        stripeClientSecret,
        {
          payment_method: paymentMethod,
          return_url: returnUrl.toString(),
        }
      )

      console.log("error----", error, "stripeClientSecret", stripeClientSecret)

      if (error) {
        const stripeError = error?.code
          ? formatMessage({
              id: `forms::stripe::${error?.code}`,
              defaultMessage: `${error?.message}`,
            })
          : null
        if (!useSavedCard)
          setStripeErrorData({
            errors: [
              {
                message: stripeError,
              },
            ],
          })
        handleDiscardOrder(
          stripeError ||
            formatMessage({
              id: "forms::error::tryAgain",
              defaultMessage: "Please try again later.",
            })
        )
      } else if (paymentIntent?.status === "succeeded") {
        onComplete({ id, isDWOR: wantsRegister && !isAuthenticated })
      }
    } catch (err) {
      handleDiscardOrder(err?.message)
    }
    setProcessing(false)
  }
  let cardElementClassName = "full-width credit_card"
  if (processing === true) {
    cardElementClassName = "full-width credit_card apply-blur"
  }

  const invoiceTooltipText = formatMessage({
    id: "services::purchaseForm::needInvoiceTooltip",
    defaultMessage:
      "Normally only companies need invoice for tax purpose. Individual normally do not need",
  })

  const el =
    typeof window !== "undefined" && document.getElementsByTagName("body")[0]
  if (el && el.style) el.style.paddingBottom = !isTablet ? "1rem" : 0
  const userType = [
    {
      id: "individual",
      label: formatMessage({
        id: "services::purchaseForm::individual",
        defaultMessage: "As an individual",
      }),
    },
    {
      id: "company",
      label: formatMessage({
        id: "services::purchaseForm::company",
        defaultMessage: "As an organization",
      }),
    },
  ]

  const handleClickContinueWOSignin = () => {
    setWantsRegister(false)
    setShowLogin(false)
  }
  const handleClickLoginLink = () => {
    setShowLogin(true)
  }
  return (
    <StyledPageWrapper>
      {processing === true && (
        <div className="spinner">
          <Spinner condensed={true} />
        </div>
      )}
      <div className={processing === true ? "spinning-form" : ""}>
        <FormWrapper>
          <Formik
            initialValues={initialValues}
            validateOnMount
            validationSchema={validationSchema}
            onSubmit={handleSubmit}>
            {({
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              setFieldTouched,
              setFieldValue,
              setTouched,
              setErrors,
            }) => (
              <Form>
                <OnSubmitValidationError />
                {!isAuthenticated ? (
                  <>
                    <StyledFlexBox>
                      <StyledTitle
                        disabled={showLogin}
                        onClick={handleClickLoginLink}>
                        <FormattedMessage
                          id="service::checkoutForm::logIn"
                          defaultMessage="Log in"
                        />
                      </StyledTitle>
                      {showLogin ? (
                        <StyledTitle onClick={handleClickContinueWOSignin}>
                          <FormattedMessage
                            id="service::checkoutForm::continueWOSignin"
                            defaultMessage="Continue without signing in"
                          />
                        </StyledTitle>
                      ) : null}
                    </StyledFlexBox>
                    {!showLogin ? (
                      <StyledSectionTitle className="mb24">
                        <FormattedMessage
                          id="service::checkoutForm::registration"
                          defaultMessage="Registration"
                        />
                      </StyledSectionTitle>
                    ) : null}
                    <StyledSectionBox className="mb32">
                      {!showLogin ? (
                        <>
                          <FormattedMessage
                            id="service::checkoutForm::registerToReceive"
                            defaultMessage="Register to receive <used>{earnedPACs}</used> Euro/PAC"
                            values={{
                              earnedPACs: (
                                <Money
                                  value={Number(discountAmount || 0)}
                                  currency={false}
                                />
                              ),
                              used: (...chunks) => (
                                <span key="used">
                                  {chunks.map((chunk, i) => (
                                    <Fragment key={i}>
                                      <img
                                        className="pacs-image"
                                        src={
                                          CLOUDFLARE_IMAGE_URL +
                                          "/static/assets/images/pac.svg"
                                        }
                                        alt={`pacs`}
                                        width={16}
                                        height={16}
                                      />
                                      {chunk}
                                    </Fragment>
                                  ))}
                                </span>
                              ),
                            }}
                          />
                          <StyledFlexFormRow className="rb24">
                            <RadioSelect
                              items={registerOptions}
                              defaultValue={
                                wantsRegister === true ? "ok" : "not"
                              }
                              onChange={(e) => {
                                setWantsRegister(e.target.value === "ok")
                              }}
                            />
                          </StyledFlexFormRow>

                          <SocialLoginComponent medium={!isWide} silent />
                          <div className="mt24">
                            {wantsRegister ? (
                              <FormattedMessage
                                id="service::checkoutForm::orCreateAnAccount"
                                defaultMessage="Or create an account with an email and password"
                              />
                            ) : (
                              <FormattedMessage
                                id="service::checkoutForm::enterAnEmail"
                                defaultMessage="Enter an email to which we will send your receipt"
                              />
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          {alreadyHaveAccount ? (
                            <StyledWarningTitle>
                              <FormattedMessage
                                id="service::checkoutForm::alreadyHaveAccount"
                                defaultMessage="You already have an account"
                                tagName={"p"}
                              />
                              <FormattedMessage
                                id="service::checkoutForm::loginToUsePacs"
                                defaultMessage="Log in to use your PACs"
                                tagName={"p"}
                              />
                            </StyledWarningTitle>
                          ) : null}
                          <SocialLoginComponent medium={!isWide} silent />
                          {!alreadyHaveAccount ? (
                            <div className="center mt24">
                              <FormattedMessage
                                id="service::checkoutForm::orEnterCredentials"
                                defaultMessage="Or enter your credentials"
                              />
                            </div>
                          ) : null}
                        </>
                      )}
                      <TextInput
                        newDesign
                        className="mt24"
                        data-testid="input-email"
                        id="intendedEmail"
                        name="intendedEmail"
                        type="email"
                        onChange={(e) => {
                          const lowerCaseEmail = e.target.value.toLowerCase()
                          handleChange({
                            target: {
                              name: e.target.name,
                              value: lowerCaseEmail,
                            },
                          })
                        }}
                        onBlur={(e) => {
                          handleBlur(e)
                          if (
                            values.intendedEmail ===
                              values.intendedConfirmEmail &&
                            !errors.intendedEmail &&
                            !errors.intendedConfirmEmail
                          ) {
                            checkUserExist({
                              variables: {
                                email: values.intendedConfirmEmail,
                              },
                            })
                          }
                        }}
                        value={values?.intendedEmail}
                        label={
                          <StyledEmailLabel>
                            {formatMessage({
                              id: "signUp::email",
                              defaultMessage: "Email",
                            })}
                            {REQUIRED_FIELD_SYMBOL}
                            <div
                              data-tooltip-id="emailTooltip"
                              data-tooltip-content={emailInformationText}
                              data-tooltip-place="bottom">
                              <FontAwesomeIcon
                                className="icon info-icon"
                                icon={faCircleInfo}
                              />
                            </div>
                            <Tooltip id="emailTooltip" className="tooltip" />
                          </StyledEmailLabel>
                        }
                        placeholder={formatMessage({
                          id: "signUp::emailPlaceholder",
                          defaultMessage: "Your personal email address",
                        })}
                        error={errors.intendedEmail}
                        touched={touched.intendedEmail}
                      />

                      {showLogin ? null : (
                        <TextInput
                          newDesign
                          className="mt24"
                          data-testid="input-confirmEmail"
                          id="intendedConfirmEmail"
                          name="intendedConfirmEmail"
                          type="email"
                          onChange={(e) => {
                            const lowerCaseEmail = e.target.value.toLowerCase()
                            handleChange({
                              target: {
                                name: e.target.name,
                                value: lowerCaseEmail,
                              },
                            })
                          }}
                          onBlur={(e) => {
                            handleBlur(e)
                            if (
                              values.intendedEmail ===
                                values.intendedConfirmEmail &&
                              !errors.intendedEmail &&
                              !errors.intendedConfirmEmail
                            ) {
                              checkUserExist({
                                variables: {
                                  email: values.intendedConfirmEmail,
                                },
                              })
                            }
                          }}
                          value={values?.intendedConfirmEmail}
                          label={`${formatMessage({
                            id: "signUp::confirmEmail",
                            defaultMessage: "Verify Email",
                          })}${REQUIRED_FIELD_SYMBOL}`}
                          placeholder={formatMessage({
                            id: "signUp::emailPlaceholder",
                            defaultMessage: "Your personal email address",
                          })}
                          onPaste={(e) => {
                            e.preventDefault()
                            return false
                          }}
                          error={errors.intendedConfirmEmail}
                          touched={touched.intendedConfirmEmail}
                        />
                      )}

                      {showLogin || wantsRegister ? (
                        <TextInput
                          newDesign
                          className="mt24"
                          data-testid="input-password"
                          id="password"
                          name="password"
                          type={"password"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.password}
                          label={`${formatMessage({
                            id: "signUp::password",
                            defaultMessage: "Password",
                          })}${REQUIRED_FIELD_SYMBOL}`}
                          placeholder={formatMessage({
                            id: "signUp::password",
                            defaultMessage: "Password",
                          })}
                          error={errors.password}
                          touched={touched.password}
                          iconRight={0.9375}
                        />
                      ) : null}
                      {!showLogin && wantsRegister ? (
                        <TextInput
                          newDesign
                          className="mt24"
                          data-testid="input-confirmPassword"
                          id="confirmPassword"
                          name="confirmPassword"
                          type={"password"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.confirmPassword}
                          label={`${formatMessage({
                            id: "signUp::confirmPassword",
                            defaultMessage: "Confirm Password",
                          })}${REQUIRED_FIELD_SYMBOL}`}
                          placeholder={formatMessage({
                            id: "signUp::confirmPassword",
                            defaultMessage: "Confirm Password",
                          })}
                          onPaste={(e) => {
                            e.preventDefault()
                            return false
                          }}
                          error={errors.confirmPassword}
                          touched={touched.confirmPassword}
                          iconRight={0.9375}
                        />
                      ) : null}
                      {showLogin ? (
                        <StyledLoginActions
                          className={!isTablet ? "sticky-bottom-bar" : ""}>
                          <StyledBlueText
                            className="mb24"
                            to="/password-recovery/">
                            <FormattedMessage
                              id="service::checkoutForm::forgotPassword"
                              defaultMessage="Forgot password?"
                            />
                          </StyledBlueText>
                          <Button
                            color="blue"
                            type="submit"
                            onClick={() => {
                              handleLogin({
                                email: values.intendedEmail,
                                password: values.password,
                              })
                            }}
                            disabled={
                              !values.intendedEmail ||
                              !values.password ||
                              loginLoading ||
                              loading
                            }>
                            <FormattedMessage
                              id="service::checkoutForm::logIn"
                              defaultMessage="log in"
                            />
                          </Button>
                        </StyledLoginActions>
                      ) : null}
                    </StyledSectionBox>
                  </>
                ) : null}
                {!isAuthenticated && showLogin ? null : (
                  <>
                    {displayCardSection ? (
                      <>
                        {!isInvoiceMandatory ? (
                          <>
                            <StyledFormRow>
                              <FormattedMessage
                                id="services::purchaseForm::needInvoice"
                                defaultMessage="Need an invoice?"
                              />
                              <StyledQuestionIcon
                                data-tooltip-id="invoiceTooltip"
                                data-tooltip-content={invoiceTooltipText}
                                data-tooltip-place="bottom-start">
                                <FontAwesomeIcon icon={faInfoCircle} />
                              </StyledQuestionIcon>
                            </StyledFormRow>
                            <StyledFlexFormRow>
                              <RadioSelect
                                items={needInvoiceTypes}
                                defaultValue={
                                  values?.isNeedInvoice === true ? "yes" : "no"
                                }
                                onChange={(e) => {
                                  setFieldValue(
                                    "isNeedInvoice",
                                    e.target.value === "yes",
                                    true
                                  )
                                  setIsNeedInvoice(e.target.value === "yes")
                                }}
                              />
                            </StyledFlexFormRow>
                          </>
                        ) : null}
                        <StyledFormRow className="title mt32">
                          <FormattedMessage
                            id="services::purchaseForm::companyOrIndividual"
                            defaultMessage="Are you purchasing as an organization or an individual?"
                          />
                        </StyledFormRow>
                        <StyledFlexFormRow>
                          <RadioSelect
                            items={userType}
                            defaultValue={isCompany ? "company" : "individual"}
                            onChange={(e) => {
                              setIsCompany(e.target.value === "company")
                              setErrors({})
                            }}
                          />
                        </StyledFlexFormRow>
                        <StyledSectionTitle className="mb16">
                          <FormattedMessage
                            id="service::checkoutForm::yourDetails"
                            defaultMessage="Your details"
                          />
                        </StyledSectionTitle>

                        <StyledSectionBox>
                          <StyledSingleDonationContainer>
                            <SingleDonation
                              isCompany={isCompany}
                              setIsCompany={setIsCompany}
                              isLoggedIn={isAuthenticated}
                              isPhoneRequired
                              isPECRequired
                            />
                          </StyledSingleDonationContainer>
                        </StyledSectionBox>
                        <StyledSectionTitle className="mt24">
                          <FormattedMessage
                            id="service::checkoutForm::messageToMerchant"
                            defaultMessage="Message to merchant"
                          />
                        </StyledSectionTitle>
                        <StyledFormRow>
                          <TextInput
                            newDesign
                            className="comment"
                            id={`comment`}
                            name={`comment`}
                            type="textarea"
                            maxLength={120}
                            value={values.comment}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={formatMessage({
                              id: "services::purchaseForm::comment",
                              defaultMessage: "Leave a comment to the merchant",
                            })}
                            error={errors?.comment}
                            touched={touched?.comment}
                          />
                        </StyledFormRow>
                        {!useSavedCard || !hasSavedCards ? (
                          <>
                            <StyledFormRow>
                              <StyledSectionTitle className="mt24">
                                <FormattedMessage
                                  id="campaign::donationForm::paymentMethodTitle"
                                  defaultMessage="Payment Method"
                                />
                              </StyledSectionTitle>
                              <Spacer bottom={1} />
                              <PaymentMethods
                                hideBankTransfer={true}
                                onSelect={async (val) => {
                                  setSelectedPaymentType(val)
                                  await createInitialOrder({
                                    stripePaymentMethodType: val,
                                  })
                                  await callCreateClientSecret({
                                    variables: { stripePaymentMethodType: val },
                                  })
                                }}
                                amount={
                                  order?.internalOrderValues?.purchaseTotal ??
                                  internalOrderValues?.purchaseTotal
                                }
                              />
                            </StyledFormRow>
                            {!isPayPal && !isBankTransfer && (
                              <>
                                <StyledFormRow>
                                  <p className="caption">
                                    <FormattedMessage
                                      id="campaign::donationForm::cardholderName"
                                      defaultMessage="Cardholder Name"
                                    />
                                    {REQUIRED_FIELD_SYMBOL}
                                  </p>
                                  <TextInput
                                    newDesign
                                    className={cardElementClassName}
                                    id="cardholderName"
                                    name="cardholderName"
                                    value={values.cardholderName}
                                    placeholder={formatMessage({
                                      id: "campaign::donationForm::cardholderNamePlaceHolder",
                                      defaultMessage: "Name on the card",
                                    })}
                                    error={errors.cardholderName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    touched={touched.cardholderName}
                                  />
                                </StyledFormRow>
                                <StyledFormRow
                                  className={`${cardElementClassName} 
                              ${submittedOnce && isCardEmpty ? "error" : ""}`}
                                  cardError={!!cardError || !!stripeErrorData}>
                                  <p className="caption">
                                    <FormattedMessage
                                      id="campaign::donationForm::cardDetails"
                                      defaultMessage="Card Details"
                                    />
                                    {REQUIRED_FIELD_SYMBOL}
                                  </p>
                                  <CardElement
                                    onFocus={handleCardFocus}
                                    onChange={(card) =>
                                      handleCardChange(card, errors)
                                    }
                                    options={{
                                      disabled: processing,
                                      style: cardElementStyles,
                                    }}
                                  />
                                  <p
                                    ref={creditCardRefError}
                                    className="input-feedback">
                                    {cardError}
                                  </p>
                                  {!cardError && stripeErrorData && (
                                    <p>
                                      <FormErrors errors={stripeErrorData} />
                                    </p>
                                  )}
                                  {submittedOnce && isCardEmpty && (
                                    <StyledCardErrorWrapper className="input-feedback error">
                                      <FormattedMessage
                                        id="forms::requiredField"
                                        defaultMessage="This field is required"
                                      />
                                    </StyledCardErrorWrapper>
                                  )}
                                </StyledFormRow>
                              </>
                            )}
                            <TextInput style={{ display: "none" }} newDesign />
                            {hasSavedCards && (
                              <StyledFormRow>
                                <StyledUseAnotherCardButton
                                  onClick={() => {
                                    setUseSavedCard(true)
                                  }}>
                                  <FormattedMessage
                                    id="campaign::donationForm::useSavedCard"
                                    defaultMessage="Use saved card"
                                  />
                                </StyledUseAnotherCardButton>
                              </StyledFormRow>
                            )}

                            {isAuthenticated && !isBankTransfer && (
                              <StyledFormRow>
                                <StyledAgreeTerms>
                                  <CheckboxContainer>
                                    <label htmlFor="saveCard">
                                      <input
                                        tabIndex="0"
                                        data-testid="check-saveCard"
                                        id="saveCard"
                                        name="saveCard"
                                        type="checkbox"
                                        checked={values.saveCard}
                                        onChange={handleChange}
                                        onClick={() =>
                                          setFieldTouched("saveCard", true)
                                        }
                                      />
                                      <span className="checkmark"></span>
                                      <FormattedMessage
                                        id="campaign::donationForm::saveCard"
                                        defaultMessage="Save Card"
                                      />
                                    </label>
                                  </CheckboxContainer>
                                </StyledAgreeTerms>
                              </StyledFormRow>
                            )}
                          </>
                        ) : (
                          <>
                            {hasSavedCards && (
                              <StyledFormRow>
                                <p className="caption">
                                  <FormattedMessage
                                    id="campaign::donationForm::paymentMethodTitle"
                                    defaultMessage="Payment Method"
                                  />
                                </p>
                                <CreditCardSelector
                                  cards={allCards}
                                  onChange={(card) => {
                                    setSelectedCard(card)

                                    createOrderData(card)
                                  }}
                                  amount={
                                    order?.internalOrderValues?.purchaseTotal
                                  }
                                />
                                <StyledUseAnotherCardButton
                                  onClick={() => {
                                    setUseSavedCard(false)
                                    setSelectedCard(null)
                                  }}>
                                  <FormattedMessage
                                    id="campaign::donationForm::useAnotherCard"
                                    defaultMessage="Use another card"
                                  />
                                </StyledUseAnotherCardButton>
                              </StyledFormRow>
                            )}
                          </>
                        )}
                      </>
                    ) : null}

                    <StyledFormRow>
                      <StyledAgreeTerms>
                        <TermsAndConditions
                          name="agreeTerms"
                          checked={values.agreeTerms}
                          errors={errors}
                          touched={touched}
                          onChange={handleChange}
                          onClick={() => setFieldTouched("agreeTerms", true)}
                        />
                      </StyledAgreeTerms>
                    </StyledFormRow>
                    {(startOrderError || startOrderError?.errors) && (
                      <StyledErrorMessage className="error">
                        <FormErrors
                          error={startOrderError}
                          errors={startOrderError?.errors}
                        />
                      </StyledErrorMessage>
                    )}
                    {addressLoading ? (
                      <BaseError
                        message={formatMessage({
                          id: "forms::address::validating",
                          defaultMessage:
                            "Please wait while your address is being validated",
                        })}
                      />
                    ) : null}
                    {addressError ? <BaseError message={addressError} /> : null}
                    <StyledButtonContainer
                      className={!isTablet ? "sticky-bottom-bar" : ""}>
                      <Button
                        color="blue"
                        type="submit"
                        onClick={() => {
                          setSubmittedOnce(true)
                        }}
                        disabled={
                          !values.agreeTerms ||
                          !variant ||
                          (donationAmount && !charityId) ||
                          processing ||
                          !order?.id
                        }>
                        <FormattedMessage
                          id="ui::continue"
                          defaultMessage="Continue"
                        />
                      </Button>
                    </StyledButtonContainer>
                  </>
                )}
              </Form>
            )}
          </Formik>
        </FormWrapper>
      </div>
      <Tooltip id="invoiceTooltip" className="tooltip" />
    </StyledPageWrapper>
  )
}

export default ServicePurchaseForm
