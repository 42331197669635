import { faHandHoldingHeart } from "@fortawesome/pro-solid-svg-icons/faHandHoldingHeart"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Money, ShareButtons } from "@tmu/components/common"
import { FormattedMessage, navigate } from "gatsby-plugin-intl"
import React, { Fragment } from "react"
import {
  StyledContainer,
  StyledGoallessCampaignStats,
  StyledSocialShare,
  StyledSupportButton,
} from "./index.styles"

const QuickDonationButton = ({
  defaultCampaign,
  donationCount,
  fundedAmount,
}) => {
  const onSupportClick = () => {
    navigate("/campaigns/" + defaultCampaign?.slug + "/donate")
  }

  return (
    <StyledContainer>
      <StyledGoallessCampaignStats>
        <FormattedMessage
          id="progress::supporterCount"
          defaultMessage="{current, plural, =0 {No Supporters} =1 {<one>#</one> Supporter} other {<num>#</num> Supporters}}"
          values={{
            one: (...chunks) => <span className="support">1</span>,
            num: (...chunks) => (
              <span key="current" className="support">
                {chunks.map((chunk, i) => (
                  <Fragment key={i}>{chunk}</Fragment>
                ))}
              </span>
            ),
            current: donationCount || defaultCampaign?.donationCount,
          }}
          tagName="p"
        />
        <FormattedMessage
          id="progress::detail::totalSupport"
          defaultMessage="<funded>{amount}</funded> Total Support"
          values={{
            funded: (...chunks) => (
              <span key="funded" className="funded">
                {chunks.map((chunk, i) => (
                  <Fragment key={i}>{chunk}</Fragment>
                ))}
              </span>
            ),
            amount: (
              <Money value={fundedAmount || defaultCampaign?.fundedAmount} />
            ),
          }}
          tagName="p"
        />
      </StyledGoallessCampaignStats>
      <StyledSupportButton
        color="red"
        data-testid="support-campaign"
        onClick={onSupportClick}>
        <FontAwesomeIcon icon={faHandHoldingHeart} />
        <FormattedMessage
          id="campaign::detail::buttons::supportCampaign"
          defaultMessage="Donate and Get PACs"
        />
      </StyledSupportButton>
      <StyledSocialShare>
        <FormattedMessage
          id="campaign::detail::shareCampaign"
          defaultMessage="Share this campaign:"
          tagName="div"
        />
        <ShareButtons
          url={window?.location?.href}
          tags={["TMU", "TrustMeUp", defaultCampaign?.slug]}
        />
      </StyledSocialShare>
    </StyledContainer>
  )
}

export default QuickDonationButton
