/* eslint-disable  no-restricted-globals */
import { faChevronDown } from "@fortawesome/pro-light-svg-icons/faChevronDown"
import { faChevronUp } from "@fortawesome/pro-light-svg-icons/faChevronUp"
import PropTypes from "prop-types"
import queryString from "query-string"
import React, { useEffect, useState } from "react"
import AccordionSection from "./AccordionSection"
import { StyledAccordion } from "./index.styles"

const Accordion = ({ multiple = false, dataTestId, location, children }) => {
  const [openSections, setOpenSections] = useState({})
  const [initialOpen, setInitialOpen] = useState(false)
  const [isInteracted, setInteracted] = useState(false)
  const params = queryString.parse(location?.search, { arrayFormat: "comma" })
  const childrenArr = React.Children.toArray(children)

  const closeAcc = [
    "language-accordion-navbar",
    "digital-banking-accordion-navbar",
  ]

  const handleClick = (sectionId) => {
    setInitialOpen(false)
    setInteracted(true)
    const isOpen = !!openSections[sectionId]
    setOpenSections({
      ...(multiple ? openSections : null),
      [sectionId]: !isOpen,
    })
  }

  useEffect(() => {
    if (childrenArr?.length === 1) {
      setOpenSections({
        ...(multiple ? openSections : null),
      })
    }
  }, [])

  return (
    <StyledAccordion data-testid={dataTestId}>
      {childrenArr.map((child, index) => {
        const defaultOpenId = child?.props?.label?.props?.id || ""
        const donationNode =
          !isInteracted &&
          defaultOpenId &&
          window?.atob(defaultOpenId).split(":")?.[1]

        const sectionId = `section#${index}`

        const isSectionOpen = !!openSections[sectionId]

        return (
          <AccordionSection
            dataTestId={dataTestId}
            {...child.props}
            index={index}
            key={index}
            isOpen={donationNode === params?.id || isSectionOpen}
            label={child?.props?.label}
            onClick={() => handleClick(sectionId)}
            icon={isSectionOpen || initialOpen ? faChevronUp : faChevronDown}>
            {child.props.children}
          </AccordionSection>
        )
      })}
    </StyledAccordion>
  )
}

Accordion.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
}

export default Accordion
