import styled from "@emotion/styled"
import { Button } from "@tmu/components/common"

export const StyledPacsOffersContainer = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  background-color: ${(p) => p.theme.colors.whiteSmoke};
  color: ${(p) => p.theme.colors.pitchBlack};
  padding: 2.5rem 0;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 2.5rem 2rem;
    background-color: ${(p) => p.theme.colors.carrot};
    color: ${(p) => p.theme.colors.white};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    background-color: ${(p) => p.theme.colors.carrot};
    color: ${(p) => p.theme.colors.white};
    padding: 3.375rem 1.875rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    background-color: ${(p) => p.theme.colors.carrot};
    color: ${(p) => p.theme.colors.white};
    padding: 5rem 6rem;
  }
`

export const StyledPacsOffersTitle = styled.div`
  font-size: 1.5rem;
  line-height: 2rem;
  padding-bottom: 0.75rem;
  margin: 0 1rem;
  font-family: ${(p) => p.theme.fontFamilies.headline};

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 2rem;
    line-height: 2.875rem;
    padding-bottom: 1.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 3rem;
    line-height: 4.0625rem;
    padding-bottom: 3rem;
  }
`

export const StyledPacsOffersSubTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: ${(p) => p.theme.fontFamilies.headline};
  margin: 0 1rem 0.5rem;
`

export const StyledPacsOffersNewOffersTitle = styled.div`
  font-size: 0.75rem;
  line-height: 1.125rem;
  letter-spacing: 0.01em;
  color: ${(p) => p.theme.colors.pitchBlack};
  font-family: ${(p) => p.theme.fontFamilies.headline};

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1rem;
    line-height: 1.5rem;
    color: ${(p) => p.theme.colors.white};
    margin-bottom: 0.75rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 1rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
    line-height: 2.625rem;
    margin-bottom: 2rem;
  }
`

export const StyledPacsOffersViewPacAllOffersButton = styled(Button)`
  p,
  span {
    font-size: 0.75rem;
    line-height: 1rem;
    color: ${(p) => p.theme.colors.carrot};
    font-family: ${(p) => p.theme.fontFamilies.headline};

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 1rem;
      line-height: 1.5rem;
      color: ${(p) => p.theme.colors.white};
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
  }
`

export const StyledPacsOffersCards = styled.div`
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  row-gap: 0.5rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    column-gap: 0.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    grid-template-columns: repeat(4, 1fr);
    column-gap: 1rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    grid-template-columns: repeat(4, 1fr);
    column-gap: 1.5rem;
  }
`
