import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { useIntl } from "gatsby-plugin-intl"
import { Tooltip } from "react-tooltip"
import { useToast } from "@tmu/hooks"
import { isBrowser } from "@tmu/utils/auth"

const StyledClickToCopy = styled.span`
  cursor: ${(p) => (!p.disabled ? "pointer" : "default")};
  .info {
    color: white;
  }
`

const ClickToCopy = ({ text, children, tooltipOptions = {} }) => {
  const { success } = useToast()
  const { formatMessage } = useIntl()
  const [tooltipStatus, setTooltipStatus] = useState(false)
  const copyMessage = formatMessage({
    id: "ui::clickToCopy",
    defaultMessage: "Click to copy",
  })
  const copiedMessage = formatMessage({
    id: "ui::copiedToClipboard",
    defaultMessage: "Copied to clipboard!",
  })
  const isEnabled = text && text !== "-" && text.length > 0

  const copyToClipboard = () => {
    if (!isBrowser || !isEnabled) {
      return
    }
    const el = document.createElement("textarea")
    el.value = text
    document.body.appendChild(el)
    el.select()
    document.execCommand("copy")
    document.body.removeChild(el)

    success(copiedMessage)
    setTooltipStatus(false)
  }

  return (
    <>
      <StyledClickToCopy
        data-tooltip-id="copy-tooltip"
        onClick={copyToClipboard}
        data-tooltip-content={copyMessage}
        onMouseEnter={() => setTooltipStatus(true)}
        onMouseLeave={() => setTooltipStatus(false)}
        disabled={!isEnabled}>
        {children}
      </StyledClickToCopy>

      <Tooltip
        id="copy-tooltip"
        isOpen={tooltipStatus}
        type="info"
        className={`info ${tooltipOptions?.className}`}
        arrowColor={tooltipOptions?.arrowColor}
      />
    </>
  )
}

ClickToCopy.propTypes = {
  text: PropTypes.string,
  children: PropTypes.node,
}

export default ClickToCopy
