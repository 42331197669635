import styled from "@emotion/styled"

export const StyledTopBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .page-title {
    margin: 0;
  }
`
