import styled from "@emotion/styled"
import { FixedSizeList as List } from "react-window"

const GridTemplate = `
  display: grid;
  grid-template-columns: 0.2fr 1.2fr 1.6fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: ". . . .";
  grid-gap: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  align-items: center;
`

export const StyledNotificationTable = styled(List)``

export const StyledNotificationTableHeader = styled.div`
  ${GridTemplate}
  color: ${(p) => p.theme.colors.tableHeaderDefault};
  border-bottom: 0.0625rem solid ${(p) => p.theme.colors.tableItemBorder};
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 0.8rem;

  .centered {
    height: 100%;
    justify-self: center;
  }
`

export const StyledNotificationTableRow = styled.div`
  ${GridTemplate}
  border-bottom: 0.0625rem solid ${(p) => p.theme.colors.tableItemBorder};
  width: 100%;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  height: 100%;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;

  *:not(button) {
    color: ${(p) => p.theme.colors.secondaryBrandTextColor};
  }
  div,
  strong {
    font-size: 0.8rem;
  }
  strong {
    font-weight: ${(p) => p.theme.fontWeights.bold};
    color: ${(p) => p.theme.colors.gondola};
  }
  .light {
    opacity: 0.6;
  }

  .status {
    &.active {
      color: ${(p) => p.theme.colors.tableItemStatusSuccess};
    }
  }
  .secondary {
    color: ${(p) => p.theme.colors.primaryBrandTextColor};
  }

  .centered {
    height: 100%;
    justify-self: center;
  }
`
