import React from "react"
import PropTypes from "prop-types"
import { StyledPACWalletTable } from "./index.styles"
import PACWalletTableHeader from "./PACWalletTableHeader"
import PACWalletTableRow from "./PACWalletTableRow"
import theme from "@tmu/global/theme"

const PACWalletTable = ({ items }) => {
  return (
    <div>
      <PACWalletTableHeader />
      <StyledPACWalletTable>
        {items.map((item, index) => (
          <PACWalletTableRow
            key={item.id}
            index={index}
            {...item}
            style={
              index % 2 === 1 ? { background: theme.colors.whiteSmoke } : null
            }
          />
        ))}
      </StyledPACWalletTable>
    </div>
  )
}

PACWalletTable.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.any.isRequired,
      created: PropTypes.string.isRequired,
      spendingAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      pacAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
      transactionDirection: PropTypes.string.isRequired,
      isIncomingTransaction: PropTypes.bool.isRequired,
    })
  ),
}

export default PACWalletTable
