import styled from "@emotion/styled"

export const Wrapper = styled.div`
  position: relative;
  display: ${(p) => (p.hidden ? "none" : "block")};
  font-family: ${(p) => p.theme.fontFamilies.regular};

  h2,
  h3 {
    font-weight: ${(p) => p.theme.fontWeights.bold};
    font-size: 1.2rem;
    margin: 0.5rem 0 1rem 0;
  }

  .side-drawer-bars {
    display: none;
    position: absolute;
    left: 0.625rem;
    cursor: pointer;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    .side-drawer-bars {
      display: block;
    }
  }
`

export const StyledSideDrawer = styled.div`
  ${(props) =>
    props.isOpen
      ? `
    display:block!important;
    `
      : `
    display:none!important;
    `};
  background: white;
  border-top-right-radius: 0.5rem;
  padding: 0.5rem 2rem;
  .close-icon {
    float: right;
    display: none;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: none;
    position: absolute;
    z-index: 2;
    width: 80%;
    max-width: ${(p) => p.theme.breakpoints.tablet};
    .close-icon {
      display: block;
    }
  }
`
