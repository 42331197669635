import styled from "@emotion/styled"
import { Link } from "gatsby-plugin-intl"

export const StyledWrapper = styled.div`
  position: relative;
  margin: 5rem auto;
  grid-template-columns: 1fr;
  grid-template-areas: "details" "form";
  display: grid;

  grid-gap: 0.6rem;
  height: 100%;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    grid-template-columns: 15rem auto;
    grid-template-areas: "details form";
    max-width: ${(p) => p.theme.breakpoints.tablet};
    padding: 0 1.563rem;
    margin: 5rem auto;
    grid-gap: 1.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    max-width: ${(p) => p.theme.breakpoints.desktop};
    padding: 0 2.25rem 0 5.6875rem;
    grid-gap: 2rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    grid-template-columns: 25.875rem auto;
    margin: 7.5rem auto;
    grid-gap: 1.5rem;
    max-width: 90rem;
  }
`

export const StyledSupportCheckoutForm = styled.div`
  grid-area: form;
  padding: 0 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 0;
  }

  & > p:first-of-type {
    color: rgba(99, 106, 117, 1);
    font-size: 1rem;
    line-height: 1rem;
    display: inline-block;
  }
`

export const StyledSupportCompanyDetails = styled.div`
  padding: 1.5rem 1rem 1rem;
  & > p {
    color: ${(p) => p.theme.colors.gondola};
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
  > p:nth-of-type(1) {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-size: 1.125rem;
    line-height: 1.5rem;
    letter-spacing: 0.01em;
    color: ${(p) => p.theme.colors.black};
    margin-bottom: 0.5rem;
  }
  > p:nth-of-type(2) {
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    font-size: 0.75rem;
    line-height: 1rem;
    color: ${(p) => p.theme.colors.black};
    margin-top: 1rem;
  }

  > span:last-of-type {
    display: block;
    margin-top: 1rem;
  }

  .campaign-image {
    width: 100%;
    margin: 1rem 0 2rem;
  }
  .rc-progress-line {
    margin-top: 1.5rem;
  }
`

export const StyledFAQSection = styled.div`
  margin-top: 2.5rem;
  padding-left: 1rem;
  & > p {
    color: ${(p) => p.theme.colors.gondola};
    font-size: 1rem;
    font-weight: ${(p) => p.theme.fontWeights.bold};
    line-height: 1.1875rem;
  }
  ul {
    color: ${(p) => p.theme.colors.disabled};
    padding: 1.25rem 0 0 0.625rem;
    list-style-type: disc;
    list-style-position: inside;

    li {
      margin-bottom: 0.625rem;
    }
  }
`

export const StyledFAQQuestion = styled.h5`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-weight: ${(p) => p.theme.fontWeights.regular};
  font-size: 0.95rem;
`
export const StyledFAQAnswer = styled.p`
  padding: 0.5rem;
  font-size: 0.85rem;
  color: ${(p) => p.theme.colors.shuttleflGray};
  line-height: 1.4;
`
export const StyledLink = styled(Link)`
  &,
  &:hover,
  &:visited,
  &.active {
    color: ${(p) => p.theme.colors.blue} !important;
    font-weight: ${(p) => p.theme.fontWeights.bold};
  }
`

export const StyledFormTitle = styled.h1`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-size: 1.5rem;
  line-height: 2.625rem;
  color: ${(p) => p.theme.colors.black};
  margin-bottom: 0.75rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-bottom: 2rem;
    font-size: 2rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 2rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin-bottom: 2.5rem;
    font-size: 3rem;
  }
`

export const StyledFormSubTitle = styled.h2`
  margin-bottom: 1rem;
  font-size: 1.125rem;
  line-height: 1.5rem;
  word-break: break-word;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
    line-height: 2.625rem;
  }
`

export const StyledDonationTierTitle = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-weight: ${(p) => p.theme.fontWeights.bold};
  font-size: 1rem;
  line-height: 1rem;
  max-width: ${(p) => p.theme.breakpoints.wide};
  margin: 0 0 1.3rem 1rem;
`

export const StyledBackButton = styled(Link)`
  position: absolute;
  top: -1rem;
  left: 1rem;
  font-size: 1rem;
  color: ${(p) => p.theme.colors.black};
  margin-top: 0;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    left: 1.5rem;
    color: ${(p) => p.theme.colors.softGreen};
    margin-top: 2rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    left: 2rem;
    margin-top: 2rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    left: -9rem;
    font-size: 1.5rem;
    margin-top: 2rem;
  }
`
export const StyledCampaignCardPreview = styled.div`
  display: none;
  margin-top: 4.8rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: block;
  }
`

export const StyledGoallessCampaignStats = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1.5rem;
  margin-top: 0.675rem;

  > div {
    width: 100%;
  }

  p {
    text-transform: uppercase;
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-size: 0.75rem !important;
    font-weight: ${(p) => p.theme.fontWeights.black};
    color: ${(p) => p.theme.colors.disabled};
  }

  .right {
    text-align: right;
  }

  .left {
    text-align: left;
  }

  span.fund {
    display: block;
    font-size: 1.25rem !important;
    margin-bottom: 0;
    color: ${(p) => p.theme.colors.primaryBrandTextColor};
  }

  .goal {
    margin-bottom: 0;
  }

  .rc-progress-line {
    margin-top: 1rem;
  }
`
export const StyledEventTimeInfo = styled.div`
  margin: 1rem 0 0;
  > span {
    display: block;
  }
`
export const StyledPartnerLogo = styled.img`
  object-fit: contain;
  border-radius: 0.25rem;
  height: 1.5rem;
  margin-right: 0.625rem;
`

export const StyledPartnerTitle = styled(Link)`
  display: flex;
  margin-top: 0.2rem;
`

export const StyledPreviewCardSubHeader = styled.span`
  text-transform: uppercase;
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-size: 0.625rem;
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.black};
  color: ${(p) => p.theme.colors.disabled};
  line-height: 1rem;
  letter-spacing: 0.04em;
  strong {
    color: ${(p) => p.theme.colors.black};
  }
`

export const StyledPartnerTitleText = styled.p`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.colors.black};
`
export const StyledDescription = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 2.0625rem;

  h1,
  p,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 0.75rem;
  }
`
