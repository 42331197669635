import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { StyledCampaignTableHeader } from "./index.styles"

const CampaignTableHeader = () => {
  return (
    <StyledCampaignTableHeader>
      <div>
        <FormattedMessage
          id="dashboard::tableHeader::campaign"
          defaultMessage="Campaign"
        />
      </div>
      <div className="centered">
        <FormattedMessage
          id="offerListing::filters::offerType"
          defaultMessage="Type"
        />
        <FontAwesomeIcon icon="" />
      </div>
      <div className="centered">
        <FormattedMessage
          id="dashboard::campaign::privacy"
          defaultMessage="Privacy"
        />
      </div>
      <div className="centered">
        <FormattedMessage
          id="dashboard::tableHeader::start"
          defaultMessage="Start"
        />
      </div>
      <div className="centered">
        <FormattedMessage
          id="dashboard::tableHeader::finish"
          defaultMessage="Finish"
        />
      </div>
      <div className="centered">
        <FormattedMessage id="dashboard::nav::donors" defaultMessage="Donors" />
      </div>
      <div className="centered">
        <FormattedMessage
          id="dashboard::tableHeader::support"
          defaultMessage="Support"
        />
      </div>
      <div className="centered">
        <FormattedMessage
          id="dashboard::ngo::tableHeader::status"
          defaultMessage="Status"
        />
      </div>
    </StyledCampaignTableHeader>
  )
}

export default CampaignTableHeader
