import React from "react"
import { CountStats } from "@tmu/components/common"
import { useQuery } from "@apollo/client"
import { FormattedMessage } from "gatsby-plugin-intl"
import { HOMEPAGE_AGGREGATE } from "@tmu/apollo/storefront/queries/home"
import { StyledSliceCounterWrapper } from "./index.styles"
const defaultQueryVariable = 8

const SliceCounter = () => {
  const { data } = useQuery(HOMEPAGE_AGGREGATE, {
    variables: {
      first: defaultQueryVariable,
      isPublic: true,
    },
  })

  return (
    <StyledSliceCounterWrapper className="counter">
      <CountStats
        stat={data?.aggregates?.numPartners || ""}
        title={
          <FormattedMessage
            id="homepage::counter::ngo"
            defaultMessage="NON-PROFITS"
          />
        }
      />
      <CountStats
        stat={data?.aggregates?.numStores || ""}
        title={
          <FormattedMessage
            id="homepage::counter::stores"
            defaultMessage="STORES"
          />
        }
      />
      {/* <CountStats
        stat={data?.aggregates?.numDonors || ""}
        title={
          <FormattedMessage
            id="homepage::counter::donors"
            defaultMessage="DONORS"
          />
        }
      />
      <CountStats
        stat={data?.aggregates?.numDonations || ""}
        title={
          <FormattedMessage
            id="homepage::counter::donations"
            defaultMessage="DONATIONS"
          />
        }
      /> */}
    </StyledSliceCounterWrapper>
  )
}

export default SliceCounter
