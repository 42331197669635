import { gql } from "@apollo/client"

export const USER_TRACKING_REFERRAL_WITH_CAMPAIGN_CONDITIONS_QUERY = gql`
  query UserTrackingReferralWithCampaignConditions($id: ID!) {
    userTrackingReferral(id: $id) {
      id
      code
      name
      maxUsage
      usageCount
      isUnlimited
      validUntil
      referralUrl
      isActive
      relatedId
      conditions(first: 30) {
        edges {
          node {
            campaign {
              id
              slug
              name
              partner {
                id
                slug
                name
              }
            }
          }
        }
      }
    }
  }
`

export const USER_TRACKING_REFERRAL_WITH_OFFER_CONDITIONS_QUERY = gql`
  query UserTrackingReferralWithOfferConditions($id: ID!) {
    userTrackingReferral(id: $id) {
      id
      code
      name
      maxUsage
      usageCount
      isUnlimited
      validUntil
      referralUrl
      isActive
      relatedId
      conditions(first: 30) {
        edges {
          node {
            offer {
              id
              slug
              name
              store {
                id
                slug
                name
              }
            }
          }
        }
      }
    }
  }
`

export const ALL_USER_TRACKING_REFERRALS_WITH_CAMPAIGN_CONDITIONS_QUERY = gql`
  query AllUserTrackingReferralsWithCampaignConditionsListing(
    $first: Int
    $orderBy: String
    $after: String
  ) {
    allUserTrackingReferrals(first: $first, orderBy: $orderBy, after: $after) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          code
          name
          maxUsage
          usageCount
          isUnlimited
          validUntil
          referralUrl
          isActive
          relatedId
          conditions(first: 30) {
            edges {
              node {
                campaign {
                  id
                  slug
                  name
                  partner {
                    id
                    slug
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const ALL_USER_TRACKING_REFERRALS_WITH_OFFER_CONDITIONS_QUERY = gql`
  query AllUserTrackingReferralsWithOfferConditionsListing(
    $first: Int
    $orderBy: String
    $after: String
  ) {
    allUserTrackingReferrals(first: $first, orderBy: $orderBy, after: $after) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          code
          name
          maxUsage
          usageCount
          isUnlimited
          validUntil
          referralUrl
          isActive
          relatedId
          conditions(first: 30) {
            edges {
              node {
                offer {
                  id
                  slug
                  name
                  store {
                    id
                    slug
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const CAMPAIGN_LISTING_FOR_CONDITIONS_QUERY = gql`
  query CampaignListingForConditions(
    $first: Int
    $user: [ID]
    $fundraiserType: [String]
    $startsAt_Lte: DateTime
    $status_In: [String]
    $visibilityStatus: [String]
  ) {
    allCampaigns(
      first: $first
      user: $user
      fundraiserType: $fundraiserType
      startsAt_Lte: $startsAt_Lte
      status_In: $status_In
      visibilityStatus: $visibilityStatus
      orderBy: "-created"
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          slug
          name
          nameEn
          nameIt
          nameEs
          partner {
            id
            slug
            name
            displayName
          }
        }
      }
    }
  }
`

export const PARTNER_CAMPAIGN_LISTING_FOR_CONDITIONS_QUERY = gql`
  query PartnerCampaignListingForConditions(
    $first: Int
    $fundraiserType: [String]
    $visibilityStatus: [String]
  ) {
    default: allCampaigns(
      first: 1
      fundraiserType: ["charities"]
      visibilityStatus: $visibilityStatus
      orderBy: "-created"
    ) {
      edges {
        node {
          id
          slug
          name
          nameEn
          nameIt
          nameEs
          partner {
            id
            slug
            name
            displayName
          }
        }
      }
    }
    allCampaigns(
      first: $first
      fundraiserType: $fundraiserType
      visibilityStatus: $visibilityStatus
      orderBy: "-created"
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          slug
          name
          nameEn
          nameIt
          nameEs
          partner {
            id
            slug
            name
            displayName
          }
        }
      }
    }
  }
`

export const OFFER_LISTING_FOR_CONDITIONS_QUERY = gql`
  query OfferListingForConditions(
    $first: Int
    $offset: Int
    $offerType_In: [OfferTypes]
  ) {
    allOffers(
      first: $first
      offset: $offset
      offerType_In: $offerType_In
      orderBy: "-created"
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          slug
          name
          isDefaultOffer
          store {
            id
            slug
            name
            displayName
          }
        }
      }
    }
  }
`

export const USER_TRACKING_REFERRAL_QR_CODE_QUERY = gql`
  query UserTrackingReferralQRCode($id: ID!) {
    userTrackingReferral(id: $id) {
      id
      referralQr
    }
  }
`
