import React, { Fragment, useState } from "react"

import { PRODUCT_VARIATION_TICKET_STATUS } from "@tmu/src/apollo/constants"
import { Button, Logo, Money } from "@tmu/src/components/common"
import { Spacer } from "@tmu/src/global/page-addons/detail-page.styles"
import theme from "@tmu/src/global/theme"
import { useAuth, useToast, useVouchers } from "@tmu/src/hooks"
import { getAllScreenTypes } from "@tmu/src/utils/mediaQueries"
import { compareAsc, format } from "date-fns"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import PropTypes from "prop-types"
import {
  StyledDetailContainer,
  StyledItemDescription,
  StyledItemValue,
  StyledLine,
  StyledListBackground,
  StyledListContainer,
  StyledListItemContainer,
  StyledOfferName,
  StyledOfferProductName,
  StyledTimeOfAction,
  StyledVerifyDate,
  StyledVerifyDetailTitle,
  StyledVoucherDetailButton,
} from "./index.styles"
import { getValueForLocale } from "@tmu/src/utils/string"

const VoucherDetail = ({
  voucherCodeParam,
  voucherCodeData,
  onClose,
  onUse,
  isCampaign,
  campaign,
  apiType,
}) => {
  const { checkVoucherCodeMutation, checkEventVoucherMutation } = useVouchers({
    apiType,
  })
  const { formatMessage, locale, defaultLocale } = useIntl()
  const { user } = useAuth()
  const { isTablet, isWide } = getAllScreenTypes()
  const [isActivated, setIsActivated] = useState()
  const { error } = useToast()

  const tier = voucherCodeData?.tier

  const makeVoucherCodeUse = () => {
    const checkMutation = isCampaign
      ? checkEventVoucherMutation
      : checkVoucherCodeMutation

    checkMutation({
      variables: { voucherCode: voucherCodeParam?.toUpperCase() },
    }).then((r) => {
      if (r?.errors?.length) {
        // r?.errors?.forEach((err) => error(err?.message))
        error(
          formatMessage({
            id: "dashboard::userForm::errorMessage",
            defaultMessage: "An error occurred",
          })
        )
        onClose()
        return
      }
      setIsActivated(true)
      if (typeof onUse === "function") {
        onUse()
      }
    })
  }

  const productVariation =
    voucherCodeData?.productVariation ?? voucherCodeData?.tier
  const offer = productVariation?.offer ?? productVariation?.campaign
  const product = voucherCodeData?.cartItem ?? voucherCodeData?.tier
  const endDate = offer?.endDate ?? offer?.deadline
  const dateEndDate = endDate ? new Date(endDate) : null
  const themeColor = isCampaign
    ? theme?.colors?.pacificBlue
    : theme?.colors?.carrot

  const isExpired = dateEndDate
    ? compareAsc(new Date(), dateEndDate) > 0
    : false

  const formattedDate = voucherCodeData?.modified
    ? format(new Date(voucherCodeData?.modified), "dd/MM/yy, HH:mm")
    : null

  const formattedExpiredDate = dateEndDate
    ? format(dateEndDate, "dd/MM/yy")
    : null

  const formattedActiveDate = voucherCodeData?.modified
    ? format(new Date(voucherCodeData?.modified), "LLL dd, yy, HH:mm")
    : null

  const voucherStatus = isExpired
    ? PRODUCT_VARIATION_TICKET_STATUS.EXPIRED
    : voucherCodeData?.status?.toUpperCase()

  const expiredDetailText = formatMessage(
    {
      id: "voucher::detail::expiredDetail",
      defaultMessage:
        "Voucher <carrot>{voucherCode}</carrot> has expired <carrot>{expiredDate}</carrot>",
    },
    {
      voucherCode: voucherCodeParam?.toUpperCase(),
      expiredDate: formattedExpiredDate,
      carrot: (...chunks) => (
        <>
          {chunks.map((chunk, i) => (
            <span
              style={{
                color: themeColor,
              }}
              key={i}>
              {chunk}
            </span>
          ))}
        </>
      ),
    }
  )

  const closeButtonText = formatMessage({
    id: "voucher::detail::closeButtonText",
    defaultMessage: "Close",
  })
  const activatedDescription = formatMessage(
    {
      id: "voucher::detail::activatedDescription",
      defaultMessage:
        "We sent a confirmation letter to {userName}’s email of applied voucher.",
    },
    {
      userName: user?.fullName,
    }
  )

  const usedDetailText = formatMessage(
    {
      id: "voucher::detail::usedDetail",
      defaultMessage:
        "Voucher <carrot>{voucherCode}</carrot> has been used already",
    },
    {
      voucherCode: voucherCodeParam?.toUpperCase(),
      carrot: (...chunks) => (
        <>
          {chunks.map((chunk, i) => (
            <span style={{ color: themeColor }} key={i}>
              {chunk}
            </span>
          ))}
        </>
      ),
    }
  )

  const activeButtonText = formatMessage({
    id: "voucher::detail::activeButtonText",
    defaultMessage: "Use Voucher",
  })

  const okButtonText = formatMessage({
    id: "voucher::detail::ok",
    defaultMessage: "Ok",
  })

  const activeDetailText = formatMessage(
    {
      id: "voucher::detail::activeDetail",
      defaultMessage: "Voucher <carrot>{voucherCode}</carrot> ",
    },
    {
      voucherCode: voucherCodeParam?.toUpperCase(),
      carrot: (...chunks) => (
        <>
          {chunks.map((chunk, i) => (
            <span style={{ color: themeColor }} key={i}>
              {chunk}
            </span>
          ))}
        </>
      ),
    }
  )

  const activedText = formatMessage(
    {
      id: "voucher::detail::actived",
      defaultMessage:
        "Voucher <carrot>{voucherCode}</carrot> has been activated",
    },
    {
      voucherCode: voucherCodeParam?.toUpperCase(),
      carrot: (...chunks) => (
        <>
          {chunks.map((chunk, i) => (
            <span style={{ color: themeColor }} key={i}>
              {chunk}
            </span>
          ))}
        </>
      ),
    }
  )

  const titleText = isActivated
    ? activedText
    : isExpired
    ? expiredDetailText
    : voucherStatus === PRODUCT_VARIATION_TICKET_STATUS.USED
    ? usedDetailText
    : activeDetailText

  const isActive =
    !isExpired && voucherStatus !== PRODUCT_VARIATION_TICKET_STATUS.USED

  const buttonText = isActivated
    ? okButtonText
    : isExpired || voucherStatus === PRODUCT_VARIATION_TICKET_STATUS.USED
    ? closeButtonText
    : activeButtonText

  const buttonAction =
    isExpired ||
    voucherStatus === PRODUCT_VARIATION_TICKET_STATUS.USED ||
    isActivated
      ? onClose
      : () => {
          makeVoucherCodeUse()
        }
  const listData = [
    {
      description: formatMessage({
        id: "dashboard::tableHeader::dateAndTime",
        defaultMessage: "Date/time",
      }),
      value: formattedActiveDate,
    },
    /* {
      description: formatMessage({
        id: "dashboard::tableHeader::customerName",
        defaultMessage: "Customer Name",
      }),
      value: isCampaign
        ? voucherCodeData?.donation?.fullName ??
          voucherCodeData?.donation?.displayName
        : user?.fullName,
    },*/
    {
      description: isCampaign
        ? formatMessage({
            id: "dashboard::tableHeader::donationAmount",
            defaultMessage: "Donation Aount",
          })
        : formatMessage({
            id: "dashboard::tableHeader::price",
            defaultMessage: "Price",
          }),
      value: (
        <Money
          value={
            voucherCodeData?.donation?.amount ??
            productVariation?.price ??
            offer?.minPrice ??
            productVariation?.donationAmount
          }
        />
      ),
    },
  ]

  if (!isCampaign) {
    listData.push({
      description: formatMessage({
        id: "dashboard::tableHeader::pacDiscount",
        defaultMessage: "PAC Discount",
      }),
      value: (
        <>
          <Logo
            className="pac-icon"
            width={isWide ? 25 : 19}
            height={isWide ? 32 : 24}
            fill={theme?.colors?.carrot}
          />
          <Money
            value={offer?.maxPacDiscount}
            currency={false}
            style="percent"
          />
        </>
      ),
    })
  }

  const tierDescription = getValueForLocale(
    tier,
    "description",
    locale,
    defaultLocale
  )

  const className = isActive && !isActivated ? "active-voucher" : ""

  return (
    <StyledDetailContainer>
      <StyledVerifyDetailTitle className={className}>
        {titleText}
      </StyledVerifyDetailTitle>
      <Spacer top={2} />
      <StyledOfferName className={className}>{offer?.name}</StyledOfferName>
      {isCampaign &&
      !(tier?.isDefault || tier?.description == "Default Tier") &&
      tierDescription ? (
        <>
          <Spacer top={1} />
          <StyledItemDescription className={className}>
            {tierDescription}
          </StyledItemDescription>
        </>
      ) : null}
      <Spacer top={1} />
      <StyledOfferProductName className={className}>
        {product?.productName}
      </StyledOfferProductName>
      <Spacer top={2} />
      {!isActive &&
        voucherStatus === PRODUCT_VARIATION_TICKET_STATUS.USED &&
        formattedDate && (
          <>
            <StyledVerifyDate className={isCampaign ? "blue-text" : ""}>
              {formattedDate}
            </StyledVerifyDate>
            <StyledTimeOfAction>
              <FormattedMessage
                id="voucher::detail::dateAndTimeOfAction"
                defaultMessage="Date and time of activation"
              />
            </StyledTimeOfAction>
          </>
        )}

      {isActive && !isActivated && (
        <StyledListContainer>
          {!isTablet && <StyledListBackground />}
          {listData?.map((item, i) => (
            <Fragment key={i}>
              <StyledListItemContainer>
                <StyledItemDescription>
                  {item?.description}
                </StyledItemDescription>
                <StyledItemValue>{item?.value}</StyledItemValue>
              </StyledListItemContainer>
              <StyledLine />
            </Fragment>
          ))}
        </StyledListContainer>
      )}
      {isActivated && (
        <StyledOfferProductName>{activatedDescription}</StyledOfferProductName>
      )}
      <Spacer top={2} />
      <StyledVoucherDetailButton>
        <Button
          color={isCampaign ? "pacificBlue" : "carrot"}
          onClick={buttonAction}
          type="button"
          className={!isTablet ? "sticky" : ""}>
          {buttonText}
        </Button>
      </StyledVoucherDetailButton>
    </StyledDetailContainer>
  )
}

VoucherDetail.propTypes = {
  onClose: PropTypes.func,
  voucherCodeParam: PropTypes.string,
}

export default VoucherDetail
