import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "gatsby-plugin-intl"
import { Checkbox } from "@tmu/components/common"
import { StyledNotificationTableHeader } from "./index.styles"

const NotificationTableHeader = ({ onToggleAll, isAllSelected }) => {
  return (
    <StyledNotificationTableHeader>
      <div>
        <Checkbox isChecked={isAllSelected} onChange={onToggleAll} />
      </div>
      <div>
        <FormattedMessage
          id="dashboard::ngo::tableHeader::description"
          defaultMessage="Description"
        />
      </div>
      <div>
        <FormattedMessage
          id="dashboard::ngo::tableHeader::details"
          defaultMessage="Details"
        />
      </div>
      <div>
        <FormattedMessage
          id="dashboard::ngo::tableHeader::dateTime"
          defaultMessage="Date / Time"
        />
      </div>
    </StyledNotificationTableHeader>
  )
}

NotificationTableHeader.propTypes = {
  isAllSelected: PropTypes.bool.isRequired,
  onToggleAll: PropTypes.func.isRequired,
}

NotificationTableHeader.defaultProps = {
  isAllSelected: false,
  onToggleAll: () => {},
}

export default NotificationTableHeader
