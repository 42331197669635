import { useEffect, useState } from "react"
import { gql, useLazyQuery } from "@apollo/client"
import { useApolloApiClients } from "@tmu/apollo/client"
import {
  orderItemParts,
  cartItemParts,
} from "@tmu/apollo/storefront/fragments/cart"

export const ALL_ORDERS = gql`
  query AllOrders(
    $first: Int
    $after: String
    $status_In: [String]
    $internal: Boolean
    $orderBy: String
  ) {
    allOrders(
      first: $first
      after: $after
      status_In: $status_In
      internal: $internal
      orderBy: $orderBy
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          ...orderItemParts
          cartItems {
            edges {
              node {
                ...cartItemParts
              }
            }
          }
        }
      }
    }
  }
  ${orderItemParts}
  ${cartItemParts}
`

export const ORDER_DETAILS = gql`
  query Order($id: ID!) {
    order(id: $id) {
      ...orderItemParts
      stripeRequiresActionData
      cartItems {
        edges {
          node {
            ...cartItemParts
          }
        }
      }
    }
  }
  ${orderItemParts}
  ${cartItemParts}
`

const useAllOrders = (options) => {
  const { storefrontClient } = useApolloApiClients()
  const query = useLazyQuery(ALL_ORDERS, {
    ...options,
    client: options?.client ?? storefrontClient,
  })

  const [callOrders, { loading, data, refetch, fetchMore }] = query

  useEffect(() => {
    callOrders()
  }, [])

  const [orders, setOrders] = useState([])
  const pageInfo = data?.allOrders?.pageInfo
  useEffect(() => {
    const orders =
      data?.allOrders?.edges?.map(({ node }) => ({
        ...node,
        cartItems: node?.cartItems?.edges?.map(({ node }) => ({
          ...node,
        })),
      })) ?? []
    setOrders(orders)
  }, [loading, data])

  const handleLoadMore = (sortInfo) => {
    fetchMore({
      variables: {
        after: pageInfo?.endCursor,
        orderBy: sortInfo,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult.allOrders?.edges
        const pageInfo = fetchMoreResult.allOrders.pageInfo

        return newEdges.length
          ? {
              // Put the new allOrders at the end of the list and update `pageInfo`
              // so we have the new `endCursor` and `hasNextPage` values
              ...fetchMoreResult,
              allOrders: {
                __typename: prev.allOrders.__typename,
                edges: [...prev.allOrders?.edges, ...newEdges],
                pageInfo,
              },
            }
          : prev
      },
    })
  }

  return {
    loading,
    orders,
    refetch,
    fetchMore,
    pageInfo,
    callOrders,
    handleLoadMore,
  }
}

export default useAllOrders
