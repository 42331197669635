import React, { useEffect } from "react"
import { SignInForm } from "@tmu/components/auth"
import { navigate, injectIntl } from "gatsby-plugin-intl"
import { useAuth } from "@tmu/hooks"
import queryString from "query-string"
import { isBrowser, isTokenExpired } from "@tmu/utils/auth"
import { get } from "@tmu/utils/storage"

const SignIn = ({ location, intl }) => {
  const { locale } = intl
  const { isLoading } = useAuth()
  const isAuthenticated = !isTokenExpired(get("token"))

  const paramOptions = {
    arrayFormat: "comma",
  }
  const params = queryString.parse(location.search, paramOptions)
  const next = params?.next?.replace(/\/(en|it|es)/g, "") ?? "/"
  const nextUrl = queryString.stringifyUrl({
    url: next,
    query: params,
  })
  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      navigate(nextUrl || "/")
    }
  }, [isLoading, isAuthenticated, next])

  return isBrowser ? <SignInForm location={location} intl={intl} /> : null
}

export default injectIntl(SignIn)
