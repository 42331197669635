import queryString from "query-string"
import React, { Fragment, useRef, useCallback, useEffect } from "react"
import {
  StyledBigText,
  StyledDescription,
  StyledCardWrapper,
  StyledCardTitle,
  StyledCard,
  StyledFormRow,
  StyledProfileFlexRow,
  StyledProfileSection,
  StyledProfileFormSection,
  StyledIconWrapper,
  StyledInfoIcon,
} from "./index.styles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons/faInfoCircle"
import { Tooltip } from "react-tooltip"

import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { useAuth, useAddressValidation, useCountries } from "@tmu/src/hooks"
import { useFormikContext } from "formik"

import {
  Checkbox,
  FieldError,
  RadioSelect,
  CountrySelectorFilter,
  TextInput,
  Dropdown,
  GroupedCategorySubcategorySelect,
  TermsAndConditions,
  PhoneInput,
} from "@tmu/components/common"
import {
  REQUIRED_FIELD_SYMBOL,
  SUPPORTED_LANGUAGES,
} from "@tmu/src/apollo/constants"
import { ImageSelector } from "../dashboard/dashboardCommon"
import { useState } from "react"

const RegisterForm = ({ offer }) => {
  const paramOptions = { arrayFormat: "comma" }
  const params = queryString.parse(location.search, paramOptions)
  const isMerchantOnline = params?.type === "online"
  const isMerchantOffline = params?.type === "offline"
  const isMerchantInternal = params?.type === "internal"
  const isMerchantCorporate = false
  const [isChecked, setIsChecked] = useState(false)
  const { checkAddress, setAddressError, addressError } = useAddressValidation()

  const {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    isSubmitting,
    setValues,
    setTouched,
    setErrors,
    setFieldError,
    initialValues,
    setFieldTouched,
  } = useFormikContext()

  const handleCheckAddress = async () => {
    console.log("handleCheckAddress")
    const correctedAddressData = await checkAddress(
      values?.addressLine1,
      values?.postalCode,
      values?.country
    )
    if (correctedAddressData?.result) {
      setValues({
        ...values,
        country: correctedAddressData.country,
        city: correctedAddressData.city,
        postalCode: correctedAddressData.postalCode,
        addressLine1: correctedAddressData.addressLine1,
        location: `SRID=4326;POINT(${correctedAddressData?.longitude} ${correctedAddressData?.latitude})`,
      })
    } else {
      setFieldError(
        "addressLine1",
        formatMessage({
          id: "dashboard::merchantForm::notValidAddress",
          defaultMessage: "Not a valid address.",
        })
      )
    }
  }

  useEffect(() => {
    console.log("useEffect handleCheckAddress")

    if (values?.addressLine1 && values?.postalCode && values?.country) {
      handleCheckAddress()
    }
  }, [values?.addressLine1, values?.postalCode, values?.country])

  const { countryOptions, getCountries, callCountry } = useCountries()
  const { user } = useAuth()
  const { formatMessage } = useIntl()

  const currentCountry = useCallback(
    countryOptions?.find((item) => item?.value === values?.country)
  )

  const [showLinks, setShowLinks] = useState(false)

  const handleShowLinks = () => setShowLinks(true)

  const handleHideLinks = () => setShowLinks(false)

  useEffect(() => {
    getCountries({ noCache: true })
  }, [])

  const radioSelectOptions = [
    {
      id: "yes",
      label: formatMessage({
        id: "dashboard::campaigns::modalYes",
        defaultMessage: "Yes",
      }),
    },
    {
      id: "no",
      label: formatMessage({
        id: "dashboard::campaigns::modalNo",
        defaultMessage: "No",
      }),
    },
  ]

  const [selectedCountries, setSelectedCountries] = useState([])
  const [selectedCities, setSelectedCities] = useState([])

  const categoryItems = offer?.categories?.edges?.map(({ node }) => node) || []

  const handleCountrySelect = (countries, cities) => {
    let tempCountries = []
    let tempCities = []

    if (countries && Array.isArray(countries)) {
      tempCountries = countries
        ?.map((item) => item?.value)
        ?.filter((item) => item !== null)
      setFieldValue("availableCountries", tempCountries)
    }
    if (cities && Array.isArray(cities)) {
      tempCities = cities
        ?.map((item) => item?.value)
        ?.filter((item) => item !== null)
      setFieldValue("availableCities", tempCities)
    }
  }

  useEffect(() => {
    if (initialValues?.availableCities) {
      const selectedCitiesOptions =
        initialValues?.availableCities?.map((item) => ({
          ...item,
          value: item?.id,
          label: `${item?.country?.name}, ${item?.region?.name}, ${item?.name}`,
        })) ?? []

      setSelectedCities(selectedCitiesOptions)
    }

    if (initialValues?.availableCountries) {
      let tempSelected = []
      const promiseArr = []
      initialValues?.availableCountries?.forEach((item) => {
        promiseArr.push(callCountry({ variables: { code: item } }))
      })
      Promise.all(promiseArr).then((results) => {
        results?.forEach((result) => {
          const item = result?.data?.country
          const countryResult = {
            ...item,
            value: item?.code,
            label: item?.name,
          }
          tempSelected = [...tempSelected, countryResult]
        })
        setSelectedCountries(tempSelected)
      })
    }
  }, [])

  const availableAreasMap = new Map()

  const areas = [...selectedCountries, ...selectedCities]
  areas.forEach((item) => {
    availableAreasMap.set(item?.id, item)
  })

  const availableAreas = Array.from(availableAreasMap?.values())

  return (
    <>
      <StyledBigText>
        <FormattedMessage
          id="merchant::selfRegistrationForm::bigText"
          defaultMessage="Fill the onboarding form"
        />
      </StyledBigText>

      <StyledDescription>
        <FormattedMessage
          id="merchant::selfRegistrationForm::description1"
          defaultMessage="You are logged in as <bold>{email}</bold>"
          values={{
            email: user?.email,
            bold: (...chunks) => (
              <span className="bold">
                {chunks.map((chunk, i) => (
                  <Fragment key={i}>{chunk}</Fragment>
                ))}
              </span>
            ),
          }}
        />
        <br />
        <br />
        <FormattedMessage
          id="merchant::selfRegistrationForm::description2"
          defaultMessage="If you proceed, this email will be used for your merchant profile. To use a different email, create a new account with that email first."
        />
      </StyledDescription>

      <StyledCardWrapper>
        <StyledCardTitle>
          <FormattedMessage
            id="merchant::selfRegistrationForm::companyFormTitle"
            defaultMessage="Company data"
          />
        </StyledCardTitle>
        <StyledCard>
          <StyledFormRow>
            <div className="flex">
              <p className="caption">
                {formatMessage({
                  id: "dashboard::forms::legalName",
                  defaultMessage: "Legal Name",
                })}
                {REQUIRED_FIELD_SYMBOL}
              </p>
              <StyledIconWrapper>
                <StyledInfoIcon
                  data-tooltip-id="info-tooltip"
                  data-tooltip="test">
                  <FontAwesomeIcon icon={faInfoCircle} />
                </StyledInfoIcon>
                <Tooltip
                  arrowColor="transparent"
                  effect="solid"
                  type="info"
                  id="info-tooltip"
                  place="bottom"
                  opacity={1}
                  data-html={true}
                  insecure={true}
                  multiline={true}
                  className="tooltip">
                  <FormattedMessage
                    id="merchant::selfRegistrationForm::legalNameInfo"
                    defaultMessage="As it is registered in INPS"
                  />
                </Tooltip>
              </StyledIconWrapper>
            </div>
            <TextInput
              newDesign
              id="legalName"
              name="legalName"
              placeholder={formatMessage({
                id: "dashboard::forms::legalName",
                defaultMessage: "Legal Name",
              })}
              value={values.legalName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.legalName}
              touched={touched.legalName}
            />
          </StyledFormRow>

          <StyledFormRow>
            <div className="flex">
              <p className="caption">
                {formatMessage({
                  id: "dashboard::forms::displayName",
                  defaultMessage: "Display Name",
                })}
                {REQUIRED_FIELD_SYMBOL}
              </p>
              <StyledIconWrapper>
                <StyledInfoIcon
                  data-tooltip-id="info-tooltip2"
                  data-tooltip="test">
                  <FontAwesomeIcon icon={faInfoCircle} />
                </StyledInfoIcon>
                <Tooltip
                  arrowColor="transparent"
                  effect="solid"
                  type="info"
                  id="info-tooltip2"
                  place="bottom"
                  opacity={1}
                  data-html={true}
                  insecure={true}
                  multiline={true}
                  className="tooltip">
                  <FormattedMessage
                    id="merchant::selfRegistrationForm::displayNameInfo"
                    defaultMessage="Publicly shown your company name"
                  />
                </Tooltip>
              </StyledIconWrapper>
            </div>

            <TextInput
              newDesign
              id="displayName"
              name="displayName"
              placeholder={formatMessage({
                id: "dashboard::forms::displayName",
                defaultMessage: "Display Name",
              })}
              value={values.displayName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.displayName}
              touched={touched.displayName}
            />
          </StyledFormRow>

          <StyledFormRow>
            <p className="caption">
              <FormattedMessage
                id="dashboard::forms::vat"
                defaultMessage="VAT Number"
              />
              {REQUIRED_FIELD_SYMBOL}
            </p>
            <TextInput
              newDesign
              id="taxId"
              name="taxId"
              placeholder={formatMessage({
                id: "dashboard::forms::vat",
                defaultMessage: "VAT Number",
              })}
              value={values.taxId}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.taxId}
              touched={touched.taxId}
            />
          </StyledFormRow>

          <StyledFormRow>
            <p className="caption">
              {formatMessage({
                id: "dashboard::forms::phoneNumber",
                defaultMessage: "Phone number",
              })}
              {REQUIRED_FIELD_SYMBOL}
            </p>
            <PhoneInput
              name="phoneNumber"
              isRequired={true}
              value={values?.phoneNumber || ""}
              errors={errors}
              touched={touched}
              onChange={(phone) => setFieldValue("phoneNumber", phone, true)}
              onBlur={() => setTouched({ ...touched, phoneNumber: true })}
            />
          </StyledFormRow>

          <StyledFormRow>
            <p className="caption">
              {formatMessage({
                id: "dashboard::forms::addressLine",
                defaultMessage: "Address",
              })}
              {REQUIRED_FIELD_SYMBOL}
            </p>
            <TextInput
              newDesign
              id="addressLine1"
              name="addressLine1"
              placeholder={formatMessage({
                id: "dashboard::forms::addressLine",
                defaultMessage: "Address",
              })}
              value={values.addressLine1}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.addressLine1}
              touched={touched.addressLine1}
            />
          </StyledFormRow>

          <StyledFormRow>
            <p className="caption">
              {formatMessage({
                id: "dashboard::forms::postalCode",
                defaultMessage: "Postal Code",
              })}
              {REQUIRED_FIELD_SYMBOL}
            </p>
            <TextInput
              newDesign
              id="postalCode"
              name="postalCode"
              placeholder={formatMessage({
                id: "dashboard::forms::postalCode",
                defaultMessage: "Postal Code",
              })}
              value={values.postalCode}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.postalCode}
              touched={touched.postalCode}
            />
          </StyledFormRow>

          <StyledFormRow>
            <p className="caption">
              {formatMessage({
                id: "howItWorks::forms::country",
                defaultMessage: "Country",
              })}
              {REQUIRED_FIELD_SYMBOL}
            </p>
            <Dropdown
              newDesign
              data-testid="country"
              id="country"
              name="country"
              onBlur={handleBlur}
              value={currentCountry}
              options={countryOptions}
              // defaultSelectStyles={selectStyles}
              onChange={(selected) => {
                setFieldValue("country", selected?.value, true)
                setTouched({ ...touched, country: true }, true)
              }}
              error={errors.country}
              touched={touched.country}
            />
          </StyledFormRow>
          {isMerchantOffline || isMerchantOnline ? (
            <StyledFormRow>
              <p className="caption">
                {formatMessage({
                  id: "dashboard::forms::maxPacDiscount",
                  defaultMessage: "General PAC Discount",
                })}
                {REQUIRED_FIELD_SYMBOL}
              </p>
              <TextInput
                newDesign
                data-testid="maxPacDiscount"
                id="maxPacDiscount"
                name="maxPacDiscount"
                type="number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.maxPacDiscount}
                placeholder={formatMessage({
                  id: "dashboard::forms::maxPacDiscount",
                  defaultMessage: "General PAC Discount",
                })}
                error={errors.maxPacDiscount}
                touched={touched.maxPacDiscount}
              />
            </StyledFormRow>
          ) : null}
          <StyledFormRow>
            <p className="caption">
              <FormattedMessage
                id="dashboard::campaignForm::category"
                defaultMessage="Category"
              />
              {REQUIRED_FIELD_SYMBOL}
            </p>

            <StyledProfileSection>
              <div data-testid="input-category">
                <GroupedCategorySubcategorySelect
                  placeholder={formatMessage({
                    id: "categoryList::placeholder",
                    defaultMessage: "Choose category",
                  })}
                  onSelect={(selectedCategories) => {
                    setFieldTouched("category")
                    setFieldValue("category", selectedCategories, true)
                  }}
                  selectedCategoryItems={categoryItems}
                  hideLine={true}
                  onBlur={(e) => {
                    setFieldTouched("category")
                    handleBlur(e)
                  }}
                />
                <div data-testid="error-category">
                  <FieldError name="category" />
                </div>
              </div>
            </StyledProfileSection>
          </StyledFormRow>

          <StyledFormRow>
            <p className="caption">
              <FormattedMessage
                id="dashboard::settings::language"
                defaultMessage="Language"
              />
              {REQUIRED_FIELD_SYMBOL}
            </p>

            <div data-testid="language-select">
              <Dropdown
                isFormField
                id="communicationLanguage"
                name="communicationLanguage"
                className="select-field custom-select hide-chevron"
                placeholder={formatMessage({
                  id: "signup::choose::languagePlaceholder",
                  defaultMessage: "Your Language",
                })}
                options={SUPPORTED_LANGUAGES}
                value={SUPPORTED_LANGUAGES?.find(
                  ({ value }) => values?.communicationLanguage === value
                )}
                onChange={(communicationLanguage) => {
                  setFieldValue(
                    "communicationLanguage",
                    communicationLanguage?.value ?? communicationLanguage,
                    true
                  )
                }}
                onBlur={() =>
                  setTouched({
                    ...touched,
                    communicationLanguage: true,
                  })
                }
                error={
                  values.communicationLanguage
                    ? null
                    : errors.communicationLanguage
                }
                touched={touched.communicationLanguage}
              />
            </div>
          </StyledFormRow>
          <StyledFormRow>
            <p className="caption">
              <FormattedMessage
                id="dashboard::forms::regions"
                defaultMessage="Regions where you provide services"
              />
              {REQUIRED_FIELD_SYMBOL}
            </p>

            <StyledProfileSection>
              <StyledProfileFlexRow
                className={
                  !values?.availableCountries?.length &&
                  touched?.availableCountries &&
                  errors?.availableCountries
                    ? "form-error-react-select"
                    : ""
                }>
                <CountrySelectorFilter
                  isOfferList={true}
                  handleSelect={handleCountrySelect}
                  isDropdown={false}
                  searchable={true}
                  inlineSearchableComponent={true}
                  activeCity={null}
                  activeCountry={null}
                  dontShowSelected={true}
                  withTagsBelow={!values?.isWorldwide}
                  cities={values?.availableCities}
                  countries={values?.availableCountries}
                  countriesFullData={selectedCountries}
                  disabled={values?.isWorldwide}
                />
              </StyledProfileFlexRow>
              {!values?.availableCountries?.length &&
                touched?.availableCountries &&
                errors?.availableCountries && (
                  <>
                    <div className="input-feedback">
                      {errors?.availableCountries}
                    </div>
                  </>
                )}
              <div className="mtop">
                <Checkbox
                  label={formatMessage({
                    id: "dashboard::service::isWorldwide",
                    defaultMessage: "Worldwide",
                  })}
                  id="isWorldwide"
                  isChecked={values?.isWorldwide}
                  onChange={(e) => {
                    handleChange(e)
                    const temp = values?.isWorldwide
                    setValues({
                      ...values,
                      isWorldwide: !temp,
                    })
                  }}
                />
              </div>
            </StyledProfileSection>
          </StyledFormRow>
        </StyledCard>
      </StyledCardWrapper>

      {false && isMerchantInternal ? (
        <StyledCardWrapper>
          <StyledCardTitle>
            <FormattedMessage
              id="merchant::selfRegistrationForm::bankDetailsTitle"
              defaultMessage="Bank Details"
            />
          </StyledCardTitle>
          <StyledCard>
            <StyledFormRow>
              <FormattedMessage
                id="merchant::selfRegistrationForm::bankDetailsDesc"
                defaultMessage="Please note, that we work only with EURO currency."
              />
            </StyledFormRow>

            <StyledFormRow>
              <p className="caption">
                {formatMessage({
                  id: "dashboard::forms::bankName",
                  defaultMessage: "Bank Name",
                })}
                {REQUIRED_FIELD_SYMBOL}
              </p>
              <TextInput
                newDesign
                id="bankName"
                name="bankName"
                placeholder={formatMessage({
                  id: "dashboard::forms::bankName",
                  defaultMessage: "Bank Name",
                })}
                value={values.bankName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.bankName}
                touched={touched.bankName}
              />
            </StyledFormRow>

            <StyledFormRow>
              <p className="caption">
                {formatMessage({
                  id: "dashboard::forms::bic",
                  defaultMessage: "BIC",
                })}
                {REQUIRED_FIELD_SYMBOL}
              </p>
              <TextInput
                newDesign
                id="bic"
                name="bic"
                placeholder={formatMessage({
                  id: "dashboard::forms::bic",
                  defaultMessage: "BIC",
                })}
                value={values.bic}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.bic}
                touched={touched.bic}
              />
            </StyledFormRow>

            <StyledFormRow>
              <p className="caption">
                {formatMessage({
                  id: "dashboard::forms::iban",
                  defaultMessage: "IBAN",
                })}
                {REQUIRED_FIELD_SYMBOL}
              </p>
              <TextInput
                newDesign
                id="iban"
                name="iban"
                placeholder={formatMessage({
                  id: "dashboard::forms::iban",
                  defaultMessage: "IBAN",
                })}
                value={values.iban}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.iban}
                touched={touched.iban}
              />
            </StyledFormRow>
            <StyledFormRow>
              <p className="caption">
                <FormattedMessage
                  id="merchant::selfRegistrationForm::vies"
                  defaultMessage="Have you registered VIES?"
                />
                {REQUIRED_FIELD_SYMBOL}
              </p>
              <RadioSelect
                items={radioSelectOptions}
                onChange={(e) => {
                  setrFiel(e.target.value === "yes")
                  setErrors({})
                }}
              />
            </StyledFormRow>
          </StyledCard>
        </StyledCardWrapper>
      ) : null}
      <StyledCardWrapper>
        <StyledCardTitle>
          <FormattedMessage
            id="merchant::selfRegistrationForm::contentAndDesc"
            defaultMessage="Content and description"
          />
        </StyledCardTitle>
        <StyledCard>
          <StyledFormRow>
            <p className="caption">
              <FormattedMessage
                id="dashboard::partnerProfileForm::logo"
                defaultMessage="Logo"
              />
              {REQUIRED_FIELD_SYMBOL}
            </p>

            <p>
              <FormattedMessage
                id="dashboard::merchantStore::storeLogoExplanation"
                defaultMessage="1200x675px is the recommended resolution, or other which has an aspect ratio of 16/9"
              />
            </p>

            <StyledProfileFormSection horizontal className="logo-wrapper">
              <ImageSelector
                dashed
                alignOverlay={true}
                noAspect
                defaultValue={values?.logo}
                onChange={(image) => {
                  setFieldValue("logo", image)
                  setTouched({ ...touched, logo: true }, true)
                }}
                onCropChange={(croppedImage) => {
                  setFieldValue("logo", croppedImage)
                  setTouched({ ...touched, logo: true }, true)
                }}
                onReset={() => setFieldValue("logo", initialValues.logo)}
              />
            </StyledProfileFormSection>
            <FieldError name="logo" />
          </StyledFormRow>

          <StyledFormRow>
            <p className="caption">
              <FormattedMessage
                id="merchant::selfRegistrationForm::companyImage"
                defaultMessage="Company image"
              />
              {REQUIRED_FIELD_SYMBOL}
            </p>
            <p>
              <FormattedMessage
                id="dashboard::service::uploadPhotoDescription"
                defaultMessage="Upload image that represent your brand. We cut images to the ratio of horizontal 16:9. 1200x675px is the recommended resolution."
              />
            </p>
            <div>
              <ImageSelector
                dashed
                defaultValue={values?.image}
                onChange={(image) => setFieldValue("image", image, true)}
                onCropChange={(croppedImage) =>
                  setFieldValue("image", croppedImage, true)
                }
                onReset={() =>
                  setFieldValue("image", initialValues?.image || "", true)
                }
              />
              <FieldError name="image" />
            </div>
          </StyledFormRow>

          <StyledFormRow>
            <p className="caption">
              {formatMessage({
                id: "dashboard::service::description",
                defaultMessage: "Description",
              })}
              {REQUIRED_FIELD_SYMBOL}
            </p>
            <TextInput
              newDesign
              id="description"
              name="description"
              type="textarea"
              textAreaMaxLength={800}
              placeholder={formatMessage({
                id: "merchant::selfRegistrationForm::descText",
                defaultMessage:
                  "This description will appear on your merchant profile page",
              })}
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.description}
              touched={touched.description}
            />
          </StyledFormRow>

          <StyledFormRow>
            <p className="caption">
              {formatMessage({
                id: "dashboard::forms::website",
                defaultMessage: "Website",
              })}
              {isMerchantOnline ? REQUIRED_FIELD_SYMBOL : ""}
            </p>
            <TextInput
              newDesign
              id="website"
              name="website"
              placeholder={formatMessage({
                id: "dashboard::forms::website",
                defaultMessage: "Website",
              })}
              value={values.website}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.website}
              touched={touched.website}
            />
          </StyledFormRow>

          {showLinks ? (
            <>
              <StyledFormRow>
                <p className="caption">
                  {formatMessage({
                    id: "dashboard::forms::facebook",
                    defaultMessage: "Facebook",
                  })}
                </p>
                <TextInput
                  newDesign
                  id="facebook"
                  name="facebook"
                  placeholder={formatMessage({
                    id: "dashboard::forms::facebook",
                    defaultMessage: "Facebook",
                  })}
                  value={values.facebook}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.facebook}
                  touched={touched.facebook}
                />
              </StyledFormRow>

              <StyledFormRow>
                <p className="caption">
                  {formatMessage({
                    id: "dashboard::forms::instagram",
                    defaultMessage: "Instagram",
                  })}
                </p>
                <TextInput
                  newDesign
                  id="instagram"
                  name="instagram"
                  placeholder={formatMessage({
                    id: "dashboard::forms::instagram",
                    defaultMessage: "Instagram",
                  })}
                  value={values.instagram}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.instagram}
                  touched={touched.instagram}
                />
              </StyledFormRow>

              <StyledFormRow>
                <p className="caption">
                  {formatMessage({
                    id: "dashboard::forms::youtube",
                    defaultMessage: "Youtube",
                  })}
                </p>
                <TextInput
                  newDesign
                  id="youtube"
                  name="youtube"
                  placeholder={formatMessage({
                    id: "dashboard::forms::youtube",
                    defaultMessage: "Youtube",
                  })}
                  value={values.youtube}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.youtube}
                  touched={touched.youtube}
                />
              </StyledFormRow>

              <StyledFormRow>
                <p className="caption">
                  {formatMessage({
                    id: "dashboard::forms::linkedin",
                    defaultMessage: "Linkedin",
                  })}
                </p>
                <TextInput
                  newDesign
                  id="linkedin"
                  name="linkedin"
                  placeholder={formatMessage({
                    id: "dashboard::forms::linkedin",
                    defaultMessage: "Linkedin",
                  })}
                  value={values.linkedin}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.linkedin}
                  touched={touched.linkedin}
                />
              </StyledFormRow>

              <StyledFormRow>
                <p className="caption">
                  {formatMessage({
                    id: "dashboard::forms::twitter",
                    defaultMessage: "Twitter",
                  })}
                </p>
                <TextInput
                  newDesign
                  id="twitter"
                  name="twitter"
                  placeholder={formatMessage({
                    id: "dashboard::forms::twitter",
                    defaultMessage: "Twitter",
                  })}
                  value={values.twitter}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.twitter}
                  touched={touched.twitter}
                />
              </StyledFormRow>

              <StyledFormRow>
                <p className="blue" onClick={handleHideLinks}>
                  <FormattedMessage
                    id="merchant::selfRegistrationForm::hideLinks"
                    defaultMessage="Hide social links"
                  />
                </p>
              </StyledFormRow>
            </>
          ) : (
            <StyledFormRow>
              <p className="blue" onClick={handleShowLinks}>
                <FormattedMessage
                  id="merchant::selfRegistrationForm::addLinks"
                  defaultMessage="Add links to socials"
                />
              </p>
            </StyledFormRow>
          )}
        </StyledCard>
      </StyledCardWrapper>
      <Checkbox
        className="check"
        label={formatMessage({
          id: "merchant::selfRegistrationForm::signUpTMU",
          defaultMessage: "Sign me up for TrustMeUp exclusive offers and news",
        })}
        isChecked={isChecked}
        onChange={() => setIsChecked(!isChecked)}
      />
      <br />
      <TermsAndConditions
        className="check"
        name="agreeTerms"
        errors={errors}
        touched={touched}
        checked={values.agreeTerms}
        onChange={handleChange}
        onClick={() => setTouched({ ...touched, agreeTerms: true })}
      />
      <br />
    </>
  )
}

export default RegisterForm
