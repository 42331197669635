import React from "react"
import CartTableHeader from "./CartTableHeader"
import CartTableRow from "./CartTableRow"
import { StyledShoppingCartTable } from "./index.styles"
import { StyledCartTable } from "./table.styles"

const CartTable = ({
  cartItems,
  removeFromCart = () => {},
  setQuantity = () => {},
  isSalesStatusVisible = false,
  isEditable = false,
  disabled,
  selectedCartItemId,
  size,
  index = "",
  page = "",
  refund = false,
  handleRequestRefund = () => {},
  setRefund = () => {},
}) => {
  return (
    <StyledShoppingCartTable className="cart-table">
      <CartTableHeader
        isSalesStatusVisible={isSalesStatusVisible}
        index={index}
        page={page}
      />
      <StyledCartTable>
        {cartItems &&
          Object.keys(cartItems).map((key, index) => {
            const cartItem = cartItems[key].node || cartItems[key]
            return (
              cartItem.offer &&
              (page === "dashboard-order" ? (
                cartItem?.fulfilments.map((key, index) => {
                  return (
                    <CartTableRow
                      key={key}
                      productId={cartItem.offer.id}
                      fulfillmentId={key?.id || null}
                      fulfillmentStatus={key?.status || null}
                      voucherLink={key?.voucher || null}
                      isSalesStatusVisible={isSalesStatusVisible}
                      status={cartItem?.status || cartItem?.offer?.status}
                      cartItemId={cartItem?.cartItemId ?? cartItem?.id ?? null}
                      name={cartItem?.name || cartItem?.offer?.name}
                      image={cartItem?.offer?.image}
                      quantity={cartItem?.quantity || 1}
                      vatPercentage={cartItem?.tax || cartItem?.vatRate || 0}
                      subtotal={
                        cartItem?.frozenPrice || cartItem?.offer?.minPrice || 0
                      }
                      pacDiscountAmount={cartItem?.pacDiscountAmount}
                      onSetQuantity={setQuantity}
                      onRemove={removeFromCart}
                      isEditable={isEditable}
                      selectedCartItemId={selectedCartItemId}
                      disabled={disabled}
                      imgSize={size}
                      index={index}
                      page={page}
                      refund={refund}
                      handleRequestRefund={handleRequestRefund}
                      refundRequests={key?.refundRequest}
                      setRefund={setRefund}
                    />
                  )
                })
              ) : (
                <CartTableRow
                  key={key}
                  productId={cartItem.offer.id}
                  fulfillmentId={
                    cartItem?.fulfilments?.length
                      ? cartItem?.fulfilments[index]?.id
                      : "-"
                  }
                  fulfillmentStatus={
                    cartItem?.fulfilments?.[index]?.status ?? "-"
                  }
                  voucherLink={cartItem?.fulfilments?.[index]?.voucher}
                  isSalesStatusVisible={isSalesStatusVisible}
                  status={cartItem?.status || cartItem?.offer?.status}
                  cartItemId={cartItem?.cartItemId ?? cartItem?.id ?? null}
                  name={cartItem?.name || cartItem?.offer?.name}
                  image={cartItem?.offer?.image}
                  quantity={cartItem?.quantity}
                  vatPercentage={cartItem?.tax || cartItem?.vatRate}
                  subtotal={
                    cartItem?.subtotal ||
                    cartItem?.frozenPrice ||
                    cartItem?.offer?.minPrice ||
                    0
                  }
                  pacDiscountAmount={cartItem?.pacDiscountAmount}
                  onSetQuantity={setQuantity}
                  onRemove={removeFromCart}
                  isEditable={isEditable}
                  selectedCartItemId={selectedCartItemId}
                  disabled={disabled}
                  imgSize={size}
                  index={index}
                />
              ))
            )
          })}
      </StyledCartTable>
    </StyledShoppingCartTable>
  )
}

export default CartTable
