import React, { useContext, useEffect, useState } from "react"
import { faBadgePercent } from "@fortawesome/pro-light-svg-icons/faBadgePercent"
import { faBahai } from "@fortawesome/pro-solid-svg-icons/faBahai"
import { faClipboardUser } from "@fortawesome/pro-solid-svg-icons/faClipboardUser"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FOOTER_TYPE } from "@tmu/apollo/constants"
import { LazyImage, Spinner } from "@tmu/components/common"
import { HowItWorksForm } from "@tmu/components/how-it-works"
import {
  StyledLogoSlice,
  StyledNgoList,
  StyledNgoListItem,
  StyledNgoListTitle,
} from "@tmu/components/how-it-works/Join/index.styles"
import { HomePageContext } from "@tmu/context/homePageContext"
import { StyledJoinPageWrapper } from "@tmu/global/page-addons/how-it-works.styles"
import {
  StyledStoryBoxTitle,
  StyledWrapper,
  StyledBenefits,
  StyledBenefitsList,
  StyledBenefitCard,
  StyledFontIcon,
  StyledTitle,
  StyledDescription,
  StyledContactFormDescription,
  StyledContactFormTitle,
  StyledStoryBoxImage,
  StyledStoryBoxMobileText,
  StyledStoryBox,
  StyledStoryBoxHeader,
  StyledStoryBoxOverlay,
  StyledStoreImage,
  StyledStoreContainer,
  StyledStoreContent,
  StyledStoreContentTitle,
  StyledStoreContentDesc,
} from "@tmu/global/page-addons/internal-merchants-page.styles"
import { useFooterType } from "@tmu/hooks"
import { getValueForLocale } from "@tmu/utils/string"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { injectIntl, useIntl, FormattedMessage } from "gatsby-plugin-intl"
import MerchantSelfRegistrationSection from "@tmu/src/components/common/MerchantSelfRegistrationSection"

const { CLOUDFLARE_IMAGE_URL } = process.env

const InternalMerchantsPage = () => {
  const { locale, defaultLocale, formatMessage } = useIntl()
  const { setHeaderConfigData } = useContext(HomePageContext)
  const [firstLoad, setFirstLoad] = useState(false)
  useFooterType({ footerType: FOOTER_TYPE.WIDE })

  useEffect(() => {
    setHeaderConfigData({ transparent: true })
  }, [])

  const { isTablet, isDesktop, isWide } = getAllScreenTypes()

  const numberOfNgos = 8

  const logoWidth = isWide ? 413 : isDesktop ? 223 : isTablet ? 168 : 156

  const bannerData = {
    header: formatMessage({
      id: "join::inlineMerchants::heroHeader",
      defaultMessage: "Create a service page for selling your service online",
    }),
    title: formatMessage({
      id: "join::inlineMerchants::heroTitle",
      defaultMessage:
        "Provide your service page with content, images and prices. Offer several service options for your customer to choose from",
    }),
    image:
      CLOUDFLARE_IMAGE_URL +
      `/static/assets/images/join/${
        !isTablet
          ? `mobile_options.png`
          : !isDesktop
          ? "service_detail_options2.png"
          : `service_detail.png`
      }`,
  }

  const benefitCards = [
    {
      title: formatMessage({
        id: "join::internalMerchant::title",
        defaultMessage: "Fill your profile to create your store page",
      }),
      desc: formatMessage({
        id: "join::internalMerchant::description",
        defaultMessage:
          "Fill the description, upload images and provide contacts if you want your customers to reach you",
      }),
      fontIcon: faClipboardUser,
    },
    {
      title: formatMessage({
        id: "join::internalMerchant::title2",
        defaultMessage: "Create service card and set a discount",
      }),
      desc: formatMessage({
        id: "join::internalMerchant::description2",
        defaultMessage:
          "If your service has several price packages you can reflect it on your service page",
      }),
      fontIcon: faBadgePercent,
    },
    {
      title: formatMessage({
        id: "join::internalMerchant::title3",
        defaultMessage: "Grow your business as charity friendly",
      }),
      desc: formatMessage({
        id: "join::internalMerchant::description3",
        defaultMessage:
          "Put your brand name alongside with big and small Non-Profit organisations as a charity friendly",
      }),
      fontIcon: faBahai,
    },
  ]

  const pageData = {
    contactFormTitle: formatMessage({
      id: "join::forms::contactForm::internalMerchantTitle",
      defaultMessage: "Join to display your professional services",
    }),
    contactFormDescription: formatMessage({
      id: "join::forms::contactForm::internalMerchantDescription",
      defaultMessage:
        "Tell us about your charity so we can help you to onboard",
    }),
    termsLink: "/legal/merchant-terms/20",
    sender: "MERCHANT",
    logoItems: [
      {
        node: {
          logoEn:
            "https://images.trustmeup.com/merchants/store/logo/1669978605081-QZPX4YBJeeZUuq4av0XPHw.jpg",
        },
      },
      {
        node: {
          logoEn:
            "https://images.trustmeup.com/merchants/store/logo/1665564894726-prhOoUFZXSEpVDSAbDjsKA.jpg",
        },
      },
      {
        node: {
          logoEn:
            "https://images.trustmeup.com/merchants/store/logo/1665564826004-l0lm5qAOl1f0QZeS9oEDLA.jpg",
        },
      },
      {
        node: {
          logoEn:
            "https://images.trustmeup.com/merchants/store/logo/1664789909085-1SZc2vL4Sxn1UbhN01gpJg.jpg",
        },
      },
      {
        node: {
          logoEn:
            "https://images.trustmeup.com/merchants/store/logo/1663678145648-nnR15Pvl5hE1lSXZLIiw7g.jpg",
        },
      },
      {
        node: {
          logoEn:
            "https://images.trustmeup.com/merchants/store/logo/1650458594430-NeSP_KJNREKBO4_AWBBQYg.jpg",
        },
      },
      {
        node: {
          logoEn:
            "https://images.trustmeup.com/merchants/store/logo/1681375137392-O7k5Ytl3e_WcqGTi0cM4vQ.jpg",
        },
      },
      {
        node: {
          logoEn:
            "https://images.trustmeup.com/merchants/store/logo/1665564991184-uNBwIR4dvgkji6KUE_4zfA.jpg",
        },
      },
    ],
    logoItemsTitle: formatMessage({
      id: "join::merchant::ngoListTitle",
      defaultMessage: "Some of Merchants that already work with us:",
    }),
  }

  const contactFormTitle = (
    <StyledContactFormTitle>
      {pageData?.contactFormTitle}
    </StyledContactFormTitle>
  )

  const contactFormDescription = (
    <StyledContactFormDescription>
      {pageData?.contactFormDescription}
    </StyledContactFormDescription>
  )
  const textData = (
    <StyledStoryBoxTitle>{bannerData?.title}</StyledStoryBoxTitle>
  )

  useEffect(() => {
    setFirstLoad(true)
  }, [])

  const centerStyles = {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "20.563rem",
    zIndex: 999,
  }

  if (!firstLoad) return <Spinner />

  return (
    <StyledJoinPageWrapper>
      <StyledWrapper>
        <div>
          <StyledStoryBox>
            <d>
              <StyledStoryBoxHeader style={!isTablet ? centerStyles : {}}>
                {bannerData?.header}
              </StyledStoryBoxHeader>
              {isTablet && textData}
            </d>
            {!isTablet && <StyledStoryBoxOverlay></StyledStoryBoxOverlay>}
            <StyledStoryBoxImage>
              <img altName="join as merchants" src={bannerData?.image} />
            </StyledStoryBoxImage>
          </StyledStoryBox>
          {!isTablet && (
            <StyledStoryBoxMobileText>{textData}</StyledStoryBoxMobileText>
          )}
        </div>
        <StyledBenefits>
          <StyledBenefitsList>
            {benefitCards?.map((e, i) => (
              <StyledBenefitCard key={i}>
                <StyledFontIcon>
                  <FontAwesomeIcon icon={e?.fontIcon} />
                </StyledFontIcon>

                {e?.title && <StyledTitle>{e?.title}</StyledTitle>}
                {e?.desc && (
                  <StyledDescription locale={locale || "en"}>
                    {e?.desc}
                  </StyledDescription>
                )}
              </StyledBenefitCard>
            ))}
          </StyledBenefitsList>
        </StyledBenefits>
        <StyledStoreContainer>
          <StyledStoreImage>
            <img
              altName="store-detail"
              src={
                CLOUDFLARE_IMAGE_URL +
                `/static/assets/images/join/service_map.png`
              }
            />
          </StyledStoreImage>

          <StyledStoreContent>
            <StyledStoreContentTitle>
              <FormattedMessage
                id="join::inlineMerchants::storeText1"
                defaultMessage="Individual store page and store directions"
              />
            </StyledStoreContentTitle>

            <StyledStoreContentDesc>
              <FormattedMessage
                id="join::inlineMerchants::storeText2"
                defaultMessage="If you choose to, share your address on the service page, so your customer could make a route to your office in one click"
              />
            </StyledStoreContentDesc>
          </StyledStoreContent>
        </StyledStoreContainer>
        <StyledLogoSlice className="join-internal-merchant">
          <StyledNgoListTitle>{pageData?.logoItemsTitle}</StyledNgoListTitle>
          <StyledNgoList sender={pageData?.sender}>
            {pageData?.logoItems?.slice(0, numberOfNgos)?.map((item, index) => {
              return (
                <StyledNgoListItem key={index}>
                  <LazyImage
                    altName={"logo-" + index}
                    src={getValueForLocale(
                      item?.node,
                      "logo",
                      locale,
                      defaultLocale
                    )}
                    width={logoWidth}
                    height="auto"
                    fit="cover"
                  />
                </StyledNgoListItem>
              )
            })}
          </StyledNgoList>
        </StyledLogoSlice>
        <MerchantSelfRegistrationSection isMerchantInternal />
      </StyledWrapper>
    </StyledJoinPageWrapper>
  )
}

export default injectIntl(InternalMerchantsPage)
