import styled from "@emotion/styled"
import {
  StyledCampaignsWrapper,
  StyledCampaignsBody,
  StyledContent as StyledCampaignContent,
  NoResults as CampaignsNoResults,
} from "@tmu/components/campaigns/CampaignListing/index.styles"

export const StyledWrapper = styled((props) => (
  <StyledCampaignsWrapper {...props} />
))`
  margin: unset;
  background: ${(p) => p.theme.colors.white};

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    background: none;
  }
`

export const StyledServicesBody = styled((props) => (
  <StyledCampaignsBody {...props} />
))`
  > div {
    display: flex;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      grid-template-columns: 14.25rem auto;
      grid-gap: 1.5rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      grid-template-columns: 24.375rem auto;
      grid-gap: 3rem;
    }
  }

  margin: 0 -1rem;
  background: ${(p) => p.theme.colors.white};

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin: unset;
    background: ${(p) =>
      p.hasCorporateCampaigns ? p.theme.colors.whiteSmoke : "none"};
  }
`

export const StyledContent = styled((props) => (
  <StyledCampaignContent {...props} />
))`
  width: 100%;
  background: ${(p) => p.theme.colors.whiteSmoke};

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    background: none;
  }

  button {
    margin-right: 1rem;
    p {
      span {
        color: ${(p) => p.theme.colors.carrot};
      }

      svg {
        display: none;
      }
    }
  }
`

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: unset !important;
  margin-bottom: 1rem;
  background: ${(p) => p.theme.colors.whiteSmoke};
`

export const StyledTitle = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.pitchBlack};

  font-size: 1.125rem;
  line-height: 1;
  margin: 1.5rem 1rem 0;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1.5rem;
    line-height: 2.125rem;
    margin: unset;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin-top: 1rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    & > h4 {
      font-size: 3rem !important;
    }
    line-height: normal;
  }
`

export const StyledListing = styled.div`
  max-width: ${(p) => p.theme.breakpoints.wide};
  margin: 0 auto;
`

export const StyledActions = styled.div`
  text-align: center;
  display: block;
  align-self: center;
  justify-self: center;

  div {
    margin: 0;
  }
`

export const NoResults = styled((props) => <CampaignsNoResults {...props} />)`
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin: 0;
  }
  margin: 0 1rem;
`

export const StyledSliceWrapper = styled.div`
  margin: auto;
  width: 100%;
  background: ${(p) => p.theme.colors.whiteSmoke};

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    max-width: ${(p) => p.theme.breakpoints.tablet};
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    padding: 1rem 0 0;
    max-width: ${(p) => p.theme.breakpoints.desktop};
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding: 2.5rem 0 2rem;
    max-width: ${(p) => p.theme.breakpoints.wide};
  }
`
