import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import { Field, useFormikContext } from "formik"
import { TextInput, FieldError, Dropdown } from "@tmu/components/common"
import { StyledNameEmailFieldSet, StyledField } from "./index.styles"

const NameEmailFieldSet = ({ readOnlyFields = [], isMerchant }) => {
  const { formatMessage } = useIntl()
  const {
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    errors,
  } = useFormikContext()
  const {
    site: {
      siteMetadata: { supportedLanguages },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            supportedLanguages {
              id
              label
            }
          }
        }
      }
    `
  )

  const supportedLanguagesLocalization = supportedLanguages.map((key) => {
    return {
      ...key,
      label: (
        <FormattedMessage
          id={`footer::languages::${key?.label.toLowerCase()}`}
          defaultMessage={key?.label}
        />
      ),
    }
  })

  return (
    <>
      <StyledNameEmailFieldSet>
        <StyledField gridArea="legalName">
          <Field
            data-testid="input-legal-name"
            id="name"
            name="name"
            component={TextInput}
            className="float-container"
            value={values?.name ?? ""}
            onChange={handleChange}
            onBlur={handleBlur}
            label={formatMessage({
              id: "dashboard::forms::legalName",
              defaultMessage: "Legal Name",
            })}
          />
          <FieldError name="name" />
        </StyledField>
        <StyledField gridArea="displayName">
          <Field
            data-testid="input-display-name"
            id="displayName"
            name="displayName"
            component={TextInput}
            className="float-container"
            value={values?.displayName ?? ""}
            onChange={handleChange}
            onBlur={handleBlur}
            readOnly={
              values?.displayName && readOnlyFields.indexOf("displayName") > -1
            }
            label={formatMessage({
              id: "dashboard::forms::displayName",
              defaultMessage: "Display Name",
            })}
          />
          <FieldError name="displayName" />
        </StyledField>
        <StyledField gridArea="email">
          <Field
            data-testid="input-email"
            id="email"
            name="email"
            type="email"
            component={TextInput}
            className="float-container"
            value={values?.email ?? ""}
            onChange={handleChange}
            onBlur={handleBlur}
            readOnly={values?.email && readOnlyFields.indexOf("email") > -1}
            label={formatMessage({
              id: "dashboard::forms::email",
              defaultMessage: "Email",
            })}
          />
          <FieldError name="email" />
        </StyledField>
        <StyledField
          gridArea="communicationLang"
          data-testid="input-communicationLanguage">
          <Dropdown
            id="communicationLanguage"
            name="communicationLanguage"
            className="select-field custom-select"
            options={supportedLanguagesLocalization}
            value={supportedLanguagesLocalization.find(
              (lang) =>
                lang.id?.toUpperCase() ===
                values?.communicationLanguage?.toUpperCase()
            )}
            onChange={(lang) => {
              setFieldValue(
                "communicationLanguage",
                lang?.id.toUpperCase() || "",
                true
              )
            }}
            label={formatMessage({
              id: "dashboard::forms::communicationLanguage",
              defaultMessage: "Communication Language",
            })}
          />
        </StyledField>
        {isMerchant === true && (
          <StyledField gridArea="website">
            <Field
              data-testid="input-shopLink"
              id="shopLink"
              name="shopLink"
              component={TextInput}
              className="float-container"
              value={values?.shopLink}
              onChange={(e) => {
                setFieldTouched("shopLink")
                handleChange(e)
              }}
              onBlur={(e) => {
                setFieldTouched("shopLink")
                handleBlur(e)
              }}
              label={formatMessage({
                id: "dashboard::forms::shopLink",
                defaultMessage: "Shop Link",
              })}
            />
            <FieldError
              name="shopLink"
              autoFocus={true}
              scrollOpts={{ block: "end", behavior: "smooth" }}
            />
          </StyledField>
        )}
      </StyledNameEmailFieldSet>
    </>
  )
}

NameEmailFieldSet.propTypes = {
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
  avatar: PropTypes.shape({ fieldName: PropTypes.string }),
  logo: PropTypes.string,
  userProfile: PropTypes.bool,
}

NameEmailFieldSet.defaultProps = {
  readOnlyFields: [],
  avatar: null,
  logo: null,
  userProfile: false,
}

export default NameEmailFieldSet
