import { useQuery } from "@apollo/client"
import { LazyImage, Spinner, Swiper } from "@tmu/components/common"
import { MAIN_PARTNERS_QUERY } from "@tmu/src/apollo/dashboard/queries/partner"
import { getAllScreenTypes } from "@tmu/src/utils/mediaQueries"
import { FormattedMessage } from "gatsby-plugin-intl"
import { useRef } from "react"
import {
  StyledMainPartner,
  StyledMainPartnerLine,
  StyledMainPartnerTitle,
  StyledMainPartnersWrapper,
  StyledSlice,
} from "./index.styles"

const SliceMainPartners = () => {
  const ref = useRef()

  const { loading, data } = useQuery(MAIN_PARTNERS_QUERY)

  const { isTablet, isDesktop, isWide } = getAllScreenTypes()

  const partners = data?.mainAssociates?.edges

  return (
    <StyledMainPartnersWrapper ref={ref}>
      <StyledMainPartnerLine />

      {loading ? (
        <Spinner />
      ) : (
        <>
          <StyledMainPartnerTitle>
            <FormattedMessage
              id="homepage::partners::mainPartners"
              defaultMessage="Main Partners"
            />
          </StyledMainPartnerTitle>

          <StyledSlice
            data-testid="main-partners"
            sliderLength={partners?.length}>
            <Swiper isSmall={true} mainPartners={true}>
              {partners?.map(({ node: partner }) => {
                const imgSize = {
                  src: partner?.logoUrl,
                  height: isWide ? 229 : isDesktop ? 127 : isTablet ? 95 : 57,
                }

                return (
                  <StyledMainPartner>
                    <LazyImage
                      altName={`partner-image`}
                      {...imgSize}
                      fit="cover"
                    />
                  </StyledMainPartner>
                )
              })}
            </Swiper>
          </StyledSlice>
        </>
      )}
    </StyledMainPartnersWrapper>
  )
}

export default SliceMainPartners
