import { CardContainer, CardNumber, CardType, CardExpiry } from "./index.styles"

const CreditCard = ({ cardType, lastFour, className, email, expiry }) => {
  let markedEmail = null
  if (email?.length) {
    const emailArr = email?.split("@")
    const beforeAt = emailArr?.[0]
    const afterArr = emailArr?.[1]?.split(".")
    const afterAt = afterArr?.[0]
    const comPart = afterArr?.[1]
    markedEmail = `***${beforeAt?.substr(-3, 3)}@${afterAt?.substr(
      0,
      3
    )}***${comPart}`
  }

  const cardNumber = "**** " + lastFour

  const maskedText = markedEmail ? markedEmail : lastFour ? cardNumber : ""
  return (
    <CardContainer className={className}>
      <CardType>{cardType}</CardType>
      <CardNumber>{maskedText}</CardNumber>
      {expiry ? <CardExpiry>{expiry}</CardExpiry> : null}
    </CardContainer>
  )
}

export default CreditCard
