import React, { useEffect } from "react"
import { Router, useLocation } from "@reach/router"
import { injectIntl } from "gatsby-plugin-intl"
import PrivateRoute from "@tmu/components/PrivateRoute"
import { DashboardLayout } from "@tmu/layouts"
import SEO from "@tmu/components/seo"
import MerchantSelfRegistration from "../components/merchant-registration"

const MerchantRegistration = ({ intl }) => {
  const { formatMessage } = intl

  return (
    <>
      <SEO
        title={`${formatMessage({
          id: "merchantRegistration::title",
          defaultMessage: "Merchant Registration",
        })}`}
      />
      <Router basepath="/:locale/merchant-registration">
        <PrivateRoute component={MerchantSelfRegistration} path="/*" />
      </Router>
    </>
  )
}

export default injectIntl(MerchantRegistration)
