import styled from "@emotion/styled"
import { LazyImage } from "@tmu/components/common"
import { Button } from "@tmu/components/common"

export const StyledHiwTitle = styled.h3`
  color: ${(p) => p.theme.colors.primaryBrandTextColor};
  font-weight: ${(p) => p.theme.fontWeights.bold};
  font-size: 3rem;
  line-height: 3.625rem;
  text-align: center;
  margin: 8.125rem auto 0;
`
export const StyledHiwText = styled.p`
  color: ${(p) => p.theme.colors.gondola};
  font-size: 1.25rem;
  line-height: 1.5625rem;
  text-align: center;
  margin: 2rem auto;
`
export const StyledStartHereWrapper = styled.div`
  max-width: ${(p) => p.theme.breakpoints.wide};
  margin: 2rem auto 0;
  & > div {
    margin-bottom: 10rem;
    @media only screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
      margin-bottom: 3rem;
    }
  }
  #partner {
    flex-direction: row-reverse;
    & > div {
      align-items: flex-end;
      .how_it_works__text,
      .wws-tab-content__text {
        text-align: right;
      }

      @media screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
        align-items: center;
        .wws-tab-content__text {
          text-align: center;
        }
      }
    }
  }
`
export const StyledLazyImage = styled(LazyImage)`
  width: 100%;
  height: 9.375rem;
`
export const StyledTextWrapper = styled.div`
  text-align: left;
  margin-bottom: 1.25rem;

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    text-align: center;
  }
`
export const StyledApplyButton = styled(Button)`
  display: block;
  grid-area: action;
  min-width: 12.5rem;
  svg {
    margin-left: 0.5rem;
  }
`

export const StyledGetStartedWrapper = styled.div`
  position: relative;
  background-color: ${(p) => p.theme.colors.white};

  h1 {
    width: 100%;
    color: ${(p) => p.theme.colors.gondola};
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-size: 2.25rem;
    padding: 0;
    margin-bottom: 2.5rem;
  }

  p {
    font-size: 1.125rem;
  }
`

export const StyledHowitWorksTabContentRight = styled.div`
  display: grid;
  grid-template-areas: "big big small" "text medium medium";
  grid-gap: 1.75rem;
  grid-template-columns: minmax(0, 1fr) minmax(0, 0.2fr) minmax(0, 0.6fr);
  max-width: ${(p) => p.theme.breakpoints.tablet};

  margin: auto;
  .how_it_works__image--big {
    grid-area: big;
    align-self: end;
    width: 100%;
    margin-left: -2rem;
    img {
      border-radius: 0.3125rem;
    }
  }
  .how_it_works__image--small {
    grid-area: small;
    align-self: end;
    justify-self: right;
    width: 100%;
    margin-right: -1rem;
    img {
      border-radius: 0.3125rem;
    }
  }
  .how_it_works__image--medium {
    grid-area: medium;
    justify-self: right;
    width: 100%;
    img {
      border-radius: 0.3125rem;
    }
  }
  .how_it_works__image--text {
    grid-area: text;
    align-self: center;
    opacity: 0.5;
    color: ${(p) => p.theme.colors.gondola};
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
  }

  @media only screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: block;
    font-size: 0.875rem;
    width: 100%;
    padding: 1rem;
    .how_it_works__image--small,
    .how_it_works__image--medium {
      display: none;
    }
    .how_it_works__image--big {
      width: 100%;
      margin-left: auto;
      img {
        border-radius: 0.3125rem;
      }
    }
    .how_it_works__image--text {
      width: 100%;
      padding: 1rem;
      max-width: unset;
    }
  }
`

export const StyledContentDigitalBankingRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  @media only screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: block;
    font-size: 0.875rem;
    width: 100%;
    padding: 1rem;
  }
`

export const StyledHowItWorksTabContents = styled.div`
  display: grid;
  grid-column-gap: 5rem;
  grid-template-columns: 1fr 1fr;
  margin: 5rem auto;
  max-width: ${(p) => p.theme.breakpoints.desktop};

  @media screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
    padding: 0.9375rem;
    grid-template-columns: 1fr;
  }
`

export const StyledHowitWorksTabContentLeft = styled.div`
  display: flex;
  flex-direction: column;
  span {
    font-size: 1.5rem;
    color: ${(p) => p.theme.colors.gondola};
    line-height: 1.8125rem;
    font-weight: ${(p) => p.theme.fontWeights.medium};
    font-family: ${(p) => p.theme.fontFamilies.headline};
    margin-bottom: 1.625rem;
  }

  p,
  ul {
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-family: ${(p) => p.theme.fontFamilies.regular};
    color: ${(p) => p.theme.colors.shuttleflGray};
    margin-bottom: 1.625rem;
    list-style-position: outside;
    margin-left: 0.625rem;
  }

  svg {
    font-size: 3rem;
    height: 3rem;
    margin: auto;
    max-width: 3rem;
    margin-bottom: 2rem;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;

    svg {
      padding-bottom: 1.875rem;
      font-size: 4rem;
      height: 4rem;
    }

    p {
      padding: 1.875rem;
    }
  }
`

export const StyledGetStartedIntro = styled.div`
  max-width: ${(p) => p.theme.breakpoints.desktop};
  margin: 0 auto;
  & > img {
    width: 100%;
    background-color: rgba(128, 179, 255, 0.5);
    margin-bottom: 3.125rem;
  }
  p,
  span {
    color: ${(p) => p.theme.colors.disabled};
    font-size: 1.125rem;
    line-height: 1.75rem;
    margin: 1rem 0;
  }
  span {
    display: block;
    margin: 0.25rem 0;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 0 1.375rem;
  }
`
export const StyledCurrentInTMU = styled.div`
  max-width: 75rem;
  background-color: rgb(255, 255, 255);
  margin: 3.75rem auto;

  h3 {
    display: flex;
    justify-content: center;
    margin-bottom: 1.875rem;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 0 1.375rem;
  }
  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    text-align: center;
  }
`

export const StyledNameTitleText = styled.h4`
  font-size: 1.25rem;
  line-height: 1.5rem;
  padding-bottom: 1.25rem;
`

export const StyledHowtoGetStarted = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgb(250, 249, 247);
  padding: 5rem 9.375rem;

  h3 {
    display: flex;
    justify-content: center;
    color: ${(p) => p.theme.colors.primaryTextColor};
    font-size: 1.75rem;
    margin-bottom: 3.75rem;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 5rem 1.875rem;
  }
`

export const StyledHowtoGetStartedContent = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 4.875rem;
  grid-row-gap: 3.75rem;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 0.9375rem;
    grid-template-columns: 1fr;
  }
`

export const StyledHowtoGetStartedCard = styled.div`
  display: grid;
  grid-template-columns: 0.1fr 1fr;
  grid-column-gap: 2.375rem;

  i {
    font-size: 2.25rem;
  }

  h5 {
    font-size: 1.25rem;
    line-height: 1.5rem;
    padding-bottom: 1.25rem;
  }

  p {
    font-size: 1rem;
    line-height: 1.5rem;
    opacity: 50%;
  }
`

export const StyledJoinUsButton = styled(Button)`
  margin: 5rem auto;
  margin-bottom: 7.8125rem;
  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin: 0 auto;
  }
`

export const StyledRegisterDigitalBankingButton = styled(Button)`
  margin: 5rem auto;
  margin-top: -3.25rem;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin: 0 auto;
  }
`

export const StyledFormWrapper = styled.div`
  display: flex;
  align-items: center;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    max-width: ${(p) => (p.corporate ? "60rem" : "38.75rem")};
    margin: auto;
  }
`

export const StyledForm = styled.div`
  & > p {
    margin: 1rem 0 2rem;
  }

  margin: auto;
  width: 100%;
  padding: 0 1rem;

  form {
    grid-gap: 1.875rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin: 1.5rem auto;
    max-width: 36rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    max-width: 45rem;
  }
`

export const StyledTextOr = styled.span`
  display: flex;
  justify-content: center;
  font-size: 1rem;
  line-height: 1.5rem;
  opacity: 50%;
  margin-top: -4.0625rem;
  padding-bottom: 0.9375rem;
`
export const StyledStores = styled.div`
  display: flex;
  justify-content: center;
`
export const StyledStoreButton = styled(Button)`
  border-color: hsl(0, 0%, 80%);
  border-radius: 0.1875rem;
  border-style: solid;
  border-width: 0.0625rem;
  box-shadow: none;
`
export const StyledJoinPageWrapper = styled.div`
  margin-top: -5.1rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
  }
`
