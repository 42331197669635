import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import { useLogoImage } from "@tmu/hooks"
import SEO from "@tmu/components/seo"

const Redirect = ({ intl }) => {
  const logoImage = useLogoImage()
  const pathname = typeof window !== "undefined" ? window.location.pathname : ""
  return <SEO lang={intl.locale} pathname={pathname} image={logoImage} />
}

export default injectIntl(Redirect)
