import React, { useState, useEffect } from "react"
import { Dropdown, LazyImage, Checkbox } from "@tmu/components/common"
import {
  StyledSortSelector,
  StyledOption,
  StyledShowAll,
  StyledMultiValueContainer,
  StyledDropdownContainer,
} from "./index.styles"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import { components } from "react-select"
import theme from "@tmu/global/theme"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { useOffers } from "@tmu/hooks"
//import { MERCHANT_TYPE } from "../OnlineOfflineMerchantFilter"
import { OFFER_TYPE } from "@tmu/apollo/constants"
const StoreFilter = ({
  handleChange,
  defaultValue,
  isMobile,
  hideShowAll,
  filters = {},
}) => {
  const { formatMessage } = useIntl()
  const [selectedItem, setSelectedItem] = useState(null)
  const { callOffers, refetch, called, getMerchantDetail } = useOffers()
  const { isWide } = getAllScreenTypes()
  let callTimeout = null

  useEffect(() => {
    if (!defaultValue?.length) {
      return
    }

    const tempArr = []
    getSelectedData(defaultValue, 0, tempArr)
  }, [])

  useEffect(() => {
    if (!defaultValue?.length) {
      setSelectedItem([])
    }
  }, [defaultValue])

  const getSelectedData = (defaultValue, count, tempArr) => {
    getMerchantDetail({ variables: { id: defaultValue[count] } }).then(
      (result) => {
        count++
        const merchant = result?.data?.merchant
        if (merchant) {
          const temp = {
            value: merchant?.id,
            label: merchant?.name,
            merchant: merchant,
          }
          tempArr.push(temp)
        }
        if (count < defaultValue?.length) {
          getSelectedData(defaultValue, count, tempArr)
        } else {
          setSelectedItem(tempArr)
        }
      }
    )
  }

  const handleChangeAction = (values) => {
    if (Array.isArray(values)) {
      setSelectedItem(values)
    } else {
      setSelectedItem(values?.value)
    }
    if (handleChange) {
      handleChange(values)
    }
  }

  const promiseOptions = (inputValue) => {
    return new Promise((resolve) => {
      clearTimeout(callTimeout)
      callTimeout = setTimeout(() => {
        const vars = {
          search: inputValue,
          first: 20,
          //offerType_In: offerType,
          offerOrigin: ["stores"],
          badge: ["NO_BADGE"],
          offerType_In: filters?.offerType_In,
        }

        if (called) {
          refetch(vars).then((result) => {
            resolve(handleResult(result?.data?.allOffers))
          })
        } else {
          callOffers({ variables: vars }).then((result) => {
            resolve(handleResult(result?.data?.allOffers))
          })
        }
      }, 1000)
    })
  }

  const handleResult = (result) => {
    const filteredResult = result?.edges?.map(({ node }) => ({
      value: node?.store?.id,
      label: node?.store?.name,
      merchant: node?.store,
    }))

    return filteredResult
  }

  return (
    <StyledSortSelector>
      <>
        <StyledDropdownContainer>
          <Dropdown
            key={JSON.stringify(filters)}
            components={{
              Option,
              ValueContainer,
              MultiValueRemove,
              MultiValueLabel,
            }}
            isMulti={true}
            type="asyncSelect"
            name="store"
            variant="condensed"
            loadOptions={promiseOptions}
            placeholder={formatMessage({
              id: "storeFilter::placeholder",
              defaultMessage: "Choose Brands",
            })}
            value={selectedItem}
            defaultOptions={true}
            cacheOptions
            onChange={(values) => handleChangeAction(values)}
            closeMenuOnScroll={false}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            defaultSelectStyles={{
              control: {
                borderRadius: "0.25rem",
                borderColor: theme.colors.disabled,
                height: isWide ? "3.5rem" : "auto",
                fontSize: isWide ? "1rem" : "0.75rem",
              },
              option: {
                backgroundColor: theme?.colors?.transparent,
                color: theme?.colors?.pitchBlack,
                hoverColor: theme?.colors?.whiteSmoke,
              },
              menuList: {
                "::-webkit-scrollbar": {
                  width: "0.25rem",
                  height: "0",
                },
                "::-webkit-scrollbar-track": {
                  background: theme?.colors?.transparent,
                },
                "::-webkit-scrollbar-thumb": {
                  background: theme?.colors?.lightGrey,
                  width: "0.25rem",
                },
                "::-webkit-scrollbar-thumb:hover": {
                  background: theme?.colors?.lightGrey,
                },
              },
              multiValue: {
                backgroundColor: theme?.colors?.transparent,
                display: "none",
              },
              input: {},
              valueContainer: {
                flexWrap: "no-wrap",
              },
            }}
          />
        </StyledDropdownContainer>
        {!hideShowAll && !isMobile && (
          <StyledShowAll onClick={() => handleChangeAction([])}>
            {formatMessage({
              id: "storeFilter::showAll",
              defaultMessage: "Show All",
            })}
          </StyledShowAll>
        )}
      </>
    </StyledSortSelector>
  )
}

export default StoreFilter

const Option = ({ children, ...props }) => {
  const merchant = props?.data?.merchant
  const offerType = merchant?.defaultOffer?.offerType
  return (
    <components.Option {...props}>
      <StyledOption>
        <div className="logo">
          <LazyImage
            src={merchant?.logo}
            altName={`offers-image-${merchant?.name}`}
            width={50}
            height={50}
          />
        </div>
        <div className="merchant-name">
          <label for={props?.value}>{props?.label}</label>
        </div>
        <div className="type">
          <FormattedMessage
            id={"storeFilter::type::" + offerType}
            defaultMessage={offerType}
          />
        </div>
        <div className="check">
          <Checkbox
            disabled={true}
            id={props?.value}
            isChecked={props?.isSelected}
            {...props}
            label=""
          />
        </div>
      </StyledOption>
    </components.Option>
  )
}

const MultiValueRemove = ({ children, ...props }) => {
  return (
    <components.MultiValueRemove {...props}>
      <span {...props}></span>
    </components.MultiValueRemove>
  )
}

const MultiValueLabel = ({ children, ...props }) => {
  return (
    <components.MultiValueLabel {...props}>
      <span {...props}></span>
    </components.MultiValueLabel>
  )
}

const ValueContainer = ({ children, ...props }) => {
  const values = props?.selectProps?.value?.map((x) => x?.label)?.join(", ")
  return (
    <components.ValueContainer {...props}>
      <StyledMultiValueContainer>{values}</StyledMultiValueContainer>
      {children}
    </components.ValueContainer>
  )
}
