import styled from "@emotion/styled"

export const StyledCountStatsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  min-width: 10rem;
  text-align: center;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    min-width: 18rem;
  }
`
export const StyledCountStat = styled.span`
  color: ${(p) => p.theme.colors.softGreen};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-size: 1.5rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 2rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 3rem;
  }
`

export const StyledCountStatTitle = styled.span`
  color: ${(p) => p.theme.colors.pitchBlack};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-size: 0.75rem;
  font-family: ${(p) => p.theme.fontFamilies.headlineBig};
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
  }
`
