import styled from "@emotion/styled"

export const StyledPriceCard = styled.div`
  display: flex;
  flex-direction: row;
  border: 0.0625rem solid ${(p) => p.theme.colors.gallery};
  border-radius: 0.625rem;
  padding: 2.375rem 1.5rem;
`
export const StyledSeperator = styled.div`
  width: 0.0625rem;
  height: 100%;
  margin-right: 1rem;
  margin-left: 1rem;
  background-color: ${(p) => p.theme.colors.gallery};
`
export const StyledSlice = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const StyledTitleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 16.9375rem;
`

export const StyledSliceTitle = styled.span`
  font-size: 1rem;
  line-height: 1.1875rem;
  color: ${(p) => p.theme.colors.racingGreen};
  padding-right: 1.3125rem;
  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 0.625rem;
  }
`
export const StyledSliceSubTitle = styled.span`
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: ${(p) => p.theme.colors.cottonSeed};
  padding-right: 1.875rem;
  max-width: 6.875rem;
  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 0.5rem;
  }
`

export const StyledBottomLine = styled.div`
  height: 0.0625rem;
  width: 100%;
  background-color: ${(p) => p.theme.colors.gallery};
  margin-top: 0.8125rem;
  margin-bottom: 0.8125rem;
`

export const StyledSlicePrice = styled.span`
  font-size: 0.875rem;
  line-height: 1.0625rem;
  text-align: right;
  color: ${(p) => p.theme.colors.pacificBlue};

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 0.5rem;
  }
`
export const StyledSliceIcon = styled.img`
  width: 1.1731rem;
  height: 0.875rem;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    width: 0.625rem;
    height: 0.4375rem;
  }
`
export const StyledRightSlices = styled.div`
  display: flex;
  flex-direction: column;
`
export const StyledLeftSlices = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledComingSoonTitle = styled.h3`
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: ${(p) => p.theme.colors.red};
  padding-bottom: 0.625rem;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 0.625rem;
  }
`
export const StyledComingSoon = styled.div`
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: ${(p) => p.theme.colors.racingGreen};
  padding-bottom: 0.625rem;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 0.5rem;
  }
`
