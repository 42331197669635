import React, { useCallback, useMemo } from "react"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import platform from "platform"
import { Button, LazyImage } from "@tmu/components/common"
import { useFooterType } from "@tmu/hooks"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleArrowDown } from "@fortawesome/free-solid-svg-icons"
import { FOOTER_TYPE } from "@tmu/apollo/constants"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import {
  StyledMainContainer,
  StyledContentMainContainer,
  StyledContent,
  StyledBrowserSection,
} from "./index.styles"

const { CLOUDFLARE_IMAGE_URL } = process.env

const Extension = () => {
  useFooterType({ footerType: FOOTER_TYPE.HIDE })
  const { formatMessage } = useIntl()
  const { isTablet, isDesktop, isWide } = getAllScreenTypes()

  const imageSize = {
    width: isWide ? 739 : isDesktop ? 426 : isTablet ? 341 : 361,
    height: isWide ? 993 : isDesktop ? 572 : isTablet ? 458 : 485,
  }

  const imageWidth = {
    width: isWide ? 833.79 : isDesktop ? 458 : isTablet ? 344 : 720,
  }

  const logoSize = {
    width: isWide ? 48 : 32,
    height: isWide ? 48 : 32,
  }

  const el =
    typeof window !== "undefined" && document.getElementsByTagName("body")[0]
  if (el) el.style.paddingBottom = !isTablet ? "4rem" : 0

  const browserName = platform?.name.includes("Chrome Mobile")
    ? "Chrome"
    : platform?.name

  const browserInfo = useMemo(() => [
    {
      name: "Chrome",
      imageName: "chrome",
      extensionLink:
        "https://chrome.google.com/webstore/detail/trustmeup-dona-e-acquista/kglhdknefgkciajkhjbpehhhcoeghgkl",
    },
    {
      name: "Firefox",
      imageName: "firefox",
      extensionLink: "https://addons.mozilla.org/firefox/addon/trustmeup/",
    },
    {
      name: "Microsoft Edge",
      imageName: "edge",
      extensionLink:
        "https://microsoftedge.microsoft.com/addons/detail/trustmeup-donate-and-shop/ekhfgolpgmacigddpgkobekpbmafeipl",
    },
    {
      name: "Safari",
      imageName: "apple",
      extensionLink:
        "https://apps.apple.com/en/app/trustmeup-donate-and-shop/id6444806432",
    },
  ])

  const Content = useCallback(({ isTablet, imageSrc, content }) => {
    return isTablet ? (
      <>
        <LazyImage
          src={imageSrc}
          altName={`extension-info-banner`}
          {...imageWidth}
          height="auto"
          fit="cover"
        />
        {content}
      </>
    ) : (
      <>
        {content}
        <hr />
        <LazyImage
          src={imageSrc}
          altName={`extension-info-banner`}
          {...imageWidth}
          height="auto"
          fit="cover"
        />
        <hr />
      </>
    )
  }, [])

  const handleInstallExtension = (bn) => {
    window.location.href = browserInfo.find(
      ({ name }) => name === bn
    )?.extensionLink
  }

  const browsers = (
    <StyledBrowserSection>
      {browserInfo.some((browser) => browser?.name === browserName) ? (
        <FormattedMessage
          id="extension::info::extensionOther"
          defaultMessage="TMU Extension for other browsers"
        />
      ) : !isTablet ? (
        <span>
          <FormattedMessage
            id="extension::info::footer"
            defaultMessage="Install TMU Extension for your browser"
          />
        </span>
      ) : null}
      <div>
        {browserInfo.map((browser) => {
          return browserName !== browser?.name ? (
            <LazyImage
              onClick={() => handleInstallExtension(browser?.name)}
              src={
                CLOUDFLARE_IMAGE_URL +
                `/static/assets/images/browser/${browser?.imageName}.png`
              }
              altName={`browser-logo`}
              {...logoSize}
            />
          ) : null
        })}
      </div>
    </StyledBrowserSection>
  )

  return (
    <StyledMainContainer>
      <FormattedMessage
        id="extension::info::header"
        defaultMessage="TrustMeUp Browser Extension"
        tagName="h4"
      />
      <StyledContentMainContainer>
        <LazyImage
          src={CLOUDFLARE_IMAGE_URL + "/static/assets/images/extension.png"}
          altName={`extension-banner`}
          {...imageSize}
          fit="cover"
        />
        <StyledContent>
          <FormattedMessage
            id="extension::info::nonProfitTitle"
            defaultMessage="Shows you if a non-profit is on TrustmeUp"
            tagName="h4"
          />
          <Content
            isTablet={isTablet}
            imageSrc={
              CLOUDFLARE_IMAGE_URL +
              `/static/assets/images/extension/NonProfit_${
                isWide ? 1920 : isDesktop ? 1024 : isTablet ? 768 : 360
              }.png`
            }
            content={
              <FormattedMessage
                id="extension::info::nonProfitContent"
                defaultMessage="So all your donations will be rewarded in PACs"
                tagName="p"
              />
            }
          />
          {isTablet && <hr />}
          <FormattedMessage
            id="extension::info::merchantTitle"
            defaultMessage="Shows you if a merchant is on TrustmeUp"
            tagName="h4"
          />
          <Content
            isTablet={isTablet}
            imageSrc={
              CLOUDFLARE_IMAGE_URL +
              `/static/assets/images/extension/Merchant_${
                isWide ? 1920 : isDesktop ? 1024 : isTablet ? 768 : 360
              }.png`
            }
            content={
              <FormattedMessage
                id="extension::info::merchantContent"
                defaultMessage="So you could shop and save money by spending your PACs"
                tagName="p"
              />
            }
          />
        </StyledContent>
      </StyledContentMainContainer>
      {isTablet && (
        <span>
          <FormattedMessage
            id="extension::info::footer"
            defaultMessage="Install TMU Extension for your browser"
          />
        </span>
      )}
      <div className={!isTablet ? `sticky-bottom-bar` : ``}>
        {!isTablet ? browsers : null}
        {browserInfo.some((browser) => browser?.name === browserName) && (
          <Button
            data-testid="btn-install-extension"
            color="blue"
            onClick={() => handleInstallExtension(browserName)}
            type="button">
            {formatMessage(
              {
                id: "extension::info::button",
                defaultMessage: "{browser} Browser TMU Extension",
              },
              {
                browser: browserName,
              }
            )}
            <FontAwesomeIcon icon={faCircleArrowDown} />
          </Button>
        )}
        {isTablet ? browsers : null}
      </div>
    </StyledMainContainer>
  )
}

export default Extension
