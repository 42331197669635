import styled from "@emotion/styled"

export const StyledCreate = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  div {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.carrot};
    font-size: 1rem;
    line-height: 1.25rem;
    text-transform: uppercase;
    height: 1.25rem;
    svg {
      height: 1.25rem;
    }
  }
`
