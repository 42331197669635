import { get, set, remove } from "@tmu/utils/storage"
import { navigate } from "gatsby-plugin-intl"
import { jwtDecode } from "jwt-decode"

export const isBrowser = typeof window !== "undefined"
export const STORAGE_KEY_LOGGED_IN = "loggedIn"
export const STORAGE_KEY_TOKENS = "tokens"

export function removeCredentials() {
  remove("token")
  remove("refreshToken")
  remove("user")
}

export const isTokenExpired = (token) => {
  if (!token) return true
  const accessTokenDecrypted = jwtDecode(token)
  const current_time = new Date().getTime() / 1000
  const expired = current_time > accessTokenDecrypted.exp
  if (expired) {
    remove("token")
    remove("user")
  }
  return expired
}

export function getCredentials() {
  const token = get("token")
  const refreshToken = get("refreshToken")
  const expired = isTokenExpired(token)
  return { expired, token, refreshToken }
}

export function setCredentials({ token, refreshToken }) {
  if (token && refreshToken) {
    set("token", token)
    set("refreshToken", refreshToken)
  } else {
    removeCredentials()
  }
}

export function parseId(id) {
  try {
    if (id) {
      return window?.atob(id).split(":")?.at(1)
    }
  } catch (e) {}

  return ""
}

export function navigateIfNotAuthenticated(nextUrl) {
  navigate(`/signup/?next=${nextUrl}`)
}
