import styled from "@emotion/styled"
import { Link } from "gatsby-plugin-intl"

export const FormWrapper = styled.div`
  margin: 1.875rem 0;
  grid-area: form;
  padding: 0 !important;
  form {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-gap: 1.25rem;
    div {
      width: 100%;
    }
    .float-container {
      margin-bottom: 0.9rem;
    }
    .full-width {
      grid-column: 1 / 3;
    }
    .ccv-info {
      color: rgba(99, 106, 117, 1);
      font-size: 0.9375rem;
      line-height: 1.5rem;
    }
    .input-feedback {
      margin: 1rem 0 0.2rem;
    }
    .credit_card {
      & > div {
        box-sizing: border-box;
        border: 0.0625rem solid ${(p) => p.theme.colors.formFieldsBorderColor};
        border-radius: 0.25rem;
        background-image: none !important;
        background-color: rgba(255, 255, 255, 1);
        padding: 1rem 0.9375rem;
        position: relative;
        text-align: left;
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
        &.active {
          border: 0.0625rem solid ${(p) => p.theme.colors.blue};
        }
      }

      .input-feedback {
        color: red;
        font-size: 0.75rem;
        padding-left: 1.9375rem;
        margin-bottom: 0.2rem;
        min-height: 1rem;
      }
    }
    button {
      grid-column: 2;
      justify-self: right;
      outline: none;
      float: right;
    }
  }
  @media only screen and (max-width: 62.5rem) {
    .float-container {
      label {
        font-size: 0.8rem;
      }
      select {
        font-size: 0.65rem;
      }
    }
    .credit_card {
      .input-feedback {
        padding-left: 0.5rem;
      }
    }
  }
`

export const StyledLink = styled(Link)`
  &,
  &:hover,
  &:visited,
  &.active {
    color: ${(p) => p.theme.colors.blue} !important;
    font-weight: ${(p) => p.theme.fontWeights.bold};
  }
`

export const StyledWarning = styled.div`
  margin-top: -1.5rem;
  margin-left: 0.15rem;
  font-size: 0.85rem;
  color: ${(p) => p.theme.colors.yellow};
  span {
    margin-left: 1rem;
  }
`

export const StyledForm = styled.form`
  max-width: 52.5rem;
  padding: 0;
  grid-area: form;

  @media only screen and (max-width: 62.5rem) {
    .float-container {
      padding-left: 0.5rem;
      label {
        font-size: 0.8rem;
      }
      select {
        font-size: 0.65rem;
      }
    }
  }

  @media only screen and (max-width: 31.25rem) {
    form {
      display: block !important;
    }
  }
`
