import { Router } from "@reach/router"
import {
  ServiceCheckoutForm,
  ServiceDetail,
  ServicePurchaseSuccess,
} from "@tmu/components/services"
import { injectIntl } from "gatsby-plugin-intl"
import { BankTransferDetails } from "../components/common"

const Services = () => (
  <Router basepath="/:locale/services">
    <ServiceDetail path="/:slug" />
    <ServiceCheckoutForm path="/:slug/checkout" />
    <ServicePurchaseSuccess path="/:slug/checkout/success" />
    <BankTransferDetails path="/:slug/bank-transfer-details" />
  </Router>
)

export default injectIntl(Services)
