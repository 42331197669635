import React, { useRef } from "react"
import PropTypes from "prop-types"
import { FormattedMessage, useIntl, navigate } from "gatsby-plugin-intl"
import { StyledSlice, StyledSliceHeader } from "./index.styles"
import { Swiper, NewCard, Button } from "@tmu/components/common"
import { getValueForLocale } from "@tmu/utils/string"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { LIST_TYPES, OFFER_TYPE } from "@tmu/apollo/constants"

const SliceOffers = ({
  offers,
  title = "",
  headline,
  viewAllLink,
  className,
}) => {
  const { locale, defaultLocale } = useIntl()
  const ref = useRef()
  const handleViewAll = (e) => {
    navigate(viewAllLink)
  }
  const { isTablet } = getAllScreenTypes()
  return (
    <StyledSlice
      className={`slice-offers ${className}`}
      data-testid="slice-offers"
      ref={ref}>
      {headline || (
        <StyledSliceHeader>
          <h3>{title}</h3>
          <Button
            variant="link"
            text={
              <FormattedMessage id="ui::viewAll" defaultMessage="View all" />
            }
            size="big"
            onClick={handleViewAll}
          />
        </StyledSliceHeader>
      )}
      <Swiper withDots={!isTablet}>
        {offers?.map(({ node: offer }) => {
          return (
            <NewCard
              bigTablet
              variant={
                offer?.isDefaultOffer
                  ? "store"
                  : offer?.offerType === OFFER_TYPE.INTERNAL
                  ? "service"
                  : "offer"
              }
              id={offer?.id}
              key={offer?.id}
              imageSrc={offer?.image}
              title={getValueForLocale(offer, "name", locale, defaultLocale)}
              description={getValueForLocale(
                offer,
                "description",
                locale,
                defaultLocale
              )}
              maxPacDiscount={offer?.maxPacDiscount}
              size={!isTablet ? LIST_TYPES.LIST : LIST_TYPES.TILE}
              storeLogo={getValueForLocale(
                offer?.store,
                "logo",
                locale,
                defaultLocale
              )}
              storeName={offer?.store?.displayName || offer?.store?.name}
              isComingSoon={offer?.store?.badge === "COMING_SOON"}
              minPrice={offer?.minPrice}
              maxPrice={offer?.maxPrice}
              cardLink={
                offer?.isDefaultOffer
                  ? `/offers/store/${offer?.store?.slug}?offer=true`
                  : offer?.offerType === OFFER_TYPE.INTERNAL
                  ? `/services/${offer?.slug}`
                  : `/offers/detail/${offer?.slug}`
              }
            />
          )
        })}
      </Swiper>
    </StyledSlice>
  )
}

SliceOffers.propTypes = {
  campaigns: PropTypes.arrayOf(PropTypes.any).isRequired,
}

SliceOffers.defaultProps = {
  campaigns: [],
}

export default SliceOffers
