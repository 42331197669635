import React from "react"
import { Line } from "rc-progress"
import theme from "@tmu/global/theme"
import { FormattedMessage, navigate, useIntl } from "gatsby-plugin-intl"
import { useLocation } from "@reach/router"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import {
  Money,
  TruncatedText,
  StoreSupporterBox,
  LazyImage,
} from "@tmu/components/common"
import { CardStats } from "../CardStats"
import { Tooltip } from "react-tooltip"

import {
  StyledCardTitle,
  StyledCard,
  StyledCardInfo,
  StyledCardSubtitle,
  StyledImageBox,
  StyledPartnerLogo,
  StyledPartnerTitle,
  StyledPartnerTitleText,
  StyledPartnerDetails,
  StyledPACMultiplier,
  StyledDoublePacsTooltip,
} from "./index.styles"

const { CLOUDFLARE_IMAGE_URL } = process.env

const CampaignCard = ({
  imageSrc,
  title,
  subtitle,
  description,
  totalDonors,
  totalSupport,
  discount,
  inSupportOfTitle,
  inSupportOfLogo,
  fiscalCode,
  size,
  cardLink,
  goalAmount,
  fundedAmount,
  type,
  partnerName,
  partnerLogo,
  storeLogo,
  hasLogo,
  progress = 0,
  showPartnerInfo,
  pacMultiplier,
  id,
  isStoreSupporter = false,
  isCorporate: isCorporateCampaign,
  isCorporateStore,
  isOfflineCampaign,
  hideDetails,
  ...rest
}) => {
  const { isTablet, isDesktop, isWide } = getAllScreenTypes()
  const { formatMessage } = useIntl()
  const pacMultiplierNumber = pacMultiplier?.split(".")[0]
  const { pathname } = useLocation()

  const doublePacsTipText = formatMessage(
    {
      id: "doublePacs::listing::cardTooltip",
      defaultMessage:
        "You will get {pacMultiplierNumber} PACs for every EUR donated",
    },
    {
      pacMultiplierNumber: pacMultiplierNumber,
    }
  )

  const defaultSubtitle =
    type === "EV" ? (
      <FormattedMessage
        id="dashboard::campaignType::event"
        defaultMessage="Event"
      />
    ) : isCorporateCampaign ? (
      <FormattedMessage
        id="campaign::donationForm::corporateCampaign"
        defaultMessage="Corporate campaign"
      />
    ) : (
      <FormattedMessage
        id="dashboard::campaignType::charityCampaign"
        defaultMessage="Charity Campaign"
      />
    )
  const partnerInfo = (
    <StyledPartnerTitle size={size}>
      <StyledPartnerLogo
        data-testid="campaign-detail__foundation-logo"
        src={partnerLogo}
      />

      <StyledPartnerTitleText>
        <TruncatedText numLines={isTablet ? 2 : 3}>{partnerName}</TruncatedText>
      </StyledPartnerTitleText>
    </StyledPartnerTitle>
  )
  const partnerInfo2 = (
    <StyledPartnerDetails>
      <div>
        <StyledPartnerTitleText>
          <TruncatedText numLines={2}>{partnerName}</TruncatedText>
        </StyledPartnerTitleText>
        <StyledPartnerLogo
          data-testid="campaign-detail__foundation-logo"
          src={partnerLogo}
        />
      </div>
      <p className="caption">
        <FormattedMessage
          id="campaign::donationForm::inSupportOf"
          defaultMessage="in support of"
        />
      </p>
    </StyledPartnerDetails>
  )

  const PacMultiplier = () => {
    return Number(pacMultiplier) > 1 ? (
      <StyledPACMultiplier>
        <img
          className="pacs-pacs-image"
          src={CLOUDFLARE_IMAGE_URL + "/static/assets/images/white_pac.svg"}
          alt={`pacs-pacs-banner`}
          width={28}
          height={23}
        />
        {` X `}
        <Money value={pacMultiplier} currency={false} />
      </StyledPACMultiplier>
    ) : null
  }

  const logoSize = {
    width: isWide ? 144 : isDesktop ? 85 : isTablet ? 61 : 41,
    height: isWide ? 79 : isDesktop ? 52 : isTablet ? 37 : 24,
  }

  const imgSize = {
    width: hasLogo ? "auto" : 309,
    height: 174,
  }

  return (
    <StyledCard
      size={size}
      {...rest}
      onClick={() => navigate(cardLink)}
      data-testid={`campaignCard${id}`}
      style={
        hideDetails && {
          height: isTablet ? 147 : 75,
          minHeight: isWide ? 233 : isDesktop || isTablet ? 147 : 75,
        }
      }>
      <StyledImageBox
        hasLogo={hasLogo}
        size={size}
        isCorporate={isCorporateCampaign || isCorporateStore}>
        {isOfflineCampaign ? (
          <StoreSupporterBox
            storeLogo={storeLogo}
            partnerLogo={partnerLogo}
            {...logoSize}
          />
        ) : imageSrc ? (
          <>
            <LazyImage
              src={imageSrc}
              {...imgSize}
              altName={`card-image`}
              fit="contain"
            />
          </>
        ) : null}
        {pacMultiplier && (
          <StyledDoublePacsTooltip
            data-tooltip-id={`campaignCard${id}`}
            data-tooltip-content={doublePacsTipText}
            data-tooltip-delay-show={500}>
            <PacMultiplier />
            <Tooltip
              arrowColor="transparent"
              effect="solid"
              place="bottom"
              offset={{
                bottom: isWide ? 40 : 20,
                right: isWide ? 59 : 11,
              }}
              type="info"
              textColor="black"
              backgroundColor="white"
              id={`campaignCard${id}`}
              className={`campaignCard ${
                pathname.includes("campaigns") ||
                pathname.includes("user-space")
                  ? "campaignCardListing"
                  : ""
              }`}
            />
          </StyledDoublePacsTooltip>
        )}
        {/* {!isStoreSupporter && type === "GB" ? (
          <Line
            percent={Number(progress)}
            strokeWidth="3"
            strokeColor={theme.colors.softGreen}
            trailColor={theme.colors.white}
            trailWidth="3"
            data-testid="progress-bar"
          />
        ) : null} */}
      </StyledImageBox>
      {hideDetails ? null : (
        <>
          <StyledCardInfo size={size}>
            <StyledCardTitle>
              <TruncatedText numLines={2}>{title}</TruncatedText>
            </StyledCardTitle>
            {partnerName ? (
              <StyledCardSubtitle className="caption">
                {subtitle || defaultSubtitle}{" "}
                {isTablet ? (
                  <FormattedMessage
                    id="campaign::donationForm::inSupportOf"
                    defaultMessage="In support of"
                  />
                ) : null}
                {showPartnerInfo || isTablet ? partnerInfo : null}
              </StyledCardSubtitle>
            ) : isCorporateStore ? (
              <StyledCardSubtitle className="caption">
                <FormattedMessage
                  id="campaign::donationForm::corporateFundraiser"
                  defaultMessage="Corporate Fundraiser"
                />
              </StyledCardSubtitle>
            ) : null}
          </StyledCardInfo>
          <CardStats
            className="stats"
            totalDonors={totalDonors}
            totalSupport={totalSupport}
            size={size}
          />
          {size !== "default" && !isTablet ? partnerInfo2 : null}
        </>
      )}
    </StyledCard>
  )
}

export default CampaignCard
