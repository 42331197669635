import React, { useEffect, useState, useRef } from "react"
import { useQuery, useLazyQuery } from "@apollo/client"
import { FormattedMessage, useIntl, navigate } from "gatsby-plugin-intl"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUser } from "@fortawesome/pro-solid-svg-icons/faUser"
import { getCustomDifferance } from "@tmu/utils/date"

import { faGem } from "@fortawesome/pro-solid-svg-icons/faGem"
import {
  Spinner,
  LazyImage,
  Money,
  Button,
  CustomModal,
} from "@tmu/components/common"
import {
  StyledSideDonors,
  StyledDonorsWrapper,
  StyledDonorName,
  StyledDonorNameWrapper,
  StyledSortingButtons,
  StyledGem,
  StyledDonorListWrapperMargin,
} from "./index.styles"
import { DONORS_LIST_QUERY } from "@tmu/apollo/storefront/queries/user"

import { getAllScreenTypes } from "@tmu/utils/mediaQueries"

const DonorListModal = ({
  modalStatus,
  closeModal,
  campaignSlug,
  campaignPartnerSlug,
}) => {
  let prevDistance = null // Track previous donation date
  const [sorting, setSorting] = useState("-latestDonationDate") // -latestDonationDate, -total
  const { isTablet } = getAllScreenTypes()
  const { locale, defaultLocale, formatMessage } = useIntl()
  const loadRef = useRef()
  const [loadMoreAfter, setLoadMoreAfter] = useState(false)
  const [donorList, setDonorList] = useState([])
  const handleChangeSorting = (orderBy) => () => {
    setDonorList([])
    setSorting(orderBy)
  }
  const { loading, data, fetchMore } = useQuery(DONORS_LIST_QUERY, {
    variables: {
      first: 25,
      orderBy: sorting,
      campaign_Slug: campaignSlug,
      partner_Slug: campaignPartnerSlug,
      isPublic: true,
    },
  })

  useEffect(() => {
    setDonorList(data?.allDonors?.edges?.map((n) => n.node) || [])
  }, [data])

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        orderBy: sorting,
        campaign_Slug: campaignSlug,
        partner_Slug: campaignPartnerSlug,
        after: data?.allDonors?.pageInfo?.endCursor,
        isPublic: true,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult?.allDonors?.edges ?? []
        const pageInfo = fetchMoreResult?.allDonors?.pageInfo
        const prevEdges = prev.allDonors?.edges ?? []

        const result = {
          allDonors: {
            __typename: prev.allDonors?.__typename,
            edges: [...prevEdges, ...newEdges],
            pageInfo,
          },
        }
        setLoadMoreAfter(false) // Move setLoadMoreAfter here
        return result
      },
    })
  }

  //Initialize the intersection observer API
  useEffect(() => {
    if (!loadRef?.current) {
      return
    }
    let options = {
      threshold: 1,
    }
    const observer = new IntersectionObserver(handleObserver, options)
    if (loadRef.current) {
      observer.observe(loadRef.current)
    }
  }, [loadRef?.current, data?.allDonors?.pageInfo])

  // Handle intersection with load more
  const handleObserver = (entities) => {
    const target = entities[0]
    if (!loadMoreAfter && target.isIntersecting) {
      setLoadMoreAfter(true)
    }
  }

  // Handle loading more
  useEffect(() => {
    if (loadMoreAfter && data?.allDonors?.pageInfo?.hasNextPage) {
      handleLoadMore()
    }
  }, [loadMoreAfter, data?.allDonors?.pageInfo?.hasNextPage])

  return (
    <CustomModal
      isModalOpen={modalStatus}
      hideParentPageOnMobile
      style={{
        padding: 16,
        lineHeight: 2,
        maxHeight: isTablet ? "41rem" : "initial",
      }}
      headerStyle={{ paddingTop: "unset" }}
      header={
        <div>
          <FormattedMessage
            id="partner::detail::donors"
            defaultMessage="Donors"
          />
        </div>
      }
      isCloseIcon={true}
      cancelButtonAction={closeModal}
      isMobile={!isTablet}
      children={
        <>
          <StyledSortingButtons sorting={sorting}>
            <Button
              color={sorting === "-latestDonationDate" ? "blue" : "whiteSmoke"}
              data-testid="modal-button"
              onClick={handleChangeSorting("-latestDonationDate")}>
              <FormattedMessage
                id="donors::modal::recent"
                defaultMessage="Recent"
              />
            </Button>
            <Button
              color={
                sorting === "-total,latestDonationDate" ? "blue" : "whiteSmoke"
              }
              data-testid="modal-button"
              onClick={handleChangeSorting("-total,latestDonationDate")}>
              <FormattedMessage
                id="donors::modal::highest"
                defaultMessage="Highest First"
              />
            </Button>
          </StyledSortingButtons>
          {loading ? (
            <Spinner />
          ) : (
            <StyledDonorListWrapperMargin isMobile={false}>
              {donorList.length ? (
                donorList?.map((donor, index) => {
                  const currentDistance = getCustomDifferance(
                    new Date(),
                    new Date(donor?.latestDonationDate),
                    formatMessage
                  )
                  const displayCaption = prevDistance
                    ? currentDistance !== prevDistance
                    : true
                  prevDistance = currentDistance // Update prevCaption for next iteration
                  return sorting === "-latestDonationDate" ? (
                    <StyledDonorsWrapper key={donor.id}>
                      {displayCaption && (
                        <StyledSideDonors className="caption time-header">
                          {currentDistance}
                        </StyledSideDonors>
                      )}
                      {donor?.isAnonymous ? (
                        <div className="user">
                          <FontAwesomeIcon icon={faUser} />
                        </div>
                      ) : donor?.user?.profileImage ? (
                        <LazyImage
                          src={donor?.user?.profileImage}
                          altName={`donor-user-${donor?.user?.fullName}`}
                          width={97}
                          height={97}
                          fit="cover"
                        />
                      ) : (
                        <div className="user">{donor?.user?.fullName?.[0]}</div>
                      )}
                      <StyledDonorNameWrapper>
                        <StyledDonorName>
                          {donor?.isAnonymous ? (
                            <FormattedMessage
                              id="donors::modal::anonymous"
                              defaultMessage="Anonymous"
                              tagName="p"
                            />
                          ) : (
                            donor?.user?.fullName
                          )}

                          {index === 0 && sorting === "-total" && (
                            <StyledGem>
                              <FontAwesomeIcon className="gem" icon={faGem} />
                              <StyledSideDonors className="caption gem">
                                <FormattedMessage
                                  id="donors::modal::top"
                                  defaultMessage="Top"
                                />
                              </StyledSideDonors>
                            </StyledGem>
                          )}
                        </StyledDonorName>
                        <div className="total">
                          <Money value={donor?.total || 0} />
                        </div>
                      </StyledDonorNameWrapper>
                    </StyledDonorsWrapper>
                  ) : (
                    <StyledDonorsWrapper key={donor.id}>
                      {donor?.isAnonymous ? (
                        <div className="user">
                          <FontAwesomeIcon icon={faUser} />
                        </div>
                      ) : donor?.user?.profileImage ? (
                        <LazyImage
                          src={donor?.user?.profileImage}
                          altName={`donor-user-${donor?.user?.fullName}`}
                          width={97}
                          height={97}
                          fit="cover"
                        />
                      ) : (
                        <div className="user">{donor?.user?.fullName?.[0]}</div>
                      )}
                      <StyledDonorNameWrapper>
                        <StyledDonorName>
                          {donor?.isAnonymous ? (
                            <FormattedMessage
                              id="donors::modal::anonymous"
                              defaultMessage="Anonymous"
                              tagName="p"
                            />
                          ) : (
                            donor?.user?.fullName
                          )}
                          <StyledSideDonors className="caption">
                            {getCustomDifferance(
                              new Date(),
                              new Date(donor?.latestDonationDate),
                              formatMessage
                            )}
                          </StyledSideDonors>
                          {index === 0 && sorting === "-total" && (
                            <StyledGem>
                              <FontAwesomeIcon className="gem" icon={faGem} />
                              <StyledSideDonors className="caption gem">
                                <FormattedMessage
                                  id="donors::modal::top"
                                  defaultMessage="Top"
                                />
                              </StyledSideDonors>
                            </StyledGem>
                          )}
                        </StyledDonorName>
                        <div className="total">
                          <Money value={donor?.total || 0} />
                        </div>
                      </StyledDonorNameWrapper>
                    </StyledDonorsWrapper>
                  )
                })
              ) : (
                <FormattedMessage
                  id="partner::detail::noDonors"
                  defaultMessage="There are no donors currently"
                  tagName="p"
                />
              )}
              <div ref={loadRef}>
                {data?.allDonors?.pageInfo?.hasNextPage && (
                  <Spinner condensed />
                )}
              </div>
            </StyledDonorListWrapperMargin>
          )}
          {/* <StyledActionButtonMargin className="sticky-bottom-bar">
            <Button
              isMobile
              color="blue"
              data-testid="modal-button"
              onClick={onAction}>
              <FontAwesomeIcon icon={faHandHoldingHeart} />
              {supportButtonText}
            </Button>
          </StyledActionButtonMargin> */}
        </>
      }
    />
  )
}

export default DonorListModal
