import styled from "@emotion/styled"

export const StyledAboutUsWrapper = styled.div`
  margin: 3rem auto 0;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    max-width: ${(p) => p.theme.breakpoints.tablet};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    max-width: ${(p) => p.theme.breakpoints.desktop};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    max-width: ${(p) => p.theme.breakpoints.wide};
    margin: 5rem auto 0;
  }
`

export const StyledSectionDivider = styled.div`
  width: 100%;
  height: 0.0625rem;
  background: ${(p) => p.theme.colors.darkGrey};
  display: none;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: block;
  }
`

export const StyledAboutUsHero = styled.div`
  position: relative;
  height: 20.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .bg-image {
    position: absolute;
    top: -0.375rem;
    z-index: -1;

    img {
      filter: brightness(0.6);
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    height: 25.3125rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    height: 39rem;
  }
`

export const StyledAboutUsHeroTitle = styled.h6`
  color: ${(p) => p.theme.colors.white};
  font-family: ${(p) => p.theme.fontFamilies.headlineBig};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.liteBlack};
  letter-spacing: 0.02em;
  text-transform: uppercase;
  text-align: center;
  font-size: 0.625rem;
  line-height: 1rem;
  margin: 0 auto 0.5rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 0.75rem;
    line-height: 1.25rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
`
export const StyledAboutUsHeroText = styled.p`
  color: ${(p) => p.theme.colors.white};
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};

  text-align: center;
  font-size: 1.1rem;
  line-height: 1.5rem;
  margin: 0 1rem;
  &:first-of-type {
    margin-bottom: 2.5rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1.5rem;
    line-height: 2rem;
    width: 37.4375rem;
    &:first-of-type {
      margin-bottom: 3rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    width: 49.8125rem;
    font-size: 2rem;
    line-height: 2.625rem;
    &:first-of-type {
      margin-bottom: 4rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 3rem;
    line-height: 4.0625rem;
    width: 89.6875rem;
    &:first-of-type {
      margin-bottom: 5rem;
    }
  }
`

export const StyledCoreWhatWeAreDoing = styled.section`
  padding: 1rem;
  background: ${(p) => p.theme.colors.whiteSmoke};
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding-bottom: 2.5rem;
  }
`

export const StyledCoreWhatWeAreDoingTitle = styled.h2`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.pitchBlack};
  font-size: 1.2rem;
  line-height: 1.5rem;
  text-align: center;
  margin: 1.5rem auto;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
    line-height: 2.625rem;
    margin-top: 5rem;
  }
`

export const StyledCoreWhatWeAreDoingDivider = styled.div`
  width: 100%;
  height: 0.0625rem;
  background: ${(p) => p.theme.colors.darkGrey};
  margin: 1.5rem 0;
`

export const StyledCoreWhatWeAreDoingItems = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    flex-direction: row;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    flex-direction: row;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    flex-direction: row;
    margin-bottom: 5rem;
  }
`

export const StyledCoreWhatWeAreDoingItem = styled.div`
  margin-bottom: 1.875rem;
  font-size: 0.8rem;
  line-height: 1.1rem;
  display: flex;
  justify-content: start;
  margin-left: 0;
  margin-right: 0;

  .icon {
    color: ${(p) => p.theme.colors.blue};
    margin-right: 1rem;
    font-weight: ${(p) => p.theme.fontWeights.bold};
    font-size: 1.5rem;
    height: 1.5rem;
  }

  span {
    max-width: 100%;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-bottom: 0;
    font-size: 1rem;
    line-height: 1.6rem;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

    .icon {
      font-size: 2rem;
      height: 2rem;
    }

    span {
      max-width: 10.625rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin-bottom: 0;
    font-size: 1rem;
    line-height: 1.6rem;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

    .icon {
      font-size: 2rem;
      height: 2rem;
    }

    span {
      max-width: 15.625rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin-bottom: 0;
    font-size: 1.7rem;
    line-height: 2.2rem;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

    .icon {
      font-size: 2.5rem;
      height: 2.5rem;
    }

    span {
      max-width: 25rem;
    }
  }
`

export const StyledWhoAreWeHelping = styled.section`
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: grid;
    grid-template-columns: 22.5rem auto;
    grid-template-areas: "text image";
    column-gap: 1rem;
    margin: 0 0 0 1.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin: 0 0 0 2rem;
    grid-template-columns: 29.125rem auto;
    column-gap: 2rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin: 0 6rem;
    grid-template-columns: 53.25rem auto;
    column-gap: 1.5rem;
  }
`

export const StyledWhoAreWeHelpingTextContainer = styled.div`
  grid-area: none;
  padding: 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    grid-area: text;
    padding: 0;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
  }
`

export const StyledWhoAreWeHelpingTitle = styled.h1`
  font-weight: ${(p) => p.theme.fontWeights.bold};
  font-size: 1.1rem;
  line-height: 1.5rem;
  margin-top: 1.875rem;
  margin-bottom: 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 1.5rem;
    line-height: 2.2rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
    line-height: 1.7rem;
    margin-top: 3rem;
    margin-bottom: 1.5rem;
  }
`

export const StyledWhoAreWeHelpingDescription = styled.p`
  font-size: 0.8rem;
  line-height: 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1rem;
    line-height: 1.4rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 1rem;
    line-height: 1.4rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2.2rem;
  }
`

export const StyledWhoAreWeHelpingImageContainer = styled.div`
  img {
    width: 100%;
    height: auto;
  }
  grid-area: none;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    grid-area: image;
    img {
      width: auto;
      height: auto;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
  }
`

export const StyledWhoAreWeHelpingImages = styled.div`
  display: flex;
  justify-content: center;

  .image {
    height: 100%;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    justify-content: flex-end;

    .image {
      height: 100%;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    justify-content: flex-end;

    .image {
      max-width: 100%;
      height: 100%;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    justify-content: flex-end;

    .image {
      max-width: 100%;
      height: 100%;
    }
  }
`

export const StyledAboutSlice = styled.div`
  margin: 1.5rem 0;

  .media-box {
    align-items: start;
    margin-bottom: 2.4rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: grid;
    grid-template-columns: 22.5rem auto;
    column-gap: 1rem;
    margin: 2.5rem 1.5rem 1.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin: 2.5rem 2rem 1.5rem;
    grid-template-columns: 29.125rem auto;
    column-gap: 2rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin: 5rem 6rem 4rem;
    grid-template-columns: 53.25rem auto;
    column-gap: 1.5rem;
  }
`

export const StyledStats = styled.div`
  background: ${(p) => p.theme.colors.whiteSmoke};
  text-align: center;
  padding: 2.5rem 1.5rem 1.5rem;

  h4 {
    font-size: 1.125rem;
  }

  hr {
    margin: 1.5rem 0;
  }

  .counter {
    margin: 0;
    > div {
      padding: 0;
      margin-bottom: 1rem;
      min-width: 9.5rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    h4 {
      font-size: 1.5rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    span:last-of-type {
      font-size: 1.25rem;
    }
  }
`
export const StyledTextContainer = styled.div`
  margin: 1rem 1rem 2.5rem 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin: 0;
  }
`

export const StyledStoryTitle = styled.h2`
  font-weight: ${(p) => p.theme.fontWeights.bold};
  font-size: 1.1rem;
  line-height: 1.5rem;
  margin-bottom: 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
    line-height: 2.6rem;
  }
`

export const StyledStoryDescription = styled.p`
  font-size: 0.8rem;
  line-height: 1.1rem;
  margin-bottom: 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1rem;
    line-height: 1.4rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 1rem;
    line-height: 1.4rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2.2rem;
  }
`
