import styled from "@emotion/styled"

export const StyledTextEditor = styled.div`
  border: 0.0625rem solid rgba(13, 8, 9, 0.15);
  border-radius: 0 0 0.25rem 0.25rem;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  position: relative;
  max-width: 95vw;

  div.DraftEditor-editorContainer,
  div.public-DraftEditor-content {
    height: 100%;
    min-height: 12rem;
  }

  ul,
  ol {
    list-style-position: unset;
    li {
      div {
        display: inline-block;
        margin: 0;
      }
    }
  }

  .editor-class {
    padding: 0 1.25rem;
  }

  &:hover {
    border: 0.0625rem solid ${(p) => p.theme.colors.blue};
  }

  &.text-editor-error {
    border: 0.0625rem solid ${(p) => p.theme.colors.red};

    &:hover {
      border: 0.0625rem solid ${(p) => p.theme.colors.blue};
    }
  }

  .text-editor-counter {
    position: absolute;
    right: 1rem;
    bottom: 0.5rem;
    margin: 0;
    background: transparent;
    font-size: 0.7rem;
    font-weight: ${(p) => p.theme.fontWeights.bold};
    color: ${(p) => p.theme.colors.secondaryBrandTextColor};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    max-width: 28rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    max-width: 43rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    max-width: 47rem;
  }
`
