import { useLazyQuery } from "@apollo/client"
import { useApolloApiClients } from "@tmu/apollo/client"
import { PER_PAGE, VISIBLE_SALES_STATUSES } from "@tmu/apollo/constants"
import { MERCHANT_ORDER_LISTING_QUERY } from "../apollo/dashboard/queries/merchant"
import { CORPORATE_COMPANIES_LISTING_QUERY } from "../apollo/storefront/queries/corporate"

const useMerchants = (options) => {
  const { merchantClient, storefrontClient } = useApolloApiClients()
  const [
    callMerchantOrders,
    { data, loading, fetchMore, refetch: callMerchantOrdersRefetch },
  ] = useLazyQuery(MERCHANT_ORDER_LISTING_QUERY, {
    variables: {
      first: PER_PAGE,
      status_In: VISIBLE_SALES_STATUSES,
    },
    client: merchantClient,
    ...options,
  })

  const [callCorporateCompanies, corportateQueryData] = useLazyQuery(
    CORPORATE_COMPANIES_LISTING_QUERY,
    {
      fetchPolicy: "network-only",
      client: storefrontClient,
      ...options,
    }
  )

  return {
    data,
    sales: data?.allOrders?.edges,
    pageInfo: data?.allOrders?.pageInfo,
    loading,
    fetchMore,
    callMerchantOrders,
    callMerchantOrdersRefetch,
    corportateQueryData: {
      ...corportateQueryData,
      data: corportateQueryData?.data,
      pageInfo: corportateQueryData?.data?.allMerchants?.pageInfo,
      allMerchants: corportateQueryData?.data?.allMerchants?.edges,
    },
    callCorporateCompanies,
  }
}

export default useMerchants
