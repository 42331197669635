import styled from "@emotion/styled"

export const Wrapper = styled.aside`
  margin-bottom: 4.375rem;

  h3 {
    > svg {
      margin-right: 0.9375rem;
    }
    display: flex;
    align-items: center;
    margin-bottom: 1.875rem;
  }

  & > p {
    color: ${(p) => p.theme.colors.gondola};
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-size: 1.5rem;
    line-height: 1.875rem;
    margin-bottom: 1.25rem;
  }

  @media only screen and (max-width: 1024px) {
    .slice__products-ads {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media only screen and (max-width: 450px) {
    padding: 1.25rem;
    .slice__products-ads {
      display: block;
    }
  }

  @media only screen and (max-width: 800px) {
    .slice__tmu-partner-brands {
      .slice-header,
      .slice-subtext {
        text-align: left;
      }
    }
  }
`

export const AddDetails = styled.div`
  display: block;
  margin-bottom: 1.25rem;

  p {
    color: ${(p) => p.theme.colors.disabled};
    font-size: 1rem;
    line-height: 1.75rem;
    margin: 0;
    span {
      font-weight: ${(p) => p.theme.fontWeights.bold};
      color: ${(p) => p.theme.colors.primaryTextColor};
    }
  }
  div {
    color: ${(p) => p.theme.colors.gondola};
    font-size: 1rem;
    line-height: 1.25rem;
    text-align: right;
    svg {
      margin-left: 0.625rem;
    }
  }

  @media only screen and (max-width: 1024px) {
    display: block;
  }
`
