import { gql } from "@apollo/client"
import { capitalize } from "@tmu/utils/string"

export const HOMEPAGE_QUERY_STR = ({ locale }) => {
  const localeCapital = capitalize(locale)

  return `
  query HomePage($first: Int) {
    featuredCampaigns: allCampaigns(
      orderBy: "-fundedAmount,-created"
      first: $first
    ) {
      edges {
        node {
          id
          slug
          name${localeCapital}
          name
          campaignType
          donationCount
          fundedAmount
          pacMultiplier
          image {
            url
            cache
          }
          partner {
            id
            name
            displayName
            logo
            logo${localeCapital}
          }
        }
      }
    }
    allPartners: allCampaigns(
      first: $first
      orderBy: "-fundedAmount,-created"
      fundraiserType: ["charities"]
    ) {
      edges {
        node {
          id
          name
          name${localeCapital}
          donationCount
          fundedAmount
          pacMultiplier
          partner {
            id
            slug
            logo
            logo${localeCapital}
            taxId
          }
        }
      }
    }
    corporateCampaigns: allMerchants(first: $first, bundleCode: ["MERCHANT_BRAND"], orderBy: "-created", isDisplayed: true) {
        edges {
          node {
            id
            name
            logo
            slug
            category {
              id
              name
              name${localeCapital}
            }
            offers {
              edges {
                node {
                  id
                  name
                  offerType
                  categories {
                    edges {
                      node {
                        id
                        name
                        name${localeCapital}
                      }
                    }
                  }
                }
              }
            }
            fundedAmountForDisplay
            donationCountForDisplay
            campaignCountForDisplay
          }
        }
    }
  }
`
}

export const HOMEPAGE_ALL_PARTNERS_QUERY_STR = ({ locale }) => {
  const localeCapital = capitalize(locale)

  return `
  query AllPartners($first: Int) {
    allPartners: allCampaigns(
      first: $first
      orderBy: "-fundedAmount,-created"
      fundraiserType: ["charities"]
    ) {
      edges {
        node {
          id
          name
          name${localeCapital}
          donationCount
          fundedAmount
          pacMultiplier
          partner {
            id
            slug
            logo
            logo${localeCapital}
            taxId
          }
        }
      }
    }
  }
`
}

export const HOMEPAGE_ALL_PARTNERS_QUERY = ({ locale }) => gql`
  ${HOMEPAGE_ALL_PARTNERS_QUERY_STR({ locale })}
`
export const HOMEPAGE_QUERY = ({ locale }) => gql`
  ${HOMEPAGE_QUERY_STR({ locale })}
`

export const HOMEPAGE_AGGREGATE = gql`
  query Aggregate {
    aggregates {
      numPartners
      numStores
      numDonors
      numDonations
    }
  }
`
