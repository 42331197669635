import React from "react"
import { useQuery } from "@apollo/client"
import SEO from "@tmu/components/seo"
import { CORPORATEPAGE_QUERY } from "@tmu/apollo/storefront/queries/corporate"
import { Spinner } from "@tmu/components/common"
import Corporate from "@tmu/components/corporate"
import { injectIntl, useIntl } from "gatsby-plugin-intl"

const CorporatePage = () => {
  const { locale, formatMessage } = useIntl()

  const defaultQueryVariable = 8

  const { loading, data } = useQuery(CORPORATEPAGE_QUERY({ locale }), {
    variables: {
      first: defaultQueryVariable,
      isPublic: true,
    },
  })

  return (
    <>
      <SEO
        lang={locale}
        title={formatMessage({
          id: "join::corporate::pageTitle",
          defaultMessage: "Join as a Corporate",
        })}
        description={formatMessage({
          id: "seo::corporate::description",
          defaultMessage:
            "Get a new transparent fundraising channel at no cost for your supporters.",
        })}
      />
      {loading ? (
        <Spinner />
      ) : (
        <Corporate corporateCampaigns={data?.corporateCampaigns} />
      )}
    </>
  )
}
export default injectIntl(CorporatePage)
