import styled from "@emotion/styled"
import {
  StyledCampaignsWrapper,
  StyledFilterBar as StyledCampaignsFilterBar,
  StyledCampaignsBody,
  StyledHeading as StyledCampaignHeading,
  StyledHeadingTop as StyledCampaignHeadingTop,
  StyledListingFilters as StyledCampaignListingFilters,
  StyledContent as StyledCampaignContent,
  NoResults as CampaignsNoResults,
  ShowAllButton as CampaignsCategoriesShowAllButton,
} from "@tmu/components/campaigns/CampaignListing/index.styles"

export const StyledWrapper = styled((props) => (
  <StyledCampaignsWrapper {...props} />
))``

export const StyledHeading = styled((props) => (
  <StyledCampaignHeading {...props} />
))`
  padding: 0;
  padding-top: 1rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 1rem 0;
  }
  min-height: initial;
`
export const StyledHeadingTop = styled((props) => (
  <StyledCampaignHeadingTop {...props} />
))`
  h5 {
    color: ${(p) => p.theme.colors.carrot};
  }

  > div {
    justify-content: end;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    grid-template-columns: 10.4375rem auto;
    grid-gap: 1.5rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    grid-template-columns: 14.25rem auto;
    grid-gap: 1.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    grid-template-columns: 24.375rem auto;
    grid-gap: 3rem;
  }
`

export const StyledOffersBody = styled((props) => (
  <StyledCampaignsBody {...props} />
))`
  > div {
    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      grid-template-columns: 14.25rem auto;
      grid-gap: 1.5rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      grid-template-columns: 24.375rem auto;
      grid-gap: 3rem;
    }
  }

  padding-bottom: 5rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding-bottom: 4rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding-bottom: 6rem;
  }
`

export const StyledListingFilters = styled((props) => (
  <StyledCampaignListingFilters {...props} />
))`
  .country-filter {
    border: none;
    padding-left: 0;
  }

  input ~ span.checkmark:hover {
    border: 0.0625rem solid ${(p) => p.theme.colors.carrot};
  }

  input:checked ~ span.checkmark {
    background-color: ${(p) => p.theme.colors.carrot};
    border: 0.0625rem solid ${(p) => p.theme.colors.carrot};
  }

  input:checked ~ span.checkmark:after {
    background-color: ${(p) => p.theme.colors.carrot};
  }

  .custom {
    input ~ span.checkmark:hover {
      border: 0.0625rem solid ${(p) => p.theme.colors.gray};
    }

    input:checked ~ span.checkmark {
      background-color: ${(p) => p.theme.colors.gray};
      border: 0.0625rem solid ${(p) => p.theme.colors.gray};
    }

    input:checked ~ span.checkmark:after {
      background-color: ${(p) => p.theme.colors.gray};
    }
  }
`

export const StyledContent = styled((props) => (
  <StyledCampaignContent {...props} />
))``

export const NoResults = styled((props) => <CampaignsNoResults {...props} />)`
  margin: 0 1rem !important;
  margin-top: 0.375rem !important;
  width: 100%;
  justify-items: center;
  text-align: center;
`

export const StyledListing = styled.div`
  max-width: ${(p) => p.theme.breakpoints.wide};
  margin: 0 auto;
`

export const StyledActions = styled.div`
  text-align: center;
  display: block;
  align-self: center;
  justify-self: center;
  width: 100%;

  div {
    margin: 0;
  }
`
export const ShowAllButton = styled((props) => (
  <CampaignsCategoriesShowAllButton {...props} />
))``

export const StyledFilterBar = styled((props) => (
  <StyledCampaignsFilterBar {...props} />
))``

export const StyledHowToSpendPACMobile = styled.div`
  height: 2.5rem;
  background: ${(p) => p.theme.colors.carrot};
  color: ${(p) => p.theme.colors.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-size: 0.75rem;
  line-height: 1rem;

  > div {
    display: flex;
    align-items: center;

    svg {
      margin-right: 0.625rem;
      font-size: 1rem;
      height: 1rem;
    }
  }
`
export const StyledHowToSpendPACWrapper = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.625rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
  }
`

export const StyledExplanationText = styled.div`
  z-index: 999;
  position: absolute;
  width: 18.5rem;
  left: 0;
  top: 0.5rem;

  > div {
    background: ${(p) => p.theme.colors.white};
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    padding: 1.5rem;
    margin: 1rem 0 0 0;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      margin: 1.75rem 0 0 0;
    }
  }

  .media-box {
    border: 0.0625rem solid ${(p) => p.theme.colors.darkGrey};
    border-radius: 0.25rem;
    height: auto;
    margin-bottom: 1rem;

    img {
      width: 100%;
      border-radius: 0.25rem;
    }
  }

  .images {
    display: flex;
    justify-content: space-between;

    .spacing {
      width: 1rem;
    }
  }

  a {
    color: ${(p) => p.theme.colors.carrot};
  }

  a,
  p {
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    font-size: 0.75rem;
    line-height: 1.25rem;
    margin-top: 0.5rem;
  }

  .pacs-link {
    display: block;
    margin-top: 0.75rem;
    text-align: left;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    width: 31.25rem;

    a,
    p {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
  }
`
export const StyledMobileExplanationText = styled.div`
  .media-box {
    height: auto;
    margin-bottom: 1rem;
    border-radius: 0;
    img {
      border-radius: 0;
      width: 100%;
    }
  }

  a {
    color: ${(p) => p.theme.colors.carrot};
  }

  a,
  p {
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    font-size: 0.75rem;
    line-height: 1.25rem;
    margin-top: 0.5rem;
    text-align: left;
  }

  .pacs-link {
    display: block;
    margin-top: 0.75rem;
    text-align: left;
  }
`

export const StyledHowToSpendPACsDescription = styled.div`
  padding: 0 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 0;
  }

  .how-to-number {
    color: ${(p) => p.theme.colors.carrot};
  }
`

export const StyledComingSoonToggle = styled.div`
  padding-top: 0 !important;
  margin-top: -0.5rem !important;
  border: none !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.75rem;
  line-height: 1.25rem;
  background: none !important;
  &:hover {
    background: none !important;
  }
`
export const StyledOnlineFilterWrapper = styled.div`
  padding: 1rem;
`

export const StyledRowGrid = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0 1.5rem 0;

  align-items: center;

  .react-select__control {
    background-color: ${(p) => p.theme.colors.whiteSmoke};
  }
`
export const StyledHowToWrapper = styled.div``
export const StyledHowToIcon = styled.div`
  svg {
    color: ${(p) => p.theme.colors.carrot};
  }
  height: 1rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    height: 1.5rem;
  }
`
export const StyledHowToText = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.carrot};
  font-size: 1rem;
  line-height: 1.5rem;
  cursor: pointer;
  justify-self: end;
  align-self: center;
  text-align: left;
  margin: 0;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.75rem;
    line-height: 1.75rem;
  }
`

export const StyledSliderWrapper = styled.div`
  border: none !important;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    padding-top: 0 !important;
  }

  &.price-slider .left-label {
    padding-left: 0;
  }

  &.price-slider .right-label {
    margin-right: 2rem;
  }
`

export const StyledCheckboxTooltip = styled.div`
  .react-tooltip__show,
  .react-tooltip__hide,
  .react-tooltip__closing {
    max-width: 10rem !important;
    margin-top: -0.75rem;
    padding: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.15) 0 0.2375rem 1.5625rem,
      rgba(0, 0, 0, 0.05) 0 0.3125rem 0.625rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1rem;
      margin-top: -0.5rem;
      max-width: 15rem !important;
    }
  }

  .react-tooltip__show {
    opacity: 1;
  }
`
