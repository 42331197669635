import React from "react"
import { faFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF"
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram"
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons/faLinkedinIn"
import { faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter"
import { faYoutube } from "@fortawesome/free-brands-svg-icons/faYoutube"
import { faLink } from "@fortawesome/pro-regular-svg-icons/faLink"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  StyledSocialCard,
  StyledSocialCardContainer,
  StyledSocialCardIcon,
  StyledSocialCardText,
  TaxIdCard,
} from "./index.styles"

import { useIntl } from "gatsby-plugin-intl"

const SocialProfileCard = ({ storeSocialLinks, taxId }) => {
  const { formatMessage } = useIntl()

  const icons = {
    website: faLink,
    linkedin: faLinkedinIn,
    twitter: faTwitter,
    facebook: faFacebookF,
    instagram: faInstagram,
    youtube: faYoutube,
  }

  return (
    <StyledSocialCardContainer>
      {storeSocialLinks &&
        Object.keys(storeSocialLinks)
          .filter((t) => storeSocialLinks[t] && storeSocialLinks[t].length)
          .map((key, index) => (
            <StyledSocialCard key={index}>
              <StyledSocialCardIcon>
                <FontAwesomeIcon icon={icons[key]} />
              </StyledSocialCardIcon>
              <StyledSocialCardText>
                <a href={storeSocialLinks[key]} target="_blank">
                  {storeSocialLinks[key]}
                </a>
              </StyledSocialCardText>
            </StyledSocialCard>
          ))}
      {taxId && (
        <TaxIdCard>
          <div className="taxIdText">
            {formatMessage({
              id: "typehead::partners::fiscalCode",
              defaultMessage: "Fiscal Code",
            })}
            :
          </div>
          <div className="taxId">{taxId}</div>
        </TaxIdCard>
      )}
    </StyledSocialCardContainer>
  )
}

export default SocialProfileCard
