import styled from "@emotion/styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "@tmu/components/common"

export const StyledWSMainContainer = styled.div`
  display: block;

  &.welcome-donor {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: "left" "right";
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: grid;
    grid-template-columns: auto 22rem;
    grid-gap: 1rem;
    grid-template-areas: "left right";
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    grid-template-columns: auto 29.25rem;
    grid-gap: 2rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    grid-template-columns: auto 52.75rem;
    grid-gap: 2.5rem;
  }
`
export const StyledWSLeftContainer = styled.div`
  grid-area: left;
  padding: 0 1rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 0;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
  }
`

export const StyledWSTitle = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.pitchBlack};

  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;
  margin-bottom: 2rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1.5rem;
    line-height: 2rem;
    text-align: unset;
    width: unset;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 2rem;
    line-height: 2.625rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 3rem;
    line-height: 4.0625rem;
  }
`

export const StyledWSButton = styled(Button)`
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    height: 2.5rem;

    margin-top: 2.5rem;

    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.04em;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    height: 2.5rem;

    margin-top: 3.125rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    height: 4rem;

    margin-top: 4rem;

    font-size: 1rem;
    line-height: 1.375rem;
  }
`

export const StyledWSButtonAwesomeIcon = styled(FontAwesomeIcon)`
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1.5rem;
    height: 1.75rem;
    margin-top: 0.2rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    height: 2.5rem;
  }
`

export const StyledWSButtonIcon = styled.i`
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1.5rem;
    height: 1.5rem;
  }
  
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }
  
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin-top: 0.8rem;
    height: 2.5rem;
  }
`

export const StyledWSRightContainer = styled.div`
  grid-area: right;
  .media-box {
    height: auto;
    width: 100vw;

    img {
      width: 100%;
      height: auto;
    }
  }

  display: flex;
  flex-direction: column-reverse;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    flex-direction: column;

    .media-box {
      width: 100%;
      margin-left: 0;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
  }
`
