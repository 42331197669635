import { useEffect } from "react"
import { useLazyQuery, gql } from "@apollo/client"
import { useApolloApiClients } from "@tmu/apollo/client"

const useOrderStatuses = ({ ...rest }) => {
  const { storefrontClient } = useApolloApiClients()

  const [callTypeConstants, { data, loading, called }] = useLazyQuery(
    gql`
      query TypeConstants {
        orderStatuses: __type(name: "OrdersOrderStatusChoices") {
          enumValues {
            name
            description
          }
        }
      }
    `,
    {
      client: storefrontClient,
      variables: { isPublic: true },
      ...rest,
    }
  )

  useEffect(() => {
    if (rest === null || rest?.callImmediately !== false) {
      if (!called) {
        callTypeConstants()
      }
    }
  }, [])

  const orderStatuses = data?.orderStatuses
    ? [emptyOption].concat(
        data.orderStatuses.enumValues.map(({ name, description }) => ({
          id: name,
          description,
        }))
      )
    : []

  return {
    orderStatuses,
    callTypeConstants,
  }
}

export default useOrderStatuses
