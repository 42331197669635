import React from "react"
import PropTypes from "prop-types"
import CorporateCompaniesTableHeader from "./CorporateCompaniesTableHeader"
import CorporateCompaniesTableRow from "./CorporateCompaniesTableRow"
import { StyledCorporateCompaniesTable } from "./index.styles"

const CorporateCompaniesTable = ({ corporateCampaigns }) => {
  return (
    <div>
      <CorporateCompaniesTableHeader />
      <StyledCorporateCompaniesTable>
        {corporateCampaigns?.map(({ node }, index) => (
          <CorporateCompaniesTableRow
            key={node?.id}
            index={index + 1}
            {...node}
          />
        ))}
      </StyledCorporateCompaniesTable>
    </div>
  )
}

CorporateCompaniesTable.propTypes = {
  corporateCampaigns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      logo: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,

      donationCountForDisplay: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]).isRequired,

      totalFundedAmount: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]).isRequired,
    })
  ),
}

export default CorporateCompaniesTable
