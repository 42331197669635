import React, { useEffect } from "react"
import { FormattedMessage, navigate } from "gatsby-plugin-intl"
import { LazyImage } from "@tmu/components/common"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import {
  StyledPacsPartnersContainer,
  StyledPacsPartnersTitleContainer,
  StyledPacsPartnersTitle,
  StyledPacsPartnersViewPacOffersButton,
  StyledPacsPartnersSomeOurPartnersImages,
} from "./index.styles"
import { useOffers } from "@tmu/hooks"

const PacsPartners = () => {
  const { isTablet, isDesktop, isWide } = getAllScreenTypes()

  const imageWidth = isWide ? 182 : isDesktop ? 100 : isTablet ? 79 : 94

  const perPage = isWide || isDesktop || isTablet ? 16 : 18

  const { callOffers, data } = useOffers()

  const variables = {
    first: perPage,
    orderBy: "-created",
  }

  useEffect(() => {
    callOffers({ variables })
  }, [])

  return (
    <StyledPacsPartnersContainer>
      <StyledPacsPartnersTitleContainer>
        <StyledPacsPartnersTitle>
          <FormattedMessage
            id="pacs::partners::title"
            defaultMessage="Some of our partners:"
          />
        </StyledPacsPartnersTitle>
        <StyledPacsPartnersViewPacOffersButton
          variant="link"
          text={
            <FormattedMessage
              id="pacs::partners::viewPacOffers"
              defaultMessage="View PAC Offers"
            />
          }
          onClick={() => {
            navigate("/offers?store=true")
          }}
        />
      </StyledPacsPartnersTitleContainer>
      <StyledPacsPartnersSomeOurPartnersImages>
        {data?.edges?.map((merchant) => {
          return (
            <LazyImage
              src={merchant?.node?.store?.logo}
              altName={`pacs-partners-image`}
              width={imageWidth}
              height="auto"
              fit="cover"
            />
          )
        })}
      </StyledPacsPartnersSomeOurPartnersImages>
    </StyledPacsPartnersContainer>
  )
}

export default PacsPartners
