import React, { useMutation } from "@apollo/client"
import { useApolloApiClients } from "@tmu/apollo/client"
import { PREFERENCES_MUTATION } from "@tmu/apollo/storefront/mutations/user"
import { useAuth } from "./useAuth"

const useUserPreferences = (options) => {
  const { storefrontClient } = useApolloApiClients()
  const { user } = useAuth()

  const [callSavePreferences] = useMutation(PREFERENCES_MUTATION, {
    client: storefrontClient,
  })

  const savePreferences = (data) => {
    const pref = user?.userPreference
    return callSavePreferences({
      variables: {
        input: {
          campaign: pref?.campaign?.id,
          campaignCategories: pref?.campaignCategories?.edges?.map(
            (item) => item?.node?.id
          ),
          productCategories: pref?.productCategories?.edges?.map(
            (item) => item?.node?.id
          ),
          ...data?.variables?.input,
        },
      },
    })
  }

  return {
    savePreferences,
  }
}

export default useUserPreferences
