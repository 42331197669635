import React from "react"
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons/faFacebookSquare"
import { faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter"
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons/faLinkedinIn"
import { faClone } from "@fortawesome/free-solid-svg-icons/faClone"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share"
import { Tooltip } from "react-tooltip"
import { useIntl } from "gatsby-plugin-intl"
import { QRModal } from "@tmu/components/common"
import { useToast } from "@tmu/hooks"

import { StyledShareButtons, StyledCopyTextButton } from "./index.styles"

export const ShareButtons = ({
  twitterHandle,
  url,
  title,
  tags,
  description,
  isNewCampaign = false,
  slug,
  name,
  isMerchantCampaign,
  discount,
  storeLogo,
  partnerLogo,
}) => {
  const { formatMessage } = useIntl()
  const { success } = useToast()
  const shareUrl = url?.charAt(url.length - 1) === "/" ? url : `${url}/`

  const copyPageUrl = formatMessage({
    id: "partner::detail::copyPageUrl",
    defaultMessage: "Copy link",
  })

  const copiedMessage = formatMessage({
    id: "ui::copiedToClipboard",
    defaultMessage: "Copied to clipboard!",
  })

  const HandleCopyClick = () => {
    var input = document.createElement("input")
    input.value = shareUrl || url
    document.body.appendChild(input)
    input.select()

    document.execCommand("copy")
    document.body.removeChild(input)

    success(copiedMessage)
  }

  return (
    <StyledShareButtons>
      <QRModal
        slug={slug}
        name={name}
        isMerchantCampaign={isMerchantCampaign}
        discount={discount}
        storeLogo={storeLogo}
        partnerLogo={partnerLogo}
      />
      <FacebookShareButton
        url={shareUrl || url}
        className={isNewCampaign ? "socialMedia-shareButton" : ""}>
        <FontAwesomeIcon icon={faFacebookSquare} />
      </FacebookShareButton>

      <TwitterShareButton
        url={shareUrl || url}
        title={title}
        via={twitterHandle}
        hashtags={tags}
        className={isNewCampaign ? "socialMedia-shareButton" : ""}>
        <FontAwesomeIcon icon={faTwitter} />
      </TwitterShareButton>
      <LinkedinShareButton
        title={title}
        summary={description}
        url={shareUrl || url}
        windowWidth={1000}
        windowHeight={1000}
        className={isNewCampaign ? "socialMedia-shareButton" : ""}>
        <FontAwesomeIcon icon={faLinkedinIn} />
      </LinkedinShareButton>
      <StyledCopyTextButton
        onClick={HandleCopyClick}
        data-tooltip-content={copyPageUrl}
        data-tooltip-id="copyButton"
        className={isNewCampaign ? "socialMedia-shareButton" : ""}>
        <FontAwesomeIcon icon={faClone} />
      </StyledCopyTextButton>
      <Tooltip
        arrowColor="transparent"
        effect="solid"
        place="bottom"
        type="info"
        textColor="black"
        backgroundColor="white"
        id="copyButton"
        className="copyButton"
        delayShow={500}
      />
    </StyledShareButtons>
  )
}

export default ShareButtons
