import React from "react"
import { FormattedMessage, navigate } from "gatsby-plugin-intl"
import { CampaignTypeCard } from "@tmu/components/dashboard/dashboardCommon"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons/faArrowLeft"
import { useDefaultMerchant } from "@tmu/hooks"
import queryString from "query-string"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import {
  StyledCampaignTypesWrapper,
  StyledCampaignTypesPageTitle,
  StyledCampaignCardTypes,
  StyledBackButton,
} from "./index.styles"

const { CLOUDFLARE_IMAGE_URL } = process.env

const CampaignTypes = () => {
  const params = queryString.parse(location?.search, { arrayFormat: "comma" })
  const isPartner = params?.partner === "true"
  const isSupporter = params?.supporter === "true"
  const isCorporate = params?.corporate === "true"
  const { isMerchantOffline } = useDefaultMerchant()

  const handleCardClick = (type) => () => {
    navigate(
      `/dashboard/campaign/new?type=${type}${
        isPartner
          ? ""
          : isSupporter
          ? "&supporter=true"
          : isCorporate
          ? "&corporate=true"
          : isMerchantOffline
          ? "&offline=true"
          : ""
      }${params?.charity ? "&charity=" + params?.charity : ""}${
        params?.defaultSupporterCause
          ? "&defaultSupporterCause=" + params?.defaultSupporterCause
          : ""
      }`
    )
  }

  const { isTablet, isDesktop, isWide } = getAllScreenTypes()

  const imageSize = {
    height: isWide ? 136.58 : isDesktop ? 67 : isTablet ? 67 : 67,
  }

  return (
    <StyledCampaignTypesWrapper>
      <StyledCampaignTypesPageTitle>
        <StyledBackButton
          to={
            isSupporter
              ? "/dashboard/user/campaigns"
              : isCorporate
              ? "/dashboard/merchants/campaigns"
              : "/dashboard/charities/campaigns"
          }>
          <FontAwesomeIcon icon={faArrowLeft} />
        </StyledBackButton>
        <FormattedMessage
          id="dashboard::campaignTypes::title"
          defaultMessage="Choose a type for your campaign"
          tagName="h1"
        />
      </StyledCampaignTypesPageTitle>
      <StyledCampaignCardTypes>
        <CampaignTypeCard
          imageSrc={CLOUDFLARE_IMAGE_URL + "/static/assets/images/jar.png"}
          imageSize={imageSize}
          type={
            <FormattedMessage
              id="dashboard::campaign::collection"
              defaultMessage="Campaign"
            />
          }
          content={
            <FormattedMessage
              id={`dashboard::campaign::${
                isSupporter
                  ? "supporterCollectionText"
                  : isCorporate
                  ? "corporateCollectionText"
                  : "partnerCollectionText"
              }`}
              defaultMessage={
                isSupporter
                  ? "Ask your friends to show their love or appreciation by donating to a charity you care about. Select an occasion: birthday, wedding, your free contribution, or even without a specific reason."
                  : isCorporate
                  ? "Create a campaign in support of a charity of your choice  that aligns with your brand's values and mission. Encourage donations or actions to amplify your impact and contribute to positive change."
                  : "Encourage conventional donations or actions to enhance the effectiveness of your campaigns."
              }
            />
          }
          buttonText={
            <FormattedMessage
              id="card::charity::choose"
              defaultMessage="Choose"
            />
          }
          buttonAction={handleCardClick("collection")}
          disabled={params.collection === "false"}
        />
        <CampaignTypeCard
          imageSrc={CLOUDFLARE_IMAGE_URL + "/static/assets/images/baloons.png"}
          imageSize={imageSize}
          type={
            <FormattedMessage
              id="dashboard::campaign::event"
              defaultMessage="Event"
            />
          }
          content={
            <FormattedMessage
              id={`dashboard::campaign::${
                isSupporter
                  ? "supporterEventText"
                  : isCorporate
                  ? "corporateEventText"
                  : "partnerEventText"
              }`}
              defaultMessage={
                isSupporter
                  ? "Use your skills, competencies, and passions for solidarity purposes: plan an event, a workshop, or an online webinar with entry tickets. Ask participants to support the cause with a donation."
                  : isCorporate
                  ? "Utilize your resources to host a workshop, webinar, or any gathering and raise awareness and support for the NGOs. Invite participants to contribute, leveraging your brand's platform to make a difference."
                  : "Utilize your resources to host an event that fosters solidarity and drives action. Whether it's a workshop, webinar, or any gathering, raise awareness and support for your NGO."
              }
            />
          }
          buttonText={
            <FormattedMessage
              id="card::charity::choose"
              defaultMessage="Choose"
            />
          }
          buttonAction={handleCardClick("event")}
          disabled={params.event === "false"}
        />
      </StyledCampaignCardTypes>
    </StyledCampaignTypesWrapper>
  )
}

export default CampaignTypes
