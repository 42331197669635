import { gql } from "@apollo/client"

export const GENERATE_MERCHANT_CAMPAIGN_REPORT_MUTATION = gql`
  mutation ($input: GenerateMerchantCampaignReportMutationInput!) {
    generateMerchantCampaignReport(input: $input) {
      merchantCampaignReport {
        id
        report
        requestedUser {
          fullName
        }
      }
      errors {
        field
        messages
      }
    }
  }
`
export const MERCHANT_SELF_REGISTER_MUTATION = gql`
  mutation CreateMerchantStore($input: CreateMerchantStoreMutationInput!) {
    createMerchantStore(input: $input) {
      store {
        id
        phoneNumber
        logo
        addressLine1
        hideAddress
        addressLine2
        website
        linkedin
        twitter
        facebook
        instagram
        youtube
        shopLink
        defaultCurrency
        displayName
        description
        shortDescription
        maxPacDiscount
        descriptionEn
        shortDescriptionEn
        descriptionEs
        shortDescriptionEs
        descriptionIt
        hideAddress
        shortDescriptionIt
        country
        city
        postCode
        email
        communicationLanguage
        maxPacDiscount
        terms
        termsEn
        termsIt
        defaultAvailableCountries
        defaultAvailableCities {
          id
          name
        }
        favoriteCharity {
          id
        }
        defaultOffer {
          id
          name
          image
        }
        allowUsersToSelectADifferentCampaign
      }
      errors {
        field
        messages
      }
    }
  }
`

export const CREATE_STRIPE_ONBOARDING_ACCOUNT_MUTATION = gql`
  mutation CreateStripeOnboardingAccount($id: String!, $type: String!) {
    createStripeOnboardingAccount(id: $id, type: $type) {
      accountSecret
      accountLinkUrl
      errors {
        field
        messages
      }
    }
  }
`
