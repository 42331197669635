/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useEffect, useState } from "react"
import queryString from "query-string"
import { Form, Field, useFormikContext } from "formik"
import { useCountries, useAuth, useSupportedLanguages } from "@tmu/hooks"
import { FormattedMessage, useIntl, Link } from "gatsby-plugin-intl"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import theme from "@tmu/global/theme"
import {
  TextInput,
  Button,
  BaseError,
  CheckboxContainer,
  TermsAndConditions,
  Dropdown,
  SocialLoginComponent,
  RadioSelect,
} from "@tmu/components/common"
import {
  REQUIRED_FIELD_SYMBOL,
  SUPPORTED_LANGUAGES,
  userType,
} from "@tmu/apollo/constants"
// import { useGoogleReCaptcha } from "react-google-recaptcha-v3"

import {
  StyledSignUpSubText,
  SignUpSubmitWrapper,
  StyledFormInputSection,
} from "./index.styles"
import IndividualCompanyForm from "../../forms/DonationForm/IndividualCompanyForm"
import {
  StyledFlexFormRow,
  StyledFormRow,
  StyledSectionBox,
  StyledSectionTitle,
} from "../../forms/ServicePurchaseForm/index.styles"
import { Spacer } from "@tmu/src/global/page-addons/detail-page.styles"

function SubForm({
  user,
  registerError,
  /* token,
  setToken, */
  isRecurringSignup,
  isRegister,
  setIsCompany,
  isCompany,
}) {
  const { isTablet } = getAllScreenTypes()
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const { isLoading } = useAuth()
  const { locale, formatMessage } = useIntl()
  const { getMatchingLanguageForLanguageValue } = useSupportedLanguages()
  // const { executeRecaptcha } = useGoogleReCaptcha()

  // if (!token && executeRecaptcha) {
  //   executeRecaptcha("register_form").then((data) => setToken(data))
  // }

  const {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    isSubmitting,
    setValues,
    setTouched,
    setErrors,
  } = useFormikContext()

  useEffect(() => {
    if (user?.id) {
      setValues({ ...user, communicationLanguage: locale.toLocaleUpperCase() })
    } else {
      setFieldValue("communicationLanguage", locale.toLocaleUpperCase(), true)
      setTouched({ ...touched, communicationLanguage: true })
    }
  }, [user?.id])

  const params = queryString.parse(location.search, { arrayFormat: "comma" })
  const next = params?.next?.replace(/\/(en|it|es)/g, "") ?? "/"
  const nextUrl = queryString.stringifyUrl({
    url: next,
    query: params,
  })

  const languageOptions = SUPPORTED_LANGUAGES.filter(
    (item) => item.value != "DE"
  )

  return (
    <Form className="user-form">
      <>
        <StyledSignUpSubText>
          <p>
            <FormattedMessage
              id="signUp::alreadyHaveAccount?"
              defaultMessage="Already have an account?"
            />{" "}
            <Link
              to={`/signin?next=${nextUrl}`}
              data-testid="signin-link"
              aria-label="Sign In">
              <FormattedMessage id="signUp::signin" defaultMessage="Sign In" />
            </Link>
          </p>
        </StyledSignUpSubText>
        <SocialLoginComponent isRegister={isRegister} />
        <hr />
      </>

      <StyledFormInputSection>
        <StyledSectionBox className="mb32 pb0">
          <StyledFormRow className="title mt32">
            <FormattedMessage
              id="signUp::companyOrIndividual"
              defaultMessage="Are you signing up as an individual or as an organization?"
            />
          </StyledFormRow>
          <StyledFlexFormRow className="my32">
            <RadioSelect
              items={userType(formatMessage)}
              defaultValue={isCompany ? "company" : "individual"}
              onChange={(e) => {
                setIsCompany(e.target.value === "company")
                setErrors({})
              }}
            />
          </StyledFlexFormRow>
          <TextInput
            newDesign
            data-testid="input-email"
            id="email"
            name="email"
            type="email"
            onChange={(e) => {
              const lowerCaseEmail = e.target.value.toLowerCase()
              handleChange({
                target: {
                  name: e.target.name,
                  value: lowerCaseEmail,
                },
              })
            }}
            onBlur={handleBlur}
            value={values?.email}
            label={`${formatMessage({
              id: "signUp::email",
              defaultMessage: "Email",
            })}${REQUIRED_FIELD_SYMBOL}`}
            placeholder={formatMessage({
              id: "signUp::emailPlaceholder",
              defaultMessage: "Type your email",
            })}
            error={errors.email}
            touched={touched.email}
          />
          <Spacer top={1.5} />
          <TextInput
            newDesign
            data-testid="input-confirmEmail"
            id="confirmEmail"
            name="confirmEmail"
            type="email"
            onChange={(e) => {
              const lowerCaseEmail = e.target.value.toLowerCase()
              handleChange({
                target: {
                  name: e.target.name,
                  value: lowerCaseEmail,
                },
              })
            }}
            onBlur={handleBlur}
            value={values?.confirmEmail}
            label={`${formatMessage({
              id: "signUp::confirmEmail",
              defaultMessage: "Confirm Email",
            })}${REQUIRED_FIELD_SYMBOL}`}
            placeholder={formatMessage({
              id: "signUp::confirmEmail",
              defaultMessage: "Confirm Email",
            })}
            onPaste={(e) => {
              e.preventDefault()
              return false
            }}
            error={errors.confirmEmail}
            touched={touched.confirmEmail}
          />
          <Spacer top={1.5} />
          <TextInput
            newDesign
            data-testid="input-password"
            id="password"
            name="password"
            type={showPassword ? "text" : "password"}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values?.password}
            label={`${formatMessage({
              id: "signUp::password",
              defaultMessage: "Password",
            })}${REQUIRED_FIELD_SYMBOL}`}
            placeholder={formatMessage({
              id: "signUp::password",
              defaultMessage: "Password",
            })}
            error={errors.password}
            touched={touched.password}
            inputIcon={
              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                className="input-icon"
                color={theme.colors.blue}
                onClick={() => setShowPassword(!showPassword)}
              />
            }
            iconRight={0.9375}
          />
          <Spacer top={1.5} />
          <TextInput
            newDesign
            data-testid="input-confirmPassword"
            id="confirmPassword"
            name="confirmPassword"
            type={showConfirmPassword ? "text" : "password"}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values?.confirmPassword}
            label={`${formatMessage({
              id: "signUp::confirmPassword",
              defaultMessage: "Confirm Password",
            })}${REQUIRED_FIELD_SYMBOL}`}
            placeholder={formatMessage({
              id: "signUp::confirmPassword",
              defaultMessage: "Confirm Password",
            })}
            onPaste={(e) => {
              e.preventDefault()
              return false
            }}
            error={errors.confirmPassword}
            touched={touched.confirmPassword}
            inputIcon={
              <FontAwesomeIcon
                icon={showConfirmPassword ? faEyeSlash : faEye}
                className="input-icon"
                color={theme.colors.blue}
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              />
            }
            iconRight={0.9375}
          />
          <Spacer top={1.5} />
          <div>
            <div className="top-align caption">
              <FormattedMessage
                id="dashboard::settings::language"
                defaultMessage="Language"
              />
              {REQUIRED_FIELD_SYMBOL}
            </div>
            <div data-testid="country-select">
              <Dropdown
                isFormField
                id="communicationLanguage"
                name="communicationLanguage"
                className="select-field custom-select hide-chevron"
                placeholder={formatMessage({
                  id: "signup::choose::languagePlaceholder",
                  defaultMessage: "Your Language",
                })}
                options={languageOptions}
                value={languageOptions?.find(
                  ({ value }) => values?.communicationLanguage === value
                )}
                onChange={(communicationLanguage) => {
                  setFieldValue(
                    "communicationLanguage",
                    communicationLanguage?.value ?? communicationLanguage,
                    true
                  )
                }}
                onBlur={() =>
                  setTouched({ ...touched, communicationLanguage: true })
                }
                error={
                  values.communicationLanguage
                    ? null
                    : errors.communicationLanguage
                }
                touched={touched.communicationLanguage}
              />
            </div>
          </div>
        </StyledSectionBox>
        <StyledSectionTitle className="mb16">
          <FormattedMessage
            id="service::checkoutForm::yourDetails"
            defaultMessage="Your details"
          />
        </StyledSectionTitle>
        <StyledSectionBox className="mb32 pb0">
          <IndividualCompanyForm isCompany={isCompany} isPhoneRequired={true} />
        </StyledSectionBox>
        {registerError && <BaseError message={registerError} />}
        {
          <CheckboxContainer className="less-margin">
            <label htmlFor="isSubscribed">
              <Field
                data-testid="checkbox-subscribe"
                id="isSubscribed"
                name="isSubscribed"
                type="checkbox"
              />
              <span className="checkmark"></span>
              <FormattedMessage
                id="signUp::subscribe"
                defaultMessage="Sign me up for the Trustmeup newsletter"
              />
            </label>
          </CheckboxContainer>
        }
        <TermsAndConditions
          name="isTermsAndPolicyAccepted"
          errors={errors}
          touched={touched}
          onChange={handleChange}
          onClick={() =>
            setTouched({ ...touched, isTermsAndPolicyAccepted: true })
          }
        />
      </StyledFormInputSection>
      {isTablet && (
        <SignUpSubmitWrapper>
          <Button
            label="signUp"
            color="blue"
            type="submit"
            data-testid="btn-signup"
            disabled={isSubmitting || isLoading}>
            {isRecurringSignup ? (
              formatMessage({
                id: "signUp::text::signUp",
                defaultMessage: "Sign Up",
              })
            ) : (
              <FormattedMessage
                id={`signUp::text::${"signUpPACText"}`}
                defaultMessage={"Get 30 € / PAC discount"}
              />
            )}
          </Button>
        </SignUpSubmitWrapper>
      )}
      {!isTablet && (
        <div className="sticky-bottom-bar">
          <Button
            label="signUp"
            color="blue"
            type="submit"
            data-testid="btn-signup"
            disabled={isSubmitting || isLoading}>
            {isRecurringSignup ? (
              formatMessage({
                id: "signUp::text::signUp",
                defaultMessage: "Sign Up",
              })
            ) : (
              <FormattedMessage
                id={`signUp::text::${"signUpPACText"}`}
                defaultMessage={"Get 30 € / PAC discount"}
              />
            )}
          </Button>
        </div>
      )}
    </Form>
  )
}

export default SubForm
