import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import { PasswordRecoveryForm } from "@tmu/components/auth"
import { StyledPageContainer } from "@tmu/global/page-addons/password-recovery.styles"

const PasswordRecovery = () => {
  return (
    <StyledPageContainer>
      <PasswordRecoveryForm backUrl="/" />
    </StyledPageContainer>
  )
}

export default injectIntl(PasswordRecovery)
