import React, { useCallback, useEffect, useMemo } from "react"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { Field, useFormikContext } from "formik"
import { TextInput, FieldError, Dropdown } from "@tmu/components/common"

import { useCountries, useTypeConstants } from "@tmu/hooks"
import { StyledFormSectionHeading } from "@tmu/global/page-addons/dashboard.styles"
import {
  StyledAddressFieldSet,
  StyledHeader,
  StyledField,
} from "./index.styles"

const AddressFieldSet = ({
  merchantProfile = false,
  partnerProfile = false,
}) => {
  const {
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    errors,
    touched,
    setTouched,
  } = useFormikContext()
  const { formatMessage } = useIntl()

  let {
    // getCityRegions,
    countries: allCountries,
    countryOptions: getCountryOptions,
    getCountries,
    // getCities,
  } = useCountries()
  let countries = allCountries

  useEffect(() => {
    getCountries()

    // getCities("IT")

    // if (values?.country) {
    //   getCities(values?.country)
    // }
  }, [])

  let {
    operatingCountries: restrictCountries,
    getCountryOptions: restrictCountryOptions,
  } = useTypeConstants({})

  if (merchantProfile || partnerProfile) {
    countries = restrictCountries
    if (Array.isArray(countries) && countries?.length > 0) {
      const empty = countries[0]
      empty.description = ""
    }
    getCountryOptions = restrictCountryOptions
  }

  const currentCountry = useCallback(
    countries?.find((item) => (item?.id || item?.shortCode) === values?.country)
  )

  const currentCity = useMemo(
    () => ({
      label: values["city"],
    }),
    [values?.city]
  )

  return (
    <StyledAddressFieldSet>
      <StyledHeader>
        <StyledFormSectionHeading>
          <FormattedMessage
            id="dashboard::forms::userData"
            defaultMessage="User Data"
            tagName="h3"
          />
          <FormattedMessage
            id="dashboard::forms::addressText"
            defaultMessage="Please provide a valid location in order to receive invoice & legal papers."
            tagName="p"
          />
        </StyledFormSectionHeading>
      </StyledHeader>
      <StyledField gridArea="address1">
        <Field
          data-testid="input-addressLine1"
          id="addressLine1"
          name="addressLine1"
          component={TextInput}
          className="float-container full-width"
          value={values?.addressLine1 || ""}
          onChange={handleChange}
          onBlur={handleBlur}
          label={formatMessage({
            id: "dashboard::forms::addressLine1",
            defaultMessage: "Address 1",
          })}
        />
        <FieldError name="addressLine1" />
      </StyledField>
      {/* <StyledField gridArea="address2">
        <Field
          data-testid="input-addressLine2"
          id="addressLine2"
          name="addressLine2"
          component={TextInput}
          className="float-container"
          value={values?.addressLine2 || ""}
          onChange={handleChange}
          onBlur={handleBlur}
          label={formatMessage({
            id: "dashboard::forms::addressLine2",
            defaultMessage: "Address 2",
          })}
        />
        <FieldError name="addressLine2" />
      </StyledField>
      <StyledField gridArea="country" data-testid="input-country">
        <Dropdown
          data-testid={`select-country`}
          id="country"
          name="country"
          className="select-field custom-select"
          label={formatMessage({
            id: "howItWorks::forms::country",
            defaultMessage: "Country",
          })}
          options={getCountryOptions}
          value={{
            label: currentCountry?.description || currentCountry?.label || "",
            value: currentCountry?.id || currentCountry?.value || "",
          }}
          onChange={(country) => {
            setFieldValue("country", country?.value, true)
            setTouched({ ...touched, country: true }, true)
            setFieldValue("city", "", true)
            if (country?.value) {
              getCities(country?.value)
            }
          }}
          isClearable
        />
      </StyledField>
      <StyledField gridArea="city" data-testid="input-city">
        <Dropdown
          id="city"
          name="city"
          type="asyncSelect"
          className="select-field city-select"
          noOptionsMessage={() =>
            formatMessage({
              id: "ui::forms::searchCity",
              defaultMessage: "Search for a city",
            })
          }
          placeholder={formatMessage({
            id: "ui::forms::startType",
            defaultMessage: "Start typing...",
          })}
          value={currentCity}
          loadOptions={(e) => getCityRegions(values?.country, e)}
          onChange={(city) => {
            setFieldValue("city", city?.label, true)
            setTouched({ ...touched, city: true }, true)
          }}
          label={formatMessage({
            id: "dashboard::forms::city",
            defaultMessage: "City",
          })}
          isClearable
        />
      </StyledField> */}
      <StyledField gridArea="country" data-testid="input-country">
        <Dropdown
          data-testid={`select-country`}
          id="country"
          name="country"
          className="select-field custom-select"
          label={formatMessage({
            id: "howItWorks::forms::country",
            defaultMessage: "Country",
          })}
          options={getCountryOptions}
          value={{
            label: currentCountry?.description || currentCountry?.label || "",
            value: currentCountry?.id || currentCountry?.value || "",
          }}
          onChange={(country) => {
            setFieldValue("country", country?.value, true)
            setTouched({ ...touched, country: true }, true)
          }}
          onBlur={handleBlur}
          isClearable
        />
        <FieldError name="country" />
      </StyledField>
      <StyledField gridArea="postal">
        <Field
          data-testid="input-zip-code"
          id="postCode"
          name="postCode"
          component={TextInput}
          className="float-container"
          value={values?.postCode || ""}
          onChange={handleChange}
          onBlur={handleBlur}
          label={formatMessage({
            id: "dashboard::forms::postalCode",
            defaultMessage: "Postal Code",
          })}
        />
        <FieldError name="postCode" />
      </StyledField>
      <StyledField gridArea="tax">
        <Field
          data-testid="input-tax-id"
          id="taxId"
          name="taxId"
          component={TextInput}
          className="float-container"
          value={values?.taxId || ""}
          onChange={handleChange}
          onBlur={handleBlur}
          label={formatMessage({
            id: "dashboard::forms::taxIdVat",
            defaultMessage: "Tax Id / VAT",
          })}
        />
        <FieldError name="taxId" />
      </StyledField>
    </StyledAddressFieldSet>
  )
}

export default AddressFieldSet
