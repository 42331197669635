import styled from "@emotion/styled"
import { css } from "@emotion/react"

export const StyledCountrySelectorFilter = styled.div`
  ${(p) =>
    p.disabled &&
    css`
      pointer-events: none;
    `}
`

export const StyledSelectedCountry = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${(p) => p.theme.colors[p.isOfferList ? "carrot" : "blue"]};
  font-family: ${(p) => p.theme.fontFamilies.headline};
  cursor: pointer;
  width: fit-content;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.75rem;
  }
`
export const StyledCountryListItem = styled.div`
  text-align: left;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0.75rem;
  margin-top: 0.75rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  color: ${(p) =>
    p.isOfferList ? p.theme.colors.carrot : p.theme.colors.pacificBlue};
  font-family: ${(p) => p.theme.fontFamilies.regular};
  span {
    margin-left: 0.5rem;
    color: ${(p) => p.theme.colors.pitchBlack};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-top: 0;
  }
`

export const StyledCountryList = styled.div`
  display: block;
  hr {
    margin: 0;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: grid;
    grid-template-columns: 4fr 4fr 4fr;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    div {
      font-size: 1.5rem;
      line-height: 2.25rem;
    }
  }
`

export const StyledOption = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  font-size: 0.75rem;
  line-height: 1rem;
  cursor: pointer;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1rem;
    line-height: 1.375rem;
  }
`
export const StyledOptionLabel = styled.div``
export const StyledSearchOptionLabel = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-size: 1.125rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2.125rem;
  }
`
export const StyledOptionNumbers = styled.div`
  color: ${(p) => p.theme?.colors?.linkBlue};
  &.active {
    color: ${(p) => p.theme.colors.white};
  }
`
export const StyledGroupHeading = styled.div`
  text-align: left;
  font-family: ${(p) => p.theme.fontFamilies.headlineBig};
  font-weight: ${(p) => p.theme.fontWeights.liteBlack};
  color: ${(p) => p.theme.colors.disabled};
  font-size: 0.625rem;
  line-height: 1rem;
  margin-top: 0.25rem;
  margin-bottom: 1rem;
  margin-left: 0.25rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1rem;
    line-height: 1.5rem;
  }
`
export const StyledLine = styled.hr`
  width: 110vw;
  margin: 0;
  margin-left: -3rem;
  background-color: ${(p) => p.theme.colors.transparent};
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.5);
`

export const StyledInlineWrapper = styled.div`
  width: 100%;
  ${(p) =>
    p.disabled &&
    css`
      pointer-events: none;
    `}
`
export const StyledRegionContainer = styled.div`
  width: 100%;
  display: block;
`
export const StyledTagsContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
`
export const StyledTagItem = styled.div`
  white-space: nowrap;
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-size: 1rem;
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  line-height: 1.5rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
`
export const StyledTagItemContainer = styled.div`
  display: flex;
  gap: 0.75rem;
  align-items: center;
  border-radius: 0.25rem;
  background-color: ${(p) => p.theme.colors.whiteSmoke};
  padding: 0.375rem 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding: 0.375rem 0.75rem;
    gap: 1rem;
  }
`

export const StyledCloseIcon = styled.div`
  cursor: pointer;
  margin-top: 0.125rem;
  svg {
    height: 1.5rem;
    width: 1.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin-top: 0.25rem;
    svg {
      height: 2rem;
      width: 1.5rem;
    }
  }
`
