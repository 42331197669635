import React from "react"
import ServiceForm from "./ServiceForm"
import ServiceListing from "./ServiceListing"
import { Router } from "@reach/router"
import OfferTypes from "../OfferTypes"

export default function Services() {
  return (
    <Router>
      <ServiceListing path="/" />
      <ServiceForm path="/new" />
      <OfferTypes path="/type" />
      <ServiceForm path="/edit" />
    </Router>
  )
}
