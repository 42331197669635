import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import { StyledCampaignFAQTableHeader } from "./index.styles"

const CampaignFAQTableHeader = () => {
  return (
    <StyledCampaignFAQTableHeader>
      <div>
        <FormattedMessage
          id="dashboard::ngo::tableHeader::faqQuestion"
          defaultMessage="Question"
        />
      </div>
      <div>
        <FormattedMessage
          id="dashboard::ngo::tableHeader::faqAnswer"
          defaultMessage="Answer"
        />
      </div>
      <div className="centered">
        <FormattedMessage
          id="dashboard::ngo::tableHeader::date"
          defaultMessage="Date"
        />
      </div>
    </StyledCampaignFAQTableHeader>
  )
}

export default CampaignFAQTableHeader
