import React from "react"
import { navigate } from "gatsby-plugin-intl"
import {
  StyledAccordionSubElement,
  StyledNavBarButton,
  StyledAccordion,
} from "../index.styles"

const DigitalBankingAccordion = ({ sections, onClose }) => {
  const handleClickDigitalBankingAccordionButton = (key) => {
    onClose()
    if (key.id === "digital-banking-business") {
      navigate("/digital-banking/business")
    }

    if (key.id === "digital-banking-personal") {
      navigate("/digital-banking/personal")
    }
  }

  return (
    <StyledAccordion
      multiple={true}
      dataTestId="digital-banking-accordion-navbar">
      {Object.entries(sections).map((section, index) => {
        return (
          <div
            label={
              <StyledNavBarButton variant="text">
                {section[1][0]}
              </StyledNavBarButton>
            }
            key={index}>
            {section[1][1].map((key) => (
              <StyledNavBarButton
                key={key.id}
                variant="text"
                align="left"
                onClick={() => handleClickDigitalBankingAccordionButton(key)}>
                <StyledAccordionSubElement data-testid="digital-banking-accordion-navbar-subelement">
                  {key.label}
                </StyledAccordionSubElement>
              </StyledNavBarButton>
            ))}
          </div>
        )
      })}
    </StyledAccordion>
  )
}

export default DigitalBankingAccordion
