import styled from "@emotion/styled"
import { NumericFormat } from "react-number-format"

export const InputFeedback = styled.div`
  margin-top: 0.5rem;
`
export const InputLabel = styled.label`
  display: block;
  margin-bottom: 0.5rem;
`

export const TextInputWrapper = styled.div`
  &.float-container {
    box-sizing: border-box;
    height: 3.1875rem;
    border: 0.0625rem solid ${(p) => p.theme.colors.formFieldsBorderColor};
    border-radius: 0.25rem;
    background-image: none !important;
    background-color: ${(p) => p.theme.colors.white};
    padding-left: 1.9375rem;
    position: relative;
    text-align: left;
    padding-top: 0.0625rem;
    input,
    textarea {
      border: none;
      font-size: 1rem;
      outline: 0;
      height: 90%;
      width: 90%;
      position: relative;
      top: 0.1rem;
    }
    textarea {
      height: 80%;
      width: 98%;
      margin-bottom: 1rem;
      resize: none;
      top: 1rem;
    }

    label {
      position: absolute;
      transform-origin: top left;
      transform: translate(0, -1.85rem) scale(1.1);
      transition: all 0.1s ease-in-out;
      color: ${(p) => p.theme.colors.shuttleflGray};
      background-color: ${(p) => p.theme.colors.white};
      font-size: 1rem;
      line-height: 1.2rem;
      cursor: pointer;
      width: 70%;
      height: 50%;
      z-index: 1;
      margin: 0 auto;
    }

    &.active {
      border: 0.0625rem solid ${(p) => p.theme.colors.blue};
      input + label {
        transform: translate(-0.5rem, -3.2rem) scale(0.8);
        background-color: ${(p) => p.theme.colors.white};
        padding: 0 0.5rem;
        height: 1.1875rem;
        width: auto;
        font-weight: ${(p) => p.theme.fontWeights.bold};
      }
    }
    input[value]:not([value=""]) + label {
      transform: translate(-0.5rem, -3.2rem) scale(0.8);
      background-color: ${(p) => p.theme.colors.white};
      padding: 0 0.5rem;
      height: 1.1875rem;
      width: auto;
      font-weight: ${(p) => p.theme.fontWeights.bold};
    }
    textarea ~ label {
      transform: translate(-0.5rem, -11.2rem) scale(0.8);
      padding: 0 0.5rem;
      height: 1.1875rem;
      width: auto;
      font-weight: ${(p) => p.theme.fontWeights.bold};
    }
    .textarea-counter {
      position: absolute;
      right: 0;
      bottom: -1rem;
      margin: 0;
      background: transparent;
      font-size: 0.7rem;
      font-weight: ${(p) => p.theme.fontWeights.bold};
      color: ${(p) => p.theme.colors.secondaryBrandTextColor};
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 1.875rem ${(p) => p.theme.colors.white} inset !important;
      box-shadow: 0 0 0 1.875rem ${(p) => p.theme.colors.white} inset !important;
    }
  }
  &.error {
    label,
    .input-feedback {
      color: ${(p) => p.theme.colors.red};
      font-size: 0.8rem;
    }
  }
  .input-group {
    margin-bottom: 1rem;
  }

  button:disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
  }

  input:read-only {
    opacity: 0.5;
  }

`

export const StyledNumberFormat = styled(NumericFormat)`
  width: 100%;
  height: 3rem;
  background: ${(p) => p.theme.colors.white};
  border: 0.0625rem solid
    ${(p) => p.theme.colors[p["data-error"] ? "red" : "disabled"]};
  box-sizing: border-box;
  border-radius: 0.25rem;
  align-self: center;
  outline: 0;
  padding: 1rem ${(p) => (p.value ? "1.4rem" : "0.75rem")};
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.regular};
  font-size: 1rem;
  &::placeholder {
    height: 1.25rem;
    line-height: 1.25rem;
    color: ${(p) => p.theme.colors.disabled};
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 1.875rem ${(p) => p.theme.colors.white} inset !important;
    box-shadow: 0 0 0 1.875rem ${(p) => p.theme.colors.white} inset !important;
  }
  &:focus {
    border: 0.0625rem solid ${(p) => p.theme.colors.blue};
  }

  &:disabled {
    background: ${(p) => p.theme.colors.whiteSmoke};
    border: 0.0625rem solid ${(p) => p.theme.colors.disabled};
    pointer-events: none;
  }
`

export const StyledTextAreaWrapper = styled.textarea`
  width: 100%;
  height: ${(p) => (p.maxLength ? "19.5rem" : "9rem")};
  background: ${(p) => p.theme.colors.white};
  border: 0.0625rem solid ${(p) => p.theme.colors[p.error ? "red" : "disabled"]};
  box-sizing: border-box;
  align-self: center;
  outline: 0;
  padding: 1rem 0.75rem;
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.regular};
  font-size: 1rem;
  &::placeholder {
    height: 1.25rem;
    line-height: 1.25rem;
    color: ${(p) => p.theme.colors.disabled};
  }
  resize: none;

  border-radius: 0.25rem;
  white-space: pre-line;
  &:focus,
  &:hover {
    border: 0.0625rem solid ${(p) => p.theme.colors.blue};
  }
`

export const StyledInputWrapper = styled.div`
  position: relative;
  .textarea-counter {
    margin: 0;
    position: absolute;
    right: 1rem;
    bottom: 0.5rem;
    background: transparent;
    font-size: 0.7rem;
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    font-family: ${(p) => p.theme.fontFamilies.regular};
    color: ${(p) => p.theme.colors.disabled};
  }
  &.currency-icon {
    position: relative;
    input:focus,
    input:hover {
      border: 0.0625rem solid ${(p) => p.theme.colors.blue};
    }
    :before {
      content: "€";
      position: absolute;
      background-color: transparent;
      left: 0;
      padding: 0.9rem 0.75rem;
      font-family: ${(p) => p.theme.fontFamilies.regular};
      font-style: ${(p) => p.theme.fontStyles.normal};
      font-weight: ${(p) => p.theme.fontWeights.liteMedium};
      font-size: 1rem;
      line-height: 1.25rem;
      color: ${(p) => p.theme.colors.black};
    }
  }

  .input-icon {
    position: absolute;
    right: ${({ iconRight }) => `${iconRight}rem` ?? "unset"};
    left: ${({ iconLeft }) => `${iconLeft}rem` ?? "unset"};
    bottom: 1rem;
    cursor: pointer;
  }

  .common-input {
    padding-left: ${({ iconLeft }) => (isNaN(iconLeft) ? "0.75rem" : `${iconLeft + 1.4375}rem`)};

  }
`

export const StyledInput = styled.input`
  width: 100%;
  height: 3rem;
  background: ${(p) => p.theme.colors.white};
  border: 0.0625rem solid ${(p) => p.theme.colors[p.error ? "red" : "disabled"]};
  box-sizing: border-box;
  border-radius: 0.25rem;
  align-self: center;
  outline: 0;
  padding: 1rem 0.75rem;
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.regular};
  font-size: 1rem;
  display: block;
  &::placeholder {
    height: 1.25rem;
    line-height: 1.25rem;
    color: ${(p) => p.theme.colors.disabled};
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 1.875rem ${(p) => p.theme.colors.white} inset !important;
    box-shadow: 0 0 0 1.875rem ${(p) => p.theme.colors.white} inset !important;
  }
  &:focus,
  &:hover {
    border: 0.0625rem solid ${(p) => p.theme.colors.blue};
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }

  &:disabled {
    background: ${(p) => p.theme.colors.whiteSmoke};
    border: 0.0625rem solid ${(p) => p.theme.colors.disabled};
    pointer-events: none;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    height: 3.375rem;
  }

  &.fade-effect {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 2.125rem;
  }

  &.fade-effect::after {
    content: "...";
    opacity: 0.3;
  }
`
export const StyledError = styled.span``
