import { useElements, useStripe } from "@stripe/react-stripe-js"
import CreditCardSelector from "."
const CreditCardSelectorWithStripe = ({ ...rest }) => {
  const stripe = useStripe()
  const elements = useElements()

  return <CreditCardSelector {...rest} stripe={stripe} elements={elements} />
}

export default CreditCardSelectorWithStripe
