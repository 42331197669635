import React, { useState } from "react"
import { Router } from "@reach/router"
import { FormattedMessage, navigate, useIntl } from "gatsby-plugin-intl"
import { faHandsHeart } from "@fortawesome/pro-light-svg-icons/faHandsHeart"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CampaignUpdatesTable } from "@tmu/components/dashboard/dashboardCommon"
import { Button, CustomModal, Spinner } from "@tmu/components/common"
import { useQuery, useMutation } from "@apollo/client"
import { useToast, useAuth } from "@tmu/hooks"

import { useApolloApiClients } from "@tmu/apollo/client"
import { PARTNER_CAMPAIGN_DETAIL_QUERY } from "@tmu/apollo/dashboard/queries/campaign"
import {
  CREATE_CAMPAIGN_UPDATE_MUTATION,
  UPDATE_CAMPAIGN_UPDATE_MUTATION,
  DELETE_CAMPAIGN_UPDATE_MUTATION,
} from "@tmu/apollo/dashboard/mutations/campaign"
import { API_PERMISSIONS } from "@tmu/apollo/constants"

import {
  StyledPage,
  StyledPageContent,
  StyledPageTitle,
  StyledPageActions,
  StyledModalActions,
} from "@tmu/global/page-addons/dashboard.styles"
import { getValueForLocale } from "@tmu/utils/string"
import Updates from "./views/Updates"
import { modalStyles } from "@tmu/global/GlobalStyle"

const CampaignUpdates = ({ slug, location }) => {
  const { isLoading, isAuthenticated, apiPermissions } = useAuth()
  const { pathname } = location
  const isEditPage = pathname.includes("edit")
  const [selectedUpdate, setSelectedUpdate] = useState({})

  const { locale, defaultLocale, formatMessage } = useIntl()

  const { success, error } = useToast()
  const { partnerClient } = useApolloApiClients()

  const { loading, data } = useQuery(PARTNER_CAMPAIGN_DETAIL_QUERY, {
    variables: {
      slug,
    },
    client: partnerClient,
    skip: isLoading || !isAuthenticated,
  })
  const campaignUpdates =
    data?.campaign?.updates?.edges.map(({ node }) => ({
      ...node,
      defaultImage: node?.image,
      title: getValueForLocale(node, "title", locale, defaultLocale),
      content: getValueForLocale(node, "content", locale, defaultLocale),
    })) || []

  const [isModalOpen, setModalOpen] = useState(false)
  const [selectedCampaignUpdate, setSelectedCampaignUpdate] = useState()
  const [createCampaignUpdates] = useMutation(CREATE_CAMPAIGN_UPDATE_MUTATION, {
    client: partnerClient,
    refetchQueries: [
      {
        query: PARTNER_CAMPAIGN_DETAIL_QUERY,
        variables: { slug },
      },
    ],
  })
  const [updateCampaignUpdates] = useMutation(UPDATE_CAMPAIGN_UPDATE_MUTATION, {
    client: partnerClient,
    refetchQueries: [
      {
        query: PARTNER_CAMPAIGN_DETAIL_QUERY,
        variables: { slug },
      },
    ],
  })
  const [deleteCampaignUpdates] = useMutation(DELETE_CAMPAIGN_UPDATE_MUTATION, {
    client: partnerClient,
    refetchQueries: [
      {
        query: PARTNER_CAMPAIGN_DETAIL_QUERY,
        variables: { slug },
      },
    ],
  })

  const handleCreateNewCampaignUpdates = () => {
    navigate(`/dashboard/charities/campaigns/${slug}/updates/create`)
  }

  const handleUpdateCampaignUpdates = (update) => {
    setSelectedUpdate(update)
    navigate(`/dashboard/charities/campaigns/${slug}/updates/edit`)
  }

  const handleOpenDeleteModal = (update) => {
    setSelectedCampaignUpdate(update)
    setModalOpen(true)
  }

  const errorHandler = (err) => {
    error(
      err?.message ??
        formatMessage({
          id: "dashboard::campaignForm::errorMessage",
          defaultMessage: "An error occurred",
        })
    )
  }

  const handleConfirmDelete = () => {
    setModalOpen(false)

    deleteCampaignUpdates({
      variables: { input: { id: selectedCampaignUpdate?.id } },
    })
      .then(({ data }) => {
        setSelectedCampaignUpdate(null)
        navigate(`/dashboard/charities/campaigns/${slug}/updates`)

        if (data?.deleteCampaignUpdate?.errors?.length) {
          data.deleteCampaignUpdate.errors.map((err) =>
            error(err.messages?.[0])
          )
        } else if (data?.deleteCampaignUpdate?.success) {
          success(
            formatMessage({
              id: "dashboard::campaign::campaignUpdateDeleted",
              defaultMessage: "The campaign update is deleted successfully",
            })
          )
        }
      })
      .catch(errorHandler)
  }

  const handleModalClose = () => {
    setSelectedCampaignUpdate(null)
    setModalOpen(false)
  }

  const handleSubmitForm = (values) => {
    let input = {
      campaign: data?.campaign?.id,
      title: values?.titleTabEn,
      titleEn: values?.titleTabEn,
      titleIt: values?.titleTabIt,
      titleEs: values?.titleTabEs,
      content: values?.contentTabEn,
      contentEn: values?.contentTabEn,
      contentIt: values?.contentTabIt,
      contentEs: values?.contentTabEs,
      date: new Date(),
      image: values?.image,
      id: values?.id,
      isActive: true,
    }

    if (!!isEditPage) {
      delete input.campaign
    } else {
      delete input.id
    }

    if (input?.image === values?.defaultImage) {
      delete input.image
    }

    if (!!isEditPage) {
      updateCampaignUpdates({
        variables: { input },
      })
        .then(({ data }) => {
          navigate(`/dashboard/charities/campaigns/${slug}/updates`)
          if (data?.updateCampaignUpdate?.errors?.length) {
            data.updateCampaignUpdate.errors.map((err) =>
              error(err.messages?.[0])
            )
          } else if (data?.updateCampaignUpdate?.campaignUpdate?.id) {
            success(
              formatMessage({
                id: "dashboard::campaign::campaignUpdateUpdated",
                defaultMessage: "The campaign update is updated successfully",
              })
            )
          }
        })
        .catch(errorHandler)
    } else {
      createCampaignUpdates({
        variables: { input },
      })
        .then(({ data }) => {
          navigate(`/dashboard/charities/campaigns/${slug}/updates`)
          if (data?.createCampaignUpdate?.errors?.length) {
            data.createCampaignUpdate.errors.map((err) =>
              error(err.messages?.[0])
            )
          } else if (data?.createCampaignUpdate?.campaignUpdate?.id) {
            success(
              formatMessage({
                id: "dashboard::campaign::campaignUpdateCreated",
                defaultMessage: "The campaign update is created successfully",
              })
            )
          }
        })
        .catch(errorHandler)
    }
  }
  const Forms = () => {
    return (
      <Updates
        handleSubmitForm={handleSubmitForm}
        selectedUpdate={selectedUpdate}
        isEditPage={!!isEditPage}
      />
    )
  }

  const UpdateList = () => {
    return loading ? (
      <Spinner condensed />
    ) : (
      <>
        <CampaignUpdatesTable
          campaignUpdates={campaignUpdates}
          onEdit={handleUpdateCampaignUpdates}
          onDelete={handleOpenDeleteModal}
        />
        <StyledPageActions>
          {apiPermissions?.includes(
            API_PERMISSIONS.PARTNER_UPDATE_CAMPAIGN_CONTENT
          ) && (
            <Button
              color="black"
              type="button"
              label="Add Update"
              data-testid="btn-create-update"
              onClick={handleCreateNewCampaignUpdates}>
              <FormattedMessage
                id="dashboard::campaigns::addUpdate"
                defaultMessage="Add Update"
              />
            </Button>
          )}
        </StyledPageActions>

        <CustomModal
          isOpen={isModalOpen}
          style={modalStyles}
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          onRequestClose={handleModalClose}>
          <FormattedMessage
            id="dashboard::campaignUpdates::confirmDelete"
            defaultMessage="Are you sure to delete the campaign update?"
            tagName="p"
          />
          <StyledModalActions>
            <Button
              color="red"
              type="button"
              label="Yes"
              onClick={handleConfirmDelete}
              data-testid="btn-confirm">
              <FormattedMessage
                id="dashboard::campaigns::modalYes"
                defaultMessage="Yes"
              />
            </Button>
            <Button
              variant="outlined"
              type="button"
              label="No"
              onClick={handleModalClose}
              data-testid="btn-cancel">
              <FormattedMessage
                id="dashboard::campaigns::modalNo"
                defaultMessage="No"
              />
            </Button>
          </StyledModalActions>
        </CustomModal>
      </>
    )
  }
  return (
    <StyledPage>
      <StyledPageTitle>
        <FontAwesomeIcon icon={faHandsHeart} />
        <FormattedMessage
          id="dashboard::campaignUpdates::campaignUpdates"
          defaultMessage="Campaign Updates"
          tagName="h1"
        />
      </StyledPageTitle>
      <StyledPageContent data-testid="table-campaign-updates">
        <Router>
          <UpdateList path="/" exact />
          <Forms path="/create" exact />
          <Forms path="/edit" exact />
        </Router>
      </StyledPageContent>
    </StyledPage>
  )
}

export default CampaignUpdates
