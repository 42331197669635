import styled from "@emotion/styled"
export const StyledSearchBoxFilter = styled.div`
  position: relative;
  width: 100%;
  height: 2.5rem;
  input {
    font-size: 0.75rem;
    height: 2.5rem;
    border-color: ${(p) => p.theme.colors.disabled};
    padding-right: 2rem;
  }

  .search-icon {
    position: absolute;
    display: block;
    font-size: 1rem;
    color: ${(p) => p.theme.colors.gondola};
    opacity: 0.6;
    top: 0.5rem;
    right: 1rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    input {
      height: 3.5rem;
      font-size: 1rem;
      line-height: 1.375rem;
    }
    .search-icon {
      font-size: 2rem;
    }
  }
`
