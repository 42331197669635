import React from "react"
import Slider from "rc-slider"
import { StyledMarkLabel, StyledSliderWrapper } from "./index.styles"
import theme from "@tmu/global/theme"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"

const CustomSlider = ({
  onChange,
  defaultValue,
  customRightLabel,
  isPercent,
  min = 0,
  max = 100,
}) => {
  const { isWide } = getAllScreenTypes()
  const colorCarrot = theme?.colors?.carrot

  const handleChange = (value, t) => {
    if (typeof onChange === "function") {
      onChange(value)
    }
  }

  const percentFormatter = (v) => {
    return `${v} %`
  }

  const createSliderWithTooltip = Slider.createSliderWithTooltip
  const Range = createSliderWithTooltip(Slider.Range)
  const handleSize = isWide ? 2 : 1

  const hasDefaultValue =
    Array.isArray(defaultValue) && defaultValue?.length > 1

  if (!hasDefaultValue) {
    defaultValue = [0, Number(max)]
  }

  const left = Number(defaultValue?.[0])
  const right = Number(defaultValue?.[1])

  const marksValue = {}

  marksValue[min] = (
    <StyledMarkLabel className="left-label">
      {min}
      {isPercent && "%"}
    </StyledMarkLabel>
  )
  marksValue[max] = (
    <StyledMarkLabel className="right-label">
      {customRightLabel ?? `${right}${isPercent && "%"}`}
    </StyledMarkLabel>
  )

  return (
    <StyledSliderWrapper>
      <Range
        range
        handleStyle={{
          borderColor: colorCarrot,
          backgroundColor: theme?.colors?.white,
          opacity: 1,
          boxShadow: "none",
          height: `${handleSize}rem`,
          width: `${handleSize}rem`,
          marginTop: isWide ? "-0.85rem" : "-0.4rem",
          borderWidth: isWide ? "0.25rem" : "0.125rem",
        }}
        tipProps={{
          prefixCls: "rc-slider-tooltip",
        }}
        tipFormatter={isPercent && percentFormatter}
        trackStyle={{
          backgroundColor: colorCarrot,
        }}
        railStyle={{
          backgroundColor: theme?.colors?.darkGrey,
        }}
        dotStyle={{
          display: "none",
        }}
        defaultValue={defaultValue}
        min={0}
        max={max}
        marks={marksValue}
        onAfterChange={handleChange}
      />
    </StyledSliderWrapper>
  )
}

export default CustomSlider
