import React from "react"
import { useIntl, navigate } from "gatsby-plugin-intl"
import { Form, useFormikContext } from "formik"
import { FieldError } from "@tmu/components/common"
import { useMutation } from "@apollo/client"
import { ImageSelector } from "@tmu/components/dashboard/dashboardCommon"
import { useApolloApiClients } from "@tmu/apollo/client"
import { useAuth, useDefaultMerchant, useToast } from "@tmu/hooks"
import { SUBMIT_FOR_REVIEW_MERCHANT_STORE_MUTATION } from "@tmu/apollo/dashboard/mutations/merchant"
import { API_PERMISSIONS } from "@tmu/apollo/constants"

import {
  StyledProfileFlagRowContent,
  StyledProfileFormSection,
  StyledProfileRowContent,
} from "./index.styles"

const StoreLogoProfileForm = () => {
  const { apiPermissions } = useAuth()
  const { formatMessage } = useIntl()
  const { merchantClient } = useApolloApiClients()
  const { success, error } = useToast()

  const { initialValues, setFieldValue } = useFormikContext()

  const { refetch } = useDefaultMerchant({ isFullData: true })

  const [submitForReview] = useMutation(
    SUBMIT_FOR_REVIEW_MERCHANT_STORE_MUTATION,
    {
      client: merchantClient,
      onCompleted() {
        refetch()
      },
    }
  )

  const onChange = (image) => {
    let input = {
      ...initialValues,
    }

    const errorHandler = (err) => {
      error(
        err?.message ??
          formatMessage({
            id: "dashboard::campaignForm::errorMessage",
            defaultMessage: "An error occurred",
          })
      )
    }

    const mutationSuccessMessage = formatMessage({
      id: "dashboard::merchantStore::submittedMessage",
      defaultMessage: "Your updates are submitted for review!",
    })

    if (image)
      submitForReview({
        variables: {
          input: {
            logo: image,
          },
        },
        client: merchantClient,
      })
        .then(({ data }) => {
          if (data?.merchantStoreSubmitForReview?.store) {
            success(mutationSuccessMessage)
            navigate("/dashboard/merchants/account/")
          } else {
            throw new Error(
              data?.merchantStoreSubmitForReview?.errors?.[0]?.messages?.[0]
            )
          }
        })

        .catch(errorHandler)
  }

  return (
    <Form className="store-logo-profile-form">
      <fieldset>
        <StyledProfileRowContent>
          <StyledProfileFlagRowContent></StyledProfileFlagRowContent>
          <StyledProfileFormSection horizontal className="logo-wrapper">
            <ImageSelector
              alignOverlay={true}
              noAspect
              defaultValue={initialValues?.logo ?? ""}
              onChange={(image) => {
                setFieldValue("logo", image)
                onChange(image)
              }}
              onReset={() => setFieldValue("logo", initialValues.logo)}
              showUploadDescription={
                apiPermissions?.includes(
                  API_PERMISSIONS.MERCHANT_UPDATE_ACCOUNT_INFORMATION
                )
                  ? true
                  : false
              }
              showUploadLabel={
                apiPermissions?.includes(
                  API_PERMISSIONS.MERCHANT_UPDATE_ACCOUNT_INFORMATION
                )
                  ? true
                  : false
              }
            />
            <div data-testid="error-logo">
              <FieldError name="logo" autoFocus={true} />
            </div>
          </StyledProfileFormSection>
        </StyledProfileRowContent>
      </fieldset>
    </Form>
  )
}

export default StoreLogoProfileForm
