import React, { useRef } from "react"
import { faHandshake } from "@fortawesome/pro-solid-svg-icons/faHandshake"
import { faBullhorn } from "@fortawesome/pro-solid-svg-icons/faBullhorn"
import { faUsers } from "@fortawesome/pro-solid-svg-icons/faUsers"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import { BlogCareerSection, LazyImage } from "@tmu/components/common"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import SEO from "@tmu/components/seo"
import { SliceCounter } from "@tmu/components/home"
import {
  StyledAboutUsWrapper,
  StyledSectionDivider,
  StyledAboutUsHero,
  StyledAboutUsHeroText,
  StyledCoreWhatWeAreDoing,
  StyledCoreWhatWeAreDoingTitle,
  StyledCoreWhatWeAreDoingDivider,
  StyledCoreWhatWeAreDoingItems,
  StyledCoreWhatWeAreDoingItem,
  StyledWhoAreWeHelping,
  StyledWhoAreWeHelpingTextContainer,
  StyledWhoAreWeHelpingTitle,
  StyledWhoAreWeHelpingDescription,
  StyledWhoAreWeHelpingImageContainer,
  StyledTextContainer,
  StyledStoryTitle,
  StyledStoryDescription,
  StyledStats,
  StyledAboutSlice,
  StyledAboutUsHeroTitle,
} from "./index.styles"

const { CLOUDFLARE_IMAGE_URL } = process.env

const heroImage =
  CLOUDFLARE_IMAGE_URL + "/static/assets/images/about/amsterdam.png"

const topImage =
  CLOUDFLARE_IMAGE_URL + "/static/assets/images/about/about-tmu-top.jpg"

const storyImage =
  CLOUDFLARE_IMAGE_URL + "/static/assets/images/about/collage.png"

const AboutUsPage = () => {
  const { locale, formatMessage } = useIntl()
  const ref = useRef()
  const { isTablet, isDesktop, isWide } = getAllScreenTypes()

  const imgSize = {
    width: isWide ? 1920 : isDesktop ? 1024 : 768,
    height: isWide ? 640 : isDesktop ? 421 : 344,
  }

  const imgSize2 = {
    width: isWide ? 852 : isDesktop ? 496 : isTablet ? 376 : 360,
    height: isWide ? 640 : isDesktop ? 476 : isTablet ? 496 : 336,
    src:
      CLOUDFLARE_IMAGE_URL +
      `/static/assets/images/about/about-${
        isWide ? "wide" : isDesktop ? "desktop" : isTablet ? "tablet" : "mobile"
      }.png`,
  }

  return (
    <>
      <SEO
        lang={locale}
        title={formatMessage({
          id: "aboutUs::pageTitle",
          defaultMessage: "About Us",
        })}
        description={formatMessage({
          id: "seo::aboutUs::description",
          defaultMessage:
            "TrustMeUp turns every purchase into a donation and rewards every donation 100% in discounts for purchases.",
        })}
      />
      <StyledAboutUsWrapper ref={ref}>
        <StyledAboutUsHero>
          <div className="bg-image">
            <LazyImage
              altName={`hero-${heroImage}`}
              src={heroImage}
              {...imgSize}
              fit="cover"
            />
          </div>
          <StyledAboutUsHeroTitle>
            <FormattedMessage
              id="aboutUs::hero::title1"
              defaultMessage="Our vision"
            />
          </StyledAboutUsHeroTitle>
          <StyledAboutUsHeroText>
            <FormattedMessage
              id="aboutUs::hero::text1"
              defaultMessage="Double donations around the world"
            />
          </StyledAboutUsHeroText>
          <StyledAboutUsHeroTitle>
            <FormattedMessage
              id="aboutUs::hero::title2"
              defaultMessage="Our mission"
            />
          </StyledAboutUsHeroTitle>
          <StyledAboutUsHeroText>
            <FormattedMessage
              id="aboutUs::hero::text2"
              defaultMessage="Turn every purchase into a donation and reward every donation 100% in discounts for purchases"
            />
          </StyledAboutUsHeroText>
        </StyledAboutUsHero>
        <StyledCoreWhatWeAreDoing>
          <StyledCoreWhatWeAreDoingTitle>
            <FormattedMessage
              id="aboutUs::core::title"
              defaultMessage="Core of what we are doing"
            />
          </StyledCoreWhatWeAreDoingTitle>
          <StyledCoreWhatWeAreDoingDivider />
          <StyledCoreWhatWeAreDoingItems>
            <StyledCoreWhatWeAreDoingItem>
              <FontAwesomeIcon className="icon" icon={faBullhorn} />
              <FormattedMessage
                id="aboutUs::core::title1"
                defaultMessage="Give voice to charity worldwide causes"
                tagName="span"
              />
            </StyledCoreWhatWeAreDoingItem>
            <StyledCoreWhatWeAreDoingItem>
              <FontAwesomeIcon className="icon" icon={faUsers} />
              <FormattedMessage
                id="aboutUs::core::title2"
                defaultMessage="Unite people in doing good to increase an impact"
                tagName="span"
              />
            </StyledCoreWhatWeAreDoingItem>
            <StyledCoreWhatWeAreDoingItem>
              <FontAwesomeIcon className="icon" icon={faHandshake} />
              <FormattedMessage
                id="aboutUs::core::title3"
                defaultMessage="Cooperate with the influential global brands for finding ways for a change"
                tagName="span"
              />
            </StyledCoreWhatWeAreDoingItem>
          </StyledCoreWhatWeAreDoingItems>
        </StyledCoreWhatWeAreDoing>
        <StyledWhoAreWeHelping>
          <StyledWhoAreWeHelpingImageContainer>
            <div className="image">
              <LazyImage
                altName={`about-us-images`}
                {...imgSize2}
                fit="cover"
              />
            </div>
          </StyledWhoAreWeHelpingImageContainer>
          <StyledWhoAreWeHelpingTextContainer>
            <StyledWhoAreWeHelpingTitle>
              <FormattedMessage
                id="aboutUs::whoWeAreHelping::title"
                defaultMessage="Who are we helping?"
              />
            </StyledWhoAreWeHelpingTitle>
            <StyledWhoAreWeHelpingDescription>
              <FormattedMessage
                id="aboutUs::whoWeAreHelpingDescription::title"
                defaultMessage="We are working on the development of the charitable sector and ivil society, involving people, creating conditions for solving social problems and supporting social initiatives around the world."
              />
            </StyledWhoAreWeHelpingDescription>
            <StyledWhoAreWeHelpingTitle>
              <FormattedMessage
                id="aboutUs::bigTask::title"
                defaultMessage="Big task"
              />
            </StyledWhoAreWeHelpingTitle>
            <StyledWhoAreWeHelpingDescription>
              <FormattedMessage
                id="aboutUs::bigTaskDescription::title"
                defaultMessage="There are so many social problems in the world as well as charitable, volunteer organizations and initiatives that solve them. We provide an environment for non-profit organizations and donors to meet and make donation process simple and benefiting to all. Supporting non-profits and making them stronger is our main task."
              />
            </StyledWhoAreWeHelpingDescription>
          </StyledWhoAreWeHelpingTextContainer>
        </StyledWhoAreWeHelping>

        <StyledSectionDivider />
        <StyledAboutSlice>
          <LazyImage
            altName={`hero-${topImage}`}
            src={topImage}
            width={isWide ? 852 : isDesktop ? 466 : 360}
            fit="cover"
          />
          <StyledTextContainer>
            <StyledStoryTitle>
              <FormattedMessage
                id="aboutUs::story::title"
                defaultMessage="Story"
              />
            </StyledStoryTitle>
            <StyledStoryDescription>
              <FormattedMessage
                id="aboutUs::story::description"
                defaultMessage="In June 2014, our CEO Angelo Fasola, for over 35 years engaged in the social sector and with managerial experience in Italian and international companies for over 25 years, after having followed, at the Palazzo Montecitorio, the intervention on the Blockchain Protocol by the Prof. Ferndinando Ametrano - University of Milan Bicocca, realized that this technology has the enormous power to renew all the major sectors: industrial, public, administrative, financial and fundraising."
              />
            </StyledStoryDescription>
            <StyledStoryDescription>
              <FormattedMessage
                id="aboutUs::story::description1"
                defaultMessage="Together with his business partner, Carlo Carmine, Chairman of TrustMeUp, an entrepreneur for over 20 years awarded twice in the USA for the results obtained by applying the new paradigms of digital marketing and lead generation processes to his business, as well as founder of the first company legal, during the Miami-Milan flight, they discussed their knowledge on blockchain as well as on the experience already acquired in terms of supporters trust, and thus gave life to the idea of ​​an international ecosystem in which charities in all over the world meet donors and supporters, to share goals and projects to be carried out together."
              />
            </StyledStoryDescription>
          </StyledTextContainer>
        </StyledAboutSlice>
        <StyledSectionDivider />
        <StyledAboutSlice>
          <LazyImage
            altName={`hero-${storyImage}`}
            src={storyImage}
            width={isWide ? 852 : isDesktop ? 466 : 360}
            fit="cover"
          />
          <StyledTextContainer>
            <StyledStoryTitle>
              <FormattedMessage
                id="aboutUs::team::title"
                defaultMessage="Team"
              />
            </StyledStoryTitle>
            <StyledStoryDescription>
              <FormattedMessage
                id="aboutUs::team::text1"
                defaultMessage="We help people to improve life of others through digital under the guidance of the Board of Directors, of Chairman Carlo Carmine, by CEO Angelo Fasola and CIO Luca Salvadori."
              />
            </StyledStoryDescription>
            <StyledStoryDescription>
              <FormattedMessage
                id="aboutUs::team::text2"
                defaultMessage="We believe that community matters as well as working environment and proud to be an equal opportunity workplace. All qualified applicants will receive consideration for employment without regard to, and will not be discriminated against based on age, race, gender, color, religion, national origin, sexual orientation, gender identity, veteran status, disability or any other protected category."
              />
            </StyledStoryDescription>
          </StyledTextContainer>
        </StyledAboutSlice>
        <StyledStats>
          <FormattedMessage
            id="aboutUs::numbers::title"
            defaultMessage="TrustMeUp in numbers"
            tagName="h4"
          />
          <hr />
          <SliceCounter />
        </StyledStats>
        <BlogCareerSection />
      </StyledAboutUsWrapper>
    </>
  )
}

export default AboutUsPage
