import React from "react"
import PropTypes from "prop-types"
import TermsAndConditionsLabel from "./label"

import { CheckboxContainer, FieldError } from "@tmu/components/common"

const TermsAndConditions = ({
  className,
  labelClassName,
  name,
  termsLink,
  policyLink,
  errors,
  touched,
  checked,
  onChange,
  onClick,
  disabled,
  ...rest
}) => {
  return (
    <CheckboxContainer
      error={errors?.[name]}
      touched={touched?.[name]}
      className={className}>
      <label htmlFor={name} className={labelClassName}>
        <input
          data-testid={`check-${name}`}
          id={name}
          name={name}
          type="checkbox"
          checked={checked}
          onChange={onChange}
          onClick={onClick}
          disabled={disabled}
        />
        <span className="checkmark"></span>
        <TermsAndConditionsLabel
          termsLink={termsLink}
          policyLink={policyLink}
          {...rest}
        />
      </label>
      <FieldError errors={errors} touched={touched} name={name} />
    </CheckboxContainer>
  )
}

TermsAndConditions.propTypes = {
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  termsLink: PropTypes.string,
  policyLink: PropTypes.string,
  name: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  checked: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default TermsAndConditions
