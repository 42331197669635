/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useWindowResize } from "beautiful-react-hooks"
import { Wrapper, StyledSideDrawer } from "./index.styles"

const SideDrawer = ({ isHidden, children, ...rest }) => {
  const [isDesktopView, setDesktopView] = useState(true)
  const [isSideDrawerOpen, setSideDrawerOpen] = useState(false)
  const onWindowResize = useWindowResize()

  const handleToggleSideDrawer = () => {
    setSideDrawerOpen(!isSideDrawerOpen)
  }

  onWindowResize(() => {
    setDesktopView(window.innerWidth > 768)
    if (window.innerWidth <= 768) {
      setDesktopView(false)
    } else {
      setSideDrawerOpen(false)
    }
  })

  return (
    <Wrapper hidden={isHidden} {...rest}>
      <StyledSideDrawer isOpen={isDesktopView || isSideDrawerOpen}>
        <button
          aria-label="side"
          className="close-icon"
          onClick={handleToggleSideDrawer}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        {children}
      </StyledSideDrawer>
    </Wrapper>
  )
}

SideDrawer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
  ]),
}

export default SideDrawer
