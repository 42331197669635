import React, { useContext, useEffect, useState } from "react"
import { injectIntl, useIntl, FormattedMessage } from "gatsby-plugin-intl"
import { useFooterType } from "@tmu/hooks"
import { HomePageContext } from "@tmu/context/homePageContext"
import { LazyImage, Spinner } from "@tmu/components/common"
import { HowItWorksForm } from "@tmu/components/how-it-works"
import { getValueForLocale } from "@tmu/utils/string"
import { FOOTER_TYPE } from "@tmu/apollo/constants"
import { faPencil } from "@fortawesome/pro-solid-svg-icons/faPencil"
import { faBadgePercent } from "@fortawesome/pro-light-svg-icons/faBadgePercent"
import { faBahai } from "@fortawesome/pro-solid-svg-icons/faBahai"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { StyledJoinPageWrapper } from "@tmu/global/page-addons/how-it-works.styles"
import {
  StyledNgoList,
  StyledNgoListItem,
  StyledNgoListTitle,
  StyledLogoSlice,
} from "@tmu/components/how-it-works/Join/index.styles"
import {
  StyledWrapper,
  StyledStoryBox,
  StyledStoryBoxHeader,
  StyledStoryBoxTitle,
  StyledStoryBoxText,
  StyledStoryBoxOverlay,
  StyledStoryBoxImage,
  StyledStoryBoxMobileText,
  StyledBenefitList,
  StyledBenefit,
  StyledBenefitCard,
  StyledBenefitFontIcon,
  StyledBenefitTitle,
  StyledBenefitDescription,
  StyledStoreContainer,
  StyledStoreImage,
  StyledStoreContent,
  StyledStoreMapIcon,
  StyledSectionDivider,
} from "@tmu/global/page-addons/offline-merchant-page.styles"
import MerchantSelfRegistrationSection from "@tmu/src/components/common/MerchantSelfRegistrationSection"

const { CLOUDFLARE_IMAGE_URL } = process.env

const OfflineMerchantsPage = () => {
  const { locale, defaultLocale, formatMessage } = useIntl()
  const { setHeaderConfigData } = useContext(HomePageContext)
  const [firstLoad, setFirstLoad] = useState(false)
  useFooterType({ footerType: FOOTER_TYPE.WIDE })

  useEffect(() => {
    setHeaderConfigData({ transparent: true })
  }, [])

  const { isTablet, isDesktop, isWide } = getAllScreenTypes()

  const imageHeight = isWide ? 640 : isDesktop ? 480 : isTablet ? 394 : 336

  const numberOfNgos = 8

  const logoWidth = isWide ? 413 : isDesktop ? 223 : isTablet ? 168 : 156

  const imageStoreHeight = isWide ? 647 : isDesktop ? 363 : isTablet ? 267 : 329

  const iconSize = {
    width: isWide ? 123 : 38,
    height: isWide ? 110 : 34,
  }

  const bannerData = {
    header: formatMessage({
      id: "join::offlineMerchants::heroHeader",
      defaultMessage:
        "Become charity-friendly store to spread awareness and support charity of your choice",
    }),
    title: formatMessage({
      id: "join::offlineMerchants::heroTitle",
      defaultMessage:
        "Give your customers discounts for donating to a charity of your choice",
    }),
    image:
      CLOUDFLARE_IMAGE_URL +
      `/static/assets/images/join/${isWide ? `qr_1920.png` : `qr_1024.png`}`,
  }

  const benefitCards = [
    {
      title: formatMessage({
        id: "join::offlineMerchants::title",
        defaultMessage: "Create a discount campaign",
      }),
      desc: formatMessage({
        id: "join::offlineMerchants::description",
        defaultMessage:
          "Choose charities you would like to support by creating your campaign",
      }),
      fontIcon: faPencil,
    },
    {
      title: formatMessage({
        id: "join::offlineMerchants::title2",
        defaultMessage:
          "Set a discount for a product and print a QR-code of your campaign",
      }),
      desc: formatMessage({
        id: "join::offlineMerchants::description2",
        defaultMessage:
          "Your customers will scan a QR-code to donate and obtain a discount",
      }),
      fontIcon: faBadgePercent,
    },
    {
      title: formatMessage({
        id: "join::offlineMerchants::title3",
        defaultMessage: "Grow your business as charity friendly",
      }),
      desc: formatMessage({
        id: "join::offlineMerchants::description3",
        defaultMessage:
          "Put your brand name alongside with big and small Non-Profit organisations as a charity friendly",
      }),
      fontIcon: faBahai,
    },
  ]

  const pageData = {
    contactFormTitle: formatMessage({
      id: "join::forms::contactForm::offlineMerchantTitle",
      defaultMessage: "Join as a Physical Store",
    }),
    contactFormDescription: formatMessage({
      id: "join::forms::contactForm::offlineMerchantDescription",
      defaultMessage:
        "Tell us about your charity so we can help you to onboard",
    }),
    termsLink: "/legal/merchant-terms/20",
    sender: "MERCHANT",
    logoItems: [
      {
        node: {
          logoEn:
            "https://images.trustmeup.com/merchants/store/logo/1669978605081-QZPX4YBJeeZUuq4av0XPHw.jpg",
        },
      },
      {
        node: {
          logoEn:
            "https://images.trustmeup.com/merchants/store/logo/1665564894726-prhOoUFZXSEpVDSAbDjsKA.jpg",
        },
      },
      {
        node: {
          logoEn:
            "https://images.trustmeup.com/merchants/store/logo/1665564991184-uNBwIR4dvgkji6KUE_4zfA.jpg",
        },
      },
      {
        node: {
          logoEn:
            "https://images.trustmeup.com/merchants/store/logo/1681375137392-O7k5Ytl3e_WcqGTi0cM4vQ.jpg",
        },
      },
      {
        node: {
          logoEn:
            "https://images.trustmeup.com/merchants/store/logo/1650458594430-NeSP_KJNREKBO4_AWBBQYg.jpg",
        },
      },
      {
        node: {
          logoEn:
            "https://images.trustmeup.com/merchants/store/logo/1664789909085-1SZc2vL4Sxn1UbhN01gpJg.jpg",
        },
      },
      {
        node: {
          logoEn:
            "https://images.trustmeup.com/merchants/store/logo/1665564826004-l0lm5qAOl1f0QZeS9oEDLA.jpg",
        },
      },
      {
        node: {
          logoEn:
            "https://images.trustmeup.com/merchants/store/logo/1663678145648-nnR15Pvl5hE1lSXZLIiw7g.jpg",
        },
      },
    ],
    logoItemsTitle: formatMessage({
      id: "join::merchant::ngoListTitle",
      defaultMessage: "Some of Merchants that already work with us:",
    }),
  }

  const contactFormTitle = <h3>{pageData?.contactFormTitle}</h3>

  const contactFormDescription = <p>{pageData?.contactFormDescription}</p>

  const textData = (
    <StyledStoryBoxTitle>{bannerData?.title}</StyledStoryBoxTitle>
  )

  useEffect(() => {
    setFirstLoad(true)
  }, [])

  if (!firstLoad) return <Spinner />

  return (
    <StyledJoinPageWrapper>
      <StyledWrapper>
        <div>
          <StyledStoryBox>
            <StyledStoryBoxText>
              <StyledStoryBoxHeader>{bannerData?.header}</StyledStoryBoxHeader>
              {isTablet && textData}
            </StyledStoryBoxText>
            {!isTablet && <StyledStoryBoxOverlay></StyledStoryBoxOverlay>}
            <StyledStoryBoxImage>
              <LazyImage
                altName="join as merchants"
                src={bannerData?.image}
                width="auto"
                height={imageHeight}
                fit="cover"
              />
            </StyledStoryBoxImage>
          </StyledStoryBox>
          {!isTablet && (
            <StyledStoryBoxMobileText>{textData}</StyledStoryBoxMobileText>
          )}
        </div>
        <StyledBenefitList>
          <StyledBenefit>
            {benefitCards?.map((e, i) => (
              <StyledBenefitCard key={i}>
                <StyledBenefitFontIcon>
                  <FontAwesomeIcon icon={e?.fontIcon} />
                </StyledBenefitFontIcon>
                {e?.title && (
                  <StyledBenefitTitle>{e?.title}</StyledBenefitTitle>
                )}
                {!isTablet && e?.desc && (
                  <>
                    <StyledBenefitDescription>
                      {e?.desc}
                    </StyledBenefitDescription>
                    {i !== benefitCards.length - 1 && <StyledSectionDivider />}
                  </>
                )}
              </StyledBenefitCard>
            ))}
          </StyledBenefit>
          {isTablet && (
            <StyledBenefit>
              {benefitCards?.map((e, i) => (
                <StyledBenefitCard key={i}>
                  {e?.desc && (
                    <StyledBenefitDescription>
                      {e?.desc}
                    </StyledBenefitDescription>
                  )}
                </StyledBenefitCard>
              ))}
            </StyledBenefit>
          )}
        </StyledBenefitList>
        {isTablet ? (
          <StyledStoreContainer>
            <StyledStoreImage>
              <LazyImage
                altName="store-detail"
                src={
                  CLOUDFLARE_IMAGE_URL +
                  `/static/assets/images/join/${
                    isWide ? `store_detail_1920.png` : `store_detail.png`
                  }`
                }
                width="auto"
                height={imageStoreHeight}
                fit="cover"
              />
            </StyledStoreImage>
            <StyledStoreContent>
              <FormattedMessage
                id="join::offlineMerchants::storeText1"
                defaultMessage="Individual store page with your store directions and directions to your store"
                tagName="h4"
              />
              <StyledStoreMapIcon>
                <img
                  className="pacs-pacs-image"
                  src={
                    CLOUDFLARE_IMAGE_URL +
                    `/static/assets/images/join/marker.svg`
                  }
                  alt={`pacs-pacs-banner`}
                  {...iconSize}
                />
              </StyledStoreMapIcon>
              <FormattedMessage
                id="join::offlineMerchants::storeText2"
                defaultMessage="Customers will discover your store page in physical section of PAC Stores and get direction to it. Use store page as a landing page for your marketing campaigns to increase the number of in-store purchases"
                tagName="p"
              />
            </StyledStoreContent>
          </StyledStoreContainer>
        ) : (
          <StyledStoreContainer>
            <FormattedMessage
              id="join::offlineMerchants::storeText1"
              defaultMessage="Individual store page with your store directions and directions to your store"
              tagName="h4"
            />
            <StyledStoreImage>
              <LazyImage
                altName="store-detail"
                src={
                  CLOUDFLARE_IMAGE_URL +
                  `/static/assets/images/join/${
                    isWide ? `store_detail_1920.png` : `store_detail.png`
                  }`
                }
                width="auto"
                height={imageStoreHeight}
                fit="cover"
              />
            </StyledStoreImage>
            <FormattedMessage
              id="join::offlineMerchants::storeText2"
              defaultMessage="Customers will discover your store page in physical section of PAC Stores and get direction to it. Use store page as a landing page for your marketing campaigns to increase the number of in-store purchases"
              tagName="p"
            />
          </StyledStoreContainer>
        )}
        <StyledLogoSlice className="join-offline-merchant">
          <StyledNgoListTitle>{pageData?.logoItemsTitle}</StyledNgoListTitle>
          <StyledNgoList sender={pageData?.sender}>
            {pageData?.logoItems?.slice(0, numberOfNgos)?.map((item, index) => {
              return (
                <StyledNgoListItem key={index}>
                  <LazyImage
                    altName={"logo-" + index}
                    src={getValueForLocale(
                      item?.node,
                      "logo",
                      locale,
                      defaultLocale
                    )}
                    width={logoWidth}
                    height="auto"
                    fit="cover"
                  />
                </StyledNgoListItem>
              )
            })}
          </StyledNgoList>
        </StyledLogoSlice>
        <MerchantSelfRegistrationSection isMerchantOffline />
      </StyledWrapper>
    </StyledJoinPageWrapper>
  )
}

export default injectIntl(OfflineMerchantsPage)
