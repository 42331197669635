import React from "react"
import { LazyImage, Money, TruncatedText } from "@tmu/components/common"
import { removeAllHTMLTags } from "@tmu/utils/string"
import { FormattedMessage, navigate } from "gatsby-plugin-intl"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import {
  StyledCardTitle,
  StyledOfflineStoreBox,
  StyledCardInfo,
  StyledImageBox,
  StyledPartnerLogo,
  StyledPartnerTitleText,
  StyledOfferStore,
  StyledCardDescription,
  StyledCardDiscount,
} from "./index.styles"

const OfflineStoreCard = ({
  id,
  title,
  description,
  maxPacDiscountInOffers,
  maxPacDiscount,
  size,
  cardLink,
  storeLogo,
  storeName,
  bigTablet,
  ...rest
}) => {
  const { isTablet, isDesktop, isWide } = getAllScreenTypes()
  const imageSize = {
    width: isWide ? 414 : isDesktop ? 228 : isTablet ? 228 : 115,
    height: isWide ? 232 : isDesktop ? 128 : isTablet ? 172 : 115,
  }

  // For offline stores we display the maxPacDiscount of default offer
  const discountBanner = (
    <StyledCardDiscount size={size} isOfflineStore={true}>
      <>
        <FormattedMessage
          id="storeCard::discount::discountUpTo"
          defaultMessage="Donate up to {discount} discount"
          values={{
            discount: (
              <Money style="percent" value={maxPacDiscount} currency={false} />
            ),
          }}
        />
      </>
    </StyledCardDiscount>
  )

  return (
    <StyledOfflineStoreBox
      data-testid={`offlineStoreCard${id}`}
      size={size}
      {...rest}
      onClick={() => navigate(cardLink)}
      bigTablet={bigTablet}>
      <StyledImageBox size={size}>
        <LazyImage
          src={storeLogo}
          {...imageSize}
          altName={`card-image`}
          fit="cover"
        />
      </StyledImageBox>
      <StyledCardInfo offer size={size}>
        {(!isTablet || (isTablet && size !== "tile")) && (
          <div>
            <StyledCardTitle offer size={size}>
              <TruncatedText numLines={2}>{storeName}</TruncatedText>
            </StyledCardTitle>
            <StyledCardDescription>
              <TruncatedText
                numLines={isDesktop || (isTablet && size === "tile") ? 4 : 3}>
                {removeAllHTMLTags(description)}
              </TruncatedText>
            </StyledCardDescription>
          </div>
        )}
        {(!isTablet || (isTablet && size !== "tile")) && discountBanner}
      </StyledCardInfo>
      <div>
        {(isDesktop || (isTablet && size === "tile")) && discountBanner}
        {(isDesktop || (isTablet && size === "tile")) && (
          <StyledOfferStore size={size} bigTablet={bigTablet}>
            <StyledPartnerLogo
              data-testid="campaign-detail__foundation-logo"
              src={storeLogo}
            />
            <StyledPartnerTitleText>
              <TruncatedText numLines={2}>{storeName}</TruncatedText>
            </StyledPartnerTitleText>
          </StyledOfferStore>
        )}
      </div>
    </StyledOfflineStoreBox>
  )
}
export default OfflineStoreCard
