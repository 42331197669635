import styled from "@emotion/styled"

export const StyledBenefitCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem 1rem;
`

export const StyledIcon = styled.img`
  width: 4.375rem;
  height: 4.375rem;
  padding-bottom: 1.875rem;
`
export const StyledTitle = styled.h4`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.regular};
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: ${(p) => p.theme.colors.racingGreen};
  min-height: 4.25rem;
  padding-bottom: 0.5rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1.125rem;
    line-height: 1.5rem;
    min-height: 5rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
    line-height: 2.625rem;
    min-height: 5.75rem;
  }
`
export const StyledDescription = styled.span`
  font-size: 0.625rem;
  line-height: 1rem;
  color: ${(p) => p.theme.colors.doveGray};
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 0.75rem;
    line-height: 1.125rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 1rem;
    line-height: 1.375rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
`

export const StyledFontIcon = styled.div`
  margin-bottom: 1rem;
  svg {
    height: 1.313rem;
    color: ${(p) => (p.carrot ? p.theme.colors.carrot :p.blue? p.theme.colors.blue: p.theme.colors.black)};
    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      height: 2.125rem;
    }
    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      height: 2.188rem;
    }
  }
`
