import React from "react"
import { Router } from "@reach/router"
import { PartnerDetail } from "@tmu/components/partners"
import { CampaignDonationForm } from "@tmu/components/campaigns"

import { injectIntl } from "gatsby-plugin-intl"

const Charities = () => (
  <Router basepath="/:locale/charities">
    <PartnerDetail path="/:slug" />
  </Router>
)

export default injectIntl(Charities)
