import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import { LazyImage } from "@tmu/components/common"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import {
  StyledPacsHeroContainer,
  StyledPacsHeroTitle,
  StyledPacsHeroReward,
  StyledPacsHeroEncourage,
  StyledPacsHeroPacsPoints,
  StyledPacsExplained,
  StyledEqualsSymbol,
  StyledPacsHeroSpecialPacOffers,
  StyledImageBox,
} from "./index.styles"

const { CLOUDFLARE_IMAGE_URL } = process.env

const equalsSymbol = " = "

const pacAmount = "1"

const PacsHero = () => {
  const { isTablet, isDesktop, isWide } = getAllScreenTypes()

  const imageHeight = isWide ? 1266.84 : isDesktop ? 671 : isTablet ? 544 : 608

  return (
    <StyledPacsHeroContainer>
      <StyledPacsHeroTitle>
        <FormattedMessage
          id="pacs::hero::title"
          defaultMessage="What are PACs?"
        />
      </StyledPacsHeroTitle>
      <StyledPacsHeroReward>
        <FormattedMessage
          id="pacs::hero::reward"
          defaultMessage="We reward for doing good"
        />
      </StyledPacsHeroReward>
      <StyledPacsHeroEncourage>
        <FormattedMessage
          id="pacs::hero::encourage"
          defaultMessage="PACs are our way to encourage people to donate more"
        />
      </StyledPacsHeroEncourage>
      <StyledPacsHeroPacsPoints>
        <FormattedMessage
          id="pacs::hero::pacsPoints"
          defaultMessage="For every donation you make you receive equal amount of PACs points:"
        />
      </StyledPacsHeroPacsPoints>
      <StyledPacsExplained>
        <FormattedMessage
          id="pacs::hero::oneEuro"
          defaultMessage="€ 1"
          tagName="span"
        />
        <FormattedMessage id="pacs::hero::donated" defaultMessage="donated" />
        <StyledEqualsSymbol>{equalsSymbol}</StyledEqualsSymbol>
        <p>
          {pacAmount}
          <img
            className="pacs-pacs-image"
            src={CLOUDFLARE_IMAGE_URL + "/static/assets/images/pac.svg"}
            alt={`pacs-pacs-banner`}
            width={28}
            height={23}
          />
          <FormattedMessage id="pacs::hero::pac" defaultMessage="PAC" />
        </p>
      </StyledPacsExplained>
      <StyledPacsHeroSpecialPacOffers>
        <FormattedMessage id="pacs::hero::example" defaultMessage="Example:" />
      </StyledPacsHeroSpecialPacOffers>
      <StyledPacsHeroSpecialPacOffers>
        <FormattedMessage
          id="pacs::hero::exampleText"
          defaultMessage="By donating 20 € you receive 20 PACs that you can use as discounts in the stores on TrustMeUp, 20 PAC = 20 €"
        />
      </StyledPacsHeroSpecialPacOffers>
      <StyledPacsHeroSpecialPacOffers>
        <FormattedMessage
          id="pacs::hero::specialPacOffers"
          defaultMessage="We partner with more then 50 stores, to create special PAC Offers, so you can spend your PACs shopping and purchase things with a discount."
        />
      </StyledPacsHeroSpecialPacOffers>
      <StyledImageBox>
        <LazyImage
          src={CLOUDFLARE_IMAGE_URL + "/static/assets/images/ladypac.png"}
          altName={`pacs-lady-banner`}
          width="auto"
          height={imageHeight}
          fit="contain"
        />
      </StyledImageBox>
    </StyledPacsHeroContainer>
  )
}

export default PacsHero
