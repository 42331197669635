import { useLazyQuery } from "@apollo/client"
import { useContext, useEffect } from "react"
import { OFFER_LINK_WITH_TOKEN } from "../apollo/storefront/queries/user"
import { HomePageContext } from "../context/homePageContext"

const useOfferLink = (options) => {
  const { setGlobalSpinnerStatus } = useContext(HomePageContext)

  const [getOfferLink, { data, loading }] = useLazyQuery(
    OFFER_LINK_WITH_TOKEN,
    {}
  )

  useEffect(() => {
    setGlobalSpinnerStatus(loading)
  }, [loading])

  return {
    getOfferLink,
  }
}

export default useOfferLink
