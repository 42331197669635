import styled from "@emotion/styled"

export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0.625rem;
  background-color: ${(p) => p.theme.colors.pampasBackgroundColor};
  padding: 1.875rem;
  min-height: 14.375rem;
`

export const StyledTitle = styled.h4`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.regular};
  font-size: 1.25rem;
  color: ${(p) => p.theme.colors.racingGreen};
  text-align: center;
  padding-bottom: 0.875rem;
`
export const StyledDescription = styled.span`
  font-size: 0.875rem;
  line-height: 160.7%;
  text-align: center;
  color: ${(p) => p.theme.colors.doveGray};
  max-width: 20rem;

  a {
    color: ${(p) => p.theme.colors.pacificBlue};
  }
`
