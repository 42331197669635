import styled from "@emotion/styled"
import { css } from "@emotion/react"
import {
  StyledCard as CommonStyledCard,
  StyledCardInfo as CommonStyledCardInfo,
  StyledCardTitle as CommonStyledCardTitle,
  StyledCardDiscount as CommonStyledCardDiscount,
  StyledCardDescription as CommonStyledCardDescription,
  StyledPartnerLogo as CommonStyledPartnerLogo,
  StyledPartnerTitleText as CommonStyledPartnerTitleText,
  StyledPartnerTitle as CommonStyledPartnerTitle,
} from "../index.styles"

export const StyledStoreCard = styled(CommonStyledCard)`
  position: relative;
  ${(p) =>
    p.isComingSoon &&
    css`
      pointer-events: none;
    `}
  ${(p) =>
    p.bgImageUrl &&
    css`
      background: url(${p.bgImageUrl});
      background-repeat: no-repeat;
      background-size: 8.1875rem auto;
      background-color: white;
      background-position-y: center;
    `};

  ${(p) =>
    p.size === "list"
      ? css`
          width: 100%;
          height: 9.625rem;
          grid-template-areas: "image info" "store info";
          grid-template-columns: 7.1875rem auto;
          grid-template-rows: 4.0625rem auto;
          grid-column-gap: 0.75rem;
        `
      : p.size === "tile"
      ? css``
      : css`
          //mainpage default view
        `}

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    background-size: 10.875rem auto;
    ${(p) =>
      p.size === "list"
        ? css`
            grid-template-areas: "image info" "store info";
            grid-template-rows: 5.3125rem auto;
          `
        : p.size === "tile"
        ? css`
            padding: 0;
            width: 10.625rem;
            height: 18.5625rem;
            grid-template-areas: unset;
            grid-template-rows: 5.9375rem auto 4.375rem;
            grid-template-columns: 1fr;
            background-size: auto;
            background-position: center;
          `
        : css`
            //mainpage default view
          `}
    ${(p) =>
      p.bigTablet &&
      css`
        grid-template-areas: unset;
        width: 14.25rem;
        height: 22.5rem;
        grid-template-rows: 17.5rem 5rem;
      `}
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    grid-template-areas: unset;
    ${(p) =>
      p.size === "list"
        ? css`
            //Pac offers page wide list view
          `
        : p.size === "tile"
        ? css`
            width: 14.25rem;
            height: 22.5rem;
            grid-template-rows: 17.5rem 5rem;
          `
        : css`
            //mainpage default wide view
          `}
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    ${(p) =>
      p.size === "list"
        ? css`
            //Pac offers page wide list view
          `
        : p.size === "tile"
        ? css`
            width: 25.875rem;
            height: 38.3125rem;
            grid-template-rows: 29.0625rem 9.25rem;
          `
        : css`
            //mainpage default wide view
          `}
  }
`

export const StyledCardInfo = styled(CommonStyledCardInfo)``

export const StyledCardTitle = styled(CommonStyledCardTitle)``

export const StyledCardDiscount = styled(CommonStyledCardDiscount)`
  span {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.carrot};
  }
`

export const StyledCardDescription = styled(CommonStyledCardDescription)``

export const StyledStoreLogo = styled.div`
  grid-row: 1 / span 2;
  .media-box {
    padding-right: 1rem;
    overflow-y: hidden;
    img {
      width: 100;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    ${(p) =>
      p.size === "tile" &&
      css`
        grid-row: unset;
        .media-box img {
          bottom: initial;
          top: 1rem;
          left: 1rem;
        }
      `}
    ${(p) =>
      p.bigTablet &&
      css`
        grid-row: unset;
        .media-box {
          padding: 0;
        }
      `}
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    grid-row: unset;
    .media-box {
      padding: 0;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
  }
`
export const StyledOverlayWithBadge = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 8.1875rem;
  background: linear-gradient(
    0deg,
    rgba(122, 119, 118, 0.7),
    rgba(122, 119, 118, 0.7)
  );
  img {
    position: absolute;
    top: 0.5rem;
    left: 3.6875rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    width: 10.875rem;
    border-radius: 0.25rem 0 0 0.25rem;
    img {
      top: 0.75rem;
      left: 5.625rem;
    }
    ${(p) =>
      p.bigTablet &&
      css`
        width: auto;
        border-radius: 0.25rem;
        right: 0;
        img {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      `}
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    width: auto;
    border-radius: 0.25rem;
    right: 0;
    img {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
  }
`
export const StyledStoreDiscountBanner = styled.div`
  p {
    background: ${(p) => p.theme.colors.white};
    border-radius: 0 0.5rem 0.5rem 0;
    // height: 2.625rem;
    width: fit-content;
    padding-right: 1rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    ${(p) =>
      p.size === "tile" &&
      css`
        grid-column: 1;
        grid-row: 4;
        padding-top: 2.8125rem;
      `}
    ${(p) =>
      p.bigTablet &&
      css`
        padding-top: 0;
        grid-column: initial;
        grid-row: initial;
      `}
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    padding-top: 0;
    grid-column: initial;
    grid-row: initial;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    p {
      height: 4.0625rem;
    }
  }
`
export const StyledPartnerLogo = styled(CommonStyledPartnerLogo)``

export const StyledPartnerTitleText = styled(CommonStyledPartnerTitleText)``

export const StyledOfferStore = styled(CommonStyledPartnerTitle)`
  grid-area: store;
  justify-self: auto;
  display: block;
  margin-top: 0.5rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 0.5rem 0 0;
    display: flex;
    margin: 0;
    ${(p) =>
      p.size === "tile" &&
      css`
        padding: 0 0.75rem;
      `}
    ${(p) =>
      p.bigTablet &&
      css`
        grid-area: unset;
        padding: 0 0.75rem 0.75rem;
        ${(p) =>
          p.isComingSoon &&
          css`
            margin-top: 2.2rem;
          `}
      `}
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    grid-area: unset;
    padding: 0 0.75rem 0.75rem;
    ${(p) =>
      p.isComingSoon &&
      css`
        margin-top: 2.2rem;
      `}
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding: 0 1rem 1rem;
    p span {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }

    ${(p) =>
      p.isComingSoon &&
      css`
        margin-top: 4rem;
      `}
  }
`
