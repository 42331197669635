const useTrackCustomEvent = () => {
  const trackEvent = (event, category, action, label) => {
    if (event && typeof event !== "undefined") {
      event.preventDefault()
    }

    if (typeof window.gtag !== "undefined")
      window.gtag("event", "click", {
        event_category: category,
        event_action: action,
        event_label: label,
      })
  }
  return { trackEvent }
}

export default useTrackCustomEvent
