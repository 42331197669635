import React from "react"
import PropTypes from "prop-types"
import { Button } from "@tmu/components/common"
import { navigate } from "gatsby-plugin-intl"
import { StyledNotFoundPage } from "@tmu/global/page-addons/flat-page.styles"

import {
  StyledButtonContainer,
  StyledDescription,
  StyledTitle,
} from "./index.styles"

const Callback = ({
  onButtonClick,
  clickUrl,
  title,
  description,
  buttonLabel,
  dataTestId = "",
}) => {
  const onClick = () => {
    if (clickUrl) {
      navigate(clickUrl)
    } else if (onButtonClick && typeof onButtonClick === "function") {
      onButtonClick()
    }
  }

  return (
    <StyledNotFoundPage
      style={{ textAlign: "center" }}
      data-testid={dataTestId}>
      {title && <StyledTitle>{title}</StyledTitle>}
      {description && <StyledDescription>{description}</StyledDescription>}
      {(clickUrl || onButtonClick) && (
        <StyledButtonContainer className="sticky-bottom-bar">
          <Button label={title} color="red" onClick={onClick}>
            {buttonLabel}
          </Button>
        </StyledButtonContainer>
      )}
    </StyledNotFoundPage>
  )
}

Callback.propTypes = {
  onButtonClick: PropTypes.func,
  clickUrl: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
}

Callback.defaultProps = {
  title: "",
  description: "",
  buttonLabel: "",
}

export default Callback
