import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import {
  StyledQRButton,
  StyledModalContent,
  //StyledName,
  StyledActionButtons,
} from "./index.styles"
import { faQrcode } from "@fortawesome/free-solid-svg-icons/faQrcode"
import { faDownload } from "@fortawesome/pro-solid-svg-icons/faDownload"
import { faPrint } from "@fortawesome/pro-solid-svg-icons/faPrint"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CustomModal, Spinner, Button } from "@tmu/components/common"
import { QR_CODE_FOLDERS } from "@tmu/apollo/constants"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"

const QRModal = ({
  slug,
  name,
  discount = 0,
  isMerchantCampaign,
  storeLogo,
  partnerLogo,
}) => {
  const { isTablet, isWide } = getAllScreenTypes()

  const slug100 = slug.slice(0, 100)
  const name30 = name.slice(0, 30)
  const { formatMessage } = useIntl()
  const [qrCodeId, setQRCodeId] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [qrCodeUrl, setQRCodeUrl] = useState("")
  const [modalStatus, setModalStatus] = useState(false)
  const isStore = window.location.href.includes("/store/")
  const isService = window.location.href.includes("/services/")
  const isCampaign = window.location.href.includes("/campaigns/")
  const folder = isStore
    ? QR_CODE_FOLDERS.STORES
    : window.location.href.includes("charities")
    ? QR_CODE_FOLDERS.CHARITIES
    : QR_CODE_FOLDERS.CAMPAIGNS

  const isMobile = !isTablet

  useEffect(() => {
    if (!modalStatus || qrCodeUrl) return
    const getPageQRCode = async () => {
      try {
        setIsLoading(true)
        const response = await fetch(
          process.env.QRFY_ENDPOINT + `api/public/qrs?searchTerm=${slug100}`,
          {
            headers: {
              "API-KEY": process.env.QRFY_API_KEY,
            },
          }
        )
        const data = await response.json()
        if (data?.[0]) setQRCodeId(data[0].id)
        else {
          createQRCode()
        }
        setIsLoading(false)
      } catch (error) {
        console.error(error)
        setIsLoading(false)
      }
    }
    getPageQRCode()
  }, [slug100, modalStatus])

  const qrStyle = {
    shape: {
      backgroundColor: "#ffffff",
      color: "#000000",
      style: "dots",
    },
    corners: {
      dotColor: "#000000",
      squareColor: isStore || isService || isCampaign ? "#e25f4a" : "#0a9dbd",
      squareStyle: "extra-rounded",
      dotStyle: "default",
    },
    image:
      isStore || isService || isCampaign
        ? "71f2db43-754c-4dc2-ae08-056a0b33f95a.webp"
        : "974675a0-53b6-4f3a-870f-498ae52032a3.webp",
  }

  const qrStyleWithFrame = {
    ...qrStyle,
    frame: {
      id: 6,
      color: isStore || isService ? "#e25f4a" : "#0a9dbd",
      text:
        isStore || isService
          ? formatMessage({
              id: "qr::modal::donateByTrustMeUp",
              defaultMessage: "Donate by Shopping",
            })
          : formatMessage({
              id: "qr::modal::donateOnTrustMeUp",
              defaultMessage: "Donate on TrustMeUp",
            }),
      fontSize: 50,
      backgroundColor: {
        type: "linear",
        rotation: 0,
        colorStops: [
          {
            offset: 0,
            color: "#ffffff",
          },
          {
            offset: 1,
            color: "#ffffff",
          },
        ],
      },
      textColor: "#ffffff",
    },
  }

  const createQRCode = async () => {
    if (!modalStatus || qrCodeUrl) return
    try {
      setIsLoading(true)
      const response = await fetch(
        process.env.QRFY_ENDPOINT + "api/public/qrs",
        {
          method: "POST",
          headers: {
            "API-KEY": process.env.QRFY_API_KEY,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            qrs: [
              {
                name: slug100,
                folder,
                type: "url",
                data: {
                  url: window.location.href,
                },
                style: isMerchantCampaign ? qrStyle : qrStyleWithFrame,
              },
            ],
          }),
        }
      )
      const data = await response.json()
      if (data.ids?.length) setQRCodeId(data.ids?.[0])
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (qrCodeId) {
      getQRCodeImage()
    }
  }, [qrCodeId])

  const getQRCodeImage = async () => {
    const qrCodeEndpoint =
      process.env.QRFY_ENDPOINT + `api/public/qrs/${qrCodeId}/png`
    const requestData = {
      headers: {
        "API-KEY": process.env.QRFY_API_KEY,
      },
    }
    try {
      const response = await fetch(qrCodeEndpoint, requestData)
      const qrCodeBlob = await response.blob()
      const qrCodeImageUrl = URL.createObjectURL(qrCodeBlob)
      setQRCodeUrl(qrCodeImageUrl)
    } catch (error) {
      console.error("Error:", error)
    }
  }

  const closeModal = () => {
    setModalStatus(false)
  }

  const handleClick = () => {
    setModalStatus(!modalStatus)
  }

  const style = {
    padding: isWide
      ? "3rem"
      : isTablet
      ? "2rem 2rem 2rem"
      : "0 1rem 1.5rem 1rem",
  }

  const downloadImage = () => {
    const link = document.createElement("a")
    link.href = qrCodeUrl
    link.download = "image.png"
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const printImage = () => {
    const description = isMerchantCampaign
      ? formatMessage(
          {
            id: "qr::modal::storeCampaignDescription",
            defaultMessage:
              "Donate to {campaign} up to {maxPacDiscount}% discount on your purchase",
          },
          {
            campaign: name,
            maxPacDiscount: discount,
          }
        )
      : ""
    // : formatMessage(
    //     {
    //       id: "qr::modal::donateTo",
    //       defaultMessage: "Donate to {campaignName}",
    //     },
    //     {
    //       campaignName: name,
    //     }
    //   )
    const img = document.createElement("img")
    img.src = qrCodeUrl
    img.onload = () => {
      const printWindow = window.open(
        "",
        "Print Window",
        "height=600,width=800"
      )
      printWindow.document.write(
        `<html>
        <head>
          <style>
            @media print {
              @page {
                margin: 0;
              }
              body {
                margin: 0;
              }
              .container {
                position:absolute;
                top:50%;
                left:50%;
                transform: translate(-50%, -50%);
              }
              .image-container {
                max-width: 19rem;
                max-height: 100%;
              }
              .description {
                max-width: 100%;
                max-height: 100%;
                font-family: "Open Sans Bold, sans-serif";
                font-style: normal;
                font-weight: 700; 
                font-size: 1.25rem;
                line-height: 1.5;
                text-align: center;
              }
              .logo-container {
                display: flex;
                justify-content: space-around;
                align-items: center;
              }
              .logo {
                max-width: 7.5rem;
                max-height: 6.25rem;
                width: auto;
                height: auto;
              }
            }
          </style>
        </head>
        <body>
          <div class="container">
            <div class="image-container">
              <img src="${qrCodeUrl}" style="max-width:19rem"/>
              <div class="description"> 
                <p>
                  ${description}
                </p>
              </div>
              <div class="logo-container"> 
              ${
                isMerchantCampaign && storeLogo
                  ? `<img src="${storeLogo}" class="logo" />`
                  : ``
              } 
              ${
                isMerchantCampaign && partnerLogo
                  ? `<img src="${partnerLogo}" class="logo" />`
                  : ``
              } 
              </div>
            </div>
          </div>
        </body>
      </html>
      `
      )
      printWindow.document.close()
      printWindow.focus()
      printWindow.print()
      //printWindow.close();
    }
  }

  return (
    <StyledQRButton>
      <FontAwesomeIcon icon={faQrcode} onClick={handleClick} />

      {modalStatus ? (
        <CustomModal
          isModalOpen={modalStatus}
          style={style}
          header={formatMessage({
            id: "qr::modal::title",
            defaultMessage: "QR Code",
          })}
          headerStyle={{
            marginBottom: isWide ? "1rem" : "0",
            paddingTop: isTablet ? "0" : "2rem",
          }}
          descriptionStyle={{ marginBottom: isWide ? "2.5rem" : "1.5rem" }}
          isCloseIcon={true}
          cancelButtonAction={closeModal}
          isMobile={isMobile}>
          {isLoading || !qrCodeUrl ? (
            <Spinner condensed />
          ) : (
            <StyledModalContent isStore={isStore}>
              {qrCodeId && qrCodeUrl ? (
                <>
                  <img width={200} src={qrCodeUrl} alt="QR Code" />
                  <br />
                  {/* <StyledName isStore={isStore}>
                    {isStore
                      ? name
                      : formatMessage(
                          {
                            id: "qr::modal::donateTo",
                            defaultMessage: "Donate to {campaignName}",
                          },
                          {
                            campaignName: name,
                          }
                        )}
                  </StyledName> */}
                  <StyledActionButtons isStore={isStore}>
                    <Button
                      data-testid="btn-qr-print"
                      type="button"
                      color="transparent"
                      onClick={printImage}>
                      <FontAwesomeIcon icon={faPrint} />
                      <FormattedMessage
                        id="qr::modal::print"
                        defaultMessage="Print"
                      />
                    </Button>
                    <Button
                      data-testid="btn-qr-download"
                      type="button"
                      color={
                        isStore || isService || isCampaign ? "carrot" : "blue"
                      }
                      onClick={downloadImage}>
                      <FontAwesomeIcon icon={faDownload} />
                      <FormattedMessage
                        id="qr::modal::download"
                        defaultMessage="Download"
                      />
                    </Button>
                  </StyledActionButtons>
                </>
              ) : (
                formatMessage({
                  id: "qr::modal::notAvailable",
                  defaultMessage: "Not available",
                })
              )}
            </StyledModalContent>
          )}
        </CustomModal>
      ) : null}
    </StyledQRButton>
  )
}

export default QRModal

QRModal.propTypes = {
  handleSelect: PropTypes.func,
  isOfferList: PropTypes.bool,
}
