import React from "react"
import { Router } from "@reach/router"
import { injectIntl } from "gatsby-plugin-intl"
import StaticPage from "@tmu/components/StaticPage"

const Terms = () => (
  <Router basepath="/:locale/terms">
    <StaticPage path="/" default slug="associations" />
    <StaticPage path="/:slug/:pageId" />
  </Router>
)

export default injectIntl(Terms)
