import React from "react"
import { navigate } from "gatsby-plugin-intl"
import { MediaSlider } from "@tmu/components/common/"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import {
  StyledWSMainContainer,
  StyledWSLeftContainer,
  StyledWSTitle,
  StyledWSButton,
  StyledWSButtonAwesomeIcon,
  StyledWSButtonIcon,
  StyledWSRightContainer,
} from "./index.styles"

const WelcomeSlice = ({
  title,
  content,
  buttonIcon,
  buttonLabel,
  buttonLink,
  images,
  imageSize,
  className = "",
}) => {
  const { isTablet } = getAllScreenTypes()

  const handleClick = () => {
    navigate(buttonLink)
  }

  return (
    <StyledWSMainContainer className={className}>
      <StyledWSRightContainer>
        <MediaSlider
          className="welcome-slider"
          images={images}
          {...imageSize}
          altName={`ws-${images}`}
        />
      </StyledWSRightContainer>
      <StyledWSLeftContainer>
        <StyledWSTitle>{title}</StyledWSTitle>
        {content}
        {isTablet && buttonLabel && buttonLink && (
          <StyledWSButton
            data-testid={`btn-${buttonLabel}`}
            label={buttonLabel}
            color={"blue"}
            onClick={handleClick}>
            {buttonIcon?.iconName !== undefined ? (
              <StyledWSButtonAwesomeIcon icon={buttonIcon} />
            ) : (
              <StyledWSButtonIcon className={buttonIcon} />
            )}
            {buttonLabel}
          </StyledWSButton>
        )}
      </StyledWSLeftContainer>
    </StyledWSMainContainer>
  )
}

export default WelcomeSlice
