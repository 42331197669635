import React from "react"
import { navigate } from "gatsby-plugin-intl"
import { useAuth } from "@tmu/hooks"
import {
  StyledAccordionSubElement,
  StyledNavBarButton,
  StyledAccordion,
} from "../index.styles"

const LanguageAccordion = ({ sections, onClose }) => {
  const { updateCommunicationLanguage } = useAuth()

  const handleClickLanguageAccordionButton = (key) => {
    onClose()
    if (key.id.includes("subtitle-language")) {
      updateCommunicationLanguage({
        variables: {
          language: key.language,
        },
      })
      return
    }

    navigate(key.link)
  }

  return (
    <StyledAccordion multiple={true} dataTestId="language-accordion-navbar">
      {Object.entries(sections).map((section, index) => {
        return (
          <div
            label={
              <StyledNavBarButton variant="text">
                {section[1][0]}
              </StyledNavBarButton>
            }
            key={index}>
            {section[1][1].map((key) => (
              <StyledNavBarButton
                key={key.id}
                variant="text"
                align="left"
                onClick={() => handleClickLanguageAccordionButton(key)}>
                <StyledAccordionSubElement data-testid="language-accordion-navbar-subelement">
                  {key.subTitle}
                </StyledAccordionSubElement>
              </StyledNavBarButton>
            ))}
          </div>
        )
      })}
    </StyledAccordion>
  )
}

export default LanguageAccordion
