import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import { CalculateDiscount } from "@tmu/components/common"
import { getQueryParams } from "@tmu/utils/string"
import { useFooterType } from "@tmu/hooks"
import { FOOTER_TYPE } from "@tmu/apollo/constants"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  StyledBackButton,
  StyledContent,
  StyledPageContainer,
} from "@tmu/global/page-addons/calculate-page.styles"

const Calculate = () => {
  const params = getQueryParams()
  useFooterType({ footerType: FOOTER_TYPE.HIDE })

  return (
    <>
      <StyledPageContainer>
        <StyledContent>
          <StyledBackButton
            onClick={() => {
              history.back()
            }}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </StyledBackButton>
          <CalculateDiscount slug={params?.slug} />
        </StyledContent>
      </StyledPageContainer>
    </>
  )
}

export default injectIntl(Calculate)
