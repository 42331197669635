import React, { useState } from "react"
import PropTypes from "prop-types"
import Autosuggest from "react-autosuggest"
import { faCheck } from "@fortawesome/pro-light-svg-icons/faCheck"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { TextInput } from "@tmu/components/common"
import { escapeRegexCharacters } from "@tmu/utils/string"
import { StyledLabel, StyledInput, StyledCheckmark } from "./index.styles"

const theme = {
  input: {
    width: "100%",
  },

  sectionTitle: {
    fontSize: "1.125rem",
    padding: "1.5625rem 0 1.5625rem 1.875rem",
    color: "rgba(74, 74, 74, 0.5)",
  },

  suggestionsContainer: {
    borderBottom: "0.0625rem solid white",
    borderRight: "0.0625rem solid white",
    borderLeft: "0.0625rem solid white",
    background: `${(p) => p.theme?.colors.white}`,
    top: "-5rem",
    maxWidth: "64.75rem",
    width: "100%",
    maxHeight: "37.5rem",
    height: "100%",
    overflowY: "scroll",
    display: "none",
  },

  suggestionsList: {
    display: "flex",
    flexDirection: "column",
    alignItems: "self-start",
    fontSize: "1.125rem",
    padding: "0 1.875rem",
  },

  suggestion: {
    marginBottom: "1.5rem",
  },
}

const TypeaheadSelect = ({
  items,
  selected,
  onSelectionChange,
  placeholder,
  ...rest
}) => {
  const [value, setValue] = useState("")
  const [suggestions, setSuggestions] = useState(items)

  const getSuggestions = (value) => {
    const escapedValue = escapeRegexCharacters(value.trim())
    if (escapedValue === "") {
      return items
    }
    const regex = new RegExp(escapedValue, "i")
    return items.filter((item) => regex.test(item.label))
  }

  const getSuggestionValue = (suggestion) => {
    return suggestion.label
  }

  const renderSuggestion = ({ label, id }) => {
    return (
      <StyledLabel key={id}>
        {label}
        <StyledInput
          type="checkbox"
          checked={selected.indexOf(id) > -1}
          defaultValue={id}
          onChange={onChange}
        />
        <StyledCheckmark>
          <FontAwesomeIcon icon={faCheck} />
        </StyledCheckmark>
      </StyledLabel>
    )
  }

  const onChange = (event, { newValue, method }) => {
    setValue(newValue)
  }

  const onSuggestionsUpdateRequested = ({ value }) => {
    setSuggestions(getSuggestions(value))
  }

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value))
  }

  const onSuggestionsClearRequested = () => {
    setSuggestions(items)
  }

  const renderInputComponent = (inputProps) => (
    <TextInput
      id="search-associations"
      label={inputProps.placeholder}
      className="float-container"
      onBlur={inputProps.onChange}
      {...rest}
      {...inputProps}
    />
  )

  const inputProps = {
    placeholder,
    value: value || "",
    onChange,
  }

  const currentItems = value && value.length > 0 ? suggestions : items

  return (
    <>
      <Autosuggest
        theme={theme}
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsUpdateRequested={onSuggestionsUpdateRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        renderInputComponent={renderInputComponent}
      />
      <fieldset>
        {currentItems.map(({ id, label }) => (
          <StyledLabel key={id}>
            {label}
            <StyledInput
              type="checkbox"
              checked={selected.indexOf(id) > -1}
              defaultValue={id}
              onChange={onSelectionChange}
            />
            <StyledCheckmark>
              <FontAwesomeIcon icon={faCheck} />
            </StyledCheckmark>
          </StyledLabel>
        ))}
      </fieldset>
    </>
  )
}

TypeaheadSelect.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  selected: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
}

TypeaheadSelect.defaultProps = {
  selected: [],
  onChange: () => {},
}

export default TypeaheadSelect
