import React from "react"
import {
  AuthProvider as ApiAuthProvider,
  ToastProvider,
  ShoppingCartProvider as ApiShoppingCartProvider,
} from "@tmu/hooks"
import { HomePageContextProvider } from "@tmu/context/homePageContext"

import { ApolloClientProvider as ApiClientProvider } from "@tmu/apollo/client"
import { StorefrontLayout as Layout } from "@tmu/layouts"

export const wrapRootElement = ({ element }) => {
  return (
    <ToastProvider>
      <ApiClientProvider>
        <ApiAuthProvider>
          <HomePageContextProvider>
            <ApiShoppingCartProvider>{element}</ApiShoppingCartProvider>
          </HomePageContextProvider>
        </ApiAuthProvider>
      </ApiClientProvider>
    </ToastProvider>
  )
}

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}
