import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons/faArrowLeft"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CampaignTypeCard } from "@tmu/components/dashboard/dashboardCommon"
import { FormattedMessage, navigate, useIntl } from "gatsby-plugin-intl"
import {
  StyledBackButton,
  StyledCampaignCardTypes,
  StyledCampaignTypesPageTitle,
  StyledCampaignTypesWrapper,
} from "./index.styles"

const OfferTypes = () => {
  const { formatMessage } = useIntl()

  const handleCardClick = (type) => () => {
    navigate(
      `/dashboard/merchants/services/new?isVoucher=${type === "voucher"}`
    )
  }

  const chooseText = formatMessage({
    id: "card::charity::choose",
    defaultMessage: "Choose",
  })

  return (
    <StyledCampaignTypesWrapper>
      <StyledCampaignTypesPageTitle>
        <StyledBackButton to={"/dashboard/merchants/services"}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </StyledBackButton>
        <FormattedMessage
          id="dashboard::offer::chooseTypeTitle"
          defaultMessage="Choose a type of sale"
          tagName="h1"
        />
      </StyledCampaignTypesPageTitle>
      <StyledCampaignCardTypes>
        <CampaignTypeCard
          type={
            <FormattedMessage
              id="dashboard::offer::service"
              defaultMessage="Service"
            />
          }
          content={
            <FormattedMessage
              id="dashboard::offer::offerText"
              defaultMessage="Choose service, if you are providing a service, consultancy, gym subscription or medical care services"
            />
          }
          buttonText={chooseText}
          buttonColor={"carrot"}
          buttonAction={handleCardClick("offer")}
        />
        <CampaignTypeCard
          type={
            <FormattedMessage
              id="dashboard::offer::voucher"
              defaultMessage="Voucher"
            />
          }
          content={
            <FormattedMessage
              id="dashboard::offer::voucherText"
              defaultMessage="Choose voucher, so your customer will be able to recieve your service/product in person within expiration dates that you provide"
            />
          }
          buttonText={chooseText}
          buttonColor={"carrot"}
          buttonAction={handleCardClick("voucher")}
        />
      </StyledCampaignCardTypes>
    </StyledCampaignTypesWrapper>
  )
}

export default OfferTypes
