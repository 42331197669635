import { gql } from "@apollo/client"

export const CANCEL_DONATION_QUERY = gql`
  mutation ($input: CancelDonationMutationInput!) {
    cancelDonation(input: $input) {
      success
      errors {
        field
        messages
      }
    }
  }
`

export const DONATION_PROVISION_MUTATION = gql`
  mutation ($input: DonationProvisionMutationInput!) {
    createDonationProvision(input: $input) {
      donationProvision {
        id
        amount
        currency
        quantity
        expectedAmount
        expectedQuantity
        tier {
          id
          maxTickets
          isUnlimitedTickets
          isSoldOut
          campaign {
            id
            name
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }
`
