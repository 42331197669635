import React from "react"
import {
  StyledOpenAccountCard,
  StyledTitleWrapper,
  StyledTitle,
  StyledSubTitle,
  StyledDescription,
  StyledButton,
} from "./index.styles"
import { isBrowser } from "@tmu/utils/auth"

const OpenAccountCard = ({ title, subTitle, desc, buttonText, url }) => {
  const handleRegisterDigitalBanking = (url) => {
    isBrowser && url && window.open(url)
  }
  
  return (
    <StyledOpenAccountCard>
      <StyledTitleWrapper>
        {title && <StyledTitle>{title}</StyledTitle>}
        {subTitle && <StyledSubTitle>{subTitle}</StyledSubTitle>}
      </StyledTitleWrapper>
      {desc && <StyledDescription>{desc}</StyledDescription>}
      {buttonText && (
        <StyledButton
          color="red"
          onClick={() => handleRegisterDigitalBanking(url)}>
          {buttonText}
        </StyledButton>
      )}
    </StyledOpenAccountCard>
  )
}

export default OpenAccountCard
