import React from "react"
import { Button, Dropdown } from "@tmu/components/common"
import {
  ShowAllButton,
  StyledDropDownWrapper,
  StyledListingFilterWrapper,
  StyledOption,
  StyledOptionIcon,
  StyledOptionLabel,
} from "./index.styles"
import { getValueForLocale } from "@tmu/utils/string"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { components } from "react-select"

const CategoryList = ({
  categories,
  selected = [],
  onSelect,
  onSingleCategorySelect,
  handleShowAll,
  isDropdown,
  ...rest
}) => {
  const { locale, defaultLocale, formatMessage } = useIntl()
  if (!categories) {
    return <></>
  }

  if (isDropdown) {
    const { isWide } = getAllScreenTypes()

    const categoryOptions = categories?.map((item, index) => ({
      ...item,
      value: item?.id,
      label: getValueForLocale(item, "name", locale, defaultLocale),
    }))

    const showAllText = formatMessage({
      id: "campaignListing::filters::showall",
      defaultMessage: "Show all",
    })

    categoryOptions.unshift({
      name: showAllText,
      value: -1,
      label: showAllText,
    })

    let selectedItem = null
    if (selected?.length > 0) {
      const category = selected?.at(0)
      selectedItem = categoryOptions?.filter((t) => t?.slug === category)?.at(0)
    }

    const dropdownHeight = isWide ? "3.375rem" : "2.5rem"

    const selectStyles = {
      control: {
        height: dropdownHeight,
        paddingTop: "0",
        paddingRight: "0",
        fontSize: isWide ? "1rem" : "0.75rem",
        textAlign: "left",
        minWidth: "unset",
      },
      valueContainer: {
        height: dropdownHeight,
        paddingTop: "0",
      },
      inputContainer: {
        height: dropdownHeight,
        paddingTop: "0",
      },
      input: {
        padding: "0",
        margin: "0",
      },
    }

    const Option = ({ children, ...props }) => {
      const optionData = props?.data
      return (
        <components.Option {...props}>
          <StyledOption>
            <StyledOptionIcon>
              {optionData?.icon && <i className={optionData?.icon} />}
            </StyledOptionIcon>
            <StyledOptionLabel>
              {getValueForLocale(optionData, "name", locale, defaultLocale)}
            </StyledOptionLabel>
          </StyledOption>
        </components.Option>
      )
    }

    return (
      <StyledDropDownWrapper data-testid="explore-campaigns__category-list-select">
        <Dropdown
          components={{
            Option,
          }}
          placeholder={formatMessage({
            id: "categoryList::placeholder",
            defaultMessage: "Choose Category",
          })}
          newDesign
          data-testid="category"
          id="category"
          name="category"
          defaultValue={selectedItem}
          value={selectedItem}
          options={categoryOptions}
          defaultSelectStyles={selectStyles}
          onChange={(select) => {
            const value = select?.value
            if (value === -1) {
              handleShowAll()
            } else if (typeof onSingleCategorySelect === "function") {
              onSingleCategorySelect(select?.id, select?.slug)
            } else if (typeof onSelect === "function") {
              onSelect(select?.id, select?.slug)
            }
          }}
        />
      </StyledDropDownWrapper>
    )
  }

  return (
    <>
      <StyledListingFilterWrapper
        data-testid="explore-campaigns__category-list"
        className="category-filters">
        {rest?.hideShowAll !== true && (
          <>
            <ShowAllButton color="black" onClick={handleShowAll} variant="text">
              <FormattedMessage
                id="campaignListing::filters::showall"
                defaultMessage="Show all"
              />
              {selected.length ? null : <FontAwesomeIcon icon={faCheck} />}
            </ShowAllButton>
            <hr />
          </>
        )}
        {categories?.map((category) => {
          const { id, icon, slug } = category
          return (
            <div key={`${id}-${slug}`} {...rest}>
              <Button
                label="categoryList"
                color="blue"
                variant="pill"
                onClick={() => onSelect(id, slug)}
                data-id={id}
                className={
                  selected.findIndex((item) => item === slug || item === id) >
                  -1
                    ? "active"
                    : ""
                }>
                <span>
                  {icon && <i className={icon} />}
                  {getValueForLocale(category, "name", locale, defaultLocale)}
                </span>
                {selected.findIndex((item) => item === slug || item === id) >
                  -1 && <FontAwesomeIcon icon={faCheck} />}
              </Button>
              <hr />
            </div>
          )
        })}
      </StyledListingFilterWrapper>
    </>
  )
}

export default CategoryList
