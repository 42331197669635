import React, { useEffect, useRef } from "react"
import { useLocation } from "@reach/router"
import { FormattedMessage } from "gatsby-plugin-intl"
import Card from "./Card/index"
import { isInViewport } from "@tmu/utils/viewport"
import {
  StyledHowItWorks,
  StyledHowItWorksTitle,
  StyledHowItWorksDescription,
  StyledHowItWorksCards,
  StyledHowItWorksDownloadTitle,
  StyledStores,
  StyledStoreButton,
  StyledRegisterButton,
  StyledImg,
} from "./index.styles"
import { isBrowser } from "@tmu/utils/auth"
const { CLOUDFLARE_IMAGE_URL } = process.env

const HowItWorks = ({
  id,
  title,
  description,
  cards,
  downloadTitle,
  register = false,
  appleStoreUrl,
  playStoreUrl,
  image,
}) => {
  const ref = useRef()
  const { hash } = useLocation()

  const hashCallback = () => {
    const hashText =
      id === "lets-get-started" ? "#lets-get-started" : `#how-it-works`
    const isHashInUrl = hash === hashText || window?.location?.hash === hashText
    if (isInViewport(ref) && !isHashInUrl) {
      history.pushState(null, null, hashText)
    }
  }

  useEffect(() => {
    document.addEventListener("scroll", hashCallback)
    return () => document.removeEventListener("scroll", hashCallback)
  }, [])
  const handleRegisterDigitalBanking = (url) => {
    isBrowser && url && window.open(url)
  }

  return (
    <StyledHowItWorks id={id} ref={ref}>
      {title && <StyledHowItWorksTitle>{title}</StyledHowItWorksTitle>}
      {description && (
        <StyledHowItWorksDescription>{description}</StyledHowItWorksDescription>
      )}
      {cards && (
        <StyledHowItWorksCards>
          {cards.map((e, i) => (
            <Card key={i} title={e.title} description={e.desc} />
          ))}
        </StyledHowItWorksCards>
      )}
      {downloadTitle && (
        <StyledHowItWorksDownloadTitle>
          {downloadTitle}
        </StyledHowItWorksDownloadTitle>
      )}
      {register && (
        <StyledRegisterButton
          color="red"
          onClick={() => handleRegisterDigitalBanking(register)}>
          <FormattedMessage
            id="digitalBanking::business::howitworks::register"
            defaultMessage="Register Now"
          />
        </StyledRegisterButton>
      )}
      <StyledStores>
        {appleStoreUrl && (
          <StyledStoreButton
            color="white"
            onClick={() => handleRegisterDigitalBanking(appleStoreUrl)}>
            <img
              src={
                CLOUDFLARE_IMAGE_URL +
                "/static/assets/images/svg/appleStore.svg"
              }
              alt="appleStore"
            />
          </StyledStoreButton>
        )}
        {playStoreUrl && (
          <StyledStoreButton
            color="white"
            onClick={() => handleRegisterDigitalBanking(playStoreUrl)}>
            <img
              src={
                CLOUDFLARE_IMAGE_URL + "/static/assets/images/svg/playStore.svg"
              }
              alt="playStore"
            />
          </StyledStoreButton>
        )}
      </StyledStores>
      {image && (
        <StyledImg src={image} alt="digital-banking-business-howit-works" />
      )}
    </StyledHowItWorks>
  )
}

export default HowItWorks
