import styled from "@emotion/styled"

export const StyledDatePicker = styled.label`
  position: relative;
  display: grid;
  input {
    width: 100%;
    max-width: 15.625rem;
    height: 3rem;
    padding: 0.875rem 0.8125rem 0.875rem 0.75rem;
    font-size: 1rem;
    border: 0.0625rem solid
      ${(p) => p.theme.colors[p.error ? "red" : "disabled"]};
    outline: 0;
    border-radius: 0.25rem;
    &:focus-within,
    &:hover {
      border: 0.0625rem solid
        ${(p) => p.theme.colors[!p.disabled ? "blue" : "disabled"]};
    }
  }

  svg {
    position: absolute;
    width: 1.25rem;
    height: 1.375rem;
    right: 0.8125rem;
    top: 0.5rem;
    color: ${(p) =>
      p.theme.colors[p.disabled ? "disabled" : p.error ? "red" : "disabled"]};
    pointer-events: none;
  }

  &:focus-within svg,
  &:hover svg {
    color: ${(p) => p.theme.colors[!p.disabled ? "blue" : "disabled"]};
  }
`

export const DatePickerError = styled.div`
  position: absolute;
  margin: 0.3rem 0 0;
  color: ${(p) => p.theme.colors.red};
  font-size: 0.75rem;
  width: max-content;
`
