import React, { useEffect, useState } from "react"
import { FormattedMessage, injectIntl, navigate } from "gatsby-plugin-intl"
import { useAuth } from "@tmu/hooks"
import { Button, WelcomeSlice } from "@tmu/components/common"
import SEO from "@tmu/components/seo"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import {
  faHandHoldingHeart,
  faShoppingBag,
} from "@fortawesome/pro-solid-svg-icons"
import {
  StyledPageContainer,
  StyledWSIconAndContent,
  StyledWSAwesomeIcon,
  StyledWSContent,
  StyledDonateMainContainer,
  StyledDonateLeftContainer,
  StyledDonateLeftTitle,
  StyledDonateLeftContent,
  StyledDonateLeftImage,
  StyledDonateRightContainer,
  StyledDonateRightTitleAndContent,
  StyledDonateRightTitleTagSection,
  StyledDonateRightTitle,
  StyledDonateRightContent,
  StyledDonateRightPACsButton,
  StyledDonateRightPACOffersButton,
  StyledReadyMainContainer,
  StyledReadyLeftContainer,
  StyledReadyLeftImage,
  StyledReadyRightContainer,
  StyledReadyRightTitle,
  StyledReadyRightButton,
} from "@tmu/global/page-addons/join-donors-page.styles"

const { CLOUDFLARE_IMAGE_URL } = process.env

const Donors = ({ intl }) => {
  const { isAuthenticated } = useAuth()
  const { locale, formatMessage } = intl
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  let hash = typeof window !== "undefined" && window?.location?.hash

  const { isTablet, isDesktop, isWide } = getAllScreenTypes()

  const plantImageSize = {
    width: isWide ? 499 : isDesktop ? 302 : isTablet ? 192 : 234,
    height: isWide ? 587 : isDesktop ? 356 : isTablet ? 225 : 272,
  }

  const boxImageSize = {
    width: isWide ? 452 : isDesktop ? 251 : 192,
    height: isWide ? 339 : isDesktop ? 188 : 144,
  }

  const el =
    typeof window !== "undefined" && document.getElementsByTagName("body")[0]
  if (el) el.style.paddingBottom = !isTablet ? "3.125rem" : 0

  useEffect(() => {
    if (isAuthenticated) {
      setIsLoggedIn(true)
    } else {
      setIsLoggedIn(false)
    }
  }, [isAuthenticated])

  const buttonLabel = () => {
    if (!isLoggedIn) {
      return formatMessage({
        id: "signUp::text::signUp",
        defaultMessage: "Sign Up",
      })
    } else {
      return formatMessage({
        id: "campaignListing::exploreCampaigns",
        defaultMessage: "Explore Campaigns",
      })
    }
  }

  const buttonLink = () => {
    if (!isLoggedIn) {
      return "/signup"
    } else {
      return "/campaigns/?campaign=true&charity=false&event=false"
    }
  }

  const handleClick = (e) => {
    if (!isLoggedIn) {
      navigate("/signup")
    } else {
      navigate("/campaigns/?campaign=true&charity=false&event=false")
    }
  }

  return (
    <>
      <SEO
        lang={locale}
        title={formatMessage({
          id: "howItWorks::donors::pageTitle",
          defaultMessage: "Donors",
        })}
        image={
          CLOUDFLARE_IMAGE_URL +
          "/cdn-cgi/image/width=1920,fit=cover,format=webp,quality=90/static/assets/images/donor.jpg"
        }
      />
      <StyledPageContainer>
        <WelcomeSlice
          title={formatMessage({
            id: "join::donors::title",
            defaultMessage: "Donate at ZERO cost without changing your habits",
          })}
          content={
            <>
              <StyledWSIconAndContent>
                <StyledWSAwesomeIcon icon={faHandHoldingHeart} />
                <StyledWSContent>
                  {formatMessage({
                    id: "join::donors::content1",
                    defaultMessage:
                      "Your donation is rewarded 100% in PAC discounts for purchases: Donate-Receive-Make Shopping!",
                  })}
                </StyledWSContent>
              </StyledWSIconAndContent>
              <StyledWSIconAndContent>
                <StyledWSAwesomeIcon icon={faShoppingBag} />
                <StyledWSContent>
                  {formatMessage({
                    id: "join::donors::content2",
                    defaultMessage:
                      "With each purchase you can donate the PAC discount for free to your favorite association: Donate By Shopping!",
                  })}
                </StyledWSContent>
              </StyledWSIconAndContent>
            </>
          }
          buttonLabel={buttonLabel()}
          buttonColor="blue"
          buttonLink={buttonLink()}
          images={Array.of({
            id: 1,
            image: CLOUDFLARE_IMAGE_URL + "/static/assets/images/join/hug.png",
          })}
        />
        <StyledDonateMainContainer>
          {isTablet ? (
            <StyledDonateLeftContainer>
              <StyledDonateLeftTitle>
                <a href="#donate" className={hash !== "#shop" ? "active" : ""}>
                  <FormattedMessage
                    id="join::donors::donateTitle"
                    defaultMessage="Donate"
                  />
                </a>
              </StyledDonateLeftTitle>
              <StyledDonateLeftContent>
                <a href="#shop" className={hash === "#shop" ? "active" : ""}>
                  <FormattedMessage
                    id="join::donors::donateContent"
                    defaultMessage="Shop"
                  />
                </a>
              </StyledDonateLeftContent>
              <StyledDonateLeftImage
                src={
                  CLOUDFLARE_IMAGE_URL + "/static/assets/images/join/plant.svg"
                }
                altName={`donors-donate-banner`}
                fit="cover"
                {...plantImageSize}
              />
            </StyledDonateLeftContainer>
          ) : (
            <StyledDonateLeftTitle>
              <FormattedMessage
                id="join::donors::donateTitle"
                defaultMessage="Donate"
              />
            </StyledDonateLeftTitle>
          )}
          <StyledDonateRightContainer>
            <StyledDonateRightTitleAndContent>
              {hash ? <StyledDonateRightTitleTagSection id="donate" /> : null}
              <StyledDonateRightTitle>
                <FormattedMessage
                  id="join::donors::donateTitle1"
                  defaultMessage="Easy, safe and quick sign-up"
                />
              </StyledDonateRightTitle>
              <StyledDonateRightContent>
                <FormattedMessage
                  id="join::donors::donateContent1"
                  defaultMessage="Sign up in less than a minute! To start donating you must be registered on the TrustMeUp platform, a quick and easy process that can be done by your email or Google/Facebook account."
                />
              </StyledDonateRightContent>
              <StyledDonateRightTitle>
                <FormattedMessage
                  id="join::donors::donateTitle2"
                  defaultMessage="Donate and get PACs"
                />
              </StyledDonateRightTitle>
              <StyledDonateRightContent>
                <FormattedMessage
                  id="join::donors::donateContent2"
                  defaultMessage="For each donation made, you will be rewarded 100% in discounts called PACs, 1 Euro = 1 PAC. Within the platform, you can use your PACs, obtaining an equivalent discount in Euro, on all your purchases at the online stores available on our Commercial Gallery, called PAC Store."
                />
              </StyledDonateRightContent>
              <StyledDonateRightTitle>
                <FormattedMessage
                  id="join::donors::donateTitle3"
                  defaultMessage="Transparency and security"
                />
              </StyledDonateRightTitle>
              <StyledDonateRightContent hasSmallMargin>
                <FormattedMessage
                  id="join::donors::donateContent3_a"
                  defaultMessage="Donations destined for the realization of the projects of charities are registered through blockchain technology, thus guaranteeing and security."
                />
              </StyledDonateRightContent>
              <StyledDonateRightContent>
                <FormattedMessage
                  id="join::donors::donateContent3_b"
                  defaultMessage="All your donations, purchases and PACs you will receive, will be registered in your account: you will always have an access to the history of your donations and purchases. Developing a habit of doing good takes time."
                />
              </StyledDonateRightContent>
              {!isTablet && (
                <StyledDonateLeftContent>
                  <FormattedMessage
                    id="join::donors::donateContent"
                    defaultMessage="Shop"
                  />
                </StyledDonateLeftContent>
              )}
              {hash ? <StyledDonateRightTitleTagSection id="shop" /> : null}
              <StyledDonateRightTitle>
                <FormattedMessage
                  id="join::donors::donateTitle4"
                  defaultMessage="Choose a charity or campaign to support"
                />
              </StyledDonateRightTitle>
              <StyledDonateRightContent hasSmallMargin>
                <FormattedMessage
                  id="join::donors::donateContent4_a"
                  defaultMessage="Donors registered on the platform can choose the Charity or charity's project they wish to support and to which they wish to allocate their donation."
                />
              </StyledDonateRightContent>
              <StyledDonateRightContent>
                <FormattedMessage
                  id="join::donors::donateContent4_b"
                  defaultMessage="So by purchasing a offer with the maximum PAC discount, you also donate to your favourite charity"
                />
              </StyledDonateRightContent>
              <StyledDonateRightTitle>
                <FormattedMessage
                  id="join::donors::donateTitle5"
                  defaultMessage="Tax Deductible Receipt"
                />
              </StyledDonateRightTitle>
              <StyledDonateRightContent hasSmallMargin>
                <FormattedMessage
                  id="join::donors::donateContent5_a"
                  defaultMessage="Possibility to deduct the donation from taxes thanks to the receipt created, sent and archived automatically for each donation made."
                />
              </StyledDonateRightContent>
              <StyledDonateRightContent>
                <FormattedMessage
                  id="join::donors::donateContent5_b"
                  defaultMessage="We partner with more then 300 merchants to provide you a wide choice of offers. Use your PACs wisely!"
                />
              </StyledDonateRightContent>
              <StyledDonateRightPACsButton
                variant="link"
                text={
                  <FormattedMessage
                    id="pacs::hero::title"
                    defaultMessage="What are PACs?"
                  />
                }
                onClick={() => {
                  navigate("/pacs")
                }}
              />
              <StyledDonateRightPACOffersButton
                variant="link"
                text={
                  <FormattedMessage
                    id="productListing::offersTitle"
                    defaultMessage="PAC Offers"
                  />
                }
                onClick={() => {
                  navigate("/offers?offer=true&store=true")
                }}
              />
              {!isTablet && (
                <StyledDonateLeftImage
                  src={
                    CLOUDFLARE_IMAGE_URL +
                    "/static/assets/images/join/plant.svg"
                  }
                  altName={`donors-donate-banner`}
                  fit="cover"
                  {...plantImageSize}
                />
              )}
            </StyledDonateRightTitleAndContent>
          </StyledDonateRightContainer>
        </StyledDonateMainContainer>
        <StyledReadyMainContainer>
          <StyledReadyLeftContainer>
            <StyledReadyLeftImage
              src={CLOUDFLARE_IMAGE_URL + "/static/assets/images/join/box.svg"}
              altName={`donors-donate-banner`}
              fit="cover"
              {...boxImageSize}
            />
          </StyledReadyLeftContainer>
          <StyledReadyRightContainer>
            <StyledReadyRightTitle>
              <FormattedMessage
                id="join::donors::readyTitle"
                defaultMessage="We reward you for doing good"
              />
            </StyledReadyRightTitle>
            <StyledReadyRightButton
              data-testid={`btn-signup`}
              color="blue"
              type="button"
              onClick={handleClick}>
              {buttonLabel()}
            </StyledReadyRightButton>
          </StyledReadyRightContainer>
        </StyledReadyMainContainer>
      </StyledPageContainer>
      {!isTablet && (
        <div className="sticky-bottom-bar">
          <Button
            type="button"
            data-testid="btn-signup"
            label={buttonLabel()}
            color="blue"
            onClick={handleClick}>
            {buttonLabel()}
          </Button>
        </div>
      )}
    </>
  )
}

export default injectIntl(Donors)
