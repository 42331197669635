/* eslint-disable react/style-prop-object */
import React, { createContext, useState, useEffect } from "react"
import PropTypes from "prop-types"
import { FormattedMessage, navigate, useIntl } from "gatsby-plugin-intl"
import { useQuery, useMutation } from "@apollo/client"
import { faCreditCard } from "@fortawesome/pro-regular-svg-icons/faCreditCard"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CartTable, CartSummary } from "@tmu/components/offers"
import { PaymentMethods, Spinner } from "@tmu/components/common"
import { useStripe, useElements } from "@stripe/react-stripe-js"
import { useAuth, useToast } from "@tmu/hooks"
import { ALL_ORDERS } from "@tmu/hooks/useAllOrders"
import { OFFER_ORDER_CHECKOUT_MUTATION } from "@tmu/apollo/storefront/mutations/cart"
import { ORDER_PAYMENT_READY_FAILED_MUTATION } from "@tmu/apollo/storefront/mutations/user"
import { useApolloApiClients } from "@tmu/apollo/client"
import { CheckoutForm } from "@tmu/components/forms"
import {
  StyledPurchaseFormFaq,
  StyleOfferDetails,
  StyleOfferDetailsTitle,
  StyledOfferHeader,
  StyledPurchaseDetails,
  /* StyledLink, */
} from "./index.styles"
import { usePayment } from "@tmu/src/hooks"
import { PAYMENT_TYPES } from "@tmu/src/apollo/constants"

export const CheckoutContext = createContext({})

export const CheckoutProvider = CheckoutContext.Provider
export const CheckoutConsumer = CheckoutContext.Consumer

const OfferPurchaseForm = () => {
  const { formatMessage, locale } = useIntl()
  const { error: errorToaster } = useToast()
  const stripe = useStripe()
  const elements = useElements({ locale })
  const [confirmingPayment, setConfirmingPayment] = useState(false)
  const [hasLastCheckError, setLastCheckError] = useState(false)
  const { loading: loadingProfile, user } = useAuth()
  const { email, country } = loadingProfile ? {} : { ...user }
  const { isAuthenticated, isLoading } = useAuth()
  const { storefrontClient } = useApolloApiClients()
  const { confirmPaymentMethod } = usePayment()
  const [selectedPaymentType, setSelectedPaymentType] = useState(
    PAYMENT_TYPES.CARD
  )
  const variables = {
    status_In: ["IN_PROGRESS"],
    first: 1,
    internal: true,
  }

  const {
    data,
    loading: loadingOrders,
    refetch: refetchOrders,
  } = useQuery(ALL_ORDERS, {
    variables,
    skip: !isAuthenticated || isLoading,
    client: storefrontClient,
  })

  const [
    createOfferOrder,
    { error: productError, data: productData, loading: creatingOrder },
  ] = useMutation(OFFER_ORDER_CHECKOUT_MUTATION)

  const [orderPaymentFail] = useMutation(ORDER_PAYMENT_READY_FAILED_MUTATION, {
    client: storefrontClient,
  })

  const order = data?.allOrders?.edges?.[0]?.node
  const [orderId, setOrderId] = useState(order?.id || "")

  useEffect(() => {
    if (loadingOrders || confirmingPayment) {
      return
    }
    setOrderId(order?.id)
    if (cartItems.length === 0) {
      navigate("/offers/cart", { replace: true })
    }
  }, [order, loadingOrders, cartItems])

  const total = order?.total ?? 0
  const subtotal = order?.subtotal ?? 0
  const pacDiscountAmount = order?.discountAmount ?? 0
  const cartItems = !order?.store?.id ? order?.cartItems?.edges : []
  const isCartEmpty = cartItems.length === 0

  const handleOrderPaymentFail = async ({ error, id, form }) => {
    try {
      const { data, loading } = await orderPaymentFail({
        variables: { input: { id } },
      })
      const stripeError = error?.code
        ? formatMessage({
            id: `forms::stripe::${error?.code}`,
            defaultMessage: `${error?.message}`,
          })
        : null
      errorToaster(
        stripeError ||
          formatMessage({
            id: "forms::error::tryAgain",
            defaultMessage: "Please try again later.",
          })
      )

      refetchOrders({ variables })

      form.setSubmitting(false)
      setConfirmingPayment(false)
    } catch (err) {
      errorToaster(
        formatMessage(
          {
            id: "forms::error::tryAgainWithError",
            defaultMessage: "{err} Please try again later.",
          },
          {
            err: error?.message,
          }
        )
      )
      form.setSubmitting(false)
      setConfirmingPayment(false)
      errorToaster(
        formatMessage({
          id: "forms::checkoutForm::unexpectedError",
          defaultMessage: `An unexpected error occured`,
        })
      )
    }
  }

  const onSubmitPayment = async (values, form) => {
    if (!stripe || !elements) {
      return
    }

    const { agreeTerms, cardholderName } = values
    form.setSubmitting(true)
    setConfirmingPayment(true)

    try {
      const { data } = await createOfferOrder({
        variables: {
          input: {
            id: orderId,
            isTermsAndPolicyAccepted: agreeTerms,
          },
        },
      })

      if (data?.orderCheckOut?.errors?.length)
        throw new Error(data?.orderCheckOut?.errors[0].messages[0])

      const order = data?.orderCheckOut?.order
      if (!order) {
        return
      }
      if (order.total !== total) {
        setLastCheckError(true)
        form.setSubmitting(false)
        return
      }

      const { stripeClientSecret, id } = { ...order }
      if (!stripeClientSecret || !id)
        throw new Error(
          formatMessage({
            id: "forms::error::stripeClientSecret",
            defaultMessage: "There was a problem during the transaction",
          })
        )
      const card = elements.getElement("card")
      const payment_method = country
        ? {
            card,
            billing_details: {
              address: {
                country,
              },
              email,
              name: cardholderName,
            },
          }
        : {
            card,
            billing_details: {
              email,
              name: cardholderName,
            },
          }
      const { paymentIntent, error } = await stripe.confirmCardPayment(
        stripeClientSecret,
        {
          payment_method,
        }
      )

      if (error) {
        handleOrderPaymentFail({ error, id, form })
      } else if (paymentIntent?.status === "succeeded") {
        form.setSubmitting(false)
        navigate(`/offers/checkout/success`)
      }
    } catch (err) {
      errorToaster(err?.message)
      form.setSubmitting(false)
      setConfirmingPayment(false)
    }
  }

  const isFormLoading = loadingOrders || creatingOrder || confirmingPayment

  return (
    <CheckoutProvider
      value={{
        isProcessing: isFormLoading,
        onSubmitPayment,
        productData,
        productError,
        hasLastCheckError,
        isCartEmpty,
      }}>
      <StyledOfferHeader>
        <h1>
          <FontAwesomeIcon icon={faCreditCard} />
          <FormattedMessage
            id="offer::checkout::header"
            defaultMessage="Checkout"
          />
        </h1>
      </StyledOfferHeader>
      {isFormLoading && <Spinner />}
      <StyledPurchaseDetails hideCard={isFormLoading}>
        <div
          style={{
            visibility: confirmingPayment ? "hidden" : "visible",
          }}>
          {!isFormLoading && <CartTable cartItems={cartItems} />}
          <div
            style={{
              visibility: isFormLoading ? "hidden" : "visible",
            }}>
            <PaymentMethods
              onSelect={async (val) => {
                setSelectedPaymentType(val)

                await callCreateClientSecret({
                  variables: { stripePaymentMethodType: val },
                })
              }}
              amount={total}
            />
            <CheckoutForm paymentType={selectedPaymentType} />
          </div>
        </div>
        {!isFormLoading && total > 0 && (
          <StyleOfferDetails>
            <StyleOfferDetailsTitle>
              <FormattedMessage
                id="offer::checkout::purchaseSummary"
                defaultMessage="Purchase Summary"
              />
            </StyleOfferDetailsTitle>
            <CartSummary
              subtotal={subtotal}
              total={total}
              pacDiscountAmount={pacDiscountAmount}
            />
            <StyledPurchaseFormFaq>
              <FormattedMessage
                id="offer::purchaseForm::spendYourPACs"
                defaultMessage="Spend your PACs at our gift card store"
                tagName="li"
              />
              <FormattedMessage
                id="offer::purchaseForm::chooseFrom"
                defaultMessage="Choose from a big variety of brands"
                tagName="li"
              />
              <FormattedMessage
                id="offer::purchaseForm::enjoyWide"
                defaultMessage="Enjoy a wide offer offer"
                tagName="li"
              />
            </StyledPurchaseFormFaq>
          </StyleOfferDetails>
        )}
      </StyledPurchaseDetails>
    </CheckoutProvider>
  )
}

OfferPurchaseForm.propTypes = {
  slug: PropTypes.string.isRequired,
}

OfferPurchaseForm.defaultProps = {
  slug: "",
}

export default OfferPurchaseForm
