import { StyledProfileSectionTitle } from "../../dashboard/dashboardCommon/ProfileForm/index.styles"
import Money from "../Money"
import {
  StyledAmountInfo,
  StyledButtonContainer,
  StyledCopyIcon,
  StyledDescription,
  StyledDetailContainer,
  StyledHeaderContainer,
  StyledInfo,
  StyledInfoContainer,
  StyledPageTitle,
  StyledWrapper,
} from "./index.styles"

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft"
import { faCopy } from "@fortawesome/pro-light-svg-icons/faCopy"
import { faArrowDownToBracket } from "@fortawesome/pro-solid-svg-icons/faArrowDownToBracket"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { isBrowser } from "@sentry/utils"
import {
  Spacer,
  StyledGlobalPageWrapper,
} from "@tmu/src/global/page-addons/detail-page.styles"
import { useDonations, useOrder } from "@tmu/src/hooks"
import { getAllScreenTypes } from "@tmu/src/utils/mediaQueries"
import { FormattedMessage, navigate, useIntl } from "gatsby-plugin-intl"
import queryString from "query-string"
import { useEffect, useState } from "react"
import Button from "../Button"
import ConfirmModal from "../ConfirmModal"

const BankTransferDetails = ({ slug }) => {
  const { formatMessage } = useIntl()
  const params = queryString.parse(location?.search, { arrayFormat: "comma" })
  const { id, orderId } = params
  let decodedId = ""
  let uid = ""
  try {
    decodedId = atob(id)
    uid = decodedId?.split(":")?.[1]
  } catch (e) {}
  const isDonation = decodedId?.toLowerCase()?.includes("donation")
  const { discardOrder, callOrder, orderDetail } = useOrder()
  const {
    callLimitedDonation,
    limitedDonation: donation,
    callCancelDonation,
  } = useDonations()
  const { isTablet } = getAllScreenTypes()
  const [confirmStatus, setConfirmStatus] = useState(false)

  const detailData = isDonation ? donation : orderDetail
  const cancelCall = isDonation ? callCancelDonation : discardOrder
  const vars = isDonation ? { id } : { order: id }

  useEffect(() => {
    if (isDonation) {
      callLimitedDonation({ variables: { donationId: uid } })
    } else {
      callOrder({ variables: { id } })
    }
  }, [])

  const {
    bic,
    iban,
    country,
    account_holder_name: accountHolderName,
  } = detailData?.stripeRequiresActionData?.bank_account_info ?? {}

  const infoListData = [
    {
      title: "BIC",
      detail: bic,
    },
    {
      title: "IBAN",
      detail: iban,
    },
    {
      title: formatMessage({
        id: "dashboard::profile::country",
        defaultMessage: "Country",
      }),
      detail: country,
    },
    {
      title: formatMessage({
        id: "bankTransfer::details::accountHolderName",
        defaultMessage: "Account Holder Name",
      }),
      detail: accountHolderName,
    },
    {
      title: formatMessage({
        id: "bankTransfer::details::reference",
        defaultMessage: "Reference",
      }),
      detail: detailData?.stripeRequiresActionData?.reference,
    },
  ]

  const closeModal = () => {
    setConfirmStatus(false)
  }

  const cancelTransfer = () => {
    cancelCall({
      variables: { input: vars },
    }).finally(() => {
      closeModal()
      if (isBrowser) {
        window.history.back()
      }
    })
  }

  const goToSuccessPage = () => {
    navigate(
      `/campaigns/${slug}/donation-success?${
        isDonation ? "donationId" : "id"
      }=${id}`
    )
  }

  return (
    <StyledGlobalPageWrapper>
      <StyledWrapper>
        <StyledHeaderContainer>
          <div className="back" onClick={cancelTransfer}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </div>
          <StyledPageTitle>
            <FormattedMessage
              id="bankTransfer::details::title"
              defaultMessage="Bank Transfer"
            />
          </StyledPageTitle>
        </StyledHeaderContainer>
        <StyledProfileSectionTitle>
          <FormattedMessage
            id="bankTransfer::details::amount"
            defaultMessage="Amount"
          />
        </StyledProfileSectionTitle>
        <StyledAmountInfo>
          <Money value={detailData?.total ?? detailData?.amount} />
        </StyledAmountInfo>
        <StyledDescription>
          <FormattedMessage
            id="bankTransfer::details::description1"
            defaultMessage="Transfer funds using the following bank information:"
          />
        </StyledDescription>
        <StyledDetailContainer>
          {infoListData?.map((item) => (
            <div key={item?.title}>
              <StyledProfileSectionTitle>
                {item?.title}
              </StyledProfileSectionTitle>
              <StyledInfoContainer>
                <StyledCopyIcon>
                  <FontAwesomeIcon icon={faCopy} />
                </StyledCopyIcon>
                <StyledInfo>{item?.detail}</StyledInfo>
              </StyledInfoContainer>
            </div>
          ))}
          <StyledDescription>
            <FormattedMessage
              id="bankTransfer::details::description2"
              defaultMessage="Please always include the reference number when sending your bank
          transfers."
            />
          </StyledDescription>
          <StyledDescription>
            <FormattedMessage
              id="bankTransfer::details::description3"
              defaultMessage="Ensure that the bank transfer amount is after deducting any charges."
            />
          </StyledDescription>
          <Button color="transparent">
            <FontAwesomeIcon icon={faArrowDownToBracket} />
            <FormattedMessage
              id="bankTransfer::details::downloadPdf"
              defaultMessage="Download PDF"
            />
          </Button>
        </StyledDetailContainer>
        <Spacer top={isTablet ? 1 : 3} />
        <StyledButtonContainer>
          <Button color="transparent" onClick={() => setConfirmStatus(true)}>
            <FormattedMessage
              id="bankTransfer::details::cancelTransder"
              defaultMessage="Cancel transfer"
            />
          </Button>
          <Button color="pacificBlue" onClick={goToSuccessPage}>
            <FormattedMessage id="ui::continue" defaultMessage="Continue" />
          </Button>
        </StyledButtonContainer>
        <Spacer top={isTablet ? 2 : 0} />
      </StyledWrapper>
      <ConfirmModal
        isModalOpen={confirmStatus}
        cancelButtonAction={() => closeModal()}
        confirmButtonAction={cancelTransfer}
      />
    </StyledGlobalPageWrapper>
  )
}

export default BankTransferDetails
