import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useIntl } from "gatsby-plugin-intl"
import { useCountries } from "@tmu/hooks"
import { Dropdown } from "@tmu/components/common"

const CountrySelect = ({
  defaultValue,
  onChange,
  variant,
  override = [],
  ...rest
}) => {
  const { formatMessage } = useIntl()
  const { countries: allCountries, getCountries } = useCountries()
  const countries = override?.length ? override : allCountries
  const selectedCountry = countries?.find((c) => c?.shortCode === defaultValue)

  useEffect(() => {
    getCountries()
  }, [])

  return (
    <Dropdown
      {...rest}
      variant={variant}
      placeholder={
        selectedCountry
          ? selectedCountry?.title
          : formatMessage({
              id: "filter::countryPlaceholder",
              defaultMessage: "Choose Country",
            })
      }
      options={countries?.map((c) => ({
        value: c?.id || c?.shortCode,
        label: c?.description || c?.label,
      }))}
      defaultValue={defaultValue}
      onChange={onChange}
    />
  )
}

CountrySelect.propTypes = {
  defaultValue: PropTypes.string,
  variant: PropTypes.oneOf(["default", "pill"]),
  onChange: PropTypes.func,
}

CountrySelect.defaultProps = {
  defaultValue: "",
  variant: "default",
  onChange: () => {},
}

export default CountrySelect
