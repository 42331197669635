import React, { useEffect } from "react"
import { connect } from "formik"
import { useToast } from "@tmu/hooks"
import { useIntl } from "gatsby-plugin-intl"

const OnSubmitValidationError = (props) => {
  const { formik } = props
  const { warning: warnToast } = useToast()
  const { formatMessage } = useIntl()

  const effect = () => {
    const errorKeys = Object.keys(formik.errors)
    if (errorKeys.length > 0) {
      const element = document.getElementsByName(errorKeys[0])[0]
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" })
      }
    }
    if (formik.submitCount > 0 && !formik.isSubmitting && !formik.isValid) {
      warnToast(
        formatMessage({
          id: "field::error::errorMesage",
          defaultMessage: "Please make sure you've filled all the fields!",
        })
      )
    }
  }
  useEffect(effect, [formik.submitCount, formik.isSubmitting])

  return null
}

export default connect(OnSubmitValidationError)
