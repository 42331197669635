import styled from "@emotion/styled"

export const StyledLegalWrapper = styled.div`
  margin: 3.125rem auto 0;
  min-height: 100vh;
  display: block;

  table,
  th,
  td {
    border: 0.0625rem solid;
    vertical-align: center !important;
    text-align: left;
  }

  h4 {
    text-align: center;
    padding: 1rem 0 0;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: grid;
    grid-template-columns: 11.0625rem 1fr;
    max-width: ${(p) => p.theme.breakpoints.tablet};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    max-width: ${(p) => p.theme.breakpoints.desktop};
    grid-template-columns: 12rem 1fr;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    max-width: ${(p) => p.theme.breakpoints.wide};
    grid-template-columns: 22.75rem 1fr;
    margin: 5rem auto 0;
  }
`

export const StyledLegalLeftMenu = styled.div`
  padding: 1.5rem 1.5rem 4rem;
  > div {
    position: sticky;
    top: 3.125rem;
    height: fit-content;

    > p {
      margin: 0 0 1rem;
      &:last-of-type {
        margin-top: 2rem;
      }
    }
  }

  a {
    display: block;
    margin-bottom: 1rem;
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    font-size: 1rem;
    line-height: 1.375rem;
    color: ${(p) => p.theme.colors.pitchBlack};
  }

  hr {
    margin: 0.75rem 0;
  }

  .active {
    color: ${(p) => p.theme.colors.black};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    background: ${(p) => p.theme.colors.whiteSmoke};

    > p:last-of-type {
      margin-top: 0;
    }

    hr {
      margin: 1.5rem 0;
    }

    a {
      display: block;
      font-family: ${(p) => p.theme.fontFamilies.regular};
      font-style: ${(p) => p.theme.fontStyles.normal};
      font-weight: ${(p) => p.theme.fontWeights.liteMedium};
      font-size: 0.75rem;
      line-height: 1.125rem;
      color: ${(p) => p.theme.colors.disabled};
      margin-bottom: 1rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    a {
      font-size: 0.75rem;
      line-height: 1.125rem;
      margin-bottom: 1rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding: 2rem 2rem 6rem;
    .caption {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    a {
      font-size: 1.5rem;
      line-height: 2.25rem;
      margin-bottom: 2rem;
    }
  }
`

export const StyledLegalContent = styled.div`
  display: block;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: flex;
    padding: 1.5rem 1rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding-top: 2rem;
  }
`

export const StyledLegalContentDetails = styled.div`
  color: ${(p) => p.theme.colors.pitchBlack};
  padding-right: 1rem;
  h1 {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};

    font-size: 1.125rem;
    line-height: 1.2rem;
    margin-bottom: 1rem;
  }

  h4 {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};

    font-size: 0.75rem;
    line-height: 2rem;
    text-align: left;
  }
  div {
    text-align: left;
  }
  figure {
    float: none !important;
  }

  p,
  td,
  ol,
  li {
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    font-size: 0.625rem;
    line-height: 1rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    h1 {
      font-family: ${(p) => p.theme.fontFamilies.headline};
      font-style: ${(p) => p.theme.fontStyles.normal};
      font-weight: ${(p) => p.theme.fontWeights.semiBolder};

      font-size: 2rem;
    }

    h4 {
      font-family: ${(p) => p.theme.fontFamilies.headline};
      font-style: ${(p) => p.theme.fontStyles.normal};
      font-weight: ${(p) => p.theme.fontWeights.semiBolder};

      font-size: 1.5rem;
    }

    p,
    td,
    ol,
    li {
      font-family: ${(p) => p.theme.fontFamilies.regular};
      font-style: ${(p) => p.theme.fontStyles.normal};
      font-weight: ${(p) => p.theme.fontWeights.liteMedium};

      font-size: 1rem;
      line-height: 1.5rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
  }
`
export const StyledLegalContentShortcuts = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  min-width: 10.5rem;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: left;
  margin-bottom: 2.5rem;
  color: ${(p) => p.theme.colors.disabled};
  > p {
    margin: 0 0 1rem;
  }
  text-transform: lowercase;
  a {
    display: block;
    margin-bottom: 1rem;
    text-transform: capitalize;
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    &.active {
      color: ${(p) => p.theme.colors.black};
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    position: sticky;
    top: 3.125rem;
    height: fit-content;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1rem;
    min-width: 25.875rem;

    line-height: 1.5rem;
    .caption {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
`
