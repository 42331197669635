import React, { useEffect, useRef } from "react"
import OpenAccountCard from "./OpenAccountCard/index"
import PriceCard from "./PriceCard/index"
import { isInViewport } from "@tmu/utils/viewport"
import { useLocation } from "@reach/router"

import {
  StyledOurPricing,
  StyledTitle,
  StyledCardWrapper,
} from "./index.styles"

const OurPricing = ({ title, openAccountCard, priceCard }) => {
  const ref = useRef()
  const { hash } = useLocation()

  const hashCallback = () => {
    const hashText = "#our-pricing"
    const isHashInUrl = hash === hashText || window?.location?.hash === hashText
    if (isInViewport(ref) && !isHashInUrl)
      history.pushState(null, null, hashText)
  }

  useEffect(() => {
    document.addEventListener("scroll", hashCallback)
    return () => document.removeEventListener("scroll", hashCallback)
  }, [])

  return (
    <StyledOurPricing id="our-pricing" ref={ref}>
      {title && <StyledTitle>{title}</StyledTitle>}
      <StyledCardWrapper>
        {openAccountCard && (
          <OpenAccountCard
            title={openAccountCard.title || ""}
            desc={openAccountCard.desc || ""}
            buttonText={openAccountCard.buttonText || ""}
            subTitle={openAccountCard.subTitle || ""}
            url={openAccountCard.url || ""}
          />
        )}
        {priceCard && (
          <PriceCard
            rightSlices={priceCard.rightSlices || ""}
            leftSlices={priceCard.leftSlices || ""}
            comingSoonTitle={priceCard.comingSoonTitle || ""}
            comingSoon={priceCard.comingSoon || ""}
          />
        )}
      </StyledCardWrapper>
    </StyledOurPricing>
  )
}

export default OurPricing
