import styled from "@emotion/styled"

export const StyledResetPassword = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 2.5rem;
  min-height: 80vh;
  height: 100%;

  .float-container {
    width: 31.875rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      width: 18.75rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-top: 5.5rem;
  }

  .recover-password-button {
    float: right;
  }
`
export const StyledResetPassTitle = styled.h1`
  color: ${(p) => p.theme.colors.primaryTextColor};
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-size: 3rem;
  padding-bottom: 1.4375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1.75rem;
  }
`

export const StyledResetPassDesc = styled.p`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 3rem;
  line-height: 1.6875rem;
  color: ${(p) => p.theme.colors.gondola};
  font-size: 1rem;
`

export const StyledTypeEmail = styled.span`
  padding-bottom: 1.125rem;
  color: ${(p) => p.theme.colors.gondola};
  font-size: 1rem;
  font-weight: ${(p) => p.theme.fontWeights.bold};
`
