import React from "react"
import { FormattedMessage, navigate } from "gatsby-plugin-intl"
import { faXmark } from "@fortawesome/free-solid-svg-icons/faXmark"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, CustomModal } from "@tmu/components/common"
import { modalStyles } from "@tmu/global/GlobalStyle"
import { StyledModalContainer, StyledModalHeader } from "./index.styles"

export const LeaveModal = ({
  isLeaveModalOpen,
  handleLeaveModalClose,
  isSupporter,
  isMerchantOffline,
}) => {
  return (
    <CustomModal
      isOpen={isLeaveModalOpen}
      style={{
        ...modalStyles,
        content: { ...modalStyles.content, border: "unset" },
      }}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      isCloseIcon={true}
      cancelButtonAction={handleLeaveModalClose}>
      <StyledModalContainer>
        <StyledModalHeader>
          <FormattedMessage
            id="dashboard::common::sureToLeave"
            defaultMessage="Sure, you want to leave?"
            tagName="h4"
          />
        </StyledModalHeader>
        <FormattedMessage
          id="dashboard::common::leaveServiceModalText"
          defaultMessage="If you leave, all service creation progress will be lost."
          tagName="p"
        />
        <Button
          data-testid="btn-modal-leave"
          color="blue"
          label="Leave"
          className="modal-leave"
          onClick={() => navigate("/dashboard/merchants/services/")}>
          <FormattedMessage
            id="dashboard::common::leave"
            defaultMessage="Leave"
          />
        </Button>
      </StyledModalContainer>
    </CustomModal>
  )
}
