import React, { useContext } from "react"
import { useQuery, useLazyQuery } from "@apollo/client"
import { useApolloApiClients } from "@tmu/apollo/client"
import { PARTNER_USER_LISTING_QUERY } from "@tmu/apollo/dashboard/queries/partner"

const usePartnerUsers = (partnerId) => {
  const { partnerClient } = useApolloApiClients()
  const [getPartnerUsers, { data }] = useLazyQuery(PARTNER_USER_LISTING_QUERY, {
    variables: { first: 100, partner: partnerId },
    client: partnerClient,
  })
  const partnerUsers = data?.allPartnerUsers?.edges.map(
    (item) => item.node?.user?.email
  )
  return {
    getPartnerUsers,
    partnerUsers,
  }
}

export default usePartnerUsers
