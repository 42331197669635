import styled from "@emotion/styled"
import { FixedSizeList as List } from "react-window"

const GridTemplate = `
  display: grid;
  grid-template-columns: minmax(8rem, 15rem) minmax(8rem, 15rem) minmax(4rem, 7rem);
  grid-template-rows: 1fr;
  grid-template-areas: ". . . .";
  grid-gap: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  align-items: center;
`

export const StyledCampaignFAQTable = styled(List)``

export const StyledCampaignFAQTableHeader = styled.div`
  ${GridTemplate}
  color: ${(p) => p.theme.colors.tableHeaderDefault};
  border-bottom: 0.0625rem solid ${(p) => p.theme.colors.tableItemBorder};
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 0.8rem;
  .centered {
    height: 100%;
    justify-self: center;
  }
`

export const StyledCampaignFAQTableRow = styled.div`
  ${GridTemplate}
  width: 100%;
  overflow: hidden;
  color: ${(p) => p.theme.colors.secondaryBrandTextColor};
  border-bottom: 0.0625rem solid ${(p) => p.theme.colors.tableItemBorder};
  width: 100%;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  height: 100%;
  box-sizing: border-box;
  div,
  strong {
    font-size: 0.8rem;
  }
  strong {
    font-weight: ${(p) => p.theme.fontWeights.bold};
    color: ${(p) => p.theme.colors.gondola};
  }
  .media-box {
    width: 5rem;
    height: 3rem;
  }
  .centered {
    height: 100%;
    display: flex;
    align-items: center;
    justify-self: center;
    button svg {
      margin-left: 0;
    }
  }
`
