import React, { useContext } from "react"
import PropTypes from "prop-types"
import { navigate, FormattedMessage } from "gatsby-plugin-intl"
import { Button } from "@tmu/components/common"
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons/faExclamationTriangle"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useMediaQuery } from "beautiful-react-hooks"
import {
  StyledDashboard,
  StyledDashboardGrid,
  StyledContent,
  StyledDashboardDisabledWarning,
} from "@tmu/global/page-addons/dashboard.styles"
import { HomePageContext } from "../context/homePageContext"

export const DisabledWarning = () => (
  <StyledDashboardDisabledWarning data-testid="text-dashboard-not-enabled">
    <FontAwesomeIcon icon={faExclamationTriangle} />
    <FormattedMessage
      id="dashboard::ui::smallScreenWarning"
      defaultMessage="Dashboard is not enabled on small screens"
      tagName="p"
    />
    <Button label="Homepage" onClick={() => navigate("/")}>
      <FormattedMessage
        id="dashboard::ui::navigateHome"
        defaultMessage="Homepage"
      />
    </Button>
  </StyledDashboardDisabledWarning>
)

const DashboardLayout = ({ children }) => {
  const isSmall =
    typeof window !== "undefined" && useMediaQuery("(max-width: 36rem)")
  const isMedium =
    typeof window !== "undefined" && useMediaQuery("(max-width: 767px)")
  const isMobile =
    typeof window !== "undefined" && useMediaQuery("(max-width: 64rem)")

  const { menuConfig } = useContext(HomePageContext)

  return (
    <>
      <StyledDashboard>
        <StyledDashboardGrid
          data-testid="styled-content-grid"
          small={isSmall}
          medium={isMedium}>
          <StyledContent
            className={menuConfig?.hideOnDashboard === true ? "no-margin" : ""}
            data-testid="styled-content">
            {children}
          </StyledContent>
        </StyledDashboardGrid>
      </StyledDashboard>
    </>
  )
}

DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default DashboardLayout
