import { gql } from "@apollo/client"
import {
  partnerCampaignUpdateParts,
  partnerCampaignFaqParts,
  partnerDonationTierParts,
} from "@tmu/apollo/dashboard/fragments/campaign"

import {
  partnerCampaignDetailPartsForPartner,
  merchantCampaignDetailParts,
} from "@tmu/apollo/dashboard/fragments/campaign"

export const CREATE_PARTNER_CAMPAIGN_MUTATION = gql`
  mutation CreateCampaignMutation($input: CreateCampaignMutationInput!) {
    createCampaign(input: $input) {
      campaign {
        ...partnerCampaignDetailPartsForPartner
        image
      }
      errors {
        field
        messages
      }
    }
  }
  ${partnerCampaignDetailPartsForPartner}
`
export const CREATE_USER_CAMPAIGN_MUTATION = gql`
  mutation CreateCampaignMutation($input: CreateCampaignMutationInput!) {
    createCampaign(input: $input) {
      campaign {
        ...partnerCampaignDetailPartsForPartner
        image {
          url
          cache
        }
      }
      errors {
        field
        messages
      }
    }
  }
  ${partnerCampaignDetailPartsForPartner}
`

export const CREATE_MERCHANT_CAMPAIGN_MUTATION = gql`
  mutation CreateCampaignMutation($input: CreateCampaignMutationInput!) {
    createCampaign(input: $input) {
      campaign {
        ...merchantCampaignDetailParts
      }
      errors {
        field
        messages
      }
    }
  }
  ${merchantCampaignDetailParts}
`

export const UPDATE_CAMPAIGN_MUTATION = gql`
  mutation UpdateCampaignMutation($input: UpdateCampaignMutationInput!) {
    updateCampaign(input: $input) {
      campaign {
        ...partnerCampaignDetailPartsForPartner
      }
      errors {
        field
        messages
      }
    }
  }
  ${partnerCampaignDetailPartsForPartner}
`
export const CAMPAIGN_SUBMIT_FOR_PARTIAL_UPDATE = gql`
  mutation PartialUpdateCampaign($input: PartialUpdateCampaignMutationInput!) {
    partialUpdateCampaign(input: $input) {
      campaign {
        id
        name
        reviewStatus
      }
      errors {
        field
        messages
      }
    }
  }
`
export const CAMPAIGN_SUBMIT_FOR_REVIEW_MUTATION = gql`
  mutation campaignSubmitForReview(
    $input: CampaignSubmitForReviewMutationInput!
  ) {
    campaignSubmitForReview(input: $input) {
      campaign {
        ...partnerCampaignDetailPartsForPartner
      }
      errors {
        field
        messages
      }
    }
  }
  ${partnerCampaignDetailPartsForPartner}
`

export const MERCHANT_CAMPAIGN_SUBMIT_FOR_REVIEW_MUTATION = gql`
  mutation campaignSubmitForReview(
    $input: CampaignSubmitForReviewMutationInput!
  ) {
    campaignSubmitForReview(input: $input) {
      campaign {
        ...merchantCampaignDetailParts
      }
      errors {
        field
        messages
      }
    }
  }
  ${merchantCampaignDetailParts}
`

export const DELETE_CAMPAIGN_MUTATION = gql`
  mutation DeleteCampaignMutation($input: DeleteCampaignMutationInput!) {
    deleteCampaign(input: $input) {
      success
      errors {
        field
        messages
      }
    }
  }
`

export const END_CAMPAIGN_MUTATION = gql`
  mutation EndCampaignMutation($input: EndCampaignMutationInput!) {
    endCampaign(input: $input) {
      success
      errors {
        field
        messages
      }
    }
  }
`

export const CREATE_CAMPAIGN_IMAGE_MUTATION = gql`
  mutation CreateCampaignImage($input: CreateCampaignImageMutationInput!) {
    createCampaignImage(input: $input) {
      campaignImage {
        id
        image
        order
      }
      errors {
        field
        messages
      }
    }
  }
`

export const DELETE_CAMPAIGN_IMAGE_MUTATION = gql`
  mutation DeleteCampaignImage($input: DeleteCampaignImageMutationInput!) {
    deleteCampaignImage(input: $input) {
      success
      errors {
        field
        messages
      }
    }
  }
`

export const CREATE_CAMPAIGN_UPDATE_MUTATION = gql`
  mutation createCampaignUpdateMutation(
    $input: CreateCampaignUpdateMutationInput!
  ) {
    createCampaignUpdate(input: $input) {
      campaignUpdate {
        ...partnerCampaignUpdateParts
      }
      errors {
        field
        messages
      }
    }
  }
  ${partnerCampaignUpdateParts}
`

export const UPDATE_CAMPAIGN_UPDATE_MUTATION = gql`
  mutation updateCampaignUpdateMutation(
    $input: UpdateCampaignUpdateMutationInput!
  ) {
    updateCampaignUpdate(input: $input) {
      campaignUpdate {
        ...partnerCampaignUpdateParts
      }
      errors {
        field
        messages
      }
    }
  }
  ${partnerCampaignUpdateParts}
`
export const DELETE_CAMPAIGN_UPDATE_MUTATION = gql`
  mutation deleteCampaignUpdateMutation(
    $input: DeleteCampaignUpdateMutationInput!
  ) {
    deleteCampaignUpdate(input: $input) {
      success
      errors {
        field
        messages
      }
    }
  }
`
export const CREATE_CAMPAIGN_FAQ_MUTATION = gql`
  mutation createCampaignFaqMutation($input: CreateCampaignFaqMutationInput!) {
    createCampaignFaq(input: $input) {
      campaignFaq {
        ...partnerCampaignFaqParts
      }
      errors {
        field
        messages
      }
    }
  }
  ${partnerCampaignFaqParts}
`

export const UPDATE_CAMPAIGN_FAQ_MUTATION = gql`
  mutation updateCampaignFaqMutation($input: UpdateCampaignFaqMutationInput!) {
    updateCampaignFaq(input: $input) {
      campaignFaq {
        ...partnerCampaignFaqParts
      }
      errors {
        field
        messages
      }
    }
  }
  ${partnerCampaignFaqParts}
`
export const DELETE_CAMPAIGN_FAQ_MUTATION = gql`
  mutation deleteCampaignFaqMutation($input: DeleteCampaignFaqMutationInput!) {
    deleteCampaignFaq(input: $input) {
      success
      errors {
        field
        messages
      }
    }
  }
`

export const CREATE_CAMPAIGN_TIER_MUTATION = gql`
  mutation createDonationTierMutation(
    $input: CreateDonationTierMutationInput!
  ) {
    createDonationTier(input: $input) {
      tier {
        ...partnerDonationTierParts
      }
      errors {
        field
        messages
      }
    }
  }
  ${partnerDonationTierParts}
`

export const UPDATE_CAMPAIGN_TIER_MUTATION = gql`
  mutation updateDonationTierMutation(
    $input: UpdateDonationTierMutationInput!
  ) {
    updateDonationTier(input: $input) {
      tier {
        ...partnerDonationTierParts
      }
      errors {
        field
        messages
      }
    }
  }
  ${partnerDonationTierParts}
`
export const DELETE_CAMPAIGN_TIER_MUTATION = gql`
  mutation deleteDonationTierMutation(
    $input: DeleteDonationTierMutationInput!
  ) {
    deleteDonationTier(input: $input) {
      success
      errors {
        field
        messages
      }
    }
  }
`

export const CREATE_SUPPORTER_CAMPAIGN_REVIEW_MUTATION = gql`
  mutation createSupporterCampaignReviewMutation(
    $input: CreateSupporterCampaignReviewMutationInput!
  ) {
    createSupporterCampaignReview(input: $input) {
      errors {
        field
        messages
      }
      supporterCampaignReview {
        id
        type
        details
      }
    }
  }
`
