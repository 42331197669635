import styled from "@emotion/styled"

export const StyledPageContainer = styled.div`
  padding-top: 15rem;
`

export const StyledTitle = styled.div`
  text-align: center;
  margin-top: 3rem;

  h1 {
    font-size: 2rem;
  }
`

export const StyledDescription = styled.div`
  font-size: 1rem;
  text-align: center;
  color: ${(p) => p.theme.passwordRecoveryDescriptionFontColor};
  margin: 0 auto;

  a {
    color: ${(p) => p.theme.passwordRecoveryDescriptionAnchorColor};
  }
`

export const StyledContinueButtonContainer = styled.div`
  button {
    width: 100%;
  }
  margin-top: 2rem;
`
export const StyledIconContainer = styled.div`
  background-color: ${(p) => p.theme.colors.resetPasswordIconBackgroundColor};
  color: ${(p) => p.theme.colors.resetPasswordIconColor};

  padding: 0.8rem 0.8em;
  font-size: 1.5rem;
  overflow: hidden;
  height: 3.2rem;
  width: 4rem;
  border-radius: 0.25rem 0 0 0.25rem;
`
export const StyledFormContainer = styled.div`
  padding-top: 2rem;
  margin: 0 auto;
  max-width: 22rem;

  .feedback {
    margin-left: -1rem !important;
  }

  .input-feedback {
    margin-left: 4rem;
    margin-top: -1.5rem;
    margin-bottom: 2rem;
  }

  #terms-checkbox .input-feedback {
    margin-left: 2rem;
  }

  #terms-checkbox {
    font-size: 0.9rem;
  }
`

export const StyledEmailWarningText = styled.div`
  padding-top: 4rem;
  margin: 0 auto;
  max-width: 33rem;
  text-align: center;
  font-size: 1rem;
  font-weight: ${(p) => p.theme.fontWeights.black};
`
export const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
`

export const StyledLogoContainer = styled.div`
  text-align: center;
`
