import React from "react"
import { Router } from "@reach/router"
import { SupporterCampaigns } from "@tmu/components/dashboard/dashboardPages"

const SupporterCampaignsRoute = () => (
  <Router>
    <SupporterCampaigns path="/*" />
  </Router>
)

export default SupporterCampaignsRoute
