import { useLazyQuery, useMutation } from "@apollo/client"
import { useApolloApiClients } from "@tmu/apollo/client"
import { NOTIFICATIONS_QUERY } from "@tmu/apollo/storefront/queries/user"
import { NOTIFICATION_MARK_AS_READ_MUTATION } from "@tmu/apollo/storefront/mutations/user"
import { useAuth } from "@tmu/hooks"
import { useEffect } from "react"

const useNotifications = () => {
  const { isAuthenticated, isLoading } = useAuth()
  const { storefrontClient } = useApolloApiClients()

  const [callNotificationMarkAsRead] = useMutation(
    NOTIFICATION_MARK_AS_READ_MUTATION,
    {
      client: storefrontClient,
    }
  )

  const markAsRead = (id) => {
    callNotificationMarkAsRead({
      variables: { id },
    })
  }

  const [callNotifications, { data, called, refetch }] = useLazyQuery(
    NOTIFICATIONS_QUERY,
    {
      client: storefrontClient,
    }
  )

  const notifications = data?.allNotifications?.edges
  let firstNotification = null

  if (notifications?.length) {
    firstNotification = notifications[0]?.node
  }

  useEffect(() => {
    if (isLoading || !isAuthenticated) {
      return
    }

    if (!called) {
      callNotifications()
    }
  }, [isLoading, isAuthenticated])

  return {
    callNotifications,
    notifications,
    firstNotification,
    called,
    markAsRead,
  }
}

export default useNotifications
