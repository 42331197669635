import { LazyImage, TruncatedText } from "@tmu/components/common"
import {
  StyledHeader,
  StyledLogo,
  StyledTitle,
} from "../../partners/PartnerDetail/index.styles"

const DetailHeader = ({
  text,
  logo,
  headerClassName = "",
  titleClassName = "",
}) => {
  return (
    <StyledHeader className={headerClassName}>
      <TruncatedText numLines={4}>
        <StyledTitle className={titleClassName}>{text}</StyledTitle>
      </TruncatedText>
      {logo && (
        <StyledLogo>
          <LazyImage src={logo} altName={`detail-logo${text}`} height={32} />
        </StyledLogo>
      )}
    </StyledHeader>
  )
}

export default DetailHeader
