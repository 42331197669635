export const getNavigatorLanguage = () => {
  if (typeof navigator === `undefined`) {
    return "en"
  }

  const lang =
    navigator &&
    typeof navigator.language === "string" &&
    navigator.language.split("-")[0]
  if (!lang) return "it"

  switch (lang) {
    case "it":
      return "it"
    case "es":
      return "es"
    default:
      return "it"
  }
}

export const getBrowserType = () => {
  if (typeof navigator === `undefined`) {
    return ""
  }
  let userAgent = navigator && navigator.userAgent
  let browserName

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = "chrome"
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = "firefox"
  } else if (userAgent.match(/safari/i)) {
    // browserName = "safari"
  } else if (userAgent.match(/opr\//i)) {
    // browserName = "opera"
  } else if (userAgent.match(/edg/i)) {
    browserName = "edge"
  } else {
    browserName = ""
  }
  return browserName
}
