import styled from "@emotion/styled"
import { Button } from "@tmu/components/common"

export const StyledPacsPartnersContainer = styled.div`
  font-style: ${(p) => p.theme.fontStyles.normal};
  background-color: ${(p) => p.theme.colors.whiteSmoke};

  padding: 2.5rem 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 2.5rem 1.875rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    padding: 3.375rem 1.875rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding: 5rem 6rem;
  }
`

export const StyledPacsPartnersTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;

  margin-bottom: 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-bottom: 1.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin-bottom: 3.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin-bottom: 2.5rem;
  }
`

export const StyledPacsPartnersTitle = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  font-size: 0.75rem;
  line-height: 1.125rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1rem;
    line-height: 1.375rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.75rem;
    line-height: 2.125rem;
  }
`

export const StyledPacsPartnersViewPacOffersButton = styled(Button)`
  p {
    span {
      font-family: ${(p) => p.theme.fontFamilies.headline};
    }
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-size: 0.75rem;
    line-height: 1rem;
    color: ${(p) => p.theme.colors.carrot};

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 1rem;
      line-height: 1.5rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
  }
`

export const StyledPacsPartnersSomeOurPartnersImages = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1.375rem;
  row-gap: 0.75rem;

  img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
  }

  .media-box {
    border-radius: 0;
    height: 3.25rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    grid-template-columns: repeat(8, 1fr);
    gap: 0.75rem;

    .media-box {
      height: 2.75rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    grid-template-columns: repeat(8, 1fr);
    gap: 1.5rem;

    .media-box {
      height: 3.4375rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    grid-template-columns: repeat(8, 1fr);
    gap: 2.5rem;

    .media-box {
      height: 6.2219rem;
    }
  }
`
