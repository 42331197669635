import styled from "@emotion/styled"

export const StyledSliceCounterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 0 0 0 -1rem;
  padding: 3rem 0 3rem;
  width: calc(100% + 2rem) !important;
  align-items: flex-start;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding: 0rem 0 4rem;
  }
`
