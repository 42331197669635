import styled from "@emotion/styled"
import { css } from "@emotion/react"

import { StyledPACMultiplier as CommonStyledPACMultiplier } from "../index.styles"

export const StyledCard = styled.div`
  cursor: pointer;
  background: ${(p) => p.theme.colors.white};
  display: grid;
  &:hover {
    box-shadow: 0 0.125rem 1.5rem rgba(0, 0, 8, 0.3);
  }
  .caption {
    font-size: 0.5rem;
    line-height: 0.75rem;
  }
  .stats {
    width: 100%;
  }
  //user space mobile mobile view
  width: 13.5rem;
  height: 21.125rem;
  grid-template-rows: 7.625rem auto 2.75rem;
  border-radius: 0.25rem;
  border: 0.0625rem solid ${(p) => p.theme.colors.darkGrey};

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    border-radius: 0.25rem;
    .caption {
      font-size: 0.625rem;
      line-height: 1rem;
    }

    .stats {
      padding: 0;
      width: auto;
      p.caption {
        text-align: left;
      }
      p.caption:first-of-type {
        margin-right: 0.75rem;
      }
    }
    // userspace tablet list view
    grid-template-columns: 16.75rem auto;
    grid-template-rows: auto;
    grid-column-gap: 1rem;
    width: 45rem;
    height: 9.4375rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    border-radius: 0.25rem;

    .rc-progress-line {
      bottom: 0.75rem;
    }

    //userspace page list view
    width: 52rem;
    height: 9.4375rem;
    padding: 0;
    grid-template-columns: 16.8125rem auto;
    grid-template-rows: auto;
    grid-column-gap: 1rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    .caption {
      font-size: 1rem;
      line-height: 1.5rem;
    }

    //userspace wide list view
    width: 96.5rem;
    height: 17.5625rem;
    padding: 0;
    grid-template-columns: 31.1875rem auto;
    grid-template-rows: auto;
    grid-column-gap: 1.5rem;
  }
`

export const StyledImageBox = styled.div`
  overflow: hidden;
  position: relative;
  .rc-progress-line {
    position: absolute;
    bottom: 0.5rem;
    margin: 0 0.75rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    grid-row: 1/3;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    ${(p) =>
      p.size === "list"
        ? css`
            //campaign page desktop list view
          `
        : p.size === "tile"
        ? css`
            //campaign page desktop tile view
            border: none;
          `
        : css`
            //mainpage default view
            border: 0.0625rem solid ${p.theme.colors.darkGrey};
            border-bottom: none;
          `}
  }
`

export const StyledDoublePacsTooltip = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 0;
`

export const StyledCardInfo = styled.div`
  border: none;
  padding: 0.5rem;

  ${(p) =>
    p.size === "default"
      ? css`
          //mainpage default mobile view
        `
      : p.size === "tile"
      ? css`
          //tile view
        `
      : css`
          // campaigns page mobile view
        `}

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 0.75rem 0 0;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    ${(p) =>
      p.size === "list"
        ? css`
            //campaign page desktop list view
            border: none;
            padding: 0.75rem 0.75rem 0 0;
          `
        : p.size === "tile"
        ? css`
            //campaign page desktop tile view
            display: block;
            padding: 0.75rem;
            padding-bottom: 0;
          `
        : css`
            //mainpage default view
          `}
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding: 1.5rem 0 0;
  }
`

export const StyledCardTitle = styled.p`
  margin-bottom: 0.25rem;
  cursor: pointer;
  span {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    color: ${(p) => p.theme.colors.black};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-size: 1.125rem;
    line-height: 1.5rem;
    word-break: break-word;
    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }
    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 2rem;
      line-height: 2.625rem;
    }
  }
`

export const StyledCardSubtitle = styled.p``

export const StyledCardSubInfo = styled.div`
  align-self: end;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: flex;
    justify-content: space-between;
    padding: 0 1rem 1rem 0;
  }
`

export const StyledPartnerTitle = styled.div`
  justify-self: right;
  display: flex;
  align-items: center;
  margin-top: 0;
  text-transform: initial;
  img {
    height: 2rem;
    width: 2rem;
    margin: 0 0.5rem 0 0;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    p {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    img {
      height: 3.375rem;
      width: 3.375rem;
      margin: 0 1.25rem 0 0;
    }
  }
`

export const StyledPartnerDetails = styled.div`
  //campaigns page mobile view
  justify-self: end;
  align-self: end;
  > div {
    display: flex;
  }
  p {
    text-align: right;
  }
`

export const StyledPartnerLogo = styled.img`
  object-fit: contain;
  border-radius: 0.25rem;
  height: 2.5rem;
  margin: 0 0.75rem 0 0;
  align-self: center;
  height: 2rem;
  width: 2rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin: 0 0 0 0.75rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    width: 4rem;
    height: 2.25rem;
  }
`

export const StyledPartnerTitleText = styled.p`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-size: 0.75rem;
  line-height: 1rem;
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.colors.black};
  span {
    font-family: ${(p) => p.theme.fontFamilies.headline};
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
`

export const StyledPACMultiplier = styled(CommonStyledPACMultiplier)``
