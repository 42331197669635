import styled from "@emotion/styled"
import { Link } from "gatsby-plugin-intl"

export const StyledCampaignTypesWrapper = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 5rem 1rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin: 5rem 1.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin: 0 2rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
  }
`

export const StyledCampaignTypesPageTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  width: 100%;

  h1 {
    font-size: 2rem;
    color: ${(p) => p.theme.colors.pitchBlack};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    margin: 0 0 0 1rem;
  }
`

export const StyledCampaignCardTypes = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-content: center;
  align-items: center;
  gap: 0.75rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    gap: 1.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    gap: 2.5rem;
  }
`
export const StyledBackButton = styled(Link)`
  text-align: left;
  font-size: 1.5rem;
  color: ${(p) => p.theme.colors.carrot};
`
