import React, { useContext, useEffect } from "react"
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"
import SEO from "@tmu/components/seo"
import { Hero } from "@tmu/components/common"
import {
  GetStarted,
  HowItWorks,
  Benefits,
  OurPricing,
} from "@tmu/components/digital-banking"
import { HomePageContext } from "@tmu/context/homePageContext"

const { CLOUDFLARE_IMAGE_URL } = process.env

const Check = CLOUDFLARE_IMAGE_URL + "/static/assets/images/svg/check.svg"

const Personal = ({ intl }) => {
  const { locale, formatMessage } = intl
  const { setHeaderConfigData } = useContext(HomePageContext)
  useEffect(() => {
    setHeaderConfigData({ transparent: true, whiteText: true })
  }, [])

  const howitWorkCards = [
    {
      title: formatMessage({
        id: "digitalBanking::personal::cards::title1",
        defaultMessage: "Make donations",
      }),
      desc: (
        <FormattedMessage
          id="digitalBanking::personal::cards::description1"
          defaultMessage="Make one-off or recurring donations from the TrustMeUp Personal Banking app or {here} at TrustMeUp.com."
          values={{
            href: (
              <a
                href="/en/campaigns"
                target="_blank"
                aria-label="Click API Docs Here">
                <FormattedMessage
                  id="dashboard::developers::clickAPIDocsHere"
                  defaultMessage="here"
                />
              </a>
            ),
          }}
        />
      ),
    },
    {
      title: formatMessage({
        id: "digitalBanking::personal::cards::title2",
        defaultMessage: "Use your PACs",
      }),
      desc: (
        <FormattedMessage
          id="digitalBanking::personal::cards::description2"
          defaultMessage="Use your PACs as discounts when you do shopping from affiliated merchants offline and online using your TrustMeUp Visa debit card."
          values={{
            pacStore: (
              <a href="/" target="_blank" aria-label="PAC Store">
                <FormattedMessage
                  id="digitalbanking::personal::PACStore"
                  defaultMessage="PAC Store"
                />
              </a>
            ),
          }}
        />
      ),
    },
    {
      title: formatMessage({
        id: "digitalBanking::personal::cards::title3",
        defaultMessage: "Be part of the new Donations Economy",
      }),
      desc: formatMessage({
        id: "digitalBanking::personal::cards::description3",
        defaultMessage:
          "Support the causes you care about and have every donation 100% transformed into digital tokens that you can use when shopping inside our marketplace. It’s that simple!",
      }),
    },
  ]
  const benefitCards = [
    {
      icon: CLOUDFLARE_IMAGE_URL + "/static/assets/images/svg/quick_set_up.svg",
      title: formatMessage({
        id: "digitalBanking::personal::benefitCards::title1",
        defaultMessage: "Quick set-up",
      }),
      desc: formatMessage({
        id: "digitalBanking::personal::benefitCards::description1",
        defaultMessage:
          "No dull paperwork or endless queuing at banks. Open your account and order your card in just a few clicks.",
      }),
    },
    {
      icon:
        CLOUDFLARE_IMAGE_URL + "/static/assets/images/svg/family_banking.svg",
      title: formatMessage({
        id: "digitalBanking::personal::benefitCards::title2",
        defaultMessage: "Family Banking",
      }),
      desc: formatMessage({
        id: "digitalBanking::personal::benefitCards::description2",
        defaultMessage:
          "Share your account with your family and issue up to 4 physical and 5 virtual cards for each member. As an Account Owner, you can control access levels by defining user roles for Card Holders and manage it all from your TrustMeUp Personal Digital Banking app.",
      }),
    },
    {
      icon:
        CLOUDFLARE_IMAGE_URL + "/static/assets/images/svg/user_friendly.svg",
      title: formatMessage({
        id: "digitalBanking::personal::benefitCards::title3",
        defaultMessage: "User-friendly",
      }),
      desc: formatMessage({
        id: "digitalBanking::personal::benefitCards::description3",
        defaultMessage:
          "Both the mobile and web apps are a pleasure to use offering language options, instant notifications, an advanced search function and integrated chat assistance. You can set up categories, download invoices for all donations you make and request spending reports.",
      }),
    },
    {
      icon:
        CLOUDFLARE_IMAGE_URL +
        "/static/assets/images/svg/near_real_time_money.svg",
      title: formatMessage({
        id: "digitalBanking::personal::benefitCards::title4",
        defaultMessage: "Near real-time money transfers",
      }),
      desc: formatMessage({
        id: "digitalBanking::personal::benefitCards::description4",
        defaultMessage:
          "Make faster payments with SEPA Instant, a new bank transfer technology that processes euro transactions in seconds, 24/7.",
      }),
    },
    {
      icon:
        CLOUDFLARE_IMAGE_URL + "/static/assets/images/svg/multi-currency.svg",
      title: formatMessage({
        id: "digitalBanking::personal::benefitCards::title5",
        defaultMessage: "Multi-Currency",
      }),
      desc: formatMessage({
        id: "digitalBanking::personal::benefitCards::description5",
        defaultMessage:
          "Complex and expensive currency exchange is a thing of the past. Choose from a range of currencies to make and receive payments with no hassle.",
      }),
    },
    {
      icon: CLOUDFLARE_IMAGE_URL + "/static/assets/images/svg/vector.svg",
      title: formatMessage({
        id: "digitalBanking::personal::benefitCards::title6",
        defaultMessage: "Even more rewards",
      }),
      desc: formatMessage({
        id: "digitalBanking::personal::benefitCards::description6",
        defaultMessage:
          "Aside from earning PACs every time you use TrustMeUp, being a TrustMeUp Personal account holder means you will receive special discounts with participating stores whenever you use your PAC debit card, whether you have PAC tokens or not!",
      }),
    },
    {
      icon: "",
      title: "",
      desc: "",
    },
    {
      icon: CLOUDFLARE_IMAGE_URL + "/static/assets/images/svg/secure.svg",
      title: formatMessage({
        id: "digitalBanking::personal::benefitCards::title7",
        defaultMessage: "Secure",
      }),
      desc: formatMessage({
        id: "digitalBanking::personal::benefitCards::description7",
        defaultMessage:
          "Rest assured that TrustMeUp Personal Banking uses the best security that tech has to offer. With us you are safe.",
      }),
    },
    {
      icon: "",
      title: "",
      desc: "",
    },
  ]
  const openAccountCard = {
    title: formatMessage({
      id: "digitalBanking::personal::openAccountCard::title",
      defaultMessage: "Free",
    }),
    desc: formatMessage({
      id: "digitalBanking::personal::openAccountCard::desc",
      defaultMessage:
        "Opening a TMU account is free. Any other charges are listed here. Nothing hidden, ever.",
    }),
    buttonText: formatMessage({
      id: "digitalBanking::personal::openAccountCard::button",
      defaultMessage: "Open Account",
    }),
    url: "https://personal.trustmeup.com/onboarding-user",
  }

  const priceCard = {
    rightSlices: [
      {
        title: formatMessage({
          id: "digitalBanking::personal::priceCard::title10",
          defaultMessage: "GBP & Euro Iban",
        }),
        price: formatMessage({
          id: "digitalBanking::personal::tmuTotmu::price",
          defaultMessage: "free",
        }),
      },
      {
        title: formatMessage({
          id: "digitalBanking::personal::priceCard::title1",
          defaultMessage: "Mobile and web app",
        }),
        icon: Check,
      },
      {
        title: formatMessage({
          id: "digitalBanking::personal::priceCard::title2",
          defaultMessage: "Issue family cards",
        }),
        icon: Check,
      },
      {
        title: formatMessage({
          id: "digitalBanking::personal::priceCard::title3",
          defaultMessage: "Debit card",
        }),
        subTitle: formatMessage({
          id: "digitalBanking::personal::priceCard::subtTitle1",
          defaultMessage: "per card",
        }),
        price: formatMessage({
          id: "digitalBanking::personal::debitCard::price",
          defaultMessage: "€9,99",
        }),
      },
      {
        title: formatMessage({
          id: "digitalBanking::personal::priceCard::title4",
          defaultMessage: "Virtual card",
        }),
        subTitle: formatMessage({
          id: "digitalBanking::personal::priceCard::subtTitle1",
          defaultMessage: "per card",
        }),
        price: formatMessage({
          id: "digitalBanking::personal::virtualCard::price",
          defaultMessage: "€0,99",
        }),
      },
      {
        title: formatMessage({
          id: "digitalBanking::personal::priceCard::title5",
          defaultMessage: "Schedule recurring payments",
        }),
        icon: Check,
      },
      {
        title: formatMessage({
          id: "digitalBanking::personal::priceCard::title6",
          defaultMessage: "SEPA in-out",
        }),
        price: formatMessage({
          id: "digitalBanking::personal::sepa::price",
          defaultMessage: "€0,49",
        }),
      },
    ],
    leftSlices: [
      {
        title: formatMessage({
          id: "digitalBanking::personal::priceCard::title7",
          defaultMessage: "Convert & send in 30+ currencies",
        }),
        subTitle: formatMessage({
          id: "digitalBanking::business::priceCardConvert::subtTitle1",
          defaultMessage: "per transaction (swift)",
        }),
        price: formatMessage({
          id: "digitalBanking::personal::convertSend::price",
          defaultMessage: "€14,99",
        }),
      },
      {
        title: formatMessage({
          id: "digitalBanking::personal::priceCard::title8",
          defaultMessage: "ATM transaction",
        }),
        subTitle: formatMessage({
          id: "digitalBanking::personal::priceCard::subtTitle2",
          defaultMessage: "per transaction",
        }),
        price: formatMessage({
          id: "digitalBanking::personal::atmTransaction::price",
          defaultMessage: "€1,99",
        }),
      },
      {
        title: formatMessage({
          id: "digitalBanking::personal::priceCard::title9",
          defaultMessage: "TMU to TMU",
        }),
        price: formatMessage({
          id: "digitalBanking::personal::tmuTotmu::price",
          defaultMessage: "free",
        }),
      },
    ],
    comingSoonTitle: formatMessage({
      id: "digitalBanking::personal::priceCard::comingSoonTitle",
      defaultMessage: "Coming soon",
    }),
    comingSoon: [
      formatMessage({
        id: "digitalBanking::personal::priceCard::comingSoon1",
        defaultMessage: "Scan & pay",
      }),
      formatMessage({
        id: "digitalBanking::personal::priceCard::comingSoon2",
        defaultMessage: "Card-to-card payments",
      }),
      formatMessage({
        id: "digitalBanking::personal::priceCard::comingSoon3",
        defaultMessage: "Spending rewards",
      }),
    ],
  }
  return (
    <>
      <SEO
        lang={locale}
        title={formatMessage({
          id: "digitalBanking::personal::pageTitle",
          defaultMessage: "Digital Banking Personal",
        })}
        image={
          CLOUDFLARE_IMAGE_URL +
          "/cdn-cgi/image/fit=cover,format=webp,quality=90/static/assets/images/personal_main_img.png"
        }
      />
      <Hero page={`/${locale}/digital-banking/personal`} />

      <>
        <GetStarted
          description={
            <FormattedMessage
              id="digitalBanking::personal::getStarted"
              defaultMessage="Become part of the Donations Economy and get rewarded for contributing to the non-profit causes you care about."
            />
          }
          image={
            CLOUDFLARE_IMAGE_URL +
            "/cdn-cgi/image/fit=cover,format=webp,quality=90/static/assets/images/personal_main_img.png"
          }
        />
        <HowItWorks
          id="how-it-works"
          title={
            <FormattedMessage
              id="digitalBanking::personal::howitworks::title"
              defaultMessage="How it works"
            />
          }
          description={
            <FormattedMessage
              id="digitalBanking::personal::howitworks::description"
              defaultMessage="Whenever you donate to your favorite nonprofit cause or association, TrustMeUp rewards you with digital tokens (1 Euro = 1 PAC) that can be used for purchases inside the TrustMeUp shopping gallery."
            />
          }
          cards={howitWorkCards}
          downloadTitle={
            <FormattedMessage
              id="digitalBanking::personal::howitworks::downloadTitle"
              defaultMessage="Download the TrustMeUp Personal Banking app now to open your account, order your card and get started."
            />
          }
          appleStoreUrl="https://apps.apple.com/tr/app/trustmeup-personal/id1534864323/"
          playStoreUrl="https://play.google.com/store/apps/details?id=com.personal.tmup"
          image={
            CLOUDFLARE_IMAGE_URL +
            "/cdn-cgi/image/fit=cover,format=webp,quality=90/static/assets/images/personal_mobile_app.png"
          }
        />
        <Benefits
          title={
            <FormattedMessage
              id="digitalBanking::personal::benefits::title"
              defaultMessage="Why open a PAC Personal Bank account?"
            />
          }
          desc={
            <FormattedMessage
              id="digitalBanking::personal::benefits::desc"
              defaultMessage="A TrustMeUp Personal account gives you much more than a debit card and a way to store and spend your PACs tokens…"
            />
          }
          subDesc={
            <FormattedMessage
              id="digitalBanking::personal::benefits::subDesc"
              defaultMessage="The TrustMeUp Personal account and PAC Visa card features and benefits include:"
            />
          }
          benefits={benefitCards}
          page="personal"
        />

        <OurPricing
          title={
            <FormattedMessage
              id="digitalBanking::personal::ourPricing::title"
              defaultMessage="Our Pricing"
            />
          }
          openAccountCard={openAccountCard}
          priceCard={priceCard}
        />
      </>
    </>
  )
}

export default injectIntl(Personal)
