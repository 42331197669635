import React from "react"
import theme from "@tmu/global/theme"

const Logo = ({
  width = 40,
  height = 33,
  fill = theme?.colors?.primaryTextColor,
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      color={fill}
      viewBox="0 0 40 33"
      className="logo"
      {...props}>
      <path
        d="M20.504 19.886l.064-.255-.177-.094a3.233 3.233 0 01-1.648-2.8l.005-.178c.093-1.647 1.495-3.015 3.193-3.073h.035l.04.004.041.003.041-.003.041-.004h.035l.18.01c1.676.151 3.02 1.575 3.017 3.24l-.007.21a3.245 3.245 0 01-1.817 2.685l.063.255h4.955l.006.078c.015.08.055.161.122.253l.393.542c.258.363.51.732.755 1.104l.154.238c.76 1.19 1.36 2.44 1.317 3.927l-.02.254c-.016.255-.03.511-.08.76l-.057.264c-.239 1.054-.656 2.033-1.419 2.82l-.226.224c-1.072 1.012-2.373 1.595-3.84 1.818l-.29.036a5.021 5.021 0 01-2.516-.401l-.388-.175a12.546 12.546 0 01-2.495-1.577l-.95-.759c-1.265-1.011-2.528-2.026-3.74-3.1l-.95-.863c-1.257-1.163-2.473-2.373-3.72-3.548l-.574-.526c-.387-.346-.78-.688-1.159-1.041l-.077-.076c-.09-.096-.13-.173-.125-.252h11.691l-1.333 5.47-.019.106a.845.845 0 00.836.943h4.125l.108-.007a.847.847 0 00.71-1.043l-1.333-5.47h-2.967zM20 0l.344.003C31.23.186 40 9.017 40 19.886H28.575l.01-.078a.686.686 0 01.127-.246l.413-.562c.272-.377.537-.76.792-1.147l.167-.258c.605-.95 1.1-1.949 1.206-3.095l.021-.323a6.198 6.198 0 00-.535-2.797l-.117-.251c-.846-1.725-2.28-2.748-4.131-3.233l-.285-.068c-1.701-.362-3.292.054-4.748 1.003l-.704.478c-.698.49-1.377 1.01-2.072 1.505l-.646.478c-1.914 1.462-3.61 3.17-5.353 4.823l-.75.692c-.757.684-1.535 1.348-2.297 2.029l-.399.364-.391.372-.072.073c-.086.093-.13.168-.136.24H0C0 8.904 8.955 0 19.999 0z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  )
}

export default Logo
