import React, { Fragment } from "react"

import { FormattedMessage } from "gatsby-plugin-intl"

import { Money } from "@tmu/components/common"

import {
  StyledYourDiscountTextTitle,
  StyledYourDiscountTextDescription,
  StyledYourDiscountText,
  StyledDonationWithMultiplierTextBox,
} from "./index.styles"

export const PartialForOfflineDonation = ({
  userBalance,
  isLowerThenMinDonation,
  pacReconDetail,
  pacsLogoContent,
  isOfflineNoDonationNeeded,
  pacMultiplier,
  amountData,
}) => {
  return (
    <StyledYourDiscountText>
      <StyledYourDiscountTextTitle>
        <FormattedMessage
          id="checkout::pacDiscount::title"
          defaultMessage="Your discount amount is <amount>{totalAmount}</amount>"
          values={{
            totalAmount: <Money value={pacReconDetail?.totalPacsRequired} />,
            amount: (...chunks) => (
              <>
                {chunks.map((chunk, i) => (
                  <Fragment key={i}>{chunk}</Fragment>
                ))}
              </>
            ),
          }}
        />
      </StyledYourDiscountTextTitle>
      {userBalance > 0 && (
        <StyledYourDiscountTextDescription>
          <FormattedMessage
            id="checkout::pacDiscount::partialDonation"
            defaultMessage="You have <pacs>{totalAmount}</pacs> PACs in your PAC Wallet"
            values={{
              totalAmount: <Money value={userBalance} currency={false} />,
              pacs: (...chunks) => (
                <>
                  {chunks.map((chunk, i) => (
                    <div className="inline" key={i}>
                      {pacsLogoContent}
                      {chunk}
                    </div>
                  ))}
                </>
              ),
            }}
          />
        </StyledYourDiscountTextDescription>
      )}
      {isOfflineNoDonationNeeded ? (
        <StyledYourDiscountTextDescription>
          <FormattedMessage
            id="checkout::pacDiscount::noDonationNeeded"
            defaultMessage="No donation needed, you will use <pacs>{totalAmount}</pacs> PACs to get your discount"
            values={{
              totalAmount: (
                <Money
                  value={pacReconDetail?.totalPacsRequired}
                  currency={false}
                />
              ),
              pacs: (...chunks) => (
                <>
                  {chunks.map((chunk, i) => (
                    <div className="inline" key={i}>
                      {pacsLogoContent}
                      {chunk}
                    </div>
                  ))}
                </>
              ),
            }}
          />
        </StyledYourDiscountTextDescription>
      ) : isLowerThenMinDonation ? (
        <StyledYourDiscountTextDescription>
          <FormattedMessage
            id="checkout::pacDiscount::minDonationPlus"
            defaultMessage="Minimum donation is <blue>€5</blue>, so your donation to {campaignName}
                      is <blue>{normalizedDonationAmount}</blue> and you will use <pacs>{diff}</pacs> PACs to get your discount."
            values={{
              normalizedDonationAmount: (
                <Money value={pacReconDetail?.normalizedDonationAmount || 0} />
              ),
              campaignName: pacReconDetail?.campaign?.name,
              diff: (
                <Money
                  value={Number(5 - pacReconDetail?.totalPacsRequired)}
                  currency={false}
                />
              ),
              pacs: (...chunks) => (
                <>
                  {chunks.map((chunk, i) => (
                    <div className="inline" key={i}>
                      {pacsLogoContent}
                      {chunk}
                    </div>
                  ))}
                </>
              ),
              amount: (...chunks) => (
                <>
                  {chunks.map((chunk, i) => (
                    <Fragment key={i}>{chunk}</Fragment>
                  ))}
                </>
              ),
              blue: (...chunks) => (
                <span className="blue">
                  {chunks.map((chunk, i) => (
                    <Fragment key={i}>{chunk}</Fragment>
                  ))}
                </span>
              ),
            }}
          />
        </StyledYourDiscountTextDescription>
      ) : (
        <StyledYourDiscountTextDescription>
          <FormattedMessage
            id="checkout::pacDiscount::fullDonation"
            defaultMessage="Your donation to {campaignName} is <blue>{totalAmount}</blue>"
            values={{
              totalAmount: (
                <Money value={pacReconDetail?.normalizedDonationAmount} />
              ),
              campaignName: pacReconDetail?.campaign?.name,
              blue: (...chunks) => (
                <span className="blue">
                  {chunks.map((chunk, i) => (
                    <Fragment key={i}>{chunk}</Fragment>
                  ))}
                </span>
              ),
            }}
          />
        </StyledYourDiscountTextDescription>
      )}
      {Number(pacMultiplier) > 1 && amountData && !isOfflineNoDonationNeeded ? (
        <StyledDonationWithMultiplierTextBox data-testid="donation-with-multiplier-text">
          <FormattedMessage
            id="campaign::donationSuccess::donatingWithMultiplier"
            defaultMessage="You are donating to <used> X {pacMultiplier}</used> campaign, so you will get additional <used>{earnedPACs}</used> PACs for your future purchases"
            values={{
              pacMultiplier: <Money value={pacMultiplier} currency={false} />,
              earnedPACs: (
                <Money
                  value={Number(pacReconDetail?.pacMultiplierSurplus || 0)}
                  currency={false}
                />
              ),
              used: (...chunks) => (
                <span key="used">
                  {chunks.map((chunk, i) => (
                    <div className="inline" key={i}>
                      {pacsLogoContent}
                      {chunk}
                    </div>
                  ))}
                </span>
              ),
            }}
          />
        </StyledDonationWithMultiplierTextBox>
      ) : null}
    </StyledYourDiscountText>
  )
}
