import { useLazyQuery, useMutation } from "@apollo/client"
import { useApolloApiClients } from "@tmu/apollo/client"
import { DONATE_BY_SHOPPING_MUTATION } from "@tmu/apollo/storefront/mutations/campaign"
import { useAuth } from "@tmu/hooks"
import { PARTNER_DONATION_LISTING_QUERY } from "@tmu/apollo/dashboard/queries/partner"
import {
  DONATION_LISTING_QUERY,
  DONATION_QUERY,
  LIMITED_DONATION_SEARCH_QUERY,
} from "@tmu/apollo/storefront/queries/donation"
import { PER_PAGE } from "@tmu/apollo/constants"
import {
  CANCEL_DONATION_QUERY,
  DONATION_PROVISION_MUTATION,
} from "@tmu/apollo/storefront/mutations/donation"

const useDonations = (options = { dashboardType: "" }) => {
  const { storefrontClient, partnerClient } = useApolloApiClients()
  const { user } = useAuth()
  const dashboardType = options?.dashboardType

  const [createDonateByShoppingMutation, { error, data }] = useMutation(
    DONATE_BY_SHOPPING_MUTATION,
    {
      client: storefrontClient,
    }
  )

  const getShopByDonationData = (dst) => {
    createDonateByShoppingMutation({
      variables: {
        input: {
          token: dst,
        },
      },
    })
  }

  const [callDonation, donationItemData] = useLazyQuery(DONATION_QUERY, {
    client: storefrontClient,
  })

  const [callLimitedDonation, limitedDonationData] = useLazyQuery(
    LIMITED_DONATION_SEARCH_QUERY,
    {
      client: storefrontClient,
    }
  )

  const [callDonationProvision, donationProvisionData] = useMutation(
    DONATION_PROVISION_MUTATION,
    {
      client: storefrontClient,
    }
  )

  const [callCancelDonation, cancelDonationData] = useMutation(
    CANCEL_DONATION_QUERY,
    {
      client: storefrontClient,
    }
  )

  const QUERY =
    dashboardType === "partners"
      ? PARTNER_DONATION_LISTING_QUERY
      : DONATION_LISTING_QUERY
  const perPage = PER_PAGE
  const [
    callDonations,
    {
      loading,
      data: donationsData,
      called: callDonationsCalled,
      fetchMore,
      refetch: callDonationsRefetch,
    },
  ] = useLazyQuery(QUERY, {
    variables: {
      first: perPage,
      status: "SUCCESS",
    },
    client: dashboardType === "partners" ? partnerClient : storefrontClient,
  })

  const donations =
    donationsData?.allDonations?.edges?.map(({ node }) => {
      const donation =
        dashboardType === "partners"
          ? node
          : {
              ...node,
              user,
              stripePaymentIntentId: node?.stripePaymentIntentId ?? "",
            }
      return donation
    }) ?? []

  return {
    getShopByDonationData,
    data,
    error,
    donations: {
      callDonations,
      callDonationsRefetch,
      fetchMore,
      loading,
      donationsData,
      donations,
      pageInfo: donationsData?.allDonations?.pageInfo,
    },
    callDonation,
    donation: donationItemData?.data?.donation,
    donationItemData,
    callCancelDonation,
    cancelDonationData,
    callLimitedDonation,
    limitedDonationData,
    limitedDonation: limitedDonationData?.data?.limitedDonation,
    callDonationProvision,
    donationProvisionData: {
      ...donationProvisionData,
      provisionData:
        donationProvisionData?.data?.createDonationProvision?.donationProvision,
    },
  }
}

export default useDonations
