import React from "react"
import PropTypes from "prop-types"
import { faPencil } from "@fortawesome/pro-light-svg-icons/faPencil"
import { faTrash } from "@fortawesome/pro-light-svg-icons/faTrash"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, TruncatedText, Date } from "@tmu/components/common"
import { StyledCampaignFAQTableRow } from "./index.styles"

const CampaignFAQTableRow = ({
  style,
  question,
  slug,
  id,
  answer,
  created,
  modified,
  onEdit,
  onDelete,
}) => {
  return (
    <StyledCampaignFAQTableRow style={style}>
      <div className="desc">
        <TruncatedText numLines={3}>{question}</TruncatedText>
      </div>
      <div className="desc">
        <TruncatedText numLines={3}>{answer}</TruncatedText>
      </div>
      <div className="centered">
        <Date value={modified || created} />
      </div>
      <div className="centered">
        <Button
          variant="text"
          label="Edit campaign"
          data-testid="btn-edit-campaign"
          data-cy-slug={slug}
          onClick={() => onEdit(slug)}>
          <FontAwesomeIcon icon={faPencil} />
        </Button>
        <Button
          variant="text"
          label="Delete campaign"
          data-testid="btn-delete-campaign"
          onClick={() => onDelete({ question, slug, id })}>
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </div>
    </StyledCampaignFAQTableRow>
  )
}

CampaignFAQTableRow.propTypes = {
  index: PropTypes.number,
  style: PropTypes.object,
  name: PropTypes.string,
  slug: PropTypes.string,
  shortDescription: PropTypes.string,
  image: PropTypes.string,
  created: PropTypes.string,
  deadline: PropTypes.string,
  goalAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxPacDiscount: PropTypes.number,
  status: PropTypes.string,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
}

export default CampaignFAQTableRow
