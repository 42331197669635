import { useState, useEffect } from "react"

const useIBAN = () => {
  const [iban, setIban] = useState(null)

  useEffect(() => {
    const IBAN = require("@tmu/utils/iban")
    setIban(IBAN)
  }, [])

  return iban
}

export default useIBAN
