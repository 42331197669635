exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-calculate-index-js": () => import("./../../../src/pages/calculate/index.js" /* webpackChunkName: "component---src-pages-calculate-index-js" */),
  "component---src-pages-callback-js": () => import("./../../../src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-campaigns-js": () => import("./../../../src/pages/campaigns.js" /* webpackChunkName: "component---src-pages-campaigns-js" */),
  "component---src-pages-charities-js": () => import("./../../../src/pages/charities.js" /* webpackChunkName: "component---src-pages-charities-js" */),
  "component---src-pages-corporate-js": () => import("./../../../src/pages/corporate.js" /* webpackChunkName: "component---src-pages-corporate-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-digital-banking-business-js": () => import("./../../../src/pages/digital-banking/business.js" /* webpackChunkName: "component---src-pages-digital-banking-business-js" */),
  "component---src-pages-digital-banking-personal-js": () => import("./../../../src/pages/digital-banking/personal.js" /* webpackChunkName: "component---src-pages-digital-banking-personal-js" */),
  "component---src-pages-donate-by-shopping-js": () => import("./../../../src/pages/donate-by-shopping.js" /* webpackChunkName: "component---src-pages-donate-by-shopping-js" */),
  "component---src-pages-donation-search-js": () => import("./../../../src/pages/donation-search.js" /* webpackChunkName: "component---src-pages-donation-search-js" */),
  "component---src-pages-extension-index-js": () => import("./../../../src/pages/extension/index.js" /* webpackChunkName: "component---src-pages-extension-index-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-fundraise-index-js": () => import("./../../../src/pages/fundraise/index.js" /* webpackChunkName: "component---src-pages-fundraise-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-corporate-js": () => import("./../../../src/pages/join/corporate.js" /* webpackChunkName: "component---src-pages-join-corporate-js" */),
  "component---src-pages-join-donors-js": () => import("./../../../src/pages/join/donors.js" /* webpackChunkName: "component---src-pages-join-donors-js" */),
  "component---src-pages-join-index-js": () => import("./../../../src/pages/join/index.js" /* webpackChunkName: "component---src-pages-join-index-js" */),
  "component---src-pages-join-merchant-internal-js": () => import("./../../../src/pages/join/merchant/internal.js" /* webpackChunkName: "component---src-pages-join-merchant-internal-js" */),
  "component---src-pages-join-merchant-offline-js": () => import("./../../../src/pages/join/merchant/offline.js" /* webpackChunkName: "component---src-pages-join-merchant-offline-js" */),
  "component---src-pages-join-merchant-online-js": () => import("./../../../src/pages/join/merchant/online.js" /* webpackChunkName: "component---src-pages-join-merchant-online-js" */),
  "component---src-pages-join-merchants-js": () => import("./../../../src/pages/join/merchants.js" /* webpackChunkName: "component---src-pages-join-merchants-js" */),
  "component---src-pages-join-ngo-js": () => import("./../../../src/pages/join/ngo.js" /* webpackChunkName: "component---src-pages-join-ngo-js" */),
  "component---src-pages-join-non-profit-js": () => import("./../../../src/pages/join/non-profit.js" /* webpackChunkName: "component---src-pages-join-non-profit-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-merchant-registration-js": () => import("./../../../src/pages/merchant-registration.js" /* webpackChunkName: "component---src-pages-merchant-registration-js" */),
  "component---src-pages-offers-js": () => import("./../../../src/pages/offers.js" /* webpackChunkName: "component---src-pages-offers-js" */),
  "component---src-pages-pacs-index-js": () => import("./../../../src/pages/pacs/index.js" /* webpackChunkName: "component---src-pages-pacs-index-js" */),
  "component---src-pages-password-recovery-index-js": () => import("./../../../src/pages/password-recovery/index.js" /* webpackChunkName: "component---src-pages-password-recovery-index-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-redirect-js": () => import("./../../../src/pages/redirect.js" /* webpackChunkName: "component---src-pages-redirect-js" */),
  "component---src-pages-reset-password-index-js": () => import("./../../../src/pages/reset-password/index.js" /* webpackChunkName: "component---src-pages-reset-password-index-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-set-password-index-js": () => import("./../../../src/pages/set-password/index.js" /* webpackChunkName: "component---src-pages-set-password-index-js" */),
  "component---src-pages-signin-js": () => import("./../../../src/pages/signin.js" /* webpackChunkName: "component---src-pages-signin-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-user-space-index-js": () => import("./../../../src/pages/user-space/index.js" /* webpackChunkName: "component---src-pages-user-space-index-js" */),
  "component---src-pages-welcome-index-js": () => import("./../../../src/pages/welcome/index.js" /* webpackChunkName: "component---src-pages-welcome-index-js" */)
}

