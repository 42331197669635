import React, { useCallback, useState, useEffect } from "react"
import { FormattedMessage, useIntl, navigate } from "gatsby-plugin-intl"
import { Tooltip } from "react-tooltip"
import { useQuery, useMutation } from "@apollo/client"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import { faHandsHeart } from "@fortawesome/pro-light-svg-icons/faHandsHeart"
import { faCog } from "@fortawesome/pro-light-svg-icons/faCog"
import { faFileSignature } from "@fortawesome/pro-light-svg-icons/faFileSignature"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import {
  Spinner,
  Button,
  CheckboxContainer,
  InfoBox,
  OnSubmitValidationError,
  CustomModal,
} from "@tmu/components/common"
import { ImageGallery } from "@tmu/components/dashboard/dashboardCommon"
import {
  useToast,
  useDefaultPartner,
  useAuth,
  useCampaignTypes,
} from "@tmu/hooks"
import { modalStyles } from "@tmu/global/GlobalStyle"

import {
  PARTNER_CAMPAIGN_LISTING_QUERY,
  PARTNER_CAMPAIGN_DETAIL_QUERY,
} from "@tmu/apollo/dashboard/queries/campaign"
import {
  CREATE_PARTNER_CAMPAIGN_MUTATION,
  UPDATE_CAMPAIGN_MUTATION,
  CREATE_CAMPAIGN_IMAGE_MUTATION,
  DELETE_CAMPAIGN_IMAGE_MUTATION,
  CAMPAIGN_SUBMIT_FOR_REVIEW_MUTATION,
  END_CAMPAIGN_MUTATION,
} from "@tmu/apollo/dashboard/mutations/campaign"
import { useApolloApiClients } from "@tmu/apollo/client"
import {
  StyledPage,
  StyledPageContent,
  StyledPageTitle,
  StyledPageActions,
  StyledModalActions,
  StyledWarningWrapper,
  StyledFormSectionTitle,
  StyledFormSectionHelpText,
} from "@tmu/global/page-addons/dashboard.styles"
import Basics from "./views/Basics"
import {
  campaignName,
  description,
  shortDescription,
  goalAmount,
  startDate,
  endDate,
  categoryList,
  campaignType,
} from "@tmu/utils/validation"
import { removeEmptyHTMLTags } from "@tmu/utils/string"
import {
  REVIEW_STATUS,
  API_PERMISSIONS,
  PER_PAGE,
  VISIBILITY_STATUS,
} from "@tmu/apollo/constants"
import { renameKeys } from "@tmu/utils/renameKeys"

const CampaignForm = ({ slug, location }) => {
  const { campaignTypes } = useCampaignTypes()
  const [selectedCampaignType, setSelectedCampaignType] = useState()
  const [fullValidate, setFullValidate] = useState(false)

  const [activeLangs, setActiveLangs] = useState({
    english: true,
    italian: false,
    spanish: false,
  })
  const { formatMessage } = useIntl()
  const [isEndModalOpen, setEndModalOpen] = useState(false)
  const [campaignEnd, setCampaignEnd] = useState(null)

  const [, formStep] = location.pathname.split(`/${slug}/`)
  const { loading: loadingProfile, user, apiPermissions } = useAuth()
  const { defaultPartner, loading: loadingPartner } = useDefaultPartner()
  const { partnerClient } = useApolloApiClients()
  const { loading, data } = useQuery(PARTNER_CAMPAIGN_DETAIL_QUERY, {
    variables: {
      slug,
    },
    skip: !slug || slug === "create" || loadingPartner || !user?.isPartner,
    client: partnerClient,
    fetchPolicy: "cache-and-network",
  })
  const campaign = data?.campaign

  useEffect(() => {
    if (loading) {
      return
    }
    const english =
      campaign?.nameEn?.length > 0 ||
      removeEmptyHTMLTags(campaign?.descriptionEn)?.length > 0 ||
      campaign?.shortDescriptionEn?.length > 0
    const italian =
      campaign?.nameIt?.length > 0 ||
      removeEmptyHTMLTags(campaign?.descriptionIt)?.length > 0 ||
      campaign?.shortDescriptionIt?.length > 0
    const spanish =
      campaign?.nameEs?.length > 0 ||
      removeEmptyHTMLTags(campaign?.descriptionEs)?.length > 0 ||
      campaign?.shortDescriptionEs?.length > 0
    setActiveLangs({
      english: campaign?.id ? english : true,
      italian,
      spanish,
    })
    setSelectedCampaignType(campaign?.campaignType)
  }, [campaign])

  const validationSchemaBasic = Yup.object().shape({
    nameTabEn: activeLangs.english && campaignName({ formatMessage }),
    descriptionTabEn: activeLangs.english && description({ formatMessage }),
    shortDescriptionTabEn:
      activeLangs.english && shortDescription({ formatMessage }),
    campaignType: campaignType({ formatMessage }),
  })

  const validationSchema = Yup.object().shape({
    nameTabEn: activeLangs.english && campaignName({ formatMessage }),
    descriptionTabEn: activeLangs.english && description({ formatMessage }),
    shortDescriptionTabEn:
      activeLangs.english && shortDescription({ formatMessage }),
    nameTabIt: activeLangs.italian && campaignName({ formatMessage }),
    descriptionTabIt: activeLangs.italian && description({ formatMessage }),
    shortDescriptionTabIt:
      activeLangs.italian && shortDescription({ formatMessage }),
    nameTabEs: activeLangs.spanish && campaignName({ formatMessage }),
    descriptionTabEs: activeLangs.spanish && description({ formatMessage }),
    shortDescriptionTabEs:
      activeLangs.spanish && shortDescription({ formatMessage }),
    goalAmount:
      selectedCampaignType === campaignTypes[1]?.id &&
      goalAmount({ formatMessage }),
    startsAt: startDate({ formatMessage }),
    deadline:
      selectedCampaignType === campaignTypes[3]?.id &&
      endDate({ formatMessage }),
    categories: categoryList({ formatMessage }),
  })

  const [createCampaign] = useMutation(CREATE_PARTNER_CAMPAIGN_MUTATION, {
    client: partnerClient,
    // refetchQueries: [
    //   {
    //     query: PARTNER_CAMPAIGN_LISTING_QUERY,
    //     variables: {
    //       first: PER_PAGE,
    //     },
    //   },
    // ],
  })
  const [updateCampaign] = useMutation(UPDATE_CAMPAIGN_MUTATION, {
    client: partnerClient,
    refetchQueries: [
      {
        query: PARTNER_CAMPAIGN_LISTING_QUERY,
        variables: {
          first: PER_PAGE,
        },
      },
    ],
  })
  const [submitForReview] = useMutation(CAMPAIGN_SUBMIT_FOR_REVIEW_MUTATION, {
    client: partnerClient,
    refetchQueries: [
      {
        query: PARTNER_CAMPAIGN_LISTING_QUERY,
        variables: {
          first: PER_PAGE,
        },
      },
    ],
  })
  const [createCampaignImage] = useMutation(CREATE_CAMPAIGN_IMAGE_MUTATION, {
    client: partnerClient,
  })
  const [deleteCampaignImage] = useMutation(DELETE_CAMPAIGN_IMAGE_MUTATION, {
    client: partnerClient,
  })
  const [endCampaign] = useMutation(END_CAMPAIGN_MUTATION, {
    client: partnerClient,
    refetchQueries: [
      {
        query: PARTNER_CAMPAIGN_LISTING_QUERY,
        variables: {
          after: data?.allCampaigns?.pageInfo?.endCursor,
          variables: {
            first: PER_PAGE,
          },
        },
      },
    ],
  })

  const [processingImages, setProcessingImages] = useState([])
  const initialValues = {
    id: campaign?.id,
    nameTabEn: campaign?.nameEn || campaign?.name || "",
    descriptionTabEn: campaign?.descriptionEn || campaign?.description || "",
    shortDescriptionTabEn:
      campaign?.shortDescriptionEn || campaign?.shortDescription || "",
    nameTabIt: campaign?.nameIt || "",
    descriptionTabIt: campaign?.descriptionIt || "",
    shortDescriptionTabIt: campaign?.shortDescriptionIt || "",
    nameTabEs: campaign?.nameEs || "",
    descriptionTabEs: campaign?.descriptionEs || "",
    shortDescriptionTabEs: campaign?.shortDescriptionEs || "",
    goalAmount: campaign?.goalAmount || 0,
    startsAt: campaign?.startsAt || null,
    deadline: campaign?.deadline || null,
    video: campaign?.video || "",
    categories: campaign?.categories?.edges?.map(({ node }) => node.id) ?? [],
    partner: defaultPartner?.id || "",
    images:
      data?.campaign?.images?.edges?.map(({ node }) => ({
        ...node,
        existing: true,
      })) ?? [],
    campaignType: campaign?.campaignType || "",
  }

  const isUpdate = campaign?.id?.length > 0
  const { success, error } = useToast()

  const handleEndCampaign = (id) => {
    setCampaignEnd({ id })
    setEndModalOpen(true)
  }

  const handleConfirmEnd = () => {
    setCampaignEnd(null)
    setEndModalOpen(false)
    endCampaign({ variables: { input: { id: campaignEnd?.id } } }).then(
      ({ data }) => {
        if (data.endCampaign.errors.length) {
          data.endCampaign.errors.map((err) => error(err.messages?.[0]))
        } else if (data.endCampaign.success) {
          success(
            formatMessage({
              id: "dashboard::campaignList::campaignEnded",
              defaultMessage: "The campaign is ended successfully",
            })
          )
        }
      }
    )
  }

  const handleCancelEnd = () => {
    setCampaignEnd(null)
    setEndModalOpen(false)
  }

  const handleSubmit = useCallback(
    (values, { setSubmitting }) => {
      if (defaultPartner?.status === REVIEW_STATUS.WAITING_FOR_APPROVAL) {
        error(
          formatMessage({
            id: "dashboard::campaignForm::partnerWaitingApproval",
            defaultMessage: "Your partner account is waiting for review!",
          })
        )
        return
      }
      setSubmitting(true)
      let newKeyMappings = {
        descriptionTabEn: "descriptionEn",
        descriptionTabEs: "descriptionEs",
        descriptionTabIt: "descriptionIt",
        nameTabEn: "nameEn",
        nameTabEs: "nameEs",
        nameTabIt: "nameIt",
        shortDescriptionTabEn: "shortDescriptionEn",
        shortDescriptionTabEs: "shortDescriptionEs",
        shortDescriptionTabIt: "shortDescriptionIt",
      }
      let mappingValues = renameKeys(newKeyMappings, values)
      // Prepare and clean input variables
      let newValues = {
        ...mappingValues,
        name: mappingValues?.nameEn || "",
        description: mappingValues?.descriptionEn || "",
        shortDescription: mappingValues?.shortDescriptionEn || "",
        goalAmount: mappingValues.goalAmount
          ? Number(mappingValues.goalAmount)
          : null,
        categories: mappingValues.categories || [],
        partner: defaultPartner?.id || "",
        visibilityStatus: values?.isUnlisted
          ? VISIBILITY_STATUS?.UNLISTED
          : VISIBILITY_STATUS?.ARCHIVED,
      }
      // Delete id
      delete newValues.id

      // Delete images prop
      const campaignImages = newValues.images
      delete newValues.images

      const resultFieldName = fullValidate
        ? "campaignSubmitForReview"
        : campaign?.id
        ? "updateCampaign"
        : "createCampaign"

      const campaignMutationSuccessMessage = fullValidate
        ? formatMessage({
            id: "dashboard::campaignForm::updateMessage",
            defaultMessage: "Your updates are submitted for review!",
          })
        : campaign?.id
        ? formatMessage({
            id: "dashboard::campaignForm::updated",
            defaultMessage: "Your campaign is updated!",
          })
        : formatMessage({
            id: "dashboard::campaignForm::created",
            defaultMessage: "Your campaign is created!",
          })

      const resultHandler = ({
        data: {
          [resultFieldName]: { campaign, errors },
        },
      }) => {
        if (errors.length) {
          errors.map((err) => error(err.messages?.[0]))
          setSubmitting(false)
        } else {
          // Save new campaign images
          const newImages = campaignImages.filter(
            (img) => img.id.indexOf("user-upload") > -1
          )

          if (newImages.length) {
            setProcessingImages(newImages.map(({ id }) => id))
            Promise.all(
              newImages.map(({ image, order, id }) =>
                createCampaignImage({
                  variables: {
                    input: {
                      image,
                      order,
                      campaign: campaign?.id,
                    },
                  },
                })
              )
            ).then(() => {
              setProcessingImages([])
              setSubmitting(false)
              success(campaignMutationSuccessMessage)
              navigate(`/dashboard/charities/campaigns`)
            })
          } else {
            setSubmitting(false)
            success(campaignMutationSuccessMessage)
            navigate(`/dashboard/charities/campaigns`)
          }
        }
      }

      const errorHandler = (err) => {
        error(
          err?.message ??
            formatMessage({
              id: "dashboard::campaignForm::errorMessage",
              defaultMessage: "An error occurred",
            })
        )
        setSubmitting(false)
      }

      if (fullValidate) {
        submitForReview({
          variables: {
            input: {
              ...newValues,
              id: campaign?.id,
            },
          },
          client: partnerClient,
        })
          .then(resultHandler)
          .catch(errorHandler)
      } else if (isUpdate) {
        updateCampaign({
          variables: {
            input: {
              ...newValues,
              id: campaign?.id,
            },
          },
          client: partnerClient,
        })
          .then(resultHandler)
          .catch(errorHandler)
      } else {
        createCampaign({
          variables: {
            input: newValues,
          },
          client: partnerClient,
        })
          .then(resultHandler)
          .catch(errorHandler)
      }
    },
    [campaign, defaultPartner]
  )

  const handleModalClose = () => {
    setEndModalOpen(false)
  }

  return loadingProfile || loadingPartner || loading ? (
    <Spinner condensed />
  ) : (
    <Formik
      initialValues={initialValues}
      validationSchema={fullValidate ? validationSchema : validationSchemaBasic}
      onSubmit={handleSubmit}>
      {({ isSubmitting, values, setFieldValue, errors }) => (
        <>
          <OnSubmitValidationError />

          <Form>
            <StyledPage>
              {!formStep && (
                <StyledPageTitle>
                  <FontAwesomeIcon icon={faHandsHeart} />
                  {isUpdate ? (
                    <>
                      <FormattedMessage
                        id="dashboard::campaignForm::campaignUpdate"
                        defaultMessage="Update Campaign"
                        tagName="h1"
                      />
                      <InfoBox status={campaign?.status} />
                    </>
                  ) : (
                    <FormattedMessage
                      id="dashboard::campaignForm::newCampaign"
                      defaultMessage="New Campaign"
                      tagName="h1"
                    />
                  )}
                </StyledPageTitle>
              )}
              {formStep === "donation" && (
                <StyledPageTitle>
                  <FontAwesomeIcon icon={faCog} />
                  <FormattedMessage
                    id="dashboard::campaignForm::donationSettings"
                    defaultMessage="Donation Settings"
                    tagName="h1"
                  />
                </StyledPageTitle>
              )}
              {formStep === "legal" && (
                <StyledPageTitle>
                  <FontAwesomeIcon icon={faFileSignature} />
                  <FormattedMessage
                    id="dashboard::campaignForm::legalDocuments"
                    defaultMessage="Legal Documents"
                    tagName="h1"
                  />
                </StyledPageTitle>
              )}
              <StyledPageContent>
                {!formStep && (
                  <>
                    <Basics
                      activeLangs={activeLangs}
                      setActiveLangs={setActiveLangs}
                      campaignType={
                        selectedCampaignType || campaign?.campaignType
                      }
                      setCampaignType={setSelectedCampaignType}
                      status={campaign?.status}
                    />
                    <StyledFormSectionTitle>
                      <FormattedMessage
                        id="dashboard::campaignForm::images"
                        defaultMessage="Campaign Images"
                      />
                    </StyledFormSectionTitle>
                    <StyledFormSectionHelpText>
                      <FormattedMessage
                        id="dashboard::campaignForm::imageDesc"
                        defaultMessage="Upload images that represent your campaign. 540x360px is the recommended resolution, which has an aspect ratio of 16/9."
                      />
                    </StyledFormSectionHelpText>
                    <ImageGallery
                      processingImages={processingImages}
                      onDeleteImage={deleteCampaignImage}
                    />

                    {(!campaign?.id ||
                      campaign?.status === REVIEW_STATUS.IN_PROGRESS) && (
                      <>
                        <hr />
                        <StyledPageActions>
                          <Button
                            data-testid="btn-form-submit"
                            type="submit"
                            label="Save"
                            color="red"
                            disabled={
                              isSubmitting ||
                              Object.keys(errors).length ||
                              fullValidate
                            }>
                            <FormattedMessage
                              id="dashboard::campaignForm::save"
                              defaultMessage="Save"
                            />
                          </Button>
                        </StyledPageActions>
                      </>
                    )}
                    {campaign?.id &&
                      campaign?.status !== REVIEW_STATUS.REJECTED &&
                      apiPermissions?.includes(
                        API_PERMISSIONS.PARTNER_PUBLISH_CAMPAIGN
                      ) && (
                        <>
                          <hr />

                          <CheckboxContainer className="full-width">
                            <label htmlFor="agreeTerms">
                              <input
                                tabIndex="0"
                                data-testid="check-agreeTerms"
                                id="agreeTerms"
                                name="agreeTerms"
                                type="checkbox"
                                checked={fullValidate}
                                onChange={(e) => {
                                  setFullValidate(e.target.checked)
                                }}
                              />
                              <span className="checkmark"></span>
                              <FormattedMessage
                                id="dashboard::campaignForm::submitForReviewText"
                                defaultMessage="By accepting, I acknowledge that I have read and
                            understood the above information and will comply within
                            the declared rules and regulations of the platform"
                              />
                            </label>
                          </CheckboxContainer>
                          <StyledPageActions>
                            <Button
                              data-testid="btn-partner-profile-campaigns-list-submit"
                              type="submit"
                              label="Submit For Review"
                              disabled={isSubmitting || !fullValidate}>
                              <FormattedMessage
                                id="dashboard::campaignForm::submitForReview"
                                defaultMessage="Submit For Review"
                              />
                            </Button>

                            <hr />
                          </StyledPageActions>
                          <hr />
                          {apiPermissions?.includes(
                            API_PERMISSIONS.PARTNER_END_CAMPAIGN
                          ) && (
                            <StyledWarningWrapper>
                              <Tooltip type="info" className="info" />
                              <div>
                                <FormattedMessage
                                  id="dashboard::campaigns::endCampaignTitle"
                                  defaultMessage="End this campaign"
                                  tagName="h3"
                                />
                                <FormattedMessage
                                  id="dashboard::campaigns::endCampaignWarning"
                                  defaultMessage="WARNING: Once you end a campaign, it cannot be
                                  reopened or get donation for this campaign."
                                  tagName="p"
                                />
                              </div>
                              <div
                                data-tooltip-content={formatMessage({
                                  id: "dashboard::campaigns::campaignEndToolTip",
                                  defaultMessage:
                                    "Only approved campaigns can be ended",
                                })}>
                                <Button
                                  color="red"
                                  data-testid="btn-end-campaign"
                                  label="End Campaign"
                                  onClick={() => handleEndCampaign(values?.id)}
                                  disabled={
                                    campaign?.status !== REVIEW_STATUS.APPROVED
                                  }>
                                  <FormattedMessage
                                    id="dashboard::campaigns::endCampaign"
                                    defaultMessage="End Campaign"
                                  />
                                </Button>
                              </div>
                            </StyledWarningWrapper>
                          )}
                        </>
                      )}
                  </>
                )}
              </StyledPageContent>
            </StyledPage>
          </Form>

          <CustomModal
            isOpen={isEndModalOpen}
            style={modalStyles}
            ariaHideApp={false}
            shouldCloseOnOverlayClick={true}
            onRequestClose={handleModalClose}>
            <FormattedMessage
              id="dashboard::campaigns::confirmEnd"
              defaultMessage="Are you sure to end the campaign named <name>{name}</name>?"
              tagName="p"
              values={{ name: values?.nameTabEn }}
            />
            <StyledModalActions>
              <Button
                color="red"
                label="Yes"
                onClick={handleConfirmEnd}
                data-testid="btn-confirm">
                <FormattedMessage
                  id="dashboard::campaigns::modalYes"
                  defaultMessage="Yes"
                />
              </Button>
              <Button
                variant="outlined"
                label="No"
                onClick={handleCancelEnd}
                data-testid="btn-cancel">
                <FormattedMessage
                  id="dashboard::campaigns::modalNo"
                  defaultMessage="No"
                />
              </Button>
            </StyledModalActions>
          </CustomModal>
        </>
      )}
    </Formik>
  )
}

export default CampaignForm
