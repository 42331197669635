import styled from "@emotion/styled"

export const StyledSearchContainer = styled.div`
  padding-right: 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding-right: unset;
  }

  position: relative;
  margin-bottom: 1.5rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin-bottom: 2.5rem;
  }
  svg {
    position: absolute;
    top: 0.75rem;
    left: 0.75rem;
    z-index: 10;
    color: ${(p) => p.theme.colors.disabled};
    height: 0.8rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      height: 1.25rem;
      top: 1rem;
      left: 1rem;
    }
  }

  .search-input {
    grid-area: text !important;
    height: 2.5rem !important;
    padding-left: 2.5rem !important;
    border-color: ${(p) => p.theme.colors.disabled} !important;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      height: 3.25rem !important;
      padding-left: 4rem !important;
    }
  }
  label {
    width: max-content !important;
    color: ${(p) => p.theme.colors.disabled} !important;
    font-size: 0.75rem !important;
    padding-top: 0.2rem !important;
    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.5rem !important;
      padding-top: 0 !important;
    }
  }

  input[value]:not([value=""]) + label {
    margin-top: 0.3rem !important;
    font-size: 0.85rem !important;
    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      margin-top: -0.2rem !important;
      font-size: 1rem !important;
    }
  }

  .active {
    input + label {
      margin-top: 0.3rem !important;
      font-size: 0.85rem !important;
      @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
        margin-top: -0.2rem !important;
        font-size: 1rem !important;
      }
    }
  }
`
export const StyledForm = styled.form`
  display: contents;
`
export const StyledInfoIcon = styled.div`
  color: ${(p) => p.theme.colors.blue};
  height: 1.25rem;
`
export const StyledIconWrapper = styled.div`
  .tooltip {
    background-color: ${(p) => p.theme.colors.white};
    color: ${(p) => p.theme.colors.pitchBlack};
    overflow-wordwrap: break-word;
    width: 20rem;
    padding: 1rem;
    font-size: 0.75rem;
    line-height: 1.25rem;
  }

  svg {
    height: 1.25rem;
    cursor: pointer;
    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      height: 1.75rem;
    }
  }
  align-self: top;
  margin-top: 1rem;
  margin-left: 1rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    align-self: center;
    margin-top: unset;
  }
`
export const StyledTitleWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0.5rem;
`
