import React, { useState, useEffect } from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import { Button, CustomModal } from "@tmu/components/common"
import { StyledModalActions } from "@tmu/global/page-addons/dashboard.styles"
import { StyledReactCrop } from "@tmu/components/dashboard/dashboardCommon/ImageSelector/index.styles"
import {
  image64toCanvasRef,
  base64StringtoFile,
  readFile,
} from "@tmu/utils/image"
import { modalStyles } from "@tmu/global/GlobalStyle"

const ImageCropper = ({
  isOpen,
  image,
  id,
  onConfirm,
  onCancel,
  square,
  banner,
}) => {
  const initialCropState = {
    unit: "px",
    width: 250,
    x: 5,
    y: 5,
    aspect: square ? 1 / 1 : banner ? 4 / 1 : 16 / 9,
  }
  const [crop, setCrop] = useState(initialCropState)
  const [imgSrc, setImgSrc] = useState(null)
  const [result, setResult] = useState(null)
  const [imgSize, setImgSize] = useState({ width: 0, height: 0 })

  const handleImageLoaded = (image) => {
    setCrop(initialCropState)
    setImgSize({ width: image.naturalWidth, height: image.naturalHeight })
  }

  const handleOnCropChange = (crop) => {
    setCrop(crop)
  }

  const handleOnCropComplete = async (crop, percentCrop) => {
    const canvas = await image64toCanvasRef(imgSrc, percentCrop, imgSize)
    const imageData64 = canvas.toDataURL("image/png")
    const filename = `image.png`

    // file to be uploaded
    const newCroppedFile = base64StringtoFile(imageData64, filename)
    setResult(newCroppedFile)
  }

  useEffect(() => {
    if (!image) {
      setImgSrc(null)
    } else {
      const readImage = async () => {
        const src = await readFile(image)
        setImgSrc(src)
      }
      readImage()
    }
  }, [image])

  return (
    <CustomModal
      isOpen={isOpen}
      style={modalStyles}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      onRequestClose={onCancel}>
      <StyledReactCrop
        src={imgSrc}
        crop={image ? crop : initialCropState}
        ruleOfThirds
        onImageLoaded={handleImageLoaded}
        onComplete={handleOnCropComplete}
        onChange={handleOnCropChange}
        keepSelection={true}
        circularCrop={false}
        crossOrigin="Anonymous"
      />
      <StyledModalActions>
        <Button
          color="red"
          label="done"
          onClick={() => onConfirm({ result, id })}
          disabled={!result}>
          <FormattedMessage id="dashboard::forms::done" defaultMessage="Done" />
        </Button>
        <Button variant="outlined" label="cancel" onClick={onCancel}>
          <FormattedMessage
            id="dashboard::forms::cancel"
            defaultMessage="Cancel"
          />
        </Button>
      </StyledModalActions>
    </CustomModal>
  )
}

export default ImageCropper
