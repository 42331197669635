import { useLazyQuery } from "@apollo/client"
import { useApolloApiClients } from "@tmu/apollo/client"
import { DONATION_TIER_QUERY } from "@tmu/apollo/storefront/queries/campaign"

const useDonationTier = () => {
  const { storefrontClient } = useApolloApiClients()
  const [callDonationTier, { data, called, refetch }] = useLazyQuery(
    DONATION_TIER_QUERY,
    {
      client: storefrontClient,
    }
  )

  const getDonationTier = (id) => {
    const queryObject = {
      variables: { id: id },
      client: storefrontClient,
    }

    if (!called) {
      callDonationTier(queryObject)
    } else {
      refetch(queryObject)
    }
  }

  const donationTierData = data?.donationTier

  return { data,donationTierData, getDonationTier, called }
}

export default useDonationTier
