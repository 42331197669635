import { gql } from "@apollo/client"
import {
  merchantStoreParts,
  merchantOfferParts,
  merchantOfferPartsByMerchant,
  merchantOfferPartsWithLocale,
} from "@tmu/apollo/storefront/fragments/merchant"
import { campaignListingParts } from "@tmu/apollo/storefront/fragments/campaign"
import { capitalize } from "@tmu/utils/string"

export const MERCHANT_GET_STARTED_PAGE_QUERY = gql`
  query {
    allOffers(first: 8, isFeatured: true, orderBy: "-isFeatured,-created") {
      edges {
        node {
          id
          image
          slug
          shortDescription
          isFeatured
          store {
            ...merchantStoreParts
          }
        }
      }
    }
  }

  ${merchantStoreParts}
`

export const MERCHANT_LISTING_QUERY = gql`
  query StoreListing(
    $first: Int
    $after: String
    $categories: [ID]
    $country: String
    $orderBy: String
    $search: String
  ) {
    allMerchants(
      first: $first
      after: $after
      category: $categories
      country: $country
      orderBy: $orderBy
      search: $search
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          slug
          name
          displayName
          logo
          shortDescriptionEn
          shortDescriptionEs
          shortDescriptionIt
          maxPacDiscount
          maxPacDiscountInOffers
          defaultOffer {
            offerType
          }
          description
          images {
            edges {
              node {
                id
                image
              }
            }
          }
        }
      }
    }
  }
`

export const MERCHANT_DETAIL_QUERY = ({ locale }) => {
  const localeCapital = capitalize(locale)

  return gql`
    query StoreDetail($id: ID, $slug: String) {
      merchant(id: $id, slug: $slug) {
        id
        slug
        name
        displayName
        logo
        shortDescription
        shortDescription${localeCapital}
        website
        phoneNumber
        maxPacDiscountInOffers
        isDisplayed
        totalCampaignCount
        totalDonationCount
        totalFundedAmount
        totalPacDiscountAmount
        defaultOffer {
          id
          slug
          name
          image
          addressLine1
          city
          postCode
          country
          offerType
          categories {
            edges {
              node {
                id
                icon
                slug
                name
                name${localeCapital}
              }
            }
          }
          location
          shortDescription
          shortDescription${localeCapital}
          description
          description${localeCapital}
          maxPacDiscount
          isDefaultOffer
          created
        }
        fundedAmountForDisplay
        donationCountForDisplay
        campaignCountForDisplay
        category {
          id
          icon
          slug
          name
          name${localeCapital}
        }
        offers {
          edges {
            node {
              id
              slug
              name
              image
              offerType
              categories {
                edges {
                  node {
                    id
                    icon
                    slug
                    name
                    name${localeCapital}
                  }
                }
              }
              location
              shortDescription
              shortDescription${localeCapital}
              description
              description${localeCapital}
              maxPacDiscount
              isDefaultOffer
              created
            }
          }
        }
      }
    }
  `
}
export const MERCHANT_OFFER_LISTING_QUERY = gql`
  query OfferListing(
    $offset: Int
    $first: Int
    $after: String
    $search: String
    $categories: [ID]
    $orderBy: String
    $availableCountries: String
    $availableCities: [ID]
    $badge: [String]
    $offerOrigin: [String]
    $offerType_In: [MerchantsOfferOfferTypeChoices]
    $store: [ID]
    $maxPacDiscount_Lte: Decimal
    $maxPacDiscount_Gte: Decimal
    $maxPrice_Lte: Decimal
    $maxPrice_Gte: Decimal
  ) {
    allOffers(
      offset: $offset
      first: $first
      after: $after
      search: $search
      categories: $categories
      availableCountries: $availableCountries
      availableCities: $availableCities
      orderBy: $orderBy
      badge: $badge
      offerOrigin: $offerOrigin
      offerType_In: $offerType_In
      store: $store
      maxPacDiscount_Lte: $maxPacDiscount_Lte
      maxPacDiscount_Gte: $maxPacDiscount_Gte
      maxPrice_Lte: $maxPrice_Lte
      maxPrice_Gte: $maxPrice_Gte
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      minPrice
      maxPrice
      totalCount
      pageCount
      edges {
        node {
          ...merchantOfferParts
        }
      }
    }
  }
  ${merchantOfferParts}
`

export const MERCHANT_OFFER_LISTING_QUERY_WITH_LOCALE = (locale) => {
  const localeSuffix = capitalize(locale)
  return gql`
    query OfferListing(
      $offset: Int
      $first: Int
      $after: String
      $search: String
      $categories: [ID]
      $orderBy: String
      $availableCountries: String
      $availableCities: [ID]
      $badge: [String]
      $offerOrigin: [String]
      $offerType_In: [MerchantsOfferOfferTypeChoices]
      $store: [ID]
      $maxPacDiscount_Lte: Decimal
      $maxPacDiscount_Gte: Decimal
      $maxPrice_Lte: Decimal
      $maxPrice_Gte: Decimal
      $isDisplayed: Boolean
    ) {
      allOffers(
        offset: $offset
        first: $first
        after: $after
        search: $search
        categories: $categories
        availableCountries: $availableCountries
        availableCities: $availableCities
        orderBy: $orderBy
        badge: $badge
        offerOrigin: $offerOrigin
        offerType_In: $offerType_In
        store: $store
        maxPacDiscount_Lte: $maxPacDiscount_Lte
        maxPacDiscount_Gte: $maxPacDiscount_Gte
        maxPrice_Lte: $maxPrice_Lte
        maxPrice_Gte: $maxPrice_Gte
        isDisplayed: $isDisplayed
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        minPrice
        maxPrice
        totalCount
        pageCount
        edges {
          node {
            ...merchantOfferPartsWithLocale
          }
        }
      }
    }
    ${merchantOfferPartsWithLocale(localeSuffix)}
  `
}
export const MERCHANT_OFFER_LISTING_QUERY_BY_MERCHANT = gql`
  query OfferListing(
    $offset: Int
    $first: Int
    $after: String
    $search: String
    $categories: [ID]
    $orderBy: String
    $availableCountries: String
    $offerOrigin: [String]
    $maxPacDiscount_Lte: Decimal
    $maxPacDiscount_Gte: Decimal
  ) {
    allOffers(
      offset: $offset
      first: $first
      after: $after
      search: $search
      categories: $categories
      availableCountries: $availableCountries
      orderBy: $orderBy
      offerOrigin: $offerOrigin
      maxPacDiscount_Lte: $maxPacDiscount_Lte
      maxPacDiscount_Gte: $maxPacDiscount_Gte
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          ...merchantOfferPartsByMerchant
        }
      }
      totalCount
      pageCount
    }
  }
  ${merchantOfferPartsByMerchant}
`

export const MERCHANT_OFFER_DETAIL_QUERY = gql`
  query OfferDetail($slug: String!) {
    merchantOffer(slug: $slug) {
      id
      name
      slug
      descriptionEn
      descriptionIt
      descriptionEs
      image
      overviewEn
      overviewIt
      overviewEs
      shortDescriptionEn
      shortDescriptionEs
      shortDescriptionIt
      vatPercentage
      disclaimerEn
      disclaimerIt
      disclaimerEs
      allowUsersToSelectADifferentCampaign
      favoriteCharity {
        id
        name
        nameEn
        nameIt
        nameEs
        pacMultiplier
        partner {
          logo
          logoEn
          logoEs
          logoIt
        }
      }

      website
      maxPacDiscount
      isAvailable
      addressLine1
      location
      city
      country
      postCode
      productVariation {
        id
        name
        nameEn
        nameIt
        nameEs
        description
        descriptionEn
        descriptionIt
        descriptionEs
        price
        isActive
        pacDiscount
        maxTickets
        isUnlimitedTickets
        isSoldOut
        stockLeft
      }
      store {
        id
        name
        phoneNumber
        maxPacDiscount
        slug
        logo
        category {
          id
          nameEn
          nameIt
          nameEs
          icon
        }
      }
    }
  }
`

export const DASHBOARD_MERCHANT_OFFER_DETAIL_QUERY = gql`
  query OfferDetail($id: ID!) {
    offer(id: $id) {
      created
      modified
      id
      name
      slugBody
      slug
      status
      image
      website
      overview
      overviewEn
      overviewIt
      overviewEs
      description
      descriptionEn
      descriptionIt
      descriptionEs
      shortDescription
      shortDescriptionEn
      shortDescriptionIt
      shortDescriptionEs
      isDefaultOffer
      disclaimer
      disclaimerEn
      disclaimerIt
      disclaimerEs
      allowUsersToSelectADifferentCampaign
      favoriteCharity {
        id
        name
        nameEn
        nameIt
        nameEs
        pacMultiplier
        partner {
          logo
          logoEn
          logoEs
          logoIt
        }
      }
      addressLine1
      startDate
      endDate
      city
      country
      postCode
      phoneNumber
      location
      isAvailable
      isFeatured
      isDigital
      isUnlisted
      visibilityStatus
      website
      offerLink
      externalId
      isWorldwide
      showAddressAndPhone
      isVoucher
      isInvoiceMandatory
      isReservationRequired
      startDateVisibility
      categories {
        edges {
          node {
            id
            nameEn
            nameIt
            nameEs
            icon
          }
        }
      }
      currency
      maxPacDiscount
      externalId
      offerLink
      taxIncluded
      availableCountries
      availableCities {
        id
        name
        country {
          code
          name
        }
        region {
          code
          name
        }
        kind
      }
      productVariation {
        id
        name
        nameEn
        nameIt
        nameEs
        description
        descriptionEn
        descriptionIt
        descriptionEs
        shortDescription
        shortDescriptionEn
        shortDescriptionIt
        shortDescriptionEs
        price
        isActive
        pacDiscount
        maxTickets
        isUnlimitedTickets
        isSoldOut
        stockLeft
      }
      store {
        id
        logo
        slug
        maxPacDiscount
        phoneNumber
      }
    }
  }
`

export const MERCHANT_LOGO_LISTING_QUERY = gql`
  query MerchantListing($first: Int, $orderBy: String) {
    allMerchants(first: $first, orderBy: $orderBy) {
      pageInfo {
        startCursor
        endCursor
      }
      edges {
        node {
          id
          logo
        }
      }
    }
  }
`

export const MERCHANT_DEFAULT_OFFER_QUERY = gql`
  query StoreDetail($id: ID) {
    merchant(id: $id) {
      defaultOffer {
        id
      }
    }
  }
`

export const MERCHANT_CAMPAIGN_LISTING_QUERY = gql`
  query MerchantCampaignListing(
    $categories: [ID]
    $partner: [ID]
    $country: String
    $campaignType: CampaignsCampaignCampaignTypeChoices
    $fundraiserType: [String]
    $orderBy: String
    $first: Int
    $pacMultiplier: Decimal
    $after: String
    $search: String
    $user: [ID]
    $isSupporter: Boolean
    $isEligibleForSupporterEvent: Boolean
    $isEligibleForSupporterCollection: Boolean
    $visibilityStatus: [String]
    $status_In: [String]
    $startsAt_Lt: DateTime
    $startsAt_Lte: DateTime
    $startsAt_Gt: DateTime
    $startsAt_Gte: DateTime
    $deadline_Lte: DateTime
    $isStoreSupporter: Boolean
  ) {
    allCampaignsTotalCount: allCampaigns(first: 1, orderBy: $orderBy) {
      totalCount
    }
    allCampaigns(
      first: $first
      after: $after
      categories: $categories
      partner: $partner
      country: $country
      pacMultiplier: $pacMultiplier
      campaignType: $campaignType
      fundraiserType: $fundraiserType
      orderBy: $orderBy
      search: $search
      user: $user
      isSupporter: $isSupporter
      visibilityStatus: $visibilityStatus
      status_In: $status_In
      isEligibleForSupporterEvent: $isEligibleForSupporterEvent
      isEligibleForSupporterCollection: $isEligibleForSupporterCollection
      startsAt_Lt: $startsAt_Lt
      startsAt_Lte: $startsAt_Lte
      startsAt_Gt: $startsAt_Gt
      startsAt_Gte: $startsAt_Gte
      deadline_Lte: $deadline_Lte
      isStoreSupporter: $isStoreSupporter
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          ...campaignListingParts
          stripePublicKey
          categories {
            edges {
              node {
                id
                slug
                name
                icon
              }
            }
          }
          partner {
            id
            slug
            name
            displayName
            isFeatured
            logoEn
            logoIt
            logoEs
            logo
            shortDescriptionEn
            shortDescriptionIt
            shortDescriptionEs
            whySupportEn
            whySupportIt
            whySupportEs
            country
            city
            addressLine1
            addressLine2
            website
            youtube
            instagram
            facebook
            twitter
            linkedin
            taxId
            isEligibleForDonationPreference
            images {
              edges {
                node {
                  id
                  order
                  image
                }
              }
            }
            __typename
          }
          store {
            id
            slug
            name
            displayName
            logo
            website
            maxPacDiscount
            maxPacDiscountInOffers
            defaultOffer {
              offerType
            }
            description
            descriptionEn
            descriptionIt
            descriptionEs
            shortDescription
            shortDescriptionEn
            shortDescriptionIt
            shortDescriptionEs
            website
            youtube
            instagram
            facebook
            twitter
            linkedin
            terms
            termsEn
            termsEs
            termsIt
            country
          }
        }
      }
    }
  }
  ${campaignListingParts}
`

export const MERCHANT_USER_LISTING_QUERY = gql`
  query MerchantUserListing($user: [ID]) {
    allMerchantUsers(first: 100, user: $user) {
      edges {
        node {
          id
          store {
            id
            name
            logo
            displayName
            defaultOffer {
              offerType
            }
          }
        }
      }
    }
  }
`

export const MERCHANT_CAMPAIGN_TOTAL_COUNT_QUERY = gql`
  query MerchantCampaignTotalCount($orderBy: String) {
    allCampaigns(first: 1, orderBy: $orderBy) {
      totalCount
    }
  }
`

export const GET_ALL_PRODUCT_VARIATION_TICKETS_QUERY = gql`
  query getALlProductVariationTickets($first: Int, $voucherCode: String) {
    allProductVariationTickets(first: $first, voucherCode: $voucherCode) {
      edges {
        node {
          id
          status
          created
          modified
          cartItem {
            id
            name
            productName
          }
          productVariation {
            id
            name
            price
            offer {
              id
              slug
              name
              endDate
              minPrice
              maxPacDiscount
            }
          }
        }
      }
    }
  }
`

export const GET_ALL_EVENT_TICKETS_QUERY = gql`
  query getEventTickets($first: Int, $voucherCode: String) {
    allEventTickets(first: $first, voucherCode: $voucherCode) {
      edges {
        node {
          id
          status
          created
          modified
          donation {
            id
            amount
            earnedPac
          }
          tier {
            id
            description
            descriptionEn
            descriptionIt
            descriptionEs
            donationAmount
            isDefault
            campaign {
              id
              slug
              name
              deadline
              user {
                id
              }
              partner {
                id
              }
              store {
                id
              }
            }
          }
        }
      }
    }
  }
`
