import styled from "@emotion/styled"

export const StyledTitle = styled.h1`
  text-align: center;

  h1 {
    font-size: 3.25rem;
  }
`
export const StyledDescription = styled.div`
  max-width: 25rem;
  font-size: 1.25rem;
  text-align: center;
  font-weight: ${(p) => p.theme.fontWeights.bold};
  color: ${(p) => p.theme.colors.passwordRecoveryDescriptionFontColor};
  margin: 0 auto;

  a {
    color: ${(p) => p.theme.colors.passwordRecoveryDescriptionAnchorColor};
  }
`

export const StyledButtonContainer = styled.div`
  padding-top: 1rem;

  button {
    margin: 0 auto;
  }
`
