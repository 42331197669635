import styled from "@emotion/styled"
import { css } from "@emotion/react"

export const StyledActionCard = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 1rem;
  background: ${(p) => p.theme.colors.white};
  border: 0.0625rem solid ${(p) => p.theme.colors.darkGrey};
  box-shadow: 0 0 0.75rem ${(p) => p.theme.colors.primaryTextColorLight};
  border-radius: 0.75rem;
  position: relative;
  cursor: pointer;

  button {
    position: absolute;
    bottom: 2rem;
    display: none;
  }
  .link-button-text {
    min-width: 10.625rem;
    span {
      font-family: ${(p) => p.theme.fontFamilies.headline};
    }

    ${(p) =>
      p.type === "offer"
        ? css`
            span,
            svg {
              color: ${p.theme.colors.carrot};
            }
          `
        : null}
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 2rem 1rem 4.5rem;
    button {
      display: block;
    }
    ${(p) =>
      p.locale !== "en"
        ? css`
            padding-top: 1rem;
          `
        : null}
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    padding: 2rem 1.5rem 4.5rem;
    ${(p) =>
      p.locale !== "en"
        ? css`
            padding-top: 1rem;
          `
        : null}
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding: 2rem 2rem 6rem;

    .link-button-text {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
  }
`
export const StyledActionCardTitle = styled.div`
  display: grid;
  grid-template-columns: 1.5rem auto;
  gap: 0.5rem;
  align-items: center;
  margin-bottom: 0.8125rem;
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: ${(p) => p.theme.colors.black};

  h4 {
    font-family: ${(p) => p.theme.fontFamilies.headline};
  }

  svg {
    font-size: 1.5rem;
    height: 1.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    grid-template-columns: 2.375rem auto;
    gap: 1rem;
    margin-bottom: 1.3125rem;
    ${(p) =>
      p.locale !== "en"
        ? css`
            min-height: 4.0625rem;
          `
        : null}

    h4 {
      font-size: 1.5rem;
      line-height: 2rem;
    }
    svg {
      font-size: 2.25rem;
      height: 2.25rem;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    grid-template-columns: 2.75rem auto;
    gap: 1rem;
    margin-bottom: 1.75rem;
    ${(p) =>
      p.locale !== "en"
        ? css`
            min-height: 5.3125rem;
          `
        : null}

    h4 {
      font-size: 2rem;
      line-height: 2.625rem;
    }
    svg {
      font-size: 2.5rem;
      height: 2.5rem;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    grid-template-columns: 3.25rem auto;
    gap: 1.5rem;
    h4 {
      font-size: 3rem;
      line-height: 4.0625rem;
    }
    svg {
      font-size: 3.25rem;
      height: 3.25rem;
    }
  }
`

export const StyledActionCardText = styled.div`
  font-size: 0.75rem;
  line-height: 1.125rem;

  ${(p) =>
    p.type === "offer"
      ? css`
          span {
            color: ${p.theme.colors.carrot};
          }
        `
      : null}

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1rem;
    line-height: 1.375rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
`
