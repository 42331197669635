import { gql } from "@apollo/client"
import { pacTransactionParts, donationParts } from "../fragments/transaction"
export const DONOR_PAC_TRANSACTIONS_QUERY = gql`
  query DonorTransactionListing(
    $first: Int
    $after: String
    $reason_In: [PacsPacTransactionReasonChoices]
  ) {
    allPacTransactions(
      first: $first
      after: $after
      orderBy: "-created"
      reason_In: $reason_In
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          ...pacTransactionParts
          campaign {
            id
            name
            nameEn
            nameIt
            nameEs
            slug
          }
          order {
            id
            cartItems(first: 1) {
              edges {
                node {
                  id
                  offer {
                    id
                    name
                    slug
                  }
                }
              }
            }
          }
          donation {
            ...donationParts
          }
        }
      }
    }
    transactionDirections: __type(
      name: "MoneyPacTransactionTransactionDirectionChoices"
    ) {
      enumValues {
        description
        name
      }
    }
  }

  ${pacTransactionParts}
  ${donationParts}
`

export const ALL_API_ROLES_QUERY = gql`
  query AllApiRoles($first: Int, $after: String) {
    allApiRoles(first: $first, after: $after) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          name
          codename
          accessType
        }
      }
    }
  }
`

export const DONOR_WALLET_ALL_OFFERS = gql`
  query AllOffers {
    allOffers(first: 8, badge: ["NO_BADGE"], orderBy: "-discount,-created") {
      edges {
        node {
          id
          slug
          name
          image
          shortDescriptionEn
          shortDescriptionIt
          shortDescriptionEs
          descriptionEn
          descriptionIt
          descriptionEs
          maxPacDiscount
          website
          isDefaultOffer
          offerType
          store {
            id
            logo
            displayName
            name
            badge
            slug
            maxPacDiscount
            maxPacDiscountInOffers
            defaultOffer {
              offerType
            }
          }
        }
      }
    }
  }
`

export const USER_ALL_ASSETS_LISTING_QUERY = gql`
  query UserAllAssetsListing(
    $first: Int
    $language: String
    $search: String
    $orderBy: String
  ) {
    allAssets(
      first: $first
      language: $language
      search: $search
      orderBy: $orderBy
    ) {
      edges {
        node {
          id
          name
          asset
          assetUrl
          description
          directory
          mimetype
          size
          language
          store {
            id
            name
          }
          charity {
            id
            name
          }
        }
      }
    }
  }
`
