import styled from "@emotion/styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "@tmu/components/common"

export const StyledPageContainer = styled.div`
  margin: 6.3125rem 1rem 0;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin: 4.5rem auto 0;
    padding: 0 1.5rem;
    max-width: ${(p) => p.theme.breakpoints.tablet};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin: 6.375rem auto 0;
    padding: 0 2rem;
    max-width: ${(p) => p.theme.breakpoints.desktop};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin: 7.375rem auto 0;
    padding: 0 6rem;
    max-width: ${(p) => p.theme.breakpoints.wide};
  }
`

export const StyledWSIconAndContent = styled.div`
  display: grid;

  grid-template-columns: 1.5rem auto;
  grid-gap: 1.0938rem;
  margin-bottom: 1.5rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-bottom: 1rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    grid-gap: 1.1563rem;
    margin-bottom: 1.125rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    grid-template-columns: 2rem auto;
    grid-gap: 1.5rem;
    margin-bottom: 1.75rem;
  }
`

export const StyledWSAwesomeIcon = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
  height: 1.5rem;
  margin-top: 0.2rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1.5rem;
    height: 1.5rem;
    margin-top: 0.3rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
    height: 2rem;
    margin-top: 0.8rem;
  }
`

export const StyledWSContent = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  color: ${(p) => p.theme.colors.pitchBlack};

  font-size: 0.75rem;
  line-height: 1.125rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1rem;
    line-height: 1.375rem;

    text-align: unset;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 1rem;
    line-height: 1.375rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
`

export const StyledDonateMainContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin: 0 -1rem;
  padding: 0 1rem;
  background: ${(p) => p.theme.colors.whiteSmoke};

  padding-top: 2.25rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    grid-template-columns: 1fr 2fr;
    margin-top: 3rem;
    padding-top: 0;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin-top: 3.375rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin-top: 5rem;
  }
`

export const StyledDonateLeftContainer = styled.div`
  grid-template-rows: 1fr;
  position: sticky;
  height: min-content;
  top: 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin: 3rem 0 3rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin: 3.375rem 0 1.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin: 5rem 0 1.5rem;
  }
`

export const StyledDonateLeftTitle = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.pitchBlack};

  font-size: 1.5rem;
  line-height: 2rem;

  margin-bottom: 1.25rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1.5rem;
    line-height: 2rem;

    margin-bottom: unset;

    a {
      color: ${(p) => p.theme.colors.blue};
      font-weight: ${(p) => p.theme.fontWeights.semiBolder};
      font-family: ${(p) => p.theme.fontFamilies.headline};

      &.active {
        color: ${(p) => p.theme.colors.pitchBlack};
      }
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 2rem;
    line-height: 2.625rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 3rem;
    line-height: 4.0625rem;
  }
`

export const StyledDonateLeftContent = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.pitchBlack};

  font-size: 1.5rem;
  line-height: 2rem;

  margin-bottom: 1.25rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1.5rem;
    line-height: 2rem;

    a {
      color: ${(p) => p.theme.colors.blue};
      font-weight: ${(p) => p.theme.fontWeights.semiBolder};
      font-family: ${(p) => p.theme.fontFamilies.headline};

      &.active {
        color: ${(p) => p.theme.colors.pitchBlack};
      }
    }

    margin-top: 1.5rem;
    margin-bottom: unset;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 2rem;
    line-height: 2.625rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 3rem;
    line-height: 4.0625rem;

    margin-top: 3rem;
  }
`

export const StyledDonateLeftImage = styled.img`
  width: 100%;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    width: unset;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
  }
`

export const StyledDonateRightContainer = styled.div`
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin: 3rem 0;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin: 3.375rem 0;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin: 5rem 0 7.5rem;
  }
`

export const StyledDonateRightTitleAndContent = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};

  color: ${(p) => p.theme.colors.pitchBlack};

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
  }
`

export const StyledDonateRightTitleTagSection = styled.div`
  padding-top: 1rem;
`

export const StyledDonateRightTitle = styled.h4`
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};

  font-size: 1.125rem;
  line-height: 1.5rem;

  margin-bottom: 0.75rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 1.5rem;
    line-height: 2rem;

    margin-bottom: 1rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
    line-height: 2.625rem;

    margin-bottom: 1.5rem;
  }
`

export const StyledDonateRightContent = styled.p`
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};

  margin-bottom: ${(p) => (p.hasSmallMargin ? "1rem" : "1.25rem")};

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1rem;
    line-height: 1.375rem;

    margin-bottom: ${(p) => (p.hasSmallMargin ? "1rem" : "2rem")};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 1rem;

    margin-bottom: ${(p) => (p.hasSmallMargin ? "1rem" : "2.5rem")};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2.25rem;

    margin-bottom: ${(p) => (p.hasSmallMargin ? "1rem" : "4rem")};
  }
`

export const StyledDonateRightPACsButton = styled(Button)`
  display: block;

  p,
  span {
    color: ${(p) => p.theme.colors.carrot};

    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-size: 1rem;
    line-height: 1.5rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.5rem;
      line-height: 2rem;

      svg {
        font-size: 2rem;
        height: 2rem;
      }
  }
`

export const StyledDonateRightPACOffersButton = styled(Button)`
  display: block;

  p {
    margin-top: 1.5rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      margin-top: 3rem;
    }
  }

  p,
  span {
    color: ${(p) => p.theme.colors.carrot};

    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};

    font-size: 1rem;
    line-height: 1.5rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.5rem;
      line-height: 2rem;

      svg {
        font-size: 2rem;
        height: 2rem;
      }
    }
  }
`

export const StyledReadyMainContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  margin-top: 2.5rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: left;

    margin-top: 3rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin-top: 5.5rem;
  }
`

export const StyledReadyLeftContainer = styled.div`
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
  }
`

export const StyledReadyLeftImage = styled.img`
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
  }
`

export const StyledReadyRightContainer = styled.div`
  align-self: start;
  text-align: center;
  width: 100%;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    text-align: left;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
  }
`

export const StyledReadyRightTitle = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.pitchBlack};

  font-size: 1.5rem;
  line-height: 2rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 2rem;
    line-height: 2.625rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 3rem;
    line-height: 4.0625rem;
  }
`

export const StyledReadyRightButton = styled(Button)`
  height: 2.5rem;

  border: 0.0625rem solid ${(p) => p.theme.colors.blue};
  border-radius: 0.25rem;

  margin: 2rem auto 2.5rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-bottom: unset;
    margin-left: 0;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin-top: 2.4375rem;
    margin-bottom: unset;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    height: 4rem;

    margin-top: 4rem;
    margin-bottom: unset;
    font-size: 1rem;
  }
`
