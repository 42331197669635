import styled from "@emotion/styled"

const GridTemplate = `
  display: grid;
  grid-template-columns: 2fr 0.5fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 0.5rem;
  padding-right: 1rem;
  align-items: center;
  width: 100%;
`

export const StyledPACWalletTable = styled.div`
  margin-bottom: 2rem;
`

export const StyledPACWalletTableHeader = styled.div`
  ${GridTemplate}
  border-bottom: 0.0625rem solid ${(p) => p.theme.colors.tableItemBorder};
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: ${(p) => p.theme.fontWeights.regular};
  color: ${(p) => p.theme.colors.tableHeaderDefault};
  .centered {
    height: 100%;
    justify-self: center;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    grid-template-columns: 3fr 1fr 2.5fr 1.5fr;
    grid-gap: 1rem;
    padding-right: 0.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    grid-template-columns: 3.75fr 1fr 2fr 1.25fr;
    grid-gap: 3rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    grid-gap: 10rem;
  }

  .pacs-amount,
  .spending-amount {
    text-align: right;
  }
`

export const StyledPACWalletTableRow = styled.div`
  ${GridTemplate}
  border-bottom: 0.0625rem solid ${(p) => p.theme.colors.tableItemBorder};
  width: 100%;
  padding-top: 0.7125rem;
  padding-bottom: 0.7125rem;
  height: 100%;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    grid-template-columns: 3fr 1fr 2.5fr 1.5fr;
    grid-gap: 1rem;
    padding-right: 0.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    grid-template-columns: 3.75fr 1fr 2fr 1.25fr;
    grid-gap: 3rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    grid-gap: 10rem;
  }

  strong {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.pitchBlack};

    font-size: 0.75rem;
    line-height: 1.25rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-family: unset;
      font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }

  div,
  strong {
    font-size: 0.75rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1rem;
    }
  }

  .light {
    opacity: 0.6;
  }

  .centered {
    height: 100%;
    justify-self: center;
  }

  .spending-amount {
    text-align: right;

    > strong {
      font-weight: unset;
    }
  }
`

export const StyledNGOPACWalletTableRow = styled(StyledPACWalletTableRow)`
  grid-template-columns: 1fr 4fr 1fr 1fr 1fr;
  border-bottom: none;
  span {
    display: inline-block;
    max-width: 15rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const StyledNGOPACWalletTableHeader = styled(StyledPACWalletTableHeader)`
  grid-template-columns: 1fr 4fr 1fr 1fr 1fr;
`

export const StyledPACWalletDetails = styled.ul`
  display: grid;
  grid-template-columns: minmax(18rem, auto) 1fr 1fr;
  grid-gap: 1rem;
  padding: 1rem;
  font-size: 0.8rem;
  list-style: none;
  background: ${(p) => p.theme.colors.grayedBackgroundColor};
`

export const StyledListItem = styled.li`
  display: grid;
  grid-gap: 0.2rem;
  grid-template-columns: 7rem 1fr;
  p {
    font-weight: ${(p) => p.theme.fontWeights.bold};
  }
  span {
    display: inline-block;
    max-width: 18.75rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.success {
    color: ${(p) => p.theme.colors.lightBlue};
    text-transform: uppercase;
  }
  &.danger {
    color: ${(p) => p.theme.colors.lightRed};
    text-transform: uppercase;
  }
`

export const StyledPACAmount = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 0.5rem;

  > div {
    text-align: right;
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-weight: unset;
    }

    & span {
      font-family: ${(p) => p.theme.fontFamilies.headline};
      font-style: ${(p) => p.theme.fontStyles.normal};
      line-height: 1.125rem;

      @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
        font-family: unset;
      }

      @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
        line-height: 1.25rem;
      }
    }
  }

  div + div {
    text-align: left;
  }
`
