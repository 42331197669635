import { gql } from "@apollo/client"
import { offerParts } from "../fragments/offer"
import { merchantParts, merchantUserParts } from "../fragments/merchant"

export const CREATE_MERCHANT_OFFER_MUTATION = gql`
  mutation CreateMerchantOffer($input: CreateOfferMutationInput!) {
    createOffer(input: $input) {
      offer {
        ...offerParts
      }
      errors {
        field
        messages
      }
    }
  }
  ${offerParts}
`

export const DELETE_MERCHANT_OFFER_MUTATION = gql`
  mutation DeleteOfferMutation($id: String) {
    deleteOffer(id: $id) {
      success
    }
  }
`

export const UPDATE_PARTIAL_MERCHANT_OFFER_MUTATION = gql`
  mutation partialUpdateOffer($input: PartialUpdateOfferMutationInput!) {
    partialUpdateOffer(input: $input) {
      offer {
        ...offerParts
      }
      errors {
        field
        messages
      }
    }
  }
  ${offerParts}
`
export const UPDATE_MERCHANT_OFFER_MUTATION = gql`
  mutation UpdateMerchantOffer($input: UpdateMerchantOfferMutationInput!) {
    updateOffer(input: $input) {
      offer {
        ...offerParts
      }
      errors {
        field
        messages
      }
    }
  }
  ${offerParts}
`

export const UPDATE_MERCHANT_MUTATION = gql`
  mutation UpdateMerchant($input: MerchantStoreMutationInput!) {
    updateMerchant(input: $input) {
      store {
        ...merchantParts
      }
      errors {
        field
        messages
      }
    }
  }
  ${merchantParts}
`

export const UPDATE_MERCHANT_PARTIAL_MUTATION = gql`
  mutation ($input: PartialUpdateMerchantOfferMutationInput!) {
    partialUpdateOffer(input: $input) {
      merchantOffer {
        id
        isUnlisted
      }
      errors {
        field
        messages
      }
    }
  }
`

export const SUBMIT_FOR_REVIEW_MERCHANT_STORE_MUTATION = gql`
  mutation SubmitForReviewMerchantStore(
    $input: MerchantStoreSubmitForReviewMutationInput!
  ) {
    merchantStoreSubmitForReview(input: $input) {
      store {
        ...merchantParts
      }
      errors {
        field
        messages
      }
    }
  }
  ${merchantParts}
`

export const CREATE_MERCHANT_USER_MUTATION = gql`
  mutation CreateMerchantUser($input: CreateMerchantUserMutationInput!) {
    createMerchantUser(input: $input) {
      merchantUser {
        ...merchantUserParts
        store {
          id
          name
        }
      }
      errors {
        field
        messages
      }
    }
  }
  ${merchantUserParts}
`

export const CREATE_MERCHANT_USER_REFERRAL_MUTATION = gql`
  mutation CreateMerchantUserReferral(
    $input: CreateMerchantUserReferralMutationInput!
  ) {
    createMerchantUserReferral(input: $input) {
      referral {
        id
        code
        email
      }
      errors {
        field
        messages
      }
    }
  }
`

export const UPDATE_MERCHANT_USER_MUTATION = gql`
  mutation UpdateMerchantUser($input: UpdateMerchantUserMutationInput!) {
    updateMerchantUser(input: $input) {
      merchantUser {
        ...merchantUserParts
        merchant {
          id
          name
        }
      }
      errors {
        field
        messages
      }
    }
  }
  ${merchantUserParts}
`

export const DELETE_MERCHANT_USER_MUTATION = gql`
  mutation DeleteMerchantUserMutation(
    $input: DeleteMerchantUserMutationInput!
  ) {
    deleteMerchantUser(input: $input) {
      success
      errors {
        field
        messages
      }
    }
  }
`

export const OFFER_SUBMIT_FOR_REVIEW_MUTATION = gql`
  mutation offerSubmitForReview($input: OfferSubmitForReviewMutationInput!) {
    offerSubmitForReview(input: $input) {
      offer {
        ...offerParts
      }
      errors {
        field
        messages
      }
    }
  }
  ${offerParts}
`

export const GENERATE_MERCHANT_REPORT_MUTATION = gql`
  mutation GenerateMerchantReport(
    $input: GenerateMerchantReportMutationInput!
  ) {
    generateMerchantReport(input: $input) {
      merchantReport {
        id
        report
      }
      errors {
        field
        messages
      }
    }
  }
`
export const CREATE_MERCHANT_IMAGE_MUTATION = gql`
  mutation CreateMerchantImage($input: CreateMerchantStoreImageMutationInput!) {
    createMerchantStoreImage(input: $input) {
      merchantStoreImage {
        id
        image
        order
        store {
          id
        }
      }
      errors {
        field
        messages
      }
    }
  }
`

export const DELETE_MERCHANT_IMAGE_MUTATION = gql`
  mutation DeleteMerchantImage($input: DeleteMerchantStoreImageMutationInput!) {
    deleteMerchantStoreImage(input: $input) {
      success
      errors {
        field
        messages
      }
    }
  }
`

export const CREATE_MERCHANT_HERO_MUTATION = gql`
  mutation createHeroItem($input: CreateHeroItemMutationInput!) {
    createHeroItem(input: $input) {
      heroItem {
        isActive
        title
        detail
        image
        page
        buttonText
        buttonLink
        addOtpToButtonLink
        ordering
        id
      }
      errors {
        field
        messages
      }
    }
  }
`

export const DELETE_MERCHANT_HERO_MUTATION = gql`
  mutation DeleteHeroItem($input: HeroItemDeleteMutationInput!) {
    deleteHeroItem(input: $input) {
      success
    }
  }
`

export const UPDATE_MERCHANT_HERO_MUTATION = gql`
  mutation UpdateHeroItem($input: HeroItemUpdateMutationInput!) {
    updateHeroItem(input: $input) {
      heroItem {
        isActive
        title
        detail
        image
        page
        buttonText
        buttonLink
        addOtpToButtonLink
        ordering
        id
      }
      errors {
        field
        messages
      }
    }
  }
`

export const HERO_SUBMIT_FOR_REVIEW_MUTATION = gql`
  mutation merchantHeroSubmitForReview(
    $input: MerchantHeroSubmitForReviewMutationInput!
  ) {
    merchantHeroSubmitForReview(input: $input) {
      heroItem {
        isActive
        title
        titleEn
        titleIt
        titleEs
        detail
        detailEn
        detailIt
        detailEs
        image
        page
        buttonText
        buttonTextEn
        buttonTextIt
        buttonTextEs
        buttonLink
        buttonLinkEn
        buttonLinkIt
        buttonLinkEs
        addOtpToButtonLink
        ordering
        id
      }
      errors {
        field
        messages
      }
    }
  }
`

export const UPDATE_MERCHANT_PAYMENT_DESTINATION_MUTATION = gql`
  mutation ($input: UpdateMerchantPaymentDestinationMutationInput!) {
    updatePaymentDestination(input: $input) {
      paymentDestination {
        accountName
        bankName
        iban
        country
        sortCode
        accountNumber
        bic
      }
      errors {
        field
        messages
      }
    }
  }
`

export const CREATE_MERCHANT_OFFER_PRODUCT_VARIATION = gql`
  mutation ($input: CreateProductVariationMutationInput!) {
    createProductVariation(input: $input) {
      productVariation {
        id
        description
        descriptionEn
        descriptionIt
        descriptionEs
        name
        nameEn
        nameIt
        nameEs
        price
        isActive
        pacDiscount
        maxTickets
        isUnlimitedTickets
        isSoldOut
        stockLeft
      }
      errors {
        field
        messages
      }
    }
  }
`

export const UPDATE_MERCHANT_OFFER_PRODUCT_VARIATION = gql`
  mutation ($input: UpdateProductVariationMutationInput!) {
    updateProductVariation(input: $input) {
      productVariation {
        id
        description
        descriptionEn
        descriptionIt
        descriptionEs
        name
        nameEn
        nameIt
        nameEs
        price
        isActive
        pacDiscount
        maxTickets
        isUnlimitedTickets
        isSoldOut
        stockLeft
      }
      errors {
        field
        messages
      }
    }
  }
`

export const CREATE_REPRESENTATIVE_USER = gql`
  mutation ($input: CreateRepresentativeUserMutationInput!) {
    createRepresentativeUser(input: $input) {
      representativeUser {
        id
        fiscalCode
        firstName
        lastName
        email
        phoneNumber
        address
        attributes
      }
      errors {
        field
        messages
      }
    }
  }
`

export const UPDATE_REPRESENTATIVE_USER = gql`
  mutation ($input: UpdateRepresentativeUserMutationInput!) {
    updateRepresentativeUser(input: $input) {
      representativeUser {
        id
        fiscalCode
        firstName
        lastName
        email
        phoneNumber
        address
        attributes
      }
      errors {
        field
        messages
      }
    }
  }
`

export const PRODUCT_VARIATION_TICKET = gql`
  mutation productVariationTicket($voucherCode: String) {
    productVariationTicket(voucherCode: $voucherCode) {
      success
    }
  }
`
export const EVENT_TICKET = gql`
  mutation eventTicket($voucherCode: String) {
    eventTicket(voucherCode: $voucherCode) {
      success
    }
  }
`

export const PARTIAL_UPDATE_MERCHANT_MUTATION = gql`
  mutation PartialUpdateMerchant($input: PartialUpdateMerchantStoreMutationInput!) {
    partialUpdateMerchant(input: $input) {
      merchant {
        ...merchantParts
      }
      errors {
        field
        messages
      }
    }
  }
  ${merchantParts}
`
