import styled from "@emotion/styled"

export const Wrapper = styled.div`
  border: 1px solid ${(p) => p.theme.colors.concreteGrey};
  border-radius: 0.25rem;
  background-color: unset;
  padding: 0;
  width: 100%;
  max-width: 25.8125rem;
  min-width: 10rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    width: 10rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    width: 12.5rem;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;

  &.error {
    border-color: ${(p) => p.theme.colors.red};
  }

  .passive {
    svg {
      color: ${(p) => p.theme.colors.concreteGrey};
    }
    border-color: ${(p) => p.theme.colors.concreteGrey};
  }
`

export const StepButton = styled.div`
  border: 1px solid ${(p) => p.theme.colors.pacificBlue};
  border-radius: 0.25rem;
  background-color: unset;

  div {
    color: ${(p) => p.theme.colors.pitchBlack};
  }

  color: ${(p) => p.theme.colors.concreteGrey};

  width: 1.875rem;
  height: 1.875rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    height: 3.375rem;
    width: 3.375rem;
  }
  margin: 0.5rem;

  svg {
    width: 1.25rem;
    height: 1.25rem;
    color: ${(p) => p.theme.colors.pacificBlue};
    margin: auto;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const StepInput = styled.div`
  background-color: unset;
  border-radius: 0;
  border: none;
  box-shadow: none;
  div {
    color: ${(p) => p.theme.colors.pitchBlack};
  }

  width: auto;
  height: auto;
  font-size: 1rem;
  color: ${(p) => p.theme.colors.pitchBlack};

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
  }
`
