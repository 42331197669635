import styled from "@emotion/styled"
export const StyledPaginationWrapper = styled.div`
  background-color: ${(p) => p.theme.colors.white};
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);
  height: 3rem;
  margin-left: 1rem;
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    height: 4rem;
  }
  &.sticky {
    width: 100vw;
    position: fixed;
    bottom: 0;
    right: 0;
  }

  &.transparent {
    box-shadow: none !important;
    background-color: none !important;
  }

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin: unset;
  }
`
export const StyledPagination = styled.div`
  padding-left: 1rem;
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding-left: calc(
      (100vw - ${(p) => p.theme.breakpoints.tablet}) / 2 + 14rem
    );
  }
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    padding-left: calc(
      (100vw - ${(p) => p.theme.breakpoints.desktop}) / 2 + 14rem
    );
  }
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding-left: calc(
      (100vw - ${(p) => p.theme.breakpoints.wide}) / 2 + 30rem
    );
  }

  ul.react-paginate {
    padding: 0;
  }

  ul.react-paginate li a {
    border: none !important;
    background-color: ${(p) => p.theme.colors.transparent} !important;
    color: ${(p) => p.theme.colors.pacificBlue} !important;

    font-weight: ${(p) => p.theme.fontWeights.semiBolder} !important;
    font-family: ${(p) => p.theme.fontFamilies.headline} !important;
    font-size: 0.75rem !important;
    line-height: 1.125rem !important;

    @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      line-height: 2.25rem !important;
      font-size: 1.125rem !important;
    }
  }

  ul.react-paginate li.selected a {
    background-color: ${(p) => p.theme.colors.transparent} !important;
    color: ${(p) => p.theme.colors.pitchBlack} !important;
  }

  ul > li.previous {
    display: none;
  }
  ul > li.next {
    display: none;
  }
`
export const StyledContainer = styled.div`
  display: flex;
  margin-top: 0.75rem;
  justify-content: space-between;
  padding-right: 1rem;
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding-right: calc(
      (100vw - ${(p) => p.theme.breakpoints.tablet}) / 2 + 2rem
    );
  }
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    padding-right: calc(
      (100vw - ${(p) => p.theme.breakpoints.desktop}) / 2 + 2rem
    );
  }
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding-right: calc(
      (100vw - ${(p) => p.theme.breakpoints.wide}) / 2 + 6rem
    );
  }
`
export const StyledPageSizeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`
export const StyledPageSizeItem = styled.div`
  cursor: pointer;
  width: 3rem;
  height: 1.5rem;
  background-color: ${(p) => p.theme.colors.whiteSmoke};
  color: ${(p) => p.theme.colors.pitchBlack};
  border-radius: 0.25rem;
  text-align: center;
  line-height: 1.5rem;
  font-size: 0.75rem;
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  font-family: ${(p) => p.theme.fontFamilies.regular};

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    width: 4rem;
    height: 2.5rem;
    line-height: 2.25rem;
    font-size: 1rem;
  }

  &.active {
    background-color: ${(p) => p.theme.colors.pacificBlue};
    color: ${(p) => p.theme.colors.white};
  }
`

export const StyledBreakContainer = styled.span`
  letter-spacing: 2rem;
  text-align: center;
  margin-left: 2rem;
`
export const StyledPageSizesLabel = styled.div`
  line-height: 1.5rem;
  font-size: 0.75rem;
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  font-family: ${(p) => p.theme.fontFamilies.regular};
  margin-right: 1rem;
`
