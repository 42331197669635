import React, { useCallback, useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { Form, useFormikContext } from "formik"
import { Tooltip } from "react-tooltip"
import {
  Button,
  Checkbox,
  Dropdown,
  FieldError,
  TextInput,
  BaseError,
} from "@tmu/components/common"
import { useCountries } from "@tmu/hooks"
import theme from "@tmu/global/theme"
import {
  StyledProfileFlagRowContent,
  StyledProfileFlexContainer,
  StyledProfileRowContent,
  StyledProfileSection,
  StyledProfileSectionTitle,
  StyledProfileSpacer,
  StyledPrivacyTooltip,
  StyledVisibleContainer,
} from "./index.styles"
import { getAllScreenTypes } from "@tmu/src/utils/mediaQueries"

const { CLOUDFLARE_IMAGE_URL } = process.env

const AddressProfileForm = ({
  isEdit,
  onCancel,
  onSave,
  addressLoading,
  addressError,
  isMerchantOnline,
  isMerchantCorporate,
}) => {
  const { formatMessage } = useIntl()
  const { countries, countryOptions, getCountries } = useCountries()
  const {
    errors,
    initialValues,
    values,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    setTouched,
  } = useFormikContext()
  const { isWide, isTablet } = getAllScreenTypes()
  const getCountryLabel = useCallback(
    (countryCode) => {
      if (countryOptions?.length) {
        return countryOptions?.find(({ value }) => value === countryCode)
      }
    },
    [countryOptions]
  )

  const handleShowAddress = () => {
    setFieldValue("hideAddress", !values?.hideAddress)
  }

  const currentCountry = countries?.find(
    (item) => (item?.id || item?.shortCode) === values?.country
  )

  useEffect(() => {
    !countryOptions && getCountries()
  })

  const privacyTooltipText = formatMessage({
    id: "dashboard::forms::privacyTooltip",
    defaultMessage: "Visible on your store page. Click edit to change",
  })

  const unPrivacyTooltipText = formatMessage({
    id: "dashboard::forms::unPrivacyTooltip",
    defaultMessage: "Not visible on your store page. Click edit to change",
  })

  const visibleInvisibleContent = (shouldHide) => {
    if (!isTablet) {
      return <></>
    }

    return (
      <StyledProfileFlagRowContent>
        <div
          data-tooltip-content={
            shouldHide ? unPrivacyTooltipText : privacyTooltipText
          }
          data-tooltip-id="privacy-tooltip">
          <img
            src={
              CLOUDFLARE_IMAGE_URL +
              `/static/assets/images/svg/${
                shouldHide ? "unprivacy.svg" : "privacy.svg"
              }`
            }
            alt={`mai-privacy-image`}
            width={16}
            height={13}
          />
          <StyledPrivacyTooltip>
            <Tooltip
              arrowColor="transparent"
              effect="solid"
              place="top"
              type="info"
              textColor="black"
              backgroundColor="white"
              id="privacy-tooltip"
              delayShow={500}
            />
          </StyledPrivacyTooltip>
        </div>
      </StyledProfileFlagRowContent>
    )
  }

  return (
    <Form className="general-profile-form">
      <fieldset>
        <StyledProfileRowContent>
          <StyledProfileSection>
            <StyledVisibleContainer className={!isEdit ? "inner" : ""}>
              {!isEdit && visibleInvisibleContent(initialValues?.hideAddress)}
              <StyledProfileSectionTitle>
                <FormattedMessage
                  id="dashboard::forms::country"
                  defaultMessage="Country"
                />
              </StyledProfileSectionTitle>
            </StyledVisibleContainer>
            <StyledProfileSection>
              {isEdit ? (
                <>
                  <Dropdown
                    newDesign
                    data-testid="country"
                    id="country"
                    name="country"
                    onBlur={handleBlur}
                    value={currentCountry}
                    options={countryOptions}
                    defaultSelectStyles={{
                      control: {
                        height: isWide ? "3.375rem" : "3rem",
                        paddingTop: "0",
                        paddingRight: "0",
                      },
                      valueContainer: {
                        height: isWide ? "3.375rem" : "3rem",
                        paddingTop: "0",
                      },
                    }}
                    onChange={(selected) => {
                      setFieldValue("country", selected?.value, true)
                      setTouched({ ...touched, country: true }, true)
                    }}
                  />
                  <FieldError name="country" />
                </>
              ) : (
                (getCountryLabel(initialValues?.country)?.label ?? "") || (
                  <>&nbsp;</>
                )
              )}
            </StyledProfileSection>
          </StyledProfileSection>
          <StyledProfileSection>
            <StyledProfileSectionTitle>
              <FormattedMessage
                id="dashboard::forms::address"
                defaultMessage="Address"
              />
            </StyledProfileSectionTitle>
            <StyledProfileSection>
              {isEdit ? (
                <>
                  <TextInput
                    newDesign
                    data-testid="addressLine1"
                    id="addressLine1"
                    name="addressLine1"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.addressLine1}
                  />
                  <FieldError name="addressLine1" />
                </>
              ) : (
                <>
                  <span>
                    {(initialValues?.addressLine1 ?? "") || <>&nbsp;</>}
                  </span>
                </>
              )}
            </StyledProfileSection>
          </StyledProfileSection>
          <StyledProfileSection>
            <StyledProfileSectionTitle>
              <FormattedMessage
                id="dashboard::forms::postalCode"
                defaultMessage="Postal code"
              />
            </StyledProfileSectionTitle>
            <StyledProfileSection>
              {isEdit ? (
                <>
                  <TextInput
                    newDesign
                    data-testid="postCode"
                    id="postCode"
                    name="postCode"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.postCode}
                  />
                  <FieldError name="postCode" />
                </>
              ) : (
                (initialValues?.postCode ?? "") || <>&nbsp;</>
              )}
            </StyledProfileSection>
          </StyledProfileSection>
          {isEdit && (isMerchantOnline || isMerchantCorporate) ? (
            <>
              <StyledProfileSection>
                <Checkbox
                  label={
                    <FormattedMessage
                      id="dashboard::forms::showAddress"
                      defaultMessage="Show address on your store page"
                    />
                  }
                  isChecked={!values?.hideAddress}
                  onChange={handleShowAddress}
                  className="profile-form"
                />
              </StyledProfileSection>
            </>
          ) : null}
        </StyledProfileRowContent>
      </fieldset>
      {isEdit && addressLoading ? (
        <BaseError
          message={formatMessage({
            id: "forms::address::validating",
            defaultMessage: "Please wait while your address is being validated",
          })}
        />
      ) : null}
      {isEdit && addressError ? <BaseError message={addressError} /> : null}
      {isEdit && (
        <>
          <StyledProfileSpacer bottom={1.5} />
          <StyledProfileRowContent>
            <StyledProfileFlexContainer>
              <Button
                className="black-button"
                color="transparent"
                label="cancel"
                onClick={onCancel}>
                <FormattedMessage
                  id="dashboard::forms::cancel"
                  defaultMessage="Cancel"
                />
              </Button>
              <Button
                data-testid="btn-save"
                className="blue-button button-margin"
                color={theme.colors.pacificBlue}
                type="button"
                disabled={Object.keys(errors)?.length > 0}
                onClick={onSave}>
                <FormattedMessage
                  id="dashboard::accountForm::save"
                  defaultMessage="Save"
                />
              </Button>
            </StyledProfileFlexContainer>
          </StyledProfileRowContent>
        </>
      )}
    </Form>
  )
}

export default AddressProfileForm
