import { gql } from "@apollo/client"
import { campaignCategoryPartsForDonateByShopping } from "@tmu/apollo/dashboard/fragments/campaign"
import { campaignDetailParts } from "@tmu/apollo/storefront/fragments/campaign"
export const CREATE_DONATION_MUTATION = gql`
  mutation Donation($input: DonationMutationInput!) {
    createDonation(input: $input) {
      donation {
        id
        amount
        earnedPac
        isAnonymous
        merchantPacReconciliation {
          id
          totalAmount
          donationAmount
          totalPacsRequired
          normalizedDonationAmount
          pacMultiplierSurplus
          pacAmountAfterNormalizedDonation
          netEarnedPacsAfterDonation
          purchaseAmountAfterDiscount
        }
        stripeClientSecret
        stripeSavePaymentMethodForFuture
        token
        refreshToken
        stripePaymentMethodId
        campaign {
          ...campaignDetailParts
          user {
            id
            fullName
            profileImage
          }
          partner {
            id
            slug
            name
          }
          store {
            id
            slug
            name
            logo
            defaultOffer {
              offerType
            }
          }
        }
        donateByShoppingMerchant {
          id
          name
        }
      }
      errors {
        field
        messages
      }
    }
  }
  ${campaignDetailParts}
`

export const DONATE_BY_SHOPPING_MUTATION = gql`
  mutation DonateByShoppingMutation($input: DonateByShoppingMutationInput!) {
    donateByShopping(input: $input) {
      campaign {
        ...campaignCategoryPartsForDonateByShopping
      }
      redirectTo
      merchantId
      amount
      errors {
        field
        messages
      }
    }
  }
  ${campaignCategoryPartsForDonateByShopping}
`

export const SUBSCRIPTION_MUTATION = gql`
  mutation Subscription($input: CreateSubscriptionMutationInput!) {
    createSubscription(input: $input) {
      subscription {
        id
        status
        stripeSubscriptionId
        stripePaymentMethodId
        plan {
          id
          amount
          currency
          interval
          product {
            name
            description
          }
        }
        campaign {
          id
          slug
          name
          nameEn
          nameEs
          nameIt
          pacMultiplier
          isDefaultCampaign
          user {
            id
            fullName
          }
          partner {
            id
            name
            slug
          }
        }
        earnedPac
      }
      errors {
        field
        messages
      }
    }
  }
`
