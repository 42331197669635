import styled from "@emotion/styled"

export const StyledPacsHeroContainer = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  color: ${(p) => p.theme.colors.pitchBlack};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  height: 38rem;
  position: relative;
  padding: 2rem 1rem;
  overflow: hidden;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 1rem 1.5rem 1.5rem;
    height: 34rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    height: 41.875rem;
    padding: 2rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    height: 58.8125rem;
    padding: 6rem;
  }
`

export const StyledPacsHeroTitle = styled.div`
  font-size: 0.75rem;
  line-height: 1.125rem;
  letter-spacing: 0.01em;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1rem;
    line-height: 2.1875rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 1rem;
    line-height: 2.1875rem;
    font-family: ${(p) => p.theme.fontFamilies.headline};
    margin: 0;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
`

export const StyledPacsHeroReward = styled.div`
  font-size: 1.5rem;
  line-height: 2rem;
  color: ${(p) => p.theme.colors.carrot};
  margin-top: calc(16.25rem - 20vw);
  max-width: 75vw;
  font-family: ${(p) => p.theme.fontFamilies.headline};

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 2rem;
    line-height: 2.625rem;
    max-width: 38.75rem;
    margin-top: 2rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 3rem;
    line-height: 4.0625rem;
    margin-top: 1.7rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 4rem;
    line-height: 4rem;
    letter-spacing: -0.01em;
    margin-top: 4rem;
    max-width: 61rem;
  }
`

export const StyledPacsHeroEncourage = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  max-width: 24.4375rem;
  margin-top: 0.75rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin-top: 1.2rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 2rem;
    line-height: 2.625rem;
    font-family: ${(p) => p.theme.fontFamilies.headline};
    max-width: 37.5rem;
    margin-top: 0.8rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2.5rem;
    line-height: 3rem;
    margin-top: 2rem;
    max-width: 49.125rem;
  }
`

export const StyledPacsHeroPacsPoints = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  font-size: 0.75rem;
  line-height: 1.125rem;
  margin-top: 1.2rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1rem;
    line-height: 1.375rem;
    margin-top: 2.5rem;
    max-width: 28rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 1rem;
    line-height: 1.375rem;
    margin-top: 2rem;
    max-width: 39.8125rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.75rem;
    line-height: 2.125rem;
    max-width: 45rem;
    margin-top: 6rem;
  }
`

export const StyledPacsExplained = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 0.75rem;
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};

  p,
  span {
    margin-right: 0.2rem;
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-size: 1rem;
    min-width: fit-content;
  }

  p {
    margin: 0;
    img {
      height: 0.8rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin-top: 1.5rem;
    font-family: ${(p) => p.theme.fontFamilies.headline};

    p,
    span {
      font-size: 1.125rem;
      line-height: 1.5rem;
      margin-right: 0.25rem;
      img {
        height: 1.1rem;
        margin: 0 0.3rem;
      }
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
    margin-top: 2rem;
    font-family: ${(p) => p.theme.fontFamilies.headline};
    p,
    span {
      font-size: 2rem;
      line-height: 1.5rem;
      margin-right: 0.5rem;
      img {
        height: 1.8rem;
        margin: 0 0.5rem;
      }
    }
  }
`

export const StyledEqualsSymbol = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-size: 1rem;
  line-height: 2.625rem;
  margin: 0 0.2rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin: 0 0.75rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
  }
`

export const StyledPacsHeroSpecialPacOffers = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  font-size: 0.75rem;
  line-height: 1.125rem;
  max-width: 28.125rem;
  margin-top: 0.5rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1rem;
    line-height: 1.375rem;
    margin-top: 1rem;
    max-width: 28rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin-top: 1.5rem;
    max-width: 38rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    max-width: 60rem;
    font-size: 1.75rem;
    line-height: 2.125rem;
    margin-top: 2rem;
  }
`

export const StyledImageBox = styled.div`
  position: absolute;
  top: 0;
  right: -10.125rem;
  bottom: 0;
  z-index: -1;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    right: -1rem;
    bottom: unset;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
  }
`
