import React from "react"

import { StyledCard, StyledTitle, StyledDescription } from "./index.styles"

const Card = ({ title, description }) => {
  return (
    <StyledCard>
      {title && <StyledTitle>{title}</StyledTitle>}
      {description && <StyledDescription>{description}</StyledDescription>}
    </StyledCard>
  )
}

export default Card
