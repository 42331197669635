import { useLazyQuery } from "@apollo/client"
import { useApolloApiClients } from "@tmu/apollo/client"
import { USER_EXISTS_QUERY } from "@tmu/apollo/storefront/queries/user"

const useUserExists = () => {
  const { storefrontClient } = useApolloApiClients()
  const [checkUserExist, { loading, data, error }] = useLazyQuery(
    USER_EXISTS_QUERY,
    {
      client: storefrontClient,
    }
  )

  return {
    checkUserExistLoading: loading,
    checkUserExist,
    doesUserExist: data?.userExists,
    error,
  }
}

export default useUserExists
