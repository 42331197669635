import styled from "@emotion/styled"

export const StyledLocationWrapper = styled.div`
  display: flex;

  svg {
    width: 0.875rem;
    height: 1.25rem;
    font-weight: ${(p) => p.theme.fontWeights.liteBlack};
    color: ${(p) => p.theme.colors.blue};
    margin-right: 0.7rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    svg {
      width: 1.75rem;
      height: 1.75rem;
    }
  }
`

export const StyledAddressSection = styled.a`
  cursor: pointer;
`

export const StyledAddressLine = styled.p`
  margin: 0;
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.black};
  font-size: 0.75rem;
  line-height: 1.25rem;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: ${(p) => p.theme.colors.blue};
  text-align: left;
`
