import styled from "@emotion/styled"

export const StyledImageList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    gap: 0.75rem 1.5rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    gap: 1.5rem 3rem;
  }
  ::-webkit-scrollbar {
    width: 0.25rem;
    height: 0;
  }
  ::-webkit-scrollbar-track {
    background: ${(p) => p.theme.colors.lightGrey};
  }
  ::-webkit-scrollbar-thumb {
    background: ${(p) => p.theme.colors.disabled};
    width: 0.25rem;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${(p) => p.theme.colors.disabled};
  }
`

export const StyledImageItem = styled.div`
  cursor: pointer;
  position: relative;
  div > img {
    border-radius: 0.25rem;
  }
  div {
    overflow: hidden;
  }
  width: 9.25rem;
  height: 5.1875rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    width: 12rem;
    height: 6.75rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    width: 20.875rem;
    height: 11.79rem;
  }
`

export const StyledImageOverlay = styled.div`
  background-color: ${(p) => p.theme.colors.softGreen};
  opacity: 0.6;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 0.25rem;
`
export const StyledNumber = styled.div`
  color: ${(p) => p.theme.colors.white};
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
`
