import React from "react"
import {
  StyledLabel,
  StyledInput,
  StyledCheckmark,
  StyledSoldOutLabel,
} from "./index.styles"
import { FormattedMessage } from "gatsby-plugin-intl"

const RadioInput = ({
  label = "",
  labelHtml,
  rawHtml,
  description,
  id = "",
  isChecked = false,
  onChange = () => {},
  index,
  isPassive,
  onClick = () => {},
  ...rest
}) => {
  let itemClass =
    isChecked === true
      ? "radio-select-item radio-select-checked-item"
      : "radio-select-item"

  if (isPassive === true || rest?.isSoldOut) {
    itemClass += " radio-select-item-passive"
  }

  const inputClass =
    isChecked === true
      ? "radio-select-input radio-select-checked-input"
      : "radio-select-input"

  let labelClass =
    isChecked === true
      ? "radio-select-label radio-select-checked-label"
      : "radio-select-label"

  if (!description || description?.length === 0) {
    itemClass += " " + "radio-select-item-empty-description"
    labelClass += " " + "radio-select-label-empty-description"
  }

  return (
    <div className={itemClass} data-testid={"radio-select-item" + index}>
      <StyledLabel key={id} onClick={onClick}>
        <div>
          <span className={labelClass}>
            {rest?.isSoldOut ? (
              <StyledSoldOutLabel>
                <FormattedMessage
                  id="event::ticket::soldOut"
                  defaultMessage="Sold Out"
                />
              </StyledSoldOutLabel>
            ) : null}

            {rawHtml ? (
              rawHtml
            ) : labelHtml ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: labelHtml,
                }}
              />
            ) : (
              label
            )}
            {description && description?.length > 0 && (
              <p className="radio-select-description">{description}</p>
            )}
          </span>
        </div>
        <div className={inputClass}>
          {isPassive === true ? (
            <StyledInput
              {...rest}
              type="radio"
              checked={isChecked}
              value={id}
              onChange={onChange}
              disabled
            />
          ) : (
            <StyledInput
              {...rest}
              type="radio"
              checked={isChecked}
              value={id}
              onChange={onChange}
            />
          )}
          <StyledCheckmark />
        </div>
      </StyledLabel>
    </div>
  )
}

export default RadioInput
