import React from "react"
import PropTypes from "prop-types"

import { CheckboxContainer } from "@tmu/components/common"
import { StyledTab } from "./index.styles"

const Tab = ({ isActive, children, activeLangs, setActiveLangs }) => {
  const defaultMessage = children?.props?.defaultMessage?.toLowerCase()

  return (
    <StyledTab>
      <button
        aria-label="tab"
        className={isActive ? "active" : null}
        id={defaultMessage}
        data-testid={`${defaultMessage}-tab`}
        type="button"
        disabled={!activeLangs || !activeLangs[defaultMessage]}>
        {children}
      </button>
      <CheckboxContainer>
        <label>
          <input
            type="checkbox"
            data-testid={`${defaultMessage}-tab-check`}
            checked={activeLangs && activeLangs[defaultMessage]}
            onChange={(e) => {
              setActiveLangs({
                ...activeLangs,
                [defaultMessage]: e.target.checked,
              })
            }}
          />
          <span className="checkmark"></span>
        </label>
      </CheckboxContainer>
    </StyledTab>
  )
}
Tab.tabsRole = "Tab" // Required field to use custom Tab

export default Tab

Tab.propTypes = {
  activeLangs: PropTypes.object,
  setActiveLangs: PropTypes.func,
  children: PropTypes.node,
}
