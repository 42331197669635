import styled from "@emotion/styled"

const GridTemplate = `
  display: grid;
  grid-template-columns: 1fr 9fr 1.25fr 2.5fr;
  grid-template-rows: 1fr;
  grid-gap: 0.5rem;
  padding-right: 1rem;
  align-items: center;
  width: 100%;
`

const RowLeft = `
  text-align: left;   
`

const RowCenter = `
  text-align: center;   
`

const RowRight = `
  text-align: right;   
`

export const StyledCorporateCompaniesTable = styled.div`
  margin-bottom: 2rem;
`

export const StyledCorporateCompaniesTableHeader = styled.div`
  ${GridTemplate}
  border-bottom: 0.0625rem solid ${(p) => p.theme.colors.darkGrey};
  padding-top: 1rem;
  padding-bottom: 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    grid-template-columns: 1fr 10rem 9fr 1.25fr 2.5fr;
    grid-gap: 1rem;
    padding-right: 0.5rem;
    grid-gap: 1.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    grid-template-columns: 1fr 10rem 9fr 1.25fr 2.5fr;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    grid-template-columns: 1fr 15rem 12.5fr 1.25fr 2.5fr;
  }

  .row {
    font-family: ${(p) => p.theme.fontFamilies.headlineBig};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.liteBlack};

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 0.75rem;
      line-height: 1.25rem;
      letter-spacing: 0.0225rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.25rem;
      line-height: 2rem;
      letter-spacing: 0.025rem;
    }
  }

  .row-left {
    ${RowLeft}
  }

  .row-center {
    ${RowCenter}
  }

  .row-right {
    ${RowRight}
  }
`

export const StyledCorporateCompaniesTableRow = styled.div`
  ${GridTemplate}
  border-bottom: 0.0625rem solid ${(p) => p.theme.colors.darkGrey};
  width: 100%;
  padding-top: 0.7125rem;
  padding-bottom: 0.7125rem;
  height: 100%;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  cursor: pointer;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    grid-template-columns: 1fr 10rem 9fr 1.25fr 2.5fr;
    grid-gap: 1.5rem;
    padding-right: 0.25rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    grid-template-columns: 1fr 10rem 9fr 1.25fr 2.5fr;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    grid-template-columns: 1fr 15rem 12.5fr 1.25fr 2.5fr;
  }

  .row {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 1.125rem;
      line-height: 1.75rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 2rem;
      line-height: 2.75rem;
    }
  }

  .row-left {
    ${RowLeft}
  }

  .row-center {
    ${RowCenter}
  }

  .row-right {
    ${RowRight}
  }

  .row-blue {
    color: ${(p) => p.theme.colors.blue};
  }

  .row-primary {
    color: ${(p) => p.theme.colors.primaryTextColor};
  }
`
