import styled from "@emotion/styled"

export const StyledTitle = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.pitchBlack};
  font-size: 2rem;
  line-height: 2.625rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 3rem;
    line-height: 4.125rem;
  }
`

export const StyledCategoryTitle = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.pitchBlack};
  font-size: 1.125rem;
  line-height: 1.5rem;
  text-align: left;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
    line-height: 2.625rem;
  }
`

export const StyledRowTitleExplanation = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  color: ${(p) => p.theme.colors.pitchBlack};
  font-size: 0.75rem;
  line-height: 1.25rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1rem;
    line-height: 1.375rem;
  }
`

export const StyledCategoryHr = styled.hr`
  margin-bottom: 0.75rem;
  margin-top: 0.75rem;
`
export const StyledCheckbox = styled.div`
  .checkmark {
    top: 0.2625rem !important;
  }

  .mobile-checkbox {
    padding: 0;
    width: 100%;
    font-size: 1rem;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 2.25rem;
    > span {
      top: 0;
      display: block;
      position: relative;
      float: right;
      min-width: 1.25rem;
      margin-top: -1rem;
    }
  }
`

export const StyledCategoryWrapper = styled.div`
  .categories > .category-filters > div > button > span > i {
    color: ${(p) => p.theme.colors.pacificBlue};
  }

  .categories > .category-filters > div > button > svg {
    color: ${(p) => p.theme.colors.pacificBlue};
  }
`

export const StyledFavButtons = styled.div`
  display: grid;
  grid-template-columns: 7.5rem 10.25rem;
  grid-gap: 1rem;
  button {
    margin: 0;
    &.blue {
      color: ${(p) => p.theme.colors.white};
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: grid;
    grid-template-columns: 7.5rem 10.25rem;
    grid-gap: 1rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    grid-template-columns: 10rem 13.875rem;
    margin-top: 2rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    grid-template-columns: 15rem 20rem;
    grid-gap: 1.5rem;
    margin-top: 2.5rem;
  }
`

export const StyledCampaignName = styled.div`
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
  }
`
export const StyledMenuContainer = styled.div`
  .pointer {
    cursor: pointer;
  }
`
export const StyledMenuItem = styled.div``
export const StyledSelectedFavoriteCharityMobile = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  color: ${(p) => p.theme.colors.pacificBlue};
  font-size: 1rem;
  line-height: 1.5rem;
  cursor: pointer;
`
export const StyledFavoriteCharityContainerMobile = styled.div`
  position: absolute;
  bottom: 1rem;
`
export const StyledSmallSectionTitleMobile = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headlineBig};
  font-weight: ${(p) => p.theme.fontWeights.liteBlack};
  color: ${(p) => p.theme.colors.concreteGrey};
  font-size: 0.75rem;
  line-height: 1rem;
  text-transform: uppercase;
`
