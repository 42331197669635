import styled from "@emotion/styled"

export const StyledSocialProfileWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 2rem;
`

export const StyledHeader = styled.div``
export const StyledField = styled.div``
