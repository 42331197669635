import { useQuery, gql } from "@apollo/client"
import { useEffect, useState } from "react"

import { useApolloApiClients } from "@tmu/apollo/client"
import { useIntl } from "gatsby-plugin-intl"
import { getValueForLocale } from "@tmu/utils/string"
import { MAX_PER_PAGE } from "@tmu/apollo/constants"

const useAllCampaignCategories = (options) => {
  const { storefrontClient } = useApolloApiClients()
  const { locale, defaultLocale } = useIntl()
  const [allCampaignCategories, setAllCampaignCategories] = useState([])
  const { loading, data } = useQuery(
    gql`
      query CampaignCategories($first: Int) {
        allCampaignCategories(first: $first) {
          edges {
            node {
              id
              slug
              nameEn
              nameIt
              nameEs
              image
              icon
            }
          }
        }
      }
    `,
    {
      ...options,
      client: storefrontClient,
      variables: { first: MAX_PER_PAGE, isPublic: true },
    }
  )

  useEffect(() => {
    const result = data?.allCampaignCategories?.edges?.map(({ node }) => ({
      ...node,
      name: getValueForLocale(node, "name", locale, defaultLocale),
    }))
    setAllCampaignCategories(result)
  }, data)

  return { allCampaignCategories, loading }
}

export default useAllCampaignCategories
