import styled from "@emotion/styled"

export const StyledSliceWrapper = styled.div`
  padding: 3rem 1rem 0;
  margin: auto;
  background: ${(p) => p.theme.colors.whiteSmoke};

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    background: ${(p) => p.theme.colors.white};
    padding: 2.5rem 1.5rem 0;
    max-width: ${(p) => p.theme.breakpoints.tablet};
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    padding: 3.25rem 2rem 0;
    max-width: ${(p) => p.theme.breakpoints.desktop};
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding: 6rem;
    max-width: ${(p) => p.theme.breakpoints.wide};
  }
`

export const StyledSliceCorporateCampaignWrapper = styled.div`
  .corporate-campaigns {
    > div:first-child {
      align-items: flex-start;

      @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
        button {
          min-width: unset;
          text-align: right;
        }
      }

      @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
        align-items: center;
        button {
          min-width: fit-content;
        }
      }
    }
  }
`
