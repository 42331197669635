export const offerParts = `
  fragment offerParts on OfferNode {
    name
    overviewEn
    overviewIt
    overviewEs
    descriptionEn
    descriptionIt
    descriptionEs
    shortDescriptionEn
    shortDescriptionIt
    shortDescriptionEs
    availableCountries
    reviewStatus
    country
    city
    postCode
    addressLine1
    location
    availableCities{
      id
      name
      country{
        code
        name
      }
      region{
        code
        name
      }
      kind
    }
    isWorldwide
    disclaimer
    showAddressAndPhone
    offerLink
    location
    isReservationRequired
    startDateVisibility
    allowUsersToSelectADifferentCampaign
    favoriteCharity {
      id
      name
      nameEn
      nameIt
      nameEs
      pacMultiplier
      partner {
        logo
        logoEn
        logoEs
        logoIt
      }
    }
    productVariation {
      id
      name
      nameEn
      nameIt
      nameEs
      description
      descriptionEn
      descriptionIt
      descriptionEs
      price
      isActive
      pacDiscount
      stockLeft
    }
    categories{
      edges{
        node{
          id
          name
          nameEn
          nameIt
          nameEs
        }
      }
    }
    image
    id
    isUnlisted
    slug
    slugBody
    website
    maxPacDiscount
    visibilityStatus
    isDefaultOffer
    offerType
  }
`
