import React, { useContext, useState } from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "gatsby-plugin-intl"
import {
  StyledListingFilterWrapper,
  StyledListingFilterText,
  StyledListingFilters,
  StyledFilterModalHeader,
  StyledComingSoonToggle,
  StyledComingSoonToggle as StyledOnlyPacsToggle,
} from "./index.styles"
import {
  CustomModal,
  Checkbox,
  SortSelector,
  StoreFilter,
  CommonToggle,
  CustomSlider,
  CategorySubcategorySelect,
} from "@tmu/components/common"
import { HomePageContext } from "@tmu/context/homePageContext"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { Spacer } from "@tmu/global/page-addons/detail-page.styles"
import CategoryList from "./CategoryList"

const ListingFilters = ({
  handleSelect,
  categories,
  selected,
  onSelect,
  onSingleCategorySelect,
  handleShowAll,
  defaultSortValue,
  sortOptions,
  handleSortAction,
  setEventsFilter,
  isEventsFilter,
  isOfferList,
  selectedCategories,
  setSelectedCategories,
  isOnlyPACFilter,
  setOnlyPACFilter,
  isSupporterFilter,
  setIsSupporterFilter,
  isCharitiesFilter,
  setCharitiesFilter,
  isOnlyCorporateFilter,
  setIsOnlyCorporateFilter,
  isCampaignsFilter,
  setCampaignsFilter,
  handleTypeChange = () => {},
  isStoresFilter,
  setStoresFilter,
  isOffersFilter,
  setOffersFilter,
  isComingSoonFilter,
  setComingSoonFilter,
  storeFilterDefaultValue,
  handleStoreFilterChange,
  filters,
  isOnlineMerchant,
  merchantType,
  onSliderChange,
  sliderDefaultValue,
  handlePriceSliderChange,
  isDonationPage = false,
  minMaxPrice,
  queryFilters = {},
  ...rest
}) => {
  const [modalStatus, setModalStatus] = useState(false)
  const { intercomStatus, setIntercomStatus } = useContext(HomePageContext)
  const { isTablet } = getAllScreenTypes()

  const closeModal = () => {
    setModalStatus(false)
    setIntercomStatus({ hide: false })
  }
  /* const handleResetFilters = () => {
    handleShowAll()
    setEventsFilter(false)
  } */

  return (
    <StyledListingFilterWrapper {...rest}>
      <StyledListingFilterText
        isOfferList={isOfferList}
        onClick={() => {
          setModalStatus(true)
          if (!isTablet) {
            setIntercomStatus({ hide: true })
          }
        }}>
        <FormattedMessage id="filter::filters" defaultMessage="Filters" />
      </StyledListingFilterText>
      {modalStatus && !isTablet ? (
        <CustomModal
          isModalOpen={modalStatus}
          hideParentPageOnMobile
          style={{ padding: 16, lineHeight: 2 }}
          headerStyle={{ paddingTop: "unset" }}
          header={
            <StyledFilterModalHeader>
              <FormattedMessage
                id="filter::filters"
                defaultMessage="Filters"
                tagName="p"
              />
              {/* <p onClick={handleResetFilters}>
                <FormattedMessage
                  id="filter::resetFilters"
                  defaultMessage="Reset filters"
                />
              </p> */}
            </StyledFilterModalHeader>
          }
          isCloseIcon={true}
          cancelButtonAction={closeModal}
          isMobile={!isTablet}
          children={
            <StyledListingFilters isOfferList={isOfferList}>
              {isOfferList ? (
                <>
                  {isOnlineMerchant !== false && (
                    <>
                      <p className="caption type-caption">
                        <FormattedMessage
                          id="offerListing::filters::offerType"
                          defaultMessage="Type"
                        />
                      </p>
                      <hr />
                      {filters?.storeTypes && (
                        <>
                          <Checkbox
                            className="filter-checkbox"
                            label={
                              <FormattedMessage
                                id="offerListing::filters::stores"
                                defaultMessage="Stores"
                              />
                            }
                            isChecked={isStoresFilter}
                            onChange={() => {
                              handleTypeChange({ store: !isStoresFilter })
                              setStoresFilter(!isStoresFilter)
                            }}
                          />
                          <hr />
                          <Checkbox
                            className="filter-checkbox"
                            label={
                              <FormattedMessage
                                id="offerListing::filters::offers"
                                defaultMessage="Offers"
                              />
                            }
                            isChecked={isOffersFilter}
                            onChange={() => {
                              handleTypeChange({ offer: !isOffersFilter })
                              setOffersFilter(!isOffersFilter)
                            }}
                          />
                          <hr />
                        </>
                      )}
                      {filters?.comingSoon && (
                        <>
                          <StyledComingSoonToggle
                            onClick={() => {
                              handleTypeChange({
                                comingsoon: !isComingSoonFilter,
                              })
                              setComingSoonFilter(!isComingSoonFilter)
                            }}>
                            <FormattedMessage
                              id="offerListing::filters::comingSoon"
                              defaultMessage="Show coming soon"
                            />
                            <CommonToggle checked={isComingSoonFilter} />
                          </StyledComingSoonToggle>
                          <hr />
                        </>
                      )}
                      {filters?.internalExternalStores && (
                        <div>
                          <Checkbox
                            className="filter-checkbox"
                            label={
                              <FormattedMessage
                                id="offerListing::filters::externalStores"
                                defaultMessage="External Stores"
                              />
                            }
                            isChecked={rest?.isExternalStoresFilter}
                            onChange={() => {
                              const newValue = !rest?.isExternalStoresFilter
                              handleTypeChange({
                                externalStore: newValue,
                              })
                              rest?.setIsExternalStoresFilter(newValue)
                            }}
                          />
                          <hr />
                          <Checkbox
                            className="filter-checkbox"
                            label={
                              <FormattedMessage
                                id="offerListing::filters::internalStores"
                                defaultMessage="Internal Stores"
                              />
                            }
                            isChecked={rest?.isInternalStoresFilter}
                            onChange={() => {
                              const newValue = !rest?.isInternalStoresFilter
                              handleTypeChange({
                                internalStore: newValue,
                              })
                              rest?.setIsInternalStoresFilter(newValue)
                            }}
                          />
                          <hr />
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {filters?.hideFundraiser !== true && (
                    <>
                      <p className="caption type-caption">
                        <FormattedMessage
                          id="campaignListing::filters::fundraiserType"
                          defaultMessage="Fundraiser Type"
                        />
                      </p>
                      <hr />
                      <Checkbox
                        className="filter-checkbox"
                        label={
                          <FormattedMessage
                            id="campaignListing::filters::charities"
                            defaultMessage="Charities"
                          />
                        }
                        isChecked={isCharitiesFilter}
                        onChange={() => {
                          handleTypeChange({ charity: !isCharitiesFilter })
                          setCharitiesFilter(!isCharitiesFilter)
                        }}
                      />
                      <hr />
                      <Checkbox
                        className="filter-checkbox"
                        label={
                          <FormattedMessage
                            id="campaignListing::filters::campaigns"
                            defaultMessage="Campaigns"
                          />
                        }
                        isChecked={isCampaignsFilter}
                        onChange={() => {
                          handleTypeChange({ campaign: !isCampaignsFilter })
                          setCampaignsFilter(!isCampaignsFilter)
                        }}
                      />
                      <hr />
                      <Checkbox
                        className="filter-checkbox"
                        label={
                          <FormattedMessage
                            id="campaignListing::filters::events"
                            defaultMessage="Events"
                          />
                        }
                        isChecked={isEventsFilter}
                        onChange={() => {
                          handleTypeChange({ event: !isEventsFilter })
                          setEventsFilter(!isEventsFilter)
                        }}
                      />
                      <hr />
                      <StyledOnlyPacsToggle
                        onClick={() => {
                          handleTypeChange({
                            corporate: !isOnlyCorporateFilter,
                            supporter:
                              !isOnlyCorporateFilter || isSupporterFilter,
                            campaign:
                              !isOnlyCorporateFilter || isCampaignsFilter,
                          })
                          setIsOnlyCorporateFilter(!isOnlyCorporateFilter)
                        }}>
                        <FormattedMessage
                          id="campaignListing::filters::showOnlyCorporate"
                          defaultMessage="Only Corporate Campaigns"
                        />
                        <CommonToggle
                          controlled
                          checked={isOnlyCorporateFilter}
                        />
                      </StyledOnlyPacsToggle>
                      <hr />
                      <StyledOnlyPacsToggle
                        onClick={() => {
                          handleTypeChange({
                            supporter: !isSupporterFilter,
                            campaign: !isSupporterFilter || isCampaignsFilter,
                          })
                          setIsSupporterFilter(!isSupporterFilter)
                        }}>
                        <FormattedMessage
                          id="campaignListing::filters::showSupporter"
                          defaultMessage="Show supporter campaigns"
                        />
                        <CommonToggle
                          checked={isSupporterFilter}
                          disabled={isOnlyCorporateFilter}
                          controlled
                        />
                      </StyledOnlyPacsToggle>
                      <hr />
                      <StyledOnlyPacsToggle
                        onClick={() => {
                          handleTypeChange({
                            pacs: !isOnlyPACFilter,
                            campaign: !isOnlyPACFilter || isCampaignsFilter,
                          })
                          setOnlyPACFilter(!isOnlyPACFilter)
                        }}>
                        <FormattedMessage
                          id="campaignListing::filters::onlyPacs"
                          defaultMessage="Only double PACs campaigns"
                        />
                        <CommonToggle checked={isOnlyPACFilter} controlled />
                      </StyledOnlyPacsToggle>
                      <hr />
                    </>
                  )}
                </>
              )}
              {filters?.discountSlider && (
                <>
                  <p className="caption">
                    <FormattedMessage
                      id="listing::filters::donationDiscount"
                      defaultMessage="Donation Discount"
                    />
                  </p>
                  <div>
                    <Spacer top={1} />
                    <CustomSlider
                      onChange={onSliderChange}
                      defaultValue={sliderDefaultValue}
                      customRightLabel={"100%"}
                      isPercent={true}
                    />
                    <Spacer bottom={4} />
                  </div>
                </>
              )}
              {false && filters?.priceSlider && (
                <>
                  <p className="caption">
                    <FormattedMessage
                      id="listing::filters::price"
                      defaultMessage="Price"
                    />
                  </p>
                  <div className="price-slider">
                    <Spacer top={1} />
                    <CustomSlider
                      onChange={handlePriceSliderChange}
                      defaultValue={minMaxPrice}
                      customRightLabel={
                        <FormattedMessage
                          id="listing::filters::priceRightLabel"
                          defaultMessage="All Prices"
                        />
                      }
                    />
                    <Spacer bottom={4} />
                  </div>
                </>
              )}
              {filters?.campaignTypes && (
                <>
                  {filters?.campaignCheckbox?.charity !== false && (
                    <>
                      <hr />
                      <Checkbox
                        className="filter-checkbox"
                        label={
                          <FormattedMessage
                            id="offerListing::campaigns::charities"
                            defaultMessage="Charities"
                          />
                        }
                        isChecked={rest?.isCharityCheck}
                        onChange={() => {
                          rest?.setIsCharityCheck(!rest?.isCharityCheck)
                        }}
                      />
                    </>
                  )}
                  {filters?.campaignCheckbox?.charityCampaign !== false && (
                    <>
                      <hr />
                      <Checkbox
                        className="filter-checkbox"
                        label={
                          <FormattedMessage
                            id="offerListing::campaigns::charityCampaigns"
                            defaultMessage="Charity Campaigns"
                          />
                        }
                        isChecked={rest?.isCharityCampaignCheck}
                        onChange={() => {
                          rest?.setIsCharityCampaignCheck(
                            !rest?.isCharityCampaignCheck
                          )
                        }}
                      />
                    </>
                  )}
                  {filters?.campaignCheckbox?.supporterCampaign !== false && (
                    <>
                      <hr />
                      <Checkbox
                        className="filter-checkbox"
                        label={
                          <FormattedMessage
                            id="offerListing::campaigns::supporterCampaigns"
                            defaultMessage="Supporter Campaigns"
                          />
                        }
                        isChecked={rest?.isSupporterCampaignCheck}
                        onChange={() => {
                          rest?.setIsSupporterCampaignCheck(
                            !rest?.isSupporterCampaignCheck
                          )
                        }}
                      />
                    </>
                  )}
                  <hr />
                </>
              )}
              <p className="caption">
                <FormattedMessage
                  id="listing::filters::category"
                  defaultMessage="Category"
                />
              </p>

              {isDonationPage ? (
                <CategoryList
                  categories={categories}
                  selected={selectedCategories}
                  onSelect={onSelect}
                />
              ) : (
                <CategorySubcategorySelect
                  handleShowAll={handleShowAll}
                  categories={categories}
                  selected={selected}
                  onSelect={onSingleCategorySelect}
                  onSingleCategorySelect={onSingleCategorySelect}
                  isDropdown={true}
                />
              )}

              <p className="caption">
                <FormattedMessage
                  id="campaignListing::filters::orderTitle"
                  defaultMessage="Order"
                />
              </p>
              <SortSelector
                defaultValue={defaultSortValue}
                options={sortOptions}
                handleSort={handleSortAction}
                isOfferList={isOfferList}
                isMobile={true}
              />
            </StyledListingFilters>
          }
        />
      ) : null}
    </StyledListingFilterWrapper>
  )
}

export default ListingFilters

ListingFilters.propTypes = {
  handleSelect: PropTypes.func,
  categories: PropTypes.array,
  selected: PropTypes.array,
  onSelect: PropTypes.func,
  onSingleCategorySelect: PropTypes.func,
  isOfferList: PropTypes.bool,
}
