import styled from "@emotion/styled"

export const StyledSignUpWrapper = styled.div`
  min-height: 80vh;
  height: 100%;
  margin-top: 3rem;
  padding: 0 1rem 3rem;

  form {
    margin: 0 auto 5rem;
    max-width: 28rem;

    .top-align,
    label {
      margin-bottom: 0.5rem;
    }

    .hide-chevron .react-select__dropdown-indicator {
      display: none;
    }
  }

  a {
    cursor: pointer;
    text-decoration: none;
    color: ${(p) => p.theme.colors.blue};

    &,
    &:visited,
    &:hover,
    &:active,
    &.active {
      color: ${(p) => p.theme.colors.blue};
    }
  }
  .less-margin {
    margin-bottom: 1rem;
  }

  .sticky-bottom-bar {
    button {
      max-width: 28rem;
      margin: auto;
    }
  }

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    form {
      hr {
        margin: 2.5rem 0;
      }
    }
  }
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    form {
      max-width: 38rem;

      hr {
        margin: 3.375rem 0;
      }
      .top-align,
      label {
        margin-bottom: 0.75rem;
      }
    }
  }
`

export const SignUpTitle = styled.h1`
  color: ${(p) => p.theme.colors.primaryTextColor};
  font-size: 1.5rem;
  line-height: 2.125rem;
  text-align: center;
  padding-top: 1rem;
  font-weight: ${(p) => p.theme.fontWeights.medium};

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 2rem;
    padding-top: 2rem;
  }

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 3rem;
    padding-top: 4rem;
    margin-bottom: 3rem;
  }

  .communication-language {
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    color: ${(p) => p.theme.colors.pitchBlack};
    text-align: center;

    font-size: 1rem;
    line-height: 1.375rem;

    margin: 1rem 0 1.5rem;

    @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      margin: 1.5rem 0 2rem;
    }

    @media only screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.5rem;
      line-height: 2.25rem;
    }
  }
`
export const SignUpText = styled.p`
  color: ${(p) => p.theme.colors.gondola};
  font-weight: ${(p) => p.theme.fontWeights.regular};
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  margin-bottom: 1.25rem;
`

export const ReferralText = styled.p`
  color: ${(p) => p.theme.colors.gondola};
  text-align: center;
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-size: 1em;
  margin-bottom: 2.8125rem;
`

export const FormDescription = styled.p`
  font-weight: ${(p) => p.theme.fontWeights.regular};
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0 0 0.625rem;
`
export const SignUpSubmitWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.625rem 0;
  min-height: 6.25rem;

  > button {
    width: 100%;
    height: 2.5rem;
    border-radius: 0.25rem;

    @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      min-width: 15rem;
    }

    @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      width: 100%;
      height: 3.375rem;
      font-size: 1rem;
      margin: 0;
    }
  }
`
export const StyledCheckBoxWrapper = styled.div`
  margin-top: 3rem;
`

export const StyledSignUpSubText = styled.div`
  width: 100%;
  min-width: 16.5rem;
  margin: auto;
  text-align: center;
  margin-bottom: 1.5rem;

  .carrot {
    color: ${(p) => p.theme.colors.carrot};
    &,
    &:visited,
    &:hover,
    &:active,
    &.active {
      color: ${(p) => p.theme.colors.carrot};
    }
  }

  p {
    text-align: center;
    margin-bottom: 0.5rem;
  }

  button {
    height: 3.375rem;
    width: 100%;
    background-color: ${(p) => p.theme.colors.white};
    border: 0.0625rem solid rgba(221, 221, 221, 1);
    border-radius: 0.25rem;
    cursor: pointer;
    color: rgba(17, 17, 17, 1);
    font-size: 1.125rem;
    font-weight: ${(p) => p.theme.fontWeights.bold};
    line-height: 1.3125rem;
    text-align: center;
    position: relative;
    margin-bottom: 2.4375rem;
    svg {
      position: absolute;
      left: 1rem;
      top: 0.8125rem;
      font-size: 1.75rem;
      height: 1.75rem;
    }
  }

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin-bottom: 2rem;
    font-size: 1.5rem;
    p {
      margin-bottom: 1rem;
      font-size: 1.5rem;
    }
  }
`

export const StyledFormInputSection = styled.div`
  > div {
    margin-bottom: 2rem;
  }
`

export const StyledSignupImage = styled.div`
  border-radius: 50%;
  padding: 1.25rem 0 2rem;
  margin: auto;
  img {
    width: 10rem;
    height: 10rem;
  }
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 1.5rem 0 2.5rem;
    img {
      width: 14.375rem;
      height: 14.375rem;
    }
  }

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding: 2rem 0 3.375rem;
    img {
      width: 20.3125rem;
      height: 20.3125rem;
    }
  }
`

export const StyledSubtitle1 = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  margin: auto;
  margin-bottom: 1rem;
  text-align: center;

  span {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.pitchBlack};
  }

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1.5rem;
    line-height: 2.25rem;
    margin-bottom: 1.5rem;
  }
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    width: 36.125rem;
  }
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2.5rem;
    line-height: 3.75rem;
    width: 63.3125rem;
    margin-bottom: 2.5rem;
  }
`

export const StyledSubtitle2 = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.pitchBlack};
  font-size: 1.125rem;
  line-height: 1.5rem;
  text-align: center;
  margin: auto;
  margin-bottom: 2rem;
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 2rem;
    line-height: 2.625rem;
    margin-bottom: 2.5rem;
  }
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    width: 47.1875rem;
  }
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 3rem;
    line-height: 4.0625rem;
    width: 77.0625rem;
    margin-bottom: 3rem;
  }
`
