import PropTypes from "prop-types"
import theme from "@tmu/src/global/theme"
import { components } from "react-select"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEllipsisH } from "@fortawesome/pro-light-svg-icons/faEllipsisH"

import { StyledDotMenuButton, StyledDropDown } from "./index.styles"

import { Dropdown } from "@tmu/components/common"
import { getAllScreenTypes } from "@tmu/src/utils/mediaQueries"
import { useRef } from "react"

const TooltipSelectMenu = ({
  options = [],
  menuWidth,
  tooltipHeight,
  menuIsOpen,
  row,
  onMouseLeave,
  onMouseOver,
}) => {
  const whiteSmoke = theme?.colors?.whiteSmoke
  const menuButtonRef = useRef()

  const { isWide } = getAllScreenTypes()

  const styles = {
    control: {
      border: "none",
      padding: 0,
      margin: 0,
      minWidth: "none",
    },
    valueContainer: {
      height: 0,
      width: 0,
    },
    inputContainer: {
      height: 0,
      width: 0,
    },
    placeholder: {
      height: 0,
    },
    indicatorContainer: {
      padding: 0,
    },
    indicator: {
      padding: 0,
    },
    menuPortal: (base, t) => {
      const rect = t?.rect

      const newTop =
        window?.innerHeight < rect?.top + tooltipHeight
          ? base?.top + 15
          : base?.top - 15

      var newBase = {
        ...base,
        width: "100%",
        top: newTop,
        whiteSpace: "nowrap",
        left:
          menuButtonRef?.current?.getBoundingClientRect()?.left -
          menuWidth +
          menuButtonRef?.current?.offsetWidth +
          10,
      }
      return newBase
    },
    menu: {
      width: "none",
    },
    option: isWide
      ? {
          fontSize: "1rem",
          lineHeight: "2.5rem",
          height: "2.75rem",
          minWidth: "6rem",
          padding: "0.25rem",
          paddingLeft: "1rem",
          color: theme.colors.pitchBlack,
          cursor: "pointer",
        }
      : {
          fontSize: "0.75rem",
          lineHeight: "1.25rem",
          height: "2.25rem",
          minWidth: "4rem",
          padding: "0.5rem",
          paddingLeft: "0.75rem",
          color: theme.colors.pitchBlack,
          cursor: "pointer",
        },
  }

  return (
    <div
      ref={menuButtonRef}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}>
      <StyledDropDown>
        <Dropdown
          menuIsOpen={menuIsOpen}
          options={options}
          menuPosition="absolute"
          menuPlacement="auto"
          menuPortalTarget={document.body}
          controlShouldRenderValue={false}
          components={{ DropdownIndicator, ValueContainer }}
          defaultSelectStyles={styles}
          onChange={(selected) => {
            const action = selected?.action

            if (action) {
              action(row)
            }
          }}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary75: whiteSmoke,
              primary50: whiteSmoke,
              primary25: whiteSmoke,
              primary: whiteSmoke,
            },
          })}
        />
      </StyledDropDown>
    </div>
  )
}

const DropdownIndicator = ({ children, ...props }) => {
  return (
    <components.DropdownIndicator {...props}>
      <StyledDotMenuButton>
        <FontAwesomeIcon icon={faEllipsisH} />
      </StyledDotMenuButton>
    </components.DropdownIndicator>
  )
}

const ValueContainer = ({ children, ...props }) => {
  return (
    <components.ValueContainer {...props}>{children}</components.ValueContainer>
  )
}

TooltipSelectMenu.propTypes = {
  menuWidth: PropTypes.number,
  tooltipHeight: PropTypes.number,
  options: PropTypes.array,
  row: PropTypes.object,
}

export default TooltipSelectMenu
