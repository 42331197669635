import React, { useEffect } from "react"
import { Router, useLocation } from "@reach/router"
import { useQuery, useLazyQuery } from "@apollo/client"
import { navigate } from "gatsby-plugin-intl"
import { isTokenExpired } from "@tmu/utils/auth"
import { get } from "@tmu/utils/storage"
import queryString from "query-string"
import { MERCHANT_DEFAULT_OFFER_QUERY } from "@tmu/apollo/storefront/queries/merchant"
import { OFFER_LINK_WITH_TOKEN } from "@tmu/apollo/storefront/queries/user"

const TMUExtension = ({ id }) => {
  const isAuthenticated = !isTokenExpired(get("token"))
  const { search } = useLocation()
  const paramOptions = { arrayFormat: "comma" }
  const params = queryString.parse(search, paramOptions)

  const { data: defaultOfferData } = useQuery(MERCHANT_DEFAULT_OFFER_QUERY, {
    variables: { id },
  })

  const [getOfferLink, { loading, data }] = useLazyQuery(
    OFFER_LINK_WITH_TOKEN,
    {
      variables: { offerId: defaultOfferData?.merchant?.defaultOffer?.id },
    }
  )

  useEffect(() => {
    if (loading) return

    if (!isAuthenticated) {
      const redirectUrl = `/redirect/${id}`
      navigate(`/signin?next=${redirectUrl}`)
    } else {
      if (defaultOfferData?.merchant?.defaultOffer?.id) {
        getOfferLink({
          onCompleted: () => {
            if (data?.offerLinkWithToken?.offerLink) {
              window.open(data.offerLinkWithToken.offerLink, "_blank")
            }

            navigate(params?.backUrl || "/user-space")
          },
        })
      }
    }
  }, [defaultOfferData?.merchant?.defaultOffer?.id, loading])

  return <></>
}

const Redirect = () => {
  return (
    <Router basepath="/:locale/redirect/">
      <TMUExtension path="/:id" />
    </Router>
  )
}

export default Redirect
