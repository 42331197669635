import styled from "@emotion/styled"

export const StyledMainContainer = styled.div`
  background-color: ${(p) => p.theme.colors.whiteSmoke};
  display: flex;
  border-radius: 0;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    border-radius: 0.03125rem;
    margin: 2.5rem 2rem 0 1.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin: 2rem 2rem 0 2rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin: 2.5rem 6rem 0 6rem;
  }
`

export const StyledLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 999;
  flex: 1;

  padding: 2.875rem 0 2rem 1.0625rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 2.4375rem 0 2.5rem 2rem;
    height: 14.4375rem;
    width: 21.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    width: 30.75rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding: 3rem 0 3rem 2rem;
    width: 71.5rem;
    height: 18.5625rem;
  }
`

export const StyledLeftTitle = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.pitchBlack};
  font-size: 1.125rem;
  margin-bottom: 1.375rem;
  width: 12.25rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 2rem;
    line-height: 2.75rem;
    margin-bottom: 1.25rem;
    width: 34.5625rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin-bottom: 1.5rem;
    font-size: 3rem;
    line-height: normal;
    width: 51.25rem;
  }
`

export const StyledLeftContent = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  color: ${(p) => p.theme.colors.pitchBlack};
  margin-bottom: 1.5rem;
  font-size: 0.75rem;
  line-height: 1.25rem;
  width: 13rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1rem;
    line-height: 1.5rem;
    width: 33.0625rem;
    margin-bottom: 2.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2.25rem;
    width: 56.375rem;
    margin-bottom: 3rem;
  }
`

export const StyledLeftButton = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${(p) => p.theme.colors.blue};
  cursor: pointer;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
`

export const StyledRightContainer = styled.div`
  position: relative;
  height: 13.5rem;
  width: 11.25rem;

  & > img {
    object-fit: cover;
    height: 13.5rem;
    width: 11.25rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    border-radius: 0 0.03125rem 0.03125rem 0;
    width: 22.5rem;
    height: 14.4375rem;

    & > img {
      border-radius: 0 0.03125rem 0.03125rem 0;
      width: 22.5rem;
      height: 14.4375rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    width: 29.1875rem;
    height: 14.4375rem;

    & > img {
      width: 29.1875rem;
      height: 14.4375rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    width: 36.5rem;
    height: 18.5625rem;

    & > img {
      width: 36.5rem;
      height: 18.5625rem;
    }
  }
`

export const StyledShadow = styled.div`
  position: absolute;
  background: linear-gradient(
    90deg,
    ${(p) => p.theme.colors.whiteSmoke} 0%,
    transparent 100%
  );
  width: 5.125rem;
  height: 13.5rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    width: 12.6875rem;
    height: 14.4375rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    width: 18.25rem;
    height: 18.5625rem;
  }
`
