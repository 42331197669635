import styled from "@emotion/styled"

export const StyledPhoneInput = styled.div`
  position: relative;

  > .caption {
    margin-bottom: 0.5rem;
  }

  > div {
    text-align: left;
  }

  .field-error {
    position: absolute;
    height: 1rem;
  }

  .react-tel-input {
    input {
      z-index: 1;
      width: 100%;
      height: 3rem;
      font-family: ${(p) => p.theme.fontFamilies.regular};
      font-style: ${(p) => p.theme.fontStyles.normal};
      font-weight: ${(p) => p.theme.fontWeights.regular};
      background: ${(p) => p.theme.colors.white};
      border: 0.0625rem solid
        ${(p) => p.theme.colors[p.error ? "red" : "disabled"]};
      font-size: 1rem;
      &:focus {
        border: 0.0625rem solid ${(p) => p.theme.colors.blue};
      }
    }

    .selected-flag {
      z-index: 1;
    }
  }

  .input-feedback {
    margin: 0 0 !important;
  }

  &.error .form-control {
    border: 0.0625rem solid ${(p) => p.theme.colors.red};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
  }
`
