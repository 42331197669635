import React, { useContext, useEffect, /* useRef, */ useState } from "react"
import PropTypes from "prop-types"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import {
  Button,
  ClickToCopy,
  CustomModal,
  Date,
  Dropdown,
} from "@tmu/components/common"
import { UserManagementContext } from "@tmu/components/dashboard/dashboardPages/Users"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from "@fortawesome/pro-light-svg-icons/faTrash"
import { faCheck } from "@fortawesome/pro-solid-svg-icons/faCheck"
import { useAuth } from "@tmu/hooks"
import { API_PERMISSIONS } from "@tmu/apollo/constants"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { StyledCategoryHr } from "@tmu/components/dashboard/dashboardPages/Settings/index.styles"
import {
  selectStyles,
  StyledUserTableRow,
  StyledUserTableMobileRow,
  StyledRoleList,
  StyledRoleItem,
  StyledRoleNameContainer,
  StyledCheckIcon,
  StyledUserContent,
  StyledDeleteUserContainer,
  StyledEmailWrapper,
} from "./index.styles"

const UserTableRow = ({
  id,
  fullName,
  email,
  created,
  apiRole,
  isActive,
  isSelf,
  dashboardType,
  index,
}) => {
  const { onUpdateUser, onDeleteUser, roles } = useContext(
    UserManagementContext
  )

  const { apiPermissions } = useAuth()
  const { formatMessage } = useIntl()
  const { isTablet } = getAllScreenTypes()
  const [isEditing, setEditing] = useState(false)
  const [modalContent, setModalContent] = useState()
  const [menuModalStatus, setMenuModalStatus] = useState(false)

  const testId = "user-table-row" + index

  const handleChangeApiRole = async (value) => {
    await onUpdateUser({ id, apiRole: value?.id, isActive })
  }

  const handleDelete = () => {
    onDeleteUser({ id, apiRole, isActive })
  }

  const getRoleOptionLabel = (option) =>
    formatMessage({
      id: `apiRoles::role::${option.codename}`,
      defaultMessage: option.name,
    })

  const handleEnableEditing = () => {
    if (
      isSelf ||
      !apiPermissions?.includes(
        API_PERMISSIONS[
          dashboardType === "partners"
            ? "PARTNER_MANAGE_USERS"
            : "MERCHANT_MANAGE_USER"
        ]
      )
    ) {
      return
    }
    setEditing(true)
  }

  useEffect(() => setEditing(true), [])

  const userContent = (
    <>
      <StyledUserContent className="fullName">
        <strong>{fullName}</strong>
      </StyledUserContent>
      <StyledUserContent className="date">
        <FormattedMessage
          id="dashboard::merchant::tableHeader::added"
          defaultMessage="Added"
        />{" "}
        <Date
          value={created}
          year="2-digit"
          month="2-digit"
          day="2-digit"
          showYear={true}
          hour={null}
          minute={null}
        />
      </StyledUserContent>
      <StyledUserContent className="email" title={email}></StyledUserContent>
      <StyledCategoryHr />
      <StyledRoleList>
        {roles.map((role, index) => {
          return (
            <>
              <StyledRoleItem
                key={index}
                onClick={() => handleChangeRole(role?.codename)}>
                <StyledRoleNameContainer>
                  {getRoleOptionLabel(role)}
                </StyledRoleNameContainer>
                {role?.codename === apiRole?.codename && (
                  <StyledCheckIcon>
                    <FontAwesomeIcon icon={faCheck} />
                  </StyledCheckIcon>
                )}
              </StyledRoleItem>
              <StyledCategoryHr />
              <StyledDeleteUserContainer>
                <Button
                  disabled={isSelf}
                  data-testid={"btn-delete-user" + index}
                  className="button-margin"
                  color="transparent"
                  onClick={handleDelete}>
                  <FormattedMessage
                    id="dashboard::users::deleteUser"
                    defaultMessage="Delete User"
                  />
                </Button>
              </StyledDeleteUserContainer>
            </>
          )
        })}
      </StyledRoleList>
    </>
  )

  const handleChangeRole = (e) => {
    if (e !== apiRole?.codename) {
      const selectedRole = roles?.filter((role) => role?.codename === e)
      handleChangeApiRole(selectedRole[0])
      closeModal()
    }
  }

  const showModal = (content, header) => {
    setMenuModalStatus(true)

    setModalContent({
      children: content,
      header: header,
    })
  }

  const closeModal = () => {
    setMenuModalStatus(false)
  }

  return isTablet ? (
    <StyledUserTableRow data-testid={testId}>
      <div className="fullName">
        <strong>{fullName}</strong>
      </div>
      <>
        <div className="role" onClick={handleEnableEditing}>
          {isActive && isEditing && !isSelf && roles && roles.length > 0 ? (
            <Dropdown
              className="role"
              value={roles.find((r) => r.id === apiRole.id)}
              data-testid="api-role-select"
              classNamePrefix="react-select"
              variant="condensed"
              autoFocus={true}
              isSearchable={false}
              options={roles}
              onChange={handleChangeApiRole}
              getOptionLabel={getRoleOptionLabel}
              getOptionValue={(option) => option.id}
              defaultSelectStyles={selectStyles}
            />
          ) : (
            <FormattedMessage
              id={`apiRoles::role::${apiRole.codename}`}
              defaultMessage={apiRole.name}
            />
          )}
        </div>
        <div className="date">
          <Date
            value={created}
            year="2-digit"
            month="2-digit"
            day="2-digit"
            showYear={true}
            hour={null}
            minute={null}
          />
        </div>
        <StyledEmailWrapper>
          <ClickToCopy text={email}>{email}</ClickToCopy>
        </StyledEmailWrapper>
        <div className="edit-buttons hide-mobile">
          {apiPermissions?.includes(
            API_PERMISSIONS[
              dashboardType === "partners"
                ? "PARTNER_MANAGE_USERS"
                : "MERCHANT_MANAGE_USER"
            ]
          ) && (
            <>
              {!isSelf && (
                <Button
                  disabled={isSelf}
                  variant="text"
                  label="Delete"
                  data-testid={"btn-delete-user" + index}
                  onClick={handleDelete}>
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              )}
            </>
          )}
        </div>
      </>
    </StyledUserTableRow>
  ) : (
    <>
      <StyledUserTableMobileRow>
        <div className="fullName">
          <strong>{fullName}</strong>
        </div>
        <div>
          {!isSelf ? (
            <Button
              variant="text"
              color="blue"
              onClick={() => showModal(userContent, "")}>
              <FormattedMessage
                id={`apiRoles::role::${apiRole.codename}`}
                defaultMessage={apiRole.name}
              />
            </Button>
          ) : (
            <FormattedMessage
              id={`apiRoles::role::${apiRole.codename}`}
              defaultMessage={apiRole.name}
              tagName="p"
            />
          )}
        </div>
      </StyledUserTableMobileRow>

      <CustomModal
        isModalOpen={menuModalStatus && !isTablet}
        style={modalContent?.style}
        header={modalContent?.header}
        headerStyle={modalContent?.headerStyle}
        text={modalContent?.text}
        children={modalContent?.children}
        isCloseIcon={modalContent?.isCloseIcon || true}
        isCloseXButton={modalContent?.isCloseXButton}
        cancelButtonAction={() => {
          closeModal()
          if (typeof modalContent?.onClose === "function") {
            modalContent.onClose()
          }
        }}
        isMobile={true}
        isFull={true}
      />
    </>
  )
}

UserTableRow.propTypes = {
  id: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
  apiRole: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    codename: PropTypes.string,
  }).isRequired,
  isSelf: PropTypes.bool,
  isActive: PropTypes.bool,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
}

export default UserTableRow
