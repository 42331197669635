import React, { createContext, useCallback, useContext } from "react"
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client"
import { onError } from "@apollo/client/link/error"
import { setContext } from "@apollo/client/link/context"
import { createUploadLink } from "apollo-upload-client"
import { TokenRefreshLink } from "apollo-link-token-refresh"
import fetch from "cross-fetch"
import { jwtDecode } from "jwt-decode"
import { navigate } from "gatsby-plugin-intl"
import { useToast } from "@tmu/hooks"
import {
  setCredentials,
  getCredentials,
  removeCredentials,
  isTokenExpired,
} from "@tmu/utils/auth"

let customDefaultHeaders = {}

if (process.env.CF_ACCESS_CLIENT_ID) {
  customDefaultHeaders["CF-Access-Client-ID"] = process.env.CF_ACCESS_CLIENT_ID
}

if (process.env.CF_ACCESS_CLIENT_SECRET) {
  customDefaultHeaders["CF-Access-Client-Secret"] =
    process.env.CF_ACCESS_CLIENT_SECRET
}

export const ApolloClientContext = createContext({})

export const ApolloClientProvider = ({ children }) => {
  const { warning: warnToast } = useToast()

  const cache = new InMemoryCache()
  const clientDefaults = {
    cache,
    connectToDevTools: false,
  }

  /* Set URI for all Apollo GraphQL requests */
  const httpLinkUser = createUploadLink({
    uri: process.env.API_GRAPHQL_ENDPOINT,
    fetch,
    credentials: "omit",
  })

  const httpLinkStrapi = createUploadLink({
    uri: process.env.API_STRAPI_GRAPHQL_ENDPOINT,
    fetch,
    credentials: "omit",
  })

  const httpLinkPartner = createUploadLink({
    uri: process.env.API_PARTNER_GRAPHQL_ENDPOINT,
    fetch,
    credentials: "omit",
  })

  const httpLinkMerchant = createUploadLink({
    uri: process.env.API_MERCHANT_GRAPHQL_ENDPOINT,
    fetch,
    credentials: "omit",
  })

  /* Set in-memory token to reduce async requests */
  const withTokenLink = setContext(async () => {
    // return token if there
    let { token: accessToken } = getCredentials()

    if (accessToken) return { accessToken }

    // else check if valid token exists with client already and set if so
    try {
      accessToken = newToken
      return { accessToken: newToken }
    } catch (error) {
      return { accessToken: null }
    }
  })

  /* Create Apollo Link to supply token in auth header with every gql request */
  const authLink = setContext(({ variables, query }, { headers }) => {
    const operationType = query?.definitions?.[0]?.operation
    let { token: accessToken } = getCredentials()
    let expired = false
    if (accessToken) {
      const accessTokenDecrypted = jwtDecode(accessToken)
      const current_time = new Date().getTime() / 1000
      expired = current_time > accessTokenDecrypted.exp
    }
    if (expired || variables?.isPublic) accessToken = null
    return {
      headers: {
        ...headers,
        ...customDefaultHeaders,
        ...(accessToken ? { authorization: `JWT ${accessToken}` } : {}),
      },
    }
  })

  //TODO: Token refresh package (apollo-link-token-refresh") to be updated or replaced
  const tokenRefreshLink = new TokenRefreshLink({
    // This is a name of access token field in response. In some scenarios we want to pass
    // additional payload with access token, i.e. new refresh token, so this field could be
    // the object's name
    accessTokenField: "token",

    isTokenValidOrUndefined: async () => {
      // Indicates the current state of access token expiration.
      // - If token not yet expired or
      // - user doesn't have a token (guest)
      // `true` should be returned
      const { token } = getCredentials()

      try {
        const expired = isTokenExpired(token)
        return !expired
      } catch (err) {
        const guest = !token || token?.length === 0
        return guest
      }
    },

    fetchAccessToken: async () => {
      // Function covers fetch call with request fresh access token
      const { token, refreshToken } = getCredentials()
      if (!isTokenExpired(token) || !refreshToken) return
      const resp = await fetch(process.env.API_GRAPHQL_ENDPOINT, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "CF-Access-Client-ID": process.env.CF_ACCESS_CLIENT_ID,
          "CF-Access-Client-Secret": process.env.CF_ACCESS_CLIENT_SECRET,
        },
        body: JSON.stringify({
          query: `
          mutation RefreshToken {
            refreshToken(input: {refreshToken: "${refreshToken}"}) 
             {
              success
              token
              refreshToken
              payload
            }
          }
            `,
        }),
      })
      return resp.json()
    },

    // Callback which receives a fresh token from Response.
    // From here we can save token to the storage
    handleFetch: (token) => {
      // already set in handleResponse
      //  setCredentials({ token })
    },

    handleResponse: (operation, accessTokenField) => (response) => {
      if (
        !response?.data?.refreshToken?.token ||
        !response?.data?.refreshToken?.refreshToken
      ) {
        // removeCredentials() // TODO: this prevents silent registration.
        return { token: null, refreshToken: null }
      }
      // parse response, handle errors, prepare returned token to further operations
      const { token, refreshToken } = response.data.refreshToken

      setCredentials({ token, refreshToken })
      return {
        token,
        refreshToken,
      }
    },

    handleError: (err) => {
      // console.log("ApolloClientProvider ~ error:", err)
    },
  })
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        const tokenErrors = [
          "Signature has expired",
          "invalid token",
          "Error decoding signature",
        ]
        const { message } = error
        if (tokenErrors.includes(message)) {
          navigate("/")
        } else if (message.toLocaleLowerCase() === "session has expired") {
          window.location.reload()
        }
      })
    }

    if (networkError) {
      const { message } = networkError
      console.error(message)
    }
  })

  const storefrontLink = ApolloLink.from([
    withTokenLink,
    tokenRefreshLink,
    authLink,
    errorLink,
    httpLinkUser,
  ])

  const strapiLink = ApolloLink.from([errorLink, httpLinkStrapi])

  const partnerLink = ApolloLink.from([
    withTokenLink,
    tokenRefreshLink,
    authLink,
    errorLink,
    httpLinkPartner,
  ])

  const merchantLink = ApolloLink.from([
    withTokenLink,
    tokenRefreshLink,
    authLink,
    errorLink,
    httpLinkMerchant,
  ])

  const defaultOptions = {
    watchQuery: {
      errorPolicy: "all",
    },
    query: {
      errorPolicy: "all",
    },
    mutate: {
      errorPolicy: "all",
    },
  }

  const clients = {
    storefrontClient: new ApolloClient({
      ...clientDefaults,
      link: storefrontLink,
      defaultOptions,
    }),
    strapiClient: new ApolloClient({
      ...clientDefaults,
      link: strapiLink,
      defaultOptions,
    }),
    partnerClient: new ApolloClient({
      ...clientDefaults,
      link: partnerLink,
      defaultOptions,
    }),

    merchantClient: new ApolloClient({
      ...clientDefaults,
      link: merchantLink,
      defaultOptions,
    }),
  }

  const getDashboardClient = useCallback((dashboardType = "donors") => {
    switch (dashboardType) {
      case "donors":
        return clients.storefrontClient
      case "partners":
        return clients.partnerClient
      case "merchants":
        return clients.merchantClient
      default:
        return clients.storefrontClient
    }
  }, [])

  return (
    <ApolloClientContext.Provider
      value={{
        ...clients,
        getDashboardClient,
      }}>
      <ApolloProvider client={clients.storefrontClient}>
        {children}
      </ApolloProvider>
    </ApolloClientContext.Provider>
  )
}

export const useApolloApiClients = () => useContext(ApolloClientContext)
