import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react"
import { useMutation, useQuery } from "@apollo/client"
import { navigate } from "gatsby-plugin-intl"
import { useAuth, useToast } from "@tmu/hooks"
import { ALL_ORDERS } from "@tmu/hooks/useAllOrders"
import { ME_QUERY } from "@tmu/apollo/storefront/queries/user"
import {
  CREATE_CART_ITEM_MUTATION,
  UPDATE_CART_ITEM_MUTATION,
  REMOVE_CART_ITEM_MUTATION,
} from "@tmu/apollo/storefront/mutations/cart"
import { useApolloApiClients } from "@tmu/apollo/client"

const UserShoppingCartContext = createContext({})

const useProvider = () => {
  const { isLoading, isAuthenticated } = useAuth()
  const [cartItems, setCartItems] = useState([])
  const [successMsg, setSuccessMsg] = useState("")
  const [errorMsg, setErrorMsg] = useState("")
  const { success, error } = useToast()

  const variables = {
    status_In: ["IN_PROGRESS"],
    first: 1,
    internal: true,
  }
  const { storefrontClient } = useApolloApiClients()
  const [createCartItem, { loading: createCartItemLoading }] = useMutation(
    CREATE_CART_ITEM_MUTATION,
    {
      client: storefrontClient,

      refetchQueries: [{ query: ALL_ORDERS, variables }, { query: ME_QUERY }],
      onCompleted: (data) => {
        if (data?.errors && data?.errors?.length) {
          let errorMessages = "Error : "

          data?.errors.forEach((err) => {
            try {
              errorMessages += err.message + "\n"
            } catch (e) {
              console.error(e)
            }
          })
          error(errorMessages)
          return
        }

        if (data && data?.createCartItem === null) {
          if (errorMsg.length) {
            error(errorMsg)
          }
          return
        }
        if (successMsg.length) {
          success(successMsg)
        }
      },
    }
  )

  const [updateCartItem, { loading: updateCartItemLoading }] = useMutation(
    UPDATE_CART_ITEM_MUTATION,
    {
      client: storefrontClient,

      refetchQueries: [{ query: ALL_ORDERS, variables }, { query: ME_QUERY }],
      onCompleted: (data) => {
        if (data?.errors && data?.errors?.length) {
          let errorMessages = "Error : "

          data?.errors.forEach((err) => {
            try {
              errorMessages += err.message + "\n"
            } catch (e) {
              console.error(e)
            }
          })
          error(errorMessages)
          return
        }

        if (data && data?.updateCartItem === null) {
          if (errorMsg.length) {
            error(errorMsg)
          }
          return
        }

        if (successMsg.length) {
          success(successMsg)
        }
      },
    }
  )
  const [removeCartItem, { loading: removeCartItemLoading }] = useMutation(
    REMOVE_CART_ITEM_MUTATION,
    {
      client: storefrontClient,
    }
  )

  const mapOrderEdges = (data) =>
    data?.allOrders?.edges?.map(({ node }) => ({
      ...node,
      cartItems: !node?.store?.id
        ? node?.cartItems?.edges?.map(({ node }) => ({
            ...node,
          }))
        : [],
    }))

  // const {
  //   loading: loadingAllOrders,
  //   data,
  //   refetch,
  // } = useQuery(ALL_ORDERS, {
  //   skip: !isAuthenticated,
  //   variables,
  //   client: storefrontClient,
  // })

  const addToCart = useCallback(
    async ({ offer, quantity = 1, successMessage = "", errorMessage = "" }) => {
      setSuccessMsg(successMessage)
      setErrorMsg(errorMessage)

      const { protocol, host, pathname } = window.location
      const returnTo = `${protocol}//${host}${pathname}`
        .replace("/signin", "")
        .replace("/signup", "")
      try {
        const existingCartItemId = cartItems?.[offer.id]?.cartItemId ?? null
        const existing = cartItems?.[offer.id]

        if (existing) {
          updateCartItem({
            variables: {
              input: {
                id: existingCartItemId,
                offer: offer.id,
                quantity: existing.quantity + quantity,
              },
            },
          })
        } else {
          createCartItem({
            variables: {
              input: {
                offer: offer.id,
                quantity,
              },
            },
          })
        }
      } catch (e) {
        navigate(`/signin`)
      }
    },
    []
  )

  const removeFromCart = ({ cartItemId }) => {
    removeCartItem({
      variables: {
        input: { id: cartItemId },
      },
    }) //.then(() => refetch())
  }

  const setQuantity = (payload) => {
    updateCartItem({
      variables: {
        input: {
          id: payload.cartItemId,
          offer: payload.productId,
          quantity: payload.quantity,
        },
      },
    })
  }

  // useEffect(() => {
  //   if (loadingAllOrders) {
  //     return
  //   }

  //   const order = data?.allOrders?.edges?.[0]?.node
  //   const cartItems =
  //     order?.cartItems?.edges?.filter(({ node }) => !node?.store?.id) ?? []
  //   setCartItems(cartItems)
  // }, [loadingAllOrders, data])

  const mutating =
    createCartItemLoading || updateCartItemLoading || removeCartItemLoading

  return {
    cartItems,
    cartItemCount: cartItems?.length,
    addToCart,
    loading: isLoading, // || loadingAllOrders,
    mutating,
    setQuantity,
    mapOrderEdges,
    refetchOrders: () => {}, //refetch,
    removeFromCart,
  }
}

export const useShoppingCart = () => useContext(UserShoppingCartContext)

export const ShoppingCartProvider = ({ children }) => {
  const cart = useProvider()

  return (
    <UserShoppingCartContext.Provider value={cart}>
      {children}
    </UserShoppingCartContext.Provider>
  )
}
