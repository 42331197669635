import styled from "@emotion/styled"

export const StyledWrapper = styled.div`
  max-width: ${(p) => p.theme.breakpoints.wide};
  margin: 0 auto;
  position: relative;
  display: block;
  width: 100%;

  ${(p) =>
    p.zebra &&
    css`
      max-width: 100%;
      margin: 0 auto;
      background-color: ${p.theme.colors.pampasBackgroundColor};

      > div {
        max-width: ${p.theme.breakpoints.wide};
        margin: 0 auto;
        display: block;
      }
    `}

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: block;

    & > img {
      display: none;
    }
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    h2 {
      display: flex;
      justify-content: center;
    }
  }
`

export const StyledHeading = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  h2 {
    margin-right: 1.5rem;
    margin-bottom: 0;
    width: auto;
    display: inline-block;
  }

  > div {
    display: inline-flex;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
    h2 {
      flex: 2 1 100%;
    }
    > div {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    > div {
      display: block;
      width: 100%;
    }
  }
`

export const StyledAboutTabs = styled.div`
  line-height: 1.75rem;

  .react-tabs__tab-list {
    border-bottom: 0.0625rem solid ${(p) => p.theme.colors.tabFieldsColor};
  }

  .react-tabs__tab {
    color: ${(p) => p.theme.colors.gondola};
    opacity: 0.3;
    outline: none;
    cursor: pointer;
    padding: 0 0 0.875rem 1rem;
    transition: 0.3s;
    font-size: 1.125rem;
    background: none;
    border: none;
    font-weight: ${(p) => p.theme.fontWeights.bold};
    margin: 0;
  }

  .react-tabs__tab--selected {
    border-bottom: 0.125rem solid ${(p) => p.theme.colors.primaryBrandTextColor};
    color: ${(p) => p.theme.colors.primaryBrandTextColor};
    opacity: 1;
  }

  &,
  p {
    color: ${(p) => p.theme.colors.disabled};
    font-weight: ${(p) => p.theme.fontFamilies.bold};
    font-size: 1rem;
    line-height: 1.75rem;
    margin-bottom: 1.5625rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-weight: ${(p) => p.theme.fontWeights.bold};
    line-height: 1.75rem;
    margin-top: 1.5625rem;
    margin-bottom: 1.5625rem;
  }

  img {
    width: 100%;
  }

  h3 {
    margin: 2.5rem 0 1.5625rem 0;
    line-height: 1.8125rem;
  }
  p {
    font-size: 1rem;
  }
  @media only screen and (max-width: 48rem) {
    display: block;
    width: 100%;
  }
`

export const StyledPageWrapper = styled.div`
  display: grid !important;
  grid-template-columns: 8fr 2fr;
  grid-template-areas: "left right";
  grid-gap: 0.2rem;
  width: -webkit-fill-available;
  width: 100%;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    grid-template-columns: 5fr;
    grid-template-areas:
      "left"
      "right";

    grid-gap: 1rem;
  }
`

export const StyledPageLeft = styled.div`
  grid-area: left;
  width: 80%;
  align-self: start;
  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    align-self: center;
    width: 100%;
  }
`

export const StyledPageRight = styled.div`
  grid-area: right;
  width: 20%;
  margin-top: -1.5rem;
  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    align-self: center;
    margin-top: 0;
    width: 100%;
  }
`

export const StyledGalleryContainer = styled.div`
  max-width: 50rem;
  margin-top: 3rem;
`
