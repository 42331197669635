import React from "react"
import { StyledEmptyTable } from "./index.styles"

const EmptyTable = ({ emptyMessage }) => {
  return (
    <>
      <StyledEmptyTable data-testid="empty-table">
        {emptyMessage}
      </StyledEmptyTable>
    </>
  )
}

export default EmptyTable
