import React, { useState } from "react"
import { useLocation } from "@reach/router"
import PropTypes from "prop-types"
import { FormattedMessage, navigate, useIntl } from "gatsby-plugin-intl"
import { Formik } from "formik"
import { useMutation } from "@apollo/client"
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3"
import * as Yup from "yup"
import queryString from "query-string"
import {
  organizationName,
  merchantName,
  contactName,
  email,
  agreeTerms,
  phone,
  urlFormatRequired,
} from "@tmu/utils/validation"
import {
  Button,
  TermsAndConditions,
  TextInput,
  PhoneInput,
} from "@tmu/components/common"

import { useToast } from "@tmu/hooks"
import { StyledContactForm } from "./index.styles"
import { CREATE_CONTACT_MUTATION } from "@tmu/apollo/storefront/mutations/user"
import { useApolloApiClients } from "@tmu/apollo/client"

const _ContactForm = ({
  sender,
  termsLink = "/legal/donor-terms/17",
  ...rest
}) => {
  const [token, setToken] = useState()
  const { executeRecaptcha } = useGoogleReCaptcha()
  if (!token && executeRecaptcha) {
    try {
      executeRecaptcha("contact_form").then((data) => setToken(data))
    } catch (e) {}
  }
  const { search } = useLocation()
  const { formatMessage } = useIntl()
  const { success, error } = useToast()

  const validationSchemaObject = {
    contactName: contactName({ formatMessage }),
    phoneNumber: phone({ formatMessage }),
    email: email({ formatMessage }),
    isTermsAndPolicyAccepted: agreeTerms({ formatMessage }),
  }

  if (rest?.isWebsite) {
    validationSchemaObject["website"] = urlFormatRequired({ formatMessage })
  } else {
    validationSchemaObject["organizationName"] =
      sender === "PARTNER"
        ? organizationName({ formatMessage })
        : merchantName({ formatMessage })
  }

  const validationSchema = Yup.object().shape(validationSchemaObject)

  const initialValues = {
    email: "",
    organizationName: "",
    contactName: "",
    phoneNumber: "",
    website: "",
    isTermsAndPolicyAccepted: false,
  }
  const { storefrontClient } = useApolloApiClients()
  const [contactMutate] = useMutation(CREATE_CONTACT_MUTATION, {
    client: storefrontClient,
  })

  const handleSubmit = (values, form) => {
    values.country = ""
    values.city = ""
    if (!values?.organizationName) values.organizationName = "default"
    if (!token) {
      error(
        formatMessage({
          id: "howitworks::contactForm::captchaErrorMessage",
          defaultMessage: "Google Captcha is not confirmed.",
        })
      )
      return
    }
    form.setSubmitting(true)
    let input = { ...values, contactType: sender, captcha: token }

    contactMutate({ variables: { input, isPublic: true } })
      .then(({ data }) => {
        if (!data || data?.createContact?.errors?.length) {
          error(
            data?.createContact?.errors?.[0]?.messages?.[0] ||
              formatMessage({
                id: "howitworks::contactForm::errorMessage",
                defaultMessage: "An error occurred",
              })
          )
        } else {
          const { createContact } = data
          success(
            formatMessage({
              id: "howitworks::contactForm::successMessage",
              defaultMessage:
                "Our team will be contacting you right away. Meanwhile, create an account so we can onboard you faster",
            })
          )
          const email = createContact?.contact?.email
          const referralCode = queryString.parse(search)?.referral
          setTimeout(
            () =>
              navigate(
                `/signup?${
                  referralCode && referralCode !== "undefined"
                    ? `referral="${referralCode}&`
                    : ""
                }email=${email}&accountType=${
                  sender === "PARTNER"
                    ? "NGO"
                    : sender === "MERCHANT"
                    ? "BUSINESS"
                    : "PERSONAL"
                }`
              ),
            1500
          )
        }
      })
      .catch((err) => {
        error(
          formatMessage({
            id: "howitworks::contactForm::errorMessage",
            defaultMessage: err?.message ?? "An error occurred",
          })
        )
      })
      .finally(() => {
        setToken("")
        form.setSubmitting(false)
      })
  }

  const organizationInputLabel =
    sender === "PARTNER"
      ? formatMessage({
          id: "howItWorks::forms::organizationName",
          defaultMessage: "Organization Name",
        })
      : formatMessage({
          id: "howItWorks::forms::merchant",
          defaultMessage: "Merchant",
        })
  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}>
      {({
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setTouched,
        isSubmitting,
      }) => (
        <>
          <StyledContactForm style={rest?.customStyles} onSubmit={handleSubmit}>
            <TextInput
              newDesign
              id="contactName"
              name="contactName"
              type="text"
              label={formatMessage({
                id: "howItWorks::forms::contactName",
                defaultMessage: "Contact Name",
              })}
              placeholder={formatMessage({
                id: "howItWorks::forms::contactName",
                defaultMessage: "Contact name",
              })}
              error={errors.contactName}
              touched={touched.contactName}
              value={values.contactName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <TextInput
              newDesign
              id="email"
              name="email"
              type="email"
              label={formatMessage({
                id: "howItWorks::forms::email",
                defaultMessage: "Email",
              })}
              placeholder={formatMessage({
                id: "signUp::emailPlaceholder",
                defaultMessage: "Type your email",
              })}
              error={errors.email}
              touched={touched.email}
              value={values.email}
              onChange={(e) => {
                const lowerCaseEmail = e.target.value.toLowerCase()
                handleChange({
                  target: {
                    name: e.target.name,
                    value: lowerCaseEmail,
                  },
                })
              }}              onBlur={handleBlur}
            />
            {!rest?.isWebsite ? (
              <TextInput
                newDesign
                id="organizationName"
                name="organizationName"
                type="text"
                label={organizationInputLabel}
                placeholder={formatMessage({
                  id: "signUp::emailPlaceholder",
                  defaultMessage: "Type your email",
                })}
                error={errors.organizationName}
                touched={touched.organizationName}
                value={values.organizationName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            ) : (
              <TextInput
                newDesign
                id="website"
                name="website"
                type="text"
                label={formatMessage({
                  id: "howItWorks::forms::website",
                  defaultMessage: "Website",
                })}
                placeholder={formatMessage({
                  id: "howItWorks::forms::websitePlaceholder",
                  defaultMessage: "Website",
                })}
                error={errors.website}
                touched={touched.website}
                value={values.website}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            )}
            <PhoneInput
              title={
                <FormattedMessage
                  id="howItWorks::forms::phone"
                  defaultMessage="Mobile Number"
                />
              }
              name="phoneNumber"
              value={values.phoneNumber}
              errors={errors}
              touched={touched}
              onChange={(phone) => setFieldValue("phoneNumber", phone, true)}
              onBlur={() => setTouched({ ...touched, phoneNumber: true })}
            />
            <TermsAndConditions
              name="isTermsAndPolicyAccepted"
              termsLink={termsLink}
              onChange={handleChange}
              onClick={() =>
                setTouched({ ...touched, isTermsAndPolicyAccepted: true })
              }
            />
            <div className="create-account-button">
              <Button
                color="red"
                type="submit"
                label="Send your request"
                disabled={isSubmitting}>
                <FormattedMessage
                  id="howItWorks::forms::letsTalk"
                  defaultMessage="Send your request"
                />
              </Button>
            </div>
          </StyledContactForm>
        </>
      )}
    </Formik>
  )
}

const ContactForm = ({ sender, termsLink = "/terms/donor-terms", ...rest }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}>
      <_ContactForm sender={sender} termsLink={termsLink} {...rest} />
    </GoogleReCaptchaProvider>
  )
}

ContactForm.propTypes = {
  sender: PropTypes.oneOf(["PARTNER", "MERCHANT", ""]),
}

ContactForm.defaultProps = {
  sender: "",
}
export default ContactForm
