import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  StyledHowItWorksTabContents,
  StyledHowitWorksTabContentRight,
  StyledHowitWorksTabContentLeft,
} from "@tmu/global/page-addons/how-it-works.styles"
const TabContent = ({
  icon,
  title,
  desc1,
  desc2,
  imgText,
  imgLandscapeMedium,
  imgSquare,
  imgLandscapeSmall,
}) => {
  return (
    <StyledHowItWorksTabContents>
      <StyledHowitWorksTabContentLeft>
        {icon && <FontAwesomeIcon icon={icon} />}
        {title}
        {desc1}
        {desc2}
      </StyledHowitWorksTabContentLeft>
      <StyledHowitWorksTabContentRight>
        {imgLandscapeMedium && (
          <div className="how_it_works__image--big">
            <img src={imgLandscapeMedium} alt={imgLandscapeMedium} />
          </div>
        )}
        {imgSquare && (
          <div className="how_it_works__image--small">
            <img src={imgSquare} alt={`how-it-works-tab-${imgSquare}`} />
          </div>
        )}
        {imgText && <p className="how_it_works__image--text">{imgText}</p>}{" "}
        {imgLandscapeSmall && (
          <div className="how_it_works__image--medium">
            <img
              src={imgLandscapeSmall}
              alt={`how-it-works-tab-${imgLandscapeSmall}`}
            />
          </div>
        )}
      </StyledHowitWorksTabContentRight>
    </StyledHowItWorksTabContents>
  )
}

export default TabContent
