import React from "react"
import { injectIntl, useIntl } from "gatsby-plugin-intl"
import Extension from "@tmu/components/extension"
import SEO from "@tmu/components/seo"
import { StyledPageContainer } from "@tmu/global/page-addons/extension-page.styles"

const ExtensionPage = () => {
  const { locale, formatMessage } = useIntl()

  return (
    <>
      <SEO
        lang={locale}
        title={formatMessage({
          id: "extension::common::pageTitle",
          defaultMessage: "TrustMeUp Extensions",
        })}
        description={formatMessage({
          id: "seo::extension::description",
          defaultMessage:
            "Would you like to be informed about the Charities and the Merchants we work with and to catch the opportunities? Install our extension.",
        })}
      />
      <StyledPageContainer>
        <Extension />
      </StyledPageContainer>
    </>
  )
}

export default injectIntl(ExtensionPage)
