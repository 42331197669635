import styled from "@emotion/styled"
import { css } from "@emotion/react"

export const StyledInfoBox = styled.span`
  border: 0.0625rem solid transparent;
  margin: ${(p) =>
    !p.campaign ? (p.condensed ? "0 0.3125rem" : "0 0.9375rem") : null};
  text-align: ${(p) => !p.campaign && "center"};
  padding: ${(p) => !p.campaign && "0.25rem 0.5rem"};
  border-radius: 0.3125rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  ${(p) =>
    p.info &&
    css`
      background-color: ${p.condensed
        ? p.theme.colors.transparent
        : p.theme.colors.gainsBoro};
      border-color: ${p.condensed
        ? p.theme.colors.transparent
        : p.theme.colors.darkGray};
      color: ${p.condensed
        ? p.theme.colors.lightBlue
        : p.theme.colors.darkGray} !important;
    `}

  ${(p) =>
    p.warning &&
    css`
      background-color: ${p.condensed
        ? p.theme.colors.transparent
        : p.theme.colors.canary};
      border-color: ${p.condensed
        ? p.theme.colors.transparent
        : p.theme.colors.goldenPoppy};
      color: ${p.condensed
        ? p.theme.colors.lightBlue
        : p.theme.colors.goldenPoppy} !important;
    `}

    ${(p) =>
    p.success &&
    css`
      background-color: ${p.condensed
        ? p.theme.colors.transparent
        : p.theme.colors.mintGreen};
      border-color: ${p.condensed
        ? p.theme.colors.transparent
        : p.theme.colors.infoBoxGreen};
      color: ${p.condensed
        ? p.theme.colors.lightBlue
        : p.theme.colors.infoBoxGreen} !important;
    `}
  
  ${(p) =>
    p.error &&
    css`
      background-color: ${p.condensed
        ? p.theme.colors.transparent
        : p.theme.colors.beautyBush};
      border-color: ${p.condensed
        ? p.theme.colors.transparent
        : p.theme.colors.mexicanRed};
      color: ${p.condensed
        ? p.theme.colors.lightBlue
        : p.theme.colors.mexicanRed} !important;
    `}
`
