import React from "react"
import { FormattedMessage, navigate } from "gatsby-plugin-intl"
import { Button } from "@tmu/components/common"
import { useLocation } from "@reach/router"
import { StyledGetStartedButton } from "../index.styles"
import queryString from "query-string"
import theme from "@tmu/global/theme"
import { isBrowser } from "@tmu/utils/auth"

const HeaderSigninSignUp = ({ customClass }) => {
  const { pathname, search } = useLocation()
  const next = pathname ? pathname.replace(/\/(en|it|es)/g, "") : "/user-space"
  const referralCode = queryString?.parse(search)?.referral

  const handleSignIn = () => {
    navigate(`/signin?next=${next || "/"}`)
  }

  const handleGetStarted = () => {
    navigate(
      `/signup?${
        referralCode && referralCode !== "undefined"
          ? `referral=${referralCode}&`
          : ""
      }next=${"/user-space"}`
    )
  }

  return (
    <>
      <li
        className={`navbar__login ${customClass}`}
        data-testid="navbar__login">
        <Button
          variant="text"
          label="Sign In"
          data-testid="btn-login"
          onClick={handleSignIn}>
          <a
            href={`/signin?${
              referralCode && referralCode !== "undefined"
                ? `referral="${referralCode}&`
                : ""
            }next=${
              isBrowser
                ? window?.location?.pathname + window?.location?.search
                : "/"
            }`}>
            <FormattedMessage id="nav::signin" defaultMessage="Sign In" />
          </a>
        </Button>
      </li>
      <li className="navbar__register " data-testid="navbar__register">
        <StyledGetStartedButton
          data-testid="btn-get-started"
          color={theme.colors.pacificBlue}
          // onClick={handleGetStarted}
          >
          <a
            href={`/signup?${
              referralCode && referralCode !== "undefined"
                ? `referral=${referralCode}&`
                : ""
            }next=${"/user-space"}`}>
            <FormattedMessage
              id="nav::getStarted"
              defaultMessage="Get Started"
            />
          </a>
        </StyledGetStartedButton>
      </li>
    </>
  )
}

export default HeaderSigninSignUp
