import styled from "@emotion/styled"

export const StyledEmptyTable = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2.5rem auto;
  font-size: 1.125rem;
  svg {
    font-size: 4rem;
    height: 4rem;
    margin-bottom: 1rem;
    opacity: 0.25;
  }
`
