import React from "react"
import { Router } from "@reach/router"
import { Settings } from "@tmu/components/dashboard/dashboardPages"

const SettingsRoute = () => (
  <Router>
    <Settings path="/*" />
  </Router>
)

export default SettingsRoute
