import styled from "@emotion/styled"

export const StyledMainContainer = styled.div`
  h1 {
    margin-bottom: unset;

    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.pitchBlack};

    font-size: 1.5rem;
    line-height: 2rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 2rem;
      line-height: 2.625rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 3rem;
      line-height: 4.0625rem;
    }
  }

  h4 {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.pitchBlack};

    font-size: 1.5rem;
    line-height: 2rem;

    margin: 3.75rem 1rem 1.125rem;
  }

  .top-bar {
    display: flex;
    gap: 3.5rem;
    align-items: center;
    justify-content: space-between;
    margin-top: 3rem;
    margin-right: 1rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      margin-top: 4.5rem;
      margin-bottom: 1.5rem;
      margin-right: 0;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      margin-top: unset;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      margin-bottom: 2.5rem;
    }

    button {
      font-family: ${(p) => p.theme.fontFamilies.headline};
      font-style: ${(p) => p.theme.fontStyles.normal};
      font-weight: ${(p) => p.theme.fontWeights.semiBolder};
      color: ${(p) => p.theme.colors.blue};
      text-transform: uppercase;
      white-space: nowrap;
      padding: 0;

      @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
        font-size: 0.75rem;
        line-height: 1rem;
      }

      @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
        font-size: 1rem;
        line-height: 1.5rem;
      }

      @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
        font-size: 1.25rem;
        line-height: 1.75rem;
      }
    }
  }

  .react-tabs {
    li {
      margin-bottom: unset;
      font-family: ${(p) => p.theme.fontFamilies.headline};
      font-style: ${(p) => p.theme.fontStyles.normal};
      font-weight: ${(p) => p.theme.fontWeights.semiBolder};
      color: ${(p) => p.theme.colors.pitchBlack};

      font-size: 1.125rem;
      line-height: 2.5rem;

      @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
        font-size: 1.25rem;
        line-height: 1.75rem;
      }

      @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      }

      @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
        font-size: 2rem;
        line-height: 2.625rem;
      }
    }

    .react-tabs__tab--selected {
      text-decoration: underline;
      text-underline-offset: 1rem;
    }

    margin: 0 1.125rem 1.5rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      margin: unset;
      margin-bottom: 1.375rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      margin-bottom: 2.875rem;
    }
  }
`

export const StyledMobileContainer = styled.div`
  max-width: 100%;
  margin: 0 1rem;
  padding-bottom: 5rem;
`

export const StyledTitle = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.black};

  font-size: 1.5rem;
  line-height: 2rem;

  text-align: center;

  padding: 6.9375rem 0 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1.5rem;
    line-height: 2rem;

    padding: 0 0.5rem;
    margin: 0.875rem 0 1rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
    line-height: 2.625rem;

    padding: 0 2.25rem;
    margin: 0.75rem 0;
  }
`

export const StyledContent = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  color: ${(p) => p.theme.colors.pitchBlack};

  font-size: 1rem;
  line-height: 1.375rem;

  text-align: center;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1rem;
    line-height: 1.375rem;
    padding: 0 0.75rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2.25rem;

    padding: 0 2.5rem;
  }

  &.mobile-message-content {
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    color: ${(p) => p.theme.colors.black};

    font-size: 1rem;
    line-height: 1.375rem;

    text-align: center;

    margin-top: 11.5rem;
  }

  &.non-mobile-message-content {
    text-align: left;
    padding: unset;

    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    color: ${(p) => p.theme.colors.black};

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 1rem;
      line-height: 1.375rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.5rem;
      line-height: 2.25rem;
    }
  }
`

export const StyledSupporterCampaignsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
`

export const StyledNonMobileContainer = styled.div``

export const StyledSupporterCampaignsCard = styled.div`
  border: 0.125rem solid ${(p) => p.theme.colors.pitchBlack};
  border-radius: 0.25rem;
  cursor: pointer;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    width: 15rem;
    height: 22.0625rem;

    text-align: center;

    svg {
      width: 2.9375rem;
      height: 3.4375rem;
      color: ${(p) => p.theme.colors.blue};

      margin-top: 5rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    svg {
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    border-radius: 0.5rem;

    width: 25.875rem;
    height: 31.9375rem;

    svg {
      width: 5.1875rem;
      height: 4.75rem;

      margin-top: 8rem;
    }
  }
`
