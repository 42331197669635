import React, { Fragment } from "react"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import {
  StyledPacsQuestionsContainer,
  StyledPacsQuestionsTitle,
  StyledPacsQuestionsContent,
} from "./index.styles"

const PacsQuestions = () => {
  const { locale } = useIntl()

  const pacsRulesLink =
    locale === "en"
      ? `${"/"}${locale}${"/legal/pac-rules/1"}`
      : locale === "it"
      ? `${"/"}${locale}${"/legal/regole-pac/1"}`
      : `${"/"}${locale}${"/legal/reglamento-pac/1"}`

  return (
    <StyledPacsQuestionsContainer>
      <StyledPacsQuestionsTitle>
        <FormattedMessage
          id="pacs::questions::title"
          defaultMessage="Still have questions?"
        />
      </StyledPacsQuestionsTitle>
      <StyledPacsQuestionsContent>
        <FormattedMessage
          id="pacs::questions::content"
          defaultMessage="View now <span>all the stores, offers, and promotions</span> available to you."
          values={{
            span: (...chunks) => (
              <a href="/offers?offer=true&store=true">
                {chunks.map((chunk, i) => (
                  <Fragment key={i}>{chunk}</Fragment>
                ))}
              </a>
            ),
          }}>
          {(...chunks) => (
            <p>
              {chunks.map((chunk, i) => (
                <Fragment key={i}>{chunk}</Fragment>
              ))}
            </p>
          )}
        </FormattedMessage>
      </StyledPacsQuestionsContent>
    </StyledPacsQuestionsContainer>
  )
}

export default PacsQuestions
