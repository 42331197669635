import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import { Form, useFormikContext } from "formik"
import {
  Button,
  Dropdown,
  FieldError,
  PhoneInput,
  TextInput,
} from "@tmu/components/common"
import {
  REPRESENTATIVE_USER_TYPE,
  USED_TECHNOLOGY_OPTIONS,
} from "@tmu/apollo/constants"
import { getFormatPhoneNumber } from "@tmu/utils/formatPhoneNumber"
import theme from "@tmu/global/theme"

import {
  StyledProfileFlagRowContent,
  StyledProfileFlexContainer,
  StyledProfileRowContent,
  StyledProfileSection,
  StyledProfileSectionBoldTitle,
  StyledProfileSectionTitle,
  StyledProfileSpacer,
} from "./index.styles"

const RepresentativeUserProfileForm = ({
  isEdit,
  onCancel,
  onSave,
  isOnline,
}) => {
  const {
    errors,
    initialValues,
    setFieldValue,
    values,
    touched,
    handleChange,
    handleBlur,
  } = useFormikContext()

  return (
    <Form className="representative-user-profile-form">
      <fieldset>
        <StyledProfileRowContent>
          <StyledProfileFlagRowContent></StyledProfileFlagRowContent>
          <StyledProfileSection>
            {isEdit ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "1rem",
                }}>
                <StyledProfileSection>
                  <StyledProfileSectionTitle>
                    <FormattedMessage
                      id="dashboard::profile::name"
                      defaultMessage="Name"
                    />
                  </StyledProfileSectionTitle>
                  <TextInput
                    newDesign
                    data-testid="firstName"
                    id="firstName"
                    name="firstName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.firstName}
                  />
                  <FieldError name="firstName" />
                </StyledProfileSection>
                <StyledProfileSection>
                  <StyledProfileSectionTitle>
                    <FormattedMessage
                      id="dashboard::profile::surName"
                      defaultMessage="Surname"
                    />
                  </StyledProfileSectionTitle>
                  <TextInput
                    newDesign
                    data-testid="lastName"
                    id="lastName"
                    name="lastName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.lastName}
                  />
                  <FieldError name="lastName" />
                </StyledProfileSection>
              </div>
            ) : (
              <StyledProfileSectionBoldTitle>
                <span>{(initialValues?.fullName ?? "") || <>-</>}</span>
              </StyledProfileSectionBoldTitle>
            )}
          </StyledProfileSection>

          {values?.representativeType === REPRESENTATIVE_USER_TYPE.LEGAL && (
            <>
              <StyledProfileFlagRowContent></StyledProfileFlagRowContent>
              <StyledProfileSection>
                <StyledProfileSectionTitle>
                  <FormattedMessage
                    id="dashboard::forms::fiscalCode"
                    defaultMessage="Fiscal code"
                  />
                </StyledProfileSectionTitle>
                <StyledProfileSection>
                  {isEdit ? (
                    <>
                      <TextInput
                        newDesign
                        data-testid="fiscalCode"
                        id="fiscalCode"
                        name="fiscalCode"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values?.fiscalCode}
                      />
                      <FieldError name="fiscalCode" />
                    </>
                  ) : (
                    (initialValues?.fiscalCode ?? "") || <>-</>
                  )}
                </StyledProfileSection>
              </StyledProfileSection>
            </>
          )}

          {values?.representativeType === REPRESENTATIVE_USER_TYPE.LEGAL && (
            <>
              <StyledProfileFlagRowContent></StyledProfileFlagRowContent>
              <StyledProfileSection>
                <StyledProfileSectionTitle>
                  <FormattedMessage
                    id="dashboard::users::modal::role"
                    defaultMessage="Role"
                  />
                </StyledProfileSectionTitle>
                <StyledProfileSection>
                  {isEdit ? (
                    <>
                      <TextInput
                        newDesign
                        data-testid="attributes"
                        id="attributes"
                        name="attributes"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values?.attributes}
                      />
                      <FieldError name="attributes" />
                    </>
                  ) : (
                    (initialValues?.attributes ?? "") || <>-</>
                  )}
                </StyledProfileSection>
              </StyledProfileSection>
            </>
          )}

          <StyledProfileFlagRowContent></StyledProfileFlagRowContent>
          <StyledProfileSection>
            <StyledProfileSectionTitle>
              <FormattedMessage
                id="dashboard::forms::address"
                defaultMessage="Address"
              />
            </StyledProfileSectionTitle>
            <StyledProfileSection>
              {isEdit ? (
                <>
                  <TextInput
                    newDesign
                    data-testid="address"
                    id="address"
                    name="address"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.address}
                  />
                  <FieldError name="address" />
                </>
              ) : (
                (initialValues?.address ?? "") || <>-</>
              )}
            </StyledProfileSection>
          </StyledProfileSection>

          <StyledProfileFlagRowContent></StyledProfileFlagRowContent>
          <StyledProfileSection>
            <StyledProfileSectionTitle>
              <FormattedMessage
                id="dashboard::merchantStore::contactNumber"
                defaultMessage="Contact number"
              />
            </StyledProfileSectionTitle>
            <StyledProfileSection>
              {isEdit ? (
                <PhoneInput
                  name="phoneNumber"
                  isRequired={false}
                  value={values.phoneNumber}
                  errors={errors}
                  touched={touched}
                  onChange={(phone) =>
                    setFieldValue("phoneNumber", phone, true)
                  }
                />
              ) : (
                getFormatPhoneNumber(initialValues?.phoneNumber ?? "") || <>-</>
              )}
            </StyledProfileSection>
          </StyledProfileSection>

          <StyledProfileFlagRowContent></StyledProfileFlagRowContent>
          <StyledProfileSection>
            <StyledProfileSectionTitle>
              <FormattedMessage
                id="dashboard::forms::email"
                defaultMessage="Email"
              />
            </StyledProfileSectionTitle>
            <StyledProfileSection>
              {isEdit ? (
                <>
                  <TextInput
                    newDesign
                    data-testid="email"
                    id="email"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.email}
                  />
                  <FieldError name="email" />
                </>
              ) : (
                (initialValues?.email ?? "") || <>-</>
              )}
            </StyledProfileSection>
          </StyledProfileSection>

          {values?.representativeType === REPRESENTATIVE_USER_TYPE.TECHNICAL &&
          isOnline ? (
            <>
              <StyledProfileFlagRowContent></StyledProfileFlagRowContent>
              <StyledProfileSection>
                <StyledProfileSectionTitle>
                  <FormattedMessage
                    id="dashboard::forms::technologyUsed"
                    defaultMessage="Technology used"
                  />
                </StyledProfileSectionTitle>
                <StyledProfileSection>
                  {isEdit ? (
                    <>
                      <Dropdown
                        newDesign
                        data-testid="attributes"
                        id="attributes"
                        name="attributes"
                        defaultValue={values?.attributes}
                        options={USED_TECHNOLOGY_OPTIONS}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue("attributes", e?.value, true)
                        }}
                      />
                      <FieldError name="attributes" />
                    </>
                  ) : (
                    (initialValues?.attributes ?? "") || <>-</>
                  )}
                </StyledProfileSection>
              </StyledProfileSection>
            </>
          ) : null}
        </StyledProfileRowContent>
      </fieldset>
      {isEdit && (
        <>
          <StyledProfileSpacer bottom={1.5} />
          <StyledProfileRowContent>
            <StyledProfileFlagRowContent></StyledProfileFlagRowContent>
            <StyledProfileFlexContainer>
              <Button
                className="black-button"
                color="transparent"
                label="cancel"
                onClick={onCancel}>
                <FormattedMessage
                  id="dashboard::forms::cancel"
                  defaultMessage="Cancel"
                />
              </Button>
              <Button
                data-testid="btn-save"
                className="blue-button button-margin"
                color={theme.colors.pacificBlue}
                type="button"
                disabled={Object.keys(errors)?.length > 0}
                onClick={onSave}>
                <FormattedMessage
                  id="dashboard::accountForm::save"
                  defaultMessage="Save"
                />
              </Button>
            </StyledProfileFlexContainer>
          </StyledProfileRowContent>
        </>
      )}
    </Form>
  )
}

export default RepresentativeUserProfileForm
