import React from "react"
import HeroComponent from "./HeroComponent"

const DetailedHero = ({
  items,
  page,
  contactRef,
  children,
  handleNavigate,
}) => {
  return (
    <HeroComponent
      contactRef={contactRef}
      items={items}
      page={page}
      children={children}
      handleNavigate={handleNavigate}
    />
  )
}

export default DetailedHero
