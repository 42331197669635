import { useMediaQuery } from "beautiful-react-hooks"
import { isBrowser } from "@tmu/utils/auth"
import theme from "@tmu/global/theme"

const getAllScreenTypes = () => {
  const isTablet =
    isBrowser && useMediaQuery("(min-width:" + theme.breakpoints.tablet + ")")

  const isDesktop =
    isBrowser && useMediaQuery("(min-width:" + theme.breakpoints.desktop + ")")

  const isWide =
    isBrowser && useMediaQuery("(min-width:" + theme.breakpoints.wide + ")")

  return {
    isTablet,
    isDesktop,
    isWide,
  }
}

export { getAllScreenTypes }
