import React from "react"
import { Router } from "@reach/router"
import { injectIntl } from "gatsby-plugin-intl"
import StaticPage from "@tmu/components/StaticPage"

const Policy = () => (
  <Router basepath="/:locale/policy">
    <StaticPage path="/" default slug="privacy" />
    <StaticPage path="/:slug" />
  </Router>
)

export default injectIntl(Policy)
