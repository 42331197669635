import React from "react"
import PropTypes from "prop-types"
import { LazyImage } from "@tmu/components/common"
import {
  StyledMainContainer,
  StyledLeftContainer,
  StyledRightContainer,
  StyledLogoContainer,
} from "./index.styles"

const StoreSupporterBox = ({ storeLogo, partnerLogo, className, ...rest }) => {
  const { width, height } = { ...rest }

  return (
    <StyledMainContainer className={className}>
      <StyledLeftContainer>
        <StyledLogoContainer
          style={{ width: width + "px", height: height + "px" }}>
          <LazyImage
            src={storeLogo}
            altName={`store-image-${storeLogo}`}
            fit="cover"
            width={width}
          />
        </StyledLogoContainer>
      </StyledLeftContainer>
      <StyledRightContainer>
        <StyledLogoContainer
          style={{ width: width + "px", height: height + "px" }}>
          <LazyImage
            src={partnerLogo}
            altName={`partner-image-${partnerLogo}`}
            fit="cover"
            width={width}
          />
        </StyledLogoContainer>
      </StyledRightContainer>
    </StyledMainContainer>
  )
}

StoreSupporterBox.propTypes = {
  storeLogo: PropTypes.string.isRequired,
  partnerLogo: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default StoreSupporterBox
