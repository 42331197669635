import styled from "@emotion/styled"

export const StyledPageContainer = styled.div`
  padding-top: 5rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding-top: 6rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
`
export const StyledContent = styled.div`
  & > div {
    padding-left: 1rem;
    padding-right: 1rem;
    margin: auto;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      max-width: ${(p) => p.theme.breakpoints.tablet};
      padding-left: 1.3rem;
      padding-right: 1.3rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      max-width: ${(p) => p.theme.breakpoints.desktop};
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      max-width: ${(p) => p.theme.breakpoints.wide};
    }
  }
`
export const StyledBackButton = styled.div`
  color: ${(p) => p.theme.colors.pitchBlack};

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding-left: 2rem !important;
    color: ${(p) => p.theme.colors.pacificBlue};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding-left: 6rem !important;
  }
`
