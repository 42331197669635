import React from "react"
import PropTypes from "prop-types"
import { faShoppingCart } from "@fortawesome/pro-regular-svg-icons/faShoppingCart"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby-plugin-intl"

const CartLink = ({ cartItemCount, anonymous }) => {
  if (anonymous && cartItemCount === 0) {
    return null
  }

  return (
    <Link to="/offers/cart" data-testid="cart-badge" aria-label="offers cart">
      <FontAwesomeIcon icon={faShoppingCart} />
      {cartItemCount > 0 && <span className="cart-badge">{cartItemCount}</span>}
    </Link>
  )
}

CartLink.propTypes = {
  cartItemCount: PropTypes.number.isRequired,
  anonymous: PropTypes.bool.isRequired,
}

CartLink.defaultProps = {
  cartItemCount: 0,
  anonymous: true,
}

export default CartLink
