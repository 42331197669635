import React from "react"
import { Router } from "@reach/router"
import { injectIntl } from "gatsby-plugin-intl"
import { CampaignListing } from "@tmu/components/campaigns"

import {
  CampaignDetail,
  CampaignDonationForm,
  CampaignDonationSuccess,
} from "@tmu/components/campaigns"
import { BankTransferDetails } from "../components/common"

const Campaigns = () => (
  <Router basepath="/:locale/campaigns">
    <CampaignListing path="/" />
    <CampaignDetail path="/:slug" />
    <CampaignDonationForm path="/:slug/donate" />
    <CampaignDonationSuccess path="/:slug/donation-success" />
    <BankTransferDetails path="/:slug/bank-transfer-details" />
  </Router>
)

export default injectIntl(Campaigns)
