import styled from "@emotion/styled"

export const StyledMainPartnerLine = styled.div`
  background-color: ${(p) => p.theme.colors.lightGrey};
  height: 0.0625rem;
  width: 110%;
  margin-left: -1rem;
  padding-left: 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    width: 100%;
    margin-left: 0;
    padding-left: 0;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    height: 0.125rem;
  }
`

export const StyledMainPartnerTitle = styled.div`
  font-weight: 700;
  text-align: left;
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.pitchBlack};
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin-top: 2rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1.5rem;
    line-height: 2.125rem;
    margin-top: 2.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 3rem;
    margin-top: 4rem;
    line-height: 4.0856rem;
  }
`

export const StyledSlice = styled.div`
  margin: 0 auto;
  margin-top: 1rem;
  width: ${(p) => (p.sliderLength > 3 ? "100%" : "min-content")};

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    .chevron {
      height: 23.5rem;
    }
    margin-top: 1.5rem;
    width: ${(p) => (p.sliderLength > 4 ? "100%" : "min-content")};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin-top: 2.5rem;
    .chevron {
      height: 33.5rem;
    }
  }
`

export const StyledMainPartnersWrapper = styled.div`
  background-color: ${(p) => p.theme.colors.white};
  margin-left: -1rem;
  padding-left: 1rem;
  width: 110%;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-left: 0;
    width: 100%;
  }
`

export const StyledMainPartner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 6.4375rem !important;
  height: 3.5625rem !important;

  img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    opacity: 0.6;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    width: 10.875rem !important;
    height: 5.9375rem !important;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    width: 14.5rem !important;
    height: 7.9375rem !important;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    width: 26.125rem !important;
    height: 14.3125rem !important;
  }
`
