import { faSearch } from "@fortawesome/pro-light-svg-icons/faSearch"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, TextInput } from "@tmu/components/common"
import { OffersTable } from "@tmu/components/dashboard/dashboardCommon"
import {
  StyledForm,
  StyledPage,
  StyledPageContent,
  StyledPageTitle,
} from "@tmu/global/page-addons/dashboard.styles"
import { getAllScreenTypes } from "@tmu/src/utils/mediaQueries"
import { FormattedMessage, useIntl, navigate } from "gatsby-plugin-intl"
import { useState } from "react"
import { StyledCreate, StyledPageWrapper } from "./index.styles"
import { StyledSearchContainer } from "../../Sales/index.styles"
import theme from "@tmu/src/global/theme"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { useAuth } from "@tmu/hooks"
import { API_PERMISSIONS } from "@tmu/apollo/constants"

const OfferListing = () => {
  const { formatMessage } = useIntl()
  const [offerName, setOfferName] = useState()
  const [searchText, setSearchText] = useState()
  const { isTablet } = getAllScreenTypes()
  const { apiPermissions } = useAuth()
  let searchTimeout = null

  const handleCreateOffer = () => {
    navigate("/dashboard/merchants/stores/offer/")
  }

  const createText = (
    <FormattedMessage
      id="dashboard::services::createOffer"
      defaultMessage="Create an Offer"
    />
  )

  return (
    <StyledPage>
      <StyledPageWrapper>
        <StyledPageTitle>
          <div>
            <FormattedMessage
              id="dashboard::nav::offers"
              defaultMessage="Offers"
              tagName="h1"
            />
          </div>
          {apiPermissions?.includes(API_PERMISSIONS.MERCHANT_CREATE_PRODUCT) &&
            isTablet && (
              <StyledCreate>
                <div>
                  <FontAwesomeIcon icon={faPlus} />
                </div>
                <div onClick={handleCreateOffer}>{createText}</div>
              </StyledCreate>
            )}
        </StyledPageTitle>

        <StyledPageContent data-testid="offers-page-content">
          <StyledSearchContainer>
            <StyledForm>
              <FontAwesomeIcon icon={faSearch} />
              <TextInput
                data-testid="offers-search-box"
                id="offersName"
                name="offersName"
                value={offerName}
                type="text"
                label={formatMessage({
                  id: "dashboard::offerSearch::inputPlaceholder",
                  defaultMessage: "Offer name",
                })}
                className="float-container full-width search-input"
                placeholder=""
                onChange={(event) => {
                  event.preventDefault()
                  event.stopPropagation()
                  setOfferName(event?.target?.value)
                  clearTimeout(searchTimeout)
                  searchTimeout = setTimeout(() => {
                    setSearchText(event?.target?.value)
                  }, 1000)
                }}
              />
            </StyledForm>
          </StyledSearchContainer>
          <br />
        </StyledPageContent>

        <OffersTable searchText={searchText} />

        {apiPermissions?.includes(API_PERMISSIONS.MERCHANT_CREATE_PRODUCT) &&
          !isTablet && (
            <div className="sticky-bottom-bar offer-create-button">
              <Button
                data-testid="btn-create-offer"
                color={theme?.colors?.carrot}
                type="button"
                onClick={handleCreateOffer}>
                {createText}
              </Button>
            </div>
          )}
      </StyledPageWrapper>
    </StyledPage>
  )
}

export default OfferListing
