import styled from "@emotion/styled"
import { css } from "@emotion/react"

export const StyledGlobalPageWrapper = styled.div`
  margin: 4rem auto 0;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    max-width: ${(p) => p.theme.breakpoints.tablet};
    padding: 0 1.3rem 0 1.3rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    max-width: ${(p) => p.theme.breakpoints.desktop};
    padding: 1.5rem 1.9rem 0 1.9rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    max-width: ${(p) => p.theme.breakpoints.wide};
    margin: 5rem auto 0;
    padding: 0 5.9rem 0 5.9rem;
  }
`

export const DetailPageContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 27.75rem 18.75rem;
  grid-template-areas: "main side";
  margin: 2.875rem auto 0.5rem;
  align-items: center;
  button {
    white-space: pre-line;
  }

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    max-width: ${(p) => p.theme.breakpoints.tablet};
    padding: 0 1.3rem 0 1.3rem;
  }

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    grid-template-columns: 38rem 24rem;
    max-width: ${(p) => p.theme.breakpoints.desktop};
    padding: 0 1.9rem 0 1.9rem;
    .caption {
      font-size: 0.75rem;
    }
  }

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    grid-template-columns: 71.5rem 36.4375rem;
    margin: 6rem auto 0.5rem;
    max-width: ${(p) => p.theme.breakpoints.wide};
    padding: 0 5.9rem 0 5.9rem;
    .caption {
      font-size: 1rem;
    }
  }
`
export const MainPart = styled.div`
  grid-area: main;
  width: 100%;
  height: 100%;
  padding-bottom: 2rem;

  .store-supporter-box {
    width: 100%;
    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      height: 15.5625rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      height: 21.375rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      height: 40.5rem;
    }
  }
`

export const SidePart = styled.div`
  grid-area: side;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${(p) => p.theme.colors.whiteSmoke};
  padding: 1.5rem 1.5rem 2rem 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    padding: 2rem 2rem 3rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding: 2rem 2rem 4rem;
  }
`

export const DetailPageMobileContainer = styled.div`
  width: 100%;
  margin: auto;
  align-items: center;
  > div {
    margin: 1rem;
  }
  > div:first-of-type {
    margin: 3rem 0 1.5rem;
  }
  .mobile-tier-description {
    margin: 0 0 1rem;
    font-size: 0.75rem;
  }
  button {
    white-space: pre-line;
  }

  .store-supporter-box {
    width: 100%;
    height: 12.6875rem;
  }
`
export const Spacer = styled.div`
  ${(p) =>
    p?.top &&
    css`
      margin-top: ${p?.top}rem !important;
    `};

  ${(p) =>
    p?.bottom &&
    css`
      margin-bottom: ${p.bottom}rem !important;
    `};
`

export const DetailFooterContainer = styled.div`
  .detail-page-footer {
    margin: 1rem auto 0.5rem;
    padding-left: 4rem;
    padding-right: 0;
    max-width: ${(p) => p.theme.breakpoints.wide};
  }

  .footer-wide {
    margin: auto;
    padding-right: 5rem;
    padding-left: 10rem;
    max-width: ${(p) => p.theme.breakpoints.wide};
  }
  margin: auto;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    max-width: ${(p) => p.theme.breakpoints.tablet};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    max-width: ${(p) => p.theme.breakpoints.desktop};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    max-width: ${(p) => p.theme.breakpoints.wide};
  }
`
