import React from "react"
import PropTypes from "prop-types"
import { TextInput } from "@tmu/components/common"
import { StyledSearchBoxFilter } from "./index.styles"
import { faSearch } from "@fortawesome/pro-solid-svg-icons/faSearch"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useIntl } from "gatsby-plugin-intl"

const SearchBoxFilter = ({ placeHolder, handleSearch }) => {
  const { formatMessage } = useIntl()
  let timeout = null

  return (
    <StyledSearchBoxFilter>
      <TextInput
        newDesign
        data-testid="input-search"
        id="search"
        name="search"
        onChange={(e) => {
          clearTimeout(timeout)

          timeout = setTimeout(() => {
            if (typeof handleSearch === "function") {
              handleSearch(e)
            }
          }, 1000)
        }}
        placeholder={
          placeHolder ||
          formatMessage({
            id: "searchbox::placeholder",
            defaultMessage: "Charity, Campaign Name, Fiscal Code",
          })
        }
      />
      <div className="search-icon">
        <FontAwesomeIcon icon={faSearch} />
      </div>
    </StyledSearchBoxFilter>
  )
}

export default SearchBoxFilter

SearchBoxFilter.propTypes = {
  placeHolder: PropTypes.string,
  handleSearch: PropTypes.func,
}
