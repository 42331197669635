import React, { Fragment } from "react"
import queryString from "query-string"
import { Money, LazyImage, Button, UseBefore } from "@tmu/components/common"
import { FormattedMessage, navigate, Link } from "gatsby-plugin-intl"
import { ORDER_DETAILS } from "@tmu/hooks/useAllOrders"
import { useQuery } from "@apollo/client"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import {
  StyledWrapper,
  StyledSuccessDetailTitle,
  StyledSuccessPageImage,
  StyledSuccessButtons,
  StyledSuccessDonationInfo,
  StyledSuccessOptionName,
  StyledSuccessDonationInfoRegister,
} from "./index.styles"
import { useAuth } from "@tmu/hooks"
import { useIntl } from "gatsby-plugin-intl"

const { CLOUDFLARE_IMAGE_URL } = process.env

const ServicePurchaseSuccess = ({ location }) => {
  const orderData = location?.state?.data?.orderData
  const signUpGift =
    orderData?.createOrder?.order?.onRegisterOrderValues?.signUpGift
  const params = queryString.parse(location.search, { arrayFormat: "comma" })
  const { isAuthenticated } = useAuth()
  const { locale } = useIntl()
  const isDWOR = params.dwor
  if (!params.id) return null
  const { data, loading } = useQuery(ORDER_DETAILS, {
    variables: {
      id: params.id,
    },
  })
  const { isTablet } = getAllScreenTypes()

  const isDonationMade =
    Number(data?.order?.internalOrderValues?.donationAmount) > 0

  const futurePACAmount = Number(
    data?.order?.internalOrderValues?.donationAmount || 0
  ) // * Number(data?.order?.campaign?.pacMultiplier - 1 || 0) //TODO: pacMultiplier should be reconsidered by business

  const offer = data?.order?.cartItems?.edges?.[0]?.node?.offer

  return (
    <StyledWrapper>
      <StyledSuccessDetailTitle data-testid="donation-success-details">
        <FormattedMessage
          id={`campaign::donationSuccess::${
            isDonationMade ? "servicePurchased1" : "servicePurchased2"
          }`}
          defaultMessage={
            isDonationMade
              ? "You have purchased a service!"
              : "Service is purchased!"
          }
        />
      </StyledSuccessDetailTitle>

      {isDonationMade ? (
        <StyledSuccessDetailTitle data-testid="donation-success-details">
          <FormattedMessage
            id="campaign::donationSuccess::processedText1"
            defaultMessage="You have donated <pacs>{amount}</pacs> to <partner>{partnerName}</partner>"
            values={{
              pacs: (...chunks) => (
                <span key="processedText">
                  {chunks.map((chunk, i) => (
                    <Fragment key={i}>{chunk}</Fragment>
                  ))}
                </span>
              ),
              partner: (...chunks) => (
                <span key="partner">
                  {chunks.map((chunk, i) => (
                    <Fragment key={i}>{chunk}</Fragment>
                  ))}
                </span>
              ),
              partnerName: data?.order?.campaign?.name,
              amount: (
                <Money
                  value={Number(
                    data?.order?.internalOrderValues?.donationAmount
                  )}
                />
              ),
            }}
          />
        </StyledSuccessDetailTitle>
      ) : null}

      <StyledSuccessPageImage>
        <LazyImage
          altName="donation-success"
          src={
            CLOUDFLARE_IMAGE_URL + "/static/assets/images/anim_order_slow.gif"
          }
          width="400"
          fit="cover"
        />

        <p className="caption">
          <FormattedMessage
            id="campaign::donationSuccess::purchased"
            defaultMessage="You purchased"
          />
        </p>
      </StyledSuccessPageImage>

      <StyledSuccessOptionName>
        {data?.order?.store?.name}
      </StyledSuccessOptionName>

      <StyledSuccessDonationInfo>
        {data?.order?.cartItems?.edges?.[0].node.productName}
      </StyledSuccessDonationInfo>
      <UseBefore isVoucher={offer?.isVoucher} endDate={offer?.endDate} />
      {isDonationMade ? (
        <FormattedMessage
          id="campaign::donationSuccess::email2"
          defaultMessage="We have sent a confirmation letter of your donation and purchase to <strong>{email}</strong>."
          values={{
            email: isAuthenticated
              ? data?.order?.user?.email
              : data?.order?.intendedUser?.email,
            strong: (...chunks) => (
              <strong key="emailStrong" className="emailStrong">
                {chunks}
              </strong>
            ),
          }}
          tagName="p"
        />
      ) : (
        <FormattedMessage
          id="campaign::donationSuccess::email1"
          defaultMessage="We have sent a confirmation letter of your purchase to <strong>{email}</strong>."
          values={{
            email: data?.order?.user?.email,
            strong: (...chunks) => (
              <strong key="emailStrong" className="emailStrong">
                {chunks}
              </strong>
            ),
          }}
          tagName="p"
        />
      )}

      {isDonationMade && !isDWOR && !isAuthenticated && (
        <StyledSuccessDonationInfoRegister>
          <FormattedMessage
            id="campaign::donationSuccess::register"
            defaultMessage="<span>Register</span> to receive PACs for your donations."
            values={{
              span: (...chunks) => (
                <Link
                  to={`/signup?next=${
                    window?.location.pathname.replace(/\/(en|it|es)/g, "") +
                    window?.location.search
                  }`}
                  aria-label="pacs">
                  {chunks.map((chunk, i) => (
                    <Fragment key={i}>{chunk}</Fragment>
                  ))}
                </Link>
              ),
            }}
          />{" "}
          <span>
            <Link to={`/pacs`} aria-label="pacs" className="pacs-link">
              <FormattedMessage
                id="footer::pacs"
                defaultMessage="What are PACs"
              />
            </Link>
          </span>
        </StyledSuccessDonationInfoRegister>
      )}
      {isAuthenticated && isDWOR && futurePACAmount === 0 && (
        <StyledSuccessDonationInfoRegister>
          <FormattedMessage
            id="service::purchaseSuccess::receivedPac"
            defaultMessage="You have received <earned>{earnedPACs}</earned> PACs for your <wallet>PAC Wallet</wallet> as a bonus for registration!"
            values={{
              wallet: (...chunks) => (
                <Link
                  to="/dashboard/donors/wallet"
                  key="usePacWallet"
                  aria-label="check pac wallet">
                  {chunks.map((chunk, i) => (
                    <Fragment key={i}>{chunk}</Fragment>
                  ))}
                </Link>
              ),
              earnedPACs: <Money value={signUpGift || 10} currency={false} />,
              earned: (...chunks) => (
                <span key="earned">
                  {chunks.map((chunk, i) => (
                    <Fragment key={i}>
                      <img
                        className="pacs-image"
                        src={
                          CLOUDFLARE_IMAGE_URL + "/static/assets/images/pac.svg"
                        }
                        alt={`pacs`}
                        width={16}
                        height={16}
                      />
                      {chunk}
                    </Fragment>
                  ))}
                </span>
              ),
            }}
            tagName="p"
          />
        </StyledSuccessDonationInfoRegister>
      )}

      {futurePACAmount > 0 && (
        <StyledSuccessDonationInfo>
          {
            isAuthenticated ? (
              <p>
                <FormattedMessage
                  id="campaign::donationSuccess::receivedPac"
                  defaultMessage="You have received <earned>{earnedPACs}</earned> PACs for your <wallet>PAC Wallet</wallet> for this donation"
                  values={{
                    wallet: (...chunks) => (
                      <Link
                        to="/dashboard/donors/wallet"
                        key="usePacWallet"
                        aria-label="check pac wallet">
                        {chunks.map((chunk, i) => (
                          <Fragment key={i}>{chunk}</Fragment>
                        ))}
                      </Link>
                    ),
                    earnedPACs: (
                      <Money value={futurePACAmount} currency={false} />
                    ),
                    earned: (...chunks) => (
                      <span key="earned">
                        {chunks.map((chunk, i) => (
                          <Fragment key={i}>
                            <img
                              className="pacs-pacs-image"
                              src={
                                CLOUDFLARE_IMAGE_URL +
                                "/static/assets/images/pac.svg"
                              }
                              alt={`pacs`}
                              width={16}
                              height={16}
                            />
                            {chunk}
                          </Fragment>
                        ))}
                      </span>
                    ),
                  }}
                />{" "}
                {isDWOR ? (
                  <FormattedMessage
                    id="campaign::donationSuccess::earnedRegistrationBonus"
                    defaultMessage="and <earned>{earnedPACs}</earned> PACs as a bonus for registration!"
                    values={{
                      earnedPACs: (
                        <Money
                          value={Number(signUpGift || 10)}
                          currency={false}
                        />
                      ),
                      earned: (...chunks) => (
                        <span key="earned">
                          {chunks.map((chunk, i) => (
                            <Fragment key={i}>
                              <img
                                className="pacs-pacs-image"
                                src={
                                  CLOUDFLARE_IMAGE_URL +
                                  "/static/assets/images/pac.svg"
                                }
                                alt={`pacs`}
                                width={16}
                                height={16}
                              />
                              {chunk}
                            </Fragment>
                          ))}
                        </span>
                      ),
                    }}
                  />
                ) : null}
              </p>
            ) : null
            //  (
            //   <FormattedMessage
            //     id="campaign::purchaseSuccess::registerToReceiveText"
            //     defaultMessage="<blueLink>Register</blueLink> to receive PACs for your purchases. <carrotLink>What are PACs</carrotLink>"
            //     values={{
            //       blueLink: (...chunks) => (
            //         <Link
            //           className="inline blue"
            //           to="/signup"
            //           key="signup"
            //           aria-label="purchase-signup">
            //           {chunks.map((chunk, i) => (
            //             <Fragment key={i}>{chunk}</Fragment>
            //           ))}
            //         </Link>
            //       ),
            //       carrotLink: (...chunks) => (
            //         <Link
            //           className="inline carrot"
            //           to="/pacs"
            //           key="pacs"
            //           aria-label="purchase-pacs">
            //           {chunks.map((chunk, i) => (
            //             <Fragment key={i}>{chunk}</Fragment>
            //           ))}
            //         </Link>
            //       ),
            //     }}
            //     tagName="p"
            //   />
            // )
          }
        </StyledSuccessDonationInfo>
      )}

      <div className={!isTablet ? "sticky-bottom-bar" : ""}>
        <StyledSuccessButtons>
          <Button
            label="EXPLORE STORES"
            variant="outlined"
            color="transparent"
            onClick={() => {
              navigate("/offers?store=true")
            }}>
            <FormattedMessage
              id="campaign::donationSuccess::exploreStores"
              defaultMessage="EXPLORE STORES"
            />
          </Button>
          <Button
            label="EXPLORE CHARITIES"
            variant="outlined"
            color="blue"
            onClick={() => {
              navigate("/campaigns?charity=true")
            }}>
            <FormattedMessage
              id="campaign::donationSuccess::exploreCharities"
              defaultMessage="EXPLORE CHARITIES"
            />
          </Button>
        </StyledSuccessButtons>
      </div>
    </StyledWrapper>
  )
}

export default ServicePurchaseSuccess
