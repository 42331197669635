import styled from "@emotion/styled"
import { css } from "@emotion/react"

export const StyledDropdownWrapper = styled.div`
  position: relative;
  ${(p) =>
    p.isFormField &&
    css`
      .react-select__control {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        height: 3rem;
        @media screen and (min-width: ${p.theme.breakpoints.wide}) {
          height: 3.375rem;
        }
      }
    `}
`
