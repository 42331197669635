import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import { useFormikContext } from "formik"
import { useQuery } from "@apollo/client"
import { FieldError, Spinner } from "@tmu/components/common"
import CauseTypeCard from "./CauseTypeCard"
import { getValueForLocale } from "@tmu/utils/string"
import { CARDS_PER_PAGE } from "@tmu/apollo/constants"
import { ALL_SUPPORTER_CAMPAIGN_CAUSES_LISTING_QUERY } from "@tmu/apollo/dashboard/queries/campaign"
import { StyledMainContainer, StyledCampaignChooseCauses } from "./index.styles"

const CampaignChooseCause = ({ isMerchantOffline }) => {
  const { locale, defaultLocale } = useIntl()
  const { values, setFieldValue, touched, setTouched, errors } =
    useFormikContext()

  const { loading, data } = useQuery(
    ALL_SUPPORTER_CAMPAIGN_CAUSES_LISTING_QUERY,
    {
      variables: {
        first: CARDS_PER_PAGE,
        orderBy: "-order",
      },
    }
  )

  const handleCardClick = (id) => () => {
    if (id === values?.supporterCause) {
      setFieldValue("supporterCause", "", true)
      setTouched({ ...touched, supporterCause: true }, true)
    } else {
      setTouched({ ...touched, supporterCause: false }, true)
      setFieldValue("supporterCause", id, true)
    }
  }

  const getCauseCards = () => {
    const causeList = isMerchantOffline
      ? data?.allSupporterCampaignCauses?.edges
      : data?.allSupporterCampaignCauses?.edges.filter(
          (item) => item?.node?.id !== process.env.CORPORATE_CAUSE_ID
        )
    return causeList?.map(({ node: cause }) => {
      return (
        <CauseTypeCard
          id="supporterCause"
          key={cause?.id}
          name="supporterCause"
          className={`campaign-choose-cause-card ${
            cause?.id === values?.supporterCause
              ? "selected"
              : touched?.supporterCause && errors?.supporterCause
              ? "error"
              : ""
          }`}
          value={values?.supporterCause ?? ""}
          icon={cause?.icon}
          type={getValueForLocale(cause, "name", locale, defaultLocale)}
          onClick={handleCardClick(cause?.id)}
        />
      )
    })
  }
  return (
    <StyledMainContainer className="campaign-choose-causes">
      {loading ? (
        <Spinner condensed />
      ) : (
        <StyledCampaignChooseCauses>
          {getCauseCards()}
        </StyledCampaignChooseCauses>
      )}
      <FieldError name="supporterCause" />
    </StyledMainContainer>
  )
}

export default CampaignChooseCause
