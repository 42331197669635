import styled from "@emotion/styled"

export const StyledOfferStats = styled.div`
  display: flex;
  align-items: flex-end;
`

export const StyledPriceContent = styled.div`
  flex-grow: 1;
`

export const StyledPrice = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  div {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.pitchBlack};
  }
  span:first-child {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.pitchBlack};
    white-space: nowrap;
    font-size: 1.5rem;
    line-height: 2.125rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      font-size: 2rem;
      line-height: 2.625rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 3rem;
      line-height: 4.0625rem;
    }
  }
`

export const StyledMaxPacDiscountContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const StyledMaxPacDiscount = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  div {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  }
  span {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.carrot};
    font-size: 1.5rem;
    line-height: 2.125rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      font-size: 2rem;
      line-height: 2.625rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 3rem;
      line-height: 4.0625rem;
    }
  }
`
