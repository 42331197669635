import { useLazyQuery, useMutation } from "@apollo/client"
import { useApolloApiClients } from "@tmu/apollo/client"
import { MAX_PER_PAGE, PER_PAGE } from "@tmu/apollo/constants"
import { useIntl } from "gatsby-plugin-intl"

import {
  CREATE_MERCHANT_OFFER_MUTATION,
  DELETE_MERCHANT_OFFER_MUTATION,
  OFFER_SUBMIT_FOR_REVIEW_MUTATION,
  UPDATE_MERCHANT_OFFER_MUTATION,
  UPDATE_PARTIAL_MERCHANT_OFFER_MUTATION,
} from "@tmu/apollo/dashboard/mutations/merchant"
import {
  DASHBOARD_MERCHANT_OFFER_DETAIL_QUERY,
  MERCHANT_DETAIL_QUERY,
  MERCHANT_OFFER_LISTING_QUERY_BY_MERCHANT,
  MERCHANT_OFFER_LISTING_QUERY_WITH_LOCALE,
} from "@tmu/apollo/storefront/queries/merchant"

const useOffers = (options) => {
  const { storefrontClient, merchantClient } = useApolloApiClients()
  const { locale } = useIntl()

  const defaultVariables = {
    first: MAX_PER_PAGE,
    isPublic: true,
  }

  const [deleteOffer] = useMutation(DELETE_MERCHANT_OFFER_MUTATION, {
    client: merchantClient,
  })

  const [partialUpdateOffer] = useMutation(
    UPDATE_PARTIAL_MERCHANT_OFFER_MUTATION,
    {
      client: merchantClient,
    }
  )

  const [callOffers, { loading, data, refetch, called, fetchMore }] =
    useLazyQuery(MERCHANT_OFFER_LISTING_QUERY_WITH_LOCALE(locale), {
      client: storefrontClient,
      ...options,
      variables: defaultVariables,
    })

  const [callMerchantOffers, merchantData] = useLazyQuery(
    MERCHANT_OFFER_LISTING_QUERY_BY_MERCHANT,
    {
      client: merchantClient,
      ...options,
      variables: {
        ...defaultVariables,
        isPublic: false,
      },
    }
  )
  const [getOfferDetail, { loading: offerDetailLoading, data: offerDetail }] =
    useLazyQuery(DASHBOARD_MERCHANT_OFFER_DETAIL_QUERY, {
      client: merchantClient,
      ...options,
    })

  const [
    getMerchantDetail,
    { loading: userOfferDetailLoading, data: userOfferDetail },
  ] = useLazyQuery(MERCHANT_DETAIL_QUERY({ locale }), {
    client: storefrontClient,
    ...options,
  })

  const handleLoadMore = (vars) => {
    return fetchMore({
      variables: { ...vars, after: data?.allOffers?.pageInfo?.endCursor },
      updateQuery: (prev, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult?.allOffers?.edges
        const pageInfo = fetchMoreResult?.allOffers?.pageInfo
        const prevOffers = prev?.allOffers
        const prevEdges = prevOffers?.edges ?? []

        const newData = newEdges.length
          ? {
              // Put the new allOffers at the end of the list and update `pageInfo`
              // so we have the new `endCursor` and `hasNextPage` values
              allOffers: {
                __typename: prevOffers?.__typename,
                maxPrice: prevOffers?.maxPrice,
                minPrice: prevOffers?.minPrice,
                pageCount: prevOffers?.pageCount,
                totalCount: prevOffers?.totalCount,
                edges: [...prevEdges, ...newEdges],
                pageInfo,
              },
            }
          : prev

        return newData
      },
    })
  }

  const handleLoadMoreByMerhant = (vars) => {
    return merchantData?.fetchMore({
      variables: {
        ...vars,
        after: merchantData?.data?.allOffers?.pageInfo?.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult?.allOffers?.edges
        const pageInfo = fetchMoreResult?.allOffers?.pageInfo
        const prevOffers = prev?.allOffers
        const prevEdges = prevOffers?.edges ?? []

        const newData = newEdges.length
          ? {
              // Put the new allOffers at the end of the list and update `pageInfo`
              // so we have the new `endCursor` and `hasNextPage` values
              allOffers: {
                __typename: prevOffers?.__typename,
                maxPrice: prevOffers?.maxPrice,
                minPrice: prevOffers?.minPrice,
                pageCount: prevOffers?.pageCount,
                totalCount: prevOffers?.totalCount,
                edges: [...prevEdges, ...newEdges],
                pageInfo,
              },
            }
          : prev

        return newData
      },
    })
  }

  const [updateOffer] = useMutation(UPDATE_MERCHANT_OFFER_MUTATION, {
    client: merchantClient,
    refetchQueries: [
      {
        query: MERCHANT_OFFER_LISTING_QUERY_BY_MERCHANT,
        variables: {
          first: PER_PAGE,
        },
        fetchPolicy: "network-only",
      },
    ],
  })

  const [createOffer] = useMutation(CREATE_MERCHANT_OFFER_MUTATION, {
    client: merchantClient,
    refetchQueries: [
      {
        query: MERCHANT_OFFER_LISTING_QUERY_BY_MERCHANT,
        variables: {
          first: PER_PAGE,
        },
        fetchPolicy: "network-only",
      },
    ],
  })

  const [offerSubmitForReview] = useMutation(OFFER_SUBMIT_FOR_REVIEW_MUTATION, {
    client: merchantClient,
    refetchQueries: [
      {
        query: MERCHANT_OFFER_LISTING_QUERY_BY_MERCHANT,
        variables: {
          first: PER_PAGE,
        },
        fetchPolicy: "network-only",
      },
    ],
  })

  return {
    offerSubmitForReview,
    updateOffer,
    createOffer,
    callOffers,
    data: data?.allOffers,
    handleLoadMore,
    pageInfo: data?.allOffers?.pageInfo,
    refetch,
    loading,
    called,
    fetchMore,
    deleteOffer,
    getOfferDetail,
    offerDetail,
    offerDetailLoading,
    callMerchantOffers,
    getMerchantDetail,
    partialUpdateOffer,
    merchantService: {
      ...merchantData,
      pageInfo: merchantData?.data?.allOffers?.pageInfo,
      data: merchantData?.data?.allOffers,
      handleLoadMoreByMerhant,
    },
  }
}

export default useOffers
