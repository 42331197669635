import React, { useEffect, useState } from "react"
import queryString from "query-string"
import theme from "@tmu/global/theme"
import { useIntl, FormattedMessage, navigate } from "gatsby-plugin-intl"
import { CustomModal } from "@tmu/components/common"
import {
  StyledBottom,
  StyledTopicContainer,
  StyledContinueButton,
  StyledDescription,
  StyledPage,
  StyledCategoryContainer,
  StyledSkipButton,
  StyledTitle,
  StyledWelcomePageContainer,
  StyledWelcomeLeft,
  StyledWelcomeRight,
  StyledWelcomeLeftContent,
  StyledLogo,
  StyledWelcomeRightContent,
} from "./index.styles"
import TopicItem from "./TopicItem"
import {
  useAllCampaignCategories,
  useAllOfferCategories,
  useUserPreferences,
} from "@tmu/hooks"
import CategoryList from "@tmu/components/common/ListingFilters/CategoryList"
import { NavbarLogo } from "@tmu/components/common/Header/Nav"
import { Spacer } from "@tmu/global/page-addons/detail-page.styles"
import { getValueForLocale } from "@tmu/utils/string"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { useAuth } from "@tmu/hooks"

const CategorySelect = ({}) => {
  const [modalStatus, setModalStatus] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)
  const { mainCategories } = useAllCampaignCategories()
  const [selectedCategories, setSelectedCategories] = useState([])
  const [selectedTopics, setSelectedTopics] = useState([])
  const { allOfferCategories } = useAllOfferCategories()
  const { savePreferences } = useUserPreferences()
  const { callUserProfile } = useAuth()
  const { locale, defaultLocale, formatMessage } = useIntl()
  const { isTablet, isWide } = getAllScreenTypes()

  const imageWidth = isWide ? 266 : isTablet ? 140 : 103
  const imageHeight = isWide ? 138 : isTablet ? 82 : 66

  const logoWidth = isWide ? 300 : 150
  const logoHeight = isWide ? 60 : 30

  const descriptionTexts = [
    formatMessage({
      id: `categorySelect::modal::description1`,
      defaultMessage: "What topic among these are you most passionate about?",
    }),
    formatMessage({
      id: `categorySelect::modal::description3`,
      defaultMessage: "What kind of offer you most interested in?",
    }),
  ]

  const descriptionText = (
    <StyledDescription>
      <p>{descriptionTexts[currentStep]}</p>
      <p>
        {formatMessage({
          id: `categorySelect::modal::description2`,
          defaultMessage: "Choose up to 3 and click continue.",
        })}
      </p>
    </StyledDescription>
  )

  const descriptionTextTablet = (
    <StyledDescription>
      {formatMessage({
        id: `categorySelect::modal::descriptionTablet1`,
        defaultMessage:
          "Let us know about your preferencies, so your recommendations will be more efficient.",
      })}
    </StyledDescription>
  )

  const headerText = (
    <StyledTitle>
      {formatMessage({
        id: "categorySelect::modal::header",
        defaultMessage: "Before we start",
      })}
    </StyledTitle>
  )
  const categoryList = mainCategories?.map((item, index) => {
    return {
      key: index,
      id: item?.id,
      label: getValueForLocale(item, "name", locale, defaultLocale),
      imageUrl: item?.image,
      selected: false,
    }
  })

  const selectCategory = (id, slug) => {
    if (selectedCategories.indexOf(id) === -1) {
      if (selectedCategories.length > 2) {
        return
      }
      setSelectedCategories([...selectedCategories, id])
    } else {
      const temp = selectedCategories.filter((item) => item !== id)
      setSelectedCategories(temp)
    }
  }

  const selectTopic = (id) => {
    if (selectedTopics.indexOf(id) === -1) {
      if (selectedTopics.length > 2) {
        return
      }
      setSelectedTopics([...selectedTopics, id])
    } else {
      const temp = selectedTopics.filter((item) => item !== id)
      setSelectedTopics(temp)
    }
  }

  const topicContent = (
    <StyledTopicContainer>
      {categoryList?.map((item, index) => {
        return (
          <div>
            <TopicItem
              id={item?.id}
              key={index}
              imageUrl={item?.imageUrl}
              label={item?.label}
              imageWidth={imageWidth}
              imageHeight={imageHeight}
              onClick={() => selectTopic(item?.id)}
              selected={selectedTopics?.indexOf(item?.id) !== -1}
            />
          </div>
        )
      })}
    </StyledTopicContainer>
  )
  const categoryContent = (
    <StyledCategoryContainer>
      <CategoryList
        categories={allOfferCategories}
        selected={selectedCategories}
        onSelect={selectCategory}
        hideShowAll={true}
      />
    </StyledCategoryContainer>
  )

  const steps = [topicContent, categoryContent]
  const itemsToCheckDisabled = [selectedTopics, selectedCategories]
  const continueButtonColors = [theme.colors.pacificBlue, theme.colors.carrot]

  const onContinueClick = () => {
    if (currentStep === 1) {
      savePreferences({
        variables: {
          input: {
            campaignCategories: selectedTopics,
            productCategories: selectedCategories,
          },
        },
      }).then((result) => {
        callUserProfile()
        const params = queryString.parse(window?.location.search, {
          arrayFormat: "comma",
        })
        const next = params?.next?.replace(/\/(en|it|es)/g, "") ?? "/"
        const nextUrl = queryString.stringifyUrl({
          url: next,
          query: params,
        })
        navigate(nextUrl || "/user-space")
      })
      return
    }
    setCurrentStep(currentStep + 1)
  }

  const skipButton = (
    <StyledSkipButton data-testid="btn-skip" onClick={onContinueClick}>
      <FormattedMessage id="initialSetup::skip" defaultMessage="Skip" />
    </StyledSkipButton>
  )

  const continueButton = (
    <StyledContinueButton
      style={{
        backgroundColor:
          itemsToCheckDisabled[currentStep]?.length === 0
            ? theme.colors.gray
            : continueButtonColors[currentStep],
      }}
      disabled={itemsToCheckDisabled[currentStep]?.length === 0}
      color={continueButtonColors[currentStep]}
      data-testid="btn-continue"
      onClick={onContinueClick}>
      <FormattedMessage id="initialSetup::continue" defaultMessage="Continue" />
    </StyledContinueButton>
  )

  const content = (
    <StyledPage>
      {steps[currentStep]}
      <StyledBottom>
        {skipButton}
        {continueButton}
      </StyledBottom>
    </StyledPage>
  )

  const style = { padding: "0 1rem 1rem" }

  const closeModal = () => {
    setModalStatus(false)
  }

  const openModal = () => {
    setModalStatus(true)
  }

  useEffect(() => {
    openModal()
  }, [])

  const fullContent = (
    <StyledWelcomePageContainer>
      <StyledWelcomeLeft className={"step-" + currentStep}>
        <StyledWelcomeLeftContent>
          <StyledLogo>
            <NavbarLogo
              flat={true}
              color="white"
              width={logoWidth}
              height={logoHeight}
            />
          </StyledLogo>
          <Spacer top={3.875} />
          <div>{headerText}</div>
          <div>{descriptionTextTablet}</div>
        </StyledWelcomeLeftContent>
      </StyledWelcomeLeft>
      <StyledWelcomeRight>
        <StyledWelcomeRightContent>
          <div className="skip-button">{skipButton}</div>
          <Spacer top={4.125} />
          <div>{descriptionText}</div>
          <Spacer top={2} />
          <div>{steps[currentStep]}</div>
          <Spacer top={2.375} />
          <div className="continue-button">{continueButton}</div>
        </StyledWelcomeRightContent>
      </StyledWelcomeRight>
    </StyledWelcomePageContainer>
  )

  return (
    <>
      {!isTablet && (
        <CustomModal
          isModalOpen={modalStatus}
          style={style}
          header={headerText}
          text={descriptionText}
          children={content}
          isCloseIcon={false}
          cancelButtonAction={closeModal}
          isMobile={true}
          isFull={true}
        />
      )}

      {isTablet && (
        <CustomModal
          style={{ padding: 0 }}
          isModalOpen={modalStatus}
          children={fullContent}
          isFull={true}
          isWithoutHeader={true}
        />
      )}
    </>
  )
}

export default CategorySelect
