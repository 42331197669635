import { CharitySelector } from "@tmu/components/common"
import { useFormikContext } from "formik"
import queryString from "query-string"
import { isBrowser } from "@tmu/utils/auth"

export const CharitySelectorModal = ({
  charitySelectorModalStatus,
  params,
  setSelectedCharity,
  setSelectedCampaign,
  setCharitySelectorModalStatus,
  scrollTopAfterClose = false,
}) => {
  const { setFieldValue } = useFormikContext()

  const changeUrlState = (url) => {
    window?.history?.pushState({}, null, url)
  }

  const navigateWithParams = (newParams) => {
    const updatedParams = queryString.stringify(
      { ...newParams },
      { arrayFormat: "comma" }
    )
    changeUrlState("?" + updatedParams)
  }

  return (
    <CharitySelector
      isOpen={charitySelectorModalStatus}
      type={params?.type}
      scrollTopAfterClose={scrollTopAfterClose}
      onSelect={(charity) => {
        const isCampaign = !charity?.isDefaultCampaign

        if (isCampaign) {
          setSelectedCampaign(charity)
          setFieldValue("supportedCampaign", charity?.id, true)
        } else {
          setSelectedCampaign(null)
          setFieldValue("supportedCampaign", null, true)
        }

        setSelectedCharity(charity?.partner)

        setFieldValue("partner", charity?.partner?.id, true)

        if (isBrowser) {
          window.scrollTo(0, 0)
        }

        navigateWithParams({
          ...params,
          charity: charity?.slug,
          isCampaign: isCampaign,
        })
      }}
      onClose={() => {
        setCharitySelectorModalStatus(false)
      }}
    />
  )
}
