import styled from "@emotion/styled"
import { css } from "@emotion/react"

export const StyledDropzoneContent = styled.div`
  border: 0.125rem dashed ${(p) => p.theme.colors.blue};
  padding: 1rem;
  text-align: center;
  border-radius: 0.3125rem;
  display: flex;
  align-items: center;
  justify-content: start;
  .open-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.125rem solid ${(p) => p.theme.colors.tabFieldsColor};
    /* Dimensions should stay in pixels */
    height: 118px;
    width: 196px;
    border-radius: 0.3125rem;
    align-self: flex-start;
    cursor: pointer;
    > svg {
      path {
        fill: ${(p) => p.theme.colors.tabFieldsColor};
      }
    }
  }
  ${(p) =>
    p.active &&
    css`
      border: 0.125rem dashed ${p.theme.colors.blue};
    `}

  p {
    padding-left: 1rem;
    padding-top: 1rem;
  }
`

export const StyledImagesContainerList = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`

export const StyledImageThumb = styled.div`
  position: relative;

  img {
    overflow: hidden;
    object-fit: contain;
    border-radius: 0.3125rem;
    background-color: ${(p) => p.theme.colors.gray};
  }

  .spinner {
    position: relative;
  }
`

export const StyledImageControls = styled.div`
  display: flex;
  gap: 1.5rem;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  svg {
    font-size: 1.15rem !important;
    height: 1.15rem !important;
    color: ${(p) => p.theme.colors.softGreen} !important;
  }

  button {
    padding: 0 !important;
  }
`

export const StyledSpinner = styled.div`
  .spinner-condensed-container {
    width: 1.5625rem !important;
    height: 1.5625rem !important;

    .spinner-condensed {
      transform: translateZ(0) scale(0.25) !important;
    }
  }
`
