import React, { memo } from "react"
import { StyledButton, StyledLinkButton } from "./index.styles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons/faChevronRight"

const Button = ({ children, text, color, label, variant, size, ...rest }) => {
  return variant === "link" ? (
    <StyledLinkButton
      buttonColor={color}
      variant={variant}
      size={size}
      aria-label={label}
      {...rest}>
      <p className="link-button-text">
        <span>{text}</span>
        <FontAwesomeIcon icon={faChevronRight} />
      </p>
    </StyledLinkButton>
  ) : (
    <StyledButton
      buttonColor={color}
      variant={variant}
      size={size}
      aria-label={label}
      {...rest}>
      {children}
    </StyledButton>
  )
}

Button.defaultProps = {
  color: "black",
  align: "center",
  size: "medium",
  type: "button",
  rounded: false,
  children: null,
}

export default memo(Button)
