import React from "react"
import { FormattedMessage, navigate } from "gatsby-plugin-intl"
import {
  StyledMainContainer,
  StyledLeftContainer,
  StyledLeftTitle,
  StyledLeftContent,
  StyledLeftButton,
  StyledRightContainer,
  StyledShadow,
} from "./index.styles"

const { CLOUDFLARE_IMAGE_URL } = process.env

const FundraiseJoinAsCorporate = () => {
  const handleNavigate = () => {
    navigate(`/join/corporate`)
  }

  return (
    <StyledMainContainer>
      <StyledLeftContainer>
        <StyledLeftTitle>
          <FormattedMessage
            id="fundraise::joinCorporate::title"
            defaultMessage="Are you a corporate?"
          />
        </StyledLeftTitle>
        <StyledLeftContent>
          <FormattedMessage
            id="fundraise::joinCorporate::content"
            defaultMessage="Join to increase sales and support charities, local or all over the world"
          />
        </StyledLeftContent>

        <StyledLeftButton onClick={handleNavigate}>
          <FormattedMessage
            id="fundraise::joinCorporate::button"
            defaultMessage="Join as a Corporate"
          />
        </StyledLeftButton>
      </StyledLeftContainer>

      <StyledRightContainer>
        <StyledShadow />
        <img
          altName="join as corporate"
          src={
            CLOUDFLARE_IMAGE_URL +
            "/static/assets/images/corporate/party_work.png"
          }
        />
      </StyledRightContainer>
    </StyledMainContainer>
  )
}

export default FundraiseJoinAsCorporate
