import React, { useReducer } from "react"
import PropTypes from "prop-types"
import { StyledNotificationTable } from "./index.styles"
import NotificationTableHeader from "./NotificationTableHeader"
import NotificationTableRow from "./NotificationTableRow"

function selectedItemsReducer(state, [type, payload]) {
  switch (type) {
    case "toggleItem":
      return state.indexOf(payload) === -1
        ? [...state, payload]
        : state.filter((id) => id !== payload)
    case "toggleAll":
      return state.length === payload.length
        ? []
        : payload.map((item) => item.notificationID)
    default:
      return state
  }
}

const NotificationTable = ({ items }) => {
  const [state, dispatch] = useReducer(selectedItemsReducer, [])
  const isAllSelected = state.length === items.length

  const handleToggleItem = (id) => {
    dispatch(["toggleItem", id])
  }
  const handleToggleAll = () => {
    dispatch(["toggleAll", items])
  }

  return (
    <>
      <NotificationTableHeader
        isAllSelected={isAllSelected}
        onToggleAll={handleToggleAll}
      />

      <StyledNotificationTable
        height={200}
        itemCount={items.length}
        itemSize={50}
        width="100%"
        itemData={items}>
        {({ index, style, data }) => {
          const item = data[index]
          return (
            <NotificationTableRow
              key={item.notificationID}
              {...item}
              index={index}
              style={style}
              isSelected={state.indexOf(item.notificationID) > -1}
              onToggleSelected={handleToggleItem}
            />
          )
        }}
      </StyledNotificationTable>
    </>
  )
}

NotificationTable.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      notificationID: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      details: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default NotificationTable
