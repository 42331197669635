export const partnerParts = `
  fragment partnerParts on PartnerNode {
    id
    name
    logo
    displayName
    description
    shortDescription
    whySupport
    descriptionEn
    shortDescriptionEn
    whySupportEn
    descriptionIt
    shortDescriptionIt
    whySupportIt
    descriptionEs
    shortDescriptionEs
    whySupportEs
    status
    addressLine1
    country
    city
    postCode
    email
    communicationLanguage
    paymentDestinations {
      accountName
      bankName
      iban
      country
      sortCode
      accountNumber
      bic
    }
  }
`
export const partnerUserParts = `
  fragment partnerUserParts on PartnerUserNode {
    id
    isActive
    created
    modified
    email
    fullName
    partner {
      id
      name
    }
    apiRole {
      id
      name
      codename
      accessType
    }
    user {
      id
      email
      displayName
      fullName
    }
  }
`
