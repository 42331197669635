import styled from "@emotion/styled"

const gradientMobileValue = 43
const gradientTabletValue = 63
const gradientWideValue = 73

export const StyledBrandsWrapper = styled.div`
  padding: 0 1rem;
  position: relative;

  .hero-content {
    margin: auto;
    padding: 7.5rem 0 0;
  }

  h1 {
    text-align: center;
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.liteBlack};
    font-size: 2rem;
    line-height: 2.75rem;
    letter-spacing: -0.01em;
    color: ${(p) => p.theme.colors.white};
    font-family: ${(p) => p.theme.fontFamilies.headlineBig};
    max-width: 39.5625rem;
    margin: auto;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 0;
    background-image: -moz-linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) ${gradientTabletValue}%,
        rgba(255, 255, 255, 0) ${gradientTabletValue}%,
        rgba(255, 255, 255, 1) 100%
      ),
      url("${(p) => p.url}"); /* FF3.6-15 */
    background-image: -webkit-linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) ${gradientTabletValue}%,
        rgba(255, 255, 255, 0) ${gradientTabletValue}%,
        rgba(255, 255, 255, 1) 100%
      ),
      url("${(p) => p.url}"); /* Chrome10-25,Safari5.1-6 */
    background-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) ${gradientTabletValue}%,
        rgba(255, 255, 255, 0) ${gradientTabletValue}%,
        rgba(255, 255, 255, 1) 100%
      ),
      url("${(p) => p.url}"); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='rgba(0, 0, 0, 1)', endColorstr='rgba(0, 0, 0, 0)',GradientType=1 ), progid:DXImageTransform.Microsoft.AlphaImageLoader(src='${(
      p
    ) => p.url}', sizingMethod='crop'); /* IE6-9 */
    h1 {
      font-size: 3rem;
      line-height: 4rem;
    }
    .hero-content {
      padding: 8.625rem 1.5rem 0;
      max-width: ${(p) => p.theme.breakpoints.tablet};
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    background-size: cover;
    overflow: hidden;
    padding-bottom: 2rem;

    .hero-content {
      padding: 8.625rem 2rem 0;
      max-width: ${(p) => p.theme.breakpoints.desktop};
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    h1 {
      font-size: 4.5rem;
      line-height: 6rem;
      max-width: 60rem;
    }
    .hero-content {
      padding: 8.625rem 6rem 0;
      max-width: ${(p) => p.theme.breakpoints.wide};
    }
    background-image: -moz-linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) ${gradientWideValue}%,
        rgba(255, 255, 255, 0) ${gradientWideValue}%,
        rgba(255, 255, 255, 1) 100%
      ),
      url("${(p) => p.url}"); /* FF3.6-15 */
    background-image: -webkit-linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) ${gradientWideValue}%,
        rgba(255, 255, 255, 0) ${gradientWideValue}%,
        rgba(255, 255, 255, 1) 100%
      ),
      url("${(p) => p.url}"); /* Chrome10-25,Safari5.1-6 */
    background-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) ${gradientWideValue}%,
        rgba(255, 255, 255, 0) ${gradientWideValue}%,
        rgba(255, 255, 255, 1) 100%
      ),
      url("${(p) => p.url}"); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='rgba(0, 0, 0, 1)', endColorstr='rgba(0, 0, 0, 0)',GradientType=1 ), progid:DXImageTransform.Microsoft.AlphaImageLoader(src='${(
      p
    ) => p.url}', sizingMethod='crop'); /* IE6-9 */
  }

  .mobile-background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
    width: 100%;
    height: 100%;
    background-color: ${(p) => p.theme.colors.whiteSmoke};

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      display: none;
    }
  }
`
export const StyledActionCardList = styled.div`
  display: flex;
  gap: 0.9375rem;
  justify-content: center;
  margin-top: 3.75rem;
  flex-wrap: wrap;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin: 6.25rem auto 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
  }
`
export const StyledBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 25rem;
  z-index: -1;
  padding: 0 1rem;
  background-image: url("${(p) => p.url}");
  background-position-y: top;
  background-image: -moz-linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) ${gradientMobileValue}%,
      rgba(255, 255, 255, 0) ${gradientMobileValue}%,
      ${(p) => p.theme.colors.whiteSmoke} 100%
    ),
    url("${(p) => p.url}"); /* FF3.6-15 */
  background-image: -webkit-linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) ${gradientMobileValue}%,
      rgba(255, 255, 255, 0) ${gradientMobileValue}%,
      ${(p) => p.theme.colors.whiteSmoke} 100%
    ),
    url("${(p) => p.url}"); /* Chrome10-25,Safari5.1-6 */
  background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) ${gradientMobileValue}%,
      rgba(255, 255, 255, 0) ${gradientMobileValue}%,
      ${(p) => p.theme.colors.whiteSmoke} 100%
    ),
    url("${(p) => p.url}"); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='rgba(0, 0, 0, 1)', endColorstr='rgba(0, 0, 0, 0)',GradientType=1 ), progid:DXImageTransform.Microsoft.AlphaImageLoader(src='${(
    p
  ) => p.url}', sizingMethod='crop'); /* IE6-9 */
  background-repeat: no-repeat;
  background-size: cover;

  .hero-content {
    margin: auto;
    padding: 7.5rem 0 0;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 0;
    height: 33.75rem;
    background-image: -moz-linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) ${gradientTabletValue}%,
        rgba(255, 255, 255, 0) ${gradientTabletValue}%,
        rgba(255, 255, 255, 1) 100%
      ),
      url("${(p) => p.url}"); /* FF3.6-15 */
    background-image: -webkit-linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) ${gradientTabletValue}%,
        rgba(255, 255, 255, 0) ${gradientTabletValue}%,
        rgba(255, 255, 255, 1) 100%
      ),
      url("${(p) => p.url}"); /* Chrome10-25,Safari5.1-6 */
    background-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) ${gradientTabletValue}%,
        rgba(255, 255, 255, 0) ${gradientTabletValue}%,
        rgba(255, 255, 255, 1) 100%
      ),
      url("${(p) => p.url}"); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='rgba(0, 0, 0, 1)', endColorstr='rgba(0, 0, 0, 0)',GradientType=1 ), progid:DXImageTransform.Microsoft.AlphaImageLoader(src='${(
      p
    ) => p.url}', sizingMethod='crop'); /* IE6-9 */
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    height: 43.75rem;
    background-size: cover;
    .hero-content {
      padding: 8.625rem 2rem 0;
      max-width: ${(p) => p.theme.breakpoints.desktop};
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    height: 54.25rem;
    background-image: -moz-linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) ${gradientWideValue}%,
        rgba(255, 255, 255, 0) ${gradientWideValue}%,
        rgba(255, 255, 255, 1) 100%
      ),
      url("${(p) => p.url}"); /* FF3.6-15 */
    background-image: -webkit-linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) ${gradientWideValue}%,
        rgba(255, 255, 255, 0) ${gradientWideValue}%,
        rgba(255, 255, 255, 1) 100%
      ),
      url("${(p) => p.url}"); /* Chrome10-25,Safari5.1-6 */
    background-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) ${gradientWideValue}%,
        rgba(255, 255, 255, 0) ${gradientWideValue}%,
        rgba(255, 255, 255, 1) 100%
      ),
      url("${(p) => p.url}"); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='rgba(0, 0, 0, 1)', endColorstr='rgba(0, 0, 0, 0)',GradientType=1 ), progid:DXImageTransform.Microsoft.AlphaImageLoader(src='${(
      p
    ) => p.url}', sizingMethod='crop'); /* IE6-9 */
  }
`
