import React from "react"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripeInstance } from "@tmu/utils/stripe"
import OfferPurchaseForm from "../OfferPurchaseForm"
import { useIntl } from "gatsby-plugin-intl"

const PurchasesForm = () => {
  const { locale } = useIntl()
  const stripeInstance = loadStripeInstance(null, locale)
  return stripeInstance ? (
    <Elements stripe={stripeInstance}>
      <OfferPurchaseForm />
    </Elements>
  ) : null
}

export default PurchasesForm
