import React, { useEffect, useState } from "react"
import { useLazyQuery } from "@apollo/client"
import { useAuth } from "@tmu/hooks"
import { HERO_ITEM_QUERY } from "@tmu/apollo/storefront/queries/hero"

import { MAX_PER_PAGE } from "@tmu/apollo/constants"
import HeroComponent from "./HeroComponent"

const SliderHero = ({
  page = "homepage",
  contactRef = null,
  children = null,
}) => {
  const { isAuthenticated } = useAuth()
  const [callHero, { data }] = useLazyQuery(HERO_ITEM_QUERY, {
    variables: {
      page,
      first: MAX_PER_PAGE,
      isPublic: true,
      authenticated: isAuthenticated,
    },
  })

  useEffect(() => {
    if (isAuthenticated !== null) {
      callHero()
    }
  }, [isAuthenticated])

  const [items, setItems] = useState([])

  useEffect(() => {
    const result = data?.allHeroItems?.edges?.map(({ node }) => node)
    if (Array.isArray(result)) {
      setItems(result)
    }
  }, [data])

  return (
    <HeroComponent
      contactRef={contactRef}
      items={items}
      page={page}
      children={children}
    />
  )
}

export default SliderHero
