import { useEffect, useState } from "react"
import { useLazyQuery } from "@apollo/client"
import { useApolloApiClients } from "@tmu/apollo/client"
import { SEARCH_QUERY } from "@tmu/apollo/storefront/queries/search"

const useSearch = ({ fieldName }) => {
  const { storefrontClient } = useApolloApiClients()
  const [searchResult, setSearchResult] = useState(null)
  const [callSearch, { data, called, refetch, fetchMore }] = useLazyQuery(
    SEARCH_QUERY,
    {
      variables: {},
      client: storefrontClient,
      fetchPolicy: "network-only",
      onCompleted: () => {
        handleResult(data)
      },
    }
  )

  useEffect(() => {
    if (data) {
      handleResult(data)
    }
  }, [data])

  const handleResult = (resultData) => {
    setSearchResult(resultData[fieldName])
  }

  const searchData = (searchKey) => {
    const variables = {
      variables: {
        query: searchKey,
        first: 6,
        isPublic: true,
        badge: ["NO_BADGE"],
        offerOrigin: ["offers"],
      },
    }

    if (!called) {
      callSearch(variables)
    } else {
      refetch(variables?.variables)
    }
  }

  const handleFetchMore = (endCursor) => {
    fetchMore({
      variables: {
        after: endCursor || data[fieldName]?.pageInfo?.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult[fieldName]?.edges
        const pageInfo = fetchMoreResult[fieldName]?.pageInfo

        return newEdges?.length
          ? {
              // Put the new allCampaigns at the end of the list and update `pageInfo`
              // so we have the new `endCursor` and `hasNextPage` values
              allCampaigns: {
                __typename: prev[fieldName].__typename,
                edges: [...prev[fieldName]?.edges, ...newEdges],
                pageInfo,
              },
            }
          : prev
      },
    })
  }

  return {
    searchResult,
    searchData,
    handleFetchMore,
  }
}

export default useSearch
