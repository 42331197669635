import React, {
  Fragment,
  useCallback,
  useEffect,
  useState,
  useContext,
} from "react"
import PropTypes from "prop-types"
import { useIntl, FormattedMessage, navigate } from "gatsby-plugin-intl"
import { useLazyQuery } from "@apollo/client"
import { useLogoImage, useAuth, useDefaultMerchant } from "@tmu/hooks"
import { faHandHoldingHeart } from "@fortawesome/pro-solid-svg-icons/faHandHoldingHeart"
import { faGratipay } from "@fortawesome/free-brands-svg-icons/faGratipay"
import { Tooltip } from "react-tooltip"
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUser } from "@fortawesome/pro-solid-svg-icons/faUser"
import { getCustomDifferance } from "@tmu/utils/date"
import RecurringDonation from "@tmu/components/partners/RecurringDonation"

import { faGem } from "@fortawesome/pro-solid-svg-icons/faGem"
import {
  ProgressBar,
  Spinner,
  Money,
  MediaSlider,
  RadioSelect,
  LocationViewer,
  Button,
  Stats,
  ShareComponent,
  DetailHeader,
  StoreSupporterBox,
  DonorListModal,
  LazyImage,
  UseBefore,
} from "@tmu/components/common"
import {
  StyledDonorListWrapper,
  StyledDonorsWrapper,
  StyledDonorName,
  StyledDonorNameWrapper,
  StyledSideDonorsTitle,
  StyledGem,
  StyledSideDonors,
} from "@tmu/components/common/DonorListModal/index.styles"
import { loadStripeInstance } from "@tmu/utils/stripe"
import ContentNotFound from "@tmu/components/ContentNotFound"
import SEO from "@tmu/components/seo"
import {
  StyledPartnerLogo,
  StyledUserImage,
  StyledSupportEndedButton,
  StyledEditButton,
  StyledDonationOptions,
  StyledEventTimeInfo,
  StyledEventLocationInfo,
  StyledSoldOutWarning,
  StyledWrapper,
  StyledContent,
  StyledPartnerSummaryMobile,
  StyledPageWrapper,
  StyledPACMultiplier,
  StyledTooltip,
  StyledCongratsPopup,
  StyledCongratsMobile,
  StyledCampaignSubtitle,
  StyledDetailHeader,
  StyledHeaderAndShare,
  StyledCampaignCreatedText,
  StyledCampaignCreatedTextInfo,
} from "./index.styles"
import { CAMPAIGN_DETAIL_QUERY } from "@tmu/apollo/storefront/queries/campaign"
import {
  StyledSectionTitle,
  StyledPartnerDescription as StyledDescription,
  StyledLine,
  StyledSupportButton,
  StyledSectionStoreSupporterTitle,
} from "@tmu/components/partners/PartnerDetail/index.styles"
import {
  DetailPageContainer,
  DetailPageMobileContainer,
  MainPart,
  SidePart,
  Spacer,
} from "@tmu/global/page-addons/detail-page.styles"
import { get } from "@tmu/utils/storage"
import { isTokenExpired } from "@tmu/utils/auth"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import theme from "@tmu/global/theme"
import { API_PERMISSIONS, FOOTER_TYPE, OFFER_TYPE } from "@tmu/apollo/constants"
import { capitalize, getValueForLocale } from "@tmu/utils/string"
import { format, differenceInCalendarDays, differenceInDays } from "date-fns"
import { useFooterType, usePartnerUsers } from "@tmu/hooks"
import { HomePageContext } from "@tmu/context/homePageContext"
import queryString from "query-string"
import { REVIEW_STATUS } from "@tmu/apollo/constants"
import { StyledNotFoundPage } from "@tmu/global/page-addons/flat-page.styles"
import {
  StyledExpiryDate,
  StyledUseBefore,
} from "../../common/UseBefore/index.styles"
import { isBrowser } from "@tmu/utils/auth"

const { CLOUDFLARE_IMAGE_URL } = process.env

const CampaignDetail = ({ slug, location }) => {
  const pathname = location?.pathname
  const search = location?.search
  const isNewCampaign = queryString.parse(search)?.new === "true"
  const referralCode = queryString.parse(search)?.referral
  const { user, apiPermissions } = useAuth()
  const isAuthenticated = !isTokenExpired(get("token"))
  const { defaultMerchant } = useDefaultMerchant()
  const [amounts, setAmounts] = useState()

  const { locale, defaultLocale, formatMessage } = useIntl()
  const [eventAddress, setEventAddress] = useState()
  const { setIntercomStatus } = useContext(HomePageContext)
  const [donationRadioOptions, setDonationRadioOptions] = useState([])
  const [selectedDonation, setSelectedDonation] = useState({})
  const [donationSelectValue, setDonationSelectValue] = useState("1")
  const [modalStatus, setModalStatus] = useState(false)

  const [
    isAllDonationOptionsSoldOutStatus,
    setIsAllDonationOptionsSoldOutStatus,
  ] = useState(false)
  const closeModal = () => {
    setModalStatus(false)
  }

  const openModal = () => {
    setModalStatus(true)
  }

  useFooterType({ footerType: FOOTER_TYPE.WIDE })

  const [getCampaignDetail, { loading, data, refetch }] = useLazyQuery(
    CAMPAIGN_DETAIL_QUERY({ locale }),
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (!data || !data?.campaign || !data?.campaign?.id) {
          setTimeout(() => refetch({ slug }), 10000)
        }
      },
    }
  )
  const topDonor = data?.topDonor?.edges?.[0]?.node || {}
  const latestDonors = data?.latestDonors?.edges?.map((n) => n.node) || []
  let donorList = topDonor?.id ? [topDonor].concat(latestDonors) : []
  const uniqueDonors = new Set()
  donorList = donorList.filter((donor) => {
    if (!uniqueDonors.has(donor.id)) {
      uniqueDonors.add(donor.id)
      return true
    }
    return false
  })

  useEffect(() => {
    getCampaignDetail({ variables: { slug } })
  }, [])

  useEffect(() => {
    if (!isTablet && isBrowser) window?.scrollTo(0, 0)
  }, [isBrowser])

  const hasStore = data?.campaign?.store !== null

  const isOfflineMerchant =
    data?.campaign?.store?.defaultOffer?.offerType === OFFER_TYPE.OFFLINE

  const isCorporateMerchant =
    data?.campaign?.store?.defaultOffer?.offerType === OFFER_TYPE.BRAND

  const { getPartnerUsers, partnerUsers } = usePartnerUsers(
    data?.campaign?.partner?.id
  )

  /* Indicates whether or not the campaign is a user supporter campaign */
  const isSupporterCampaign = !!data?.campaign?.user

  /* Indicates whether it is the merchant's campaign that owns the campaign */
  const isMerchantCampaign =
    data?.campaign?.store?.id &&
    defaultMerchant?.id &&
    data?.campaign?.store?.id === defaultMerchant?.id

  /* If the user is the owner of the campaign, the edit button will be displayed */
  const canSupporterEditCampaign =
    data?.campaign?.user?.id &&
    user?.id &&
    data?.campaign?.user?.id === user?.id

  const dateFormat = "yyyy/MM/dd"

  const currentDate = new Date(format(Date.now(), dateFormat))
  const campaignDate = data?.campaign?.startsAt
    ? new Date(format(new Date(data?.campaign?.startsAt), dateFormat))
    : null

  const isUpcomingCampaign = differenceInDays(campaignDate, currentDate) > 0

  useEffect(() => {
    if (user?.isPartner && data?.campaign?.partner?.id && !partnerUsers)
      getPartnerUsers()
  }, [user, data?.campaign?.partner?.id])
  const logoImage = useLogoImage()

  const { isTablet, isDesktop, isWide } = getAllScreenTypes()

  const donationOptions = data?.campaign?.tiers?.edges || []

  const soldOutMessage = formatMessage({
    id: "event::ticket::soldOut",
    defaultMessage: "sold out",
  }).toUpperCase()

  useEffect(() => {
    setIntercomStatus({ hide: true })
  }, [])

  useEffect(() => {
    if (data?.campaign?.stripePublicKey) {
      loadStripeInstance(data?.campaign?.stripePublicKey, locale)
    }

    const radioOptions = donationOptions.map((item) => {
      const isSoldOut =
        data?.campaign?.campaignType === "EV" ? item?.node?.isSoldOut : false
      const labelText = "€" + item?.node?.donationAmount
      let labelHtml = null

      if (isSoldOut === true) {
        labelHtml =
          "<p className='radio-label-align-left'>" +
          labelText +
          "</p><p className='radio-label-align-right'>" +
          soldOutMessage +
          "</p>"
      }

      const descriptionText = getValueForLocale(
        item?.node,
        "description",
        locale,
        defaultLocale
      )

      return {
        id: item?.node?.id,
        label: labelText,
        amount: item?.node?.donationAmount,
        labelHtml: labelHtml,
        operator: item?.node?.donationAmountOperator,
        description: descriptionText,
        isPassive: isSoldOut,
      }
    })
    if (!radioOptions.length) return
    radioOptions.sort((a, b) => a?.amount - b?.amount)
    const isAllDonationOptionsSoldOut = radioOptions.every(
      (item) => item?.isPassive
    )
    if (data?.campaign?.campaignType !== "EV") {
      radioOptions.push({
        id: "",
        label: formatMessage({
          id: `donation::option::${!isTablet ? "setOtherAmount" : "setAmount"}`,
          defaultMessage: !isTablet ? "Other" : "I will set an amount",
        }),
        amount: "",
        labelHtml: "",
        operator: "",
        description: "",
        isPassive: false,
      })
    }
    setDonationRadioOptions(radioOptions)
    setIsAllDonationOptionsSoldOutStatus(isAllDonationOptionsSoldOut)

    if (isAllDonationOptionsSoldOut === true) {
      setDonationSelectValue(null)
      setSelectedDonation(null)
    } else {
      const selectedItem = radioOptions?.filter(
        (item) => item?.isPassive === false
      )[0]
      setDonationSelectValue(selectedItem?.id)
      setSelectedDonation(selectedItem)
    }
  }, [data, isTablet])

  useEffect(() => {
    const eventAddressData = {
      addressLine1: campaign?.eventAddress,
      locationLink: campaign?.eventMapLink,
      virtualEventLink: campaign?.virtualEventLink,
    }
    if (!eventAddress?.virtualEventLink && !eventAddress?.addressLine1) {
      setEventAddress(eventAddressData)
    }
  }, [data, isTablet])

  const getEventDate = useCallback(() => {
    const deadline = new Date(campaign?.deadline)

    const timeDiff = deadline.getTime() - Date.now()

    if (timeDiff > 0) {
      const dayDiff = differenceInCalendarDays(deadline, new Date())

      const formattedDate = format(deadline.getTime(), "dd/MM/yyyy")
      const formattedTime = format(deadline.getTime(), "HH:mm")

      const dateData = {
        daysLeft: dayDiff,
        endDate: formattedDate,
        hour: formattedTime,
      }
      return dateData
    }
    return null
  }, [data, isTablet])

  const handleModalOpenClose = () => {
    navigate(`/campaigns/${slug}`)
  }

  const handleSupportClick = () => {
    if (isUpcomingCampaign) {
      navigate(`/campaigns?campaign=true`)
    } else {
      if (isOfflineMerchant) {
        navigate(
          `/calculate/?slug=${slug}${
            referralCode ? "&referral=" + referralCode : ""
          }`
        )
      } else if (selectedDonation?.id?.length > 0) {
        navigate(
          `/campaigns/${slug}/donate?tier=${selectedDonation?.id}${
            referralCode ? "&referral=" + referralCode : ""
          }`
        )
      } else {
        navigate(
          `/campaigns/${slug}/donate${
            referralCode ? "?referral=" + referralCode : ""
          }`
        )
      }
    }
  }

  const handleCampaignEndClick = () => {
    navigate(`/campaigns?campaign=true&charity=true&event=true`)
  }

  const campaign = data?.campaign
    ? {
        ...data.campaign,
        name: getValueForLocale(data.campaign, "name", locale, defaultLocale),
        description: getValueForLocale(
          data.campaign,
          "description",
          locale,
          defaultLocale
        ),
        shortDescription: getValueForLocale(
          data.campaign,
          "shortDescription",
          locale,
          defaultLocale
        ),
      }
    : null

  const pacMultiplierNumber = campaign?.pacMultiplier.split(".")[0]
  const subscriptionProductsId =
    campaign?.subscriptionProducts?.edges?.[0]?.node?.id
  const doublePacsTipText = formatMessage(
    {
      id: "doublePacs::listing::cardTooltip",
      defaultMessage:
        "You will get {pacMultiplierNumber} PACs for every EUR donated",
    },
    {
      pacMultiplierNumber: pacMultiplierNumber,
    }
  )

  const campaignImages = campaign?.images?.edges?.map(({ node }) => node) || []
  if (!campaignImages?.length)
    campaignImages.push({
      id: 0,
      image: getValueForLocale(
        campaign?.partner,
        "logo",
        locale,
        defaultLocale
      ),
    })
  const campaignImage =
    campaign?.image?.url || campaignImages?.length
      ? `${campaignImages[0].image}?width=600&height=317&quality=90`
      : campaign?.image || logoImage

  const partnerMatch = partnerUsers?.includes(user?.email)

  const canPartnerEditCampaign =
    isAuthenticated &&
    !isSupporterCampaign &&
    partnerMatch &&
    apiPermissions?.includes(API_PERMISSIONS.PARTNER_UPDATE_CAMPAIGN_CONTENT)

  const supportButtonText = isAllDonationOptionsSoldOutStatus
    ? formatMessage({
        id: "campaign::detail::buttons::supportCampaign",
        defaultMessage: "Donate and Get PACs",
      })
    : isCorporateMerchant
    ? formatMessage({
        id: "campaign::detail::buttons::corporateSupportCampaign",
        defaultMessage: "Support and Get PACs",
      })
    : isOfflineMerchant
    ? formatMessage({
        id: "campaign::detail::buttons::storeSupportCampaign",
        defaultMessage: "Support and Get Discount",
      })
    : // : selectedDonation?.operator
      // ? formatMessage({
      //     id: "eventDetail::button::donate",
      //     defaultMessage: "Donate and get PACs",
      //   })
      // : donationOptions.length && campaign?.campaignType !== "EV"
      // ? formatMessage({
      //     id: "donation::option::setAmount2",
      //     defaultMessage: "I will set an amount2",
      //   })
      formatMessage({
        id: "campaign::detail::buttons::supportCampaign",
        defaultMessage: "Donate and Get PACs",
      })

  const handleCampaignEditClick = () => {
    navigate(
      `/dashboard/campaign/edit/${slug}
        ?supporter=${!!isSupporterCampaign}&charity=${
        data?.campaign?.partner?.slug
      }&type=${data?.campaign?.campaignType === "EV" ? "event" : "collection"}${
        isOfflineMerchant ? "&offline=true" : ""
      }${isCorporateMerchant ? "&corporate=true" : ""}`

        .split(" ")
        .join("")
    )
  }

  const PacMultiplier = () => {
    return Number(campaign?.pacMultiplier) > 1 ? (
      <StyledPACMultiplier>
        <img
          className="pacs-pacs-image"
          src={CLOUDFLARE_IMAGE_URL + "/static/assets/images/white_pac.svg"}
          alt={`pacs-pacs-banner`}
          width={28}
          height={23}
        />
        {` X `}
        <Money value={campaign?.pacMultiplier} currency={false} />
      </StyledPACMultiplier>
    ) : null
  }

  const PartnerDonors = useCallback(
    ({ isMobile }) => {
      return (
        <>
          <StyledSideDonorsTitle>
            <p className="caption">
              <FormattedMessage
                id="partner::detail::donors"
                defaultMessage="Donors"
              />
            </p>
            <Button variant="text" onClick={openModal}>
              <FormattedMessage id="ui::seeAll" defaultMessage="See all" />
            </Button>
          </StyledSideDonorsTitle>
          <StyledDonorListWrapper isMobile={isMobile}>
            {donorList.length ? (
              donorList?.slice(0, 5).map((donor, index) => (
                <StyledDonorsWrapper key={donor.id}>
                  {donor?.isAnonymous ? (
                    <div className="user">
                      <FontAwesomeIcon icon={faUser} />
                    </div>
                  ) : donor?.user?.profileImage ? (
                    <LazyImage
                      src={donor?.user?.profileImage}
                      altName={`donor-user-${donor?.user?.fullName}`}
                      width={97}
                      height={97}
                      fit="cover"
                    />
                  ) : (
                    <div className="user">{donor?.user?.fullName?.[0]}</div>
                  )}
                  <StyledDonorNameWrapper>
                    <StyledDonorName>
                      {donor?.isAnonymous ? (
                        <FormattedMessage
                          id="donors::modal::anonymous"
                          defaultMessage="Anonymous"
                          tagName="p"
                        />
                      ) : (
                        donor?.user?.fullName
                      )}

                      <StyledSideDonors className="caption">
                        {getCustomDifferance(
                          new Date(),
                          new Date(donor?.latestDonationDate),
                          formatMessage
                        )}
                      </StyledSideDonors>
                      {index === 0 && (
                        <StyledGem>
                          <FontAwesomeIcon className="gem" icon={faGem} />
                          <StyledSideDonors className="caption gem">
                            <FormattedMessage
                              id="donors::modal::top"
                              defaultMessage="Top"
                            />
                          </StyledSideDonors>
                        </StyledGem>
                      )}
                    </StyledDonorName>
                    <div className="total">
                      <Money value={donor?.total || 0} />
                    </div>
                  </StyledDonorNameWrapper>
                </StyledDonorsWrapper>
              ))
            ) : (
              <>
                <FormattedMessage
                  id="partner::detail::noDonors"
                  defaultMessage="There are no donors currently"
                  tagName="p"
                />
                <hr></hr>
              </>
            )}
          </StyledDonorListWrapper>
        </>
      )
    },
    [data]
  )
  const EventTimeInfo = useCallback(() => {
    const eventDate = getEventDate()

    if (!eventDate) return null
    return (
      <>
        {!isTablet ? (
          <StyledEventTimeInfo data-testid="event-time-info">
            <p className="caption">
              <FormattedMessage
                id={
                  data?.campaign?.campaignType === "EV"
                    ? "event::timeInfo"
                    : "event::timeInfoDay"
                }
                defaultMessage={
                  data?.campaign?.campaignType === "EV"
                    ? "{daysLeft, plural, =0 {<strong>today</strong>} =1 {<strong>tomorrow</strong>} other {<strong>{daysLeft} days left</strong> until <strong>{endDate}</strong>}}, starts at <strong>{hour}</strong>"
                    : "{daysLeft, plural, =0 {<strong>today</strong>} =1 {<strong>TOMORROW</strong>} other {<strong>{daysLeft} days left</strong> until <strong>{endDate}</strong>}}"
                }
                values={{
                  daysLeft: eventDate?.daysLeft,
                  endDate: eventDate?.endDate,
                  hour: eventDate?.hour,
                  strong: (...chunks) => (
                    <span>
                      {chunks.map((chunk, i) => (
                        <Fragment key={i}>{chunk}</Fragment>
                      ))}
                    </span>
                  ),
                }}
              />
            </p>
          </StyledEventTimeInfo>
        ) : (
          <StyledEventTimeInfo data-testid="event-time-info">
            <p className="caption">
              <FormattedMessage
                id="event::timeInfoDay"
                defaultMessage="{daysLeft, plural, =0 {<strong>today</strong>} =1 {<strong>TOMORROW</strong>} other {<strong>{daysLeft} days left</strong> until <strong>{endDate}</strong>}}"
                values={{
                  daysLeft: eventDate?.daysLeft,
                  endDate: eventDate?.endDate,
                  strong: (...chunks) => (
                    <bold className="caption">
                      {chunks.map((chunk, i) => (
                        <Fragment key={i}>{chunk}</Fragment>
                      ))}
                    </bold>
                  ),
                }}
              />
            </p>
            {data?.campaign?.campaignType === "EV" ? (
              <p className="caption">
                <FormattedMessage
                  id="event::timeInfoHour"
                  defaultMessage="starts at <strong>{hour}</strong>"
                  values={{
                    hour: eventDate?.hour,
                    strong: (...chunks) => (
                      <bold className="caption">
                        {chunks.map((chunk, i) => (
                          <Fragment key={i}>{chunk}</Fragment>
                        ))}
                      </bold>
                    ),
                  }}
                />
              </p>
            ) : null}
          </StyledEventTimeInfo>
        )}
      </>
    )
  }, [data, data?.campaign?.campaignType])

  const DefaultSubtitle = useCallback(() => {
    return (
      <StyledCampaignSubtitle eventDate={!!getEventDate()}>
        {data?.campaign?.supporterCause?.icon ? (
          <i className={data?.campaign?.supporterCause?.icon} />
        ) : null}
        <div>
          <p className="caption">
            {data?.campaign?.supporterCause?.id ? (
              <>
                {data?.campaign?.supporterCause?.slug === "no-cause" ? (
                  data?.campaign?.campaignType === "EV" ? (
                    <FormattedMessage
                      id="campaign::detail::supporterEvent"
                      defaultMessage="Supporter Event"
                    />
                  ) : (
                    <FormattedMessage
                      id="campaign::detail::supporterCampaign"
                      defaultMessage="Supporter Campaign"
                    />
                  )
                ) : (
                  <>
                    {data?.campaign?.campaignType === "EV" ? (
                      <FormattedMessage
                        id={`dashboard::campaignType::event_${
                          data?.campaign?.supporterCause?.slug || ""
                        }`}
                        defaultMessage={
                          getValueForLocale(
                            data?.campaign?.supporterCause,
                            "name",
                            locale,
                            defaultLocale
                          ) + " Event"
                        }
                      />
                    ) : (
                      <FormattedMessage
                        id={`dashboard::campaignType::campaign_${
                          data?.campaign?.supporterCause?.slug || ""
                        }`}
                        defaultMessage={
                          getValueForLocale(
                            data?.campaign?.supporterCause,
                            "name",
                            locale,
                            defaultLocale
                          ) + " Campaign"
                        }
                      />
                    )}
                  </>
                )}
              </>
            ) : data?.campaign?.campaignType === "EV" ? (
              <>
                <FormattedMessage
                  id="dashboard::campaignType::event"
                  defaultMessage="Event"
                />
              </>
            ) : (
              <>
                <FormattedMessage
                  id="dashboard::campaignType::charityCampaign"
                  defaultMessage="Charity Campaign"
                />
              </>
            )}
          </p>
          <EventTimeInfo />
        </div>
      </StyledCampaignSubtitle>
    )
  }, [data?.campaign])

  const headerComponent = (
    <StyledDetailHeader>
      <DetailHeader
        headerClassName="detail-header"
        text={getValueForLocale(data?.campaign, "name", locale, defaultLocale)}
      />
      <DefaultSubtitle />
    </StyledDetailHeader>
  )

  const handleShare = () => {
    try {
      if (navigator && navigator.share)
        navigator.share({
          title: campaign?.name,
          url: location?.href || "https://trustmeup.com",
          text: campaign?.name || "TrustmeUp",
        })
    } catch (err) {}
  }

  const TierAction = useCallback(() => {
    const gridClassName =
      donationRadioOptions?.length > 3 ? "donation-grid-four" : ""
    const el =
      typeof window !== "undefined" && document.getElementsByTagName("body")[0]
    if (el)
      el.style.paddingBottom = !isTablet
        ? data?.campaign?.campaignType === "EV"
          ? "9.6875rem"
          : "3.125rem"
        : 0
    return (
      <div>
        {campaign?.visibilityStatus === "ARCHIVED" ||
        campaign?.status === "ENDED" ||
        !campaign?.isAvailable ? (
          <StyledSupportEndedButton
            color="blue"
            data-testid="support-campaign-end"
            onClick={handleCampaignEndClick}
            className="extra-margin">
            <FontAwesomeIcon icon={faGratipay} />
            <FormattedMessage
              id="campaign::detail::buttons::endCampaign"
              defaultMessage="Finished campaign! Click here to see others"
            />
          </StyledSupportEndedButton>
        ) : (
          <>
            {campaign?.recurringIsEnabled ? null : (
              <>
                {donationRadioOptions.length > 0 &&
                  !isAllDonationOptionsSoldOutStatus && (
                    <StyledDonationOptions className={gridClassName}>
                      <RadioSelect
                        items={donationRadioOptions}
                        defaultValue={donationSelectValue}
                        onChange={(e) => {
                          const selectedValue = e.target.value
                          if (selectedValue?.isPassive !== true) {
                            setDonationSelectValue(selectedValue)
                            const filteredData = donationRadioOptions.filter(
                              (i) => i.id === selectedValue
                            )
                            if (filteredData.length > 0) {
                              setSelectedDonation(filteredData[0])
                            }
                          }
                        }}
                      />
                    </StyledDonationOptions>
                  )}
                {isAllDonationOptionsSoldOutStatus && (
                  <StyledSoldOutWarning>
                    <FormattedMessage
                      id="eventDetail::button::soldOutWarning"
                      defaultMessage="Tickets for this event are sold out but donations are open for your support."
                    />
                  </StyledSoldOutWarning>
                )}
                <div className={!isTablet ? "sticky-bottom-bar" : ""}>
                  <StyledSupportButton
                    color="blue"
                    data-testid="support-campaign"
                    onClick={handleSupportClick}
                    className={`${!campaign.goalAmount && "extra-margin"}  ${
                      process.env.IS_DISABLE_DONATION_PAYMENT === "true"
                        ? "disabled"
                        : ""
                    }`}
                    data-tooltip-id={
                      Number(campaign?.pacMultiplier) > 1
                        ? "campaignDetails"
                        : ""
                    }
                    data-tooltip-content={doublePacsTipText}>
                    {process.env.IS_DISABLE_DONATION_PAYMENT === "true" ? (
                      <FormattedMessage
                        id="button::text::comingSoon"
                        defaultMessage="Coming Soon"
                      />
                    ) : isUpcomingCampaign ? (
                      <FormattedMessage
                        id="button::text::upcoming"
                        defaultMessage="UPCOMING CAMPAIGN. CLICK HERE TO SEE OTHERS"
                      />
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faHandHoldingHeart} />
                        {supportButtonText}
                        {Number(campaign?.pacMultiplier) > 1 ? (
                          <PacMultiplier />
                        ) : null}
                      </>
                    )}
                  </StyledSupportButton>
                </div>
                <StyledTooltip>
                  {Number(campaign?.pacMultiplier) > 1 && (
                    <Tooltip
                      arrowColor="transparent"
                      effect="solid"
                      place="bottom"
                      type="info"
                      textColor="black"
                      backgroundColor="white"
                      id="campaignDetails"
                      className="campaignDetails"
                      delayShow={500}
                    />
                  )}
                </StyledTooltip>
              </>
            )}
          </>
        )}
        {(canPartnerEditCampaign ||
          canSupporterEditCampaign ||
          (isMerchantCampaign &&
            apiPermissions?.includes(
              API_PERMISSIONS.MERCHANT_UPDATE_CAMPAIGN_CONTENT
            ))) &&
          data?.campaign.status !== REVIEW_STATUS.ENDED && (
            <StyledEditButton
              className="edit"
              color="transparent"
              onClick={handleCampaignEditClick}>
              {data?.campaign?.campaignType === "EV" ? (
                <FormattedMessage
                  id="button::text::editEvent"
                  defaultMessage="Edit Event"
                />
              ) : (
                <FormattedMessage
                  id="button::text::edit"
                  defaultMessage="Edit Campaign"
                />
              )}
            </StyledEditButton>
          )}
      </div>
    )
  }, [
    campaign,
    donationRadioOptions,
    isAllDonationOptionsSoldOutStatus,
    donationSelectValue,
    locale,
    isTablet,
  ])

  const CampaignTabs = useCallback(() => {
    return (
      <StyledWrapper data-testid="campaign-detail__overview">
        <StyledContent>
          <StyledSectionTitle
            className="caption"
            data-testid="campaign-detail__overview__tab">
            {data?.campaign?.campaignType === "EV" ? (
              <FormattedMessage
                id="partner::detail::descriptionEvent"
                defaultMessage="Description of the event"
              />
            ) : isOfflineMerchant ? (
              <>
                <StyledSectionStoreSupporterTitle>
                  <FormattedMessage
                    id="partner::detail::descriptionStoreSupporter"
                    defaultMessage="Donate to {partner} to get up to {maxPacDiscount}% discount of your order in {campaign}"
                    values={{
                      partner: campaign?.partner?.displayName,
                      maxPacDiscount: campaign?.store?.maxPacDiscount ? (
                        <Money
                          value={campaign?.store?.maxPacDiscount}
                          currency={false}
                        />
                      ) : (
                        0
                      ),
                      campaign: hasStore
                        ? campaign?.store?.name
                        : campaign?.name,
                    }}
                  />
                </StyledSectionStoreSupporterTitle>
                <FormattedMessage
                  id="partner::detail::descriptionCampaign"
                  defaultMessage="Description of the campaign"
                  tagName="p"
                />
              </>
            ) : (
              <FormattedMessage
                id="partner::detail::descriptionCampaign"
                defaultMessage="Description of the campaign"
              />
            )}
          </StyledSectionTitle>
          <StyledDescription
            dangerouslySetInnerHTML={{ __html: campaign?.description }}
          />
        </StyledContent>
      </StyledWrapper>
    )
  }, [campaign?.description])

  const EventLocationInfo = () => {
    return (
      <>
        {(eventAddress?.virtualEventLink || eventAddress?.addressLine1) && (
          <>
            <Spacer top={1.5} />
            <StyledEventLocationInfo data-testid="event-location-info">
              <LocationViewer address={eventAddress} />
            </StyledEventLocationInfo>
            <Spacer top={2} />
          </>
        )}
      </>
    )
  }

  const PartnerSummary = useCallback(() => {
    const campaignName = !!campaign?.supportedCampaign
      ? campaign?.supportedCampaign?.name
      : campaign?.partner?.displayName.length
      ? campaign?.partner?.displayName
      : campaign?.partner?.name

    const partnerUrl = "/" + locale + "/charities/" + campaign?.partner?.slug

    const supportedCampaignUrl =
      "/" + locale + "/campaigns/" + campaign?.supportedCampaign?.slug

    const storeUrl = "/" + locale + "/offers/store/" + campaign?.store?.slug

    return (
      <>
        <StyledLine />
        <StyledPartnerSummaryMobile
          isCorporateMerchant={isCorporateMerchant}
          isOfflineMerchant={isOfflineMerchant}>
          {hasStore ? (
            <>
              {isOfflineMerchant ? (
                <StyledUserImage
                  data-testid="campaign-detail__supporter-image"
                  src={campaign?.store?.logo}
                />
              ) : null}
              {isOfflineMerchant ? (
                <FormattedMessage
                  id="campaign::detail:createdByOfflineMerchant"
                  defaultMessage="This campaign is created by {storeLink} in support of {partnerLink}"
                  values={{
                    storeLink: <a href={storeUrl}>{campaign?.store?.name}</a>,
                    partnerLink: (
                      <a
                        href={
                          !!campaign?.supportedCampaign
                            ? supportedCampaignUrl
                            : partnerUrl
                        }>
                        {campaignName}
                      </a>
                    ),
                  }}
                  tagName="span"
                />
              ) : isCorporateMerchant ? (
                <FormattedMessage
                  id="campaign::detail:createdByCorporateMerchant"
                  defaultMessage="Created by {storeLink} in support of {partnerLink}"
                  values={{
                    storeLink: !campaign?.store?.isDisplayed ? (
                      <span className="carrot-text">
                        {campaign?.store?.name}
                      </span>
                    ) : (
                      <a href={storeUrl}>{campaign?.store?.name}</a>
                    ),
                    partnerLink: (
                      <a
                        href={
                          !!campaign?.supportedCampaign
                            ? supportedCampaignUrl
                            : partnerUrl
                        }>
                        {campaignName}
                      </a>
                    ),
                  }}
                  tagName="span"
                />
              ) : null}
            </>
          ) : isSupporterCampaign && campaign?.user ? (
            <>
              <StyledUserImage
                data-testid="campaign-detail__supporter-image"
                src={campaign?.user?.profileImage}
              />
              {data?.campaign?.campaignType === "EV" ? (
                <FormattedMessage
                  id="campaign::detail:createdByUserEvent"
                  defaultMessage="This event is created by {user} in support of {partnerLink}"
                  values={{
                    user: campaign?.user?.fullName,
                    partnerLink: (
                      <a
                        href={
                          !!campaign?.supportedCampaign
                            ? supportedCampaignUrl
                            : partnerUrl
                        }>
                        {campaignName}
                      </a>
                    ),
                  }}
                  tagName="span"
                />
              ) : (
                <FormattedMessage
                  id="campaign::detail:createdByUser"
                  defaultMessage="This campaign is created by {user} in support of {partnerLink}"
                  values={{
                    user: campaign?.user?.fullName,
                    partnerLink: (
                      <a
                        href={
                          !!campaign?.supportedCampaign
                            ? supportedCampaignUrl
                            : partnerUrl
                        }>
                        {campaignName}
                      </a>
                    ),
                  }}
                  tagName="span"
                />
              )}
            </>
          ) : (
            <>
              <StyledPartnerLogo
                data-testid="campaign-detail__foundation-logo"
                src={getValueForLocale(
                  campaign?.partner,
                  "logo",
                  locale,
                  defaultLocale
                )}
              />
              <FormattedMessage
                id="campaign::detail:createdBy"
                defaultMessage="Official campaign created by {partnerLink}"
                values={{
                  partnerLink: (
                    <a
                      href={
                        !!campaign?.supportedCampaign
                          ? supportedCampaignUrl
                          : partnerUrl
                      }>
                      {campaignName}
                    </a>
                  ),
                }}
                tagName="span"
              />
            </>
          )}
        </StyledPartnerSummaryMobile>
        <StyledLine />
      </>
    )
  }, [campaign?.partner, campaign?.supportedCampaign])

  const descriptionToShare =
    (campaign?.shortDescription || campaign?.description) +
    (campaign?.fundedAmount && campaign.fundedAmount !== "0.00"
      ? " " +
        campaign?.fundedAmount +
        " " +
        formatMessage({
          id: "progress::detail::totalSupport",
          defaultMessage: "Raised",
        })
      : "")

  const statsComponent = (
    <>
      <Stats
        fundedAmount={campaign?.fundedAmount}
        donationCount={campaign?.donationCount}
        campaignName={campaign?.name}
        campaignId={campaign?.id}
        isTablet={isTablet}
        canSupporterEditCampaign={canSupporterEditCampaign}
        isMerchantCampaign={isMerchantCampaign}
      />

      {campaign?.goalAmount > 0 && (
        <div>
          <ProgressBar
            hideDecimals
            amount={campaign?.fundedAmount}
            total={campaign?.goalAmount}
            currentNumber={campaign?.donationCount}
            campaignType={campaign?.campaignType}
            showProgressLine={true}
          />
        </div>
      )}
    </>
  )

  const logoSize = {
    width: isWide ? 406 : isDesktop ? 215 : isTablet ? 157 : 128,
    height: isWide ? 232 : isDesktop ? 122 : isTablet ? 89 : 73,
  }

  const voucherUserBeforeContent = (
    <>
      {/* {campaign?.isVoucher && campaign?.deadline && (
        <div className="use-before">
          <Spacer top={1} />
          <UseBefore
            isVoucher={campaign?.isVoucher}
            endDate={campaign?.deadline}
            isCampaign={true}
          />
          <Spacer top={1} />
        </div>
      )} */}

      {campaign?.isReservationRequired && (
        <div className="use-before">
          <StyledExpiryDate>
            <StyledUseBefore>
              <FormattedMessage
                id="purchase::success::reservationRequired"
                defaultMessage="Reservation Required"
              />
            </StyledUseBefore>
          </StyledExpiryDate>
          <Spacer top={1} />
        </div>
      )}
    </>
  )

  return (
    <>
      <SEO
        lang={locale}
        title={campaign?.name}
        description={descriptionToShare}
        image={campaignImage}
        pathname={pathname}
      />
      {loading ? (
        <Spinner />
      ) : campaign ? (
        <>
          {!isTablet ? (
            <>
              {isNewCampaign ? (
                // mobile version new campaign
                <StyledCongratsMobile>
                  <div className="modal-content">
                    <span>
                      <FormattedMessage
                        id="campaign::detail::congrats::heading"
                        defaultMessage="Congrats!"
                        tagName="h2"
                      />
                      <FormattedMessage
                        id="campaign::detail::congrats::heading-mobile"
                        defaultMessage="Your campaign is ready to receive donations"
                        tagName="h2"
                      />
                    </span>
                    <FormattedMessage
                      id="campaign::detail::congrats::paragraph"
                      defaultMessage="To help your campaign do better start from sharing the campaign on your socials with friends"
                      tagName="p"
                    />
                  </div>
                  <div className="sticky-bottom-bar">
                    <hr />
                    <Button
                      type="button"
                      onClick={() => {
                        handleModalOpenClose()
                        handleShare()
                      }}>
                      <FormattedMessage
                        id="campaign::detail::congrats::shareButton"
                        defaultMessage="ok, let‘s share it"
                      />
                    </Button>
                  </div>
                </StyledCongratsMobile>
              ) : (
                // mobile version non-new campaign
                <StyledPageWrapper>
                  <DetailPageMobileContainer>
                    {isOfflineMerchant ? (
                      <StoreSupporterBox
                        className="store-supporter-box"
                        storeLogo={campaign?.store?.logo}
                        partnerLogo={campaign?.partner?.logo}
                        {...logoSize}
                      />
                    ) : (
                      <MediaSlider
                        images={campaignImages}
                        altName={`campaign-detail-${campaignImages}`}
                        video={campaign?.video}
                      />
                    )}
                    {headerComponent}
                    <Spacer top={1.5} />
                    <EventLocationInfo />
                    <Spacer top={1} />
                    {voucherUserBeforeContent}
                    <Spacer top={1} />
                    {statsComponent}
                    <TierAction />
                    <hr />
                    <PartnerDonors isMobile />
                    <PartnerSummary />
                    <CampaignTabs />

                    {campaign?.recurringIsEnabled && (
                      <RecurringDonation
                        amountOne={campaign?.recurringAmountOne}
                        amountTwo={campaign?.recurringAmountTwo}
                        amountThree={campaign?.recurringAmountThree}
                        // descriptionOne={amounts?.[0]?.description}
                        // descriptionTwo={amounts?.[1]?.description}
                        // descriptionThree={amounts?.[2]?.description}
                        campaign={campaign}
                      />
                    )}
                  </DetailPageMobileContainer>
                </StyledPageWrapper>
              )}
            </>
          ) : (
            // non-mobile version
            <StyledPageWrapper>
              <DetailPageContainer>
                <MainPart>
                  {isOfflineMerchant ? (
                    <StoreSupporterBox
                      className="store-supporter-box"
                      storeLogo={campaign?.store?.logo}
                      partnerLogo={campaign?.partner?.logo}
                      {...logoSize}
                    />
                  ) : (
                    <MediaSlider
                      images={campaignImages}
                      altName={`campaign-detail-${campaignImages}`}
                      video={campaign?.video}
                    />
                  )}
                  <CampaignTabs />
                </MainPart>
                <SidePart
                  style={{
                    paddingTop: "unset",
                  }}>
                  <StyledHeaderAndShare>
                    <div>{headerComponent}</div>
                    <div>
                      <ShareComponent
                        slug={slug}
                        isNewCampaign={isNewCampaign}
                        name={campaign?.name}
                        isMerchantCampaign={isMerchantCampaign}
                        discount={campaign?.store?.maxPacDiscount}
                        storeLogo={campaign?.store?.logo}
                        partnerLogo={campaign?.partner?.logo}
                        position="center"
                        iconColor={
                          isOfflineMerchant
                            ? theme?.colors?.carrot
                            : isCorporateMerchant
                            ? theme?.colors?.carrot
                            : theme?.colors?.blue
                        }
                      />
                    </div>
                  </StyledHeaderAndShare>
                  <EventLocationInfo />
                  <Spacer top={1} />
                  {voucherUserBeforeContent}
                  <PartnerSummary />
                  {statsComponent}
                  {campaign?.recurringIsEnabled ? (
                    <RecurringDonation
                      amountOne={campaign?.recurringAmountOne}
                      amountTwo={campaign?.recurringAmountTwo}
                      amountThree={campaign?.recurringAmountThree}
                      // descriptionOne={amounts?.[0]?.description}
                      // descriptionTwo={amounts?.[1]?.description}
                      // descriptionThree={amounts?.[2]?.description}
                      campaign={campaign}
                    />
                  ) : null}

                  <TierAction />
                  <hr />
                  <PartnerDonors />
                  {isNewCampaign ? (
                    <StyledCongratsPopup>
                      <div className="modal-content">
                        {data?.campaign?.campaignType === "EV" ? (
                          <>
                            <FormattedMessage
                              id="campaign::detail::congrats::heading-desktop-event"
                              defaultMessage="Congratulations! Your event is ready to receive donations."
                              tagName="h2"
                            />
                            <FormattedMessage
                              id="campaign::detail::congrats::paragraphEvent"
                              defaultMessage="Do you want your event to be successful? Share it with your friends through social networks."
                              tagName="p"
                            />
                          </>
                        ) : (
                          <>
                            <FormattedMessage
                              id="campaign::detail::congrats::heading-desktop"
                              defaultMessage="Congratulations! Your campaign is ready to receive donations."
                              tagName="h2"
                            />
                            <FormattedMessage
                              id="campaign::detail::congrats::paragraph"
                              defaultMessage="Do you want your campaign to be successful? Share it with your friends through social networks."
                              tagName="p"
                            />
                          </>
                        )}
                      </div>
                      <FontAwesomeIcon
                        icon={faTimes}
                        onClick={handleModalOpenClose}
                      />
                    </StyledCongratsPopup>
                  ) : null}
                </SidePart>
              </DetailPageContainer>
            </StyledPageWrapper>
          )}
        </>
      ) : isNewCampaign ? (
        <>
          <StyledNotFoundPage
            style={{ textAlign: "center" }}
            data-testid="content-not-found">
            <img
              src={
                CLOUDFLARE_IMAGE_URL +
                "/static/assets/images/loading_spinner.gif"
              }
              alt={`loading-spinner`}
              width={160}
            />

            <StyledCampaignCreatedText>
              <FormattedMessage
                id="campaign::detail::beingCreated"
                defaultMessage="Your campaign is being created"
              />
            </StyledCampaignCreatedText>
            <StyledCampaignCreatedTextInfo>
              {data?.campaign?.campaignType === "EV" ? (
                <FormattedMessage
                  id="campaign::detail::infoMsgEvent"
                  defaultMessage="It may take up to 10 seconds for the event to appear. If it doesn’t load, please refresh the page"
                />
              ) : (
                <FormattedMessage
                  id="campaign::detail::infoMsgCampaign"
                  defaultMessage="It may take up to 10 seconds for the campaign to appear. If it doesn’t load, please refresh the page"
                />
              )}
            </StyledCampaignCreatedTextInfo>
          </StyledNotFoundPage>
        </>
      ) : (
        <ContentNotFound slug={slug} />
      )}
      {modalStatus ? (
        <DonorListModal
          modalStatus={modalStatus}
          closeModal={closeModal}
          campaignSlug={slug}
          onAction={handleSupportClick}
          supportButtonText={
            isAllDonationOptionsSoldOutStatus
              ? formatMessage({
                  id: "campaign::detail::buttons::supportCampaign",
                  defaultMessage: "Donate and Get PACs",
                })
              : isCorporateMerchant
              ? formatMessage({
                  id: "campaign::detail::buttons::corporateSupportCampaign",
                  defaultMessage: "Support and Get PACs",
                })
              : isOfflineMerchant
              ? formatMessage({
                  id: "campaign::detail::buttons::storeSupportCampaign",
                  defaultMessage: "Support and Get Discount",
                })
              : formatMessage({
                  id: "campaign::detail::buttons::supportCampaign",
                  defaultMessage: "Donate and Get PACs",
                })
          }
        />
      ) : null}
    </>
  )
}

CampaignDetail.propTypes = {
  slug: PropTypes.string.isRequired,
}

CampaignDetail.defaultProps = {
  slug: "",
}

export default CampaignDetail
