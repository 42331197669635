import { AsYouType } from "libphonenumber-js"

const getFormatPhoneNumber = (phoneNumber) => {
  const phnNumber = phoneNumber ?? ""
  const formatPhoneNumber = phnNumber.length
    ? phnNumber.startsWith("+")
      ? phnNumber
      : `+${phnNumber}`
    : ""

  return new AsYouType().input(formatPhoneNumber)
}

export { getFormatPhoneNumber }
