import styled from "@emotion/styled"

export const StyledModalContent = styled.div`
  img {
    width: 12.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
  }
`

export const StyledActionButtons = styled.div`
  display: flex;
  gap: 1.5rem;

  button {
    width: 100%;
    padding: 0;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    button {
      width: 10.5rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    button {
      width: 11.75rem;
    }
  }
`
