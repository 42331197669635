import { gql } from "@apollo/client"
import { capitalize } from "@tmu/utils/string"

export const OFFER_DETAIL_QUERY = ({ locale }) => {
  const localeCapital = capitalize(locale)

  return gql`
    query OfferDetail($slug: String!) {
      offer(slug: $slug) {
        id
        slug
        name
        shortDescription
        shortDescription${localeCapital}
        description
        description${localeCapital}
        image
        maxPacDiscount
        location
        visibilityStatus
        isAvailable
        startDate
        endDate
        maxPrice
        minPrice
        price
        vatAmount
        vatPercentage
        pacDiscountAmount
        total
        isUnlisted
        isFeatured
        stripePublicKey
        isDefaultOffer
        addressLine1
        city
        country
        postCode
        location
        location
        phoneNumber
        showAddressAndPhone
        isReservationRequired
        startDateVisibility
        isVoucher
        isInvoiceMandatory
        activePromotion {
          id
        }
        allowUsersToSelectADifferentCampaign
          favoriteCharity {
            id
            name
            nameEn
            nameIt
            nameEs
            pacMultiplier
            partner {
              logo
              logoEn
              logoEs
              logoIt
            }
          }
        promotions {
          id
        }
        store {
          id
          name
          displayName
          logo
          slug
          shortDescription
          shortDescription${localeCapital}
          description
          description${localeCapital}
          phoneNumber
          defaultOffer {
            phoneNumber
            addressLine1
            city
            country
            postCode
            location
          }
          image
          images {
            edges {
              node {
                id
              }
            }
          }
          allowUsersToSelectADifferentCampaign
          favoriteCharity {
            id
            name
            nameEn
            nameIt
            nameEs
            pacMultiplier
            partner {
              logo
              logoEn
              logoEs
              logoIt
            }
          }
        }
        productVariation {
          edges {
            node {
              id
              name
              name${localeCapital}
              description
              description${localeCapital}
              price
              pacDiscount
              maxTickets
              isUnlimitedTickets
              isSoldOut
              stockLeft
            }
          }
        }
      }
    }
  `
}
