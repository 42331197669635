import React from "react"
import { navigate } from "gatsby-plugin-intl"
import { Button } from "@tmu/components/common"
import { LogoText } from "@tmu/components/common"
import { StyledNavbarLogo } from "../index.styles"
import theme from "@tmu/global/theme"
import { useIntl } from "gatsby-plugin-intl"

const NavbarLogo = ({ flat, onClick, ...rest }) => {
  const { locale } = useIntl()

  // const handleClick = () => {
  //   navigate(`/${locale}/`)
  // }
  return (
    <StyledNavbarLogo onClick={onClick}>
      <Button
        variant="text"
        label="logo"
        // onClick={handleClick}
        data-testid="btn-navbar-logo">
        <a href={`/${locale}/`}>
          <LogoText
            width={rest?.width || 130}
            height={rest?.height || 25}
            flat={flat || false}
            fill={rest?.color || theme?.colors?.blue}
          />
        </a>
      </Button>
    </StyledNavbarLogo>
  )
}

export default NavbarLogo
