import React, {
  useState,
  Fragment,
  useEffect,
  forwardRef,
  useRef,
  useImperativeHandle,
  useCallback,
} from "react"
import { useDropzone } from "react-dropzone"
import { faArrowUpFromBracket } from "@fortawesome/free-solid-svg-icons/faArrowUpFromBracket"
import { faUndo } from "@fortawesome/pro-light-svg-icons/faUndo"
import { faImage } from "@fortawesome/pro-light-svg-icons/faImage"
import { Button } from "@tmu/components/common"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FormattedMessage } from "gatsby-plugin-intl"
import {
  StyledImageSelector,
  StyledReactCrop,
  StyledImageControls,
  StyledDropzone,
  StyledDisabledPreviewImage,
  StyledUploadIcon,
} from "./index.styles"
import {
  base64StringtoFile,
  extractImageFileExtensionFromBase64,
  image64toCanvasRef,
} from "@tmu/utils/image"
import { isSafari } from "react-device-detect"

const { CLOUDFLARE_IMAGE_DOMAIN = "trustmeup.com" } = process.env

const imageMaxSize = 1000000000 // bytes
const acceptedFileTypes =
  "image/x-png, image/png, image/jpg, image/jpeg, image/gif"
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => {
  return item.trim()
})

const verifyFile = (files) => {
  if (files && files.length > 0) {
    const currentFile = files[0]
    const currentFileType = currentFile.type
    const currentFileSize = currentFile.size
    if (currentFileSize > imageMaxSize) {
      alert(
        "This file is not allowed. " + currentFileSize + " bytes is too large"
      )
      return false
    }
    if (!acceptedFileTypesArray.includes(currentFileType)) {
      alert("This file is not allowed. Only images are allowed.")
      return false
    }
    return true
  }
}

const ImageSelector = forwardRef(
  (
    {
      dashed,
      square,
      noAspect,
      banner,
      defaultValue = "",
      onChange,
      onCropChange,
      onReset,
      disabled,
      imgWidth,
      imgHeight,
      alignOverlay,
      customIcon,
      showUploadDescription = true,
      showUploadLabel = true,
      newDesign = false,
      iconStyle = {},
    },
    ref
  ) => {
    const {
      getRootProps,
      getInputProps,
      isDragActive,
      isDragAccept,
      isDragReject,
    } = useDropzone({
      onDrop: handleOnDrop,
      accept: acceptedFileTypes,
      multiple: false,
      maxSize: imageMaxSize,
      disabled: !showUploadDescription && !showUploadLabel,
    })
    const [isEditing, setEditing] = useState(false)
    const imageRef = useRef()
    const svgRef = useRef()

    useImperativeHandle(ref, () => ({
      closeEdit: closeEdit,
    }))

    const initialState = {
      imgSrc: defaultValue,
      imgSrcExt: null,
      crop: {
        unit: "px",
        width: 100,
        x: 5,
        y: 5,
        aspect: noAspect ? null : square ? 1 / 1 : banner ? 4 / 1 : 16 / 9,
      },
    }
    const [state, setState] = useState(initialState)
    const [isPhotoHover, setIsPhotoHover] = useState(false)
    const [imgSize, setImgSize] = useState({ width: 0, height: 0 })

    async function handleOnDrop(acceptedFiles, rejectedFiles) {
      if (rejectedFiles && rejectedFiles.length > 0) {
        verifyFile(rejectedFiles)
      }

      if (acceptedFiles && acceptedFiles.length > 0) {
        const isVerified = verifyFile(acceptedFiles)

        if (isVerified) {
          // imageBase64Data
          const currentFile = acceptedFiles[0]
          const myFileItemReader = new FileReader()
          myFileItemReader.addEventListener(
            "load",
            () => {
              const myResult = myFileItemReader.result
              setState({
                ...state,
                crop: initialState.crop,
                imgSrc: myResult,
                imgSrcExt: extractImageFileExtensionFromBase64(myResult),
              })
              onChange(currentFile)
              setEditing(true)
            },
            false
          )

          myFileItemReader.readAsDataURL(currentFile)
        }
      }
    }

    function handleImageLoaded(image) {
      setState({
        ...state,
        crop: {
          ...state.crop,
        },
      })
      setImgSize({ width: image.naturalWidth, height: image.naturalHeight })
      return false // Return false when setting crop state in here.
    }

    const handleOnCropChange = (crop) => {
      setState({
        ...state,
        crop: { ...crop },
      })
    }

    const handleOnCropComplete = async (crop, pixelCrop) => {
      const { imgSrc } = state
      const canvas = await image64toCanvasRef(imgSrc, pixelCrop, imgSize)
      const imageData64 = canvas.toDataURL("image/png")
      const filename = `image.png`

      // file to be uploaded
      const myNewCroppedFile = base64StringtoFile(imageData64, filename)
      onCropChange(myNewCroppedFile)
    }

    const handleClearToDefault = async (event) => {
      if (event) {
        event.preventDefault()
      }

      setState(initialState)
      setEditing(false)
      onReset()
    }

    const closeEdit = () => {
      setEditing(false)
    }

    const { imgSrc, crop } = state

    const addCdn = useCallback((src, width, height) => {
      let options = `width=${width},height=${height}`

      return src.includes(CLOUDFLARE_IMAGE_DOMAIN)
        ? src.replace(
            CLOUDFLARE_IMAGE_DOMAIN,
            `${CLOUDFLARE_IMAGE_DOMAIN}/cdn-cgi/image/${options},${
              !isSafari && `format=webp`
            },quality=90`
          )
        : src
    })
    const imageSource =
      defaultValue && defaultValue?.length > 0 && imgWidth && imgHeight
        ? addCdn(defaultValue, imgWidth, imgHeight)
        : defaultValue

    return (
      <StyledImageSelector className="image-selector" dashed={dashed}>
        {isEditing ? (
          <>
            <StyledReactCrop
              src={imgSrc}
              crop={crop}
              onImageLoaded={handleImageLoaded}
              onComplete={handleOnCropComplete}
              onChange={handleOnCropChange}
              keepSelection={true}
              circularCrop={square}
              crossOrigin="Anonymous"
            />
            <StyledImageControls>
              <Button
                variant="icon"
                label="undo"
                onClick={handleClearToDefault}>
                <FontAwesomeIcon icon={faUndo} />
              </Button>
            </StyledImageControls>
          </>
        ) : (
          <StyledDropzone
            square={square}
            {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
            <input {...getInputProps()} />
            {defaultValue ? (
              <>
                <StyledDisabledPreviewImage
                  onMouseEnter={() =>
                    setIsPhotoHover(
                      showUploadDescription && showUploadLabel ? true : false
                    )
                  }
                  onMouseLeave={() => setIsPhotoHover(false)}
                  square={square}>
                  <img ref={imageRef} src={imageSource} />
                  {isPhotoHover && (
                    <StyledUploadIcon
                      style={
                        alignOverlay &&
                        imageRef?.current && {
                          width: imageRef?.current?.clientWidth,
                          height: imageRef?.current?.clientHeight,
                          minHeight: 100,
                          display: "flex",
                          height: "100%",
                          width: "100%",
                        }
                      }>
                      <FontAwesomeIcon
                        ref={svgRef}
                        icon={faArrowUpFromBracket}
                        style={
                          alignOverlay
                            ? {
                                width: 24,
                                marginTop:
                                  Math.abs(
                                    imageRef?.current?.clientHeight - 64
                                  ) / 2,
                              }
                            : { width: 24 }
                        }
                      />
                    </StyledUploadIcon>
                  )}
                </StyledDisabledPreviewImage>
              </>
            ) : !newDesign ? (
              <>
                <FontAwesomeIcon icon={customIcon ?? faImage} />
                {showUploadDescription && (
                  <FormattedMessage
                    id="dashboard::imageCropper::uploadDescription"
                    defaultMessage="Click <strong>Upload</strong> or Drag and Drop your image here"
                    values={{
                      strong: (...chunks) => (
                        <strong key="str">{chunks}</strong>
                      ),
                    }}>
                    {(...chunks) => (
                      <p>
                        {chunks.map((chunk, i) => (
                          <Fragment key={i}>{chunk}</Fragment>
                        ))}
                      </p>
                    )}
                  </FormattedMessage>
                )}
                {square && (
                  <FormattedMessage
                    id="dashboard::imageCropper::uploadSquare"
                    defaultMessage="Use a rectangular image for better presentation"
                  />
                )}
                {showUploadLabel && (
                  <Button
                    color="white"
                    label="Upload"
                    style={{ margin: "0 auto" }}>
                    <FormattedMessage
                      id="dashboard::forms::upload"
                      defaultMessage="Upload"
                    />
                  </Button>
                )}
              </>
            ) : (
              <div style={iconStyle} className="upload-icon">
                <FontAwesomeIcon icon={customIcon ?? faImage} />
              </div>
            )}
          </StyledDropzone>
        )}
      </StyledImageSelector>
    )
  }
)
export default ImageSelector
