// Download a Base64-encoded file
export function downloadBase64File(base64Data, filename) {
  let element = document.createElement("a")
  element.setAttribute("href", base64Data)
  element.setAttribute("download", filename)
  element.style.display = "none"
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

// Convert a Base64-encoded string to a File object
export function base64StringtoFile(base64String, filename) {
  let arr = base64String.split(",")
  if (!arr[1]) return
  let mime = arr[0].match(/:(.*?);/)[1],
    bstr = window?.atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}

// Extract an Base64 Image's File Extension
export function extractImageFileExtensionFromBase64(base64Data) {
  return base64Data.substring(
    "data:image/".length,
    base64Data.indexOf(";base64")
  )
}

// Base64 Image to Canvas with a Crop
export function image64toCanvasRef(image64, crop, imgSize) {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas")
    canvas.width = (crop.width * imgSize.width) / 100
    canvas.height = (crop.height * imgSize.height) / 100
    const ctx = canvas.getContext("2d")
    const image = new Image()
    image.crossOrigin = "Anonymous"
    image.src = image64

    image.onload = function () {
      ctx.drawImage(
        image,
        (crop.x * imgSize.width) / 100,
        (crop.y * imgSize.height) / 100,
        canvas.width,
        canvas.height,
        0,
        0,
        canvas.width,
        canvas.height
      )
      resolve(canvas)
    }
  })
}

export const readFile = (file) =>
  new Promise((resolve, reject) => {
    // Initialize FileReader browser API
    const reader = new FileReader()
    // onload callback gets called after the reader reads the file data
    reader.onload = (e) => {
      resolve(e.target.result)
    }
    // Read the file as Data URL (since we accept only images)
    reader.readAsDataURL(file)
  })
