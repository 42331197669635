import styled from "@emotion/styled"

export const StyledMainContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export const StyledLeftContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(p) => p.theme.colors.carrot};
  height: 100%;
  width: 50%;
`

export const StyledRightContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(p) => p.theme.colors.softGreen};
  height: 100%;
  width: 50%;
`

export const StyledLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(p) => p.theme.colors.white};
  border-radius: 0.25rem;

  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
`
