import styled from "@emotion/styled"

export const StyledMediaSlider = styled.div`
  width: 100%;
  .slick-current {
    height: auto;
    padding: 0;
    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }
  }
  .media-box img {
    overflow: hidden;
    object-fit: contain !important;
    width: 100%;
    height: 100%;
    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    }
  }

  .slick-slider {
    overflow: hidden;
    background: ${(p) => p.theme.colors.white};
    border: none;
  }

  .slick-dots {
    bottom: 1rem !important;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      bottom: 1.5rem !important;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      bottom: 2.5rem !important;
    }

    li {
      border: none;
      cursor: pointer;
      height: 0.75rem !important;
      width: 0.75rem !important;
      border-radius: 1rem;

      margin: 0 0.125rem !important;
      background-color: ${(p) => p.theme.colors.white};
      box-shadow: 0 0.25rem 0.75rem 0 ${(p) => p.theme.colors.disabledOpacity};
      opacity: 1;
      button {
        height: 0.125rem !important;
        width: 2.5rem !important;
        border-radius: 0.25rem;
        padding: 0 !important;

        :after {
          content: "";
          position: absolute;
          top: -1.25rem;
          bottom: -1.25rem;
          left: -0.125rem;
          right: -0.125rem;
        }
        &::before {
          display: none;
        }
      }
    }
    .slick-active {
      opacity: 1;
      background-color: ${(p) => p.theme.colors.blue};
    }
  }

  .react-player__preview {
    width: 50%;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
  }
`
