import { gql } from "@apollo/client"
import { cartItemParts } from "@tmu/apollo/storefront/fragments/cart"

export const CREATE_CART_ITEM_MUTATION = gql`
  mutation CreateCartItem($input: CreateCartItemMutationInput!) {
    createCartItem(input: $input) {
      cartItem {
        ...cartItemParts
      }
      errors {
        field
        messages
      }
    }
  }
  ${cartItemParts}
`

export const UPDATE_CART_ITEM_MUTATION = gql`
  mutation UpdateCartItem($input: UpdateCartItemMutationInput!) {
    updateCartItem(input: $input) {
      cartItem {
        ...cartItemParts
      }
      errors {
        field
        messages
      }
    }
  }
  ${cartItemParts}
`

export const REMOVE_CART_ITEM_MUTATION = gql`
  mutation RemoveCartItem($input: DeleteCartItemMutationInput!) {
    deleteCartItem(input: $input) {
      success
      errors {
        messages
        field
      }
    }
  }
`
export const OFFER_ORDER_CHECKOUT_MUTATION = gql`
  mutation OrderCheckOutMutation($input: OrderCheckOutMutationInput!) {
    orderCheckOut(input: $input) {
      order {
        id
        status
        stripeClientSecret
        subtotal
        pacAmount
        total
        cartItems {
          edges {
            node {
              order {
                id
                status
                user {
                  id
                }
              }
              id
            }
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }
`

export const DISCARD_ORDER_CHECKOUT_MUTATION = gql`
  mutation ($input: DiscardOrderMutationInput!) {
    discardOrder(input: $input) {
      order {
        id
        currency
        status
        frozenTotal
      }
      errors {
        field
        messages
      }
    }
  }
`

export const CREATE_ORDER_CHECKOUT_MUTATION = gql`
  mutation ($input: CreateOrderMutationInput!) {
    createOrder(input: $input) {
      order {
        id
        currency
        status
        frozenTotal
        stripeSavePaymentMethodForFuture
        token
        refreshToken
        stripePaymentIntentId
        stripeClientSecret
        stripePaymentMethodId
        internalOrderValues
        onRegisterOrderValues
        campaign {
          id
          name
        }
        donation {
          id
          amount
        }
        cartItems {
          edges {
            node {
              id
              currency
              quantity
              frozenTotal
              productVariation {
                id
                name
                price
                pacDiscount
                stockLeft
                isUnlimitedTickets
                offer {
                  id
                  name
                }
              }
            }
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }
`

export const START_ORDER_PAYMENT_CHECKOUT_MUTATION = gql`
  mutation ($input: StartOrderPaymentMutationInput!) {
    startOrderPayment(input: $input) {
      order {
        id
        currency
        status
        frozenTotal
        stripeSavePaymentMethodForFuture
        token
        refreshToken
        stripePaymentIntentId
        stripeClientSecret
        stripePaymentMethodId
        internalOrderValues
        campaign {
          id
          name
        }
        donation {
          id
          amount
        }
        cartItems {
          edges {
            node {
              id
              currency
              quantity
              frozenTotal
              productVariation {
                id
                name
                price
                pacDiscount
                maxTickets
                isUnlimitedTickets
                isSoldOut
                stockLeft
                offer {
                  id
                  name
                }
              }
            }
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }
`
