import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import { Wrapper } from "./index.styles"

const AccountTypeLabel = ({ type }) => {
  return (
    <Wrapper type={type} data-testid="account-type">
      {type === "PERSONAL" && (
        <FormattedMessage
          id="account::type::personal"
          defaultMessage="Personal"
        />
      )}
      {type === "BUSINESS" && (
        <FormattedMessage
          id="account::type::business"
          defaultMessage="Business"
        />
      )}
      {type === "NGO" && (
        <FormattedMessage id="account::type::ngo" defaultMessage="NGO" />
      )}
    </Wrapper>
  )
}

export default AccountTypeLabel
