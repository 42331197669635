import { gql, useLazyQuery } from "@apollo/client"
import { useApolloApiClients } from "@tmu/apollo/client"

const useReferral = (options) => {
  const { storefrontClient } = useApolloApiClients()

  const query = gql`
    query Referral($code: String!) {
      referral(code: $code) {
        id
        code
        validUntil
      }
    }
  `

  const [callReferral, { loading, data }] = useLazyQuery(query, {
    ...options,
    client: storefrontClient,
  })

  return {
    callReferral,
  }
}

export default useReferral
