export const merchantParts = `
  fragment merchantParts on MerchantStoreNode {
    id
    name
    displayName
    description
    shortDescription
    descriptionEn
    shortDescriptionEn
    descriptionEs
    shortDescriptionEs
    descriptionIt
    shortDescriptionIt
    country
    email
    communicationLanguage
    maxPacDiscount
    terms
    termsEn
    termsIt
    termsEn
    paymentDestinations {
      accountName
      bankName
      iban
      country
      sortCode
      accountNumber
      bic
    }
  }
`
export const merchantUserParts = `
  fragment merchantUserParts on MerchantUserNode {
    id
    isActive
    created
    modified
    email
    fullName
    store {
      id
      name
    }
    apiRole {
      id
      name
      codename
      accessType
    }
    user {
      id
      email
      displayName
      fullName
    }
  }
`
