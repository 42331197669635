import format from "date-fns/format"
import parseISO from "date-fns/parseISO"
import startOfMonth from "date-fns/startOfMonth"
import endOfMonth from "date-fns/endOfMonth"
import add from "date-fns/add"
import isValid from "date-fns/isValid"
import differenceInDays from "date-fns/differenceInDays"
import differenceInHours from "date-fns/differenceInHours"
import differenceInCalendarDays from "date-fns/differenceInCalendarDays"
import { differenceInMonths, differenceInYears } from "date-fns"

const getCustomDifferance = (now, pastDate, formatMessage) => {
  const years = differenceInYears(now, pastDate)

  if (years > 0) {
    if (years === 1) {
      return `< ${years} ${formatMessage({
        id: "donors::modal::year",
        defaultMessage: "year",
      })}`
    }
    return `< ${years} ${formatMessage({
      id: "donors::modal::years",
      defaultMessage: "years",
    })}`
  } else {
    const months = differenceInMonths(now, pastDate)
    if (months === 1) {
      return `< ${months} ${formatMessage({
        id: "donors::modal::month",
        defaultMessage: "month",
      })}`
    }
    if (months > 1) {
      return `< ${months} ${formatMessage({
        id: "donors::modal::months",
        defaultMessage: "months",
      })}`
    } else {
      const days = differenceInCalendarDays(now, pastDate)
      if (days === 0) {
        return `${formatMessage({
          id: "donors::modal::today",
          defaultMessage: "today",
        })}`
      }
      if (days === 1) {
        return `${formatMessage({
          id: "donors::modal::yesterday",
          defaultMessage: "yesterday",
        })}`
      }
      if (days > 1) {
        return `${days} ${formatMessage({
          id: "donors::modal::daysAgo",
          defaultMessage: "days ago",
        })}`
      }
    }
  }
}

const formatDate = (date, dateFormat = "dd/MM/yyyy") => {
  if (!date) return
  return format(
    isValid(date)
      ? date
      : isValid(new Date(date))
      ? new Date(date)
      : isValid(parseISO(date))
      ? parseISO(date)
      : "",
    dateFormat
  )
}
const todaysDate = formatDate(new Date())

export {
  endOfMonth,
  add as addDate,
  startOfMonth,
  formatDate,
  todaysDate,
  parseISO,
  isValid,
  differenceInDays,
  differenceInCalendarDays,
  differenceInHours,
  getCustomDifferance,
}
