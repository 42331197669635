import styled from "@emotion/styled"

export const Wrapper = styled.div`
  padding: 0.3125rem 1rem;
  margin-left: 0.9375rem;
  border-radius: 6.25rem;
  background: ${(p) =>
    p.type === "PERSONAL" ? p.theme.colors.pacificBlue : p.theme.colors.red};
  color: ${(p) => p.theme.colors.white};
  font-size: 0.75rem;
  line-height: 0.875rem;
`
