import React, { createContext, useState } from "react"
import { FOOTER_TYPE } from "@tmu/apollo/constants"

export const HomePageContext = createContext()

export const HomePageContextProvider = ({ children }) => {
  const [globalPacBalance, setGlobalPacBalance] = useState(0)
  const [footerType, setFooterType] = useState(FOOTER_TYPE.TIGHT)
  const [headerConfigData, setHeaderConfigData] = useState({})
  const [intercomStatus, setIntercomStatus] = useState({ hide: false })
  const [defaultOfferType, setDefaultOfferType] = useState()
  const [globalSpinnerStatus, setGlobalSpinnerStatus] = useState(false)
  const [menuConfig, setMenuConfig] = useState({
    hideOnDashboard: false,
    forceOverlay: false,
  })
  return (
    <HomePageContext.Provider
      value={{
        globalPacBalance,
        setGlobalPacBalance,
        footerType,
        setFooterType,
        headerConfigData,
        setHeaderConfigData,
        intercomStatus,
        setIntercomStatus,
        menuConfig,
        setMenuConfig,
        defaultOfferType,
        setDefaultOfferType,
        globalSpinnerStatus,
        setGlobalSpinnerStatus,
      }}>
      {children}
    </HomePageContext.Provider>
  )
}
