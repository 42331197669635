import React from "react"
import { FormattedMessage, navigate } from "gatsby-plugin-intl"
import { faHandHoldingHeart } from "@fortawesome/pro-solid-svg-icons/faHandHoldingHeart"
import { faStore } from "@fortawesome/pro-solid-svg-icons/faStore"
import { faGift } from "@fortawesome/pro-solid-svg-icons/faGift"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, LazyImage } from "@tmu/components/common"
import {
  StyledWwsTabContent,
  StyledWwsTabContentRight,
  StyledWwsTabContentLeft,
} from "./index.styles"

const { CLOUDFLARE_IMAGE_URL } = process.env

export const StartHere = ({ userType }) => {
  const handleClick = () => {
    const target =
      userType === "partner"
        ? "non-profit"
        : userType === "donor"
        ? "donors"
        : "merchants"
    navigate(`/join/${target}`)
  }

  switch (userType) {
    case "donor":
      return (
        <StyledWwsTabContent
          id="donor"
          className="tabcontent"
          data-testid="start-here-donor">
          <StyledWwsTabContentLeft>
            <p className="wws-tab-content__icon">
              <FontAwesomeIcon icon={faHandHoldingHeart} />
            </p>
            <p className="wws-tab-content__title">
              <FormattedMessage
                id="whoWeServe::donors::tabTitle"
                defaultMessage="Donors"
              />
            </p>
            <div className="wws-tab-content__text">
              <FormattedMessage
                id="whoWeServe::donors::tabContent"
                defaultMessage="With TrustMeUp, donors get a unique possibility to choose a distinct non-profit cause to support."
              />
            </div>
            <Button
              className="wws-tab-content__button"
              label="Start Donating"
              color="red"
              onClick={handleClick}>
              <FormattedMessage
                id="whoWeServe::donors::buttonText"
                defaultMessage="Start Donating"
              />
            </Button>
          </StyledWwsTabContentLeft>
          <StyledWwsTabContentRight>
            <div className="wws-tab-content__image--big">
              <LazyImage
                src={
                  CLOUDFLARE_IMAGE_URL +
                  "/static/assets/images/users/blue-team.jpg"
                }
                altName={`start-here-blue-team`}
                width="312"
                height="227"
                fit="cover"
              />
            </div>
            <div className="wws-tab-content__image--small">
              <LazyImage
                src={
                  CLOUDFLARE_IMAGE_URL +
                  "/static/assets/images/users/donors-square.jpg"
                }
                altName={`start-here-donors-square`}
                width="170"
                height="156"
                fit="cover"
              />
            </div>

            <p className="wws-tab-content__image--text">
              <FormattedMessage
                id="whoWeServe::donors::tabImageText"
                defaultMessage="Get rewarded for doing good as never before."
              />
            </p>
            <div className="wws-tab-content__image--medium">
              <LazyImage
                src={
                  CLOUDFLARE_IMAGE_URL +
                  "/static/assets/images/users/heart-in-hands.jpg"
                }
                altName={`start-here-heart-in-hands`}
                width="255"
                height="156"
                fit="cover"
              />
            </div>
          </StyledWwsTabContentRight>
        </StyledWwsTabContent>
      )
    case "partner":
      return (
        <StyledWwsTabContent
          id="partner"
          className="tabcontent"
          data-testid="start-here-partner">
          <StyledWwsTabContentLeft>
            <p className="wws-tab-content__icon">
              <FontAwesomeIcon icon={faGift} />
            </p>
            <p className="wws-tab-content__title">
              <FormattedMessage
                id="whoWeServe::nonProfits::tabTitle"
                defaultMessage="Non-Profit"
              />
            </p>
            <div className="wws-tab-content__text">
              <FormattedMessage
                id="whoWeServe::nonProfits::tabContent"
                defaultMessage="Through the TrustMeUp web platform non-profit organizations can promote one or more of their projects and create the dedicated fundraising accounts."
              />
            </div>
            <Button
              className="wws-tab-content__button"
              label="Start Fundraising"
              color="red"
              onClick={handleClick}>
              <FormattedMessage
                id="whoWeServe::nonProfits::buttonText"
                defaultMessage="Start Fundraising"
              />
            </Button>
          </StyledWwsTabContentLeft>
          <StyledWwsTabContentRight>
            <div className="wws-tab-content__image--big">
              <LazyImage
                src={
                  CLOUDFLARE_IMAGE_URL +
                  "/static/assets/images/users/non-profit-landscape-medium.jpg"
                }
                altName={`start-here-blue-team`}
                width="312"
                height="227"
                fit="cover"
              />
            </div>
            <div className="wws-tab-content__image--small">
              <LazyImage
                src={
                  CLOUDFLARE_IMAGE_URL +
                  "/static/assets/images/users/soccer-team.jpg"
                }
                altName={`start-here-soccer-team`}
                width="170"
                height="156"
                fit="cover"
              />
            </div>
            <p className="wws-tab-content__image--text">
              <FormattedMessage
                id="whoWeServe::nonProfits::tabImageText"
                defaultMessage="TrustMeUp rewards the supporters and increases their feeling of trust and belonging, resulted from the donations transparency and tangibility."
              />
            </p>
            <div className="wws-tab-content__image--medium">
              <LazyImage
                src={
                  CLOUDFLARE_IMAGE_URL +
                  "/static/assets/images/users/non-profit-landscape-small.jpg"
                }
                altName={`start-here-non-profit-landscape-small`}
                width="255"
                height="156"
                fit="cover"
              />
            </div>
          </StyledWwsTabContentRight>
        </StyledWwsTabContent>
      )
    case "merchant":
      return (
        <StyledWwsTabContent
          id="merchant"
          data-testid="start-here-merchant"
          className="tabcontent"
          name="start-here-merchant">
          <StyledWwsTabContentLeft>
            <p className="wws-tab-content__icon">
              <FontAwesomeIcon icon={faStore} />
            </p>
            <p className="wws-tab-content__title">
              <FormattedMessage
                id="whoWeServe::merchants::tabTitle"
                defaultMessage="Merchants"
              />
            </p>
            <div className="wws-tab-content__text">
              <FormattedMessage
                id="whoWeServe::merchants::tabContent"
                defaultMessage="TrustMeUp web platform gives access to the powerful advertising engine with the possibility to showcase unique deals to the supporters who actively participate in social communities."
              />
            </div>
            <Button
              className="wws-tab-content__button"
              color="red"
              label="Start Selling"
              onClick={handleClick}>
              <FormattedMessage
                id="whoWeServe::merchants::buttonText"
                defaultMessage="Start Selling"
              />
            </Button>
          </StyledWwsTabContentLeft>
          <StyledWwsTabContentRight>
            <div className="wws-tab-content__image--big">
              <LazyImage
                src={
                  CLOUDFLARE_IMAGE_URL +
                  "/static/assets/images/users/phone-store.jpg"
                }
                altName={`start-here-phone-store`}
                width="312"
                height="227"
                fit="cover"
              />
            </div>
            <div className="wws-tab-content__image--small">
              <LazyImage
                src={
                  CLOUDFLARE_IMAGE_URL +
                  "/static/assets/images/users/tech-store.jpg"
                }
                altName={`start-here-tech-store`}
                width="170"
                height="156"
                fit="cover"
              />
            </div>
            <p className="wws-tab-content__image--text">
              <FormattedMessage
                id="whoWeServe::merchants::tabImageText"
                defaultMessage="Boost your business doing good thanks to the TrustMeUp marketplace."
              />
            </p>
            <div className="wws-tab-content__image--medium">
              <LazyImage
                src={
                  CLOUDFLARE_IMAGE_URL +
                  "/static/assets/images/users/tech-store.jpg"
                }
                altName={`start-here-tech-store`}
                width="255"
                height="156"
                fit="cover"
              />
            </div>
          </StyledWwsTabContentRight>
        </StyledWwsTabContent>
      )
    case "merchantStore":
      return (
        <StyledWwsTabContent
          id="merchant"
          data-testid="start-here-merchant-store"
          className="tabcontent">
          <StyledWwsTabContentLeft>
            <p className="wws-tab-content__icon">
              <FontAwesomeIcon icon={faStore} />
            </p>
            <p className="wws-tab-content__title">
              <FormattedMessage
                id="whoWeServe::merchantStore::tabTitle"
                defaultMessage="Creating a New Store? Start Here."
              />
            </p>
            <div className="wws-tab-content__text">
              <FormattedMessage
                id="whoWeServe::merchantStore::tabContent"
                defaultMessage="With TrustMeUp, donors get a unique possibility of influencing the decisions of the organizations they support."
              />
            </div>
            <Button
              className="wws-tab-content__button"
              color="red"
              label="Get Started"
              onClick={handleClick}>
              <FormattedMessage
                id="whoWeServe::merchantStore::buttonText"
                defaultMessage="Get Started"
              />
            </Button>
          </StyledWwsTabContentLeft>
          <StyledWwsTabContentRight>
            <div className="wws-tab-content__image--big">
              <LazyImage
                src={
                  CLOUDFLARE_IMAGE_URL +
                  "/static/assets/images/users/netflix.png"
                }
                altName={`start-here-netflix`}
                width="312"
                height="227"
                fit="cover"
              />
            </div>
            <div className="wws-tab-content__image--small">
              <LazyImage
                src={
                  CLOUDFLARE_IMAGE_URL + "/static/assets/images/users/nike.png"
                }
                altName={`start-here-nike`}
                width="170"
                height="156"
                fit="cover"
              />
            </div>
            <p className="wws-tab-content__image--text">
              <FormattedMessage
                id="whoWeServe::merchantStore::tabImageText"
                defaultMessage="Boost your business thanks to the TrustMeUp marketplace."
              />
            </p>
            <div className="wws-tab-content__image--medium">
              <LazyImage
                src={
                  CLOUDFLARE_IMAGE_URL + "/static/assets/images/users/ikea.png"
                }
                altName={`start-here-ikea`}
                width="255"
                height="156"
                fit="cover"
              />
            </div>
          </StyledWwsTabContentRight>
        </StyledWwsTabContent>
      )
    default:
      break
  }
}
