import React from "react"
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl"
import { navigate } from "gatsby-plugin-intl"
import { Button, LazyImage } from "@tmu/components/common"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import SEO from "@tmu/components/seo"
import {
  StyledButtons,
  StyledCommonNotFoundPage,
} from "@tmu/global/page-addons/flat-page.styles"

const { CLOUDFLARE_IMAGE_URL } = process.env

const NotFound = ({ intl }) => {
  const { isTablet, isDesktop, isWide } = getAllScreenTypes()

  const imageSize = {
    width: isWide ? 240 : isDesktop ? 126 : isTablet ? 222 : 130,
    height: isWide ? 458 : isDesktop ? 240 : isTablet ? 422 : 248,
  }

  return (
    <>
      <SEO lang={intl.locale} title="404" />
      <StyledCommonNotFoundPage data-testid="page-not-found">
        <LazyImage
          altName="donation-success"
          src={CLOUDFLARE_IMAGE_URL + "/static/assets/images/common_error.png"}
          {...imageSize}
          fit="cover"
        />
        <FormattedMessage
          id="notfound::wrong"
          defaultMessage="Something went wrong ..."
          tagName="h4"
        />
        <FormattedMessage
          id="notfound::refresh"
          defaultMessage="Refresh the page or come back later"
          tagName="span"
        />
        <div className={!isTablet ? "sticky-bottom-bar" : ""}>
          <StyledButtons>
            <Button
              label="EXPLORE STORES"
              variant="outlined"
              color="transparent"
              onClick={() => {
                navigate("/offers?store=true")
              }}>
              <FormattedMessage
                id="campaign::donationSuccess::exploreStores"
                defaultMessage="EXPLORE STORES"
              />
            </Button>
            <Button
              label="EXPLORE CHARITIES"
              variant="outlined"
              color="blue"
              onClick={() => {
                navigate("/campaigns?charity=true")
              }}>
              <FormattedMessage
                id="campaign::donationSuccess::exploreCharities"
                defaultMessage="EXPLORE CHARITIES"
              />
            </Button>
          </StyledButtons>
        </div>
      </StyledCommonNotFoundPage>
    </>
  )
}

export default injectIntl(NotFound)
