import { gql } from "@apollo/client"
import { pacTransactionParts } from "../fragments/transaction"

export const MERCHANT_PAC_TRANSACTIONS_QUERY = gql`
  query MerchantTransactionListing(
    $first: Int
    $after: String
    $reason_In: [String]
  ) {
    allPacTransactions(
      first: $first
      after: $after
      reason_In: $reason_In
      orderBy: "-created"
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          ...pacTransactionParts
          order {
            id
          }
        }
      }
    }
    transactionDirections: __type(
      name: "MoneyPacTransactionTransactionDirectionChoices"
    ) {
      enumValues {
        description
        name
      }
    }
  }

  ${pacTransactionParts}
`

export const MERCHANT_OFFER_LISTING_QUERY = gql`
  query MerchantOfferListing($first: Int, $after: String) {
    allOffers(first: $first, after: $after, orderBy: "-created") {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          name
          created
          modified
          slug
          image
          website
          overview
          description
          shortDescription
          disclaimer
          isAvailable
          isFeatured
          isDigital
          isUnlisted
          visibilityStatus
          currency
          maxPacDiscount
          status
          reviewStatus
          isDefaultOffer
          country
          city
          postCode
          addressLine1
          location
          store {
            id
            name
            displayName
            maxPacDiscount
            maxPacDiscountInOffers
            defaultOffer {
              offerType
            }
          }
        }
      }
    }
  }
`

export const MERCHANT_HERO_LISTING_QUERY = gql`
  query MerchantHeroListing($first: Int, $after: String) {
    allHeroItems(first: $first, after: $after) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          isActive
          title
          titleEn
          titleIt
          titleEs
          detail
          detailEn
          detailIt
          detailEs
          image
          page
          buttonText
          buttonTextEn
          buttonTextIt
          buttonTextEs
          buttonLink
          buttonLinkEn
          buttonLinkIt
          buttonLinkEs
          addOtpToButtonLink
          ordering
          id
        }
      }
    }
  }
`

export const MERCHANT_ORDER_LISTING_QUERY = gql`
  query MerchantOrderListing(
    $first: Int
    $after: String
    $status_In: [String]
    $search: String
    $created_Lte: DateTime
    $created_Gte: DateTime
  ) {
    allOrders(
      first: $first
      after: $after
      status_In: $status_In
      orderBy: "-created"
      search: $search
      created_Lte: $created_Lte
      created_Gte: $created_Gte
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          status
          created
          modified
          checkoutAt
          finalizedAt
          pacAmount
          total
          subtotal
          user {
            displayName
          }
          campaign {
            name
            nameEn
            nameIt
            nameEs
          }
          frozenTotal
          frozenSubtotal
          store {
            name
          }
          cartItems {
            edges {
              node {
                id
                name
                quantity
                status
                frozenPrice
                frozenTotal
                frozenPacAmount
                offer {
                  id
                  name
                  image
                  minPrice
                }
              }
            }
          }
        }
      }
    }
  }
`

export const MERCHANT_USER_LISTING_QUERY = gql`
  query MerchantUserListing(
    $first: Int
    $after: String
    $isActive: Boolean
    $store: ID
  ) {
    allMerchantUsers(
      first: $first
      after: $after
      isActive: $isActive
      store: $store
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          fullName
          email
          created
          isActive
          user {
            id
            email
          }
          apiRole {
            id
            codename
            name
            accessType
          }
        }
      }
    }
  }
`

export const SEARCH_MERCHANT_USER_QUERY = gql`
  query SearchUser($email: String!) {
    user(email: $email) {
      id
      email
    }
  }
`

export const MERCHANT_REFERRAL_LIST = gql`
  query MerchantReferralList($first: Int, $after: String) {
    allReferrals(first: $first, after: $after) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          code
          email
          validUntil
          escalation
          hours
        }
      }
    }
  }
`

export const DASHBOARD_MERCHANT_HERO_DETAIL_QUERY = gql`
  query heroItem($id: ID!) {
    heroItem(id: $id) {
      isActive
      title
      titleEn
      titleIt
      titleEs
      detail
      detailEn
      detailIt
      detailEs
      image
      page
      buttonText
      buttonTextEn
      buttonTextIt
      buttonTextEs
      buttonLink
      buttonLinkEn
      buttonLinkIt
      buttonLinkEs
      buttonEnabled
      addOtpToButtonLink
      ordering
      id
    }
  }
`

export const MERCHANT_ALL_ASSETS_LISTING_QUERY = gql`
  query MerchantAllAssetsListing(
    $first: Int
    $language: String
    $search: String
    $orderBy: String
  ) {
    allAssets(
      first: $first
      language: $language
      search: $search
      orderBy: $orderBy
    ) {
      edges {
        node {
          id
          name
          asset
          assetUrl
          description
          directory
          mimetype
          size
          language
          store {
            id
            name
          }
          charity {
            id
            name
          }
        }
      }
    }
  }
`

export const MERCHANT_STORE_REPRESENTATIVE_USERS = gql`
  query MerchantStoreRepresentativeUsers($first: Int, $orderBy: String) {
    allMerchantStoreRepresentativeUsers(first: $first, orderBy: $orderBy) {
      edges {
        node {
          id
          store {
            id
            name
          }
          representativeUser {
            id
            fiscalCode
            firstName
            lastName
            address
            email
            phoneNumber
            attributes
          }
          representativeType
        }
      }
    }
  }
`
