import styled from "@emotion/styled"

export const StyledMainContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column-reverse;

  margin: 0 auto;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    max-width: ${(p) => p.theme.breakpoints.tablet};
    padding: 0 1.5rem;
    margin-top: 6rem;
    height: 17rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    max-width: ${(p) => p.theme.breakpoints.desktop};
    padding: 0 2rem;
    height: 26rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    max-width: ${(p) => p.theme.breakpoints.wide};
    padding: 0 6rem;
    margin-top: 7rem;
    height: 29rem;
  }
`

export const StyledLeftContainer = styled.div`
  z-index: 2;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    width: 75%;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    width: 65%;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    width: 50%;
  }
`

export const StyledLeftTitle = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.white};

  font-size: 1.5rem;
  line-height: 2rem;

  position: absolute;
  top: 50vw;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20.5rem;
  text-align: center;
  padding: 0 0.9375rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 2rem;
    line-height: 2.625rem;
    margin-bottom: 1.5rem;

    color: ${(p) => p.theme.colors.shareButtonColorOneOpacity};

    position: unset;
    top: unset;
    left: unset;
    z-index: unset;
    transform: unset;
    width: unset;
    text-align: unset;
    padding: unset;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 3rem;
    line-height: 4.0625rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
  }
`

export const StyledLeftContent = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.pitchBlack};

  font-size: 1.125rem;
  line-height: 1.5rem;
  text-align: center;

  margin: 2rem 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding-bottom: 3rem;
    text-align: unset;
    margin: unset;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
    line-height: 2.625rem;
  }
`

export const StyledRightContainer = styled.div`
  position: relative;
  .media-box {
    width: 100vw;
    height: auto;

    img {
      width: 100%;
      height: auto;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    height: 23rem;
    position: absolute;
    right: 0;
    margin-top: -6rem;
    width: 60%;
    -webkit-mask-image: linear-gradient(to right, transparent 0%, black 50%);

    .media-box {
      width: auto;
      height: 23rem;

      img {
        width: auto;
        height: 23rem;
      }
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin-right: -0.6rem;
    height: 32rem;

    .media-box {
      width: auto;
      height: 32rem;

      img {
        width: auto;
        height: 32rem;
      }
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin-right: unset;
    margin-top: -7rem;
    height: 36.0625rem;

    .media-box {
      width: auto;
      height: 36.0625rem;

      img {
        width: auto;
        height: 36.0625rem;
      }
    }
  }
  }

  .image-linear-gradient {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.4);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    height: 100%;
  }
`
