import React from "react"
import PropTypes from "prop-types"
import { Button, LazyImage } from "@tmu/components/common"

import {
  StyledCardWrapper,
  StyledCardType,
  StyledCardContent,
} from "./index.styles"

const CampaignTypeCard = ({
  imageSrc,
  imageSize,
  type,
  content,
  buttonText,
  buttonColor,
  buttonAction,
  ...rest
}) =>
  imageSrc || type || content || buttonText || buttonAction ? (
    <StyledCardWrapper {...rest}>
      {imageSrc && (
        <LazyImage
          src={imageSrc}
          altName={`balloons-banner`}
          {...imageSize}
          fit="cover"
        />
      )}
      {type && <StyledCardType>{type}</StyledCardType>}
      {content && <StyledCardContent>{content}</StyledCardContent>}
      {buttonText && (
        <Button
          color={buttonColor || "blue"}
          onClick={() => {
            if (typeof buttonAction === "function") {
              buttonAction()
            }
          }}
          data-testid={`btn-${buttonText}`}>
          {buttonText}
        </Button>
      )}
    </StyledCardWrapper>
  ) : (
    <></>
  )

CampaignTypeCard.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  imageSize: PropTypes.object,
  type: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonColor: PropTypes.string,
  buttonAction: PropTypes.func.isRequired,
}

export default CampaignTypeCard
