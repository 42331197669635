import React from "react"
import { FormattedMessage, navigate } from "gatsby-plugin-intl"
import { LazyImage } from "@tmu/components/common"
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons/faChevronRight"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import {
  StyledSlice,
  StyledDescription,
  StyledTitle,
  StyledStoryBox,
  StyledText,
  StyledLink,
  StyledImage,
} from "./index.styles"

const { CLOUDFLARE_IMAGE_URL } = process.env

const SliceCharityFriendlyBrand = () => {
  const { isTablet, isDesktop, isWide } = getAllScreenTypes()

  const imgSize = {
    width: isWide ? 301 : isDesktop ? 259 : isTablet ? 211 : 96,
    height: isWide ? 302 : isDesktop ? 260 : isTablet ? 212 : 96,
  }

  return (
    <StyledSlice
      data-testid="slice-charity-friendly-brand"
      id="charity-friendly-brand">
      <StyledStoryBox className="row-two">
        <StyledImage className={!isTablet ? "right-to-left" : "left-to-right"}>
          <LazyImage
            altName="join as corporate"
            src={
              CLOUDFLARE_IMAGE_URL +
              "/static/assets/images/corporate/social_Impact.svg"
            }
            {...imgSize}
            fit="cover"
          />
        </StyledImage>
        <StyledText className="right">
          <StyledTitle>
            <FormattedMessage
              id="charityFriendlyBrand::sliceFriendlyBrand::title"
              defaultMessage={`Become a "Social Impact Company"`}
            />
          </StyledTitle>
          <StyledDescription>
            <FormattedMessage
              id="charityFriendlyBrand::sliceFriendlyBrand::description"
              defaultMessage="Fundraise to support your favorite charity and promote your brand"
            />
          </StyledDescription>
          <StyledLink onClick={() => navigate("/join/corporate")}>
            <FormattedMessage
              id="join::corporate::pageTitle"
              defaultMessage="Join as a Corporate"
            />
            <FontAwesomeIcon icon={faChevronRight} />
          </StyledLink>
        </StyledText>
      </StyledStoryBox>
    </StyledSlice>
  )
}

export default SliceCharityFriendlyBrand
