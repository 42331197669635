import { useEffect } from "react"
import { useLazyQuery, gql } from "@apollo/client"
import { useApolloApiClients } from "@tmu/apollo/client"

const useCampaignTypes = ({ ...rest }) => {
  const { storefrontClient } = useApolloApiClients()

  const [callTypeConstants, { data, loading, called }] = useLazyQuery(
    gql`
      query TypeConstants {
        campaignTypes: __type(name: "CampaignsCampaignCampaignTypeChoices") {
          enumValues {
            description
            name
          }
        }
      }
    `,
    {
      client: storefrontClient,
      variables: { isPublic: true },
      ...rest,
    }
  )

  useEffect(() => {
    if (rest === null || rest?.callImmediately !== false) {
      if (!called) {
        callTypeConstants()
      }
    }
  }, [])

  const campaignTypes = data?.campaignTypes
    ? data.campaignTypes.enumValues.map(({ name, description }) => ({
        id: name,
        description,
      }))
    : []

  return {
    campaignTypes,
    callTypeConstants,
  }
}

export default useCampaignTypes
