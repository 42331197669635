import React, { useEffect } from "react"
import { useIntl, navigate } from "gatsby-plugin-intl"
import { useLazyQuery } from "@apollo/client"
import { useAuth } from "@tmu/hooks"
import { useApolloApiClients } from "@tmu/apollo/client"
import WelcomeSlice from "@tmu/components/userspace/WelcomeSlice"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import {
  QUERY_USERSPACE_IMAGES,
  QUERY_USERSPACE_CTA,
} from "@tmu/apollo/storefront/queries/userspace"
import {
  StyledWelcomeSliceWrapper,
  StyledQuoteAndPerson,
  StyledWSTitle,
  StyledContent,
  StyledWSButton,
  StyledWSButtonIcon,
} from "./index.styles"

const { API_STRAPI_BASE_ENDPOINT } = process.env
const { CLOUDFLARE_IMAGE_URL } = process.env

const USWelcome = () => {
  const { strapiClient } = useApolloApiClients()
  const { isLoading, user } = useAuth()
  const { locale, formatMessage } = useIntl()

  const [callUserSpaceBtns, { data: ctaData, called: ctaCalled }] =
    useLazyQuery(QUERY_USERSPACE_CTA, {
      client: strapiClient,
      variables: { locale },
    })

  const [callUserspaceImages, { data: imagesData, called: imagesCalled }] =
    useLazyQuery(QUERY_USERSPACE_IMAGES, {
      client: strapiClient,
      variables: { locale },
    })

  useEffect(() => {
    if (isLoading) {
      return
    }

    if (!ctaCalled && !imagesCalled) {
      callUserSpaceBtns()
      callUserspaceImages()
    }
  }, [isLoading])

  const images = imagesData?.userSpaceImages?.data?.map((item) => ({
    image: item?.attributes?.image?.data?.[0]?.attributes?.url
      ? API_STRAPI_BASE_ENDPOINT +
        item?.attributes?.image?.data?.[0].attributes?.url
      : "",
    description: (
      <StyledQuoteAndPerson>
        <p>{item?.attributes?.quote}</p>
        <p>{item?.attributes?.person}</p>
      </StyledQuoteAndPerson>
    ),
  })) || [
    {
      image: CLOUDFLARE_IMAGE_URL + "/static/assets/images/userspace1.jpg",
      description: (
        <StyledQuoteAndPerson>
          <p>
            "
            {formatMessage({
              id: "home::userSpace::quote1",
              defaultMessage:
                "The measure of a well-spent life does not lie in how long it lasted but in how much it gave itself.",
            })}
            "
          </p>
          <p>{"— Peter Marshall"}</p>
        </StyledQuoteAndPerson>
      ),
    },
    {
      image: CLOUDFLARE_IMAGE_URL + "/static/assets/images/userspace2.jpg",
      description: (
        <StyledQuoteAndPerson>
          <p className="black">
            "
            {formatMessage({
              id: "home::userSpace::quote2",
              defaultMessage:
                "Among other benefits, donating frees the donor's soul.",
            })}
            "
          </p>
          <p>{"— Maya Angelou"}</p>
        </StyledQuoteAndPerson>
      ),
    },
    {
      image: CLOUDFLARE_IMAGE_URL + "/static/assets/images/userspace3.jpg",
      description: (
        <StyledQuoteAndPerson>
          <p>
            "
            {formatMessage({
              id: "home::userSpace::quote3",
              defaultMessage:
                "The value of a person lies in what he is capable of giving and not in what he is capable of taking.",
            })}
            "
          </p>
          <p>{"— Albert Einstein"}</p>
        </StyledQuoteAndPerson>
      ),
    },
  ]
  const title = formatMessage(
    {
      id: "home::userSpace::title",
      defaultMessage: `Welcome, {displayName}!`,
    },
    {
      displayName: user?.displayName,
    }
  )

  const { isTablet, isDesktop, isWide } = getAllScreenTypes()

  const imageSize = {
    imageWidth: isWide ? 856 : isDesktop ? 471 : isTablet ? 352 : 360,
    imageHeight: isWide ? 641 : isDesktop ? 328 : isTablet ? 245 : 251,
  }

  const handleClick = (e) => {
    navigate(ctaData?.userSpaceBtns?.data?.attributes?.buttonLink)
  }

  return (
    <StyledWelcomeSliceWrapper>
      <WelcomeSlice
        className="welcome-user-space"
        title={<StyledWSTitle>{title}</StyledWSTitle>}
        content={
          <StyledContent>
            {formatMessage({
              id: "home::userSpace::content",
              defaultMessage:
                "Now you can bring change by starting your fundraising campaign to support charity of your choice! Doing good is easy, try it.",
            })}
          </StyledContent>
        }
        buttonIcon={
          ctaData?.userSpaceBtns?.data?.[0]?.attributes?.buttonIcon ||
          "fas fa-hand-holding-heart"
        }
        buttonLabel={
          ctaData?.userSpaceBtns?.data?.[0]?.attributes?.buttonLabel ||
          formatMessage({
            id: "home::userSpace::buttonLabel",
            defaultMessage: "Choose a Charity",
          })
        }
        buttonColor={
          ctaData?.userSpaceBtns?.data?.[0]?.attributes?.buttonColor || "blue"
        }
        buttonLink={
          ctaData?.userSpaceBtns?.data?.[0]?.attributes?.buttonLink ||
          "/campaigns?campaign=false&charity=true&event=false"
        }
        images={images}
        imageSize={imageSize}
      />
      {!isTablet &&
        ctaData?.userSpaceCta?.data?.attributes?.buttonLabel &&
        ctaData?.userSpaceCta?.data?.attributes?.buttonLink && (
          <StyledWSButton
            data-testid={`btn-${ctaData?.userSpaceCta?.data?.attributes?.buttonLabel}`}
            label={ctaData?.userSpaceCta?.data?.attributes?.buttonLabel}
            color={"blue"}
            onClick={handleClick}>
            <StyledWSButtonIcon
              className={ctaData?.userSpaceCta?.data?.attributes?.buttonIcon}
            />
            {ctaData?.userSpaceCta?.data?.attributes?.buttonLabel}
          </StyledWSButton>
        )}
    </StyledWelcomeSliceWrapper>
  )
}

export default USWelcome
