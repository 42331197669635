import React from "react"
import PropTypes from "prop-types"
import { RadioInput } from "@tmu/components/common"

const RadioSelect = ({ className, items, defaultValue, onChange, ...rest }) => (
  <fieldset className={className} disabled={rest?.disabled}>
    {items.map(
      (
        {
          id,
          label,
          labelHtml,
          rawHtml,
          description,
          isPassive,
          onClick,
          isSoldOut = false,
        },
        index
      ) => (
        <RadioInput
          {...rest}
          key={id}
          id={id}
          name={id}
          label={label}
          labelHtml={labelHtml}
          rawHtml={rawHtml}
          description={description}
          isChecked={defaultValue === id}
          onChange={onChange}
          index={index}
          isPassive={isPassive}
          onClick={onClick}
          isSoldOut={isSoldOut}
        />
      )
    )}
  </fieldset>
)

RadioSelect.defaultProps = {
  defaultValue: "",
  onChange: () => {},
}

export default RadioSelect
