import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { StyledPACWalletTableHeader } from "./index.styles"

const PACWalletTableHeader = () => {
  const { isTablet } = getAllScreenTypes()

  return (
    <StyledPACWalletTableHeader>
      <div data-testid="text-header-transactions" className="caption">
        <FormattedMessage
          id="dashboard::ngo::tableHeader::transactions"
          defaultMessage="Transactions"
        />
      </div>
      <div data-testid="text-header-amount" className="spending-amount caption">
        <FormattedMessage
          id="dashboard::ngo::tableHeader::donationSpending"
          defaultMessage="Amount"
        />
      </div>
      {isTablet && (
        <div data-testid="text-header-date" className="caption">
          <FormattedMessage
            id="dashboard::ngo::tableHeader::dateTime"
            defaultMessage="Date/Time"
          />
        </div>
      )}
      <div data-testid="text-header-pacs" className="pacs-amount caption">
        <FormattedMessage
          id="dashboard::ngo::tableHeader::pacs"
          defaultMessage="PACs Amount"
        />
      </div>
    </StyledPACWalletTableHeader>
  )
}

export default PACWalletTableHeader
