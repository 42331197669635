import styled from "@emotion/styled"
import { Form } from "formik"

export const StyledForm = styled(Form)`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-gap: 0.625rem;

  legend {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-weight: ${(p) => p.theme.fontWeights.bold};
    font-size: 1.4rem;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: flex;
    flex-direction: column;
  }

  .float-container {
    margin-bottom: 1rem;
  }
  .input-feedback {
    text-align: left;
    padding-left: 0;
    margin: -0.75rem 0 1rem;
    line-height: 1;
    &.warning {
      background-color: ${(p) => p.theme.colors.yellow};
      color: ${(p) => p.theme.colors.gondola};
      border-radius: 0.3125rem;
      padding: 0.5rem 0.75rem;
    }
  }
  .react-select__value-container {
    height: 3rem;
  }
  .react-select__menu {
    height: 9.375rem;
    z-index: 2;
    overflow: auto;
    .react-select__option {
      height: 3.125rem;
      line-height: 3.125rem;
      padding: 0 0 0 0.5rem;
    }
  }
  .flex {
    display: flex;
  }

  .float-container {
    min-width: 18.125rem;
  }
`

export const StyledPageTitleUserContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const StyledPageUserContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.blue};
    font-size: 1rem;
    line-height: 1.5rem;
    padding: unset;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }

    &.btn-roles {
      svg {
        margin-left: 0.5rem;
        height: 1.25rem;
        cursor: pointer;
      }
    }
  }
`

export const StyledRolesContainer = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.625rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
  }
`

export const StyledRoleText = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.blue};
  font-size: 1rem;
  line-height: 1.5rem;
  cursor: pointer;
  justify-self: end;
  align-self: center;
  text-align: left;
  margin: 0;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.75rem;
    line-height: 1.75rem;
  }
`

export const StyledRoleIcon = styled.div`
  color: ${(p) => p.theme.colors.blue};

  svg {
    margin-right: 1.5rem;
    cursor: pointer;
  }
`

export const StyledRolesExplanationText = styled.div`
  z-index: 999;
  position: absolute;
  left: -28.5625rem;
  top: 0.5rem;

  > div {
    background: ${(p) => p.theme.colors.white};
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    padding: 1.5rem;
    margin: 1rem 0 0 0;
  }

  hr {
    margin: unset;
  }
`

export const StyledRightRelationshipTableHeader = styled.div`
  display: grid;
  grid-template-columns: 9rem repeat(${(p) => p.columns}, minmax(6.25rem, 1fr));
  grid-template-rows: 1fr;
  padding-left: 0;
  padding-right: 0;
  //align-items: center;
  text-align: center;

  color: ${(p) => p.theme.colors.tableHeaderDefault};
  border-bottom: 0.0625rem solid ${(p) => p.theme.colors.tableItemBorder};
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 0.8rem;

  .edit-buttons {
    height: 100%;
    justify-self: end;
  }

  @media only screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
    //grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr;
  }

  @media only screen and (max-width: ${(p) => p.theme.breakpoints.phone}) {
    display: none;
  }
`

export const StyledRightRelationshipTableRow = styled.div`
  display: grid;
  grid-template-columns: 9rem repeat(${(p) => p.columns}, minmax(6.25rem, 1fr));
  grid-template-rows: 1fr;

  padding-left: 0;
  padding-right: 0;
  align-items: center;

  svg {
    color: ${(p) => p.theme.colors.blue};
    height: 1.25rem;
  }

  span {
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    font-style: ${(p) => p.theme.fontStyles.normal};
    color: ${(p) => p.theme.colors.pitchBlack};
    font-size: 0.75rem;
    line-height: 1.25rem;
  }

  .cell {
    text-align: center;
    padding: 0.625rem;
    border-bottom: 0.0625rem solid ${(p) => p.theme.colors.tableItemBorder};
    height: 100%;

    span {
      font-family: ${(p) => p.theme.fontFamilies.regular};
      font-weight: ${(p) => p.theme.fontWeights.liteMedium};
      font-style: ${(p) => p.theme.fontStyles.normal};
      color: ${(p) => p.theme.colors.blue};

      @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
        font-size: 0.75rem;
        line-height: 1.25rem;
      }

      @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }
  }

  .first-cell {
    text-align: left;
    padding-left: 0;

    span {
      color: unset;
    }
  }
`

export const StyledAddUserContainer = styled.div`
  button {
    width: 100%;
  }
  .blue-button {
    color: ${(p) => p.theme.colors.white};
    background-color: ${(p) => p.theme.colors.pacificBlue};
    :disabled {
      background-color: ${(p) => p.theme.colors.gray};
    }
    margin-bottom: 0;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    width: 13rem;
    position: relative;
    .blue-button {
      margin-bottom: 1rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    width: 13rem;
  }
`

export const StyledRoleName = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-style: ${(p) => p.theme.fontStyles.normal};
  color: ${(p) => p.theme.colors.pitchBlack};

  text-align: center;
  font-size: 0.75rem;
  line-height: 1.125rem;
  letter-spacing: 0.0075rem;
  padding: 0.625rem;
`
