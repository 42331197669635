import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { useQuery } from "@apollo/client"
import { NewCard, Spinner } from "@tmu/components/common"
import { CAMPAIGN_LISTING_QUERY } from "@tmu/apollo/storefront/queries/campaign"
import { CARDS_PER_PAGE, LIST_TYPES } from "@tmu/apollo/constants"
import { getValueForLocale } from "@tmu/utils/string"
import SEO from "@tmu/components/seo"
import {
  StyledWrapper,
  StyledServicesBody,
  StyledContent,
  StyledHeader,
  StyledTitle,
  StyledListing,
  NoResults,
  StyledSliceWrapper,
} from "./index.styles"
import { OFFER_TYPE } from "@tmu/apollo/constants"
import { SliceCampaigns } from "@tmu/components/home"
import { getAllScreenTypes } from "@tmu/src/utils/mediaQueries"

const CorporateCampaignListing = ({
  title,
  storeId,
  customNotFoundMessage,
}) => {
  const { locale, defaultLocale, formatMessage } = useIntl()

  const { loading, data } = useQuery(CAMPAIGN_LISTING_QUERY, {
    variables: {
      first: CARDS_PER_PAGE,
      store: [storeId],
      orderBy: "-created",
    },
    fetchPolicy: "network-only",
  })

  const { isDesktop } = getAllScreenTypes()

  return (
    <>
      <SEO
        lang={locale}
        title={formatMessage({
          id: "dashboard::nav::corporate",
          defaultMessage: "Corporate",
        })}
      />
      <StyledWrapper id="corporate">
        <StyledServicesBody
          hasCorporateCampaigns={
            data?.allCampaigns?.edges?.length > 0 ? true : false
          }>
          <div>
            <StyledContent>
              {loading ? (
                <Spinner />
              ) : (
                <>
                  {data?.allCampaigns?.edges?.length > 0 && (
                    <StyledHeader>
                      <StyledTitle>{title}</StyledTitle>
                    </StyledHeader>
                  )}
                  <StyledListing>
                    {data?.allCampaigns?.edges?.length > 0 ? (
                      <>
                        {isDesktop ? (
                          <StyledSliceWrapper isCorporate>
                            <SliceCampaigns
                              isStoreCampaign={true}
                              isStoreSupporter={true}
                              headline={<></>}
                              campaigns={data?.allCampaigns?.edges ?? []}
                            />
                          </StyledSliceWrapper>
                        ) : (
                          data?.allCampaigns?.edges?.map(
                            ({ node: campaign }) => {
                              return (
                                <NewCard
                                  variant="campaign"
                                  key={campaign?.id}
                                  imageSrc={
                                    campaign?.image?.url ||
                                    campaign?.images?.edges?.[0]?.node?.image
                                  }
                                  title={getValueForLocale(
                                    campaign,
                                    "name",
                                    locale,
                                    defaultLocale
                                  )}
                                  type={campaign?.campaignType}
                                  totalDonors={campaign?.donationCount}
                                  totalSupport={campaign?.fundedAmount}
                                  size={
                                    !isDesktop
                                      ? LIST_TYPES.LIST
                                      : LIST_TYPES.TILE
                                  }
                                  cardLink={`/campaigns/${campaign?.slug}`}
                                  goalAmount={campaign?.goalAmount}
                                  progress={campaign?.progress}
                                  fundedAmount={campaign?.fundedAmount}
                                  partnerLogo={getValueForLocale(
                                    campaign?.partner,
                                    "logo",
                                    locale,
                                    defaultLocale
                                  )}
                                  storeLogo={getValueForLocale(
                                    campaign?.store,
                                    "logo",
                                    locale,
                                    defaultLocale
                                  )}
                                  partnerName={
                                    campaign?.partner?.displayName ||
                                    campaign?.partner?.name
                                  }
                                  taxId={campaign?.partner?.taxId}
                                  pacMultiplier={campaign?.pacMultiplier}
                                  isStoreSupporter={true}
                                  isCorporate={
                                    campaign?.store?.defaultOffer?.offerType ===
                                    OFFER_TYPE.BRAND
                                  }
                                />
                              )
                            }
                          )
                        )}
                      </>
                    ) : (
                      !loading && (
                        <NoResults>
                          {customNotFoundMessage || (
                            <FormattedMessage
                              id="campaignListing::filters::noResult"
                              defaultMessage="We couldn’t find any results ("
                              tagName="h6"
                            />
                          )}
                        </NoResults>
                      )
                    )}
                  </StyledListing>
                </>
              )}
            </StyledContent>
          </div>
        </StyledServicesBody>
      </StyledWrapper>
    </>
  )
}

CorporateCampaignListing.propTypes = {
  title: PropTypes.node.isRequired,
  storeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  customNotFoundMessage: PropTypes.node.isRequired,
}

export default CorporateCampaignListing
