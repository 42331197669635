import React from "react"
import { CountdownCircleTimer } from "react-countdown-circle-timer"
import { FormattedMessage } from "gatsby-plugin-intl"
import { StyledTimerWrapper, StyledRenderTimeWrapper } from "./index.styles"
import PropTypes from "prop-types"
import theme from "@tmu/global/theme"
const renderTime = ({ remainingTime }) => {
  return (
    <StyledRenderTimeWrapper>
      <div className="time">{remainingTime}</div>
      <div className="dimension">
        <FormattedMessage
          id="circle::time::dimension"
          defaultMessage="seconds"
        />
      </div>
    </StyledRenderTimeWrapper>
  )
}
const timerProps = {
  isPlaying: true,
  size: 80,
  strokeWidth: 6,
}

const Timer = ({ duration, onCompletedCb }) => {
  return (
    <StyledTimerWrapper>
      <CountdownCircleTimer
        {...timerProps}
        duration={duration || 5}
        onComplete={onCompletedCb}
        colors={theme?.colors?.irisBlue}>
        {renderTime}
      </CountdownCircleTimer>
    </StyledTimerWrapper>
  )
}

Timer.propTypes = {
  duration: PropTypes.any.isRequired,
  onCompletedCb: PropTypes.func.isRequired,
}

Timer.defaultProps = {
  duration: 6,
  remainingTimeMsg: "",
  onCompletedCb: () => {},
}

export default Timer
