import React from "react"
import { Router } from "@reach/router"
import { Profile } from "@tmu/components/dashboard/dashboardPages"

const ProfileRoute = () => (
  <Router>
    <Profile path="/*" />
  </Router>
)

export default ProfileRoute
