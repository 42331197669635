import React from "react"
import { Router } from "@reach/router"
import { injectIntl } from "gatsby-plugin-intl"
import LegalPage from "@tmu/components/legal"

import { useIntl } from "gatsby-plugin-intl"
import SEO from "@tmu/components/seo"

const Legal = () => {
  const { locale, formatMessage } = useIntl()

  return (
    <>
      <SEO
        lang={locale}
        title={formatMessage({
          id: "legal::pageTitle",
          defaultMessage: "Terms and Policies",
        })}
        description={formatMessage({
          id: "seo::legal::description",
          defaultMessage:
            "Read the terms and policies in our donation ecosystem.",
        })}
      />

      <Router basepath="/:locale/legal">
        <LegalPage exact path="/" />
        <LegalPage path="/:slug/:pageId" />
      </Router>
    </>
  )
}

export default injectIntl(Legal)
