import {
  LazyImage,
  Money,
  ProgressBar,
  TruncatedText,
} from "@tmu/components/common"
import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { FormattedMessage, Link, navigate, useIntl } from "gatsby-plugin-intl"
import PropTypes from "prop-types"
import React from "react"
import {
  StyledButton,
  StyledCampaignCardTitle,
  StyledRibbon,
  StyledCampaignCardTitleWrapper,
  StyledCard,
  StyledCardInfo,
  StyledCardLogo,
  StyledCardText,
  StyledCardTitle,
  StyledImageBox,
  StyledImageBoxOffer,
  StyledPromoText,
  StyledPromoTextOffer,
  StyledTitlePrice,
  StyledBrandImageBox,
  StyledPartnerImageBox,
} from "./index.styles"

const CardImage = ({ src, href, fixed, ...rest }) =>
  fixed ? (
    <StaticImage fixed={fixed} alt={`card-image-${src}`} />
  ) : href ? (
    <Link to={href} aria-label={"Card Image " + src}>
      <LazyImage
        src={src}
        altName={`card-image-${src}`}
        fit="cover"
        {...rest}
      />
    </Link>
  ) : (
    <LazyImage src={src} altName={`card-image-${src}`} fit="cover" {...rest} />
  )

const CampaignCard = ({
  name,
  displayName,
  image,
  shortDescription,
  goalAmount,
  fundedAmount,
  donationCount,
  buttonLink,
  isFeatured,
  status = "",
  index = "",
  isAvailable = false,
  ...rest
}) => {
  const {
    site: {
      siteMetadata: { cardViewProgressEnabled },
    },
  } = useStaticQuery(graphql`
    query CardViewProgressEnabled {
      site {
        siteMetadata {
          cardViewProgressEnabled
        }
      }
    }
  `)

  const featuredStr = isFeatured ? `${isFeatured}` : ""
  return (
    <StyledCard
      {...rest}
      featured={featuredStr}
      onClick={() => navigate(buttonLink)}>
      <StyledImageBox>
        <CardImage
          {...rest}
          src={image}
          href={buttonLink}
          width={280}
          height={158}
        />
        {status === "ENDED" ? (
          <StyledRibbon>
            <FormattedMessage id="card::status::ENDED" defaultMessage="ENDED" />
          </StyledRibbon>
        ) : !isAvailable ? (
          <StyledRibbon>
            <FormattedMessage
              id="card::status::UNAVAILABLE"
              defaultMessage="UNAVAILABLE"
            />
          </StyledRibbon>
        ) : null}
      </StyledImageBox>
      <StyledCardInfo>
        {buttonLink && (
          <StyledCampaignCardTitleWrapper>
            <StyledCampaignCardTitle
              featured={featuredStr}
              to={buttonLink}
              variant="big">
              {name}
            </StyledCampaignCardTitle>
          </StyledCampaignCardTitleWrapper>
        )}

        <StyledCardText featured={featuredStr} variant="big">
          <TruncatedText numLines={cardViewProgressEnabled ? 2 : 4}>
            {shortDescription}
          </TruncatedText>
        </StyledCardText>
        {cardViewProgressEnabled && (
          <ProgressBar
            hideDecimals
            amount={Number(fundedAmount)}
            total={goalAmount}
            currentNumber={donationCount}
            displayCurrentNumber={false}
            featured={featuredStr}
          />
        )}
      </StyledCardInfo>
    </StyledCard>
  )
}

const StoreCard = ({
  name,
  image,
  maxPacDiscount,
  logo,
  buttonLink,
  shortDescription,
  square,
  ...rest
}) => {
  return (
    <StyledCard {...rest} onClick={() => navigate(buttonLink)}>
      <StyledImageBox square={square} hasImageBorder>
        <CardImage
          {...rest}
          src={image}
          href={buttonLink}
          width={280}
          height={158}
        />
        {maxPacDiscount ? (
          <StyledPromoText square={square}>
            <FormattedMessage
              id="card::usePACsUpTo"
              defaultMessage="Use PACs up to {maxPacDiscount}%"
              values={{
                maxPacDiscount,
              }}
            />
          </StyledPromoText>
        ) : null}
        {logo && (
          <StyledCardLogo
            style={{
              backgroundImage: `url(${logo}?width=180&height=180)`,
            }}
          />
        )}
      </StyledImageBox>
      <StyledCardTitle to={buttonLink}>{name}</StyledCardTitle>
      {shortDescription && (
        <StyledCardText>
          <TruncatedText>{shortDescription}</TruncatedText>
        </StyledCardText>
      )}
    </StyledCard>
  )
}

const PartnerCard = ({
  name,
  displayName,
  image,
  shortDescription,
  buttonLink,
  title,
  fundedAmount,
  goalAmount,
  donationCount,
  ...rest
}) => {
  return (
    <StyledCard {...rest} onClick={() => navigate(buttonLink)}>
      <StyledPartnerImageBox>
        <CardImage
          {...rest}
          src={image}
          href={buttonLink}
          width={"auto"}
          height={157}
          maxWidth={280}
          maxHeight={158}
        />
      </StyledPartnerImageBox>
      <StyledCardInfo>
        {buttonLink && (
          <StyledCampaignCardTitleWrapper>
            <StyledCampaignCardTitle to={buttonLink} variant="big">
              {title}
            </StyledCampaignCardTitle>
          </StyledCampaignCardTitleWrapper>
        )}
        {shortDescription && (
          <StyledCardText>
            <TruncatedText numLines={3}>{shortDescription}</TruncatedText>
          </StyledCardText>
        )}
        <ProgressBar
          hideDecimals
          noPadding
          amount={Number(fundedAmount || 0)}
          total={goalAmount || 0}
          currentNumber={donationCount || 0}
          displayCurrentNumber={false}
        />
      </StyledCardInfo>
    </StyledCard>
  )
}

const BrandCard = ({
  name,
  displayName,
  image,
  fixedImage,
  shortDescription,
  buttonText,
  buttonLink,
  variant = "campaign",
  src,
  isLight,
  ...rest
}) => {
  const label = displayName?.length > 0 ? displayName : name
  const handlePartnerClick = () => {
    navigate(buttonLink)
  }
  return (
    <StyledCard {...rest} onClick={() => navigate(buttonLink)}>
      <StyledBrandImageBox
        variant={variant}
        fixed={fixedImage}
        hasImageBorder
        isLight={isLight}>
        <CardImage
          {...rest}
          src={src}
          width={rest?.width || 280}
          height={rest?.height || 158}
        />
      </StyledBrandImageBox>
      {buttonLink && <StyledCardTitle to={buttonLink}>{label}</StyledCardTitle>}
      {shortDescription && (
        <StyledCardText>
          <TruncatedText>{shortDescription}</TruncatedText>
        </StyledCardText>
      )}
      {buttonLink && (
        <StyledButton onClick={handlePartnerClick}>{buttonText}</StyledButton>
      )}
    </StyledCard>
  )
}

const OfferCard = ({
  name,
  image,
  shortDescription,
  maxPacDiscount,
  buttonLink,
  price,
  ...rest
}) => {
  maxPacDiscount = <Money value={maxPacDiscount} style="percent" />
  return (
    <StyledCard {...rest} onClick={() => navigate(buttonLink)}>
      <StyledImageBoxOffer hasImageBorder>
        <CardImage
          {...rest}
          src={image}
          href={buttonLink}
          width={280}
          height={158}
        />
      </StyledImageBoxOffer>
      {maxPacDiscount ? (
        <Link to={buttonLink} aria-label="Use PACs up to">
          <StyledPromoTextOffer
            style={{
              left: "auto",
              right: 0,
              borderRadius: 0,
            }}
            className="offer">
            <FormattedMessage
              id="card::usePACsUpTo"
              defaultMessage="Use PACs up to {maxPacDiscount}"
              values={{
                maxPacDiscount,
              }}
            />
          </StyledPromoTextOffer>
        </Link>
      ) : null}
      <StyledTitlePrice>
        <StyledCardTitle to={buttonLink}>{name}</StyledCardTitle>
      </StyledTitlePrice>
      <StyledCardText>
        <TruncatedText>{shortDescription}</TruncatedText>
      </StyledCardText>
    </StyledCard>
  )
}

const MerchantCard = ({
  name,
  image,
  shortDescription,
  maxPacDiscount,
  buttonLink,
  price,
  ...rest
}) => {
  maxPacDiscount = (
    <Money
      value={rest?.pacDiscountAmount ?? maxPacDiscount}
      style="percent"
      currency={false}
    />
  )

  return (
    <StyledCard {...rest} onClick={() => navigate(buttonLink)}>
      <StyledImageBoxOffer hasImageBorder>
        <CardImage
          {...rest}
          src={image}
          href={buttonLink}
          width={280}
          height={158}
        />
      </StyledImageBoxOffer>
      {maxPacDiscount ? (
        <Link to={buttonLink} aria-label="Use PACs up to">
          <StyledPromoTextOffer
            style={{
              left: "auto",
              right: 0,
              borderRadius: 0,
            }}
            className="offer">
            <FormattedMessage
              id="card::usePACsUpTo"
              defaultMessage="Use PACs up to {maxPacDiscount}"
              values={{
                maxPacDiscount,
              }}
            />
          </StyledPromoTextOffer>
        </Link>
      ) : null}
      <StyledCardTitle to={buttonLink}>{name}</StyledCardTitle>
      <StyledCardText>
        <TruncatedText>{shortDescription}</TruncatedText>
      </StyledCardText>
    </StyledCard>
  )
}

const Card = ({
  variant,
  isFeatured = false,
  index = "",
  status = "",
  isAvailable = false,
  ...rest
}) => {
  const { locale, defaultLocale } = useIntl()
  switch (variant) {
    case "campaign":
      return (
        <CampaignCard
          {...rest}
          id={rest?.id + "_" + Date.now()}
          isFeatured={isFeatured}
          data-testid={"card-campaign_" + status + "_" + index}
          data-testlink={rest.buttonLink}
          variant="campaign"
          index={index}
          status={status}
          isAvailable={isAvailable}
        />
      )
    case "partner":
      return (
        <PartnerCard
          {...rest}
          id={rest?.id + "_" + Date.now()}
          isFeatured={isFeatured}
          data-testid={"card-charity_" + index}
          data-testlink={rest.buttonLink}
          index={index}
          status={status}
        />
      )
    case "store":
      return (
        <StoreCard
          {...rest}
          id={rest?.id + "_" + Date.now()}
          data-testid="card-store"
          data-testlink={rest.buttonLink}
        />
      )
    case "brand":
      return (
        <BrandCard
          {...rest}
          id={rest?.id + "_" + Date.now()}
          variant={variant}
          data-testid="card-partner"
          data-testlink={rest.buttonLink}
        />
      )
    case "offer":
      return (
        <OfferCard
          {...rest}
          id={rest?.id + "_" + Date.now()}
          data-testid={"card-offer" + index}
          data-testlink={rest.buttonLink}
        />
      )
    case "merchant":
      return (
        <MerchantCard
          {...rest}
          id={rest?.id + "_" + Date.now()}
          data-testid={"merchant-card" + index}
          data-testlink={rest.buttonLink}
        />
      )
    default:
      return null
  }
}

Card.propTypes = {
  name: PropTypes.string.isRequired,
  shortDescription: PropTypes.string,
  logo: PropTypes.string,
  maxPacDiscount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  deadline: PropTypes.string,
  goalAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fundedAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  donationCount: PropTypes.number,
  buttonLink: PropTypes.string,
  fixedImage: PropTypes.any,
  buttonText: PropTypes.string,
  isFeatured: PropTypes.bool,
  variant: PropTypes.oneOf([
    "campaign",
    "store",
    "partner",
    "offer",
    "brand",
    "merchant",
    "service",
    "offlineStore",
    "summary",
  ]).isRequired,
}

Card.defaultProps = {
  name: "",
  shortDescription: "",
  logo: "",
  variant: "campaign",
  isFeatured: false,
}

export default Card
