import React from "react"
import { useLocation } from "@reach/router"
import { LazyImage, Money, TruncatedText } from "@tmu/components/common"
import { useAuth } from "@tmu/hooks"
import { getQueryParams, removeAllHTMLTags } from "@tmu/utils/string"
import { FormattedMessage, navigate } from "gatsby-plugin-intl"
import queryString from "query-string"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import {
  StyledCardTitle,
  StyledOfferBox,
  StyledCardInfo,
  StyledImageBox,
  StyledPartnerLogo,
  StyledPartnerTitleText,
  StyledOfferStore,
  StyledCardDescription,
  StyledCardDiscount,
} from "./index.styles"
import { useOfferLink } from "@tmu/src/hooks"
const { CLOUDFLARE_IMAGE_URL } = process.env

const OfferCard = ({
  id,
  imageSrc,
  title,
  description,
  maxPacDiscount,
  size,
  cardLink,
  storeLogo,
  storeName,
  bigTablet,
  isServiceCard,
  minPrice,
  maxPrice,
  ...rest
}) => {
  const { pathname } = useLocation()
  const params = queryString.stringify({ ...getQueryParams() })
  const { isAuthenticated } = useAuth()
  const { isTablet, isDesktop, isWide } = getAllScreenTypes()
  const { getOfferLink } = useOfferLink()

  const handleExternalOffer = () => {
    if (!isAuthenticated) {
      const currentUrl = `${pathname}?${params}`
      const redirectUrl = `/redirect/${id}?backUrl=${currentUrl}`
      navigate(`/signin?next=${redirectUrl}`)
    } else {
      getOfferLink({
        variables: { offerId: id },
        onCompleted: (offerData) => {
          if (offerData?.offerLinkWithToken?.offerLink)
            window.open(offerData.offerLinkWithToken.offerLink, "_blank")
        },
      })
    }
  }

  const imageSize = {
    width: isWide ? 414 : isDesktop ? 228 : isTablet ? 228 : 115,
    height: isWide ? 232 : isDesktop ? 128 : isTablet ? 128 : 64,
  }

  const discountBanner = (
    <StyledCardDiscount size={size}>
      <div>
        <img
          className="pacs-pacs-image"
          src={CLOUDFLARE_IMAGE_URL + "/static/assets/images/pac.svg"}
          alt={`pacs`}
          width={28}
          height={23}
        />
        <span>
          <Money style="percent" value={maxPacDiscount} currency={false} />
        </span>
      </div>
      {isServiceCard ? (
        <div className="service-price">
          {Number(minPrice || 0) < Number(maxPrice || 0) ? (
            <FormattedMessage
              id="card::service::from"
              defaultMessage="from {discount}"
              values={{
                discount: <Money value={Number(minPrice || 0)} />,
              }}
            />
          ) : (
            <Money value={Number(minPrice || 0)} />
          )}
        </div>
      ) : null}
    </StyledCardDiscount>
  )
  return (
    <StyledOfferBox
      data-testid={`offerCard${id}`}
      size={size}
      {...rest}
      onClick={() =>
        isServiceCard ? navigate(cardLink) : handleExternalOffer()
      }
      bigTablet={bigTablet}>
      <StyledImageBox size={size}>
        <LazyImage
          src={imageSrc}
          {...imageSize}
          altName={`card-image`}
          fit="cover"
        />
      </StyledImageBox>
      <StyledCardInfo offer size={size}>
        <div>
          <StyledCardTitle offer size={size}>
            <TruncatedText numLines={2}>{title}</TruncatedText>
          </StyledCardTitle>
          <StyledCardDescription>
            <TruncatedText
              numLines={isDesktop || (isTablet && size === "tile") ? 4 : 3}>
              {removeAllHTMLTags(description)}
            </TruncatedText>
          </StyledCardDescription>
        </div>
        {(!isTablet || (isTablet && size !== "tile")) && discountBanner}
      </StyledCardInfo>
      <div>
        {(isDesktop || (isTablet && size === "tile")) && discountBanner}
        <StyledOfferStore size={size} bigTablet={bigTablet}>
          <StyledPartnerLogo
            data-testid="campaign-detail__foundation-logo"
            src={storeLogo}
          />
          <StyledPartnerTitleText>
            <TruncatedText numLines={2}>{storeName}</TruncatedText>
          </StyledPartnerTitleText>
        </StyledOfferStore>
      </div>
    </StyledOfferBox>
  )
}
export default OfferCard
