import React, { useEffect, useRef } from "react"
import BenefitCard from "./BenefitCard/index"
import { isInViewport } from "@tmu/utils/viewport"
import { useLocation } from "@reach/router"
import {
  StyledBenefits,
  StyledSubDescription,
  StyledDescription,
  StyledTitle,
  StyledBenefitsList,
} from "./index.styles"

const Benefits = ({ title, desc, subDesc, benefits, page, carrot, blue }) => {
  const ref = useRef()
  const { hash } = useLocation()
  const hashCallback = () => {
    const hashText = ""
    const isHashInUrl = hash === hashText || window?.location?.hash === hashText
    if (isInViewport(ref) && !isHashInUrl)
      history.pushState(null, null, hashText)
  }

  useEffect(() => {
    document.addEventListener("scroll", hashCallback)
    return () => document.removeEventListener("scroll", hashCallback)
  }, [])

  return (
    <StyledBenefits id="why-open-a-PAC-personal-bank-account" ref={ref}>
      {title && <StyledTitle>{title}</StyledTitle>}
      {desc && <StyledDescription>{desc}</StyledDescription>}

      {subDesc && <StyledSubDescription>{subDesc}</StyledSubDescription>}
      {benefits && (
        <StyledBenefitsList page={page}>
          {benefits.map((e, i) => (
            <BenefitCard
              key={i}
              icon={e.icon}
              fontIcon={e.fontIcon}
              title={e.title}
              desc={e.desc}
              className={`benefit${i + 1}`}
              carrot={carrot}
              blue={blue}
            />
          ))}
        </StyledBenefitsList>
      )}
    </StyledBenefits>
  )
}

export default Benefits
