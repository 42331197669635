import { gql } from "@apollo/client"
import { campaignListingParts } from "@tmu/apollo/storefront/fragments/campaign"
import { partnerListingParts } from "@tmu/apollo/storefront/fragments/partner"
import { merchantOfferParts } from "@tmu/apollo/storefront/fragments/merchant"

export const QUERY_USERSPACE_IMAGES = gql`
  query UserSpaceImages($locale: I18NLocaleCode) {
    userSpaceImages(locale: $locale) {
      data {
        attributes {
          quote
          person
          image {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`
export const QUERY_USERSPACE_CTA = gql`
  query abc($locale: I18NLocaleCode) {
    userSpaceBtns(locale: $locale) {
      data {
        attributes {
          buttonIcon
          buttonLink
          buttonLabel
          buttonColor
        }
      }
    }
  }
`

export const QUERY_USERSPACE_PAGEDATA = gql`
  query UserSpacePageData(
    $country: String
    $campaignCategory1: ID
    $campaignCategory2: ID
    $campaignCategory3: ID
    $offerCategory1: ID
    $offerCategories1: [ID]
    $offerCategory2: ID
    $offerCategories2: [ID]
    $offerCategory3: ID
    $offerCategories3: [ID]
  ) {
    offerCategory1: offerCategory(id: $offerCategory1) {
      id
      name
      nameEn
      nameIt
      nameEs
      slug
    }
    offerCategory2: offerCategory(id: $offerCategory2) {
      id
      name
      nameEn
      nameIt
      nameEs
      slug
    }
    offerCategory3: offerCategory(id: $offerCategory3) {
      id
      name
      nameEn
      nameIt
      nameEs
      slug
    }
    featuredCampaigns: allCampaigns(
      orderBy: "-visibilityStatus,-created"
      first: 12
    ) {
      edges {
        node {
          ...campaignListingParts
          partner {
            id
            name
            displayName
            logo
            logoEn
            logoIt
            logoEs
          }
          store {
            id
            slug
            name
            displayName
            logo
            defaultOffer {
              offerType
            }
          }
        }
      }
    }
    # Get selected campaign categories from userPreference section of me query
    # Same campaign can be in more than one of these queries
    recommendedCampaigns1: allCampaigns(
      first: 4
      categories: [$campaignCategory1]
      orderBy: "-fundedAmount"
    ) {
      edges {
        node {
          ...campaignListingParts
          partner {
            ...partnerListingParts
          }
          store {
            id
            slug
            name
            displayName
            logo
            defaultOffer {
              offerType
            }
          }
        }
      }
    }
    recommendedCampaigns2: allCampaigns(
      first: 4
      categories: [$campaignCategory2]
      orderBy: "-fundedAmount"
    ) {
      edges {
        node {
          ...campaignListingParts
          partner {
            ...partnerListingParts
          }
          store {
            id
            slug
            name
            displayName
            logo
            defaultOffer {
              offerType
            }
          }
        }
      }
    }
    recommendedCampaigns3: allCampaigns(
      first: 4
      categories: [$campaignCategory3]
      orderBy: "-fundedAmount"
    ) {
      edges {
        node {
          ...campaignListingParts
          partner {
            ...partnerListingParts
          }
          store {
            id
            slug
            name
            displayName
            logo
            defaultOffer {
              offerType
            }
          }
        }
      }
    }

    # Get al campaigns with the "wordlwide" tag and 2 pac multipliers, bring the most donated ones first
    worldwideCampaigns: allCampaigns(
      first: 10
      tags: "worldwide"
      pacMultiplier: "2.00"
      orderBy: "-fundedAmount"
    ) {
      edges {
        node {
          ...campaignListingParts
          partner {
            ...partnerListingParts
          }
          store {
            id
            slug
            name
            displayName
            logo
            defaultOffer {
              offerType
            }
          }
        }
      }
    }

    recentlyCreatedCampaigns: allCampaigns(
      first: 10
      isSupporter: false
      country: $country
      orderBy: "-created"
    ) {
      edges {
        node {
          ...campaignListingParts
          partner {
            ...partnerListingParts
          }
          store {
            id
            slug
            name
            displayName
            logo
            defaultOffer {
              offerType
            }
          }
        }
      }
    }

    # This is the list of latest distinct campaigns the user has donated to. Always returns last 5 donation.
    repeatYourDonation: repeatYourDonation {
      edges {
        node {
          ...campaignListingParts
          partner {
            ...partnerListingParts
          }
          store {
            id
            slug
            name
            displayName
            logo
            defaultOffer {
              offerType
            }
          }
        }
      }
    }

    # Get selected offer categories from userPreference section of me query
    # Same offer can be in more than one of these queries
    # If we have less than 4 offers for interested category, hide it.
    recommendedOffers1: allOffers(
      first: 12
      categories: $offerCategories1
      badge: ["NO_BADGE"]
      orderBy: "-discount,-created"
    ) {
      edges {
        node {
          ...merchantOfferParts
        }
      }
    }

    recommendedOffers2: allOffers(
      first: 12
      categories: $offerCategories2
      badge: ["NO_BADGE"]
      orderBy: "-discount,-created"
    ) {
      edges {
        node {
          ...merchantOfferParts
        }
      }
    }

    recommendedOffers3: allOffers(
      first: 12
      categories: $offerCategories3
      badge: ["NO_BADGE"]
      orderBy: "-discount,-created"
    ) {
      edges {
        node {
          ...merchantOfferParts
        }
      }
    }

    newStores: allOffers(
      first: 4
      orderBy: "-created"
      offerOrigin: ["stores"]
      badge: ["NO_BADGE"]
    ) {
      edges {
        node {
          ...merchantOfferParts
        }
      }
    }
    newOffers: allOffers(
      first: 4
      orderBy: "-created"
      offerOrigin: ["offers"]
    ) {
      edges {
        node {
          ...merchantOfferParts
        }
      }
    }
  }
  ${campaignListingParts}
  ${partnerListingParts}
  ${merchantOfferParts}
`
