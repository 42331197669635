import styled from "@emotion/styled"
import { css } from "@emotion/react"
export const StyledPageGrid = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-column-gap: 1.25rem;
  margin-top: 1rem;
  .accordion-section-top > * {
    font-family: ${(p) => p.theme.fontFamilies.headline} !important;
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.pitchBlack};
    font-size: 1.125rem;
  }
  p.link-button-text span {
    font-family: ${(p) => p.theme.fontFamilies.headline} !important;
  }
  ${(p) =>
    p.disabled &&
    css`
      pointer-events: none;
    `};
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    grid-template-columns: 2fr 3fr;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    .accordion-section-top > * {
      font-size: 1.5rem;
    }
    p.link-button-text span {
      font-size: 1.5rem !important;
    }
  }
`
export const StyledProfileImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -2rem;

  & > div > div {
    background-color: ${(p) => p.theme.colors.whiteSmoke};
  }

  & > div > div > button {
    display: none;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    justify-content: flex-start;
    margin-top: 0;
    & > div > div {
      background-color: ${(p) => p.theme.colors.white};
    }
  }

  &.justify-end {
    justify-content: flex-end;
  }

  .float-container.select-field {
    min-width: 40%;
  }

  &.space-between {
    justify-content: space-between;
  }

  .image-selector {
    display: block;
    flex: unset;
  }

  .image-selector > div {
    img {
      max-height: 6.25rem;
      max-width: 6.25rem;
      @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
        max-height: 10rem;
        max-width: 10rem;
      }
    }
    /*
    svg {
      display: none;
      position: absolute;
      left: 40%;
      top: 1rem;
      color: white;
      opacity: 1;
    }

    :hover {
      svg {
        display: block;
      }

      :before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background: black;
        opacity: 0.5;
        top: 0.2rem;
        left: 0.5rem;
        border-radius: 50%;
        max-height: 6.4rem;
        max-width: 6.4rem;
      }
    }
    */
  }

  .ReactCrop > div > img {
    max-height: unset;
    max-width: 100%;
  }

  .image-selector div {
    line-height: 1rem;
    ${(p) =>
      p.horizontal &&
      css`
        display: block;
      `};

    .image-selector {
      width: 100%;
    }
  }
`

export const StyledUserDetail = styled.div`
  text-align: center;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    text-align: left;
  }
`

export const StyledUserName = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.pitchBlack};
  font-size: 1.5rem;
  line-height: 2rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2.5rem;
    line-height: 4.125rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 2rem;
    line-height: 2.625rem;
  }
`
export const StyledStats = styled.div`
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  min-height: 2.5rem;
  justify-content: space-evenly;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    gap: 2.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    justify-content: space-between;
  }
`
export const StyledStatItem = styled.div``

export const StyledStatItemNumber = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.pitchBlack};
  font-size: 1.125rem;
  line-height: 1.5rem;
  text-align: center;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
    line-height: 2.625rem;
  }
`

export const StyledSection = styled.div`
  .react-select__control {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    .react-select__control {
      height: unset;
    }
  }

  .recurring-donation-link {
    span {
      font-family: ${(p) => p.theme.fontFamilies.headline};
      font-style: ${(p) => p.theme.fontStyles.normal};
      font-weight: ${(p) => p.theme.fontWeights.semiBolder};
      font-size: 1rem;
      line-height: 1.5rem;

      @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      }

      @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      }

      @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
        font-size: 1.25rem;
        line-height: 1.75rem;
      }
    }

    svg {
      display: none;
    }
  }
`

export const StyledSectionTitle = styled.div`
  text-transform: uppercase;
  font-family: ${(p) => p.theme.fontFamilies.headlineBig};
  font-weight: ${(p) => p.theme.fontWeights.liteBlack};
  color: ${(p) => p.theme.colors.disabled};
  font-size: 0.625rem;
  line-height: 1rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.25rem;
    line-height: 2rem;
  }
`

export const StyledRowTitle = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.pitchBlack};
  font-size: 1.25rem;
  line-height: 1.75rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
    line-height: 2.625rem;
  }
`

export const StyledSimpleTextContent = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  color: ${(p) => p.theme.colors.pitchBlack};
  font-size: 1rem;
  line-height: 1.375rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }

  &.delete-account-text {
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    color: ${(p) => p.theme.colors.pitchBlack};

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 1rem;
      line-height: 1.5rem;

      width: 27rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.5rem;
      line-height: 2.25rem;

      width: 50rem;
    }
  }
`
export const StyledRestPassword = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.pacificBlue};
  font-size: 1.25rem;
  line-height: 1.75rem;
  cursor: pointer;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
    line-height: 2.625rem;
  }
`
export const StyledEditLink = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) =>
    p.color === "red" ? p.theme.colors.red : p.theme.colors.pacificBlue};
  font-size: 1rem;
  line-height: 1.5rem;
  cursor: pointer;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }

  &.mobile {
    position: absolute;
    right: 1rem;
    top: 1.3rem;
  }
`
export const StyledFlexContainer = styled.div`
  button {
    width: 100%;
  }
  .blue-button {
    color: ${(p) => p.theme.colors.white};
    background-color: ${(p) => p.theme.colors.pacificBlue};
    :disabled {
      background-color: ${(p) => p.theme.colors.gray};
    }
    margin-bottom: 1rem;
  }

  .button-margin {
    margin-left: 0;
    margin-right: 0;
    width: 88vw;
    position: absolute;
    bottom: 1rem;
    white-space: normal;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      width: 13rem;
      position: relative;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      width: 13rem;
    }
  }
`
export const StyledRowContent = styled.div`
  text-align: left;

  &.mobile-scroll {
    ::-webkit-scrollbar {
      width: 0;
      background: transparent; /* make scrollbar transparent */
    }

    text-align: left;
    overflow-y: scroll;
    max-height: 73vh;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    p {
      font-size: 1.5rem;
    }
  }
`

export const StyledLabel = styled.p`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  width: 13.0625rem;
  height: 1.75rem;
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.bold};
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: ${(p) => p.theme.colors.black};
  margin: 0;
  padding: 0;
  align-self: center;
  &.top-align {
    align-self: flex-start;
    margin-top: 0.6875rem;
  }

  &.subtext {
    margin-top: 0.5rem;
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.regular};
    font-size: 0.75rem;
    line-height: 1rem;
    color: ${(p) => p.theme.colors.black};
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
    width: auto;
    :not(.subheader):not(.subtext) {
      font-weight: ${(p) => p.theme.fontWeights.black};
      font-size: 0.625rem;
      line-height: 1rem;
      letter-spacing: 0.04rem;
      text-transform: uppercase;
      color: ${(p) => p.theme.colors.disabled};
      margin-top: 1rem;
    }
  }
`
export const StyledHr = styled.hr`
  margin-top: 1rem;
`

export const StyledMobileHr = styled.hr`
  margin-top: 1rem;
  margin-bottom: 1rem;

  &.first-mobile-hr {
    margin-top: 0;
  }
`
export const StyledMobileMenuItem = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  color: ${(p) => p.theme.colors.pitchBlack};
  font-size: 1rem;
  line-height: 1.375rem;
  cursor: pointer;
`

export const StyledMobileMenu = styled.div`
  text-align: left;
`

export const StyledMobileDeleteConfirmContainer = styled.div`
  height: 80%;
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
`
export const StyledMobileDeleteConfirmTitle = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.pitchBlack};
  font-size: 1.5rem;
  line-height: 2rem;
  width: 100%;
`
export const StyledMobileDeleteConfirmDescription = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  color: ${(p) => p.theme.colors.pitchBlack};
  font-size: 1rem;
  line-height: 1.5rem;
`
export const StyledMobileDeleteConfirmButtonsContainer = styled.div`
  position: absolute;
  bottom: 1rem;
  width: 85vw;
  .blue-button {
    background-color: ${(p) => p.theme.colors.pacificBlue};
  }

  button {
    width: 100%;
  }
`
export const StyledMobileBackground = styled.div`
  position: absolute;
  background-color: ${(p) => p.theme.colors.whiteSmoke};
  width: 100vw;
  height: 19rem;
  top: 0;
  left: 0;
  z-index: -1;

  &.without-image {
    height: 22rem;
    @media only screen and (min-width: ${(p) => p.theme.breakpoints.phone}) {
      height: 21rem;
    }
  }
`

export const StyledUsedCardTitle = styled.div`
  overflow-wrap: break-word;
  inline-size: 95%;
  text-align: left;
  font-family: ${(p) => p.theme.fontFamilies.headlineBig};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.pitchBlack};
  font-size: 1.5rem;
  line-height: 2rem;
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    inline-size: 80%;
  }

  &.recurring-donation-mobile-text {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-size: 1.5rem;
    line-height: 2.125rem;
    text-align: center;
    width: 100%;
    margin-top: 8.3125rem;
  }
`

export const StyledChooseNewCardText = styled.div`
  margin-top: 2rem;
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.pitchBlack};
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: left;
`
export const StyledCreditCardContainer = styled.div`
  margin-left: 2rem;
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-left: 2rem;
  }
`
export const StyledReceiptFormContainer = styled.div`
  max-height: calc(100vh - 10rem);
  overflow-y: auto;
  .base {
    margin-bottom: 0.5rem;
  }
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    max-height: unset;
    overflow-y: unset;
  }
`

export const StyledCampaignImage = styled.div`
  margin-bottom: 0.75rem;
  width: fit-content;
  height: fit-content;
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin-bottom: 1rem;
  }
  min-width: 6rem;
  min-height: 3.375rem;
`
