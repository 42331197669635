import React from "react"
import { FormattedMessage, navigate, useIntl } from "gatsby-plugin-intl"
import { Line } from "rc-progress"
import theme from "@tmu/global/theme"

import {
  LazyImage,
  Money,
  TruncatedText,
  StoreSupporterBox,
} from "@tmu/components/common"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { CardStats } from "../CardStats"

import {
  StyledCard,
  StyledImageBox,
  StyledCardInfo,
  StyledCardTitle,
  StyledCardSubtitle,
  StyledCardSubInfo,
  StyledPartnerTitle,
  StyledPartnerDetails,
  StyledPartnerLogo,
  StyledPartnerTitleText,
  StyledPACMultiplier,
  StyledDoublePacsTooltip,
} from "./index.styles"

const { CLOUDFLARE_IMAGE_URL } = process.env

const WideCampaignCard = ({
  imageSrc,
  title,
  subtitle,
  description,
  totalDonors,
  totalSupport,
  discount,
  inSupportOfTitle,
  inSupportOfLogo,
  fiscalCode,
  size,
  cardLink,
  goalAmount,
  fundedAmount,
  type,
  partnerName,
  partnerLogo,
  hasLogo,
  progress = 0,
  showPartnerInfo,
  pacMultiplier,
  isStoreSupporter,
  isCorporate: isCorporateCampaign,
  isCorporateStore,
  isOfflineCampaign,
  storeLogo,
  id,
  ...rest
}) => {
  const { isTablet, isDesktop, isWide } = getAllScreenTypes()
  const logoSize = {
    width: isWide ? 144 : isDesktop ? 85 : isTablet ? 61 : 41,
    height: isWide ? 79 : isDesktop ? 52 : isTablet ? 37 : 24,
  }
  const defaultSubtitle =
    type === "EV" ? (
      <FormattedMessage
        id="dashboard::campaignType::event"
        defaultMessage="Event"
      />
    ) : (
      <FormattedMessage
        id="dashboard::campaignType::charityCampaign"
        defaultMessage="Charity Campaign"
      />
    )

  const partnerInfo = (
    <StyledPartnerTitle size={size}>
      <StyledPartnerLogo
        data-testid="campaign-detail__foundation-logo"
        src={partnerLogo}
      />
      <StyledPartnerTitleText>
        <TruncatedText numLines={isTablet ? 2 : 3}>{partnerName}</TruncatedText>
      </StyledPartnerTitleText>
    </StyledPartnerTitle>
  )
  const partnerInfo2 = (
    <StyledPartnerDetails>
      <div>
        <StyledPartnerTitleText>
          <TruncatedText numLines={2}>{partnerName}</TruncatedText>
        </StyledPartnerTitleText>
        <StyledPartnerLogo
          data-testid="campaign-detail__foundation-logo"
          src={partnerLogo}
        />
      </div>
      <p className="caption">
        <FormattedMessage
          id="campaign::donationForm::inSupportOf"
          defaultMessage="in support of"
        />
      </p>
    </StyledPartnerDetails>
  )
  const imageSize = {
    width: isWide ? 499 : isDesktop ? 269 : isTablet ? 268 : 216,
    height: isWide ? 281 : isDesktop ? 151 : isTablet ? 151 : 122,
  }
  const PacMultiplier = () => {
    return Number(pacMultiplier) > 1 ? (
      <StyledPACMultiplier>
        <img
          className="pacs-pacs-image"
          src={CLOUDFLARE_IMAGE_URL + "/static/assets/images/white_pac.svg"}
          alt={`pacs-pacs-banner`}
          width={28}
          height={23}
        />
        {` X `}
        <Money value={pacMultiplier} currency={false} />
      </StyledPACMultiplier>
    ) : null
  }

  return (
    <StyledCard
      {...rest}
      onClick={() => navigate(cardLink)}
      data-testid={`wideCampaignCard${id}`}>
      <StyledImageBox
        hasLogo={hasLogo}
        size={size}
        isCorporate={isCorporateCampaign || isCorporateStore}>
        {isStoreSupporter ||
        isCorporateCampaign ||
        isCorporateStore ||
        isOfflineCampaign ? (
          <StoreSupporterBox
            storeLogo={storeLogo}
            partnerLogo={partnerLogo}
            {...logoSize}
          />
        ) : imageSrc ? (
          <LazyImage src={imageSrc} altName={`card-image`} {...imageSize} />
        ) : null}
        {pacMultiplier && (
          <StyledDoublePacsTooltip>
            <PacMultiplier />
          </StyledDoublePacsTooltip>
        )}
        {!isStoreSupporter && type === "GB" ? (
          <Line
            percent={Number(progress)}
            strokeWidth="3"
            strokeColor={theme.colors.softGreen}
            trailColor={theme.colors.white}
            trailWidth="3"
            data-testid="progress-bar"
          />
        ) : null}
      </StyledImageBox>
      <StyledCardInfo size={size}>
        <StyledCardTitle>
          <TruncatedText numLines={2}>{title}</TruncatedText>
        </StyledCardTitle>
        <StyledCardSubtitle className="caption">
          {subtitle || defaultSubtitle}{" "}
          {!isTablet ? (
            <>
              <FormattedMessage
                id="campaign::donationForm::inSupportOf"
                defaultMessage="in support of"
              />
              {partnerInfo}
            </>
          ) : null}
        </StyledCardSubtitle>
      </StyledCardInfo>
      <StyledCardSubInfo>
        <CardStats
          className="stats"
          totalDonors={totalDonors}
          totalSupport={totalSupport}
          size={size}
        />
        {isTablet ? partnerInfo2 : null}
      </StyledCardSubInfo>
    </StyledCard>
  )
}

export default WideCampaignCard
