import React, { useEffect } from "react"
import { useMutation } from "@apollo/client"
import { faLockAlt } from "@fortawesome/pro-light-svg-icons/faLockAlt"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useApolloApiClients } from "@tmu/apollo/client"
import { PASSWORD_RESET } from "@tmu/apollo/storefront/mutations/user"
import { Button, TextInput } from "@tmu/components/common"
import FooterLogoText from "@tmu/components/common/Footer/FooterLogoText"
import { StyledForm } from "@tmu/global/page-addons/dashboard.styles"
import { useToast } from "@tmu/hooks"
import {
  StyledContinueButtonContainer,
  StyledDescription,
  StyledFormContainer,
  StyledIconContainer,
  StyledInputContainer,
  StyledLogoContainer,
  StyledPageContainer,
  StyledTitle,
} from "@tmu/global/page-addons/reset-password.styles"
import { agreeTerms, password } from "@tmu/utils/validation"
import { Field, Formik } from "formik"
import {
  FormattedMessage,
  injectIntl,
  navigate,
  useIntl,
} from "gatsby-plugin-intl"
import * as Yup from "yup"
import theme from "@tmu/global/theme"

const SetPassword = () => {
  const { storefrontClient } = useApolloApiClients()
  const { formatMessage } = useIntl()
  const { error, success } = useToast()

  let token = ""

  if (typeof window !== "undefined") {
    token = new URL(window?.location?.href).searchParams.get("token")
  }

  const initialValues = {
    password: "",
    repassword: "",
    terms: true,
  }
  const validationSchema = Yup.object().shape({
    password: password({ formatMessage }),
    repassword: password({ formatMessage }),
    terms: agreeTerms({ formatMessage }),
  })

  const [callPasswordReset, { data, errors }] = useMutation(PASSWORD_RESET, {
    client: storefrontClient,
  })

  const setPassword = (password, repassword, terms) => {
    callPasswordReset({
      variables: {
        input: {
          newPassword1: password,
          newPassword2: repassword,
          token,
          isTermsAndPolicyAccepted: terms,
        },
        isPublic: true,
      },
    })
  }

  useEffect(() => {
    if (data?.passwordReset?.success) {
      const passwordSetSuccessfullyText = formatMessage({
        id: "password::set::passwordSetSuccessfully",
        defaultMessage: "Your password is set successfully",
      })

      success(passwordSetSuccessfullyText)
      setTimeout(() => {
        navigate("/")
      }, 2000)
    }

    if (data?.passwordReset?.errors) {
      let errorMessage = ""

      Object.values(data?.passwordReset?.errors).forEach((t) =>
        t.forEach((s) => (errorMessage += "\n" + s.message))
      )

      error(errorMessage)
    }

    if (errors) {
      error(errors.message)
    }
  }, [data, errors])

  const continueText = formatMessage({
    id: "set::password::continueToTMU",
    defaultMessage: "Continue to TrustMeUp",
  })

  const descriptionText = formatMessage({
    id: "set::password::description",
    defaultMessage: "Enter a password",
  })

  const passwordsMatchErrorText = formatMessage({
    id: "set::password::passwordMatch",
    defaultMessage: "Passwords should match",
  })

  const handleSubmit = (values, form) => {
    setPassword(values.password, values.repassword)
  }

  const onClick = (values) => {
    setPassword(values.password, values.repassword, values.terms)
  }

  return (
    <>
      <StyledPageContainer>
        <StyledLogoContainer>
          <FooterLogoText
            width="180"
            height="90"
            flat={false}
            fill={theme?.colors?.blue}
          />
        </StyledLogoContainer>
        <StyledTitle>
          <FormattedMessage
            id="set::password::title"
            defaultMessage="Set Your Password"
            tagName="h1"></FormattedMessage>
        </StyledTitle>

        <StyledDescription>
          <div
            dangerouslySetInnerHTML={{
              __html: descriptionText,
            }}
          />
        </StyledDescription>
        <Formik
          enableReinitialize={true}
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={handleSubmit}>
          {({
            values,
            handleChange,
            handleBlur,
            handleReset,
            touched,
            errors,
            setFieldTouched,
          }) => {
            return (
              <>
                <StyledFormContainer>
                  <StyledForm onSubmit={handleSubmit}>
                    <StyledInputContainer>
                      <StyledIconContainer>
                        <FontAwesomeIcon icon={faLockAlt} />
                      </StyledIconContainer>
                      <Field
                        data-testid="input-password"
                        id="password"
                        name="password"
                        type="password"
                        component={TextInput}
                        className="float-container"
                        label={formatMessage({
                          id: "set::password::password",
                          defaultMessage: "Your password",
                        })}
                        value={values?.password ?? ""}
                        onChange={(e) => {
                          setFieldTouched("password")
                          handleChange(e)
                        }}
                        onBlur={(e) => {
                          setFieldTouched("password")
                          handleBlur(e)
                        }}
                      />
                    </StyledInputContainer>
                    {touched?.password && errors?.password && (
                      <div className="input-feedback">{errors.password}</div>
                    )}
                    <StyledInputContainer>
                      <StyledIconContainer>
                        <FontAwesomeIcon icon={faLockAlt} />
                      </StyledIconContainer>
                      <Field
                        data-testid="input-repassword"
                        id="repassword"
                        name="repassword"
                        type="password"
                        component={TextInput}
                        className="float-container"
                        label={formatMessage({
                          id: "set::password::confirmPassword",
                          defaultMessage: "Confirm your password",
                        })}
                        value={values?.repassword ?? ""}
                        onChange={(e) => {
                          setFieldTouched("repassword")
                          handleChange(e)
                        }}
                        onBlur={(e) => {
                          setFieldTouched("repassword")
                          handleBlur(e)
                        }}
                        onPaste={(e) => {
                          e.preventDefault()
                          return false
                        }}
                      />
                    </StyledInputContainer>

                    {touched?.repassword && errors?.repassword && (
                      <div className="input-feedback">{errors.repassword}</div>
                    )}
                    {touched?.repassword &&
                      touched?.password &&
                      values?.password !== values?.repassword && (
                        <div className="input-feedback">
                          {passwordsMatchErrorText}
                        </div>
                      )}
                  </StyledForm>

                  <StyledContinueButtonContainer>
                    <Button
                      label="Continue"
                      type="submit"
                      color="red"
                      onClick={() => {
                        onClick(values)
                        handleReset()
                      }}
                      disabled={
                        errors?.password ||
                        !values?.password?.length ||
                        errors?.repassword ||
                        !values?.repassword?.length ||
                        values?.password !== values?.repassword
                      }>
                      {continueText}
                    </Button>
                  </StyledContinueButtonContainer>
                </StyledFormContainer>
              </>
            )
          }}
        </Formik>
      </StyledPageContainer>
    </>
  )
}

export default injectIntl(SetPassword)
