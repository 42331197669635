import styled from "@emotion/styled"
import { css } from "@emotion/react"

export const StyledNameEmailFieldSet = styled.div`
  margin-top: 1rem;
  flex: 2 1 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & > p {
    color: red;
    align-self: center;
    padding-bottom: 2.5rem;
    cursor: pointer;
  }

  .react-select__option {
    color: ${(p) => p.theme.colors.primaryTextColor};
    background-color: ${(p) => p.theme.colors.white};
    &:hover {
      background-color: rgba(10, 157, 189, 0.5);
    }
  }
`

export const StyledField = styled.div`
  ${(p) =>
    p.gridArea &&
    css`
      grid-area: ${p.gridArea};
    `}
`
