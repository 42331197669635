import React, { useRef, Fragment } from "react"
import { FormattedMessage, Link, navigate, useIntl } from "gatsby-plugin-intl"
import { faHandHoldingHeart } from "@fortawesome/pro-solid-svg-icons/faHandHoldingHeart"
import { faBagShopping } from "@fortawesome/free-solid-svg-icons/faBagShopping"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { StyledRewardForGood } from "./index.styles"
import {
  StyledSuccessDetails,
  StyledSuccessDetailsTitle,
  StyledSuccessDetailsText,
  StyledDiscoverPACsButton,
  StyledVideoContainerBox,
} from "./index.styles"
const SuccessStories = () => {
  const ref = useRef()
  const { locale } = useIntl()

  const videoUrl =
    locale === "it"
      ? "https://player.vimeo.com/video/762698896?h=aa0c550553"
      : locale === "es"
      ? "https://player.vimeo.com/video/762682795?h=0a3e514d72"
      : "https://player.vimeo.com/video/762682855?h=4f528c6eca"
  return (
    <StyledRewardForGood
      ref={ref}
      data-testid="slice-reward-for-good"
      id="rewardForGood">
      <StyledVideoContainerBox>
        <iframe src={videoUrl} />
      </StyledVideoContainerBox>
      <StyledSuccessDetails>
        <StyledSuccessDetailsTitle>
          <FormattedMessage
            id="sliceRewardForGood::title"
            defaultMessage="Donate at ZERO cost without changing your habits"
          />
        </StyledSuccessDetailsTitle>
        <StyledSuccessDetailsText>
          <FontAwesomeIcon icon={faHandHoldingHeart} />
          <FormattedMessage
            id="sliceRewardForGood::subtitle1"
            defaultMessage="Your donation is rewarded 100% in PAC discounts for purchases: Donate-Receive-Make Shopping!"
          />
        </StyledSuccessDetailsText>
        <StyledSuccessDetailsText>
          <FontAwesomeIcon icon={faBagShopping} />
          <FormattedMessage
            id="sliceRewardForGood::subtitle2"
            defaultMessage="With each purchase you can donate the PAC discount for free to your favorite association: Donate By Shopping!"
          />
        </StyledSuccessDetailsText>
        <StyledDiscoverPACsButton
          variant="link"
          text={
            <FormattedMessage
              id="sliceRewardForGood::subtitle3"
              defaultMessage="Discover PACs"
            />
          }
          onClick={() => {
            navigate("/pacs")
          }}
        />
      </StyledSuccessDetails>
    </StyledRewardForGood>
  )
}

export default SuccessStories
