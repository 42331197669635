import styled from "@emotion/styled"
import { css } from "@emotion/react"
import {
  StyledCard as CommonStyledCard,
  StyledImageBox as CommonStyledImageBox,
  StyledCardInfo as CommonStyledCardInfo,
  StyledCardTitle as CommonStyledCardTitle,
  StyledCardSubtitle as CommonStyledCardSubtitle,
  StyledPartnerTitle as CommonStyledPartnerTitle,
  StyledPartnerDetails as CommonStyledPartnerDetails,
  StyledPartnerLogo as CommonStyledPartnerLogo,
  StyledPartnerTitleText as CommonStyledPartnerTitleText,
  StyledPACMultiplier as CommonStyledPACMultiplier,
} from "../index.styles"

export const StyledCard = styled(CommonStyledCard)`
  border-radius: 0.25rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    ${(p) =>
      p.size === "default" &&
      css`
        //mainpage default tablet view
        width: 15rem;
        height: 22.0625rem;
        grid-template-rows: 9.1875rem auto 3.25rem;
      `}
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    ${(p) =>
      p.size === "default" &&
      css`
        //mainpage default tablet/desktop view
        width: 15rem;
        height: 22.0625rem;
        grid-template-rows: 9.1875rem auto 3.25rem;
      `}
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    ${(p) =>
      p.size === "default" &&
      css`
        //mainpage default wide view
        width: 25.875rem;
        height: 31.9375rem;
        grid-template-rows: 14.5625rem auto 4.375rem;
        .stats {
          padding: 1rem;
        }
      `}
  }
`

export const StyledOfferBox = styled(StyledCard)``

export const StyledImageBox = styled(CommonStyledImageBox)``

export const StyledDoublePacsTooltip = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 0;

  .campaignCard {
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.15);
    opacity: 1 !important;
  }
  .campaignCardListing {
    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      width: 13.75rem;
    }
    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      width: unset;
    }
  }
`

export const StyledCardInfo = styled(CommonStyledCardInfo)`
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    ${(p) =>
      p.size === "default" &&
      css`
        //mainpage default wide view
        padding: 1rem;
      `}
  }
`

export const StyledCardTitle = styled(CommonStyledCardTitle)``

export const StyledCardSubtitle = styled(CommonStyledCardSubtitle)``

export const StyledPartnerTitle = styled(CommonStyledPartnerTitle)``

export const StyledPartnerDetails = styled(CommonStyledPartnerDetails)``

export const StyledPartnerLogo = styled(CommonStyledPartnerLogo)``

export const StyledPartnerTitleText = styled(CommonStyledPartnerTitleText)``

export const StyledPACMultiplier = styled(CommonStyledPACMultiplier)``
