import { Date, Logo, Money } from "@tmu/components/common"
import { Spacer } from "@tmu/global/page-addons/detail-page.styles"
import theme from "@tmu/global/theme"
import { FormattedMessage } from "gatsby-plugin-intl"
import {
  StyledBottomContainer,
  StyledDonationAmount,
  StyledDonor,
  StyledHeader,
  StyledLabel,
  StyledLine,
  StyledPacsContainer,
  StyledPageWrapper,
  StyledRow,
  StyledValue,
  StyledValueItem,
} from "./index.styles"
import { useDefaultMerchant } from "@tmu/src/hooks"

export const SaleDetail = ({ order, rowData }) => {
  const tableData = rowData?.original
  const offerName = tableData?.offerName

  const { isMerchantInternal } = useDefaultMerchant()
  return (
    <StyledPageWrapper>
      <Spacer top={0.25} />
      <StyledHeader>
        <FormattedMessage
          id="dashboard::saleDetail::title"
          defaultMessage="Sale"
        />
      </StyledHeader>
      <Spacer top={3} />
      <StyledDonationAmount>
        <Money value={tableData?.total} />
      </StyledDonationAmount>
      <Spacer top={1} />
      <StyledDonor>{offerName}</StyledDonor>
      <Spacer top={3} />
      <StyledBottomContainer>
        <StyledRow>
          <StyledLabel className="align-left">
            <FormattedMessage
              id="dashboard::tableHeader::dateAndTime"
              defaultMessage="Date / Time"
            />
          </StyledLabel>
          <StyledValue className="align-right">
            <Date value={tableData?.date} showYear={true} second="numeric" />
          </StyledValue>
        </StyledRow>
        <StyledLine />
        {isMerchantInternal ? (
          <StyledRow>
            <StyledLabel className="align-left">
              <FormattedMessage
                id="dashboard::tableHeader::quantity"
                defaultMessage="Quantity"
              />
            </StyledLabel>
            <StyledValue className="align-right">
              {tableData?.sale?.cartItems?.edges?.[0]?.node?.quantity}
            </StyledValue>
          </StyledRow>
        ) : (
          <>
            {tableData?.sale?.cartItems?.edges?.map((item) => {
              return (
                <StyledRow>
                  <StyledLabel className="align-left">
                    {item?.node?.offer?.name}
                  </StyledLabel>
                  <StyledValue className="align-right">
                    <StyledValueItem>
                      <Money value={item.node?.frozenPrice} />
                    </StyledValueItem>
                    <StyledValueItem>{item.node?.quantity}</StyledValueItem>
                    <StyledValueItem>
                      <Money value={item.node?.frozenTotal} />
                    </StyledValueItem>
                  </StyledValue>
                </StyledRow>
              )
            })}
          </>
        )}

        <StyledLine />
        <StyledRow>
          <StyledLabel>
            <div className="header-text header-icon align-left">
              <FormattedMessage
                id="dashboard::tableHeader::pacsSpend"
                defaultMessage="PACs Spend"
              />
            </div>
          </StyledLabel>
          <StyledValue className="align-right">
            <StyledPacsContainer>
              {Number(tableData?.pacAmount) > 0 && "-"}
              <Money value={tableData?.pacAmount} currency={false} />
              <Logo
                className="pac-icon"
                width={19}
                height={24}
                fill={theme?.colors?.carrot}
              />
            </StyledPacsContainer>
          </StyledValue>
        </StyledRow>
        <StyledLine />
      </StyledBottomContainer>
    </StyledPageWrapper>
  )
}

export default SaleDetail
