import React from "react"
import { Wrapper, Wallet, Label } from "./index.styles"
import { Logo, Money } from "@tmu/components/common"
import theme from "@tmu/global/theme"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"

const WalletBox = ({ amount }) => {
  const { isWide } = getAllScreenTypes()

  return (
    <Wrapper>
      <Wallet>
        <Label>
          <Logo
            className="pac-icon"
            width={isWide ? 27 : 21}
            height={isWide ? 34 : 26}
            fill={theme?.colors?.carrot}
          />
          <span className="amount">
            <Money value={amount} currency={false} />
          </span>
        </Label>
      </Wallet>
    </Wrapper>
  )
}

export default WalletBox
