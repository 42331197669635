import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { StyledDevelopersTableHeader } from "./index.styles"

const DevelopersTableHeader = () => {
  const { isTablet } = getAllScreenTypes()

  return (
    <StyledDevelopersTableHeader>
      <div className="caption">
        <FormattedMessage
          id="dashboard::developers::tableHeader::name"
          defaultMessage="Name"
        />
      </div>
      {isTablet && (
        <div className="caption">
          <FormattedMessage
            id="dashboard::developers::tableHeader::clientId"
            defaultMessage="Client ID"
          />
        </div>
      )}
      <div className="caption">
        <FormattedMessage
          id="dashboard::developers::tableHeader::password"
          defaultMessage="Password"
        />
      </div>
      <div className="caption">
        <FormattedMessage
          id="dashboard::developers::tableHeader::revoke"
          defaultMessage="Revoke"
        />
      </div>
    </StyledDevelopersTableHeader>
  )
}

export default DevelopersTableHeader
