import { useLazyQuery, useMutation } from "@apollo/client"
import {
  CREATE_ORDER_CHECKOUT_MUTATION,
  DISCARD_ORDER_CHECKOUT_MUTATION,
} from "../apollo/storefront/mutations/cart"
import useCurrency from "./useCurrency"
import { useApolloApiClients } from "../apollo/client"
import { ORDER_DETAILS } from "./useAllOrders"

const useOrder = (options) => {
  const { storefrontClient } = useApolloApiClients()

  const [callCreateNewOrder, { error, loading, data }] = useMutation(
    CREATE_ORDER_CHECKOUT_MUTATION
  )
  const [callOrder, orderDetailData] = useLazyQuery(ORDER_DETAILS)

  const [callDiscardOrder, { error: discardError, data: discardData }] =
    useMutation(DISCARD_ORDER_CHECKOUT_MUTATION)

  const { currency } = useCurrency()

  const createNewOrder = async (params) => {
    return callCreateNewOrder({
      variables: {
        input: {
          ...params?.variables?.input,
          currency,
        },
      },
      client: storefrontClient,
    })
  }

  const discardOrder = async (params) => {
    return callDiscardOrder({
      variables: {
        ...params?.variables,
      },
      client: storefrontClient,
    })
  }

  return {
    error,
    data,
    createNewOrder,
    createNewOrderLoading: loading,
    discardOrder,
    discardError,
    discardData,
    callOrder,
    orderDetailData,
    orderDetail: orderDetailData?.data?.order,
  }
}

export default useOrder
