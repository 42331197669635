import queryString from "query-string"
import React, { Fragment, useRef, useCallback, useEffect } from "react"
import {
  StyledBigText,
  StyledDescription,
  StyledToggleWrapper,
} from "./index.styles"

import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { useAuth } from "@tmu/src/hooks"

import { CommonToggle } from "@tmu/components/common"
import { useFormikContext } from "formik"

import { FavoriteNGOSection } from "../dashboard/dashboardCommon"
import { useState } from "react"

const CharityForm = ({ offer, step, setStep }) => {
  const paramOptions = { arrayFormat: "comma" }
  const params = queryString.parse(location.search, paramOptions)
  const isMerchantOnline = params?.type === "online"
  const isMerchantOffline = params?.type === "offline"
  const isMerchantInternal = params?.type === "internal"
  const isMerchantCorporate = false
  const { values, setFieldValue } = useFormikContext()
  const { user } = useAuth()
  const { formatMessage } = useIntl()

  const favNgoRef = useRef()

  const [favoriteNGO, setFavoriteNGO] = useState()

  return (
    <>
      <StyledBigText>
        <FormattedMessage
          id="merchant::selfRegistrationForm::supportCharityTitleText"
          defaultMessage="Choose a charity to support"
        />
      </StyledBigText>

      <StyledDescription>
        <FormattedMessage
          id="merchant::selfRegistrationForm::descriptionCharity"
          defaultMessage="Make charity your favourite and it will become default choice of donation for your customers. You can change it in your account anytime."
        />
      </StyledDescription>
      <FavoriteNGOSection
        ref={favNgoRef}
        me={user}
        hideLabel={true}
        favoriteNGO={favoriteNGO}
        setFavoriteNGO={setFavoriteNGO}
        forceToDesktopView
        onClose={() => {}}
        onFavChange={(charityId, charity) => {
          setFieldValue("charity", charityId)
        }}
      />

      <StyledToggleWrapper>
        <CommonToggle
          defaultChecked={values.allowUsersToSelectADifferentCampaign}
          handleChange={() =>
            setFieldValue(
              "allowUsersToSelectADifferentCampaign",
              !values.allowUsersToSelectADifferentCampaign
            )
          }
        />
        <FormattedMessage
          id="dashboard::accountForm::charitySupport"
          defaultMessage="Buyer can change charity to support"
        />
      </StyledToggleWrapper>
    </>
  )
}

export default CharityForm
