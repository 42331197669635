import React, { useState } from "react"
import PropTypes from "prop-types"
import { Formik, FastField } from "formik"
import * as Yup from "yup"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import {
  TextInput,
  FieldError,
  Tabs,
  TabList,
  Panel,
  Button,
} from "@tmu/components/common"
import { ImageSelector } from "@tmu/components/dashboard/dashboardCommon"
import {
  StyledPageContent,
  StyledForm,
} from "@tmu/global/page-addons/dashboard.styles"
import { Tab } from "@tmu/components/dashboard/dashboardCommon"
import { title, description } from "@tmu/utils/validation"

function Updates({ isEditPage, handleSubmitForm, selectedUpdate }) {
  const { formatMessage } = useIntl()
  const [activeLangs, setActiveLangs] = useState({
    english: !!(selectedUpdate?.titleEn || selectedUpdate?.title),
    italian: !!selectedUpdate?.titleIt,
    spanish: !!selectedUpdate?.titleEs,
  })
  const [tabIndex, setTabIndex] = useState(0)

  const initialValues = {
    id: selectedUpdate?.id || null,
    titleTabEn: selectedUpdate?.titleEn || selectedUpdate?.title || "",
    titleTabIt: selectedUpdate?.titleIt || "",
    titleTabEs: selectedUpdate?.titleEs || "",
    contentTabEn: selectedUpdate?.contentEn || selectedUpdate?.content || "",
    contentTabIt: selectedUpdate?.contentIt || "",
    contentTabEs: selectedUpdate?.contentEs || "",
    image: selectedUpdate?.image || "",
  }

  const validationSchema = Yup.object().shape({
    titleTabEn: activeLangs.english && title({ formatMessage }),
    contentTabEn: activeLangs.english && description({ formatMessage }),
    titleTabIt: activeLangs.italian && title({ formatMessage }),
    contentTabIt: activeLangs.italian && description({ formatMessage }),
    titleTabEs: activeLangs.spanish && title({ formatMessage }),
    contentTabEs: activeLangs.spanish && description({ formatMessage }),
  })
  const CampaignUpdatesTitle = formatMessage({
    id: "dashboard::campaignUpdates::title",
    defaultMessage: "Title",
  })
  const CampaignUpdatesContent = formatMessage({
    id: "dashboard::campaignUpdates::content",
    defaultMessage: "Content",
  })
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmitForm}>
      {({
        isSubmitting,
        values,
        setFieldValue,
        resetForm,
        handleSubmit,
        handleChange,
      }) => (
        <StyledForm className="modal-form">
          {isEditPage ? (
            <FormattedMessage
              id="dashboard::campaigns::createCampaignUpdateEdit"
              defaultMessage="Edit Campaign Update"
              tagName="h5"
            />
          ) : (
            <FormattedMessage
              id="dashboard::campaigns::createCampaignUpdate"
              defaultMessage="Create Campaign Update"
              tagName="h5"
            />
          )}
          <StyledPageContent>
            <Tabs
              selectedIndex={tabIndex}
              onSelect={(index) => setTabIndex(index)}>
              <TabList>
                <Tab
                  isActive={tabIndex === 0}
                  activeLangs={activeLangs}
                  setActiveLangs={setActiveLangs}>
                  <FormattedMessage
                    id="dashboard::campaignForm::English"
                    defaultMessage="English"
                  />
                </Tab>
                <Tab
                  isActive={tabIndex === 1}
                  activeLangs={activeLangs}
                  setActiveLangs={setActiveLangs}>
                  <FormattedMessage
                    id="dashboard::campaignForm::Italian"
                    defaultMessage="Italian"
                  />
                </Tab>
                <Tab
                  isActive={tabIndex === 2}
                  activeLangs={activeLangs}
                  setActiveLangs={setActiveLangs}>
                  <FormattedMessage
                    id="dashboard::campaignForm::Spanish"
                    defaultMessage="Spanish"
                  />
                </Tab>
              </TabList>
              <Panel>
                <FastField
                  id="titleTabEn"
                  name="titleTabEn"
                  value={values.titleTabEn}
                  component={TextInput}
                  label={CampaignUpdatesTitle}
                  className="float-container"
                  placeholder=""
                  onChange={handleChange}
                />
                <FieldError name="titleTabEn" autoFocus={true} />
                <FastField
                  id="contentTabEn"
                  name="contentTabEn"
                  label={CampaignUpdatesContent}
                  type="textarea"
                  component={TextInput}
                  className="float-container info-area full-width"
                  value={values.contentTabEn}
                  onChange={handleChange}
                  placeholder=""
                />
                <FieldError name="contentTabEn" autoFocus={true} />
              </Panel>
              <Panel>
                <FastField
                  id="titleTabIt"
                  name="titleTabIt"
                  value={values.titleTabIt}
                  component={TextInput}
                  label={CampaignUpdatesTitle}
                  className="float-container"
                  placeholder=""
                  onChange={handleChange}
                />
                <FieldError name="titleTabIt" autoFocus={true} />
                <FastField
                  id="contentTabIt"
                  name="contentTabIt"
                  label={CampaignUpdatesContent}
                  type="textarea"
                  component={TextInput}
                  className="float-container info-area full-width"
                  value={values.contentTabIt}
                  onChange={handleChange}
                  placeholder=""
                />
                <FieldError name="contentTabIt" autoFocus={true} />
              </Panel>
              <Panel>
                <FastField
                  id="titleTabEs"
                  name="titleTabEs"
                  value={values.titleTabEs}
                  component={TextInput}
                  label={CampaignUpdatesTitle}
                  className="float-container"
                  placeholder=""
                  onChange={handleChange}
                />
                <FieldError name="titleTabEs" autoFocus={true} />
                <FastField
                  id="contentTabEs"
                  name="contentTabEs"
                  label={CampaignUpdatesContent}
                  type="textarea"
                  component={TextInput}
                  className="float-container info-area full-width"
                  value={values.contentTabEs}
                  onChange={handleChange}
                  placeholder=""
                />
                <FieldError name="contentTabEs" autoFocus={true} />
              </Panel>
            </Tabs>
            <ImageSelector
              defaultValue={values?.image}
              onChange={(image) => setFieldValue("image", image)}
              onCropChange={(croppedImage) =>
                setFieldValue("image", croppedImage)
              }
              onReset={() =>
                setFieldValue("image", values?.defaultImage || null)
              }
            />
            <Button
              type="submit"
              label="Save"
              data-testid="btn-save"
              onClick={handleSubmit}>
              <FormattedMessage
                id="dashboard::campaigns::save"
                defaultMessage="Save"
              />
            </Button>
          </StyledPageContent>
        </StyledForm>
      )}
    </Formik>
  )
}

export default Updates

Updates.propTypes = {
  activeLangs: PropTypes.object,
  setActiveLangs: PropTypes.func,
  isEditPage: PropTypes.bool,
}
