import { useEffect, useState } from "react"
import { StepButton, StepInput, Wrapper } from "./index.styles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMinus } from "@fortawesome/free-solid-svg-icons/faMinus"
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus"
const NumericStepper = ({
  onChange,
  initialValue,
  disabled,
  minimumValue = 1,
  maximumValue,
  ...rest
}) => {
  const INITIAL_VALUE = initialValue ?? 1
  const [value, setValue] = useState(INITIAL_VALUE)

  const increment = () => {
    if (disabled) return
    if (!maximumValue || value < maximumValue) {
      setValue(value + 1)
    }
  }

  const decrement = () => {
    if (disabled) return
    if (value > minimumValue) {
      setValue(value - 1)
    }
  }

  useEffect(() => {
    if (typeof onChange === "function") onChange(value)
  }, [value])

  return (
    <Wrapper>
      <StepButton
        className={value === minimumValue ? "passive" : ""}
        onClick={decrement}>
        <FontAwesomeIcon className="icon" icon={faMinus} />
      </StepButton>
      <StepInput>{value}</StepInput>
      <StepButton
        className={value === maximumValue ? "passive" : ""}
        onClick={increment}>
        <FontAwesomeIcon className="icon" icon={faPlus} />
      </StepButton>
    </Wrapper>
  )
}

export default NumericStepper
