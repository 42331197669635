import React, { useEffect, useState, useMemo } from "react"
import { FormattedMessage, injectIntl, navigate } from "gatsby-plugin-intl"
import { useLazyQuery } from "@apollo/client"
import { useAuth } from "@tmu/hooks"
import {
  BlogCareerSection,
  Button,
  CountrySelectorFilter,
  Spinner,
} from "@tmu/components/common"
import { SliceCampaigns, SliceOffers } from "@tmu/components/home"
import { USWelcome, USPacs } from "@tmu/components/userspace"
import SEO from "@tmu/components/seo"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { isBrowser, isTokenExpired } from "@tmu/utils/auth"
import { get } from "@tmu/utils/storage"
import { QUERY_USERSPACE_PAGEDATA } from "@tmu/apollo/storefront/queries/userspace"
import {
  StyledPageContainer,
  StyledCenteredHeadline,
  StyledSliceHeader,
  StyledHeadlineLeft,
  StyledOffersSection,
  StyledCampaignsSection,
} from "@tmu/global/page-addons/user-space-page.styles"
import { getValueForLocale } from "@tmu/utils/string"
import { useAllOfferCategories } from "@tmu/src/hooks"

const UserSpacePage = ({ intl }) => {
  const { locale, formatMessage, defaultLocale } = intl
  const { user } = useAuth()
  const isAuthenticated = !isTokenExpired(get("token"))
  const [recommendedCampaigns, setRecommendedCampaigns] = useState([])
  const [worldwideCampaigns, setWorldwideCampaigns] = useState([])
  const [recentlyCreatedCampaigns, setRecentlyCreatedCampaigns] = useState([])
  const [repeatYourDonationCampaigns, setRepeatYourDonationCampaigns] =
    useState([])
  const [selectedCountry, setSelectedCountry] = useState()
  const [recommendedOffers1, setRecommendedOffers1] = useState([])
  const [recommendedOffers2, setRecommendedOffers2] = useState([])
  const [recommendedOffers3, setRecommendedOffers3] = useState([])
  const [newOffers, setNewOffers] = useState([])
  const [newStores, setNewStores] = useState([])
  const { isTablet } = getAllScreenTypes()
  const { organizedCategories } = useAllOfferCategories()

  const headLineOrTitle4Interested = useMemo(
    () =>
      !isTablet
        ? {
            headline: (
              <StyledSliceHeader>
                <h3>
                  {formatMessage({
                    id: "home::userSpace::interested",
                    defaultMessage: "You might be interested",
                  })}
                </h3>
                <Button
                  variant="link"
                  text={<FormattedMessage id="ui::all" defaultMessage="All" />}
                  size="big"
                  onClick={() => navigate("/campaigns?campaign=true")}
                />
              </StyledSliceHeader>
            ),
          }
        : {
            headline: (
              <StyledSliceHeader>
                <h3>
                  {formatMessage({
                    id: "home::userSpace::interested",
                    defaultMessage: "You might be interested",
                  })}
                </h3>
                <Button
                  variant="link"
                  text={
                    <FormattedMessage
                      id="ui::viewAllCampaigns"
                      defaultMessage="View all campaigns"
                    />
                  }
                  size="big"
                  onClick={() => navigate("/campaigns?campaign=true")}
                />
              </StyledSliceHeader>
            ),
          },
    [isTablet]
  )

  const redirectUrl = isBrowser
    ? window?.location?.pathname + window?.location?.search
    : "/"

  const campaignCategories =
    user?.userPreference?.campaignCategories?.edges.map((item) => item.node)
  const productCategories = user?.userPreference?.productCategories?.edges.map(
    (item) => item.node
  )

  const productList = [
    ...new Set(
      [
        productCategories?.[0]?.id,
        productCategories?.[1]?.id,
        productCategories?.[2]?.id,
        process.env.DEFAULT_OFFER_ID_1,
        process.env.DEFAULT_OFFER_ID_2,
        process.env.DEFAULT_OFFER_ID_3,
      ].filter((n) => n)
    ),
  ]

  var cat1 =
    organizedCategories
      ?.find((item) => item.id === productCategories?.[0]?.id)
      ?.children?.map((item) => item.id) || []

  var cat2 =
    organizedCategories
      ?.find((item) => item.id === productCategories?.[1]?.id)
      ?.children?.map((item) => item.id) || []

  var cat3 =
    organizedCategories
      ?.find((item) => item.id === productCategories?.[2]?.id)
      ?.children?.map((item) => item.id) || []

  const [callPageData, { loading: pageDataLoading, data: pageData, called }] =
    useLazyQuery(QUERY_USERSPACE_PAGEDATA, {
      variables: {
        locale,
        country: selectedCountry?.id,
        campaignCategory1: campaignCategories?.[0]?.id || "",
        campaignCategory2: campaignCategories?.[1]?.id || "",
        campaignCategory3: campaignCategories?.[2]?.id || "",
        offerCategory1: productList?.[0] || "",
        offerCategory2: productList?.[1] || "",
        offerCategory3: productList?.[2] || "",
        offerCategories1: [productList?.[0], ...cat1],
        offerCategories2: [productList?.[1], ...cat2],
        offerCategories3: [productList?.[2], ...cat3],
      },
    })

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(`/signin?next=${redirectUrl}`)
    } else if (!called && !pageData) callPageData()
  }, [isAuthenticated])

  useEffect(() => {
    if (pageDataLoading || !pageData) {
      return
    }

    const hasCampaignCategory =
      !!campaignCategories?.[0]?.id &&
      !!campaignCategories?.[1]?.id &&
      !!campaignCategories?.[2]?.id

    const recommendedCampaignList = hasCampaignCategory
      ? [
          ...(pageData?.recommendedCampaigns1?.edges || []),
          ...(pageData?.recommendedCampaigns2?.edges || []),
          ...(pageData?.recommendedCampaigns3?.edges || []),
        ]
      : [...(pageData?.featuredCampaigns?.edges || [])]

    var uniq = {}
    var uniqueRecommendedCampaignList = recommendedCampaignList.filter(
      (obj) => !uniq[obj.node?.id] && (uniq[obj.node?.id] = true)
    )

    setRecommendedCampaigns(uniqueRecommendedCampaignList)
    setWorldwideCampaigns(pageData?.worldwideCampaigns?.edges || [])
    setRecentlyCreatedCampaigns(pageData?.recentlyCreatedCampaigns?.edges)
    setRepeatYourDonationCampaigns(pageData?.repeatYourDonation?.edges)
    setRecommendedOffers1(pageData?.recommendedOffers1?.edges || [])
    setRecommendedOffers2(pageData?.recommendedOffers2?.edges || [])
    setRecommendedOffers3(pageData?.recommendedOffers3?.edges || [])
    setNewOffers(pageData?.newOffers?.edges || [])
    setNewStores(pageData?.newStores?.edges || [])
  }, [pageData])

  const handleSelectCountryAction = (country) => {
    if (selectCountry) {
      selectCountry(country)
    }
  }

  const selectCountry = (countryShortCode) => {
    setSelectedCountry(countryShortCode)
  }

  const recommendedCampaignsSlice = useMemo(
    () => (
      <SliceCampaigns
        campaigns={recommendedCampaigns}
        {...headLineOrTitle4Interested}
      />
    ),
    [recommendedCampaigns]
  )
  const worldwideCampaignsSlice = useMemo(
    () => (
      <SliceCampaigns
        withDots
        campaigns={worldwideCampaigns}
        cardVariant="wide-campaign"
        headline={
          <StyledCenteredHeadline>
            <h3>
              {formatMessage({
                id: "home::userSpace::worldwideCauses",
                defaultMessage: "Current worldwide causes",
              })}
            </h3>
          </StyledCenteredHeadline>
        }
      />
    ),
    [worldwideCampaigns]
  )

  const recentlyCreatedCampaignsSlice = useMemo(
    () => (
      <SliceCampaigns
        campaigns={recentlyCreatedCampaigns}
        headline={
          <StyledSliceHeader>
            <h3>
              {selectedCountry?.id
                ? formatMessage(
                    {
                      id: "home::userSpace::recentlyCreatedIn",
                      defaultMessage: "Recently created campaigns in {country}",
                    },
                    { country: selectedCountry?.description }
                  )
                : formatMessage({
                    id: "home::userSpace::recentlyCreated",
                    defaultMessage: "Recently created campaigns",
                  })}
            </h3>
            <CountrySelectorFilter
              handleSelect={handleSelectCountryAction}
              activeCountry={selectedCountry?.description || user?.country}
            />
          </StyledSliceHeader>
        }
      />
    ),
    [recentlyCreatedCampaigns]
  )
  const repeatYourDonationCampaignsSlice = useMemo(
    () => (
      <SliceCampaigns
        withDots
        campaigns={repeatYourDonationCampaigns}
        cardVariant="wide-campaign"
        headline={
          <StyledCenteredHeadline>
            <h3>
              {formatMessage({
                id: "home::userSpace::repeatDonation",
                defaultMessage: "Repeat your donation",
              })}
            </h3>
          </StyledCenteredHeadline>
        }
      />
    ),
    [repeatYourDonationCampaigns]
  )
  const recommendedOffers1Slice = useMemo(
    () => (
      <SliceOffers
        withDots
        offers={recommendedOffers1}
        headline={
          <StyledSliceHeader carrot>
            <h3>
              {getValueForLocale(
                pageData?.offerCategory1,
                "name",
                locale,
                defaultLocale
              )}
            </h3>{" "}
            <Button
              variant="link"
              text={
                <FormattedMessage
                  id="ui::viewAll"
                  defaultMessage="View all"
                  values={{
                    name: getValueForLocale(
                      pageData?.offerCategory1,
                      "name",
                      locale,
                      defaultLocale
                    ),
                  }}
                />
              }
              size="big"
              onClick={() =>
                navigate(
                  `/offers?offer=true&offerCategories=${pageData?.offerCategory1?.slug.toLowerCase()}&store=true`
                )
              }
            />
          </StyledSliceHeader>
        }
      />
    ),
    [recommendedOffers1]
  )
  const recommendedOffers2Slice = useMemo(
    () => (
      <SliceOffers
        withDots
        offers={recommendedOffers2}
        headline={
          <StyledSliceHeader carrot>
            <h3>
              {getValueForLocale(
                pageData?.offerCategory2,
                "name",
                locale,
                defaultLocale
              )}
            </h3>
            <Button
              variant="link"
              text={
                <FormattedMessage
                  id="ui::viewAll"
                  defaultMessage="View all"
                  values={{
                    name: getValueForLocale(
                      pageData?.offerCategory2,
                      "name",
                      locale,
                      defaultLocale
                    ),
                  }}
                />
              }
              size="big"
              onClick={() =>
                navigate(
                  `/offers?offer=true&offerCategories=${pageData?.offerCategory2?.slug.toLowerCase()}&store=true`
                )
              }
            />
          </StyledSliceHeader>
        }
      />
    ),
    [recommendedOffers2]
  )
  const recommendedOffers3Slice = useMemo(
    () => (
      <SliceOffers
        withDots
        offers={recommendedOffers3}
        headline={
          <StyledSliceHeader carrot>
            <h3>
              {getValueForLocale(
                pageData?.offerCategory3,
                "name",
                locale,
                defaultLocale
              )}
            </h3>{" "}
            <Button
              variant="link"
              text={
                <FormattedMessage
                  id="ui::viewAll"
                  defaultMessage="View all"
                  values={{
                    name: getValueForLocale(
                      pageData?.offerCategory3,
                      "name",
                      locale,
                      defaultLocale
                    ),
                  }}
                />
              }
              size="big"
              onClick={() =>
                navigate(
                  `/offers?offer=true&offerCategories=${pageData?.offerCategory3?.slug.toLowerCase()}&store=true`
                )
              }
            />
          </StyledSliceHeader>
        }
      />
    ),
    [recommendedOffers3]
  )
  const newStoresSlice = useMemo(
    () => (
      <SliceOffers
        className="hide-chevron"
        withDots
        offers={newStores}
        headline={
          <StyledSliceHeader carrot>
            <h3>
              {formatMessage({
                id: "home::userSpace::newStores",
                defaultMessage: "New Stores",
              })}
            </h3>{" "}
            <Button
              variant="link"
              text={
                <FormattedMessage
                  id="home::userSpace::viewAllStores"
                  defaultMessage="View all stores"
                />
              }
              size="big"
              onClick={() => navigate("/offers?offer=false&store=true")}
            />
          </StyledSliceHeader>
        }
      />
    ),
    [newStores]
  )
  const newOffersSlice = useMemo(
    () => (
      <SliceOffers
        className="hide-chevron"
        withDots
        offers={newOffers}
        headline={
          <StyledSliceHeader carrot>
            <h3>
              {formatMessage({
                id: "home::userSpace::newOffers",
                defaultMessage: "New Offers",
              })}
            </h3>{" "}
            <Button
              variant="link"
              text={
                <FormattedMessage
                  id="home::userSpace::viewAllOffers"
                  defaultMessage="View all offers"
                />
              }
              size="big"
              onClick={() => navigate("/offers?offer=true&store=false")}
            />
          </StyledSliceHeader>
        }
      />
    ),
    [newOffers]
  )
  if (!isAuthenticated) return <Spinner condensed />
  return (
    <>
      <SEO
        lang={locale}
        title={formatMessage({
          id: "home::userSpace::pageTitle",
          defaultMessage: "User Space Page",
        })}
      />
      <StyledPageContainer>
        <StyledCampaignsSection>
          <USWelcome />
        </StyledCampaignsSection>
        <>
          <StyledCampaignsSection>
            {recommendedCampaigns?.length ? (
              recommendedCampaignsSlice
            ) : (
              <Spinner condensed />
            )}
            {worldwideCampaigns?.length ? worldwideCampaignsSlice : null}
            {recentlyCreatedCampaigns?.length ? (
              <>{recentlyCreatedCampaignsSlice}</>
            ) : null}
            {repeatYourDonationCampaigns?.length
              ? repeatYourDonationCampaignsSlice
              : null}
          </StyledCampaignsSection>
          {recommendedCampaigns?.length ? <USPacs me={user} /> : null}
          {(pageData?.offerCategory1 ||
            pageData?.offerCategory2 ||
            pageData?.offerCategory3) &&
          (recommendedOffers1?.length ||
            recommendedOffers2?.length ||
            recommendedOffers3?.length) ? (
            <>
              <StyledOffersSection>
                <>
                  <StyledHeadlineLeft>
                    {formatMessage({
                      id: "home::userSpace::yourPersonalRecommendations",
                      defaultMessage: "Your personal recommendations",
                    })}
                  </StyledHeadlineLeft>
                  {recommendedOffers1?.length ? recommendedOffers1Slice : null}
                  {recommendedOffers2?.length ? recommendedOffers2Slice : null}
                  {recommendedOffers3?.length ? recommendedOffers3Slice : null}
                  {newStores?.length ? (
                    <StyledHeadlineLeft>
                      {formatMessage({
                        id: "home::userSpace::newStoresAndOffers",
                        defaultMessage: "New PAC stores and offers",
                      })}
                    </StyledHeadlineLeft>
                  ) : null}
                  {newStores?.length ? newStoresSlice : null}
                  {newOffers?.length ? newOffersSlice : null}
                </>
              </StyledOffersSection>
              <BlogCareerSection />
            </>
          ) : null}
        </>
      </StyledPageContainer>
    </>
  )
}

export default injectIntl(UserSpacePage)
