import { useMutation } from "@apollo/client"
import { useIntl } from "gatsby-plugin-intl"
import { useApolloApiClients } from "@tmu/apollo/client"
import { GENERATE_SUPPORTER_REPORT_MUTATION } from "@tmu/apollo/storefront/mutations/user"
import { GENERATE_MERCHANT_CAMPAIGN_REPORT_MUTATION } from "@tmu/apollo/storefront/mutations/merchant"
import { useToast } from "@tmu/hooks"

const useDonorList = () => {
  const { storefrontClient, merchantClient } = useApolloApiClients()
  const { error: errorToast } = useToast()
  const { formatMessage } = useIntl()

  const [getSupporterDonorList] = useMutation(
    GENERATE_SUPPORTER_REPORT_MUTATION,
    {
      client: storefrontClient,
      onCompleted: (data) => {
        if (data?.supporterReport?.supporterReport?.report) {
          window.open(data?.supporterReport?.supporterReport?.report, "_blank")
        } else
          errorToast(
            formatMessage({
              id: "dashboard::campaignForm::errorMessage",
              defaultMessage: "An error occurred",
            })
          )
      },
    }
  )

  const [getOfflineMerchantDonorList] = useMutation(
    GENERATE_MERCHANT_CAMPAIGN_REPORT_MUTATION,
    {
      client: merchantClient,
      onCompleted: (data) => {
        if (
          data?.generateMerchantCampaignReport?.merchantCampaignReport?.report
        ) {
          window.open(
            data?.generateMerchantCampaignReport?.merchantCampaignReport
              ?.report,
            "_blank"
          )
        } else
          errorToast(
            formatMessage({
              id: "dashboard::campaignForm::errorMessage",
              defaultMessage: "An error occurred",
            })
          )
      },
    }
  )

  return {
    getSupporterDonorList,
    getOfflineMerchantDonorList,
  }
}

export default useDonorList
