import React from "react"
import {
  Tab,
  Tabs,
  TabList,
  Panel,
  SocialProfileCard,
  MediaSlider,
} from "@tmu/components/common"
import { FormattedMessage } from "gatsby-plugin-intl"
import {
  StyledAboutTabs,
  StyledPageWrapper,
  StyledPageLeft,
  StyledPageRight,
  StyledWrapper,
  StyledHeading,
  StyledGalleryContainer,
} from "./index.styles"

const AboutStore = ({ aboutText, storeSocialLinks, terms, images, taxId }) => {
  return (
    <>
      <StyledWrapper>
        <StyledHeading>
          <StyledPageWrapper>
            <StyledPageLeft>
              <StyledAboutTabs>
                <Tabs>
                  <div className="tab">
                    <TabList>
                      {aboutText ? (
                        <Tab>
                          <FormattedMessage
                            id="aboutStore::about"
                            defaultMessage="About"
                          />
                        </Tab>
                      ) : null}
                      {terms ? (
                        <Tab>
                          <FormattedMessage
                            id="aboutStore::termsAndConditions"
                            defaultMessage="Terms And Conditions"
                          />
                        </Tab>
                      ) : null}
                    </TabList>
                  </div>
                  {aboutText ? (
                    <Panel>
                      {images?.length > 0 && (
                        <StyledGalleryContainer>
                          <MediaSlider
                            images={images}
                            altName={`about-store-${images}`}
                          />
                        </StyledGalleryContainer>
                      )}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: aboutText,
                        }}
                      />
                    </Panel>
                  ) : null}
                  {terms ? (
                    <Panel>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: terms,
                        }}
                      />
                    </Panel>
                  ) : null}
                </Tabs>
              </StyledAboutTabs>
            </StyledPageLeft>
            <StyledPageRight>
              <SocialProfileCard
                storeSocialLinks={storeSocialLinks}
                taxId={taxId}
              />
            </StyledPageRight>
          </StyledPageWrapper>
        </StyledHeading>
      </StyledWrapper>
    </>
  )
}

export default AboutStore
