import React from "react"
import { FormattedMessage, navigate } from "gatsby-plugin-intl"
import { faXmark } from "@fortawesome/free-solid-svg-icons/faXmark"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, CustomModal } from "@tmu/components/common"
import { modalStyles } from "@tmu/global/GlobalStyle"
import { StyledModalContainer, StyledModalHeader } from "./index.styles"
import theme from "@tmu/src/global/theme"

export const LeaveModal = ({
  isLeaveModalOpen,
  handleLeaveModalClose,
  isSupporter,
  isMerchantOffline,
  onConfirm,
  isMerchantCorporate,
  charity,
  defaultSupporterCause,
}) => {
  return (
    <CustomModal
      isOpen={isLeaveModalOpen}
      style={{
        ...modalStyles,
        content: { ...modalStyles.content, border: "unset" },
      }}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}>
      <StyledModalContainer>
        <StyledModalHeader>
          <FormattedMessage
            id="dashboard::common::sureToLeave"
            defaultMessage="Sure, you want to leave?"
            tagName="h4"
          />
          <Button
            data-testid="btn-modal-close"
            variant="text"
            onClick={handleLeaveModalClose}
            type="button">
            <FontAwesomeIcon icon={faXmark} />
          </Button>
        </StyledModalHeader>
        <FormattedMessage
          id="dashboard::common::leaveModelText"
          defaultMessage="If you leave, all campaign creation progress will be lost."
          tagName="p"
        />
        <Button
          data-testid="btn-modal-leave"
          color="blue"
          label="Leave"
          style={{ backgroundColor: theme.colors.pacificBlue }}
          className="modal-leave"
          onClick={() => {
            if (typeof onConfirm === "function") {
              onConfirm()
            } else {
              isMerchantOffline || isMerchantCorporate
                ? navigate(
                    `/dashboard/merchants/campaigns/${
                      isMerchantCorporate
                        ? "corporate=true"
                        : isMerchantOffline
                        ? "offline=true"
                        : ""
                    }`
                  )
                : navigate(
                    `/dashboard/campaign?${
                      isSupporter ? "supporter=true" : "partner=true"
                    }${charity ? "&charity=" + charity : ""}${
                      defaultSupporterCause
                        ? "&defaultSupporterCause=" + defaultSupporterCause
                        : ""
                    }`
                  )
            }
          }}>
          <FormattedMessage
            id="dashboard::common::leave"
            defaultMessage="Leave"
          />
        </Button>
      </StyledModalContainer>
    </CustomModal>
  )
}
