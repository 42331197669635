import React, { useEffect } from "react"
import { useIntl } from "gatsby-plugin-intl"
import { faCalendar } from "@fortawesome/pro-regular-svg-icons/faCalendar"
import { faChevronDown } from "@fortawesome/pro-light-svg-icons/faChevronDown"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import DatePicker from "react-datepicker"
import { StyledDatePicker, DatePickerError } from "./index.styles"
import { registerLocale, setDefaultLocale } from "react-datepicker"
import es from "date-fns/locale/es"
import it from "date-fns/locale/it"
registerLocale("es", es)
registerLocale("it", it)
import { Tooltip } from "react-tooltip"

import "react-datepicker/dist/react-datepicker.css"

export default function CustomDatePicker({
  children,
  onlyTimer,
  isClearable = false,
  startDate,
  endDate,
  showRange,
  placeholderText,
  dataTestId = "",
  showTimeSelect,
  showTimeSelectOnly,
  onDateChange,
  error,
  touched,
  disabled,
  inputDataTip,
  inputDataFor,
  ...rest
}) {
  const { locale, formatMessage } = useIntl()
  setDefaultLocale(locale)

  const dateOfStart = showRange && startDate ? new Date(startDate) : null
  const dateOfEnd = showRange && endDate ? new Date(endDate) : null
  const dateRange = [dateOfStart, dateOfEnd]
  const [formattedStartDate, formattedEndDate] = dateRange

  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <StyledDatePicker
        className={"date-picker-input"}
        error={!!error && touched}
        disabled={disabled}>
        {inputDataFor ? (
          <Tooltip
            place="top"
            type="info"
            effect="solid"
            arrowColor="transparent"
            textColor="black"
            backgroundColor="white"
            id={inputDataFor}
            className="tooltip"
            delayShow={200}
          />
        ) : null}
        <input
          data-tooltip-content={inputDataTip}
          data-tooltip-id={inputDataFor}
          value={props.value || props.placeholder}
          onChange={props.onChange}
          onClick={props.onClick}
          ref={ref}
          autoComplete="off"
          disabled={disabled}
        />
        <FontAwesomeIcon icon={showTimeSelect ? faChevronDown : faCalendar} />
        {error && touched ? <DatePickerError>{error}</DatePickerError> : null}
      </StyledDatePicker>
    )
  })
  return showRange ? (
    <DatePicker
      {...rest}
      id="datePicker"
      className="range-date-picker"
      selectsRange={true}
      startDate={formattedStartDate}
      endDate={formattedEndDate}
      onChange={onDateChange}
      isClearable={isClearable}
      placeholderText={placeholderText}
      dateFormat="dd/MM/yyyy"
      customInput={<CustomInput />}
    />
  ) : (
    <DatePicker
      {...rest}
      data-testid={dataTestId}
      id="datePicker"
      className="single-date-picker"
      onChange={onDateChange}
      isClearable={isClearable}
      showTimeSelect={showTimeSelect}
      showTimeSelectOnly={showTimeSelectOnly}
      timeIntervals={15}
      timeFormat="HH:mm"
      timeCaption={formatMessage({
        id: "dashboard::common::time",
        defaultMessage: "Time",
      })}
      dateFormat={
        showTimeSelect
          ? showTimeSelectOnly
            ? "HH:mm"
            : "dd/MM/yyyy HH:mm"
          : "dd/MM/yyyy"
      }
      placeholderText={placeholderText}
      customInput={<CustomInput />}>
      {children}
    </DatePicker>
  )
}
