import React from "react"
import PropTypes from "prop-types"
import { faPencil } from "@fortawesome/pro-light-svg-icons/faPencil"
import { faTrash } from "@fortawesome/pro-light-svg-icons/faTrash"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, TruncatedText, LazyImage, Date } from "@tmu/components/common"
import { useAuth } from "@tmu/hooks"
import { API_PERMISSIONS } from "@tmu/apollo/constants"
import { StyledCampaignUpdatesTableRow } from "./index.styles"

const CampaignUpdatesTableRow = ({
  style,
  title,
  slug,
  id,
  content,
  image,
  onEdit,
  onDelete,
  date,
}) => {
  const { apiPermissions } = useAuth()

  return (
    <StyledCampaignUpdatesTableRow style={style}>
      <strong>{title}</strong>
      <div className="desc">
        <TruncatedText numLines={3}>{content}</TruncatedText>
      </div>
      <div className="centered">
        <LazyImage
          src={image}
          altName={`campaign-updates-table-row-name-${title}`}
          width={92}
          height={62}
        />
      </div>
      <div className="centered">
        <Date value={date} />
      </div>
      {apiPermissions?.includes(
        API_PERMISSIONS.PARTNER_UPDATE_CAMPAIGN_CONTENT
      ) && (
        <div className="centered">
          <Button
            variant="text"
            label="Edit campaign"
            data-testid="btn-edit-campaign"
            data-cy-slug={slug}
            onClick={() => onEdit(slug)}>
            <FontAwesomeIcon icon={faPencil} />
          </Button>

          <Button
            variant="text"
            data-testid="btn-delete-campaign"
            label="Delete campaign"
            onClick={() => onDelete({ title, slug, id })}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </div>
      )}
    </StyledCampaignUpdatesTableRow>
  )
}

CampaignUpdatesTableRow.propTypes = {
  index: PropTypes.number,
  style: PropTypes.object,
  name: PropTypes.string,
  slug: PropTypes.string,
  shortDescription: PropTypes.string,
  image: PropTypes.string,
  deadline: PropTypes.string,
  goalAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxPacDiscount: PropTypes.number,
  status: PropTypes.string,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
}

export default CampaignUpdatesTableRow
