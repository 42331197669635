import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { StyledCorporateCompaniesTableHeader } from "./index.styles"

const CorporateCompaniesTableHeader = () => {
  const { isTablet } = getAllScreenTypes()

  return (
    <StyledCorporateCompaniesTableHeader>
      <div data-testid="text-header-no" className="row row-center caption">
        <FormattedMessage
          id="corporate::companies::tableHeader::no"
          defaultMessage="No"
        />
      </div>
      <div data-testid="text-header-companies" className="row row-left caption">
        <FormattedMessage
          id="corporate::companies::tableHeader::companies"
          defaultMessage="Companies"
        />
      </div>
      {isTablet && <div data-testid="text-header-empty"></div>}
      <div
        data-testid="text-header-donations"
        className="row row-right caption">
        <FormattedMessage
          id="corporate::companies::tableHeader::donations"
          defaultMessage="Donations"
        />
      </div>
      <div data-testid="text-header-raised" className="row row-right caption">
        <FormattedMessage
          id="corporate::companies::tableHeader::totalSocialImpact"
          defaultMessage="Total Social Impact"
        />
      </div>
    </StyledCorporateCompaniesTableHeader>
  )
}

export default CorporateCompaniesTableHeader
