import React, { useState } from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import pass from "secure-random-password"
import { gql, useMutation } from "@apollo/client"
import { useApolloApiClients } from "@tmu/apollo/client"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from "@fortawesome/pro-light-svg-icons/faTrash"
import { Button, ClickToCopy } from "@tmu/components/common"
import { DevelopersModal } from "./DevelopersModal"
import { useToast } from "@tmu/hooks"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"

import {
  StyledDevelopersTableRow,
  StyledDevelopersTableRowText,
} from "./index.styles"

const API_USER_MUTATION = gql`
  mutation updateMerchantApiUser($input: UpdateMerchantAPIUserMutationInput!) {
    updateMerchantApiUser(input: $input) {
      apiUser {
        clientId
      }
      errors {
        field
        messages
      }
    }
  }
`

const REVOKE_USER_MUTATION = gql`
  mutation ($input: RevokeMerchantAPIUserMutationInput!) {
    revokeMerchantApiUser(input: $input) {
      success
      errors {
        field
        messages
      }
    }
  }
`

const DevelopersTableRow = ({
  id,
  name,
  clientId,
  callAllApiUsers,
  checkUserExist,
}) => {
  const { merchantClient } = useApolloApiClients()
  const { error } = useToast()
  const { isTablet } = getAllScreenTypes()
  const [password, setPassword] = useState("")
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isRevoke, setIsRevoke] = useState(false)

  const [createAPIUser] = useMutation(API_USER_MUTATION, {
    client: merchantClient,
  })

  const [revokeUserMutation] = useMutation(REVOKE_USER_MUTATION, {
    client: merchantClient,
  })

  const revokeUser = async () => {
    await revokeUserMutation({
      variables: {
        input: {
          id,
        },
      },
    })
      .then(() => callAllApiUsers())
      .catch((err) => error(err.description))
  }

  const handleSubmit = async () => {
    const passData = pass.randomPassword({
      length: 16,
      characters: [pass.lower, pass.upper, pass.digits],
    })
    await createAPIUser({
      variables: {
        input: {
          password: passData,
          name,
          id,
        },
      },
    })

    setPassword(passData)
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
    setIsRevoke(false)
  }

  return (
    <>
      <StyledDevelopersTableRow>
        <StyledDevelopersTableRowText
          className="name"
          onClick={() => setIsModalOpen(true)}>
          {name}
          {!isTablet && (
            <StyledDevelopersTableRowText className="mobile-client-id">
              <ClickToCopy text={clientId}>{clientId}</ClickToCopy>
            </StyledDevelopersTableRowText>
          )}
        </StyledDevelopersTableRowText>
        {isTablet && (
          <StyledDevelopersTableRowText className="client-id">
            <ClickToCopy text={clientId}>{clientId}</ClickToCopy>
          </StyledDevelopersTableRowText>
        )}
        <StyledDevelopersTableRowText
          className={
            password && password.length > 0 ? `password` : `regenerate`
          }
          onClick={() => {
            if (!password && !password?.length > 0) handleSubmit()
          }}>
          {password && password.length > 0 ? (
            <ClickToCopy text={password}>{password}</ClickToCopy>
          ) : (
            <FormattedMessage
              id="dashboard::developers::tableHeader::regenerate"
              defaultMessage="Regenerate"
            />
          )}
        </StyledDevelopersTableRowText>
        <StyledDevelopersTableRowText className="revoke">
          <Button
            variant="text"
            label="Trash"
            data-testid="btn-revoke"
            onClick={() => {
              setIsRevoke(true)
              setIsModalOpen(true)
            }}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </StyledDevelopersTableRowText>
      </StyledDevelopersTableRow>

      {isModalOpen && (
        <DevelopersModal
          {...{
            isModalOpen,
            id,
            name,
            isRevoke,
            revokeUser,
            handleModalClose,
            callAllApiUsers,
            checkUserExist,
          }}
        />
      )}
    </>
  )
}

DevelopersTableRow.propTypes = {}

export default DevelopersTableRow
