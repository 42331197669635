import styled from "@emotion/styled"

export const StyledPacsQuestionsContainer = styled.div`
  font-style: ${(p) => p.theme.fontStyles.normal};
  color: ${(p) => p.theme.colors.pitchBlack};
  align-items: center;
  text-align: center;
  padding: 0 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
  }
`

export const StyledPacsQuestionsTitle = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-size: 1.5rem;
  line-height: 2rem;
  padding: 2.875rem 0 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    line-height: 2.625rem;
    padding: 3.3125rem 0 1rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 2rem;
    padding: 4rem 0 1rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 3rem;
    line-height: 4.0625rem;
    padding: 5rem 0 2rem;
  }
`

export const StyledPacsQuestionsContent = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  padding-bottom: 3.375rem;
  display: flex;
  justify-content: center;
  p {
    font-size: 1rem;
    line-height: 1.375rem;
  }
  span,
  a {
    color: ${(p) => p.theme.colors.carrot};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding-bottom: 2.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    p {
      font-size: 1.5rem;
      line-height: 2.25rem;
    }
    padding-bottom: 4rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    p {
      font-size: 1.75rem;
      line-height: 2.125rem;
    }
    padding-bottom: 4rem;
  }
`
