import store from "store/dist/store.modern"
import expirePlugin from "store/plugins/expire"

const set = (key, value, expiration) => store.set(key, value, expiration)
const get = (key) => store.get(key)
const remove = (key) => store.remove(key)
const getExpiration = (key) => store.getExpiration(key)
const removeExpiredKeys = () => store.removeExpiredKeys()
store.addPlugin(expirePlugin)
export { get, set, remove, getExpiration, removeExpiredKeys }
