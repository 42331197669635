import React, { Fragment, useContext, useEffect } from "react"
import { injectIntl, useIntl, FormattedMessage } from "gatsby-plugin-intl"
import SEO from "@tmu/components/seo"
import { faHandHoldingHeart } from "@fortawesome/pro-solid-svg-icons/faHandHoldingHeart"
import { faHandPeace } from "@fortawesome/free-solid-svg-icons/faHandPeace"
import { faHandshake } from "@fortawesome/pro-solid-svg-icons/faHandshake"
import { faCubesStacked } from "@fortawesome/free-solid-svg-icons/faCubesStacked"
import { faDrum } from "@fortawesome/free-solid-svg-icons/faDrum"
import { faArrowUpRightDots } from "@fortawesome/free-solid-svg-icons/faArrowUpRightDots"
import { JoinPageComponent } from "@tmu/components/how-it-works"
import { useFooterType } from "@tmu/hooks"
import { FOOTER_TYPE } from "@tmu/apollo/constants"
import { HomePageContext } from "@tmu/context/homePageContext"
import { StyledJoinPageWrapper } from "@tmu/global/page-addons/how-it-works.styles"

const { CLOUDFLARE_IMAGE_URL } = process.env

const MerchantPage = () => {
  const { locale, formatMessage } = useIntl()
  const { setHeaderConfigData } = useContext(HomePageContext)
  useFooterType({ footerType: FOOTER_TYPE.WIDE })

  useEffect(() => {
    setHeaderConfigData({ transparent: true })
  }, [])

  const benefitCards = [
    {
      title: formatMessage({
        id: "onlineMerchant::textTable::title1",
        defaultMessage: "Join the New Generation of Donations",
      }),
      desc: formatMessage({
        id: "onlineMerchant::textTable::description1",
        defaultMessage:
          "Membership and presence, with its own showcase inside the TrustMeUp shopping gallery, totally free.",
      }),
      fontIcon: faHandHoldingHeart,
    },
    {
      title: formatMessage({
        id: "onlineMerchant::textTable::title2",
        defaultMessage: "Boost your visibility",
      }),
      desc: formatMessage({
        id: "onlineMerchant::textTable::description2",
        defaultMessage:
          "You are given the TrustMeUp Product Gallery to showcase your products/services totally free of charge",
      }),
      fontIcon: faDrum,
    },
    {
      title: formatMessage({
        id: "onlineMerchant::textTable::title3",
        defaultMessage: "Clever tool to increase your sales",
      }),
      desc: formatMessage({
        id: "onlineMerchant::textTable::description3",
        defaultMessage:
          "Thanks to TrustMeUp, you will have access to a large number of users ready to buy because they have PAC discounts (1 Euro = 1 PAC) that can be used within the Shopping Gallery, based on the percentage you define.",
      }),
      fontIcon: faArrowUpRightDots,
    },
    {
      title: formatMessage({
        id: "onlineMerchant::textTable::title4",
        defaultMessage: "DonateByShopping",
      }),
      desc: formatMessage({
        id: "onlineMerchant::textTable::description4",
        defaultMessage:
          "Each purchase generates a donation, based on the PAC discount percentage that your e-commerce decides to reserve for TrustMeUp customers. This is possible thanks to a TrustMeUp channel cost that is cheaper than traditional digital marketplaces.",
      }),
      fontIcon: faHandshake,
    },
    {
      title: formatMessage({
        id: "onlineMerchant::textTable::title5",
        defaultMessage: "Supported Onboarding Process",
      }),
      desc: formatMessage({
        id: "onlineMerchant::textTable::description5",
        defaultMessage:
          "You will have at your disposal a free personal assistant who will help you join our platform. We will also support you with plug-in integration and showcase organization. The process is very simple and only takes a few minutes.",
      }),
      fontIcon: faHandPeace,
    },
    {
      title: formatMessage({
        id: "onlineMerchant::textTable::title6",
        defaultMessage: "Increase your Corporate Social Responsibility",
      }),
      desc: formatMessage({
        id: "onlineMerchant::textTable::description6",
        defaultMessage:
          "By allowing your customers to donate a portion of their purchases, you will increase your reputation and will always be their first choice. Who wouldn't want to buy in a store that does good?",
      }),
      fontIcon: faCubesStacked,
    },
  ]

  const howToJoinItemsTitle = formatMessage({
    id: "join::ngo::howToJoinTitleMerchant",
    defaultMessage: "How to join?",
  })

  const howToJoinItems = [
    formatMessage({
      id: "merchant::howToJoin::item1",
      defaultMessage: "Contact us to let us know the best ways to onboard you",
    }),
    formatMessage({
      id: "merchant::howToJoin::item2",
      defaultMessage: "Create your e-commerce account",
    }),
    formatMessage({
      id: "merchant::howToJoin::item3",
      defaultMessage: "Setup your marketplace",
    }),
    formatMessage({
      id: "merchant::howToJoin::item4",
      defaultMessage: "Define the discounts",
    }),
  ]

  const howToJoinItemsAdd = (
    <FormattedMessage
      id="merchant::howToJoin::item5"
      defaultMessage="Get additional sales from  <span>PAC Offers</span> marketplace"
      values={{
        span: (...chunks) => (
          <a href={`/${locale}/offers?offerrue&store=true`}>
            {chunks.map((chunk, i) => (
              <Fragment key={i}>{chunk}</Fragment>
            ))}
          </a>
        ),
      }}>
      {(...chunks) => (
        <>
          {chunks.map((chunk, i) => (
            <Fragment key={i}>{chunk}</Fragment>
          ))}
        </>
      )}
    </FormattedMessage>
  )

  const sliceBannerData = {
    header: formatMessage({
      id: "join::onlineMerchant::heroHeader",
      defaultMessage:
        "Charity-friendly commercial gallery for your products and marketing campaigns",
    }),
    title: formatMessage({
      id: "join::merchant::heroTitle",
      defaultMessage:
        "Being present in TrustMeUp means increasing your sales and retaining your customers by doing good.",
    }),
    image: CLOUDFLARE_IMAGE_URL + "/static/assets/images/join/funoffice.png",
  }

  const pageData = {
    contactFormTitle: formatMessage({
      id: "join::forms::contactForm::merchantTitle",
      defaultMessage: "Join as a Merchant",
      tagName: "h3",
    }),
    contactFormDescription: formatMessage({
      id: "join::forms::contactForm::merchantDescription",
      defaultMessage: "Tell us about your store so we can help you to onboard.",
      tagName: "p",
    }),
    sender: "MERCHANT",
    logoItems: [
      {
        node: {
          logoEn:
            "https://images.trustmeup.com/merchants/store/logo/1669978605081-QZPX4YBJeeZUuq4av0XPHw.jpg",
        },
      },
      {
        node: {
          logoEn:
            "https://images.trustmeup.com/merchants/store/logo/1665564894726-prhOoUFZXSEpVDSAbDjsKA.jpg",
        },
      },
      {
        node: {
          logoEn:
            "https://images.trustmeup.com/merchants/store/logo/1665564991184-uNBwIR4dvgkji6KUE_4zfA.jpg",
        },
      },
      {
        node: {
          logoEn:
            "https://images.trustmeup.com/merchants/store/logo/1681375137392-O7k5Ytl3e_WcqGTi0cM4vQ.jpg",
        },
      },
      {
        node: {
          logoEn:
            "https://images.trustmeup.com/merchants/store/logo/1650458594430-NeSP_KJNREKBO4_AWBBQYg.jpg",
        },
      },
      {
        node: {
          logoEn:
            "https://images.trustmeup.com/merchants/store/logo/1664789909085-1SZc2vL4Sxn1UbhN01gpJg.jpg",
        },
      },
      {
        node: {
          logoEn:
            "https://images.trustmeup.com/merchants/store/logo/1665564826004-l0lm5qAOl1f0QZeS9oEDLA.jpg",
        },
      },
      {
        node: {
          logoEn:
            "https://images.trustmeup.com/merchants/store/logo/1663678145648-nnR15Pvl5hE1lSXZLIiw7g.jpg",
        },
      },
    ],
    logoItemsTitle: formatMessage({
      id: "join::merchant::ngoListTitle",
      defaultMessage: "Some of Merchants that already work with us:",
    }),
    benefitCards,
    howToJoinItems,
    howToJoinItemsTitle,
    sliceBannerData,
    howToJoinItemsAdd,
    termsLink: "/legal/merchant-terms/20",
  }

  return (
    <>
      <SEO
        lang={locale}
        title={formatMessage({
          id: "join::merchant::pageTitle",
          defaultMessage: "Join as a merchant",
        })}
        description={formatMessage({
          id: "seo::merchant::description",
          defaultMessage:
            "Increase your sales and retain your customers by doing good.",
        })}
      />
      <StyledJoinPageWrapper>
        <JoinPageComponent pageData={pageData} carrot joinMerchant />
      </StyledJoinPageWrapper>
    </>
  )
}
export default injectIntl(MerchantPage)
