import { capitalize } from "@tmu/utils/string"

export const merchantStoreParts = `
  fragment merchantStoreParts on MerchantStoreNode {
    id
    slug
    name
    displayName
    logo
    website
    maxPacDiscount
    maxPacDiscountInOffers
    defaultOffer {
      offerType
      country
      city
      postCode
      addressLine1
      location
    }
    description
    descriptionEn
    descriptionIt
    descriptionEs
    shortDescription
    shortDescriptionEn
    shortDescriptionIt
    shortDescriptionEs
    website
    youtube
    instagram
    facebook
    twitter
    linkedin
    terms
    termsEn
    termsEs
    termsIt
    category {
      id
      icon
      slug
      name
      nameEn
      nameIt
      nameEs
    }
  }
`

export const merchantStorePartsWithLocale = (locale) => {
  const localeSuffix = capitalize(locale)

  return `fragment merchantStoreParts on MerchantStoreNode {
    id
    slug
    name
    displayName
    logo
  }
`
}

export const merchantOfferParts = `
  fragment merchantOfferParts on OfferNode {
    id
    slug
    name
    image
    shortDescriptionEn
    shortDescriptionIt
    shortDescriptionEs
    descriptionEn
    descriptionIt
    descriptionEs
    maxPacDiscount
    website
    isDefaultOffer
    offerType
    startDate
    endDate
    price
    minPrice
    store {
      id
      logo
      displayName
      name
      badge
      slug
      isDisplayed
      maxPacDiscount
      maxPacDiscountInOffers
      defaultOffer {
        offerType
      }
    }
    __typename
  }
`

export const merchantOfferPartsWithLocale = (locale) => `
  fragment merchantOfferPartsWithLocale on OfferNode {
    id
    slug
    name
    image
    shortDescription${locale}
    description${locale}
    maxPacDiscount
    website
    isDefaultOffer
    offerType
    startDate
    endDate
    price
    minPrice
    store {
      id
      logo
      displayName
      name
      badge
      slug
      maxPacDiscount
      isDisplayed
      maxPacDiscountInOffers
      defaultOffer {
        offerType
      }
    }
    __typename
  }
`

export const merchantOfferPartsByMerchant = `
  fragment merchantOfferPartsByMerchant on OfferNode {
    id
    slug
    name
    image
    shortDescriptionEn
    shortDescriptionIt
    shortDescriptionEs
    descriptionEn
    descriptionIt
    descriptionEs
    maxPacDiscount
    website
    isDefaultOffer
    offerType
    startDate
    endDate
    minPrice
    status
    visibilityStatus
    isVoucher
    isInvoiceMandatory
    isReservationRequired
    startDateVisibility
    allowUsersToSelectADifferentCampaign
    favoriteCharity {
      id
      name
      nameEn
      nameIt
      nameEs
      pacMultiplier
      partner {
        logo
        logoEn
        logoEs
        logoIt
      }
    }
    productVariation {
      maxTickets
      isUnlimitedTickets
      isSoldOut
      stockLeft
      pacDiscount
      price
      isActive
    }
    __typename
  }
`
export const merchantOfferPartsForStoreDetail = `
  fragment merchantOfferPartsForStoreDetail on OfferNode {
    id
    slug
    name
    image
    shortDescriptionEn
    shortDescriptionIt
    shortDescriptionEs
    descriptionEn
    descriptionIt
    descriptionEs
    maxPacDiscount
    isDefaultOffer
    __typename
  }
`
