import styled from "@emotion/styled"

export const StyledWrapper = styled.div`
  margin: 4rem auto;
  padding: 0 1rem;

  .referral-code-form > fieldset {
    display: flex;
    flex-direction: column;
    min-width: 0;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      display: block;
    }

    > div {
      margin-bottom: 1.75rem;

      @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
        margin-bottom: 1.5rem;
      }
    }

    > p {
      margin-bottom: 0.75rem;

      @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
        margin-bottom: 1rem;
      }
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    max-width: ${(p) => p.theme.breakpoints.tablet};
    margin: auto;

    .referral-code-form > fieldset {
      display: grid;
      grid-template-columns: 12.5rem auto;
      grid-gap: 2.5rem 2.5625rem;

      > div {
        margin-bottom: 0;
      }
    }
  }

  h1 {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-style: ${(p) => p.theme.fontStyles.normal};
    margin-bottom: 1.25rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      margin-bottom: 1.875rem;
      font-size: 2rem;
      line-height: 2.75rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 3rem;
      line-height: normal;
    }
  }
`

export const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  svg {
    color: ${(p) => p.theme?.colors?.blue};
    font-size: 1rem;
    height: 1rem;
    margin: 0.5625rem 0 0 0.125rem;
    cursor: pointer;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      margin: 0.75rem 0 0 -0.6875rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.6669rem;
      height: 1.6669rem;
    }
  }
`

export const StyledTitle = styled.h1`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.bold};
  font-style: ${(p) => p.theme.fontStyles.normal};
  color: ${(p) => p.theme.colors.black};
  text-align: center;

  font-size: 1.5rem;
  line-height: 2rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 2rem;
    line-height: 2.625rem;
  }
`

export const StyledLabel = styled.p`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-style: ${(p) => p.theme.fontStyles.normal};
  align-self: center;
  padding: 0;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
    line-height: 2.625rem;
  }

  &.top-align {
    align-self: flex-start;
  }
`

export const StyledFlexRow = styled.div`
  display: block;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: flex;
    flex-direction: ${(p) => p.flexDirection};
    column-gap: 1.5rem;
    justify-content: space-between;
  }

  .unlimited {
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    font-style: ${(p) => p.theme.fontStyles.normal};
    color: ${(p) => p.theme.colors.pitchBlack};

    margin-bottom: unset;
    margin-top: 1.5rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 1rem;
      line-height: 1.5rem;
      margin-top: 1.875rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.5rem;
      line-height: 2.25rem;
      margin-top: 2.25rem;
    }
  }

  &.valid-until {
    svg {
      top: 0.75rem;
    }
  }

  &.mobile-valid-until {
    display: flex;
    column-gap: 0.5rem;
    width: 100%;

    svg {
      top: 0.75rem;
    }
  }
`

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.75rem;

  .full-width {
    width: 100%;
    margin: unset;
  }
`
