import styled from "@emotion/styled"
import { Button } from "@tmu/components/common"

export const StyledWelcomeSliceWrapper = styled.div`
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
  }

  .slick-slider {
    padding-bottom: 3rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      padding-bottom: 1rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    }
  }

  .welcome-user-space .slick-dots {
    display: flex !important;
    justify-content: center;
    margin: 0;
    padding: 1rem 0;

    bottom: 0.875rem !important;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      bottom: -0.125rem !important;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      bottom: -0.3125rem !important;
    }

    list-style-type: none;

    li {
      margin: 0 0.5rem !important;
      opacity: unset;
      width: unset !important;

      @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
        margin: 0 1.5rem !important;
      }
    }

    button {
      display: block;
      width: 0.5rem !important;
      height: 0.5rem !important;

      @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
        width: 1rem !important;
        height: 1rem !important;
      }

      padding: 0;

      border: none;
      border-radius: 100%;
      background-color: ${(p) => p.theme.colors.disabled};

      text-indent: -624.9375rem;
    }

    li.slick-active {
      background-color: unset;
    }

    li.slick-active button {
      background-color: ${(p) => p.theme.colors.blue};
    }
  }
`

export const StyledWSTitle = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.pitchBlack};
  font-size: 1.5rem;
  line-height: 2rem;

  text-align: center;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 2rem;
    line-height: 2.625rem;

    text-align: unset;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 2rem;
    line-height: 2.625rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 3rem;
    line-height: 4.0625rem;

    margin-top: 6.625rem;
  }
`

export const StyledContent = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  color: ${(p) => p.theme.colors.pitchBlack};

  font-size: 1rem;
  line-height: 1.375rem;

  text-align: center;

  margin: -0.9375rem 0 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1rem;
    line-height: 1.375rem;

    text-align: unset;

    margin: 1rem 0 2.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 1.5rem;
    line-height: 2.25rem;

    margin: 1.125rem 0 2.75rem;

    width: 25.4375rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.75rem;
    line-height: 2.5rem;

    margin: 2.5rem 0 4rem;

    width: 43.625rem;
  }
`

export const StyledQuoteAndPerson = styled.div`
  position: absolute;
  bottom: 0.75rem;
  margin-left: 1rem;

  p {
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    color: ${(p) => p.theme.colors.white};

    font-size: 0.625rem;
    line-height: 1rem;
  }

  p:last-child {
    font-style: italic;
    margin-top: 0.25rem;
  }

  max-width: 22rem;

  p.black {
    color: ${(p) => p.theme.colors.pitchBlack};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    max-width: 100%;
    position: unset;
    bottom: unset;
    margin-left: unset;

    p {
      font-size: 0.75rem;
      line-height: 1.125rem;

      color: ${(p) => p.theme.colors.pitchBlack};

      margin: 0.75rem 0 0.5625rem;
    }

    p:last-child {
      margin-top: 0.5rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    p {
      font-size: 0.75rem;
      line-height: 1.125rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    p {
      font-size: 1.5rem;
      line-height: 2rem;

      margin: 2.5rem 0 2rem;
    }

    p:last-child {
      margin-top: 0.75rem;
    }
  }
`
export const StyledWSButton = styled(Button)`
  margin: auto;
  width: 14.25rem;
  min-height: 2.5rem;
  border-radius: 0.5rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin: unset;
    width: unset;
    height: unset;
    border-radius: unset;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
  }
`

export const StyledWSButtonIcon = styled.i`
  font-size: 1.5rem !important;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: unset;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
  }
`
