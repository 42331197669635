import styled from "@emotion/styled"
import { css } from "@emotion/react"

export const StyledFilterBar = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-areas: "right" "left";
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: grid;
    grid-template-columns: ${(p) =>
      p.isOfferList || !p.filters?.listType
        ? "minmax(8rem,12rem) auto"
        : "10.4375rem auto"};
    grid-template-areas: "left right";
    grid-gap: 1rem;
    width: 100%;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    grid-template-columns: ${(p) => (p.isOfferList ? "10rem" : "12rem")} auto;
    grid-gap: 1.5rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    grid-template-columns: ${(p) =>
      p.isOfferList || !p.filters?.listType
        ? " 15rem auto"
        : " 24.375rem auto"};
    grid-gap: 3rem;
  }
`
export const StyledRightGroup = styled.div`
  grid-area: right;
  justify-content: center;
  margin: 0 1rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: grid;
    grid-template-columns: ${(p) =>
      p.withCampaignTypes === true
        ? "1fr"
        : p.filterType === "charity"
        ? "1fr 13rem"
        : p.isOfferList || !p.filters?.listType
        ? " 13rem 1fr"
        : " 1fr 12.5rem"};
    gap: 1rem;
    justify-content: space-between;
    margin: 0;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    grid-template-columns: ${(p) =>
      p.withCampaignTypes === true
        ? "1fr"
        : p.filterType === "charity"
        ? "1fr 13rem"
        : p.isOfferList || !p.filters?.listType
        ? " 13rem 1fr"
        : " 1fr 6.25rem 12.5rem"};
    gap: 1.5rem;
    justify-content: space-between;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    grid-template-columns: ${(p) =>
      p.withCampaignTypes === true
        ? "1fr"
        : p.filterType === "charity"
        ? "1fr 20rem"
        : p.isOfferList || !p.filters?.listType
        ? " 20rem 1fr"
        : " 1fr 9.25rem 18.5rem"};
    gap: 3rem;
  }
`
export const StyledLeftGroup = styled.div`
  grid-area: left;
  align-self: center;
  margin: ${(p) => (p.isOfferList ? "0 1rem 1rem" : "1rem 1rem 0")};
  display: flex;
  justify-content: space-between;

  ${(p) =>
    p.isOfferList &&
    css`
      flex-direction: column;
    `}

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin: 0;
    flex-direction: rıw;
    display: block;
    .mobile-filter {
      display: none;
    }
  }
`

export const StyledSelectorsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`
export const StyledCategoryFilterWrapper = styled.div`
  width: 100%;
`
export const StoreFilterWrapper = styled.div`
  margin-top: 1rem;
`

export const StyledCaption = styled.div`
  text-transform: uppercase;
  font-weight: ${(p) => p.theme.fontWeights.liteBlack};
  font-size: 0.625rem;
  line-height: 1rem;
  letter-spacing: 0.03em;
  color: ${(p) => p.theme.colors.disabled};
  font-family: ${(p) => p.theme.fontFamilies.headlineBig};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0.25rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    line-height: 2rem;
    font-size: 1.25rem;
  }
`
export const StyledCampaignTypes = styled.div`
  display: flex;
  gap: 2rem;
`

export const StyledHorizontalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;

  .filter-checkbox {
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    color: ${(p) => p.theme.colors.black};

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 0.75rem;
      line-height: 1.5rem;
      margin: 0 0.6875rem 0 0;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      font-size: 1rem;
    }
    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.5rem;
      line-height: 2.25rem;
      margin: 0 2.625rem 0 0;
    }

    > span {
      top: 0.1rem;

      @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
        top: 0.6rem;
      }
    }
  }
`

export const StyledWrapper = styled.div`
  display: block;
`
export const StyledBlocker = styled.div`
  width: 100vw;
  height: 4rem;
  position: absolute;
  z-index: 999;
`
