import React from "react"
import { injectIntl } from "gatsby-plugin-intl"

import Donors from "./donors"

const JoinPage = () => {
  return <Donors />
}

export default injectIntl(JoinPage)
