import styled from "@emotion/styled"

export const StyledCorporateHeader = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  text-align: center;
  max-width: 35.5625rem;
  font-size: 1.5rem;
  color: ${(p) => p.theme.colors.whiteSmoke};

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 3rem;
    text-align: left;
    color: ${(p) => p.theme.colors.blue};
    margin: 1.5rem 0;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 3rem;
    margin-bottom: 1.5rem;
    max-width: 35.5625rem;
    margin: 3rem 0 1.5rem 0;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    max-width: 62.6875rem;
    margin: 0 0 1.5rem 0;
  }
`

export const StyledContactFormTitle = styled.p`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-size: 1.5rem;
  line-height: 2.125rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  text-align: left;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    line-height: 2.75rem;
    margin-top 0;
    text-align: center;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 3rem;
    margin-bottom: 2.5rem;
    line-height: normal;
  }
`

export const StyledContactFormDescription = styled.p`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: left;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    text-align: center;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2.2rem;
  }
`

export const StyledCorporateTitle = styled.p`
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-family: ${(p) => p.theme.fontFamilies.headline};
  color: ${(p) => p.theme.colors.gondola};
  margin-bottom: 2rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1.25rem;
    line-height: 2rem;
    max-width: 30.25rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    max-width: 38.1875rem;
    font-size: 1.25rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
    line-height: 2.75rem;
    max-width: 55.8125rem;
  }
`

export const StyledCorporateSVGWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 2rem 0 3.375rem;
`

export const StyledCorporateDescription = styled.p`
  display: inline-block;
  color: ${(p) => p.theme.colors.black};
  font-size: 1rem;
  line-height: 1.125rem;
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1rem;
    line-height: 1.5rem;
    max-width: 18.875rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin-left: 1.125rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    max-width: 28.25rem;
    line-height: 2.25rem;
  }
`

export const StyledCorporateImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    height: 2.9375rem;
    width: 2.9375rem;
    margin-right: 1.25rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    & > img {
      height: 5rem;
      width: 5rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    & > img {
      height: 10rem;
      width: 10rem;
      margin-right: 1.5rem;
    }
  }
`

export const StyledCorporateStoryBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: auto;

  img {
    width: 100%;
  }
  min-height: 21.875rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    min-height: auto;
    .media-box {
      justify-content: end;
    }

    img {
      width: auto;
    }
    min-height: 35rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    padding-left: 2rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    min-height: 50rem;
  }
`

export const StyledWrapper = styled.div`
  margin: 5.125rem auto 0;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    max-width: ${(p) => p.theme.breakpoints.tablet};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    max-width: ${(p) => p.theme.breakpoints.desktop};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    max-width: ${(p) => p.theme.breakpoints.wide};
  }
`

export const StyledText = styled.div`
  overflow: hidden;
  z-index: 999;
  margin: auto;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin: 2rem 0 0 0.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin: 0;
  }
`

export const StyledImage = styled.div`
  width: 100%;
  height: auto;
  .media-box {
    width: 100%;
    height: auto;
    > div {
      width: 100% !important;
      height: auto !important;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    .media-box {
      height: 620px;
      img {
        height: 620px;
      }
    }
    left: auto;
    width: 50%;
    -webkit-mask-image: linear-gradient(to right, transparent 0%, black 100%);
    mask-image: linear-gradient(to right, transparent 0%, black 50%);
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    .media-box {
      height: 908px;
      img {
        height: 908px;
      }
    }
    width: 60%;
    -webkit-mask-image: linear-gradient(to right, transparent 0%, black 10%);
    mask-image: linear-gradient(to right, transparent 0%, black 50%);
  }
`

export const StyledMobileTextWrapper = styled.div`
  margin: 2.5rem 1rem 3rem 1rem;
`

export const StyledOverlay = styled.div`
  z-index: 899;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: ${(p) => p.theme.colors.pitchBlack};
  opacity: 0.4;
`

export const StyledBenefitsList = styled.div`
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    align-items: flex-start;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
`

export const StyledBenefits = styled.div`
  background-color: ${(p) => p.theme.colors.whiteSmoke};
  display: flex;
  flex-direction: column;
  padding: 0.875rem 0;
  margin-bottom: 2.5rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 3.375rem 1.5rem;
    margin-bottom: 3.375rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    padding: 3.375rem 2rem;
    margin-bottom: 2.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding: 4rem 6rem;
    margin-bottom: 4rem;
  }
`

export const StyledBenefitCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  padding-top: 2.5rem;

  &:nth-child(2) {
    border-top: 0.063rem solid ${(p) => p.theme.colors.lightGrey};
    border-bottom: 0.063rem solid ${(p) => p.theme.colors.lightGrey};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    height: 100%;
    padding-top: 0;
    justify-content: flex-start;
    align-items: flex-start;

    &:nth-child(2) {
      border-right: 0.063rem solid ${(p) => p.theme.colors.lightGrey};
      border-left: 0.063rem solid ${(p) => p.theme.colors.lightGrey};
      border-top: none;
      border-bottom: none;
    }

    &:nth-child(1) {
      padding-right: 0.438rem;
    }

    &:nth-child(2) {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }

    &:nth-child(3) {
      padding-left: 1rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    &:nth-child(1) {
      padding-right: 1.438rem;
    }

    &:nth-child(2) {
      padding-right: 1rem;
      padding-left: 1rem;
    }

    &:nth-child(3) {
      padding-left: 1rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    &:nth-child(1) {
      padding-right: 1.938rem;
    }
    &:nth-child(2) {
      padding-right: 2rem;
      padding-left: 2rem;
    }

    &:nth-child(3) {
      padding-left: 1.938rem;
    }
  }
`

export const StyledFontIcon = styled.div`
  & > svg {
    color: ${(p) => p.theme.colors.blue};
    height: 2rem;
    width: 2rem;
  }

  margin-bottom: 1.5rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-bottom: 1.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin-bottom: 1.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    & > svg {
      height: 4.125rem;
      width: 4.125rem;
    }
    margin-bottom: 2.5rem;
  }
`

export const StyledTitle = styled.h4`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-size: 1.125rem;
  width: 80%;
  line-height: 1.75rem;
  color: ${(p) => p.theme.colors.pitchBlack};
  text-align: center;
  margin-bottom: 1rem;
  height: auto;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    height: 100%;
    font-size: 1.4375rem;
    line-height: 2.125rem;
    text-align: left;
    margin-bottom: 1rem;
    width: 100%;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin-bottom: 0.625rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
    line-height: 2.75rem;
    margin-bottom: 2.0625rem;
  }
`

export const StyledDescription = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-weight: ${(p) => p.theme.fontWeights.regular};
  color: ${(p) => p.theme.colors.pitchBlack};
  text-align: center;
  width: 80%;
  font-size: 0.75rem;
  line-height: 1.25rem;
  min-width: 20.5625rem;
  margin-bottom: 2.5rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    min-height: ${(p) => (p.locale === "en" ? "6rem" : "8rem")};
    line-height: 1.5rem;
    text-align: left;
    min-width: 13.75rem;
    font-size: 1rem;
    margin-bottom: 0;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    min-height: ${(p) => (p.locale === "en" ? "4.5rem" : "6rem")};
    line-height: 1.5rem;
    min-width: 17.5rem;
    font-size: 1rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    min-height: 7rem;
    font-size: 1.5rem;
    line-height: 2.25rem;
    min-width: 33.3125rem;
  }
`
export const SliceCampaignsWrapper = styled.div`
  padding: 0 1rem;
`
