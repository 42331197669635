import queryString from "query-string"
import React, { Fragment, useRef, useCallback, useEffect } from "react"
import {
  StyledBigText,
  StyledCardWrapper,
  StyledCardTitle,
  StyledCard,
  StyledFormRow,
  StyledPurchaseWrapper,
} from "./index.styles"

import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { useAuth } from "@tmu/src/hooks"

import { Button, PaymentMethods, TextInput } from "@tmu/components/common"
import { REQUIRED_FIELD_SYMBOL } from "@tmu/src/apollo/constants"

const StripeForm = ({ offer }) => {
  const paramOptions = { arrayFormat: "comma" }
  const params = queryString.parse(location.search, paramOptions)
  const isMerchantOnline = params?.type === "online"
  const isMerchantOffline = params?.type === "offline"
  const isMerchantInternal = params?.type === "internal"
  const isMerchantCorporate = false

  const { user } = useAuth()
  const { formatMessage } = useIntl()

  return (
    <>
      <StyledBigText>
        <FormattedMessage
          id="merchant::selfRegistrationForm::paymentTitleText"
          defaultMessage="Pay for the subscription"
        />
      </StyledBigText>

      <StyledCardTitle>
        <FormattedMessage
          id="merchant::selfRegistrationForm::purchaseTitle"
          defaultMessage="Purchase"
        />
      </StyledCardTitle>
      <StyledPurchaseWrapper>
        <div className="details">
          <FormattedMessage
            id="merchant::selfRegistrationForm::Annual"
            defaultMessage="Annual subscription"
          />
          <br />
          <br />
          <FormattedMessage
            id="merchant::selfRegistrationForm::internal"
            defaultMessage="Internal merchant"
          />
        </div>
        <div>
          <div className="amount">€ 397 / year</div>

          <div className="price">
            <FormattedMessage
              id="merchant::selfRegistrationForm::price"
              defaultMessage="Price"
            />
          </div>
        </div>
      </StyledPurchaseWrapper>
      <StyledCardWrapper>
        <StyledCard>
          <StyledFormRow>
            <PaymentMethods
              hideBankTransfer={true}
              onSelect={async (val) => {
                setSelectedPaymentType(val)

                await callCreateClientSecret({
                  variables: { stripePaymentMethodType: val },
                })
              }}
              amount={20}
            />
          </StyledFormRow>

          {!isPayPal && !isBankTransfer && (
            <>
              <StyledFormRow>
                <p className="caption">
                  <FormattedMessage
                    id="campaign::donationForm::cardholderName"
                    defaultMessage="Cardholder Name"
                  />
                  {REQUIRED_FIELD_SYMBOL}
                </p>
                <TextInput
                  newDesign
                  className={cardElementClassName}
                  id="cardholderName"
                  name="cardholderName"
                  // value={values.cardholderName}
                  placeholder={formatMessage({
                    id: "campaign::donationForm::cardholderNamePlaceHolder",
                    defaultMessage: "Name on the card",
                  })}
                  // error={errors.cardholderName}
                  // onChange={handleChange}
                  // onBlur={handleBlur}
                  // touched={touched.cardholderName}
                />
              </StyledFormRow>
              <StyledFormRow className={`${cardElementClassName} `}>
                <p className="caption">
                  <FormattedMessage
                    id="campaign::donationForm::cardDetails"
                    defaultMessage="Card Details"
                  />
                  {REQUIRED_FIELD_SYMBOL}
                </p>
                {/* <CardElement
                      onFocus={() => {}}
                      onChange={(card) => {}}
                      options={{
                        disabled: false,
                        style: cardElementStyles,
                      }}
                    /> */}
              </StyledFormRow>
            </>
          )}
        </StyledCard>
      </StyledCardWrapper>

      <Button
        type="button"
        color="carrot"
        onClick={nextStep}
        className="full-width">
        <FormattedMessage
          id="merchant::selfRegistrationForm::continue"
          defaultMessage="continue"
        />
      </Button>
    </>
  )
}

export default StripeForm
