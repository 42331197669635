export const pacTransactionParts = `
  fragment pacTransactionParts on PacTransactionNode {
    id
    pacAmount
    spendingAmount
    transactionDirection
    isIncomingTransaction
    reason
    created
  }
`
export const donationParts = `
  fragment donationParts on DonationNode {
      id
      campaign {
        id
        name
        nameEn
        nameIt
        nameEs
        slug
        deadline
        partner {
          id
          name
        }
      }
      created
      modified
      amount
      status
      receipt
      earnedPac
  }

`
