import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { StaticImage } from "gatsby-plugin-image"

import {
  StyledHowItWorksTabContents,
  StyledContentDigitalBankingRight,
  StyledHowitWorksTabContentLeft,
} from "@tmu/global/page-addons/how-it-works.styles"
const TabContentDigitalBanking = ({ icon, title, desc1, desc2, image }) => {
  return (
    <StyledHowItWorksTabContents>
      <StyledHowitWorksTabContentLeft>
        {icon && <FontAwesomeIcon icon={icon} />}
        {title}
        {desc1}
        {desc2}
      </StyledHowitWorksTabContentLeft>
      <StyledContentDigitalBankingRight>
        {image && (
          <StaticImage fixed={image} alt="tab-content-digital-banking" />
        )}
      </StyledContentDigitalBankingRight>
    </StyledHowItWorksTabContents>
  )
}

export default TabContentDigitalBanking
