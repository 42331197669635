import { Money } from "@tmu/components/common"
import { FormattedMessage } from "gatsby-plugin-intl"
import React from "react"
import {
  StyledAmount,
  StyledCartSummary,
  StyledCartSummaryRow,
} from "./index.styles"

const CartSummary = ({ subtotal, total, pacAmount, size = "", index = "" }) => {
  return (
    <StyledCartSummary size={size} data-testid={"cart-summary" + index}>
      <StyledCartSummaryRow size={size}>
        <FormattedMessage
          id="cart::detail::productValue"
          defaultMessage="Offer Value"
          tagName="p"
        />
        <StyledAmount size={size}>
          <Money value={subtotal} tagName="p" />
        </StyledAmount>
      </StyledCartSummaryRow>
      <StyledCartSummaryRow size={size}>
        <FormattedMessage
          id="cart::pacsUsed"
          defaultMessage="PACs Used"
          tagName="p"
        />
        <StyledAmount highlighted strong size={size}>
          <span>{pacAmount > 0 && " - "}</span>
          <Money value={pacAmount} currency={false} />
        </StyledAmount>
      </StyledCartSummaryRow>
      <StyledCartSummaryRow strong size={size}>
        <FormattedMessage
          id="cart::detail::totalDue"
          defaultMessage="Total Due"
          tagName="p"
        />
        {pacAmount ? (
          <StyledAmount strong size={size}>
            <Money value={total} />
          </StyledAmount>
        ) : (
          "-"
        )}
      </StyledCartSummaryRow>
    </StyledCartSummary>
  )
}

export default CartSummary
