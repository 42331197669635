import styled from "@emotion/styled"
import { css } from "@emotion/react"

export const StyledTitle = styled.div`
  margin-top: -1.75rem;
  font-size: 1.5rem;
  line-height: 2rem;
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.pitchBlack};
  text-align: left !important;
  margin-left: 2rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-top: -2.25rem;
    font-size: 2rem;
    line-height: 3rem;
    margin-left: unset;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 4rem;
    line-height: 6rem;
  }
`
export const StyledContainer = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;
  justify-content: center;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    flex-direction: row;
  }
`
export const StyledLogo = styled.div``
export const StyledDescription = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  color: ${(p) => p.theme.colors.pitchBlack};

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
`
export const StyledRightContent = styled.div``
export const StyledLeftContent = styled.div``
export const StyledInputContainer = styled.div`
  text-align: left;

  input {
    width: 10rem;
    text-align: right;
  }
`
export const StyledButtonContainer = styled.div`

`
