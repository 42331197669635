import React, { useEffect, useState } from "react"
import { FormattedMessage, useIntl, navigate } from "gatsby-plugin-intl"
import { useMutation, useQuery } from "@apollo/client"
import { Formik } from "formik"
import * as Yup from "yup"
import { Spinner } from "@tmu/components/common"
import {
  useDefaultMerchant,
  useAuth,
  useToast,
  useAddressValidation,
  useOffers,
} from "@tmu/hooks"
import { useApolloApiClients } from "@tmu/apollo/client"
import {
  AddressProfileForm,
  FavoriteCharityProfileForm,
  GeneralProfileForm,
  PhotoProfileForm,
  RepresentativeUserProfileForm,
  StoreLogoProfileForm,
  TellAboutProfileForm,
  DetailsProfileForm,
} from "@tmu/components/dashboard/dashboardCommon"
import { getValueForLocale } from "@tmu/utils/string"
import { MERCHANT_STORE_REPRESENTATIVE_USERS } from "@tmu/apollo/dashboard/queries/merchant"
import {
  SUBMIT_FOR_REVIEW_MERCHANT_STORE_MUTATION,
  OFFER_SUBMIT_FOR_REVIEW_MUTATION,
  UPDATE_REPRESENTATIVE_USER,
  CREATE_REPRESENTATIVE_USER,
  PARTIAL_UPDATE_MERCHANT_MUTATION,
} from "@tmu/apollo/dashboard/mutations/merchant"
import {
  API_PERMISSIONS,
  PER_PAGE,
  REPRESENTATIVE_USER_TYPE,
} from "@tmu/apollo/constants"
import {
  address,
  categories,
  email,
  requiredString,
  shortDescription,
  urlFormat,
} from "@tmu/utils/validation"
import {
  StyledForm,
  StyledPage,
  StyledPageContent,
  StyledPageTitle,
} from "@tmu/global/page-addons/dashboard.styles"
import { StyledProfileSpacer } from "@tmu/components/dashboard/dashboardCommon/ProfileForm/index.styles"
import {
  StyledProfileEditLink as StyledEditLink,
  StyledProfilePageGrid as StyledPageGrid,
  StyledProfileRowTitle as StyledRowTitle,
  StyledProfileRowTitleExplanation as StyledRowTitleExplanation,
} from "./index.styles"

const MerchantProfileForm = () => {
  const { isLoading, apiPermissions } = useAuth()
  const { locale, defaultLocale, formatMessage } = useIntl()
  const { success, error } = useToast()
  const { checkAddress, setAddressError, addressError } = useAddressValidation()
  const { merchantClient } = useApolloApiClients()
  const [isGeneralEdit, setIsGeneralEdit] = useState(false)
  const [isDetailEdit, setIsDetailEdit] = useState(false)
  const [isAddressEdit, setIsAddressEdit] = useState(false)
  const [isFavouriteCharityEdit, setIsFavouriteCharityEdit] = useState(false)
  const [isTellAboutEdit, setIsTellAboutEdit] = useState(false)
  const [isLegalEdit, setIsLegalEdit] = useState(false)
  const [isTechnicalEdit, setIsTechnicalEdit] = useState(false)
  const [isMarketingEdit, setIsMarketingEdit] = useState(false)
  const [addressLoading, setAddressLoading] = useState(false)
  const [tempFavoriteCharityId, setTempFavoriteCharityId] = useState()
  const [tempFavoriteCharity, setTempFavoriteCharity] = useState()
  const [partnerData, setPartnerData] = useState()
  const { partialUpdateOffer } = useOffers()
  const { data, refetch: refetchRepresentatives } = useQuery(
    MERCHANT_STORE_REPRESENTATIVE_USERS,
    {
      variables: { first: PER_PAGE, orderBy: "-created" },
      client: merchantClient,
      fetchPolicy: "cache-and-network",
    }
  )

  const findRepresentativeUserByType = (representativeType) => {
    const userData = data?.allMerchantStoreRepresentativeUsers?.edges?.find(
      ({ node }) => node?.representativeType === representativeType
    )

    return {
      ...userData?.node?.representativeUser,
      attributes: JSON.parse(
        userData?.node?.representativeUser?.attributes || "{}"
      )?.body,
      representativeType: userData?.node?.representativeType,
    }
  }

  const legalRepresentativeUser = findRepresentativeUserByType(
    REPRESENTATIVE_USER_TYPE.LEGAL
  )

  const technicalRepresentativeUser = findRepresentativeUserByType(
    REPRESENTATIVE_USER_TYPE.TECHNICAL
  )

  const marketingRepresentativeUser = findRepresentativeUserByType(
    REPRESENTATIVE_USER_TYPE.MARKETING
  )

  const {
    defaultOffer,
    defaultMerchant,
    loading: loadingMerchant,
    refetch,
    isMerchantInternal,
    isMerchantOffline,
    isMerchantOnline,
    isMerchantCorporate,
  } = useDefaultMerchant({ isFullData: true, fetchPolicy: "network-only" })

  useEffect(() => {
    if (partnerData) {
      return
    }

    if (defaultMerchant) {
      setPartnerData(defaultMerchant?.favoriteCharity?.partner)
    }
  }, [defaultMerchant])

  const [submitForReview] = useMutation(
    SUBMIT_FOR_REVIEW_MERCHANT_STORE_MUTATION,
    {
      client: merchantClient,
    }
  )

  const [partialUpdateMerchant] = useMutation(
    PARTIAL_UPDATE_MERCHANT_MUTATION,
    {
      client: merchantClient,
    }
  )

  const [offerSubmitForReview] = useMutation(OFFER_SUBMIT_FOR_REVIEW_MUTATION, {
    client: merchantClient,
  })

  const [createRepresentativeUser] = useMutation(CREATE_REPRESENTATIVE_USER, {
    client: merchantClient,
  })

  const [updateRepresentativeUser] = useMutation(UPDATE_REPRESENTATIVE_USER, {
    client: merchantClient,
  })

  const editText = (
    <span>
      {formatMessage({
        id: "dashboard::profile::edit",
        defaultMessage: "Edit",
      })}
    </span>
  )

  const initialValues = {
    name: defaultMerchant?.name ?? "",
    displayName: defaultMerchant?.displayName ?? "",
    website: defaultMerchant?.website ?? "",
    communicationLanguage:
      defaultMerchant?.communicationLanguage.length > 0
        ? defaultMerchant.communicationLanguage.toUpperCase()
        : locale.toUpperCase(),
    logo: defaultMerchant?.logo ?? "",
    image: defaultMerchant?.image ?? "",
    images:
      defaultMerchant?.images?.edges?.map(({ node }) => ({
        ...node,
        existing: true,
      })) ?? [],
    hidePhoneNumber: defaultMerchant?.hidePhoneNumber ?? "",
    hideEmail: defaultMerchant?.hideEmail ?? "",
    phoneNumber: defaultMerchant?.phoneNumber ?? "",
    hideAddress: defaultMerchant?.hideAddress ?? "",
    // showAddressAndPhone: defaultOffer?.showAddressAndPhone ?? true,
    email: defaultMerchant?.email ?? "",
    taxId: defaultMerchant?.taxId ?? "",
    shortDescription: defaultMerchant?.shortDescription || "",
    shortDescriptionEn: defaultMerchant?.shortDescriptionEn || "",
    shortDescriptionIt: defaultMerchant?.shortDescriptionIt || "",
    shortDescriptionEs: defaultMerchant?.shortDescriptionEs || "",
    maxPacDiscount: defaultMerchant?.maxPacDiscount,
    shopLink: defaultMerchant?.shopLink ?? "",
    categories: defaultOffer?.categories?.edges?.length
      ? defaultOffer?.categories?.edges?.map((item) => {
          return {
            value: item?.node?.id,
            label: getValueForLocale(item?.node, "name", locale, defaultLocale),
          }
        })
      : [],
    country: defaultMerchant?.country ?? "",
    city: defaultMerchant?.city ?? "",
    postCode: defaultMerchant?.postCode ?? "",
    addressLine1: defaultMerchant?.addressLine1 ?? "",
    favoriteCharity: defaultMerchant?.favoriteCharity?.id ?? "",
    allowUsersToSelectADifferentCampaign:
      defaultMerchant?.allowUsersToSelectADifferentCampaign,
    availableCountries: defaultOffer?.availableCountries,
    availableCities: defaultOffer?.availableCities,
    isWorldwide: defaultOffer?.isWorldwide,
    maxPacDiscount: defaultOffer?.maxPacDiscount,
  }

  const validationSchema = Yup.object().shape({
    /* General */
    name:
      isGeneralEdit &&
      requiredString({
        formatMessage,
      }),
    displayName:
      isGeneralEdit &&
      requiredString({
        formatMessage,
      }),
    communicationLanguage:
      isGeneralEdit &&
      requiredString({
        formatMessage,
      }),
    categories:
      isDetailEdit &&
      categories({
        formatMessage,
      }),
    website: isGeneralEdit && urlFormat({ formatMessage }),
    phoneNumber:
      isGeneralEdit &&
      requiredString({
        formatMessage,
      }),
    maxPacDiscount:
      isDetailEdit &&
      (isMerchantInternal || isMerchantOffline || isMerchantOnline) &&
      requiredString({
        formatMessage,
      }),
    email:
      isGeneralEdit &&
      email({
        formatMessage,
      }),
    /* Address */
    country:
      isAddressEdit &&
      requiredString({
        formatMessage,
      }),
    addressLine1:
      isAddressEdit &&
      requiredString({
        formatMessage,
      }),
    postCode:
      isAddressEdit &&
      requiredString({
        formatMessage,
      }),
    /* Favourite Charity */
    favoriteCharity:
      isFavouriteCharityEdit &&
      requiredString({
        formatMessage,
      }),
    /* Tell About locale === "en" &&*/
    shortDescriptionEn:
      isTellAboutEdit &&
      locale === "en" &&
      shortDescription({
        formatMessage,
      }),
    shortDescriptionIt:
      isTellAboutEdit &&
      locale === "it" &&
      shortDescription({
        formatMessage,
      }),
    shortDescriptionEs:
      isTellAboutEdit &&
      locale === "es" &&
      shortDescription({
        formatMessage,
      }),
  })

  /**
   * submitForReview: for all updates except for location and categories
   * offerSubmitForReview: for location and categories update
   */

  const handleSubmit = async (values) => {
    const promiseArr = []

    const valuesSubmitForReview = {}
    const valuesOfferSubmitForReview = {}

    if (isGeneralEdit) {
      valuesSubmitForReview.name = values?.name
      valuesSubmitForReview.displayName = values?.displayName
      valuesSubmitForReview.communicationLanguage =
        values?.communicationLanguage.toUpperCase()
      valuesSubmitForReview.website = values?.website
      valuesSubmitForReview.hidePhoneNumber = values?.hidePhoneNumber
      valuesSubmitForReview.phoneNumber = values?.phoneNumber
      valuesSubmitForReview.hideEmail = values?.hideEmail
      valuesSubmitForReview.email = values?.email
      valuesSubmitForReview.taxId = values?.taxId
    }
    if (isDetailEdit) {
      valuesOfferSubmitForReview.categories = values?.categories
      if (isMerchantInternal || isMerchantOffline || isMerchantOnline) {
        valuesOfferSubmitForReview.availableCities = values?.isWorldwide
          ? []
          : values?.availableCities
        valuesOfferSubmitForReview.availableCountries = values?.isWorldwide
          ? []
          : values?.availableCountries
        valuesOfferSubmitForReview.isWorldwide = values?.isWorldwide
        valuesOfferSubmitForReview.maxPacDiscount = values?.maxPacDiscount
      }
    }

    if (isAddressEdit) {
      try {
        setAddressError()
        setAddressLoading(true)
        const correctedAddressData = await checkAddress(
          values?.addressLine1,
          values?.postCode,
          values?.country
        )
        if (correctedAddressData?.result) {
          setAddressError()
          valuesSubmitForReview.addressLine1 =
            correctedAddressData?.addressLine1
          valuesSubmitForReview.city = correctedAddressData?.city
          valuesSubmitForReview.country =
            correctedAddressData?.country || values?.country
          valuesSubmitForReview.postCode = correctedAddressData?.postalCode
          valuesOfferSubmitForReview.location = `SRID=4326;POINT(${correctedAddressData?.longitude} ${correctedAddressData?.latitude})`
        } else throw new Error()
      } catch {
        setAddressError(
          formatMessage({
            id: "forms::address::notValid",
            defaultMessage:
              "Address or post code is not valid. Please try again.",
          })
        )
        return
      } finally {
        setAddressLoading(false)
      }
    }

    if (isFavouriteCharityEdit) {
      valuesSubmitForReview.favoriteCharity = values?.favoriteCharity
      valuesSubmitForReview.allowUsersToSelectADifferentCampaign =
        values?.allowUsersToSelectADifferentCampaign
    }

    if (isTellAboutEdit) {
      valuesSubmitForReview.shortDescription = values?.shortDescription
      valuesSubmitForReview.shortDescriptionEn = values?.shortDescriptionEn
      valuesSubmitForReview.shortDescriptionIt = values?.shortDescriptionIt
      valuesSubmitForReview.shortDescriptionEs = values?.shortDescriptionEs
    }

    setIsGeneralEdit(false)
    setIsAddressEdit(false)
    setIsDetailEdit(false)
    setIsFavouriteCharityEdit(false)
    setIsTellAboutEdit(false)

    const errorHandler = (err) => {
      const msg = err?.message ?? err?.field + ":" + err?.messages?.[0]
      error(
        msg ??
          formatMessage({
            id: "dashboard::campaignForm::errorMessage",
            defaultMessage: "An error occurred",
          })
      )
    }

    const mutationSuccessMessage = formatMessage({
      id: "dashboard::merchantStore::submittedMessage",
      defaultMessage: "Your updates are submitted for review!",
    })

    if (Object.keys(valuesSubmitForReview).length > 0)
      promiseArr.push(
        submitForReview({
          variables: {
            input: {
              ...valuesSubmitForReview,
            },
          },
          client: merchantClient,
        })
          .then(() => {
            setTimeout(() => {
              partialUpdateMerchant({
                variables: {
                  input: {
                    id: defaultMerchant?.id,
                    reviewStatus: "READY",
                  },
                },
              })
            }, 1000)
            if (isMerchantCorporate) {
              success(mutationSuccessMessage)
              refetch()
              navigate(`/dashboard/merchants/account/`)
            }
          })
          .catch(errorHandler)
      )

    if (Object.keys(valuesOfferSubmitForReview).length > 0)
      promiseArr.push(
        offerSubmitForReview({
          variables: {
            input: {
              id: defaultOffer?.id,
              ...valuesOfferSubmitForReview,
            },
          },
        })
          .then(() => {
            setTimeout(() => {
              partialUpdateOffer({
                variables: {
                  input: {
                    id: defaultOffer?.id,
                    reviewStatus: "READY",
                  },
                },
              })
            }, 1000)
            if (isMerchantCorporate) {
              success(mutationSuccessMessage)
              refetch()
              navigate(`/dashboard/merchants/account/`)
            }
          })
          .catch(errorHandler)
      )

    Promise.all(promiseArr)
      .catch(errorHandler)
      .then((result) => {
        const merchantStoreSubmitForReviewErrors =
          result?.[0]?.data?.merchantStoreSubmitForReview?.errors || []
        const offerSubmitForReview =
          result?.[1]?.data?.offerSubmitForReview?.errors || []
        const errors = [
          ...merchantStoreSubmitForReviewErrors,
          ...offerSubmitForReview,
        ]
        if (errors?.length) {
          errors?.forEach((err) => errorHandler(err))
          return
        }
        // setTimeout(() => {
        //   partialUpdateOffer({
        //     variables: {
        //       input: {
        //         id: defaultOffer?.id,
        //         reviewStatus: "READY",
        //       },
        //     },
        //   })
        //   partialUpdateMerchant({
        //     variables: {
        //       input: {
        //         id: defaultMerchant?.id,
        //         reviewStatus: "READY",
        //       },
        //     },
        //   })
        // }, 1000)
        success(mutationSuccessMessage)
        navigate(`/dashboard/merchants/account/`)
        refetch()
      })
  }

  /* Representative User */
  const initialValuesForLegalUser = {
    id: legalRepresentativeUser?.id ?? "",
    firstName: legalRepresentativeUser?.firstName ?? "",
    lastName: legalRepresentativeUser?.lastName ?? "",
    fullName:
      (legalRepresentativeUser?.firstName ?? "") +
      " " +
      (legalRepresentativeUser?.lastName ?? ""),
    fiscalCode: legalRepresentativeUser?.fiscalCode ?? "",
    email: legalRepresentativeUser?.email ?? "",
    phoneNumber: legalRepresentativeUser?.phoneNumber ?? "",
    address: legalRepresentativeUser?.address ?? "",
    attributes: legalRepresentativeUser?.attributes ?? "",
    representativeType:
      legalRepresentativeUser?.representativeType ??
      REPRESENTATIVE_USER_TYPE.LEGAL,
  }

  const initialValuesForTechnicalUser = {
    id: technicalRepresentativeUser?.id ?? "",
    firstName: technicalRepresentativeUser?.firstName ?? "",
    lastName: technicalRepresentativeUser?.lastName ?? "",
    fullName:
      (technicalRepresentativeUser?.firstName ?? "") +
      " " +
      (technicalRepresentativeUser?.lastName ?? ""),
    email: technicalRepresentativeUser?.email ?? "",
    phoneNumber: technicalRepresentativeUser?.phoneNumber ?? "",
    address: technicalRepresentativeUser?.address ?? "",
    attributes: technicalRepresentativeUser?.attributes ?? "",
    representativeType:
      technicalRepresentativeUser?.representativeType ??
      REPRESENTATIVE_USER_TYPE.TECHNICAL,
  }

  const initialValuesForMarketingUser = {
    id: marketingRepresentativeUser?.id ?? "",
    firstName: marketingRepresentativeUser?.firstName ?? "",
    lastName: marketingRepresentativeUser?.lastName ?? "",
    fullName:
      (marketingRepresentativeUser?.firstName ?? "") +
      " " +
      (marketingRepresentativeUser?.lastName ?? ""),
    email: marketingRepresentativeUser?.email ?? "",
    phoneNumber: marketingRepresentativeUser?.phoneNumber ?? "",
    address: marketingRepresentativeUser?.address ?? "",
    representativeType:
      marketingRepresentativeUser?.representativeType ??
      REPRESENTATIVE_USER_TYPE.MARKETING,
  }

  const validationSchemaForRepresentativeUser = Yup.object().shape({
    firstName: requiredString({
      formatMessage,
    }),
    lastName: requiredString({
      formatMessage,
    }),
    fiscalCode:
      isLegalEdit &&
      requiredString({
        formatMessage,
      }),
    email: email({
      formatMessage,
    }),
    address: address({ formatMessage }),
  })

  const handleSubmitForRepresentativeUser = (values, representativeType) => {
    delete values?.fullName

    if (values?.id) {
      updateRepresentativeUser({
        variables: {
          input: {
            ...values,
            attributes: JSON.stringify(
              isLegalEdit
                ? { body: values?.attributes, title: "Role" }
                : isTechnicalEdit
                ? { body: values?.attributes, title: "Technology used" }
                : ""
            ),
          },
        },
        client: merchantClient,
      })
        .catch(errorHandler)
        .then(() => {
          setTimeout(() => {
            partialUpdateMerchant({
              variables: {
                input: {
                  id: defaultMerchant?.id,
                  reviewStatus: "READY",
                },
              },
            })
          }, 2000)
          refetchRepresentatives()
          success(
            formatMessage({
              id: "dashboard::profileForm::updateMessage",
              defaultMessage: "Representative user is updated!",
            })
          )
          navigate(`/dashboard/merchants/account/`)
        })
    } else {
      createRepresentativeUser({
        variables: {
          input: {
            ...values,
            attributes: JSON.stringify(
              isLegalEdit
                ? { body: values?.attributes, title: "Role" }
                : isTechnicalEdit
                ? { body: values?.attributes, title: "Technology used" }
                : ""
            ),
          },
        },
        client: merchantClient,
      })
        .then(({ data }) => {
          if (data?.createRepresentativeUser?.errors?.[0]?.messages?.[0])
            throw new Error(
              data?.createRepresentativeUser?.errors?.[0]?.messages?.[0]
            )
          setTimeout(() => {
            partialUpdateMerchant({
              variables: {
                input: {
                  id: defaultMerchant?.id,
                  reviewStatus: "READY",
                },
              },
            })
          }, 2000)
          refetchRepresentatives()
          success(
            formatMessage({
              id: "dashboard::profileForm::updateMessage",
              defaultMessage: "Representative user is updated!",
            })
          )
          navigate(`/dashboard/merchants/account/`)
        })
        .catch(errorHandler)
    }

    var t =
      representativeType === REPRESENTATIVE_USER_TYPE.LEGAL
        ? setIsLegalEdit(false)
        : representativeType === REPRESENTATIVE_USER_TYPE.TECHNICAL
        ? setIsTechnicalEdit(false)
        : representativeType === REPRESENTATIVE_USER_TYPE.MARKETING
        ? setIsMarketingEdit(false)
        : null
  }

  const errorHandler = (err) => {
    error(
      err?.message ??
        formatMessage({
          id: "dashboard::campaignForm::errorMessage",
          defaultMessage: "An error occurred",
        })
    )
  }

  return (
    <StyledPage>
      <StyledPageTitle>
        <FormattedMessage
          id="dashboard::profile::accounts"
          defaultMessage="Account"
          tagName="h1"
        />
      </StyledPageTitle>
      <StyledPageContent>
        {isLoading || loadingMerchant ? (
          <Spinner condensed />
        ) : (
          <>
            <Formik
              enableReinitialize={true}
              validationSchema={validationSchema}
              initialValues={initialValues}>
              {({ values, handleReset, setFieldValue }) => {
                return (
                  <StyledForm>
                    {/* Store Logo */}
                    <StyledPageGrid>
                      <StyledRowTitle>
                        <FormattedMessage
                          id="dashboard::merchantStore::storeLogo"
                          defaultMessage="Store logo"
                        />
                        <StyledProfileSpacer bottom={0.5} />
                        <StyledRowTitleExplanation>
                          <FormattedMessage
                            id="dashboard::merchantStore::storeLogoExplanation"
                            defaultMessage="1200x675px is the recommended resolution, or other which has an aspect ratio of 16/9"
                          />
                        </StyledRowTitleExplanation>
                      </StyledRowTitle>
                      <StoreLogoProfileForm merchant={defaultMerchant} />
                    </StyledPageGrid>

                    <StyledProfileSpacer bottom={1.75} />

                    {/* General */}
                    <StyledPageGrid>
                      <StyledRowTitle>
                        <FormattedMessage
                          id="dashboard::merchantStore::general"
                          defaultMessage="General"
                        />
                        <StyledProfileSpacer bottom={0.5} />
                        <StyledRowTitleExplanation>
                          <FormattedMessage
                            id="dashboard::settings::generalExplanation"
                            defaultMessage="Customers will see your display name on your profile and products pages. Legal name will be used to process payments as well as will be stated in the receipt"
                          />
                        </StyledRowTitleExplanation>
                        <StyledProfileSpacer bottom={0.5} />
                        {apiPermissions?.includes(
                          API_PERMISSIONS.MERCHANT_UPDATE_ACCOUNT_INFORMATION
                        ) &&
                          !isGeneralEdit && (
                            <StyledEditLink
                              onClick={() => {
                                handleReset()
                                setIsGeneralEdit(true)
                                setIsDetailEdit(false)

                                setIsAddressEdit(false)
                                setIsFavouriteCharityEdit(false)
                                setIsTellAboutEdit(false)
                                setIsLegalEdit(false)
                                setIsTechnicalEdit(false)
                                setIsMarketingEdit(false)
                              }}>
                              {editText}
                            </StyledEditLink>
                          )}
                      </StyledRowTitle>
                      <GeneralProfileForm
                        merchant={defaultMerchant}
                        offer={defaultOffer}
                        isMerchantInternal={isMerchantInternal}
                        isMerchantOffline={isMerchantOffline}
                        isMerchantOnline={isMerchantOnline}
                        isEdit={isGeneralEdit}
                        onCancel={() => {
                          handleReset()
                          setIsGeneralEdit(false)
                        }}
                        onSave={() => handleSubmit(values)}
                      />
                    </StyledPageGrid>

                    <StyledProfileSpacer bottom={1.75} />

                    {/* Details/Categories */}
                    <StyledPageGrid>
                      <StyledRowTitle>
                        <FormattedMessage
                          id="dashboard::merchantStore::details"
                          defaultMessage="Details"
                        />
                        <StyledProfileSpacer bottom={0.5} />
                        {apiPermissions?.includes(
                          API_PERMISSIONS.MERCHANT_UPDATE_ACCOUNT_INFORMATION
                        ) &&
                          !isDetailEdit && (
                            <StyledEditLink
                              onClick={() => {
                                handleReset()
                                setIsDetailEdit(true)
                                setIsGeneralEdit(false)
                                setIsAddressEdit(false)
                                setIsFavouriteCharityEdit(false)
                                setIsTellAboutEdit(false)
                                setIsLegalEdit(false)
                                setIsTechnicalEdit(false)
                                setIsMarketingEdit(false)
                              }}>
                              {editText}
                            </StyledEditLink>
                          )}
                      </StyledRowTitle>
                      <DetailsProfileForm
                        offer={defaultOffer}
                        isMerchantCorporate={isMerchantCorporate}
                        isMerchantInternal={isMerchantInternal}
                        isMerchantOffline={isMerchantOffline}
                        isMerchantOnline={isMerchantOnline}
                        isEdit={isDetailEdit}
                        onCancel={() => {
                          handleReset()
                          setIsDetailEdit(false)
                        }}
                        onSave={() => handleSubmit(values)}
                      />
                    </StyledPageGrid>

                    <StyledProfileSpacer bottom={1.75} />

                    {/* Address */}
                    <StyledPageGrid>
                      <StyledRowTitle>
                        <FormattedMessage
                          id="dashboard::forms::address"
                          defaultMessage="Address"
                        />
                        <StyledProfileSpacer bottom={0.5} />
                        {apiPermissions?.includes(
                          API_PERMISSIONS.MERCHANT_UPDATE_ACCOUNT_INFORMATION
                        ) &&
                          !isAddressEdit && (
                            <StyledEditLink
                              onClick={() => {
                                handleReset()
                                setIsAddressEdit(true)
                                setIsGeneralEdit(false)
                                setIsDetailEdit(false)
                                setIsFavouriteCharityEdit(false)
                                setIsTellAboutEdit(false)
                                setIsLegalEdit(false)
                                setIsTechnicalEdit(false)
                                setIsMarketingEdit(false)
                              }}>
                              {editText}
                            </StyledEditLink>
                          )}
                      </StyledRowTitle>
                      <AddressProfileForm
                        merchant={defaultMerchant}
                        isEdit={isAddressEdit}
                        addressLoading={addressLoading}
                        addressError={addressError}
                        onCancel={() => {
                          handleReset()
                          setIsAddressEdit(false)
                          setAddressError(false)
                        }}
                        onSave={() => handleSubmit(values)}
                        isMerchantOnline={isMerchantOnline}
                        isMerchantCorporate={isMerchantCorporate}
                      />
                    </StyledPageGrid>

                    <StyledProfileSpacer bottom={1.75} />

                    {/* Favourite Charity */}
                    {isMerchantInternal && (
                      <>
                        <StyledPageGrid>
                          <StyledRowTitle>
                            <FormattedMessage
                              id="dashboard::settings::favouriteCharity"
                              defaultMessage="Favorite Charity"
                            />
                            <StyledProfileSpacer bottom={0.5} />
                            <StyledRowTitleExplanation>
                              <FormattedMessage
                                id="dashboard::settings::favouriteCharityExplanation2"
                                defaultMessage="Make charity your favourite, we will remember your choice and it will be used for donations by shopping as well as orders"
                              />
                            </StyledRowTitleExplanation>
                            <StyledProfileSpacer bottom={0.5} />
                            {apiPermissions?.includes(
                              API_PERMISSIONS.MERCHANT_UPDATE_ACCOUNT_INFORMATION
                            ) &&
                              !isFavouriteCharityEdit && (
                                <StyledEditLink
                                  onClick={() => {
                                    handleReset()
                                    setIsFavouriteCharityEdit(true)
                                    setIsGeneralEdit(false)
                                    setIsDetailEdit(false)
                                    setIsAddressEdit(false)
                                    setIsTellAboutEdit(false)
                                    setIsLegalEdit(false)
                                    setIsTechnicalEdit(false)
                                    setIsMarketingEdit(false)

                                    setTempFavoriteCharityId(
                                      tempFavoriteCharityId ??
                                        values?.favoriteCharity
                                    )
                                    setTempFavoriteCharity(
                                      tempFavoriteCharity ??
                                        defaultMerchant?.favoriteCharity
                                    )
                                  }}>
                                  {editText}
                                </StyledEditLink>
                              )}
                          </StyledRowTitle>
                          <FavoriteCharityProfileForm
                            merchant={defaultMerchant}
                            partnerLogo={
                              defaultMerchant?.favoriteCharity?.partner?.logo
                            }
                            tempFavoriteCharityId={tempFavoriteCharityId}
                            tempFavoriteCharity={tempFavoriteCharity}
                            isEdit={isFavouriteCharityEdit}
                            onFavChange={(charityId, charity) => {
                              setFieldValue("favoriteCharity", charityId)
                              setTempFavoriteCharityId(charityId)
                              setTempFavoriteCharity(charity)
                            }}
                            onToggleChange={() => {
                              setFieldValue(
                                "allowUsersToSelectADifferentCampaign",
                                !values?.allowUsersToSelectADifferentCampaign
                              )
                            }}
                            onCancel={() => {
                              handleReset()
                              setIsFavouriteCharityEdit(false)
                            }}
                            onSave={() => handleSubmit(values)}
                          />
                        </StyledPageGrid>

                        <StyledProfileSpacer bottom={1.75} />
                      </>
                    )}

                    {/* Photo */}
                    {(isMerchantOnline || isMerchantInternal) && (
                      <>
                        <StyledPageGrid>
                          <StyledRowTitle>
                            <FormattedMessage
                              id="dashboard::merchantStore::photo"
                              defaultMessage="Photo"
                            />
                            <StyledProfileSpacer bottom={0.5} />
                            <StyledRowTitleExplanation>
                              <FormattedMessage
                                id="dashboard::service::uploadPhotoDescription"
                                defaultMessage="Upload image that represent your brand. We cut images to the ratio of horizontal 16:9. 1200x675px is the recommended resolution."
                              />
                            </StyledRowTitleExplanation>
                          </StyledRowTitle>
                          <PhotoProfileForm initialValues={initialValues} />
                        </StyledPageGrid>

                        <StyledProfileSpacer bottom={1.75} />
                      </>
                    )}

                    {/* Tell About */}
                    <StyledPageGrid>
                      <StyledRowTitle>
                        {isMerchantCorporate ? (
                          <FormattedMessage
                            id="dashboard::merchantStore::tellAboutYourOrganization"
                            defaultMessage="Tell customers about your organization"
                          />
                        ) : (
                          <FormattedMessage
                            id="dashboard::merchantStore::tellAboutYourStore"
                            defaultMessage="Tell customers about your store"
                          />
                        )}
                        <StyledProfileSpacer bottom={0.5} />
                        <StyledRowTitleExplanation>
                          {isMerchantCorporate ? (
                            <FormattedMessage
                              id="dashboard::merchantStore::tellAboutYourOrganizationExplanation"
                              defaultMessage="Main organization information to be seen on your store page"
                            />
                          ) : (
                            <FormattedMessage
                              id="dashboard::merchantStore::tellAboutYourStoreExplanation"
                              defaultMessage="Main store information to be seen on your store page"
                            />
                          )}
                        </StyledRowTitleExplanation>
                        <StyledProfileSpacer bottom={0.5} />
                        {apiPermissions?.includes(
                          API_PERMISSIONS.MERCHANT_UPDATE_ACCOUNT_INFORMATION
                        ) &&
                          !isTellAboutEdit && (
                            <StyledEditLink
                              onClick={() => {
                                handleReset()
                                setIsTellAboutEdit(true)
                                setIsGeneralEdit(false)
                                setIsDetailEdit(false)

                                setIsAddressEdit(false)
                                setIsFavouriteCharityEdit(false)
                                setIsLegalEdit(false)
                                setIsTechnicalEdit(false)
                                setIsMarketingEdit(false)
                              }}>
                              {editText}
                            </StyledEditLink>
                          )}
                      </StyledRowTitle>
                      <TellAboutProfileForm
                        merchant={defaultMerchant}
                        isEdit={isTellAboutEdit}
                        onCancel={() => {
                          handleReset()
                          setIsTellAboutEdit(false)
                        }}
                        onSave={() => handleSubmit(values)}
                      />
                    </StyledPageGrid>
                  </StyledForm>
                )
              }}
            </Formik>
            <StyledProfileSpacer bottom={1.75} />
            <Formik
              enableReinitialize={true}
              validationSchema={validationSchemaForRepresentativeUser}
              initialValues={initialValuesForLegalUser}>
              {({ values, handleReset }) => {
                return (
                  <StyledForm>
                    <StyledPageGrid>
                      <StyledRowTitle style={{ width: "12.8125rem" }}>
                        <FormattedMessage
                          id="dashboard::forms::legalRepresentative"
                          defaultMessage="Legal representative"
                        />
                        <StyledProfileSpacer bottom={0.5} />
                        {apiPermissions?.includes(
                          API_PERMISSIONS.MERCHANT_UPDATE_ACCOUNT_INFORMATION
                        ) &&
                          !isLegalEdit && (
                            <StyledEditLink
                              onClick={() => {
                                handleReset()
                                setIsLegalEdit(true)
                                setIsGeneralEdit(false)
                                setIsDetailEdit(false)
                                setIsAddressEdit(false)
                                setIsFavouriteCharityEdit(false)
                                setIsTellAboutEdit(false)
                                setIsTechnicalEdit(false)
                                setIsMarketingEdit(false)
                              }}>
                              {editText}
                            </StyledEditLink>
                          )}
                      </StyledRowTitle>

                      {legalRepresentativeUser?.representativeType ||
                      isLegalEdit ? (
                        <RepresentativeUserProfileForm
                          representativeUser={legalRepresentativeUser}
                          isEdit={isLegalEdit}
                          onCancel={() => {
                            handleReset()
                            setIsLegalEdit(false)
                          }}
                          onSave={() =>
                            handleSubmitForRepresentativeUser(
                              values,
                              REPRESENTATIVE_USER_TYPE.LEGAL
                            )
                          }
                          isOnline={isMerchantOnline}
                        />
                      ) : (
                        <FormattedMessage
                          id="dashboard::forms::NoLegalRepresentative"
                          defaultMessage="You have not entered legal representative details yet."
                        />
                      )}
                    </StyledPageGrid>
                  </StyledForm>
                )
              }}
            </Formik>
            <StyledProfileSpacer bottom={1.75} />
            <Formik
              enableReinitialize={true}
              validationSchema={validationSchemaForRepresentativeUser}
              initialValues={initialValuesForTechnicalUser}>
              {({ values, handleReset }) => {
                return (
                  <StyledForm>
                    <StyledPageGrid>
                      <StyledRowTitle style={{ width: "12.8125rem" }}>
                        <FormattedMessage
                          id="dashboard::forms::technicalRepresentative"
                          defaultMessage="Technical representative"
                        />
                        <StyledProfileSpacer bottom={0.5} />
                        {apiPermissions?.includes(
                          API_PERMISSIONS.MERCHANT_UPDATE_ACCOUNT_INFORMATION
                        ) &&
                          !isTechnicalEdit && (
                            <StyledEditLink
                              onClick={() => {
                                handleReset()
                                setIsTechnicalEdit(true)
                                setIsGeneralEdit(false)
                                setIsDetailEdit(false)
                                setIsAddressEdit(false)
                                setIsFavouriteCharityEdit(false)
                                setIsTellAboutEdit(false)
                                setIsLegalEdit(false)
                                setIsMarketingEdit(false)
                              }}>
                              {editText}
                            </StyledEditLink>
                          )}
                      </StyledRowTitle>

                      {technicalRepresentativeUser?.representativeType ||
                      isTechnicalEdit ? (
                        <RepresentativeUserProfileForm
                          representativeUser={technicalRepresentativeUser}
                          isEdit={isTechnicalEdit}
                          onCancel={() => {
                            handleReset()
                            setIsTechnicalEdit(false)
                          }}
                          onSave={() =>
                            handleSubmitForRepresentativeUser(
                              values,
                              REPRESENTATIVE_USER_TYPE.TECHNICAL
                            )
                          }
                          isOnline={isMerchantOnline}
                        />
                      ) : (
                        <FormattedMessage
                          id="dashboard::forms::NoTechnicalRepresentative"
                          defaultMessage="You have not entered technical representative details yet."
                        />
                      )}
                    </StyledPageGrid>
                  </StyledForm>
                )
              }}
            </Formik>
            <StyledProfileSpacer bottom={1.75} />
            <Formik
              enableReinitialize={true}
              validationSchema={validationSchemaForRepresentativeUser}
              initialValues={initialValuesForMarketingUser}>
              {({ values, handleReset }) => {
                return (
                  <StyledForm>
                    <StyledPageGrid>
                      <StyledRowTitle style={{ width: "12.8125rem" }}>
                        <FormattedMessage
                          id="dashboard::forms::marketingRepresentative"
                          defaultMessage="Marketing representative"
                        />
                        <StyledProfileSpacer bottom={0.5} />
                        {apiPermissions?.includes(
                          API_PERMISSIONS.MERCHANT_UPDATE_ACCOUNT_INFORMATION
                        ) &&
                          !isMarketingEdit && (
                            <StyledEditLink
                              onClick={() => {
                                handleReset()
                                setIsMarketingEdit(true)
                                setIsGeneralEdit(false)
                                setIsDetailEdit(false)
                                setIsAddressEdit(false)
                                setIsFavouriteCharityEdit(false)
                                setIsTellAboutEdit(false)
                                setIsLegalEdit(false)
                                setIsTechnicalEdit(false)
                              }}>
                              {editText}
                            </StyledEditLink>
                          )}
                      </StyledRowTitle>

                      {marketingRepresentativeUser?.representativeType ||
                      isMarketingEdit ? (
                        <RepresentativeUserProfileForm
                          representativeUser={marketingRepresentativeUser}
                          isEdit={isMarketingEdit}
                          onCancel={() => {
                            handleReset()
                            setIsMarketingEdit(false)
                          }}
                          onSave={() =>
                            handleSubmitForRepresentativeUser(
                              values,
                              REPRESENTATIVE_USER_TYPE.MARKETING
                            )
                          }
                          isOnline={isMerchantOnline}
                        />
                      ) : (
                        <FormattedMessage
                          id="dashboard::forms::NoMarketingRepresentative"
                          defaultMessage="You have not entered marketing representative details yet."
                        />
                      )}
                    </StyledPageGrid>
                  </StyledForm>
                )
              }}
            </Formik>
          </>
        )}
      </StyledPageContent>
    </StyledPage>
  )
}

export default MerchantProfileForm
