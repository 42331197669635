import React, { Fragment } from "react"
import { FormattedMessage, Link } from "gatsby-plugin-intl"
import { LazyImage } from "@tmu/components/common"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import {
  StyledPacsDiscountContainer,
  StyledImageBox,
  StyledPacsDiscountDescriptionContainer,
  StyledPacsDiscountTitle,
  StyledPacsDiscountText1,
  StyledPacsDiscountText2,
  StyledPacsDiscountViewPacAllOffersButton,
} from "./index.styles"

const { CLOUDFLARE_IMAGE_URL } = process.env

const PacsDiscount = () => {
  const { isTablet, isDesktop, isWide } = getAllScreenTypes()
  const imageName1 = isTablet ? "Card2_small.png" : "Card_1_360.png"
  const imageWidth = isWide ? 268 : isDesktop ? 183 : isTablet ? 153 : 360
  const imageHeight = isWide ? 397 : isDesktop ? 270 : isTablet ? 225 : 154

  return (
    <StyledPacsDiscountContainer>
      {isTablet ? (
        <>
          <StyledImageBox>
            <LazyImage
              src={
                CLOUDFLARE_IMAGE_URL +
                "/static/assets/images/blank_cards/" +
                imageName1
              }
              altName={`pacs-blank_cards-banner`}
              width={imageWidth}
              height={imageHeight}
              fit="cover"
            />
          </StyledImageBox>
        </>
      ) : (
        <>
          <StyledPacsDiscountTitle>
            <FormattedMessage
              id="pacs::discount::title"
              defaultMessage="Choose a discount"
            />
          </StyledPacsDiscountTitle>
          <StyledImageBox>
            <LazyImage
              src={
                CLOUDFLARE_IMAGE_URL +
                "/static/assets/images/blank_cards/" +
                imageName1
              }
              altName={`pacs-blank_cards-banner`}
              width={imageWidth}
              height={imageHeight}
              fit="cover"
            />
          </StyledImageBox>
        </>
      )}
      <StyledPacsDiscountDescriptionContainer>
        {isTablet && (
          <StyledPacsDiscountTitle>
            <FormattedMessage
              id="pacs::discount::title"
              defaultMessage="Choose a discount"
            />
          </StyledPacsDiscountTitle>
        )}
        <StyledPacsDiscountText1>
          <FormattedMessage
            id="productListing::howToSpendPacs::text1"
            defaultMessage="<span>Numbers</span> show the amount of PAC Offer -- how much % of the price you can pay with your PACS."
            values={{
              span: (...chunks) => (
                <span>
                  {chunks.map((chunk, i) => (
                    <Fragment key={i}>{chunk}</Fragment>
                  ))}
                </span>
              ),
            }}>
            {(...chunks) => (
              <p>
                {chunks.map((chunk, i) => (
                  <Fragment key={i}>{chunk}</Fragment>
                ))}
              </p>
            )}
          </FormattedMessage>
        </StyledPacsDiscountText1>
        <StyledPacsDiscountText2>
          <FormattedMessage
            id="productListing::howToSpendPacs::text2"
            defaultMessage="Choose an offer to go the merchant’s store and put the offer in the merchant’s cart. PACs will be applied in the merchant’s cart."
            tagName="p"
          />
        </StyledPacsDiscountText2>
        <br />
        <StyledPacsDiscountViewPacAllOffersButton>
          <Link to={`/offers?offer=true`} aria-label="View PAC offers">
            <FormattedMessage
              id="pacs::discount::viewPacAllOffersInDiscount"
              defaultMessage="View PAC offers"
            />
          </Link>
        </StyledPacsDiscountViewPacAllOffersButton>
      </StyledPacsDiscountDescriptionContainer>
    </StyledPacsDiscountContainer>
  )
}

export default PacsDiscount
