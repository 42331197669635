import React, { useState } from "react"
import PropTypes from "prop-types"
import { StyledListButton, StyledListTypeSwitcher } from "./index.styles"
import { faBars } from "@fortawesome/pro-solid-svg-icons/faBars"
import { faGrip } from "@fortawesome/free-solid-svg-icons/faGrip"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { LIST_TYPES } from "@tmu/apollo/constants"

const ListTypeSwitcher = ({ handleListType }) => {
  const [activeListType, setActiveListType] = useState(LIST_TYPES.TILE)

  const setListType = (listType) => {
    setActiveListType(listType)

    if (handleListType) {
      handleListType(listType)
    }
  }

  return (
    <StyledListTypeSwitcher>
      <StyledListButton
        className={activeListType === LIST_TYPES.TILE ? "tile active" : "tile"}
        onClick={() => setListType(LIST_TYPES.TILE)}>
        <FontAwesomeIcon icon={faGrip} />
      </StyledListButton>
      <StyledListButton
        className={activeListType === LIST_TYPES.LIST ? "active" : ""}
        onClick={() => setListType(LIST_TYPES.LIST)}>
        <FontAwesomeIcon icon={faBars} />
      </StyledListButton>
    </StyledListTypeSwitcher>
  )
}

export default ListTypeSwitcher

ListTypeSwitcher.propTypes = {
  handleListType: PropTypes.func,
}
