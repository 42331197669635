import styled from "@emotion/styled"

export const StyledContactForm = styled.form`
  text-align: left;

  > div {
    width: 100%;
    margin-bottom: 2rem;
  }

  .full-width {
    grid-column: 1 / 3;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }

  .select-field label {
    min-width: 4.6875rem;
  }

  .create-account-button {
    grid-column: 2;
    margin: 2.5rem auto 0;
    width: 100%;
    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      margin: 3rem auto 0;
      min-width: 19rem;
    }

    button {
      background-color: ${(p) => p.theme.colors.pacificBlue};
      width: 100%;
      font-family: ${(p) => p.theme.fontFamilies.headline};
      font-style: ${(p) => p.theme.fontStyles.normal};
      color: ${(p) => p.theme.colors.white};
      font-weight: ${(p) => p.theme.fontWeights.semiBolder};
      font-size: 0.75rem;
      line-height: 1rem;
      text-align: center;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      margin: 0 auto;

      @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
        font-size: 1rem;
        line-height: 1.375rem;
        margin: 1.5rem auto;
      }
    }
  }

  .info-area .input-feedback {
    margin: 1.5rem 0;
  }

  .select-field + div .input-feedback {
    padding-left: 1.9375rem;
    margin: 0.5rem 0;
  }

  .select-field {
    .react-select__control {
      height: 3rem;
      box-shadow: none;
      border-width: 0.0625rem;
      padding-left: 1.5rem;
      color: ${(p) => p.theme.colors.shuttleflGray};
      font-size: 1.1rem;
      &:hover,
      &:focus {
        box-shadow: none;
      }
    }

    .react-select__menu {
      z-index: 2;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    > div {
      margin-bottom: 3rem;
    }
    .caption {
      font-size: 1rem;
      margin-bottom: 0.75rem;
    }
    .input-feedback-absolute {
      font-size: 1rem;
      bottom: -1.5rem;
    }
    .input-feedback {
      font-size: 1rem;
    }
    input,
    .react-tel-input input,
    .create-account-button button {
      // height: 3.375rem;
    }
  }
`
