import React, { Fragment, useRef, useEffect } from "react"
import { useLocation } from "@reach/router"
import { FormattedMessage, navigate } from "gatsby-plugin-intl"
import { CardList, Button, Logo } from "@tmu/components/common"

import { Wrapper, AddDetails } from "./index.styles"
import { isInViewport } from "@tmu/utils/viewport"
import theme from "@tmu/global/theme"
const Offers = ({ offers }) => {
  const ref = useRef()
  const { hash } = useLocation()

  const handleViewAll = () => {
    navigate("/offers?offerrue&store=true")
  }

  const hashCallback = () => {
    const hashText = "#offers"
    const isHashInUrl = hash === hashText || window?.location?.hash === hashText
    if (isInViewport(ref) && !isHashInUrl)
      history.pushState(null, null, hashText)
  }

  useEffect(() => {
    document.addEventListener("scroll", hashCallback)
    return () => document.removeEventListener("scroll", hashCallback)
  }, [])

  return (
    <Wrapper data-testid="offeristing" id="offers" ref={ref}>
      <h3>
        <Logo fill={theme?.colors?.blue} />
        <FormattedMessage
          id="campaign::detail::earnPACsTitle"
          defaultMessage="Earn PACs and use them in stores"
        />
      </h3>
      <AddDetails>
        <FormattedMessage
          id="campaign::detail::earnPACsDetail"
          defaultMessage="TrustMeUp 'refunds' your trust! Here we reward you for doing good. Check your favorite stores to spend your PACs at."
          tagName="p"
        />
        <FormattedMessage
          id="campaign::detail::productSubText"
          defaultMessage="Support campaigns and we will <reward>reward</reward> you with
          <pac>Passions Coins(PACs)</pac> you can use at our partner stores as a discount for attractive deals on your favorite items"
          tagName="p"
          values={{
            reward: (...chunks) => <span>{chunks}</span>,
            pac: (...chunks) => <span>{chunks}</span>,
          }}>
          {(...chunks) => (
            <p className="text-message">
              {chunks.map((chunk, i) => (
                <Fragment key={i}>{chunk}</Fragment>
              ))}
            </p>
          )}
        </FormattedMessage>
      </AddDetails>
      <CardList slider variant="offer" items={offers} />
      <Button
        label="View All"
        color="red"
        onClick={handleViewAll}
        style={{ margin: "auto" }}>
        <FormattedMessage id="ui::viewAll" defaultMessage="View All" />
      </Button>
    </Wrapper>
  )
}

export default Offers
