import styled from "@emotion/styled"
import { Button } from "@tmu/components/common"

export const StyledDonorListWrapper = styled.div`
  color: ${(p) => p.theme.colors.pitchBlack};
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  min-width: 21rem;
  max-height: 80vh;
  overflow-y: auto;
  margin: 1rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    max-height: 31rem;
    margin: 0 !important;
  }
`

export const StyledDonorListWrapperMargin = styled(StyledDonorListWrapper)`
  padding-bottom: 5rem;
`
export const StyledActionButton = styled(Button)`
  width: auto;
  border-top: ${(p) => (p.isMobile ? "0.0625rem whitesmoke solid" : "none")};
  padding: 0;
  margin: 2rem 1rem 1rem 1rem";
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    width:  100%;
    margin: 2rem 0 0 0 !important;
  }
`
export const StyledActionButtonMargin = styled.div`
  width: ${(p) => (p.isMobile ? "auto" : "100%")};
  height: 4.5rem;
  filter: none;
  &:before {
    display: block;
    height: 3rem;
    width: 100%;
    background: linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
    content: "";
    position: absolute;
    top: -3rem;
    left: 0rem;
  }
`

export const StyledDonorsWrapper = styled.div`
  display: grid;
  grid-template-columns: 2rem auto;
  margin-right: 0.5rem;
  gap: 1rem;
  margin-bottom: 0.75rem;

  p {
  }
  div > img,
  .user {
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    background: ${(p) => p.theme.colors.blue};
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-transform: uppercase;
    svg {
      color: white;
      width: 1rem;
      height: 1rem;
    }
  }

  div {
    color: ${(p) => p.theme.colors.pitchBlack};
    font-size: 0.75rem;
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-family: ${(p) => p.theme.fontFamilies.headline};
    line-height: 1.25rem;
    &:first-letter {
      text-transform: capitalize;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    grid-template-columns: 3rem auto;
    margin-bottom: 2rem;

    div {
      font-size: 1rem;
    }
    div > img,
    .user {
      width: 3rem;
      height: 3rem;
      svg {
        color: white;
        width: 2rem;
        height: 2rem;
      }
    }
  }
`

export const StyledSideDonors = styled.p`
  &.time-header {
    grid-column: 1 / span 3;
    text-align: left;
    margin: 0.5rem 0 0 0 !important;
  }
  margin: 2rem 0 1rem 1rem !important;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin: 1.5rem 0 1.5rem !important;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin: 2rem 0 1.5rem !important;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin: 3rem 0 1.5rem !important;
  }
`
export const StyledSideDonorsTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  p {
    margin: 0 !important;
  }
  button {
    font-size: 0.75rem;
    font-style: normal;
    line-height: 1.25rem;
    letter-spacing: 0.0225rem;
    text-transform: uppercase;
    color: ${(p) => p.theme.colors.blue};
    font-weight: ${(p) => p.theme.fontWeights.liteBlack};
    font-family: ${(p) => p.theme.fontFamilies.headline};
    padding: 0;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    p {
      // margin: 1.5rem 0 1.5rem !important;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    p {
      // margin: 2rem 0 1.5rem !important;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    div,
    .caption,
    button {
      font-size: 1rem;
    }
  }
`

export const StyledSortingButtons = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  min-width: 21rem;
  button {
    color: ${(p) =>
      p.sorting === "-latestDonationDate"
        ? p.theme.colors.pitchBlack
        : p.theme.colors.white};

    border-radius: 0rem 0.25rem 0.25rem 0rem;
    margin: 0;
    padding: 0;
    width: 100%;
    text-transform: capitalize;
    font-size: 1rem;
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    font-family: ${(p) => p.theme.fontFamilies.regular};
  }
  button:first-child {
    color: ${(p) => {
      return p.sorting === "-latestDonationDate"
        ? p.theme.colors.white
        : p.theme.colors.pitchBlack
    }};

    border-radius: 0.25rem 0rem 0rem 0.25rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    min-width: 35rem;
    button {
      font-soze: 1.5rem;
    }
  }
`

export const StyledDonorNameWrapper = styled.div`
  div {
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-size: 0.725rem;
  }
  .total {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    text-align: left;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    div,
    .caption {
      font-size: 1rem;
    }
  }
`

export const StyledDonorName = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 0.5rem;

  p {
    margin: 0 !important;
    font-weight: 400;
  }

  .gem {
    color: ${(p) => p.theme.colors.gemYellow};
    margin-right: 0.3rem;
  }

  .caption {
    font-size: 0.625rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    .caption {
      font-size: 0.8rem;
    }

    margin-bottom: 0.5rem;
  }
`

export const StyledGem = styled.div`
  display: flex;
  align-items: center;
`
