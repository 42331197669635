import React, { useState } from "react"
import { Router } from "@reach/router"
import { FormattedMessage, navigate, useIntl } from "gatsby-plugin-intl"
import { faHandsHeart } from "@fortawesome/pro-light-svg-icons/faHandsHeart"
import { useAuth } from "@tmu/hooks"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CampaignFAQTable } from "@tmu/components/dashboard/dashboardCommon"
import { Button, CustomModal, Spinner } from "@tmu/components/common"
import { useQuery, useMutation } from "@apollo/client"
import { useToast } from "@tmu/hooks"
import { useApolloApiClients } from "@tmu/apollo/client"
import { PARTNER_CAMPAIGN_DETAIL_QUERY } from "@tmu/apollo/dashboard/queries/campaign"
import {
  CREATE_CAMPAIGN_FAQ_MUTATION,
  UPDATE_CAMPAIGN_FAQ_MUTATION,
  DELETE_CAMPAIGN_FAQ_MUTATION,
} from "@tmu/apollo/dashboard/mutations/campaign"
import {
  StyledPage,
  StyledPageContent,
  StyledPageTitle,
  StyledPageActions,
  StyledModalActions,
} from "@tmu/global/page-addons/dashboard.styles"
import { getValueForLocale } from "@tmu/utils/string"
import Faq from "./views/Faq"
import { modalStyles } from "@tmu/global/GlobalStyle"

const CampaignFAQ = ({ slug, location }) => {
  const { isLoading, isAuthenticated } = useAuth()
  const { pathname } = location
  const isEditPage = pathname.includes("edit")
  const { partnerClient } = useApolloApiClients()
  const [selectedFaq, setSelectedFaq] = useState({})
  const { locale, defaultLocale, formatMessage } = useIntl()

  const { success, error } = useToast()
  const { loading, data } = useQuery(PARTNER_CAMPAIGN_DETAIL_QUERY, {
    variables: {
      slug,
    },
    client: partnerClient,
    skip: isLoading || !isAuthenticated,
  })
  const campaignFAQ =
    data?.campaign?.faqs?.edges.map(({ node }) => ({
      ...node,
      question: getValueForLocale(node, "question", locale, defaultLocale),
      answer: getValueForLocale(node, "answer", locale, defaultLocale),
    })) || []

  const [isModalOpen, setModalOpen] = useState(false)
  const [selectedCampaignFAQ, setSelectedCampaignFAQ] = useState()
  const [createCampaignFAQ] = useMutation(CREATE_CAMPAIGN_FAQ_MUTATION, {
    client: partnerClient,
    refetchQueries: [
      {
        query: PARTNER_CAMPAIGN_DETAIL_QUERY,
        variables: { slug },
      },
    ],
  })
  const [updateCampaignFAQ] = useMutation(UPDATE_CAMPAIGN_FAQ_MUTATION, {
    client: partnerClient,
    refetchQueries: [
      {
        query: PARTNER_CAMPAIGN_DETAIL_QUERY,
        variables: { slug },
      },
    ],
  })
  const [deleteCampaignFAQ] = useMutation(DELETE_CAMPAIGN_FAQ_MUTATION, {
    client: partnerClient,
    refetchQueries: [
      {
        query: PARTNER_CAMPAIGN_DETAIL_QUERY,
        variables: { slug },
      },
    ],
  })

  const handleCreateNewCampaignFAQ = () => {
    navigate(`/dashboard/charities/campaigns/${slug}/faq/create`)
  }

  const handleUpdateCampaignFAQ = (faq) => {
    setSelectedFaq(faq)
    navigate(`/dashboard/charities/campaigns/${slug}/faq/edit`)
  }

  const handleOpenDeleteModal = (faq) => {
    setSelectedCampaignFAQ(faq)
    setModalOpen(true)
  }

  const errorHandler = (err) => {
    error(
      err?.message ??
        formatMessage({
          id: "dashboard::campaignForm::errorMessage",
          defaultMessage: "An error occurred",
        })
    )
  }

  const handleConfirmDelete = () => {
    setModalOpen(false)

    deleteCampaignFAQ({
      variables: {
        input: {
          id: selectedCampaignFAQ?.id,
        },
      },
    })
      .then(({ data }) => {
        setSelectedCampaignFAQ(null)
        navigate(`/dashboard/charities/campaigns/${slug}/faq`)

        if (data?.deleteCampaignFaq?.errors?.length) {
          data.deleteCampaignFaq.errors.map((err) => error(err.messages?.[0]))
        } else if (data?.deleteCampaignFaq?.success) {
          success(
            formatMessage({
              id: "dashboard::campaign::campaignFAQDeleted",
              defaultMessage: "The campaign FAQ is deleted successfully",
            })
          )
        }
      })
      .catch(errorHandler)
  }

  const handleModalClose = () => {
    setSelectedCampaignFAQ(null)
    setModalOpen(false)
  }

  const handleSubmitForm = (values) => {
    let input = {
      campaign: data?.campaign?.id,
      question: values?.questionTabEn,
      questionEn: values?.questionTabEn,
      questionIt: values?.questionTabIt,
      questionEs: values?.questionTabEs,
      answer: values?.answerTabEn,
      answerEn: values?.answerTabEn,
      answerIt: values?.answerTabIt,
      answerEs: values?.answerTabEs,
      id: values?.id,
      isActive: true,
    }

    if (!!isEditPage) {
      delete input.campaign
    } else {
      delete input.id
    }

    if (!!isEditPage) {
      updateCampaignFAQ({
        variables: { input },
      })
        .then(({ data }) => {
          navigate(`/dashboard/charities/campaigns/${slug}/faq`)
          if (data?.updateCampaignFaq?.errors?.length) {
            data.updateCampaignFaq.errors.map((err) => error(err.messages?.[0]))
          } else if (data?.updateCampaignFaq?.campaignFaq?.id) {
            success(
              formatMessage({
                id: "dashboard::campaign::campaignFAQUpdated",
                defaultMessage: "The campaign FAQ is updated successfully",
              })
            )
          }
        })
        .catch(errorHandler)
    } else {
      createCampaignFAQ({
        variables: { input },
      })
        .then(({ data }) => {
          navigate(`/dashboard/charities/campaigns/${slug}/faq`)
          if (data?.createCampaignFaq?.errors?.length) {
            data.createCampaignFaq.errors.map((err) => error(err.messages?.[0]))
          } else if (data?.createCampaignFaq?.campaignFaq?.id) {
            success(
              formatMessage({
                id: "dashboard::campaign::campaignFAQCreated",
                defaultMessage: "The campaign FAQ is created successfully",
              })
            )
          }
        })
        .catch(errorHandler)
    }
  }
  const Forms = () => {
    return (
      <Faq
        isEditPage={!!isEditPage}
        handleSubmitForm={handleSubmitForm}
        selectedFaq={selectedFaq}
      />
    )
  }

  const FAQList = () => {
    return loading ? (
      <Spinner condensed />
    ) : (
      <>
        <CampaignFAQTable
          campaignFAQ={campaignFAQ}
          onEdit={handleUpdateCampaignFAQ}
          onDelete={handleOpenDeleteModal}
        />
        <StyledPageActions>
          <Button
            color="black"
            type="button"
            label="Add FAQ"
            data-testid="btn-create-faq"
            onClick={handleCreateNewCampaignFAQ}>
            <FormattedMessage
              id="dashboard::campaigns::addFAQ"
              defaultMessage="Add FAQ"
            />
          </Button>
        </StyledPageActions>

        <CustomModal
          isOpen={isModalOpen}
          style={modalStyles}
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          onRequestClose={handleModalClose}>
          <FormattedMessage
            id="dashboard::campaignFAQ::confirmDelete"
            defaultMessage="Are you sure to delete the campaign FAQ?"
            tagName="p"
          />
          <StyledModalActions>
            <Button
              color="red"
              type="button"
              label="Yes"
              onClick={handleConfirmDelete}
              data-testid="btn-confirm">
              <FormattedMessage
                id="dashboard::campaigns::modalYes"
                defaultMessage="Yes"
              />
            </Button>
            <Button
              variant="outlined"
              type="button"
              label="No"
              onClick={handleModalClose}
              data-testid="btn-cancel">
              <FormattedMessage
                id="dashboard::campaigns::modalNo"
                defaultMessage="No"
              />
            </Button>
          </StyledModalActions>
        </CustomModal>
      </>
    )
  }
  return (
    <StyledPage>
      <StyledPageTitle>
        <FontAwesomeIcon icon={faHandsHeart} />
        <FormattedMessage
          id="dashboard::campaignFAQ::campaignFAQ"
          defaultMessage="Campaign FAQ"
          tagName="h1"
        />
      </StyledPageTitle>
      <StyledPageContent data-testid="table-campaign-faq">
        <Router>
          <FAQList path="/" exact />
          <Forms path="/create" exact />
          <Forms path="/edit" exact />
        </Router>
      </StyledPageContent>
    </StyledPage>
  )
}

export default CampaignFAQ
