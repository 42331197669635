import styled from "@emotion/styled"
import { css } from "@emotion/react"
import {
  StyledCard as CommonStyledCard,
  StyledImageBox as CommonStyledImageBox,
  StyledCardInfo as CommonStyledCardInfo,
  StyledCardTitle as CommonStyledCardTitle,
  StyledPartnerTitle as CommonStyledPartnerTitle,
  StyledPartnerLogo as CommonStyledPartnerLogo,
  StyledPartnerTitleText as CommonStyledPartnerTitleText,
  StyledCardDiscount as CommonStyledCardDiscount,
  StyledCardDescription as CommonStyledCardDescription,
} from "../index.styles"

export const StyledOfflineStoreBox = styled(CommonStyledCard)`
  ${(p) =>
    p.size === "list"
      ? css`
          width: 100%;
          height: 9.625rem;
          grid-template-areas: "image info" "store info";
          grid-template-columns: 7.1875rem auto;
          grid-template-rows: 8.0625rem auto;
          grid-column-gap: 0.75rem;
          padding: 0.5rem 1rem;
        `
      : p.size === "tile"
      ? css``
      : css`
          //mainpage default view
        `}

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    ${(p) =>
      p.size === "list"
        ? css`
            grid-template-areas: "image info" "store info";
            grid-template-rows: 8.3125rem auto;
          `
        : p.size === "tile"
        ? css`
            padding: 0;
            width: 10.625rem;
            height: 18.5625rem;
            grid-template-areas: unset;
            grid-template-rows: 5.9375rem auto 4.375rem;
            grid-template-columns: 1fr;
          `
        : css`
            //mainpage default view
          `}
    ${(p) =>
      p.bigTablet &&
      css`
        width: 14.25rem;
        height: 22.5rem;
        grid-template-rows: 8rem auto 5rem;
      `}
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    grid-template-areas: unset;
    ${(p) =>
      p.size === "list"
        ? css`
            //Pac offers page wide list view
          `
        : p.size === "tile"
        ? css`
            width: 14.25rem;
            height: 22.5rem;
            grid-template-rows: auto 0.0313rem 6rem;
          `
        : css`
            //mainpage default wide view
          `}
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    ${(p) =>
      p.size === "list"
        ? css`
            //Pac offers page wide list view
          `
        : p.size === "tile"
        ? css`
            width: 25.875rem;
            height: 38.3125rem;
            grid-template-rows: 26.5rem 0.0313rem 11.25rem;
          `
        : css`
            //mainpage default wide view
          `}
  }
`

export const StyledImageBox = styled(CommonStyledImageBox)`
  img {
    align-self: center;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 7.1875rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    img {
      max-height: 13.0625rem;
    }
  }
`

export const StyledCardInfo = styled(CommonStyledCardInfo)``

export const StyledCardTitle = styled(CommonStyledCardTitle)``

export const StyledOfferStore = styled(CommonStyledPartnerTitle)`
  grid-area: store;
  justify-self: auto;
  display: block;
  margin-top: 0.25rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 0.5rem 0 0;
    display: flex;
    margin: 0;
    ${(p) =>
      p.size === "tile" &&
      css`
        padding: 0 0.75rem;
      `}

    ${(p) =>
      p.bigTablet &&
      css`
        grid-area: unset;
        padding: 0 0.75rem 0.75rem;
      `}
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    grid-area: unset;
    padding: 0 0.75rem 0.75rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding: 0 1rem 1rem;
    p span {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
  }
`

export const StyledPartnerLogo = styled(CommonStyledPartnerLogo)``

export const StyledPartnerTitleText = styled(CommonStyledPartnerTitleText)``

export const StyledCardDiscount = styled(CommonStyledCardDiscount)``

export const StyledCardDescription = styled(CommonStyledCardDescription)``
