import React, { useRef, useState, useEffect } from "react"
import { useLazyQuery } from "@apollo/client"
import { useIntl, FormattedMessage, navigate, Link } from "gatsby-plugin-intl"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { useApolloApiClients } from "@tmu/apollo/client"
import { QUERY_STATIC_PAGE_LIST } from "@tmu/apollo/storefront/queries/static"
import { useAuth } from "@tmu/hooks"
import LegalContent from "./content"
import { CustomModal, Spinner } from "@tmu/components/common"
import { StyledLegalWrapper, StyledLegalLeftMenu } from "./index.styles"
import { getContent } from "./getContent"

const LegalPage = ({ slug, pageId }) => {
  const { strapiClient } = useApolloApiClients()
  const { isLoading } = useAuth()
  const [modalStatus, setModalStatus] = useState(false)
  const { locale } = useIntl()
  const ref = useRef()
  const { isTablet } = getAllScreenTypes()

  const [callAllPages, { data: dataStrapi, called }] = useLazyQuery(
    QUERY_STATIC_PAGE_LIST,
    {
      client: strapiClient,
      variables: { locale },
    }
  )
  const defaultContent = getContent({ locale })
  const data = dataStrapi || defaultContent

  const termsListItems = data?.pages?.data
    ?.filter((item) => item && item?.attributes?.Location === "Terms")
    .map((page) => {
      return {
        slug: `${page?.attributes?.slug}`,
        link: `/legal/${page?.attributes?.slug}/${page?.attributes?.pageId}`,
        subTitle: page?.attributes?.Title,
        directLink: page?.attributes?.directLink,
      }
    })

  const policyListItems = data?.pages?.data
    ?.filter((item) => item && item?.attributes?.Location === "Policies")
    .map((page) => ({
      slug: `${page?.attributes?.slug}`,
      link: `/legal/${page?.attributes?.slug}/${page?.attributes?.pageId}`,
      subTitle: page?.attributes?.Title,
      directLink: page?.attributes?.directLink,
    }))

  const digitalBankingListItems = data?.pages?.data
    ?.filter((item) => item && item?.attributes?.Location === "DigitalBanking")
    .map((page) => ({
      slug: `${page?.attributes?.slug}`,
      link: `/legal/${page?.attributes?.slug}/${page?.attributes?.pageId}`,
      subTitle: page?.attributes?.Title,
      directLink: page?.attributes?.directLink,
    }))

  const otherListItems = data?.pages?.data
    ?.filter((item) => item && item?.attributes?.Location === "Other")
    .map((page) => ({
      slug: `${page?.attributes?.slug}`,
      link: `/legal/${page?.attributes?.slug}/${page?.attributes?.pageId}`,
      subTitle: page?.attributes?.Title,
      directLink: page?.attributes?.directLink,
    }))

  const handleFooterLinkClick = (item) => {
    navigate(item.link)

    if (!isTablet) setModalStatus(item)
  }

  const closeModal = () => {
    setModalStatus(false)
  }
  useEffect(() => {
    if (isLoading) {
      return
    }

    if (!called) {
      callAllPages()
    }

    if (termsListItems?.length && (!slug || !pageId)) {
      navigate(termsListItems?.[0]?.link)
    }
  }, [isLoading, termsListItems])

  return !data ? (
    <Spinner condensed />
  ) : (
    <StyledLegalWrapper ref={ref}>
      {!isTablet && (
        <FormattedMessage
          id="legal::pageTitle"
          defaultMessage="Terms and Policies"
          tagName="h4"
        />
      )}
      <StyledLegalLeftMenu>
        <div>
          <p className="caption">
            <FormattedMessage id="legal::menu::terms" defaultMessage="Terms" />
          </p>
          {!isTablet && <hr />}
          {termsListItems?.map((item, index) => {
            return (
              <Link
                key={index}
                to={item.directLink || item.link}
                onClick={() => handleFooterLinkClick(item)}
                activeClassName="active">
                {item.subTitle}
                {!isTablet && <hr />}
              </Link>
            )
          })}
          {isTablet && <hr />}
          <p className="caption">
            <FormattedMessage
              id="legal::menu::policies"
              defaultMessage="Policies"
            />
          </p>

          {!isTablet && <hr />}
          {policyListItems?.map((item, index) => {
            return (
              <Link
                key={index}
                to={item.directLink || item.link}
                onClick={() => handleFooterLinkClick(item)}
                activeClassName="active">
                {item.subTitle}
                {!isTablet && <hr />}
              </Link>
            )
          })}
          {digitalBankingListItems?.length ? (
            <>
              {" "}
              {isTablet && <hr />}
              <p className="caption">
                <FormattedMessage
                  id="legal::menu::digitalBanking"
                  defaultMessage="Digital Banking"
                />
              </p>
              {!isTablet && <hr />}
              {digitalBankingListItems?.map((item, index) => {
                return (
                  <Link
                    key={index}
                    to={item.directLink || item.link}
                    onClick={() => handleFooterLinkClick(item)}
                    activeClassName="active">
                    {item.subTitle}
                    {!isTablet && <hr />}
                  </Link>
                )
              })}
            </>
          ) : null}
          {isTablet && <hr />}
          <p className="caption">
            <FormattedMessage id="legal::menu::other" defaultMessage="Other" />
          </p>

          {!isTablet && <hr />}
          {otherListItems?.length
            ? otherListItems?.map((item, index) => {
                return (
                  <Link
                    key={index}
                    to={item.directLink || item.link}
                    onClick={() => handleFooterLinkClick(item)}
                    activeClassName="active">
                    {item.subTitle}
                    {!isTablet && <hr />}
                  </Link>
                )
              })
            : null}
        </div>
      </StyledLegalLeftMenu>
      {isTablet ? (
        <LegalContent slug={slug} pageId={pageId} />
      ) : modalStatus ? (
        <CustomModal
          isModalOpen={modalStatus}
          header={modalStatus.subTitle}
          children={<LegalContent slug={slug} pageId={pageId} />}
          isCloseIcon={true}
          cancelButtonAction={closeModal}
          isMobile={true}
          stickyHeader
        />
      ) : null}
    </StyledLegalWrapper>
  )
}

export default LegalPage
