import styled from "@emotion/styled"

export const CardContainer = styled.div`
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    width: 22.5rem;
    min-width: 22.5rem;
    min-height: 11.875rem;
  }
  width: 15rem;
  min-width: 15rem;
  min-height: 8rem;
  background-color: ${(p) => p.theme.colors.whiteSmoke};
  border-radius: 0.25rem;
  position: relative;

  &.active {
    background-color: ${(p) => p.theme.colors.white};
    border-color: ${(p) => p.theme.colors.blue};
    border-width: 0.063rem;
    border-radius: 0.25rem;
    border-style: solid;
  }
`

export const CardType = styled.div`
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.pitchBlack};
  position: absolute;
  top: 1rem;
  left: 1rem;
  text-transform: capitalize;

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2rem;
    left: 1.5rem;
    top: 1.5rem;
  }
`

export const CardNumber = styled.div`
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.pitchBlack};
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2rem;
    left: 1.5rem;
    bottom: 1.5rem;
  }
`

export const CardExpiry = styled.div`
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.pitchBlack};
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2rem;
    right: 1.5rem;
    bottom: 1.5rem;
  }
`

export const IconContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
