import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import {
  PacsHero,
  PacsPartners,
  PacsOffers,
  PacsDiscount,
  PacsQuestions,
} from "@tmu/components/pacs"
import SEO from "@tmu/components/seo"
import { StyledPageContainer } from "@tmu/global/page-addons/pacs-page.styles"

const PacsPage = ({ intl }) => {
  const { locale, formatMessage } = intl

  return (
    <>
      <SEO
        lang={locale}
        title={formatMessage({
          id: "pacs::common::pageTitle",
          defaultMessage: "How TrustMeUp Works",
        })}
        description={formatMessage({
          id: "seo::pacs::description",
          defaultMessage:
            "You will be rewarded 100% in discounts for your donations. You can also double your discounts with special campaigns. Join now to discover.",
        })}
      />
      <StyledPageContainer>
        <PacsHero />
        <PacsPartners />
        <PacsOffers />
        <PacsDiscount />
        <PacsQuestions />
      </StyledPageContainer>
    </>
  )
}

export default injectIntl(PacsPage)
