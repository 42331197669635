import React, { Fragment, useEffect } from "react"
import PropTypes from "prop-types"
import { useQuery } from "@apollo/client"
import { useAuth } from "@tmu/hooks"
import { Spinner } from "@tmu/components/common"
import { SliceOffers } from "@tmu/components/home"
import { Link, FormattedMessage } from "gatsby-plugin-intl"
import { faCheckCircle } from "@fortawesome/pro-light-svg-icons/faCheckCircle"
import { usePACBalance } from "@tmu/hooks"
import {
  StyledWrapper,
  StyledBanner,
  StyledSuccessContainer,
  StyledSuccessDocument,
  StyledSuccessDetails,
  StyledSuccessOfferTitle,
  StyledSuccessIcon,
} from "./index.styles"
import { MERCHANT_OFFER_LISTING_QUERY } from "@tmu/apollo/storefront/queries/merchant"

const OfferPurchaseSuccess = () => {
  const { isLoading, isAuthenticated } = useAuth()
  const { refetchPacBalance } = usePACBalance()

  const { loading: loadingOrders, data } = useQuery(
    MERCHANT_OFFER_LISTING_QUERY,
    {
      variables: {
        first: 4,
      },
      skip: isLoading || !isAuthenticated,
    }
  )

  if (isLoading || loadingOrders) {
    return <Spinner />
  }

  if (isAuthenticated && !isLoading) {
    try {
      setTimeout(() => {
        if (refetchPacBalance && typeof refetchPacBalance === "function")
          refetchPacBalance()
      }, 5000)
    } catch (error) {}
  }

  return (
    <>
      <StyledWrapper>
        <StyledBanner data-testid="offer-success-banner">
          <FormattedMessage
            id="offer::purchaseSuccess::thanks"
            defaultMessage="Thank you for your purchase!"
          />
        </StyledBanner>
        <StyledSuccessContainer>
          <StyledSuccessDocument>
            <StyledSuccessDetails data-testid="offer-success-details">
              <FormattedMessage
                id="offer::purchaseSuccess::processedText"
                defaultMessage="Your order has been received and a copy of your {purchases} has been sent to your email."
                tagName="p"
                values={{
                  purchases: (
                    <Link
                      to="/dashboard/donors/orders"
                      aria-label="Purchase Summary">
                      <FormattedMessage
                        id="offer::checkout::purchaseSummary"
                        defaultMessage="purchase summary"
                      />
                    </Link>
                  ),
                }}
              />
              <StyledSuccessOfferTitle data-testid="offer-success-title">
                <StyledSuccessIcon icon={faCheckCircle} />
                <FormattedMessage
                  id="offer::purchaseSuccess::purchaseConfirmed"
                  defaultMessage="Purchase Confirmed"
                />
              </StyledSuccessOfferTitle>
              <FormattedMessage
                id="offer::purchaseSuccess::usePacsText"
                defaultMessage="Dont forget to use your PACs to enjoy discounts at our <pacs>PACStore</pacs>."
                values={{
                  pacs: (...chunks) => (
                    <Link
                      to="/offers?offer=true&store=true"
                      key="usePacsText"
                      aria-label="use pacs">
                      {chunks.map((chunk, i) => (
                        <Fragment key={i}>{chunk}</Fragment>
                      ))}
                    </Link>
                  ),
                }}
                tagName="p"
              />
            </StyledSuccessDetails>
          </StyledSuccessDocument>
        </StyledSuccessContainer>
        <SliceOffers offers={data?.allOffers?.edges} />
      </StyledWrapper>
    </>
  )
}

OfferPurchaseSuccess.propTypes = {
  slug: PropTypes.string.isRequired,
}

OfferPurchaseSuccess.defaultProps = {
  slug: "",
}

export default OfferPurchaseSuccess
