import styled from "@emotion/styled"
export const StyledSortSelector = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  max-height: 2.5rem;
  .react-select__multi-value__label {
    font-size: 0.75rem !important;
  }

  .react-select__placeholder {
    text-align: left;
  }
`

export const StyledDropdownContainer = styled.div`
  width: 100%;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    max-width: unset;
  }
`

export const StyledShowAll = styled.div`
  align-self: center;
  font-size: 0.75rem;
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  cursor: pointer;
  white-space: nowrap;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 1rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
  }
`
export const StyledListItem = styled.div`
  text-align: left;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0.75rem;
  margin-top: 0.75rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  color: ${(p) => p.theme.colors.pitchBlack};
  font-family: ${(p) => p.theme.fontFamilies.regular};
  div > svg {
    color: ${(p) => p.theme.colors.pacificBlue};
  }
  span {
    margin-left: 0.5rem;
    color: ${(p) => p.theme.colors.pitchBlack};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-top: 0;
  }
`

export const StyledList = styled.div`
  display: block;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: grid;
    grid-template-columns: 4fr 4fr 4fr;
  }
`

export const StyledOption = styled.div`
  display: grid;
  grid-template-columns: 1fr 10fr 1fr 1fr;
  grid-template-areas: "logo name type check";
  align-items: center;
  grid-gap: 0.5rem;

  .type {
    grid-area: type;
    font-family: ${(p) => p.theme.fontFamilies.headlineBig};
    font-size: 0.625rem;
    font-weight: ${(p) => p.theme.fontWeights.liteBlack};
    line-height: 1.25rem;
    color: ${(p) => p.theme.colors.concreteGrey};
    text-decoration: uppercase;
    justify-self: end;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 0.75rem;
    }
  }

  .logo {
    grid-area: logo;
    justify-self: start;
  }

  .merchant-name {
    grid-area: name;
    justify-self: start;
    font-size: 0.75rem;
    font-weight: ${(p) => p.theme.fontFamilies.headline};
    font-family: ${(p) => p.theme.fontWeights.liteMedium};
    margin-left: 0.5rem;
  }

  .check {
    grid-area: check;
    justify-self: end;
    padding-bottom: 0.5rem;
  }

  input:checked ~ .checkmark {
    background-color: ${(p) => p.theme.colors.carrot};
    border: 0.0625rem solid ${(p) => p.theme.colors.carrot};
  }

  input:checked ~ .checkmark:after {
    background-color: ${(p) => p.theme.colors.carrot};
  }
`

export const StyledMultiValueContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 20rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    max-width: 18.75rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    max-width: 50rem;
  }
`

export const StyledMultiValueRemove = styled.div``
