import styled from "@emotion/styled"

export const StyledSeatWrapper = styled.div`
  display: grid;
  grid-template-areas:
    "title input"
    ". check";
  grid-gap: 2rem 1rem;
  position: relative;
  width: 20.625rem;
  svg {
    color: ${(p) => p.theme.colors.blue};
    font-size: 1.25rem;
    height: 1.25rem;
    font-weight: ${(p) => p.theme.fontWeights.bold};
    cursor: pointer;
  }

  .float-container {
    grid-area: input;
    width: 10.6875rem;
    height: 3.125rem;
    padding: 0.875rem 0 0.875rem 0.75rem;
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.regular};
    font-size: 1rem;
    line-height: 1.25rem;
  }
  .react-tooltip,
  tooltip {
    max-width: 350px;
  }
  @media screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
    display: block;
    width: 9rem;
  }
`

export const StyledTicketText = styled.h2`
  grid-area: title;
  align-self: center;
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.bold};
  font-size: 1.25rem;
  line-height: 1.75rem;
  text-align: right;
  color: ${(p) => p.theme.colors.black};
  margin: 0;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
    text-align: left;
    font-weight: ${(p) => p.theme.fontWeights.black};
    font-size: 0.625rem;
    line-height: 1rem;
    letter-spacing: 0.04rem;
    text-transform: uppercase;
    color: ${(p) => p.theme.colors.disabled};
    margin-top: 1rem;
    align-self: start;
  }
`

export const StyledTicketCheckboxContainer = styled.div`
  grid-area: check;

  display: flex;
  align-items: center;
  justify-content: space-between;

  label {
    margin: 0;
    padding-left: 1.8125rem;
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.regular};
    font-size: 1rem;
    line-height: 1.125rem;
    color: ${(p) => p.theme.colors.black};

    span {
      height: 1.125rem;
      width: 1.125rem;
    }
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin-top: 1.25rem;
  }
`
export const StyledExplanationText = styled.p`
  position: absolute;
  display: ${(p) => (p.isUnlisted ? "block" : "none")};
  bottom: -7rem;
  right: 0;
  max-width: 23.5rem;
  width: calc(100vw - 2rem);
  z-index: 2;
  margin: 0;
  background: ${(p) => p.theme.colors.white};
  box-shadow: 0 0.25rem 0.75rem ${(p) => p.theme.colors.primaryTextColorLight};
  border-radius: 0.25rem;
  padding: 1rem;
`
