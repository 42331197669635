import React, { useState } from "react"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { faPencil } from "@fortawesome/pro-solid-svg-icons/faPencil"
import { faVideo } from "@fortawesome/pro-light-svg-icons/faVideo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { TextInput, VideoPlayer } from "@tmu/components/common"
import {
  StyledVideoWrapper,
  StyledVideoWrapperDashed,
  StyledPlayer,
} from "./index.styles"
import { youtubeVimeoUrlControl } from "@tmu/utils/string"
import { Button } from "@tmu/components/common"
import { usePreviousValue } from "beautiful-react-hooks"

const VideoSource = ({ videoUrl = "", onChange }) => {
  const { formatMessage } = useIntl()

  const [videoUrlState, setVideoUrlState] = useState(videoUrl)
  const prevVideoUrl = usePreviousValue(videoUrlState)

  const handleEnter = (event) => {
    const { value = "" } = event.target
    const code = event.keyCode || event.charCode
    if (code === 13) {
      const urlControl = youtubeVimeoUrlControl(value)
      urlControl && setVideoUrlState(value)
    }
  }

  const editVideo = () => {
    setVideoUrlState("")
  }

  return (
    <StyledVideoWrapper>
      {!videoUrlState ? (
        <StyledVideoWrapperDashed>
          <FontAwesomeIcon icon={faVideo} className="icon" />
          <FormattedMessage
            id="dashboard::video::info"
            defaultMessage="Enter a YouTube or Vimeo URL to appear at the top of campaign page"
            tagName="span"
          />
          <TextInput
            id="enter-video"
            type="text"
            placeholder={formatMessage({
              id: "dashboard::video::enter",
              defaultMessage: "Enter Video Url",
            })}
            className="enter-video-appear"
            onKeyPress={handleEnter}
            onChange={(e) => onChange(e.target?.value)}
            autoComplete="off"
            defaultValue={prevVideoUrl ? prevVideoUrl : ""}
          />
        </StyledVideoWrapperDashed>
      ) : (
        <StyledPlayer>
          <Button color="white" label="edit" onClick={editVideo}>
            <FontAwesomeIcon icon={faPencil} />
          </Button>
          <VideoPlayer url={videoUrlState} />
        </StyledPlayer>
      )}
    </StyledVideoWrapper>
  )
}

export default VideoSource
