import styled from "@emotion/styled"
import { Link } from "gatsby-plugin-intl"
import { default as globalTheme } from "@tmu/global/theme"

export const theme = {
  suggestionsContainer: {
    maxHeight: "64rem",
    overflowY: "auto",
    backgroundColor: globalTheme.colors.white,
    position: "relative",
    marginTop: "-4.5rem",
    width: "100%",
    borderBottom: `0.0625rem solid ${globalTheme.colors.formFieldsBorderColor}`,
    borderRight: `0.0625rem solid ${globalTheme.colors.formFieldsBorderColor}`,
    borderLeft: `0.0625rem solid ${globalTheme.colors.formFieldsBorderColor}`,
    paddingBottom: "1rem",
    top: "-0.9375rem",
  },
  sectionContainer: {
    display: "flex",
    flexDirection: "column",
  },
  suggestionsList: {
    display: "flex",
    flexDirection: "column",
    alignItems: "self-start",
    height: "auto",
    color: globalTheme.colors.black,
    padding: "0 1rem 0 1rem",
    outline: "none",
  },
  input: {
    width: "100%",
    outline: "none",
    fontSize: "1rem",
    height: "3rem",
    maxHeight: "3rem",
    paddingLeft: "3rem",
    zIndex: "1",
  },
  sectionTitle: {
    fontSize: "0.625rem",
    padding: "1rem",
    color: globalTheme.colors.disabled,
  },
  suggestion: {
    width: "100%",
    padding: "0.625rem",
  },
  suggestionHighlighted: {
    backgroundColor: globalTheme.colors.whiteSmoke,
  },
}

export const StyledTypeahead = styled.div`
  width: 100%;
  position: relative;
  top: -1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    top: 0.9rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    top: -0.0625rem;
  }

  .common-typeahead {
    font-size: 0.75rem;
    color: ${(p) => p.theme.colors.pitchBlack};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-family: ${(p) => p.theme.fontFamilies.headline};
    width: 100%;
    display: grid;
    grid-template-columns: minmax(5.5rem, 5.5rem) auto;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      grid-template-columns: minmax(15rem, 15rem) auto;
    }
    gap: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .typeahead-item {
    &,
    span,
    div {
      font-size: 0.75rem;
      line-height: 1rem;
      font-family: ${(p) => p.theme.fontFamilies.headline};
      font-weight: ${(p) => p.theme.fontWeights.semiBolder};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }
  }

  .no-ellipsis {
    overflow: unset !important;
    margin-right: -1.5rem !important;
  }

  .typeahead-item-small {
    &,
    span,
    div {
      font-size: 0.625rem !important;
      line-height: 1.125rem !important;
      @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
        font-size: 1.125rem !important;
        line-height: 1.5rem !important;
      }
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    max-width: 22rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    max-width: 28rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    max-width: 64rem;
  }

  &.archived {
    display: none;
  }

  .suggest-title {
    font-size: 0.625rem;
    line-height: 1rem;
    color: ${(p) => p.theme.colors.disabled};
    font-weight: ${(p) => p.theme.fontWeights.black};
    font-family: ${(p) => p.theme.fontFamilies.headlineBig};
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.25rem;
      line-height: 2rem;
    }
  }

  .search-icon {
    position: absolute;
    display: block;
    top: 1.7rem;
    left: 1rem;
    font-size: 1.25rem;
    color: ${(p) => p.theme.colors.gondola};
    opacity: 0.6;
    z-index: 1;
  }

  .times-icon {
    position: absolute;
    top: 2.5625rem;
    right: 1.3rem;
    margin-top: -0.625rem;
    border: none;
    outline: none;
    cursor: pointer;
    background: none;
    z-index: 1;

    svg {
      color: ${(p) => p.theme.colors.gondola};
      font-size: 1.25rem;
      height: 1.25rem;
      opacity: 0.6;
    }
  }

  [role="listbox"] {
    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      margin-left: -0.6937rem;
      max-width: 22rem;
    }
    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      max-width: 30rem;
    }
    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      max-width: 64rem;
    }
    & > li > a {
      width: 100%;
    }
  }

  #react-autowhatever-1 {
    overscroll-behavior: none;
  }
`

export const StyledCategories = styled.div`
  color: ${(p) => p.theme.colors.tundora};
  .type {
    font-weight: ${(p) => p.theme.fontWeights.semiBold};
  }
`

export const StyledOffers = styled(Link)`
  color: ${(p) => p.theme.colors.tundora};
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  .type {
    font-weight: ${(p) => p.theme.fontWeights.semiBold};
  }

  span {
    padding-left: 0.625rem;
  }
`

export const StyledStores = styled(Link)`
  color: ${(p) => p.theme.colors.tundora};
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  .type {
    font-weight: ${(p) => p.theme.fontWeights.semiBold};
  }

  span {
    padding-left: 0.625rem;
  }
`

export const StyledTaxId = styled.div`
  padding: 0.1rem;
  color: ${(p) => p.theme.colors.disabled};
  font-weight: ${(p) => p.theme.fontWeights.liteBlack};
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-size: 0.5rem;
  line-height: 0.75rem;
  max-width: 12rem;
  min-width: 12rem;
  margin-top: 0.5rem;
  text-transform: uppercase;
`
export const StyledPartners = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  color: ${(p) => p.theme.colors.tundora};

  span {
    padding-left: 0.625rem;
  }
`

export const StyledCampaigns = styled(Link)`
  display: grid;
  grid-template-columns: 6.25rem 1fr;
  grid-gap: 0.5rem;
  justify-content: start;
  flex: 1;
  color: ${(p) => p.theme.colors.tundora};
  .info {
    div {
      padding-top: 0;
    }
    svg {
      width: 12.5rem;
    }
    .name {
      font-size: 1rem;
      padding-bottom: 0.3rem;
    }
    p {
      font-size: 0.875rem;
    }
  }
`

export const StyledTypeaheadInput = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
  max-width: 48rem;
  max-height: 3rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    max-width: 64rem;
    max-height: 4rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: flex;
    position: relative;
    transform: translate(-0.7rem, -4.9rem);
  }

  input {
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 1rem;
    color: ${(p) => p.theme.colors.pitchBlack};
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    font-family: inherit;
    font-size: 0.75rem !important;
    line-height: 1.125rem !important;
    font-weight: ${(p) => p.theme.fontWeights.liteMedium} !important;
    height: 3rem !important;
    max-height: 3rem !important;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      position: relative;
      ::placeholder {
        width: 16.25rem;
      }
    }
    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      font-size: 1rem !important;
      line-height: 1.25rem !important;
      ::placeholder {
        width: 21.875rem;
      }
    }
    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.5rem !important;
      line-height: 2.25rem !important;
      max-width: 64rem;
      height: 5rem !important;
      max-height: 5rem !important;
      margin-top: 0;
      ::placeholder {
        width: auto;
      }
    }

    outline: none !important;
    border: 0 solid;
    box-shadow: 0.125rem 0 1.25rem -0.0625rem rgb(10 11 11 / 20%),
      -0.125rem 0 1.25rem -0.0625rem rgb(13 13 14 / 20%);
  }
  input:focus {
    outline: none;
  }

  *:focus {
    outline: none;
  }
  outline: none;
`

export const StyledCampaignDetail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const StyledPartnerContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(3rem, 3rem) auto;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    grid-template-columns: minmax(6.25rem, 6.25rem) auto;
  }
  gap: 0.25rem;
  row-gap: 0.5rem;
  align-items: center;
  font-size: 0.625rem;
  font-family: ${(p) => p.theme.fontFamilies.headline};
  line-height: 1.125rem;
`
export const StyledImageContainer = styled.div`
  div {
    display: block !important;
    img {
      border-radius: 0.25rem;
    }
  }
`

export const StyledOfferContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(3rem, 3rem) auto;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    grid-template-columns: minmax(6.25rem, 6.25rem) auto;
  }
  gap: 0.25rem;
  row-gap: 0.5rem;
  align-items: center;
  align-content: center;
`

export const StyledPacContainer = styled.div`
  min-width: 2rem;
  &,
  span {
    color: ${(p) => p.theme?.colors?.carrot};
    font-size: 0.75rem;
    line-height: 1rem;
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  }
  span {
    padding-left: 0;
  }
  svg {
    margin-right: 0.1rem;
    padding-top: 0.2rem;
  }
`
