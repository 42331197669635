import styled from "@emotion/styled"
import { Button } from "@tmu/components/common"
import { Link } from "gatsby-plugin-intl"

export const StyledOfferTop = styled.div`
  top: 0;
  left: 0;
  z-index: -1;
  height: 24.75rem;
  width: 100%;
  position: absolute;
  background: ${(p) => p.theme.colors.celticGreen};
  border-bottom: 0.0625rem solid ${(p) => p.theme.colors.white};
  &:after {
    content: "";
    background: ${(p) => p.theme.colors.white};
    position: absolute;
    bottom: -0.0625rem;
    left: 50%;
    width: 50%;
    height: 8.75rem;
    z-index: 1;
    @media screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
      height: 4rem;
    }
    @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
      display: none;
    }
  }
  & > div {
    height: 28.75rem;
  }
  @media screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
    height: 18rem;
  }
  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: none;
  }
`

export const StyledOfferMain = styled.div`
  width: 100%;
  max-width: ${(p) => p.theme.breakpoints.wide};
  margin: 4rem auto 0.5rem;
  padding: 4rem 1rem 0;
  display: grid;
  grid-template-columns: minmax(0, 0.63fr) minmax(0, 0.37fr);
  grid-gap: 2rem;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
    column-gap: 1rem;
  }
  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: block;
  }
`

export const OfferImage = styled.div`
  .media-box {
    padding-bottom: 0;
    height: auto;
    background: ${(p) => p.theme.colors.white};
    img {
      width: 100%;
      height: auto;
      border-radius: 0.3125rem;
      background: transparent;
    }
  }
`

export const StyledOfferCaption = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  padding-top: 1rem;
  height: auto !important;
`

export const StyledOfferLabel = styled(Link)`
  margin: 0.5rem 1rem 0 0;
  display: block;
  margin-bottom: 1.5rem;
  cursor: pointer;
  opacity: 0.55;
  svg,
  i {
    margin-right: 0.4375rem;
  }
  &:hover {
    opacity: 1;
  }
`

export const StyledOfferHeader = styled.div`
  height: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
    height: 6rem;
  }
  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    height: auto;
  }
`
export const StyledOfferTitle = styled.div`
  color: ${(p) => p.theme.colors.white};
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-size: 1.75rem;
  margin-bottom: 0.5rem;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 1.2rem;
  }
  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    color: ${(p) => p.theme.colors.gondola};
    font-size: 1.7rem;
    margin-bottom: 1rem;
  }
`

export const OfferStats = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .pac-image {
    width: 2.5625rem !important;
    height: 1.25rem !important;
    display: inline-block;
    margin-right: 0.625rem;
  }
  > p {
    margin: 2rem 0;
  }
  @media screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
    padding-top: 1.875rem;
  }
  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding-top: 1rem;
    margin-bottom: 2rem;
    > p {
      margin: 0 0 2rem 0;
    }
  }
`

export const OfferValues = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 2rem;
  .base-value,
  .final-value {
    span {
      font-weight: ${(p) => p.theme.fontWeights.bold};
    }
    p:last-of-type {
      color: ${(p) => p.theme.colors.gondola};
      font-size: 1.5rem;
      font-weight: ${(p) => p.theme.fontWeights.bold};
    }
  }
  .final-value p:last-of-type {
    color: ${(p) => p.theme.colors.blue};
  }
  & > svg {
    font-size: 2rem;
    height: 2rem;
    opacity: 0.6;
    color: rgba(151, 151, 151, 1);
    margin: 0 0.5rem;
  }
`

export const StyledAddCartButton = styled(Button)`
  text-align: center;
  height: 4.0625rem;
  width: 100%;
  margin: 1.875rem 0 2.3rem;
  font-size: 1.125rem;
  position: relative;
  cursor: pointer;
  svg {
    font-size: 2.3125rem;
    height: 2.3125rem;
    position: absolute;
    left: 0.9375rem;
  }
  @media screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 1rem;
    height: 3rem;
    svg {
      font-size: 1.5rem;
      height: 1.5rem;
    }
  }
  @media screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 0.9rem;
    height: 2.8rem;
    margin: 0.6rem 0;
  }
  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1rem;
    height: 3rem;
    margin: 2rem 0;
  }
`

export const StyledSocialShare = styled.div`
  border-top: 0.0625rem solid ${(p) => p.theme.colors.primaryTextColorLight};
  border-bottom: 0.0625rem solid ${(p) => p.theme.colors.primaryTextColorLight};
  height: 4rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    color: ${(p) => p.theme.colors.disabled};
    font-size: 1rem;
  }
  svg {
    font-size: 2rem;
    height: 2rem;
    margin-left: 1.25rem;
    cursor: pointer;
  }
  @media screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
    height: 2rem;
    svg {
      font-size: 1.3rem;
      height: 1.3rem;
    }
  }
  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    position: relative;
    height: 4rem;
  }
`

export const StyledOfferTabs = styled.div`
  margin: 0;
  line-height: 1.75rem;

  .react-tabs__tab-list {
    border-bottom: 0.0625rem solid ${(p) => p.theme.colors.tabFieldsColor};
  }

  .react-tabs__tab {
    color: ${(p) => p.theme.colors.gondola};
    opacity: 0.3;
    outline: none;
    cursor: pointer;
    padding: 0.875rem 1rem;
    transition: 0.3s;
    font-size: 1.125rem;
    background: none;
    border: none;
    font-weight: ${(p) => p.theme.fontWeights.bold};
    margin: 0;
  }

  .react-tabs__tab--selected {
    border-bottom: 0.125rem solid ${(p) => p.theme.colors.primaryBrandTextColor};
    color: ${(p) => p.theme.colors.primaryBrandTextColor};
    opacity: 1;
  }

  &,
  p {
    color: ${(p) => p.theme.colors.disabled};
    font-weight: ${(p) => p.theme.fontFamilies.bold};
    font-size: 1rem;
    line-height: 1.75rem;
    margin-bottom: 1.5625rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-weight: ${(p) => p.theme.fontWeights.bold};
    line-height: 1.75rem;
    margin-top: 1.5625rem;
    margin-bottom: 1.5625rem;
  }

  img {
    width: 100%;
  }

  h3 {
    margin: 2.5rem 0 1.5625rem 0;
    line-height: 1.8125rem;
  }
  p {
    font-size: 1rem;
  }
  @media only screen and (max-width: 48rem) {
    display: block;
    width: 100%;
  }
`

export const StyledPacText = styled.div`
  display: flex;
  align-items: center;
  line-height: 1.25rem;
  position: relative;
  font-size: 1rem;
  transform: translateY(-0.1875rem);
  margin-top: 1.875rem;

  span {
    font-weight: ${(p) => p.theme.fontWeights.bold};
  }

  .pac-image {
    min-height: 2rem;
  }

  .pac-amount {
    color: ${(p) => p.theme.colors.blue};
  }

  & > span {
    font-weight: ${(p) => p.theme.fontWeights.regular};
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 0.9rem;
  }
`
export const StyledRibbon = styled.div`
  position: absolute;
  top: 0;
  left: 1rem;
  padding: 0.75em 0em;
  text-align: center;
  transform: translateY(-140%) rotate(358deg) translateX(-40%) rotate(-45deg);
  transform-origin: bottom right;
  background-color: ${(p) => p.theme.colors.red};
  color: white;
  font-weight: ${(p) => p.theme.fontWeights.bold};
  width: 15rem;
  height: 2rem;
  font-size: 0.75rem;
  display: block;
`

export const StyledImageBox = styled.div`
  position: relative;
  border-radius: 0.35rem;
  border: ${(p) => p.variant !== "brand" && "0.0625rem solid transparent"};
  background-color: transparent;
  overflow: hidden;

  ${(p) =>
    p.hasImageBorder &&
    css`
      border-color: ${(p) => p.theme.colors.gallery};
    `}
  .media-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    padding: 0;
  }
  img {
    object-fit: cover;
  }

  a {
    display: flex;
    align-items: center;
  }
`
