import React, { useRef, Fragment, useEffect } from "react"
import { useLocation } from "@reach/router"
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl"
import { useQuery } from "@apollo/client"
import { faHandHeart } from "@fortawesome/pro-regular-svg-icons/faHandHeart"
import { useAuth } from "@tmu/hooks"
import SEO from "@tmu/components/seo"
import {
  HowtoGetStarted,
  GetStartedIntro,
  TabContent,
  CurrentInTMU,
  HowItWorksForm,
} from "@tmu/components/how-it-works"
import { Hero, LazyImage } from "@tmu/components/common"
import { PARTNER_GET_STARTED_PAGE_QUERY } from "@tmu/apollo/storefront/queries/partner"
import { StyledGetStartedWrapper } from "@tmu/global/page-addons/how-it-works.styles"
import { isInViewport } from "@tmu/utils/viewport"

const { CLOUDFLARE_IMAGE_URL } = process.env

const NonProfitPage = ({ intl, location }) => {
  const { locale, formatMessage } = intl
  const { user } = useAuth()
  const { data } = useQuery(PARTNER_GET_STARTED_PAGE_QUERY)
  const contactRef = useRef()
  const ref = useRef()
  const { hash } = useLocation()

  const howtoGetStarted = [
    {
      id: "0",
      logo: "far fa-hand-holding-box",
      name: formatMessage({
        id: "howItWorks::nonProfit::benefit1::title",
        defaultMessage: "Join The Donations Revolution",
      }),
      shortDescription: formatMessage({
        id: "howItWorks::nonProfit::benefit1::description",
        defaultMessage:
          "Access to a new transparent fundraising channel at ZERO COST",
      }),
    },
    {
      id: "1",
      logo: "far fa-globe",
      name: formatMessage({
        id: "howItWorks::nonProfit::benefit2::title",
        defaultMessage: "A system of rewards for your supporters",
      }),
      shortDescription: formatMessage({
        id: "howItWorks::nonProfit::benefit2::description",
        defaultMessage:
          "With TrustMeUp, your supporters are 100% Rewarded in digital tokens, called PACs (1 Euro = 1 PAC (Passions Coin)) for each contribution they make to be used together with Euro (€), when shopping inside TrustMeUp marketplace",
      }),
    },
    {
      id: "2",
      logo: "far fa-portrait",
      name: formatMessage({
        id: "howItWorks::nonProfit::benefit3::title",
        defaultMessage: "Global Visibility In An Instant",
      }),
      shortDescription: formatMessage({
        id: "howItWorks::nonProfit::benefit3::description",
        defaultMessage:
          "Receive a gift of $10K Google AdWords to promote your projects",
      }),
    },
    {
      id: "3",
      logo: "far fa-megaphone",
      name: formatMessage({
        id: "howItWorks::nonProfit::benefit4::title",
        defaultMessage: "Transparency and inviolability",
      }),
      shortDescription: formatMessage({
        id: "howItWorks::nonProfit::benefit4::description",
        defaultMessage:
          "Thanks to the blockchain technology utilised, your organization gains transparent positioning and increased trustworthiness of your non-profit projects among donors.",
      }),
    },
    {
      id: "4",
      logo: "far fa-chart-network",
      name: formatMessage({
        id: "howItWorks::nonProfit::benefit5::title",
        defaultMessage: "Centralized Management",
      }),
      shortDescription: formatMessage({
        id: "howItWorks::nonProfit::benefit5::description",
        defaultMessage:
          "Having an admin panel where you can manage your fundraising operations easily",
      }),
    },
    {
      id: "5",
      logo: "far fa-shield-check",
      name: formatMessage({
        id: "howItWorks::nonProfit::benefit6::title",
        defaultMessage: "Supported Onboarding Process",
      }),
      shortDescription: formatMessage({
        id: "howItWorks::nonProfit::benefit6::description",
        defaultMessage:
          "Send us a message using the contact form below and TrustMeUp marketing team will call you back to quickly get you started.",
      }),
    },
  ]

  const hashCallback = () => {
    const hashText = "#join-us"
    const isHashInUrl = hash === hashText || window?.location?.hash === hashText
    if (isInViewport(ref) && !isHashInUrl) {
      history.pushState(null, null, hashText)
    }
    if (!isInViewport(ref) && isHashInUrl) {
      history.replaceState(null, null, " ")
    }
  }

  useEffect(() => {
    document.addEventListener("scroll", hashCallback)
    return () => document.removeEventListener("scroll", hashCallback)
  }, [])

  return (
    <>
      <SEO
        lang={locale}
        title={formatMessage({
          id: "howItWorks::nonProfit::pageTitle",
          defaultMessage: "Non-Profit Organizations",
        })}
        image={
          CLOUDFLARE_IMAGE_URL +
          "/cdn-cgi/image/width=1920,fit=cover,format=webp,quality=90/static/assets/images/ngo.jpg"
        }
      />
      <Hero page={`/${locale}/join/ngo`} contactRef={contactRef}>
        <LazyImage
          className="merchant-banner-image"
          src={
            CLOUDFLARE_IMAGE_URL +
            "/static/assets/images/placeholder/default-banner.jpg"
          }
          altName={`non-profit-default-banner`}
          width="1920"
          fit="cover"
        />
      </Hero>
      <StyledGetStartedWrapper>
        <GetStartedIntro>
          <FormattedMessage
            id="howItWorks::nonProfit::text1"
            defaultMessage="Looking for help to raise more funds for your non-profit projects? We've got you covered by giving a new way to encourage your supporters to make their contribution."
            tagName="p"
          />

          <FormattedMessage
            id="howItWorks::nonProfit::text2"
            defaultMessage="TrustMeUp is the first economic model that rewards your supporters allowing them to contribute to the common good, both economically and actively, eliminating the fear of uncertainty of the use of funds and the inability to decide their destination"
            tagName="p"
          />
        </GetStartedIntro>

        <TabContent
          icon={faHandHeart}
          title={
            <FormattedMessage
              id="howItWorks::tabLeft::title"
              defaultMessage="How to Join"
              tagName="span"
            />
          }
          desc1={
            <ul>
              <FormattedMessage
                id="howItWorks::nonProfit:tabLeft::desc1"
                defaultMessage="<list>Sign up just in 60 seconds using your email or Google account</list><list>Create your dedicated fundraising account</list><list>Publish your fundraising project </list><list>Receive the donations</list><list>Publish updates on the project and keep your donors informed</list>"
                values={{
                  list: (...chunks) => (
                    <li>
                      {chunks.map((chunk, i) => (
                        <Fragment key={i}>{chunk}</Fragment>
                      ))}
                    </li>
                  ),
                }}
                tagName="ul"
              />
            </ul>
          }
          imgText={
            <FormattedMessage
              id="howItWorks::nonProfit:tabImageText"
              defaultMessage="Raise More Funds and Attract Donors from All Around the World"
            />
          }
          imgLandscapeMedium={
            CLOUDFLARE_IMAGE_URL +
            "/cdn-cgi/image/width=312,height=227,fit=cover,format=webp,quality=90/static/assets/images/users/non-profit-landscape-medium.jpg"
          }
          imgSquare={
            CLOUDFLARE_IMAGE_URL +
            "/cdn-cgi/image/width=170,height=156,fit=cover,format=webp,quality=90/static/assets/images/users/soccer-team.jpg"
          }
          imgLandscapeSmall={
            CLOUDFLARE_IMAGE_URL +
            "/cdn-cgi/image/width=255,height=156,fit=cover,format=webp,quality=90/static/assets/images/users/non-profit-landscape-small.jpg"
          }
        />

        <HowtoGetStarted
          title={
            <FormattedMessage
              id="howItWorks::howtoGetStarted::title"
              defaultMessage="Benefits"
              tagName="h3"
            />
          }
          howtoGetStarted={howtoGetStarted}
          contactRef={!user?.isPartner && contactRef}
          type="partner"
        />

        <CurrentInTMU
          title={
            <FormattedMessage
              id="howItWorks::nonProfit::currentMerchants"
              defaultMessage="Current Organizations in TMU"
              tagName="h3"
            />
          }
          variant="partner"
          data={data?.allPartners}
        />
        {!user?.isPartner && (
          <div ref={ref} id="join-us" data-testid="text-nonProfit">
            <HowItWorksForm
              contactRef={contactRef}
              title={
                <FormattedMessage
                  id="howItWorks::forms::contactForm::partnersTitle"
                  defaultMessage="Join Us as a Non-Profit"
                  tagName="h3"
                />
              }
              desc={
                <FormattedMessage
                  id="howItWorks::forms::contactForm::description"
                  defaultMessage="Tell us a little bit about yourself so we can get you started in the donations economy"
                  tagName="p"
                />
              }
              sender="PARTNER"
            />
          </div>
        )}
      </StyledGetStartedWrapper>
    </>
  )
}

export default injectIntl(NonProfitPage)
