import React, { useEffect } from "react"
import { useIntl, navigate } from "gatsby-plugin-intl"
import queryString from "query-string"
import { useAuth, useUserExists } from "@tmu/hooks"
import { setCredentials, removeCredentials, isBrowser } from "@tmu/utils/auth"
import { get, set } from "@tmu/utils/storage"
import { useMutation } from "@apollo/client"
import { SOCIAL_AUTH_LOGIN } from "@tmu/apollo/storefront/mutations/user"
import { StyledSocialSection, GoogleSocialButton } from "./index.styles"
import { useMediaQuery } from "beautiful-react-hooks"
import { jwtDecode } from "jwt-decode"

export function GoogleSignin({ medium, silent, isRegister }) {
  const { user, callUserProfile, callAddReferralMutation, socialRegister } =
    useAuth()
  const { locale } = useIntl()

  const { checkUserExist } = useUserExists()

  const isTablet =
    typeof window !== "undefined" && useMediaQuery("(min-width: 432px)") //related to google button's max width + padding

  const [socialAuthMutation] = useMutation(SOCIAL_AUTH_LOGIN)
  useEffect(() => {
    if (user?._id) return

    const initializeGsi = () => {
      if (!window.google) {
        return
      }

      window.google.accounts.id.initialize({
        client_id: process.env.GOOGLE_CLIENT_ID,
        callback: handleGoogleSignIn,
      })
      window.google.accounts.id.renderButton(
        document.getElementById("googleSignInButtonWrapper"),
        {
          type: "standard",
          theme: "outline",
          size: "large",
          shape: "rectangular",
          logo_alignment: "center",
          width: isTablet ? (medium ? 378 : 400) : 328,
          height: 40,
          locale,
        } // customization attributes
      )
      google.accounts.id.prompt() // display the One Tap dialog
    }

    const script = document.createElement("script")
    script.src = "https://accounts.google.com/gsi/client"
    script.onload = initializeGsi
    script.async = true
    script.id = "google-client-script"
    document.querySelector("body")?.appendChild(script)

    return () => {
      // Cleanup function that runs when component unmounts
      window.google?.accounts.id.cancel()
      document.getElementById("google-client-script")?.remove()
    }
  }, [isTablet, handleGoogleSignIn, user?._id])

  const handleGoogleSignIn = (res) => {
    if (!(res.clientId || res.credential)) return
    const accessTokenDecrypted = jwtDecode(res.credential)
    checkUserExist({
      variables: {
        email: accessTokenDecrypted?.email,
      },
    }).then(({ data }) => {
      let nextUrl
      let params = {}
      try {
        const paramOptions = { arrayFormat: "comma" }
        params = queryString.parse(location.search, paramOptions)
        const next = params?.next?.replace(/\/(en|it|es)/g, "") ?? "/"
        nextUrl = queryString.stringifyUrl({
          url: next,
          query: params,
        })
      } catch (error) {}
      if (isRegister && !data.userExists) {
        return socialRegister({
          provider: "GOOGLE",
          tokenId: res.credential,
          next: nextUrl,
        })
          .then(() => {
            const referralCode = params?.referral || get("referralCode")
            if (referralCode) {
              callAddReferralMutation(referralCode)
                ?.then((response) => {
                  handleRegisterResponse(response, values?.email, referralCode)
                })
                .catch((err) => {
                  formatMessage({
                    id: "signup::auth::errorOccured",
                    defaultMessage:
                      "An error occured. Please contact TMU support!",
                  })
                })
            }
          })
          .catch((err) => {
            setRegisterError(
              formatMessage({
                id: "signup::auth::errorOccured",
                defaultMessage: "An error occured. Please contact TMU support!",
              })
            )
          })
      } else
        return socialAuthMutation({
          variables: {
            input: { provider: "GOOGLE", tokenId: res.credential },
          },
        }).then((result) => {
          if (!result?.data?.socialAuth) {
            removeCredentials()
            return null
          }
          const { provider, token, refreshToken, user, isRegistered, errors } =
            result?.data?.socialAuth
          const refCode = params?.referral || get("referralCode")

          setCredentials({ token, refreshToken })
          callAddReferralMutation(refCode)
          callUserProfile()
          if (!silent)
            navigate(
              [
                "/?next=/en/",
                "/?next=/it/",
                "/?next=/es/",
                "/?next=/",
                "/",
              ].includes(decodeURIComponent(nextUrl))
                ? "/user-space"
                : nextUrl || "/user-space",
              {
                replace: true,
              }
            )
        })
    })
  }

  return (
    <GoogleSocialButton id="googleSignInButtonWrapper"></GoogleSocialButton>
  )
}

const SocialLoginComponent = ({ isSubmitting, isRegister, medium, silent }) => {
  const { signInWithFacebook, loading } = useAuth()

  return (
    <StyledSocialSection>
      <GoogleSignin medium={medium} silent={silent} isRegister={isRegister} />
      {/* <FacebookLoginButton signInWithFacebook={signInWithFacebook} /> */}
    </StyledSocialSection>
  )
}

export default SocialLoginComponent
