import styled from "@emotion/styled"
import { css } from "@emotion/react"

export const StyledFieldWrapper = styled.div``

export const StyledField = styled.div`
  margin-bottom: 1rem;
  ${(p) =>
    p.detail &&
    css`
      position: relative;
      height: 11.5rem;
      top: -0.4938rem;
      margin: 2rem 0;

      div:first-of-type {
        height: 100%;
      }

      .float-container textarea ~ label {
        transform: translate(-0.5rem, -12rem) scale(0.8);
      }
    `}

  ${(p) =>
    p.ordering &&
    css`
      display: flex;
      align-items: center;

      > div {
        margin-left: 1rem;
      }

      .react-select__control {
        border-radius: 0.25rem;
      }
    `}
`
export const StyledInfoText = styled.div`
  margin-bottom: 1rem;
`
export const StyledCheckboxArea = styled.div`
  display: flex;
  gap: 3rem;
`
