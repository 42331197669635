import React from "react"
import PropTypes from "prop-types"
import "react-toggle/style.css"
import Toggle from "react-toggle"
import { StyledToggleWrapper } from "./index.styles"

const CommonToggle = ({
  className,
  defaultChecked,
  checked,
  handleChange,
  color = "blue",
  disabled,
  controlled,
}) => {
  return (
    <StyledToggleWrapper
      className={className}
      color={color}
      disabled={disabled}>
      {controlled ? (
        <Toggle
          checked={checked}
          defaultChecked={defaultChecked}
          icons={false}
          onChange={handleChange}
          disabled={disabled}
        />
      ) : (
        <Toggle
          defaultChecked={defaultChecked}
          icons={false}
          onChange={handleChange}
          disabled={disabled}
        />
      )}
    </StyledToggleWrapper>
  )
}

CommonToggle.propTypes = {
  handleChange: PropTypes.func.isRequired,
}

CommonToggle.defaultProps = {
  defaultChecked: false,
  handleChange: () => {},
}

export default CommonToggle
