import { useMutation } from "@apollo/client"
import { useApolloApiClients } from "@tmu/apollo/client"
import { SEND_PASSWORD_RESET_EMAIL } from "@tmu/apollo/storefront/mutations/user"
import { Button, TextInput } from "@tmu/components/common"
import { StyledForm } from "@tmu/global/page-addons/dashboard.styles"
import { useToast } from "@tmu/hooks"
import { email } from "@tmu/utils/validation"
import { Formik } from "formik"
import { FormattedMessage, useIntl, Link } from "gatsby-plugin-intl"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import * as Yup from "yup"
import {
  StyledButtonContainer,
  StyledFormContainer,
  StyledDescription,
  StyledBackLink,
  StyledTitle,
} from "./index.styles"

const PasswordRecoveryForm = ({ onBack, backUrl, onButtonClick, clickUrl }) => {
  const { storefrontClient } = useApolloApiClients()
  const { formatMessage } = useIntl()
  const { error, success } = useToast()

  const initialValues = {
    email: "",
  }
  const validationSchema = Yup.object().shape({
    email: email({ formatMessage }),
  })

  const [callSendEmail, { data, errors }] = useMutation(
    SEND_PASSWORD_RESET_EMAIL,
    {
      client: storefrontClient,
    }
  )

  const sendEmail = (email) => {
    callSendEmail({
      variables: { email, isPublic: true },
    })
  }

  useEffect(() => {
    if (data?.sendPasswordResetEmail?.success) {
      const sentEmailSuccessfullText = formatMessage({
        id: "passwordRecovery::sentEmailSuccessfully",
        defaultMessage: "Email is sent successfully",
      })

      success(sentEmailSuccessfullText)
    }

    if (errors) {
      error(errors.message)
    }
  }, [data, errors])

  const continueText = formatMessage({
    id: "passwordRecovery::send",
    defaultMessage: "Send",
  })

  const backText = formatMessage({
    id: "passwordRecovery::back",
    defaultMessage: "Back",
  }).toUpperCase()

  const descriptionText = formatMessage({
    id: "resetYourPassword::description",
    defaultMessage: defaultDescriptionText,
  })

  const handleSubmit = (values, form) => {
    onClick(values)
  }

  const onClick = (values) => {
    try {
      if (clickUrl?.length) {
        window.location.href = clickUrl
      } else if (onButtonClick && typeof onButtonClick === "function") {
        onButtonClick(values?.email)
      }
    } catch (e) {
      console.error(e)
    }

    if (values?.email?.length) {
      sendEmail(values.email)
    }
  }

  const onBackClick = () => {
    if (backUrl?.length) {
      window.location.href = backUrl
    } else if (onBack && typeof onBack === "function") {
      onBack()
    }
  }

  return (
    <>
      <StyledTitle>
        <FormattedMessage
          id="resetYourPassword::title"
          defaultMessage="Reset your password"
          tagName="h1"
        />
      </StyledTitle>

      <StyledDescription>
        <div
          dangerouslySetInnerHTML={{
            __html: descriptionText,
          }}
        />
      </StyledDescription>
      <Formik
        enableReinitialize={true}
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}>
        {({
          values,
          handleChange,
          handleBlur,
          handleReset,
          touched,
          errors,
          setFieldTouched,
        }) => {
          return (
            <>
              <StyledFormContainer>
                <StyledForm onSubmit={handleSubmit}>
                  <TextInput
                    newDesign
                    data-testid="input-email"
                    id="email"
                    name="email"
                    type="email"
                    className="float-container"
                    label={formatMessage({
                      id: "dashboard::forms::email",
                      defaultMessage: "Email",
                    })}
                    error={errors.email}
                    value={values.email ?? ""}
                    onChange={(e) => {
                      setFieldTouched("email")
                      const lowerCaseEmail = e.target.value.toLowerCase()
                      handleChange({
                        target: {
                          name: e.target.name,
                          value: lowerCaseEmail,
                        },
                      })
                    }}
                    placeholder={formatMessage({
                      id: "resetYourPassword::emailPlaceholder",
                      defaultMessage: "Type your email",
                    })}
                    onBlur={(e) => {
                      setFieldTouched("email")
                      handleBlur(e)
                    }}
                    touched={touched.password}
                  />
                </StyledForm>
                <StyledButtonContainer>
                  <Button
                    label="resetYourPassword"
                    type="submit"
                    color="blue"
                    onClick={() => {
                      onClick(values)
                      handleReset()
                    }}
                    disabled={errors?.email || !values?.email?.length}>
                    {continueText}
                  </Button>
                  <StyledBackLink>
                    <Link
                      to={`/signin`}
                      data-testid="text-signin"
                      aria-label="SignIn Here">
                      <FormattedMessage
                        id="resetYourPassword::backToSignIn"
                        defaultMessage="Back to sign in"
                      />
                    </Link>
                  </StyledBackLink>
                </StyledButtonContainer>
              </StyledFormContainer>
            </>
          )
        }}
      </Formik>
    </>
  )
}

const defaultDescriptionText =
  "Enter your email address and we will send a link to reset your password."

PasswordRecoveryForm.propTypes = {
  onBack: PropTypes.func,
  backUrl: PropTypes.string,
  onButtonClick: PropTypes.func,
  clickUrl: PropTypes.string,
}

export default PasswordRecoveryForm
