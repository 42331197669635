import styled from "@emotion/styled"
import { Link } from "gatsby-plugin-intl"

export const StyledMissingAddressWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 5rem;
  margin-bottom: 5rem;
  z-index: 999;
  display: flex;
  background: ${(p) => p.theme.colors.white};
  padding: 0.95rem;
  border: 0.0625rem solid rgba(0, 0, 0, 0.15);
  color: ${(p) => p.theme.colors.secondaryBrandTextColor};
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
    flex-direction: column;
    font-size: 0.9375rem;
    padding: 0.625rem;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.phone}) {
    top: 10%;
  }
`

export const StyledLink = styled(Link)`
  &,
  &:hover,
  &:visited,
  &.active {
    color: ${(p) => p.theme.colors.blue} !important;
    font-weight: ${(p) => p.theme.fontWeights.bold};
  }
`
