import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { Link } from "gatsby-plugin-intl"
import { Button } from "@tmu/components/common"
import Slider from "react-slick"

export const StyledCardContainer = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(${(p) => p.maxCols ?? 4}, 1fr);
  grid-gap: 1rem;
  padding: 1rem 0;
  align-items: start;
  justify-items: center;
  width: 100%;
  @media only screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
    grid-template-columns: repeat(auto-fill, minmax(0, 17.8125rem));
    place-content: center;
  }

  @media only screen and (max-width: 40.375rem) {
    grid-template-columns: 1fr;

    > div {
      width: 100%;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
`

export const StyledCampaignCardContainer = styled(StyledCardContainer)`
  grid-gap: 2rem 1rem;
  grid-template-columns: repeat(auto-fill, minmax(16.875rem, 1fr));
`

export const StyledBrandsCardContainer = styled(StyledCardContainer)`
  max-width: ${(p) => p.theme.breakpoints.wide};
  display: flex;
  justify-content: space-between;
  padding: 4.375rem;
  flex-wrap: wrap;
  > div {
    align-self: center;
    margin-bottom: 0;
    width: auto;
  }
`

export const StyledCard = styled.div`
  position: relative;
  margin-bottom: ${(p) =>
    p.variant && p.variant === "campaign" ? 0 : "1.5rem"};
  grid-area: ${(p) =>
    p.featured && p.featured.length > 0 ? "featured" : "auto"};

  transform: scale(1);
  transition: all 0.2s ease;
  z-index: 100;

  &:hover,
  &:focus {
    transform: scale(1.025);
    z-index: 200;
  }

  @media only screen and (min-width: 40.375rem) {
    max-width: 17.5rem;
  }
  cursor: pointer;
`

export const StyledImageBox = styled.div`
  position: relative;
  border-radius: 0.35rem;
  border: ${(p) => p.variant !== "brand" && "0.0625rem solid transparent"};
  background-color: transparent;
  overflow: hidden;

  ${(p) =>
    p.hasImageBorder &&
    css`
      border-color: ${(p) => p.theme.colors.gallery};
    `}
  .media-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    padding: 0;
  }
  img {
    object-fit: cover;
  }

  a {
    display: flex;
    align-items: center;
  }
`

export const StyledPartnerImageBox = styled(StyledImageBox)`
  height: 9.875rem;
  width: 17.5rem;
  display: flex;
  background-color: ${(p) => p.theme.colors.white};
  border-radius: 0.35rem;
  border: 0.0625rem solid ${(p) => p.theme.colors.gallery};

  @media only screen and (max-width: 40.375rem) {
    width: 100%;
  }
`
export const StyledBrandImageBox = styled(StyledImageBox)`
  img {
    filter: ${(p) => (p.isLight ? "contrast(80%) brightness(130%)" : "")}
      grayscale(100%);
  }
`

export const StyledRibbon = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-131%) rotate(358deg) translateX(-36%) rotate(-45deg);
  transform-origin: bottom right;
  background-color: ${(p) => p.theme.colors.red};
  color: white;
  font-weight: ${(p) => p.theme.fontWeights.bold};
  width: 10rem;
  height: 2rem;
  font-size: 0.75rem;
`

export const StyledImageBoxOffer = styled(StyledImageBox)`
  margin-bottom: 0;
  border-bottom: none;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
`

export const StyledPromoText = styled.p`
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  text-align: left;
  line-height: 1.875rem;
  border-radius: 0 0 0 0.25rem;
  color: ${(p) => p.theme.colors.white};
  background-color: ${(p) => p.theme.colors.blue};
  font-size: 0.8125rem;
  margin-bottom: 0;
  padding: 0.05rem 0.5rem;
  margin-left: ${(p) => p.square && "7.1875rem"};
`

export const StyledPromoTextOffer = styled(StyledPromoText)`
  border-radius: 0 0 0.35rem 0.35rem !important;
  position: relative;
  color: ${(p) => p.theme.colors.white} !important;
`

export const StyledCardLogo = styled.div`
  height: 5.625rem;
  width: 5.625rem;
  position: absolute;
  right: 0;
  bottom: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: ${(p) => p.theme.colors.tableItemBorder};
  border-top-left-radius: 0.25rem;
  overflow: hidden;
`

export const StyledCardTitle = styled(Link)`
  margin-top: 1.25rem;
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-size: 1.22rem;
  background: transparent;
  color: ${(p) => p.theme.colors.primaryTextColor};
  width: 100%;
  display: block;
`
export const StyledCardInfo = styled.div`
  position: relative;
`
export const StyledCampaignCardTitleWrapper = styled.div`
  height: 3.05rem;
  margin-top: 1rem;
`

export const StyledCampaignCardTitle = styled(StyledCardTitle)`
  max-width: 75%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 1.125rem;
  font-weight: ${(p) => p.theme.fontWeights.bold};
  margin: 0;
  grid-area: title;
  margin-bottom: ${(p) => p.featured && p.featured.length > 0 && "1.125rem"};

  ${(p) =>
    p.variant === "big" ??
    `
      position: relative;
      height: 4rem;
      padding: 1rem;
      border-radius: 0 0.3125rem 0 0;
      white-space: wrap;
  `}
`

export const StyledCardText = styled.p`
  display: ${(p) => p.featured && p.featured.length > 0 && "none"};
  position: relative;
  color: ${(p) => p.theme.colors.secondaryBrandTextColor};
  font-size: 1rem;
  line-height: 1.5rem;
  min-height: 4.5rem;

  ${(p) =>
    p?.variant === "big" ??
    `
    margin-top: -4rem;
    padding: 1rem;
  `}
`

export const StyledButton = styled(Button)`
  margin: 1.8rem auto 0;
`

export const StyledTitlePrice = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
`

export const StyledCardPrice = styled.div`
  margin-top: 0.875rem;
  padding: 0.3125rem 0.375rem 0.25rem 0.5rem;
  background-color: ${(p) => p.theme.colors.black};
  color: ${(p) => p.theme.colors.white};
  opacity: 0.8;
  border-radius: 0.25rem;
  font-size: 1rem;
  font-weight: ${(p) => p.theme.fontWeights.bold};
  line-height: 1.1875rem;
`
export const StyledSlider = styled(Slider)`
  .slick-slide {
    > div {
      margin: 0 0.5rem;
    }
  }

  @media screen and (max-width: 80.4375rem) {
    .slick-prev,
    .slick-next {
      display: none !important;
    }
  }
`
