import styled from "@emotion/styled"

import {
  StyledEditLink,
  StyledPageGrid,
  StyledRowTitle,
} from "@tmu/components/dashboard/dashboardPages/Profile/index.styles"

import { StyledRowTitleExplanation } from "@tmu/components/dashboard/dashboardPages/Settings/index.styles"

export const StyledProfileEditLink = styled((props) => (
  <StyledEditLink {...props} />
))`
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    .image-selector {
      flex: unset;
      img {
        width: auto;
        height: auto;
        max-height: 7.5rem !important;
      }
    }
  }

  span {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-style: ${(p) => p.theme.fontStyles.normal};

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 1rem;
      line-height: 1.5rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.5rem;
      line-height: 2.125rem;
    }
  }
`

export const StyledProfilePageGrid = styled((props) => (
  <StyledPageGrid {...props} />
))`
  grid-column-gap: 2rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    grid-template-columns: 2fr 5fr;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    grid-template-columns: 2fr 8fr;
  }
`

export const StyledProfileRowTitle = styled((props) => (
  <StyledRowTitle {...props} />
))`
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    width: 13.0625rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    width: 16.9375rem;
  }
`

export const StyledProfileRowTitleExplanation = styled((props) => (
  <StyledRowTitleExplanation {...props} />
))`
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    width: 13.1875rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    width: 16.9375rem;
  }
`
