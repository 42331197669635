import React from "react"
import { FormattedDate } from "gatsby-plugin-intl"

const Date = ({ value, showYear, ...rest }) => {
  let props = {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    value,
    ...rest,
  }
  if (rest.year === null) {
    delete props.year
  }
  if (rest.hour === null) {
    delete props.hour
  }
  if (rest.minute === null) {
    delete props.minute
  }
  if (!showYear) {
    delete props?.year
  }

  if (props?.value === true || props?.value === "true") {
    delete props?.value
  }

  return value && value.length > 0 ? (
    <FormattedDate hour12={false} format {...props} />
  ) : (
    <></>
  )
}

export default Date
