import React, { useEffect, useState } from "react"
import { useMutation } from "@apollo/client"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useApolloApiClients } from "@tmu/apollo/client"
import { PASSWORD_RESET } from "@tmu/apollo/storefront/mutations/user"
import { Button, TermsAndConditions, TextInput } from "@tmu/components/common"
import FooterLogoText from "@tmu/components/common/Footer/FooterLogoText"
import { StyledForm } from "@tmu/global/page-addons/dashboard.styles"
import { useToast } from "@tmu/hooks"
import {
  StyledContinueButtonContainer,
  StyledDescription,
  StyledFormContainer,
  StyledInputContainer,
  StyledLogoContainer,
  StyledPageContainer,
  StyledTitle,
} from "@tmu/global/page-addons/reset-password.styles"
import { agreeTerms, password } from "@tmu/utils/validation"
import { Formik } from "formik"
import {
  FormattedMessage,
  injectIntl,
  navigate,
  useIntl,
} from "gatsby-plugin-intl"
import * as Yup from "yup"
import theme from "@tmu/global/theme"

const ResetPassword = () => {
  const { storefrontClient } = useApolloApiClients()
  const { formatMessage } = useIntl()
  const { error, success } = useToast()
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  let token = ""
  let terms = "1"

  if (typeof window !== "undefined") {
    token = new URL(window?.location?.href).searchParams.get("token")

    terms = new URL(window?.location?.href).searchParams.get("terms")
  }

  const initialValues = {
    password: "",
    repassword: "",
    terms: terms === "1",
  }
  const validationSchema = Yup.object().shape({
    password: password({ formatMessage }),
    repassword: Yup.string()
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: () =>
          Yup.string().oneOf(
            [Yup.ref("password")],
            formatMessage({
              id: "signup::password::notMatch",
              defaultMessage: "Both passwords need to be the same",
            })
          ),
        otherwise: () => {},
      })
      .required(
        formatMessage({
          id: "forms::passwordRequiredError",
          defaultMessage: "A password is required.",
        })
      ),
    terms: agreeTerms({ formatMessage }),
  })

  const [callPasswordReset, { data, errors }] = useMutation(PASSWORD_RESET, {
    client: storefrontClient,
  })

  const resetPassword = (password, repassword, terms) => {
    callPasswordReset({
      variables: {
        input: {
          newPassword1: password,
          newPassword2: repassword,
          token,
          isTermsAndPolicyAccepted: terms,
        },
        isPublic: true,
      },
    })
  }

  useEffect(() => {
    if (data?.passwordReset?.success) {
      const passwordResetSuccessfullyText = formatMessage({
        id: "passwordReset::passwordResetSuccessfully",
        defaultMessage: "Your password is reset successfully",
      })

      success(passwordResetSuccessfullyText)
      setTimeout(() => {
        navigate("/")
      }, 2000)
    }

    if (data?.passwordReset?.errors) {
      let errorMessage = ""

      Object.values(data?.passwordReset?.errors).forEach((t) =>
        t.forEach((s) => (errorMessage += "\n" + s.message))
      )

      error(errorMessage)
    }

    if (errors) {
      error(errors.message)
    }
  }, [data, errors])

  const continueText = formatMessage({
    id: "resetPassword::continueToTMU",
    defaultMessage: "Continue to TrustMeUp",
  })

  const descriptionText = formatMessage({
    id: "resetPassword::description",
    defaultMessage: "Enter a new password",
  })

  const handleSubmit = (values) => {
    resetPassword(values.password, values.repassword)
  }

  const onClick = (values) => {
    resetPassword(values.password, values.repassword, values.terms)
  }

  return (
    <>
      <StyledPageContainer>
        <StyledLogoContainer>
          <FooterLogoText
            width="180"
            height="90"
            flat={false}
            fill={theme?.colors?.blue}
          />
        </StyledLogoContainer>
        <StyledTitle>
          <FormattedMessage
            id="resetPassword::title"
            defaultMessage="Reset Password"
            tagName="h1"></FormattedMessage>
        </StyledTitle>

        <StyledDescription>
          <div
            dangerouslySetInnerHTML={{
              __html: descriptionText,
            }}
          />
        </StyledDescription>
        <Formik
          enableReinitialize={true}
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={handleSubmit}>
          {({
            values,
            handleChange,
            handleBlur,
            handleReset,
            touched,
            errors,
            setTouched,
          }) => {
            return (
              <>
                <StyledFormContainer>
                  <StyledForm onSubmit={handleSubmit}>
                    <StyledInputContainer>
                      <TextInput
                        newDesign
                        data-testid="input-password"
                        id="password"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.password}
                        label={formatMessage({
                          id: "dashboard::forms::newPassword",
                          defaultMessage: "Your new password",
                        })}
                        placeholder={formatMessage({
                          id: "dashboard::forms::newPassword",
                          defaultMessage: "Your new password",
                        })}
                        error={errors.password}
                        touched={touched.password}
                        inputIcon={
                          <FontAwesomeIcon
                            icon={showPassword ? faEyeSlash : faEye}
                            className="input-icon"
                            color={theme.colors.blue}
                            onClick={() => setShowPassword(!showPassword)}
                          />
                        }
                        iconRight={0.9375}
                      />
                      <TextInput
                        newDesign
                        data-testid="input-repassword"
                        id="repassword"
                        name="repassword"
                        type={showConfirmPassword ? "text" : "password"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.repassword}
                        label={formatMessage({
                          id: "dashboard::forms::confirmNewPassword",
                          defaultMessage: "Confirm your new password",
                        })}
                        placeholder={formatMessage({
                          id: "dashboard::forms::confirmNewPassword",
                          defaultMessage: "Confirm your new password",
                        })}
                        error={errors.repassword}
                        touched={touched.repassword}
                        inputIcon={
                          <FontAwesomeIcon
                            icon={showConfirmPassword ? faEyeSlash : faEye}
                            className="input-icon"
                            color={theme.colors.blue}
                            onClick={() =>
                              setShowConfirmPassword(!showConfirmPassword)
                            }
                          />
                        }
                        onPaste={(e) => {
                          e.preventDefault()
                          return false
                        }}
                        iconRight={0.9375}
                      />
                    </StyledInputContainer>
                  </StyledForm>
                  {terms === "0" && (
                    <TermsAndConditions
                      className="full-width"
                      name="terms"
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                      onClick={() => setTouched({ ...touched, terms: true })}
                    />
                  )}
                  <StyledContinueButtonContainer>
                    <Button
                      label="Continue"
                      type="submit"
                      color="blue"
                      onClick={() => {
                        onClick(values)
                        handleReset()
                      }}
                      disabled={
                        errors?.password ||
                        !values?.password?.length ||
                        errors?.repassword ||
                        !values?.repassword?.length ||
                        values?.password !== values?.repassword
                      }>
                      {continueText}
                    </Button>
                  </StyledContinueButtonContainer>
                </StyledFormContainer>
              </>
            )
          }}
        </Formik>
      </StyledPageContainer>
    </>
  )
}

export default injectIntl(ResetPassword)
