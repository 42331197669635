import { useToast } from "@tmu/hooks"
import { TextInput } from "@tmu/src/components/common"
import { Spacer } from "@tmu/src/global/page-addons/detail-page.styles"
import { useVouchers } from "@tmu/src/hooks"
import { FormattedMessage } from "gatsby-plugin-intl"
import PropTypes from "prop-types"
import { createRef, useEffect, useState } from "react"
import {
  StyledContainer,
  StyledInvalidCodeError,
  StyledVerifyVoucherTitle,
  StyledVoucherCodeVerifyWrapper,
} from "./index.styles"
import { compareAsc } from "date-fns"
import { PRODUCT_VARIATION_TICKET_STATUS } from "@tmu/src/apollo/constants"

const VoucherCodeVerify = ({
  voucherCodeParam,
  onSuccess,
  isCampaign,
  apiType,
}) => {
  const [voucherCode, setVoucherCode] = useState(
    voucherCodeParam?.split("") ?? []
  )
  const { error } = useToast()
  const { callAllVoucherCodes, callAllEventVoucherCodes } = useVouchers({
    apiType,
  })

  const voucherCodeLen = 6
  const [invalidCodeErrorStatus, setInvalidCodeErrorStatus] = useState(false)
  const [inputRefs] = useState(() =>
    Array.from({ length: voucherCodeLen }, () => createRef())
  )

  useEffect(() => {
    if (voucherCodeParam?.length) {
      checkVoucherCode(voucherCodeParam)
    }
  }, [])

  const checkVoucherCode = (code) => {
    setInvalidCodeErrorStatus(false)

    if (code?.length < voucherCodeLen) {
      return
    }

    const callVouchers = isCampaign
      ? callAllEventVoucherCodes
      : callAllVoucherCodes

    callVouchers({ variables: { voucherCode: code?.toUpperCase() } })
      .then((result) => {
        const itemsData = isCampaign
          ? result?.data?.allEventTickets
          : result?.data?.allProductVariationTickets

        const item = itemsData?.edges?.[0]?.node
        if (result?.errors?.length || !item) {
          setInvalidCodeErrorStatus(true)
          result?.errors?.forEach((err) => error(err?.message))
          return
        }
        const dataItem = isCampaign
          ? item?.tier?.campaign
          : item?.productVariation?.offer
        const endDate = dataItem?.endDate ?? dataItem?.deadline
        const dateEndDate = endDate ? new Date(endDate) : null
        const isExpired = dateEndDate
          ? compareAsc(new Date(), dateEndDate) > 0
          : false

        const voucherStatus = isExpired
          ? PRODUCT_VARIATION_TICKET_STATUS.EXPIRED
          : item?.status?.toUpperCase()

        const isCloseIcon =
          isExpired || voucherStatus === PRODUCT_VARIATION_TICKET_STATUS.USED

        if (typeof onSuccess === "function") {
          onSuccess(code?.toUpperCase(), item, !isCloseIcon)
        }
      })
      .catch((err) => {
        error(err?.message)
      })
  }
  return (
    <StyledContainer>
      <StyledVerifyVoucherTitle>
        <FormattedMessage
          id="dashboard::voucher::verifyTitle"
          defaultMessage="Enter 6 symbols voucher code"
        />
      </StyledVerifyVoucherTitle>
      <Spacer top={1} />
      <StyledVoucherCodeVerifyWrapper
        className={invalidCodeErrorStatus ? "error" : ""}>
        {inputRefs.map((ref, index) => (
          <div ref={ref}>
            <TextInput
              key={index}
              maxLength={1}
              defaultValue={voucherCode[index]}
              max={1}
              onInput={(e) => {
                e.target.value = e.target.value
                  .slice(0, e.target.maxLength)
                  ?.trim()
                if (e.target.value?.length === 0) {
                  return
                }

                if (index < voucherCodeLen - 1) {
                  const input =
                    inputRefs?.[index + 1]?.current?.getElementsByTagName(
                      "input"
                    )?.[0]
                  input?.select()
                }
              }}
              onChange={(e) => {
                const temp = voucherCode
                temp[index] = e?.target?.value
                setVoucherCode(temp)
                const code = temp?.join("")
                if (code?.length === voucherCodeLen) {
                  checkVoucherCode(code)
                }
              }}
            />
          </div>
        ))}
      </StyledVoucherCodeVerifyWrapper>

      <StyledInvalidCodeError>
        {invalidCodeErrorStatus && (
          <FormattedMessage
            id="dashboard::voucher::invalidCode"
            defaultMessage="Invalid Code"
          />
        )}
      </StyledInvalidCodeError>
    </StyledContainer>
  )
}

VoucherCodeVerify.propTypes = {
  voucherCodeParam: PropTypes.string,
}

export default VoucherCodeVerify
