import React from "react"
import { Button } from "@tmu/components/common"
import { StyledCategoryItem } from "./index.styles"
import { getValueForLocale } from "@tmu/utils/string"
import { useIntl } from "gatsby-plugin-intl"

import { faXmark } from "@fortawesome/free-solid-svg-icons/faXmark"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const CategoryList = ({ categories, selected = [], onSelect, ...rest }) => {
  const { locale, defaultLocale } = useIntl()

  if (!categories) {
    return <></>
  }

  return categories?.map((category) => {
    const { id, icon, slug } = category

    return (
      <StyledCategoryItem key={`${id}-${slug}`} {...rest}>
        <Button
          label="categoryList"
          color="blue"
          variant="pill"
          onClick={() => onSelect(id, slug)}
          data-id={id}
          className={
            selected.findIndex((item) => item === slug) > -1 ? "active" : ""
          }>
          {icon && <i className={icon} />}
          {getValueForLocale(category, "name", locale, defaultLocale)}
          {selected.findIndex((item) => item === slug) > -1 && (
            <FontAwesomeIcon icon={faXmark} />
          )}
        </Button>
      </StyledCategoryItem>
    )
  })
}
export default CategoryList
