import React, { useEffect, useState } from "react"
import ReactModal from "react-modal"
import { faXmark } from "@fortawesome/free-solid-svg-icons/faXmark"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "@tmu/components/common"
import {
  StyledHeader,
  StyledText,
  modalStyles,
  StyledModalActions,
  StyledCloseIcon,
  StyledHeaderWrapper,
} from "./index.styles"
import { isBrowser } from "@tmu/utils/auth"

const CustomModal = ({
  isModalOpen,
  hideParentPageOnMobile,
  handleModalClose,
  header,
  text,
  confirmButtonText,
  confirmButtonAction,
  confirmButtonColor,
  cancelButtonText,
  cancelButtonAction,
  children,
  isCloseIcon,
  isCloseXButton,
  isCloseBackButton,
  isMobile,
  isFull,
  style,
  headerStyle,
  headerTextStyle,
  descriptionStyle,
  stickyHeader,
  isWithoutHeader,
  isStickyModalActions = false,
  modalActionsStyle,
  closeIconClassName,
  closeIconStyle,
  scrollTopAfterClose = false,
  ...rest
}) => {
  if (isMobile === true || isFull) {
    modalStyles.content.width = "100%"
    modalStyles.content.height = "100%"
  } else {
    modalStyles.content.width = ""
    modalStyles.content.height = ""
  }

  if (isFull) {
    modalStyles["maxWidth"] = "none"
  }

  const [overflowYStyle, setOverflowYStyle] = useState(false)

  useEffect(() => {
    if (isModalOpen && isMobile && hideParentPageOnMobile) {
      const el = document.getElementById("gatsby-focus-wrapper")
      if (el) el.style.display = "none"
    }

    if (isModalOpen) {
      document.body.style.overflowY = "hidden"
    }

    return () => {
      const el = document.getElementById("gatsby-focus-wrapper")
      if (el) el.style.display = "block"
    }
  }, [isModalOpen, isMobile, hideParentPageOnMobile])

  useEffect(() => {
    if (isBrowser && isModalOpen && isMobile) {
      setOverflowYStyle(true)
    }
  }, [overflowYStyle])

  const callAfterClose = () => {
    if (isBrowser && isModalOpen && isMobile) {
      setOverflowYStyle(false)
    }
    document.body.style.overflowY = "unset"
    if (scrollTopAfterClose) {
      setTimeout(() => {
        window?.scrollTo(0, 0)
      }, 100)
    }
  }

  return (
    <ReactModal
      isOpen={isModalOpen}
      style={{
        content: { ...modalStyles.content, maxWidth: "none", ...style },
      }}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      onRequestClose={handleModalClose}
      {...rest}>
      {isWithoutHeader !== true && (
        <>
          <StyledHeaderWrapper
            stickyHeader={stickyHeader}
            style={{ ...headerStyle }}
            data-testid="modal-header">
            {isCloseIcon === true &&
              (isMobile || isCloseBackButton) &&
              !isCloseXButton && (
                <StyledCloseIcon
                  onClick={() => {
                    if (typeof cancelButtonAction === "function") {
                      cancelButtonAction()
                    }

                    callAfterClose()
                  }}
                  className={`mobile-icon ${closeIconClassName}`}
                  style={closeIconStyle}>
                  <FontAwesomeIcon icon={faArrowLeft} style={closeIconStyle} />
                </StyledCloseIcon>
              )}
            <StyledHeader
              className={isMobile ? "mobile-header" : ""}
              style={{ ...headerTextStyle }}>
              {header}
            </StyledHeader>
            {isCloseIcon === true &&
              (!isMobile || isCloseXButton) &&
              !isCloseBackButton && (
                <StyledCloseIcon
                  onClick={() => {
                    if (typeof cancelButtonAction === "function") {
                      cancelButtonAction()
                    }

                    callAfterClose()
                  }}
                  className={closeIconClassName}
                  style={closeIconStyle}>
                  <FontAwesomeIcon icon={faXmark} style={closeIconStyle} />
                </StyledCloseIcon>
              )}
          </StyledHeaderWrapper>
          {text && (
            <StyledText style={{ ...descriptionStyle }}>{text}</StyledText>
          )}
        </>
      )}
      {children}
      <StyledModalActions
        style={{ ...modalActionsStyle }}
        className={isStickyModalActions && "sticky-bottom-bar"}>
        {cancelButtonText && (
          <Button
            variant="outlined"
            color="transparent"
            onClick={() => {
              if (typeof cancelButtonAction === "function") {
                cancelButtonAction()
              }

              callAfterClose()
            }}
            data-testid="btn-cancel">
            {cancelButtonText}
          </Button>
        )}
        {confirmButtonText && (
          <Button
            color={confirmButtonColor || "blue"}
            onClick={() => {
              if (typeof confirmButtonAction === "function") {
                confirmButtonAction()
              }

              callAfterClose()
            }}
            data-testid="btn-confirm">
            {confirmButtonText}
          </Button>
        )}
      </StyledModalActions>
    </ReactModal>
  )
}
export default CustomModal
