import React, { useEffect, useContext } from "react"
import { FormattedMessage, useIntl, navigate } from "gatsby-plugin-intl"
import { Button, LogoText } from "@tmu/components/common"
import FooterSectionsDesktop from "./FooterSectionsDesktop.js"
import SocialLinks from "./SocialLinks"
import {
  StyledFooterButton,
  StyledFooterCopyRight,
  StyledFooterLogo,
  StyledFooterWrapper,
} from "./index.styles"
import theme from "@tmu/global/theme"
import LanguageBar from "./LanguageBar.js"
import { DetailFooterContainer } from "@tmu/global/page-addons/detail-page.styles"
import { HomePageContext } from "@tmu/context/homePageContext"
import { FOOTER_TYPE } from "@tmu/apollo/constants.js"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"

const FooterDesktop = ({
  exploreSection,
  aboutSection,
  joinTMUSection,
  supportEmail,
}) => {
  const { formatMessage } = useIntl()
  const { footerType } = useContext(HomePageContext)
  const email = supportEmail || "support@trustmeup.com"
  const { isTablet, isWide } = getAllScreenTypes()

  const handleLogoClick = () => {
    navigate("/")
  }

  useEffect(() => {}, [footerType])

  const copyright = (
    <StyledFooterCopyRight className="copyright-section">
      <FormattedMessage
        id="footer::copyright::copyright"
        defaultMessage="Copyright {year}"
        values={{
          year: new Date().getFullYear(),
        }}
        tagName="span"
      />
      <FormattedMessage
        id="footer::copyright::tmu"
        defaultMessage="Trustmeup Ireland Limited"
        tagName="span"
      />
      <span>VAT 3804385KH</span>
      <br />
    </StyledFooterCopyRight>
  )

  const footerClass = isWide
    ? "footer-wide"
    : footerType === FOOTER_TYPE.WIDE && isTablet
    ? "detail-page-footer"
    : ""

  return (
    <DetailFooterContainer>
      <StyledFooterWrapper className={footerClass} data-testid="footer">
        <>
          <StyledFooterLogo>
            <Button label="footerLogo" variant="text" onClick={handleLogoClick}>
              <a href="/">
                <LogoText
                  className="logo"
                  width="225"
                  height="52"
                  flat={false}
                  fill={theme?.colors?.blue}
                />
              </a>
            </Button>
            {isTablet && copyright}
          </StyledFooterLogo>
          <FooterSectionsDesktop
            dataTestId="text-joinTrustMeUp"
            id="join-tmu-section"
            className="join-tmu-section"
            title={formatMessage({
              id: "footer::joinTMU",
              defaultMessage: "Join",
            })}
            subTitles={joinTMUSection}
          />

          <FooterSectionsDesktop
            dataTestId="text-explore"
            id="explore-section"
            className="explore-section"
            title={formatMessage({
              id: "nav::explore",
              defaultMessage: "Explore",
            })}
            subTitles={exploreSection}
          />
          <FooterSectionsDesktop
            dataTestId="text-about"
            id="about-section"
            className="about-section"
            title={formatMessage({
              id: "footer::aboutTitle",
              defaultMessage: "Us",
            })}
            subTitles={aboutSection}
          />

          <FooterSectionsDesktop
            dataTestId="text-contact"
            id="contact-section"
            className="contact-section"
            title={formatMessage({
              id: "footer::contactTitle",
              defaultMessage: "Contact",
            })}
            customContent={
              <>
                <StyledFooterButton variant="text" align="left">
                  <a href={"mailto:" + email}>{email}</a>
                </StyledFooterButton>
                <SocialLinks color={theme.colors.pacificBlue} size="1.5rem" />
                <LanguageBar />
              </>
            }
          />
          {!isTablet && (
            <FooterSectionsDesktop
              dataTestId="text-coyright"
              id="copyright-section"
              customContent={copyright}
            />
          )}
        </>
      </StyledFooterWrapper>
    </DetailFooterContainer>
  )
}
export default FooterDesktop
