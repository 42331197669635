import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import { Button, CustomModal, ShowQRCode } from "@tmu/components/common"
import { modalStyles } from "@tmu/global/GlobalStyle"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import theme from "@tmu/global/theme"
import { StyledModalContent, StyledModalButton } from "./index.styles"

export const ReferralCodeModal = ({
  isModalOpen,
  modalType,
  svgSource,
  handleModalAction,
  handleModalClose,
}) => {
  const { isTablet } = getAllScreenTypes()

  const modalHeader = (
    <FormattedMessage
      id={`dashboard::referralCodes::${
        modalType === "delete"
          ? "deleteReferralCodeTitle"
          : modalType === "stop"
          ? "stopReferralCodeTitle"
          : modalType === "leave"
          ? "leaveReferralCodeTitle"
          : "qrReferralCodeTitle"
      }`}
      defaultMessage={
        modalType === "delete"
          ? "Delete referral"
          : modalType === "stop"
          ? "Stop referral"
          : modalType === "leave"
          ? "Sure, you want to leave?"
          : "QR Code"
      }
      tagName={!isTablet ? "p" : null}
    />
  )

  return (
    <CustomModal
      isModalOpen={isModalOpen}
      style={{
        ...modalStyles,
        content: {
          ...modalStyles.content,
          border: "unset",
        },
      }}
      headerTextStyle={
        isTablet && {
          fontFamily: theme.fontFamilies.headline,
          fontWeight: theme.fontWeights.semiBolder,
          fontStyle: theme.fontStyles.normal,
          color: theme?.colors.pitchBlack,
          fontSize: "1.5rem",
          lineHeight: "normal",
        }
      }
      header={isTablet ? modalHeader : null}
      descriptionStyle={{ marginBottom: "unset" }}
      isCloseIcon={true}
      isCloseXButton={isTablet ? true : false}
      isCloseBackButton={!isTablet ? true : false}
      cancelButtonAction={() => handleModalClose()}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      isMobile={!isTablet}
      isFull={!isTablet}>
      <StyledModalContent className={!isTablet ? "mobile" : "not-mobile"}>
        {!isTablet && modalHeader}
        {modalType !== "qrCode" ? (
          <FormattedMessage
            id={`dashboard::referralCodes::${
              modalType === "delete"
                ? "deleteReferralCodeText"
                : modalType === "stop"
                ? "stopReferralCodeText"
                : modalType === "leave"
                ? "leaveReferralCodeText"
                : ""
            }`}
            defaultMessage={
              modalType === "delete"
                ? "You will lose all the track of the referral code usage."
                : modalType === "stop"
                ? "Stop tracking referral code usage?"
                : modalType === "leave"
                ? "If you leave, referral code progress will be lost."
                : ""
            }
            tagName="span"
          />
        ) : (
          <ShowQRCode svgSource={svgSource} />
        )}
      </StyledModalContent>
      {modalType !== "qrCode" && (
        <StyledModalButton className={!isTablet ? "mobile" : "not-mobile"}>
          <Button
            color="blue"
            type="button"
            label="referral-codes-action"
            onClick={handleModalAction}
            data-testid="btn-referral-codes-action">
            <FormattedMessage
              id={`dashboard::referralCodes::${
                modalType === "delete"
                  ? "deleteReferralCode"
                  : modalType === "stop"
                  ? "stopReferralCode"
                  : "leaveReferralCode"
              }`}
              defaultMessage={
                modalType === "delete"
                  ? "Delete referral"
                  : modalType === "stop"
                  ? "Stop referral"
                  : "Leave"
              }
            />
          </Button>
        </StyledModalButton>
      )}
    </CustomModal>
  )
}
