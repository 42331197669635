import React from "react"
import { FormattedMessage, navigate } from "gatsby-plugin-intl"
import { Button } from "@tmu/components/common"
import {
  StyledWwsContent,
  StyledWwsContentText,
  StyledWwsContentSubtext,
  StyledWwsImagesRight,
  StyledWwsImageSmall,
  StyledWwsImageBig,
  StyledWwsImageMedium,
  StyledWwsContentLeft,
} from "./index.styles"
import { LazyImage } from "@tmu/components/common"

const { CLOUDFLARE_IMAGE_URL } = process.env

export const SliceWhoWeServe = ({ userType }) => {
  const handleClick = () => {
    const target =
      userType === "partner"
        ? "non-profit"
        : userType === "donor"
        ? "donors"
        : "merchants"
    navigate(`/join/${target}`)
  }

  switch (userType) {
    case "donor":
      return (
        <StyledWwsContent
          id="donor"
          className="tabcontent"
          data-testid="start-here-donor">
          <StyledWwsContentLeft>
            <StyledWwsContentText>
              <FormattedMessage
                id="whoWeServe::donors::tabContent"
                defaultMessage="With TrustMeUp, donors get a unique possibility to choose a distinct non-profit cause to support."
              />
            </StyledWwsContentText>
            <StyledWwsContentSubtext>
              <FormattedMessage
                id="whoWeServe::donors::tabImageText"
                defaultMessage="Get rewarded for doing good as never before."
              />
            </StyledWwsContentSubtext>
            <Button
              className="wws-tab-content__button"
              label="Start Donating"
              color="red"
              onClick={handleClick}>
              <FormattedMessage
                id="whoWeServe::donors::buttonText"
                defaultMessage="Start Donating"
              />
            </Button>
          </StyledWwsContentLeft>
          <StyledWwsImagesRight>
            <StyledWwsImageBig>
              <LazyImage
                src={
                  CLOUDFLARE_IMAGE_URL +
                  "/static/assets/images/users/blue-team.jpg"
                }
                altName={`slice-who-we-serve-blue-team`}
                width="312"
                height="227"
                fit="cover"
              />
            </StyledWwsImageBig>
            <StyledWwsImageSmall>
              <LazyImage
                src={
                  CLOUDFLARE_IMAGE_URL +
                  "/static/assets/images/users/donors-square.jpg"
                }
                altName={`slice-who-we-serve-donors-square`}
                width="170"
                height="156"
                fit="cover"
              />
            </StyledWwsImageSmall>

            <StyledWwsImageMedium>
              <LazyImage
                src={
                  CLOUDFLARE_IMAGE_URL +
                  "/static/assets/images/users/heart-in-hands.jpg"
                }
                altName={`slice-who-we-serve-heart-in-hands`}
                width="255"
                height="156"
                fit="cover"
              />
            </StyledWwsImageMedium>
          </StyledWwsImagesRight>
        </StyledWwsContent>
      )
    case "partner":
      return (
        <StyledWwsContent
          id="partner"
          className="tabcontent"
          data-testid="start-here-partner">
          <StyledWwsContentLeft>
            <StyledWwsContentText>
              <FormattedMessage
                id="whoWeServe::nonProfits::tabContent"
                defaultMessage="Through the TrustMeUp web platform non-profit organizations can promote one or more of their projects and create the dedicated fundraising accounts."
              />
            </StyledWwsContentText>

            <StyledWwsContentSubtext>
              <FormattedMessage
                id="whoWeServe::nonProfits::tabImageText"
                defaultMessage="TrustMeUp rewards the supporters and increases their feeling of trust and belonging, resulted from the donations transparency and tangibility."
              />
            </StyledWwsContentSubtext>
            <Button
              className="wws-tab-content__button"
              label="Start Fundraising"
              color="red"
              onClick={handleClick}>
              <FormattedMessage
                id="whoWeServe::nonProfits::buttonText"
                defaultMessage="Start Fundraising"
              />
            </Button>
          </StyledWwsContentLeft>
          <StyledWwsImagesRight>
            <StyledWwsImageBig>
              <LazyImage
                src={
                  CLOUDFLARE_IMAGE_URL +
                  "/static/assets/images/users/non-profit-landscape-medium.jpg"
                }
                altName={`slice-who-we-serve-non-profit-landscape-medium`}
                width="312"
                height="227"
                fit="cover"
              />
            </StyledWwsImageBig>
            <StyledWwsImageSmall>
              <LazyImage
                src={
                  CLOUDFLARE_IMAGE_URL +
                  "/static/assets/images/users/soccer-team.jpg"
                }
                altName={`slice-who-we-serve-soccer-tea`}
                width="170"
                height="156"
                fit="cover"
              />
            </StyledWwsImageSmall>

            <StyledWwsImageMedium>
              <LazyImage
                src={
                  CLOUDFLARE_IMAGE_URL +
                  "/static/assets/images/users/non-profit-landscape-small.jpg"
                }
                altName={`slice-who-we-serve-non-profit-landscape-small`}
                width="255"
                height="156"
                fit="cover"
              />
            </StyledWwsImageMedium>
          </StyledWwsImagesRight>
        </StyledWwsContent>
      )
    case "merchant":
      return (
        <StyledWwsContent
          id="merchant"
          data-testid="start-here-merchant"
          className="tabcontent"
          name="start-here-merchant">
          <StyledWwsContentLeft>
            <StyledWwsContentText>
              <FormattedMessage
                id="whoWeServe::merchants::tabContent"
                defaultMessage="TrustMeUp web platform gives access to the powerful advertising engine with the possibility to showcase unique deals to the supporters who actively participate in social communities."
              />
            </StyledWwsContentText>

            <StyledWwsContentSubtext>
              <FormattedMessage
                id="whoWeServe::merchants::tabImageText"
                defaultMessage="Boost your business doing good thanks to the TrustMeUp marketplace."
              />
            </StyledWwsContentSubtext>
            <Button
              className="wws-tab-content__button"
              label="Start Selling"
              color="red"
              onClick={handleClick}>
              <FormattedMessage
                id="whoWeServe::merchants::buttonText"
                defaultMessage="Start Selling"
              />
            </Button>
          </StyledWwsContentLeft>
          <StyledWwsImagesRight>
            <StyledWwsImageBig>
              <LazyImage
                src={
                  CLOUDFLARE_IMAGE_URL +
                  "/static/assets/images/users/phone-store.jpg"
                }
                altName={`slice-who-we-serve-phone-store`}
                width="312"
                height="227"
                fit="cover"
              />
            </StyledWwsImageBig>
            <StyledWwsImageSmall>
              <LazyImage
                src={
                  CLOUDFLARE_IMAGE_URL +
                  "/static/assets/images/users/tech-store.jpg"
                }
                altName={`slice-who-we-serve-tech-store`}
                width="170"
                height="156"
                fit="cover"
              />
            </StyledWwsImageSmall>

            <StyledWwsImageMedium>
              <LazyImage
                src={
                  CLOUDFLARE_IMAGE_URL +
                  "/static/assets/images/users/tablet.jpg"
                }
                altName={`slice-who-we-serve-tablet`}
                width="255"
                height="156"
                fit="cover"
              />
            </StyledWwsImageMedium>
          </StyledWwsImagesRight>
        </StyledWwsContent>
      )
    default:
      break
  }
}
