import React, { useState } from "react"
import PropTypes from "prop-types"
import { Formik, FastField } from "formik"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import {
  TextInput,
  FieldError,
  Tabs,
  TabList,
  Panel,
  Button,
} from "@tmu/components/common"
import { Tab } from "@tmu/components/dashboard/dashboardCommon"
import {
  StyledPageContent,
  StyledForm,
} from "@tmu/global/page-addons/dashboard.styles"
import * as Yup from "yup"
import { title, description } from "@tmu/utils/validation"

function Faq({ isEditPage, handleSubmitForm, selectedFaq }) {
  const { formatMessage } = useIntl()
  const [tabIndex, setTabIndex] = useState(0)
  const [activeLangs, setActiveLangs] = useState({
    english: !!(selectedFaq?.questionEn || selectedFaq?.question),
    italian: !!selectedFaq?.questionIt,
    spanish: !!selectedFaq?.questionEs,
  })
  const initialValues = {
    id: selectedFaq?.id || null,
    questionTabEn: selectedFaq?.questionEn || selectedFaq?.question || "",
    questionTabIt: selectedFaq?.questionIt || "",
    questionTabEs: selectedFaq?.questionEs || "",
    answerTabEn: selectedFaq?.answerEn || selectedFaq?.answer || "",
    answerTabIt: selectedFaq?.answerIt || "",
    answerTabEs: selectedFaq?.answerEs || "",
  }
  const validationSchema = Yup.object().shape({
    questionTabEn:
      activeLangs.english &&
      title({
        formatMessage,
      }),
    answerTabEn:
      activeLangs.english &&
      description({
        formatMessage,
      }),
    questionTabIt:
      activeLangs.italian &&
      title({
        formatMessage,
      }),
    answerTabIt:
      activeLangs.italian &&
      description({
        formatMessage,
      }),
    questionTabEs:
      activeLangs.spanish &&
      title({
        formatMessage,
      }),
    answerTabEs:
      activeLangs.spanish &&
      description({
        formatMessage,
      }),
  })

  const CampaignFAQTitle = formatMessage({
    id: "dashboard::campaignFAQ::question",
    defaultMessage: "Question",
  })
  const CampaignFAQContent = formatMessage({
    id: "dashboard::campaignFAQ::answer",
    defaultMessage: "Answer",
  })
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmitForm}>
      {({
        isSubmitting,
        values,
        setFieldValue,
        resetForm,
        handleSubmit,
        handleChange,
      }) => (
        <StyledForm className="modal-form">
          {isEditPage ? (
            <FormattedMessage
              id="dashboard::campaigns::createCampaignFAQEdit"
              defaultMessage="Edit Campaign FAQ"
              tagName="h5"
            />
          ) : (
            <FormattedMessage
              id="dashboard::campaigns::createCampaignFAQ"
              defaultMessage="Create Campaign FAQ"
              tagName="h5"
            />
          )}
          <StyledPageContent>
            <Tabs
              selectedIndex={tabIndex}
              onSelect={(index) => setTabIndex(index)}>
              <TabList>
                <Tab
                  isActive={tabIndex === 0}
                  activeLangs={activeLangs}
                  setActiveLangs={setActiveLangs}>
                  <FormattedMessage
                    id="dashboard::campaignForm::English"
                    defaultMessage="English"
                  />
                </Tab>
                <Tab
                  isActive={tabIndex === 1}
                  activeLangs={activeLangs}
                  setActiveLangs={setActiveLangs}>
                  <FormattedMessage
                    id="dashboard::campaignForm::Italian"
                    defaultMessage="Italian"
                  />
                </Tab>
                <Tab
                  isActive={tabIndex === 2}
                  activeLangs={activeLangs}
                  setActiveLangs={setActiveLangs}>
                  <FormattedMessage
                    id="dashboard::campaignForm::Spanish"
                    defaultMessage="Spanish"
                  />
                </Tab>
              </TabList>
              <Panel>
                <FastField
                  id="questionTabEn"
                  name="questionTabEn"
                  value={values.questionTabEn}
                  component={TextInput}
                  label={CampaignFAQTitle}
                  className="float-container"
                  placeholder=""
                  onChange={handleChange}
                />
                <FieldError name="questionTabEn" />
                <FastField
                  id="answerTabEn"
                  name="answerTabEn"
                  label={CampaignFAQContent}
                  type="textarea"
                  component={TextInput}
                  className="float-container info-area full-width"
                  value={values.answerTabEn}
                  onChange={handleChange}
                  placeholder=""
                />
                <FieldError name="answerTabEn" />
              </Panel>
              <Panel>
                <FastField
                  id="questionTabIt"
                  name="questionTabIt"
                  value={values.questionTabIt}
                  component={TextInput}
                  label={CampaignFAQTitle}
                  className="float-container"
                  placeholder=""
                  onChange={handleChange}
                />
                <FieldError name="questionTabIt" />
                <FastField
                  id="answerTabIt"
                  name="answerTabIt"
                  label={CampaignFAQContent}
                  type="textarea"
                  component={TextInput}
                  className="float-container info-area full-width"
                  value={values.answerTabIt}
                  onChange={handleChange}
                  placeholder=""
                />
                <FieldError name="answerTabIt" />
              </Panel>
              <Panel>
                <FastField
                  id="questionTabEs"
                  name="questionTabEs"
                  value={values.questionTabEs}
                  component={TextInput}
                  label={CampaignFAQTitle}
                  className="float-container"
                  placeholder=""
                  onChange={handleChange}
                />
                <FieldError name="questionTabEs" />
                <FastField
                  id="answerTabEs"
                  name="answerTabEs"
                  label={CampaignFAQContent}
                  type="textarea"
                  component={TextInput}
                  className="float-container info-area full-width"
                  value={values.answerTabEs}
                  onChange={handleChange}
                  placeholder=""
                />
                <FieldError name="answerTabEs" />
              </Panel>
            </Tabs>
            <Button
              type="submit"
              label="Save"
              data-testid="btn-save"
              onClick={handleSubmit}>
              <FormattedMessage
                id="dashboard::campaigns::save"
                defaultMessage="Save"
              />
            </Button>
          </StyledPageContent>
        </StyledForm>
      )}
    </Formik>
  )
}

export default Faq

Faq.propTypes = {
  activeLangs: PropTypes.object,
  setActiveLangs: PropTypes.func,
  isEditPage: PropTypes.bool,
}
