import { gql } from "@apollo/client"

export const CREATE_USER_TRACKING_REFERRAL_WITH_CAMPAIGN_CONDITIONS_MUTATION = gql`
  mutation CreateUserTrackingReferralWithCampaignConditionsMutation(
    $input: CreateUserTrackingReferralMutationInput!
  ) {
    createUserTrackingReferral(input: $input) {
      referral {
        id
        code
        name
        maxUsage
        usageCount
        isUnlimited
        validUntil
        referralUrl
        isActive
        relatedId
        conditions(first: 30) {
          edges {
            node {
              campaign {
                id
                slug
                name
                partner {
                  id
                  slug
                  name
                }
              }
            }
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }
`

export const CREATE_USER_TRACKING_REFERRAL_WITH_OFFER_CONDITIONS_MUTATION = gql`
  mutation CreateUserTrackingReferralWithOfferConditionsMutation(
    $input: CreateUserTrackingReferralMutationInput!
  ) {
    createUserTrackingReferral(input: $input) {
      referral {
        id
        code
        name
        maxUsage
        usageCount
        isUnlimited
        validUntil
        referralUrl
        isActive
        relatedId
        conditions(first: 30) {
          edges {
            node {
              offer {
                id
                slug
                name
                store {
                  id
                  slug
                  name
                }
              }
            }
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }
`

export const DELETE_REFERRAL_MUTATION = gql`
  mutation DeleteReferralMutation($input: DeleteReferralMutationInput!) {
    deleteReferral(input: $input) {
      success
      errors {
        field
        messages
      }
    }
  }
`

export const DUPLICATE_REFERRAL_WITH_CAMPAIGN_CONDITIONS_MUTATION = gql`
  mutation DuplicateReferralWithCampaignConditionsMutation(
    $input: DuplicateReferralMutationInput!
  ) {
    duplicateReferral(input: $input) {
      referral {
        id
        code
        name
        maxUsage
        usageCount
        isUnlimited
        validUntil
        referralUrl
        isActive
        relatedId
        conditions(first: 30) {
          edges {
            node {
              campaign {
                id
                slug
                name
                partner {
                  id
                  slug
                  name
                }
              }
            }
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }
`

export const DUPLICATE_REFERRAL_WITH_OFFER_CONDITIONS_MUTATION = gql`
  mutation DuplicateReferralWithOfferConditionsMutation(
    $input: DuplicateReferralMutationInput!
  ) {
    duplicateReferral(input: $input) {
      referral {
        id
        code
        name
        maxUsage
        usageCount
        isUnlimited
        validUntil
        referralUrl
        isActive
        relatedId
        conditions(first: 30) {
          edges {
            node {
              offer {
                id
                slug
                name
                store {
                  id
                  slug
                  name
                }
              }
            }
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }
`

export const UPDATE_USER_TRACKING_REFERRAL_WITH_CAMPAIGN_CONDITIONS_MUTATION = gql`
  mutation UpdateUserTrackingReferralWithCampaignConditionsMutation(
    $input: UpdateUserTrackingReferralMutationInput!
  ) {
    updateUserTrackingReferral(input: $input) {
      referral {
        id
        code
        name
        maxUsage
        usageCount
        isUnlimited
        validUntil
        referralUrl
        isActive
        relatedId
        conditions(first: 30) {
          edges {
            node {
              campaign {
                id
                slug
                name
                partner {
                  id
                  slug
                  name
                }
              }
            }
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }
`

export const UPDATE_USER_TRACKING_REFERRAL_WITH_OFFER_CONDITIONS_MUTATION = gql`
  mutation UpdateUserTrackingReferralWithOfferConditionsMutation(
    $input: UpdateUserTrackingReferralMutationInput!
  ) {
    updateUserTrackingReferral(input: $input) {
      referral {
        id
        code
        name
        maxUsage
        usageCount
        isUnlimited
        validUntil
        referralUrl
        isActive
        relatedId
        conditions(first: 30) {
          edges {
            node {
              offer {
                id
                slug
                name
                store {
                  id
                  slug
                  name
                }
              }
            }
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }
`
