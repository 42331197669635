import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import PropTypes from "prop-types"
import React from "react"
import Card from "./Card"

import {
  StyledBrandsCardContainer,
  StyledCampaignCardContainer,
  StyledCardContainer,
  StyledSlider,
} from "./index.styles"
import { getValueForLocale } from "@tmu/utils/string"

const CardList = ({
  variant,
  items = [],
  maxCols,
  showFeatured,
  dataTestId = "card-container",
  slider,
}) => {
  const { locale, defaultLocale, formatMessage } = useIntl()
  const sliderSettings = {
    dots: false,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: items?.length > 4 ? 4 : items?.length,
    infinite: items?.length > 4 ? true : false,
    slidesToScroll: 1,
    lazyLoad: true,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  }
  const renderList = (variant) => {
    switch (variant) {
      case "campaign":
        const firstFeatured = items
          .map(({ node }) => node)
          .find((c) => c.isFeatured)
        return items
          .map(({ node }) => node)
          .map((campaign, index) => ({
            ...campaign,
            index: index,
            variant: "campaign",
            buttonLink: `/campaigns/${campaign?.slug}`,
            categories: campaign.categories
              ? campaign.categories?.edges.map(({ node }) => node)
              : [],
            name: getValueForLocale(campaign, "name", locale, defaultLocale),
            shortDescription: getValueForLocale(
              campaign,
              "shortDescription",
              locale,
              defaultLocale
            ),
            isFeatured: showFeatured && campaign.id === firstFeatured?.id,
          }))
          .map((campaign, index) => (
            <Card
              key={campaign.id}
              index={index}
              status={campaign?.status}
              isAvailable={campaign?.isAvailable}
              {...campaign}
            />
          ))

      case "merchant":
        return items
          .map(({ node }) => node)
          .map((store, index) => ({
            ...store,
            index: index,
            key: store.id,
            variant: "merchant",
            image: store.image || store.logo,
            buttonLink: `/offers/store/${store?.slug}`,
            name: store.name,
            shortDescription: getValueForLocale(
              store,
              "shortDescription",
              locale,
              defaultLocale
            ),
            maxPacDiscount: store?.maxPacDiscountInOffers || 0,
          }))
          .map((store) => <Card key={store.id} {...store} />)

      case "partner":
        return items
          .map(({ node }) => node)
          .map((partner, index) => ({
            ...partner,
            index: index,
            variant: "partner",
            name: getValueForLocale(partner, "name", locale, defaultLocale),
            shortDescription: getValueForLocale(
              partner,
              "shortDescription",
              locale,
              defaultLocale
            ),
            buttonLink: partner.slug && `/charities/${partner.slug}`,
            image: getValueForLocale(partner, "logo", locale, defaultLocale),
            title:
              partner.displayName?.length > 0
                ? partner.displayName
                : partner.name,
          }))

          .map((partner) => <Card key={partner.id} {...partner} />)

      case "brand":
        return items
          .map(({ node }) => node)
          .map((partner, index) => ({
            ...partner,
            index: index,
            variant: "brand",
            buttonText: formatMessage({
              id: `partnerCard::actionType::TM`,
              defaultMessage: "Read More",
            }),
            name: getValueForLocale(partner, "name", locale),
            shortDescription: getValueForLocale(
              partner,
              "shortDescription",
              locale,
              defaultLocale
            ),
            buttonLink: partner.slug && `/charities/${partner.slug}`,
          }))
          .map((partner) => <Card key={partner.id} {...partner} />)

      case "offer":
        return items
          .map(({ node }) => node)
          .map((offer, index) => ({
            ...offer,
            index: index,
            key: offer.id,
            variant: "offer",
            image: offer.image,
            buttonLink: `/offers/detail/${offer.slug}`,
            name: offer.name, // getValueForLocale(offer, "name", locale, defaultLocale),
            shortDescription: getValueForLocale(
              offer,
              "shortDescription",
              locale,
              defaultLocale
            ),
            maxPacDiscount:
              offer?.maxPacDiscount ?? offer?.store?.maxPacDiscount,
          }))
          .map((offer) => <Card key={offer.id} {...offer} />)

      default:
        throw new Error("Unknown card type")
    }
  }

  const Container =
    variant === "campaign"
      ? StyledCampaignCardContainer
      : variant === "brand"
      ? StyledBrandsCardContainer
      : StyledCardContainer

  return slider ? (
    <StyledSlider {...sliderSettings}>{renderList(variant)}</StyledSlider>
  ) : (
    <Container
      data-testid={dataTestId}
      className="card-container"
      maxCols={maxCols}
      itemCount={items?.length}
      showFeatured={showFeatured}>
      {renderList(variant)}
    </Container>
  )
}

CardList.propTypes = {
  variant: PropTypes.oneOf([
    "campaign",
    "store",
    "partner",
    "offer",
    "brand",
    "merchant",
    "service",
    "offlineStore",
    "summary",
  ]).isRequired,
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  maxCols: PropTypes.number,
  showFeatured: PropTypes.bool,
}

CardList.defaultProps = {
  variant: "campaign",
  items: [],
  showFeatured: false,
}

export default CardList
