import styled from "@emotion/styled"
import { Button } from "@tmu/components/common"

export const StyledBlogJoiningContainer = styled.div`
  display: grid;
  grid-column: 1fr;
  grid-gap: 1rem;
  margin: 2.5rem 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-auto-flow: column;
    margin: 2.5rem 1rem;
    grid-gap: 0 1rem;

    margin: 3.375rem auto 0;
    padding: 0 1.5rem;
    max-width: ${(p) => p.theme.breakpoints.tablet};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin: 3rem auto 0;
    padding: 0 2rem;
    grid-gap: 0 1.5rem;
    max-width: ${(p) => p.theme.breakpoints.desktop};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    grid-gap: 1rem 2rem;
    margin: 5rem auto 0;
    padding: 0 6rem;
    grid-gap: 0 2rem;
    max-width: ${(p) => p.theme.breakpoints.wide};
  }
`

export const StyledBlogTitle = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-style: ${(p) => p.theme.fontStyles.normal};
  color: ${(p) => p.theme.colors.pitchBlack};
  font-size: 1rem;
  line-height: 1.5rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
    line-height: 2.625rem;
  }
`

export const StyledBlogContent = styled.div`
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  font-style: ${(p) => p.theme.fontStyles.normal};
  color: ${(p) => p.theme.colors.pitchBlack};
  font-size: 0.75rem;
  line-height: 1.125rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1rem;
    line-height: 1.375rem;

    margin-bottom: 2.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
`

export const StyledBlogButton = styled(Button)`
  span {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-style: ${(p) => p.theme.fontStyles.normal};
    color: ${(p) => p.theme.colors.blue};
  }

  font-size: 1rem;
  line-height: 1.5rem;
  height: fit-content;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-top: 0;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    p {
      font-size: 1.25rem;
    }
  }
`

export const StyledJoining = styled.div`
  display: grid;
  grid-template-rows: repeat(3, 1fr);

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
  }
`

export const StyledJoiningTitle = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-style: ${(p) => p.theme.fontStyles.normal};
  color: ${(p) => p.theme.colors.pitchBlack};
  font-size: 1rem;
  line-height: 1.5rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
    line-height: 2.625rem;
  }
`

export const StyledJoiningContent = styled.div`
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  font-style: ${(p) => p.theme.fontStyles.normal};
  color: ${(p) => p.theme.colors.pitchBlack};
  font-size: 0.75rem;
  line-height: 1.125rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1rem;
    line-height: 1.375rem;

    margin-bottom: 2.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
`

export const StyledJoiningButton = styled(Button)`
  span {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-style: ${(p) => p.theme.fontStyles.normal};
    color: ${(p) => p.theme.colors.blue};
  }

  font-size: 1rem;
  line-height: 1.5rem;
  height: fit-content;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-top: 0;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    p {
      font-size: 1.25rem;
    }
  }
`
