import { gql } from "@apollo/client"
import {
  partnerCampaignListingParts,
  partnerCampaignListingReportParts,
  partnerCampaignDetailPartsForPartner,
} from "@tmu/apollo/dashboard/fragments/campaign"

export const PARTNER_CAMPAIGN_LISTING_QUERY = gql`
  query PartnerCampaignListing(
    $first: Int
    $after: String
    $isSupporter: Boolean
    $supporter: [String]
    $visibilityStatus: [String]
  ) {
    allCampaigns(
      first: $first
      after: $after
      supporterType: $supporter
      isSupporter: $isSupporter
      orderBy: "-created"
      visibilityStatus: $visibilityStatus
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          ...partnerCampaignListingParts
          image
        }
      }
    }
  }
  ${partnerCampaignListingParts}
`
export const PARTNER_SUPPORTER_CAMPAIGN_LISTING_QUERY = gql`
  query PartnerCampaignListing(
    $first: Int
    $after: String
    $isSupporter: Boolean
    $supporter: [String]
    $visibilityStatus: [String]
  ) {
    allCampaigns(
      first: $first
      after: $after
      isSupporter: $isSupporter
      supporterType: $supporter
      orderBy: "-created"
      visibilityStatus: $visibilityStatus
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          ...partnerCampaignListingParts
          image {
            url
            cache
          }
        }
      }
    }
  }
  ${partnerCampaignListingParts}
`

export const PARTNER_CAMPAIGN_LISTING_REPORT_QUERY = gql`
  query PartnerCampaignListing(
    $first: Int
    $after: String
    $status_In: [String]
    $supporter: [String]
  ) {
    allCampaigns(
      first: $first
      after: $after
      status_In: $status_In
      orderBy: "-created"
      supporterType: $supporter
    ) {
      edges {
        node {
          ...partnerCampaignListingReportParts
        }
      }
    }
  }
  ${partnerCampaignListingReportParts}
`

export const PARTNER_CAMPAIGN_DETAIL_QUERY = gql`
  query PartnerCampaignDetail($slug: String!) {
    campaign(slug: $slug) {
      ...partnerCampaignDetailPartsForPartner
    }
  }
  ${partnerCampaignDetailPartsForPartner}
`
export const MERCHANT_CAMPAIGN_DETAIL_QUERY = gql`
  query MerchantCampaignDetail($slug: String!) {
    campaign(slug: $slug) {
      id
      slug
      nameEn
      nameIt
      nameEs
      descriptionEn
      descriptionIt
      descriptionEs
      shortDescriptionEn
      shortDescriptionIt
      shortDescriptionEs
      isDefaultCampaign
      supportedCampaign {
        id
        slug
        name
        nameEn
        nameIt
        nameEs
      }
      video
      goalAmount
      fundedAmount
      donationCount
      created
      deadline
      startsAt
      campaignType
      isAvailable
      status
      isUnlisted
      visibilityStatus
      eventAddress
      eventMapLink
      eventStartsAt
      virtualEventLink
      enableDonationNote
      isVoucher
      isReservationRequired
      recurringIsEnabled
      recurringAmountOne
      recurringAmountTwo
      recurringAmountThree
      subscriptionProducts {
        edges {
          node {
            id
            description
            stripeStatementDescriptor
          }
        }
      }
      images {
        edges {
          node {
            id
            image
            order
            __typename
          }
        }
      }
      tiers {
        edges {
          node {
            id
            description
            donationAmount
            donationAmountOperator
            maxTickets
            isUnlimitedTickets
            descriptionEn
            descriptionIt
            descriptionEs
            isSoldOut
            __typename
          }
        }
      }
      partner {
        id
        taxId
        name
        logo
        logoEn
        logoEs
        logoIt
        images {
          edges {
            node {
              id
              image
              order
              __typename
            }
          }
        }
      }
    }
  }
`

export const SUPPORTER_CAMPAIGN_DETAIL_QUERY = gql`
  query PartnerCampaignDetail($slug: String!) {
    campaign(slug: $slug) {
      ...partnerCampaignDetailPartsForPartner
      supporterCause {
        id
        name
        nameEn
        nameIt
        nameEs
        nameDe
        icon
        slug
      }
      user {
        id
        displayName
      }
    }
  }
  ${partnerCampaignDetailPartsForPartner}
`

export const ALL_SUPPORTER_CAMPAIGN_CAUSES_LISTING_QUERY = gql`
  query AllSupporterCampaignCauses($first: Int, $orderBy: String) {
    allSupporterCampaignCauses(first: $first, orderBy: $orderBy) {
      edges {
        node {
          id
          name
          nameEn
          nameIt
          nameEs
          slug
          icon
        }
      }
    }
  }
`

export const PARTNER_CAMPAIGN_LISTING_BY_FUNDRAISER_TYPE_QUERY = gql`
  query PartnerCampaignListingByFundraiserType(
    $first: Int
    $after: String
    $supporterType: [String]
    $status_In: [String]
    $visibilityStatus: [String]
  ) {
    allCampaigns(
      first: $first
      after: $after
      supporterType: $supporterType
      status_In: $status_In
      visibilityStatus: $visibilityStatus
      orderBy: "-created"
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          ...partnerCampaignListingParts
          image
          store {
            id
            slug
            name
            logo
            defaultOffer {
              offerType
            }
          }
        }
      }
    }
  }
  ${partnerCampaignListingParts}
`
