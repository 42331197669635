import React from "react"
import PropTypes from "prop-types"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import AsyncSelect from "react-select/async"
import { StyledDropdownWrapper } from "./index.styles"
import { useIntl } from "gatsby-plugin-intl"

const animatedComponents = makeAnimated()

const Dropdown = ({
  variant = "default",
  defaultValue,
  isLarge,
  defaultSelectStyles = {},
  value,
  type,
  error,
  touched,
  isFormField,
  placeholder,
  ...rest
}) => {
  const { formatMessage } = useIntl()

  const placeholderTranslated =
    placeholder ||
    formatMessage({
      id: "common::select::placeholder::select",
      defaultMessage: "Select",
    })
  const defaultSelected = rest.isMulti
    ? rest?.options?.filter((item) => defaultValue?.indexOf(item.value) > -1)
    : rest?.options?.find(({ value }) => value === defaultValue)

  const dropdownTheme = {
    default: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: 16,
      borderWidth: 1,
      borderRadius: "0.25rem",
      borderColor:
        error && touched ? "rgba(233, 69, 69, 1)" : "rgba(122, 119, 118, 1)",
      "&:focus-within": { borderColor: "rgba(10, 157, 189, 1)" },
      "&:hover svg": { color: "rgba(10, 157, 189, 1)" },
      "&:hover": { borderColor: "rgba(10, 157, 189, 1)" },
    },
    pill: {
      padding: isLarge ? 4 : 0,
      paddingLeft: 4,
      fontSize: "inherit",
      borderWidth: 1,
      borderRadius: 50,
    },
    condensed: {
      padding: 1,
      fontSize: 12,
      borderWidth: 1,
      borderRadius: 12,
    },
    text: {
      padding: "inherit",
      fontSize: 16,
      borderWidth: 0,
      borderRadius: 16,
    },
  }

  const selectStyles = {
    ...defaultSelectStyles,
    singleValue: (provided, state) => ({
      ...provided,
      opacity: state.isDisabled ? 0.5 : 1,
      transition: "opacity 300ms",
      ...defaultSelectStyles?.singleValue,
    }),
    multiValue: (provided, state) => ({
      ...provided,
      ...defaultSelectStyles?.multiValue,
    }),
    container: (provided) => ({
      ...provided,
      ...defaultSelectStyles?.container,
    }),
    menu: (provided, state) => ({
      ...provided,
      padding: 0,
      zIndex: 999,
      ...defaultSelectStyles?.menu,
    }),
    menuList: (provided, state) => ({
      ...provided,
      ...defaultSelectStyles?.menuList,
    }),
    placeholder: (provided, state) => ({
      ...provided,
      ...defaultSelectStyles?.placeholder,
    }),
    control: (provided) => ({
      ...provided,
      ...dropdownTheme[variant],
      whiteSpace: "nowrap",
      boxShadow: "none",
      paddingLeft: "0.25rem",
      minWidth: rest.condensed ? "8rem" : "12rem",
      ...defaultSelectStyles?.control,
    }),
    option: (provided, state) => ({
      ...provided,
      padding: variant === "condensed" ? "0.5rem" : "1rem",
      fontSize: variant === "condensed" ? "0.85rem" : "1rem",
      textAlign: "left",
      ...defaultSelectStyles?.option,
      "&:hover": {
        backgroundColor: defaultSelectStyles?.option?.hoverColor || "",
      },
    }),
    indicatorSeparator: (provided) => ({ ...provided, display: "none" }),
    indicatorsContainer: (provided) => ({
      ...provided,
      ...defaultSelectStyles?.indicatorsContainer,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      ...defaultSelectStyles?.dropdownIndicator,
      color:
        error && touched ? "rgba(233, 69, 69, 1)" : "rgba(122, 119, 118, 1)",
    }),
    valueContainer: (provided) => ({
      ...provided,
      ...defaultSelectStyles?.valueContainer,
    }),
    input: (provided, state) => ({
      ...provided,
      ...defaultSelectStyles?.input,
    }),
  }
  const theme = (t) => ({
    ...t,
    colors: {
      ...t.colors,
      primary25: "rgba(10, 157, 189, 0.25)",
      primary50: "rgba(10, 157, 189, 0.5)",
      primary: "rgba(10, 157, 189, 1)",
    },
  })

  return (
    <StyledDropdownWrapper isFormField={isFormField}>
      {type === "asyncSelect" ? (
        <AsyncSelect
          value={value}
          styles={selectStyles}
          menuContainerStyle={{ zIndex: 999 }}
          classNamePrefix="react-select"
          theme={theme}
          placeholder={placeholderTranslated}
          {...rest}
        />
      ) : (
        <Select
          value={value || defaultSelected}
          styles={selectStyles}
          components={animatedComponents}
          menuContainerStyle={{ zIndex: 999 }}
          classNamePrefix="react-select"
          theme={theme}
          placeholder={placeholderTranslated}
          {...rest}
        />
      )}
      {error && touched ? (
        <div className="input-feedback-absolute">{error}</div>
      ) : null}
    </StyledDropdownWrapper>
  )
}

Dropdown.propTypes = {
  ...Select.propTypes,
  options: PropTypes.arrayOf(PropTypes.object),
  variant: PropTypes.oneOf(["default", "pill", "condensed", "text"]),
  placeholder: PropTypes.string, // Added prop type for placeholder
}

export default Dropdown
