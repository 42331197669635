import React, { useEffect } from "react"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { Field, useFormikContext } from "formik"
import { TextInput, FieldError, Dropdown } from "@tmu/components/common"
import { useTypeConstants } from "@tmu/hooks"
import { StyledFormSectionHeading } from "@tmu/global/page-addons/dashboard.styles"
import {
  StyledBankInfoFieldSet,
  StyledHeader,
  StyledField,
} from "./index.styles"

const BankInfoFieldSet = ({
  isPartner = false,
  setIsUKBank = () => {},
  isUKBank = false,
}) => {
  const {
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    touched,
    setTouched,
  } = useFormikContext()
  const { formatMessage } = useIntl()
  const { operatingCountries, getCountryOptions } = useTypeConstants({})

  const currentCountry = operatingCountries.find(
    (item) => item.id === values?.bankCountry
  )

  useEffect(() => {
    setIsUKBank(currentCountry?.id === "GB")
  }, [currentCountry?.id])

  return (
    <StyledBankInfoFieldSet isUKBank={isUKBank} isPartner={isPartner}>
      <StyledHeader>
        <StyledFormSectionHeading>
          <FormattedMessage
            id="dashboard::forms::bankingInfo"
            defaultMessage="Banking Info"
            tagName="h3"
          />
          <FormattedMessage
            id="dashboard::forms::bankingText"
            defaultMessage="Please provide valid banking information."
            tagName="p"
          />
        </StyledFormSectionHeading>
      </StyledHeader>

      <StyledField gridArea="legalName">
        <Field
          data-testid="input-legal-name-account"
          id="bankAccountName"
          name="bankAccountName"
          component={TextInput}
          className="float-container"
          value={values.bankAccountName}
          onChange={handleChange}
          onBlur={handleBlur}
          label={formatMessage({
            id: "dashboard::forms::bankAccountName",
            defaultMessage: "Bank Account Name",
          })}
        />
        <FieldError name="bankAccountName" />
      </StyledField>

      <StyledField gridArea="bank">
        <Field
          data-testid="input-bank-name"
          id="bankName"
          name="bankName"
          component={TextInput}
          className="float-container"
          value={values.bankName}
          onChange={handleChange}
          onBlur={handleBlur}
          label={formatMessage({
            id: "dashboard::forms::bankName",
            defaultMessage: "Bank Name",
          })}
        />
        <FieldError name="bankName" />
      </StyledField>
      <StyledField gridArea="country" data-testid="input-banking-country">
        <Dropdown
          id="bankCountry"
          name="bankCountry"
          className="select-field custom-select"
          options={getCountryOptions}
          value={{
            label:
              currentCountry?.description || currentCountry?.countryName || "",
            value: currentCountry?.id || currentCountry?.countryShortCode || "",
          }}
          onChange={(bankCountry) => {
            setFieldValue("bankCountry", bankCountry?.value, true)
            setTouched({ ...touched, bankCountry: true }, true)
          }}
          label={formatMessage({
            id: "dashboard::forms::country",
            defaultMessage: "Country",
          })}
        />
        <FieldError name="bankCountry" />
      </StyledField>
      {!isUKBank ? (
        <StyledField gridArea="iban">
          <Field
            data-testid="input-iban"
            id="bankIban"
            name="bankIban"
            component={TextInput}
            className="float-container"
            value={values.bankIban}
            onChange={handleChange}
            onBlur={handleBlur}
            label={formatMessage({
              id: "dashboard::forms::iban",
              defaultMessage: "IBAN",
            })}
          />
          <FieldError name="bankIban" />
        </StyledField>
      ) : (
        <>
          <StyledField gridArea="sortCode">
            <Field
              data-testid="input-bankSortCode"
              id="bankSortCode"
              name="bankSortCode"
              component={TextInput}
              className="float-container"
              value={values.bankSortCode}
              onChange={handleChange}
              onBlur={handleBlur}
              label={formatMessage({
                id: "dashboard::forms::bankSortCode",
                defaultMessage: "Bank Sort Code",
              })}
            />
            <FieldError name="bankSortCode" />
          </StyledField>
          <StyledField gridArea="accountNumber">
            <Field
              data-testid="input-bankAccountNumber"
              id="bankAccountNumber"
              name="bankAccountNumber"
              component={TextInput}
              className="float-container"
              value={values.bankAccountNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              label={formatMessage({
                id: "dashboard::forms::bankAccountNumber",
                defaultMessage: "Bank Account Number",
              })}
            />
            <FieldError name="bankAccountNumber" />
          </StyledField>
        </>
      )}
      {isPartner && (
        <StyledField gridArea="bic">
          <Field
            id="bankBic"
            name="bankBic"
            component={TextInput}
            className="float-container"
            value={values.bankBic}
            onChange={handleChange}
            onBlur={handleBlur}
            label={formatMessage({
              id: "dashboard::forms::bic",
              defaultMessage: "BIC",
            })}
          />
          <FieldError name="bankBic" />
        </StyledField>
      )}
    </StyledBankInfoFieldSet>
  )
}

export default BankInfoFieldSet
