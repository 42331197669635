import React from "react"
import { Router } from "@reach/router"
import CampaignListing from "./CampaignListing"
import CampaignForm from "./CampaignForm"
import CampaignUpdates from "./CampaignUpdates"
import CampaignFAQ from "./CampaignFAQ"

export default function Campaign() {
  return (
    <Router>
      <CampaignListing path="/" />
      <CampaignForm path="/create" />
      <CampaignForm path="/:slug" />
      <CampaignUpdates path="/:slug/updates/*" />
      <CampaignFAQ path="/:slug/faq/*" />
      {/* TODO: Will be re-implemented when BE API & Designs are ready */}
      {/*       <CampaignForm path="/:slug/donation" />
      <CampaignForm path="/:slug/legal" /> */}
    </Router>
  )
}
