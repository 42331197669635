import { useEffect } from "react"
import { useLazyQuery, gql } from "@apollo/client"
import { useApolloApiClients } from "@tmu/apollo/client"
import { useIntl } from "gatsby-plugin-intl"

const useTypeConstants = ({ filter, callImmediately }) => {
  const { storefrontClient } = useApolloApiClients()
  const { formatMessage, locale } = useIntl()
  const emptyOption = {
    id: "",
    description: formatMessage({
      id: "filter::all",
      defaultMessage: "All countries",
    }),
  }

  const countryFilter = filter || "SELECTED"

  const [callTypeConstants, { data, loading, called }] = useLazyQuery(
    gql`
      query TypeConstants($language: String!, $filter: CountryFilter) {
        sepaCountries: countries(language: $language, filter: $filter) {
          shortCode
          label
          numCampaigns
          numOffers
        }
      }
    `,
    {
      client: storefrontClient,
      variables: { language: locale, isPublic: true, filter: countryFilter },
      filter,
    }
  )

  useEffect(() => {
    if (callImmediately !== false) {
      if (!called) {
        callTypeConstants()
      }
    }
  }, [])

  const operatingCountries = loading
    ? []
    : data?.sepaCountries
    ? [emptyOption].concat(
        data.sepaCountries.map(
          ({ shortCode, label, numCampaigns, numOffers }) => ({
            id: shortCode,
            description: label,
            numCampaigns,
            numOffers,
          })
        )
      )
    : []

  const getCountryOptions = operatingCountries
    .filter((item) => item.id.length)
    .map((item) => ({ value: item.id, label: item.description }))

  return {
    operatingCountries,
    getCountryOptions,
    callTypeConstants,
    called,
  }
}

export default useTypeConstants
