import styled from "@emotion/styled"

export const StyledSDIMainContainer = styled.div`
  display: grid;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    grid-template-columns: 10.5rem auto 10.5rem;
    gap: 1rem;
    padding-bottom: 1.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    grid-template-columns: 14.25rem auto 14.25rem;
    gap: 1.125rem;
    padding-bottom: 2rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    grid-template-columns: 25.9375rem auto 25.9375rem;
    gap: 1.5rem;
    padding-bottom: 2.5rem;
  }

  .media-box {
    height: unset;
  }
`

export const StyledSDILeftContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledLeftBackStores = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;

  svg:last-child {
    display: none;
  }

  svg {
    margin: auto 0;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 1rem;
      height: 1rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.3125rem;
      height: 1.3125rem;
    }
  }

  span {
    color: ${(p) => p.theme.colors.pitchBlack};
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 2rem;
      line-height: 2.625rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    gap: 0.75rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    gap: 1.3125rem;
  }
`

export const StyledLeftLogoBox = styled.div`
  margin: unset;
  width: unset;

  img {
    border-radius: 0.25rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin: 2rem 0 1rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin: 2.5rem 0 2rem;
  }
`

export const StyledLeftCategoryBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  text-align: center;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    gap: 1rem;
  }
`

export const StyledLeftCategory = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(p) => p.theme.colors.whiteSmoke};
  border-radius: 0.25rem;
  width: fit-content;

  span {
    color: ${(p) => p.theme.colors.pitchBlack};
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};

    margin: auto 0;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 1rem;
      line-height: 1.375rem;

      padding: 0.375rem 0.75rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      padding: 0.375rem 1rem;

      font-size: 1.5rem;
      line-height: 2.25rem;
    }
  }

  :hover {
    background: ${(p) => p.theme.colors.carrot};
    cursor: pointer;
    span {
      color: ${(p) => p.theme.colors.white};
    }
  }
`

export const StyledLeftContacts = styled.div`
  margin: 1.5rem 0 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin: 2rem 0 1rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin: 2rem 0 1.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin: 2.875rem 0 1.5rem;
  }

  span {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.carrot};

    font-size: 1rem;
    line-height: 1.5rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.5rem;
      line-height: 2.125rem;
    }
  }
`

export const StyledLeftPhoneBox = styled.div`
  svg {
    color: ${(p) => p.theme.colors.carrot};

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 1rem;
      width: 1.125rem;
      height: 1.125rem;
      margin-right: 0.4375rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      margin-right: 0.6875rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.3125rem;
      width: 1.625rem;
      height: 1.625rem;
      margin-right: 0.8125rem;
    }
  }

  span {
    color: ${(p) => p.theme.colors.pitchBlack};
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 1rem;
      line-height: 1.5rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.5rem;
      line-height: 2.125rem;
    }
  }

  &.contacts {
    margin: unset;
  }
`

export const StyledLeftLocationBox = styled.div`
  display: flex;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-top: 0.875rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin-top: 1rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
  }

  svg {
    color: ${(p) => p.theme.colors.carrot};

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 1rem;
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.125rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      margin-right: 0.375rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.3125rem;
      width: 1.625rem;
      height: 1.625rem;
      margin-right: 0.75rem;
    }
  }

  span {
    color: ${(p) => p.theme.colors.pitchBlack};
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 1rem;
      line-height: 1.5rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.5rem;
      line-height: 2.125rem;
    }
  }
`

export const StyledSDICenterContainer = styled.div``

export const StyledCenterTitle = styled.div`
  color: ${(p) => p.theme.colors.pitchBlack};
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 2rem;
    line-height: 2.625rem;

    margin: 3.5rem 0 0.75rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin: 3.5rem 0 1rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 3rem;
    line-height: 4.0625rem;

    margin: 5.125rem 0 1.75rem;
  }
`

export const StyledCenterContent = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};

  color: ${(p) => p.theme.colors.pitchBlack};

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 0.75rem;
    line-height: 1.25rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 1rem;
    line-height: 1.375rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
`

export const StyledSDIRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  img {
    border-radius: 0.25rem;
  }

  & > div > .share-wrapper {
    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      margin-bottom: 2rem !important;
    }
  }

  .btn-go-to-store {
    margin: 0 auto;
    width: 100%;

    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    text-transform: uppercase;
    letter-spacing: 0.04em;

    border-radius: 0.25rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 0.75rem;
      line-height: 1rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1rem;
      line-height: 1.375rem;
    }
  }

  .gray-back {
    border-radius: 0.25rem;
    background-color: ${(p) => p.theme.colors.whiteSmoke};
    padding: 1rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      padding: 2rem;
    }
  }
`

export const StyledRightShareBox = styled.div`
  .caption {
    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-family: ${(p) => p.theme.fontFamilies.headlineBig};
      font-style: ${(p) => p.theme.fontStyles.normal};
      font-weight: ${(p) => p.theme.fontWeights.liteBlack};
      font-size: 1.25rem;
      line-height: 2rem;

      letter-spacing: 0.02em;
      text-transform: uppercase;

      color: ${(p) => p.theme.colors.disabled};
    }
  }

  height: 1.125rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    font-size: 1.5rem;
    height: 1.5rem;
    margin-left: 1.125rem;
    cursor: pointer;
    color: ${(p) => p.theme.colors.carrot};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-bottom: 1rem;

    height: 2rem;
    > div {
      display: flex;
      justify-content: space-around;
      width: 100%;
      button:first-of-type {
        svg {
          margin: 0;
        }
      }
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin-bottom: 1.5rem;

    svg {
      font-size: 2rem;
      height: 2rem;
      margin-left: 2.5rem;
      cursor: pointer;
    }
  }
`

export const StyledRightPacBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      margin-right: 0.5rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      margin-right: 0.75rem;
    }
  }

  span {
    color: ${(p) => p.theme.colors.pitchBlack};
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin: 0.75rem 0;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin: 2.5rem 0;
  }
`

export const StyledRightDiscountBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: ${(p) => p.theme.colors.blue};
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    text-align: center;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 0.75rem;
      line-height: 1.125rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.5rem;
      line-height: 2.125rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin: 1.5rem 0;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin: 2.5rem 0;
  }
`

export const StyledSDIMobileMainContainer = styled.div`
  display: block;

  .media-box {
    border-radius: 0;
  }

  .default-offer-image {
    img {
      width: 100%;
      height: auto;
    }
  }

  .back-to-stores {
    position: absolute;
    z-index: 1;
    margin: 1.5rem 0 0 1.25rem;
  }

  margin-bottom: ${(p) => (p.isCorporate ? "unset" : "1.5rem")};
`

export const StyledMobileTitleLogoBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-radius: 0.25rem;
  margin: 1.5rem 1rem 0.8125rem;

  .media-box {
    justify-content: right;
  }
`

export const StyledMobileTitle = styled.div`
  color: ${(p) => p.theme.colors.pitchBlack};
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-size: 1.5rem;
  line-height: 2rem;

  min-width: 12.4375rem;
`

export const StyledMobilePhoneBox = styled.div``

export const StyledMobileContacts = styled.div`
  margin: 1.5rem 0 1rem 1rem;

  span {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.carrot};

    font-size: 1rem;
    line-height: 1.5rem;
  }
`

export const StyledMobileLocationBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 1rem;

  .phone-icon {
    color: ${(p) => p.theme.colors.carrot};
    margin-right: 0.4375rem;

    svg {
      width: 1rem;
      height: 1rem;
    }
  }

  .location-icon {
    color: ${(p) => p.theme.colors.carrot};
    margin: 0.3125rem 0.625rem 0 0;

    svg {
      width: 1rem;
      height: 1rem;
    }
  }

  div > span,
  p {
    color: ${(p) => p.theme.colors.pitchBlack};
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-size: 1rem;
    line-height: 1.5rem;
  }
`

export const StyledMobileCategoryBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin: 1.25rem 1rem 0.75rem;
  text-align: center;
`

export const StyledMobileCategory = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(p) => p.theme.colors.whiteSmoke};
  border-radius: 0.25rem;
  width: fit-content;

  span {
    color: ${(p) => p.theme.colors.pitchBlack};
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    font-size: 1rem;
    line-height: 1.375rem;

    margin: auto 0;
    padding: 0.375rem 0.75rem;
  }

  :hover {
    background: ${(p) => p.theme.colors.carrot};
    cursor: pointer;
    span {
      color: ${(p) => p.theme.colors.white};
    }
  }
`

export const StyledMobileContent = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  font-size: 0.75rem;
  line-height: 1.25rem;

  color: ${(p) => p.theme.colors.pitchBlack};

  margin: 0 1rem;
`

export const StyledMobilePacBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;

  img {
    margin-right: 0.5rem;
  }

  span {
    color: ${(p) => p.theme.colors.pitchBlack};
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
`

export const StyledMobileDiscountBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;

  img {
    margin-right: 0.5rem;
  }

  span {
    color: ${(p) => p.theme.colors.blue};
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-size: 1.1rem;
    line-height: 1.5rem;
  }

  margin-bottom: 1rem;
`

export const StyledCenterTitleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 1.25rem;
`

export const StyledCenterImage = styled.div`
  img {
    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      margin: 3.5rem 0 0.75rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      margin: 3.5rem 0 1rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      margin: 5.125rem 0 1.75rem;
    }
  }
`

export const StyledSDIRightCorporateContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledRightCorporate = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0.25rem;
  background: ${(p) => p.theme.colors.whiteSmoke};

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 1rem;
    row-gap: 0.75rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    padding: 1.5rem;
    row-gap: 1rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding: 2rem;
    row-gap: 1.5rem;
    flex-grow: 1;
  }

  h4 {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.pitchBlack};

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 1.125rem;
      line-height: 1.75rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      font-size: 1.5rem;
      line-height: 2.125rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 2rem;
      line-height: 2.75rem;
    }
  }

  span {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.softGreen};

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 1.5rem;
      line-height: 2.125rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 2rem;
      line-height: 2.75rem;
    }
  }

  p {
    font-family: ${(p) => p.theme.fontFamilies.headlineBig};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.liteBlack};
    color: ${(p) => p.theme.colors.pitchBlack};
    text-transform: uppercase;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 0.625rem;
      line-height: 1rem;
      letter-spacing: 0.0187rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      font-size: 0.75rem;
      line-height: 1.25rem;
      letter-spacing: 0.0225rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1rem;
      line-height: 1.5rem;
      letter-spacing: 0.02rem;
    }
  }
`

export const StyledMobileCorporateContainer = styled.div`
  border-radius: 0.25rem;
  background: ${(p) => p.theme.colors.white};
  padding: 0 1rem 1.5rem;

  h4 {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.pitchBlack};
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
`

export const StyledMobileCorporate = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 0.25rem;
  background: ${(p) => p.theme.colors.white};
  margin-top: 0.75rem;

  span {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.softGreen};
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  p {
    font-family: ${(p) => p.theme.fontFamilies.headlineBig};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.liteBlack};
    color: ${(p) => p.theme.colors.pitchBlack};
    text-transform: uppercase;
    font-size: 0.5rem;
    line-height: 0.75rem;
    letter-spacing: 0.015rem;
  }
`

export const StyledMobileCategoryBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 1rem;
`

export const StyledMobileHr = styled.hr`
  margin: 1.5rem 1rem;
  width: unset;
`

export const StyledFundraisingPortfolioBox = styled.div`
  flex: 1;
  padding: 0.5rem;
  width: calc(100% / 3);
  text-align: center;
`
export const StyledPayWithPacs = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headlineBig};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.liteBlack};
  color: ${(p) => p.theme.colors.concreteGrey};
  font-size: 0.625rem;
  line-height: 1rem;
  text-transform: uppercase;
  text-align: center;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1rem;
    line-height: 1.5rem;
  }
`
