import { Spacer } from "@tmu/src/global/page-addons/detail-page.styles"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import RadioSelect from "../RadioSelect"
import {
  StyledConfirmationOptionsContainer,
  StyledConfirmationOptionsDescription,
  StyledConfirmationOptionsTitle,
  StyledRadioOptions,
} from "./index.styles"

const VoucherCodeSelector = ({
  isVoucher,
  onChange,
  confirmationCodeOptionDescriptionText,
  confirmationCodeOptionText,
  disabled,
  isCampaign,
}) => {
  const { formatMessage } = useIntl()
  const confirmationCodeText = formatMessage({
    id: "dashboard::offerForm::confirmationCode",
    defaultMessage: "Confirmation Code",
  })

  const voucherConfirmationCodeText = formatMessage({
    id: "dashboard::offerForm::confirmationCodeVoucher",
    defaultMessage: "Voucher Confirmation Code",
  })

  const voucherConfirmationCodeCampaignText = formatMessage({
    id: "dashboard::offerForm::confirmationCodeCampaignVoucher",
    defaultMessage: "Confirmation Code / Voucher",
  })

  const serviceConfirmationCodeText = formatMessage({
    id: "dashboard::offerForm::confirmationCodeService",
    defaultMessage: "Service Confirmation Code",
  })

  const voucherConfirmationCodeDescriptionText = formatMessage({
    id: "dashboard::offerForm::confirmationCodeDescriptionVoucher",
    defaultMessage:
      "Choose to send the voucher confirmation code so your customers will receive your product/service in person after your approval of the confirmation code",
  })

  const voucherConfirmationCodeCampaignDescriptionText = formatMessage({
    id: "dashboard::offerForm::confirmationCodeCampaignDescriptionVoucher",
    defaultMessage:
      "Donors will receive a confirmation code that can only be verified by you. You can use this code when creating an event, offering a gift in exchange for a donation",
  })

  const serviceConfirmationCodeDescriptionText = formatMessage({
    id: "dashboard::offerForm::confirmationCodeDescriptionService",
    defaultMessage:
      "Choose to send service confirmation code so your customers will receive your product/service in person after your approval of the confirmation code",
  })

  const serviceConfirmationCodeCampaignDescriptionText = formatMessage({
    id: "dashboard::offerForm::confirmationCodeCampaignDescriptionService",
    defaultMessage: "Donors will not receive a confirmation code",
  })

  const confirmationCodeOptionTextData =
    confirmationCodeOptionText ?? !isVoucher
      ? serviceConfirmationCodeText
      : voucherConfirmationCodeText

  const confirmationCodeOptionDescriptionTextData =
    confirmationCodeOptionDescriptionText ?? isCampaign
      ? voucherConfirmationCodeCampaignDescriptionText
      : !isVoucher
      ? serviceConfirmationCodeDescriptionText
      : voucherConfirmationCodeDescriptionText

  const confirmationCodeOptionContent = (
    <StyledConfirmationOptionsContainer className={!isVoucher ? "passive" : ""}>
      <StyledConfirmationOptionsTitle>
        {isCampaign
          ? voucherConfirmationCodeCampaignText
          : confirmationCodeOptionTextData}
      </StyledConfirmationOptionsTitle>
      <Spacer top={1} />
      <StyledConfirmationOptionsDescription>
        {confirmationCodeOptionDescriptionTextData}
      </StyledConfirmationOptionsDescription>
    </StyledConfirmationOptionsContainer>
  )

  const noConfirmationCodeOptionContent = (
    <StyledConfirmationOptionsContainer className={isVoucher ? "passive" : ""}>
      <StyledConfirmationOptionsTitle>
        <FormattedMessage
          id="dashboard::offerForm::noConfirmationCode"
          defaultMessage="No confirmation code"
        />
      </StyledConfirmationOptionsTitle>
      <Spacer top={1} />
      <StyledConfirmationOptionsDescription>
        {isCampaign ? (
          serviceConfirmationCodeCampaignDescriptionText
        ) : (
          <FormattedMessage
            id="dashboard::offerForm::noConfirmationCodeDescription"
            defaultMessage="Suitable for services, consultancy, gym or other subscription, medical care services"
          />
        )}
      </StyledConfirmationOptionsDescription>
    </StyledConfirmationOptionsContainer>
  )

  const radioOptions = [
    {
      id: 1,
      rawHtml: confirmationCodeOptionContent,
      isPassive: false,
    },
    {
      id: 0,
      rawHtml: noConfirmationCodeOptionContent,
      isPassive: false,
    },
  ]

  return (
    <StyledRadioOptions
      disabled={disabled}
      className={isCampaign ? "campaign-colors" : ""}>
      <RadioSelect
        items={radioOptions}
        defaultValue={isVoucher ? 1 : 0}
        onChange={(e) => {
          const selectedValue = e.target.value
          const temp = selectedValue == 1

          if (typeof onChange === "function") {
            onChange(temp)
          }
        }}
        disabled={disabled}
      />
    </StyledRadioOptions>
  )
}

export default VoucherCodeSelector
