import React, { useContext, useEffect } from "react"
import { injectIntl } from "gatsby-plugin-intl"
import { useIntl } from "gatsby-plugin-intl"
import SEO from "@tmu/components/seo"
import { faHandHoldingHeart } from "@fortawesome/pro-solid-svg-icons/faHandHoldingHeart"
import { faHandPeace } from "@fortawesome/free-solid-svg-icons/faHandPeace"
import { faHeart } from "@fortawesome/pro-solid-svg-icons/faHeart"
import { faHandshake } from "@fortawesome/pro-solid-svg-icons/faHandshake"
import { faCubesStacked } from "@fortawesome/free-solid-svg-icons/faCubesStacked"
import { faPeopleGroup } from "@fortawesome/free-solid-svg-icons/faPeopleGroup"
import { JoinPageComponent } from "@tmu/components/how-it-works"
import { useFooterType } from "@tmu/hooks"
import { FOOTER_TYPE } from "@tmu/apollo/constants"
import { HomePageContext } from "@tmu/context/homePageContext"
import { StyledJoinPageWrapper } from "@tmu/global/page-addons/how-it-works.styles"

const { CLOUDFLARE_IMAGE_URL } = process.env

const NgoPage = () => {
  const { locale, formatMessage } = useIntl()
  const { setHeaderConfigData } = useContext(HomePageContext)
  useFooterType({ footerType: FOOTER_TYPE.WIDE })

  useEffect(() => {
    setHeaderConfigData({ transparent: true })
  }, [])

  const benefitCards = [
    {
      title: formatMessage({
        id: "join::textTable::title1",
        defaultMessage: "Join the New Generation of Donations",
      }),
      desc: formatMessage({
        id: "join::textTable::description1",
        defaultMessage:
          "Access to a new transparent fundraising channel that is at ZERO COST for your supporters.",
      }),
      fontIcon: faHandHoldingHeart,
    },
    {
      title: formatMessage({
        id: "join::textTable::title2",
        defaultMessage: "Two innovative reward models for your donors",
      }),
      desc: formatMessage({
        id: "join::textTable::description2",
        defaultMessage:
          "All donors are rewarded 100% with discounts called PACs (1 Euro = 1 PAC) that can be used within our PAC Offers, allowing to get discounts on all purchases made in the PAC Stores. With the Donate By Shopping feature, a part of each purchase is transformed into a donation.",
      }),
      fontIcon: faHandPeace,
    },
    {
      title: formatMessage({
        id: "join::textTable::title3",
        defaultMessage: 'Become "Favorite Charity"',
      }),
      desc: formatMessage({
        id: "join::textTable::description3",
        defaultMessage:
          "Thanks to DonateByShopping, TrustMeUp transforms every purchase into a donation. You will be able to be chosen as the preferred charity of digital consumers present in TrustMeUp and receive a donation for every purchase made by them in our Shopping Gallery​.",
      }),
      fontIcon: faHeart,
    },
    {
      title: formatMessage({
        id: "join::textTable::title4",
        defaultMessage: "Transparency and security",
      }),
      desc: formatMessage({
        id: "join::textTable::description4",
        defaultMessage:
          "All donations made are registered through blockchain technology. This guarantees maximum transparency and safety, becoming an added value for all donors.",
      }),
      fontIcon: faHandshake,
    },
    {
      title: formatMessage({
        id: "join::textTable::title5",
        defaultMessage: "Custom dashboard and overview",
      }),
      desc: formatMessage({
        id: "join::textTable::description5",
        defaultMessage:
          "After registering your charity, you will have a management panel at your disposal that will allow you to modify active campaigns, create new ones, download the list of your supporters or  the list of donations received.",
      }),
      fontIcon: faCubesStacked,
    },
    {
      title: formatMessage({
        id: "join::textTable::title6",
        defaultMessage: "Always by your side!",
      }),
      desc: formatMessage({
        id: "join::textTable::description6",
        defaultMessage:
          "From the Onboarding process to all your needs, you will always have our support team available to eliminate management times and improve your experience on the platform.",
      }),
      fontIcon: faPeopleGroup,
    },
  ]

  const benefitsTitle = formatMessage({
    id: "join::tables::title",
    defaultMessage: "Join and you will get",
  })

  const howToJoinItemsTitle = formatMessage({
    id: "join::ngo::howToJoinTitle",
    defaultMessage: "How to join?",
  })

  const howToJoinItems = [
    formatMessage({
      id: "join::howToJoin::item1",
      defaultMessage: "Create your fundraising account in under a minute",
    }),
    formatMessage({
      id: "join::howToJoin::item2",
      defaultMessage: "Register your charity",
    }),
    formatMessage({
      id: "join::howToJoin::item3",
      defaultMessage: "Publish your first campaign",
    }),
  ]

  const howToJoinItemsAdd = formatMessage({
    id: "join::howToJoin::item4",
    defaultMessage: "Receive donations",
  })

  const sliceBannerData = {
    header: formatMessage({
      id: "join::ngo::heroHeader",
      defaultMessage: "Fundraising is simple and efficient",
    }),
    title: formatMessage({
      id: "join::ngo::heroTitle",
      defaultMessage:
        "Manage your charity page as well as all campaigns from one place.",
    }),
    description: formatMessage({
      id: "join::ngo::heroDescription",
      defaultMessage:
        "We believe that every campaign must be valued as its best and that's why we work every day to improve the donors' experiences. We do it thanks to our innovative fundraising models; on the one hand we reward each donor 100% with PAC discounts for each donation: Donate-Receive-Buy.",
    }),
    description2: formatMessage({
      id: "join::ngo::heroDescription2",
      defaultMessage:
        "On the other hand, we allow digital consumers to allocate part of the sums spent on their purchases, as a donation.",
    }),
    image: CLOUDFLARE_IMAGE_URL + "/static/assets/images/join/children.png",
  }

  const pageData = {
    contactFormTitle: formatMessage({
      id: "join::forms::contactForm::ngoTitle",
      defaultMessage: "Join as a Non-Profit",
      tagName: "h3",
    }),
    contactFormDescription: formatMessage({
      id: "join::forms::contactForm::ngoDescription",
      defaultMessage:
        "Tell us about your charity so we can help you to onboard.",
      tagName: "p",
    }),
    sender: "PARTNER",
    logoItems: [
      {
        node: {
          logoEn:
            "https://images.trustmeup.com/partners/partner/logo/1666002113146-Lxt3GIvVqBsbaMYxqFNhWA.jpg",
        },
      },
      {
        node: {
          logoEn:
            "https://images.trustmeup.com/partners/partner/logo/1659538528448-5iR6fR844vXMeNSouAPx0A.jpg",
        },
      },
      {
        node: {
          logoEn:
            "https://images.trustmeup.com/partners/partner/logo/1675097370791-03uT7dfQsaoXNBsZ5cHdnQ.jpg",
        },
      },
      {
        node: {
          logoEn:
            "https://images.trustmeup.com/partners/partner/logo/1639667487103-CSMsxLx5YwH195PdDfS0-A.jpg",
        },
      },
      {
        node: {
          logoEn:
            "https://images.trustmeup.com/partners/partner/logo/1628165773776--ibsCzMMuOM0Oz-HRqcuoA.png",
        },
      },
      {
        node: {
          logoEn:
            "https://images.trustmeup.com/partners/partner/logo/1652967952759-IN4qxqU3AIMPZGfhq16Tcg.jpg",
        },
      },
      {
        node: {
          logoEn:
            "https://images.trustmeup.com/partners/partner/logo/1640092842640-pvFw9NEh3z9l4e8kNyaUvA.jpg",
        },
      },
      {
        node: {
          logoEn:
            "https://images.trustmeup.com/partners/partner/logo/1662543228919-6o1SC-_aS7zaj35Re7GS9g.jpg",
        },
      },
    ],
    logoItemsTitle: formatMessage({
      id: "join::ngo::ngoListTitle",
      defaultMessage: "Some of Non Profits that already work with us:",
    }),
    benefitCards,
    benefitsTitle,
    howToJoinItems,
    howToJoinItemsAdd,
    sliceBannerData,
    howToJoinItemsTitle,
    termsLink: "/legal/charity-terms/5",
  }
  return (
    <>
      <SEO
        lang={locale}
        title={formatMessage({
          id: "join::ngo::pageTitle",
          defaultMessage: "Join as a Charity",
        })}
        description={formatMessage({
          id: "seo::ngo::description",
          defaultMessage:
            "Get a new transparent fundraising channel at no cost for your supporters.",
        })}
      />
      <StyledJoinPageWrapper>
        <JoinPageComponent pageData={pageData} blue />
      </StyledJoinPageWrapper>
    </>
  )
}
export default injectIntl(NgoPage)
