import styled from "@emotion/styled"

export const StyledGetStarted = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.wide}) {
    flex-direction: column;
  }
`
export const StyledImg = styled.img`
  position: relative;
  margin-left: 23rem;
  width: 50rem;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
    width: 37.5rem;
    margin: 0 auto;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    width: 17.5rem;
    margin: 0 auto;
  }
`
export const StyledDescription = styled.h3`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.regular};
  position: absolute;
  font-size: 1.625rem;
  line-height: 153.8%;

  color: ${(p) => p.theme.colors.racingGreen};
  transform: translate(-40%, 30%);
  max-width: 43.4375rem;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.wide}) {
    text-align: center;
    transform: none;
    position: relative;
    padding-bottom: 1.875rem;
    margin: 0 auto;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 1.25rem;
    text-align: center;
  }
`
