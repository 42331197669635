import styled from "@emotion/styled"
import { StyledFormSection } from "@tmu/global/page-addons/dashboard.styles"

export const StyledWrapper = styled.div`
  margin: 5.25rem auto 0;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    max-width: ${(p) => p.theme.breakpoints.tablet};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    max-width: ${(p) => p.theme.breakpoints.desktop};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    max-width: ${(p) => p.theme.breakpoints.wide};
  }

  display: flex;
`

export const StyledImageWrapper = styled.div`
  img {
    width: 100%;
    height: 54.875rem;
    object-fit: cover;
    position: sticky;
    top: 0;
  }
  flex: 1;
  display: none;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: block;
  }
`

export const StyledFormWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem;

  .stepper {
    display: grid;
    margin-bottom: 24px;
  }

  .stepper.four-steps {
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas:
      "label0 label1 label2 label3"
      "label0s label1s label2s label3s";
  }

  .stepper.three-steps {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
      "label0 label1 label2"
      "label0s label1s label2s";
  }
  .stepper.two-steps {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "label0 label1"
      "label0s label1s";
  }

  .box {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    width: 100%;
  }

  .label {
    margin-bottom: 8px;
    text-align: center;
    font-size: 12px;
  }

  .circle {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid #7a7776;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #7a7776;
    cursor: pointer;
    background-color: white;
    z-index: 1;
  }

  .circle.active {
    border-color: #f56a00;
    color: #f56a00;
  }
  .circle.completed {
    background-color: #f56a00;
  }

  .line {
    transform: translate(50%, -12px);
    height: 2px;
    width: 100%;
    background-color: #7a7776;
    margin: 0 8px; /* Adds consistent spacing between circle and line */
  }
  .line.active {
    border-color: #f56a00;
    background-color: #f56a00;
  }

  .step .circle.active + .line {
    background-color: #f56a00;
  }

  .label-0 {
    grid-area: label0;
  }
  .label-1 {
    grid-area: label1;
  }
  .label-2 {
    grid-area: label2;
  }
  .label-3 {
    grid-area: label3;
  }
  .box-0 {
    grid-area: label0s;
  }
  .box-1 {
    grid-area: label1s;
  }
  .box-2 {
    grid-area: label2s;
  }
  .box-3 {
    grid-area: label3s;
  }
  .finance {
    color: #f56a00;
  }
  .full-width {
    width: 100%;
    place-self: center;
    justify-self: center;
  }
  .button-wrapper button {
    width: 100%;
    max-width: 400px;
    place-self: center;
    justify-self: center;
  }

  .stripe-button {
    margin-top: 1.5rem;
  }

  .logo-wrapper {
    .image-selector {
      width: 100%;
    }
    .image-selector div {
      max-width: initial !important;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 1.25rem 1.5rem;
    .stepper {
      margin-bottom: 32px;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    padding: 1.25rem 3.125rem;
    .stepper {
      margin-bottom: 40px;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding: 1.25rem 6.25rem;
    .stepper {
      margin-bottom: 52px;
    }

    .check {
      label {
        padding-left: 2rem;
      }
      .checkmark {
        height: 1.5rem;
        width: 1.5rem;
      }
      .field-error {
        padding-left: 2rem;
      }
    }
  }
`

export const StyledBigText = styled.h1`
  font-weight: ${(p) => p.theme.fontWeights.bold};
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-size: 2rem;
  line-height: 2.75rem;
  color: ${(p) => p.theme.colors.pitchBlack};
  margin-bottom: 1.5rem;
  text-align: center;
`

export const StyledDescription = styled.p`
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  font-family: ${(p) => p.theme.fontFamilies.regular};

  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 24px;
  text-align: center;

  .bold {
    font-weight: ${(p) => p.theme.fontWeights.bold};
  }
`

export const StyledCardWrapper = styled.div`
  margin-bottom: 2.5rem;

  &:last-child {
    margin-bottom: 1rem;
  }
`
export const StyledCardTitle = styled.div`
  font-weight: ${(p) => p.theme.fontWeights.bold};
  font-family: ${(p) => p.theme.fontFamilies.headline};

  font-size: 1.125rem;
  line-height: 1.125rem;
  margin-bottom: 1em;
`
export const StyledCard = styled.div`
  border-radius: 0.25rem;
  border: 0.0625rem solid ${(p) => p.theme.colors.concreteGrey};
  padding: 1.5rem 1rem 1.5rem;
  margin-top: 0.75rem;
`

export const StyledFormRow = styled.div`
  margin: 0 0 1.5rem 0;
  .caption {
    margin-bottom: 0.5rem;
  }

  .blue {
    color: ${(p) => p.theme.colors.blue};
    cursor: pointer;
  }

  fieldset {
    display: flex;
    gap: 0.5rem;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .image-selector {
    padding: 2rem 1rem 1rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    .caption {
      margin-bottom: 0.625rem;
      font-size: 1rem;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin: 0 0 2rem 0;

    .caption {
      margin-bottom: 1rem;
    }
  }
`

export const StyledToggleWrapper = styled.div`
  display: flex;
  gap: 1rem;
  margin: 1.5rem 0;
`

export const StyledPurchaseWrapper = styled.div`
  margin-top: -0.5rem;
  padding: 1rem 0;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;

  .details {
    font-size: 0.75rem;
  }

  & > div > .amount {
    font-weight: ${(p) => p.theme.fontWeights.bold};
    font-family: ${(p) => p.theme.fontFamilies.headline};
    color: ${(p) => p.theme.colors.pitchBlack};

    font-size: 1.5rem;
  }

  & > div > .price {
    font-weight: ${(p) => p.theme.fontWeights.bold};
    font-family: ${(p) => p.theme.fontFamilies.headline};

    font-size: 0.625rem;
    color: ${(p) => p.theme.colors.concreteGrey};
    text-align: right;
  }

  border-top: 0.0625rem solid ${(p) => p.theme.colors.concreteGrey};
  border-bottom: 0.0625rem solid ${(p) => p.theme.colors.concreteGrey};
`

export const StyledProfileFlexRow = styled.div`
  display: block;
  .spacer {
    margin-top: 0 !important;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    display: flex;
    justify-content: space-between;
  }
`
export const StyledProfileSection = styled.div`
  width: 100%;
  &.tell-about-short-description {
    p {
      margin-bottom: unset;
    }
  }

  &.tell-about-short-description-show-error {
    margin-bottom: 0.75rem;
  }

  &.tell-about-short-description-hide-error {
    margin-bottom: unset;
  }

  .react-select__control {
    margin-top: 0;
  }
  .mtop {
    margin-top: 0.5rem;
  }
`

export const StyledProfileFormSection = styled((props) => (
  <StyledFormSection {...props} />
))`
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    .image-selector {
      flex: unset;
      img {
        width: auto;
        height: auto;
        max-height: 7.5rem !important;
      }
    }
  }
`

export const StyledInfoIcon = styled.div`
  color: ${(p) => p.theme.colors.blue};
  height: 1.25rem;
`
export const StyledIconWrapper = styled.div`
  .tooltip {
    background-color: ${(p) => p.theme.colors.white};
    color: ${(p) => p.theme.colors.pitchBlack};
    overflow-wordwrap: break-word;
    width: 20rem;
    padding: 1rem;
    font-size: 0.75rem;
    line-height: 1.25rem;
  }

  svg {
    height: 1rem;
    cursor: pointer;
    position: relative;
    top: -5px;
    right: 5px;
    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      height: 1.25rem;
      position: relative;
      top: -5px;
      right: 5px;
    }
  }
  align-self: top;
  margin-top: 1rem;
  margin-left: 1rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    align-self: center;
    margin-top: unset;
  }
`

export const StyledSuccessDetailTitle = styled.div`
  font-weight: ${(p) => p.theme.fontWeights.bold};
  font-family: ${(p) => p.theme.fontFamilies.headline};
  color: ${(p) => p.theme.colors.pitchBlack};

  font-size: 1.5rem;
  line-height: 2.125rem;
  padding: 1rem 0 0;

  text-align: center;
  margin: 1rem auto;

  span {
    font-weight: ${(p) => p.theme.fontWeights.bold};
    font-family: ${(p) => p.theme.fontFamilies.headline};
  }

  .pacs-image {
    height: 1.2rem;
    width: 1.3rem;
    margin-right: 0.2rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 2rem;
    line-height: 2.75rem;
    .pacs-image {
      height: 1.5rem;
      width: 1.5rem;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 3rem;
    line-height: 4.0625rem;
  }
`

export const StyledSuccessPageImage = styled.div`
  svg,
  img {
    width: 200px;
    height: auto;
    margin: 2rem auto;
  }
  text-align: center;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    /* svg,
    img {
      margin: 2rem auto;
    } */
  }
`
