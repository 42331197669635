import React from "react"
import PropTypes from "prop-types"
import { StyledNotificationTableRow } from "./index.styles"
import { Checkbox, Date } from "@tmu/components/common"

const NotificationTableRow = ({
  index,
  style,
  notificationID,
  description,
  details,
  date,
  isSelected,
  onToggleSelected,
}) => {
  return (
    <StyledNotificationTableRow style={style} isSelected={isSelected}>
      <div>
        <Checkbox
          id={notificationID}
          isChecked={isSelected}
          onChange={onToggleSelected}
        />
      </div>
      <div>{description}</div>
      <div>{details}</div>
      <div>
        <Date value={date} />
      </div>
    </StyledNotificationTableRow>
  )
}

NotificationTableRow.propTypes = {
  index: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
  notificationID: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  details: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onToggleSelected: PropTypes.func.isRequired,
}

export default NotificationTableRow
