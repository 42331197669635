import styled from "@emotion/styled"

export const StyledFavoriteNGOSection = styled.div`
  display: flex;
  align-items: baseline;
  p {
    margin-right: 2rem;
  }
  .select-field {
    width: 100%;
  }
  & > div {
    width: 100%;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.phone}) {
    display: block;
    .select-field {
      width: 100%;
    }

    #favoriteCharity {
      margin-top: 1rem;
    }
  }
`
export const StyledMobileWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  flex-direction: column;
  text-align: left;
  margin-top: -2rem;
`

export const StyledMobileCharityTitle = styled.div``

export const StyledMobileDescription = styled.div``

export const StyledCharityList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  flex-direction: column;
  overflow-y: scroll;
  text-align: left;
  max-height: calc(100vh - 4rem);
`
export const StyledCharityListItem = styled.div`
  width: 100%;
  cursor: pointer;
  display: grid;
  grid-template-columns: minmax(5.5rem, 5.5rem) auto;
  justify-items: start;
  justify-content: start;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    grid-template-columns: minmax(15rem, 15rem) auto;
  }
  gap: 0.5rem;
`
export const StyledImageContainer = styled.div`
  height: 3rem;
  div {
    display: block !important;
    img {
      border-radius: 0.25rem;
    }
  }
`
export const StyledTaxId = styled.div`
  padding: 0.1rem;
  color: ${(p) => p.theme.colors.disabled};
  font-weight: ${(p) => p.theme.fontWeights.liteBlack};
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-size: 0.5rem;
  line-height: 0.75rem;
  max-width: 12rem;
  min-width: 12rem;
  margin-top: 0.5rem;
  text-transform: uppercase;
  text-align: left;
`
export const StyledPartnerName = styled.div`
  text-align: left;
  font-size: 0.75rem;
  line-height: 1rem;
  color: ${(p) => p.theme.colors.pitchBlack};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-family: ${(p) => p.theme.fontFamilies.headline};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: calc(100vw - 9rem);
`
export const StyledTitle = styled.div`
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1rem;
  color: ${(p) => p.theme.colors.disabled};
  font-weight: ${(p) => p.theme.fontWeights.liteBlack};
  font-family: ${(p) => p.theme.fontFamilies.headline};
  text-align: left;
`
export const StyledSearchBoxFilter = styled.div`
  position: relative;
  width: calc(100vw - 1.5rem);
  height: 2.5rem;
  margin-left: -1.5rem;
  input {
    font-size: 0.75rem;
    height: 2.5rem;
    padding-right: 2rem;
    border: none;
    margin-left: 2rem;
    width: calc(100vw - 1.5rem);
  }

  input,
  input:hover,
  input:active,
  input:focus {
    border: none;
  }

  .search-icon {
    position: absolute;
    display: block;
    font-size: 1rem;
    color: ${(p) => p.theme.colors.gondola};
    opacity: 0.6;
    top: 0rem;
    left: 1rem;
    svg {
      height: 1.25rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    input {
      height: 3.5rem;
      font-size: 1rem;
      line-height: 1.375rem;
    }
    .search-icon {
      font-size: 2rem;
    }
  }
`

export const StyledLine = styled.hr`
  margin: 0;
  width: auto;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin: 1.5rem 0;
  }
`
