import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import { Router } from "@reach/router"
// import PrivateRoute from "@tmu/components/PrivateRoute"
import {
  OfferListing,
  // OfferDetail,
  StoreDetail,
  /* PurchasesForm,
  OfferPurchaseSuccess,
  ShoppingCart, */
} from "@tmu/components/offers"

import { StyledPageContainer } from "@tmu/global/page-addons/offers-page.styles"

const Offers = () => (
  <StyledPageContainer>
    <Router basepath="/:locale/offers">
      <OfferListing path="/" />
      {/* <PrivateRoute path="/cart" component={ShoppingCart} /> */}
      {/* <PrivateRoute path="/checkout" component={PurchasesForm} /> */}
      {/* <PrivateRoute path="/checkout/success" component={OfferPurchaseSuccess} /> */}
      <StoreDetail path="/store/:slug" />
      {/* <OfferDetail path="/detail/:slug" />  */}
    </Router>
  </StyledPageContainer>
)

export default injectIntl(Offers)
