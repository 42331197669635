import styled from "@emotion/styled"

export const StyledMainContainer = styled.div`
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 0 1.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    padding: 0 2rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding: 0 6rem;
  }

  h4 {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.pitchBlack};
    text-align: center;

    font-size: 1.5rem;
    line-height: 2.125rem;

    margin-top: 2.5rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      text-align: left;
      font-size: 2rem;
      line-height: 2.75rem;

      margin-top: unset;
      margin-bottom: 1.9375rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 3rem;
      line-height: 4.0625rem;

      margin-bottom: 3.375rem;
    }
  }

  span {
    display: table;

    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.pitchBlack};

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 1.5rem;
      line-height: 2.125rem;

      margin: 0 auto 1.9375rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 3rem;
      line-height: 4.0625rem;

      margin: 0 auto 3.375rem;
    }
  }

  button {
    margin: 0 auto;

    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.white};
    font-size: 0.75rem;
    line-height: 1rem;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.04em;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      margin: 0 auto 4rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1rem;
      line-height: 1.375rem;
      height: 3.375rem;
    }
  }
`

export const StyledContentMainContainer = styled.div`
  display: block;
  padding-bottom: 5rem;

  .media-box {
    height: auto;
    width: 100vw;
    margin-left: -0.1rem;

    img {
      width: 100%;
      height: auto;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: grid;
    grid-template-columns: 21.3125rem auto;

    .media-box {
      width: unset;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    grid-template-columns: 26.625rem auto;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    grid-template-columns: 46.1875rem auto;
  }

  h4 {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.pitchBlack};
    text-align: center;

    font-size: 1.125rem;
    line-height: 1.5rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      text-align: left;
      font-size: 1.5rem;
      line-height: 2.125rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 2rem;
      line-height: 2.75rem;
    }
  }

  p {
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    color: ${(p) => p.theme.colors.pitchBlack};

    text-align: center;

    font-size: 1rem;
    line-height: 1.375rem;

    margin: 0.8125rem 0 1.5rem;
    padding: 0 1rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      text-align: left;
      margin: unset;
      padding: unset;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.5rem;
      line-height: 2.25rem;
    }
  }

  hr {
    border: 0.0625rem solid ${(p) => p.theme.colors.darkGrey};

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      margin: 2.5rem 0;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      margin: 4rem 0;
    }
  }
`

export const StyledContent = styled.div`
  div {
    display: unset;
    justify-content: unset;
    align-items: unset;
    height: unset;
  }

  .media-box {
    height: auto;
    width: 100vw;
    padding: 0 1rem;

    img {
      width: 100%;
      height: auto;

      @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
        margin: 1rem 0 1rem;
      }

      @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
        margin: 0.75rem 0 1rem;
      }

      @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
        margin: 1.9375rem 0;
      }
    }
  }
`

export const StyledBrowserSection = styled.div`
  text-align: center;
  margin: auto;
  font-size: 1rem;
  span {
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.pitchBlack};
    margin: auto;
  }
  > div {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-top: 1.5rem;
    margin-bottom: 3rem;

    .media-box {
      width: fit-content;
      cursor: pointer;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    > div {
      gap: 3rem;
      margin-top: 3rem;
    }
  }
`
