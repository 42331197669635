import React from "react"
import { useQuery } from "@apollo/client"
import { FormattedMessage, useIntl, navigate } from "gatsby-plugin-intl"
import { NewCard } from "@tmu/components/common"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { getValueForLocale } from "@tmu/utils/string"
import { MERCHANT_OFFER_LISTING_QUERY } from "@tmu/apollo/storefront/queries/merchant"
import {
  StyledPacsOffersContainer,
  StyledPacsOffersTitle,
  StyledPacsOffersSubTitleContainer,
  StyledPacsOffersNewOffersTitle,
  StyledPacsOffersViewPacAllOffersButton,
  StyledPacsOffersCards,
} from "./index.styles"
import { LIST_TYPES } from "@tmu/apollo/constants"

const PacsOffers = () => {
  const { locale, defaultLocale } = useIntl()
  const { isTablet } = getAllScreenTypes()

  const { data } = useQuery(MERCHANT_OFFER_LISTING_QUERY, {
    variables: {
      first: 4,
      orderBy: "-created",
      isPublic: true,
      offerOrigin: ["offers"],
    },
  })

  return (
    <StyledPacsOffersContainer>
      <StyledPacsOffersTitle>
        <FormattedMessage
          id="pacs::offers::title"
          defaultMessage="Explore PAC Offers"
        />
      </StyledPacsOffersTitle>
      <StyledPacsOffersSubTitleContainer>
        <StyledPacsOffersNewOffersTitle>
          <FormattedMessage
            id="pacs::offers::newOffers"
            defaultMessage="New offers"
          />
        </StyledPacsOffersNewOffersTitle>
        <StyledPacsOffersViewPacAllOffersButton
          variant="link"
          text={
            <FormattedMessage
              id="pacs::offers::viewPacAllOffers"
              defaultMessage="View all offers"
            />
          }
          onClick={() => {
            navigate("/offers?offer=true")
          }}
        />
      </StyledPacsOffersSubTitleContainer>
      <StyledPacsOffersCards>
        {data?.allOffers?.edges?.map(({ node: offer }) => {
          return (
            <NewCard
              variant={offer?.isDefaultOffer ? "store" : "offer"}
              id={offer?.id}
              key={offer?.id}
              imageSrc={offer?.image}
              title={getValueForLocale(offer, "name", locale, defaultLocale)}
              description={getValueForLocale(
                offer,
                "description",
                locale,
                defaultLocale
              )}
              maxPacDiscount={offer?.maxPacDiscount}
              size={!isTablet ? LIST_TYPES.LIST : LIST_TYPES.TILE}
              storeLogo={getValueForLocale(
                offer?.store,
                "logo",
                locale,
                defaultLocale
              )}
              storeName={offer?.store?.displayName || offer?.store?.name}
              isComingSoon={offer?.store?.badge === "COMING_SOON"}
            />
          )
        })}
      </StyledPacsOffersCards>
    </StyledPacsOffersContainer>
  )
}

export default PacsOffers
