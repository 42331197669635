import { useStaticQuery, graphql } from "gatsby"

const useSiteMetadata = () => {
  const {
    site: { siteMetadata },
  } = useStaticQuery(graphql`
    query Site {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
    }
  `)

  return siteMetadata
}

export default useSiteMetadata
