import styled from "@emotion/styled"

export const StyledSocialCardContainer = styled.div`
  width: 100%;
`

export const StyledSocialCard = styled.div`
  display: grid;
  grid-template-columns: 4rem auto;
  grid-template-areas: "icon link";
  grid-gap: 0.2rem;
  color: ${(p) => p.theme.colors.racingGreen};
  margin-bottom: 0.5rem;
`

export const StyledSocialCardIcon = styled.div`
  font-size: 1.5rem;
  font-weight: ${(p) => p.theme.fontWeights.bold};
  grid-area: icon;
  align-self: center;
  width: 4rem;
  height: 3.5rem;
  padding: 1rem 1.25rem;
  border-radius: 0.5rem 0 0 0.5rem;
  background-color: ${(p) => p.theme.colors.pampasBackgroundColor};
`

export const StyledSocialCardText = styled.div`
  font-weight: ${(p) => p.theme.fontWeights.black};
  grid-area: link;
  padding: 1.2rem 1rem;
  align-self: start;
  height: 3.5rem;
  width: 100%;
  min-width: 15rem;
  border-radius: 0 0.5rem 0.5rem 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: ${(p) => p.theme.colors.pampasBackgroundColor};
`

export const TaxIdCard = styled(StyledSocialCardText)`
  display: grid;
  grid-template-areas: "text id";
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  padding: 1rem 1rem;

  .taxIdText {
    grid-area: text;
    justify-self: start;
  }

  .taxId {
    grid-area: id;
    justify-self: end;
  }
`
