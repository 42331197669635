import { Button, CustomModal } from "@tmu/components/common"
import { modalStyles } from "@tmu/global/GlobalStyle"
import theme from "@tmu/src/global/theme"
import { FormattedMessage, navigate, useIntl } from "gatsby-plugin-intl"
import { StyledModalContainer } from "./index.styles"

export const LeaveModal = ({
  isLeaveModalOpen,
  handleLeaveModalClose,
  onConfirm,
}) => {
  const { formatMessage } = useIntl()
  return (
    <CustomModal
      header={formatMessage({
        id: "dashboard::common::sureToLeave",
        defaultMessage: "Sure, you want to leave?",
      })}
      isOpen={isLeaveModalOpen}
      isCloseIcon={true}
      isCloseXButton={true}
      cancelButtonAction={handleLeaveModalClose}
      style={{
        ...modalStyles,
        content: { ...modalStyles.content, border: "unset" },
      }}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}>
      <StyledModalContainer>
        <FormattedMessage
          id="dashboard::common::leaveModalDescription"
          defaultMessage="If you leave, all changes will be lost."
          tagName="p"
        />
        <Button
          data-testid="btn-modal-leave"
          color={theme.colors.carrot}
          style={{ backgroundColor: theme.colors.carrot }}
          label="Leave"
          className="modal-leave"
          onClick={() => {
            if (typeof onConfirm === "function") {
              onConfirm()
            } else {
              navigate("/dashboard/merchants/stores")
            }
          }}>
          <FormattedMessage
            id="dashboard::common::leave"
            defaultMessage="Leave"
          />
        </Button>
      </StyledModalContainer>
    </CustomModal>
  )
}
