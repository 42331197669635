import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/pro-solid-svg-icons/faCheck"

import { graphql, useStaticQuery } from "gatsby"
import {
  StyledCheckIcon,
  StyledFlagNameContainer,
  StyledLanguageBar,
  StyledLanguageItem,
  StyledLanguageList,
  StyledLine,
} from "./index.styles"
import { useIntl } from "gatsby-plugin-intl"
import { useAuth } from "@tmu/hooks"
import { Dropdown } from "@tmu/components/common"
import { getAllScreenTypes } from "@tmu/src/utils/mediaQueries"
const { CLOUDFLARE_IMAGE_URL } = process.env

const LanguageBar = ({
  width,
  height,
  value,
  isList = false,
  isDropDown = false,
  updateOnSelect = true,
  onSelect,
}) => {
  const { updateCommunicationLanguage } = useAuth()
  const { locale } = useIntl()
  const { isWide } = getAllScreenTypes()
  const {
    site: {
      siteMetadata: { supportedLanguages },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            supportedLanguages {
              id
              label
            }
          }
        }
      }
    `
  )

  const imageWidth = width || 24
  const imageHeight = height || 24

  const handleChangeLanguage = (e) => {
    if (typeof onSelect === "function") {
      onSelect(e)
    }
    if (updateOnSelect === true) {
      updateCommunicationLanguage({
        variables: {
          language: e,
        },
      })
    }
  }

  const languageSection = supportedLanguages.map((key) => {
    let icon
    switch (key.id) {
      case "it":
        icon = (
          <img
            src={CLOUDFLARE_IMAGE_URL + "/static/assets/images/svg/italy.svg"}
            alt="Italy Flag"
            width={imageWidth}
            height={imageHeight}
          />
        )

        break
      case "es":
        icon = (
          <img
            src={CLOUDFLARE_IMAGE_URL + "/static/assets/images/svg/spain.svg"}
            alt="Spain Flag"
            width={imageWidth}
            height={imageHeight}
          />
        )
        break
      default:
        icon = (
          <img
            src={
              CLOUDFLARE_IMAGE_URL +
              "/static/assets/images/svg/united-kingdom.svg"
            }
            alt="England Flag"
            width={imageWidth}
            height={imageHeight}
          />
        )
    }
    return {
      id: `subtitle-language-${key.id}`,
      language: key.id,
      label: <div>{icon}</div>,
      text: key?.label,
    }
  })

  const langugeOptions = supportedLanguages?.map((item) => {
    return {
      id: item?.id?.toLowerCase(),
      label: item?.label,
      value: item?.id?.toLowerCase(),
    }
  })

  return (
    <>
      {isList ? (
        <StyledLanguageList>
          {languageSection.map((item, index) => {
            return (
              <>
                <StyledLanguageItem
                  key={index}
                  onClick={() => handleChangeLanguage(item?.language)}>
                  <StyledFlagNameContainer>
                    {item?.label} {item?.text}
                  </StyledFlagNameContainer>
                  {item?.language === locale && (
                    <StyledCheckIcon>
                      <FontAwesomeIcon icon={faCheck} />
                    </StyledCheckIcon>
                  )}
                </StyledLanguageItem>
                <StyledLine />
              </>
            )
          })}
        </StyledLanguageList>
      ) : isDropDown ? (
        <Dropdown
          newDesign
          data-testid="languages"
          id="languages"
          name="languages"
          defaultValue={value?.toLowerCase()}
          options={langugeOptions}
          defaultSelectStyles={{
            control: {
              height: isWide ? "3.375rem" : "3rem",
              paddingTop: "0",
              paddingRight: "0",
            },
            valueContainer: {
              height: isWide ? "3.375rem" : "3rem",
              paddingTop: "0",
            },
          }}
          onChange={(value) => handleChangeLanguage(value?.id)}
        />
      ) : (
        <StyledLanguageBar>
          {languageSection.map((item, index) => {
            return (
              <div
                key={index}
                onClick={() => handleChangeLanguage(item?.language)}
                className={item?.language === locale ? "glow" : ""}>
                {item?.label}
              </div>
            )
          })}
        </StyledLanguageBar>
      )}
    </>
  )
}

export default LanguageBar
