import styled from "@emotion/styled"

export const StyledProgressBar = styled.div`
  line-height: 1.5rem;
  .rc-progress-line-path {
    transition: ${(p) =>
      p.animate &&
      p.percent &&
      `all ${p.percent > 50 ? "3s" : "1s"} 0s !important`};
  }

  .rc-progress-line {
    height: 0.5rem;
    width: 100%;
  }
`
export const StyledFundAmount = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(p) => p.theme.colors.secondaryBrandTextColor};
  font-size: 0.95rem;
  line-height: 1.5rem;
  padding-top: 0.8125rem;

  .fund {
    margin-bottom: 0.375rem;
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-family: ${(p) => p.theme.fontFamilies.headline};
    color: ${(p) => p.theme.colors.primaryBrandTextColor};
    padding-top: ${(p) => p.isFeatured && "1.5625rem"};
    font-size: 2rem !important;
  }

  .fund-card {
    margin-bottom: 0.375rem;
    font-weight: ${(p) => p.theme.fontWeights.bold};
    color: ${(p) => p.theme.colors.primaryBrandTextColor};
    padding-top: ${(p) => p.isFeatured && "1.5625rem"};
    font-size: 0.95rem;
  }

  .right {
    text-align: right;
  }

  .left {
    text-align: left;
  }

  div:last-of-type {
    float: right;
  }

  p {
  }
  .download {
    color: ${(p) => p.theme.colors.blue};
    font-size: 1rem;
    margin-left: 0.5rem;
    cursor: pointer;
  }
`
export const StyledAmountSupporterText = styled.p`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const StyledSupporters = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  align-items: flex-end;

  span {
    font-size: 1.5rem;
    color: ${(p) => p.theme.colors.primaryBrandTextColor};
    font-weight: ${(p) => p.theme.fontWeights.bold};
  }
`

export const StyledGoalText = styled.div`
  margin-bottom: 2rem;
  p > span {
    color: ${(p) => p.theme.colors.pitchBlack} !important;
  }

  p {
    color: ${(p) => p.theme.colors.disabled} !important;
  }
`

export const StyledSharedButton = styled.div`
  margin: 0 0 2rem 1.5rem;
  height: 1.25rem;
  font-size: 1.25rem;
  color: ${(p) => p.theme.colors.pitchBlack};
`
