import styled from "@emotion/styled"

export const StyledStoreDetailWrapper = styled.div`
  & + div {
    margin-top: unset;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    max-width: ${(p) => p.theme.breakpoints.tablet};
    margin: auto;
    padding: 1.5rem 1.5625rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    max-width: ${(p) => p.theme.breakpoints.desktop};
    padding: 1.5rem 2rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    max-width: ${(p) => p.theme.breakpoints.wide};
    padding: 2.5rem 6rem;
  }
`

export const StyledVisitStore = styled.span`
  color: ${(p) => p.theme.colors.carrot};
  cursor: pointer;
`
export const StyledNotFoundMessageWrapper = styled.div`
  p {
    margin-top: 1rem;
  }

  &.custom-not-found-service .explore-services a span {
    color: ${(p) => p.theme.colors.carrot};
    cursor: pointer;
  }
`
