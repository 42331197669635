import React from "react"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { Field, useFormikContext } from "formik"
import { TextInput, FieldError } from "@tmu/components/common"
import { StyledFormSectionHeading } from "@tmu/global/page-addons/dashboard.styles"
import {
  StyledSocialProfileWrapper,
  StyledHeader,
  StyledField,
} from "./index.styles"

const SocialProfileFieldSet = () => {
  const {
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    touched,
    setTouched,
    setFieldTouched,
  } = useFormikContext()
  const { formatMessage } = useIntl()

  return (
    <>
      <StyledHeader>
        <StyledFormSectionHeading>
          <FormattedMessage
            id="dashboard::forms::socialProfile"
            defaultMessage="Social Profile Links"
            tagName="h3"
          />
          <FormattedMessage
            id="dashboard::forms::provideSocialProfileLinks"
            defaultMessage="Please provide social profile links."
            tagName="p"
          />
        </StyledFormSectionHeading>
      </StyledHeader>
      <StyledSocialProfileWrapper>
        <StyledField>
          <Field
            data-testid="input-website"
            id="website"
            name="website"
            component={TextInput}
            className="float-container"
            value={values.website}
            onChange={handleChange}
            onBlur={handleBlur}
            label={formatMessage({
              id: "dashboard::forms::website",
              defaultMessage: "Website",
            })}
          />
          <FieldError name="website" />
        </StyledField>
        <StyledField>
          <Field
            data-testid="input-facebook"
            id="facebook"
            name="facebook"
            component={TextInput}
            className="float-container"
            value={values.facebook}
            onChange={handleChange}
            onBlur={handleBlur}
            label={formatMessage({
              id: "dashboard::forms::facebook",
              defaultMessage: "Facebook",
            })}
          />
          <FieldError name="facebook" />
        </StyledField>
        <StyledField>
          <Field
            data-testid="input-instagram"
            id="instagram"
            name="instagram"
            component={TextInput}
            className="float-container"
            value={values.instagram}
            onChange={handleChange}
            onBlur={handleBlur}
            label={formatMessage({
              id: "dashboard::forms::instagram",
              defaultMessage: "Instagram",
            })}
          />
          <FieldError name="instagram" />
        </StyledField>
        <StyledField>
          <Field
            data-testid="input-linkedin"
            id="linkedin"
            name="linkedin"
            component={TextInput}
            className="float-container"
            value={values.linkedin}
            onChange={handleChange}
            onBlur={handleBlur}
            label={formatMessage({
              id: "dashboard::forms::linkedin",
              defaultMessage: "Linkedin",
            })}
          />
          <FieldError name="linkedin" />
        </StyledField>
        <StyledField>
          <Field
            data-testid="input-twitter"
            id="twitter"
            name="twitter"
            component={TextInput}
            className="float-container"
            value={values.twitter}
            onChange={handleChange}
            onBlur={handleBlur}
            label={formatMessage({
              id: "dashboard::forms::twitter",
              defaultMessage: "Twitter",
            })}
          />
          <FieldError name="twitter" />
        </StyledField>

        <StyledField>
          <Field
            data-testid="input-youtube"
            id="youtube"
            name="youtube"
            component={TextInput}
            className="float-container"
            value={values.youtube}
            onChange={handleChange}
            onBlur={handleBlur}
            label={formatMessage({
              id: "dashboard::forms::youtube",
              defaultMessage: "Youtube",
            })}
          />
          <FieldError name="youtube" />
        </StyledField>
      </StyledSocialProfileWrapper>
    </>
  )
}

export default SocialProfileFieldSet
