import React, { useRef, useEffect } from "react"
import { FormattedMessage, navigate } from "gatsby-plugin-intl"
import { useLocation } from "@reach/router"
import { Button } from "@tmu/components/common"
import { StyledSliceSupportWin } from "./index.styles"
import { useAuth } from "@tmu/hooks"
import { isInViewport } from "@tmu/utils/viewport"

import queryString from "query-string"

const SupportAndWin = () => {
  const ref = useRef()
  const { hash, search } = useLocation()
  const { isAuthenticated } = useAuth()

  const handleClick = (targetLink) => {
    navigate(`/${targetLink}`)
  }

  const handleRegister = () => {
    const referralCode = queryString.parse(search)?.referral
    navigate(
      `/signup${
        referralCode && referralCode !== "undefined"
          ? `?referral="${referralCode}`
          : ""
      }`
    )
  }

  const hashCallback = () => {
    const hashText = "#support-and-win"
    const isHashInUrl = hash === hashText || window?.location?.hash === hashText
    if (isInViewport(ref) && !isHashInUrl)
      history.pushState(null, null, hashText)
  }

  useEffect(() => {
    document.addEventListener("scroll", hashCallback)
    return () => document.removeEventListener("scroll", hashCallback)
  }, [])

  return (
    <StyledSliceSupportWin
      data-testid="slice-support-and-win"
      id="support-and-win"
      ref={ref}>
      <div data-testid="text-supportGetPacs">
        <FormattedMessage
          id="supportAndWin::title"
          defaultMessage="Support & Win"
          tagName="h3"
        />

        <FormattedMessage
          id="supportAndWin::description"
          defaultMessage="Fund vetted campaigns, obtain Passions Coins (PACs) and enjoy doing good with trusted names while saving money when shopping for the brands you love. A real win-win, isn't it?"
          tagName="p"
        />

        {!isAuthenticated && (
          <Button
            color="red"
            label="Sign Up"
            onClick={handleRegister}
            data-testid="signup">
            <FormattedMessage
              id="supportAndWin::signUp"
              defaultMessage="Sign Up"
            />
          </Button>
        )}
        <Button
          data-testid="btn-learnHow"
          label="Learn How"
          onClick={() => handleClick("how-it-works")}>
          <FormattedMessage
            id="supportAndWin::learnHow"
            defaultMessage="Learn How"
          />
        </Button>
      </div>
    </StyledSliceSupportWin>
  )
}

export default SupportAndWin
