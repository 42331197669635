import React, { useState } from "react"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { useFormikContext } from "formik"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons/faQuestionCircle"
import {
  StyledSeatWrapper,
  StyledTicketText,
  StyledExplanationText,
  StyledTicketCheckboxContainer,
} from "./index.styles"
import { Checkbox, TextInput } from "@tmu/components/common"
import { Tooltip } from "react-tooltip"

const defaultExplanation =
  "When the limit is reached, we display “Sold out” sign and this ticket is no longer available, while donations are possible."

const SeatComponent = ({ index }) => {
  const { formatMessage } = useIntl()
  const [isExplanationVisible, setExplanationVisible] = useState(false)
  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    setValues,
  } = useFormikContext()
  const handleCheckBoxChange = () => {
    setFieldValue(`tiers[${index}]`, {
      ...values.tiers[index],
      isUnlimitedTickets: !values.tiers?.[index]?.isUnlimitedTickets,
      maxTickets: "",
    })
  }

  return (
    <StyledSeatWrapper>
      <StyledTicketText>
        <FormattedMessage
          id="common::seat::ticketsAvailable"
          defaultMessage="tickets available"
        />
      </StyledTicketText>
      <Tooltip
        place="left"
        type="info"
        effect="solid"
        arrowColor="transparent"
        textColor="black"
        backgroundColor="white"
        id="maxTickets"
        className="tooltip"
        delayShow={200}
      />
      <TextInput
        newDesign
        data-testid={`tiers[${index}].maxTickets`}
        inputDataTip={formatMessage({
          id: "dashboard::campaign::ticketsAvailableToolTip",
          defaultMessage:
            "How many people you are willing to invite with this ticket option",
        })}
        inputDataFor={`tiers[${index}].maxTickets`}
        id={`tiers[${index}].maxTickets`}
        name={`tiers[${index}].maxTickets`}
        value={values.tiers?.[index]?.maxTickets}
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors?.tiers?.[index]?.maxTickets}
        touched={touched?.tiers?.[index]?.maxTickets}
        type="number"
        min={0}
        label=""
        disabled={!!values.tiers?.[index]?.isUnlimitedTickets}
        placeholder={formatMessage({
          id: "common::seat::typeAmount",
          defaultMessage: "Type the amount",
        })}
      />
      <StyledTicketCheckboxContainer>
        <Checkbox
          label={formatMessage({
            id: "common::seat::unlimited",
            defaultMessage: "Unlimited",
          })}
          onChange={handleCheckBoxChange}
          id={`tiers[${index}].isUnlimitedTickets`}
          name={`tiers[${index}].isUnlimitedTickets`}
          isChecked={values.tiers?.[index]?.isUnlimitedTickets}
        />
        <FontAwesomeIcon
          icon={faQuestionCircle}
          onMouseEnter={() => setExplanationVisible(true)}
          onMouseLeave={() => setExplanationVisible(false)}
          onClick={() => setExplanationVisible(!isExplanationVisible)}
        />
        <StyledExplanationText isUnlisted={isExplanationVisible}>
          <FormattedMessage
            id="common::seat::defaultExplanation"
            defaultMessage={defaultExplanation}
          />
        </StyledExplanationText>
      </StyledTicketCheckboxContainer>
    </StyledSeatWrapper>
  )
}

export default SeatComponent
