import { REQUIRED_FIELD_SYMBOL } from "@tmu/apollo/constants"
import { PhoneInput, TextInput } from "@tmu/components/common"
import { Spacer } from "@tmu/global/page-addons/detail-page.styles"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { useFormikContext } from "formik"
import { useIntl } from "gatsby-plugin-intl"
import { StyledSingleDonation } from "./index.styles"

const IndividualCompanyForm = ({ isCompany, isPhoneRequired }) => {
  const { formatMessage } = useIntl()

  const {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    setTouched,
  } = useFormikContext()

  const { isTablet, isDesktop, isWide } = getAllScreenTypes()

  return (
    <StyledSingleDonation>
      <TextInput style={{ display: "none" }} newDesign />
      <TextInput
        newDesign
        data-testid="input-firstName"
        id="firstName"
        name="firstName"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values?.firstName || ""}
        label={`${formatMessage({
          id: !isCompany ? "signUp::firstName" : "signUp::companyName",
          defaultMessage: !isCompany ? "First Name" : "Company Name",
        })}${REQUIRED_FIELD_SYMBOL}`}
        placeholder={formatMessage({
          id: !isCompany ? "signUp::firstName" : "signUp::companyName",
          defaultMessage: !isCompany ? "First Name" : "Company Name",
        })}
        error={errors.firstName}
        touched={touched.firstName}
      />
      <Spacer bottom={isWide ? 2.5 : isTablet || isDesktop ? 2 : 1.5} />
      {!isCompany && (
        <>
          <TextInput
            newDesign
            data-testid="input-lastName"
            id="lastName"
            name="lastName"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values?.lastName || ""}
            label={`${formatMessage({
              id: "signUp::lastName",
              defaultMessage: "Last Name",
            })}${REQUIRED_FIELD_SYMBOL}`}
            placeholder={formatMessage({
              id: "signUp::lastName",
              defaultMessage: "Last Name",
            })}
            error={errors.lastName}
            touched={touched.lastName}
          />
          <Spacer bottom={isWide ? 2.5 : isTablet || isDesktop ? 2 : 1.5} />
        </>
      )}
      <TextInput
        newDesign
        data-testid="input-taxId"
        id="taxId"
        name="taxId"
        type="taxId"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values?.taxId || ""}
        label={`${formatMessage({
          id: !isCompany ? "signUp::taxId" : "signUp::vat",
          defaultMessage: !isCompany ? "Fiscal Code" : "VAT",
        })}${REQUIRED_FIELD_SYMBOL}`}
        placeholder={formatMessage({
          id: !isCompany ? "signUp::fiscalCodePlaceHolder" : "signUp::vat",
          defaultMessage: !isCompany ? "Fiscal Code" : "VAT",
        })}
        error={errors.taxId}
        touched={touched.taxId}
      />
      <Spacer bottom={isWide ? 2.5 : isTablet || isDesktop ? 2 : 1.5} />
      <PhoneInput
        title={`${formatMessage({
          id: "dashboard::profile::phone",
          defaultMessage: "phone number",
        })}${isPhoneRequired ? REQUIRED_FIELD_SYMBOL : ""}`}
        name="phoneNumber"
        isRequired={isPhoneRequired}
        value={values?.phoneNumber || ""}
        errors={errors}
        touched={touched}
        onChange={(phone) => setFieldValue("phoneNumber", phone, true)}
        onBlur={() => setTouched({ ...touched, phoneNumber: true })}
      />
      <Spacer bottom={isWide ? 2.5 : isTablet || isDesktop ? 2 : 1.5} />
    </StyledSingleDonation>
  )
}

export default IndividualCompanyForm
