import React from "react"
import PropTypes from "prop-types"
import { Global, css } from "@emotion/react"
import theme from "@tmu/global/theme"

const GlobalStyles = () => (
  <Global
    styles={css`
      :root {
        font-size: 1rem;
      }
      * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: ${theme.fontFamilies.regular};
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
      }

      html {
        height: 100%;
        margin: 0;
      }

      body {
        width: 100vw;
        overflow-x: hidden;
        margin: 0;
        font-family: ${theme.fontFamilies.regular};
        letter-spacing: 0;
        -webkit-font-smoothing: antialiased;
      }

      a {
        border: 0;
        margin: auto;
        text-decoration: none;
        color: inherit;
        &:hover {
          border-image-slice: 1;
        }
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: ${theme.fontFamilies.headline};
        color: ${theme.colors.gondola};
        width: 100%;
      }

      h1 {
        font-size: 2.25rem;
        margin-bottom: 1.875rem;
      }

      h2 {
        font-size: 2rem;
        margin-bottom: 1.875rem;
      }

      h3 {
        font-size: 1.75rem;
        margin-bottom: 0.9375rem;
      }

      h4 {
        font-size: 1.5rem;
      }

      h5 {
        font-size: 1.25rem;
      }

      h6 {
        font-size: 1.15rem;
      }

      p {
        color: ${theme.colors.primaryBrandTextColor};
        white-space: pre-line;
      }
      input {
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 1.875rem ${theme.colors.white} inset !important;
          box-shadow: 0 0 0 1.875rem ${theme.colors.white} inset !important;
        }
      }
      table {
        width: auto !important;
        min-width: 100%;
        display: table;
      }

      ul,
      ol {
        margin: 0;
        padding: 0;
      }

      fieldset {
        border: none;
      }

      button:focus {
        outline: none;
      }

      hr {
        border: none;
        width: 100%;
        height: 0.0625rem;
        margin: 2rem 0;
        background-color: ${theme.colors.darkGrey};
      }
      .svg-inline--fa {
        height: 1rem;
        @media screen and (min-width: ${theme.breakpoints.wide}) {
          height: 1.5rem;
        }
      }

      .form-error {
        border: 0.0625rem solid ${theme.colors.red};
      }
      .form-error-react-select .react-select__control {
        border: 0.0625rem solid ${theme.colors.red};
      }

      .input-feedback {
        position: relative;
        bottom: -0.25rem;
        left: 0;
        color: ${theme.colors.red};
        font-size: 0.75rem;
        text-align: left;
        width: 100%;
        @media screen and (min-width: ${theme.breakpoints.wide}) {
          font-size: 1rem;
        }
      }

      .input-feedback-absolute {
        position: absolute;
        bottom: -1.25rem;
        left: 0;
        color: ${theme.colors.red};
        font-size: 0.75rem;
        text-align: left;
        width: max-content;
        @media screen and (min-width: ${theme.breakpoints.wide}) {
          font-size: 1rem;
          bottom: -1.5rem;
        }
      }

      .Toastify__toast-theme--colored.Toastify__toast--info {
        background: ${theme.colors.blue};
      }

      .Toastify__toast-theme--colored.Toastify__toast--success {
        background: ${theme.colors.blue};
      }

      .Toastify__toast-theme--colored.Toastify__toast--error {
        background: ${theme.colors.red};
      }

      .Toastify__toast-theme--colored.Toastify__toast--warning {
        background: ${theme.colors.yellow};
      }

      .Toastify__toast-container--top-left,
      .Toastify__toast-container--top-center,
      .Toastify__toast-container--top-right {
        top: 5rem;
      }

      .html-text {
        * {
          color: ${theme.colors.disabled};
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-family: ${theme.fontFamilies.headline};
        }

        h3 {
          font-size: 1.75rem;
        }

        ul,
        ol {
          margin: 0;
          padding: 0;
          line-height: 1.6;
          margin-bottom: 1.25rem;
        }

        p {
          font-size: 1rem;
          line-height: 1.75rem;
          display: inline-block;
        }
      }

      ul,
      ol {
        list-style-position: inside;

        li {
          p {
            display: inline-block;
          }
        }
      }

      form {
        h3 {
          margin-bottom: 1.5625rem;
        }

        p {
          margin-bottom: 1.25rem;
        }
        .float-container {
          margin-bottom: 1.5rem;
        }
        .info-area {
          height: 12rem !important;
        }
      }

      strong {
        font-weight: ${theme.fontWeights.bold};
      }

      .page-wrapper {
        margin: 0 auto;
        width: 100%;
        padding-top: 0;
        @media screen and (min-width: ${theme.breakpoints.tablet}) {
          min-height: 37vh;
        }
      }
      .ReactModal__Overlay {
        z-index: 9999;
        background: rgba(0, 0, 0, 0.4) !important;
        .ReactModal__Content {
          max-width: 48rem;
          overflow: visible;
          @media screen and (min-width: ${theme.breakpoints.desktop}) {
            max-width: 37.75rem;
          }
          @media screen and (min-width: ${theme.breakpoints.wide}) {
            max-width: 56rem;
          }
        }
      }

      a.download-link,
      button.download-link {
        font-size: 1.2rem;
        margin: 0;
        padding: 0;
      }

      .account-info-message {
        padding: 0.625rem 0.625rem;
      }

      .disabled {
        pointer-events: none;
        opacity: 0.6;
      }

      .hide {
        display: none !important;
      }

      .react-paginate li.break > a > span {
        margin: 0;
        letter-spacing: 0.2rem;
      }

      // source: https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css
      .slick-list,
      .slick-slider,
      .slick-track {
        position: relative;
        display: block;
      }
      .slick-loading .slick-slide,
      .slick-loading .slick-track {
        visibility: hidden;
      }
      .slick-slider {
        box-sizing: border-box;
        user-select: none;
        touch-action: pan-y;
        -webkit-tap-highlight-color: transparent;
      }
      .slick-list {
        overflow: hidden;
        margin: 0;
        padding: 0;
      }
      .slick-list:focus {
        outline: 0;
      }
      .slick-list.dragging {
        cursor: pointer;
        cursor: hand;
      }
      .slick-slider .slick-list,
      .slick-slider .slick-track {
        transform: translate3d(0, 0, 0);
      }
      .slick-track {
        top: 0;
        left: 0;
      }
      .slick-track:after,
      .slick-track:before {
        display: table;
        content: "";
      }
      .slick-track:after {
        clear: both;
      }
      .slick-slide {
        display: none;
        float: left;
        height: 100%;
        min-height: 0.0625rem;
      }
      .slick-slide img {
        display: block;
      }
      .slick-slide.slick-loading img {
        display: none;
      }
      .slick-slide.dragging img {
        pointer-events: none;
      }
      .slick-initialized .slick-slide {
        display: block;
      }
      .slick-vertical .slick-slide {
        display: block;
        height: auto;
        border: 0.0625rem solid transparent;
      }
      .slick-arrow.slick-hidden {
        display: none;
      }

      // source: https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css
      .slick-dots,
      .slick-next,
      .slick-prev {
        position: absolute;
        display: block;
        padding: 0;
      }
      .slick-loading .slick-list {
        background: url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/ajax-loader.gif)
          center center no-repeat ${theme.colors.white};
      }
      .slick-next,
      .slick-prev {
        font-size: 0;
        line-height: 0;
        top: 50%;
        width: 1.25rem;
        height: 1.25rem;
        transform: translate(0, -50%);
        cursor: pointer;
        color: transparent;
        border: none;
        outline: 0;
        background: 0 0;
      }
      .slick-next:before,
      .slick-prev:before {
        font-size: 1.25rem;
        line-height: 1;
        color: ${theme.colors.white};
        background-color: ${theme.colors.pacificBlue};
        border-radius: 50%;
        display: flex;
        font-weight: ${theme.fontWeights.black};
        justify-content: center;
        align-items: center;
      }
      .slick-prev {
        left: -2.5625rem;
      }
      .slick-prev:before {
        content: "<";
      }
      .slick-next:before {
        content: ">";
      }
      .slick-next {
        right: -2.5625rem;
      }
      .slick-dotted.slick-slider {
        margin-bottom: 1.875rem;
      }
      .slick-dots {
        bottom: -1.5625rem;
        width: 100%;
        margin: 0;
        list-style: none;
        text-align: center;
      }
      .slick-dots li {
        position: relative;
        display: inline-block;
        width: 1.25rem;
        height: 1.25rem;
        margin: 0 0.3125rem;
        padding: 0;
        cursor: pointer;
      }
      .slick-dots li button {
        font-size: 0;
        line-height: 0;
        display: block;
        width: 1.25rem;
        height: 1.25rem;
        padding: 0.3125rem;
        cursor: pointer;
        color: transparent;
        border: 0;
        outline: 0;
        background: 0 0;
      }
      .slick-dots li button:focus,
      .slick-dots li button:hover {
        outline: 0;
      }
      .slick-dots li button:focus:before,
      .slick-dots li button:hover:before {
        opacity: 1;
      }
      .slick-dots li button:before {
        font-size: 1.25rem;
        line-height: 1.25rem;
        position: absolute;
        top: 0;
        left: 0;
        width: 1.25rem;
        height: 1.25rem;
        content: "•";
        text-align: center;
        opacity: 0.25;
        color: ${theme.colors.black};
      }
      .slick-dots li.slick-active button:before {
        opacity: 0.75;
        color: ${theme.colors.pacificBlue};
      }
      .react-datepicker__close-icon::after {
        font-size: 1rem !important;
        position: absolute;
        top: 1rem;
        left: 0.5rem;
      }

      .sticky-bottom-bar {
        padding: 1rem;
        position: fixed;
        background: ${theme.colors.white};
        bottom: 0;
        margin: 0 !important;
        width: 100%;
        filter: drop-shadow(
          0 0.375rem 1rem ${theme.colors.stickyBarShadowColor}
        );
        > button {
          margin: 0;
          width: 100%;
          border-radius: 0.25rem;
          white-space: pre-line;
          padding: 0 1rem;
          &.edit {
            margin-top: 0.4rem;
          }
        }
        z-index: 999;
        left: 0;
        right: 0;
      }

      .inline {
        display: inline-block;
      }
      .caption {
        font-family: ${theme.fontFamilies.headlineBig};
        font-style: ${theme.fontStyles.normal};
        font-weight: ${theme.fontWeights.liteBlack};
        font-size: 0.625rem;
        line-height: 1rem;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        color: ${theme.colors.disabled};
        @media screen and (min-width: ${theme.breakpoints.wide}) {
          font-size: 1rem;
        }
      }
      .tooltip {
        box-shadow: 0 0.25rem 0.75rem ${theme.colors.primaryTextColorLight};
        // opacity: 1 !important;
      }

      .react-tooltip {
        box-shadow: 0 0.25rem 0.75rem ${theme.colors.primaryTextColorLight};
        background: ${theme.colors.white} !important;
        color: ${theme.colors.pitchBlack} !important;
        z-index: 999 !important;
      }
      .react-select__control_text_input {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        height: 3rem;
        @media screen and (min-width: ${theme.breakpoints.wide}) {
          height: 3.375rem;
        }
      }

      .register-info-toast {
        &.Toastify__toast {
          margin-bottom: 0.5rem;

          @media screen and (min-width: ${theme.breakpoints.tablet}) {
            padding: 2rem;
            width: 27.5rem;
            right: 8rem;
          }

          @media screen and (min-width: ${theme.breakpoints.wide}) {
            padding: 2.5rem;
            width: 39.375rem;
            right: 20.5rem;
          }
        }

        .Toastify__close-button--light {
          opacity: unset;
        }

        .Toastify__close-button > svg {
          position: absolute;
          right: 2.0313rem;
          top: 2.75rem;

          @media screen and (min-width: ${theme.breakpoints.tablet}) {
            width: 1.5rem;
            height: 1.5rem;
          }

          @media screen and (min-width: ${theme.breakpoints.wide}) {
            right: 2.8125rem;
            top: 3.125rem;
            width: 2.25rem;
            height: 2.25rem;
          }
        }
      }

      .register-info-toast-free-account-title {
        h4 {
          font-family: ${theme.fontFamilies.headline};
          font-style: ${theme.fontStyles.normal};
          font-weight: ${theme.fontWeights.semiBolder};

          @media screen and (min-width: ${theme.breakpoints.tablet}) {
            font-size: 1.5rem;
            line-height: 2.125rem;
          }

          @media screen and (min-width: ${theme.breakpoints.wide}) {
            font-size: 2rem;
            line-height: 2.75rem;
          }
        }

        > p {
          font-family: ${theme.fontFamilies.regular};
          font-style: ${theme.fontStyles.normal};
          font-weight: ${theme.fontWeights.liteMedium};
          margin-top: 1.5rem;

          @media screen and (min-width: ${theme.breakpoints.tablet}) {
            font-size: 1rem;
            line-height: 1.5rem;
          }

          @media screen and (min-width: ${theme.breakpoints.wide}) {
            font-size: 1.5rem;
            line-height: 2.25rem;
          }

          .pacs {
            color: ${theme.colors.carrot};
          }
        }
      }

      .register-info-toast-create-account-btn {
        font-family: ${theme.fontFamilies.headline};
        font-style: ${theme.fontStyles.normal};
        font-weight: ${theme.fontWeights.semiBolder};
        background-color: ${theme.colors.blue};
        color: ${theme.colors.white};
        line-height: normal;
        border-radius: 0.25rem;

        @media screen and (min-width: ${theme.breakpoints.tablet}) {
          height: 2.5rem;
          font-size: 0.75rem !important;
          letter-spacing: 0.48px;
        }

        @media screen and (min-width: ${theme.breakpoints.wide}) {
          height: 3.375rem;
          font-size: 1rem !important;
          letter-spacing: 0.04rem;
        }

        width: 100%;
        margin: 1.5rem 0 !important;
      }

      .register-info-toast-flex {
        display: flex;
        flex-direction: row;
        justify-content: center;
        column-gap: 0.5rem;

        span {
          font-family: ${theme.fontFamilies.regular};
          font-style: ${theme.fontStyles.normal};
          font-weight: ${theme.fontWeights.liteMedium};
          color: ${theme.colors.pitchBlack};

          @media screen and (min-width: ${theme.breakpoints.tablet}) {
            font-size: 1rem;
            line-height: 1.5rem;
          }

          @media screen and (min-width: ${theme.breakpoints.wide}) {
            font-size: 1.5rem;
            line-height: 2.25rem;
          }
        }
      }

      .register-info-toast-sign-in-btn .link-button-text span {
        font-family: ${theme.fontFamilies.regular};
        font-style: ${theme.fontStyles.normal};
        font-weight: ${theme.fontWeights.liteMedium};
        color: ${theme.colors.blue};

        @media screen and (min-width: ${theme.breakpoints.tablet}) {
          font-size: 1rem;
          line-height: 1.5rem;
        }

        @media screen and (min-width: ${theme.breakpoints.wide}) {
          font-size: 1.5rem;
          line-height: 2.25rem;
        }
      }
    `}
  />
)

export const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    lineHeight: 3,
    display: "flex",
    alignItems: "center",
    justifyContent: "spaceBetween",
  },
}

GlobalStyles.propTypes = {
  hasPaddingTop: PropTypes.bool.isRequired,
}

GlobalStyles.defaultProps = {
  hasPaddingTop: false,
}

export const cardElementStyles = {
  base: {
    iconColor: "rgba(10, 157, 189, 1)",
    fontSize: "0.9375rem",
    fontWeight: "normal",
    fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
    color: `${theme.colors.primaryTextColor}`,
    "::placeholder": {
      color: `${theme.colors.dimGray}`,
    },
  },
  complete: {
    color: `${theme.colors.primaryTextColor}`,
  },
  invalid: {
    iconColor: `${theme.colors.brown}`,
    color: `${theme.colors.brown}`,
  },
}

export default GlobalStyles
