import React from "react"
import { StyledLogoText } from "./index.styles"
import theme from "@tmu/global/theme"

const FooterLogoText = ({
  width = 170,
  height = 33,
  fill = theme?.colors?.blue,
  flat,
  ...props
}) => {
  return (
    <StyledLogoText
      viewBox="0 0 1272.33 663.7"
      width={width}
      height={height}
      color={fill}
      {...props}>
      <defs>
        <clipPath id="clip-path">
          <path
            className="cls-1"
            d="M1008.47,426.71v62.57c0,25.61,7.6,42.61,37.81,42.61a36.88,36.88,0,0,0,31.61-17.21h.6v14.41h27V480.45h.16V427.14h-28.56v52.74c0,21-6.6,29.6-22,29.6-13.2,0-18.2-8.4-18.2-25.4V426.71Zm186,51c0-16.81,6-33.41,24.61-33.41,18.2,0,24.8,17,24.8,33.41,0,16.8-6,32.8-24.6,32.8-18.41,0-24.81-16-24.81-32.8m0-38.81h-.4V425.66h-27V565.5h28.41V516.28h.4c6.8,10.21,18.61,15.61,31,15.61,30.21,0,45.41-25.81,45.41-53.41,0-29.41-14.6-55.61-47-55.61-12.8,0-24,5-30.81,16"
          />
        </clipPath>
        <clipPath id="clip-path-2">
          <path
            className="cls-1"
            d="M633.78,0C519.1,0,426.14,93,426.14,207.64H516.2c.07-.95.79-1.92,2.16-3.27,2.68-2.63,5.42-5.18,8.21-7.69C537.11,187.21,548,178,558.2,168.27c20.12-19.18,39.6-39.06,62.29-55.35,9.61-6.9,19-14.23,28.83-20.71,15.94-10.45,33.45-14.73,52.23-9.76,20.14,5.31,35.52,16.77,44.11,36.38A65.28,65.28,0,0,1,751,151.4c-1.19,13.07-7.21,24.29-14.24,35-4,6.08-8.18,12-12.5,17.86a6,6,0,0,0-1.44,3.37h118.6C841.42,93,748.45,0,633.78,0Z"
          />
        </clipPath>
        <clipPath id="clip-path-3">
          <path
            className="cls-1"
            d="M669.87,207.64,683.72,264a8.76,8.76,0,0,1-6.42,10.58,8.5,8.5,0,0,1-2.07.25H632.35a8.75,8.75,0,0,1-8.74-8.76,8.5,8.5,0,0,1,.25-2.07l13.85-56.39H516.21c-.08,1,.6,2,2.08,3.38,5.94,5.47,12.13,10.66,18,16.17,16.19,15.15,31.89,30.84,48.54,45.48,15.73,13.83,32.31,26.73,48.73,39.78a129,129,0,0,0,30,18.07,52.68,52.68,0,0,0,29.15,3.76c16.34-2.46,30.69-9.18,42.27-21,8.59-8.81,13-19.92,15.34-31.82a98.78,98.78,0,0,0,1-10.45c.5-16.35-6.64-29.93-15.29-42.94q-5.73-8.64-11.92-17a5.51,5.51,0,0,1-1.33-3.42Z"
          />
        </clipPath>
        <clipPath id="clip-path-4">
          <path
            className="cls-1"
            d="M654.75,140c-.33,0-.63.08-1,.08s-.63-.07-1-.08h-.1a34.57,34.57,0,0,0-33.15,34.24,34.22,34.22,0,0,0,18.84,30.49l-.66,2.69h32.07l-.66-2.69A34.19,34.19,0,0,0,688,174.27,34.57,34.57,0,0,0,654.85,140Z"
          />
        </clipPath>
      </defs>
      <title>Asset 1_svg</title>
      <g id="Livello_1" data-name="Livello 1">
        <path
          className="cls-2"
          d="M53.47,530.29a108.43,108.43,0,0,0,16.6-1v-22.2a44.07,44.07,0,0,1-8.8.8c-9.6,0-12-2.4-12-12V444.67h20.8v-19H49.27v-31H20.86v31H3.66v19h17.2v61c0,20.6,15.21,24.6,32.61,24.6m126.63-81a52.18,52.18,0,0,1,10.2,1v-26.4a21.1,21.1,0,0,0-6.2-1c-13.81,0-27.81,9-33,22h-.4v-19.2h-27V529.09h28.41V482.48c0-18.2,7.2-33.2,28-33.2m131.43,79.81h27V425.67H310.12v54.21c0,21-6.6,29.6-22,29.6-13.2,0-18.2-8.4-18.2-25.4V425.67H241.51v63.62c0,25.6,7.6,42.6,37.81,42.6a36.91,36.91,0,0,0,31.61-17.2h.6ZM426,451.48c0-8.41,9.4-9.61,15.8-9.61,10.2,0,18.6,3,19.41,14h27c-2.2-25.41-23-33-45.61-33-22.2,0-45,6.8-45,33.21,0,18.2,16.41,24,32.81,27.6,20.4,4.4,32.2,7.4,32.2,16,0,10-10.4,13.21-18.6,13.21-11.2,0-21.6-5-21.8-17.4h-27c1.2,27.4,24.61,36.4,48.61,36.4,23.61,0,47.21-8.8,47.21-36,0-19.21-16.2-25.21-32.81-29-16.2-3.8-32.2-5.2-32.2-15.4M587.85,530.3a108.56,108.56,0,0,0,16.61-1V507.1a44.1,44.1,0,0,1-8.81.8c-9.6,0-12-2.4-12-12V444.67h20.81v-19H583.58v-31H555.17v31H538v19h17.2v61c0,20.6,15.21,24.6,32.61,24.6m201.44-1.2h12.61v-70c0-24.41-11.61-36.21-34.21-36.21-13.6,0-26.61,6.6-32.81,20.2-3.8-13.6-16-20.2-29.6-20.2-16.41,0-27.21,8-33.81,20.4h-.6v-17.4H659.2V529.09h12.6V471.48c.2-21.61,9-38,30.81-38,16,0,21.8,11.6,21.6,25.61v70h12.6V470.68c0-19.2,6.2-37.21,28-37.21,18,0,24.4,9.2,24.4,26.21Zm84-58.81c2-18.8,13.41-36.81,34-36.81,20.4,0,32.21,17.8,32.81,36.81Zm0,10.6h79.42c1-29-12.2-58-45.41-58-32.81,0-46.61,27.4-46.61,54.61,0,29.41,13.8,54.61,46.61,54.61,26,0,39.61-13.6,45-37h-12.6c-4,15.6-14,26.4-32.41,26.4-24.21,0-33.81-22.21-34-40.61"
        />
        <path
          className="cls-3"
          d="M1008.47,426.71v62.57c0,25.61,7.6,42.61,37.81,42.61a36.88,36.88,0,0,0,31.61-17.21h.6v14.41h27V480.45h.16V427.14h-28.56v52.74c0,21-6.6,29.6-22,29.6-13.2,0-18.2-8.4-18.2-25.4V426.71Zm186,51c0-16.81,6-33.41,24.61-33.41,18.2,0,24.8,17,24.8,33.41,0,16.8-6,32.8-24.6,32.8-18.41,0-24.81-16-24.81-32.8m0-38.81h-.4V425.66h-27V565.5h28.41V516.28h.4c6.8,10.21,18.61,15.61,31,15.61,30.21,0,45.41-25.81,45.41-53.41,0-29.41-14.6-55.61-47-55.61-12.8,0-24,5-30.81,16"
        />
        <g className="cls-4">
          <rect
            className="cls-3"
            x="1008.47"
            y="422.87"
            width={width}
            height={height}
          />
        </g>
        <path
          className="cls-3"
          d="M633.78,0C519.1,0,426.14,93,426.14,207.64H516.2c.07-.95.79-1.92,2.16-3.27,2.68-2.63,5.42-5.18,8.21-7.69C537.11,187.21,548,178,558.2,168.27c20.12-19.18,39.6-39.06,62.29-55.35,9.61-6.9,19-14.23,28.83-20.71,15.94-10.45,33.45-14.73,52.23-9.76,20.14,5.31,35.52,16.77,44.11,36.38A65.28,65.28,0,0,1,751,151.4c-1.19,13.07-7.21,24.29-14.24,35-4,6.08-8.18,12-12.5,17.86a6,6,0,0,0-1.44,3.37h118.6C841.42,93,748.45,0,633.78,0Z"
        />
        <g className="cls-5">
          <rect className="cls-3" x="426.14" width="415.28" height="207.64" />
          <path
            className="cls-3"
            d="M633.78,15V14c107,0,193.66,86.7,193.66,193.66S740.73,401.31,633.78,401.31,440.11,314.6,440.11,207.64,526.82,14,633.78,14v2.11c-105.79,0-191.55,85.76-191.55,191.55S528,399.21,633.78,399.21s191.55-85.76,191.55-191.55v0C825.2,101.9,739.52,16.22,633.78,16.09Z"
          />
        </g>
        <path
          className="cls-3"
          d="M669.87,207.64,683.72,264a8.76,8.76,0,0,1-6.42,10.58,8.5,8.5,0,0,1-2.07.25H632.35a8.75,8.75,0,0,1-8.74-8.76,8.5,8.5,0,0,1,.25-2.07l13.85-56.39H516.21c-.08,1,.6,2,2.08,3.38,5.94,5.47,12.13,10.66,18,16.17,16.19,15.15,31.89,30.84,48.54,45.48,15.73,13.83,32.31,26.73,48.73,39.78a129,129,0,0,0,30,18.07,52.68,52.68,0,0,0,29.15,3.76c16.34-2.46,30.69-9.18,42.27-21,8.59-8.81,13-19.92,15.34-31.82a98.78,98.78,0,0,0,1-10.45c.5-16.35-6.64-29.93-15.29-42.94q-5.73-8.64-11.92-17a5.51,5.51,0,0,1-1.33-3.42Z"
        />
        <g className="cls-6">
          <rect
            className="cls-3"
            x="516.13"
            y="207.64"
            width={width}
            height={height}
          />
        </g>
        <path
          className="cls-3"
          d="M654.75,140c-.33,0-.63.08-1,.08s-.63-.07-1-.08h-.1a34.57,34.57,0,0,0-33.15,34.24,34.22,34.22,0,0,0,18.84,30.49l-.66,2.69h32.07l-.66-2.69A34.19,34.19,0,0,0,688,174.27,34.57,34.57,0,0,0,654.85,140Z"
        />
        <g className="cls-7">
          <rect
            className="cls-3"
            x="619.55"
            y="140.03"
            width="68.47"
            height="67.42"
          />
        </g>
        <g className="cls-8">
          <text className="cls-9" transform="translate(0 641.51)">
            REWARDS FOR DOING GOOD
          </text>
        </g>
      </g>
    </StyledLogoText>
  )
}

export default FooterLogoText
