import React from "react"

import { StyledGetStarted, StyledDescription, StyledImg } from "./index.styles"

const GetStarted = ({ description, image }) => {
  return (
    <StyledGetStarted>
      {description && <StyledDescription>{description}</StyledDescription>}
      {image && <StyledImg src={image} alt="getStartedPersonal" />}
    </StyledGetStarted>
  )
}

export default GetStarted
