import React, { useEffect, useState } from "react"
import { Dropdown } from "@tmu/components/common"
import theme from "@tmu/global/theme"
import { useAllOfferCategories } from "@tmu/hooks"
import { getValueForLocale } from "@tmu/utils/string"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { useIntl } from "gatsby-plugin-intl"
import { components } from "react-select"
import { StyledLine } from "../Table/index.styles"
import {
  StyledGroupHeading,
  StyledGroupHeadingContainer,
  StyledGroupHeadingIcon,
  StyledOption,
  StyledOptionLabel,
  StyledRegionContainer,
} from "./index.styles"

const GroupedCategorySubcategorySelect = ({
  selectedCategoryItems,
  onSelect,
  hideLine = false,
}) => {
  const { organizedCategories } = useAllOfferCategories()
  //const [categories, setCategories] = useState([])
  const [selectedCategory, setSelectedCategory] = useState()
  //const [selectedCategories, setSelectedCategories] = useState([])
  const [selectedCategoryIds, setSelectedCategoryIds] = useState([])
  const { locale, defaultLocale, formatMessage } = useIntl()
  const { isTablet, isWide } = getAllScreenTypes()

  const groupedCategories = []

  organizedCategories?.forEach((item) => {
    groupedCategories.push({
      ...item,
      value: item?.id,
      label: getValueForLocale(item, "name", locale, defaultLocale),
    })
    item?.children?.forEach((child) => {
      groupedCategories.push({
        ...child,
        value: child?.id,
        label: getValueForLocale(child, "name", locale, defaultLocale),
      })
    })
  })

  /*
  useEffect(() => {
    if (!selectedCategory && !selectedSubCategory) {
      const selectedOne = selected?.[0]
      const category = categories?.find(
        (item) => item?.id !== "-1" && item?.slug === selectedOne
      )
      if (category) {
        setSelectedCategory(category)

        setInitialSelected(category)
        if (!isTablet && category?.children?.length > 0) {
        }
      }

      categories?.forEach((item) =>
        item?.children?.forEach((sub) => {
          if (sub?.id !== "-1" && sub?.slug === selectedOne) {
            setSelectedSubCategory(sub)
            setSelectedCategory(item)
            setInitialSelected(sub)
          }
        })
      )
    }

    if (!initialSelected && !selected?.length && isTablet) {
      const category = categories?.[1]
      setTempSelected(category)
    }

    const category = categories?.find(
      (item) => item?.id !== "-1" && item?.slug === selectedOne
    )
    if (category) {
      setSelectedCategory(category)
    }
  }, [categories, selected])
*/
  useEffect(() => {
    if (!selectedCategoryItems?.length) {
      return
    }
    const item = selectedCategoryItems?.[0]

    if (!item) {
      return
    }

    setSelectedCategory({
      ...item,
      value: item?.id,
      label: getValueForLocale(item, "name", locale, defaultLocale),
    })
  }, [])

  const handleSelected = (selected) => {
    /*
    const isRemove = selectedCategories?.find(
      (item) => item?.value === selected?.value
    )

    const filtered = [
      ...selectedCategories?.filter((item) => item?.value !== selected?.value),
    ]

    if (!isRemove) {
      filtered.push(selected)
    }
    setSelectedCategories(filtered)
    setSelectedCategoryIds(filtered?.map((item) => item?.value))
    */
    setSelectedCategory(selected)
  }

  useEffect(() => {
    if (!selectedCategory) {
      return
    }

    if (typeof onSelect === "function") {
      onSelect([selectedCategory?.id])
    }
  }, [selectedCategory])

  const Option = ({ children, ...props }) => {
    const optionData = props?.data
    return (
      optionData?.label && (
        <components.Option {...props}>
          {!optionData?.parent ? (
            <StyledGroupHeadingContainer
              onClick={() => {
                handleSelected(optionData)
              }}
              className={
                selectedCategoryIds?.includes(optionData?.value)
                  ? "selected"
                  : ""
              }>
              {optionData?.icon && (
                <StyledGroupHeadingIcon>
                  <i className={optionData?.icon} />
                </StyledGroupHeadingIcon>
              )}
              <StyledGroupHeading>{children}</StyledGroupHeading>
            </StyledGroupHeadingContainer>
          ) : (
            <StyledOption
              onClick={() => {
                handleSelected(optionData)
              }}>
              <StyledOptionLabel>{optionData?.label}</StyledOptionLabel>
            </StyledOption>
          )}
        </components.Option>
      )
    )
  }

  const dropdownHeight = isWide ? "3.375rem" : "3rem"

  const searchableStyles = {
    control: {
      height: dropdownHeight,
      paddingTop: "0",
      paddingRight: "0",
      fontSize: isWide ? "1.5rem" : "1rem",
      textAlign: "left",
      minWidth: "unset",
      lineHeight: isWide ? "2.25rem" : "1.25rem",
      marginTop: !isTablet && "-1.8rem",
    },
    valueContainer: {
      height: dropdownHeight,
      paddingTop: "0",
    },
    singleValue: {
      color: selectedCategory?.value
        ? theme.colors.pitchBlack
        : theme?.colors?.disabled,
    },
    inputContainer: {
      height: dropdownHeight,
      paddingTop: "0",
    },
    input: {
      padding: "0",
      margin: "0",
    },
    menuList: {
      maxHeight: !isTablet ? "35vh" : "18.75rem",
    },
    dropdownIndicator: {
      display: "none",
    },
    placeholder: {},
    menu: {},
    option: {
      backgroundColor: theme.colors.white,
      color: theme.colors.pitchBlack,
      ":hover": {
        backgroundColor: theme.colors.whiteSmoke,
      },
    },
  }

  return (
    <>
      <StyledRegionContainer>
        <Dropdown
          components={{ Option }}
          newDesign
          data-testid="category"
          id="category"
          name="category"
          value={selectedCategory}
          options={groupedCategories}
          defaultSelectStyles={searchableStyles}
          placeholder={formatMessage({
            id: "dashboard::service::chooseCategory",
            defaultMessage: "Choose category",
          })}
        />
        {!isTablet && !hideLine && <StyledLine />}
      </StyledRegionContainer>
    </>
  )
}

export default GroupedCategorySubcategorySelect
