import React from "react"
import { Router } from "@reach/router"
import OfferListing from "./OfferListing"
import OfferForm from "./OfferForm"

export default function Stores() {
  return (
    <Router>
      <OfferListing path="/" />
      <OfferForm path="/offer" />
      <OfferForm path="/:id" />
    </Router>
  )
}
