import React from "react"
import { Router } from "@reach/router"
import { CampaignTypes as CampaignTypesPage } from "@tmu/components/dashboard/dashboardPages"
import { CampaignCreation as CampaignCreationPage } from "@tmu/components/dashboard/dashboardPages"
import PrivateRoute from "@tmu/components/PrivateRoute"

const CampaignTypes = () => (
  <Router>
    <PrivateRoute default component={CampaignTypesPage} />
    <PrivateRoute component={CampaignCreationPage} path="new/*" />
    <PrivateRoute component={CampaignCreationPage} path="edit/:slug" />
  </Router>
)

export default CampaignTypes
