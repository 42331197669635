import styled from "@emotion/styled"

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`
export const StyledBigText = styled.h1`
  font-weight: ${(p) => p.theme.fontWeights.bold};
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-size: 2rem;
  line-height: 2.75rem;
  color: ${(p) => p.theme.colors.pitchBlack};
  margin-bottom: 1.75rem;
  text-align: center;
  .colored {
    color: ${(p) => p.theme.colors.carrot};
  }
`

export const StyledDescription = styled.p`
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  font-family: ${(p) => p.theme.fontFamilies.regular};

  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 2.5rem;
  text-align: center;

  .bold {
    font-weight: ${(p) => p.theme.fontWeights.bold};
  }
`
