import { gql } from "@apollo/client"

export const COUNTRIES_QUERY = gql`
  query Countries($language: String!) {
    countries(language: $language, filter: SEPA) {
      label
      shortCode
    }
  }
`

export const CITIES_QUERY = gql`
  query Cities($country: String!) {
    cities(country: $country) {
      id
      name
      shortCode
      countryCode
      countryName
    }
  }
`

export const ALL_COUNTRIES_QUERY = gql`
  query ($search: String) {
    allCountries(search: $search, first: 100, isSepa: true) {
      edges {
        node {
          id
          name
          code
          population
          area
          currency
          capital
        }
      }
    }
  }
`

export const ALL_CITIES_QUERY = gql`
  query ($search: String) {
    allCities(search: $search, first: 100, isSepa: true) {
      edges {
        node {
          id
          name
          country {
            code
            name
          }
          region {
            code
            name
          }
          kind
        }
      }
    }
  }
`

export const COUNTRIES_AND_CITIES_QUERY = gql`
  query ($search: String) {
    allCities(search: $search, first: 100, isSepa: true) {
      edges {
        node {
          id
          name
          country {
            code
            name
          }
          region {
            code
            name
          }
          kind
        }
      }
    }

    allCountries(search: $search, first: 100, isSepa: true) {
      edges {
        node {
          id
          name
          code
          population
          area
          currency
          capital
        }
      }
    }
  }
`
export const CITY_QUERY = gql`
  query ($id: ID!) {
    city(id: $id) {
      id
      name
      country {
        code
        name
      }
      region {
        code
        name
      }
      kind
    }
  }
`
export const COUNTRY_QUERY = gql`
  query ($code: String) {
    country(code: $code) {
      id
      name
      code
      population
      area
      currency
      capital
    }
  }
`
