import React from "react"
import { FormattedMessage, navigate } from "gatsby-plugin-intl"
import { CardList } from "@tmu/components/common"
import {
  StyledWrapper,
  Header,
  SliceHeader,
  StyledViewAllButton,
} from "./index.styles"

const SliceFeaturedOffers = ({ offers, isHeaderHidden = false, maxCols }) => {
  const handleViewAll = () => {
    navigate("/offers?offer=true&store=true")
  }

  return (
    <StyledWrapper data-testid="list-slice-featured-offers" id="featuredOffers">
      {!isHeaderHidden && (
        <Header>
          <SliceHeader className="slice-header">
            <FormattedMessage
              id="featuredOffers"
              defaultMessage="Featured Offers"
            />
          </SliceHeader>
        </Header>
      )}

      <CardList variant="offer" items={offers} maxCols={maxCols} />
      <StyledViewAllButton
        color="red"
        data-testid="merchant-view-all-button"
        onClick={handleViewAll}>
        <FormattedMessage id="ui::viewAll" defaultMessage="View All" />
      </StyledViewAllButton>
    </StyledWrapper>
  )
}

export default SliceFeaturedOffers
