import React from "react"
import { Router } from "@reach/router"
import { Tooltip } from "react-tooltip"

import { FormattedMessage, navigate } from "gatsby-plugin-intl"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faShoppingCart } from "@fortawesome/pro-light-svg-icons/faShoppingCart"
import { faChevronLeft } from "@fortawesome/pro-light-svg-icons/faChevronLeft"
import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons/faQuestionCircle"
import { useQuery } from "@apollo/client"
import { useAuth } from "@tmu/hooks"
import { Spinner, Button } from "@tmu/components/common"
import { OrdersTable } from "@tmu/components/dashboard/dashboardCommon"
import {
  StyledPage,
  StyledPageContent,
  StyledPageActions,
  StyledPageTitle,
} from "@tmu/global/page-addons/dashboard.styles"
import { useApolloApiClients } from "@tmu/apollo/client"
import { ORDER_DETAILS } from "@tmu/hooks/useAllOrders"
import { StyledIconWrapper, StyledInfoIcon } from "./index.styles"
const getEmptyMessage = (type) => {
  switch (type) {
    case "donors":
      return (
        <FormattedMessage
          id="dashboard::orders::donorsEmpty"
          defaultMessage="It seems that you haven't made a purchase yet."
        />
      )
    case "partners":
      return (
        <FormattedMessage
          id="dashboard::orders::partnersEmpty"
          defaultMessage="There are no orders."
        />
      )
    case "merchants":
      return (
        <FormattedMessage
          id="dashboard::orders::merchantsEmpty"
          defaultMessage="There are no orders"
        />
      )
    default:
      throw new Error("Unknown type")
  }
}

const OrderListing = ({ dashboardType, location }) => {
  const { isLoading, isAuthenticated } = useAuth()

  const handleSelectOrder = (id) => {
    const [, decodedId] = window?.atob(id).split(":")
    navigate(
      `/dashboard/${
        dashboardType === "partners" ? "charities" : "merchants"
      }/orders/${decodedId}`
    )
  }

  return (
    <StyledPage>
      <StyledPageTitle>
        <FormattedMessage
          id="dashboard::nav::purchases"
          defaultMessage="Purchases"
          tagName="h1"
        />

        <StyledIconWrapper>
          <FormattedMessage
            id="dashboard::orders::refund"
            defaultMessage="Refund"
            tagName="p"
          />
          <StyledInfoIcon data-tooltip-id="info-tooltip" data-tooltip="test">
            <FontAwesomeIcon icon={faQuestionCircle} />
          </StyledInfoIcon>
          <Tooltip
            arrowColor="transparent"
            effect="solid"
            type="info"
            id="info-tooltip"
            place="bottom"
            opacity={1}
            data-html={true}
            insecure={true}
            multiline={true}
            className="tooltip">
            <FormattedMessage
              id="dashboard::orders::refundInfo1"
              defaultMessage="To make a refund proceed to the store where you purchased and make a refund."
            />
            <div className="text-tooltip">
              <FormattedMessage
                id="dashboard::orders::refundInfo2"
                defaultMessage="Contact our support next to receive PACs that you spent."
              />
            </div>
          </Tooltip>
        </StyledIconWrapper>
      </StyledPageTitle>

      <StyledPageContent data-testid="orders-page-content">
        <OrdersTable />
      </StyledPageContent>
    </StyledPage>
  )
}

const OrderDetail = ({ id, dashboardType }) => {
  const encodedId = btoa(`OrderNode:${id}`)
  const { isLoading, isAuthenticated } = useAuth()
  const { storefrontClient } = useApolloApiClients()
  const { data, loading: loadingOrder } = useQuery(ORDER_DETAILS, {
    variables: {
      id: encodedId,
    },
    client: storefrontClient,
    skip: isLoading || !isAuthenticated,
    fetchPolicy: "cache-and-network",
  })

  const handleBack = () =>
    navigate(
      `/dashboard/${
        dashboardType === "partners" ? "charities" : "merchants"
      }/orders`
    )

  const order = parseOrderNode(data?.order ?? {})

  return (
    <StyledPage>
      <StyledPageTitle>
        <FontAwesomeIcon icon={faShoppingCart} />
        <FormattedMessage
          id="dashboard::nav::orderDetails"
          defaultMessage="Order Details"
          tagName="h1"
        />
      </StyledPageTitle>

      <StyledPageContent>
        {loadingOrder ? (
          <Spinner condensed />
        ) : (
          <>
            <OrdersTable orders={[order]} />

            <StyledPageActions align="left" data-testid="order-back-button">
              <Button label="Back" color="black" onClick={handleBack}>
                <FontAwesomeIcon icon={faChevronLeft} />
                <FormattedMessage id="ui::back" defaultMessage="Back" />
              </Button>
            </StyledPageActions>
          </>
        )}
      </StyledPageContent>
    </StyledPage>
  )
}

const Orders = ({ dashboardType, location }) => {
  return (
    <Router>
      <OrderListing dashboardType={dashboardType} default location={location} />
      <OrderDetail dashboardType={dashboardType} path=":id" />
    </Router>
  )
}

export default Orders
