import styled from "@emotion/styled"

export const StyledMediaBox = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 0.4875rem;
  transition: all 0.2s ease;
  height: 100%;
`

export const StyledImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  font-family: "blur-up: always", "object-fit: cover";
  object-fit: cover;
  overflow: hidden;
  border-radius: 0.3125rem;
`

export const StyledLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`
