import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import {
  StyledUserTableHeader,
  StyledUserTableMobileHeader,
} from "./index.styles"

const UserTableHeader = () => {
  const { isTablet } = getAllScreenTypes()

  return isTablet ? (
    <StyledUserTableHeader data-testid="user-table-header">
      <div className="caption">
        <FormattedMessage
          id="dashboard::userTable::tableHeader::fullName"
          defaultMessage="Full name"
        />
      </div>
      {/* <div>
        <FormattedMessage
          id="dashboard::userTable::tableHeader::email"
          defaultMessage="Email"
        />
      </div> */}
      <div className="caption">
        <FormattedMessage
          id="dashboard::userTable::tableHeader::role"
          defaultMessage="Role"
        />
      </div>
      <div className="caption">
        <FormattedMessage
          id="dashboard::userTable::tableHeader::date"
          defaultMessage="Adding date"
        />
      </div>
      <div className="caption">
        <FormattedMessage
          id="dashboard::userTable::tableHeader::email"
          defaultMessage="Email"
        />
      </div>
    </StyledUserTableHeader>
  ) : (
    <StyledUserTableMobileHeader data-testid="user-table-mobile-header">
      <div className="caption">
        <FormattedMessage
          id="dashboard::userTable::tableHeader::fullName"
          defaultMessage="Full name"
        />
      </div>
      <div className="caption">
        <FormattedMessage
          id="dashboard::userTable::tableHeader::role"
          defaultMessage="Role"
        />
      </div>
    </StyledUserTableMobileHeader>
  )
}

export default UserTableHeader
