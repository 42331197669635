import styled from "@emotion/styled"

export const StyledDropDown = styled.div``

export const StyledDotMenuButton = styled.div`
  cursor: pointer;
  svg {
    height: 1.6rem;
    @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      height: 2rem;
    }
  }
`
