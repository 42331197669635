import {
  StyledPage,
  StyledPageContent,
  StyledPageTitle,
} from "@tmu/src/global/page-addons/dashboard.styles"
import { FormattedMessage } from "gatsby-plugin-intl"
import { CorporateBrandsTable } from "../../dashboardCommon"

export default function CorporateBrands() {
  return (
    <StyledPage>
      <StyledPageTitle>
        <h1 data-testid="dashboard-download-center-page-title">
          <FormattedMessage
            id="dashboard::nav::socialImpactPortfolio"
            defaultMessage="Social Impact Portfolio"
          />
        </h1>
      </StyledPageTitle>
      <StyledPageContent data-testid="dashboard-social-impact-portfolio-body">
        <CorporateBrandsTable />
      </StyledPageContent>
    </StyledPage>
  )
}
