import React, { useState, useEffect, useRef } from "react"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import SEO from "@tmu/components/seo"
import queryString from "query-string"
import { NewCard, Spinner, Checkbox } from "@tmu/components/common"
import {
  StyledWrapper,
  StyledListingFilters,
  StyledContent,
  StyledListing,
  StyledActions,
  NoResults,
  StyledOffersBody,
} from "./index.styles"
import { CARDS_PER_PAGE, LIST_TYPES } from "@tmu/apollo/constants"
import { getQueryParams, getValueForLocale } from "@tmu/utils/string"
import { useOffers } from "@tmu/src/hooks"

const OfferListing = ({ storeId, customNotFoundMessage, isOffers }) => {
  const { locale, defaultLocale, formatMessage } = useIntl()
  const params = getQueryParams()
  const loadRef = useRef()
  const [selectedCategoryIds, setSelectedCategoryIds] = useState([])
  const [listType, setListType] = useState(params?.view || LIST_TYPES.TILE)
  const [isOffersFilter, setOffersFilter] = useState(
    isOffers || params?.offer === "true"
  )
  const [isPromotionsFilter, setPromotionsFilter] = useState(
    params?.promotion === "true"
  )
  const [loadMoreAfter, setLoadMoreAfter] = useState(false)
  const { callOffers, handleLoadMore, data, pageInfo, loading } = useOffers()
  const { isDesktop } = getAllScreenTypes()
  const perPage = CARDS_PER_PAGE
  const offerVariables = {
    first: perPage,
    categories: selectedCategoryIds,
    store: [storeId],
    isPublic: true,
    offerOrigin: [isOffersFilter ? "offers" : null].filter((item) => item),
  }

  useEffect(() => {
    setOffersFilter(isOffers || params?.offer === "true")
    setPromotionsFilter(params?.promotion === "true")
  }, [params?.store, params?.promotion])

  useEffect(() => {
    callOffers({ variables: offerVariables })
    //TODO: will be implemented after promotions
    // if (params?.promotion === "true" || params?.offer === "true") {
    //   getOffers()
    // } else {
    //   setListData([])
    // }
  }, [isOffers, params?.offer, params?.promotion])

  const changeUrlState = (url) => {
    window?.history?.pushState({}, null, url)
  }

  const navigateWithParams = (newParams) => {
    const updatedParams = queryString.stringify(
      { ...newParams },
      { arrayFormat: "comma" }
    )
    changeUrlState("?" + updatedParams)
  }

  //Initialize the intersection observer API
  useEffect(() => {
    if (!loadRef?.current) {
      return
    }
    let options = {
      threshold: 1,
    }
    const observer = new IntersectionObserver(handleObserver, options)
    if (loadRef.current) {
      observer.observe(loadRef.current)
    }
  }, [loadRef?.current, pageInfo])

  // Handle intersection with load more
  const handleObserver = (entities) => {
    const target = entities[0]
    if (target.isIntersecting) {
      setLoadMoreAfter(true)
    }
  }

  // Handle loading more
  useEffect(() => {
    if (loadMoreAfter && pageInfo?.hasNextPage) {
      handleLoadMore(offerVariables)
      setLoadMoreAfter(false)
    }
  }, [loadMoreAfter, pageInfo?.hasNextPage])

  return (
    <>
      <SEO
        lang={locale}
        title={formatMessage({
          id: "offerListing::title::pacStores",
          defaultMessage: "PAC Stores",
        })}
      />
      <StyledWrapper id="offers">
        <StyledOffersBody>
          <div>
            <StyledListingFilters>
              <FormattedMessage
                id="offerListing::filters::offerType"
                defaultMessage="Offer Type"
                tagName="p"
              />
              <div>
                <Checkbox
                  className="filter-checkbox custom"
                  label={
                    <FormattedMessage
                      id="offerListing::filters::offers"
                      defaultMessage="Offers"
                    />
                  }
                  disabled
                  isChecked={true}
                  // isChecked={isOffersFilter}
                  // onChange={() => {
                  //   handleTypeChange({ offer: !isOffersFilter })
                  //   setOffersFilter(!isOffersFilter)
                  // }}
                />
                {/* <Checkbox
                  className="filter-checkbox"
                  label={
                    <FormattedMessage
                      id="offerListing::filters::promotions"
                      defaultMessage="Promotions"
                    />
                  }
                  isChecked={isPromotionsFilter}
                  onChange={() => {
                    handleTypeChange({ promotion: !isPromotionsFilter })
                    setPromotionsFilter(!isPromotionsFilter)
                  }}
                /> */}
              </div>
            </StyledListingFilters>
            <StyledContent>
              {loading ? (
                <Spinner />
              ) : (
                <>
                  <StyledListing>
                    {data?.edges?.length > 0
                      ? data?.edges?.map(({ node: offer }) => {
                          return (
                            <NewCard
                              variant={
                                offer?.isDefaultOffer ? "store" : "offer"
                              }
                              id={offer?.id}
                              key={offer?.id}
                              imageSrc={offer?.image}
                              title={getValueForLocale(
                                offer,
                                "name",
                                locale,
                                defaultLocale
                              )}
                              description={getValueForLocale(
                                offer,
                                "description",
                                locale,
                                defaultLocale
                              )}
                              maxPacDiscount={offer?.maxPacDiscount}
                              size={!isDesktop ? LIST_TYPES.LIST : listType}
                              cardLink={
                                offer?.isDefaultOffer
                                  ? `/offers/store/${offer?.store?.slug}?offer=true`
                                  : `/offers/detail/${offer?.slug}`
                              }
                              storeLogo={getValueForLocale(
                                offer?.store,
                                "logo",
                                locale,
                                defaultLocale
                              )}
                              storeName={
                                offer?.store?.displayName || offer?.store?.name
                              }
                              isComingSoon={offer?.store?.badge?.includes(
                                "COMING_SOON"
                              )}
                            />
                          )
                        })
                      : !loading && (
                          <NoResults>
                            {customNotFoundMessage || (
                              <FormattedMessage
                                id="campaignListing::filters::noResult"
                                defaultMessage="We couldn’t find any results ("
                                tagName="h6"
                              />
                            )}
                          </NoResults>
                        )}
                  </StyledListing>
                  <StyledActions ref={loadRef}>
                    {data?.allOffers?.pageInfo?.hasNextPage ? (
                      <Spinner condensed />
                    ) : null}
                  </StyledActions>
                </>
              )}
            </StyledContent>
          </div>
        </StyledOffersBody>
      </StyledWrapper>
    </>
  )
}

export default OfferListing
