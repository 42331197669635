import styled from "@emotion/styled"
import { css } from "@emotion/react"

export const StyledCartHeader = styled.div`
  z-index: -1;
  height: 8rem;
  width: 100%;
  margin: 5rem 0 2rem;
  overflow: hidden;
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.bold};
  background-color: ${(p) => p.theme.colors.primarySubmenuBackgroundColor};
  h1 {
    padding: 0.625rem 0.625rem;
    font-size: 2.5rem;
    max-width: ${(p) => p.theme.breakpoints.wide};
    margin: 3.75rem auto 0 auto;
    svg {
      margin-right: 1rem;
    }
    @media screen and (max-width: ${(p) => p.theme.breakpoints.phone}) {
      font-size: 1.6rem;
    }
  }
`

export const StyledWrapper = styled.div`
  max-width: ${(p) => p.theme.breakpoints.wide};
  height: 100%;
  margin: 0 auto;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: minmax(0, 0.65fr) minmax(0, 0.35fr);
  padding: 0 1rem;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: block;
    margin: 0 auto;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  ${(p) =>
    p.empty &&
    css`
      display: block;
    `}
`

export const StyledEmptyTablePlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  line-height: 4rem;
  font-size: 1.2rem;
  min-height: 35vh;

  svg {
    font-size: 2.8rem;
    height: 2.8rem;
    opacity: 0.35;
    margin-bottom: 0.5rem;
  }

  button {
    margin: 0.5rem auto;
  }
  p {
    line-height: 1;
    margin: 1rem 0;
    text-align: center;
  }
`

export const StyledOfferCount = styled.div`
  color: ${(p) => p.theme.colors.gondola};
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-size: 1.5rem;
  align-self: stretch;
  justify-self: end;
  background-color: ${(p) => p.theme.colors.white};
  width: 100%;
  padding: 1rem 1rem 0 1rem;
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  flex-direction: column;

  h3 {
    border-bottom: 0.0625rem solid
      ${(p) => p.theme.colors.formFieldsBorderColor};
  }
`

export const StyledCartSummarySection = styled.div`
  position: relative;
  top: -0.5rem;
`

export const StyledCartSummary = styled.div`
  position: relative;
  background-color: ${(p) => p.theme.colors.white};
  padding: 0.875rem 0.5rem 0 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  ${(p) =>
    p.size === "condensed" &&
    css`
      font-size: 0.85rem;
    `}
`

export const StyledCartSummaryRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${(p) => p.theme.colors.gondola};
  border-bottom: 0.0625rem solid ${(p) => p.theme.colors.formFieldsBorderColor};
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  font-weight: ${(p) => p.strong && "bold"};
  > p:first-of-type {
    opacity: ${(p) => (p.strong ? 1 : 0.5)};
  }

  ${(p) =>
    p.size === "condensed" &&
    css`
      padding-top: 0.15rem;
      padding-bottom: 0.15rem;
    `}
`

export const StyledCartFaq = styled.ul`
  color: ${(p) => p.theme.colors.disabled};
  padding: 1.25rem 0 1.25rem 0;
  list-style-type: disc;
  list-style-position: inside;
  margin-left: 0.5rem;
  li {
    margin-bottom: 0.625rem;
  }
`

export const StyledAmount = styled.p`
  color: ${(p) =>
    p.highlighted ? p.theme.colors.blue : p.theme.colors.gondola};
  font-size: ${(p) => (p.strong ? "1.4rem" : "1.2rem")};
  font-weight: ${(p) => p.strong && "bold"};
  line-height: 1.75rem;

  ${(p) =>
    p.size === "condensed"
      ? css`
          font-size: 0.95rem;
        `
      : css`
          font-size: ${p.strong ? "1.4rem" : "1.2rem"};
        `}
`

export const StyledSubTotalAmount = styled.div`
  grid-area: subtotal;
  padding: 1rem 2rem 0.5rem 2rem;
  background-color: ${(p) => p.theme.colors.white};
  border-bottom: 0.0625rem solid ${(p) => p.theme.colors.formFieldsBorderColor};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  align-self: end;

  > span {
    margin-right: 0.5rem;
  }

  @media only screen and (max-width: 62.5rem) {
    padding: 1rem 0.5rem;
    justify-content: space-between;
  }
`

export const StyledCartContent = styled.div`
  height: 100%;
`

export const StyledCartActions = styled.div`
  background-color: ${(p) => p.theme.colors.white};
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  padding-right: 0.5rem;

  button {
    width: 100%;
    max-width: ${(p) => p.theme.breakpoints.phone};
  }
  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    button {
      align-self: flex-end;
    }
  }
`

export const StyledShoppingCartTable = styled.div`
  grid-area: items;
`

export const StyledCartProceed = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  & > div {
    margin-left: 0.5rem;
  }
  .proceed-button {
    width: 100%;
    margin-left: 0.3rem;
  }
  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding-left: 1rem;
    button {
      max-width: ${(p) => p.theme.breakpoints.phone};
      align-self: flex-end;
    }
  }
`

export const StyledHelpText = styled.div`
  font-size: 0.85rem;
  margin: 1.5rem 0;
`

export const StyledModalActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  button {
    min-width: 6.25rem;
    height: 2.8125rem;
  }
`
