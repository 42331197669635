import React from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby-plugin-intl"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import MediaSlider from "@tmu/components/common/MediaSlider"

import {
  StyledWSMainContainer,
  StyledWSLeftContainer,
  StyledWSTitle,
  StyledWSButton,
  StyledWSButtonAwesomeIcon,
  StyledWSRightContainer,
} from "./index.styles"

const WelcomeSlice = ({
  title,
  content,
  buttonIcon,
  buttonLabel,
  buttonLink,
  images,
}) => {
  const { isTablet, isDesktop, isWide } = getAllScreenTypes()

  const imageSize = {
    imageWidth: isWide ? 844 : isDesktop ? 468 : isTablet ? 352 : 360,
    imageHeight: isWide ? 584 : isDesktop ? 329 : isTablet ? 247 : 253,
  }

  const handleClick = () => {
    navigate(buttonLink)
  }

  return (
    <StyledWSMainContainer>
      <StyledWSLeftContainer>
        <StyledWSTitle>{title}</StyledWSTitle>
        {content}
        {isTablet && buttonLabel && buttonLink && (
          <StyledWSButton
            data-testid={`btn-${buttonLabel}`}
            label={buttonLabel}
            color={"blue"}
            onClick={handleClick}>
            {buttonIcon?.iconName !== undefined ? (
              <StyledWSButtonAwesomeIcon icon={buttonIcon} />
            ) : null}
            {buttonLabel}
          </StyledWSButton>
        )}
      </StyledWSLeftContainer>
      <StyledWSRightContainer>
        <MediaSlider images={images} {...imageSize} altName={`ws-${images}`} />
      </StyledWSRightContainer>
    </StyledWSMainContainer>
  )
}

WelcomeSlice.propTypes = {
  title: PropTypes.node,
  content: PropTypes.node.isRequired,
  buttonIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  buttonLabel: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      image: PropTypes.string.isRequired,
      description: PropTypes.node,
    })
  ).isRequired,
}

export default WelcomeSlice
