import React from "react"
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons/faChevronRight"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { LazyImage } from "@tmu/components/common"
import {
  StyledOverviewCard,
  StyledOverviewCardTitle,
  StyledOverviewCardImage,
  StyledOverviewCardInfo,
  StyledOverviewCardContent,
  StyledOverviewCardMeta,
  StyledOverviewCardAction,
} from "./index.styles"

export default ({
  iconName = "",
  title,
  avatar,
  info,
  logo,
  children,
  meta,
  action,
  permission = true,
}) => (
  <StyledOverviewCard
    hasImage={(avatar && avatar.length) || (logo && logo.length)}
    data-testid={title}>
    <StyledOverviewCardTitle>
      {iconName.length && <i className={`icon fal fa-${iconName}`}></i>}
      {title}
      {permission && <FontAwesomeIcon icon={faChevronRight} />}
    </StyledOverviewCardTitle>
    {avatar ? (
      <StyledOverviewCardImage avatar>
        <LazyImage
          src={avatar}
          altName={`overview-card-image-${title}`}
          width={120}
          height={120}
        />
      </StyledOverviewCardImage>
    ) : logo ? (
      <StyledOverviewCardImage>
        <LazyImage
          src={logo}
          altName={`overview-card-image-${title}`}
          width={120}
          height={120}
        />
      </StyledOverviewCardImage>
    ) : (
      <StyledOverviewCardInfo>{info}</StyledOverviewCardInfo>
    )}
    <StyledOverviewCardContent>{children}</StyledOverviewCardContent>
    {meta && <StyledOverviewCardMeta>{meta}</StyledOverviewCardMeta>}
    {action && <StyledOverviewCardAction>{action}</StyledOverviewCardAction>}
  </StyledOverviewCard>
)
