import React, { useRef, useEffect } from "react"
import { useLocation } from "@reach/router"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { Tab, Tabs, TabList, Panel } from "@tmu/components/common"
import {
  StyledSliceWhoWeServe,
  StyledWwsHeader,
  StyledWwsTitle,
  StyledWwsText,
  StyledWwsTabContainer,
  StyledTab,
} from "./index.styles"
import { useMediaQuery } from "beautiful-react-hooks"
import { StyledStartHereWrapper } from "@tmu/global/page-addons/how-it-works.styles"
import { SliceWhoWeServe } from "./SliceWhoWeServe"
import { StartHere } from "./StartHere"
import { isInViewport } from "@tmu/utils/viewport"
import { isBrowser } from "@tmu/utils/auth"

const WhoWeServe = () => {
  const intl = useIntl()
  const { hash } = useLocation()
  const isSmall = isBrowser && useMediaQuery("(max-width: 50rem)")
  const ref = useRef()
  const { formatMessage } = intl
  const tabs = [
    {
      label: formatMessage({
        id: "whoWeServe::donors::tabTitle",
        defaultMessage: "Donors",
      }),
      key: "donors",
      iconName: "hand-holding-heart",
      dataTestId: "donors",
    },
    {
      label: formatMessage({
        id: "whoWeServe::nonProfits::tabTitle",
        defaultMessage: "Non-Profits",
      }),
      key: "non-profit",
      iconName: "gift",
      dataTestId: "non-profits",
    },
    {
      label: formatMessage({
        id: "whoWeServe::merchants::tabTitle",
        defaultMessage: "Merchants",
      }),
      key: "merchants",
      iconName: "store",
      dataTestId: "merchants",
    },
  ]

  const hashCallback = () => {
    const hashText = "#who-we-serve"
    const isHashInUrl = hash === hashText || window?.location?.hash === hashText
    if (isInViewport(ref) && !isHashInUrl)
      history.pushState(null, null, hashText)
  }

  useEffect(() => {
    document.addEventListener("scroll", hashCallback)
    return () => document.removeEventListener("scroll", hashCallback)
  }, [])

  return (
    <StyledSliceWhoWeServe
      data-testid="slice-who-we-serve"
      id="who-we-serve"
      ref={ref}>
      <StyledWwsHeader>
        <StyledWwsTitle className="slice-header">
          <FormattedMessage
            id="whoWeServe::title"
            defaultMessage="Who We Serve"
          />
        </StyledWwsTitle>
        <StyledWwsText>
          <FormattedMessage
            id="whoWeServe::description"
            defaultMessage="From dreamers to players and shoppers - everyone you know has gathered at TMU to reward you for doing good, instantly, equally and transparently."
          />
        </StyledWwsText>
      </StyledWwsHeader>

      {isSmall ? (
        <StyledStartHereWrapper>
          <StartHere userType="donor" />
          <StartHere userType="partner" />
          <StartHere userType="merchant" />
        </StyledStartHereWrapper>
      ) : (
        <Tabs>
          <StyledWwsTabContainer>
            <StyledTab>
              <TabList>
                {tabs.map(({ key, label, iconName, dataTestId }) => (
                  <Tab key={key} data-testid={"test-id-" + dataTestId}>
                    <i className={`fas fa-${iconName}`}></i> {label}
                  </Tab>
                ))}
              </TabList>
            </StyledTab>
            <Panel>
              <SliceWhoWeServe userType="donor" />
            </Panel>
            <Panel>
              <SliceWhoWeServe userType="partner" />
            </Panel>
            <Panel>
              <SliceWhoWeServe userType="merchant" />
            </Panel>
          </StyledWwsTabContainer>
        </Tabs>
      )}
    </StyledSliceWhoWeServe>
  )
}

export default WhoWeServe
