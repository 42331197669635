import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import { StyledNotFoundPage } from "@tmu/global/page-addons/flat-page.styles"

const ContentNotFound = ({ slug = "" }) => {
  const { formatMessage } = useIntl()

  const title = formatMessage({
    id: "pages::contentNotFound::title",
    defaultMessage: "Found Nothing :(",
  })

  const desc = formatMessage(
    {
      id: "pages::contentNotFound::description",
      defaultMessage: "Sorry, we couldn't find the content related to {slug}",
    },
    {
      slug: slug,
    }
  )

  return (
    <StyledNotFoundPage
      style={{ textAlign: "center" }}
      data-testid="content-not-found">
      <h1>{title}</h1>
      <p>{desc}</p>
    </StyledNotFoundPage>
  )
}

export default ContentNotFound
