import styled from "@emotion/styled"

export const StyledAccordion = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledAccordionSectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;
  cursor: pointer;
  border-bottom: 0.0625rem solid
    ${(p) => p.theme.colors.accordionSeparatorBottomBorder};
  padding-right: 1.5rem;
  svg {
    margin-left: 0.5rem;
    max-width: 0.875rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding-right: 0;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
    svg {
      margin-left: 2rem;
    }
  }
`

export const StyledAccordionSection = styled.div`
  padding: 0;

  button {
    font-size: inherit;
  }
`
