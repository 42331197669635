import React, { useState, useContext, createContext, useEffect } from "react"
import { navigate, changeLocale } from "gatsby-plugin-intl"
import { useLazyQuery, useMutation } from "@apollo/client"
import queryString from "query-string"
import {
  ME_QUERY,
  PARTNER_API_PERMISSIONS,
  SINGLE_MERCHANT_API_PERMISSIONS,
} from "@tmu/apollo/storefront/queries/user"
import {
  ADD_REFERRAL_CODE,
  UPDATE_COMMUNICATION_LANGUAGE,
  AUTHENTICATE_MUTATION,
  REGISTER_MUTATION,
  LOGOUT_MUTATION,
  VERIFY_ACCOUNT_MUTATION,
  SOCIAL_AUTH_REGISTER,
  SOCIAL_AUTH_LOGIN,
} from "@tmu/apollo/storefront/mutations/user"
import { useApolloApiClients } from "@tmu/apollo/client"
import { getInitials } from "@tmu/utils/string"
import { get, set, remove } from "@tmu/utils/storage"
import {
  setCredentials,
  getCredentials,
  removeCredentials,
  isTokenExpired,
} from "@tmu/utils/auth"
import { useToast } from "@tmu/hooks"
import { getNavigatorLanguage } from "../utils/navigator"

const AuthContext = createContext({})

export const useAuth = () => {
  return useContext(AuthContext)
}

export const AuthProvider = ({ children }) => {
  const auth = useProvideAuth()

  return (
    <AuthContext.Provider
      value={{
        ...auth,
      }}>
      {children}
    </AuthContext.Provider>
  )
}

function useProvideAuth() {
  const [user, setUser] = useState(JSON.parse(get("user") || "{}"))
  const [partnerPermissions, setPartnerPermissions] = useState([])
  const [merchantPermissions, setMerchantPermissions] = useState([])
  const { success: successToast, error: errorToast } = useToast()
  const token = get("token")
  const [isAuthenticated, setIsAuthenticated] = useState(!isTokenExpired(token))
  const { storefrontClient, partnerClient, merchantClient } =
    useApolloApiClients()

  const [
    callUserProfile,
    {
      data,
      loading: loadingProfile,
      called: userProfileCalled,
      refetch: refetchUserProfile,
      error: userProfileError,
    },
  ] = useLazyQuery(ME_QUERY, { fetchPolicy: "network-only" })

  useEffect(() => {
    if (userProfileError?.message === "User is disabled") {
      errorToast(userProfileError.message)
      removeCredentials()
      navigate("/")
    }
  }, [userProfileError])

  useEffect(() => {
    if (!loadingProfile && !userProfileCalled && token) {
      callUserProfile()
    }
  }, [isAuthenticated, loadingProfile, user])

  const [
    callPartnerApiPermissions,
    {
      loading: partnerPermissionsLoading,
      data: partnerPermissionsData,
      called: calledPartnerApiPermissions,
    },
  ] = useLazyQuery(PARTNER_API_PERMISSIONS, {
    fetchPolicy: "cache-and-network",
  })

  const [
    callMerchantApiPermissions,
    {
      loading: merchantPermissionsLoading,
      data: merchantPermissionsData,
      called: calledMerchantApiPermissions,
    },
  ] = useLazyQuery(SINGLE_MERCHANT_API_PERMISSIONS, {
    client: merchantClient,
    fetchPolicy: "cache-and-network",
  })

  useEffect(() => {
    if (
      partnerPermissionsLoading ||
      !partnerPermissionsData ||
      !partnerPermissionsData?.me
    ) {
      return
    }
    const perms = partnerPermissionsData?.me?.partnerUsers?.edges
      .flatMap(({ node }) => node?.apiRole.apiPermissions?.edges)
      .map(({ node }) => node.codename)
    setPartnerPermissions(perms)
  }, [partnerPermissionsLoading, partnerPermissionsData])

  useEffect(() => {
    if (
      merchantPermissionsLoading ||
      !merchantPermissionsData ||
      !merchantPermissionsData?.merchantUser
    ) {
      return
    }
    const perms = merchantPermissionsData?.merchantUser?.finalPermissions
    setMerchantPermissions(perms)
  }, [merchantPermissionsLoading, merchantPermissionsData])

  const [addReferralCode, { loading: addReferralCodeLoading }] = useMutation(
    ADD_REFERRAL_CODE,
    {
      client: storefrontClient,
    }
  )

  const [updateCommunicationLanguage] = useMutation(
    UPDATE_COMMUNICATION_LANGUAGE,
    {
      refetchQueries: [
        {
          query: ME_QUERY,
        },
      ],
    }
  )

  const updateCommunicationLanguageWithBrowser = () => {
    return updateCommunicationLanguage({
      variables: {
        language: getNavigatorLanguage()?.toLocaleUpperCase() ?? "IT",
      },
    })
  }

  const [signUpMutation] = useMutation(REGISTER_MUTATION, {
    client: storefrontClient,
  })

  const [socialAuthMutation, { called, loading }] =
    useMutation(SOCIAL_AUTH_LOGIN)

  const [socialRegisterUpMutation] = useMutation(SOCIAL_AUTH_REGISTER, {
    client: storefrontClient,
  })

  const [verifyAccountMutation] = useMutation(VERIFY_ACCOUNT_MUTATION, {
    client: storefrontClient,
  })

  const [signInMutation, { called: signInCalled, loading: signInLoading }] =
    useMutation(AUTHENTICATE_MUTATION, {
      client: storefrontClient,
    })

  const callAddReferralMutation = (referralCode) => {
    return addReferralCode({ variables: { code: referralCode } })
  }

  useEffect(() => {
    if (!loadingProfile && data?.me) {
      if (data?.me?.isActive) {
        setIsAuthenticated(true)
        refetchUserProfileCallback(data)
      } else {
        if (data?.me?.isActive === false) {
          errorToast("User is not active")
          setIsAuthenticated(false)
        }
      }
    }
  }, [loadingProfile, data?.me])

  const [
    logout,
    { error: logoutError, data: logoutData, called: logoutCalled },
  ] = useMutation(LOGOUT_MUTATION, {
    onCompleted: () => {
      storefrontClient.resetStore()
      if (user?.isPartner) {
        partnerClient.resetStore()
      }
      if (user?.isMerchant) {
        merchantClient.resetStore()
      }
      remove("user")
      setUser(null)
    },
  })

  const refetchUserProfileCallback = (data) => {
    if (data && data?.me) {
      const displayName = data.me.displayName ?? ""
      const userData = {
        ...data.me,
        displayName,
        shortDisplayName: getInitials(displayName).toUpperCase(),
        apiPermissions: [...partnerPermissions, ...merchantPermissions],
      }
      set("user", JSON.stringify(userData))
      setUser(userData)

      const userLocale = userData?.communicationLanguage?.toLowerCase()
      if (userLocale?.length) {
        changeLocale(userLocale)
      }
    } else {
      remove("user")
      setUser(null)
    }
  }

  const signUp = ({
    email,
    displayName,
    firstName,
    lastName,
    country,
    isSubscribed,
    password1,
    password2,
    accountType,
    communicationLanguage,
    isTermsAndPolicyAccepted,
    code = "",
    captcha,
    redirectUrl,
    taxId,
    phoneNumber,
  }) => {
    const browserLanguage = navigator?.language?.split("-")?.[0]?.toUpperCase()

    const languageToSend = communicationLanguage
      ? communicationLanguage
      : [("IT", "ES", "EN")].includes(browserLanguage)
      ? browserLanguage
      : "IT"

    return signUpMutation({
      variables: {
        input: {
          email,
          displayName,
          firstName,
          lastName,
          country,
          isSubscribed,
          password1,
          password2,
          accountType,
          isTermsAndPolicyAccepted,
          phoneNumber,
          code,
          captcha,
          redirectUrl,
          communicationLanguage: languageToSend,
          taxId,
        },
        isPublic: true,
      },
    })
  }

  const verifyAccount = ({ token }) => {
    return verifyAccountMutation({
      variables: {
        input: {
          token,
        },
      },
    })
  }

  const checkWelcomePageStatus = (me) => {
    const userPreferences = me?.userPreference
    const userId = me?.id
    const offerPreferences = userPreferences?.productCategories?.edges || []
    const campaignPreferences = userPreferences?.campaignCategories?.edges || []
    const preferences = offerPreferences.concat(campaignPreferences) || []
    if (!preferences || preferences?.length === 0) {
      const WELCOME_KEY = "welcome_key_" + userId
      if (get(WELCOME_KEY) === null) {
        const params = queryString.parse(location.search, {
          arrayFormat: "comma",
        })
        const next = params?.next?.replace(/\/(en|it|es)/g, "") ?? "/"
        const nextUrl = queryString.stringifyUrl({
          url: next,
          query: params,
        })
        navigate(
          "/user-space?next=" +
            (next
              ? nextUrl
              : window?.location.pathname.replace(/\/(en|it|es)/g, "") +
                window?.location.search)
        )
      }
    }
  }

  const signIn = async ({ email, password, remember, next, silent }) => {
    if (remember) {
      set("email", email)
      set("remember", true)
    } else {
      remove("email")
      remove("remember")
    }
    return signInMutation({
      variables: {
        input: { email, password },
        isPublic: true,
      },
    }).then(
      ({
        data: {
          tokenAuth: { token, refreshToken, success, errors },
        },
      }) => {
        if (success) {
          if (!silent)
            navigate(
              [
                "/?next=/en/",
                "/?next=/it/",
                "/?next=/es/",
                "/?next=/",
              ].includes(decodeURIComponent(next))
                ? "/user-space"
                : next || "/user-space",
              {
                replace: true,
              }
            )
          setCredentials({ token, refreshToken })
          callUserProfile().then((userData) => {
            checkWelcomePageStatus(userData?.data?.me)
          })
        } else return errors
      }
    )
  }

  const socialRegister = async ({
    provider = get("provider"),
    tokenId = get("tokenId"),
    accountType = "PERSONAL",
    isTermsAndPolicyAccepted = true,
    next,
  }) => {
    const browserLanguage = navigator?.language?.split("-")?.[0]?.toUpperCase()

    const languageToSend = ["IT", "ES", "EN"].includes(browserLanguage)
      ? browserLanguage
      : "IT"
    return socialRegisterUpMutation({
      variables: {
        input: {
          provider,
          tokenId,
          accountType,
          communicationLanguage: languageToSend,
          isTermsAndPolicyAccepted,
        },
      },
    })
      .then((result) => {
        const { token, refreshToken, user } = { ...result.data.socialRegister }

        if (token) {
          setCredentials({ token, refreshToken })
          callUserProfile()
          navigate(
            ["/?next=/en/", "/?next=/it/", "/?next=/es/", "/?next=/"].includes(
              decodeURIComponent(next)
            )
              ? "/user-space"
              : next || "/user-space",
            {
              replace: true,
            }
          )
        }
        if (result?.errors) {
          result.errors.forEach((err) => errorToast(err.message))
        }
      })
      .catch((err) => {
        errorToast(err.message)
      })
  }

  const signOut = (callback) => {
    setIsAuthenticated(false)
    const { refreshToken } = getCredentials()
    if (refreshToken) logout({ variables: { input: { refreshToken } } })
    removeCredentials()

    if (typeof callback === "function") {
      callback()
    }
  }

  const signInWithFacebook = async ({ accessToken }) => {
    const tokenId = accessToken
    if (!accessToken) return null
    const paramOptions = {
      arrayFormat: "comma",
    }
    const params = queryString.parse(location.search, paramOptions)
    const next = params?.next?.replace(/\/(en|it|es)/g, "") ?? "/"
    const nextUrl = queryString.stringifyUrl({
      url: next,
      query: params,
    })

    return socialAuthMutation({
      variables: {
        input: { provider: "FACEBOOK", tokenId },
      },
    })
      .then((result) => {
        if (result?.errors?.[0]?.message) {
          errorToast(result?.errors[0]?.message)
        }
        if (!result?.data?.socialAuth) {
          removeCredentials()
          return null
        }
        const { provider, token, refreshToken, user, isRegistered, errors } =
          result?.data?.socialAuth
        const refCode = get("referralCode")

        if (!isRegistered) {
          set("provider", "FACEBOOK")
          set("tokenId", tokenId)
          navigate(
            `/signup?${refCode ? `&referral=${refCode}` : ""}${
              next ? `&next=${nextUrl}` : ""
            }`,
            {
              replace: true,
            }
          )
        } else {
          callAddReferralMutation(refCode)
          setCredentials({ token, refreshToken })
          callUserProfile()
          navigate(nextUrl)
        }
      })
      .catch((err) => {
        errorToast(err?.message)
      })
  }
  return {
    user,
    isAuthenticated,
    callAddReferralMutation,
    setIsAuthenticated,
    signIn,
    signUp,
    signOut,
    socialRegister,
    verifyAccount,
    signInWithFacebook,
    callPartnerApiPermissions,
    calledPartnerApiPermissions,
    callMerchantApiPermissions,
    calledMerchantApiPermissions,
    apiPermissions: [...partnerPermissions, ...merchantPermissions],
    isLoading:
      loadingProfile || partnerPermissionsLoading || merchantPermissionsLoading,
    refetchUserProfile,
    callUserProfile,
    addReferralCode,
    addReferralCodeLoading,
    updateCommunicationLanguageWithBrowser,
    updateCommunicationLanguage: async ({ variables }) => {
      const locale =
        typeof variables.language === "string"
          ? variables.language
          : variables?.language?.value || "en"
      if (isAuthenticated) {
        await updateCommunicationLanguage({
          variables: {
            language: locale.toUpperCase() || "EN",
          },
        })
        changeLocale(locale)
      } else {
        changeLocale(locale)
      }
    },
  }
}
