import React from "react"
import PropTypes from "prop-types"
import { LazyImage } from "@tmu/components/common"
import { StyledStores, StyledImageContainer } from "../index.styles"

const Stores = ({ id, name, logo, slug, onClick, imgWidth, imgHeight }) => {
  return (
    <StyledStores
      to={`/offers/store/${slug.toLowerCase()}?offer=true`}
      data-testid="typeahead-stores"
      className="common-typeahead"
      onClick={onClick}>
      <StyledImageContainer>
        <LazyImage
          src={logo}
          altName={`stores-image-${name}`}
          width={imgWidth || 320}
          height={imgHeight || 240}
          fit="cover"
        />
      </StyledImageContainer>
      <div className="typeahead-item"> {name} </div>
    </StyledStores>
  )
}

Stores.propTypes = {
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
}

Stores.defaultProps = {
  name: "",
  slug: "/",
}

export default Stores
