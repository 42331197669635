import React from "react"
import PropTypes from "prop-types"
import { Formik, Form } from "formik"
import { FormattedMessage, useIntl, navigate } from "gatsby-plugin-intl"
import * as Yup from "yup"
import { email } from "@tmu/utils/validation"
import { TextInput, Button } from "@tmu/components/common"
import { useAuth, useToast } from "@tmu/hooks"
import {
  StyledResetPassword,
  StyledResetPassTitle,
  StyledResetPassDesc,
  StyledTypeEmail,
} from "./index.styles"
import SEO from "@tmu/components/seo"

const ResetPasswordForm = () => {
  const { changePassword } = useAuth()
  const { formatMessage, locale } = useIntl()
  const { success, error } = useToast()
  const validationSchema = Yup.object().shape({
    email: email({ formatMessage }),
  })

  const initialValues = {
    email: "",
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)
    changePassword(values.email)
      .then((result) => {
        success(result)
      })
      .catch((err) => error(err.description))
      .finally(() => setSubmitting(false))
  }

  return (
    <StyledResetPassword>
      <SEO
        lang={locale}
        title={formatMessage({
          id: "nav::resetPassword",
          defaultMessage: "Reset Password",
        })}
      />
      <StyledResetPassTitle>
        <FormattedMessage
          id="resetPass::title"
          defaultMessage="Password Recovery"
        />
      </StyledResetPassTitle>
      <StyledResetPassDesc>
        <FormattedMessage
          id="resetPass::descEnterEmail"
          defaultMessage="Enter the email address associated with your TMU account."
          tagName="span"
        />
        <FormattedMessage
          id="resetPass::descResetLink"
          defaultMessage="You’ll receive an email with a reset link. Please check the spam folder."
          tagName="span"
        />
      </StyledResetPassDesc>
      <StyledTypeEmail>
        <FormattedMessage
          id="resetPass::typeEmail"
          defaultMessage="Please type your email and press continue"
        />
      </StyledTypeEmail>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}>
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          isSubmitting,
        }) => (
          <Form className="user-form">
            <TextInput
              id="email"
              type="email"
              label={formatMessage({
                id: "signUp::email",
                defaultMessage: "Email",
              })}
              className="float-container"
              placeholder=""
              error={touched.email && errors.email}
              defaultValue={values.email}
              onChange={(e) => {
                const lowerCaseEmail = e.target.value.toLowerCase()
                handleChange({
                  target: {
                    name: e.target.name,
                    value: lowerCaseEmail,
                  },
                })
              }}
              onBlur={handleBlur}
            />
            <div className="recover-password-button">
              <Button
                label="resetPassword"
                color="red"
                type="submit"
                disabled={isSubmitting}>
                <FormattedMessage id="ui::continue" defaultMessage="Continue" />
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </StyledResetPassword>
  )
}

ResetPasswordForm.propTypes = {
  initialValues: PropTypes.shape({
    email: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
}

ResetPasswordForm.defaultProps = {
  initialValues: {
    email: "",
  },
  onSubmit: () => {},
}

export default ResetPasswordForm
