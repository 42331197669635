import React from "react"
import PropTypes from "prop-types"
import { StyledVideoPlayerWrapper, StyledVideoPlayer } from "./index.styles"

const VideoPlayer = ({ url, isVideoVisible, ...rest }) => {
  return (
    <StyledVideoPlayerWrapper {...rest}>
      {isVideoVisible === undefined ? (
        <StyledVideoPlayer url={url} />
      ) : isVideoVisible ? (
        <StyledVideoPlayer url={url} light={true} playing={true} />
      ) : null}
    </StyledVideoPlayerWrapper>
  )
}

VideoPlayer.propTypes = {
  url: PropTypes.string.isRequired,
}

VideoPlayer.defaultProps = {
  url: "",
}

export default VideoPlayer
