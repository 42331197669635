import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { useIntl, navigate, FormattedMessage } from "gatsby-plugin-intl"
import { Money, Tabs, TabList, Tab, TextInput } from "@tmu/components/common"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHandHoldingHeart } from "@fortawesome/pro-solid-svg-icons/faHandHoldingHeart"
import queryString from "query-string"

import {
  ANNUALLY_MULTIPLIER_FOR_RECURRING_DONATION,
  DEFAULT_CURRENCY_SYMBOL,
  RECURRING_DONATION_TYPE,
} from "@tmu/apollo/constants"
import { Tooltip } from "react-tooltip"

import {
  StyledMainContainer,
  StyledDonationContainer,
  StyledDonationDescription,
  StyledDonationAmount,
} from "./index.styles"
import {
  StyledSupportButton,
  StyledPACMultiplier,
  StyledTooltip,
} from "../PartnerDetail/index.styles"
import { isTokenExpired } from "@tmu/utils/auth"
import { get } from "@tmu/utils/storage"
import { useLocation } from "@reach/router"

const { CLOUDFLARE_IMAGE_URL } = process.env

const RecurringDonation = ({
  amountOne,
  descriptionOne,
  amountTwo,
  descriptionTwo,
  amountThree,
  descriptionThree,
  campaign,
}) => {
  const { formatMessage } = useIntl()
  const [tabIndex, setTabIndex] = useState(0)
  const [value, setValue] = useState("")
  const [error, setError] = useState("")
  const isAuthenticated = !isTokenExpired(get("token"))
  const location = useLocation()

  const referralCode = queryString.parse(location?.search)?.referral

  const pacMultiplierNumber = campaign?.pacMultiplier.split(".")[0]

  const doublePacsTipText = formatMessage(
    {
      id: "doublePacs::listing::cardTooltip",
      defaultMessage:
        "You will get {pacMultiplierNumber} PACs for every EUR donated",
    },
    {
      pacMultiplierNumber: pacMultiplierNumber,
    }
  )

  const PacMultiplier = () => {
    return Number(campaign?.pacMultiplier) > 1 ? (
      <StyledPACMultiplier>
        <img
          className="pacs-pacs-image"
          src={CLOUDFLARE_IMAGE_URL + "/static/assets/images/white_pac.svg"}
          alt={`pacs-pacs-banner`}
          width={28}
          height={23}
        />
        {` X `}
        <Money value={campaign?.pacMultiplier} currency={false} />
      </StyledPACMultiplier>
    ) : null
  }

  const tabs = useMemo(() => {
    return [
      {
        label: formatMessage({
          id: "ui::single",
          defaultMessage: "Single",
        }),
        key: "single",
        dataTestId: "single",
      },
      {
        label: formatMessage({
          id: "ui::monthly",
          defaultMessage: "Monthly",
        }),
        key: "monthly",
        dataTestId: "monthly",
      },
      {
        label: formatMessage({
          id: "ui::annually",
          defaultMessage: "Annually",
        }),
        key: "annually",
        dataTestId: "annually",
      },
    ]
  }, [])

  const getMinAmount = () => {
    let min = Math.min(amountOne, amountTwo, amountThree)
    if (min < 5) min = 5
    return tabIndex !== 2
      ? min
      : min * ANNUALLY_MULTIPLIER_FOR_RECURRING_DONATION
  }

  const getMaxAmount = () => Math.max(amountOne, amountTwo, amountThree)

  const getSelectedAmount = () => {
    return Array.of(amountOne, amountTwo, amountThree).filter(
      (item) =>
        item >
          (tabIndex !== 2
            ? getMinAmount()
            : getMinAmount() / ANNUALLY_MULTIPLIER_FOR_RECURRING_DONATION) &&
        item < getMaxAmount()
    )[0]
  }

  const [selectedAmount, setSelectedAmount] = useState(getSelectedAmount())

  const getDescription = () =>
    selectedAmount === amountOne
      ? descriptionOne
      : selectedAmount === amountTwo
      ? descriptionTwo
      : selectedAmount === amountThree
      ? descriptionThree
      : null

  useEffect(() => {
    setSelectedAmount(getSelectedAmount())
    checkDonateAmount(value)
  }, [tabIndex])

  const checkDonateAmount = (floatValue) => {
    return floatValue > 0 && floatValue < getMinAmount()
      ? setError(
          formatMessage(
            {
              id: "forms::donationMinAmountError",
              defaultMessage: "The amount must be at least {value}.",
            },
            {
              value: getMinAmount(),
            }
          )
        )
      : floatValue > 9999.99
      ? setError(
          formatMessage(
            {
              id: "forms::donationMaxAmountError",
              defaultMessage: "The amount must be lower than {value}.",
            },
            {
              value: 9999.99,
            }
          )
        )
      : error
      ? setError("")
      : null
  }

  return (
    <StyledMainContainer>
      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList>
          {tabs.map(({ key, label, dataTestId }) => (
            <Tab key={key} data-testid={"test-id-" + dataTestId}>
              {label}
            </Tab>
          ))}
        </TabList>
      </Tabs>
      <StyledDonationContainer>
        {Array.of(amountOne, amountTwo, amountThree).map((amount) => {
          return amount ? (
            <div
              key={`amount-${amount}`}
              onClick={() => {
                setSelectedAmount(amount)
                setValue("")
              }}
              className={
                !value && parseFloat(selectedAmount) === parseFloat(amount)
                  ? "selected"
                  : null
              }>
              <Money
                value={
                  tabIndex !== 2
                    ? amount
                    : amount * ANNUALLY_MULTIPLIER_FOR_RECURRING_DONATION || ""
                }
              />
            </div>
          ) : null
        })}
      </StyledDonationContainer>
      {getDescription() && (
        <StyledDonationDescription>
          {getDescription()}
        </StyledDonationDescription>
      )}
      <StyledDonationAmount hasAmount={!!value}>
        <TextInput
          newDesign
          className="currency-icon"
          type="currency"
          id="paymentAmount"
          name="paymentAmount"
          value={value}
          placeholder={formatMessage(
            {
              id: "recurring::donation::paymentAmountPlaceHolder",
              defaultMessage: "your amount, min {currencySymbol} {minAmount}",
            },
            {
              currencySymbol: DEFAULT_CURRENCY_SYMBOL,
              minAmount: getMinAmount(),
            }
          )}
          error={error}
          touched={true}
          onChange={(e) => {
            setValue(e.target.value)
          }}
          onValueChange={({ floatValue }) => {
            checkDonateAmount(floatValue)
          }}
          maxLength={8}
        />
      </StyledDonationAmount>
      {campaign?.status !== "ENDED" && campaign?.isAvailable && (
        <div>
          <StyledSupportButton
            color="blue"
            data-testid="support-campaign"
            disabled={
              error ||
              (value && Number(value) >= 0 && Number(value) < getMinAmount())
            }
            onClick={() => {
              const url = `/campaigns/${campaign?.slug}/donate?donationType=${
                tabIndex === 1
                  ? RECURRING_DONATION_TYPE.MONTHLY
                  : tabIndex === 2
                  ? RECURRING_DONATION_TYPE.ANNUALLY
                  : RECURRING_DONATION_TYPE.SINGLE
              }&donationAmount=${
                value ||
                (tabIndex !== 2
                  ? selectedAmount
                  : selectedAmount * ANNUALLY_MULTIPLIER_FOR_RECURRING_DONATION)
              }${referralCode ? "&referral=" + referralCode : ""}`
              navigate(url)
            }}
            className={`${!campaign.goalAmount && "extra-margin"} ${
              process.env.IS_DISABLE_DONATION_PAYMENT === "true"
                ? "disabled"
                : ""
            }`}
            data-tooltip-id={
              Number(campaign?.pacMultiplier) > 1 ? "campaignDetailCard" : ""
            }
            data-tooltip-content={doublePacsTipText}>
            <FontAwesomeIcon icon={faHandHoldingHeart} />
            {process.env.IS_DISABLE_DONATION_PAYMENT === "true" ? (
              <FormattedMessage
                id="button::text::comingSoon"
                defaultMessage="Coming Soon"
              />
            ) : (
              <FormattedMessage
                id="campaign::detail::buttons::supportCampaign"
                defaultMessage="Donate and Get PACs"
              />
            )}
            {Number(campaign?.pacMultiplier) > 1 ? <PacMultiplier /> : null}
          </StyledSupportButton>
          <StyledTooltip>
            {Number(campaign?.pacMultiplier) > 1 && (
              <Tooltip
                arrowColor="transparent"
                effect="solid"
                place="bottom"
                type="info"
                textColor="black"
                backgroundColor="white"
                id="campaignDetailCard"
                className="campaignDetailCard"
                delayShow={500}
              />
            )}
          </StyledTooltip>
        </div>
      )}
    </StyledMainContainer>
  )
}

RecurringDonation.propTypes = {
  amountOne: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  descriptionOne: PropTypes.string,
  amountTwo: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  descriptionTwo: PropTypes.string,
  amountThree: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  descriptionThree: PropTypes.string,
  campaign: PropTypes.object,
}

export default RecurringDonation
