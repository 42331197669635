import React from "react"
import { CardList } from "@tmu/components/common"
import { StyledCurrentInTMU } from "@tmu/global/page-addons/how-it-works.styles"

const CurrentInTMU = ({ title, data, variant }) => {
  return (
    <StyledCurrentInTMU data-testid="list-current">
      {title}
      {data && (
        <CardList slider variant={variant} items={data?.edges} maxCols={3} />
      )}
    </StyledCurrentInTMU>
  )
}

export default CurrentInTMU
