import styled from "@emotion/styled"
import { css } from "@emotion/react"

export const StyledModalActions = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  gap: 1rem;
  button {
    width: 100%;
    min-height: 2.5rem;
    margin: 0;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    display: block;
    button {
      width: 100%;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    button {
      min-height: 3.375rem;
    }
  }

  &.sticky-bottom-bar {
    button {
      margin-bottom: unset;
    }
  }
`

export const StyledHeader = styled.h2`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.bold};
  font-size: 1.5rem;
  line-height: 2rem;
  display: flex;
  align-items: center;
  letter-spacing: 0.01rem;
  color: ${(p) => p.theme.colors.black};
  margin: 0 1rem 1.5rem 0;
  div {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.bold};
    font-size: 1.5rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
    line-height: 2.625rem;
  }
`

export const StyledText = styled.p`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.regular};
  font-size: 1rem;
  line-height: 1.25rem;
  color: ${(p) => p.theme.colors.black};
  text-align: left;
  margin-bottom: 2.5rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
`

export const StyledCloseIcon = styled.div`
  font-size: 1.75rem;
  height: 1.75rem;
  margin-top: 0;
  line-height: 1.25rem;
  color: ${(p) => p.theme.colors.black};
  cursor: pointer;

  &.close-icon {
    svg {
      font-size: 1.5rem;
      height: 1.5rem;
      width: 1.5rem;
    }
  }
`

export const StyledHeaderWrapper = styled.div`
  display: flex;
  padding-top: 1.5rem;

  ${(p) =>
    p.stickyHeader &&
    css`
      position: sticky;
      top: 0;
      height: fit-content;
      background: white;
      text-align: left;
    `}

  .mobile-header {
    margin-bottom: 1.5rem;
    margin-left: 1rem;
  }

  .mobile-icon {
    font-size: 1.2rem;
    margin-top: 0.5rem;
  }
`

export const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0 1.5rem 1.5rem",
    textAlign: "center",
    lineHeight: 3,
    borderRadius: 4,
    boxShadow: "0 0.25rem 0.75rem rgba(0, 0, 0, 0.15)",
    maxHeight: "100vh",
  },
}
