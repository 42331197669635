import React, { useState } from "react"
import {
  StyledTopicSelectItem,
  StyledTopicSelectItemImage,
  StyledTopicSelectItemText,
} from "./index.styles"

import { LazyImage } from "@tmu/components/common"

const TopicItem = ({
  id,
  imageUrl,
  label,
  imageWidth,
  imageHeight,
  onClick,
  selected,
}) => {
  return (
    <StyledTopicSelectItem
      onClick={() => {
        onClick()
      }}
      className={selected ? "selected" : ""}>
      <StyledTopicSelectItemImage>
        <LazyImage
          altName={`category-select-${imageUrl}`}
          src={imageUrl}
          width={imageWidth}
          height={imageHeight}
          fit="cover"
        />
      </StyledTopicSelectItemImage>

      <StyledTopicSelectItemText className={selected ? "selected" : ""}>
        <div>{label} </div>
      </StyledTopicSelectItemText>
    </StyledTopicSelectItem>
  )
}

export default TopicItem
