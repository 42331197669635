import React, { /* Fragment, */ useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useMutation } from "@apollo/client"

import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { FormattedMessage, useIntl, navigate } from "gatsby-plugin-intl"
import { useLazyQuery } from "@apollo/client"
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons/faArrowLeft"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useDonations, useToast, useDonationTier } from "@tmu/hooks"
import { Accordion, Spinner, NewCard } from "@tmu/components/common"
import SEO from "@tmu/components/seo"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripeInstance } from "@tmu/utils/stripe"
import { DonationForm } from "@tmu/components/forms"
import { useApolloApiClients } from "@tmu/apollo/client"
import { CAMPAIGN_DETAIL_QUERY_FOR_DONATION } from "@tmu/apollo/storefront/queries/campaign"
import { LATEST_SUBSCRIPTION_STATUS_QUERY } from "@tmu/apollo/storefront/queries/donation"
import { capitalize } from "@tmu/utils/string"
import { UPDATE_ME_MUTATION } from "@tmu/apollo/storefront/mutations/user"
import {
  StyledWrapper,
  StyledSupportCheckoutForm,
  StyledFAQSection,
  StyledFAQQuestion,
  StyledFAQAnswer,
  StyledFormTitle,
  StyledFormSubTitle,
  StyledBackButton,
  StyledCampaignCardPreview,
} from "./index.styles"
import { getValueForLocale } from "@tmu/utils/string"
import { format, differenceInCalendarDays } from "date-fns"
import { useFooterType } from "@tmu/hooks"
import { FOOTER_TYPE, RECURRING_DONATION_TYPE } from "@tmu/apollo/constants"
import { get } from "@tmu/utils/storage"
import { isTokenExpired } from "@tmu/utils/auth"
import { usePacDiscountCalculation } from "@tmu/hooks"
import { OFFER_TYPE } from "@tmu/apollo/constants"
import { useAuth } from "@tmu/src/hooks"

const style = {
  width: "90%",
  maxWidth: "31.25rem",
  height: "90%",
  padding: 0,
}

const CampaignDonationForm = ({ slug }) => {
  const { isTablet } = getAllScreenTypes()
  const { formatMessage, locale, defaultLocale } = useIntl()
  const { storefrontClient } = useApolloApiClients()
  const [amount, setAmount] = useState("")
  const [redirectTo, setRedirectTo] = useState("")
  const [merchantId, setMerchantId] = useState("")
  const [eventDate, setEventDate] = useState(null)
  const { callUserProfile } = useAuth()
  useFooterType({ footerType: FOOTER_TYPE.HIDE })
  const {
    getDonationTier,
    donationTierData,
    called: donationTierCalled,
  } = useDonationTier()
  const isAuthenticated = !isTokenExpired(get("token"))
  const { updatePacRecon, updateCalled } = usePacDiscountCalculation()

  const [campaign, setCampaign] = useState(null)

  const isStoreSupporter = !!campaign?.store?.id
  const [updateProfile] = useMutation(UPDATE_ME_MUTATION, {
    client: storefrontClient,
  })
  const toast = useToast()
  const [callCampaignDetail, { loading, error, data }] = useLazyQuery(
    CAMPAIGN_DETAIL_QUERY_FOR_DONATION,
    {
      variables: { slug, isPublic: true },
      client: storefrontClient,
    }
  )
  const subscriptionProductsId = campaign?.subscriptionProducts?.edges?.[0]?.node?.id

  const [
    getLatestSubscription,
    { error: subscriptionError, data: subscriptionData },
  ] = useLazyQuery(LATEST_SUBSCRIPTION_STATUS_QUERY, {
    client: storefrontClient,
  })

  let dst = ""
  let isCharities = false
  let donationTierId = null
  let donationType = ""
  let donationAmount = 0
  let referralCode = ""

  if (typeof window !== "undefined") {
    const pageUrl = new URL(window?.location?.href)
    dst = pageUrl.searchParams.get("dst")
    donationTierId = pageUrl.searchParams.get("tier")
    isCharities = pageUrl.pathname.includes("/charities")
    donationType = pageUrl.searchParams.get("donationType")
    donationAmount = parseFloat(
      pageUrl.searchParams
        .get("donationAmount")
        ?.toString()
        .replace(/,/g, "") || 5
    )
    referralCode = pageUrl.searchParams.get("referral")
  }

  const isSingleDonation = donationType === RECURRING_DONATION_TYPE.SINGLE
  const isRecurringDonation =
    donationType === RECURRING_DONATION_TYPE.MONTHLY ||
    donationType === RECURRING_DONATION_TYPE.ANNUALLY

  const handleError = (error) => {
    toast?.error(
      formatMessage({
        id: "forms::error::stripeClientSecret",
        defaultMessage: "There was a problem during the transaction",
      })
    )
  }
  const sleep = (time) =>
    new Promise((res) => setTimeout(res, time, "done sleeping"))

  async function confirmSubscription({
    donationId,
    merchantName,
    recurringData,
  }) {
    let status
    const donationData = recurringData?.donation
    donationData.campaign = recurringData?.campaign
    let paymentIntentStatus
    let clientSecret
    let counter = 0
    while (counter < 5) {
      await sleep(1000).then(async () => {
        try {
          const result = await getLatestSubscription({
            variables: { id: recurringData.subscriptionId },
            fetchPolicy: "network-only",
          })
          status = result.data?.subscription?.status
          clientSecret = result.data?.subscription?.clientSecret

          if (
            paymentIntentStatus === "succeeded" ||
            status === "ACTIVE" ||
            status === "CANCELED"
          ) {
            counter = 5
          }
        } catch (error) {
          console.error(error)
          handleError(1)
        } finally {
          counter++
        }
      })
    }
    if (paymentIntentStatus === "succeeded" || status === "ACTIVE") {
      const earnedPACValue =
        Number(donationData?.earnedPac) ||
        Number(donationData?.amount || 0) *
          Number(donationData?.campaign?.pacMultiplier || 0) ||
        0
      const isOfflineMerchant =
        donationData?.campaign?.store?.defaultOffer?.offerType ===
        OFFER_TYPE.OFFLINE

      const isCorporateMerchant =
        donationData?.campaign?.store?.defaultOffer?.offerType ===
        OFFER_TYPE.BRAND

      const campaignName = capitalize(
        getValueForLocale(donationData?.campaign, "name", locale, defaultLocale)
      )
      navigate(
        `/campaigns/${slug}/donation-success?donationType=${
          donationType || "other"
        }${donationId ? `&donation=${donationId}` : ""}${
          referralCode ? "&referral=" + referralCode : ""
        }${donationData?.isDWOR ? `&dwor=true` : ""}${
          isCorporateMerchant ? `&icm=true` : ""
        }${isOfflineMerchant ? `&iom=true` : ""}${
          donationData?.intendedEmail
            ? `&email=${donationData?.intendedEmail}`
            : ""
        }${donationData?.amount ? `&da=${donationData?.amount}` : ""}${
          donationData?.campaign?.isVoucher ? `&civ=true` : ""
        }${
          donationData?.campaign?.isVoucher && donationData?.campaign?.deadline
            ? `&cd=${
                new Date(donationData?.campaign?.deadline)
                  .toISOString()
                  .split("T")[0]
              }`
            : ""
        }${earnedPACValue ? `&epv=${earnedPACValue}` : ""}${
          donationData?.campaign?.isDefaultCampaign ? `&cid=true` : ""
        }${campaignName ? `&cn=${campaignName}` : ""}${
          donationData?.campaign?.slug
            ? `&cs=${donationData?.campaign?.slug}`
            : ""
        }${
          donationData?.campaign?.user?.fullName
            ? `&cuf=${donationData?.campaign?.user?.fullName}`
            : ""
        }${
          donationData?.campaign?.store?.name
            ? `&csn=${donationData?.campaign?.store?.name}`
            : ""
        }${
          donationData?.campaign?.partner?.slug
            ? `&cps=${donationData?.campaign?.partner?.slug}`
            : ""
        }${
          donationData?.campaign?.partner?.name
            ? `&cpn=${donationData?.campaign?.partner?.name}`
            : ""
        }${
          redirectTo && merchantName
            ? `&redirectTo=${redirectTo}&merchantName=${merchantName}`
            : ""
        }`,
        {
          state: {
            data: recurringData,
          },
        }
      )
    } else if (paymentIntentStatus !== "succeeded" || status === "CANCELED") {
      handleError(2)
    }
  }

  const handleDonationComplete = async ({
    donationId,
    merchantName,
    donationData,
    recurringData,
    reconId,
    isOfflineNoDonationNeeded,
    paymentMethod,
    stripe,
    paymentType,
  }) => {
    if (isAuthenticated) {
      updateProfile({
        variables: {
          input: {
            firstName: donationData?.firstName,
            lastName: donationData?.lastName,
            phoneNumber: donationData?.phoneNumber,
            taxId: donationData?.taxId,
            accountType: donationData?.accountType,
          },
        },
      })
        .then(({ data }) => {
          callUserProfile()
        })
        .catch((err) => {
          console.error(err)
        })
    }
    if (reconId) {
      await updatePacRecon({
        variables: {
          input: {
            id: reconId,
            status: "USED",
          },
        },
      }).then(({ data }) => {
        if (isOfflineNoDonationNeeded) {
          navigate(
            `/campaigns/${slug}/donation-success?noDonation=true&used=${
              data?.updateMerchantPacReconciliation?.merchantPacReconciliation
                ?.pacAmountAfterNormalizedDonation || 0
            }&newPrice=${
              data?.updateMerchantPacReconciliation?.merchantPacReconciliation
                ?.purchaseAmountAfterDiscount || 0
            }`
          )
        }
      })
      if (isOfflineNoDonationNeeded) return
    }

    if (recurringData?.subscriptionId) {
      await confirmSubscription({
        donationId,
        merchantName,
        recurringData,
        paymentMethod,
        stripe,
        paymentType,
        donationData,
      })
    } else if (donationId) {
      const earnedPACValue =
        Number(donationData?.earnedPac) ||
        Number(donationData?.amount || 0) *
          Number(donationData?.campaign?.pacMultiplier || 0) ||
        0
      const isOfflineMerchant =
        donationData?.campaign?.store?.defaultOffer?.offerType ===
        OFFER_TYPE.OFFLINE

      const isCorporateMerchant =
        donationData?.campaign?.store?.defaultOffer?.offerType ===
        OFFER_TYPE.BRAND

      const campaignName = capitalize(
        getValueForLocale(donationData?.campaign, "name", locale, defaultLocale)
      )
      navigate(
        `/campaigns/${slug}/donation-success?donationType=${
          donationType || "other"
        }${donationId ? `&donation=${donationId}` : ""}${
          referralCode ? "&referral=" + referralCode : ""
        }${donationData?.isDWOR ? `&dwor=true` : ""}${
          isCorporateMerchant ? `&icm=true` : ""
        }${isOfflineMerchant ? `&iom=true` : ""}${
          donationData?.intendedEmail
            ? `&email=${donationData?.intendedEmail}`
            : ""
        }${donationData?.amount ? `&da=${donationData?.amount}` : ""}${
          donationData?.campaign?.isVoucher ? `&civ=true` : ""
        }${
          donationData?.campaign?.isVoucher && donationData?.campaign?.deadline
            ? `&cd=${
                new Date(donationData?.campaign?.deadline)
                  .toISOString()
                  .split("T")[0]
              }`
            : ""
        }${earnedPACValue ? `&epv=${earnedPACValue}` : ""}${
          donationData?.campaign?.isDefaultCampaign ? `&cid=true` : ""
        }${campaignName ? `&cn=${campaignName}` : ""}${
          donationData?.campaign?.slug
            ? `&cs=${donationData?.campaign?.slug}`
            : ""
        }${
          donationData?.campaign?.user?.fullName
            ? `&cuf=${donationData?.campaign?.user?.fullName}`
            : ""
        }${
          donationData?.campaign?.store?.name
            ? `&csn=${donationData?.campaign?.store?.name}`
            : ""
        }${
          donationData?.campaign?.partner?.slug
            ? `&cps=${donationData?.campaign?.partner?.slug}`
            : ""
        }${
          donationData?.campaign?.partner?.name
            ? `&cpn=${donationData?.campaign?.partner?.name}`
            : ""
        }${
          redirectTo && merchantName
            ? `&redirectTo=${redirectTo}&merchantName=${merchantName}`
            : ""
        }`,
        {
          state: {
            data: { donation: donationData, campaign: donationData?.campaign },
          },
        }
      )
    } else handleError(3)
  }

  const {
    getShopByDonationData,
    data: shopByDonationData,
    error: shopByDonationError,
  } = useDonations()

  useEffect(() => {
    if (dst?.length) {
      getShopByDonationData(dst)
    } else {
      callCampaignDetail()
    }
  }, [])

  useEffect(() => {
    if (donationTierId?.length && !donationTierCalled) {
      getDonationTier(donationTierId)
    }
  }, [])

  useEffect(() => {
    setAmount(donationType ? donationAmount : donationTierData?.donationAmount)
  }, [donationTierData])

  useEffect(() => {
    if (shopByDonationError) {
      toast?.error(shopByDonationError?.message)
    }
  }, [shopByDonationError])

  useEffect(() => {
    if (shopByDonationData?.donateByShopping) {
      setAmount(shopByDonationData?.donateByShopping?.amount)
      setRedirectTo(shopByDonationData?.donateByShopping?.redirectTo)
      setMerchantId(shopByDonationData?.donateByShopping?.merchantId)

      const campaignData = {
        ...shopByDonationData?.donateByShopping?.campaign,
        name: getValueForLocale(
          shopByDonationData?.donateByShopping?.campaign,
          "name",
          locale,
          defaultLocale
        ),
      }

      setCampaign(campaignData)
    }
  }, [shopByDonationData])

  useEffect(() => {
    if (data) {
      const campaignData = {
        ...data.campaign,
        name: getValueForLocale(data.campaign, "name", locale, defaultLocale),
      }

      setCampaign(campaignData)
    }
    if (data?.campaign) {
      const deadline = new Date(data?.campaign?.deadline)
      const timeDiff = deadline.getTime() - Date.now()
      if (timeDiff > 0) {
        const dayDiff = differenceInCalendarDays(deadline, new Date())
        const formattedDate = format(deadline.getTime(), "dd/MM/yyyy")
        const formattedTime = format(deadline.getTime(), "HH:mm")
        const dateData = {
          daysLeft: dayDiff,
          endDate: formattedDate,
          hour: formattedTime,
        }
        setEventDate(dateData)
      }
    }
  }, [data])

  if (loading) return <Spinner />

  if (error) {
    toast?.error(
      formatMessage({
        id: "dashboard::donations::refundError",
        defaultMessage: "An error occurred while processing your request",
      })
    )
    navigate("/campaigns?campaign=true&charity=true&event=true")
  }
  const faqs =
    campaign?.faqs?.edges.map(({ node }) => ({
      ...node,
      question: getValueForLocale(node, "question", locale, defaultLocale),
      answer: getValueForLocale(node, "answer", locale, defaultLocale),
    })) ?? []

  const stripeInstance = loadStripeInstance(
    data?.campaign?.stripePublicKey ||
      shopByDonationData?.donateByShopping?.campaign?.stripePublicKey,
    locale
  )

  const donationTierTitle = getValueForLocale(
    donationTierData,
    "description",
    locale,
    defaultLocale
  )

  /* const EventTimeInfo = () => {
    return eventDate ? (
      <StyledEventTimeInfo data-testid="event-time-info">
        <StyledPreviewCardSubHeader>
          <FormattedMessage
            id="event::officialEventCampaign"
            defaultMessage="Official Event Campaign"
          />
        </StyledPreviewCardSubHeader>
        <StyledPreviewCardSubHeader>
          <FormattedMessage
            id="event::timeInfoDay"
            defaultMessage="{daysLeft, plural, =0 {<strong>today</strong>} =1 {<strong>tomorrow</strong>} other {<strong>{daysLeft} days left</strong> until <strong>{endDate}</strong>}}"
            values={{
              daysLeft: eventDate?.daysLeft,
              endDate: eventDate?.endDate,
              strong: (...chunks) => (
                <strong>
                  {chunks.map((chunk, i) => (
                    <Fragment key={i}>{chunk}</Fragment>
                  ))}
                </strong>
              ),
            }}
          />
        </StyledPreviewCardSubHeader>
        <StyledPreviewCardSubHeader>
          <FormattedMessage
            id="event::timeInfoHour"
            defaultMessage="starts at <strong>{hour}</strong>"
            values={{
              hour: eventDate?.hour,
              strong: (...chunks) => (
                <strong>
                  {chunks.map((chunk, i) => (
                    <Fragment key={i}>{chunk}</Fragment>
                  ))}
                </strong>
              ),
            }}
          />
        </StyledPreviewCardSubHeader>
      </StyledEventTimeInfo>
    ) : null
  } */

  return (
    <>
      <SEO
        lang={locale}
        title={formatMessage(
          {
            id: "campaign::donationForm::title",
            defaultMessage: "{name}",
          },
          { name: campaign?.name }
        )}
        image={campaign?.image?.url}
      />
      <StyledWrapper>
        <StyledBackButton
          onClick={() =>
            window?.history ? window.history.back() : navigate("/")
          }>
          <FontAwesomeIcon icon={faArrowLeft} />
        </StyledBackButton>
        <div>
          <StyledCampaignCardPreview>
            <NewCard
              variant={campaign?.store?.id ? "campaign" : "summary"}
              key={campaign?.id}
              id={campaign?.id}
              imageSrc={
                campaign?.image?.url ||
                campaign?.images?.edges?.[0]?.node?.image ||
                getValueForLocale(
                  campaign?.partner,
                  "logo",
                  locale,
                  defaultLocale
                )
              }
              title={getValueForLocale(campaign, "name", locale, defaultLocale)}
              type={campaign?.campaignType}
              totalDonors={campaign?.donationCount}
              totalSupport={campaign?.fundedAmount}
              size="default"
              cardLink={`/campaigns/${campaign?.slug}`}
              goalAmount={campaign?.goalAmount}
              fundedAmount={campaign?.fundedAmount}
              storeLogo={getValueForLocale(
                campaign?.store,
                "logo",
                locale,
                defaultLocale
              )}
              partnerLogo={getValueForLocale(
                campaign?.partner,
                "logo",
                locale,
                defaultLocale
              )}
              partnerName={
                campaign?.partner?.displayName || campaign?.partner?.name
              }
              pacMultiplier={campaign?.pacMultiplier}
              isStoreSupporter={isStoreSupporter}
              isCorporate={
                campaign?.store?.defaultOffer?.offerType === OFFER_TYPE.BRAND
              }
            />
          </StyledCampaignCardPreview>
        </div>
        <StyledSupportCheckoutForm>
          <StyledFormTitle>
            {isTablet ? (
              isSingleDonation ? (
                <FormattedMessage
                  id="campaign::donationForm::donationSingleCheckout"
                  defaultMessage="Single donation checkout"
                />
              ) : isRecurringDonation ? (
                donationType === RECURRING_DONATION_TYPE.MONTHLY ? (
                  <FormattedMessage
                    id="campaign::donationForm::donationMonthlyRecurringCheckout"
                    defaultMessage="Monthly recurring donation checkout"
                  />
                ) : donationType === RECURRING_DONATION_TYPE.ANNUALLY ? (
                  <FormattedMessage
                    id="campaign::donationForm::donationAnnuallyRecurringCheckout"
                    defaultMessage="Annually recurring donation checkout"
                  />
                ) : null
              ) : (
                <FormattedMessage
                  id="campaign::donationForm::donationCheckout"
                  defaultMessage="Donation checkout"
                />
              )
            ) : isSingleDonation ? (
              <FormattedMessage
                id="campaign::donationForm::donationSingleFor"
                defaultMessage="Single donation for {name}"
                values={{
                  name: getValueForLocale(
                    campaign,
                    "name",
                    locale,
                    defaultLocale
                  ),
                }}
              />
            ) : isRecurringDonation ? (
              donationType === RECURRING_DONATION_TYPE.MONTHLY ? (
                <FormattedMessage
                  id="campaign::donationForm::donationMonthlyRecurringFor"
                  defaultMessage="Monthly recurring donation for {name}"
                  values={{
                    name: getValueForLocale(
                      campaign,
                      "name",
                      locale,
                      defaultLocale
                    ),
                  }}
                />
              ) : donationType === RECURRING_DONATION_TYPE.ANNUALLY ? (
                <FormattedMessage
                  id="campaign::donationForm::donationAnnuallyRecurringFor"
                  defaultMessage="Annually recurring donation for {name}"
                  values={{
                    name: getValueForLocale(
                      campaign,
                      "name",
                      locale,
                      defaultLocale
                    ),
                  }}
                />
              ) : null
            ) : (
              <FormattedMessage
                id="campaign::donationForm::donationFor"
                defaultMessage="Donation for {name}"
                values={{
                  name: getValueForLocale(
                    campaign,
                    "name",
                    locale,
                    defaultLocale
                  ),
                }}
              />
            )}
          </StyledFormTitle>
          {donationTierData && (
            <StyledFormSubTitle>{donationTierTitle}</StyledFormSubTitle>
          )}
          {campaign?.id && stripeInstance ? (
            <>
              <Elements stripe={stripeInstance} options={{ locale }}>
                <DonationForm
                  onComplete={handleDonationComplete}
                  campaign={campaign}
                  redirectTo={redirectTo}
                  merchantId={merchantId}
                  amount={amount}
                  pacMultiplier={campaign?.pacMultiplier}
                  dst={dst}
                  selectedTier={donationTierData?.id}
                  amountMin={Number(donationTierData?.donationAmount)}
                  subscriptionProductsId={subscriptionProductsId}
                  amountMax={
                    donationTierData?.donationAmountOperator === "EQ"
                      ? Number(donationTierData?.donationAmount)
                      : null
                  }
                  isAmountFixed={
                    donationType
                      ? true
                      : donationTierData?.donationAmountOperator === "EQ"
                  }
                  isStoreSupporter={isStoreSupporter}
                />
              </Elements>
            </>
          ) : (
            <FormattedMessage
              id="campaign::donationForm::donationNotAvailable"
              defaultMessage="Donations' checkout is not available at the moment. Please try later."
            />
          )}
          {faqs?.length > 0 && (
            <StyledFAQSection>
              <FormattedMessage
                id="campaign::donationForm::faq"
                defaultMessage="FAQ"
                tagName="p"
              />
              <Accordion>
                {faqs?.map((item) => (
                  <div
                    key={item.id}
                    label={
                      <StyledFAQQuestion>{item?.question}</StyledFAQQuestion>
                    }>
                    <StyledFAQAnswer>{item?.answer}</StyledFAQAnswer>
                  </div>
                ))}
              </Accordion>
            </StyledFAQSection>
          )}
        </StyledSupportCheckoutForm>
      </StyledWrapper>
    </>
  )
}

CampaignDonationForm.propTypes = {
  slug: PropTypes.string.isRequired,
}

CampaignDonationForm.defaultProps = {
  slug: "",
}

export default CampaignDonationForm
