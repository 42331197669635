import styled from "@emotion/styled"

export const StyledBenefits = styled.div`
  padding-bottom: 6.25rem;
  background-color: ${(p) => p.theme.colors.whiteSmoke};
`
export const StyledTitle = styled.h3`
  margin: 0 auto;
  text-align: center;

  font-size: 1.25rem;
  line-height: 153.8%;
  color: ${(p) => p.theme.colors.racingGreen};
  padding-bottom: 1rem;
  font-weight: ${(p) => p.theme.fontWeights.regular};

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1.5rem;
    padding-bottom: 2.5rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 2rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding-bottom: 4rem;
    font-size: 3rem;
  }
`
export const StyledDescription = styled.div`
  margin: 0 auto;
  text-align: center;
  font-size: 1.25rem;
  line-height: 153.8%;

  max-width: 47.5rem;
  padding-bottom: 5.125rem;
  color: ${(p) => p.theme.colors.doveGray};

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 0.9375rem;
    max-width: 18.75rem;
    padding-bottom: 1.875rem;
  }
`

export const StyledSubDescription = styled.h3`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.regular};
  font-size: 1.5rem;
  line-height: 1.8125rem;
  text-align: center;
  margin: 0 auto;
  color: ${(p) => p.theme.colors.pacificBlue};
  padding-bottom: 2.5rem;
  width: 30rem;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 0.9375rem;
    max-width: 18.75rem;
    padding-bottom: 1.875rem;
  }
`
export const StyledBenefitsList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 1.8rem;
  }
`
