import React from "react"
import {
  StyledCountStatsWrapper,
  StyledCountStat,
  StyledCountStatTitle,
} from "./index.styles"
import Money from "../Money"
const CountStats = ({ stat, title }) => {
  return (
    <StyledCountStatsWrapper>
      {stat && (
        <StyledCountStat>
          <Money value={stat || 0} maximumFractionDigits={0} currency={false} />
        </StyledCountStat>
      )}
      {title && <StyledCountStatTitle>{title}</StyledCountStatTitle>}
    </StyledCountStatsWrapper>
  )
}

export default CountStats
