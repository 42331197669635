import styled from "@emotion/styled"

export const StyledInfoIcon = styled.div`
  color: ${(p) => p.theme.colors.blue};
  height: 1.25rem;
`
export const StyledIconWrapper = styled.div`
  display: none;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: flex;
    align-items: center;
    > p {
      font-family: ${(p) => p.theme.fontFamilies.headline};
      font-style: ${(p) => p.theme.fontStyles.normal};
      font-weight: ${(p) => p.theme.fontWeights.semiBolder};
      color: ${(p) => p.theme.colors.blue};
      font-size: 1rem;
      line-height: 1.5rem;
    }
    .tooltip {
      background-color: ${(p) => p.theme.colors.white};
      color: ${(p) => p.theme.colors.pitchBlack};
      overflow-wordwrap: break-word;
      width: 20rem;
      padding: 1rem;
      font-size: 0.75rem;
      line-height: 1.25rem;
    }
    .text-tooltip {
      margin-top: 0.5rem;
    }
    svg {
      margin-left: 0.5rem;
      height: 1.25rem;
      cursor: pointer;
    }
    margin-top: 1rem;
    align-self: center;
    margin-top: unset;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    > p {
      font-size: 2rem;
      line-height: 2.625rem;
      margin-top: 0.5rem;
    }
    svg {
      height: 1.75rem;
    }
  }
`
