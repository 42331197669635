import styled from "@emotion/styled"

export const StyledShareButtons = styled.div`
  color: ${(p) => p.theme.colors.softGreen};

  .socialMedia-shareButton {
    animation: change 1.5s forwards 3;
    animation-delay: 1s;
  }
  @keyframes change {
    40% {
      color: black;
    }
  }

  .copyButton {
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.15);
  }
`

export const StyledCopyTextButton = styled.button`
  background-color: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
`
