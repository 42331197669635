import styled from "@emotion/styled"

export const StyledCardList = styled.div``

export const StyledCardCheckboxContainer = styled.div`
  cursor: pointer;
  display: flex;
  position: relative;
  margin-bottom: 1rem;
`

export const StyledDeleteButton = styled.div`
  height: 100%;
  margin-top: auto;
  margin-left: 0.5rem;
  svg {
    color: ${(p) => p.theme.colors.blue};
    font-family: ${(p) => p.theme.fontFamilies.headlineBig};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};

    @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.5rem;
      height: 1.5rem;
    }
  }
`

export const StyledRadioButton = styled.div`
  position: relative;
  z-index: 10;
  .radio-select-label {
    color: white;
  }

  .tooltip {
    box-shadow: 0.0625rem 0.0625rem 0.75rem rgba(0, 0, 0, 0.15);
    opacity: 1 !important;
    font-size: 1rem;
    line-height: 1.25rem;
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};

    @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.5rem;
      line-height: 2.25rem;
    }
  }
`
export const StyledMobileContent = styled.div`
  margin-top: calc(100vh / 4);
  h2 {
    line-height: 2rem;
  }
  p {
    line-height: 1.5rem;
  }

  button {
    width: calc(100vw - 3.5rem);
    bottom: 1rem;
    position: absolute;
  }
`
export const StyledNewCardText = styled.div`
  text-align: left;
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.pitchBlack};
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 1.5rem;
  margin-left: 1rem;
`
export const StyledNewCardForm = styled.div`
  width: 100%;
  .caption {
    text-align: left;
  }
  .input-feedback-absolute {
    top: 2.5rem;
  }

  .input-feedback {
    margin-top: -1rem;
  }
`
export const StyledButtonContainer = styled.div`
  width: 100%;
  margin-top: 3rem;
  button {
    width: 100%;
    margin-top: 1rem;
    margin-left: 0;
  }
`

export const StyledInoutContainer = styled.div`
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-left: 2rem;
  }
`
