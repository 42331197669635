import styled from "@emotion/styled"

export const StyledWrapper = styled.div`
  margin: 5.125rem auto 0;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    max-width: ${(p) => p.theme.breakpoints.tablet};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    max-width: ${(p) => p.theme.breakpoints.desktop};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    max-width: ${(p) => p.theme.breakpoints.wide};
  }
`

export const StyledStoryBox = styled.div`
  position: relative;
  display: flex;
  margin: auto;
  background-color: ${(p) => p.theme.colors.whiteSmoke};

  padding: 0;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 3rem 1.5rem 0 1.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    padding: 3rem 2rem 0 2rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding: 4rem 6rem 0 6rem;
  }
`

export const StyledStoryBoxHeader = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.white};
  text-align: center;
  font-size: 1.5rem;
  line-height: 2.125rem;
  max-width: 20.563rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    color: ${(p) => p.theme.colors.carrot};
    text-align: left;
    font-size: 2rem;
    line-height: 2.75rem;
    max-width: 21.5rem;
    margin-top: 2rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    max-width: 27.375rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 3rem;
    line-height: normal;
    max-width: 53.188rem;
    margin-top: 6rem;
  }
`

export const StyledStoryBoxTitle = styled.p`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.pitchBlack};
  background-color: ${(p) => p.theme.colors.whiteSmoke};
  text-align: center;
  font-size: 1.125rem;
  line-height: 1.75rem;
  width: 100%;
  padding: 2rem 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    background-color: inherit;
    padding: 0;
    text-align: unset;
    font-size: 1.125rem;
    line-height: 1.75rem;
    margin-top: 1.5rem;
    max-width: 21.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 1.125rem;
    line-height: 1.75rem;
    margin-top: 1.5rem;
    max-width: 26.875rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin-top: 2.5rem;
    font-size: 2rem;
    line-height: 2.75rem;
    max-width: 54.563rem;
  }
`

export const StyledStoryBoxOverlay = styled.div`
  z-index: 899;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: ${(p) => p.theme.colors.pitchBlack};
  opacity: 0.4;
`

export const StyledStoryBoxImage = styled.div`
  margin-top: 0;
  margin-left: 0;
  width: 100%;
  display: flex;
  padding-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    width: 14.766rem;
    height: 18.854rem;
    object-fit: cover;
    object-position: center top;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding-top: 0;
    width: auto;
    margin-top: 0.3125rem;
    margin-left: 2rem;

    & > img {
      width: 21.5rem;
      height: 21.375rem;
      object-fit: cover;
      object-position: center top;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin-left: 2.875rem;
    & > img {
      width: 30.313rem;
      height: 21.5rem;
      display: block;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin-top: 2rem;
    margin-left: 1.25rem;
    & > img {
      width: 52rem;
      height: 34.375rem;
    }
  }
`

export const StyledStoryBoxMobileText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledBenefitsList = styled.div`
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    align-items: flex-start;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
`

export const StyledBenefits = styled.div`
  background-color: ${(p) => p.theme.colors.white};
  display: flex;
  flex-direction: column;
  padding: 0.875rem 0;
  margin-bottom: 2.5rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 3.375rem 1.5rem;
    margin-bottom: 0;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    padding: 3rem 2rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding: 4rem 6rem;
  }
`

export const StyledBenefitCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  padding-top: 2.5rem;

  &:nth-child(2) {
    border-top: 0.063rem solid ${(p) => p.theme.colors.lightGrey};
    border-bottom: 0.063rem solid ${(p) => p.theme.colors.lightGrey};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    height: 100%;
    padding-top: 0;
    justify-content: flex-start;
    align-items: flex-start;

    &:nth-child(2) {
      border-right: 0.063rem solid ${(p) => p.theme.colors.lightGrey};
      border-left: 0.063rem solid ${(p) => p.theme.colors.lightGrey};
      border-top: none;
      border-bottom: none;
    }

    &:nth-child(1) {
      padding-right: 0.438rem;
    }

    &:nth-child(2) {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }

    &:nth-child(3) {
      padding-left: 1rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    &:nth-child(1) {
      padding-right: 1.438rem;
    }

    &:nth-child(2) {
      padding-right: 1rem;
      padding-left: 1rem;
    }

    &:nth-child(3) {
      padding-left: 1rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    &:nth-child(1) {
      padding-right: 1.938rem;
    }
    &:nth-child(2) {
      padding-right: 2rem;
      padding-left: 2rem;
    }

    &:nth-child(3) {
      padding-left: 1.938rem;
    }
  }
`

export const StyledFontIcon = styled.div`
  & > svg {
    color: ${(p) => p.theme.colors.carrot};
    height: 2rem;
    width: 2rem;
  }

  margin-bottom: 1.5rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-bottom: 1.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin-bottom: 1.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    & > svg {
      height: 4.125rem;
      width: 4.125rem;
    }
    margin-bottom: 2.5rem;
  }
`

export const StyledTitle = styled.h4`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-size: 1.125rem;
  width: 80%;
  line-height: 1.75rem;
  color: ${(p) => p.theme.colors.pitchBlack};
  text-align: center;
  margin-bottom: 1rem;
  height: auto;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    height: 100%;
    font-size: 1.4375rem;
    line-height: 2.125rem;
    text-align: left;
    margin-bottom: 1rem;
    width: 100%;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin-bottom: 0.625rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
    line-height: 2.75rem;
    margin-bottom: 2.0625rem;
  }
`

export const StyledDescription = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-weight: ${(p) => p.theme.fontWeights.regular};
  color: ${(p) => p.theme.colors.pitchBlack};
  text-align: center;
  width: 80%;
  font-size: 0.75rem;
  line-height: 1.25rem;
  min-width: 20.5625rem;
  margin-bottom: 2.5rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    min-height: ${(p) => (p.locale === "en" ? "6rem" : "8rem")};
    line-height: 1.5rem;
    text-align: left;
    min-width: 13.75rem;
    font-size: 1rem;
    margin-bottom: 0;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    min-height: ${(p) => (p.locale === "en" ? "4.5rem" : "6rem")};
    line-height: 1.5rem;
    min-width: 17.5rem;
    font-size: 1rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    min-height: 7rem;
    font-size: 1.5rem;
    line-height: 2.25rem;
    min-width: 33.3125rem;
  }
`

export const StyledStoreContainer = styled.div`
  display: flex;
  background-color: ${(p) => p.theme.colors.white};
  padding: 0;
  flex-direction: column-reverse;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    background-color: ${(p) => p.theme.colors.whiteSmoke};
    flex-direction: row;
    padding: 0 1.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    padding: 0 2rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding: 0 6rem;
  }
`

export const StyledStoreImage = styled.div`
  display: flex;
  justify-content: center;

  & > img {
    width: 20.5rem;
    height: 17.6875rem;
    margin-top: -0.3125rem;
    margin-left: 0;
    margin-bottom: 1.5625rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    & > img {
      width: 21.5rem;
      height: 18.5rem;
      margin-top: -0.3125rem;
      margin-left: -0.5rem;
      margin-bottom: 1.875rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    & > img {
      width: 30.313rem;
      height: 26rem;
      margin-top: -0.46875rem;
      margin-left: -0.75rem;
      margin-bottom: 2.625rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    & > img {
      width: 52rem;
      height: 44.625rem;
      margin-top: -0.875rem;
      margin-left: -1.25rem;
      margin-bottom: 4.375rem;
    }
  }
`

export const StyledStoreContent = styled.div`
  background-color: ${(p) => p.theme.colors.whiteSmoke};
  margin-left: 0;
  padding: 3rem 1rem 1.5rem 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 0;
    background-color: transparent;
    margin-left: 2rem;
  }
`

export const StyledStoreContentTitle = styled.h4`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-size: 1.5rem;
  line-height: 2.125rem;
  margin-bottom: 1rem;
  margin-top: 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 2rem;
    margin-top: 1.875rem;
    line-height: 2.75rem;
    margin-bottom: 1.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 1.75rem;
    line-height: 2.625rem;
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 3rem;
    margin-bottom: 2.5rem;
  }
`

export const StyledStoreContentDesc = styled.p`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-top: 1.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2.2rem;
    margin-top: 2rem;
  }
`

export const StyledStoreMapIcon = styled.div`
  margin: 1rem 0;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin: 1.5rem 0;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin: 2.5rem 0;
  }
`

export const StyledContactFormTitle = styled.p`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-size: 1.5rem;
  line-height: 2.125rem;
  margin-bottom: 1rem;
  margin-top: 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    line-height: 2.75rem;
    margin-top: 0;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 3rem;
    margin-bottom: 2.5rem;
    line-height: normal;
  }
`

export const StyledContactFormDescription = styled.p`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  font-size: 1rem;
  line-height: 1.5rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2.2rem;
  }
`
