import styled from "@emotion/styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "@tmu/components/common"

export const StyledWSMainContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    grid-template-columns: auto 22rem;
    grid-gap: 1rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    grid-template-columns: auto 29.25rem;
    grid-gap: 2rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    grid-template-columns: auto 52.75rem;
    grid-gap: 2.5rem;
  }
`
export const StyledWSLeftContainer = styled.div`
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
  }
`

export const StyledWSTitle = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.pitchBlack};

  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;
  width: 20.5rem;
  margin-bottom: 2rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1.5rem;
    line-height: 2rem;
    text-align: unset;
    width: unset;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 2rem;
    line-height: 2.625rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 3rem;
    line-height: 4.0625rem;
  }
`

export const StyledWSButton = styled(Button)`
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    min-height: 2.5rem;

    margin-top: 2.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin-top: 3.125rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    min-height: 4rem;
    margin-top: 4rem;
    font-size: 1rem;
  }
`

export const StyledWSButtonAwesomeIcon = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
  height: 1.5rem;
  margin-top: 0.2rem;
  
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    height: 1.5rem;
    font-size: 1.5rem;
    margin-top: 0.3rem;
  }
  
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }
  
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
    height: 2rem;
    margin-top: 0.8rem;
  }
`

export const StyledWSRightContainer = styled.div`
  .media-box {
    height: auto;
    width: 100vw;
    margin-left: -1.1rem;

    img {
      width: 100%;
      height: auto;
    }
  }

  display: flex;
  flex-direction: column-reverse;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    flex-direction: column;

    .media-box {
      width: 100%;
      margin-left: 0;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
  }
`
