import styled from "@emotion/styled"
import { css } from "@emotion/react"

export const StyledToggleWrapper = styled.div`
  &:hover {
    background-color: ${(p) => p.theme.colors.transparent}!important;
  }
  .react-toggle-track {
    width: 1.375rem;
    height: 0.75rem;
    border: 0.0625rem solid ${(p) => p.theme.colors.disabled};
    background: none !important;
    &:hover {
      background: none !important;
    }
  }
  .react-toggle-thumb {
    width: 0.5rem;
    height: 0.5rem;
    top: 0.1125rem;
    left: 0.15rem;
    border: none;
    background-color: ${(p) => p.theme.colors.disabled};
  }

  .react-toggle--focus {
    background: none !important;
    &:hover {
      background: none !important;
    }
    .react-toggle-thumb {
      box-shadow: none;
    }
  }
  .react-toggle--checked {
    background: none !important;
    &:hover {
      background: none !important;
    }
    .react-toggle-thumb {
      box-shadow: none;
      left: 0.6625rem;
    }
    .react-toggle-track {
      border: 0.0625rem solid ${(p) => p.theme.colors[p.color]};
      background: none !important;
      &:hover {
        background: none !important;
      }
    }
    .react-toggle-thumb {
      background-color: ${(p) => p.theme.colors[p.color]};
    }
  }
  .react-toggle--checked:hover:not(.react-toggle--disabled)
    .react-toggle-track {
    background-color: ${(p) => p.theme.colors.disabled};
    &:hover {
      background-color: ${(p) => p.theme.colors.transparent}!important;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    height: 0.75rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    height: auto;
    .react-toggle-track {
      width: 1.8125rem;
      height: 1rem;
    }
    .react-toggle-thumb {
      width: 0.75rem;
      height: 0.75rem;
    }
    .react-toggle--checked {
      .react-toggle-thumb {
        left: 0.8rem;
      }
    }
  }

  ${(p) =>
    p.disabled &&
    css`
      pointer-events: none;
    `}
`
