/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-onchange */

import { faTrashAlt } from "@fortawesome/pro-light-svg-icons/faTrashAlt"
import { faRepeat } from "@fortawesome/pro-light-svg-icons/faRepeat"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { MAX_PRODUCT_QUANTITY } from "@tmu/apollo/constants"
import { Button, LazyImage, Money } from "@tmu/components/common"
import { fillRange } from "@tmu/utils/array"
import PropTypes from "prop-types"
import React from "react"
import Select from "react-select"
import { StyledCartTableRow } from "./table.styles"
import theme from "@tmu/global/theme"
const CartTableRow = ({
  style,
  productId,
  fulfillmentId,
  fulfillmentStatus,
  voucherLink,
  cartItemId,
  name,
  image,
  subtotal,
  vatPercentage,
  pacDiscountAmount,
  quantity,
  onSetQuantity,
  onRemove = () => {},
  isSalesStatusVisible,
  disabled,
  isEditable = false,
  selectedCartItemId,
  imgSize,
  index = "",
  page = "",
  refund,
  handleRequestRefund,
  refundRequests = false,
  setRefund,
  status,
}) => {
  const handleQuantityChange = (item) => {
    onSetQuantity({ productId, cartItemId, quantity: item.value })
  }

  const quantityOptions = fillRange(1, MAX_PRODUCT_QUANTITY).map((i) => ({
    label: i,
    value: i,
  }))

  const selectStyles = {
    container: (provided, state) => {
      return { ...provided, width: 65 }
    },
    singleValue: (provided, state) => ({
      ...provided,
      opacity: state.isDisabled ? 0.5 : 1,
      transition: "opacity 300ms",
    }),
    menu: (provided, state) => ({
      ...provided,
      padding: 1,
    }),
    control: (provided, state) => {
      return { ...provided }
    },
    indicatorSeparator: (provided, state) => ({ ...provided, display: "none" }),
  }

  const [, fulfillmentIdStr] =
    fulfillmentId && fulfillmentId !== "-"
      ? window?.atob(fulfillmentId).split(":")
      : [, "-"]

  const imgSizeProps =
    imgSize === "condensed"
      ? {
          width: 64,
          height: 36,
        }
      : {
          width: 112,
          height: 64,
        }

  return (
    <StyledCartTableRow
      data-testid={"cart-table-row" + index}
      style={style}
      isSalesStatusVisible={isSalesStatusVisible}>
      {/* {isSalesStatusVisible &&
        (fulfillmentStatus === "SUCCESS" ? (
          <form method="get" action={voucherLink}>
            <Button
              type="submit"
              label="Download"
              variant="text"
              className="download-link"
              disabled={!voucherLink}>
              <FontAwesomeIcon icon={faFileDownload} />
            </Button>
          </form>
        ) : status ? (
          <FormattedMessage
            id={`dashboard::donors::orders::${status}`}
            defaultMessage={status}
          />
        ) : (
          "-"
        ))} */}
      <div>
        <LazyImage
          src={image}
          altName={`cart-table-row-${name}`}
          {...imgSizeProps}
        />
      </div>
      {/* <div>
        <strong>{name}</strong>
      </div> */}
      {/* {isSalesStatusVisible && (
        <ClickToCopy text={fulfillmentIdStr}>
          {truncateHash(fulfillmentIdStr, 16)}
        </ClickToCopy>
      )} */}
      {!isSalesStatusVisible && (
        <div className="centered">
          <Money value={pacDiscountAmount || 0} />
        </div>
      )}
      <div className="centered">
        {vatPercentage ? (
          <Money style="percent" value={vatPercentage || 0} currency={false} />
        ) : (
          "-"
        )}
      </div>
      <div className="centered">
        {isEditable && onSetQuantity ? (
          <Select
            simpleValue
            value={{ value: quantity, label: quantity }}
            onChange={handleQuantityChange}
            options={quantityOptions}
            getOptionLabel={(option) => option.label}
            isClearable={false}
            isSearchable={false}
            styles={selectStyles}
            menuContainerStyle={{ zIndex: 999 }}
            theme={(t) => ({
              ...t,
              borderRadius: 5,
              borderWidth: 1,
              colors: {
                ...t.colors,
                primary: `${(p) => p.theme?.colors?.lightGreen}`,
                primary25: `${(p) => p.theme?.colors?.lightOutline}`,
              },
            })}
            isDisabled={disabled}
          />
        ) : (
          quantity
        )}
      </div>
      <div className="centered">
        <Money value={subtotal || 0} />
      </div>
      {page === "dashboard-order" && (
        <Button
          data-testid="order-refund"
          label="Refund"
          className={`hide-mobile ${
            (refund || refundRequests || !fulfillmentId) && "lightButton"
          }`}
          disabled={refund || refundRequests || !fulfillmentId}
          variant="text"
          onClick={(evt) => {
            evt.stopPropagation()
            handleRequestRefund(fulfillmentId, refundRequests, setRefund)
          }}>
          <FontAwesomeIcon icon={faRepeat} />
        </Button>
      )}

      {isEditable && (
        <Button
          variant="text"
          label="Delete"
          className="delete-button"
          disabled={selectedCartItemId === cartItemId}
          onClick={() => onRemove({ productId, cartItemId })}>
          <FontAwesomeIcon icon={faTrashAlt} />
        </Button>
      )}
    </StyledCartTableRow>
  )
}

CartTableRow.propTypes = {
  style: PropTypes.object,
  productId: PropTypes.string.isRequired,
  cartItemId: PropTypes.string,
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  subtotal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pacDiscountAmount: PropTypes.string,
}

export default CartTableRow
