import { useLazyQuery } from "@apollo/client"
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons/faInfoCircle"
import { faSearch } from "@fortawesome/pro-light-svg-icons/faSearch"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useApolloApiClients } from "@tmu/apollo/client"
import { LIMITED_DONATION_SEARCH_QUERY } from "@tmu/apollo/storefront/queries/donation"
import { TextInput } from "@tmu/components/common"
import {
  DonationTable,
  EmptyTable,
} from "@tmu/components/dashboard/dashboardCommon"
import {
  StyledPage,
  StyledPageContent,
  StyledPageTitle,
} from "@tmu/global/page-addons/dashboard.styles"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import React, { useEffect, useState } from "react"
import { Tooltip } from "react-tooltip"
import {
  StyledForm,
  StyledIconWrapper,
  StyledInfoIcon,
  StyledSearchContainer,
  StyledTitleWrapper,
} from "./index.styles"

export default function DonationSearch({ dashboardType = "donors", location }) {
  const [searchId, setSearchId] = useState("")
  const { storefrontClient } = useApolloApiClients()
  const { formatMessage } = useIntl()
  const [donations, setDonations] = useState([])
  const [refetchLoading, setRefetchLoading] = useState(false)
  const [dataNotFound, setDataNotFound] = useState(false)
  const [isFirstCall, setIsFirstCall] = useState(false)

  const handleResult = (called, data, err) => {
    if (called && data?.limitedDonation === null) {
      setDonations([])
      if (!isFirstCall) {
        handleSearchError(err)
      }
      return
    }

    if (data?.limitedDonation !== null) {
      const donationData = {
        ...data?.limitedDonation,
        id: window?.btoa("DonationNode:" + searchId),
        campaign: {
          id: "",
          deadline: data?.limitedDonation?.campaignDeadline,
          partner: {
            name: data?.limitedDonation?.nonProfit,
            id: "",
          },
          nameEn: data?.limitedDonation?.campaign,
          nameEs: data?.limitedDonation?.campaign,
          nameIt: data?.limitedDonation?.campaign,
          name: data?.limitedDonation?.campaign,
          slug: data?.limitedDonation?.campaignSlug,
        },
        earnedPac: data?.limitedDonation?.earnedPac,
        created: data?.limitedDonation?.datetime,
      }
      setDonations([donationData])
    }
  }

  const [
    callDonationSearch,
    {
      loading: donationSearchLoading,
      data: donationSearchData,
      refetch: refetchDonationSearch,
      called: refetchDonationSearchCalled,
      error: donationError,
    },
  ] = useLazyQuery(LIMITED_DONATION_SEARCH_QUERY, {
    client: storefrontClient,
    onCompleted: (result) => {
      setIsFirstCall(true)
      handleResult(refetchDonationSearchCalled, result, donationError)
    },
  })

  useEffect(() => {
    if (typeof window !== "undefined") {
      const code = new URL(window?.location?.href).searchParams.get("code")
      setSearchId(code)
      handleSearch(code)
    }
  }, [])

  const handleSearch = (value) => {
    setDonations([])
    value = value?.trim()
    if (value?.length) {
      if (refetchDonationSearchCalled) {
        setRefetchLoading(true)
        setDataNotFound(false)
        refetchDonationSearch({ donationId: value })
          ?.then((d) => {
            setRefetchLoading(false)
            if (d?.data?.limitedDonation) {
              handleResult(refetchDonationSearchCalled, d?.data)
            } else {
              setDataNotFound(true)
              handleSearchError(d?.errors?.at(0))
            }
          })
          .catch((err) => {
            setDataNotFound(true)
          })
      } else {
        callDonationSearch({
          variables: { donationId: value },
        })
      }
    }
  }

  const handleSearchError = (err) => {
    if (err && (err?.length || err?.message)) {
      const errorMessage = err?.message || err
      //error(errorMessage)
    }
  }

  const shouldShowEmptyTable =
    (donations === null || donations?.length === 0 || dataNotFound) &&
    refetchDonationSearchCalled

  return (
    <StyledPage>
      <StyledPageTitle>
        <StyledTitleWrapper>
          <div>
            <FormattedMessage
              id="dashboard::donationSearch::title"
              defaultMessage="Donation Search"
              tagName="h1"
            />
          </div>

          <StyledIconWrapper>
            <StyledInfoIcon data-tooltip-id="info-tooltip" data-tooltip="test">
              <FontAwesomeIcon icon={faInfoCircle} />
            </StyledInfoIcon>
            <Tooltip
              arrowColor="transparent"
              effect="solid"
              type="info"
              id="info-tooltip"
              place="bottom"
              opacity={1}
              data-html={true}
              insecure={true}
              multiline={true}
              className="tooltip">
              <FormattedMessage
                id="dashboard::donationSearch::info"
                defaultMessage="Using this search, you can verify the immutable recording of your donation in our private blockchain environment"
              />
            </Tooltip>
          </StyledIconWrapper>
        </StyledTitleWrapper>
      </StyledPageTitle>

      <StyledPageContent data-testid="donations-page-content">
        <>
          <StyledSearchContainer>
            <StyledForm onSubmit={() => handleSearch(searchId)}>
              <FontAwesomeIcon icon={faSearch} />
              <TextInput
                data-testid="donation-search-box"
                id="donationId"
                name="donationId"
                value={searchId}
                type="text"
                label={formatMessage({
                  id: "dashboard::donationSearch::inputPlaceholder",
                  defaultMessage: "Blockchain donation id",
                })}
                className="float-container full-width search-input"
                placeholder=""
                onChange={(event) => {
                  event.preventDefault()
                  event.stopPropagation()
                  setSearchId(event?.target?.value?.trim())
                }}
                onKeyPress={(event) => {
                  if (event.key === "Enter" || event.code === "Enter") {
                    event.preventDefault()
                    event.stopPropagation()
                    const val = event?.target?.value
                    setSearchId(val)
                    handleSearch(val)
                  }
                }}
              />
            </StyledForm>
          </StyledSearchContainer>
          <>
            {donations && donations?.length > 0 && (
              <DonationTable
                dashboardType={dashboardType}
                donationsData={donations}
                isStatsBar={false}
                columnsToHide={["receipt"]}
              />
            )}

            {shouldShowEmptyTable && (
              <EmptyTable
                emptyMessage={
                  <FormattedMessage
                    id="dashboard::donationSearch::empty"
                    defaultMessage="It seems there is no donation with this info!"
                  />
                }
              />
            )}
          </>
        </>
      </StyledPageContent>
    </StyledPage>
  )
}
