import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft"
import { faMinus } from "@fortawesome/pro-light-svg-icons/faMinus"
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  API_PERMISSIONS,
  REVIEW_STATUS,
  VISIBILITY_STATUS,
} from "@tmu/apollo/constants"
import {
  Button,
  Checkbox,
  CountrySelectorFilter,
  DatePicker,
  Dropdown,
  FieldError,
  InfoBox,
  OnSubmitValidationError,
  RadioSelect,
  Spinner,
  TermsAndConditions,
  TextInput,
} from "@tmu/components/common"
import { ImageSelector } from "@tmu/components/dashboard/dashboardCommon"
import {
  StyledForm,
  StyledFormSectionHelpText,
  StyledFormSectionTitle,
  StyledPageActions,
  StyledPageContent,
} from "@tmu/global/page-addons/dashboard.styles"
import { useAllOfferCategories, useAuth, useToast } from "@tmu/hooks"
import SEO from "@tmu/src/components/seo"
import { HomePageContext } from "@tmu/src/context/homePageContext"
import { Spacer } from "@tmu/src/global/page-addons/detail-page.styles"
import theme from "@tmu/src/global/theme"
import { useOffers } from "@tmu/src/hooks"
import { getAllScreenTypes } from "@tmu/src/utils/mediaQueries"
import { formatDate } from "@tmu/utils/date"
import {
  capitalize,
  getQueryParams,
  getValueForLocale,
  htmlToPlainText,
} from "@tmu/utils/string"
import {
  categories,
  countryRequired,
  description,
  offerLink,
  offerName,
  percentage,
} from "@tmu/utils/validation"
import { FastField, Formik } from "formik"
import { FormattedMessage, navigate, useIntl } from "gatsby-plugin-intl"
import { useContext, useEffect, useState } from "react"
import * as Yup from "yup"
import { StyledCampaignCreationWrapper } from "../../CampaignCreation/index.styles"
import { LeaveModal } from "./LeaveModal"
import {
  StyledConfirmationOptionsContainer,
  StyledConfirmationOptionsDescription,
  StyledConfirmationOptionsTitle,
  StyledDatePickerWrapper,
  StyledDescriptionText,
  StyledLabel,
  StyledOfferPage,
  StyledRadioOptions,
  StyledTitle,
  StyledTitleContainer,
} from "./index.styles"

const ENTRY_DONATION_MAX_TEXT_LENGTH = 5000

const OfferForm = ({ id }) => {
  const { apiPermissions } = useAuth()
  const { formatMessage, locale, defaultLocale } = useIntl()
  const { success, error } = useToast()
  const [tabIndex, setTabIndex] = useState(0)
  const [fullValidate, setFullValidate] = useState(false)
  const { menuConfig, setMenuConfig } = useContext(HomePageContext)
  const [isLeaveModalOpen, setLeaveModalOpen] = useState(false)
  const { isTablet } = getAllScreenTypes()
  const [selectedDate, setSelectedDate] = useState(null)
  const params = getQueryParams()
  const [isVoucher, setIsVoucher] = useState(params?.isVoucher === "true")

  const {
    getOfferDetail,
    updateOffer,
    createOffer,
    offerSubmitForReview,
    partialUpdateOffer,
    offerDetailLoading: loading,
    offerDetail: data,
  } = useOffers()

  const { allOfferCategories } = useAllOfferCategories()
  const offer = data?.offer
  const isDefaultOffer = offer?.isDefaultOffer

  const [activeLangs, setActiveLangs] = useState({
    english: "en" === locale,
    italian: "it" === locale,
    spanish: "es" === locale,
  })

  const [languageDescriptionStatus, setLanguageDescriptionStatus] = useState({
    italian: false,
    spanish: false,
  })

  const initialValues = {
    name: offer?.name || "",
    slugBody: offer?.slugBody || "",
    descriptionTabEn: htmlToPlainText(offer?.descriptionEn) || "",
    descriptionTabIt: htmlToPlainText(offer?.descriptionIt) || "",
    descriptionTabEs: htmlToPlainText(offer?.descriptionEs) || "",
    startDate: offer?.startDate,
    endDate: offer?.endDate,
    website: offer?.website || "",
    image: offer?.image || "",
    isUnlisted: offer?.isUnlisted || false,
    isReservationRequired: offer?.isReservationRequired ?? false,
    maxPacDiscount: offer?.maxPacDiscount,
    isVoucher: isVoucher || offer?.isVoucher,
    categories: offer?.categories?.edges?.length
      ? offer?.categories?.edges?.map((item) => {
          return {
            value: item?.node?.id,
            label: getValueForLocale(item?.node, "name", locale, defaultLocale),
          }
        })
      : [],
    availableCountries: offer?.availableCountries || [],
    availableCities: offer?.availableCities || [],
    externalId: offer?.externalId || "",
    offerLink: offer?.offerLink || "",
    country: offer?.country || offer?.store?.defaultOffer?.country || "",
    isWorldwide: offer?.isWorldwide,
  }

  const validationSchemaBasic = Yup.object().shape({
    name: offerName({ formatMessage }),
    categories: categories({ formatMessage }),
    country: countryRequired({ formatMessage }),
    descriptionTabEn: activeLangs.english && description({ formatMessage }),
    descriptionTabIt: activeLangs.italian && description({ formatMessage }),
    descriptionTabEs: activeLangs.spanish && description({ formatMessage }),
    maxPacDiscount: percentage({
      formatMessage,
    }),
    offerLink: offerLink({ formatMessage }),
  })

  const validationSchema = Yup.object().shape({
    name: offerName({
      formatMessage,
    }),
    descriptionTabEn: activeLangs.english && description({ formatMessage }),
    descriptionTabIt: activeLangs.italian && description({ formatMessage }),
    descriptionTabEs: activeLangs.spanish && description({ formatMessage }),
    categories: categories({ formatMessage }),
    country: countryRequired({ formatMessage }),
    maxPacDiscount: percentage({
      formatMessage,
    }),
    offerLink: offerLink({ formatMessage }),
  })

  useEffect(() => {
    if (id) {
      getOfferDetail({ variables: { id } })
    }
    setMenuConfig({ ...menuConfig, hideOnDashboard: true, forceOverlay: true })
  }, [])

  useEffect(() => {
    if (offer?.id) {
      const hasDescription =
        !!offer?.descriptionEn ||
        !!offer?.descriptionIt ||
        !!offer?.descriptionEs

      setActiveLangs({
        english: !!offer?.descriptionEn,
        italian: !!offer?.descriptionIt,
        spanish: !!offer?.descriptionEs,
      })

      if (!hasDescription) {
        setActiveLangs({
          english: "en" === locale,
          italian: "it" === locale,
          spanish: "es" === locale,
        })
      }
    }

    setSelectedDate([offer?.startDate ?? new Date(), offer?.endDate])
    if (offer) {
      setIsVoucher(offer?.isVoucher)
    }
  }, [offer])

  const OfferNameDescriptionText = (
    <StyledDescriptionText>
      <FormattedMessage
        id="dashboard::productForm::productNameDesc"
        defaultMessage="What is the title of the offer or offer?"
      />
    </StyledDescriptionText>
  )

  const OfferShortDescDescriprionText = (
    <StyledDescriptionText>
      <FormattedMessage
        id="dashboard::productForm::shortDescText"
        defaultMessage="Provide a short description that best describes your offer to your audience."
      />
    </StyledDescriptionText>
  )

  const OfferLongDescription = (
    <StyledFormSectionTitle>
      <FormattedMessage
        id="dashboard::productForm::longDesc"
        defaultMessage="Long Description"
      />
    </StyledFormSectionTitle>
  )

  const OfferLongDescriptionTextData = formatMessage({
    id: "dashboard::productForm::longDescriptionDesc",
    defaultMessage:
      "Tell potential supporters more about your campaign. Provide details that will motivate people to contribute. A good pitch is compelling, informative, and easy to digest.",
  })

  const OfferLongDescriptionText = (
    <StyledFormSectionHelpText>
      {OfferLongDescriptionTextData}
    </StyledFormSectionHelpText>
  )

  const handleLeaveModalClose = () => {
    setLeaveModalOpen(false)
  }

  const OfferName = formatMessage({
    id: "dashboard::offerForm::offerName",
    defaultMessage: "Offer Name",
  })
  const OfferShortDescription = formatMessage({
    id: "dashboard::productForm::shortDesc",
    defaultMessage: "Short Description",
  })
  const ProjectDescription = formatMessage({
    id: "dashboard::campaignForm::descFieldTitle",
    defaultMessage: "Project Description",
  })

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true)

    let newValues = {
      name: values?.name || "",
      slugBody: values?.slugBody || "",
      descriptionEn: values?.descriptionTabEn || "",
      descriptionIt: values?.descriptionTabIt || "",
      descriptionEs: values?.descriptionTabEs || "",
      website: values?.website || "",
      image: values?.image || "",
      isUnlisted: values?.isUnlisted || false,
      isAvailable: values?.isUnlisted || false,
      offerLink: values?.offerLink || "",
      maxPacDiscount: values?.maxPacDiscount || null,
      categories: values?.categories?.map((item) => item?.value) || [],
      availableCountries: values?.availableCountries || [],
      availableCities: values?.availableCities || [],
      isWorldwide: values?.isWorldwide,
      externalId: values?.externalId || "",
      startDate: values?.startDate ? new Date(values?.startDate) : null,
      endDate: values?.endDate ? new Date(values?.endDate) : null,
      isReservationRequired: values?.isVoucher
        ? values?.isReservationRequired ?? false
        : false,
      isVoucher: values?.isVoucher ?? false,
      visibilityStatus: values?.isUnlisted
        ? VISIBILITY_STATUS?.LISTED
        : VISIBILITY_STATUS?.ARCHIVED,
    }
    const operation =
      offer?.id && fullValidate
        ? "offerSubmitForReview"
        : offer?.id
        ? "updateOffer"
        : "createOffer"
    const mutation = eval(operation)
    const variables = offer?.id
      ? {
          input: {
            ...newValues,
            id: offer?.id,
          },
        }
      : {
          input: {
            ...newValues,
          },
        }

    const productMutationSuccessMessage = fullValidate
      ? formatMessage({
          id: "dashboard::campaignForm::updateMessage",
          defaultMessage: "Your updates are submitted for review!",
        })
      : offer?.id
      ? offeressage({
          id: "dashboard::productForm::updateMessage",
          defaultMessage: "Your offer is updated!",
        })
      : formatMessage({
          id: "dashboard::productForm::createMessage",
          defaultMessage: "Your offer is created!",
        })

    mutation({
      variables,
    })
      .then(({ data }) => {
        const result = data?.[operation]
        if (result?.offer) {
          setTimeout(() => {
            partialUpdateOffer({
              variables: {
                input: {
                  id: result?.offer?.id,
                  reviewStatus: "READY",
                },
              },
            })
            success(productMutationSuccessMessage)
            navigate("/dashboard/merchants/stores")
          }, 2000)
        }
        if (result?.errors?.length)
          error(result?.errors[0]?.field + " " + result?.errors[0]?.messages[0])
      })
      .catch((err) => {
        if (err?.errors?.length > 0) {
          err.errors.map((e) => error(e?.message))
        } else
          error(
            formatMessage({
              id: "dashboard::productForm::errorMessage",
              defaultMessage: "An error occurred",
            })
          )
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  useEffect(() => {
    const result = Object.keys(activeLangs)?.some(
      (item, index) => tabIndex === index && !activeLangs[item]
    )
    if (result) {
      Object.keys(activeLangs)?.forEach((item, index) => {
        if (activeLangs[item]) {
          setTabIndex(index)
        }
      })
    }
  }, [activeLangs])

  const items = [
    {
      id: "public",
      label: formatMessage({
        id: "dashboard::campaignForm::publicText",
        defaultMessage: "I want my page to be displayed on the site",
      }),
    },
    {
      id: "private",
      label: formatMessage({
        id: "dashboard::campaignForm::privateText",

        defaultMessage:
          "I want to hide my page and make it visible only through a direct link or share and not be displayed on the site. (use this option if page is not ready and you are going to make changes before uploading it)",
      }),
    },
  ]

  const offerDescriptionLabelText = formatMessage({
    id: "dashboard::offerForm::offerLabelText",
    defaultMessage: "Offer Description",
  })

  const getDescriptionByLang = (
    values,
    setFieldValue,
    handleBlur,
    lang,
    errors
  ) => {
    const localeSuffix = capitalize(lang)
    const field = `descriptionTab${localeSuffix}`
    const langLowerCase = lang?.toLowerCase()

    const placeholder =
      langLowerCase === locale ? OfferLongDescriptionTextData : ""

    let labelText = offerDescriptionLabelText

    if (locale !== langLowerCase) {
      labelText =
        langLowerCase === "it"
          ? "Descrizione dell'offerta"
          : langLowerCase === "es"
          ? "Descripción de la oferta"
          : "Offer Description"
    }
    return (
      <>
        <StyledLabel>
          {labelText}
          {isTablet && locale === langLowerCase && (
            <p className="info">
              <FormattedMessage
                id="dashboard::offerForm::offerDescriptioinText"
                defaultMessage="Customers will see this description on your offer card"
              />
            </p>
          )}
        </StyledLabel>
        <div>
          <TextInput
            data-testid={`offer-input-${field}`}
            newDesign
            id={field}
            name={field}
            type="textarea"
            maxLength={ENTRY_DONATION_MAX_TEXT_LENGTH}
            value={values[field]}
            placeholder={placeholder}
            className="border"
            onBlur={handleBlur}
            onChange={(evt) => setFieldValue(field, evt.target.value, true)}
          />
          <div data-testid={`error-${field}`}>
            <FieldError
              name={field}
              scrollOpts={{
                block: "center",
                behavior: "smooth",
              }}
            />
          </div>
        </div>
      </>
    )
  }

  const getDescriptionToggleButton = (lang) => {
    let activeText = "Add the English version"
    let passiveText = "Delete the English version"
    let currentStatus = languageDescriptionStatus.english
    let statusData = { english: !currentStatus }

    if (lang?.toString()?.toLowerCase() === "es") {
      activeText = "Borrar opción de ticket"
      passiveText = "Añadi̇r la versi̇ón española"
      currentStatus = languageDescriptionStatus.spanish
      statusData = { spanish: !currentStatus }
    } else if (lang?.toString()?.toLowerCase() === "it") {
      activeText = "Elimina la versione italiana"
      passiveText = "Aggiungere la versione italiana"
      currentStatus = languageDescriptionStatus.italian
      statusData = { italian: !currentStatus }
    }
    return (
      <Button
        className={`${!isTablet && "mobile-text"} second-grid`}
        data-testid="btn-add-spanish"
        color="transparent"
        onClick={() => {
          setLanguageDescriptionStatus({
            ...languageDescriptionStatus,
            ...statusData,
          })
        }}
        type="button">
        <FontAwesomeIcon icon={currentStatus ? faMinus : faPlus} />
        {currentStatus ? passiveText : activeText}
      </Button>
    )
  }

  const nameYourOfferText = formatMessage({
    id: "dashboard::offerForm::nameYourOffer",
    defaultMessage: "Name Your Offer",
  })

  const newOfferText = formatMessage({
    id: "dashboard::offerForm::pageTitle",
    defaultMessage: "New offer",
  })

  const newVoucherText = formatMessage({
    id: "dashboard::offerForm::pageTitleVoucher",
    defaultMessage: "New voucher",
  })

  const confirmationCodeOptionContent = (
    <StyledConfirmationOptionsContainer className={!isVoucher ? "passive" : ""}>
      <StyledConfirmationOptionsTitle>
        <FormattedMessage
          id="dashboard::offerForm::confirmationCodeVoucher"
          defaultMessage="Confirmation Code / Voucher"
        />
      </StyledConfirmationOptionsTitle>
      <Spacer top={1} />
      <StyledConfirmationOptionsDescription>
        <FormattedMessage
          id="dashboard::offerForm::confirmationCodeVoucherDescription"
          defaultMessage="Choose voucher, so your customers will receive your service/product in person after your approval of the confirmation code"
        />
      </StyledConfirmationOptionsDescription>
    </StyledConfirmationOptionsContainer>
  )

  const noConfirmationCodeOptionContent = (
    <StyledConfirmationOptionsContainer className={isVoucher ? "passive" : ""}>
      <StyledConfirmationOptionsTitle>
        <FormattedMessage
          id="dashboard::offerForm::noConfirmationCode"
          defaultMessage="No confirmation code"
        />
      </StyledConfirmationOptionsTitle>
      <Spacer top={1} />
      <StyledConfirmationOptionsDescription>
        <FormattedMessage
          id="dashboard::offerForm::noConfirmationCodeDescription"
          defaultMessage="Suitable for services, consultancy, gym or other subscription, medical care services"
        />
      </StyledConfirmationOptionsDescription>
    </StyledConfirmationOptionsContainer>
  )

  const radioOptions = [
    {
      id: 1,
      rawHtml: confirmationCodeOptionContent,
      isPassive: false,
    },
    {
      id: 0,
      rawHtml: noConfirmationCodeOptionContent,
      isPassive: false,
    },
  ]

  const handleCountrySelect = (countries, cities, setFieldValue) => {
    if (countries && Array.isArray(countries)) {
      const tempCountries = countries
        ?.map((item) => item?.value)
        ?.filter((item) => item !== null)
      setFieldValue("availableCountries", tempCountries, true)
    }
    if (cities && Array.isArray(cities)) {
      const tempCities = cities
        ?.map((item) => item?.value)
        ?.filter((item) => item !== null)
      setFieldValue("availableCities", tempCities, true)
    }
  }

  return (
    <StyledCampaignCreationWrapper>
      <StyledOfferPage data-testid="StyledOfferPage">
        {loading ? (
          <Spinner />
        ) : (
          <>
            <SEO
              lang={locale}
              title={isVoucher ? newVoucherText : newOfferText}
            />
            {id ? (
              <StyledTitleContainer>
                <div className="back">
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    onClick={() => setLeaveModalOpen(true)}
                  />
                </div>
                <StyledTitle className="title">
                  <FormattedMessage
                    id="dashboard::offerForm::editOffer"
                    defaultMessage="Edit Offer"
                  />
                </StyledTitle>
                <div className="status">
                  <InfoBox status={offer?.status} />
                </div>
              </StyledTitleContainer>
            ) : (
              <StyledTitleContainer>
                <div className="back">
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    onClick={() => setLeaveModalOpen(true)}
                  />
                </div>
                <StyledTitle className="title">
                  {isVoucher ? newVoucherText : newOfferText}
                </StyledTitle>
              </StyledTitleContainer>
            )}
            <Spacer top={2} />
            <StyledPageContent>
              <Formik
                enableReinitialize={true}
                validationSchema={
                  fullValidate ? validationSchema : validationSchemaBasic
                }
                initialValues={initialValues}
                onSubmit={handleSubmit}>
                {({
                  values,
                  touched,
                  errors,
                  handleBlur,
                  handleSubmit,
                  setTouched,
                  setFieldValue,
                  setValues,
                  setFieldTouched,
                  isSubmitting,
                }) => {
                  return (
                    <>
                      <OnSubmitValidationError />
                      <StyledForm
                        onSubmit={handleSubmit}
                        className="campaign-form">
                        <fieldset>
                          <StyledLabel>{nameYourOfferText}</StyledLabel>
                          <div>
                            <FastField
                              data-testid="offer-input-name"
                              id="name"
                              name="name"
                              value={values.name}
                              component={TextInput}
                              className="float-container offer-name form-input border"
                              placeholder={nameYourOfferText}
                              onChange={(evt) =>
                                setFieldValue("name", evt.target.value, true)
                              }
                              onBlur={handleBlur}
                            />
                            <div data-testid="error-name">
                              <FieldError name="name" />
                            </div>
                          </div>
                          <StyledLabel>
                            <FormattedMessage
                              id="dashboard::service::chooseCategory"
                              defaultMessage="Choose Category"
                            />
                          </StyledLabel>
                          <div
                            data-testid="offer-input-categories"
                            className="float-container border">
                            <Dropdown
                              isMulti={true}
                              placeholder={formatMessage({
                                id: "dashboard::productForm::category",
                                defaultMessage: "Category",
                              })}
                              name="categories"
                              id="categories"
                              options={allOfferCategories.map(
                                ({ id, name }) => ({
                                  value: id,
                                  label: name,
                                })
                              )}
                              value={values?.categories}
                              onChange={(selectedCategories) => {
                                setFieldTouched("categories")
                                setFieldValue(
                                  "categories",
                                  selectedCategories,
                                  true
                                )
                              }}
                              onBlur={(e) => {
                                setFieldTouched("categories")
                                handleBlur(e)
                              }}
                            />
                            <div data-testid="error-category">
                              <FieldError name="categories" />
                            </div>
                          </div>
                          <StyledLabel>
                            <FormattedMessage
                              id="dashboard::service::chooseRegion"
                              defaultMessage="Choose Region"
                            />
                            {isTablet && (
                              <p className="info">
                                <FormattedMessage
                                  id="dashboard::offerForm::countryDescription"
                                  defaultMessage="In what countries you are ready to provide your offer"
                                />
                              </p>
                            )}
                          </StyledLabel>
                          <div data-testid="offer-available-countries">
                            <CountrySelectorFilter
                              isOfferList={true}
                              handleSelect={(
                                selectedCountries,
                                selectedCities
                              ) =>
                                handleCountrySelect(
                                  selectedCountries,
                                  selectedCities,
                                  setFieldValue
                                )
                              }
                              isDropdown={false}
                              searchable={true}
                              inlineSearchableComponent={true}
                              activeCity={null}
                              activeCountry={null}
                              dontShowSelected={true}
                              withTagsBelow={true}
                              cities={values?.availableCities}
                              countries={values?.availableCountries}
                              disabled={values.isWorldwide}
                            />
                            <div style={{ marginTop: "0.5rem" }}>
                              <Checkbox
                                label={formatMessage({
                                  id: "dashboard::service::isWorldwide",
                                  defaultMessage: "Worldwide",
                                })}
                                id="isWorldwide"
                                isChecked={values.isWorldwide}
                                onChange={() => {
                                  const temp = values.isWorldwide
                                  setValues({
                                    ...values,
                                    availableCountries: [],
                                    availableCities: [],
                                    isWorldwide: !temp,
                                  })
                                }}
                              />
                            </div>
                            <div data-testid="error-countries">
                              <FieldError name="country" />
                            </div>
                          </div>
                          <StyledLabel>
                            <FormattedMessage
                              id="dashboard::offerForm::offerDates"
                              defaultMessage="Offer Dates"
                            />
                          </StyledLabel>
                          <StyledDatePickerWrapper>
                            <DatePicker
                              showRange
                              minDate={new Date("2020-01-01")}
                              dataTestId="order-date-picker"
                              startDate={selectedDate?.[0] || values?.startDate}
                              endDate={selectedDate?.[1] || values?.endDate}
                              onDateChange={(date) => {
                                setSelectedDate(date)
                                setFieldValue(
                                  "startDate",
                                  formatDate(date[0], "yyyy-MM-dd"),
                                  true
                                )
                                setFieldValue(
                                  "endDate",
                                  formatDate(date[1], "yyyy-MM-dd"),
                                  true
                                )
                              }}
                              placeholderText={formatMessage({
                                id: "dashboard::campaignForm::startEndDate",
                                defaultMessage: "Start - End Date",
                              })}
                            />
                          </StyledDatePickerWrapper>
                          <StyledLabel className={!isTablet && "bold-title"}>
                            <FormattedMessage
                              id="dashboard::offerForm::uploadPhoto"
                              defaultMessage="Upload Photo"
                            />
                            {isTablet && (
                              <p className="info">
                                <FormattedMessage
                                  id="dashboard::offerForm::uploadPhotoDescription"
                                  defaultMessage="Upload image that represent your offer. Recommended resolution, or other which has an aspect ratio of 16/9."
                                />
                              </p>
                            )}
                          </StyledLabel>
                          <div>
                            <div className="image-upload">
                              <ImageSelector
                                newDesign={true}
                                customIcon={faPlus}
                                showUploadLabel={false}
                                defaultValue={values?.image}
                                onChange={(image) =>
                                  setFieldValue("image", image)
                                }
                                onCropChange={(croppedImage) =>
                                  setFieldValue("image", croppedImage)
                                }
                                onReset={() =>
                                  setFieldValue("image", initialValues.image)
                                }
                              />
                            </div>
                            <div data-testid="error-image">
                              <FieldError name="image" />
                            </div>
                          </div>
                          {getDescriptionByLang(
                            values,
                            setFieldValue,
                            handleBlur,
                            locale,
                            errors
                          )}
                          <StyledLabel>
                            <FormattedMessage
                              id="dashboard::offerForm::offerPacDiscount"
                              defaultMessage="Offer PAC Discount"
                            />
                          </StyledLabel>
                          <div className="input-container">
                            <FastField
                              id="maxPacDiscount"
                              name="maxPacDiscount"
                              type="number"
                              currency="%"
                              component={TextInput}
                              className="float-container form-input pac-discount"
                              maxLength={5}
                              value={
                                isNaN(values?.maxPacDiscount)
                                  ? null
                                  : values?.maxPacDiscount
                              }
                              onChange={(evt) => {
                                setFieldValue(
                                  "maxPacDiscount",
                                  evt.target.value,
                                  true
                                )
                              }}
                              onFocus={() =>
                                setFieldTouched("maxPacDiscount", true)
                              }
                              onBlur={handleBlur}
                            />
                            <div className="input-percent">%</div>
                            <div data-testid="error-maxPacDiscount">
                              <FieldError name="maxPacDiscount" />
                            </div>
                          </div>
                          <StyledLabel>
                            <FormattedMessage
                              id="dashboard::productForm::productOfferLink"
                              defaultMessage="Offer Link"
                            />
                            {isTablet && (
                              <p className="info">
                                <FormattedMessage
                                  id="dashboard::offerForm::offerLinkText"
                                  defaultMessage="Link to the product page you are creating offer for"
                                />
                              </p>
                            )}
                          </StyledLabel>
                          <div>
                            <FastField
                              data-testid="offer-offer-link"
                              id="offerLink"
                              name="offerLink"
                              value={values.offerLink}
                              component={TextInput}
                              className="float-container form-input"
                              placeholder=""
                              onChange={(evt) =>
                                setFieldValue(
                                  "offerLink",
                                  evt.target.value,
                                  true
                                )
                              }
                              onBlur={handleBlur}
                            />
                            <div data-testid="error-offerLink">
                              <FieldError name="offerLink" />
                            </div>
                          </div>
                          {languageDescriptionStatus.english &&
                            getDescriptionByLang(
                              values,
                              setFieldValue,
                              handleBlur,
                              "EN"
                            )}
                          {locale !== "en" && getDescriptionToggleButton("EN")}
                          {languageDescriptionStatus.italian &&
                            getDescriptionByLang(
                              values,
                              setFieldValue,
                              handleBlur,
                              "IT"
                            )}
                          {locale !== "it" && getDescriptionToggleButton("IT")}
                          {languageDescriptionStatus.spanish &&
                            getDescriptionByLang(
                              values,
                              setFieldValue,
                              handleBlur,
                              "ES"
                            )}
                          {locale !== "es" && getDescriptionToggleButton("ES")}

                          <StyledLabel className={!isTablet && "bold-title"}>
                            <FormattedMessage
                              id="dashboard::service::conditions"
                              defaultMessage="Conditions"
                            />
                          </StyledLabel>
                          <TermsAndConditions
                            labelClassName="terms-label"
                            name="isTermsAndPolicyAccepted"
                            errors={errors}
                            touched={touched}
                            onChange={(evt) => {
                              setFullValidate(evt?.target?.checked)
                            }}
                            onClick={() => {
                              setTouched({
                                ...touched,
                                isTermsAndPolicyAccepted: true,
                              })
                            }}
                          />
                          <StyledLabel className={!isTablet && "bold-title"}>
                            <FormattedMessage
                              id="dashboard::service::privacy"
                              defaultMessage="Privacy"
                            />
                          </StyledLabel>
                          <RadioSelect
                            className={!isTablet && "mobile-text"}
                            items={items}
                            defaultValue={
                              values.isUnlisted ? "public" : "private"
                            }
                            onChange={(e) => {
                              setFieldValue(
                                "isUnlisted",
                                e.target.value === "public" ? true : false
                              )
                            }}
                          />
                          {!isTablet && <Spacer top={3} />}
                        </fieldset>

                        {((!offer?.id &&
                          apiPermissions?.includes(
                            API_PERMISSIONS.MERCHANT_CREATE_PRODUCT
                          )) ||
                          (offer?.status === REVIEW_STATUS.IN_PROGRESS &&
                            apiPermissions?.includes(
                              API_PERMISSIONS.MERCHANT_UPDATE_PRODUCT
                            ))) && (
                          <div>
                            <Spacer top={2.25} />
                            <StyledPageActions
                              className={`save-container ${
                                !isTablet && "sticky-bottom-bar"
                              }`}>
                              <Button
                                type="submit"
                                label="Save"
                                data-testid="btn-save-merchant-offer"
                                className="btn-save-merchant-offer save-button"
                                color="red"
                                disabled={isSubmitting || !fullValidate}>
                                <FormattedMessage
                                  id="dashboard::offerForm::createOffer"
                                  defaultMessage="Create Offer"
                                />
                              </Button>
                            </StyledPageActions>
                          </div>
                        )}
                        {offer?.id &&
                          offer?.status !== REVIEW_STATUS.REJECTED &&
                          apiPermissions?.includes(
                            API_PERMISSIONS.MERCHANT_UPDATE_PRODUCT
                          ) &&
                          apiPermissions?.includes(
                            API_PERMISSIONS.MERCHANT_PUBLISH_PRODUCT
                          ) && (
                            <>
                              <Spacer top={2} />
                              <StyledPageActions
                                className={`update-buttons ${
                                  !isTablet && "sticky-bottom-bar"
                                }`}>
                                <Button
                                  type="button"
                                  label="Leave without action"
                                  color="transparent"
                                  data-testid="btn-leave"
                                  className="leave-button"
                                  onClick={() => setLeaveModalOpen(true)}>
                                  <FormattedMessage
                                    id="dashboard::campaign::leave"
                                    defaultMessage="Leave without saving"
                                  />
                                </Button>
                                <Button
                                  type="submit"
                                  label="Submit For Review"
                                  color={theme.colors.carrot}
                                  data-testid="btn-submit"
                                  className="submit-for-review-button"
                                  disabled={
                                    isSubmitting ||
                                    !fullValidate ||
                                    isDefaultOffer
                                  }>
                                  <FormattedMessage
                                    id="dashboard::campaignForm::submitForReview"
                                    defaultMessage="Submit For Review"
                                  />
                                </Button>
                              </StyledPageActions>
                            </>
                          )}
                      </StyledForm>
                    </>
                  )
                }}
              </Formik>
            </StyledPageContent>
          </>
        )}
      </StyledOfferPage>
      {isLeaveModalOpen && (
        <LeaveModal
          {...{
            isLeaveModalOpen,
            handleLeaveModalClose,
          }}
        />
      )}
    </StyledCampaignCreationWrapper>
  )
}

export default OfferForm
