import styled from "@emotion/styled"
import { css } from "@emotion/react"
//import { Link } from "gatsby-plugin-intl"

export const StyledOfferHeader = styled.div`
  z-index: -1;
  height: 8rem;
  width: 100%;
  margin: 5rem 0 2rem;
  overflow: hidden;
  background-color: ${(p) => p.theme.colors.primarySubmenuBackgroundColor};
  color: ${(p) => p.theme.colors.primaryBrandTextColor};
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.bold};

  h1 {
    padding: 0.625rem 0.625rem;
    font-size: 2.5rem;
    max-width: ${(p) => p.theme.breakpoints.wide};
    margin: 3.75rem auto 0 auto;
    svg {
      margin-right: 1rem;
    }
  }

  @media only screen and (max-width: 62.5rem) {
    h1 {
      font-size: 1.8rem;
      padding-left: 1.875rem;
    }
  }
`

export const StyledPurchaseDetails = styled.div`
  display: grid;
  grid-gap: 1.25rem;
  max-width: ${(p) => p.theme.breakpoints.wide};
  margin: auto;

  ${(p) =>
    p.hideCard
      ? css`
          grid-template-columns: 1fr 0 0;
          grid-template-areas: "spinner . .";
        `
      : css`
          grid-template-columns: minmax(0, 6fr) minmax(0, 4fr);
          grid-template-areas: "items details" "form details";
        `}

  @media only screen and (max-width: 62.5rem) {
    display: grid;
    grid-gap: 0;
    & > div {
      padding: 0 1rem;
    }

    ${(p) =>
      p.hideCard
        ? css`
            grid-template-columns: 100%;
            grid-template-areas: "spinner";
          `
        : css`
            grid-template-columns: minmax(0, 1fr);
            grid-template-areas: "items" "details" "form";
          `}
  }
`

export const StyleOfferDetails = styled.div`
  grid-area: details;
  position: relative;
  top: -6rem;
  background-color: ${(p) => p.theme.colors.white};
  padding: 1rem;
  @media only screen and (max-width: 62.5rem) {
    position: static;
  }
`
export const StyleOfferDetailsTitle = styled.p`
  color: ${(p) => p.theme.colors.gondola};
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-size: 1.5rem;
  line-height: 1.5rem;
  margin-top: 3rem;
`

export const StyledPurchaseFormFaq = styled.ul`
  color: ${(p) => p.theme.colors.disabled};
  padding: 1.25rem 0 1.25rem 0;
  list-style-type: disc;
  list-style-position: inside;
  li {
    margin-bottom: 0.625rem;
  }
`

export const StyledCartSummaryRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${(p) =>
    p.highlighted ? p.theme.colors.blue : p.theme.colors.gondola};
  border-bottom: 0.0625rem solid ${(p) => p.theme.colors.formFieldsBorderColor};
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;

  > p:first-of-type {
    opacity: 0.5;
  }
`

export const StyledCartTotalRow = styled(StyledCartSummaryRow)`
  grid-area: total;
  border-top: 0.0625rem solid ${(p) => p.theme.colors.formFieldsBorderColor};
  align-self: end;

  @media only screen and (max-width: 62.5rem) {
    border-top: none;
    align-self: center;
  }
`

export const StyledCartSummary = styled.div`
  grid-area: checkout;
  position: relative;
  background-color: ${(p) => p.theme.colors.white};
  padding: 0.875rem 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
`

export const StyledAmount = styled.p`
  color: inherit;
  font-size: ${(p) => (p.strong ? "1.4rem" : "1.2rem")};
  font-weight: ${(p) => p.strong && "bold"};
  line-height: 1.75rem;
`
/*
export const StyledLink = styled(Link)`
  &,
  &:hover,
  &:visited,
  &.active {
    color: ${(p) => p.theme.colors.blue} !important;
    font-weight: ${(p) => p.theme.fontWeights.bold};
  }
`
*/
