import styled from "@emotion/styled"

export const StyledSignInWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 1rem;
  min-height: 80vh;
  padding: 1rem;

  & > div {
    max-width: 27rem;
    width: 100%;
    .float-container label {
      background-color: transparent;
    }
  }

  a {
    cursor: pointer;
    text-decoration: none;
    color: ${(p) => p.theme.colors.blue};

    &,
    &:visited,
    &:hover,
    &:active,
    &.active {
      color: ${(p) => p.theme.colors.blue};
    }
  }

  .forgot-password {
    margin-top: 2rem;

    @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      margin-top: 2.5rem;
    }
  }

  p:first-of-type {
    text-align: center;
    margin-bottom: 1.5rem;
    @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      margin-bottom: 4rem;
      font-size: 1.5rem;
    }
  }

  .user-form {
    > div {
      margin-bottom: 2rem;
    }
  }

  hr {
    @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      margin: 2.5rem 0;
    }

    @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      margin: 3.375rem 0;
    }
  }
`
export const StyledSignInButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  > button {
    width: auto;
    height: auto;
    border-radius: 0.25rem;
    white-space: pre-line;

    @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      min-width: 12rem;
    }

    @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      width: 100%;
      height: 3.375rem;
      font-size: 1rem;
    }
  }
`

export const SignInTitle = styled.h1`
  color: ${(p) => p.theme.colors.primaryTextColor};
  font-size: 1.5rem;
  line-height: 3.625rem;
  text-align: center;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
  font-weight: ${(p) => p.theme.fontWeights.medium};

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 2rem;
    margin-top: 2rem;
    margin-bottom: 1.5rem;
  }

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 3rem;
    margin-top: 4rem;
  }
`

export const StyledLinkSection = styled.div`
  p {
    line-height: 2rem;
    margin: 0;
    font-size: 1rem;
    font-weight: ${(p) => p.theme.fontWeights.regular};
  }
`
