import styled from "@emotion/styled"

export const StyledTimerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 0;
  svg {
    display: none;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding-bottom: 0.625rem;
    padding-top: 0.625rem;
  }
`

export const StyledRenderTimeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: ${(p) => p.theme.colors.black};

  .time {
    font-weight: ${(p) => p.theme.fontWeights.bold};
  }

  .dimension {
    padding-left: 0.3125rem;
  }
`
