import React from "react"
import { FormattedMessage, navigate } from "gatsby-plugin-intl"
import { LazyImage, Money, TruncatedText } from "@tmu/components/common"
import { removeAllHTMLTags } from "@tmu/utils/string"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import {
  StyledCardTitle,
  StyledStoreCard,
  StyledCardInfo,
  StyledStoreLogo,
  StyledCardDescription,
  StyledCardDiscount,
  StyledStoreDiscountBanner,
  StyledOverlayWithBadge,
  StyledPartnerLogo,
  StyledPartnerTitleText,
  StyledOfferStore,
} from "./index.styles"

const { CLOUDFLARE_IMAGE_URL } = process.env

const StoreCard = ({
  id,
  title,
  description,
  maxPacDiscount,
  size,
  cardLink,
  storeLogo,
  imageSrc,
  isComingSoon,
  bigTablet,
  ...rest
}) => {
  const { isTablet, isDesktop, isWide } = getAllScreenTypes()

  const discountBanner = (
    <StyledCardDiscount size={size}>
      <img
        className="pacs-pacs-image"
        src={CLOUDFLARE_IMAGE_URL + "/static/assets/images/pac.svg"}
        alt={`pacs`}
        width={28}
        height={23}
      />
      <span>
        <FormattedMessage
          id="storeCard::discount::upTo"
          defaultMessage="Up to"
        />{" "}
        <Money style="percent" value={maxPacDiscount} currency={false} />
      </span>
    </StyledCardDiscount>
  )

  const comingSoonBadge = {
    width: isWide ? 197 : isDesktop ? 120 : isTablet ? 72 : 64,
    height: isWide ? 192 : isDesktop ? 114 : isTablet ? 69 : 61,
    src: CLOUDFLARE_IMAGE_URL + "/static/assets/images/svg/comingsoon.svg",
  }

  return (
    <StyledStoreCard
      bigTablet={bigTablet}
      isComingSoon={isComingSoon}
      size={size}
      data-testid={`storeCard${id}`}
      {...rest}
      onClick={() => navigate(cardLink)}>
      {isComingSoon ? (
        <StyledOverlayWithBadge bigTablet={bigTablet}>
          <img alt={`coming-soon`} {...comingSoonBadge} />
        </StyledOverlayWithBadge>
      ) : null}
      <StyledStoreLogo size={size} bigTablet={bigTablet}>
        <LazyImage
          src={storeLogo}
          width={isWide ? 300 : isDesktop ? 180 : isTablet ? 160 : 90}
          height={"auto"}
          maxWidth={isWide ? 300 : isDesktop ? 180 : isTablet ? 160 : 90}
          altName={`card-image`}
          fit="cover"
        />
      </StyledStoreLogo>
      {!isTablet || (isTablet && size !== "tile") ? (
        <StyledCardInfo isComingSoon={isComingSoon} store size={size}>
          <div>
            <StyledCardTitle store size={size}>
              <TruncatedText numLines={2}>{title}</TruncatedText>
            </StyledCardTitle>
            <StyledCardDescription>
              <TruncatedText numLines={3}>
                {removeAllHTMLTags(description)}
              </TruncatedText>
            </StyledCardDescription>
          </div>
          {Number(maxPacDiscount) ? discountBanner : null}
        </StyledCardInfo>
      ) : (
        <div>
          {!isComingSoon && Number(maxPacDiscount) ? (
            <StyledStoreDiscountBanner size={size} bigTablet={bigTablet}>
              {discountBanner}
            </StyledStoreDiscountBanner>
          ) : null}
          <StyledOfferStore
            isComingSoon={isComingSoon}
            size={size}
            bigTablet={bigTablet}>
            <StyledPartnerLogo
              data-testid="campaign-detail__foundation-logo"
              src={storeLogo}
            />
            <StyledPartnerTitleText>
              <TruncatedText numLines={2}>{title}</TruncatedText>
            </StyledPartnerTitleText>
          </StyledOfferStore>
        </div>
      )}
    </StyledStoreCard>
  )
}

export default StoreCard
