import React, { useCallback, useEffect, useState, useRef } from "react"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { useStaticQuery, graphql } from "gatsby"
import {
  StyledPage,
  StyledPageContent,
  StyledPageTitle,
} from "@tmu/global/page-addons/dashboard.styles"
import {
  StyledCategoryHr,
  StyledCategoryTitle,
  StyledCategoryWrapper,
  StyledCheckbox,
  StyledRowTitleExplanation,
  StyledTitle,
  StyledFavButtons,
  StyledCampaignName,
  StyledMenuItem,
  StyledMenuContainer,
  StyledSelectedFavoriteCharityMobile,
  StyledFavoriteCharityContainerMobile,
  StyledSmallSectionTitleMobile,
} from "./index.styles"
import {
  StyledPageGrid,
  StyledRowContent,
  StyledRowTitle,
  StyledSection,
  StyledSectionTitle,
  StyledCampaignImage,
  StyledEditLink,
} from "../Profile/index.styles"
import { Spacer } from "@tmu/global/page-addons/detail-page.styles"
import {
  Button,
  Checkbox,
  CustomModal,
  Dropdown,
  LanguageBar,
  LazyImage,
} from "@tmu/components/common"
import { StyledCategoryContainer } from "@tmu/components/common/CategorySelect/index.styles"
import CategoryList from "@tmu/components/common/ListingFilters/CategoryList"
import {
  useAllCampaignCategories,
  useAllOfferCategories,
  useAuth,
  useUserPreferences,
} from "@tmu/hooks"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { FavoriteNGOSection } from "../../dashboardCommon"
import { useApolloApiClients } from "@tmu/apollo/client"
import { useMutation } from "@apollo/client"
import { UPDATE_ME_MUTATION } from "@tmu/apollo/storefront/mutations/user"
import { getValueForLocale } from "@tmu/utils/string"
import { useToast } from "@tmu/hooks"

const Settings = () => {
  const { locale, formatMessage, defaultLocale } = useIntl()
  const [selectedLanguage, setSelectedLanguage] = useState(locale)
  const [subscription, setSubscription] = useState(false)
  const { allCampaignCategories, loading: allCategoriesLoading } =
    useAllCampaignCategories()
  const { mainCategories, loading: allOfferCategoriesLoading } =
    useAllOfferCategories()
  const [selectedCampaignCategories, setSelectedCampaignCategories] = useState(
    []
  )
  const { isTablet, isWide } = getAllScreenTypes()
  const { success, error } = useToast()
  const [menuModalStatus, setMenuModalStatus] = useState(false)
  const [modalContent, setModalContent] = useState()
  const [selectedOfferCategories, setSelectedOfferCategories] = useState([])
  const { savePreferences } = useUserPreferences()
  const [favoriteNGO, setFavoriteNGO] = useState()
  const [isPreferencesChanged, setPreferencesChanged] = useState(false)
  const [isFavSectionEditOpen, setFavSectionEditOpen] = useState(false)
  const [isPreferencesLoading, setPreferencesLoading] = useState(false)
  const { user, callUserProfile } = useAuth()
  const { storefrontClient } = useApolloApiClients()
  const favNgoRef = useRef()
  const [updateProfile] = useMutation(UPDATE_ME_MUTATION, {
    client: storefrontClient,
  })

  const {
    site: {
      siteMetadata: { supportedLanguages },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            supportedLanguages {
              id
              label
            }
          }
        }
      }
    `
  )

  useEffect(() => {
    const campaignPreferences =
      user?.userPreference?.campaignCategories?.edges?.map(
        (item) => item?.node?.id
      ) || []
    setSelectedCampaignCategories(campaignPreferences)

    const offerPreferences =
      user?.userPreference?.productCategories?.edges?.map(
        (item) => item?.node?.id
      ) || []
    setSelectedOfferCategories(offerPreferences)

    setSubscription(user?.isSubscribed || false)
  }, [user])

  const supportedLanguagesLocalization = supportedLanguages.map((key) => {
    return {
      ...key,
      value: key?.id,
      label: (
        <FormattedMessage
          id={`footer::languages::${key?.label.toLowerCase()}`}
          defaultMessage={key?.label}
        />
      ),
    }
  })

  const currentLanguage = useCallback(
    supportedLanguagesLocalization?.find(
      (item) => item?.id === selectedLanguage
    )
  )

  const selectCampaignCategory = (id, slug) => {
    if (selectedCampaignCategories?.indexOf(id) === -1) {
      if (selectedCampaignCategories?.length > 2) {
        return
      }
      setSelectedCampaignCategories([...selectedCampaignCategories, id])
    } else {
      const temp = selectedCampaignCategories?.filter((item) => item !== id)
      setSelectedCampaignCategories(temp)
    }
    setPreferencesChanged(true)
  }

  const selectOfferCategory = (id, slug) => {
    if (selectedOfferCategories?.indexOf(id) === -1) {
      if (selectedOfferCategories?.length > 2) {
        return
      }
      setSelectedOfferCategories([...selectedOfferCategories, id])
    } else {
      const temp = selectedOfferCategories?.filter((item) => item !== id)
      setSelectedOfferCategories(temp)
    }
    setPreferencesChanged(true)
  }

  useEffect(() => {
    if (isPreferencesChanged) handlePreferencesChange()
  }, [isPreferencesChanged])

  const sendMeSubscriptionText = formatMessage({
    id: "dashboard::settings::sendMeNewsText",
    defaultMessage: "Send me TrustMeUp news, announcements and offer updates",
  })

  const preferencesText = formatMessage({
    id: "dashboard::settings::preferences",
    defaultMessage: "Preferences",
  })

  const communicationLanguageText = formatMessage({
    id: "dashboard::forms::communicationLanguage",
    defaultMessage: "Communication Language",
  })

  const favoriteCharityTitle = formatMessage({
    id: "dashboard::settings::favouriteCharity",
    defaultMessage: "Favourite Charity",
  })

  const chhoseFavoriteCharityText = formatMessage({
    id: "dashboard::campaign::chooseFavourite",
    defaultMessage: "Choose favourite",
  })

  const choseFavoriteExplanationText = formatMessage({
    id: "dashboard::settings::favouriteCharityExplanation1",
    defaultMessage:
      "Make charity your favourite, we will remember your choice and it will be used for donations by shopping. You will be able to change it before the purchase",
  })

  const campaignCategoryContent = (
    <StyledCategoryContainer className="categories">
      <CategoryList
        categories={allCampaignCategories}
        selected={selectedCampaignCategories}
        onSelect={selectCampaignCategory}
        hideShowAll={true}
      />
    </StyledCategoryContainer>
  )

  const offerCategoryContent = (
    <StyledCategoryContainer className="offers">
      <CategoryList
        categories={mainCategories}
        selected={selectedOfferCategories}
        onSelect={selectOfferCategory}
        isOfferList={true}
        hideShowAll={true}
      />
    </StyledCategoryContainer>
  )

  const handleLanguageChange = (lang) => {
    updateProfile({
      variables: {
        input: {
          communicationLanguage: lang?.toUpperCase(),
        },
      },
    })
  }
  const handleSubscribeChange = () => {
    updateProfile({
      variables: {
        input: {
          isSubscribed: !subscription,
        },
      },
    })
    setSubscription(!subscription)
  }
  const handlePreferencesChange = (favoriteCharityId) => {
    setPreferencesLoading(true)
    savePreferences({
      variables: {
        input: {
          campaign:
            favoriteCharityId ||
            favoriteNGO ||
            user?.userPreference?.campaign?.id,
          campaignCategories: selectedCampaignCategories,
          productCategories: selectedOfferCategories,
        },
      },
    })
      .then((data) => {
        success(
          formatMessage({
            id: "dashboard::userForm::preferencesUpdated",
            defaultMessage: "Your preferences are updated",
          })
        )
      })
      .catch((err) => {
        error(
          formatMessage({
            id: "dashboard::userForm::errorMessage",
            defaultMessage: "An error occurred",
          })
        )
      })
      .finally(() => {
        setTimeout(() => {
          callUserProfile()
          setPreferencesLoading(false)
        }, 1000)
      })
  }

  const partnerLogo = getValueForLocale(
    user?.userPreference?.campaign?.partner,
    "logo",
    locale,
    defaultLocale
  )

  const closeModal = () => {
    setMenuModalStatus(false)
  }

  const categoryContent = (
    <>
      {allCampaignCategories?.length > 0 && (
        <>
          <StyledCategoryTitle>
            <FormattedMessage
              id="dashboard::settings::donateTitle"
              defaultMessage="Donate"
            />
          </StyledCategoryTitle>
          <Spacer bottom={1} />
          <StyledCategoryHr />
          <StyledSection>
            <StyledCategoryWrapper>
              {campaignCategoryContent}
            </StyledCategoryWrapper>
          </StyledSection>
          <Spacer bottom={2.5} />
        </>
      )}
      {mainCategories?.length > 0 && (
        <>
          <StyledCategoryTitle>
            <FormattedMessage
              id="dashboard::settings::shopTitle"
              defaultMessage="Shop"
            />
          </StyledCategoryTitle>
          <Spacer bottom={1} />
          <StyledCategoryHr />
          <StyledSection>
            <StyledCategoryWrapper>
              {offerCategoryContent}
            </StyledCategoryWrapper>
          </StyledSection>
        </>
      )}
    </>
  )

  const languageContent = (
    <div className="language">
      <LanguageBar height={16} isList={true} />
    </div>
  )

  const selectFavoriteCharityContent = (
    <StyledRowTitle>
      {favoriteCharityTitle}
      <Spacer bottom={0.5} />
      <StyledRowTitleExplanation>
        {choseFavoriteExplanationText}
      </StyledRowTitleExplanation>
      <Spacer bottom={1} />
      {!isFavSectionEditOpen && (
        <StyledEditLink
          onClick={() => {
            if (isTablet) {
              setFavSectionEditOpen(true)
            } else {
              showModal(favoriteNgoSelector, favoriteCharityTitle)
            }
          }}>
          {user?.userPreference?.campaign?.name ? (
            <FormattedMessage
              id="dashboard::campaign::pickAnother"
              defaultMessage="Pick another"
            />
          ) : (
            chhoseFavoriteCharityText
          )}
        </StyledEditLink>
      )}
    </StyledRowTitle>
  )

  const favoriteCharitySaveControls = (
    <StyledFavButtons>
      <Button
        type="button"
        label="Cancel"
        onClick={() => setFavSectionEditOpen(false)}
        color="transparent">
        <FormattedMessage
          id="dashboard::users::cancel"
          defaultMessage="Cancel"
        />
      </Button>
      <Button
        label="Save"
        color="blue"
        className="blue"
        onClick={() => handlePreferencesChange()}>
        <FormattedMessage
          id="dashboard::merchant::save"
          defaultMessage="Save"
        />
      </Button>
    </StyledFavButtons>
  )

  const favoriteNgoSelector = (
    <FavoriteNGOSection
      ref={favNgoRef}
      me={user}
      hideLabel={true}
      favoriteNGO={favoriteNGO}
      setFavoriteNGO={setFavoriteNGO}
      onClose={closeModal}
      onFavChange={(charityId, charity) => {
        if (!isTablet) {
          handlePreferencesChange(charityId)
        }
      }}
    />
  )

  const editFavoriteCharityContent = (
    <>
      <StyledSection>
        <StyledSectionTitle>
          <FormattedMessage
            id="dashboard::settings::charity"
            defaultMessage="Charity"
          />
        </StyledSectionTitle>
        <Spacer bottom={0.5} />
        {favoriteNgoSelector}
        <Spacer bottom={1} />
        {favoriteCharitySaveControls}
      </StyledSection>
    </>
  )

  const selectedFavoriteCharityContent = (
    <>
      <StyledCampaignImage>
        {user?.userPreference?.campaign?.name ? (
          <LazyImage
            src={partnerLogo}
            altName={`campaign-iamge`}
            width={96}
            height={54}
            fit="cover"
          />
        ) : (
          <StyledCampaignName>
            <FormattedMessage
              id="dashboard::settings::notChosenFavouriteCharity"
              defaultMessage="You have not chosen favourite charity yet"
            />
          </StyledCampaignName>
        )}
      </StyledCampaignImage>
      <StyledCampaignName>
        {user?.userPreference?.campaign?.name &&
          getValueForLocale(
            user?.userPreference?.campaign,
            "name",
            locale,
            defaultLocale
          )}
      </StyledCampaignName>
    </>
  )

  const sendMeSubscriptionContent = (
    <StyledPageGrid>
      <StyledRowTitle>
        <FormattedMessage
          id="dashboard::settings::subscription"
          defaultMessage="Subscription"
        />
        <Spacer bottom={0.5} />
      </StyledRowTitle>
      <StyledRowContent>
        <StyledSection>
          <StyledCheckbox>
            <Checkbox
              label={sendMeSubscriptionText}
              isChecked={subscription}
              onChange={(e) => {
                handleSubscribeChange(e)
              }}
            />
          </StyledCheckbox>
        </StyledSection>
      </StyledRowContent>
    </StyledPageGrid>
  )

  const mobileFavoriteCharityContent = (
    <StyledFavoriteCharityContainerMobile>
      <StyledSmallSectionTitleMobile>
        {favoriteCharityTitle}
      </StyledSmallSectionTitleMobile>
      <Spacer bottom={0.5} />
      <StyledSelectedFavoriteCharityMobile
        onClick={() => {
          showModal(favoriteNgoSelector, "Favorite Charity")
        }}>
        {user?.userPreference?.campaign?.name
          ? getValueForLocale(
              user?.userPreference?.campaign,
              "name",
              locale,
              defaultLocale
            )
          : chhoseFavoriteCharityText}
      </StyledSelectedFavoriteCharityMobile>
      <Spacer bottom={0.5} />
      <StyledRowTitleExplanation>
        {choseFavoriteExplanationText}
      </StyledRowTitleExplanation>
      <Spacer bottom={1} />
    </StyledFavoriteCharityContainerMobile>
  )

  const mobileContent = (
    <StyledMenuContainer>
      <StyledCategoryHr />
      <StyledMenuItem
        className="pointer"
        onClick={() => showModal(categoryContent, preferencesText)}>
        {preferencesText}
      </StyledMenuItem>
      <StyledCategoryHr />
      <StyledMenuItem
        className="pointer"
        onClick={() => showModal(languageContent, communicationLanguageText)}>
        {communicationLanguageText}
      </StyledMenuItem>
      <StyledCategoryHr />
      <StyledCheckbox>
        <Checkbox
          className="mobile-checkbox"
          label={sendMeSubscriptionText}
          isChecked={subscription}
          onChange={(e) => {
            handleSubscribeChange(e)
          }}
        />
      </StyledCheckbox>
      {mobileFavoriteCharityContent}
    </StyledMenuContainer>
  )

  const showModal = (content, header) => {
    setMenuModalStatus(true)

    setModalContent({
      children: content,
      header: header,
    })
  }

  return (
    <StyledPage>
      <StyledPageTitle>
        <StyledTitle>
          <FormattedMessage
            id="dashboard::settings::title"
            defaultMessage="Settings"
          />
        </StyledTitle>
      </StyledPageTitle>
      <StyledPageContent>
        {!isTablet ? (
          mobileContent
        ) : (
          <>
            <StyledPageGrid>
              <StyledRowTitle>
                {communicationLanguageText}
                <Spacer bottom={0.5} />
              </StyledRowTitle>
              <StyledRowContent>
                <StyledSection>
                  <StyledSectionTitle>
                    <FormattedMessage
                      id="dashboard::settings::language"
                      defaultMessage="Language"
                    />
                  </StyledSectionTitle>
                  <Spacer bottom={0.5} />
                  <Dropdown
                    id="communicationLanguage"
                    name="communicationLanguage"
                    options={supportedLanguagesLocalization}
                    value={{
                      value: selectedLanguage,
                      label: currentLanguage?.label,
                    }}
                    onChange={(lang) => {
                      handleLanguageChange(lang?.value)
                    }}
                    label={communicationLanguageText}
                    defaultSelectStyles={{
                      control: {
                        paddingTop: isWide ? "0.5rem" : "0.3rem",
                        paddingRight: "0",
                        maxWidth: isWide ? "28.25rem" : "13rem",
                        width: "100%",
                      },
                    }}
                  />
                  <Spacer bottom={1} />
                </StyledSection>
              </StyledRowContent>
            </StyledPageGrid>
            <Spacer bottom={2.5} />
            <StyledPageGrid>
              {selectFavoriteCharityContent}
              <StyledRowContent>
                {isFavSectionEditOpen
                  ? editFavoriteCharityContent
                  : selectedFavoriteCharityContent}
              </StyledRowContent>
            </StyledPageGrid>
            {/*
          <Spacer bottom={2.5} />
          <StyledPageGrid>
            <StyledRowTitle>
              <FormattedMessage
                id="dashboard::settings::privacy"
                defaultMessage="Privacy"
              />
              <Spacer bottom={0.5} />
            </StyledRowTitle>
            <StyledRowContent>
              <StyledSection>
                <Checkbox
                  label={
                    <FormattedMessage
                      id="dashboard::settings::anonymousDonations"
                      defaultMessage="Make my donations anonymous"
                    />
                  }
                  isChecked={privacy}
                  onChange={(e) => {
                    setPrivacy(!privacy)
                  }}
                />
              </StyledSection>
            </StyledRowContent>
          </StyledPageGrid>
          */}
            <Spacer bottom={4} />
            <StyledPageGrid disabled={isPreferencesLoading}>
              <StyledRowTitle>
                {preferencesText}
                <Spacer bottom={0.5} />
                <StyledRowTitleExplanation>
                  <FormattedMessage
                    id="dashboard::settings::preferencesExplanation"
                    defaultMessage="Choose topics you would like to see in your recommendations"
                  />
                </StyledRowTitleExplanation>
              </StyledRowTitle>
              <StyledRowContent>{categoryContent}</StyledRowContent>
            </StyledPageGrid>
            {!allCategoriesLoading && !allOfferCategoriesLoading && (
              <>
                <Spacer bottom={2.5} />
                {sendMeSubscriptionContent}
                <Spacer bottom={2.5} />
              </>
            )}
          </>
        )}
      </StyledPageContent>

      <CustomModal
        isModalOpen={menuModalStatus && !isTablet}
        style={modalContent?.style}
        header={modalContent?.header}
        headerStyle={modalContent?.headerStyle}
        text={modalContent?.text}
        children={modalContent?.children}
        isCloseIcon={modalContent?.isCloseIcon || true}
        isCloseXButton={modalContent?.isCloseXButton}
        cancelButtonAction={() => {
          closeModal()
          if (typeof modalContent?.onClose === "function") {
            modalContent.onClose()
          }
        }}
        isMobile={true}
        isFull={true}
      />
    </StyledPage>
  )
}

export default Settings
