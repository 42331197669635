import styled from "@emotion/styled"

export const StyledLabel = styled.label`
  display: block;
  position: relative;
  padding-left: 2.1875rem;
  margin-bottom: 0.75rem;
  cursor: pointer;
  user-select: none;

  &:hover input ~ span {
    background-color: transparent;
  }
`

export const StyledInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ span {
    background-color: transparent;
    .fa-check {
      display: block;
    }
  }
`

export const StyledCheckmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 1.5625rem;
  width: 1.5625rem;
  border-radius: 0.1875rem;
  border: 0.0625rem solid ${(p) => p.theme.colors.formFieldsBorderColor};
  background-color: transparent;

  .fa-check {
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    display: none;
  }
`
