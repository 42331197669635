import React, { Fragment } from "react"
import {
  StyledBrandsWrapper,
  StyledActionCardList,
  StyledBackground,
} from "./index.styles"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import { faHandHoldingHeart } from "@fortawesome/pro-solid-svg-icons/faHandHoldingHeart"
import { faBagShopping } from "@fortawesome/free-solid-svg-icons/faBagShopping"
import { faPiggyBank } from "@fortawesome/pro-solid-svg-icons/faPiggyBank"
import { ActionCard } from "@tmu/components/common"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"

const { CLOUDFLARE_IMAGE_URL } = process.env

const SliceHeroHome = () => {
  const { formatMessage } = useIntl()

  const { isDesktop } = getAllScreenTypes()

  const imageOptions =
    "/cdn-cgi/image/width=auto,height=auto,fit=cover,format=webp,quality=90"

  const trimOptions = isDesktop ? ",trim=100:0:0:0" : ",trim=50:0:0:0"

  const heroImageUrl =
    CLOUDFLARE_IMAGE_URL +
    imageOptions +
    trimOptions +
    "/static/assets/holding_heart.png"

  const ActionCardListData = [
    {
      id: 0,
      icon: faHandHoldingHeart,
      title: (
        <FormattedMessage
          id="sliceHeroHome::donateTitle"
          defaultMessage="Donate"
          tagName="h4"
        />
      ),
      description: (
        <FormattedMessage
          id="sliceHeroHome::donateDescription"
          defaultMessage="All charities on TrustMeUp are vetted organizations, so you can be sure your donation will reach the proper reciever"
        />
      ),
      buttonText: formatMessage({
        id: "sliceHeroHome::donateCTAText",
        defaultMessage: "Explore Campaigns",
      }),
      link: "/campaigns?campaign=true&charity=true&event=true",
      type: "campaign",
    },
    {
      id: 1,
      icon: faPiggyBank,
      title: (
        <FormattedMessage
          id="sliceHeroHome::fundraiseTitle"
          defaultMessage="Fundraise"
          tagName="h4"
        />
      ),
      description: (
        <FormattedMessage
          id="sliceHeroHome::fundraiseDescription"
          defaultMessage="Support your favourite charity by creating a campaign. Choose a good cause: birthday, wedding, having a baby, etc or provide yours"
        />
      ),
      buttonText: (
        <FormattedMessage
          id="sliceHeroHome::fundraiseCTAText"
          defaultMessage="Fundraise"
        />
      ),
      link: "/fundraise",
      type: "fundraise",
    },
    {
      id: 2,
      icon: faBagShopping,
      title: (
        <FormattedMessage
          id="sliceHeroHome::shopTitle"
          defaultMessage="Shop"
          tagName="h4"
        />
      ),
      description: (
        <FormattedMessage
          id="sliceHeroHome::shopDescription"
          defaultMessage="We reward every donation with the same amount of PAC discounts, which can be used at our <span>PAC Offers</span> shopping gallery"
          values={{
            span: (...chunks) => (
              <span>
                {chunks.map((chunk, i) => (
                  <Fragment key={i}>{chunk}</Fragment>
                ))}
              </span>
            ),
          }}>
          {(...chunks) => (
            <p>
              {chunks.map((chunk, i) => (
                <Fragment key={i}>{chunk}</Fragment>
              ))}
            </p>
          )}
        </FormattedMessage>
      ),
      buttonText: formatMessage({
        id: "sliceHeroHome::shopCTAText",
        defaultMessage: "Explore PAC Offers",
      }),
      link: "/offers?offer=true&store=true",
      type: "offer",
    },
  ]

  return (
    <StyledBrandsWrapper data-testid="slice-hero-home" id="homepage-hero">
      <div className="hero-content">
        <FormattedMessage
          id="sliceHeroHome::homeTitle"
          defaultMessage="The New Generation of Donations and Online Shopping"
          tagName="h1"
        />
        <StyledActionCardList>
          {ActionCardListData.map(({ id, ...rest }) => {
            return <ActionCard key={id} {...rest} />
          })}
        </StyledActionCardList>
      </div>
      <StyledBackground url={heroImageUrl} />
      <div className="mobile-background" />
    </StyledBrandsWrapper>
  )
}

export default SliceHeroHome
