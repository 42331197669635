import styled from "@emotion/styled"
import ReactPlayer from "react-player"

export const StyledVideoPlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  overflow: hidden;
  width: 100%;
`

export const StyledVideoPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
`
