import React from "react"
import PropTypes from "prop-types"
import { CurrencyInputWrapper, StyledPayAmount } from "./index.styles"
import { FormattedMessage } from "gatsby-plugin-intl"
import { TextInput, Logo } from "@tmu/components/common"

const CurrencyInput = ({
  amount,
  isMinMax,
  minAmount,
  maxAmount,
  currency = "€",
  earnedPACs,
  className,
  id,
  name,
  type,
  label,
  placeholder,
  step,
  min,
  max,
  maxLength,
  onChange,
  onValueChange,
  onBlur,
  error,
  isDisabled,
}) => {
  return (
    <CurrencyInputWrapper
      className={`${className} ${isMinMax ? "min-max-input" : ""}`}>
      {isMinMax ? (
        <>
          <StyledPayAmount
            data-testid="currency-input--min"
            id={`${id}minInput`}
            name={`${name}minInput`}
            value={minAmount}
            type={type || "currency"}
            label={"Min"}
            className={`float-container min-input`}
            placeholder={placeholder}
            step={step}
            min={min}
            onChange={onChange}
            onValueChange={onValueChange}
            onBlur={onBlur}
            error={error}
            disabled={isDisabled}>
            <div className="pay-amount__currency">{currency}</div>
          </StyledPayAmount>
          <StyledPayAmount
            data-testid="currency-input--max"
            id={`${id}maxInput`}
            name={`${name}maxInput`}
            value={maxAmount}
            type={type || "currency"}
            label={"Max"}
            className={`float-container max-input`}
            placeholder={placeholder}
            step={step}
            min={min}
            onChange={onChange}
            onValueChange={onValueChange}
            onBlur={onBlur}
            error={error}
            disabled={isDisabled}
          />
        </>
      ) : (
        <StyledPayAmount
          data-testid="currency-input"
          component={TextInput}
          id={id}
          name={name}
          value={amount}
          type={type || "currency"}
          label={label}
          className={`float-container currency-input`}
          placeholder={placeholder}
          step={step}
          min={min}
          max={max}
          maxLength={maxLength}
          onChange={onChange}
          onValueChange={onValueChange}
          onBlur={onBlur}
          error={error}
          disabled={isDisabled}>
          <div className="pay-amount__currency">{currency}</div>
          {earnedPACs !== undefined ? (
            <div className="pay-amount__earned-pacs">
              <FormattedMessage
                id="campaign::donationForm::youWillEarn"
                defaultMessage="You will earn"
                tagName="p"
              />
              <div>
                <Logo /> {earnedPACs}
              </div>
            </div>
          ) : null}
        </StyledPayAmount>
      )}
    </CurrencyInputWrapper>
  )
}

CurrencyInput.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  earnedPACs: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  step: PropTypes.number,
  min: PropTypes.number,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  error: PropTypes.string,
  isMinMax: PropTypes.bool,
  isDisabled: PropTypes.bool,
}

CurrencyInput.defaultProps = {
  label: "",
  id: "",
  onChange: () => {},
}

export default CurrencyInput
