import { gql } from "@apollo/client"

import { campaignListingParts } from "@tmu/apollo/storefront/fragments/campaign"
import { partnerListingParts } from "@tmu/apollo/storefront/fragments/partner"
import { merchantOfferParts } from "@tmu/apollo/storefront/fragments/merchant"
export const SEARCH_QUERY = gql`
  query Search(
    $query: String
    $first: Int
    $after: String
    $badge: [String]
    $offerOrigin: [String]
  ) {
    allPartners(search: $query, first: $first) {
      edges {
        node {
          name
          logo
          slug
          taxId
        }
      }
    }
    allCampaigns(
      search: $query
      first: $first
      after: $after
      visibilityStatus: ["2", "3"]
    ) {
      edges {
        node {
          ...campaignListingParts
          categories {
            edges {
              node {
                id
                slug
                name
                icon
              }
            }
          }
          partner {
            ...partnerListingParts
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }

    allOffers(
      search: $query
      first: $first
      badge: $badge
      offerOrigin: $offerOrigin
    ) {
      edges {
        node {
          ...merchantOfferParts
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
    offers: allOffers(
      first: $first
      search: $query
      offerType_In: [INTERNAL, ONLINE, OFFLINE, BRAND]
      isDisplayed: true
    ) {
      edges {
        node {
          id
          store {
            id
            name
            slug
            logo
            isDisplayed
            maxPacDiscount
          }
        }
      }
    }
  }
  ${campaignListingParts}
  ${partnerListingParts}
  ${merchantOfferParts}
`
