import styled from "@emotion/styled"

export const StyledOfflineMerchantTooltip = styled.div`
  display: inline;

  .react-tooltip__show,
  .react-tooltip__closing {
    max-width: 10.625rem;
    margin-top: -0.2rem;
    margin-left: 1rem;
    padding: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.15) 0 0.9375rem 1.5625rem,
      rgba(0, 0, 0, 0.05) 0 0.3125rem 0.625rem;

    &.offline-merchant {
      font-family: ${(p) => p.theme.fontFamilies.regular};
      font-style: ${(p) => p.theme.fontStyles.normal};
      font-weight: ${(p) => p.theme.fontWeights.liteMedium};
      font-size: 1rem;
      line-height: 1.5rem;
      text-transform: initial;
    }
  }

  .react-tooltip__show {
    opacity: 1;
  }
`
