import React from "react"
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"
import SEO from "@tmu/components/seo"
import { Hero } from "@tmu/components/common"
import {
  GetStarted,
  HowItWorks,
  Benefits,
  OurPricing,
} from "@tmu/components/digital-banking"

const { CLOUDFLARE_IMAGE_URL } = process.env

const Check = CLOUDFLARE_IMAGE_URL + "/static/assets/images/svg/check.svg"

const Business = ({ intl }) => {
  const { locale, formatMessage } = intl

  const benefitCards = [
    {
      icon: CLOUDFLARE_IMAGE_URL + "/static/assets/images/svg/teams.svg",
      title: formatMessage({
        id: "digitalBanking::business::benefitCards::title1",
        defaultMessage: "A teams & expenses solution",
      }),
      desc: formatMessage({
        id: "digitalBanking::business::benefitCards::description1",
        defaultMessage:
          "Add team accounts, assign roles with relevant access levels and issue PAC Visa debit cards for employees. Manage everything - from expenses approval and invoicing to card issuing and budgeting - from your app. No more hassle; no more paperwork.",
      }),
    },
    {
      icon:
        CLOUDFLARE_IMAGE_URL + "/static/assets/images/svg/faster_payments.svg",
      title: formatMessage({
        id: "digitalBanking::business::benefitCards::title2",
        defaultMessage: "24/7 SEPA Faster payments",
      }),
      desc: formatMessage({
        id: "digitalBanking::business::benefitCards::description2",
        defaultMessage:
          "Todays’ world and today’s businesses are always-on – so your bank should be too! Make and take payments to and from customers and suppliers anytime, anywhere in near-realtime.",
      }),
    },
    {
      icon: CLOUDFLARE_IMAGE_URL + "/static/assets/images/svg/payments.svg",
      title: formatMessage({
        id: "digitalBanking::business::benefitCards::title3",
        defaultMessage: "Payments that make themselves",
      }),
      desc: formatMessage({
        id: "digitalBanking::business::benefitCards::description3",
        defaultMessage:
          "Organise and schedule instant, recurring and future payments from the account of your choice in the currency you prefer.",
      }),
    },
    {
      icon: CLOUDFLARE_IMAGE_URL + "/static/assets/images/svg/categorized.svg",
      title: formatMessage({
        id: "digitalBanking::business::benefitCards::title4",
        defaultMessage: "Categorized Spending",
      }),
      desc: formatMessage({
        id: "digitalBanking::business::benefitCards::description4",
        defaultMessage:
          "Get a clear overview of expenditure by category at your chosen time interval. Add hours to your day!",
      }),
    },
    {
      icon:
        CLOUDFLARE_IMAGE_URL + "/static/assets/images/svg/multi-currency.svg",
      title: formatMessage({
        id: "digitalBanking::business::benefitCards::title5",
        defaultMessage: "Integrated accounting",
      }),
      desc: formatMessage({
        id: "digitalBanking::business::benefitCards::description5",
        defaultMessage:
          "Integrate automated accounting tools like Xero, Sage One and A3, or use TrustMeUp Business Reports to receive weekly or monthly analyses of transactions and expenses.",
      }),
    },
    {
      icon: CLOUDFLARE_IMAGE_URL + "/static/assets/images/svg/secure.svg",
      title: formatMessage({
        id: "digitalBanking::business::benefitCards::title6",
        defaultMessage: "Security",
      }),
      desc: formatMessage({
        id: "digitalBanking::business::benefitCards::description6",
        defaultMessage:
          "Rest assured that, with TrustMeUp Business, your business is in the safest hands. PAC makes use of the best security that technology has to offer.",
      }),
    },
  ]
  const openAccountCard = {
    title: formatMessage({
      id: "digitalBanking::business::openAccountCard::title",
      defaultMessage: "€ 19,99",
    }),
    desc: formatMessage({
      id: "digitalBanking::business::openAccountCard::desc",
      defaultMessage:
        "Best for businesses starting out, with few monthly payments and a focus on local transactions.",
    }),
    buttonText: formatMessage({
      id: "digitalBanking::business::openAccountCard::button",
      defaultMessage: "Open Account",
    }),

    subTitle: formatMessage({
      id: "digitalBanking::business::ourPricing::subTitle",
      defaultMessage: "/monthly fee",
    }),
    url: "https://business.trustmeup.com/onboarding-user",
  }

  const priceCard = {
    rightSlices: [
      {
        title: formatMessage({
          id: "digitalBanking::business::priceCard::title7",
          defaultMessage: "GBP & Euro IBAN",
        }),
        price: formatMessage({
          id: "digitalBanking::business::tmuTotmu::price",
          defaultMessage: "free",
        }),
      },
      {
        title: formatMessage({
          id: "digitalBanking::business::priceCard::title1",
          defaultMessage: "Debit card",
        }),
        icon: Check,
      },
      {
        title: formatMessage({
          id: "digitalBanking::business::priceCard::title2",
          defaultMessage: "Virtual card",
        }),
        icon: Check,
      },
      {
        title: formatMessage({
          id: "digitalBanking::business::priceCard::title3",
          defaultMessage: "SEPA in-out",
        }),
        subTitle: formatMessage({
          id: "digitalBanking::business::priceCard::subtTitle1",
          defaultMessage: "per transaction",
        }),
        price: formatMessage({
          id: "digitalBanking::business::sepa::price",
          defaultMessage: "€0.99",
        }),
      },
      {
        title: formatMessage({
          id: "digitalBanking::business::priceCard::title4",
          defaultMessage: "Convert & send in 30+ currencies",
        }),
        subTitle: formatMessage({
          id: "digitalBanking::business::priceCardConvert::subtTitle1",
          defaultMessage: "per transaction (swift)",
        }),
        price: formatMessage({
          id: "digitalBanking::business::swift::price",
          defaultMessage: "€14,99",
        }),
      },
      {
        title: formatMessage({
          id: "digitalBanking::business::priceCard::title5",
          defaultMessage: "ATM transaction",
        }),
        subTitle: formatMessage({
          id: "digitalBanking::business::priceCard::subtTitle1",
          defaultMessage: "per transaction",
        }),
        price: formatMessage({
          id: "digitalBanking::business::atm::price",
          defaultMessage: "€1,99",
        }),
      },
    ],
    leftSlices: [
      {
        title: formatMessage({
          id: "digitalBanking::business::priceCard::title6",
          defaultMessage: "TMU to TMU",
        }),
        price: formatMessage({
          id: "digitalBanking::business::tmuTotmu::price",
          defaultMessage: "free",
        }),
      },
      {
        title: formatMessage({
          id: "digitalBanking::business::priceCard::title8",
          defaultMessage: "Mobile and desktop app",
        }),
        icon: Check,
      },
      {
        title: formatMessage({
          id: "digitalBanking::business::priceCard::title9",
          defaultMessage: "Schedule recurring payments",
        }),
        icon: Check,
      },
      {
        title: formatMessage({
          id: "digitalBanking::business::priceCard::title10",
          defaultMessage: "Accounting tool integration",
        }),
        icon: Check,
      },
      {
        title: formatMessage({
          id: "digitalBanking::business::priceCard::title11",
          defaultMessage: "Role-based access",
        }),
        subTitle: formatMessage({
          id: "digitalBanking::business::priceCard::subtTitle2",
          defaultMessage: "team members and accountants",
        }),
        icon: Check,
      },
      {
        title: formatMessage({
          id: "digitalBanking::business::priceCard::title12",
          defaultMessage: "Expense management",
        }),
        icon: Check,
      },
    ],
  }
  return (
    <>
      <SEO
        lang={locale}
        title={formatMessage({
          id: "digitalBanking::business::pageTitle",
          defaultMessage: "Digital Banking Business",
        })}
        image={
          CLOUDFLARE_IMAGE_URL +
          "/cdn-cgi/image/width=1920,fit=cover,format=webp,quality=90/static/assets/images/business_main_img.png"
        }
      />
      <Hero page={`/${locale}/digital-banking/business`} />
      <>
        <GetStarted
          description={
            <FormattedMessage
              id="digitalBanking::business::getStarted"
              defaultMessage="Be part of a growing international Donations Economy, where your business and your customers are rewarded for supporting the organizations and charities that make a difference in the world."
            />
          }
          image={
            CLOUDFLARE_IMAGE_URL +
            "/cdn-cgi/image/width=1920,fit=cover,format=webp,quality=90/static/assets/images/business_main_img.png"
          }
        />

        <HowItWorks
          id="lets-get-started"
          title={
            <FormattedMessage
              id="digitalBanking::business::howitworks::title"
              defaultMessage="Let’s get started"
            />
          }
          description={
            <FormattedMessage
              id="digitalBanking::business::howitworks::description"
              defaultMessage="It all starts with opening a TrustMeUp Business Banking account. What makes this account special? A PAC account comes with multiple smart features that allow you to organise and manage all of your business finances from one app, easily."
            />
          }
          downloadTitle={
            <FormattedMessage
              id="digitalBanking::business::howitworks::downloadTitle"
              defaultMessage="Business account features that make a real difference."
            />
          }
          appleStoreUrl="https://apps.apple.com/tr/app/trustmeup-business/id1537085404/"
          playStoreUrl="https://play.google.com/store/apps/details?id=com.business.tmup"
          register="https://business.trustmeup.com/login/"
          image={
            CLOUDFLARE_IMAGE_URL +
            "/cdn-cgi/image/fit=cover,format=webp,quality=90/static/assets/images/business_mobile_app.png"
          }
        />

        <Benefits benefits={benefitCards} />

        <OurPricing
          title={
            <FormattedMessage
              id="digitalBanking::business::ourPricing::title"
              defaultMessage="Our Pricing"
            />
          }
          openAccountCard={openAccountCard}
          priceCard={priceCard}
        />
      </>
    </>
  )
}

export default injectIntl(Business)
