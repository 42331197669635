import queryString from "query-string"
import React, { useState, useRef, useCallback, useEffect } from "react"
import {
  StyledWrapper,
  StyledImageWrapper,
  StyledFormWrapper,
} from "./index.styles"
import { useMutation } from "@apollo/client"
import { MERCHANT_SELF_REGISTER_MUTATION } from "@tmu/apollo/storefront/mutations/merchant"
import { capitalize } from "@tmu/utils/string"
import { CREATE_STRIPE_ONBOARDING_ACCOUNT_MUTATION } from "@tmu/apollo/storefront/mutations/merchant"

import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { useAuth, useToast } from "@tmu/src/hooks"
import { Formik, Form } from "formik"

import * as Yup from "yup"
import { Button } from "@tmu/components/common"
import {
  categories,
  requiredString,
  urlFormat,
  agreeTerms,
  urlFormatRequired2,
  imageOnlyRequired,
  percentage,
} from "@tmu/utils/validation"
import { useApolloApiClients } from "@tmu/apollo/client"
import { ME_QUERY } from "@tmu/apollo/storefront/queries/user"

import RegisterForm from "./RegisterForm"
import CharityForm from "./CharityForm"
import StripePage from "./StripePage"
import CheckoutForm from "./CheckoutForm"
import Onboarded from "./Onboarded"

const MerchantSelfRegistration = ({}) => {
  const paramOptions = { arrayFormat: "comma" }
  const params = queryString.parse(location.search, paramOptions)
  const isMerchantOnline = params?.type === "online"
  const isMerchantOffline = params?.type === "offline"
  const isMerchantInternal = params?.type === "internal"
  const isMerchantCorporate = false
  const { user } = useAuth()
  const { formatMessage } = useIntl()
  const { storefrontClient } = useApolloApiClients()
  const { error: errorToast } = useToast()

  const [step, setStep] = useState(0)
  const [loading, setLoading] = useState(false)

  const handleClick = (index) => {
    if (index <= step) {
      setStep(index)
    }
  }
  const [registerMerchant, { data, errors }] = useMutation(
    MERCHANT_SELF_REGISTER_MUTATION,
    {
      client: storefrontClient,
      refetchQueries: [
        {
          query: ME_QUERY,
        },
      ],
    }
  )

  const [
    createStripeOnboardingAccount,
    { data: stripeData, errors: stripeErrors },
  ] = useMutation(CREATE_STRIPE_ONBOARDING_ACCOUNT_MUTATION, {
    client: storefrontClient,
  })

  const { CLOUDFLARE_IMAGE_URL } = process.env

  const getImgSrc = (s) => {
    switch (s) {
      case 0:
        return CLOUDFLARE_IMAGE_URL + "/static/assets/images/shopowner1.png"

      case 1:
        return CLOUDFLARE_IMAGE_URL + "/static/assets/images/hands1.png"

      default:
        return CLOUDFLARE_IMAGE_URL + "/static/assets/images/shopowner2.png"
    }
  }

  const imgSrc = getImgSrc(step)

  const steps = [
    formatMessage({
      id: "merchant::selfRegistrationForm::onboardingForm",
      defaultMessage: "Onboarding Form",
    }),
    isMerchantInternal
      ? formatMessage({
          id: "merchant::selfRegistrationForm::charity",
          defaultMessage: "Charity",
        })
      : null,
    isMerchantInternal
      ? formatMessage({
          id: "merchant::selfRegistrationForm::stripe",
          defaultMessage: "Stripe",
        })
      : null,
    formatMessage({
      id: "merchant::selfRegistrationForm::onboarded",
      defaultMessage: "Onboarded",
    }),
    isMerchantOnline
      ? formatMessage({
          id: "merchant::selfRegistrationForm::plugin",
          defaultMessage: "Plugin",
        })
      : null,
  ].filter(Boolean)

  const initialValues = {
    legalName: "",
    displayName: "",
    taxId: "",
    phoneNumber: "",
    addressLine1: "",
    postalCode: "",
    country: "",
    category: "",
    communicationLanguage: "",
    maxPacDiscount: 0,
    availableCities: [],
    availableCountries: [],
    // bankName: "",
    // iban: "",
    // bic: "",
    // is_vies_registered : false,
    logo: "",
    image: "",
    description: "",
    website: "",
    facebook: "",
    instagram: "",
    youtube: "",
    linkedin: "",
    twitter: "",
    isWorldwide: true,
    agreeTerms: false,
    charity: "",
    allowUsersToSelectADifferentCampaign: false,
  }
  const validationSchema = Yup.object().shape({
    legalName: requiredString({ formatMessage }),
    displayName: requiredString({ formatMessage }),
    taxId: requiredString({ formatMessage }),
    phoneNumber: requiredString({ formatMessage }),
    addressLine1: requiredString({ formatMessage }),
    postalCode: requiredString({ formatMessage }),
    country: requiredString({ formatMessage }),
    category: categories({ formatMessage }),
    communicationLanguage: requiredString({
      formatMessage,
    }),
    // bankName: requiredString({ formatMessage }),
    // bic: requiredString({ formatMessage }),
    // iban: iban({ formatMessage }),
    website: isMerchantOnline && urlFormatRequired2({ formatMessage }),
    maxPacDiscount:
      (isMerchantOnline || isMerchantOffline) && percentage({ formatMessage }),
    description: requiredString({ formatMessage }),
    // availableCountries: availableCountries({ formatMessage }),
    logo: imageOnlyRequired({ formatMessage }),
    image: imageOnlyRequired({ formatMessage }),
    facebook: urlFormat({ formatMessage }),
    instagram: urlFormat({ formatMessage }),
    youtube: urlFormat({ formatMessage }),
    linkedin: urlFormat({ formatMessage }),
    twitter: urlFormat({ formatMessage }),
    agreeTerms: agreeTerms({ formatMessage }),
  })

  const handleSubmit = (values, form) => {
    setLoading(true)
    console.log("values", values)

    const registerData = {
      visibilityStatus: "1",
      defaultOfferCategories: values.category?.[0],
      merchantType: isMerchantOnline
        ? "MERCHANT_ONLINE"
        : isMerchantOffline
        ? "MERCHANT_OFFLINE"
        : isMerchantInternal
        ? "MERCHANT_INTERNAL"
        : null,
      communicationLanguage: values.communicationLanguage,
      email: user.email,
      maxPacDiscount: values.maxPacDiscount || 0,
      defaultAvailableCities: values.availableCities || [],
      defaultAvailableCountries: values.availableCountries || [],
      name: values.legalName,
      phoneNumber: values.phoneNumber,
      displayName: values.displayName,
      description: values.description,
      shortDescription: values.description,
      [`shortDescription${capitalize(values.communicationLanguage)}`]:
        values.description,
      country: values.country,
      city: values.city,
      postCode: values.postCode,
      addressLine1: values.addressLine1,
      taxId: values.taxId,
      vatNumber: values.taxId,
      logo: values.logo,
      image: values.image,
      pricingBundle: "",
      website: values.website,
      shopLink: values.website,
      linkedin: values.linkedin,
      twitter: values.twitter,
      facebook: values.facebook,
      instagram: values.instagram,
      youtube: values.youtube,
      favoriteCharity: values.charity,
      allowUsersToSelectADifferentCampaign:
        values.allowUsersToSelectADifferentCampaign,
    }
    console.log("registerData", registerData)

    registerMerchant({
      variables: {
        input: registerData,
      },
    })
      .then((response) => {
        console.log("response", response)
        if (response?.errors?.length) {
          errorToast(
            formatMessage({
              id: "dashboard::campaignForm::errorMessage",
              defaultMessage: "An error occurred",
            })
          )
        } else {
          const merchantId = response?.data?.createMerchantStore?.store?.id
          if (merchantId) {
            createStripeOnboardingAccount({
              variables: {
                id: merchantId,
                type: "merchant",
              },
            })
            const newUrl = new URL(window.location)
            newUrl.searchParams.set("merchant", merchantId)
            window.history.replaceState({}, "", newUrl)
          }
          setStep((prev) => prev + 1)
        }
      })
      .catch((error) => {
        console.error("Registration error", error)
        errorToast(
          formatMessage({
            id: "dashboard::campaignForm::errorMessage",
            defaultMessage: "An error occurred",
          })
        )
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <StyledWrapper>
      {step < 3 ? (
        <StyledImageWrapper>
          <img src={imgSrc} />
        </StyledImageWrapper>
      ) : null}
      <StyledFormWrapper>
        <div
          className={`stepper ${
            isMerchantOnline
              ? "three-steps"
              : isMerchantOffline
              ? "two-steps"
              : "four-steps"
          }`}>
          {steps.map((item, index) => (
            <React.Fragment key={index}>
              <p className={`label label-${index}`}>{item}</p>
              <div className={`box box-${index}`}>
                <div
                  className={`circle ${index <= step ? "active" : ""} ${
                    index < step ||
                    (index === 1 &&
                      step === 1 &&
                      (isMerchantOffline || isMerchantOnline))
                      ? "completed"
                      : ""
                  }`}
                  onClick={() => handleClick(index)}
                  style={{ pointerEvents: index <= step ? "auto" : "none" }}
                />
                {index < steps.length - 1 && (
                  <div className={`line ${index < step ? "active" : ""}`} />
                )}
              </div>
            </React.Fragment>
          ))}
        </div>
        {step === 0 || (step === 1 && isMerchantInternal) ? (
          <Formik
            enableReinitialize={true}
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={handleSubmit}>
            {({
              values,
              handleChange,
              handleBlur,
              handleReset,
              touched,
              errors,
              setFieldTouched,
            }) => {
              return (
                <Form>
                  {step === 0 ? (
                    <>
                      <RegisterForm />

                      {isMerchantInternal ? (
                        <Button
                          color="carrot"
                          type="button"
                          className="full-width"
                          disabled={Object.keys(errors)?.length}
                          onClick={(e) => {
                            e.preventDefault()
                            setStep((prev) => prev + 1)
                          }}>
                          <FormattedMessage
                            id="ui::continue"
                            defaultMessage="Continue"
                          />
                        </Button>
                      ) : (
                        <Button
                          color="carrot"
                          type="submit"
                          disabled={loading}
                          className="full-width">
                          <FormattedMessage
                            id="ui::continue"
                            defaultMessage="Continue"
                          />
                        </Button>
                      )}
                    </>
                  ) : (
                    <>
                      <CharityForm />
                      <Button
                        color="carrot"
                        type="submit"
                        className="full-width"
                        disabled={loading || !values.charity}>
                        <FormattedMessage
                          id="ui::continue"
                          defaultMessage="Continue"
                        />
                      </Button>
                    </>
                  )}
                </Form>
              )
            }}
          </Formik>
        ) : step === 1 && (isMerchantOffline || isMerchantOnline) ? (
          <Onboarded />
        ) : step === 2 && isMerchantInternal ? (
          <StripePage
            createStripeOnboardingAccount={createStripeOnboardingAccount}
            data={stripeData}
            errors={stripeErrors}
            onClick={(e) => {
              e.preventDefault()
              setStep((prev) => prev + 1)
            }}
          />
        ) : step === 3 && isMerchantInternal ? (
          <Onboarded />
        ) : null}
      </StyledFormWrapper>
    </StyledWrapper>
  )
}

export default MerchantSelfRegistration
