import React from "react"
import PropTypes from "prop-types"
import { EmptyTable } from "@tmu/components/dashboard/dashboardCommon"
import { FormattedMessage } from "gatsby-plugin-intl"
import { StyledCampaignUpdatesTable } from "./index.styles"
import CampaignUpdatesTableHeader from "./CampaignUpdatesTableHeader"
import CampaignUpdatesTableRow from "./CampaignUpdatesTableRow"

const CampaignUpdatesTable = ({ campaignUpdates, onEdit, onDelete }) => {
  return (
    <>
      {campaignUpdates?.length > 0 ? (
        <>
          <CampaignUpdatesTableHeader />
          <StyledCampaignUpdatesTable
            height={430}
            itemCount={campaignUpdates?.length ?? 0}
            itemSize={90}
            width="100%"
            itemData={campaignUpdates}>
            {({ index, style, data }) => {
              const update = data[index]
              return (
                <CampaignUpdatesTableRow
                  key={update?.id}
                  {...update}
                  index={index}
                  style={style}
                  onEdit={() => onEdit(update)}
                  onDelete={() => onDelete(update)}
                />
              )
            }}
          </StyledCampaignUpdatesTable>
        </>
      ) : (
        <EmptyTable
          emptyMessage={
            <FormattedMessage
              id="dashboard::campaignUpdates::empty"
              defaultMessage="It seems you haven't created any campaign updates."
            />
          }
        />
      )}
    </>
  )
}

CampaignUpdatesTable.propTypes = {
  campaignUpdates: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.string,
      id: PropTypes.string,
      created: PropTypes.string,
      modified: PropTypes.string,
    })
  ).isRequired,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
}

export default CampaignUpdatesTable
