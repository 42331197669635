import styled from "@emotion/styled"
export const StyledRadioOptions = styled.div`
  fieldset {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }

  .radio-select-input > span {
    border-color: ${(p) => p.theme.colors.carrot};
    top: 1rem;
    left: 0.5rem;
  }

  fieldset:disabled {
    color: ${(p) => p.theme.colors.disabled};
  }
  .radio-select-checked-input > span:after {
    background: ${(p) => p.theme.colors.carrot};
  }

  .passive {
    background-color: ${(p) => p.theme.colors.whiteSmoke};
    box-shadow: none;
  }

  &.campaign-colors {
    .radio-select-checked-input > span:after {
      background: ${(p) => p.theme.colors.pacificBlue};
    }

    .radio-select-input > span {
      border-color: ${(p) => p.theme.colors.pacificBlue};
    }
  }
`

export const StyledConfirmationOptionsContainer = styled.div`
  padding: 1rem;
  padding-top: 3rem;
  margin-left: -3rem;
  border-radius: 0.25rem;
  border: 0px solid ${(p) => p.theme.colors.lightGrey};
  background: ${(p) => p.theme.colors.white};
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.15);
  min-height: 20rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    min-height: 15rem;
  }
`
export const StyledConfirmationOptionsTitle = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-size: 1rem;
  line-height: 1.5rem;
`
export const StyledConfirmationOptionsDescription = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  font-size: 1rem;
  line-height: 1.5rem;
`
