import React, { useState, useEffect } from "react"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { Field, useFormikContext } from "formik"
import {
  TextInput,
  FieldError,
  Panel,
  Tabs,
  TabList,
  CheckboxContainer,
  Dropdown,
} from "@tmu/components/common"
import { ImageSelector, Tab } from "@tmu/components/dashboard/dashboardCommon"
import {
  StyledField,
  StyledFieldWrapper,
  StyledInfoText,
  StyledCheckboxArea,
} from "./index.styles"

const HeroItemsFieldSet = ({
  activeLangs,
  setActiveLangs,
  dashboardType,
  totalHeroItemNumber,
}) => {
  const { formatMessage } = useIntl()
  const { values, handleChange, handleBlur, setFieldValue, setFieldTouched } =
    useFormikContext()
  const [tabIndex, setTabIndex] = useState(0)
  const [statusOptions, setStatusOptions] = useState([])

  const infoForMerchant = formatMessage({
    id: "dashboard::forms::hero::merchantInfo",
    defaultMessage:
      "You can change button's visibility, visibility of the banner and addition of donate by shopping code to the link with these settings",
  })

  const infoForPartner = formatMessage({
    id: "dashboard::forms::hero::partnerInfo",
    defaultMessage:
      "You can change button's visibility, visibility of the banner.",
  })

  useEffect(() => {
    if (!totalHeroItemNumber) return
    const opts = []
    for (let i = 0; i < totalHeroItemNumber; i++) {
      opts.push({ value: i, label: i.toString() })
    }
    setStatusOptions(opts)
  }, [totalHeroItemNumber])

  const formContent = (lang) => (
    <StyledFieldWrapper>
      <StyledField fullWidth>
        <Field
          data-testid={`heroTitle${lang}`}
          id={`title${lang}`}
          name={`title${lang}`}
          component={TextInput}
          className="float-container"
          value={values[`title${lang}`]}
          onChange={handleChange}
          onBlur={handleBlur}
          label={formatMessage({
            id: "dashboard::forms::title",
            defaultMessage: "Title",
          })}
        />
        <FieldError name={`title${lang}`} />
      </StyledField>
      <StyledField fullWidth detail>
        <Field
          data-testid={`heroDetail${lang}`}
          id={`detail${lang}`}
          name={`detail${lang}`}
          type="textarea"
          component={TextInput}
          className="float-container"
          value={values[`detail${lang}`]}
          onChange={handleChange}
          onBlur={handleBlur}
          label={formatMessage({
            id: "dashboard::forms::detail",
            defaultMessage: "Detail",
          })}
          placeholder=""
          textAreaMaxLength={150}
        />
        <FieldError name={`detail${lang}`} />
      </StyledField>
      <StyledField>
        <Field
          data-testid={`heroButtonText${lang}`}
          id={`buttonText${lang}`}
          name={`buttonText${lang}`}
          component={TextInput}
          className="float-container"
          value={values[`buttonText${lang}`]}
          onChange={handleChange}
          onBlur={handleBlur}
          label={formatMessage({
            id: "dashboard::forms::buttonText",
            defaultMessage: "Button Text",
          })}
        />
        <FieldError name={`buttonText${lang}`} />
      </StyledField>
      <StyledField fullWidth>
        <Field
          data-testid={`heroButtonLink${lang}`}
          id={`buttonLink${lang}`}
          name={`buttonLink${lang}`}
          component={TextInput}
          className="float-container"
          value={values[`buttonLink${lang}`]}
          onChange={handleChange}
          onBlur={handleBlur}
          label={formatMessage({
            id: "dashboard::forms::buttonLink",
            defaultMessage: "Button Link",
          })}
        />
        <FieldError name={`buttonLink${lang}`} />
      </StyledField>

      <StyledField fullWidth ordering>
        <FormattedMessage
          id="dashboard::forms::heroOrder"
          defaultMessage="Hero Order"
        />
        {":"}
        <Dropdown
          id="ordering"
          name="ordering"
          className="ordering"
          data-testid="hero-ordering-select"
          classNamePrefix="react-select"
          variant="condensed"
          isSearchable={false}
          options={statusOptions}
          value={
            statusOptions.filter((type) => type.value === values.ordering) ||
            statusOptions[0]
          }
          onChange={(type) => {
            setFieldValue("ordering", type?.value, true)
          }}
        />
        <FieldError name="ordering" />
      </StyledField>

      <StyledInfoText>
        {dashboardType === "merchants" ? infoForMerchant : infoForPartner}
      </StyledInfoText>
      <StyledCheckboxArea>
        {dashboardType === "merchants" ? (
          <CheckboxContainer className="full-width">
            <label htmlFor="addOtpToButtonLink">
              <input
                tabIndex="0"
                data-testid="check-addOtpToButtonLink"
                id="addOtpToButtonLink"
                name="addOtpToButtonLink"
                type="checkbox"
                checked={values?.addOtpToButtonLink}
                onChange={handleChange}
              />
              <span className="checkmark"></span>
              <FormattedMessage
                id="dashboard::forms::addOtpToButtonLink"
                defaultMessage="Add OTP to the link?"
              />
            </label>
            <FieldError name="addOtpToButtonLink" />
          </CheckboxContainer>
        ) : null}
        <CheckboxContainer className="full-width">
          <label htmlFor="isActive">
            <input
              tabIndex="1"
              data-testid="check-isActive"
              id="isActive"
              name="isActive"
              type="checkbox"
              checked={values?.isActive}
              onChange={handleChange}
            />
            <span className="checkmark"></span>
            <FormattedMessage
              id="dashboard::addHero::isActive"
              defaultMessage="Is hero item active?"
            />
          </label>
          <FieldError name="isActive" />
        </CheckboxContainer>
        <CheckboxContainer className="full-width">
          <label htmlFor="buttonEnabled">
            <input
              tabIndex="1"
              data-testid="check-buttonEnabled"
              id="buttonEnabled"
              name="buttonEnabled"
              type="checkbox"
              checked={values?.buttonEnabled}
              onChange={handleChange}
            />
            <span className="checkmark"></span>
            <FormattedMessage
              id="dashboard::addHero::buttonEnabled"
              defaultMessage="Is CTA button visible?"
            />
          </label>
          <FieldError name="buttonEnabled" />
        </CheckboxContainer>
      </StyledCheckboxArea>
    </StyledFieldWrapper>
  )
  return (
    <>
      <StyledField fullWidth>
        <ImageSelector
          defaultValue={values.image}
          onChange={(image) => setFieldValue("image", image)}
          onCropChange={(croppedImage) => setFieldValue("image", croppedImage)}
          onReset={() => setFieldValue("image", item.image)}
        />
        <FieldError name="image" />
      </StyledField>
      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList>
          <Tab
            isActive={tabIndex === 0}
            activeLangs={activeLangs}
            setActiveLangs={setActiveLangs}>
            <FormattedMessage
              id="dashboard::heroForm::English"
              defaultMessage="English"
            />
          </Tab>
          <Tab
            isActive={tabIndex === 1}
            activeLangs={activeLangs}
            setActiveLangs={setActiveLangs}>
            <FormattedMessage
              id="dashboard::heroForm::Italian"
              defaultMessage="Italian"
            />
          </Tab>
          <Tab
            isActive={tabIndex === 2}
            activeLangs={activeLangs}
            setActiveLangs={setActiveLangs}>
            <FormattedMessage
              id="dashboard::heroForm::Spanish"
              defaultMessage="Spanish"
            />
          </Tab>
        </TabList>
        <Panel>{formContent("En")}</Panel>
        <Panel>{formContent("It")}</Panel>
        <Panel>{formContent("Es")}</Panel>
      </Tabs>
    </>
  )
}

export default HeroItemsFieldSet
