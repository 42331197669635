import styled from "@emotion/styled"
import {
  StyledCard as CommonStyledCard,
  StyledImageBox as CommonStyledImageBox,
  StyledCardInfo as CommonStyledCardInfo,
  StyledCardTitle as CommonStyledCardTitle,
  StyledCardSubtitle as CommonStyledCardSubtitle,
  StyledPACMultiplier as CommonStyledPACMultiplier,
} from "../index.styles"

export const StyledCard = styled(CommonStyledCard)`
  padding: 0.5rem 1rem;
  grid-template-columns: 7.1875rem auto;
  grid-template-areas:
    "image info "
    "stats stats";

  .caption {
    font-size: 0.625rem;
    line-height: 1;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    grid-template-rows: 3.5rem auto;
    grid-template-areas:
      "image info"
      "image stats";
    height: 8.25rem;
    // width: 42.5rem;
    padding: 0.75rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    grid-template-columns: 16rem auto;
    height: 9.125rem;
    padding: 1rem;
    // width: 46.4375rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    grid-template-columns: 21.8125rem auto;
    grid-column-gap: 1.5625rem;
    height: 16.375rem;
    padding: 2rem;

    .caption {
      font-size: 1rem;
      span {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
      }
    }
  }

  &:hover .button-margin {
    color: ${(p) => p.theme.colors.blue};
    border-color: ${(p) => p.theme.colors.blue};
  }
`

export const StyledImageBox = styled(CommonStyledImageBox)`
  border-radius: 0.25rem;
  .media-box img {
    width: auto;
    max-width: 100%;
    max-height: 100%;
  }
`

export const StyledDoublePacsTooltip = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 0;

  .charityCard {
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.15);
    opacity: 1 !important;
  }
  .charityCardListing {
    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      width: 13.75rem;
    }
    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      width: unset;
    }
  }
`

export const StyledCharityStatsContainer = styled.div`
  grid-area: stats;
  display: flex;
  justify-content: space-between;
  > span {
    align-self: end;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    > div {
      padding: 0;
    }
    p:first-of-type {
      margin-right: 2rem;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    p:first-of-type {
      margin-right: 2rem;
    }
  }
`
export const StyledChooseButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: end;
  button {
    margin: 0;
    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1rem;
    }
  }
`

export const StyledCardInfo = styled(CommonStyledCardInfo)`
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 0;
    justify-content: unset;
    .caption {
      margin-top: 0.25rem;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    .caption {
      margin-top: 0.5rem;
    }
  }
`

export const StyledCardTitle = styled(CommonStyledCardTitle)`
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    span {
      font-size: 2rem;
      line-height: 2.625rem;
    }
  }
`

export const StyledCardSubtitle = styled(CommonStyledCardSubtitle)``

export const StyledPACMultiplier = styled(CommonStyledPACMultiplier)``
