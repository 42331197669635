import { useLazyQuery, useMutation } from "@apollo/client"
import { useApolloApiClients } from "../apollo/client"
import { MAX_PER_PAGE } from "../apollo/constants"
import {
  EVENT_TICKET,
  PRODUCT_VARIATION_TICKET,
} from "../apollo/dashboard/mutations/merchant"
import {
  GET_ALL_EVENT_TICKETS_QUERY,
  GET_ALL_PRODUCT_VARIATION_TICKETS_QUERY,
} from "../apollo/storefront/queries/merchant"

const useVouchers = (options) => {
  const { merchantClient, storefrontClient, partnerClient } =
    useApolloApiClients()

  let apolloClient = storefrontClient
  const apiType = options?.apiType

  if (apiType) {
    if (apiType?.merchant) {
      apolloClient = merchantClient
    } else if (apiType?.partner) {
      apolloClient = partnerClient
    }
  }

  const [checkVoucherCodeMutation] = useMutation(PRODUCT_VARIATION_TICKET, {
    client: apiType?.partner ? partnerClient : merchantClient,
  })

  const [checkEventVoucherMutation] = useMutation(EVENT_TICKET, {
    client: apolloClient,
  })

  const [
    callAllVoucherCodes,
    { data: allVouchersData, loading: allVouchersLoading },
  ] = useLazyQuery(GET_ALL_PRODUCT_VARIATION_TICKETS_QUERY, {
    client: apolloClient,
    fetchPolicy: "network-only",
    variables: { first: MAX_PER_PAGE },
  })

  const [
    callAllEventVoucherCodes,
    { data: allEventVouchersData, loading: allEventVouchersLoading },
  ] = useLazyQuery(GET_ALL_EVENT_TICKETS_QUERY, {
    client: apolloClient,
    fetchPolicy: "network-only",
    variables: { first: MAX_PER_PAGE },
  })

  return {
    checkVoucherCodeMutation,
    callAllVoucherCodes,
    allVouchers: allVouchersData?.allProductVariationTickets?.edges,
    allVouchersLoading,
    callAllEventVoucherCodes,
    allEventVouchersData,
    allEventVouchersLoading,
    allEventTickets: allEventVouchersData?.allEventTickets?.edges,
    checkEventVoucherMutation,
  }
}

export default useVouchers
