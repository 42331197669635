import { gql } from "@apollo/client"

export const AUTHENTICATE_MUTATION = gql`
  mutation Authenticate($input: ObtainJSONWebTokenInput!) {
    tokenAuth(input: $input) {
      success
      errors
      token
      refreshToken
      user {
        id
      }
      unarchiving
      clientMutationId
      refreshToken
    }
  }
`

export const ADD_REFERRAL_CODE = gql`
  mutation AddReferralCode($code: String!) {
    addReferralCode(input: { code: $code }) {
      success
      errors {
        field
        messages
      }
    }
  }
`

export const UPDATE_COMMUNICATION_LANGUAGE = gql`
  mutation UpdateMe($language: String!) {
    updateMe(input: { communicationLanguage: $language }) {
      me {
        id
        communicationLanguage
      }
    }
  }
`

export const VERIFY_TOKEN_MUTATION = gql`
  mutation VerifyToken($token: String!) {
    verifyToken(token: $token) {
      success
      errors
      payload
    }
  }
`

export const REFRESH_TOKEN_MUTATION = gql`
  mutation RefreshToken($input: RefreshTokenInput!) {
    refreshToken(input: $input) {
      success
      token
      refreshToken
      payload
    }
  }
`

export const REGISTER_MUTATION = gql`
  mutation Register($input: RegisterMutationInput!) {
    register(input: $input) {
      success
      token
      refreshToken
      errors
      clientMutationId
    }
  }
`
export const VERIFY_ACCOUNT_MUTATION = gql`
  mutation VerifyAccount($input: VerifyAccountInput!) {
    verifyAccount(input: $input) {
      success
      errors
      clientMutationId
      redirectUrl
    }
  }
`

export const LOGOUT_MUTATION = gql`
  mutation Logout($input: RevokeTokenInput!) {
    revokeToken(input: $input) {
      revoked
      success
      errors
    }
  }
`

export const UPDATE_ME_MUTATION = gql`
  mutation UpdateMe($input: UpdateMeMutationInput!) {
    updateMe(input: $input) {
      me {
        id
        fullName
        displayName
        profileImage
        email
        pacBalance
        city
        country
        region
        postCode
        addressLine1
        addressLine2
        communicationLanguage
        isSubscribed
      }
      errors {
        field
        messages
      }
    }
  }
`
// TODO: Refund option is removed. It will be re-implemented when required.
/* export const CREATE_DONATION_REFUND_MUTATION = gql`
  mutation($input: CreateDonationRefundRequestMutationInput!) {
    createDonationRefundRequest(input: $input) {
      donationRefundRequest {
        id
        donation {
          id
        }
        status
      }
      errors {
        messages
        field
      }
    }
  }
` */

export const CREATE_ORDER_REFUND_MUTATION = gql`
  mutation ($input: CreateCartItemFulfilmentRefundRequestMutationInput!) {
    createCartItemFulfilmentRefundRequest(input: $input) {
      cartItemFulfilmentRefundRequest {
        id
        status
      }
    }
  }
`

export const CREATE_CONTACT_MUTATION = gql`
  mutation ($input: CreateContactMutationInput!) {
    createContact(input: $input) {
      errors {
        field
        messages
      }
      contact {
        id
        contactType
        organizationName
        email
        contactName
        country
        city
        phoneNumber
        addressLine1
        addressLine2
        postalCode
        additionalInfo
        isTermsAndPolicyAccepted
      }
    }
  }
`

export const ORDER_PAYMENT_READY_FAILED_MUTATION = gql`
  mutation ($input: OrderPaymentReadyFailedMutationInput!) {
    orderPaymentReadyFailed(input: $input) {
      errors {
        field
        messages
      }

      order {
        id
        status
        total
      }
    }
  }
`

export const SEND_PASSWORD_RESET_EMAIL = gql`
  mutation SendPasswordResetEmail($email: String!) {
    sendPasswordResetEmail(input: { email: $email }) {
      success
      errors
    }
  }
`

export const RESEND_VERIFICATION_EMAIL = gql`
  mutation ResendVerificationEmail($input: ResendActivationEmailInput!) {
    resendActivationEmail(input: $input) {
      success
      errors
    }
  }
`

export const PASSWORD_RESET = gql`
  mutation PasswordReset($input: PasswordResetInput!) {
    passwordReset(input: $input) {
      success
      errors
    }
  }
`

export const SOCIAL_AUTH_LOGIN = gql`
  mutation socialAuth($input: SocialAuthMutationInput!) {
    socialAuth(input: $input) {
      token
      refreshToken
      isRegistered
      user {
        id
        isTermsAndPolicyAccepted
        email
        accountType
        communicationLanguage
      }
      errors {
        field
        messages
      }
    }
  }
`
export const SOCIAL_AUTH_REGISTER = gql`
  mutation socialRegister($input: SocialRegisterMutationInput!) {
    socialRegister(input: $input) {
      user {
        id
        email
      }
      token
      refreshToken
    }
  }
`
export const NOTIFICATION_MARK_AS_READ_MUTATION = gql`
  mutation notificationMarkAsRead($id: ID!) {
    notificationMarkAsRead(id: $id) {
      notification {
        id
      }
    }
  }
`

export const DELETE_ACCOUNT_MUTATION = gql`
  mutation DeleteAccount {
    forgetMe {
      success
      errors
    }
  }
`

export const PREFERENCES_MUTATION = gql`
  mutation ($input: UserPreferenceMutationInput!) {
    userPreference(input: $input) {
      userPreference {
        id
        campaign {
          id
          name
        }
        user {
          id
        }
        created
        campaignCategories {
          edges {
            node {
              id
              name
            }
          }
        }
        productCategories {
          edges {
            node {
              id
              name
            }
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }
`

export const GENERATE_SUPPORTER_REPORT_MUTATION = gql`
  mutation ($input: GenerateSupporterReportMutationInput!) {
    supporterReport(input: $input) {
      supporterReport {
        id
        report
        requestedUser {
          fullName
        }
      }
      errors {
        field
        messages
      }
    }
  }
`

export const CANCEL_SUBSCRIPTION_MUTATION = gql`
  mutation ($input: CancelSubscriptionMutationInput!) {
    cancelSubscription(input: $input) {
      errors {
        field
        messages
      }
      subscription {
        id
        status
        canceledAt
        campaign {
          id
          name
        }
        user {
          id
          fullName
        }
      }
    }
  }
`
export const CREATE_MERCHANT_PAC_RECONCILIATION_MUTATION = gql`
  mutation ($input: CreateMerchantPacReconciliationMutationInput!) {
    createMerchantPacReconciliation(input: $input) {
      merchantPacReconciliation {
        id
        campaign {
          id
          name
          slug
        }
        offer {
          id
          name
          slug
        }
        user {
          id
          fullName
        }
        transaction {
          id
        }
        status
        strategy
        currency
        totalAmount
        offerMaxPacDiscount
        totalPacsRequired
        donationAmount
        normalizedDonationAmount
        pacMultiplierSurplus
        netEarnedPacsAfterDonation
        userPacBalanceAfterPurchase
        purchaseAmountAfterDiscount
        pacAmountAfterNormalizedDonation
        userEmailStatus
        storeEmailStatus
      }
      errors {
        field
        messages
      }
    }
  }
`

export const UPDATE_MERCHANT_PAC_RECONCILIATION_MUTATION = gql`
  mutation ($input: UpdateMerchantPacReconciliationMutationInput!) {
    updateMerchantPacReconciliation(input: $input) {
      merchantPacReconciliation {
        id
        campaign {
          id
          name
          slug
        }
        offer {
          id
          name
          slug
        }
        user {
          id
          fullName
        }
        transaction {
          id
        }
        status
        strategy
        currency
        totalAmount
        offerMaxPacDiscount
        totalPacsRequired
        donationAmount
        normalizedDonationAmount
        pacMultiplierSurplus
        netEarnedPacsAfterDonation
        userPacBalanceAfterPurchase
        pacAmountAfterNormalizedDonation
        purchaseAmountAfterDiscount
        userEmailStatus
        storeEmailStatus
      }
      errors {
        field
        messages
      }
    }
  }
`
