import styled from "@emotion/styled"
import { css } from "@emotion/react"

export const StyledPageContainer = styled.div``

export const StyledCampaignsSection = styled.div`
  .blog-joining-wrapper {
    margin: unset;
  }

  margin: 4rem auto 0;

  > div {
    margin-bottom: 3rem;
  }
  div .swiper {
    margin: 0;
    padding: 0.5rem 0.5rem 0.3rem 1rem;
  }
  .swiper-horizontal > .swiper-pagination-horizontal {
    bottom: -1.5rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin: 4.5rem auto 0;
    padding: 0 1.5rem;
    max-width: ${(p) => p.theme.breakpoints.tablet};
    div .swiper {
      padding-left: 0.15rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin: 6.375rem auto 0;
    padding: 0 2rem;
    max-width: ${(p) => p.theme.breakpoints.desktop};
    > div {
      margin-bottom: 5rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin: 7.375rem auto 0;
    padding: 0 6rem;
    max-width: ${(p) => p.theme.breakpoints.wide};
    > div {
      margin-bottom: 6rem;
    }
  }
`

export const StyledOffersSection = styled.div`
  background: ${(p) => p.theme.colors.whiteSmoke};
  padding-top: 4rem;
  padding-bottom: 5rem;
  .swiper {
    margin-bottom: 3rem;
  }
  .swiper .swiper-slide {
    width: 100% !important;
    > div {
      border-radius: 0;
    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }
  .swiper-horizontal > .swiper-pagination-bullets {
    bottom: -1.5rem;
  }
  .hide-chevron {
    .chevron {
      display: none;
    }
  }
  .slice-offers {
    svg {
      color: ${(p) => p.theme.colors.carrot};
    }
  }
  > h3 {
    padding: 0 1rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    max-width: ${(p) => p.theme.breakpoints.tablet};
    margin: auto;
    .chevron {
      height: 23.5rem;
    }
    .chevron {
      background: linear-gradient(
        270deg,
        #f4f4f2 42.13%,
        rgba(244, 244, 242, 0) 100%
      );
    }
    .chevron-left {
      background: linear-gradient(
        90deg,
        #f4f4f2 42.13%,
        rgba(244, 244, 242, 0) 100%
      );
    }

    .swiper .swiper-slide {
      width: auto !important;
      > div {
        border-radius: 0.25rem;
      }
    }
    > h3,
    > div {
      padding: 0 1.5rem;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    max-width: ${(p) => p.theme.breakpoints.desktop};
    > h3,
    > div {
      padding: 0 2rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    max-width: ${(p) => p.theme.breakpoints.wide};
    .swiper-slide {
      margin-right: 1.5rem;
      > div {
        border-radius: 0.5rem;
      }
    }
    .chevron {
      height: 39.5rem;
      svg {
        height: 3.375rem;
        width: 3.375rem;
        padding: 0.8rem;
        box-sizing: border-box;
      }
    }
    > h3,
    > div {
      padding: 0 6rem;
    }
  }
`

export const StyledCenteredHeadline = styled.div`
  display: block;
  text-align: center;
  position: relative;
  margin-bottom: 1rem;
  h3 {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-size: 1.5rem;
    line-height: 2rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin: 0 auto 1.5rem;
    h3 {
      font-size: 2rem;
      line-height: 2.625rem;
    }
    > p {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-40%);
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    > p {
      right: 4.0625rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin-bottom: 2.5rem;
    margin-top: -1.9375rem;
    h3 {
      font-size: 3rem;
      line-height: 4.0625rem;
    }
    > p {
      font-size: 2rem;
      right: 5.8125rem;

      img {
        width: 2rem;
        height: 2rem;
      }
    }
  }
`

export const StyledSliceHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 0 1rem;

  div {
    width: 100%;
    text-align: right;
  }

  h3 {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    color: ${(p) => p.theme.colors.black};
    max-width: 53vw;
    word-break: break-word;
    margin-right: 0.3rem;
  }

  .link-button-text span {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-size: 1rem;
    line-height: 1.5rem;
  }

  ${(p) =>
    p.carrot &&
    css`
      button {
        text-align: right;
      }
      p.link-button-text {
        color: ${p.theme.colors.carrot};
        max-width: 42vw;
      }
    `}

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 0.1875rem 0 0;
    h3 {
      font-size: 1.5rem;
      line-height: 2rem;
    }
    .link-button-text span {
      font-family: ${(p) => p.theme.fontFamilies.headline};
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    padding-top: 0.0625rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding-top: 1.5rem;
    h3 {
      font-size: 2rem;
      line-height: 2.625rem;
    }
  }
`

export const StyledHeadlineLeft = styled.h3`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 0 auto 1.5rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 2rem;
    line-height: 2.875rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin-bottom: 2rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 3rem;
    line-height: 4.0625rem;
    margin-bottom: 3.5rem;
  }
`
