import React, { useEffect, useState } from "react"
import * as Yup from "yup"
import { FormattedMessage, navigate, useIntl } from "gatsby-plugin-intl"
import { Formik, Form } from "formik"
import { addDays } from "date-fns"
import { useLocation } from "@reach/router"
import { useQuery, useLazyQuery, useMutation } from "@apollo/client"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons/faArrowLeft"
import { useApolloApiClients } from "@tmu/apollo/client"
import { useAuth, useDefaultMerchant, useToast } from "@tmu/hooks"
import {
  Button,
  Checkbox,
  DatePicker,
  Dropdown,
  OnSubmitValidationError,
  Spinner,
  TextInput,
} from "@tmu/components/common"
import { ReferralCodeModal } from "../ReferralCodeModal"
import { getDateWithZeroHours } from "@tmu/utils/string"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import {
  fieldRequired,
  referralEmail,
  campaignConditions,
  offerConditions,
  referralName,
  maxReferralNumber,
} from "@tmu/utils/validation"
import { MAX_PER_PAGE } from "@tmu/apollo/constants"
import {
  USER_TRACKING_REFERRAL_WITH_CAMPAIGN_CONDITIONS_QUERY,
  USER_TRACKING_REFERRAL_WITH_OFFER_CONDITIONS_QUERY,
  CAMPAIGN_LISTING_FOR_CONDITIONS_QUERY,
  PARTNER_CAMPAIGN_LISTING_FOR_CONDITIONS_QUERY,
  OFFER_LISTING_FOR_CONDITIONS_QUERY,
} from "@tmu/apollo/dashboard/queries/referrals"

import {
  CREATE_USER_TRACKING_REFERRAL_WITH_CAMPAIGN_CONDITIONS_MUTATION,
  CREATE_USER_TRACKING_REFERRAL_WITH_OFFER_CONDITIONS_MUTATION,
  UPDATE_USER_TRACKING_REFERRAL_WITH_CAMPAIGN_CONDITIONS_MUTATION,
  UPDATE_USER_TRACKING_REFERRAL_WITH_OFFER_CONDITIONS_MUTATION,
} from "@tmu/apollo/dashboard/mutations/referrals"

import {
  StyledWrapper,
  StyledHeader,
  StyledTitle,
  StyledLabel,
  StyledFlexRow,
  StyledButtonContainer,
} from "./index.styles"

const ReferralCodeForm = ({ dashboardType, id }) => {
  const { pathname } = useLocation()
  const { formatMessage } = useIntl()
  const { getDashboardClient } = useApolloApiClients()
  const { isLoading, user: currentUser } = useAuth()
  const { success: successToast, error: errorToast } = useToast()
  const {
    isMerchantCorporate,
    isMerchantInternal,
    isMerchantOffline,
    isMerchantOnline,
  } = useDefaultMerchant({
    skip: isLoading || !currentUser?.isMerchant,
  })
  const { isTablet, isWide } = getAllScreenTypes()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalType, setModalType] = useState("")
  const [maxUsage, setMaxUsage] = useState()
  const client = getDashboardClient(dashboardType)
  const isEdit = pathname.includes("edit")
  const isMerchant = dashboardType === "merchants"
  const referralCodeLinkPrefix =
    dashboardType === "partners" ? "charities" : dashboardType
  const isCampaign = isMerchant
    ? isMerchantCorporate || isMerchantOffline
    : true

  let variables = { first: MAX_PER_PAGE }

  if (dashboardType === "merchants") {
    if (isMerchantInternal) {
      variables = {
        ...variables,
        isPublic: false,
        offerType_In: ["INTERNAL"],
        offset: 0,
      }
    }

    if (isMerchantOnline) {
      variables = {
        ...variables,
        isPublic: false,
      }
    }

    if (isMerchantCorporate || isMerchantOffline) {
      variables = {
        ...variables,
        startsAt_Lte: getDateWithZeroHours(addDays(new Date(), 1)),
        status_In: ["APPROVED"],
        visibilityStatus: ["1", "2", "3"],
      }
    }
  } else if (dashboardType === "donors") {
    variables = {
      ...variables,
      user: currentUser.id,
      startsAt_Lte: getDateWithZeroHours(addDays(new Date(), 1)),
      status_In: ["APPROVED"],
      visibilityStatus: ["1", "2", "3"],
    }
  } else if (dashboardType === "partners") {
    variables = {
      ...variables,
      fundraiserType: ["campaigns", "events"],
      visibilityStatus: ["1", "2", "3"],
    }
  }

  const CONDITION_LISTING_QUERY = isCampaign
    ? dashboardType === "partners"
      ? PARTNER_CAMPAIGN_LISTING_FOR_CONDITIONS_QUERY
      : CAMPAIGN_LISTING_FOR_CONDITIONS_QUERY
    : OFFER_LISTING_FOR_CONDITIONS_QUERY

  const { loading: conditionsLoading, data: conditionsData } = useQuery(
    CONDITION_LISTING_QUERY,
    {
      client,
      variables,
      fetchPolicy: "network-only",
    }
  )

  const LISTING_QUERY = isCampaign
    ? USER_TRACKING_REFERRAL_WITH_CAMPAIGN_CONDITIONS_QUERY
    : USER_TRACKING_REFERRAL_WITH_OFFER_CONDITIONS_QUERY

  const [callReferralCode, { loading, data }] = useLazyQuery(LISTING_QUERY, {
    client,
    variables: { id },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data?.userTrackingReferral?.id)
        setMaxUsage(data?.userTrackingReferral?.maxUsage)
    },
  })

  useEffect(() => {
    if (isEdit && id !== undefined) {
      callReferralCode()
    }
  }, [])

  const getConditionsNode = (edges) =>
    edges?.map(({ node }) => ({
      value: node?.id,
      label: node?.name,
    })) || []

  const conditions = isCampaign
    ? dashboardType === "partners"
      ? [
          ...getConditionsNode(conditionsData?.default?.edges),
          ...getConditionsNode(conditionsData?.allCampaigns?.edges),
        ]
      : getConditionsNode(conditionsData?.allCampaigns?.edges)
    : getConditionsNode(
        conditionsData?.allOffers?.edges.filter(
          (item) => !item.node.isDefaultOffer
        )
      )

  const conditionText = isCampaign ? (
    <FormattedMessage
      id="dashboard::tableHeader::campaign"
      defaultMessage="Campaign"
    />
  ) : (
    <FormattedMessage
      id="dashboard::tableHeader::offer"
      defaultMessage="Offer"
    />
  )

  const CREATE_MUTATION = isCampaign
    ? CREATE_USER_TRACKING_REFERRAL_WITH_CAMPAIGN_CONDITIONS_MUTATION
    : CREATE_USER_TRACKING_REFERRAL_WITH_OFFER_CONDITIONS_MUTATION

  const [callCreateReferralMutation] = useMutation(CREATE_MUTATION, {
    client,
    fetchPolicy: "network-only",
  })

  const UPDATE_MUTATION = isCampaign
    ? UPDATE_USER_TRACKING_REFERRAL_WITH_CAMPAIGN_CONDITIONS_MUTATION
    : UPDATE_USER_TRACKING_REFERRAL_WITH_OFFER_CONDITIONS_MUTATION

  const [callUpdateReferralMutation] = useMutation(UPDATE_MUTATION, {
    client,
    fetchPolicy: "network-only",
  })

  const unknownError = formatMessage({
    id: "dashboard::campaignForm::errorMessage",
    defaultMessage: "An error occurred",
  })

  const errorHandler = (errors) => {
    if (!errors) return

    if (Array.isArray(errors)) {
      errors.forEach((err) => {
        errorToast(
          (err?.field
            ? err.field + " : " + err.messages?.[0]
            : err.messages?.[0]) ||
            err.message ||
            unknownError
        )
      })
    } else errorToast(errors?.message ?? unknownError)
  }

  const getConditionId = isCampaign
    ? data?.userTrackingReferral?.conditions?.edges?.map(
        ({ node }) => node?.campaign?.id
      )?.[0]
    : data?.userTrackingReferral?.conditions?.edges?.map(
        ({ node }) => node?.offer?.id
      )?.[0]

  const initialValues = {
    id: data?.userTrackingReferral?.id,
    code: data?.userTrackingReferral?.code,
    condition: getConditionId,
    name: data?.userTrackingReferral?.name,
    usageCount: data?.userTrackingReferral?.usageCount,
    maxUsage: data?.userTrackingReferral?.maxUsage,
    isActive: data?.userTrackingReferral?.isActive,
    isUnlimited: data?.userTrackingReferral?.isUnlimited || false,
    referralUrl: data?.userTrackingReferral?.referralUrl,
    relatedId: data?.userTrackingReferral?.relatedId,
    validUntil: data?.userTrackingReferral?.validUntil
      ? new Date(data?.userTrackingReferral?.validUntil)
      : new Date(new Date().setMonth(new Date().getMonth() + 1)),
  }

  const validationSchema = Yup.object().shape({
    name: referralName({ formatMessage }),
    relatedId: referralEmail({ formatMessage }),
    maxUsage: maxReferralNumber({ formatMessage }),
    condition: isCampaign
      ? campaignConditions({ formatMessage })
      : offerConditions({ formatMessage }),
  })

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true)

    if (isEdit) {
      callUpdateReferralMutation({
        variables: {
          input: {
            id: values?.id,
            name: values?.name,
            maxUsage: values.isUnlimited ? 1 : values?.maxUsage,
            isUnlimited: values?.isUnlimited,
            validUntil: values?.validUntil,
            isActive: values?.isActive,
            relatedId: values?.relatedId,
            ...(isCampaign
              ? { campaign: values?.condition }
              : { offer: values?.condition }),
          },
        },
      })
        .then(({ data }) => {
          if (
            data?.updateUserTrackingReferral?.errors &&
            data?.updateUserTrackingReferral?.errors?.length
          ) {
            errorHandler(data?.updateUserTrackingReferral?.errors)
          } else if (data?.updateUserTrackingReferral?.referral?.id) {
            successToast(
              formatMessage({
                id: "dashboard::referralCodes::referralCodeUpdated",
                defaultMessage: "The referral code is updated successfully",
              })
            )
          }
        })
        .catch((err) => {
          errorHandler(err)
        })
        .finally(() => {
          setSubmitting(false)
        })
    } else {
      callCreateReferralMutation({
        variables: {
          input: {
            name: values?.name,
            maxUsage: values.isUnlimited ? 1 : values?.maxUsage,
            isUnlimited: values?.isUnlimited,
            validUntil: values?.validUntil,
            relatedId: values?.relatedId,
            ...(isCampaign
              ? { campaign: values?.condition }
              : { offer: values?.condition }),
          },
        },
      })
        .then(({ data }) => {
          if (
            data?.createUserTrackingReferral?.errors &&
            data?.createUserTrackingReferral?.errors?.length
          ) {
            errorHandler(data?.createUserTrackingReferral?.errors)
          } else if (data?.createUserTrackingReferral?.referral?.id) {
            successToast(
              formatMessage({
                id: "dashboard::referralCodes::referralCodeCreated",
                defaultMessage: "The referral code is created successfully",
              })
            )
          }
        })
        .catch((err) => {
          errorHandler(err)
        })
        .finally(() => {
          setSubmitting(false)
        })
    }

    setTimeout(() => {
      navigate(`/dashboard/${referralCodeLinkPrefix}/referral-codes/`)
    }, 3000)
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  const handleModalAction = () => {
    navigate(`/dashboard/${referralCodeLinkPrefix}/referral-codes`)
  }

  return loading ? (
    <Spinner condensed />
  ) : (
    <StyledWrapper>
      <StyledHeader>
        <div>
          <FontAwesomeIcon
            icon={faArrowLeft}
            onClick={() => {
              setModalType("leave")
              setIsModalOpen(true)
            }}
          />
        </div>
        <StyledTitle data-testid="title">
          {isEdit ? (
            <FormattedMessage
              id="dashboard::referralCodeForm::updateReferralCode"
              defaultMessage="Update referral code"
            />
          ) : (
            <FormattedMessage
              id="dashboard::referralCodeForm::newReferralCode"
              defaultMessage="New referral code"
            />
          )}
        </StyledTitle>
      </StyledHeader>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}>
        {({
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          setValues,
          setTouched,
        }) => {
          return (
            <>
              <OnSubmitValidationError />
              <Form className="referral-code-form">
                <fieldset>
                  <StyledLabel className="top-align">
                    <FormattedMessage
                      id="dashboard::referralCodeForm::referralCodeName"
                      defaultMessage="Referral code name"
                    />
                  </StyledLabel>
                  <TextInput
                    newDesign
                    data-testid="input-name"
                    id="name"
                    name="name"
                    placeholder={formatMessage({
                      id: "dashboard::referralCodeForm::referralCodeNamePlaceholder",
                      defaultMessage: "Name your referral code",
                    })}
                    value={values?.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={errors?.name}
                    touched={touched?.name}
                    autoComplete="off"
                  />
                  <StyledLabel className="top-align">
                    <FormattedMessage
                      id="dashboard::referralCodeForm::emailOfReferral"
                      defaultMessage="Email of referral"
                    />
                  </StyledLabel>
                  <TextInput
                    newDesign
                    data-testid="input-related-id"
                    id="relatedId"
                    name="relatedId"
                    placeholder={formatMessage({
                      id: "dashboard::referralCodeForm::emailOfReferralPlaceholder",
                      defaultMessage: "Referral's Email",
                    })}
                    value={values?.relatedId}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={errors?.relatedId}
                    touched={touched?.relatedId}
                    autoComplete="off"
                  />
                  <StyledLabel className="top-align">
                    <FormattedMessage
                      id="dashboard::referralCodeForm::validUntil"
                      defaultMessage="Valid until"
                    />
                  </StyledLabel>
                  <StyledFlexRow
                    flexDirection="row"
                    className={isTablet ? "valid-until" : "mobile-valid-until"}>
                    <DatePicker
                      dataTestId="date-picker-valid-until"
                      placeholder={formatMessage({
                        id: "dashboard::referralCodeForm::validUntilDatePlaceholder",
                        defaultMessage: "Date",
                      })}
                      onDateChange={(date) => {
                        setValues({
                          ...values,
                          validUntil: date ?? new Date(),
                        })
                      }}
                      selected={values?.validUntil}
                      startDate={values?.validUntil}
                      minDate={new Date()}
                    />
                    <div>
                      <DatePicker
                        dataTestId="time-picker-valid-until"
                        placeholder={formatMessage({
                          id: "dashboard::referralCodeForm::validUntilTimePlaceholder",
                          defaultMessage: "Time",
                        })}
                        selected={values?.validUntil}
                        onDateChange={(date) => {
                          const validUntil = values?.validUntil
                          const validStarts = date
                          validUntil.setHours(validStarts?.getHours())
                          validUntil.setMinutes(validStarts?.getMinutes())
                          validUntil.setSeconds(validStarts?.getSeconds())

                          setValues({
                            ...values,
                            validUntil: validStarts,
                          })
                        }}
                        showTimeSelect
                        showTimeSelectOnly
                      />
                    </div>
                  </StyledFlexRow>
                  <StyledLabel className="top-align">
                    <FormattedMessage
                      id="dashboard::referralCodeForm::maxUsage"
                      defaultMessage="Max usage"
                    />
                  </StyledLabel>
                  <StyledFlexRow flexDirection="column">
                    <TextInput
                      newDesign
                      data-testid="input-max-usage"
                      id="maxUsage"
                      name="maxUsage"
                      placeholder={formatMessage({
                        id: "dashboard::referralCodeForm::maxUsagePlaceholder",
                        defaultMessage: "Enter a number",
                      })}
                      value={values?.maxUsage || ""}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={errors?.maxUsage}
                      touched={touched?.maxUsage}
                      type="number"
                      min={0}
                      label=""
                      disabled={!!values?.isUnlimited}
                    />
                    <Checkbox
                      id="unlimited"
                      name="unlimited"
                      label={formatMessage({
                        id: "dashboard::referralCodeForm::unlimited",
                        defaultMessage: "Unlimited code usage",
                      })}
                      className="unlimited"
                      onChange={(e) => {
                        setValues({
                          ...values,
                          isUnlimited: !values?.isUnlimited,
                          maxUsage: null,
                        })
                        handleChange(e)
                      }}
                      isChecked={values?.isUnlimited}
                    />
                  </StyledFlexRow>
                  <StyledLabel className="top-align">
                    {conditionText}
                  </StyledLabel>
                  <Dropdown
                    newDesign
                    data-testid="conditions"
                    id="condition"
                    name="condition"
                    placeholder={
                      isCampaign
                        ? formatMessage({
                            id: "dashboard::referralCodeForm::chooseCampaign",
                            defaultMessage: "Choose campaign",
                          })
                        : formatMessage({
                            id: "dashboard::referralCodeForm::chooseOffer",
                            defaultMessage: "Choose offer",
                          })
                    }
                    defaultValue={values?.condition}
                    value={conditions?.find(
                      ({ value }) => values?.condition === value
                    )}
                    options={conditions}
                    defaultSelectStyles={{
                      control: {
                        height: isWide ? "3.375rem" : "3rem",
                        paddingTop: "0",
                        paddingRight: "0",
                      },
                      valueContainer: {
                        height: isWide ? "3.375rem" : "3rem",
                        paddingTop: "0",
                      },
                    }}
                    isClearable={true}
                    onBlur={() => setTouched({ ...touched, condition: true })}
                    onChange={(condition) => {
                      setValues({
                        ...values,
                        condition: condition?.value,
                      })
                    }}
                    error={values?.condition ? null : errors?.condition}
                    touched={touched?.condition}
                  />
                </fieldset>
                <StyledButtonContainer>
                  {id ? (
                    <>
                      <Button
                        data-testid="btn-leave"
                        className="hide-on-mobile"
                        color="transparent"
                        type="button"
                        onClick={() => {
                          setModalType("leave")
                          setIsModalOpen(true)
                        }}>
                        <FormattedMessage
                          id="dashboard::campaign::leave"
                          defaultMessage="Leave without saving"
                        />
                      </Button>
                      <Button
                        data-testid="btn-submit"
                        className={id ? "" : "full-width"}
                        color="carrot"
                        type="submit">
                        <FormattedMessage
                          id="dashboard::referralCodeForm::saveCode"
                          defaultMessage="Save code"
                        />
                      </Button>
                    </>
                  ) : (
                    <Button
                      data-testid="btn-submit"
                      className={id ? "" : "full-width"}
                      color={dashboardType === "merchants" ? "carrot" : "blue"}
                      type="submit">
                      <FormattedMessage
                        id="dashboard::referralCodeForm::createReferralCode"
                        defaultMessage="Create referral code"
                      />
                    </Button>
                  )}
                </StyledButtonContainer>
              </Form>
              {isModalOpen && (
                <ReferralCodeModal
                  {...{
                    isModalOpen,
                    modalType,
                    handleModalAction,
                    handleModalClose,
                  }}
                />
              )}
            </>
          )
        }}
      </Formik>
    </StyledWrapper>
  )
}

export default ReferralCodeForm
