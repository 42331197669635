import React, { useMemo } from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import { LazyImage } from "@tmu/components/common"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import {
  StyledMainContainer,
  StyledLeftContainer,
  StyledLeftTitle,
  StyledLeftContent,
  StyledRightContainer,
} from "./index.styles"

const { CLOUDFLARE_IMAGE_URL } = process.env

const FundraiseHero = () => {
  const { isTablet, isDesktop, isWide } = getAllScreenTypes()

  const imageSize = {
    width: isWide ? 1157 : isDesktop ? 497 : isTablet ? 547 : 720,
    height: isWide ? 528 : isDesktop ? 428 : isTablet ? 301 : 666,
  }

  const getImageUrl = useMemo(() => {
    const url = CLOUDFLARE_IMAGE_URL + "/static/assets/images/blue-balloons"
    return isWide
      ? `${url + "_1920.png"}`
      : isDesktop
      ? `${url + "_1024.png"}`
      : isTablet
      ? `${url + "_768.png"}`
      : `${url + "_360.png"}`
  }, [isTablet, isDesktop, isWide])

  return (
    <StyledMainContainer>
      <StyledLeftContainer>
        <StyledLeftTitle>
          <FormattedMessage
            id="fundraise::hero::title"
            defaultMessage="Fundraise for a charity of your choice"
          />
        </StyledLeftTitle>
        <StyledLeftContent>
          <FormattedMessage
            id="fundraise::hero::content"
            defaultMessage="You are having a birthday? Marriage, or other good cause? Now you can use your cause to support your favourite charity, by creating a fundraise campaign"
          />
        </StyledLeftContent>
      </StyledLeftContainer>
      <StyledRightContainer>
        {!isTablet && <div className="image-linear-gradient"></div>}
        <LazyImage
          src={getImageUrl}
          altName={`blue-balloons-banner`}
          {...imageSize}
          fit="cover"
        />
      </StyledRightContainer>
    </StyledMainContainer>
  )
}

export default FundraiseHero
