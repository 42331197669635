import React from "react"
import { ContactForm } from "@tmu/components/forms"
import {
  StyledForm,
  StyledFormWrapper,
  StyledTextWrapper,
} from "@tmu/global/page-addons/how-it-works.styles"

const HowItWorksForm = ({
  contactRef,
  title,
  desc,
  sender,
  termsLink = "/terms/donor-terms",
  corporate,
  ...rest
}) => {
  const TextWrapper = corporate ? StyledTextWrapper : React.Fragment
  return (
    <StyledFormWrapper corporate={!!corporate}>
      <StyledForm data-testid="contact-form">
        <TextWrapper>
          {title}
          {desc}
        </TextWrapper>
        {sender && (
          <ContactForm sender={sender} termsLink={termsLink} {...rest} />
        )}
      </StyledForm>
    </StyledFormWrapper>
  )
}

export default HowItWorksForm
