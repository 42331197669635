import styled from "@emotion/styled"
import { Link } from "gatsby-plugin-intl"

export const StyledWrapper = styled.div`
  position: relative;
  margin: 5rem auto;

  height: 100%;

  .blue {
    color: ${(p) => p.theme.colors.blue};
  }
  .carrot {
    color: ${(p) => p.theme.colors.carrot};
  }

  .clickable {
    cursor: pointer;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    max-width: ${(p) => p.theme.breakpoints.tablet};
    padding: 0 1.563rem;
    margin: 5rem auto;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    max-width: ${(p) => p.theme.breakpoints.desktop};
    padding: 0 2.25rem 0 5.6875rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    grid-template-columns: 25.875rem auto;
    margin: 7.5rem auto;
    max-width: 90rem;
  }

  .grey {
    color: ${(p) => p.theme.colors.concreteGrey};

    img {
      filter: grayscale(100%);
      color: ${(p) => p.theme.colors.concreteGrey};
    }
  }
`

export const StyledSupportCheckoutForm = styled.div`
  padding: 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 0 2rem;
  }

  & > p:first-of-type {
    color: rgba(99, 106, 117, 1);
    font-size: 1rem;
    line-height: 1rem;
    display: inline-block;
  }

  .gray-background {
    background-color: rgba(244, 244, 242, 1);
    margin: 1.5rem -1rem 0;
    padding: 1rem 1rem 1rem;
  }
`

export const StyledSupportCompanyDetails = styled.div`
  padding: 1.5rem 1rem 1rem;
  & > p {
    color: ${(p) => p.theme.colors.gondola};
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
  > p:nth-of-type(1) {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-size: 1.125rem;
    line-height: 1.5rem;
    letter-spacing: 0.01em;
    color: ${(p) => p.theme.colors.black};
    margin-bottom: 0.5rem;
  }
  > p:nth-of-type(2) {
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    font-size: 0.75rem;
    line-height: 1rem;
    color: ${(p) => p.theme.colors.black};
    margin-top: 1rem;
  }

  > span:last-of-type {
    display: block;
    margin-top: 1rem;
  }

  .campaign-image {
    width: 100%;
    margin: 1rem 0 2rem;
  }
  .rc-progress-line {
    margin-top: 1.5rem;
  }
`

export const StyledFAQSection = styled.div`
  margin-top: 2.5rem;
  padding-left: 1rem;
  & > p {
    color: ${(p) => p.theme.colors.gondola};
    font-size: 1rem;
    font-weight: ${(p) => p.theme.fontWeights.bold};
    line-height: 1.1875rem;
  }
  ul {
    color: ${(p) => p.theme.colors.disabled};
    padding: 1.25rem 0 0 0.625rem;
    list-style-type: disc;
    list-style-position: inside;

    li {
      margin-bottom: 0.625rem;
    }
  }
`

export const StyledFAQQuestion = styled.h5`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-weight: ${(p) => p.theme.fontWeights.regular};
  font-size: 0.95rem;
`
export const StyledFAQAnswer = styled.p`
  padding: 0.5rem;
  font-size: 0.85rem;
  color: ${(p) => p.theme.colors.shuttleflGray};
  line-height: 1.4;
`
export const StyledLink = styled(Link)`
  &,
  &:hover,
  &:visited,
  &.active {
    color: ${(p) => p.theme.colors.blue} !important;
    font-weight: ${(p) => p.theme.fontWeights.bold};
  }
`

export const StyledFormTitle = styled.h1`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-size: 1.5rem;
  line-height: 2.625rem;
  color: ${(p) => p.theme.colors.black};
  margin-bottom: 0.75rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-bottom: 2rem;
    font-size: 2rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 2rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin-bottom: 2.5rem;
    font-size: 3rem;
  }
`

export const StyledBreakWord = styled.span`
  word-break: break-word;
`

export const StyledFormSubTitle = styled.h2`
  margin-bottom: 1rem;
  font-size: 1.125rem;
  line-height: 1.5rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
    line-height: 2.625rem;
  }
`

export const StyledDonationTierTitle = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-weight: ${(p) => p.theme.fontWeights.bold};
  font-size: 1rem;
  line-height: 1rem;
  max-width: ${(p) => p.theme.breakpoints.wide};
  margin: 0 0 1.3rem 1rem;
`

export const StyledBackButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: -1rem;
  left: 1rem;
  font-size: 1rem;
  color: ${(p) => p.theme.colors.black};
  margin-top: 0;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    left: 1.5rem;
    color: ${(p) => p.theme.colors.softGreen};
    margin-top: 1.7rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    left: 2rem;
    margin-top: 1.6rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    left: -9rem;
    font-size: 1.5rem;
    margin-top: 1.6rem;
  }
`
export const StyledCampaignCardPreview = styled.div`
  display: none;
  margin-top: 4.8rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: block;
  }
`

export const StyledGoallessCampaignStats = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1.5rem;
  margin-top: 0.675rem;

  > div {
    width: 100%;
  }

  p {
    text-transform: uppercase;
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-size: 0.75rem !important;
    font-weight: ${(p) => p.theme.fontWeights.black};
    color: ${(p) => p.theme.colors.disabled};
  }

  .right {
    text-align: right;
  }

  .left {
    text-align: left;
  }

  span.fund {
    display: block;
    font-size: 1.25rem !important;
    margin-bottom: 0;
    color: ${(p) => p.theme.colors.primaryBrandTextColor};
  }

  .goal {
    margin-bottom: 0;
  }

  .rc-progress-line {
    margin-top: 1rem;
  }
`
export const StyledEventTimeInfo = styled.div`
  margin: 1rem 0 0;
  > span {
    display: block;
  }
`
export const StyledPartnerLogo = styled.img`
  object-fit: contain;
  border-radius: 0.25rem;
  height: 1.5rem;
  margin-right: 0.625rem;
`

export const StyledPartnerTitle = styled(Link)`
  display: flex;
  margin-top: 0.2rem;
`

export const StyledPreviewCardSubHeader = styled.span`
  text-transform: uppercase;
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-size: 0.625rem;
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.black};
  color: ${(p) => p.theme.colors.disabled};
  line-height: 1rem;
  letter-spacing: 0.04em;
  strong {
    color: ${(p) => p.theme.colors.black};
  }
`

export const StyledPartnerTitleText = styled.p`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.colors.black};
`
export const StyledDescription = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 2.0625rem;

  h1,
  p,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 0.75rem;
  }
`
export const StyledYourDiscountTextTitle = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-style: ${(p) => p.theme.fontStyles.normal};
  color: ${(p) => p.theme.colors.pitchBlack};
  font-size: 1.125rem;
  line-height: 1.75rem;

  img {
    height: 1rem;
    width: 1rem;
    margin-right: 0.2rem;
    padding-top: 0.1875rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1.5rem;
    line-height: 2.125rem;

    img {
      height: 1.3rem;
      width: 1.3rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
    line-height: 2.75rem;

    img {
      height: 1.65rem;
      width: 1.65rem;
    }
  }

  .inline {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-style: ${(p) => p.theme.fontStyles.normal};
  }
`

export const StyledYourDiscountTextDescription = styled.div`
  font-size: 0.75rem;
  line-height: 1.25rem;
  margin-top: 0.75rem;

  font-style: normal;
  font-weight: 400;
  color: ${(p) => p.theme.colors.pitchBlack};

  .blue {
    color: ${(p) => p.theme.colors.blue};
  }

  img {
    height: 0.625rem;
    width: 0.625rem;
    margin-right: 0.2rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1rem;
    line-height: 1.5rem;

    img {
      height: 0.8rem;
      width: 0.8rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin-top: 1rem;

    font-size: 1.5rem;
    line-height: 2.25rem;

    img {
      height: 1.3rem;
      width: 1.3rem;
    }
  }
`

export const StyledFavoriteCharityGrid = styled.div`
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  margin-top: 0;

  .caption {
    font-size: 0.5rem;
    line-height: 0.75rem;
    margin-bottom: 0.5rem;
  }

  .blue {
    cursor: pointer;
  }
  > div {
    display: flex;
    justify-content: space-between;
  }
  span {
    display: flex;
    align-items: top;
    gap: 1.2rem;
    flex-wrap: wrap;
  }

  .image-wrapper {
    display: none;
  }

  .text {
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  }

  .mobile-charity-name,
  .mobile-pick-another {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-size: 0.75rem;
    line-height: 1.25rem;
  }

  .mobile-amount {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-size: 0.75rem;
    line-height: 1rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-top: 2rem;

    .caption {
      font-size: 0.625rem;
      line-height: 1rem;
    }
    > div {
      border-bottom: 0.0063rem solid ${(p) => p.theme.colors.lightGrey};
    }
    > div.extra {
      padding: 1.5rem 0;
    }
    span {
      align-items: center;
    }
    .image-wrapper {
      display: block;
    }
    img {
      border-radius: 0.25rem;
      height: auto;
      width: auto;
      max-width: 6.25rem;
      max-height: 3.4375rem;
    }
    .image-wrapper {
      width: 6.25rem;
      justify-content: center;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin-top: 2.5rem;

    font-size: 1.5rem;
    .caption {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    img {
      max-width: 10rem;
      max-height: 5.5rem;
    }
    .image-wrapper {
      width: 10rem;
    }

    .mobile-charity-name,
    .mobile-pick-another,
    .mobile-amount {
      font-size: 1.25rem;
      line-height: 1.55rem;
    }
  }
`
export const StyledPurchaseGrid = styled.div`
  margin-top: 2rem;
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-weight: ${(p) => p.theme.fontWeights.regular};
  .caption {
    font-size: 0.5rem;
    line-height: 0.75rem;
    margin-bottom: 0.5rem;
  }
  .right {
    text-align: right;
    justify-content: end;
  }
  > div {
    display: grid;
    grid-template-columns: auto 7.5rem 4.375rem 9.6875rem 7.5rem 9.0625rem;
    grid-column-gap: 1.5rem;
    padding: 1rem 0;
  }
  span {
    display: flex;
    align-items: center;
    line-break: auto;
  }

  img {
    display: none;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-top: 1.5rem;

    .caption {
      font-size: 0.625rem;
      line-height: 1rem;
    }
    > div {
      grid-template-columns: auto 3.75rem 3.5rem 6.25rem 4.6875rem 5.625rem;
      border-bottom: 0.0063rem solid ${(p) => p.theme.colors.lightGrey};
      grid-column-gap: 0.8rem;
    }
    img {
      display: block;
      border-radius: 0.25rem;
      margin-right: 1.2rem;
      &.pacs-image {
        margin-right: 0.3125rem;
      }
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin-top: 2rem;
    font-size: 1.5rem;

    .caption {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    > div {
      grid-template-columns: auto 7.5rem 6.5rem 9.6875rem 7.5rem 9.0625rem;
      grid-column-gap: 1.5rem;
    }
  }

  .gray-background {
    background-color: rgba(244, 244, 242, 1);
    margin: 1.5rem -1rem 0;
    padding: 1.5rem 1rem 1rem;
  }
`

export const StyledTotalValue = styled.div`
  margin: 0.5rem 0 1.5rem;
  display: flex;
  justify-content: space-between;
  font-size: 1rem;

  span {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-size: 1.125rem;
    margin-left: 2.5rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    justify-content: end;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;

    span {
      font-size: 1.5rem;
      margin-left: 2.5rem;
    }
  }
`
export const StyledMobileGraySection = styled.div`
  background-color: ${(p) => p.theme.colors.whiteSmoke};
  margin: 1.5rem -1rem 0;
  padding: 1.5rem 1rem 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    background: none;
    margin: 0;
    padding: 0;
  }
`

export const StyledPurchaseMobileGrid = styled.div`
  margin-top: 2rem;
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  .caption {
    font-size: 0.5rem;
    line-height: 0.75rem;
    margin-bottom: 0.5rem;
  }
  .right {
    text-align: right;
    justify-content: end;
  }

  .flex {
    display: flex;
    justify-content: space-between;
  }
  .grid {
    display: grid;
    grid-template-columns: auto 5rem 4.375rem 5rem;
    grid-column-gap: 1rem;
    > span {
      display: flex;
      align-items: center;
    }
  }
  .tier {
    margin-bottom: 1rem;
  }
  .pacs-image {
    margin-right: 0.2rem;
  }

  .mobile-offer-name {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-size: 0.75rem;
    line-height: 1.25rem;
  }

  .mobil-price,
  .mobile-max-pac-discount,
  .mobile-discount-amount,
  .mobile-discount-price {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-size: 0.75rem;
    line-height: 1rem;
  }
`

export const StyledWhatArePacsContainer = styled.div`
  width: 35rem;
  height: 23.25rem;
  display: flex;
`

export const StyledWhatArePacsLeft = styled.div`
  width: 20.375rem;
`
export const StyledWhatArePacsRight = styled.div`
  position: relative;
  width: 12.5rem;
  display: flex;
  align-items: flex-end;
  flex: 1;
`
export const StyledWhatArePacsTitle = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-size: 1.5rem;
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  line-height: 2rem;
  color: ${(p) => p.theme.colors.carrot};
`

export const StyledWhatArePacsDescriptionOne = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-size: 1rem;
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  line-height: 1.5rem;
  color: ${(p) => p.theme.colors.pitchBlack};
`

export const StyledWhatArePacsDescriptionTwo = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-size: 1rem;
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  line-height: 1.5rem;
  color: ${(p) => p.theme.colors.pitchBlack};
`

export const StyledWhatArePacsDescriptionThree = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-size: 1.125rem;
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  line-height: 1.5rem;
  color: ${(p) => p.theme.colors.pitchBlack};
`

export const StyledWhatArePacs = styled.div`
  color: ${(p) => p.theme.colors.carrot};
  cursor: pointer;
  display: inline-block;
`
export const StyledTooltip = styled.div`
  .whatArePacs {
    filter: drop-shadow(0 0 0.3125rem rgba(0, 0, 0, 0.5));
    margin-left: 0 !important;
    margin-bottom: 3rem;
    pointer-events: auto !important;
  }
`
export const StyledWhatArePacsImage = styled.div`
  img {
    height: 20.5rem;
    width: 100%;
    object-fit: cover;
    margin-bottom: -0.8rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    img {
      margin-bottom: -1rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    img {
      margin-bottom: -1.3rem;
    }
  }
`
export const StyledSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  border-bottom: 0.0063rem solid rgba(196, 196, 196, 1);
  padding-top: 1rem;
  padding-bottom: 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    border-top: 0.0063rem solid rgba(196, 196, 196, 1);
  }
  .inline {
    display: inline-block;
  }
`

export const StyledSmallDetailedText = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-size: 0.75rem;
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  line-height: 1.25rem;

  &.mobile {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
`

export const StyledGeneralText = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-size: 1rem;
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  line-height: 1.5rem;

  &.mobile {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
`

export const StyledTotalText = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-size: 1rem;
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  line-height: 1.5rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
`

export const StyledSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  &.right {
    text-align: right;
    justify-content: end;
  }
`

export const StyledSectionItem = styled.div`
  &.right {
    justify-self: end;
    text-align: right;
  }

  &.side-by-side {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }

  img {
    border-radius: 0.25rem;
  }
`

export const StyledPrice = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-size: 1rem;
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  line-height: 1.5rem;
  &.carrot {
    color: ${(p) => p.theme.colors.carrot};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
`
export const StyledSectionTitle = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headlineBig};
  font-size: 0.75rem;
  font-weight: ${(p) => p.theme.fontWeights.liteBlack};
  line-height: 1.5rem;
  text-transform: uppercase;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
`
export const StyledRowGrid = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const StyledPickAnother = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: ${(p) => p.theme.colors.pacificBlue};
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
`
export const StyledHorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;

  &.right {
    justify-content: end;
    align-items: center;
  }

  &.column {
    flex-direction: column;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    &.less-margin {
      margin-top: -1rem !important;
    }

    &.more-margin {
      margin-top: 1rem !important;
    }
  }
`

export const StyledExplanation = styled.div`
  &,
  div,
  span {
    text-transform: uppercase;
    font-family: ${(p) => p.theme.fontFamilies.headlineBig};
    font-weight: ${(p) => p.theme.fontWeights.liteBlack};
    color: ${(p) => p.theme.colors.disabled};
    font-size: 0.625rem;
    line-height: 1rem;
    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.25rem;
      line-height: 2rem;
    }
  }
`
