import { getAllScreenTypes } from "@tmu/src/utils/mediaQueries"
import { getQueryParams } from "@tmu/src/utils/string"
import { useIntl } from "gatsby-plugin-intl"
import { useEffect, useState } from "react"
import { Tooltip } from "react-tooltip"
import VoucherCodeVerify from "../../dashboard/dashboardPages/Sales/voucherCodeVerify"
import VoucherDetail from "../../dashboard/dashboardPages/Sales/voucherDetail"
import CustomModal from "../CustomModal"
import { StyledVoucherCodeButton, StyledWrapper } from "./index.styles"

const VoucherChecker = ({
  isCampaign,
  apiType,
  isVoucherText,
  dashboardType,
}) => {
  const params = getQueryParams()
  const [modalStatus, setModalStatus] = useState(false)
  const [modalContent, setModalContent] = useState()
  const [voucherCode, setVoucherCode] = useState(params?.voucherCode)
  const [isCloseIcon, setIsCloseIcon] = useState(true)
  const { isTablet } = getAllScreenTypes()
  const { formatMessage } = useIntl()
  const voucherCodeVerifyModalContent = {
    children: (
      <VoucherCodeVerify
        voucherCodeParam={voucherCode}
        onSuccess={(code, voucherCodeData, isClose) => {
          setIsCloseIcon(isClose)
          setVoucherCode(code)
          openModal(voucherCodeDetail(code, voucherCodeData))
        }}
        isCampaign={isCampaign}
        apiType={apiType}
      />
    ),
  }

  const voucherCodeDetail = (code, voucherCodeData) => {
    return {
      children: (
        <VoucherDetail
          voucherCodeParam={code?.toUpperCase()}
          onClose={closeModal}
          voucherCodeData={voucherCodeData}
          onUse={() => {
            setIsCloseIcon(false)
          }}
          isCampaign={isCampaign}
          campaign={voucherCodeData?.tier?.campaign}
          apiType={apiType}
        />
      ),
    }
  }

  const openModal = (content) => {
    setModalContent(content)
    setModalStatus(true)
  }

  const closeModal = () => {
    setModalContent(null)
    setModalStatus(false)
    setVoucherCode(null)
  }
  useEffect(() => {
    const voucherParam = params?.voucherCode
    if (voucherParam?.length === 6) {
      setIsCloseIcon(true)
      openModal(voucherCodeVerifyModalContent)
    }
  }, [])

  const buttonText = isVoucherText
    ? formatMessage({
        id: "dashboard::sales::enterVoucherCode",
        defaultMessage: "Voucher Code",
      })
    : formatMessage({
        id: "dashboard::sales::enterConfirmationCode",
        defaultMessage: "Confirmation Code",
      })

  const isPartnerDashboard = dashboardType === "partners"
  const tooltipText =
    isCampaign && isPartnerDashboard
      ? formatMessage({
          id: "voucher::checker::tooltipTextPartnerCampaign",
          defaultMessage:
            "Insert donors confirmation codes to verify their donations",
        })
      : isCampaign
      ? formatMessage({
          id: "voucher::checker::tooltipTextCampaign",
          defaultMessage:
            "Verify donations to campaigns you've created by entering donors’ voucher codes.",
        })
      : ""

  return (
    <StyledWrapper>
      <StyledVoucherCodeButton
        data-tooltip-id="enterCode"
        data-tooltip-content={tooltipText}
        onClick={() => {
          setIsCloseIcon(true)
          openModal(voucherCodeVerifyModalContent)
        }}
        className={isCampaign ? "blue-text" : ""}>
        {buttonText}
      </StyledVoucherCodeButton>
      <CustomModal
        isModalOpen={modalStatus}
        header={modalContent?.header}
        children={modalContent?.children}
        isMobile={!isTablet}
        isCloseIcon={isCloseIcon}
        cancelButtonAction={closeModal}
      />
      {tooltipText?.length > 0 ? (
        <Tooltip
          arrowColor="transparent"
          effect="solid"
          place="bottom"
          type="info"
          textColor="black"
          backgroundColor="white"
          id="enterCode"
          className="tooltip"
          delayShow={500}
        />
      ) : null}
    </StyledWrapper>
  )
}

export default VoucherChecker
