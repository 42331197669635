import React, { useState, useRef, useEffect } from "react"
import { useQuery, useMutation } from "@apollo/client"
import { useApolloApiClients } from "@tmu/apollo/client"
import {
  UPDATE_PARTNER_MUTATION,
  PARTNER_SUBMIT_FOR_REVIEW_MUTATION,
  CREATE_PARTNER_IMAGE_MUTATION,
  DELETE_PARTNER_IMAGE_MUTATION,
  UPDATE_PARTNER_PAYMENT_DESTINATION_MUTATION,
  UPDATE_SUBSCRIPTION_PRODUCT,
  CREATE_PARTNER_PAYMENT_DESTINATION_MUTATION,
  PARTIAL_UPDATE_PARTNER_MUTATION,
} from "@tmu/apollo/dashboard/mutations/partner"
import { CAMPAIGN_SUBMIT_FOR_REVIEW_MUTATION } from "@tmu/apollo/dashboard/mutations/campaign"
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus"
import { faMinus } from "@fortawesome/pro-light-svg-icons/faMinus"
import {
  NameEmailFieldSet,
  AddressFieldSet,
  BankInfoFieldSet,
  ImageSelector,
  Tab,
  SocialProfileFieldSet,
  ImageGallery,
  StoreLogoProfileForm,
} from "@tmu/components/dashboard/dashboardCommon"
import {
  StyledPage,
  StyledPageContent,
  StyledPageTitle,
  StyledForm,
  StyledFormSection,
  StyledAboutSection,
  StyledPageActions,
  StyledFormSectionTitle,
  StyledFormSectionHelpText,
} from "@tmu/global/page-addons/dashboard.styles"
import { faUser } from "@fortawesome/pro-light-svg-icons/faUser"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Formik, Field } from "formik"
import * as Yup from "yup"
import { useIntl, FormattedMessage, navigate } from "gatsby-plugin-intl"
import {
  Button,
  Spinner,
  TextInput,
  TextEditor,
  FieldError,
  CheckboxContainer,
  Tabs,
  TabList,
  Panel,
  InfoBox,
  OnSubmitValidationError,
  CommonToggle,
  BaseError,
} from "@tmu/components/common"
import { useIBAN, useToast, useDefaultPartner, useAuth } from "@tmu/hooks"
import {
  legalName,
  displayName,
  email,
  country,
  bankName,
  iban,
  city,
  address,
  description,
  textField,
  postalCode,
  donationOption1,
  donationOption2,
  donationOption3,
} from "@tmu/utils/validation"
import { removeEmptyHTMLTags } from "@tmu/utils/string"
import { REVIEW_STATUS, API_PERMISSIONS } from "@tmu/apollo/constants"
import { renameKeys } from "@tmu/utils/renameKeys"
import {
  StyledDonationOptionsWrapper,
  StyledDonationOptionItem,
  StyledShareOptionsWrapper,
  StyledShareOptionsItem,
  StyledDescriptionSection,
} from "./index.styles"
import { capitalize } from "@tmu/utils/string"
import { StyledPageGrid, StyledRowTitle } from "../Profile/index.styles"
import {
  StyledProfileFormSection,
  StyledProfileSpacer,
} from "../../dashboardCommon/ProfileForm/index.styles"
import { StyledRowTitleExplanation } from "../Settings/index.styles"

const PartnerProfileForm = () => {
  const { formatMessage, locale } = useIntl()
  const [isUKBank, setIsUKBank] = useState(false)
  const [fullValidate, setFullValidate] = useState(false)
  const { loading: loadingProfile, user, apiPermissions } = useAuth()
  const [processingImages, setProcessingImages] = useState([])
  const [isEnglishSectionOpen, setEnglishSectionOpen] = useState(false)
  const [isItalianSectionOpen, setItalianSectionOpen] = useState(false)
  const [isSpanishSectionOpen, setSpanishSectionOpen] = useState(false)
  let [newValidationSchema, setNewValidationSchema] = useState(validationSchema)
  const [minValue1, setMinValue1] = useState()
  const [minValue2, setMinValue2] = useState()

  const { partnerClient } = useApolloApiClients()
  const [tabIndex, setTabIndex] = useState(0)
  const [activeLangs, setActiveLangs] = useState({
    english: false,
    italian: false,
    spanish: false,
  })
  const localeSuffix = capitalize(locale)
  const formRef = useRef()

  const {
    defaultPartner,
    refetch,
    loading: loadingPartner,
  } = useDefaultPartner({
    isFullData: true,
    skip: loadingProfile || !user?.isPartner,
    onCompleted: () => {
      const english =
        removeEmptyHTMLTags(defaultPartner?.descriptionEn)?.length > 0 ||
        defaultPartner?.shortDescriptionEn?.length > 0 ||
        defaultPartner?.whySupportEn?.length > 0
      const italian =
        removeEmptyHTMLTags(defaultPartner?.descriptionIt)?.length > 0 ||
        defaultPartner?.shortDescriptionIt?.length > 0 ||
        defaultPartner?.whySupportIt?.length > 0
      const spanish =
        removeEmptyHTMLTags(defaultPartner?.descriptionEs)?.length > 0 ||
        defaultPartner?.shortDescriptionEs?.length > 0 ||
        defaultPartner?.whySupportEs?.length > 0
      setActiveLangs({
        english,
        italian,
        spanish,
      })
    },
  })
  const [submitForReviewCampaign] = useMutation(
    CAMPAIGN_SUBMIT_FOR_REVIEW_MUTATION,
    {
      client: partnerClient,
    }
  )

  const { success, error } = useToast()

  useEffect(() => {
    setMinValue1(defaultPartner?.defaultCampaign?.recurringAmountOne)
    setMinValue2(defaultPartner?.defaultCampaign?.recurringAmountTwo)
  }, [defaultPartner?.defaultCampaign?.recurringAmountOne])

  const [updateProfile, { loading: submittingForm }] = useMutation(
    UPDATE_PARTNER_MUTATION,
    {
      client: partnerClient,
    }
  )

  const [submitForReview] = useMutation(PARTNER_SUBMIT_FOR_REVIEW_MUTATION, {
    client: partnerClient,
  })
  const [partialUpdatePartner] = useMutation(PARTIAL_UPDATE_PARTNER_MUTATION, {
    client: partnerClient,
  })
  const [createPartnerImage] = useMutation(CREATE_PARTNER_IMAGE_MUTATION, {
    client: partnerClient,
  })

  const [deletePartnerImage] = useMutation(DELETE_PARTNER_IMAGE_MUTATION, {
    client: partnerClient,
  })

  const [updatePartnerPaymentDestination] = useMutation(
    UPDATE_PARTNER_PAYMENT_DESTINATION_MUTATION,
    {
      client: partnerClient,
    }
  )
  const [createPartnerPaymentDestination] = useMutation(
    CREATE_PARTNER_PAYMENT_DESTINATION_MUTATION,
    {
      client: partnerClient,
    }
  )

  const IBAN = useIBAN()

  const initialValues = {
    name: defaultPartner?.name || "",
    displayName: defaultPartner?.displayName || "",
    email: defaultPartner?.email || "",
    communicationLanguage:
      defaultPartner?.communicationLanguage.length > 0
        ? defaultPartner.communicationLanguage.toUpperCase()
        : locale.toUpperCase(),
    logo: defaultPartner?.logo,
    country: defaultPartner?.country || "",
    city: defaultPartner?.city || "",
    postCode: defaultPartner?.postCode || "",
    addressLine1: defaultPartner?.addressLine1 || "",
    addressLine2: defaultPartner?.addressLine2 || "",
    taxId: defaultPartner?.taxId || "",
    bankName: defaultPartner?.paymentDestinations?.bankName || "",
    bankCountry: defaultPartner?.paymentDestinations?.country || "",
    bankAccountName: defaultPartner?.paymentDestinations?.accountName || "",
    bankIban: defaultPartner?.paymentDestinations?.iban || "",
    bankBic: defaultPartner?.paymentDestinations?.bic || "",
    bankAccountNumber: defaultPartner?.paymentDestinations?.accountNumber || "",
    bankSortCode: defaultPartner?.paymentDestinations?.sortCode || "",
    descriptionTabEn: removeEmptyHTMLTags(defaultPartner?.descriptionEn || ""),
    shortDescriptionTabEn: defaultPartner?.shortDescriptionEn || "",
    whySupportTabEn: defaultPartner?.whySupportEn || "",
    descriptionTabIt: removeEmptyHTMLTags(defaultPartner?.descriptionIt || ""),
    shortDescriptionTabIt: defaultPartner?.shortDescriptionIt || "",
    whySupportTabIt: defaultPartner?.whySupportIt || "",
    descriptionTabEs: removeEmptyHTMLTags(defaultPartner?.descriptionEs || ""),
    shortDescriptionTabEs: defaultPartner?.shortDescriptionEs || "",
    whySupportTabEs: defaultPartner?.whySupportEs || "",
    images:
      defaultPartner?.images?.edges?.map(({ node }) => ({
        ...node,
        existing: true,
      })) ?? [],
    website: defaultPartner?.website ?? "",
    linkedin: defaultPartner?.linkedin ?? "",
    twitter: defaultPartner?.twitter ?? "",
    instagram: defaultPartner?.instagram ?? "",
    youtube: defaultPartner?.youtube ?? "",
    facebook: defaultPartner?.facebook ?? "",
    isEligibleForSupporterCollection:
      defaultPartner?.isEligibleForSupporterCollection ?? false,
    isEligibleForSupporterEvent:
      defaultPartner?.isEligibleForSupporterEvent ?? false,
    recurringIsEnabled: defaultPartner?.defaultCampaign?.recurringIsEnabled,
    recurringAmountOne: defaultPartner?.defaultCampaign?.recurringAmountOne,
    recurringAmountTwo: defaultPartner?.defaultCampaign?.recurringAmountTwo,
    recurringAmountThree: defaultPartner?.defaultCampaign?.recurringAmountThree,
  }

  const hasPaymentDestionation = defaultPartner?.paymentDestinations !== null

  const validationSchema = () => {
    return Yup.object().shape({
      name: legalName({
        formatMessage,
      }),

      displayName: displayName({
        formatMessage,
      }),

      email: email({ formatMessage }),

      country: country({
        formatMessage,
      }),

      descriptionTabEn: activeLangs.english && description({ formatMessage }),

      shortDescriptionTabEn:
        activeLangs.english &&
        textField({
          formatMessage,
        }),

      whySupportTabEn:
        activeLangs.english &&
        textField({
          formatMessage,
        }),

      descriptionTabIt: activeLangs.italian && description({ formatMessage }),

      shortDescriptionTabIt:
        activeLangs.italian &&
        textField({
          formatMessage,
        }),

      whySupportTabIt:
        activeLangs.italian &&
        textField({
          formatMessage,
        }),

      descriptionTabEs: activeLangs.spanish && description({ formatMessage }),

      shortDescriptionTabEs:
        activeLangs.spanish &&
        textField({
          formatMessage,
        }),

      whySupportTabEs:
        activeLangs.spanish &&
        textField({
          formatMessage,
        }),

      city: city({ formatMessage }),

      addressLine1: address({
        formatMessage,
      }),

      bankAccountName: legalName({
        formatMessage,
      }),
      bankName: bankName({
        formatMessage,
      }),

      bankCountry: country({
        formatMessage,
      }),

      bankIban: !isUKBank && IBAN ? iban({ formatMessage, IBAN }) : null,

      postCode: postalCode({
        formatMessage,
      }),

      recurringAmountOne: donationOption1({
        formatMessage,
        minValue: 5,
      }),

      recurringAmountTwo: donationOption2({
        formatMessage,
        minValue1,
      }),

      recurringAmountThree: donationOption3({
        formatMessage,
        minValue1,
        minValue2,
      }),
    })
  }

  useEffect(() => {
    setNewValidationSchema(validationSchema)
  }, [minValue1, minValue2])

  const AboutText = (
    <FormattedMessage
      id="dashboard::partnerProfileForm::descriptionTitle"
      defaultMessage="About"
      tagName="h3"
    />
  )

  const PartnerDescriptionText = (
    <FormattedMessage
      id="dashboard::partnerProfileForm::descriptionDesc"
      defaultMessage="Partner details to be used in public views"
      tagName="p"
    />
  )

  const DescFieldTitle = formatMessage({
    id: "dashboard::partnerProfileForm::descFieldTitle",
    defaultMessage: "What do we do",
  })

  const ShortDesc = (
    <FormattedMessage
      id="dashboard::partnerProfileForm::shortDesc"
      defaultMessage="Please add a short description explaining your organization. This will show up on various parts on the platform to promote you"
      tagName="p"
    />
  )

  const ShortDescFieldTitle = formatMessage({
    id: "dashboard::campaignForm::shortDescFieldTitle",
    defaultMessage: "Short Description",
  })

  const WhySupport = (
    <FormattedMessage
      id="dashboard::partnerProfileForm::whySupport"
      defaultMessage="Please explain how donations help your activities"
      tagName="p"
    />
  )

  const WhySupportTitle = formatMessage({
    id: "dashboard::campaignForm::whySupportTitle",
    defaultMessage: "Why Support?",
  })

  const handleEnglishSectionChange = () => {
    if (isEnglishSectionOpen) {
      const values = formRef?.current?.values
      formRef?.current?.setValues({
        ...values,
        descriptionOneEn: "",
        descriptionTwoEn: "",
        descriptionThreeEn: "",
      })
    }

    setEnglishSectionOpen(!isEnglishSectionOpen)
  }
  const handleItalianSectionChange = () => {
    if (isItalianSectionOpen) {
      const values = formRef?.current?.values
      formRef?.current?.setValues({
        ...values,
        descriptionOneIt: "",
        descriptionTwoIt: "",
        descriptionThreeIt: "",
      })
    }

    setItalianSectionOpen(!isItalianSectionOpen)
  }
  const handleSpanishSectionChange = () => {
    if (isSpanishSectionOpen) {
      const values = formRef?.current?.values
      formRef?.current?.setValues({
        ...values,
        descriptionOneEs: "",
        descriptionTwoEs: "",
        descriptionThreeEs: "",
      })
    }

    setSpanishSectionOpen(!isSpanishSectionOpen)
  }

  const handleSubmitForm = (values, form) => {
    let newKeyMappings = {
      descriptionTabEn: "descriptionEn",
      descriptionTabEs: "descriptionEs",
      descriptionTabIt: "descriptionIt",
      whySupportTabEn: "whySupportEn",
      whySupportTabEs: "whySupportEs",
      whySupportTabIt: "whySupportIt",
      shortDescriptionTabEn: "shortDescriptionEn",
      shortDescriptionTabEs: "shortDescriptionEs",
      shortDescriptionTabIt: "shortDescriptionIt",
    }

    const paymentDestinations = {
      accountName: values.bankAccountName,
      bankName: values.bankName,
      iban: values.bankIban,
      country: values.bankCountry,
      sortCode: values.bankSortCode,
      accountNumber: values.bankAccountNumber,
      bic: values.bankBic,
    }

    // check if country is GB or not
    if (paymentDestinations.country === "GB") {
      delete paymentDestinations.bankName
      delete paymentDestinations.iban
    } else {
      delete paymentDestinations.sortCode
      delete paymentDestinations.accountNumber
    }

    const newValues = renameKeys && renameKeys(newKeyMappings, values)

    form.setSubmitting(true)
    let input = { ...newValues }

    // Logo not changed
    if (input?.logo === defaultPartner?.logo) {
      delete input.logo
    } else {
      input.logoEn = input.logo
    }

    delete input.bankAccountName
    delete input.bankName
    delete input.bankIban
    delete input.bankCountry
    delete input.bankSortCode
    delete input.bankAccountNumber
    delete input.bankBic

    delete input.recurringIsEnabled
    delete input.recurringAmountOne
    delete input.descriptionOneEn
    delete input.descriptionOneIt
    delete input.descriptionOneEs
    delete input.recurringAmountTwo
    delete input.descriptionTwoEn
    delete input.descriptionTwoIt
    delete input.descriptionTwoEs
    delete input.recurringAmountThree
    delete input.descriptionThreeEn
    delete input.descriptionThreeIt
    delete input.descriptionThreeEs

    // Delete images prop
    const partnerAccountImages = input.images
    delete input.images

    const errorHandler = (err) => {
      error(
        err?.message ||
          formatMessage({
            id: "dashboard::campaignForm::errorMessage",
            defaultMessage: "An error occurred",
          })
      )
      form.setSubmitting(false)
    }

    const resultFieldName = fullValidate
      ? "partnerSubmitForReview"
      : "updatePartner"

    const mutationSuccessMessage = fullValidate
      ? formatMessage({
          id: "dashboard::campaignForm::updateMessage",
          defaultMessage: "Your updates are submitted for review!",
        })
      : formatMessage({
          id: "dashboard::partnerForm::updated",
          defaultMessage: "Partner is updated!",
        })

    const resultHandler = ({
      data: {
        [resultFieldName]: { partner, errors },
      },
    }) => {
      if (errors.length) {
        errors.forEach(errorHandler)
        form.setSubmitting(false)
      } else {
        submitForReviewCampaign({
          variables: {
            input: {
              recurringAmountOne: values.recurringAmountOne.toString(),
              recurringAmountTwo: values.recurringAmountTwo.toString(),
              recurringAmountThree: values.recurringAmountThree.toString(),
              id: defaultPartner?.defaultCampaign?.id,
            },
          },
          client: partnerClient,
        })

        // Save new partner account  images
        const newImages = partnerAccountImages.filter(
          (img) => img.id.indexOf("user-upload") > -1
        )
        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
        if (newImages.length) {
          setProcessingImages(newImages.map(({ id }) => id))

          const createImagesWithDelay = async () => {
            for (const { image, order, id } of newImages) {
              await delay(2500)
              await createPartnerImage({
                variables: {
                  input: {
                    image,
                    order,
                    partner: defaultPartner?.id,
                  },
                },
              })
            }
          }

          createImagesWithDelay().then(() => {
            setProcessingImages([])
            setTimeout(() => {
              partialUpdatePartner({
                variables: {
                  input: {
                    id: defaultPartner?.id,
                    reviewStatus: "READY",
                  },
                },
              })
            }, 2000)
            form.setSubmitting(false)
            success(mutationSuccessMessage)
            navigate(`/dashboard/charities`)
          })
        } else {
          setTimeout(() => {
            partialUpdatePartner({
              variables: {
                input: {
                  id: defaultPartner?.id,
                  reviewStatus: "READY",
                },
              },
            })
            form.setSubmitting(false)
            success(mutationSuccessMessage)
            navigate("/dashboard/charities")
          }, 2000)
        }
      }
      refetch()
    }

    delete input?.shopLink

    if (fullValidate) {
      submitForReview({
        variables: {
          input: {
            ...input,
            communicationLanguage: input.communicationLanguage.toUpperCase(),
          },
        },
        client: partnerClient,
      })
        .then(resultHandler)
        .catch(errorHandler)
    } else
      updateProfile({
        variables: { input },
      })
        .then(resultHandler)
        .catch(errorHandler)

    const paymentMutation = hasPaymentDestionation
      ? updatePartnerPaymentDestination
      : createPartnerPaymentDestination

    paymentMutation({
      variables: {
        input: {
          ...paymentDestinations,
          id: defaultPartner?.paymentDestinations?.id,
        },
      },
      client: partnerClient,
    })
      .then((result) => {
        result?.errors?.forEach(errorHandler)
      })
      .catch(errorHandler)
  }

  const status = defaultPartner?.status

  return (
    <StyledPage
      disabled={
        !apiPermissions?.includes(
          API_PERMISSIONS.PARTNER_UPDATE_ACCOUNT_INFORMATION
        )
      }>
      <StyledPageTitle>
        <FormattedMessage
          id="dashboard::nav::accountInformation"
          defaultMessage="Account Information"
          tagName="h1"
        />
        <InfoBox status={status} />
      </StyledPageTitle>

      <StyledPageContent>
        {loadingProfile || loadingPartner ? (
          <Spinner condensed />
        ) : (
          <Formik
            enableReinitialize={true}
            validateOnBlur={true}
            validationSchema={newValidationSchema}
            innerRef={formRef}
            initialValues={initialValues}
            onSubmit={handleSubmitForm}>
            {({
              isSubmitting,
              values,
              setFieldValue,
              touched,
              setTouched,
              handleBlur,
              handleSubmit,
              resetForm,
              errors,
            }) => (
              <>
                <OnSubmitValidationError />
                <StyledForm onSubmit={handleSubmit}>
                  <StyledPageGrid>
                    <StyledRowTitle>
                      <FormattedMessage
                        id="dashboard::partnerProfileForm::logo"
                        defaultMessage="Logo"
                      />
                      <StyledProfileSpacer bottom={0.5} />
                      <StyledRowTitleExplanation>
                        <FormattedMessage
                          id="dashboard::merchantStore::storeLogoExplanation"
                          defaultMessage="1200x675px is the recommended resolution, or other which has an aspect ratio of 16/9"
                        />
                      </StyledRowTitleExplanation>
                    </StyledRowTitle>
                    <StyledProfileFormSection
                      horizontal
                      className="logo-wrapper">
                      <ImageSelector
                        alignOverlay={true}
                        noAspect
                        defaultValue={values?.logo}
                        onChange={(image) => {
                          setFieldValue("logo", image)
                          setTouched({ ...touched, logo: true }, true)
                        }}
                        onCropChange={(croppedImage) => {
                          setFieldValue("logo", croppedImage)
                          setTouched({ ...touched, logo: true }, true)
                        }}
                        onReset={() =>
                          setFieldValue("logo", initialValues.logo)
                        }
                      />
                    </StyledProfileFormSection>
                  </StyledPageGrid>

                  <StyledProfileSpacer bottom={1.75} />

                  <StyledPageGrid>
                    <StyledRowTitle>
                      <FormattedMessage
                        id="dashboard::merchantStore::general"
                        defaultMessage="General"
                      />
                      <StyledProfileSpacer bottom={0.5} />
                      <StyledRowTitleExplanation>
                        <FormattedMessage
                          id="dashboard::settings::generalExplanation"
                          defaultMessage="Customers will see your display name on your profile and products pages. Legal name will be used to process payments as well as will be stated in the receipt"
                        />
                      </StyledRowTitleExplanation>
                      <StyledProfileSpacer bottom={0.5} />
                    </StyledRowTitle>
                    <NameEmailFieldSet />
                  </StyledPageGrid>

                  {/* <StyledFormSection horizontal className="logo-wrapper">
                    <ImageSelector
                      alignOverlay={true}
                      noAspect
                      defaultValue={values?.logo}
                      onChange={(image) => {
                        setFieldValue("logo", image)
                        setTouched({ ...touched, logo: true }, true)
                      }}
                      onCropChange={(croppedImage) => {
                        setFieldValue("logo", croppedImage)
                        setTouched({ ...touched, logo: true }, true)
                      }}
                      onReset={() => setFieldValue("logo", initialValues.logo)}
                    />
                    <NameEmailFieldSet />
                  </StyledFormSection> */}
                  <hr />
                  <Tabs
                    selectedIndex={tabIndex}
                    onSelect={(index) => setTabIndex(index)}>
                    <TabList>
                      <Tab
                        isActive={tabIndex === 0}
                        activeLangs={activeLangs}
                        setActiveLangs={setActiveLangs}>
                        <FormattedMessage
                          id="dashboard::ngoProfileForm::English"
                          defaultMessage="English"
                        />
                      </Tab>
                      <Tab
                        isActive={tabIndex === 1}
                        activeLangs={activeLangs}
                        setActiveLangs={setActiveLangs}>
                        <FormattedMessage
                          id="dashboard::ngoProfileForm::Italian"
                          defaultMessage="Italian"
                        />
                      </Tab>
                      <Tab
                        isActive={tabIndex === 2}
                        activeLangs={activeLangs}
                        setActiveLangs={setActiveLangs}>
                        <FormattedMessage
                          id="dashboard::ngoProfileForm::Spanish"
                          defaultMessage="Spanish"
                        />
                      </Tab>
                    </TabList>
                    <StyledAboutSection>
                      <Panel>
                        {AboutText}
                        {PartnerDescriptionText}
                        <TextEditor
                          name="descriptionTabEn"
                          label={DescFieldTitle}
                          value={values.descriptionTabEn}
                          onChange={(value) =>
                            setFieldValue("descriptionTabEn", value)
                          }
                          onBlur={() => handleBlur("descriptionTabEn")}
                          placeholder=""
                        />
                        <FieldError
                          name="descriptionTabEn"
                          autoFocus={true}
                          scrollOpts={{ block: "end", behavior: "smooth" }}
                        />
                        {ShortDesc}
                        <Field
                          data-testid="partner-input-shortDescriptionEn"
                          id="shortDescriptionTabEn"
                          name="shortDescriptionTabEn"
                          label={ShortDescFieldTitle}
                          type="textarea"
                          component={TextInput}
                          className="float-container info-area full-width"
                          value={values.shortDescriptionTabEn}
                          onChange={(evt) =>
                            setFieldValue(
                              "shortDescriptionTabEn",
                              evt.target.value,
                              true
                            )
                          }
                          placeholder=""
                          textAreaMaxLength={1000}
                        />
                        <FieldError
                          name="shortDescriptionTabEn"
                          autoFocus={true}
                          scrollOpts={{ block: "center", behavior: "smooth" }}
                        />
                        {WhySupport}
                        <Field
                          data-testid="partner-input-whySupportEn"
                          id="whySupportTabEn"
                          name="whySupportTabEn"
                          label={WhySupportTitle}
                          type="textarea"
                          component={TextInput}
                          className="float-container info-area full-width"
                          value={values.whySupportTabEn}
                          onChange={(evt) =>
                            setFieldValue(
                              "whySupportTabEn",
                              evt.target.value,
                              true
                            )
                          }
                          placeholder=""
                          textAreaMaxLength={500}
                        />
                        <FieldError
                          name="whySupportTabEn"
                          autoFocus={true}
                          scrollOpts={{ block: "center", behavior: "smooth" }}
                        />
                        <hr />
                      </Panel>
                      <Panel>
                        {AboutText}
                        {PartnerDescriptionText}
                        <TextEditor
                          name="descriptionTabIt"
                          label={DescFieldTitle}
                          value={values.descriptionTabIt}
                          onChange={(value) =>
                            setFieldValue("descriptionTabIt", value)
                          }
                          onBlur={() => handleBlur("descriptionTabIt")}
                          placeholder=""
                        />
                        <FieldError
                          name="descriptionTabIt"
                          autoFocus={true}
                          scrollOpts={{ block: "end", behavior: "smooth" }}
                        />
                        {ShortDesc}
                        <Field
                          data-testid="partner-input-shortDescriptionIt"
                          id="shortDescriptionTabIt"
                          name="shortDescriptionTabIt"
                          label={ShortDescFieldTitle}
                          type="textarea"
                          component={TextInput}
                          className="float-container info-area full-width"
                          value={values.shortDescriptionTabIt}
                          onChange={(evt) =>
                            setFieldValue(
                              "shortDescriptionTabIt",
                              evt.target.value,
                              true
                            )
                          }
                          placeholder=""
                          textAreaMaxLength={1000}
                        />
                        <FieldError
                          name="shortDescriptionTabIt"
                          autoFocus={true}
                          scrollOpts={{ block: "center", behavior: "smooth" }}
                        />
                        {WhySupport}
                        <Field
                          data-testid="partner-input-whySupportIt"
                          id="whySupportTabIt"
                          name="whySupportTabIt"
                          label={WhySupportTitle}
                          type="textarea"
                          component={TextInput}
                          className="float-container info-area full-width"
                          value={values.whySupportTabIt}
                          onChange={(evt) =>
                            setFieldValue(
                              "whySupportTabIt",
                              evt.target.value,
                              true
                            )
                          }
                          placeholder=""
                          textAreaMaxLength={500}
                        />
                        <FieldError
                          name="whySupportTabIt"
                          autoFocus={true}
                          scrollOpts={{ block: "center", behavior: "smooth" }}
                        />
                        <hr />
                      </Panel>
                      <Panel>
                        {AboutText}
                        {PartnerDescriptionText}
                        <TextEditor
                          name="descriptionTabEs"
                          label={DescFieldTitle}
                          value={values.descriptionTabEs}
                          onChange={(value) =>
                            setFieldValue("descriptionTabEs", value)
                          }
                          onBlur={() => handleBlur("descriptionTabEs")}
                          placeholder=""
                        />
                        <FieldError
                          name="descriptionTabEs"
                          autoFocus={true}
                          scrollOpts={{ block: "end", behavior: "smooth" }}
                        />
                        {ShortDesc}
                        <Field
                          data-testid="partner-input-shortDescriptionEs"
                          id="shortDescriptionTabEs"
                          name="shortDescriptionTabEs"
                          label={ShortDescFieldTitle}
                          type="textarea"
                          component={TextInput}
                          className="float-container info-area full-width"
                          value={values.shortDescriptionTabEs}
                          onChange={(evt) =>
                            setFieldValue(
                              "shortDescriptionTabEs",
                              evt.target.value,
                              true
                            )
                          }
                          placeholder=""
                          textAreaMaxLength={1000}
                        />
                        <FieldError
                          name="shortDescriptionTabEs"
                          autoFocus={true}
                          scrollOpts={{ block: "center", behavior: "smooth" }}
                        />
                        {WhySupport}
                        <Field
                          data-testid="partner-input-whySupportEs"
                          id="whySupportTabEs"
                          name="whySupportTabEs"
                          label={WhySupportTitle}
                          type="textarea"
                          component={TextInput}
                          className="float-container info-area full-width"
                          value={values.whySupportTabEs}
                          onChange={(evt) =>
                            setFieldValue(
                              "whySupportTabEs",
                              evt.target.value,
                              true
                            )
                          }
                          placeholder=""
                          textAreaMaxLength={500}
                        />
                        <FieldError
                          name="whySupportTabEs"
                          autoFocus={true}
                          scrollOpts={{ block: "center", behavior: "smooth" }}
                        />
                        <hr />
                      </Panel>
                    </StyledAboutSection>
                  </Tabs>
                  <hr />
                  <StyledFormSectionTitle>
                    <FormattedMessage
                      id="dashboard::partner::supporterImageGallery"
                      defaultMessage="Supporter Image Gallery"
                    />
                  </StyledFormSectionTitle>
                  <StyledFormSectionHelpText>
                    <FormattedMessage
                      id="dashboard::merchant::imageDesc"
                      defaultMessage="Upload images that represent your campaign with the aspect ratio of 16/9"
                    />
                  </StyledFormSectionHelpText>
                  <ImageGallery
                    processingImages={processingImages}
                    onDeleteImage={deletePartnerImage}
                  />
                  <hr />
                  <AddressFieldSet partnerProfile={true} />
                  <hr />

                  <SocialProfileFieldSet />
                  <hr />

                  <BankInfoFieldSet
                    isPartner={true}
                    isUKBank={isUKBank}
                    setIsUKBank={setIsUKBank}
                  />
                  <hr />
                  <StyledDonationOptionsWrapper>
                    <StyledFormSectionTitle>
                      <FormattedMessage
                        id="dashboard::forms::donationOptionsTitle"
                        defaultMessage="Donation Options"
                        tagName="h3"
                      />
                    </StyledFormSectionTitle>
                    <StyledFormSectionHelpText>
                      <FormattedMessage
                        id="dashboard::forms::donationOptionsHelpText"
                        defaultMessage="Choose donation options for your donor. Set 3 donation options for single and recurring donations to your charity as well as receive a custom donation amount. Minimum amount of a donation is 5 €"
                      />
                    </StyledFormSectionHelpText>
                    <StyledDonationOptionItem>
                      <div>
                        <Field
                          data-testid="input-recurringAmountOne"
                          id="recurringAmountOne"
                          name="recurringAmountOne"
                          component={TextInput}
                          type="currency"
                          maxLength={8}
                          className="float-container currency-icon"
                          value={values?.recurringAmountOne || ""}
                          onChange={(evt) => {
                            setFieldValue(
                              "recurringAmountOne",
                              evt.target.value,
                              true
                            )
                            setMinValue1(evt.target.value)
                          }}
                          label={formatMessage({
                            id: "dashboard::forms::donationOption1",
                            defaultMessage: "Option 1",
                          })}
                        />
                        <BaseError message={errors.recurringAmountOne} />
                      </div>
                      <div>
                        <Field
                          data-testid="input-recurringAmountTwo"
                          id="recurringAmountTwo"
                          name="recurringAmountTwo"
                          component={TextInput}
                          type="currency"
                          maxLength={8}
                          className="float-container currency-icon"
                          value={values?.recurringAmountTwo || ""}
                          onChange={(evt) => {
                            setFieldValue(
                              "recurringAmountTwo",
                              evt.target.value,
                              true
                            )
                            setMinValue2(evt.target.value)
                          }}
                          label={formatMessage({
                            id: "dashboard::forms::donationOption2",
                            defaultMessage: "Option 2",
                          })}
                        />
                        <BaseError message={errors.recurringAmountTwo} />
                      </div>
                      <div>
                        <Field
                          data-testid="input-recurringAmountThree"
                          id="recurringAmountThree"
                          name="recurringAmountThree"
                          component={TextInput}
                          type="currency"
                          maxLength={8}
                          className="float-container currency-icon"
                          value={values?.recurringAmountThree || ""}
                          onChange={(evt) => {
                            setFieldValue(
                              "recurringAmountThree",
                              evt.target.value,
                              true
                            )
                          }}
                          label={formatMessage({
                            id: "dashboard::forms::donationOption3",
                            defaultMessage: "Option 3",
                          })}
                        />
                        <BaseError message={errors.recurringAmountThree} />
                      </div>
                    </StyledDonationOptionItem>
                    {/* 
                    <StyledDescriptionSection>
                      <Field
                        data-testid="input-descriptionOne"
                        id={`descriptionOne${localeSuffix}`}
                        name={`descriptionOne${localeSuffix}`}
                        component={TextInput}
                        type="textarea"
                        textAreaMaxLength={160}
                        className="float-container description"
                        value={values[`descriptionOne${localeSuffix}`]}
                        onChange={(evt) =>
                          setFieldValue(
                            `descriptionOne${localeSuffix}`,
                            evt.target.value,
                            true
                          )
                        }
                        label={formatMessage({
                          id: "dashboard::forms::descriptionOne",
                          defaultMessage: "Description 1",
                        })}
                      />
                      <Field
                        data-testid="input-descriptionTwo"
                        id={`descriptionTwo${localeSuffix}`}
                        name={`descriptionTwo${localeSuffix}`}
                        component={TextInput}
                        type="textarea"
                        textAreaMaxLength={160}
                        className="float-container description"
                        value={values[`descriptionTwo${localeSuffix}`]}
                        onChange={(evt) =>
                          setFieldValue(
                            `descriptionTwo${localeSuffix}`,
                            evt.target.value,
                            true
                          )
                        }
                        label={formatMessage({
                          id: "dashboard::forms::descriptionTwo",
                          defaultMessage: "Description 2",
                        })}
                      />
                      <Field
                        data-testid="input-descriptionThree"
                        id={`descriptionThree${localeSuffix}`}
                        name={`descriptionThree${localeSuffix}`}
                        component={TextInput}
                        type="textarea"
                        textAreaMaxLength={160}
                        className="float-container description"
                        value={values[`descriptionThree${localeSuffix}`]}
                        onChange={(evt) =>
                          setFieldValue(
                            `descriptionThree${localeSuffix}`,
                            evt.target.value,
                            true
                          )
                        }
                        label={formatMessage({
                          id: "dashboard::forms::descriptionThree",
                          defaultMessage: "Description 3",
                        })}
                      />
                      {locale !== "en" ? (
                        <>
                          <Button
                            className="second-grid"
                            data-testid="btn-add-english"
                            color="transparent"
                            onClick={handleEnglishSectionChange}
                            type="button">
                            <FontAwesomeIcon
                              icon={
                                isEnglishSectionOpen ||
                                values.descriptionOneEn ||
                                values.descriptionTwoEn ||
                                values.descriptionThreeEn
                                  ? faMinus
                                  : faPlus
                              }
                            />
                            {`${
                              isEnglishSectionOpen ||
                              values.descriptionOneEn ||
                              values.descriptionTwoEn ||
                              values.descriptionThreeEn
                                ? "Delete"
                                : "Add"
                            } the English version`}
                          </Button>
                          {isEnglishSectionOpen ||
                          values.descriptionOneEn ||
                          values.descriptionTwoEn ||
                          values.descriptionThreeEn ? (
                            <>
                              <Field
                                data-testid="input-descriptionOneEn"
                                id="descriptionOneEn"
                                name="descriptionOneEn"
                                component={TextInput}
                                type="textarea"
                                textAreaMaxLength={160}
                                className="float-container description"
                                value={values.descriptionOneEn}
                                onChange={(evt) =>
                                  setFieldValue(
                                    "descriptionOneEn",
                                    evt.target.value,
                                    true
                                  )
                                }
                                label="Description 1"
                              />
                              <Field
                                data-testid="input-descriptionTwoEn"
                                id="descriptionTwoEn"
                                name="descriptionTwoEn"
                                component={TextInput}
                                type="textarea"
                                className="float-container description"
                                value={values.descriptionTwoEn}
                                onChange={(evt) =>
                                  setFieldValue(
                                    "descriptionTwoEn",
                                    evt.target.value,
                                    true
                                  )
                                }
                                label="Description 2"
                              />
                              <Field
                                data-testid="input-descriptionThreeEn"
                                id="descriptionThreeEn"
                                name="descriptionThreeEn"
                                component={TextInput}
                                type="textarea"
                                textAreaMaxLength={160}
                                className="float-container description"
                                value={values.descriptionThreeEn}
                                onChange={(evt) =>
                                  setFieldValue(
                                    "descriptionThreeEn",
                                    evt.target.value,
                                    true
                                  )
                                }
                                label="Description 3"
                              />
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      ) : null}

                      {locale !== "it" ? (
                        <>
                          <Button
                            className="second-grid"
                            data-testid="btn-add-italian"
                            color="transparent"
                            onClick={handleItalianSectionChange}
                            type="button">
                            <FontAwesomeIcon
                              icon={
                                isItalianSectionOpen ||
                                values.descriptionOneIt ||
                                values.descriptionTwoIt ||
                                values.descriptionThreeIt
                                  ? faMinus
                                  : faPlus
                              }
                            />
                            {`${
                              isItalianSectionOpen ||
                              values.descriptionOneIt ||
                              values.descriptionTwoIt ||
                              values.descriptionThreeIt
                                ? "Elimina"
                                : "Aggiungere"
                            } la versione italiana`}
                          </Button>
                          {isItalianSectionOpen ||
                          values.descriptionOneIt ||
                          values.descriptionTwoIt ||
                          values.descriptionThreeIt ? (
                            <>
                              <Field
                                data-testid="input-descriptionOneIt"
                                id="descriptionOneIt"
                                name="descriptionOneIt"
                                component={TextInput}
                                type="textarea"
                                textAreaMaxLength={160}
                                className="float-container description"
                                value={values.descriptionOneIt}
                                onChange={(evt) =>
                                  setFieldValue(
                                    "descriptionOneIt",
                                    evt.target.value,
                                    true
                                  )
                                }
                                label="Descrizione 1"
                              />
                              <Field
                                data-testid="input-descriptionTwoIt"
                                id="descriptionTwoIt"
                                name="descriptionTwoIt"
                                component={TextInput}
                                type="textarea"
                                textAreaMaxLength={160}
                                className="float-container description"
                                value={values.descriptionTwoIt}
                                onChange={(evt) =>
                                  setFieldValue(
                                    "descriptionTwoIt",
                                    evt.target.value,
                                    true
                                  )
                                }
                                label="Descrizione 2"
                              />
                              <Field
                                data-testid="input-descriptionThreeIt"
                                id="descriptionThreeIt"
                                name="descriptionThreeIt"
                                component={TextInput}
                                type="textarea"
                                textAreaMaxLength={160}
                                className="float-container description"
                                value={values.descriptionThreeIt}
                                onChange={(evt) =>
                                  setFieldValue(
                                    "descriptionThreeIt",
                                    evt.target.value,
                                    true
                                  )
                                }
                                label="Descrizione 3"
                              />
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      ) : null}
                      {locale !== "es" ? (
                        <>
                          <Button
                            className="second-grid"
                            data-testid="btn-add-spanishEs"
                            color="transparent"
                            onClick={handleSpanishSectionChange}
                            type="button">
                            <FontAwesomeIcon
                              icon={
                                isSpanishSectionOpen ||
                                values.descriptionOneEs ||
                                values.descriptionTwoEs ||
                                values.descriptionThreeEs
                                  ? faMinus
                                  : faPlus
                              }
                            />
                            {`${
                              isSpanishSectionOpen ||
                              values.descriptionOneEs ||
                              values.descriptionTwoEs ||
                              values.descriptionThreeEs
                                ? "Eliminar"
                                : "Añadir"
                            } la versión en español`}
                          </Button>
                          {isSpanishSectionOpen ||
                          values.descriptionOneEs ||
                          values.descriptionTwoEs ||
                          values.descriptionThreeEs ? (
                            <>
                              <Field
                                data-testid="input-descriptionOneEs"
                                id="descriptionOneEs"
                                name="descriptionOneEs"
                                component={TextInput}
                                type="textarea"
                                textAreaMaxLength={160}
                                className="float-container description"
                                value={values.descriptionOneEs}
                                onChange={(evt) =>
                                  setFieldValue(
                                    "descriptionOneEs",
                                    evt.target.value,
                                    true
                                  )
                                }
                                label="Descripción 1"
                              />
                              <Field
                                data-testid="input-descriptionTwoEs"
                                id="descriptionTwoEs"
                                name="descriptionTwoEs"
                                component={TextInput}
                                type="textarea"
                                textAreaMaxLength={160}
                                className="float-container description"
                                value={values.descriptionTwoEs}
                                onChange={(evt) =>
                                  setFieldValue(
                                    "descriptionTwoEs",
                                    evt.target.value,
                                    true
                                  )
                                }
                                label="Descripción 2"
                              />
                              <Field
                                data-testid="input-descriptionThreeEs"
                                id="descriptionThreeEs"
                                name="descriptionThreeEs"
                                component={TextInput}
                                type="textarea"
                                textAreaMaxLength={160}
                                className="float-container description"
                                value={values.descriptionThreeEs}
                                onChange={(evt) =>
                                  setFieldValue(
                                    "descriptionThreeEs",
                                    evt.target.value,
                                    true
                                  )
                                }
                                label="Descripción 3"
                              />
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      ) : null}
                    </StyledDescriptionSection> 
                    */}
                  </StyledDonationOptionsWrapper>
                  <hr />
                  <StyledFormSectionTitle>
                    <FormattedMessage
                      id="dashboard::merchant::sharingOptionsTitle"
                      defaultMessage="Sharing Options"
                      tagName="h3"
                    />
                  </StyledFormSectionTitle>
                  <StyledFormSectionHelpText>
                    <FormattedMessage
                      id="dashboard::merchant::sharingOptionsHelpText"
                      defaultMessage="Let supporters create fundraising campaigns in support of your charity."
                    />
                  </StyledFormSectionHelpText>
                  <StyledShareOptionsWrapper
                    disabled={
                      !apiPermissions?.includes(
                        API_PERMISSIONS.PARTNER_UPDATE_ACCOUNT_INFORMATION
                      )
                    }>
                    <StyledShareOptionsItem
                      onClick={() => {
                        setFieldValue(
                          "isEligibleForSupporterEvent",
                          !values?.isEligibleForSupporterEvent
                        )
                      }}>
                      <FormattedMessage
                        id="dashboard::merchant::shareEventCampaigns"
                        defaultMessage="Event Campaigns"
                      />
                      <CommonToggle
                        controlled
                        checked={values?.isEligibleForSupporterEvent}
                      />
                    </StyledShareOptionsItem>
                    <StyledShareOptionsItem
                      onClick={() => {
                        setFieldValue(
                          "isEligibleForSupporterCollection",
                          !values?.isEligibleForSupporterCollection
                        )
                      }}>
                      <FormattedMessage
                        id="dashboard::merchant::shareCollectionCampaigns"
                        defaultMessage="Collection Campaigns"
                      />
                      <CommonToggle
                        controlled
                        checked={values?.isEligibleForSupporterCollection}
                      />
                    </StyledShareOptionsItem>
                  </StyledShareOptionsWrapper>

                  {defaultPartner?.status === REVIEW_STATUS.IN_PROGRESS &&
                    apiPermissions?.includes(
                      API_PERMISSIONS.PARTNER_UPDATE_ACCOUNT_INFORMATION
                    ) && (
                      <StyledPageActions>
                        <Button
                          data-testid="btn-partner-profile-save"
                          label="Save"
                          type="submit"
                          color="red"
                          disabled={submittingForm || isSubmitting}>
                          <FormattedMessage
                            id="dashboard::campaignForm::save"
                            defaultMessage="Save"
                          />
                        </Button>
                      </StyledPageActions>
                    )}

                  {apiPermissions?.includes(
                    API_PERMISSIONS.PARTNER_UPDATE_ACCOUNT_INFORMATION
                  ) && (
                    <>
                      <hr />

                      <CheckboxContainer className="full-width">
                        <label htmlFor="agreeTerms">
                          <input
                            tabIndex="0"
                            data-testid="check-agreeTerms"
                            id="agreeTerms"
                            name="agreeTerms"
                            type="checkbox"
                            checked={fullValidate}
                            onChange={(e) => {
                              setFullValidate(e.target.checked)
                            }}
                          />
                          <span className="checkmark"></span>
                          <FormattedMessage
                            id="dashboard::campaignForm::submitForReviewText"
                            defaultMessage="By accepting, I acknowledge that I have read and
                      understood the above information and will comply within
                      the declared rules and regulations of the platform"
                          />
                        </label>
                      </CheckboxContainer>
                      <StyledPageActions>
                        <>
                          <Button
                            data-testid="btn-partner-profile-reset-form"
                            label="Reset Form"
                            variant="text"
                            onClick={resetForm}>
                            <FormattedMessage
                              id="dashboard::accountForm::reset"
                              defaultMessage="Reset Form"
                            />
                          </Button>
                          <Button
                            data-testid="btn-partner-profile-submit-for-review"
                            label="Submit For Review"
                            type="button"
                            color={
                              submittingForm || isSubmitting || !fullValidate
                                ? "disabled"
                                : "blue"
                            }
                            disabled={
                              submittingForm || isSubmitting || !fullValidate
                            }
                            onClick={handleSubmit}
                            className="submit-for-review-button">
                            <FormattedMessage
                              id="dashboard::campaignForm::submitForReview"
                              defaultMessage="Submit For Review"
                            />
                          </Button>
                        </>
                      </StyledPageActions>
                    </>
                  )}
                </StyledForm>
              </>
            )}
          </Formik>
        )}
      </StyledPageContent>
    </StyledPage>
  )
}

export default PartnerProfileForm
