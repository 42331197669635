import { useQuery, gql } from "@apollo/client"
import { useIntl } from "gatsby-plugin-intl"
import { useApolloApiClients } from "@tmu/apollo/client"
import { useAuth } from "@tmu/hooks"
import { getValueForLocale } from "@tmu/utils/string"
import { API_PERMISSIONS } from "@tmu/apollo/constants"

const useDefaultPartner = (options = {}) => {
  const { locale, defaultLocale } = useIntl()
  const { isLoading, isAuthenticated, user, apiPermissions } = useAuth()
  const { partnerClient } = useApolloApiClients()

  const partnerPart =
    options?.isFullData === true
      ? `
    fragment partnerPart on PartnerNode {
      id
      name
      email
      communicationLanguage
      displayName
      descriptionEn
      descriptionIt
      descriptionEs
      shortDescriptionEn
      shortDescriptionIt
      shortDescriptionEs
      whySupportEn
      whySupportIt
      whySupportEs
      reviewStatus
      status
      isFeatured
      logo
      country
      city
      postCode
      addressLine1
      addressLine2
      taxId
      website
      facebook
      instagram
      linkedin
      twitter
      youtube
      isEligibleForSupporterCollection
      isEligibleForSupporterEvent
      paymentDestinations {
        accountName
        bankName
        iban
        country
        sortCode
        accountNumber
        bic
        id
      }
      defaultCampaign {
        id
        recurringIsEnabled
        recurringAmountOne
        recurringAmountTwo
        recurringAmountThree
      }
      images {
        edges {
          node {
            id
            order
            image
          }
        }
      }
    }
  `
      : `
  fragment partnerPart on PartnerNode {
    displayName
    name
    id
    status
    email
    pacBalance
  }
  `

  const defaultPartnerQuery = gql`
    query DefaultPartnerQuery {
      partner {
        ...partnerPart
      }
    }
    ${partnerPart}
  `
  const defaultPartnerQueryWithBalance = gql`
    query DefaultPartnerQuery {
      partner {
        ...partnerPart
      }
    }
    ${partnerPart}
  `

  const { loading, data, error, refetch } = useQuery(
    apiPermissions?.includes(API_PERMISSIONS.PARTNER_VIEW_WALLET)
      ? defaultPartnerQueryWithBalance
      : defaultPartnerQuery,
    {
      ...options,
      skip:
        options?.skip ||
        isLoading ||
        !isAuthenticated ||
        !user ||
        !user?.isPartner,
      client: partnerClient,
      fetchPolicy: "cache-and-network",
    }
  )

  const partner = data?.partner ?? null

  return {
    defaultPartner: partner
      ? {
          ...partner,
          logo:
            getValueForLocale(partner, "logo", locale, defaultLocale) ??
            partner?.logo,
        }
      : null,
    loading,
    error,
    refetch,
  }
}

export default useDefaultPartner
