import { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

const useCurrency = () => {
  const {
    site: {
      siteMetadata: { defaultCurrency },
    },
  } = useStaticQuery(graphql`
    query DefaultCurrency {
      site {
        siteMetadata {
          defaultCurrency
        }
      }
    }
  `)
  const [currency, setCurrency] = useState(defaultCurrency ?? "EUR")

  return { currency, setCurrency }
}

export default useCurrency
