import styled from "@emotion/styled"

export const StyledPageTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const StyledPageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.blue};
    font-size: 1rem;
    line-height: 1.5rem;
    padding: unset;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
  }
`

export const StyledHowToUseContainer = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.625rem;

  .react-tooltip__show,
  .react-tooltip__closing {
    max-width: 13.0625rem;
    margin-top: -0.2rem;
    padding: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.15) 0 0.9375rem 1.5625rem,
      rgba(0, 0, 0, 0.05) 0 0.3125rem 0.625rem;

    &.referral-code {
      font-family: ${(p) => p.theme.fontFamilies.regular};
      font-weight: ${(p) => p.theme.fontWeights.liteMedium};
      font-style: ${(p) => p.theme.fontStyles.normal};
      font-size: 0.75rem;
      line-height: 1.25rem;
      text-transform: initial;
    }
  }

  .react-tooltip__show {
    opacity: 1;
  }
`

export const StyledHowToUseText = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.blue};
  font-size: 1rem;
  line-height: 1.5rem;
  cursor: pointer;
  justify-self: end;
  align-self: center;
  text-align: left;
  margin: 0;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.75rem;
    line-height: 1.75rem;
  }
`

export const StyledHowToUseIcon = styled.div`
  color: ${(p) => p.theme.colors.blue};

  svg {
    margin-right: 1.5rem;
    cursor: pointer;
  }
`

export const StyledPageInfo = styled.div`
  margin-bottom: 1.5rem;

  p {
    font-family: ${(p) => p.theme.fontFamilies.headlineBig};
    font-weight: ${(p) => p.theme.fontWeights.liteBlack};
    font-style: ${(p) => p.theme.fontStyles.normal};
    color: ${(p) => p.theme.colors.disabled};
    font-size: 0.75rem;
    line-height: 1.25rem;
    letter-spacing: 0.0225rem;
    text-transform: uppercase;
  }
`

export const StyledTable = styled.div`
  td,
  tr {
    padding-right: unset !important;

    @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      padding-right: 1rem !important;
      padding-left: unset !important;
    }
  }

  td,
  tr,
  span,
  div,
  p {
    font-family: ${(p) => p.theme.fontFamilies.headline} !important;
    font-weight: ${(p) => p.theme.fontWeights.semiBolder} !important;
    font-style: ${(p) => p.theme.fontStyles.normal};

    @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-family: ${(p) => p.theme.fontFamilies.regular} !important;
      font-weight: ${(p) => p.theme.fontWeights.liteMedium} !important;
      font-style: ${(p) => p.theme.fontStyles.normal};

      &.name {
        font-family: ${(p) => p.theme.fontFamilies.headline} !important;
        font-weight: ${(p) => p.theme.fontWeights.semiBolder} !important;
        font-style: ${(p) => p.theme.fontStyles.normal};
      }
    }
  }

  tr > td {
    height: 4rem;
  }

  tr th * {
    font-family: ${(p) => p.theme.fontFamilies.headlineBig} !important;
    font-weight: ${(p) => p.theme.fontWeights.semiBolder} !important;
    font-style: ${(p) => p.theme.fontStyles.normal};
  }

  thead > tr > th:first-child {
    div {
      padding-left: 0;
    }
  }

  .copy-link,
  .condition {
    color: ${(p) => p.theme.colors.blue};
  }

  .align-right {
    text-align: right !important;
    justify-content: end !important;
  }

  .nowrap {
    white-space: nowrap;
  }

  .align-center {
    text-align: center !important;
    justify-content: center !important;
  }

  .mobile-related-id {
    font-family: ${(p) => p.theme.fontFamilies.regular} !important;
    font-weight: ${(p) => p.theme.fontWeights.liteMedium} !important;
    font-style: ${(p) => p.theme.fontStyles.normal};
    color: ${(p) => p.theme.colors.disabled};
    font-size: 0.625rem;
  }

  .tooltip {
    box-shadow: 0 0 0.3125rem rgba(122, 119, 118, 0.5) !important;
    background-color: ${(p) => p.theme.colors.white};
    color: ${(p) => p.theme.colors.pitchBlack} !important;
  }

  .symbol {
    font-size: 1rem;
  }

  .dot-menu {
    .react-select__input-container {
      display: none;
    }
    svg {
      color: ${(p) => p.theme.colors.pitchBlack};
    }

    @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      margin-right: -1rem;
      svg {
        color: ${(p) => p.theme.colors.blue};
      }
    }
  }
`

export const StyledNewReferralCode = styled.div`
  button {
    width: 100%;
  }

  .blue-button {
    color: ${(p) => p.theme.colors.white};
    background-color: ${(p) => p.theme.colors.pacificBlue};
    :disabled {
      background-color: ${(p) => p.theme.colors.gray};
    }
  }

  .button-margin {
    margin-left: 0;
    margin-right: 0;
    width: 92vw;
    white-space: normal;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      width: 13rem;
      position: relative;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      width: 13rem;
    }
  }
`
