import styled from "@emotion/styled"
import { css } from "@emotion/react"

export const StyledAssetTable = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledAssetNameInfo = styled.div`
  display: flex;
  align-items: flex-end;
`

export const StyledAssetName = styled.div`
  flex: 3;

  a {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.blue};
    text-align: left;

    font-size: 0.75rem;
    line-height: 1.125rem;
    letter-spacing: 0.01em;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 1.125rem;
      line-height: 1.75rem;
      letter-spacing: none;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      font-size: 1.125rem;
      line-height: 1.75rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 2rem;
      line-height: 2.75rem;
    }
  }
`

export const StyledAssetInfo = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  color: ${(p) => p.theme.colors.disabled};
  text-align: right;

  font-size: 0.625rem;
  line-height: 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 0.75rem;
    line-height: 1.25rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
`

export const StyledAssetDescription = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  color: ${(p) => p.theme.colors.pitchBlack};

  margin-top: 0.25rem;
  font-size: 0.75rem;
  line-height: 1.25rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-top: 0.5rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin-top: 0.75rem;
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
`

export const StyledAssetHr = styled.hr`
  margin: unset;

  ${(p) =>
    p?.top &&
    css`
      margin-top: ${p?.top}rem !important;
    `};

  ${(p) =>
    p?.bottom &&
    css`
      margin-bottom: ${p.bottom}rem !important;
    `};
`
