import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import { useQuery } from "@apollo/client"
import { useApolloApiClients } from "@tmu/apollo/client"
import { useSupportedLanguages } from "@tmu/hooks"
import DownloadCenterTable from "@tmu/components/dashboard/dashboardCommon/DownloadCenterTable/"
import { Spinner } from "@tmu/components/common"
import { MERCHANT_ALL_ASSETS_LISTING_QUERY } from "@tmu/apollo/dashboard/queries/merchant"
import { PARTNER_ALL_ASSETS_LISTING_QUERY } from "@tmu/apollo/dashboard/queries/partner"
import { USER_ALL_ASSETS_LISTING_QUERY } from "@tmu/apollo/dashboard/queries/user"
import { PER_PAGE, COMMON_MIME_TYPES } from "@tmu/apollo/constants"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import {
  StyledPage,
  StyledPageContent,
  StyledPageTitle,
} from "@tmu/global/page-addons/dashboard.styles"

const DownloadCenter = () => {
  const { isTablet } = getAllScreenTypes()

  const { merchantClient, partnerClient, storefrontClient } =
    useApolloApiClients()
  const { getLanguageLabelForLanguageValue } = useSupportedLanguages()

  let isUser = false
  let isCharities = false
  let isMerchants = false

  if (typeof window !== "undefined") {
    const pageUrl = new URL(window?.location?.href)
    isUser = pageUrl.pathname.includes("/user")
    isCharities = pageUrl.pathname.includes("/charities")
    isMerchants = pageUrl.pathname.includes("/merchants")
  }

  const query = isUser
    ? USER_ALL_ASSETS_LISTING_QUERY
    : isCharities
    ? PARTNER_ALL_ASSETS_LISTING_QUERY
    : isMerchants
    ? MERCHANT_ALL_ASSETS_LISTING_QUERY
    : USER_ALL_ASSETS_LISTING_QUERY

  const client = isUser
    ? storefrontClient
    : isCharities
    ? partnerClient
    : isMerchants
    ? merchantClient
    : storefrontClient

  const { loading, data } = useQuery(query, {
    variables: { first: PER_PAGE },
    client,
    fetchPolicy: "cache-and-network",
  })

  const getCommonMimeTypeLabelForCommonMimeTypeValue = (
    commonMimeTypeValue
  ) => {
    const selectedCommonMimeType = COMMON_MIME_TYPES.find(
      (commonMimeType) => commonMimeType.value === commonMimeTypeValue
    )

    return selectedCommonMimeType ? selectedCommonMimeType.label : null
  }

  const convertBytesToMegabytes = (bytes) => {
    const megabytes = bytes / (1024 * 1024)
    return megabytes.toFixed(2).replace(".", ",")
  }

  const allAssets = data?.allAssets?.edges.map(({ node }) => {
    let commonMimeTypeLabel = getCommonMimeTypeLabelForCommonMimeTypeValue(
      node?.mimetype
    )

    let sizeInMB = convertBytesToMegabytes(node?.size)
    if (sizeInMB === "0,00") {
      sizeInMB = "1,00"
    }

    let languageLabel = getLanguageLabelForLanguageValue(node?.language)

    return {
      ...node,
      mimetype: commonMimeTypeLabel,
      size: `${sizeInMB} mb`,
      language: languageLabel,
    }
  })

  return (
    <StyledPage className={!isTablet ? "download-center" : ""}>
      <StyledPageTitle className={!isTablet ? "download-center" : ""}>
        <h1 data-testid="dashboard-download-center-page-title">
          <FormattedMessage
            id="dashboard::nav::downloadCenter"
            defaultMessage="Download Center"
          />
        </h1>
      </StyledPageTitle>
      <StyledPageContent data-testid="dashboard-download-center-page-body">
        {loading ? (
          <Spinner condensed />
        ) : allAssets?.length ? (
          <DownloadCenterTable allAssets={allAssets} />
        ) : null}
      </StyledPageContent>
    </StyledPage>
  )
}

export default DownloadCenter
