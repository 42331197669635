import styled from "@emotion/styled"

export const StyledMainContainer = styled.div`
  background: ${(p) => p.theme.colors.whiteSmoke};

  display: grid;
  grid-template-rows: 1fr;
  grid-row-gap: 2.5rem;

  padding: 2.0938rem 0 3rem;

  svg {
    height: 1.75rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: unset;

    max-width: ${(p) => p.theme.breakpoints.tablet};
    padding: 2.5rem 1.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    max-width: ${(p) => p.theme.breakpoints.desktop};
    padding: 2.5rem 2rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    grid-column-gap: 1.75rem;

    max-width: ${(p) => p.theme.breakpoints.wide};
    padding: 2.5rem 6rem;
    svg {
      height: 2.75rem;
    }
  }

  .support-charity-center-button {
    grid-column-start: 2;
    width: 100%;

    button {
      font-family: ${(p) => p.theme.fontFamilies.headline};
      font-style: ${(p) => p.theme.fontStyles.normal};
      font-weight: ${(p) => p.theme.fontWeights.semiBolder};
      color: ${(p) => p.theme.colors.white};

      text-align: center;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      white-space: normal;

      width: 100%;

      @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
        font-size: 0.75rem;
        line-height: 1rem;
        margin-top: 1.5rem;
      }

      @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      }

      @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
        font-size: 1rem;
        line-height: 1.375rem;
        height: 3.375rem;
      }
    }
  }

  .support-charity-bottom-button {
    margin: 0 auto;
    padding: 0.375rem 1rem 0;
    width: 100%;

    button {
      font-family: ${(p) => p.theme.fontFamilies.headline};
      font-style: ${(p) => p.theme.fontStyles.normal};
      font-weight: ${(p) => p.theme.fontWeights.semiBolder};
      color: ${(p) => p.theme.colors.white};

      text-align: center;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      white-space: normal;

      font-size: 0.75rem;
      line-height: 1rem;

      width: 100%;
      height: 2.5rem;
    }
  }
`

export const StyledLeftContainer = styled.div`
  display: grid;
  grid-row-gap: 0.75rem;
  margin: 0 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    grid-template-rows: 1.875rem 1fr 2fr;
    margin: unset;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    grid-row-gap: 0.625rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    grid-template-rows: 1.875rem 0.9fr 3fr;
    grid-row-gap: 0.75rem;
  }
`

export const StyledLeftIcon = styled.div`
  text-align: center;
  font-size: 1.1487rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    text-align: unset;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 1.75rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
  }
`

export const StyledLeftTitle = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.pitchBlack};
  text-align: center;

  font-size: 1.125rem;
  line-height: 1.5rem;

  margin-top: 0.25rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    text-align: unset;

    margin-top: 0.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 1.5rem;
    line-height: 2rem;

    margin-top: 1rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
    line-height: 2.625rem;

    margin-top: 1.875rem;
  }
`

export const StyledLeftContent = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  color: ${(p) => p.theme.colors.pitchBlack};
  text-align: center;

  font-size: 0.75rem;
  line-height: 1.25rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    text-align: unset;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 1rem;
    line-height: 1.375rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
    line-height: 2.625rem;
  }
`

export const StyledCenterContainer = styled.div`
  display: grid;
  grid-template-rows: unset;
  grid-row-gap: 0.75rem;
  margin: 0 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    grid-template-rows: 1.875rem 1fr 2fr;
    margin: unset;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    grid-row-gap: 0.625rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    grid-template-rows: 1.875rem 0.9fr 3fr;
    grid-row-gap: 0.75rem;
  }
`

export const StyledCenterIcon = styled.div`
  text-align: center;
  font-size: 1.1487rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    text-align: unset;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 1.75rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
  }
`

export const StyledCenterTitle = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.pitchBlack};
  text-align: center;

  font-size: 1.125rem;
  line-height: 1.5rem;

  margin-top: 0.25rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    text-align: unset;

    margin-top: 0.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 1.5rem;
    line-height: 2rem;

    margin-top: 1rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
    line-height: 2.625rem;

    margin-top: 1.875rem;
  }
`

export const StyledCenterContent = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  color: ${(p) => p.theme.colors.pitchBlack};
  text-align: center;

  font-size: 0.75rem;
  line-height: 1.25rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    text-align: unset;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 1rem;
    line-height: 1.375rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
    line-height: 2.625rem;
  }
`

export const StyledRightContainer = styled.div`
  display: grid;
  grid-template-rows: unset;
  grid-row-gap: 0.75rem;
  margin: 0 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    grid-template-rows: 1.875rem 1fr 2fr;
    margin: unset;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    grid-row-gap: 0.625rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    grid-template-rows: 1.875rem 0.9fr 3fr;
    grid-row-gap: 0.75rem;
  }
`

export const StyledRightIcon = styled.div`
  text-align: center;
  font-size: 1.1487rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    text-align: unset;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 1.75rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
  }
`

export const StyledRightTitle = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.pitchBlack};
  text-align: center;

  font-size: 1.125rem;
  line-height: 1.5rem;

  margin-top: 0.25rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    text-align: unset;

    margin-top: 0.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 1.5rem;
    line-height: 2rem;

    margin-top: 1rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
    line-height: 2.625rem;

    margin-top: 1.875rem;
  }
`

export const StyledRightContent = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  color: ${(p) => p.theme.colors.pitchBlack};
  text-align: center;

  font-size: 0.75rem;
  line-height: 1.25rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    text-align: unset;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 1rem;
    line-height: 1.375rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
    line-height: 2.625rem;
  }
`
