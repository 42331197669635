import React, { useRef } from "react"
import PropTypes from "prop-types"
import { FormattedMessage, useIntl, navigate } from "gatsby-plugin-intl"
import { StyledSlice, StyledSliceHeader } from "./index.styles"
import { Swiper, NewCard, Button } from "@tmu/components/common"
import { getValueForLocale } from "@tmu/utils/string"

const Campaigns = ({
  campaigns,
  title = "",
  headline,
  buttonText = "",
  cardVariant,
  withDots,
  className,
  isStoreSupporter,
  isCorporate,
  isCorporateStore,
  isStoreCampaign,
  hideDetails,
  isMerchantOffline,
}) => {
  const { locale, defaultLocale } = useIntl()
  const ref = useRef()

  const handleViewAll = () => {
    isCorporate || isCorporateStore
      ? navigate("/corporate")
      : navigate("/campaigns?campaign=true&charity=false")
  }

  return (
    <StyledSlice data-testid="campaigns" ref={ref} className={className}>
      {headline || (
        <StyledSliceHeader>
          <h3>
            {title || (
              <FormattedMessage
                id="homepage::campaigns::popularCampaigns"
                defaultMessage="Popular Campaigns"
              />
            )}
          </h3>
          <Button
            variant="link"
            text={
              buttonText || (
                <FormattedMessage id="ui::viewAll" defaultMessage="View all" />
              )
            }
            size="big"
            onClick={handleViewAll}
          />
        </StyledSliceHeader>
      )}
      <Swiper
        withDots={withDots}
        isStoreCampaign={isStoreCampaign}
        isSmall={hideDetails}>
        {campaigns?.map(({ node: campaign }) => {
          return (
            <NewCard
              hideDetails={hideDetails}
              variant={cardVariant || "campaign"}
              key={campaign?.id}
              id={campaign?.id}
              imageSrc={
                campaign?.image?.url ||
                campaign?.images?.edges?.[0]?.node?.image ||
                campaign?.logo
              }
              title={getValueForLocale(campaign, "name", locale, defaultLocale)}
              type={campaign?.campaignType}
              totalDonors={
                campaign?.donationCountForDisplay ?? campaign?.donationCount
              }
              totalSupport={
                campaign?.fundedAmountForDisplay ?? campaign?.fundedAmount
              }
              size="default"
              cardLink={
                isCorporateStore
                  ? `/offers/store/${campaign?.slug}`
                  : `/campaigns/${campaign?.slug}`
              }
              goalAmount={campaign?.goalAmount}
              fundedAmount={campaign?.fundedAmount}
              partnerLogo={getValueForLocale(
                campaign?.partner,
                "logo",
                locale,
                defaultLocale
              )}
              storeLogo={getValueForLocale(
                campaign?.store,
                "logo",
                locale,
                defaultLocale
              )}
              partnerName={
                campaign?.partner?.displayName || campaign?.partner?.name
              }
              pacMultiplier={campaign?.pacMultiplier}
              isStoreSupporter={isStoreSupporter}
              isCorporate={
                isCorporate ||
                campaign?.store?.defaultOffer?.offerType === "BRAND"
              }
              isOfflineCampaign={
                campaign?.store?.defaultOffer?.offerType === "OFFLINE"
              }
              isCorporateStore={isCorporateStore}
            />
          )
        })}
      </Swiper>
    </StyledSlice>
  )
}

Campaigns.propTypes = {
  campaigns: PropTypes.arrayOf(PropTypes.any).isRequired,
}

Campaigns.defaultProps = {
  campaigns: [],
}

export default Campaigns
