import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import PropTypes from "prop-types"
import React from "react"
import {
  StyledAccordionSection,
  StyledAccordionSectionHeader,
} from "./index.styles"

const AccordionSection = ({
  label,
  isOpen,
  onClick,
  icon,
  dataTestId = "",
  children,
  index,
}) => {
  return (
    <StyledAccordionSection
      expanded={isOpen && children?.length === 0}
      data-testid={`accordion-section${dataTestId && `-${dataTestId}`}` + index}
      className="accordion-section-top">
      <StyledAccordionSectionHeader
        onClick={onClick}
        data-testid={
          `accordion-section-header${dataTestId && `-${dataTestId}`}` + index
        }>
        {label}
        {icon && children && <FontAwesomeIcon icon={icon} />}
      </StyledAccordionSectionHeader>
      {isOpen && <div className="accordion-section">{children}</div>}
    </StyledAccordionSection>
  )
}

AccordionSection.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
  isOpen: PropTypes.bool.isRequired,
  label: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.any,
}

export default AccordionSection
