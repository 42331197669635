import React from "react"
import { EmptyTable } from "@tmu/components/dashboard/dashboardCommon"
import {
  CustomModal,
  Table,
  Date as CustomDate,
  Logo,
  PurchaseDetail,
  Button,
  Spinner,
} from "@tmu/components/common"
import { FormattedMessage } from "gatsby-plugin-intl"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { parseId } from "@tmu/utils/auth"
import theme from "@tmu/global/theme"
import { useState } from "react"
import Money from "@tmu/components/common/Money"
import {
  StyledDateContainer,
  StyledPacContainer,
  StyledPageWrapper,
  StyledTable,
} from "./index.styles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons"
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus"
import useAllOrders from "@tmu/hooks/useAllOrders"
import { PER_PAGE, VISIBLE_SALES_STATUSES } from "@tmu/apollo/constants"
import { StyledPageActions } from "@tmu/global/page-addons/dashboard.styles"
import { format } from "date-fns"
import { useIntl } from "gatsby-plugin-intl"

const OrdersTable = ({}) => {
  const [detailStatus, setDetailStatus] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [sortInfo, setSortInfo] = useState([{ id: "date", desc: true }])
  const [firstLoad, setFirstLoad] = useState(false)
  const { formatMessage } = useIntl()

  const perPage = PER_PAGE
  const variables = {
    first: perPage,
    status_In: VISIBLE_SALES_STATUSES,
    orderBy: "-created",
  }
  const options = {
    variables,
    onCompleted: () => setFirstLoad(true),
  }
  const { orders, loading, fetchMore, pageInfo, callOrders, handleLoadMore } =
    useAllOrders(options)
  const { isTablet, isWide } = getAllScreenTypes()

  const tableData =
    orders?.length > 0
      ? orders?.map((item) => {
          return {
            orderId: parseId(item?.id),
            name: item?.store?.displayName || item?.store?.name,
            pacs: "-" + (item?.pacAmount || 0),
            price: null,
            date: item?.created,
            order: item,
            amount: item?.frozenTotal || 0,
            subRows: item?.cartItems?.map((cartItem) => {
              return {
                name: cartItem?.offer?.name,
                voucherCodes: cartItem?.tickets?.edges
                  ?.map((item) => item.node?.voucherCode)
                  .filter((item) => item),
                endDate: cartItem?.offer?.endDate
                  ? format(new Date(cartItem?.offer?.endDate), "dd.MM.yy")
                  : "",
                quantity: cartItem?.quantity || 0,
                price: cartItem?.frozenPrice || 0,
                // amount: cartItem?.frozenTotal || 0,
                // pacs: "-" + (cartItem?.frozenPacAmount || 0),
              }
            }),
          }
        })
      : []

  const openDetail = (row) => {
    setSelectedRow(row)
    setDetailStatus(true)
  }

  const closeDetail = () => {
    setSelectedRow(null)
    setDetailStatus(false)
  }

  const handleSort = (sortBy) => {
    setSortInfo(sortBy)

    const sortValue = getSortValue(sortBy)
    callOrders({ variables: { ...variables, orderBy: sortValue } })
  }

  const voucherText = formatMessage({
    id: "orders::table::Voucher",
    defaultMessage: "Voucher",
  })

  const expirationDateText = formatMessage({
    id: "orders::table::expirationDate",
    defaultMessage: "expiration date",
  })

  const getSortValue = (sortBy) => {
    const sortData = sortBy?.at(0)

    const direction = sortData?.desc === true ? "-" : ""
    let sortField = null

    switch (sortData?.id) {
      case "date":
        sortField = "created"
        break
      case "amount":
        sortField = "frozenTotal"
        break

      default:
        sortField = null
        break
    }
    return sortField ? direction + sortField : null
  }

  const columns = [
    {
      Header: (props) => (
        <div className="header-text">
          <FormattedMessage
            id="dashboard::tableHeader::purchases"
            defaultMessage="Purchases"
          />
        </div>
      ),
      Cell: ({ row }) => {
        return (
          <div>
            <p>{row?.values?.name}</p>
            {isTablet ? (
              <>
                <p className="mobile-date">{row?.original?.orderId}</p>
                {row?.original?.voucherCodes?.map((voucherCode, i) => {
                  return (
                    <p className="mobile-date no-wrap">
                      {`${voucherText}${
                        row?.original?.voucherCodes.length > 1
                          ? "-" + (i + 1)
                          : ""
                      }: ${voucherCode} ${
                        row?.original?.endDate?.length
                          ? expirationDateText + " " + row?.original?.endDate
                          : ""
                      }`}
                    </p>
                  )
                })}
              </>
            ) : (
              <p className="mobile-date">
                <CustomDate
                  value={row?.values?.date}
                  showYear={true}
                  second="numeric"
                />
              </p>
            )}
          </div>
        )
      },
      accessor: "name",
      disableSort: true,
    },
    {
      Header: (props) => (
        <div className="header-text align-right">
          <FormattedMessage
            id="dashboard::tableHeader::quantity"
            defaultMessage="Quantity"
          />
        </div>
      ),
      Cell: (props) => <div className="align-right">{props?.value}</div>,
      accessor: "quantity",
      disableSort: true,
    },
    {
      Header: (props) => (
        <div className="header-text header-icon align-right">
          <FormattedMessage
            id="dashboard::tableHeader::price"
            defaultMessage="Price"
          />
        </div>
      ),
      Cell: ({ value }) => (
        <div className="align-right">{value && <Money value={value} />}</div>
      ),
      accessor: "price",
      disableSort: true,
    },
    {
      Header: (props) => (
        <div className="header-text header-icon align-right">
          <FormattedMessage
            id="dashboard::tableHeader::amount"
            defaultMessage="Amount"
          />
        </div>
      ),
      Cell: ({ value }) => (
        <div className={`align-right ${!isTablet && "lite-font"}`}>
          {value && <Money value={value} />}
        </div>
      ),
      accessor: "amount",
      headerClassName: !isTablet && "align-header-right",
    },

    {
      Header: (props) => (
        <span className={`header-text header-icon align-center`}>
          <FormattedMessage
            id="dashboard::tableHeader::dateAndTime"
            defaultMessage="Date / Time"
          />
        </span>
      ),
      Cell: ({ row }) => (
        <StyledDateContainer className="align-center">
          <CustomDate
            value={row?.values?.date}
            showYear={true}
            second="numeric"
          />
        </StyledDateContainer>
      ),
      accessor: "date",
      headerClassName: "align-header-center",
    },
    {
      Header: (props) => (
        <div className="header-text header-icon align-right">
          <FormattedMessage
            id="dashboard::tableHeader::pacsAmount"
            defaultMessage="PACs Amount"
          />
        </div>
      ),
      Cell: (props) =>
        props?.value ? (
          <StyledPacContainer className="align-right">
            <div>
              <Money value={props?.value || 0} currency={false} />
            </div>
            <Logo
              className="pac-icon"
              width={isWide ? 25 : 19}
              height={isWide ? 32 : 24}
              fill={theme?.colors?.carrot}
            />
          </StyledPacContainer>
        ) : null,
      accessor: "pacs",
      disableSort: true,
    },
    {
      Header: ({ getToggleAllRowsExpandedProps }) => (
        <span {...getToggleAllRowsExpandedProps()}></span>
      ),
      id: "icon",
      Cell: ({ row }) => {
        const icon = row?.isExpanded ? faChevronUp : faChevronDown
        return row?.canExpand ? (
          <div className="align-center chevron">
            <span {...row.getToggleRowExpandedProps({})}>
              <FontAwesomeIcon icon={icon} />
            </span>
          </div>
        ) : (
          <></>
        )
      },
    },
  ]
  const hiddenColumns = isTablet ? [] : ["price", "quantity", "date", "icon"]
  const handleRowClick = (row, index) => {
    openDetail(row)
    if (typeof onRowSelect === "function") {
      onRowSelect(row, index)
    }
  }

  return loading || !firstLoad ? (
    <Spinner condensed />
  ) : orders?.length > 0 ? (
    <StyledPageWrapper>
      <StyledTable>
        <Table
          columns={columns}
          data={tableData}
          hiddenColumns={hiddenColumns}
          initialSort={sortInfo}
          headerLineSeperator={true}
          onRowClick={!isTablet ? handleRowClick : null}
          expandClassName="expanded-row"
          expandingClassName="expanding-row"
          options={{ expand: true, sort: true }}
          handleSort={handleSort}
        />
      </StyledTable>
      <CustomModal
        isModalOpen={!isTablet && selectedRow && detailStatus}
        cancelButtonAction={closeDetail}
        children={
          <PurchaseDetail
            order={selectedRow?.original?.order}
            rowData={selectedRow}
          />
        }
        isMobile={!isTablet}
        isCloseIcon={true}
        style={{
          overflow: "hidden",
        }}
      />
      {pageInfo?.hasNextPage && (
        <StyledPageActions align="center" data-testid="view-more">
          <Button
            data-testid="button-view-more"
            label="View More"
            variant="text"
            onClick={() => handleLoadMore(getSortValue(sortInfo))}>
            <FontAwesomeIcon icon={faPlus} />
            <FormattedMessage id="ui::viewMore" defaultMessage="View More" />
          </Button>
        </StyledPageActions>
      )}
    </StyledPageWrapper>
  ) : (
    <EmptyTable
      emptyMessage={
        <FormattedMessage
          id="dashboard::sales::empty"
          defaultMessage="It seems there are no sales yet"
        />
      }
    />
  )
}

export default OrdersTable
