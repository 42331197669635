import HowtoGetStarted from "./HowtoGetStarted"
import GetStartedIntro from "./GetStartedIntro"
import TabContent from "./TabContent"
import TabContentDigitalBanking from "./TabContentDigitalBanking"
import CurrentInTMU from "./CurrentInTMU"
import HowItWorksForm from "./HIWForm"
import JoinPageComponent from "./Join"

export {
  HowtoGetStarted,
  GetStartedIntro,
  TabContent,
  CurrentInTMU,
  HowItWorksForm,
  TabContentDigitalBanking,
  JoinPageComponent,
}
