import styled from "@emotion/styled"
import { Button } from "@tmu/components/common"
import { css } from "@emotion/react"

export const StyledLabel = styled.span`
  display: block;
  position: relative;
  padding-left: 0 !important;
  cursor: auto;
  user-select: none;
  line-height: 0;

  &:hover input ~ span {
    background-color: transparent;
  }

  a {
    color: ${(p) => p.theme.colors.blue} !important;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1rem !important;
    a {
      font-size: 1rem !important;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    ${(p) =>
      p.page === "donation"
        ? css`
            //font-size: 1.5rem !important;
            button > a,
            p {
              // font-size: 1.5rem !important;
            }
          `
        : css``}

    a {
    //  font-size: 1.5rem !important;
    }
  }
`

export const StyledLink = styled(Button)`
  padding: 0;
  margin: 0;
  width: auto;
  min-height: auto !important;
  line-height: 1;
  height: auto;
  display: inline-block;
  font-size: inherit;
  color: ${(p) => p.theme.colors.blue} !important;
  font-weight: ${(p) => p.theme.fontWeights.bold};
`
