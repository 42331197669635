import React from "react"
import { Router } from "@reach/router"
import { Users } from "@tmu/components/dashboard/dashboardPages"
import { useAuth } from "@tmu/hooks"
import { API_PERMISSIONS } from "@tmu/apollo/constants"

const PartnersUsersRoute = () => {
  const { apiPermissions } = useAuth()

  return (
    <Router>
      {apiPermissions?.includes(API_PERMISSIONS.PARTNER_MANAGE_USERS) && (
        <Users path="/*" dashboardType="partners" />
      )}
    </Router>
  )
}

export default PartnersUsersRoute
