import { enUS, it, es } from "date-fns/esm/locale"

export const REVIEW_STATUS = {
  IN_PROGRESS: "IN_PROGRESS",
  WAITING_FOR_APPROVAL: "WAITING_FOR_APPROVAL",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  ENDED: "ENDED",
  PENDING: "PENDING",
  READY: "READY",
}

export const PRODUCT_CLASSES = {
  ESERVICE: "ESERVICE",
  STANDARD: "STANDARD",
  EBOOK: "EBOOK",
  SAAS: "SAAS",
  REDUCED: "REDUCED",
}

export const SUPPORTED_LANGUAGES = [
  { value: "EN", label: "English" },
  { value: "IT", label: "Italian" },
  { value: "ES", label: "Spanish" },
  // { value: "DE", label: "German" },
]

export const USED_TECHNOLOGY_OPTIONS = [
  { value: "Shopify", label: "Shopify" },
  { value: "Woocommerce", label: "Woocommerce" },
  { value: "Prestashop", label: "Prestashop" },
]

export const PRODUCT_USAGES = {
  MULTI_USE: "MULTI_USE",
  SINGLE_USE: "SINGLE_USE",
}

export const VISIBLE_SALES_STATUSES = [
  // "IN_PROGRESS",
  "COMPLETED",
  // "PAYMENT_INITIATED",
  // "DISCARDED",
]

export const CARDS_PER_PAGE = 30
export const PER_PAGE = 8
export const MAX_PER_PAGE = 50
export const MAX_PRODUCT_QUANTITY = 10
export const PAC_BALANCE_UPDATE_INTERVAL = 1000
export const PAC_BALANCE_LIMIT_INTERVAL = 5
export const ANNUALLY_MULTIPLIER_FOR_RECURRING_DONATION = 12
export const DEFAULT_CURRENCY_SYMBOL = "€"
export const CURRENCY_DIGIT_COUNT = 7

export const MAX_TIERS = 15
export const MAX_TEXT_LENGTH = 5000
export const ENTRY_DONATION_MAX_TEXT_LENGTH = 180
export const SHORT_DESCRIPTION_MAX_TEXT_LENGTH = 96

export const FIELD_LENGTHS = {
  name: 128,
  shortDescription: 300,
}

export const SWAGGER_DOC_URL = process.env.API_EXTERNAL_MERCHANT_SWAGGER_URL

export const API_PERMISSIONS = {
  PARTNER_VIEW_ACCOUNT_INFORMATION: "partner-view-account-information",
  PARTNER_UPDATE_ACCOUNT_INFORMATION: "partner-update-account-information",
  PARTNER_MANAGE_USERS: "partner-manage-users",
  PARTNER_VIEW_REPORTS: "partner-view-reports",
  PARTNER_VIEW_NOTIFICATION_CENTER: "partner-view-notification-center",
  PARTNER_CREATE_CAMPAIGN: "partner-create-campaign",
  PARTNER_VIEW_CAMPAIGN_LISTING: "partner-view-campaign-listing",
  PARTNER_UPDATE_CAMPAIGN_CONTENT: "partner-update-campaign-content",
  PARTNER_DELETE_CAMPAIGN: "partner-delete-campaign",
  PARTNER_END_CAMPAIGN: "partner-end-campaign",
  PARTNER_PUBLISH_CAMPAIGN: "partner-publish-campaign",
  PARTNER_VIEW_WALLET: "partner-view-wallet",
  PARTNER_REFUND_WALLET: "partner-refund-wallet",
  PARTNER_INTERCOM: "partner-intercom",
  PARTNER_UPDATE_SUPPORTER_CAMPAIGN_REVIEW:
    "partner-update-supporter-campaign-review",
  PARTNER_CREATE_SUPPORTER_CAMPAIGN_REVIEW:
    "partner-create-supporter-campaign-review",
  PARTNER_VIEW_SUPPORTER_CAMPAIGN_REVIEW_LISTING:
    "partner-view-supporter-campaign-review-listing",
  PARTNER_SUBSCRIPTION_MANAGEMENT: "partner-subscription-management",
  PARTNER_VIEW_REFERRAL_LINK: "partner-view-referral-listing",
  PARTNER_CREATE_REFERRAL_LINK: "partner-create-referral-link",
  PARTNER_UPDATE_REFERRAL_LINK: "partner-update-referral-link",
  PARTNER_DELETE_REFERRAL_LINK: "partner-delete-referral-link",
  PARTNER_VERIFICATION_VOUCHER: "partner-verification-voucher",

  MERCHANT_VIEW_ACCOUNT_INFORMATION: "merchant-view-account-information",
  MERCHANT_UPDATE_ACCOUNT_INFORMATION: "merchant-update-account-information",
  MERCHANT_MANAGE_INFORMATION: "merchant-manage-information",
  MERCHANT_MANAGE_USER: "merchant-manage-user",
  MERCHANT_VIEW_REPORTS: "merchant-view-reports",
  MERCHANT_VIEW_NOTIFICATION_CENTER: "merchant-view-notification-center",
  MERCHANT_CREATE_STORE: "merchant-create-store",
  MERCHANT_VIEW_PRODUCT_LISTING: "merchant-view-product-listing",
  MERCHANT_CREATE_PRODUCT: "merchant-create-product",
  MERCHANT_UPDATE_PRODUCT: "merchant-update-product",
  MERCHANT_DELETE_PRODUCT: "merchant-delete-product",
  MERCHANT_PUBLISH_PRODUCT: "merchant-publish-product",
  MERCHANT_VIEW_ORDER: "merchant-view-order",
  MERCHANT_REFUND_ORDER: "merchant-refund-order",
  MERCHANT_UNPUBLISH_STORE: "merchant-unpublish-store",
  MERCHANT_VIEW_WALLET: "merchant-view-wallet",
  MERCHANT_REFUND_WALLET: "merchant-refund-wallet",
  MERCHANT_INTERCOM: "merchant-intercom",
  MERCHANT_CREATE_CAMPAIGN: "merchant-create-campaign",
  MERCHANT_VIEW_CAMPAIGN_LISTING: "merchant-view-campaign-listing",
  MERCHANT_UPDATE_CAMPAIGN_CONTENT: "merchant-update-campaign-content",
  MERCHANT_END_CAMPAIGN: "merchant-end-campaign",
  MERCHANT_DELETE_CAMPAIGN: "merchant-delete-campaign",
  MERCHANT_VIEW_PLUGIN_MANAGEMENT: "merchant-view-plugin-management",
  MERCHANT_UPDATE_PLUGIN_MANAGEMENT: "merchant-update-plugin-management",
  MERCHANT_VIEW_REFERRAL_LINK: "merchant-view-referral-link",
  MERCHANT_CREATE_REFERRAL_LINK: "merchant-create-referral-link",
  MERCHANT_UPDATE_REFERRAL_LINK: "merchant-update-referral-link",
  MERCHANT_DELETE_REFERRAL_LINK: "merchant-delete-referral-link",
  MERCHANT_VERIFICATION_VOUCHER: "merchant-verification-voucher",

  ADMIN_VIEW_ACCOUNT_INFORMATION: "admin-view_account-information",
  ADMIN_MANAGE_USER: "admin-manage-user",
  ADMIN_VIEW_NOTIFICATION_CENTER: "admin-view-notification-center",
  ADMIN_MANAGE_DONOR_OPERATIONS: "admin-manage-donor-operations",
  ADMIN_MANAGE_PARTNER_OPERATIONS: "admin-manage-partner-operations",
  ADMIN_MANAGE_MERCHANT_OPERATIONS: "admin-manage-merchant-operations",
  ADMIN_MANAGE_ADMIN_USERS: "admin-manage-admin-users",
  ADMIN_MANAGE_PAYOUTS: "admin-manage-payouts",
  ADMIN_VIEW_REPORTS: "admin-view-reports",
  ADMIN_KNOW_YOUR_BUSINESS: "admin-know-your-business",
  ADMIN_LEAD_MANAGEMENT: "admin-lead-management",
  ADMIN_INTERCOM: "admin-intercom",
}

export const DEFAULT_MIN_TIER_AMOUNT = 50

export const MAX_DAYS_END_EVENT = 540
export const MERCHANT_MAX_DAYS_END_EVENT = 540
export const DEFAULT_DAY_OF_EVENT_START = 30

export const VISIBILITY_STATUS = {
  UPCOMING: "UPCOMING",
  ARCHIVED: "ARCHIVED",
  UNLISTED: "UNLISTED",
  LISTED: "LISTED",
  FEATURED: "FEATURED",
}

export const LIST_TYPES = {
  LIST: "list",
  TILE: "tile",
}

export const FOOTER_TYPE = {
  TIGHT: "tight",
  WIDE: "wide",
  HIDE: "hide",
}

export const CAMPAIGN_STOP_REASON = {
  SCAM: "SCAM",
  VIOLENCE: "VIOLENCE",
  FALSE_INFORMATION: "FALSE_INFORMATION",
  OTHER: "OTHER",
}

export const TRANSACTION_TYPE_REASON = {
  DONATION: "DONATION",
  PURCHASE: "PURCHASE",
  SIGNUP_GIFT: "SIGNUP_GIFT",
  REFERRAL_GIFT: "REFERRAL_GIFT",
  CAMPAIGN_CONDITION: "CAMPAIGN_CONDITION",
  PARTNER_CONDITION: "PARTNER_CONDITION",
  OFFLINE_PURCHASE: "OFFLINE_PURCHASE",
}

export const RECURRING_DONATION_TYPE = {
  SINGLE: "single",
  MONTHLY: "monthly",
  ANNUALLY: "annually",
}

export const MERCHANT_PAC_RECONCILIATION_STRATEGY = {
  NO_DONATION_NEEDED: "NO_DONATION_NEEDED", // The customer has more PACs than discount amount (no need for a donation)
  PARTIAL_DONATION: "PARTIAL_DONATION", //The customer has partial PACs but the donation amount will be equal or more than 5 Euros
  PARTIAL_DONATION_SURPLUS_PACS: "SURPLUS_PACS", //The customer has partial PACs but the donation amount is less than 5 Euros
  FULL_DONATION: "FULL_DONATION", // user has 0 pacs and total amount is equal or more than 5 euros
  FULL_DONATION_SURPLUS_PACS: "FULL_DONATION_SURPLUS_PACS", // The user has 0 pacs, and the total donation amount is less than 5 euros
}

export const PRODUCT_VARIATION_TICKET_STATUS = {
  USED: "USED",
  REFUND: "REFUND",
  FAIL: "FAIL",
  RESERVED: "RESERVED",
  ACQUIRED: "ACQUIRED",
  CANCELED: "CANCELED",
  EXPIRED: "EXPIRED",
}

export const REQUIRED_FIELD_SYMBOL = "*"

export const OFFER_TYPE = {
  INTERNAL: "INTERNAL",
  OFFLINE: "OFFLINE",
  ONLINE: "ONLINE",
  BRAND: "BRAND",
}

//https://qrfy.com/api/public/folders
export const QR_CODE_FOLDERS = {
  STORES: 358165,
  CAMPAIGNS: 357476,
  CHARITIES: 358166,
}

export const COMMON_MIME_TYPES = [
  { value: "audio/aac", label: "AAC AUDIO" },
  { value: "application/vnd.amazon.ebook", label: "AMAZON KINDLE eBOOK" },
  { value: "text/csv", label: "CSV" },
  { value: "application/msword", label: "MS WORD" },
  {
    value:
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    label: "MS WORD",
  },
  { value: "application/epub+zip", label: "EPUB" },
  { value: "text/html", label: "HTML" },
  { value: "application/xhtml+xml", label: "HTML" },
  { value: "application/json", label: "JSON" },
  { value: "application/pdf", label: "PDF" },
  { value: "application/vnd.ms-powerpoint", label: "MS POWER POINT" },
  {
    value:
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    label: "MS POWER POINT",
  },
  { value: "application/vnd.rar", label: "RAR ARCHIVE" },
  { value: "application/rtf", label: "RTF" },
  { value: "text/plain", label: "TEXT" },
  { value: "application/vnd.visio", label: "VISIO" },
  { value: "audio/wav", label: "WAVEFORM AUDIO" },
  { value: "application/vnd.ms-excel", label: "MS EXCEL" },
  {
    value: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    label: "MS EXCEL",
  },
  { value: "application/xml", label: "XML" },
  { value: "application/zip", label: "ZIP ARCHIVE" },
  { value: "application/x-7z-compressed", label: "7-zip ARCHIVE" },
  { value: "image/bmp", label: "BMP" },
  { value: "image/gif", label: "GIF" },
  { value: "image/jpeg", label: "JPEG" },
  { value: "image/png", label: "PNG" },
  { value: "image/tiff", label: "TIFF" },
]

export const CAMPAIGNS_TABS_MENU = (formatMessage) => [
  {
    label: formatMessage({
      id: "ui::current",
      defaultMessage: "Current",
    }),
    key: "current",
    dataTestId: "current",
  },
  {
    label: formatMessage({
      id: "ui::upcoming",
      defaultMessage: "Upcoming",
    }),
    key: "upcoming",
    dataTestId: "upcoming",
  },
  {
    label: formatMessage({
      id: "ui::finished",
      defaultMessage: "Finished",
    }),
    key: "finished",
    dataTestId: "finished",
  },
  {
    label: formatMessage({
      id: "ui::all",
      defaultMessage: "All",
    }),
    key: "all",
    dataTestId: "all",
  },
]

export const REPRESENTATIVE_USER_TYPE = {
  LEGAL: "LEGAL",
  TECHNICAL: "TECHNICAL",
  MARKETING: "MARKETING",
}

const editKey = (formatMessage) => ({
  label: formatMessage({
    id: "ui::rightRelationship::edit",
    defaultMessage: "Edit",
  }),
  key: "edit",
  dataTestId: "edit",
})

const viewKey = (formatMessage) => ({
  label: formatMessage({
    id: "ui::rightRelationship::view",
    defaultMessage: "View",
  }),
  key: "view",
  dataTestId: "view",
})

const emptyKey = ""

const getRelationshipOfPartnerUserRole = (formatMessage) => ({
  accountInformation: {
    name: {
      label: formatMessage({
        id: "ui::rightRelationship::accountInformation",
        defaultMessage: "Account Information",
      }),
      key: "accountInformation",
      dataTestId: "accountInformation",
    },
    "partner-admin": editKey(formatMessage),
    "partner-content-creator": viewKey(formatMessage),
    "partner-finance": editKey(formatMessage),
    "partner-user": viewKey(formatMessage),
    "partner-voucher-manager": emptyKey,
  },
  campaigns: {
    name: {
      label: formatMessage({
        id: "ui::rightRelationship::campaigns",
        defaultMessage: "Campaigns",
      }),
      key: "campaigns",
      dataTestId: "campaigns",
    },
    "partner-admin": editKey(formatMessage),
    "partner-content-creator": editKey(formatMessage),
    "partner-finance": viewKey(formatMessage),
    "partner-user": viewKey(formatMessage),
    "partner-voucher-manager": emptyKey,
  },
  donations: {
    name: {
      label: formatMessage({
        id: "ui::rightRelationship::donations",
        defaultMessage: "Donations",
      }),
      key: "donations",
      dataTestId: "donations",
    },
    "partner-admin": viewKey(formatMessage),
    "partner-content-creator": emptyKey,
    "partner-finance": viewKey(formatMessage),
    "partner-user": emptyKey,
    "partner-voucher-manager": viewKey(formatMessage),
  },
  userManagement: {
    name: {
      label: formatMessage({
        id: "ui::rightRelationship::userManagement",
        defaultMessage: "User Management",
      }),
      key: "userManagement",
      dataTestId: "userManagement",
    },
    "partner-admin": editKey(formatMessage),
    "partner-content-creator": emptyKey,
    "partner-finance": emptyKey,
    "partner-user": emptyKey,
    "partner-voucher-manager": emptyKey,
  },
  downloadCenter: {
    name: {
      label: formatMessage({
        id: "ui::rightRelationship::downloadCenter",
        defaultMessage: "Download Center",
      }),
      key: "downloadCenter",
      dataTestId: "downloadCenter",
    },
    "partner-admin": viewKey(formatMessage),
    "partner-content-creator": viewKey(formatMessage),
    "partner-finance": viewKey(formatMessage),
    "partner-user": viewKey(formatMessage),
    "partner-voucher-manager": viewKey(formatMessage),
  },
  referralCodes: {
    name: {
      label: formatMessage({
        id: "dashboard::nav::referralCodes",
        defaultMessage: "Referral Codes",
      }),
      key: "referralCodes",
      dataTestId: "referralCodes",
    },
    "partner-admin": editKey(formatMessage),
    "partner-content-creator": editKey(formatMessage),
    "partner-finance": editKey(formatMessage),
    "partner-user": emptyKey,
    "partner-voucher-manager": emptyKey,
  },
  voucherConfirmation: {
    name: {
      label: formatMessage({
        id: "ui::rightRelationship::voucherConfirmation",
        defaultMessage: "Voucher Confirmation",
      }),
      key: "voucherConfirmation",
      dataTestId: "voucherConfirmation",
    },
    "partner-admin": editKey(formatMessage),
    "partner-content-creator": editKey(formatMessage),
    "partner-finance": emptyKey,
    "partner-user": emptyKey,
    "partner-voucher-manager": editKey(formatMessage),
  },
})

const getRelationshipOfMerchantUserRole = (formatMessage) => ({
  accountInformation: {
    name: {
      label: formatMessage({
        id: "ui::rightRelationship::accountInformation",
        defaultMessage: "Account Information",
      }),
      key: "accountInformation",
      dataTestId: "accountInformation",
    },
    "merchant-admin": editKey(formatMessage),
    "merchant-content-creator": viewKey(formatMessage),
    "merchant-developer": viewKey(formatMessage),
    "merchant-finance": viewKey(formatMessage),
    "merchant-user": viewKey(formatMessage),
    "merchant-voucher-manager": emptyKey,
    isMerchantCorporate: true,
    isMerchantInternal: true,
    isMerchantOnline: true,
    isMerchantOffline: true,
  },
  userManagement: {
    name: {
      label: formatMessage({
        id: "ui::rightRelationship::userManagement",
        defaultMessage: "User Management",
      }),
      key: "userManagement",
      dataTestId: "userManagement",
    },
    "merchant-admin": editKey(formatMessage),
    "merchant-content-creator": emptyKey,
    "merchant-developer": emptyKey,
    "merchant-finance": emptyKey,
    "merchant-user": emptyKey,
    "merchant-voucher-manager": emptyKey,
    isMerchantCorporate: false,
    isMerchantInternal: true,
    isMerchantOnline: true,
    isMerchantOffline: true,
  },
  offers: {
    name: {
      label: formatMessage({
        id: "ui::rightRelationship::offers",
        defaultMessage: "Offers",
      }),
      key: "offers",
      dataTestId: "offers",
    },
    "merchant-admin": editKey(formatMessage),
    "merchant-content-creator": editKey(formatMessage),
    "merchant-developer": viewKey(formatMessage),
    "merchant-finance": viewKey(formatMessage),
    "merchant-user": viewKey(formatMessage),
    "merchant-voucher-manager": emptyKey,
    isMerchantCorporate: false,
    isMerchantInternal: false,
    isMerchantOnline: true,
    isMerchantOffline: false,
  },
  sales: {
    name: {
      label: formatMessage({
        id: "ui::rightRelationship::sales",
        defaultMessage: "Sales",
      }),
      key: "sales",
      dataTestId: "sales",
    },
    "merchant-admin": editKey(formatMessage),
    "merchant-content-creator": emptyKey,
    "merchant-developer": emptyKey,
    "merchant-finance": editKey(formatMessage),
    "merchant-user": emptyKey,
    "merchant-voucher-manager": emptyKey,
    isMerchantCorporate: false,
    isMerchantInternal: false,
    isMerchantOnline: true,
    isMerchantOffline: false,
  },
  pluginManagement: {
    name: {
      label: formatMessage({
        id: "ui::rightRelationship::pluginManagement",
        defaultMessage: "Plugin Management",
      }),
      key: "pluginManagement",
      dataTestId: "pluginManagement",
    },
    "merchant-admin": editKey(formatMessage),
    "merchant-content-creator": emptyKey,
    "merchant-developer": editKey(formatMessage),
    "merchant-finance": emptyKey,
    "merchant-user": emptyKey,
    "merchant-voucher-manager": emptyKey,
    isMerchantCorporate: false,
    isMerchantInternal: false,
    isMerchantOnline: true,
    isMerchantOffline: false,
  },
  downloadCenter: {
    name: {
      label: formatMessage({
        id: "ui::rightRelationship::downloadCenter",
        defaultMessage: "Download Center",
      }),
      key: "downloadCenter",
      dataTestId: "downloadCenter",
    },
    "merchant-admin": viewKey(formatMessage),
    "merchant-content-creator": viewKey(formatMessage),
    "merchant-developer": viewKey(formatMessage),
    "merchant-finance": viewKey(formatMessage),
    "merchant-user": viewKey(formatMessage),
    "merchant-voucher-manager": viewKey(formatMessage),
    isMerchantCorporate: true,
    isMerchantInternal: true,
    isMerchantOnline: true,
    isMerchantOffline: true,
  },
  reports: {
    name: {
      label: formatMessage({
        id: "ui::rightRelationship::reports",
        defaultMessage: "Reports",
      }),
      key: "reports",
      dataTestId: "reports",
    },
    "merchant-admin": editKey(formatMessage),
    "merchant-content-creator": emptyKey,
    "merchant-developer": emptyKey,
    "merchant-finance": editKey(formatMessage),
    "merchant-user": emptyKey,
    "merchant-voucher-manager": emptyKey,
    isMerchantCorporate: true,
    isMerchantInternal: true,
    isMerchantOffline: true,
    isMerchantOnline: true,
  },
  discountCampaigns: {
    name: {
      label: formatMessage({
        id: "ui::rightRelationship::discountCampaigns",
        defaultMessage: "Campaigns",
      }),
      key: "discountCampaigns",
      dataTestId: "discountCampaigns",
    },
    "merchant-admin": editKey(formatMessage),
    "merchant-content-creator": editKey(formatMessage),
    "merchant-developer": emptyKey,
    "merchant-finance": editKey(formatMessage),
    "merchant-user": viewKey(formatMessage),
    "merchant-voucher-manager": viewKey(formatMessage),
    isMerchantCorporate: true,
    isMerchantInternal: false,
    isMerchantOffline: true,
    isMerchantOnline: false,
  },
  services: {
    name: {
      label: formatMessage({
        id: "ui::rightRelationship::services",
        defaultMessage: "Services",
      }),
      key: "services",
      dataTestId: "services",
    },
    "merchant-admin": editKey(formatMessage),
    "merchant-content-creator": editKey(formatMessage),
    "merchant-developer": emptyKey,
    "merchant-finance": editKey(formatMessage),
    "merchant-user": viewKey(formatMessage),
    "merchant-voucher-manager": emptyKey,
    isMerchantCorporate: false,
    isMerchantInternal: true,
    isMerchantOffline: false,
    isMerchantOnline: false,
  },
  salesIncludesVoucherVerification: {
    name: {
      label: formatMessage({
        id: "ui::rightRelationship::salesIncludesVoucherVerification",
        defaultMessage: "Sales (Includes Voucher Verification)",
      }),
      key: "salesIncludesVoucherVerification",
      dataTestId: "salesIncludesVoucherVerification",
    },
    "merchant-admin": editKey(formatMessage),
    "merchant-content-creator": emptyKey,
    "merchant-developer": emptyKey,
    "merchant-finance": editKey(formatMessage),
    "merchant-user": emptyKey,
    "merchant-voucher-manager": viewKey(formatMessage),
    isMerchantCorporate: false,
    isMerchantInternal: true,
    isMerchantOffline: false,
    isMerchantOnline: false,
  },
})

export const getRelationshipTable = (
  dashboardType,
  merchantKey,
  tableHeader,
  formatMessage
) => {
  const getRelationshipFunction = () => {
    switch (dashboardType) {
      case "merchants":
        return getRelationshipOfMerchantUserRole(formatMessage)
      case "partners":
        return getRelationshipOfPartnerUserRole(formatMessage)
      default:
        return null
    }
  }

  const relationshipData = getRelationshipFunction()

  if (!relationshipData) return null

  return Object.values(relationshipData)
    .filter((userData) =>
      dashboardType === "merchants" ? userData[merchantKey] : true
    )
    .flatMap((userData) => {
      const arr = [userData.name]
      tableHeader.forEach(({ codename }) => arr.push(userData[codename]))
      return arr
    })
}

export const PAYMENT_TYPES = {
  CARD: "card",
  PAYPAL: "paypal",
  CUSTOMER_BALANCE: "customer_balance",
  KLARNA: "klarna",
}

export const dateLocales = { en: enUS, it: it, es: es }

export const TOAST_THEME = {
  COLORED: "colored",
  DARK: "dark",
  LIGHT: "light",
}

export const userType = (formatMessage) => [
  {
    id: "individual",
    label: formatMessage({
      id: "services::purchaseForm::individual",
      defaultMessage: "As an individual",
    }),
  },
  {
    id: "company",
    label: formatMessage({
      id: "services::purchaseForm::company",
      defaultMessage: "As a company",
    }),
  },
]

export const ACCOUNT_TYPE = {
  PERSONAL: "PERSONAL",
  BUSINESS: "BUSINESS",
}
