import styled from "@emotion/styled"
import { Link } from "gatsby-plugin-intl"

export const StyledStaticPage = styled.div`
  margin: 0 auto;
  padding: 0;
  max-width: ${(p) => p.theme.breakpoints.wide};
  text-align: left;
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > div {
    width: 100%;
  }

  h1 {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-weight: regular;
    margin: 2.8125rem auto 1.875rem auto;
    text-align: center;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${(p) => p.theme.fontFamilies.regular};
    margin: 1.875rem 0 0.9375rem 0;
  }

  ul,
  ol,
  p {
    line-height: 1.6;
    text-align: left;
  }

  & > p,
  ul {
    width: 100%;
  }

  span {
    font-family: inherit;
  }

  a {
    color: ${(p) => p.theme.colors.blue};
    font-weight: ${(p) => p.theme.fontWeights.bold};
  }

  p,
  h3 {
    padding: 0.9375rem 0;
  }

  ol {
    padding: 1.875rem;
  }

  li {
    padding-bottom: 1.25rem;
  }

  table,
  tr,
  td,
  th {
    border: 0.0625rem solid ${(p) => p.theme.colors.tableItemBorder};
  }

  table,
  figure {
    overflow: auto;
    width: 100% !important;
  }

  .signup-register-success {
    display: grid;
    justify-content: center;
    justify-items: center;

    padding: 5rem 1rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      margin-top: 3rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      margin-top: 6rem;
    }

    h4 {
      width: 20.4375rem;

      font-family: ${(p) => p.theme.fontFamilies.headline};
      font-style: ${(p) => p.theme.fontStyles.normal};
      font-weight: ${(p) => p.theme.fontWeights.semiBolder};
      color: ${(p) => p.theme.colors.pitchBlack};
      font-size: 1.5rem;
      line-height: 2rem;
      text-align: center;

      margin: 2.5rem 0 1.25rem;

      @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
        width: 45rem;

        font-size: 2rem;
        line-height: 2.625rem;

        margin: 3rem 0 1.5rem;
      }

      @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
        width: 60rem;

        font-size: 2rem;
        line-height: 2.625rem;

        margin: 3rem 0 1.5rem;
      }

      @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
        font-size: 3rem;
        line-height: 4.0625rem;

        margin: 6rem 0 1.5rem;
      }
    }

    .signup-success-message1 {
      width: 20.5625rem;

      font-family: ${(p) => p.theme.fontFamilies.regular};
      font-style: ${(p) => p.theme.fontStyles.normal};
      font-weight: ${(p) => p.theme.fontWeights.liteMedium};
      color: ${(p) => p.theme.colors.pitchBlack};

      font-size: 1rem;
      line-height: 1.375rem;
      text-align: center;

      @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
        width: 25.25rem;
      }

      @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      }

      @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
        width: 37.875rem;

        font-size: 1.5rem;
        line-height: 2.25rem;
      }
    }

    .signup-success-message2 {
      width: 100%;

      font-family: ${(p) => p.theme.fontFamilies.regular};
      font-style: ${(p) => p.theme.fontStyles.normal};
      font-weight: ${(p) => p.theme.fontWeights.liteMedium};
      color: ${(p) => p.theme.colors.pitchBlack};

      font-size: 1rem;
      line-height: 1.375rem;
      text-align: center;

      @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      }

      @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      }

      @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
        font-size: 1.5rem;
        line-height: 2.25rem;
      }
    }
  }

  .sticky-bottom-bar {
    filter: unset;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      padding: unset;
      position: unset;
      background: unset;
      bottom: unset;
      margin: unset;
      width: unset;
      filter: unset;
      > button {
        margin: unset;
        width: unset;
        white-space: nowrap;
      }
      z-index: unset;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    }
  }

  button {
    min-width: 20.5rem;

    background: ${(p) => p.theme.colors.white};
    border: 0.0625rem solid ${(p) => p.theme.colors.pitchBlack};
    border-radius: 0.25rem;

    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.pitchBlack};

    font-size: 0.75rem;
    line-height: 1rem;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      margin-top: 3rem !important;

      min-width: 12.125rem;

      background: ${(p) => p.theme.colors.blue};
      border: none;
      color: ${(p) => p.theme.colors.white};
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      margin-top: 3.625rem !important;

      min-width: 22.5rem !important;
      font-size: 1rem;
      line-height: 1.375rem;
    }

    &:hover {
      background: ${(p) => p.theme.colors.blue};
      color: ${(p) => p.theme.colors.white};
      border: none;
    }
  }
`

export const StyledNotFoundPage = styled(StyledStaticPage)`
  max-width: ${(p) => p.theme.breakpoints.tablet};
  min-height: 80vh;
  height: 100%;
  text-align: center;
  padding: 0 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: unset;
  }

  h1 {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-size: 3.2rem;
    margin-bottom: 1rem;
  }

  p {
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-size: 1.2rem;
    max-width: ${(p) => p.theme.breakpoints.tablet};
    line-height: 1.6;
    text-align: center;
  }
`

export const StyledLink = styled(Link)`
  &,
  &:hover,
  &:visited,
  &.active {
    color: ${(p) => p.theme.colors.blue} !important;
    font-weight: ${(p) => p.theme.fontWeights.bold};
  }
`

export const StyledCommonNotFoundPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 0 auto;
  padding: 0;
  min-height: 80vh;
  height: 100%;
  text-align: center;
  padding: 0 1rem;

  max-width: ${(p) => p.theme.breakpoints.tablet};
  > div {
    width: 100%;

    &.media-box {
      @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
        margin-top: 3rem;
      }

      @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
        margin-top: unset;
      }
    }
  }

  h4 {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-style: ${(p) => p.theme.fontStyles.normal};
    color: ${(p) => p.theme.colors.pitchBlack};
    font-size: 1.5rem;
    line-height: 2.125rem;

    margin: 0.75rem 0 1.25rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      margin: 0 0 1rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 3rem;
      line-height: normal;
      margin: 3.3125rem 0 2.5rem;
    }
  }

  span {
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-size: 1rem;
    line-height: 1.5rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.5rem;
      line-height: 2.25rem;
    }
  }
`

export const StyledButtons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  button {
    width: 100%;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    flex-direction: row;
    gap: 1.5rem;
    padding: 0;
    margin: 2.5rem 0 3.375rem;

    button {
      min-width: 12.5rem;
      margin: 0;
      width: unset;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin-top: 5rem;

    button {
      min-width: 20rem;
    }
  }
`
