import { isBrowser } from "@tmu/utils/auth"

const useIntercom = () => {
  const callIntercom = ({ user, appId }) => {
    try {
      if (
        isBrowser &&
        (window.IntercomIncludeInDevelopment ||
          process.env.NODE_ENV === "production") &&
        typeof Intercom === "function" &&
        (window?.IntercomAppId || appId)
      ) {
        if (!window.Intercom) {
          window.Intercom = Intercom
        }
        if (user?.intercomIdentity) {
          window.Intercom("boot", {
            app_id: appId || window?.IntercomAppId,
            hide_default_launcher: window?.HideDefaultLauncher,
            user_id: user?.email,
            user_hash: user?.intercomIdentity,
            name: user?.displayName || user?.fullName,
          })
        } else {
          window.Intercom("boot", {
            app_id: appId || window?.IntercomAppId,
            hide_default_launcher: window?.HideDefaultLauncher,
          })
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  const closeIntercom = () => {
    if (
      isBrowser &&
      (window.IntercomIncludeInDevelopment ||
        process.env.NODE_ENV === "production") &&
      typeof Intercom === "function" &&
      (window?.IntercomAppId || appId)
    ) {
      window.Intercom("shutdown")
    }
  }
  return { callIntercom, closeIntercom }
}

export default useIntercom
