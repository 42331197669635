import React, { Fragment, memo, useState, useEffect } from "react"
import PropTypes from "prop-types"
import { faClock } from "@fortawesome/pro-regular-svg-icons/faClock"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FormattedMessage } from "gatsby-plugin-intl"
import { Line } from "rc-progress"
import { Money, Date } from "@tmu/components/common"
import {
  StyledProgressBar,
  StyledFundAmount,
  StyledSupporters,
  StyledGoalText,
  StyledSharedButton,
} from "./index.styles"
import { faShareAlt as faShare } from "@fortawesome/pro-regular-svg-icons/faShareAlt"
import { useMediaQuery } from "beautiful-react-hooks"

const ProgressBar = ({
  amount,
  total,
  currentNumber,
  endDate,
  isFeatured,
  hideDecimals,
  animate,
  campaignType,
  showProgressLine,
  handleShare,
}) => {
  const [percent, setPercent] = useState(0)
  const decimalDigits = hideDecimals ? 0 : 2
  const [totalPercent, setTotalPercent] = useState(0)
  const isMedium =
    typeof window !== "undefined" && useMediaQuery("(max-width: 767px)")

  useEffect(() => {
    setPercent(totalPercent)
  }, [totalPercent])

  useEffect(() => {
    calculateTotalPercent()
  }, [total, amount])

  const calculateTotalPercent = () => {
    const totalPercentData = total
      ? Number(amount) < Number(total)
        ? 100 * (Number(amount) / Number(total))
        : 100
      : 100

    setTotalPercent(totalPercentData)
  }

  const totalAmount = (
    <p className="caption">
      <FormattedMessage
        id="progress::detail::fundedOf"
        defaultMessage="<funded>{amount}</funded> donated of <goal>{total}</goal> goal"
        values={{
          goal: (...chunks) => (
            <span key="amount" className="caption fund-card">
              {chunks.map((chunk, i) => (
                <Fragment key={i}>{chunk}</Fragment>
              ))}
            </span>
          ),
          total: (
            <Money
              minimumFractionDigits={decimalDigits}
              maximumFractionDigits={decimalDigits}
              value={total}
            />
          ),
          funded: (...chunks) => (
            <span key="funded" className="caption fund-card">
              {chunks.map((chunk, i) => (
                <Fragment key={i}>{chunk}</Fragment>
              ))}
            </span>
          ),
          amount: (
            <Money
              minimumFractionDigits={decimalDigits}
              maximumFractionDigits={decimalDigits}
              value={amount}
            />
          ),
        }}
      />
    </p>
  )

  return (
    <StyledProgressBar animate={animate} percent={percent}>
      <StyledFundAmount className="amount-bar" isFeatured={isFeatured}>
        {!isFeatured ? (
          endDate ? (
            <p>
              <FontAwesomeIcon icon={faClock} />
              <FormattedMessage
                id="progress::endsIn"
                defaultMessage="Ends in"
              />
              <Date value={endDate} year="numeric" month="long" day="2-digit" />
            </p>
          ) : (
            <>
              {isMedium && handleShare && (
                <StyledSharedButton>
                  <FontAwesomeIcon icon={faShare} onClick={handleShare} />
                </StyledSharedButton>
              )}
            </>
          )
        ) : (
          <>
            {totalAmount}
            <StyledSupporters>
              <span>{currentNumber}</span>
              <FormattedMessage
                id="card::supporters"
                defaultMessage="Supporters"
              />
            </StyledSupporters>
          </>
        )}
      </StyledFundAmount>
      {showProgressLine === true && (total > 0 || campaignType === "GB") && (
        <>
          <Line
            percent={percent}
            strokeWidth="3"
            strokeColor="rgba(29, 158, 188, 1)"
            trailColor="rgba(196, 196, 196, 1)"
            trailWidth="3"
            data-testid="progress-bar"
          />
          <StyledGoalText className="caption goal">
            {totalAmount}
          </StyledGoalText>
        </>
      )}
    </StyledProgressBar>
  )
}

ProgressBar.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currentNumber: PropTypes.number,
  displayCurrentNumber: PropTypes.bool,
  endDate: PropTypes.instanceOf(Date),
  isFeatured: PropTypes.bool,
}

ProgressBar.defaultProps = {
  amount: 0,
  total: 0,
  currentNumber: 0,
  displayCurrentNumber: false,
  isFeatured: false,
}

export default memo(ProgressBar)
