import React from "react"
import { Spacer } from "@tmu/global/page-addons/detail-page.styles"
import { Money, Date, ClickToCopy, Logo } from "@tmu/components/common"
import { FormattedMessage } from "gatsby-plugin-intl"
import {
  StyledBottomContainer,
  StyledDonationAmount,
  StyledDonationId,
  StyledDonor,
  StyledGridRow,
  StyledHeader,
  StyledLabel,
  StyledLine,
  StyledPacsContainer,
  StyledPageWrapper,
  StyledRow,
  StyledValue,
} from "./index.styles"
import theme from "@tmu/global/theme"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCopy } from "@fortawesome/pro-light-svg-icons/faCopy"

export const PurchaseDetail = ({ order, rowData }) => {
  const tableData = rowData?.original
  const storeName = tableData?.name
  return (
    <StyledPageWrapper>
      <StyledHeader>
        <FormattedMessage
          id="dashboard::purchaseDetail::title"
          defaultMessage="Purchases"
        />
      </StyledHeader>
      <Spacer top={1.5} />
      <StyledDonationAmount>
        <Money value={order?.total} />
      </StyledDonationAmount>
      <Spacer top={1} />
      <StyledDonor>{storeName}</StyledDonor>
      <Spacer top={0.75} />
      <StyledDonationId>
        <div>{tableData?.orderId}</div>
        <div>
          <ClickToCopy text={tableData?.orderId}>
            <FontAwesomeIcon icon={faCopy} />
          </ClickToCopy>
        </div>
      </StyledDonationId>
      <Spacer bottom={1.5} />
      <StyledBottomContainer>
        {order?.cartItems?.map((item, i) => (
          <StyledRow key={"cartItem-" + i}>
            <StyledLabel
              title={item?.offer?.name}
              className="align-left ellipsis">
              {item?.offer?.name}
            </StyledLabel>
            <StyledGridRow>
              <StyledLabel className="align-right">
                <Money value={item?.frozenPrice} />
              </StyledLabel>
              <StyledLabel>{item?.quantity}</StyledLabel>
              <StyledValue className="align-right">
                <Money value={item?.frozenTotal} />
              </StyledValue>
            </StyledGridRow>
          </StyledRow>
        ))}
        {order?.cartItems?.length > 0 && <StyledLine />}
        <StyledRow>
          <StyledLabel className="align-left">
            <FormattedMessage
              id="dashboard::tableHeader::dateAndTime"
              defaultMessage="Date / Time"
            />
          </StyledLabel>
          <StyledValue className="align-right">
            <Date value={tableData?.date} showYear={true} second="numeric" />
          </StyledValue>
        </StyledRow>
        <StyledLine />
        <StyledRow>
          <StyledLabel>
            <div className="header-text header-icon align-left">
              <FormattedMessage
                id="dashboard::tableHeader::pacsAmount"
                defaultMessage="PACs Amount"
              />
            </div>
          </StyledLabel>
          <StyledValue className="align-right">
            <StyledPacsContainer>
              <Logo
                className="pac-icon"
                width={19}
                height={24}
                fill={theme?.colors?.carrot}
              />
              {tableData?.pacs}
            </StyledPacsContainer>
          </StyledValue>
        </StyledRow>
        <StyledLine />
      </StyledBottomContainer>
    </StyledPageWrapper>
  )
}

export default PurchaseDetail
